"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { CompanyAPI } from "@/api-client/index.api";
import { UploadProfilePicture } from "@/component/forms/UploadProfilePicture";
import { GlobalContext } from "@/GlobalState";
import { CompanySettingsDetailsDrawer } from "@/v2/feature/company/company-settings/features/components/company-settings/company-details/company-settings-details-drawer.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const CompanySettingsDetailsComponent = ({
  generalSettings,
  refreshSettings,
  editMode,
  setEditMode
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const [localAvatar, setLocalAvatar] = useState();
  useEffect(() => {
    setLocalAvatar(globalState.user.company.avatar);
  }, [globalState.user.company.avatar, setLocalAvatar]);
  const hasAvatar = useMemo(() => {
    return globalState.publicURL && localAvatar ? true : false;
  }, [localAvatar, globalState.publicURL]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t("CompanySettingsGeneralEdit.displayName"),
                value: (_a = generalSettings == null ? void 0 : generalSettings.name) != null ? _a : "-"
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t("CompanySettingsGeneralEdit.companyLogo"),
                value: /* @__PURE__ */ jsx(
                  UploadProfilePicture,
                  {
                    boxSize: 20,
                    avatarSize: "s20",
                    disabled: true,
                    value: hasAvatar ? `${globalState.publicURL}/${localAvatar}` : void 0,
                    onChange: (tempAvatar) => {
                      setLocalAvatar(tempAvatar);
                    },
                    url: CompanyAPI.getCompanyUploadURL(),
                    userId: null
                  }
                )
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      CompanySettingsDetailsDrawer,
      {
        generalSettings,
        refreshSettings,
        setEditMode,
        editMode
      }
    )
  ] });
};
