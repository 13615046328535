"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import { EditInsuranceQuoteMembersDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/components/edit-insurance-members-drawer.component";
import { InsuranceAddToPolicyDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/insurance/components/insurance-add-to-policy-drawer.component";
import { BenefitsSettingsPageSection } from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import { InsuranceQuoteStatus } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const InsuranceSettingsMembersPage = ({ insuranceQuote, refreshInsurance }) => {
  const { polyglot } = usePolyglot();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const [searchInput, setSearchInput] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const listOfUsers = useMemo(() => {
    var _a;
    const userIdsSet = new Set((_a = insuranceQuote.includedUserIds) != null ? _a : []);
    return companyUsers.filter((u) => userIdsSet.has(u.userId));
  }, [insuranceQuote, companyUsers]);
  const filteredUsers = useMemo(() => {
    if (!searchInput) return [...listOfUsers];
    const search = searchInput.toLowerCase();
    return [...listOfUsers.filter((u) => u.displayName.toLowerCase().includes(search))];
  }, [listOfUsers, searchInput]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("General.name") }),
        accessorFn: (row) => row,
        id: "displayName",
        enableSorting: true,
        cell: ({
          row: {
            original: { userId, displayName }
          }
        }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId, size: "s20" }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t(displayName) })
        ] }, userId)
      }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("General.members"),
      onEdit: () => {
        setIsEditOpen(true);
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("AbsencePolicyMembersSection.list") }),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => setSearchInput(e.target.value),
            style: { width: "572px" }
          }
        ),
        filteredUsers.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { width: "600px" }, children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: filteredUsers,
            columnData: tableColumns,
            initialSort: [{ id: "displayName", desc: true }],
            fixedLastColumn: false
          }
        ) }),
        isEditOpen && insuranceQuote.status && insuranceQuote.status === InsuranceQuoteStatus.Pending && !insuranceQuote.policy && /* @__PURE__ */ jsx(
          EditInsuranceQuoteMembersDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            insuranceQuote,
            refresh: refreshInsurance
          }
        ),
        isEditOpen && insuranceQuote.status && insuranceQuote.policy && /* @__PURE__ */ jsx(
          InsuranceAddToPolicyDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            insurancePolicy: insuranceQuote.policy,
            refresh: refreshInsurance,
            onClose: () => {
            }
          }
        )
      ]
    }
  );
};
