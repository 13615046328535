"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { intersection, keyBy } from "lodash";
import { ReactComponent as Back } from "@/images/fields/Left.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  ReviewerTypesOrderMap,
  getReviewerTypesChips
} from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { NonSelfReviewerTypes, ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { QuestionType, QuestionTypeMap } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { stripHtml } from "@/v2/util/string.util";
export const RCTemplatePreviewer = ({
  templateName,
  questions,
  sections,
  growthFactors,
  order,
  addTemplateAction,
  onBack
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", boxSizing: "border-box" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
        /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: onBack, children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize)) }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: templateName })
      ] }),
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: addTemplateAction, children: polyglot.t("RCTemplatePreviewer.addTemplate") })
    ] }),
    questions && questions.length ? /* @__PURE__ */ jsx(
      RCTemplatePreviewerContent,
      {
        questions,
        sections,
        order,
        growthFactors
      }
    ) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("RCTemplatePreviewer.addSomeSectionsAndQuestionsToPreview") })
  ] });
};
const RCTemplatePreviewerContent = ({
  questions,
  sections,
  order,
  growthFactors
}) => {
  const questionLookup = useMemo(() => {
    return keyBy(questions != null ? questions : [], "id");
  }, [questions]);
  const sectionGroupedQuestionLookup = useMemo(() => {
    return keyBy(sections != null ? sections : [], "id");
  }, [sections]);
  const sectionLookup = useMemo(() => {
    return keyBy(sections != null ? sections : [], "id");
  }, [sections]);
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column" }, children: (order || []).filter(Boolean).map((item, idx) => {
    const showBorder = Boolean(item.type === "question" && order[idx - 1] && order[idx - 1].type === "section");
    return item.type === "question" ? /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, showBorder ? { borderTop: borders.middle, paddingTop: spacing.s5 } : {}), {
          marginBottom: spacing.s2
        }),
        children: /* @__PURE__ */ jsx(
          QuestionComponent,
          {
            question: questionLookup[item.id],
            growthFactors,
            showBorder: true
          },
          item.id
        )
      },
      idx
    ) : item.type === "section" ? /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadValues({}, idx === 0 ? { marginBottom: spacing.s5 } : { marginY: spacing.s5 }),
        children: /* @__PURE__ */ jsx(
          SectionComponent,
          {
            item,
            sectionLookup,
            sectionGroupedQuestionLookup,
            growthFactors,
            questionLookup
          },
          item.id
        )
      },
      idx
    ) : null;
  }) });
};
const QuestionComponent = ({
  question,
  growthFactors,
  showBorder
}) => {
  var _a, _b;
  const questionText = useMemo(() => {
    if (!question) return "";
    return Boolean(intersection(NonSelfReviewerTypes, question.reviewerSelect).length > 0) ? stripHtml(question.questionMain) : stripHtml(question.questionSelf);
  }, [question]);
  if (!question) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      showBorder,
      cells: [
        {
          content: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.g8 }, children: [
            question && questionText && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: questionText }),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap", gap: spacing.g8 }, children: [
              question.factor && /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "middle",
                  name: /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.g4
                      },
                      children: [
                        /* @__PURE__ */ jsx(
                          "div",
                          {
                            style: {
                              backgroundColor: (_b = (_a = growthFactors[question.factor]) == null ? void 0 : _a.color) != null ? _b : themeColors.Grey,
                              height: "8px",
                              width: "8px",
                              borderRadius: radius.br25
                            }
                          }
                        ),
                        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: question.factor })
                      ]
                    }
                  )
                }
              ),
              question.type === QuestionType.ScaleQuestion && question.scaleConfig ? /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "middle",
                  name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: question.scaleConfig.type })
                }
              ) : /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "middle",
                  name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: QuestionTypeMap[question.type] })
                }
              ),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey, gap: spacing.g4, display: "flex" }, children: question.reviewerSelect ? question.reviewerSelect.sort((a, b) => ReviewerTypesOrderMap[a] - ReviewerTypesOrderMap[b]).map((r) => {
                const hideVisibility = r !== ReviewerTypes.Self && (r === ReviewerTypes.Manager && question.visibilitySettings.hideManagerResult || r === ReviewerTypes.Upward && question.visibilitySettings.hideUpwardResult || r === ReviewerTypes.Peer && question.visibilitySettings.hidePeerResult);
                return getReviewerTypesChips(hideVisibility)[r];
              }) : "" })
            ] })
          ] }),
          gridXs: 12
        }
      ]
    }
  );
};
const SectionComponent = ({
  item,
  sectionLookup,
  sectionGroupedQuestionLookup,
  growthFactors,
  questionLookup
}) => {
  const section = sectionLookup[item.id];
  if (!section) return /* @__PURE__ */ jsx(Fragment, {});
  const sectionQuestions = sectionGroupedQuestionLookup[item.id].questionIds || [];
  if (!sectionQuestions.length) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", border: borders.middle, borderRadius: radius.br12 },
      children: [
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              borderTopLeftRadius: radius.br12,
              borderTopRightRadius: radius.br12,
              background: themeColors.Background,
              p: spacing.s2,
              height: "80px",
              boxSizing: "border-box"
            },
            children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: section.name })
          }
        ),
        sectionQuestions.map((id) => /* @__PURE__ */ jsx(
          QuestionComponent,
          {
            question: questionLookup[id],
            growthFactors,
            showBorder: false
          },
          item.id
        ))
      ]
    },
    item.id
  );
};
