"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { MultipleSelectCheckbox } from "@v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { UserInsuranceDependants } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import {
  calculateAnnualValueOfTheBenefitForCurrentTaxYear,
  getAdditionalMembersOptions,
  getInsuranceMultiplierValueByDependantsValue
} from "@v2/feature/benefits/subfeature/insurance/insurance.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { UserFamilyMemberType } from "@v2/feature/user/features/user-forms/user-family/user-family.interface";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const InsuranceAddDependantsDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  insurancePolicy,
  refresh,
  onClose
}) => {
  const { data: userFamilyMembers } = useApiClient(UserEndpoints.getUserFamilyMembers(userInsurance.userId), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const [insuranceAddonsMultipliers, setInsuranceAddonsMultipliers] = useState(
    null
  );
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const insuranceAddonsMultipliers2 = yield InsuranceAPI.getInsuranceAddonsMultipliersByPolicyId(
          insurancePolicy.id
        );
        if (!insuranceAddonsMultipliers2) {
          showMessage(polyglot.t("BenefitModule.ErrorMessages.couldNotFindPolicyAddOns"), "error");
          return;
        }
        setInsuranceAddonsMultipliers(insuranceAddonsMultipliers2);
      } catch (error) {
        showMessage(
          polyglot.t("BenefitModule.ErrorMessages.couldNotGetAddOnsMultipliers", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }))();
  }, [polyglot, showMessage, insurancePolicy.id]);
  return !loading && insuranceAddonsMultipliers ? /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        InsuranceAddDependantsDrawerContent,
        {
          userInsurance,
          insurancePolicy,
          insuranceAddonsMultipliers,
          userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [],
          refresh,
          onClose
        }
      )
    }
  ) }) : null;
};
const InsuranceAddDependantsDrawerContent = ({
  userInsurance,
  insurancePolicy,
  insuranceAddonsMultipliers,
  userFamilyMembers,
  refresh,
  onClose
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isEditForm = !!(userInsurance == null ? void 0 : userInsurance.dependants);
  const formik = useFormik({
    initialValues: {
      dependants: (_a = userInsurance == null ? void 0 : userInsurance.dependants) != null ? _a : UserInsuranceDependants.SpousePartner,
      additionalMonthlyPremium: (_b = userInsurance == null ? void 0 : userInsurance.dependantsMonthlyPremium) != null ? _b : userInsurance.monthlyPremium ? getInsuranceMultiplierValueByDependantsValue(
        userInsurance.monthlyPremium,
        isEditForm ? userInsurance.dependants : UserInsuranceDependants.SpousePartner,
        insuranceAddonsMultipliers
      ) : 0,
      employeeCoversAdditionalPremium: isEditForm ? !!userInsurance.dependantsMonthlyPremium && userInsurance.dependantsMonthlyPremium === userInsurance.dependantsMonthlyContribution : true,
      dependantsList: isEditForm ? (_c = userInsurance.dependantsList) != null ? _c : [] : []
    },
    validationSchema: yup.object({
      additionalMonthlyPremium: yup.number().required(polyglot.t("ValidationMessages.requiredField")),
      employeeCoversAdditionalPremium: yup.boolean().required(polyglot.t("ValidationMessages.requiredField")),
      dependants: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      dependantsList: yup.array().of(yup.number().integer()).min(1, polyglot.t("ValidationMessages.select1Value")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => addDependants(userInsurance.userId, values, insurancePolicy.id)
  });
  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];
    return formik.values.dependantsList.map((id) => {
      const familyMember = userFamilyMembers.find((m) => m.id === id);
      return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
    }).filter(Boolean);
  }, [formik.values.dependantsList, userFamilyMembers]);
  const dependantsListOptions = useMemo(() => {
    var _a2, _b2, _c2;
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (_a2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type)).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _a2 : [];
    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (_b2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => m.type === UserFamilyMemberType.Child).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _b2 : [];
    return (_c2 = userFamilyMembers == null ? void 0 : userFamilyMembers.map((m) => ({ value: m.id, label: m.name }))) != null ? _c2 : [];
  }, [userFamilyMembers, formik.values.dependants]);
  useEffect(() => {
    formik.validateForm();
  }, []);
  const addDependants = (userId, values, insurancePolicyId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield InsuranceAPI.addDependantsToUserPolicy(userId, insurancePolicyId, {
        dependants: values.dependants,
        dependantsList: values.dependantsList,
        dependantsMonthlyPremium: values.additionalMonthlyPremium,
        employeeCoversAdditionalPremium: values.employeeCoversAdditionalPremium
      });
      yield refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: isEditForm ? polyglot.t("BenefitModule.updateDependants") : polyglot.t("BenefitModule.addDependants") }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb30 }, children: /* @__PURE__ */ jsx(UserCell, { userId: userInsurance.userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }) }) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "dependants",
        label: polyglot.t("BenefitModule.additionalMembers"),
        options: getAdditionalMembersOptions(polyglot),
        value: formik.values.dependants,
        compareValue: formik.values.dependants,
        error: !!formik.errors.dependants && formik.touched.dependants,
        onChange: (e) => {
          formik.handleChange(e);
          const value = e.target.value;
          const newAdditionalMonthlyPremium = userInsurance.monthlyPremium ? getInsuranceMultiplierValueByDependantsValue(
            userInsurance.monthlyPremium,
            value,
            insuranceAddonsMultipliers
          ) : 0;
          formik.setFieldValue("additionalMonthlyPremium", newAdditionalMonthlyPremium);
          formik.setFieldValue("dependantsList", []);
        },
        helperText: formik.errors.dependants && formik.touched.dependants
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "additionalMonthlyPremium",
        label: polyglot.t("BenefitModule.additionalMonthlyPremium"),
        value: formik.values.additionalMonthlyPremium,
        error: formik.touched.additionalMonthlyPremium && Boolean(formik.errors.additionalMonthlyPremium),
        helperText: formik.touched.additionalMonthlyPremium && formik.errors.additionalMonthlyPremium,
        disabled: true
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb30 }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "employeeCoversAdditionalPremium",
        label: polyglot.t("BenefitModule.employeeCoversAdditionalPremium"),
        checked: formik.values.employeeCoversAdditionalPremium,
        onChange: formik.handleChange
      }
    ) }),
    userInsurance.monthlyPremium && /* @__PURE__ */ jsxs(Box, { sx: fieldSx, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mb: spacing.mb5 }), children: polyglot.t("BenefitModule.employeeMonthlyPremium") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: formatMoney({
        amount: userInsurance.monthlyPremium + getInsuranceMultiplierValueByDependantsValue(
          userInsurance.monthlyPremium,
          formik.values.dependants,
          insuranceAddonsMultipliers
        )
      }) })
    ] }),
    userInsurance.monthlyPremium && /* @__PURE__ */ jsxs(Box, { sx: fieldSx, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mb: spacing.mb5 }), children: polyglot.t("BenefitModule.employeeMonthlyContribution") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: formatMoney({
        amount: ((_d = userInsurance.monthlyContribution) != null ? _d : 0) + (formik.values.employeeCoversAdditionalPremium ? getInsuranceMultiplierValueByDependantsValue(
          userInsurance.monthlyPremium,
          formik.values.dependants,
          insuranceAddonsMultipliers
        ) : 0)
      }) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: fieldSx, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mb: spacing.mb5 }), children: polyglot.t("BenefitModule.annualValueOfBenefit") }),
      userInsurance.startDate && userInsurance.monthlyPremium && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: formatMoney({
        amount: calculateAnnualValueOfTheBenefitForCurrentTaxYear(
          new Date(userInsurance.startDate),
          userInsurance.monthlyPremium,
          (_e = userInsurance.monthlyContribution) != null ? _e : 0,
          Number(formik.values.additionalMonthlyPremium),
          formik.values.employeeCoversAdditionalPremium ? Number(formik.values.additionalMonthlyPremium) : 0
        )
      }) })
    ] }),
    userFamilyMembers.length > 0 && formik.values.dependants && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mb: spacing.m10, mt: spacing.m10 }), children: polyglot.t("BenefitModule.dependantsDetails") }),
    formik.values.dependants && dependantsListOptions.length === 0 && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Red, mb: spacing.m10 }), children: polyglot.t("BenefitModule.noFamilyMembersFound") }),
    userFamilyMembers.length > 0 && formik.values.dependants && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mb: spacing.m30 }), children: /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        label: polyglot.t("BenefitModule.additionalMembersList"),
        id: "dependantsList",
        limitTags: -1,
        options: dependantsListOptions,
        value: selectedDependantsList,
        onChange: (_, values) => {
          const updatedOptionList = values.map(({ value }) => value);
          formik.setFieldValue("dependantsList", updatedOptionList);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: isEditForm ? polyglot.t("General.update") : polyglot.t("General.add"),
        loading,
        sizeVariant: "small",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) }) });
};
