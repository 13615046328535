"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { PlanNames, UpgradeToProModal } from "../../user/components/upgrade-to-pro-modal.component";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/app-icons/Close.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { nestErrorMessage } from "@/lib/errors";
import { copyInviteLink, getInviteLink, inviteUser } from "@/lib/users";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import {
  doesErrorRequireCompanyToIncreaseFixedSeatsLimit,
  doesErrorRequireCompanyToUpgrade
} from "@/v2/infrastructure/restrictions/restriction.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const iconSize = { width: 14, height: 14 };
export const OnboardingUserInvite = ({ user, onBackClick, onFinish }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isInviting, setIsInviting] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [seatsLimitError, setSeatsLimitError] = useState(false);
  const inviteUserAndFinish = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsInviting(true);
      yield inviteUser(user.userId, showMessage, polyglot, "onboard");
      onFinish();
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(false);
      } else if (doesErrorRequireCompanyToIncreaseFixedSeatsLimit(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(true);
      } else {
        showMessage(
          polyglot.t("OnboardingUserInvite.errorMessages.invitation", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    } finally {
      setIsInviting(false);
    }
  }), [polyglot, onFinish, showMessage, user.userId]);
  const copyInviteLinkAndFinish = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsInviting(true);
      const inviteLink = yield getInviteLink(user.userId, showMessage, polyglot, "onboard");
      if (!inviteLink) return;
      yield copyInviteLink(inviteLink, showMessage, polyglot);
      onFinish();
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(false);
      } else if (doesErrorRequireCompanyToIncreaseFixedSeatsLimit(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(true);
      } else {
        showMessage(
          polyglot.t("OnboardingUserInvite.errorMessages.copyInvite", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    } finally {
      setIsInviting(false);
    }
  }), [polyglot, onFinish, showMessage, user.userId]);
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30, position: "absolute", zIndex: 10, inset: 0, background: themeColors.white }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", width: "70%", mx: "auto" }, children: [
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", left: spacing.m50 }),
          onClick: () => onBackClick(),
          children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
        }
      ),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", right: spacing.m50 }),
          onClick: () => onFinish(),
          children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden auto", pb: spacing.p30 }, children: /* @__PURE__ */ jsxs(Stack, { sx: { width: "400px", maxWidth: "90vw", mx: "auto", mt: spacing.mt40 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mb: spacing.mb40 }), children: polyglot.t("OnboardingUserInvite.inviteMessage", { firstName: user.firstName, lastName: user.lastName }) }),
      /* @__PURE__ */ jsxs(Stack, { sx: { display: "flex", flexFlow: "row", gap: spacing.g20 }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            disabled: isInviting,
            colorVariant: "secondary",
            sizeVariant: "large",
            fullWidth: true,
            type: "button",
            onClick: () => onFinish(),
            children: polyglot.t("OnboardingUserInvite.saveDraft")
          }
        ),
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            loading: isInviting,
            options: [
              {
                handler: () => __async(void 0, null, function* () {
                  return yield inviteUserAndFinish();
                }),
                label: polyglot.t("OnboardingUserInvite.invite"),
                disabled: false
              },
              {
                handler: () => __async(void 0, null, function* () {
                  return yield copyInviteLinkAndFinish();
                }),
                label: polyglot.t("General.copyInvite"),
                disabled: false
              }
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "primary",
              sizeVariant: "large",
              title: polyglot.t("General.invite"),
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end",
              fullWidth: true
            },
            sx: { width: "100%" }
          }
        )
      ] })
    ] }) }),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: setUpgradeModalOpen,
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proPeople",
        seatsLimitError
      }
    )
  ] });
};
