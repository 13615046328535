"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ApprovalRuleEndpoints } from "@v2/feature/approval-rule/approval-rule.api";
import { useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { PEOPLE_ME_ACTION_ITEMS } from "@/lib/routes";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { RequestFormComponent } from "@/v2/feature/requests/features/request-forms/components/request-form.component";
import { RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SubmitRequestPage = () => {
  var _a;
  const { data: approvalRules } = useApiClient(ApprovalRuleEndpoints.getApprovalRules(true), { suspense: false });
  const [formTemplate, setFormTemplate] = useState();
  const { id: formIdValue } = useParams();
  const { data: forms, error } = useApiClient(RequestFormsEndpoints.getFormTemplates(), { suspense: false });
  useEffect(() => {
    if (!forms) return;
    const formId = Number(formIdValue);
    const form = forms.find((f) => f.id === formId);
    setFormTemplate(form != null ? form : "not-found");
  }, [formIdValue, forms]);
  if (formTemplate && formTemplate !== "not-found") {
    return /* @__PURE__ */ jsx(SubmitRequestPageLayout, { approvalRules: approvalRules != null ? approvalRules : [], formTemplate });
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    forms === void 0 && /* @__PURE__ */ jsx(LoadingSpinner, {}),
    error && /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: (_a = error.data) == null ? void 0 : _a.message }) }),
    formTemplate === "not-found" && /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Form not found" }) })
  ] });
};
const SubmitRequestPageLayout = ({
  formTemplate,
  approvalRules
}) => {
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(ContentWrapper, { children: /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: "flex",
        overflowY: "hidden",
        flexDirection: "column",
        gap: spacing.s2,
        maxWidth: "500px",
        width: "100%",
        margin: "0 auto"
      },
      children: /* @__PURE__ */ jsx(
        RequestFormComponent,
        {
          template: formTemplate,
          onSubmit: (requiresApproval) => {
            if (requiresApproval) {
              showMessage("Your request has been submitted for approval.", "success");
            } else {
              showMessage("Your request has been saved.", "success");
            }
          },
          onClose: () => {
            routerHistory.push(PEOPLE_ME_ACTION_ITEMS);
          },
          approvalRules: approvalRules != null ? approvalRules : []
        }
      )
    }
  ) }) });
};
