"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { StyledSwipeableDrawer } from "@/v2/styles/sidebar-edit.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollGeneralSettingsSection = ({ payroll, refreshPayroll, sx }) => {
  const { polyglot } = usePolyglot();
  const [showGeneralEditDrawer, setShowGeneralEditDrawer] = useState(false);
  const [editedDisplayName, setEditedDisplayName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    if (showGeneralEditDrawer) {
      setEditedDisplayName(payroll.displayName);
    }
  }, [showGeneralEditDrawer, payroll.displayName]);
  const saveGeneralSettings = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsSaving(true);
      yield PayrollAPI.setGeneralSettings(payroll.id, { displayName: editedDisplayName });
      yield refreshPayroll();
      setShowGeneralEditDrawer(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setIsSaving(false);
    }
  }), [editedDisplayName, payroll.id, polyglot, refreshPayroll, showMessage]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx, children: [
      /* @__PURE__ */ jsx(PayrollSettingSectionHeader, { showEditButton: true, onEditClick: () => setShowGeneralEditDrawer(true), children: "General" }),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "inline-grid",
            gridTemplateColumns: "1fr 3fr",
            rowGap: spacing.g10,
            columnGap: spacing.g20,
            mt: spacing.mt10
          },
          children: [
            /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { whiteSpace: "nowrap" }, children: "Entity" }),
              /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: payroll.entity.legalName })
            ] }),
            /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { whiteSpace: "nowrap" }, children: "Display name" }),
              /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: payroll.displayName })
            ] })
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      StyledSwipeableDrawer,
      {
        anchor: "right",
        open: showGeneralEditDrawer,
        onOpen: () => {
        },
        onClose: () => setShowGeneralEditDrawer(false),
        children: showGeneralEditDrawer && /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ width: "400px", padding: "20px 30px" }, drawerContentSx), children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "General" }),
          /* @__PURE__ */ jsx(TextfieldComponent, { label: "Entity", disabled: true, value: payroll.entity.legalName }),
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: "Display name",
              value: editedDisplayName,
              onChange: (e) => setEditedDisplayName(e.target.value),
              autoFocus: true,
              disabled: isSaving
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "primary",
              fullWidth: true,
              onClick: () => saveGeneralSettings(),
              loading: isSaving,
              children: polyglot.t("General.save")
            }
          ) })
        ] })
      }
    )
  ] });
};
