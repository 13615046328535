"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { AppleDevices, DeviceModelOs, SupportedDeviceType } from "@v2/feature/device/device.interface";
import { DeviceEncryptionPolicyTile } from "@v2/feature/device/features/device-cards/components/device-encryption-policy-tile.component";
import { DevicePolicyTile } from "@v2/feature/device/features/device-cards/components/device-policy-tile.component";
import { ActivationLockPolicyViewDrawer } from "@v2/feature/device/features/device-cards/components/inhouse-mdm-activation-lock-policy-viewer.component";
import { EncryptionPolicyViewDrawer } from "@v2/feature/device/features/device-cards/components/inhouse-mdm-encryption-policy-viewer.component";
import { LoginItemPolicyViewDrawer } from "@v2/feature/device/features/device-cards/components/inhouse-mdm-login-item-policy-viewer.component";
import { SafariBrowsingPolicyViewDrawer } from "@v2/feature/device/features/device-cards/components/inhouse-mdm-safari-browsing-policy-viewer.component";
import { WifiPolicyViewDrawer } from "@v2/feature/device/features/device-cards/components/inhouse-mdm-wifi-policy-viewer.component";
import { GeneralPolicyViewDrawer } from "@v2/feature/device/features/device-cards/components/mdm-general-policy-viewer.component";
import { OsUpdatesPolicyViewDrawer } from "@v2/feature/device/features/device-cards/components/mdm-os-updates-policy-viewer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceProtection = ({
  devicePossession,
  appliedPolicies
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const device = devicePossession.device;
  const isApple = Boolean(
    devicePossession && devicePossession.device && devicePossession.device.os && AppleDevices.includes((_b = (_a = devicePossession.device) == null ? void 0 : _a.os) == null ? void 0 : _b.toLowerCase())
  );
  const isWindows = Boolean(
    devicePossession && devicePossession.device && devicePossession.device.os && DeviceModelOs && DeviceModelOs.Windows === ((_d = (_c = devicePossession.device) == null ? void 0 : _c.os) == null ? void 0 : _d.toLowerCase())
  );
  const [isPasscodeOpen, setIsPasscodeOpen] = useState(false);
  const [isEncryptionOpen, setIsEncryptionOpen] = useState(false);
  const [isFirewallOpen, setIsFirewallOpen] = useState(false);
  const [isAdditionalPasswordSecurityOpen, setIsAdditionalPasswordSecurityOpen] = useState(false);
  const [isScreenPolicyOpen, setIsScreenPolicyOpen] = useState(false);
  const [isSecurityPolicyOpen, setIsSecurityPolicyOpen] = useState(false);
  const [isBrowsingPolicyOpen, setIsBrowsingPolicyOpen] = useState(false);
  const [isOsUpdatePolicyOpen, setIsOsUpdatePolicyOpen] = useState(false);
  const [isActivationLockPolicyOpen, setIsActivationLockPolicyOpen] = useState(false);
  const [isWifiOpen, setIsWifiOpen] = useState(false);
  const [isLoginItemsOpen, setIsLoginItemsOpen] = useState(false);
  const { data } = useApiClient(DeviceEndpoints.getAllAvailableDevicePolicies(), {
    suspense: false
  });
  const isSupportedPolicyByHexnodeDevice = (policy) => {
    if (data && data.length > 0) {
      const supportedDeviceType = isWindows ? SupportedDeviceType.windows : SupportedDeviceType.android;
      const selectedPolicy = data.filter((p) => {
        var _a2;
        return ((_a2 = p == null ? void 0 : p.supportedDeviceType) == null ? void 0 : _a2.toString()) === supportedDeviceType.toString();
      }).find((p) => policy.includes(p == null ? void 0 : p.id));
      return !!selectedPolicy;
    }
    return false;
  };
  const getObjectSize = (obj) => {
    if (isApple) {
      return obj ? Object.keys(obj).length - 1 : 0;
    }
    return 1;
  };
  return device && appliedPolicies ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Box, { component: "header", sx: { width: "100%", display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Configuration" }),
    (isApple && appliedPolicies.configurablePasscode || !isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.passcode)) && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Passcode policy",
        status: device.passwordProtected ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurablePasscode),
        onClick: () => {
          setIsPasscodeOpen(true);
        }
      }
    ),
    (isApple && appliedPolicies.configurableEncryptionPolicy || !isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.encryption)) && /* @__PURE__ */ jsx(
      DeviceEncryptionPolicyTile,
      {
        policyName: "Encryption policy",
        status: device.encryptionEnabled ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableEncryptionPolicy),
        onClick: () => {
          setIsEncryptionOpen(true);
        },
        filevaultKeyStatus: isApple ? !!device.filevaultKey : null
      }
    ),
    (isApple && appliedPolicies.configurableFirewallPolicy || !isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.firewall)) && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Firewall policy",
        status: device.firewallEnabled ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableFirewallPolicy),
        onClick: () => {
          setIsFirewallOpen(true);
        }
      }
    ),
    isApple && appliedPolicies.configurableAdditionalPasswordPolicy && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Additional password security policy",
        status: device.additionalPasswordEnabled ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableAdditionalPasswordPolicy),
        onClick: () => {
          setIsAdditionalPasswordSecurityOpen(true);
        }
      }
    ),
    isApple && appliedPolicies.configurableScreenSettingPolicy && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Screen policy",
        status: device.screenLoginSettingsEnabled ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableScreenSettingPolicy),
        onClick: () => {
          setIsScreenPolicyOpen(true);
        }
      }
    ),
    isApple && appliedPolicies.configurableSecurity && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Apps and features restrictions policy",
        status: device.securityEnabled ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableSecurity),
        onClick: () => {
          setIsSecurityPolicyOpen(true);
        }
      }
    ),
    isApple && appliedPolicies.configurableBrowsing && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Safari browsing policy",
        status: device.browsingEnabled ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableBrowsing),
        onClick: () => {
          setIsBrowsingPolicyOpen(true);
        }
      }
    ),
    (isApple && appliedPolicies.configurableAutomaticOsUpdate || !isApple && isSupportedPolicyByHexnodeDevice(appliedPolicies.updates)) && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "OS update policy",
        status: (isApple ? appliedPolicies.configurableAutomaticOsUpdate : appliedPolicies.updates[0] !== 0) ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableAutomaticOsUpdate),
        onClick: () => {
          setIsOsUpdatePolicyOpen(true);
        }
      }
    ),
    isApple && appliedPolicies.configurableActivationLock && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Activation lock policy",
        status: device.activationLockBypassKey ? "Installed" : "Missing",
        settingsNumber: 1,
        onClick: () => {
          setIsActivationLockPolicyOpen(true);
        }
      }
    ),
    isApple && appliedPolicies.configurableWifi && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "WiFi policy",
        status: device.wifiEnabled ? "Installed" : "Missing",
        settingsNumber: getObjectSize(appliedPolicies.configurableWifi),
        onClick: () => {
          setIsWifiOpen(true);
        }
      }
    ),
    isApple && appliedPolicies.configurableFirewallPolicy && /* @__PURE__ */ jsx(
      DevicePolicyTile,
      {
        policyName: "Open at login policy",
        status: device.loginItemsEnabled ? "Installed" : "Missing",
        settingsNumber: (_g = (_f = (_e = appliedPolicies.configurableLoginItems) == null ? void 0 : _e.autoLaunchedApplicationDictionaryManaged) == null ? void 0 : _f.length) != null ? _g : 0,
        onClick: () => {
          setIsLoginItemsOpen(true);
        }
      }
    ),
    /* @__PURE__ */ jsx(
      GeneralPolicyViewDrawer,
      {
        policyName: "Passcode policy",
        isOpen: isPasscodeOpen,
        onClose: () => {
          setIsPasscodeOpen(false);
        },
        setIsOpen: setIsPasscodeOpen,
        configurationPayloadOption: appliedPolicies.configurablePasscode,
        policy: appliedPolicies.passcode,
        isApple,
        isWindows,
        allPolicies: data
      }
    ),
    /* @__PURE__ */ jsx(
      EncryptionPolicyViewDrawer,
      {
        policyName: "Encryption policy",
        isOpen: isEncryptionOpen,
        onClose: () => {
          setIsEncryptionOpen(false);
        },
        setIsOpen: setIsEncryptionOpen,
        configurationPayloadOption: appliedPolicies.configurableEncryptionPolicy,
        policy: appliedPolicies.encryption,
        filevaultKey: device.filevaultKey,
        isApple,
        isWindows,
        allPolicies: data
      }
    ),
    /* @__PURE__ */ jsx(
      GeneralPolicyViewDrawer,
      {
        policyName: "Firewall policy",
        isOpen: isFirewallOpen,
        onClose: () => {
          setIsFirewallOpen(false);
        },
        setIsOpen: setIsFirewallOpen,
        configurationPayloadOption: appliedPolicies.configurableFirewallPolicy,
        policy: appliedPolicies.firewall,
        isApple,
        isWindows,
        allPolicies: data
      }
    ),
    /* @__PURE__ */ jsx(
      GeneralPolicyViewDrawer,
      {
        policyName: "Additional password security policy",
        isOpen: isAdditionalPasswordSecurityOpen,
        onClose: () => {
          setIsAdditionalPasswordSecurityOpen(false);
        },
        setIsOpen: setIsAdditionalPasswordSecurityOpen,
        configurationPayloadOption: appliedPolicies.configurableAdditionalPasswordPolicy,
        policy: null,
        isApple,
        isWindows,
        allPolicies: data
      }
    ),
    /* @__PURE__ */ jsx(
      GeneralPolicyViewDrawer,
      {
        policyName: "Screen policy",
        isOpen: isScreenPolicyOpen,
        onClose: () => {
          setIsScreenPolicyOpen(false);
        },
        setIsOpen: setIsScreenPolicyOpen,
        configurationPayloadOption: appliedPolicies.configurableScreenSettingPolicy,
        policy: null,
        isApple,
        isWindows,
        allPolicies: data
      }
    ),
    /* @__PURE__ */ jsx(
      GeneralPolicyViewDrawer,
      {
        policyName: "Apps and features restrictions policy",
        isOpen: isSecurityPolicyOpen,
        onClose: () => {
          setIsSecurityPolicyOpen(false);
        },
        setIsOpen: setIsSecurityPolicyOpen,
        configurationPayloadOption: appliedPolicies.configurableSecurity,
        policy: null,
        isApple,
        isWindows,
        allPolicies: data
      }
    ),
    /* @__PURE__ */ jsx(
      SafariBrowsingPolicyViewDrawer,
      {
        isOpen: isBrowsingPolicyOpen,
        onClose: () => {
          setIsBrowsingPolicyOpen(false);
        },
        setIsOpen: setIsBrowsingPolicyOpen,
        configurationPayloadOption: appliedPolicies.configurableBrowsing
      }
    ),
    /* @__PURE__ */ jsx(
      OsUpdatesPolicyViewDrawer,
      {
        policyName: "OS update policy",
        isOpen: isOsUpdatePolicyOpen,
        onClose: () => {
          setIsOsUpdatePolicyOpen(false);
        },
        setIsOpen: setIsOsUpdatePolicyOpen,
        configurationPayloadOption: appliedPolicies.configurableAutomaticOsUpdate,
        isApple,
        isWindows,
        policy: appliedPolicies.updates,
        allPolicies: data
      }
    ),
    /* @__PURE__ */ jsx(
      WifiPolicyViewDrawer,
      {
        policyName: "WiFi policy",
        isOpen: isWifiOpen,
        onClose: () => {
          setIsWifiOpen(false);
        },
        setIsOpen: setIsWifiOpen,
        configurationPayloadOption: appliedPolicies.configurableWifi
      }
    ),
    /* @__PURE__ */ jsx(
      LoginItemPolicyViewDrawer,
      {
        isOpen: isLoginItemsOpen,
        onClose: () => {
          setIsLoginItemsOpen(false);
        },
        setIsOpen: setIsLoginItemsOpen,
        configurationPayloadOption: appliedPolicies.configurableLoginItems
      }
    ),
    /* @__PURE__ */ jsx(
      ActivationLockPolicyViewDrawer,
      {
        policyName: "Activation lock policy",
        isOpen: isActivationLockPolicyOpen,
        onClose: () => {
          setIsActivationLockPolicyOpen(false);
        },
        setIsOpen: setIsActivationLockPolicyOpen,
        configurationPayloadOption: appliedPolicies.configurableActivationLock
      }
    )
  ] }) }) : /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "No policies applied" }) });
};
