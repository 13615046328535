"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { ChipSelect } from "@v2/components/table/chip-select.component";
import { ColumnsDrawer } from "@v2/components/table/columns-drawer.component";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ResultTableOldReports } from "@v2/feature/reports/components/result-table.component";
import { ReportsAPI } from "@v2/feature/reports/reports.api";
import { ReportEntity } from "@v2/feature/reports/reports.interface";
import { exportReportCSVOldReports, extractAllColumnsFromColumnsConfig } from "@v2/feature/reports/util/report.util";
import { TimeBalancesReportColumnsOptions } from "@v2/feature/reports/util/time-report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { primarySmallBtn, secondarySmallBtn } from "@v2/styles/buttons.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_OVERVIEW } from "@/lib/routes";
export const TimeReportBalancesSection = ({ report, loading, filters }) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getAbsencePolicies(), {
    suspense: false
  });
  const [filteredData, setFilteredData] = useState(void 0);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState(
    void 0
  );
  const [filterString, setFilterString] = useState((_b = (_a = report == null ? void 0 : report.config) == null ? void 0 : _a.filters) != null ? _b : "");
  const [selectedColumns, setSelectedColumns] = useState(
    ((_c = report == null ? void 0 : report.config) == null ? void 0 : _c.columns) ? extractAllColumnsFromColumnsConfig(report.config.columns) : []
  );
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [reportLoading, setReportsLoading] = useState(true);
  const [reportName, setReportName] = useState((_d = report == null ? void 0 : report.name) != null ? _d : "");
  const [searchInput, setSearchInput] = useState("");
  const [selectedPolicy, setsSelectedPolicy] = useState();
  const savedPolicy = useMemo(() => {
    var _a2;
    if (!absencePolicies) return void 0;
    return (_a2 = absencePolicies == null ? void 0 : absencePolicies.find((p) => {
      var _a3;
      return p.id === ((_a3 = report.config) == null ? void 0 : _a3.singleNumericFilter1);
    })) != null ? _a2 : absencePolicies[0];
  }, [absencePolicies, report]);
  useEffect(() => {
    setsSelectedPolicy(savedPolicy);
  }, [savedPolicy]);
  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData == null ? void 0 : filteredData.filter(
        (data) => !searchInput || (data == null ? void 0 : data.employeeName) && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, filteredData]);
  const getReportData = useCallback(
    (report2) => __async(void 0, null, function* () {
      if (!selectedPolicy) return;
      setReportsLoading(true);
      try {
        const paramObj = __spreadProps(__spreadValues({}, report2.config), {
          filters: filterString,
          columns: { [ReportEntity.Balances]: selectedColumns },
          singleNumericFilter1: selectedPolicy.id
        });
        const filteredTimeBalances = yield ReportsAPI.generateTimeCreatedReport(paramObj);
        setFilteredData(filteredTimeBalances[ReportEntity.Balances]);
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setReportsLoading(false);
      }
    }),
    [selectedColumns, filterString, showMessage, selectedPolicy]
  );
  useEffect(() => {
    if (report) getReportData(report);
  }, [getReportData, report]);
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!report || !selectedPolicy) return;
      const paramObj = {
        filters: filterString,
        columns: { [ReportEntity.Balances]: selectedColumns },
        fileName: reportName != null ? reportName : report.name,
        singleNumericFilter1: selectedPolicy.id
      };
      yield ReportsAPI.patchReport(report.id, paramObj);
      showMessage(polyglot.t("TimeReportBalancesSection.successMessages.update"), "success");
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  }), [polyglot, filterString, report, reportName, routerHistory, selectedColumns, selectedPolicy, showMessage]);
  const exportCsv = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!selectedPolicy || !filteredData) return;
      exportReportCSVOldReports(
        reportName,
        filteredData ? { [ReportEntity.Balances]: filteredData } : void 0,
        `report-Balances-${selectedPolicy.name}.csv`
      );
    } catch (e) {
      showMessage(polyglot.t("TimeReportBalancesSection.errorMessages.download"), "error");
    }
  }), [polyglot, selectedPolicy, filteredData, reportName, showMessage]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        showBack: true,
        title: reportName ? /* @__PURE__ */ jsx(
          EditableTitle,
          {
            variant: "headline1",
            value: reportName,
            onChange: (value) => {
              setReportName(value);
            },
            maxLength: 50,
            editButtonTooltip: void 0
          }
        ) : /* @__PURE__ */ jsx(Fragment, {}),
        showAction: true,
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(Button, { fullWidth: true, sx: secondarySmallBtn, startIcon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)), onClick: exportCsv, children: polyglot.t("TimeReportBalancesSection.downloadCsv") }),
          /* @__PURE__ */ jsx(Button, { sx: primarySmallBtn, onClick: handleSave, children: polyglot.t("General.save") })
        ] })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { pt: 0 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", mt: spacing.m20, alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(
          ChipSelect,
          {
            title: (_e = selectedPolicy == null ? void 0 : selectedPolicy.name) != null ? _e : polyglot.t("TimeReportBalancesSection.select"),
            options: (_f = absencePolicies == null ? void 0 : absencePolicies.map((p) => ({
              label: p.name,
              handler: () => setsSelectedPolicy(p)
            }))) != null ? _f : []
          }
        ),
        /* @__PURE__ */ jsx(
          ColumnsDrawer,
          {
            columnsOptions: TimeBalancesReportColumnsOptions(polyglot),
            selectedColumns,
            setSelectedColumns
          }
        ),
        /* @__PURE__ */ jsx(
          FiltersDrawer,
          {
            filtersOptions: [{ filters }],
            selectedFilters: filterString,
            setSelectedFilters: setFilterString,
            encodedFilterNames: true
          }
        ),
        /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
          var _a2, _b2;
          return setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
        } })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m20 }, children: /* @__PURE__ */ jsx(ResultTableOldReports, { filteredData: filteredAndSearchedData != null ? filteredAndSearchedData : [], loading: reportLoading }) })
    ] })
  ] });
};
