"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownloadIcon } from "@/images/icons/download-icon.svg";
import { ReactComponent as UploadIcon } from "@/images/icons/Upload.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { InPayrunAttendanceEntriesWithoutPaycodeWarning } from "@/v2/feature/payroll/components/in-payrun-attendance-entries-without-paycode-warning.component";
import { SideMenuHeader } from "@/v2/feature/payroll/components/side-menu-header.component";
import { formatPayrunPeriod } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { GlobalPayrollTable } from "@/v2/feature/payroll/features/payroll-global/components/global-payroll-table.component";
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints
} from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { ImportPayrunDrawer } from "@/v2/feature/payroll/features/payroll-global/global-payrun-flow/import-payrun-drawer.component";
import { PayrunHeader } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-header.component";
import { navigateToPayrunsView } from "@/v2/feature/payroll/payroll-router.util";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { CompanyPayrollType } from "@/v2/feature/payroll/payroll.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GlobalPayrunPage = ({ payroll, taxYear, payPeriod, period }) => {
  var _a, _b, _c, _d;
  const routerHistory = useHistory();
  const [isFinalising, setIsFinalising] = useState(false);
  const [showMessage] = useMessage();
  const [showImportDrawer, setShowImportDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { mutate: refreshPayrollM } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), {
    suspense: false,
    revalidateOnMount: false
  });
  const { data: payrun, mutate: refreshPayrunM, isValidating: isRefreshingPayrun } = useApiClient(
    GlobalPayrollEndpoints.getPayrun(payroll.id, taxYear, payPeriod, period),
    {
      suspense: false
    }
  );
  const refreshPayroll = useCallback(() => __async(void 0, null, function* () {
    if (refreshPayrollM) yield refreshPayrollM();
  }), [refreshPayrollM]);
  const refreshPayrun = useCallback(() => __async(void 0, null, function* () {
    if (refreshPayrunM) yield refreshPayrunM();
  }), [refreshPayrunM]);
  const lockPayrun = useCallback(() => __async(void 0, null, function* () {
    setIsFinalising(true);
    try {
      yield GlobalPayrollAPI.finalisePayrun(payroll.id, taxYear, payPeriod, period);
      yield refreshPayroll();
      navigateToPayrunsView(routerHistory, "replace", payroll.id, payrun == null ? void 0 : payrun.id);
    } catch (error) {
      showMessage(error.message, "error");
      setIsFinalising(false);
    }
  }), [payroll.id, taxYear, payPeriod, period, refreshPayroll, routerHistory, payrun == null ? void 0 : payrun.id, showMessage]);
  const entityName = (_b = (_a = payroll.entity) == null ? void 0 : _a.legalName) != null ? _b : "";
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, flexFlow: "row", alignItems: "top", gap: spacing.g60, overflow: "hidden" }, children: [
    /* @__PURE__ */ jsx(
      SideMenuHeader,
      {
        heading: formatPayrunPeriod({
          payPeriod,
          period,
          taxYear,
          taxYearStart: (_d = (_c = payroll.schedule) == null ? void 0 : _c.taxYearStart) != null ? _d : "01-01"
        }),
        caption: entityName,
        onBackClick: () => {
          navigateToPayrunsView(routerHistory, "replace", payroll.id, payrun == null ? void 0 : payrun.id);
        },
        sx: { mt: "20px", ml: "20px" }
      }
    ),
    /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, overflowY: "auto" }, children: payrun && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g20, mr: spacing.mr40, height: "100%" }, children: [
      /* @__PURE__ */ jsx(
        PayrunHeader,
        {
          payrollId: payroll.id,
          payrollType: CompanyPayrollType.GlobalPayroll,
          entityName,
          state: payrun.state,
          onReopenPayrun: () => __async(void 0, null, function* () {
            yield Promise.all([refreshPayroll(), refreshPayrun()]);
          }),
          sx: { position: "sticky", top: 0, zIndex: 2, background: themeColors.white, py: spacing.p15 }
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            height: "100%",
            flexGrow: 1,
            paddingBottom: spacing.p10,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column"
          },
          children: [
            /* @__PURE__ */ jsxs(Stack, { sx: { flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Review and edit pay for employees" }),
              /* @__PURE__ */ jsxs(Stack, { sx: { flexDirection: "row", alignItems: "center", gap: spacing.g10 }, children: [
                payrun.state === "DRAFT" && /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    sizeVariant: "small",
                    colorVariant: "secondary",
                    startIcon: /* @__PURE__ */ jsx(UploadIcon, __spreadValues({}, iconSize)),
                    onClick: () => setShowImportDrawer(true),
                    disabled: showImportDrawer || isFinalising,
                    children: "Import"
                  }
                ),
                /* @__PURE__ */ jsx(
                  "a",
                  {
                    style: { color: "unset", textDecoration: "unset" },
                    href: GlobalPayrollEndpoints.exportPayrun(payroll.id, taxYear, payPeriod, period).url,
                    children: /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        sizeVariant: "small",
                        colorVariant: "secondary",
                        startIcon: /* @__PURE__ */ jsx(DownloadIcon, __spreadValues({}, iconSize)),
                        disabled: showImportDrawer || isFinalising,
                        children: "Export"
                      }
                    )
                  }
                )
              ] })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m10 }, children: [
              /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
              /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.m20, gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
                InPayrunAttendanceEntriesWithoutPaycodeWarning,
                {
                  payrunId: payrun.id,
                  payrollType: "Global",
                  payrollId: payroll.id
                }
              ) })
            ] }),
            /* @__PURE__ */ jsx(
              GlobalPayrollTable,
              {
                payrun,
                searchQuery,
                allowEdit: payrun.state === "DRAFT",
                refreshPayrun,
                stickyHeaderHeight: "100%",
                containerStyle: { flex: 1, height: 0 },
                tableStyle: { maxHeight: "90%" }
              }
            ),
            payrun.state === "DRAFT" && !isRefreshingPayrun && /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.m10, mb: spacing.m10, alignItems: "flex-end" }, children: /* @__PURE__ */ jsx(
              LoaderButton,
              {
                type: "button",
                name: "Lock payrun",
                loading: isFinalising,
                onClick: () => __async(void 0, null, function* () {
                  yield lockPayrun();
                }),
                sizeVariant: "large",
                colorVariant: "primary",
                fullWidth: true,
                style: { width: "fit-content" }
              }
            ) })
          ]
        }
      )
    ] }) }),
    payrun && /* @__PURE__ */ jsx(
      ImportPayrunDrawer,
      {
        isOpen: showImportDrawer,
        setIsOpen: setShowImportDrawer,
        payrun,
        onImportSuccess: () => __async(void 0, null, function* () {
          yield refreshPayrun();
          setShowImportDrawer(false);
          showMessage("Payrun updated", "success");
        }),
        onUploadFailed: () => {
          setShowImportDrawer(false);
          showMessage("File upload failed.", "error");
        }
      }
    )
  ] });
};
