"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ActiveShiftsHeaderSkeleton = () => {
  return /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", gap: spacing.g40 }, children: [0, 1].map((i) => /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
    /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "68px",
        height: "21px",
        sx: {
          background: themeColors.Background,
          borderRadius: radius.br10
        }
      }
    ),
    /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "136px",
        height: "31px",
        sx: {
          background: themeColors.Background,
          borderRadius: radius.br15
        }
      }
    )
  ] }, i)) });
};
