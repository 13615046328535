"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownArrow } from "@/images/fields/DownArrow.svg";
import { ReactComponent as UpArrow } from "@/images/fields/UpArrow.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowLeft } from "@/images/side-bar-icons/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "@/images/side-bar-icons/ArrowRight.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewQuestionAPI } from "@/v2/feature/growth/reviews/api-client/review-question.api";
import { iconSize } from "@/v2/styles/menu.styles";
export const useCycleQuestionOptions = (question, setQuestion, setIsQuestionModalOpen, setIsMoveToSectionOpen, cycle, refresh, hasSections) => {
  const { id: cycleId } = cycle;
  const [showMessage] = useMessage();
  const currentOrder = useMemo(() => cycle.order ? [...cycle.order] : [], [cycle]);
  const moveQuestion = useCallback(
    (direction) => __async(void 0, null, function* () {
      if (!cycle) return;
      const questionIndex = currentOrder.findIndex((o) => o.type === "question" && o.id === question.id);
      if (questionIndex < 0) return;
      const newOrder = [...currentOrder];
      if (direction === "up" && questionIndex > 0) {
        [newOrder[questionIndex - 1], newOrder[questionIndex]] = [newOrder[questionIndex], newOrder[questionIndex - 1]];
      } else if (direction === "down" && questionIndex < newOrder.length - 1) {
        [newOrder[questionIndex], newOrder[questionIndex + 1]] = [newOrder[questionIndex + 1], newOrder[questionIndex]];
      }
      try {
        yield ReviewCycleAPI.updateOrderReviewCycle({ cycleId, order: newOrder });
        yield refresh == null ? void 0 : refresh();
        showMessage(`Successfully moved question ${direction}`, "success");
      } catch (error) {
        showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [cycleId, cycle, currentOrder, question, refresh, showMessage]
  );
  const editHandler = useCallback(() => {
    setQuestion(question);
    setIsQuestionModalOpen(true);
  }, [question, setQuestion, setIsQuestionModalOpen]);
  const deleteHandler = useCallback(() => __async(void 0, null, function* () {
    try {
      yield ReviewQuestionAPI.deleteReviewQuestion(question.id, cycleId);
      refresh();
      setQuestion(null);
      showMessage("Successfully deleted", "success");
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage, setQuestion, question == null ? void 0 : question.id, cycleId, refresh]);
  const moveToSectionHandler = useCallback(() => {
    setIsMoveToSectionOpen(true);
    setQuestion(question);
  }, [setIsMoveToSectionOpen, setQuestion, question]);
  const moveOutOfSectionHandler = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!cycle || !question.sectionId) return;
      yield ReviewCycleAPI.moveQuestionOutsideSection({
        cycleId,
        sectionId: question.sectionId,
        questionId: question.id
      });
      yield refresh();
      showMessage("Successfully moved the question outside the section", "success");
    } catch (error) {
      showMessage(`Error moving question outside section: ${nestErrorMessage(error)}`, "error");
    }
  }), [cycle, question.sectionId, question.id, cycleId, refresh, showMessage]);
  return useMemo(() => {
    var _a;
    const questionIndex = currentOrder.findIndex((o) => o.type === "question" && o.id === question.id);
    const isQuestionFirst = questionIndex === 0 || questionIndex > 0 && ((_a = currentOrder[questionIndex - 1]) == null ? void 0 : _a.type) === "section";
    const isQuestionLast = questionIndex === currentOrder.length - 1;
    return [
      { icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)), handler: editHandler, label: "Edit", disabled: false },
      ...!(question == null ? void 0 : question.sectionId) ? [
        {
          icon: /* @__PURE__ */ jsx(UpArrow, __spreadValues({}, iconSize)),
          handler: () => moveQuestion("up"),
          label: "Move up",
          disabled: isQuestionFirst
        },
        {
          icon: /* @__PURE__ */ jsx(DownArrow, __spreadValues({}, iconSize)),
          handler: () => moveQuestion("down"),
          label: "Move down",
          disabled: isQuestionLast
        },
        {
          icon: /* @__PURE__ */ jsx(ArrowRight, __spreadValues({}, iconSize)),
          handler: moveToSectionHandler,
          label: "Move inside section",
          disabled: !hasSections
        }
      ] : [
        {
          icon: /* @__PURE__ */ jsx(ArrowLeft, __spreadValues({}, iconSize)),
          handler: moveOutOfSectionHandler,
          label: "Move outside section",
          disabled: false
        }
      ],
      { icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)), handler: deleteHandler, label: "Delete", disabled: false }
    ];
  }, [
    moveQuestion,
    editHandler,
    deleteHandler,
    currentOrder,
    question,
    hasSections,
    moveOutOfSectionHandler,
    moveToSectionHandler
  ]);
};
