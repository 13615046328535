"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { AppIntegrationAPI, AppIntegrationEndpoints } from "@v2/feature/app-integration/app-integration.api";
import { AppDetailsAPI } from "@v2/feature/app-integration/features/app-details/app-details.api";
import { AppBasicAuthConnectDrawer } from "@v2/feature/app-integration/features/app-details/components/app-basic-auth-connect-drawer.component";
import {
  LastpassAccountIdInstructions,
  LastpassInfoCard,
  LastpassProvHashInstructions
} from "@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-lastpass.component";
import { MondayApiKeyInstructions } from "@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-monday.component";
import {
  NotionInfoCard,
  NotionInstallInstructions
} from "@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-notion.component";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, APPS_COMPANY_ROUTE } from "@/lib/routes";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import {
  APPS_WITH_CC_INVITE,
  APPS_WITH_DOMAIN_NAME_CALENDAR_CONFIGURATION
} from "@/v2/feature/app-integration/app-integration.interface";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
import { AppCalendarConfiguration } from "@/v2/feature/app-integration/features/app-details/components/app-calendar-configuration.component";
import { AppCCForNewAccounts } from "@/v2/feature/app-integration/features/app-details/components/app-cc-for-new-accounts.component";
import { JumpcloudApiKeyInstructions } from "@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-jumpcloud.component";
import { AppAutomaticOnboardingConfiguration } from "@/v2/feature/app-integration/features/app-details/components/app-details-automatic-onboarding-configuration.component";
import { AppAutomaticOnboardingViaWebhook } from "@/v2/feature/app-integration/features/app-details/components/app-details-automatic-onboarding-via-webhook.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { FieldStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { definitionTermSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { OnboardingEndpoints } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { LocalDate } from "@/v2/util/local-date";
const appCredentialsInstructions = {
  notion: { tokenInstallInstructions: /* @__PURE__ */ jsx(NotionInstallInstructions, {}) },
  lastpass: {
    hashKeyInstructions: /* @__PURE__ */ jsx(LastpassProvHashInstructions, {}),
    accountIdInstructions: /* @__PURE__ */ jsx(LastpassAccountIdInstructions, {})
  },
  monday: {
    mondayApiKeyInstructions: /* @__PURE__ */ jsx(MondayApiKeyInstructions, {})
  },
  jumpcloud: {
    tokenInstallInstructions: /* @__PURE__ */ jsx(JumpcloudApiKeyInstructions, {})
  }
};
const appInfoCards = {
  notion: /* @__PURE__ */ jsx(NotionInfoCard, {}),
  lastpass: /* @__PURE__ */ jsx(LastpassInfoCard, {})
};
const REMOVE_TENANT_CONFIRM = `Are you sure you want to remove this tenant?`;
export const AppUserSettingPage = () => {
  var _a;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const [showMessage] = useMessage();
  const { appStub } = useParams();
  const [app, setApp] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [credentialsPlaceholders, setCredentialsPlaceholders] = useState(void 0);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [removeModalMode, setRemoveModalMode] = useState("deleteIntegration");
  const [tenantToDisconnect, setTenantToDisconnect] = useState(null);
  const [isQbState, setIsQbState] = useState("default");
  const [credentials, setCredentials] = useState(void 0);
  const [appDetailError, setAppDetailError] = useState(void 0);
  const [basicAuthConnectDrawerOpen, setBasicAuthConnectDrawerOpen] = useState(false);
  const { mutate: refreshUserApps } = useApiClient(AppIntegrationEndpoints.getUserApps(globalState.user.userId), {
    suspense: false
  });
  const { data: appOnboardingCheck } = useApiClient(OnboardingEndpoints.isAppCurrentlyUsedInOnboarding(appStub), {
    suspense: false
  });
  const { mutate: refreshInstalledApps } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), {
    suspense: false
  });
  const { getCachedUserById } = useCachedUsers();
  const fetchAppDetails = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b, _c, _d, _e, _f, _g;
    setLoading(true);
    try {
      const app2 = yield AppDetailsAPI.getIntegrationData(appStub);
      setApp(app2);
      setCredentialsPlaceholders((_a2 = app2 == null ? void 0 : app2.credentialsPlaceholders) != null ? _a2 : void 0);
      setLoading(false);
      setCredentials(void 0);
    } catch (error) {
      if ([401, 403].includes((_c = (_b = error.response) == null ? void 0 : _b.data) == null ? void 0 : _c.statusCode))
        setAppDetailError(`${(_e = (_d = error == null ? void 0 : error.response) == null ? void 0 : _d.data) == null ? void 0 : _e.error}: ${(_g = (_f = error == null ? void 0 : error.response) == null ? void 0 : _f.data) == null ? void 0 : _g.message}`);
      else setAppDetailError(void 0);
      setLoading(false);
      setApp(void 0);
    }
  }), [appStub]);
  useEffect(() => {
    fetchAppDetails();
  }, [fetchAppDetails]);
  const APP_IN_USE_CONFIRMATION_MESSAGE = useMemo(() => {
    const defaultMessage = polyglot.t("AppUserSettingConfirmRemoveIntegration.normalConfirmMessage");
    if (!(appOnboardingCheck == null ? void 0 : appOnboardingCheck.used)) {
      return defaultMessage;
    } else if (appOnboardingCheck.used === true) {
      return polyglot.t("AppUserSettingConfirmRemoveIntegration.appUsedInOnboardingConfirmMessage");
    }
    return defaultMessage;
  }, [appOnboardingCheck, polyglot]);
  const disconnectXeroOrganisation = useCallback(() => __async(void 0, null, function* () {
    if (tenantToDisconnect) yield AppIntegrationAPI.disconnectXeroTenant(tenantToDisconnect);
    fetchAppDetails();
    setTenantToDisconnect(null);
    setIsRemovalModalOpen(false);
  }), [fetchAppDetails, tenantToDisconnect]);
  function onDeleteApp() {
    return __async(this, null, function* () {
      try {
        yield AppIntegrationAPI.revokeToken(appStub);
        showMessage("Successfully removed app integration", "success");
        setTimeout(() => {
          refreshUserApps();
          refreshInstalledApps();
        }, 1300);
        setTimeout(() => routerHistory.push(APPS_COMPANY_ROUTE), 1500);
      } catch (error) {
        showMessage(`${nestErrorMessage(error)}`, "error");
      }
    });
  }
  function isConfigured() {
    return !(app == null ? void 0 : app.requiredConfiguration) || (app == null ? void 0 : app.requiredConfiguration) && (app == null ? void 0 : app.configuration);
  }
  const modeForOnboardingConfig = useMemo(() => {
    if (app == null ? void 0 : app.allowsCandidateManagement) return "candidate";
    if (app == null ? void 0 : app.allowsEmploymentManagement) return "employment";
    return void 0;
  }, [app]);
  const editBasicAuth = () => {
    setBasicAuthConnectDrawerOpen(true);
  };
  const connectAccountingApp = useCallback((appStub2) => {
    window.location.href = AppIntegrationAPI.accountingAppInitiateTenantConnection(appStub2);
  }, []);
  const tableColumns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("General.name"),
        accessorFn: (row) => row.tenantName,
        id: "Name",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.tenantName),
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { sx: definitionTermSx, children: original.tenantName });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { float: "right" }),
              onClick: () => {
                setTenantToDisconnect(original.tenantId);
                setRemoveModalMode("deleteTenant");
                setIsRemovalModalOpen(true);
              },
              disabled: ((_a2 = app == null ? void 0 : app.tenants) == null ? void 0 : _a2.length) === 1,
              children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
            }
          );
        }
      }
    ];
  }, [(_a = app == null ? void 0 : app.tenants) == null ? void 0 : _a.length, polyglot]);
  function configureIntegration() {
    var _a2, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
    return /* @__PURE__ */ jsxs("div", { children: [
      (app == null ? void 0 : app.directory) && (app == null ? void 0 : app.directory.oauth) === true && (app == null ? void 0 : app.stub) !== "pipedrive" && // TODO: remove this after the app is published and can be installed outside the marketplace
      /* @__PURE__ */ jsx("div", { children: app.authorised ? /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "flex-start"
          },
          children: [
            app && (app == null ? void 0 : app.tenants) && ((_a2 = app == null ? void 0 : app.tenants) == null ? void 0 : _a2.length) > 0 && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "Xero Organisation", fieldValue: (_b = tenantNames()) != null ? _b : "" }),
            /* @__PURE__ */ jsx(
              FieldStructure,
              {
                fieldTitle: polyglot.t("AppUserSettingPage.appOwner"),
                fieldValue: app.appOwnerId && getCachedUserById(app.appOwnerId) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
                  /* @__PURE__ */ jsx(UserAvatar, { userId: app.appOwnerId, size: "s20" }),
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: (_c = getCachedUserById(app.appOwnerId)) == null ? void 0 : _c.displayName })
                ] }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: "Deleted user" })
              }
            ),
            (app == null ? void 0 : app.createdAt) && /* @__PURE__ */ jsx(
              FieldStructure,
              {
                fieldTitle: polyglot.t("AppUserSettingPage.appConnectedOn"),
                fieldValue: dateAPItoDisplay(app.createdAt)
              }
            ),
            !!((_d = app == null ? void 0 : app.configuration) == null ? void 0 : _d.expiresAt) && /* @__PURE__ */ jsx(
              FieldStructure,
              {
                fieldTitle: polyglot.t("AppUserSettingPage.appExpiresOn"),
                fieldValue: dateAPItoDisplay(
                  new LocalDate((_e = app == null ? void 0 : app.configuration) == null ? void 0 : _e.expiresAt).toDateString()
                )
              }
            ),
            (app == null ? void 0 : app.slackTeamDetails) && ((_f = app == null ? void 0 : app.slackTeamDetails) == null ? void 0 : _f.name) && /* @__PURE__ */ jsx(
              FieldStructure,
              {
                fieldTitle: polyglot.t("AppUserSettingPage.connectedTeam"),
                fieldValue: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: (_g = app == null ? void 0 : app.slackTeamDetails) == null ? void 0 : _g.name }) })
              }
            )
          ]
        }
      ) : /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Basic auth setup" }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mt: 2 }), children: [
          "Connect ",
          app == null ? void 0 : app.name,
          " to manage users and groups"
        ] })
      ] }) }),
      !(app == null ? void 0 : app.directory) && (app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: "App Owner",
            fieldValue: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
              /* @__PURE__ */ jsx(UserAvatar, { userId: app.appOwnerId, size: "s20" }),
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: (_h = getCachedUserById(app.appOwnerId)) == null ? void 0 : _h.displayName })
            ] })
          }
        ),
        (app == null ? void 0 : app.createdAt) && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "App connected on", fieldValue: dateAPItoDisplay(app.createdAt) })
      ] }),
      (app == null ? void 0 : app.directory) && (app == null ? void 0 : app.directory.credentials) && /* @__PURE__ */ jsxs("div", { children: [
        (app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
          FieldStructure,
          {
            fieldTitle: "App Owner",
            fieldValue: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
              /* @__PURE__ */ jsx(UserAvatar, { userId: app.appOwnerId, size: "s20" }),
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: (_i = getCachedUserById(app.appOwnerId)) == null ? void 0 : _i.displayName })
            ] })
          }
        ),
        (app == null ? void 0 : app.createdAt) && /* @__PURE__ */ jsx(FieldStructure, { fieldTitle: "App connected on", fieldValue: dateAPItoDisplay(app.createdAt) }),
        (!((_j = app == null ? void 0 : app.directory) == null ? void 0 : _j.oauth) || (app == null ? void 0 : app.hasBasicAuthAndOAuth)) && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.headline1), {
                color: themeColors.DarkGrey,
                marginTop: spacing.m60
              }),
              children: "Basic auth setup"
            }
          ),
          appInfoCards[app == null ? void 0 : app.stub],
          app.directory.credentials.length > 0 && app.directory.credentials.map((credential, idx) => {
            var _a3, _b2, _c2, _d2, _e2;
            return /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m30 }, children: /* @__PURE__ */ jsxs(Grid, { container: true, sx: { width: "100%" }, children: [
              /* @__PURE__ */ jsx(Grid, { item: true, xs: 2, sx: { maxWidth: "40%" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, width: "190px" }), children: credential.label }) }),
              /* @__PURE__ */ jsxs(Grid, { item: true, xs: 6, sx: { maxWidth: "50%" }, children: [
                !app.authorised && credential.name !== "zeltCompanyId" && /* @__PURE__ */ jsx(
                  TextField,
                  {
                    label: `Enter ${credential.label}`,
                    variant: "standard",
                    required: true,
                    placeholder: credentialsPlaceholders ? (_a3 = credentialsPlaceholders[(credential == null ? void 0 : credential.name) || ""]) == null ? void 0 : _a3.toString() : void 0,
                    onChange: (event) => {
                      setCredentials(__spreadProps(__spreadValues({}, credentials), {
                        [event.target.name]: event.target.value
                      }));
                    },
                    value: credentials == null ? void 0 : credentials[credential.name],
                    name: credential.name,
                    size: "small",
                    sx: { minWidth: "50%" }
                  }
                ),
                app.authorised && credential.name && credentialsPlaceholders && credentialsPlaceholders[credential == null ? void 0 : credential.name] && /* @__PURE__ */ jsxs(Fragment, { children: [
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      disabled: true,
                      variant: "standard",
                      required: true,
                      value: credentialsPlaceholders ? (_b2 = credentialsPlaceholders[(credential == null ? void 0 : credential.name) || ""]) == null ? void 0 : _b2.toString() : void 0,
                      name: credential.name,
                      size: "small",
                      sx: { minWidth: "50%" }
                    }
                  ),
                  /* @__PURE__ */ jsx(IconButton, { onClick: () => editBasicAuth(), children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) })
                ] }),
                credential.name === "token" && appCredentialsInstructions[app.stub] && appCredentialsInstructions[app.stub].tokenInstallInstructions,
                credential.name === "provhash" && appCredentialsInstructions[app.stub] && appCredentialsInstructions[app.stub].hashKeyInstructions,
                credential.name === "cid" && appCredentialsInstructions[app.stub] && appCredentialsInstructions[app.stub].accountIdInstructions,
                credential.name === "apikey" && appCredentialsInstructions[app.stub] && appCredentialsInstructions[app.stub].mondayApiKeyInstructions,
                credential.name === "zeltCompanyId" && /* @__PURE__ */ jsx(
                  TextField,
                  {
                    disabled: true,
                    variant: "standard",
                    required: true,
                    value: (_e2 = (_d2 = (_c2 = globalState == null ? void 0 : globalState.user) == null ? void 0 : _c2.company) == null ? void 0 : _d2.companyId) != null ? _e2 : "0",
                    name: credential.name,
                    size: "small",
                    sx: { minWidth: "50%" }
                  }
                )
              ] })
            ] }, credential.name) }, idx);
          }),
          (!((_k = app == null ? void 0 : app.directory) == null ? void 0 : _k.oauth) || (app == null ? void 0 : app.hasBasicAuthAndOAuth)) && /* @__PURE__ */ jsx(
            AppBasicAuthConnectDrawer,
            {
              isOpen: basicAuthConnectDrawerOpen,
              setIsOpen: setBasicAuthConnectDrawerOpen,
              appDetails: app,
              currentUser: globalState.user
            }
          )
        ] })
      ] }),
      app && app.stub === "xero" && (app == null ? void 0 : app.tenants) && ((_l = app == null ? void 0 : app.tenants) == null ? void 0 : _l.length) > 0 && /* @__PURE__ */ jsx(
        SettingsSectionContent,
        {
          title: /* @__PURE__ */ jsx(Typography, { sx: { font: themeFonts.headline2 }, children: polyglot.t("AppUserSettingPage.connectedOrganisations") }),
          buttons: [
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => connectAccountingApp("xero"),
                children: polyglot.t("AppUserSettingPage.connectXeroOrganisation")
              },
              "connectOrg"
            )
          ],
          children: /* @__PURE__ */ jsx(
            SettingsSubsectionContent,
            {
              sections: [
                {
                  items: [
                    {
                      type: SectionItemType.Component,
                      value: /* @__PURE__ */ jsx(BasicTable, { rowData: app.tenants, columnData: tableColumns, hidePagination: true })
                    }
                  ]
                }
              ]
            }
          )
        }
      ),
      (app == null ? void 0 : app.allowsOnboardingViaWebhook) && /* @__PURE__ */ jsx(AppAutomaticOnboardingViaWebhook, { appDetails: app, companyId: globalState.user.company.companyId }),
      (app == null ? void 0 : app.directory) && (app == null ? void 0 : app.directory.credentials) && isConfigured() && /* @__PURE__ */ jsx(Fragment, { children: !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          style: { marginTop: "60px" },
          disabled: !areCredentialsCompleted(),
          onClick: (event) => __async(this, null, function* () {
            if (event) event.preventDefault();
            yield upsertCredentials();
          }),
          children: "Save"
        }
      ) }),
      (app == null ? void 0 : app.directory) && (app == null ? void 0 : app.directory.oauth) && !app.directory.marketplaceInstall && /* @__PURE__ */ jsx(Fragment, { children: !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsxs(Fragment, { children: [
        (app == null ? void 0 : app.stub) !== "quickbooks" && /* @__PURE__ */ jsx(
          ScopesControl,
          {
            scopes: ["apps.connect", "apps:all"],
            context: getScopesContext({ userId: globalState.user.userId }),
            children: /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "primary",
                style: { marginTop: spacing.m2 },
                onClick: (event) => __async(this, null, function* () {
                  if (event) event.preventDefault();
                  window.location.href = AppIntegrationAPI.getInitiateUrl(app.stub);
                }),
                children: "Connect"
              }
            )
          }
        ),
        (app == null ? void 0 : app.stub) === "quickbooks" && /* @__PURE__ */ jsx(
          ScopesControl,
          {
            scopes: ["apps.connect", "apps:all"],
            context: getScopesContext({ userId: globalState.user.userId }),
            children: /* @__PURE__ */ jsx(
              "button",
              {
                onClick: (event) => __async(this, null, function* () {
                  if (event) event.preventDefault();
                  window.location.href = AppIntegrationAPI.getInitiateUrl(app.stub);
                }),
                onMouseEnter: () => setIsQbState("hover"),
                onMouseLeave: () => setIsQbState("default"),
                disabled: app == null ? void 0 : app.authorised,
                children: /* @__PURE__ */ jsx("img", { src: `/app-icons-v2/QB${isQbState}.png`, alt: "qb-connect-button" })
              }
            )
          }
        )
      ] }) }),
      (app == null ? void 0 : app.directory) && (app == null ? void 0 : app.directory.marketplaceInstall) && isConfigured() && /* @__PURE__ */ jsx(Fragment, { children: !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx("a", { href: app == null ? void 0 : app.directory.marketplaceInstall, children: /* @__PURE__ */ jsx(Button, { color: "primary", onClick: () => {
      }, children: "Install App from Marketplace" }) }) }),
      !(app == null ? void 0 : app.authorised) && (app == null ? void 0 : app.directory) && (app == null ? void 0 : app.directory.marketplaceInstall) && /* @__PURE__ */ jsx("div", { children: "Install from Marketplace" })
    ] });
  }
  function upsertCredentials() {
    return __async(this, null, function* () {
      try {
        if (credentials) {
          yield AppDetailsAPI.saveOrUpdateIntegrationCredentials(appStub, credentials);
          const app2 = yield AppDetailsAPI.getIntegrationData(appStub);
          refreshInstalledApps();
          setTimeout(
            () => routerHistory.push(
              generatePath(APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, {
                appStub
              }),
              { app: app2, needToRefreshDirectory: true }
            ),
            700
          );
        }
        yield fetchAppDetails();
      } catch (error) {
        showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
      } finally {
      }
    });
  }
  function areCredentialsCompleted() {
    var _a2, _b;
    if (!credentials) {
      return false;
    } else {
      if ((_a2 = app == null ? void 0 : app.directory) == null ? void 0 : _a2.credentials) {
        for (let cred of (_b = app == null ? void 0 : app.directory) == null ? void 0 : _b.credentials) {
          if (cred) {
            const propName = cred.name || void 0;
            if (!credentials[propName]) return false;
          }
        }
      }
    }
    return true;
  }
  function tenantNames() {
    return (app == null ? void 0 : app.tenants) && (app == null ? void 0 : app.tenants.map((eachTenant) => eachTenant.tenantName).join(", "));
  }
  const handleDeleteIntegration = () => __async(void 0, null, function* () {
    setDeleteLoading(true);
    yield onDeleteApp();
    setIsRemovalModalOpen(false);
    setDeleteLoading(false);
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("AppUserSettingPage.title") }),
        showAction: !!appDetailError || (app == null ? void 0 : app.authorised) && app && hasScopes(AppScopes.DELETE_APP_INTEGRATION, { userId: app.appOwnerId }),
        actions: /* @__PURE__ */ jsx(Tooltip, { title: appDetailError != null ? appDetailError : "", placement: "bottom-start", children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            disabled: deleteLoading,
            sizeVariant: "small",
            colorVariant: "secondary",
            onClick: () => {
              setRemoveModalMode("deleteIntegration");
              setIsRemovalModalOpen(true);
            },
            children: polyglot.t("AppUserSettingPage.removeIntegration")
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading || deleteLoading, border: false, children: [
      configureIntegration(),
      (app == null ? void 0 : app.authorised) && APPS_WITH_CC_INVITE.includes(app.stub) && /* @__PURE__ */ jsx(AppCCForNewAccounts, { appDetails: app }),
      (app == null ? void 0 : app.authorised) && APPS_WITH_DOMAIN_NAME_CALENDAR_CONFIGURATION.includes(app.stub) && /* @__PURE__ */ jsx(AppCalendarConfiguration, {}),
      (app == null ? void 0 : app.allowsAutomaticImportViaCron) && ((app == null ? void 0 : app.allowsCandidateManagement) || (app == null ? void 0 : app.allowsEmploymentManagement)) && /* @__PURE__ */ jsx(AppAutomaticOnboardingConfiguration, { appDetails: app, mode: modeForOnboardingConfig }),
      /* @__PURE__ */ jsx(
        DeleteDrawer,
        {
          title: polyglot.t("AppUserSettingConfirmRemoveIntegration.title"),
          description: removeModalMode === "deleteIntegration" ? APP_IN_USE_CONFIRMATION_MESSAGE : REMOVE_TENANT_CONFIRM,
          isOpen: isRemovalModalOpen,
          setIsOpen: setIsRemovalModalOpen,
          loading: deleteLoading,
          action: () => __async(void 0, null, function* () {
            if (removeModalMode === "deleteIntegration") yield handleDeleteIntegration();
            else if (removeModalMode === "deleteTenant" && tenantToDisconnect) yield disconnectXeroOrganisation();
          })
        }
      )
    ] })
  ] });
};
