"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { CompanyPayrollType } from "@v2/feature/payroll/payroll.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { generatePath, useHistory } from "react-router-dom";
import { CompanyEndpoints } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  PAYROLL_COMPANY_NEW_PAYROLL_ROUTE,
  SETTINGS_COMPANY_ROUTE,
  SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE
} from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledRadioGroup } from "@/v2/components/theme-components/styled-radio-group.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { PayrollContext } from "@/v2/feature/payroll/payroll-company.router";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { isUKCountryCode } from "@/v2/infrastructure/country/country.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { caseInsensitiveSort } from "@/v2/util/array.util";
const EntitySelectionView = ({ companyEntities, creatingPayroll, onEntitySelected }) => {
  const { polyglot } = usePolyglot();
  const [selectedEntityId, setSelectedEntityId] = useState(0);
  const routerHistory = useHistory();
  const { data: companyPayrolls } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });
  const entityOptions = useMemo(() => {
    if (!companyEntities || !companyPayrolls) return null;
    return [...companyEntities].sort((a, b) => caseInsensitiveSort(a, b, (item) => item.legalName)).map((e) => ({
      label: e.legalName,
      value: e.id,
      disabled: companyPayrolls.items.some((p) => {
        var _a;
        return ((_a = p.entity) == null ? void 0 : _a.id) === e.id;
      })
    }));
  }, [companyEntities, companyPayrolls]);
  const goToCompanyEntities = useCallback(() => {
    routerHistory.push(SETTINGS_COMPANY_ROUTE);
  }, [routerHistory]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("CompanyPayroll.setupPayroll") }),
    companyEntities.length > 0 && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("CompanyPayroll.whichEntityForPayroll") }),
    companyEntities.length > 0 && /* @__PURE__ */ jsx(
      StyledRadioGroup,
      {
        name: "entity",
        options: entityOptions != null ? entityOptions : [],
        selectedValue: selectedEntityId,
        labelPlacement: "end",
        onChange: (e) => setSelectedEntityId(Number(e.target.value)),
        disabled: creatingPayroll,
        radioSx: {
          borderBottom: 0,
          justifyContent: "normal",
          paddingY: 0
        },
        sx: { mb: spacing.mb10 }
      }
    ),
    companyEntities.length === 0 && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("CompanyPayroll.noEntities") }),
    companyEntities.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: !selectedEntityId,
        loading: creatingPayroll,
        onClick: () => __async(void 0, null, function* () {
          onEntitySelected(selectedEntityId);
        }),
        children: polyglot.t("General.continue")
      }
    ) }) : /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => goToCompanyEntities(), children: polyglot.t("PayrollCompanyCreate.createNewEntity") })
  ] });
};
const ReportPayrollToHMRCView = ({ creatingPayroll, onHMRCFilingSelected }) => {
  const { polyglot } = usePolyglot();
  const [filingMode, setFilingMode] = useState("");
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("CompanyPayroll.shouldReportPayrollToHMRC") }),
    /* @__PURE__ */ jsx(
      StyledRadioGroup,
      {
        options: [
          {
            label: polyglot.t("CompanyPayroll.reportPayrollToHMRCNo"),
            value: "external-hmrc-filing"
          },
          {
            label: polyglot.t("CompanyPayroll.reportPayrollToHMRCYes"),
            value: "zelt-hmrc-filing"
          }
        ],
        selectedValue: filingMode,
        labelPlacement: "end",
        onChange: (e) => setFilingMode(e.target.value),
        disabled: creatingPayroll,
        radioSx: {
          borderBottom: 0,
          justifyContent: "normal",
          paddingY: 0
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: !filingMode,
        loading: creatingPayroll,
        onClick: () => {
          onHMRCFilingSelected(filingMode === "zelt-hmrc-filing");
        },
        children: polyglot.t("General.continue")
      }
    ) })
  ] });
};
export const SetupPayrollDrawer = () => {
  const { polyglot } = usePolyglot();
  const selectedEntityId = useRef(0);
  const [stage, setStage] = useState("entity-selection");
  const [creatingPayroll, setCreatingPayroll] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const { refreshPayrollState } = useContext(PayrollContext);
  const { data: companyEntities } = useApiClient(CompanyEndpoints.getCompanyEntities(), { suspense: false });
  const createPayroll = useCallback(
    (entityId, payrollType) => __async(void 0, null, function* () {
      try {
        setCreatingPayroll(true);
        const newPayroll = yield PayrollExternalApi.createCompanyPayroll(entityId, payrollType);
        yield refreshPayrollState();
        return newPayroll;
      } catch (error) {
        showMessage(
          polyglot.t("PayrollCompanyCreate.payrollCouldNotBeCreated", { reason: nestErrorMessage(error) }),
          "error"
        );
        return null;
      } finally {
        setCreatingPayroll(false);
      }
    }),
    [refreshPayrollState, showMessage, polyglot]
  );
  const createUKPayroll = useCallback(
    (entityId) => __async(void 0, null, function* () {
      const newPayroll = yield createPayroll(entityId, CompanyPayrollType.UKPayroll);
      if (newPayroll) {
        routerHistory.push(generatePath(PAYROLL_COMPANY_NEW_PAYROLL_ROUTE, { payrollId: newPayroll.id }));
      }
    }),
    [createPayroll, routerHistory]
  );
  const createGlobalPayroll = useCallback(
    (entityId) => __async(void 0, null, function* () {
      const newPayroll = yield createPayroll(entityId, CompanyPayrollType.GlobalPayroll);
      if (newPayroll) {
        routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId: newPayroll.id }));
      }
    }),
    [createPayroll, routerHistory]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ width: 340, p: "20px 30px" }, drawerContentSx), children: [
    stage === "entity-selection" && companyEntities && /* @__PURE__ */ jsx(
      EntitySelectionView,
      {
        companyEntities,
        creatingPayroll,
        onEntitySelected: (entityId) => {
          const entity = companyEntities.find((e) => e.id === entityId);
          if (!(entity == null ? void 0 : entity.address)) return;
          selectedEntityId.current = entityId;
          if (isUKCountryCode(entity.address.countryCode)) {
            setStage("hmrc-filing");
            return;
          }
          createGlobalPayroll(selectedEntityId.current);
        }
      }
    ),
    stage === "hmrc-filing" && /* @__PURE__ */ jsx(
      ReportPayrollToHMRCView,
      {
        creatingPayroll,
        onHMRCFilingSelected: (isZeltFilingToHMRC) => {
          if (isZeltFilingToHMRC) {
            createUKPayroll(selectedEntityId.current);
            return;
          }
          createGlobalPayroll(selectedEntityId.current);
        }
      }
    )
  ] });
};
