"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { translateJSMonthNo } from "@v2/infrastructure/i18n/translate.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { range } from "lodash";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { formatPayPeriod } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import {
  formatPayrollDateType,
  formatTaxYearStart
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payroll-company-settings.util";
import { UK_TAX_YEAR_START } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { addDate, getMaxDayInMonth } from "@/v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const SupportedPayPeriods = ["Monthly", "Weekly"];
const DefaultPayPeriod = "Monthly";
const daysOfMonthOptions = range(31).map((n) => ({ label: (n + 1).toString().padStart(2, "0"), value: n + 1 }));
const twoDigit = (n) => n.toString().padStart(2, "0");
const getTaxYearFromFirstPeriodEndDateAndTaxYearStart = (firstPeriodEndDate, taxYearStart) => {
  const [firstPeriodEndYear, firstPeriodEndDateMonth, firstPeriodEndDateDay] = firstPeriodEndDate.split("-");
  const numericFirstPeriodEndYear = Number(firstPeriodEndYear);
  const isPaymentEndDateBeforeTaxYearStart = `${firstPeriodEndDateMonth}-${firstPeriodEndDateDay}` < taxYearStart;
  const yearNumber = isPaymentEndDateBeforeTaxYearStart ? numericFirstPeriodEndYear - 1 : numericFirstPeriodEndYear;
  return `Year${yearNumber}`;
};
const PayrollScheduleEdit = ({
  isOpen,
  setIsOpen,
  isUKPayroll,
  payrollId,
  schedule,
  onClose
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [savingSchedule, setSavingSchedule] = useState(false);
  const isLastDay = useRef(false);
  const [showMessage] = useMessage();
  const [endOfThisMonth, twentyFifthOfThisMonth, nextFriday, nextSunday] = useMemo(() => {
    const now = /* @__PURE__ */ new Date();
    const [day, month, year] = [now.getDate(), now.getMonth() + 1, now.getFullYear()];
    const endOfThisMonth2 = `${year}-${twoDigit(month)}-${getMaxDayInMonth(month, year)}`;
    const twentyFifthOfThisMonth2 = `${year}-${twoDigit(month)}-25`;
    const today = `${year}-${twoDigit(month)}-${twoDigit(day)}`;
    const nextSunday2 = addDate(today, { days: 7 - now.getDay() });
    const nextFriday2 = addDate(nextSunday2, { days: -2 });
    return [endOfThisMonth2, twentyFifthOfThisMonth2, nextFriday2, nextSunday2];
  }, []);
  const defaultFirstPeriodEndDate = useCallback(
    (payPeriod) => {
      return payPeriod === "Weekly" ? nextSunday : endOfThisMonth;
    },
    [endOfThisMonth, nextSunday]
  );
  const defaultFirstPaymentDate = useCallback(
    (payPeriod) => {
      return payPeriod === "Weekly" ? nextFriday : twentyFifthOfThisMonth;
    },
    [twentyFifthOfThisMonth, nextFriday]
  );
  const monthOptions = useMemo(
    () => range(12).map((n) => ({
      label: translateJSMonthNo(n, polyglot),
      value: n + 1
    })),
    [polyglot]
  );
  const periodOptions = useMemo(
    () => SupportedPayPeriods.map((value) => ({
      label: formatPayPeriod(value, polyglot),
      value
    })),
    [polyglot]
  );
  const formik = useFormik({
    initialValues: {
      payPeriod: (_a = schedule == null ? void 0 : schedule.payPeriod) != null ? _a : DefaultPayPeriod,
      taxYearStart: (_b = schedule == null ? void 0 : schedule.taxYearStart) != null ? _b : isUKPayroll ? UK_TAX_YEAR_START : "01-01",
      firstPeriodEndDate: (_c = schedule == null ? void 0 : schedule.firstPeriodEndDate) != null ? _c : defaultFirstPeriodEndDate(DefaultPayPeriod),
      firstPaymentDate: (_d = schedule == null ? void 0 : schedule.firstPaymentDate) != null ? _d : defaultFirstPaymentDate(DefaultPayPeriod)
    },
    onSubmit: (values) => __async(void 0, null, function* () {
      const { payPeriod, firstPaymentDate, firstPeriodEndDate, taxYearStart } = values;
      let payScheduleUpdated = false;
      setSavingSchedule(true);
      try {
        if (schedule) {
          yield PayrollExternalApi.updatePaySchedule(
            payrollId,
            schedule.taxYear,
            schedule.payPeriod,
            schedule.ordinal,
            {
              payPeriod,
              firstPaymentDate,
              firstPeriodEndDate,
              taxYearStart
            }
          );
        } else {
          const taxYear = getTaxYearFromFirstPeriodEndDateAndTaxYearStart(firstPeriodEndDate, taxYearStart);
          yield PayrollExternalApi.createNewPaySchedule(payrollId, taxYear, payPeriod, {
            firstPaymentDate,
            firstPeriodEndDate,
            taxYearStart
          });
        }
        payScheduleUpdated = true;
      } catch (error) {
        showMessage(`A new pay schedule could not be configured. ${nestErrorMessage(error)}`, "error");
      } finally {
        setSavingSchedule(false);
      }
      if (payScheduleUpdated) {
        onClose();
      }
    })
  });
  useLayoutEffect(() => {
    const [month, day] = formik.values.taxYearStart.split("-").map(Number);
    const maxDay = getMaxDayInMonth(month, 2e3);
    isLastDay.current = day >= maxDay;
    if (day <= maxDay) return;
    formik.setFieldValue("taxYearStart", `${twoDigit(month)}-${twoDigit(maxDay)}`);
  }, [formik, formik.values.taxYearStart]);
  const resetDates = useCallback(
    (payPeriod) => {
      formik.setFieldValue("firstPeriodEndDate", defaultFirstPeriodEndDate(payPeriod));
      formik.setFieldValue("firstPaymentDate", defaultFirstPaymentDate(payPeriod));
    },
    [defaultFirstPaymentDate, defaultFirstPeriodEndDate, formik]
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Schedule" }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "payPeriod",
        label: "Pay period",
        options: periodOptions,
        value: formik.values.payPeriod,
        onChange: (e) => {
          formik.handleChange(e);
          resetDates(e.target.value);
        },
        disabled: savingSchedule
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "taxYearStartDate",
        label: "Tax year start (Date)",
        options: daysOfMonthOptions,
        value: Number(formik.values.taxYearStart.slice(3, 5)),
        onChange: (e) => {
          const newDay = twoDigit(Number(e.target.value));
          const month = formik.values.taxYearStart.slice(0, 2);
          formik.setFieldValue("taxYearStart", `${month}-${newDay}`);
        },
        disabled: isUKPayroll || savingSchedule
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "taxYearStartMonth",
        label: "Tax year start (Month)",
        options: monthOptions,
        value: Number(formik.values.taxYearStart.slice(0, 2)),
        onChange: (e) => {
          const newMonth = twoDigit(Number(e.target.value));
          const day = isLastDay.current ? twoDigit(getMaxDayInMonth(Number(e.target.value), 2e3)) : formik.values.taxYearStart.slice(3, 5);
          formik.setFieldValue("taxYearStart", `${newMonth}-${day}`);
        },
        disabled: isUKPayroll || savingSchedule
      }
    ),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "firstPeriodEndDate",
        label: "First pay period ending",
        value: formik.values.firstPeriodEndDate,
        onChange: (date) => formik.setFieldValue("firstPeriodEndDate", date),
        disabled: savingSchedule
      }
    ),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "firstPaymentDate",
        label: "First payment date",
        value: formik.values.firstPaymentDate,
        onChange: (date) => formik.setFieldValue("firstPaymentDate", date),
        disabled: savingSchedule
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, type: "submit", children: polyglot.t("General.save") }) })
  ] }) }) });
};
export const PayrollScheduleSettingsSection = ({ payroll, sx, refreshPayroll }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const [editSchedule, setEditSchedule] = useState(false);
  const hasPayruns = !!payroll.payruns.length || !!payroll.globalPayruns.length;
  const [showMessage] = useMessage();
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(
      PayrollSettingSectionHeader,
      {
        showEditButton: !editSchedule,
        onEditClick: () => {
          if (hasPayruns) {
            showMessage("The payroll schedule cannot be changed after the first payrun has started.", "info");
            return;
          }
          setEditSchedule(true);
        },
        children: "Schedule"
      }
    ),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "inline-grid",
          gridTemplateColumns: "1fr 3fr",
          rowGap: spacing.g10,
          columnGap: spacing.g20,
          mt: spacing.mt10
        },
        children: [
          /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { whiteSpace: "nowrap" }, children: "Tax year start" }),
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: (_b = formatTaxYearStart((_a = payroll.schedule) == null ? void 0 : _a.taxYearStart)) != null ? _b : "-" })
          ] }),
          /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { whiteSpace: "nowrap" }, children: "Pay period" }),
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: (_d = (_c = payroll.schedule) == null ? void 0 : _c.payPeriod) != null ? _d : "-" })
          ] }),
          /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { whiteSpace: "nowrap" }, children: "Pay period ends" }),
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: (_h = formatPayrollDateType(
              (_e = payroll.schedule) == null ? void 0 : _e.payPeriod,
              (_f = payroll.schedule) == null ? void 0 : _f.firstPeriodEndDate,
              (_g = payroll.schedule) == null ? void 0 : _g.periodEndDateType
            )) != null ? _h : "-" })
          ] }),
          /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { whiteSpace: "nowrap" }, children: "Payment date" }),
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: (_l = formatPayrollDateType(
              (_i = payroll.schedule) == null ? void 0 : _i.payPeriod,
              (_j = payroll.schedule) == null ? void 0 : _j.firstPaymentDate,
              (_k = payroll.schedule) == null ? void 0 : _k.paymentDateType
            )) != null ? _l : "-" })
          ] })
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      PayrollScheduleEdit,
      {
        isOpen: editSchedule,
        setIsOpen: setEditSchedule,
        payrollId: payroll.id,
        isUKPayroll: !!payroll.employer,
        schedule: payroll.schedule,
        onClose: () => __async(void 0, null, function* () {
          yield refreshPayroll();
          setEditSchedule(false);
        })
      }
    )
  ] });
};
