"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as HMRC } from "@/images/side-bar-icons/hmrc.svg";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { PaymentsEndpoints } from "@/v2/feature/payments/payments.api";
import { PAYMENTS_ROUTE } from "@/v2/feature/payments/payments.router";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserBankAccountAPI } from "@/v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import { ShowHideButton } from "@/v2/feature/user/features/user-profile/details/components/show-hide-button.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
function openInNewTab(url) {
  const win = window.open(url, "");
  if (win) win.focus();
}
function PaymentRequestsDetails({ requests }) {
  const { getCachedUserById } = useCachedUsers();
  const [bankRequest, setBankRequest] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const setBankInfoInRequest = useCallback(() => __async(this, null, function* () {
    setLoading(true);
    try {
      const userIds = [...new Set(requests.map((r) => r.userId).filter(Boolean))];
      const accountsMapping = yield UserBankAccountAPI.findCurrentByUserIds(userIds);
      const allRequests = requests.map((r) => {
        var _a, _b, _c, _d;
        return __spreadProps(__spreadValues({}, r), {
          accountNumber: r.userId ? (_b = (_a = accountsMapping[r.userId]) == null ? void 0 : _a.accountNumber) != null ? _b : null : null,
          sortCode: r.userId ? (_d = (_c = accountsMapping[r.userId]) == null ? void 0 : _c.sortCode) != null ? _d : null : null
        });
      });
      setBankRequest(allRequests);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setLoading(false);
  }), [polyglot, requests, showMessage]);
  useEffect(() => {
    setBankInfoInRequest();
  }, [setBankInfoInRequest]);
  const columns = useMemo(
    () => [
      {
        header: () => "Beneficiary",
        id: "userId",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const user = (original == null ? void 0 : original.userId) ? getCachedUserById(original.userId) : void 0;
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m10, cursor: "pointer" }, children: original.userId ? /* @__PURE__ */ jsx(Box, { sx: { display: "block" }, children: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: [
                /* @__PURE__ */ jsx(UserAvatar, { userId: original == null ? void 0 : original.userId, size: "s20" }),
                /* @__PURE__ */ jsxs(Box, { sx: { display: "block" }, children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: user == null ? void 0 : user.displayName }),
                  original.accountNumber && /* @__PURE__ */ jsxs(Typography, { variant: "paragraphSmall", color: "Grey", children: [
                    original.accountNumber,
                    "\u30FB",
                    original.sortCode
                  ] })
                ] })
              ]
            }
          ) }) : /* @__PURE__ */ jsxs(
            Typography,
            {
              variant: "paragraph",
              sx: {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              },
              children: [
                /* @__PURE__ */ jsx(HMRC, { width: 20, height: 20 }),
                "HMRC"
              ]
            }
          ) });
        }
      },
      {
        header: () => "Reference",
        id: "reference",
        enableSorting: false,
        accessorFn: (row) => row.reference,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Fragment, { children: original.reference })
      },
      {
        header: () => "Amount",
        id: "amount",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(CurrencyCell, { value: original.amount, currency: original.currency })
      }
    ],
    [getCachedUserById]
  );
  return /* @__PURE__ */ jsx(
    BasicTable,
    {
      rowData: bankRequest ? [...bankRequest] : [],
      columnData: columns,
      hidePagination: true,
      loading
    }
  );
}
function PaymentFailed({ tryAgain, error }) {
  return /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(MistakeIcon, { width: 30, height: 30 }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Payment failed" })
    ] }),
    /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", sx: { mt: spacing.m10 }, children: [
      "The bank responded with the following error:",
      " ",
      error == null ? void 0 : error.replaceAll("We can help you on https://docs.yapily.com/support", "")
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: () => tryAgain(), children: "Restart" }) })
  ] });
}
function PaymentYapilyConnectContent({
  institution,
  requests,
  onSubmit,
  onChange,
  setUnsafeClose
}) {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const routerHistory = useHistory();
  const [checkTransactionId, setCheckTransactionId] = useState(null);
  const { data: transactionStatus } = useApiClient(
    checkTransactionId ? PaymentsEndpoints.getTransactionStatus(checkTransactionId) : null,
    {
      refreshInterval: 2e3
    }
  );
  const showGroupPaymentsButton = useMemo(() => {
    return requests.length > 1 && requests.every(
      (request) => request.userId && request.userId === requests[0].userId && request.currency === requests[0].currency
    );
  }, [requests]);
  const tryAgain = () => {
    setError(void 0);
    routerHistory.push(PAYMENTS_ROUTE);
  };
  useEffect(() => {
    if (transactionStatus) {
      setCheckTransactionId(null);
      localStorage.setItem("transactionStatus", transactionStatus);
      onChange();
    } else {
      localStorage.removeItem("transactionStatus");
    }
  }, [transactionStatus, onChange]);
  const totalAmountByCurrency = requests.reduce((prev, request) => {
    var _a2;
    const currency = (_a2 = request.currency) != null ? _a2 : "Unknown";
    if (!prev[currency]) prev[currency] = 0;
    prev[currency] += request.amount;
    return prev;
  }, {});
  const formik = useFormik({
    initialValues: {
      groupPayments: false,
      groupPaymentReference: "Group payment"
    },
    validationSchema: yup.object({
      groupPayments: yup.boolean().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      groupPaymentReference: yup.string().trim().min(6, "Minimum 6 characters required").max(16, "Maximum 16 characters allowed").when("groupPayments", {
        is: true,
        then: (schema) => schema.matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric characters and spaces are allowed").required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.notRequired()
      })
    }),
    onSubmit: (_0) => __async(this, [_0], function* ({ groupPayments, groupPaymentReference }) {
      var _a2;
      try {
        setLoading(true);
        const response = yield onSubmit(groupPayments, groupPaymentReference);
        if (response) {
          setUnsafeClose(false);
          openInNewTab(response.authorisationUrl);
          setCheckTransactionId((_a2 = response.transactionId) != null ? _a2 : null);
        }
      } catch (error2) {
        setError(nestErrorMessage(error2));
        setLoading(false);
        setUnsafeClose(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    error && /* @__PURE__ */ jsx(PaymentFailed, { error, tryAgain }),
    !error && /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { mb: "20px" }, children: "Confirm details" }),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
            width: "100%",
            gap: spacing.g40
          },
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" } }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "To easily set up payments from your bank in Zelt, we are about to securely re-direct you to your bank where you will be asked to confirm the payment via Yapily Connect, an FCA regulated payment initiation provider for Zelt. Yapily Connect will share these details with your bank, where you will then be asked to confirm the following payment setup:" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.m10 }, children: "This consent request is a one-off, you will not receive additional requests once completed." }),
              /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10 }, children: [
                /* @__PURE__ */ jsx(
                  ShowHideButton,
                  {
                    showDetails,
                    setShowDetails,
                    showTitle: "About Yapily Connect",
                    hideTitle: "About Yapily Connect"
                  }
                ),
                showDetails && /* @__PURE__ */ jsxs(Box, { children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { mt: spacing.m30 }, children: "Data Sharing" }),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.m10 }, children: "Yapily Connect will retrieve bank data needed to facilitate this payment based on your request and provide this information to Zelt." }),
                  /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { mt: spacing.m30 }, children: "Secure Connection" }),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.m10 }, children: "Data is securely accessed in read-only format and only for the purposes of this payment request. This request is a one off, you will not receive any other requests from Yapily Connect for this payment." }),
                  /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { mt: spacing.m30 }, children: "FCA Authorisation" }),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.m10 }, children: "Yapily Connect Ltd is authorised and regulated by the Financial Conduct Authority under the Payment Service Regulations 2017 [827001] for the provision of Account Information and Payment Initiation services." }),
                  /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", sx: { mt: spacing.m10 }, children: [
                    "Please read",
                    " ",
                    /* @__PURE__ */ jsx(
                      "a",
                      {
                        href: "https://yapi.ly/MwMo",
                        style: { textDecoration: "none", color: themeColors.ZeltYellow },
                        children: "Yapily Connect PIS - Terms & Conditions"
                      }
                    )
                  ] })
                ] })
              ] })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" } }, children: [
              /* @__PURE__ */ jsx(PaymentRequestsDetails, { requests }),
              /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mt: spacing.m20
                  },
                  children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Total Amount" }),
                    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", alignItems: "end" }, children: Object.keys(totalAmountByCurrency).map((currency) => /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { pr: spacing.p5 }, children: formatMoney({
                      amount: totalAmountByCurrency[currency],
                      currency
                    }) }, currency)) })
                  ]
                }
              ),
              showGroupPaymentsButton && /* @__PURE__ */ jsx(Box, { sx: { mt: "20px" }, children: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  label: "Group payments",
                  name: "groupPayments",
                  checked: formik.values.groupPayments,
                  onChange: formik.handleChange
                }
              ) }),
              showGroupPaymentsButton && formik.values.groupPayments && /* @__PURE__ */ jsx(Box, { sx: { mt: "10px" }, children: /* @__PURE__ */ jsx(
                TextfieldComponent,
                {
                  name: "groupPaymentReference",
                  label: "Reference",
                  value: formik.values.groupPaymentReference,
                  onChange: formik.handleChange,
                  error: formik.touched.groupPaymentReference && !!formik.errors.groupPaymentReference,
                  helperText: (_a = formik.touched.groupPaymentReference && formik.errors.groupPaymentReference) != null ? _a : " "
                }
              ) }),
              /* @__PURE__ */ jsx(Box, { sx: { marginTop: "40px", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
                LoaderButton,
                {
                  name: `Send payments to ${institution == null ? void 0 : institution.name}`,
                  loading,
                  type: "submit",
                  sizeVariant: "medium",
                  colorVariant: "primary",
                  fullWidth: true
                }
              ) })
            ] })
          ]
        }
      )
    ] })
  ] }) });
}
function PaymentYapilyConnectContentSkeleton() {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Skeleton, { sx: { backgroundColor: themeColors.Background } }),
    /* @__PURE__ */ jsx(Skeleton, { sx: { backgroundColor: themeColors.Background } }),
    /* @__PURE__ */ jsx(Skeleton, { sx: { backgroundColor: themeColors.Background } })
  ] });
}
export function PaymentYapilyConnect(props) {
  return /* @__PURE__ */ jsx(Box, { display: "flex", justifyContent: "center", children: /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "850px" }, children: /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(PaymentYapilyConnectContentSkeleton, {}), children: /* @__PURE__ */ jsx(PaymentYapilyConnectContent, __spreadValues({}, props)) }) }) });
}
