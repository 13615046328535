"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { getDateString } from "@v2/components/forms/date-label.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortDate, sortString } from "@v2/components/table/table-sorting.util";
import { UserCell } from "@v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ReportDeleteDrawer } from "@v2/feature/reports/reports-advanced/components/report-delete-drawer.component";
import { ReportsAPI } from "@v2/feature/reports/reports.api";
import { exportReportCSV } from "@v2/feature/reports/util/report.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { ReactComponent as Report } from "@/images/side-bar-icons/Report.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  REPORTS_COMPANY_CREATE_ROUTE,
  REPORTS_COMPANY_VIEW_REPORT_ROUTE,
  REPORTS_TEAM_CREATE_ROUTE,
  REPORTS_TEAM_VIEW_REPORT_ROUTE
} from "@/lib/routes";
export const ReportsAdvancedOverviewPage = ({ reports, refreshReportsList, isLoading, target }) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(void 0);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  useEffect(() => {
    if (!reports) return;
    let filteredData2;
    if (searchInput.length) {
      filteredData2 = reports.filter((r) => r.name.includes(searchInput) || r.type.includes(searchInput));
      setFilteredData(filteredData2);
    } else {
      setFilteredData(reports);
    }
  }, [searchInput, reports]);
  const exportCsv = useCallback(
    (report) => __async(void 0, null, function* () {
      var _a;
      if (!(report == null ? void 0 : report.columns) || !(report == null ? void 0 : report.filters)) {
        showMessage("Could not find the report to be downloaded.", "error");
        return;
      }
      try {
        const response = yield ReportsAPI.generateReport(
          report.columns,
          report.filters,
          (_a = report.effectiveDate) != null ? _a : new LocalDate().toDateString(),
          report.effectiveDateEnabled,
          target
        );
        exportReportCSV(report.name, response);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    }),
    [polyglot, showMessage, target]
  );
  const copyReport = useCallback(
    (report) => __async(void 0, null, function* () {
      if (!(report == null ? void 0 : report.name) || !report.columns || !report.filters) {
        showMessage("Could not find the report to be copied.", "error");
        return;
      }
      const copyName = `${report.name} - Copy`;
      try {
        yield ReportsAPI.saveAdvancedReport({
          name: copyName,
          columns: report.columns,
          filters: report.filters,
          effectiveDate: report.effectiveDate,
          effectiveDateEnabled: report.effectiveDateEnabled
        });
        yield refreshReportsList();
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    }),
    [refreshReportsList, polyglot, showMessage]
  );
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: () => polyglot.t("ReportsPage.name"),
        accessorFn: (row) => row.name,
        maxSize: 250,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item == null ? void 0 : item.name),
        cell: ({ row: { original } }) => original.name ? /* @__PURE__ */ jsx(Box, { children: original.name }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("ReportsPage.createdBy"),
        accessorFn: (row) => row,
        id: "createdBy",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return item.createdBy ? (_a = getCachedUserById(item.createdBy)) == null ? void 0 : _a.displayName : "";
        }),
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.createdBy);
          return user && /* @__PURE__ */ jsx(UserCell, { userId: original.createdBy });
        }
      },
      {
        id: "updatedAt",
        header: () => polyglot.t("ReportsPage.lastUpdated"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item == null ? void 0 : item.updatedAt),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.updatedAt ? getDateString(original.updatedAt) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => original.id ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end", cursor: "default" }, children: /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                icon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                handler: () => __async(void 0, null, function* () {
                  return exportCsv(original);
                }),
                label: polyglot.t("General.exportAsCSV")
              },
              {
                icon: /* @__PURE__ */ jsx(Report, __spreadValues({}, iconSize)),
                handler: () => __async(void 0, null, function* () {
                  yield copyReport(original);
                }),
                label: "Save as new report"
              },
              {
                icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                handler: () => {
                  setReportToDelete(original);
                  setIsDeleteDrawerOpen(true);
                },
                label: polyglot.t("General.delete")
              }
            ],
            actionButtonDetails: {
              type: "iconButton",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "actions",
              icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
            }
          }
        ) }) : null
      }
    ],
    [polyglot, getCachedUserById, exportCsv, copyReport]
  );
  const handleRowClick = useCallback(
    (row) => {
      if (target === "all")
        routerHistory.push(generatePath(REPORTS_COMPANY_VIEW_REPORT_ROUTE, { reportId: row.original.id }));
      else routerHistory.push(generatePath(REPORTS_TEAM_VIEW_REPORT_ROUTE, { reportId: row.original.id }));
    },
    [routerHistory, target]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("ReportsPage.reports") }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => target === "all" ? routerHistory.push(REPORTS_COMPANY_CREATE_ROUTE) : routerHistory.push(REPORTS_TEAM_CREATE_ROUTE),
            children: polyglot.t("ReportsPage.new")
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoading, sx: { pt: 0, pb: 0, height: "100%" }, border: false, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredData ? [...filteredData] : [],
          columnData: columns,
          loading: isLoading,
          rowClick: handleRowClick
        }
      ) }),
      /* @__PURE__ */ jsx(
        ReportDeleteDrawer,
        {
          isOpen: isDeleteDrawerOpen,
          setIsOpen: setIsDeleteDrawerOpen,
          report: reportToDelete,
          refresh: refreshReportsList,
          onClose: () => {
            setReportToDelete(null);
          },
          target
        }
      )
    ] })
  ] });
};
