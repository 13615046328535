"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, InputAdornment } from "@mui/material";
import { Form, FormikProvider } from "formik";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TimeTextField } from "@/v2/components/forms/time-text-field.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { useSCTimelineForm } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/hook/use-sc-timeline-form.hook";
import { SurveyFrequencyEnum } from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCScheduledTimelineModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SCScheduledTimelineContent, { surveyCycle, onClose, refresh }) });
};
const SCScheduledTimelineContent = ({
  surveyCycle,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const formik = useSCTimelineForm(surveyCycle, onClose, refresh);
  const Views = [
    { name: "Start now", value: "true" },
    { name: "Schedule for later", value: "false" }
  ];
  const surveyFrequencyOptions = useMemo(() => {
    return Object.entries(SurveyFrequencyEnum).map(([value, label]) => ({
      value,
      label
    }));
  }, []);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Timeline" }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5, mb: spacing.m10 }, children: /* @__PURE__ */ jsx(
      TabFilterButtons,
      {
        filters: Views,
        setFilterValue: (value) => {
          if (value === "true") {
            formik.setFieldValue("timelineSettings.startDate", null);
            formik.setFieldValue("timelineSettings.startTime", null);
            formik.setFieldValue("timelineSettings.startTimeMeridiem", null);
          }
          formik.setFieldValue("timelineSettings.startNow", value === "true");
        },
        filterValue: formik.values.timelineSettings.startNow.toString()
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "timelineSettings.surveyOpenFor",
        label: polyglot.t("SurveyParticipantDrawer.surveyOpenFor"),
        value: formik.values.timelineSettings.surveyOpenFor,
        onChange: formik.handleChange,
        error: ((_a = formik.touched.timelineSettings) == null ? void 0 : _a.surveyOpenFor) && !!((_b = formik.errors.timelineSettings) == null ? void 0 : _b.surveyOpenFor),
        helperText: (_e = ((_c = formik.touched.timelineSettings) == null ? void 0 : _c.surveyOpenFor) && ((_d = formik.errors.timelineSettings) == null ? void 0 : _d.surveyOpenFor)) != null ? _e : " ",
        endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "days" }) }),
        type: "number"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "timelineSettings.surveyFrequency",
        label: polyglot.t("SurveyParticipantDrawer.surveyFrequency"),
        options: surveyFrequencyOptions,
        value: formik.values.timelineSettings.surveyFrequency,
        onChange: formik.handleChange,
        compareValue: formik.values.timelineSettings.surveyFrequency,
        disabled: true
      }
    ) }),
    !formik.values.timelineSettings.startNow && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "timelineSettings.startDate",
        label: "Start date",
        sx: { width: "100%" },
        disablePast: true,
        value: formik.values.timelineSettings.startDate,
        onChange: (date) => formik.setFieldValue("timelineSettings.startDate", date)
      }
    ) }),
    !formik.values.timelineSettings.startNow && /* @__PURE__ */ jsx(
      TimeTextField,
      {
        name: "timelineSettings.startTime",
        label: "Start time (GMT)",
        value: formik.values.timelineSettings.startTime,
        onChange: (value) => {
          formik.setFieldValue("timelineSettings.startTime", value);
        },
        selectedMeridiem: formik.values.timelineSettings.startTimeMeridiem,
        sx: { width: "100%" },
        onMeridiemChange: (newMeridiem) => {
          formik.setFieldValue("timelineSettings.startTimeMeridiem", newMeridiem);
        },
        error: ((_f = formik.touched.timelineSettings) == null ? void 0 : _f.startTime) && Boolean((_g = formik.errors.timelineSettings) == null ? void 0 : _g.startTime),
        helperText: ((_h = formik.touched.timelineSettings) == null ? void 0 : _h.startTime) && ((_i = formik.errors.timelineSettings) == null ? void 0 : _i.startTime)
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
