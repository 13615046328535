"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SectionItemType } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const getDayOrDays = (days) => days > 1 ? "days" : "day";
export const timelineByReviwer = (reviewCycle, showFlags) => {
  const { timelineSettings } = reviewCycle;
  return [
    ...showFlags.showSelf ? [
      {
        type: SectionItemType.Pair,
        label: "Self review deadline",
        value: (timelineSettings == null ? void 0 : timelineSettings.selfReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.selfReviewDeadline} ${getDayOrDays(
          timelineSettings == null ? void 0 : timelineSettings.selfReviewDeadline
        )} after start` : "NA"
      }
    ] : [],
    ...showFlags.showUpward ? [
      {
        type: SectionItemType.Pair,
        label: "Upward review deadline",
        value: (timelineSettings == null ? void 0 : timelineSettings.upwardReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.upwardReviewDeadline} ${getDayOrDays(
          timelineSettings == null ? void 0 : timelineSettings.upwardReviewDeadline
        )} after start` : "NA"
      }
    ] : [],
    ...showFlags.showPeer ? [
      {
        type: SectionItemType.Pair,
        label: "Peer review deadline",
        value: (timelineSettings == null ? void 0 : timelineSettings.peerReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.peerReviewDeadline} ${getDayOrDays(
          timelineSettings == null ? void 0 : timelineSettings.peerReviewDeadline
        )} after start` : "NA"
      }
    ] : [],
    ...showFlags.showManager ? [
      {
        type: SectionItemType.Pair,
        label: "Manager review deadline",
        value: (timelineSettings == null ? void 0 : timelineSettings.managerReviewDeadline) ? `${timelineSettings == null ? void 0 : timelineSettings.managerReviewDeadline} ${getDayOrDays(
          timelineSettings == null ? void 0 : timelineSettings.managerReviewDeadline
        )} after start` : "NA"
      }
    ] : []
  ];
};
export const getDeadlineByReviewerFields = (showFlags, formik, polyglot) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t;
  const { showSelf, showUpward, showPeer, showManager } = showFlags;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    showSelf && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "timelineSettings.selfReviewDeadline",
        label: "Self review deadline",
        value: formik.values.timelineSettings.selfReviewDeadline,
        onChange: formik.handleChange,
        type: "number",
        minValue: 1,
        validateNumber: true,
        error: ((_a = formik.touched.timelineSettings) == null ? void 0 : _a.selfReviewDeadline) && !!((_b = formik.errors.timelineSettings) == null ? void 0 : _b.selfReviewDeadline),
        helperText: (_e = ((_c = formik.touched.timelineSettings) == null ? void 0 : _c.selfReviewDeadline) && ((_d = formik.errors.timelineSettings) == null ? void 0 : _d.selfReviewDeadline)) != null ? _e : " ",
        endAdornment: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "days after start" })
      }
    ),
    showUpward && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "timelineSettings.upwardReviewDeadline",
        label: "Upward review deadline",
        value: formik.values.timelineSettings.upwardReviewDeadline,
        onChange: formik.handleChange,
        type: "number",
        minValue: 1,
        validateNumber: true,
        error: ((_f = formik.touched.timelineSettings) == null ? void 0 : _f.upwardReviewDeadline) && !!((_g = formik.errors.timelineSettings) == null ? void 0 : _g.upwardReviewDeadline),
        helperText: (_j = ((_h = formik.touched.timelineSettings) == null ? void 0 : _h.upwardReviewDeadline) && ((_i = formik.errors.timelineSettings) == null ? void 0 : _i.upwardReviewDeadline)) != null ? _j : " ",
        endAdornment: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "days after start" })
      }
    ),
    showPeer && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "timelineSettings.peerReviewDeadline",
        label: "Peer review deadline",
        value: formik.values.timelineSettings.peerReviewDeadline,
        onChange: formik.handleChange,
        type: "number",
        minValue: 1,
        validateNumber: true,
        error: ((_k = formik.touched.timelineSettings) == null ? void 0 : _k.peerReviewDeadline) && !!((_l = formik.errors.timelineSettings) == null ? void 0 : _l.peerReviewDeadline),
        helperText: (_o = ((_m = formik.touched.timelineSettings) == null ? void 0 : _m.peerReviewDeadline) && ((_n = formik.errors.timelineSettings) == null ? void 0 : _n.peerReviewDeadline)) != null ? _o : " ",
        endAdornment: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "days after start" })
      }
    ),
    showManager && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "timelineSettings.managerReviewDeadline",
        label: "Manager review deadline",
        value: formik.values.timelineSettings.managerReviewDeadline,
        onChange: formik.handleChange,
        type: "number",
        minValue: 1,
        validateNumber: true,
        error: ((_p = formik.touched.timelineSettings) == null ? void 0 : _p.managerReviewDeadline) && !!((_q = formik.errors.timelineSettings) == null ? void 0 : _q.managerReviewDeadline),
        helperText: (_t = ((_r = formik.touched.timelineSettings) == null ? void 0 : _r.managerReviewDeadline) && ((_s = formik.errors.timelineSettings) == null ? void 0 : _s.managerReviewDeadline)) != null ? _t : " ",
        endAdornment: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "days after start" })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] });
};
