"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { getDateFormation } from "@/v2/feature/dashboard/dashboard.util";
import { UserEventDetailDrawer } from "@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-event-detail-drawer.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserEventCard = ({
  event,
  type,
  setSelectedAbsence
}) => {
  const { polyglot } = usePolyglot();
  const [selectedEvent, setSelectedEvent] = useState(void 0);
  const [isOpen, setIsOpen] = useState(false);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        alignItems: "flex-start",
        gap: spacing.g3,
        "&:hover": { background: themeColors.transparency, cursor: "pointer" },
        borderRadius: radius.br10,
        width: "100%"
      },
      onClick: () => {
        if (event.isAbsence) {
          setSelectedAbsence({ absenceId: event.absenceId, userId: event.userId });
        } else {
          setSelectedEvent({
            type: event.type,
            userId: event.userId,
            startDate: event.startDate,
            endDate: event.endDate,
            day: new LocalDate().toDateString()
          });
          setIsOpen(true);
        }
      },
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              height: "100%",
              width: "100%",
              padding: spacing.p3,
              gap: spacing.s1,
              overflow: "hidden"
            },
            children: [
              /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.s1,
                    justifyContent: "flex-start",
                    overflow: "hidden"
                  },
                  children: [
                    event.userId && /* @__PURE__ */ jsx(UserAvatar, { userId: event.userId, size: "s16" }, event.userId),
                    /* @__PURE__ */ jsx(
                      Typography,
                      {
                        variant: "paragraph",
                        sx: {
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "start",
                          width: "100%"
                        },
                        children: polyglot.t(event.displayName)
                      }
                    )
                  ]
                }
              ),
              /* @__PURE__ */ jsxs(
                Typography,
                {
                  variant: "paragraph",
                  sx: {
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "start",
                    width: "100%"
                  },
                  children: [
                    getDateFormation(event.startDate, event.endDate),
                    type ? `\u30FB${type}` : ""
                  ]
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(UserEventDetailDrawer, { selectedEvent, setIsOpen, isOpen })
      ]
    },
    `-${event ? event == null ? void 0 : event.userId : Math.random()}-user-absence`
  );
};
