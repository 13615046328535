"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { HelperAbsencePolicyMembersDrawer } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-members-drawer.component";
import { themeColors } from "@v2/styles/colors.styles";
export const HelperAbsencePolicyDrawer = ({ isOpen, setIsOpen, absencePolicy, onClose, users }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const [isMembersOpen, setIsMembersOpen] = useState(false);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: absencePolicy ? /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "10px", alignItems: "center", mb: "20px" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "20px", height: "20px", borderRadius: "50%", bgcolor: absencePolicy.color } }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: absencePolicy.name })
    ] }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Name", value: absencePolicy.name }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Full name", value: (_a = absencePolicy.fullName) != null ? _a : "-" }),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Allowance",
        value: absencePolicy.allowance == null ? "Unlimited" : absencePolicy.allowance
      }
    ),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Allowance type", value: absencePolicy.allowanceType }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Is public", value: absencePolicy.isPublic ? "True" : "False" }),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Cycle start (Day/Month)",
        value: `${absencePolicy.cycleStartDay}/${absencePolicy.cycleStartMonth}`
      }
    ),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Prorating FTE", value: absencePolicy.proratingFte ? "True" : "False" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Prorating StartDate", value: absencePolicy.proratingStartDate ? "True" : "False" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Allowance rounding type", value: absencePolicy.allowanceRoundingType }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Accrual unit", value: (_b = absencePolicy.accrualUnit) != null ? _b : "-" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "No of accrual unit per period", value: (_c = absencePolicy.accrualUnitsNo) != null ? _c : "-" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Accrual upfront", value: absencePolicy.accrualUnit ? "Enabled" : "Disabled" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Accrual cliff", value: (_d = absencePolicy.accrualCliff) != null ? _d : "-" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Allowance type", value: absencePolicy.allowanceType }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Carry-over enabled", value: absencePolicy.carryOverEnabled ? "True" : "False" }),
    absencePolicy.carryOverEnabled && /* @__PURE__ */ jsx(HelperDrawerField, { label: "Carry-over limit", value: (_e = absencePolicy.carryOverLimit) != null ? _e : "-" }),
    absencePolicy.carryOverEnabled && /* @__PURE__ */ jsx(HelperDrawerField, { label: "Carry-over expiration day", value: (_f = absencePolicy.carryOverExpirationDay) != null ? _f : "-" }),
    absencePolicy.carryOverEnabled && /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Carry-over expiration month",
        value: (_g = absencePolicy.carryOverExpirationMonth) != null ? _g : "-"
      }
    ),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Members rule", value: absencePolicy.membersRule }),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Selected members ids",
        value: absencePolicy.selectedMembersIds ? "See here" : "-",
        action: absencePolicy.selectedMembersIds ? () => {
          setIsMembersOpen(true);
        } : void 0
      }
    ),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Custom rule", value: (_h = absencePolicy.customRule) != null ? _h : "-" }),
    absencePolicy.backoffApprovalRule && /* @__PURE__ */ jsx(HelperDrawerField, { label: "Approval rule", value: (_i = absencePolicy.backoffApprovalRule.name) != null ? _i : "-" }),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Update approval not required",
        value: absencePolicy.updateApprovalNotRequired ? "True" : "False"
      }
    ),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Public holiday on top",
        value: absencePolicy.publicHolidayOnTop ? "True" : "False"
      }
    ),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Public holiday autobook",
        value: absencePolicy.publicHolidayAutobook ? "True" : "False"
      }
    ),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Holidays prorating rule", value: absencePolicy.holidaysProratingRule }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Requests to payroll", value: absencePolicy.inPayrollRequests ? "True" : "False" }),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Offboarding balance to payroll",
        value: absencePolicy.isPaid && absencePolicy.payCode && absencePolicy.showOnPayslip ? "True" : "False"
      }
    ),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Pay code", value: (_j = absencePolicy.payCode) != null ? _j : "-" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Pay code multiplier", value: (_k = absencePolicy.payCodeMultiplier) != null ? _k : "-" }),
    /* @__PURE__ */ jsx(HelperDrawerField, { label: "Tenure enabled", value: absencePolicy.tenureEnabled ? "True" : "False" }),
    absencePolicy.tenureEnabled && /* @__PURE__ */ jsx(HelperDrawerField, { label: "Tenure allocation", value: (_l = absencePolicy.tenureAllocation) != null ? _l : "-" }),
    absencePolicy.tenureEnabled && /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Tenure settings",
        value: absencePolicy.tenureAdditionSettings ? JSON.stringify(absencePolicy.tenureAdditionSettings, null, 2) : "-"
      }
    ),
    /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Notifications enabled",
        value: absencePolicy.notificationsEnabled ? "True" : "False"
      }
    ),
    absencePolicy.notificationsEnabled && /* @__PURE__ */ jsx(
      HelperDrawerField,
      {
        label: "Reminders settings",
        value: absencePolicy.remindersSettings ? JSON.stringify(absencePolicy.remindersSettings, null, 2) : "-"
      }
    ),
    /* @__PURE__ */ jsx(
      HelperAbsencePolicyMembersDrawer,
      {
        isOpen: isMembersOpen,
        setIsOpen: setIsMembersOpen,
        membersIds: (_m = absencePolicy.selectedMembersIds) != null ? _m : [],
        users
      }
    )
  ] }) : /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "90%", height: "90vh", sx: { borderRadius: "10px" } }) });
};
export const HelperDrawerField = ({
  label,
  value,
  action
}) => /* @__PURE__ */ jsxs(
  Box,
  {
    sx: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
      width: "100%",
      alignItems: "start",
      py: "2px",
      px: "5px",
      "&:hover": { bgcolor: themeColors.Background, borderRadius: "15px" }
    },
    children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: label }),
      /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "headline3",
          sx: {
            color: action ? "blue" : "black",
            textDecoration: action ? "underline" : "none",
            cursor: action ? "pointer" : "default"
          },
          onClick: action,
          children: value
        }
      )
    ]
  }
);
