"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReportConfigSection } from "@v2/feature/reports/reports-advanced/components/report-config.section";
import { ReportViewResult } from "@v2/feature/reports/reports-advanced/components/report-view-result.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { reverseColumnsSelectionCleanUpUsingDefaultValues } from "@v2/feature/reports/util/report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { deepCopy } from "@v2/infrastructure/object/object.util";
import { RootStyle } from "@v2/styles/root.styles";
import { LocalDate } from "@v2/util/local-date";
import { useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ReportsAdvancedCreatePage = ({ refreshReportsList, target }) => {
  var _a, _b;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getTestReportsFiltersOptions(), {
    suspense: false
  });
  const location = useLocation();
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [effectiveDate, setEffectiveDate] = useState(new LocalDate().toDateString());
  const [showEffectiveRecords, setShowEffectiveRecords] = useState(true);
  const [reportResponse, setReportResponse] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const querySettings = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryColumns = queryParams.get("columns");
    const queryFilters = queryParams.get("filters");
    const queryEffectiveDate = queryParams.get("effectiveDate");
    const queryEffectiveDateEnabled = queryParams.get("effectiveDateEnabled");
    if (!queryColumns && !queryFilters && !queryEffectiveDate && !queryEffectiveDateEnabled) return null;
    return { queryColumns, queryFilters, queryEffectiveDate, queryEffectiveDateEnabled };
  }, [location]);
  useEffect(() => {
    var _a2, _b2;
    if (!filtersAndColumns) return;
    let initialSelectedColumns = {};
    let initialSelectedFilters = {};
    let initialEffectiveDate = null;
    let initialEffectiveDateEnabled = true;
    try {
      if (querySettings) {
        initialSelectedColumns = querySettings.queryColumns ? JSON.parse(decodeURIComponent(querySettings.queryColumns)) : {};
        initialSelectedFilters = querySettings.queryFilters ? JSON.parse(decodeURIComponent(querySettings.queryFilters)) : {};
        if (querySettings.queryEffectiveDate) {
          initialEffectiveDate = JSON.parse(decodeURIComponent(querySettings.queryEffectiveDate));
        }
        if (querySettings.queryEffectiveDateEnabled) {
          const enabledValue = JSON.parse(decodeURIComponent(querySettings.queryEffectiveDateEnabled));
          initialEffectiveDateEnabled = typeof enabledValue === "boolean" ? enabledValue : Boolean(enabledValue === "true");
        }
        reverseColumnsSelectionCleanUpUsingDefaultValues(initialSelectedColumns, filtersAndColumns.columns);
      } else {
        initialSelectedColumns = deepCopy((_a2 = filtersAndColumns.defaultSelectedColumns) != null ? _a2 : {});
        initialSelectedFilters = deepCopy((_b2 = filtersAndColumns.defaultSelectedFilters) != null ? _b2 : {});
        initialEffectiveDate = new LocalDate().toDateString();
        initialEffectiveDateEnabled = true;
      }
    } catch (error) {
      console.error(error);
      showMessage(`Something went wrong. Could not load report setup. ${error.message}`, "error");
    }
    setSelectedColumns(initialSelectedColumns);
    setSelectedFilters(initialSelectedFilters);
    if (initialEffectiveDate !== null) {
      setEffectiveDate(initialEffectiveDate);
    }
    setShowEffectiveRecords(initialEffectiveDateEnabled);
  }, [filtersAndColumns, querySettings, showMessage]);
  const updateURLWithSettings = useCallback(() => {
    const params = new URLSearchParams();
    if (Object.keys(selectedColumns).length > 0) {
      params.set("columns", encodeURIComponent(JSON.stringify(selectedColumns)));
    }
    if (Object.keys(selectedFilters).length > 0) {
      params.set("filters", encodeURIComponent(JSON.stringify(selectedFilters)));
    }
    if (effectiveDate) {
      params.set("effectiveDate", encodeURIComponent(JSON.stringify(effectiveDate)));
    }
    params.set("effectiveDateEnabled", encodeURIComponent(JSON.stringify(showEffectiveRecords)));
    window.history.replaceState(null, "", `?${params.toString()}`);
  }, [selectedColumns, selectedFilters, effectiveDate, showEffectiveRecords]);
  const generateReport = useCallback(() => __async(void 0, null, function* () {
    if (Object.keys(selectedColumns).length === 0 && Object.keys(selectedFilters).length === 0) {
      setReportResponse(null);
      return;
    }
    try {
      setIsGenerating(true);
      updateURLWithSettings();
      const response = yield ReportsAPI.generateReport(
        selectedColumns,
        selectedFilters,
        effectiveDate,
        showEffectiveRecords,
        target
      );
      setReportResponse(response);
    } catch (error) {
      setReportResponse(null);
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsGenerating(false);
  }), [
    selectedColumns,
    selectedFilters,
    effectiveDate,
    showEffectiveRecords,
    polyglot,
    showMessage,
    target,
    updateURLWithSettings
  ]);
  useEffect(() => {
    if (Object.keys(selectedColumns).length > 0) generateReport();
    else setReportResponse(null);
  }, [selectedColumns, generateReport]);
  return /* @__PURE__ */ jsxs(RootStyle, { style: { display: "flex", flexDirection: "row" }, children: [
    /* @__PURE__ */ jsx(
      ReportConfigSection,
      {
        selectedColumns,
        setSelectedColumns,
        selectedFilters,
        setSelectedFilters,
        reportColumns: (_a = filtersAndColumns == null ? void 0 : filtersAndColumns.columns) != null ? _a : [],
        reportFilters: (_b = filtersAndColumns == null ? void 0 : filtersAndColumns.filters) != null ? _b : [],
        target,
        effectiveDate,
        setEffectiveDate,
        showEffectiveRecords,
        setShowEffectiveRecords
      }
    ),
    /* @__PURE__ */ jsx(
      ReportViewResult,
      {
        report: null,
        reportResponse,
        isLoading: !!isLoading || isGenerating,
        selectedColumns,
        selectedFilters,
        refreshReportsList,
        refreshReport: generateReport,
        target,
        effectiveDate,
        effectiveDateEnabled: showEffectiveRecords
      }
    )
  ] });
};
