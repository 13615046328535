"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TableSearch } from "@v2/components/table/table-search.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { HelperDrawerField } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-drawer.component";
export const HelperAbsencePolicyMembersDrawer = ({ isOpen, setIsOpen, users, membersIds }) => {
  const [searchInput, setSearchInput] = useState("");
  const members = useMemo(() => {
    return membersIds.map((memberId) => {
      var _a;
      const m = users.find((u) => u.userId === memberId);
      return { userId: (_a = m == null ? void 0 : m.userId) != null ? _a : memberId, name: m ? `${m.firstName} ${m.lastName}` : "" };
    }).sort((a, b) => a.name > b.name ? 1 : -1);
  }, [users, membersIds]);
  const filteredMembers = useMemo(() => {
    if (!searchInput) return members;
    const search = searchInput.toLowerCase();
    return members.filter((m) => m.name.toLowerCase().includes(search) || m.userId.toString().includes(search));
  }, [members, searchInput]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { mb: "20px" }, children: "Members" }),
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => {
          setSearchInput(e.target.value);
        },
        style: { width: "100%", marginBottom: "10px" },
        placeholder: "Search"
      }
    ),
    filteredMembers.map((member) => /* @__PURE__ */ jsx(HelperDrawerField, { label: member.name, value: member.userId }, member.userId))
  ] }) });
};
