"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { MonitoringAPI } from "@v2/feature/monitoring/monitoring.api";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const ViewQueueToCleanUpSummaryDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  queues
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: queues ? /* @__PURE__ */ jsx(
  ViewQueueToCleanUpSummaryDrawerContent,
  {
    queues,
    onAdd: () => __async(void 0, null, function* () {
      yield onClose();
      setIsOpen(false);
    })
  }
) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { children: "No queues" }) }) });
export const ViewQueueToCleanUpSummaryDrawerContent = ({
  queues,
  onAdd
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState();
  const [showMessage] = useMessage();
  const cleanQueue = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (selectedQueue) {
        yield MonitoringAPI.cleanInHouseMdmQueues(selectedQueue);
      }
      showMessage("Queues clean-up started", "success");
      yield onAdd();
    } catch (error) {
      showMessage(`Queues clean-up failed: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const handleChange = (event) => {
    setSelectedQueue(event.target.value);
  };
  const mappedQueues = Array.from(queues);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey, mt: spacing.mt10 }), children: "Check logs, to see queues stats" }),
    /* @__PURE__ */ jsx(Box, { children: mappedQueues.map(([key, value]) => /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: [
        "Queue: ",
        key
      ] }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: [
        "Number of completed jobs: ",
        value
      ] })
    ] }, value)) }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey, mt: spacing.mt10 }), children: "Select queue to clean up" }),
      /* @__PURE__ */ jsxs(FormControl, { fullWidth: true, sx: { mt: spacing.mt10 }, children: [
        /* @__PURE__ */ jsx(InputLabel, { id: "demo-simple-select-label", children: "Queues" }),
        /* @__PURE__ */ jsxs(
          Select,
          {
            labelId: "demo-simple-select-label",
            id: "demo-simple-select",
            value: selectedQueue,
            label: "Queues",
            onChange: handleChange,
            children: [
              /* @__PURE__ */ jsx(MenuItem, { value: "inHouseMdmDeviceSync", children: "inHouseMdmDeviceSync" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "inHouseMdmLockDevice", children: "inHouseMdmLockDevice" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "inHouseMdmWipeDevice", children: "inHouseMdmWipeDevice" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "mdmDepQueue", children: "mdmDepQueue" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "mdmEnrolmentStatus", children: "mdmEnrolmentStatus" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "mdmCommandSecurityPolicyQueue", children: "mdmCommandSecurityPolicyQueue" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "deviceInstalledAppQueue", children: "deviceInstalledAppQueue" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "deviceManagedApplicationQueue", children: "deviceManagedApplicationQueue" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "mdmDepDeviceInfoQueue", children: "mdmDepDeviceInfoQueue" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "mdmQueue", children: "mdmQueue" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "mdmStateSync", children: "mdmStateSync" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "securityPolicyStatusQueue", children: "securityPolicyStatusQueue" }),
              /* @__PURE__ */ jsx(MenuItem, { value: "mdmFileVaultStatusQueue", children: "mdmFileVaultStatusQueue" })
            ]
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Clean queue",
        fullWidth: true,
        loading,
        onClick: () => __async(void 0, null, function* () {
          return cleanQueue();
        }),
        colorVariant: "primary",
        sizeVariant: "large"
      }
    ) })
  ] });
};
