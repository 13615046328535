"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { ColumnsDrawer } from "@v2/components/table/columns-drawer.component";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { StyledTabsComponent } from "@v2/components/theme-components/styled-tabs.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ResultTableOldReports } from "@v2/feature/reports/components/result-table.component";
import {
  UpdateReportDatesDrawer
} from "@v2/feature/reports/features/update-report/people/update-report-dates-drawer.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { DateType } from "@v2/feature/reports/reports.interface";
import {
  getAllSelectedColumnsFromUserReportColumnsOptions,
  getFilterColumnsPeopleChangeReport,
  getPeopleReportFilters
} from "@v2/feature/reports/util/people-report.util";
import { exportReportCSVOldReports } from "@v2/feature/reports/util/report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Calendar } from "@/images/side-bar-icons/Calendar.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_OVERVIEW } from "@/lib/routes";
export const PeopleChangeUpdateReport = ({
  report,
  loading,
  customFields,
  additionalColumns,
  refreshReport
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false
  });
  const { hasScopes } = useScopes();
  const [filteredData, setFilteredData] = useState(void 0);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState(
    void 0
  );
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState((_b = (_a = report == null ? void 0 : report.config) == null ? void 0 : _a.filters) != null ? _b : "");
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [reportLoading, setReportsLoading] = useState(true);
  const [reportName, setReportName] = useState((_c = report == null ? void 0 : report.name) != null ? _c : "");
  const [currentTab, setCurrentTab] = useState(
    ((_d = report.config) == null ? void 0 : _d.entities) && report.config.entities[0] ? (_e = report.config) == null ? void 0 : _e.entities[0] : ""
  );
  const [isEditDatesOpen, setIsEditDatesOpen] = useState(false);
  const reportDates = useMemo(() => {
    if (!report) return "";
    if (report.config.dateType === DateType.Last30days) return polyglot.t("PeopleChangeUpdateReport.thirtyDays");
    if (report.config.dateType === DateType.Last7days) return polyglot.t("PeopleChangeUpdateReport.sevenDays");
    if (report.config.start && report.config.end)
      return `${new LocalDate(report.config.start).toLocaleDateString()} - ${new LocalDate(
        report.config.end
      ).toLocaleDateString()}`;
  }, [report, polyglot]);
  const tabs = useMemo(
    () => {
      var _a2, _b2, _c2;
      return (_c2 = (_b2 = (_a2 = report.config) == null ? void 0 : _a2.entities) == null ? void 0 : _b2.map((entity) => ({
        label: entity,
        value: entity
      }))) != null ? _c2 : [];
    },
    [(_f = report.config) == null ? void 0 : _f.entities]
  );
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const userEntities = useMemo(() => report.config.entities, [(_g = report.config) == null ? void 0 : _g.entities]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState(
    userEntities && userEntities.length > 0 ? (_h = selectedColumns[userEntities[0]]) != null ? _h : [] : []
  );
  useEffect(() => {
    setCurrentTab((prev) => {
      var _a2, _b2;
      return prev && tabs.some((t) => t.value === prev) ? prev : (_b2 = (_a2 = tabs[0]) == null ? void 0 : _a2.value) != null ? _b2 : "";
    });
  }, [tabs]);
  useEffect(() => {
    var _a2, _b2;
    const selectedCols = ((_a2 = report.config) == null ? void 0 : _a2.columns) ? report.config.columns : getAllSelectedColumnsFromUserReportColumnsOptions(
      userEntities,
      customFields,
      hasScopes,
      additionalColumns,
      polyglot
    );
    setSelectedColumns(selectedCols);
    setSelectedColumnsStrings(
      currentTab ? (_b2 = selectedCols[currentTab]) != null ? _b2 : [] : []
    );
    setAllDataLoaded(true);
  }, [polyglot, currentTab, hasScopes, (_i = report.config) == null ? void 0 : _i.columns, userEntities, customFields, additionalColumns]);
  useEffect(() => {
    if (!allDataLoaded) return;
    setSelectedColumns((prev) => __spreadProps(__spreadValues({}, prev), { [currentTab]: selectedColumnsStrings }));
  }, [allDataLoaded, currentTab, selectedColumnsStrings]);
  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);
  const getReportData = useCallback(
    (formData, selectedColumns2, filterString2) => __async(void 0, null, function* () {
      setReportsLoading(true);
      try {
        let filteredUsers = yield ReportsAPI.generatePeopleCreatedChangeReport(__spreadProps(__spreadValues({}, formData.config), {
          filters: filterString2,
          columns: selectedColumns2
        }));
        setFilteredData(filteredUsers != null ? filteredUsers : []);
      } catch (error) {
        showMessage(
          `${polyglot.t("PeopleChangeUpdateReport.errorMessages.load")}. ${nestErrorMessage(error)}`,
          "error"
        );
      }
      setReportsLoading(false);
    }),
    [polyglot, showMessage]
  );
  useEffect(() => {
    if (report && allDataLoaded) getReportData(report, selectedColumns, filterString);
  }, [allDataLoaded, getReportData, report, filterString, selectedColumns]);
  useEffect(() => {
    var _a2;
    setFilteredAndSearchedData(
      filteredData && currentTab && filteredData[currentTab] ? (_a2 = filteredData[currentTab]) == null ? void 0 : _a2.filter(
        (data) => !searchInput || (data == null ? void 0 : data.employeeName) && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase())
      ) : void 0
    );
  }, [currentTab, searchInput, filteredData]);
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      if (report) {
        const paramObj = {
          filters: filterString,
          columns: selectedColumns,
          fileName: reportName != null ? reportName : report.name
        };
        yield ReportsAPI.patchReport(report.id, paramObj);
      }
      showMessage(polyglot.t("PeopleChangeUpdateReport.successMessages.update"), "success");
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  }), [polyglot, showMessage, report, reportName, routerHistory, filterString, selectedColumns]);
  const exportCsv = useCallback(() => {
    try {
      exportReportCSVOldReports(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t("PeopleChangeUpdateReport.errorMessages.download"), "error");
    }
  }, [polyglot, showMessage, reportName, filteredData]);
  const updateReportDates = useCallback(
    (datesUpdate) => __async(void 0, null, function* () {
      try {
        if (datesUpdate.dateType && datesUpdate.start && datesUpdate.end) {
          yield ReportsAPI.patchReportDates(report.id, datesUpdate);
          showMessage(polyglot.t("PeopleChangeUpdateReport.successMessages.update"), "success");
          yield refreshReport();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [polyglot, report.id, refreshReport, showMessage]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        showBack: true,
        title: reportName ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
          reportDates && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: reportDates }),
          /* @__PURE__ */ jsx(
            EditableTitle,
            {
              variant: "headline1",
              value: reportName,
              onChange: (value) => {
                setReportName(value);
              },
              maxLength: 50,
              editButtonTooltip: void 0
            }
          )
        ] }) : /* @__PURE__ */ jsx(Fragment, {}),
        showAction: true,
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              startIcon: /* @__PURE__ */ jsx(Calendar, __spreadValues({}, iconSize)),
              onClick: () => setIsEditDatesOpen(true),
              children: polyglot.t("PeopleChangeUpdateReport.edit")
            }
          ),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              startIcon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
              onClick: exportCsv,
              children: polyglot.t("PeopleChangeUpdateReport.downloadCsv")
            }
          ),
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: handleSave, children: polyglot.t("General.save") }),
          /* @__PURE__ */ jsx(
            UpdateReportDatesDrawer,
            {
              isOpen: isEditDatesOpen,
              setIsOpen: setIsEditDatesOpen,
              reportDates: { dateType: report.config.dateType, start: report.config.start, end: report.config.end },
              action: updateReportDates
            }
          )
        ] })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { pt: 0 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20, display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          ColumnsDrawer,
          {
            columnsOptions: getFilterColumnsPeopleChangeReport(
              currentTab,
              userEntities,
              customFields,
              hasScopes,
              additionalColumns,
              polyglot
            ),
            selectedColumns: selectedColumnsStrings,
            setSelectedColumns: setSelectedColumnsStrings
          }
        ),
        /* @__PURE__ */ jsx(
          FiltersDrawer,
          {
            filtersOptions: [{ filters: filterTypes }],
            selectedFilters: filterString,
            setSelectedFilters: setFilterString,
            encodedFilterNames: true
          }
        ),
        /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
          var _a2, _b2;
          return setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
        } })
      ] }),
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: tabs ? /* @__PURE__ */ jsx(StyledTabsComponent, { tabs, currentTab, setCurrentTab }) : /* @__PURE__ */ jsx(Skeleton, { variant: "text", sx: { backgroundColor: themeColors.Background } }) }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(ResultTableOldReports, { filteredData: filteredAndSearchedData != null ? filteredAndSearchedData : [], loading: reportLoading }) })
      ] })
    ] })
  ] });
};
