"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { HealthAPI } from "@/api-client/health.api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { DASHBOARD_ROUTE } from "@/lib/routes";
import { CompanyUserHeader } from "@/v2/feature/app-layout/features/sidebar/components/company-user-header.component";
import {
  NavigationSection
} from "@/v2/feature/app-layout/features/sidebar/components/navigation-section.component";
import { getAdminSidebarConfig } from "@/v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util";
import { neutralColors, themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const DRAWER_WIDTH = 200;
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex
    })
  }
}));
export const SuperAdminSidebar = () => {
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const [sideBarConfig, setSideBarConfig] = useState([]);
  const [serverTZ, setServerTZ] = useState("UTC");
  const toggleDrawerState = (drawerState) => {
    dispatch({ type: GlobalStateActions.UPDATE_DRAWER, payload: drawerState });
  };
  useEffect(() => {
    (() => {
      if (state.drawerState) {
        toggleDrawerState(false);
      }
      const config = getAdminSidebarConfig();
      setSideBarConfig(config);
    })();
  }, [user]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const serverTZ2 = yield HealthAPI.getServersTimeZone();
        setServerTZ(serverTZ2);
      } catch (error) {
        setServerTZ("Could not be retrieved");
      }
    }))();
  }, []);
  const renderContent = /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({
        paddingTop: spacing.p35
      }, spacing.px20), {
        background: neutralColors.n0,
        display: "flex",
        flexDirection: "column",
        height: "100vh"
      }),
      children: [
        /* @__PURE__ */ jsx(CompanyUserHeader, { redirectPath: DASHBOARD_ROUTE, headerName: "Backoffice" }),
        serverTZ !== "UTC" && /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m10, p: spacing.m5, border: "2px solid red", borderRadius: "10px" }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Mistake, { width: 25, height: 25 }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.Red }), children: "WARNING!!!" })
          ] }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.Red }), children: "Server's timezone is not UTC!" }),
          /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.headline3, children: [
            "Server TZ = ",
            serverTZ
          ] })
        ] }),
        /* @__PURE__ */ jsx(NavigationSection, { navConfig: sideBarConfig })
      ]
    }
  );
  return /* @__PURE__ */ jsxs(
    RootStyle,
    {
      sx: {
        width: {
          lg: DRAWER_WIDTH
        }
      },
      children: [
        /* @__PURE__ */ jsx(
          Drawer,
          {
            open: state.drawerState,
            onClose: () => toggleDrawerState(false),
            PaperProps: {
              sx: { width: DRAWER_WIDTH }
            },
            children: renderContent
          }
        ),
        /* @__PURE__ */ jsx(
          Drawer,
          {
            open: true,
            variant: "persistent",
            PaperProps: {
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: "background.default",
                overflowY: "auto"
              }
            },
            children: renderContent
          }
        )
      ]
    }
  );
};
