"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import { SuperAdminBillingCompanySubscriptionEditForm } from "../components/super-admin-billing-company-subscription-edit-form.component";
import { SuperAdminBillingCompanySubscriptionEditModuleForm } from "../components/super-admin-billing-company-subscription-edit-module-form.component";
import { CompanyEndpoints } from "@/api-client/company.api";
import { CompanyAPI } from "@/api-client/index.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { FreeProductPlans, PaidProductPlans } from "@/models/subscription.model";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableFilter } from "@/v2/components/table/table-filter.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SuperAdminBillingPlanEditModal } from "@/v2/feature/super-admin/features/super-admin-billing/components/super-admin-billing-plan-edit-modal.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { toTitleCase } from "@/v2/util/string.util";
export function SuperAdminBillingCompanySubscriptionsPage({ pageConfig }) {
  var _a, _b, _c, _d;
  const [companySubscriptions, setCompanySubscriptions] = useState([]);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false);
  const [selectedCompanySubscriptionToEdit, setSelectedCompanySubscriptionToEdit] = useState();
  const [showMessage] = useMessage();
  const [sorting, setSorting] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditModuleDrawerOpen, setIsEditModuleDrawerOpen] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [editModalCloseIcon, setEditModalCloseIcon] = useState("close");
  const [moduleBeingEdited, setModuleBeingEdited] = useState(void 0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterString, setFilterString] = useState(``);
  const { data: availablePlans, isLoading: loadingPlans } = useApiClient(
    CompanyEndpoints.getAvailablePlanDefinitions(),
    {
      suspense: false
    }
  );
  const [filterTypes, setFilterTypes] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const debouncedFilter = useDebouncedCallback((filterString2) => __async(this, null, function* () {
    try {
      setFilterString(filterString2);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage(`Failed to handle filterL ${nestErrorMessage(error)}`, "error");
    }
  }), 500);
  const handleFilter = useCallback((event) => debouncedFilter.callback(event), [debouncedFilter]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const getFilterOptions = useCallback(() => __async(this, null, function* () {
    const PLAN_FILTER_TYPES = [
      { label: "Core", value: FreeProductPlans.PEOPLE_FREE.toString() },
      { label: "People Pro", value: PaidProductPlans.PEOPLE_PRO.toString() },
      { label: "Tech Pro", value: PaidProductPlans.APPS_PRO.toString() },
      { label: "Growth Pro", value: PaidProductPlans.DEVICES_PRO.toString() },
      { label: "Money Pro", value: PaidProductPlans.MONEY_PRO.toString() }
    ];
    const FILTERS = ["Plans"];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case "Plans":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: PLAN_FILTER_TYPES
          });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), []);
  const fetchCompanySubscriptions = useCallback(
    (params) => __async(this, null, function* () {
      try {
        const { subscriptions, pagination: paginationInfo } = yield CompanyAPI.getAllCompanySubscriptions(params);
        setCompanySubscriptions(subscriptions || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
      } catch (error) {
        showMessage(`Something went wrong fetching company plans. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const refresh = useCallback(() => __async(this, null, function* () {
    try {
      yield fetchCompanySubscriptions({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split("Plans=")[1] || ""
      });
      setIsEditModalOpen(false);
    } catch (error) {
      showMessage(`Failed to refresh. ${nestErrorMessage(error)}`, "error");
    }
  }), [fetchCompanySubscriptions, pageIndex, searchQuery, filterString, showMessage]);
  useEffect(() => {
    (() => __async(this, null, function* () {
      setIsLoadingSubscriptions(true);
      yield fetchCompanySubscriptions({
        page: pageIndex.toString(),
        pageSize: pageSize.toString(),
        searchQuery,
        // e.g of filterString `plans=1,3,4`
        plans: filterString.split("Plans=")[1] || ""
      });
      yield getFilterOptions();
      setIsLoadingSubscriptions(false);
    }))();
  }, [
    fetchCompanySubscriptions,
    setIsLoadingSubscriptions,
    getFilterOptions,
    pageIndex,
    pageSize,
    searchQuery,
    filterString
  ]);
  const companyPlanColumn = useMemo(
    () => [
      {
        header: () => "Company id",
        accessorFn: (row) => row,
        id: "companyId",
        enableSorting: true,
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
              color: themeColors.DarkGrey,
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: original == null ? void 0 : original.companyId
          }
        ) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        size: 50
      },
      {
        header: () => "Company name",
        accessorFn: (row) => row,
        id: "companyName",
        enableSorting: true,
        cell: ({ row: { original } }) => {
          var _a2, _b2;
          return ((_a2 = original.company) == null ? void 0 : _a2.name) ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                color: themeColors.DarkGrey,
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: (_b2 = original.company) == null ? void 0 : _b2.name
            }
          ) }) : /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                color: themeColors.Grey
              }),
              children: "\u2014"
            }
          );
        },
        size: 150
      },
      {
        header: () => "Seats method",
        accessorFn: (row) => row,
        id: "seatsMethod",
        enableSorting: true,
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
              color: themeColors.DarkGrey,
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: (original == null ? void 0 : original.seatsMethod) ? toTitleCase(original.seatsMethod) : "-"
          }
        ) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        size: 90
      },
      {
        header: () => "Subscription type",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: true,
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
              color: themeColors.DarkGrey,
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: (original == null ? void 0 : original.type) ? toTitleCase(original.type) : "-"
          }
        ) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        size: 70
      },
      {
        header: () => "Modules",
        accessorFn: (row) => row,
        id: "modules",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2, _b2;
          return original.companyId && ((_a2 = original.subscribedPlans) == null ? void 0 : _a2.length) > 0 ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                color: themeColors.DarkGrey,
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: ((_b2 = original.subscribedPlans) == null ? void 0 : _b2.length) > 0 ? original.subscribedPlans.map((p) => p.planName).join(", ") : "None"
            }
          ) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 220
      },
      {
        header: () => "End date",
        accessorFn: (row) => row,
        id: "endDate",
        enableSorting: true,
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
              color: themeColors.DarkGrey,
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: (original == null ? void 0 : original.endDate) ? dateAPItoDisplay(original == null ? void 0 : original.endDate) : "\u2014"
          }
        ) }) : /* @__PURE__ */ jsx(EmptyCell, {}),
        size: 220
      }
    ],
    []
  );
  const handleRowClick = useCallback((row) => {
    setSelectedCompanySubscriptionToEdit(row.original);
    setIsEditModalOpen(true);
  }, []);
  const handleEditModule = useCallback(
    (moduleToEdit, updatedSubscription) => {
      setEditModalCloseIcon("back");
      setModuleBeingEdited(moduleToEdit);
      setSelectedCompanySubscriptionToEdit(updatedSubscription);
      setIsEditModuleDrawerOpen(true);
    },
    []
  );
  const updateModuleInSubscription = (updatedModule) => {
    if (!selectedCompanySubscriptionToEdit || !selectedCompanySubscriptionToEdit.subscribedPlans) {
      console.error("selectedCompanySubscriptionToEdit or subscribedPlans is not defined");
      return;
    }
    const moduleIndex = selectedCompanySubscriptionToEdit.subscribedPlans.findIndex(
      (p) => p.planDefinitionId === updatedModule.planDefinitionId
    );
    if (moduleIndex >= 0) {
      const updatedSubscription = __spreadProps(__spreadValues({}, selectedCompanySubscriptionToEdit), {
        subscribedPlans: [
          ...selectedCompanySubscriptionToEdit.subscribedPlans.slice(0, moduleIndex),
          updatedModule,
          ...selectedCompanySubscriptionToEdit.subscribedPlans.slice(moduleIndex + 1)
        ]
      });
      setSelectedCompanySubscriptionToEdit(updatedSubscription);
    } else {
      console.error("Module not found in the current subscription plans");
    }
    setIsEditModuleDrawerOpen(false);
  };
  const handleDeleteModule = (moduleToDelete) => {
    if (!selectedCompanySubscriptionToEdit || !selectedCompanySubscriptionToEdit.subscribedPlans) {
      console.error("selectedCompanySubscriptionToEdit or subscribedPlans is not defined");
      return;
    }
    const updatedSubscription = __spreadProps(__spreadValues({}, selectedCompanySubscriptionToEdit), {
      subscribedPlans: selectedCompanySubscriptionToEdit.subscribedPlans.filter(
        (p) => p.planDefinitionId !== moduleToDelete.planDefinitionId
      )
    });
    setSelectedCompanySubscriptionToEdit(updatedSubscription);
    setIsEditModuleDrawerOpen(false);
  };
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Billing" }),
        showAction: true
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoadingSubscriptions, secondLevel: true, children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: __spreadProps(__spreadValues({
            display: "flex",
            justifyContent: "flex-start",
            width: "100%"
          }, spacing.mb20), {
            gap: "5px",
            alignItems: "center"
          }),
          children: [
            /* @__PURE__ */ jsx(
              TableSearch,
              {
                style: { width: "15em" },
                query: searchQuery,
                handleChange: (e) => {
                  var _a2, _b2;
                  setSearchQuery((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                  setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                },
                placeholder: "Enter company id or name..."
              }
            ),
            filterTypes && /* @__PURE__ */ jsx(TableFilter, { filterTypes, setFilterString: handleFilter, filterString })
          ]
        }
      ),
      !isLoadingSubscriptions && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          BasicServerTable,
          {
            rowData: [...companySubscriptions],
            columnData: companyPlanColumn,
            sorting,
            setSorting,
            pagination,
            setPagination,
            totalPages,
            totalItems,
            rowClick: handleRowClick
          }
        ),
        selectedCompanySubscriptionToEdit && availablePlans && (!loadingPlans || !isLoadingSubscriptions) && /* @__PURE__ */ jsx(SuperAdminBillingPlanEditModal, { isOpen: isEditModalOpen, setIsOpen: setIsEditModalOpen, children: /* @__PURE__ */ jsx(
          SuperAdminBillingCompanySubscriptionEditForm,
          {
            companySubscription: __spreadValues({}, selectedCompanySubscriptionToEdit),
            availablePlans,
            onSave: refresh,
            onEditModule: handleEditModule
          },
          JSON.stringify(selectedCompanySubscriptionToEdit)
        ) }),
        moduleBeingEdited && selectedCompanySubscriptionToEdit && availablePlans && isEditModuleDrawerOpen && (!loadingPlans || !isLoadingSubscriptions) && /* @__PURE__ */ jsx(
          SuperAdminBillingPlanEditModal,
          {
            isOpen: isEditModuleDrawerOpen,
            setIsOpen: setIsEditModuleDrawerOpen,
            closeIcon: editModalCloseIcon,
            afterClose: () => {
              setModuleBeingEdited(void 0);
              setEditModalCloseIcon("close");
            },
            children: /* @__PURE__ */ jsx(
              SuperAdminBillingCompanySubscriptionEditModuleForm,
              {
                selectedModule: __spreadValues({}, moduleBeingEdited),
                updateSubscriptionInParentDrawer: updateModuleInSubscription,
                companyName: (_d = (_c = selectedCompanySubscriptionToEdit == null ? void 0 : selectedCompanySubscriptionToEdit.company) == null ? void 0 : _c.name) != null ? _d : "",
                subscriptionCurrency: selectedCompanySubscriptionToEdit == null ? void 0 : selectedCompanySubscriptionToEdit.currency,
                handleDeleteModule
              }
            )
          }
        )
      ] })
    ] })
  ] });
}
