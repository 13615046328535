"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { ChipComponent } from "@v2/components/chip/chip.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { QuestionType } from "@v2/feature/growth/reviews/interfaces/review-question.interface";
import { GridDisplayBarV2 } from "@v2/feature/growth/shared/components/grid-display-bar.component";
import { SurveyQuestionGridLine } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-question-grid-line.component";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const SurveyQuestionDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  questionItem,
  growthFactors,
  loading = false,
  widthPercentage = 95,
  impactResult,
  impactFilter,
  measureByFilter,
  gridsXs
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose,
      loading,
      widthPercentage,
      children: questionItem ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g48 }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s3 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Question" }),
          /* @__PURE__ */ jsx(
            SurveyQuestionGridLine,
            {
              item: questionItem,
              growthFactors,
              impactResult,
              impactFilter,
              measureByFilter,
              gridsXs,
              sx: { background: themeColors.TableHover }
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { mb: spacing.s1 }, children: "Answers" }),
          questionItem.answersLowerThanThreshold ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Not sufficient data to show results." }) : questionItem.answers && questionItem.answers.map((a) => /* @__PURE__ */ jsx(SurveyAnswerComponent, { answer: a, question: questionItem.question }))
        ] })
      ] }) : /* @__PURE__ */ jsx(Fragment, {})
    }
  );
};
const SurveyAnswerComponent = ({
  answer,
  question
}) => {
  var _a, _b, _c, _d;
  const scaleLabel = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e;
    return answer.answer && question ? `${(_b2 = (_a2 = question.scaleConfig) == null ? void 0 : _a2.points) == null ? void 0 : _b2[answer.answer]} (${((_d2 = (_c2 = question.scaleConfig) == null ? void 0 : _c2.value) == null ? void 0 : _d2[answer.answer]) || 0} out of ${Math.max(...Object.values(((_e = question.scaleConfig) == null ? void 0 : _e.value) || []).map(Number))})` : "NA";
  }, [answer.answer, question]);
  if (!question) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      showBorder: true,
      cells: [
        {
          content: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.g8 }, children: [
            question.hasComment ? answer.comment ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", dangerouslySetInnerHTML: { __html: (_a = answer.comment) != null ? _a : "" } }) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "No comment provided" }) : /* @__PURE__ */ jsx(Fragment, {}),
            /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: /* @__PURE__ */ jsx(
              ChipComponent,
              {
                name: answer.participantId ? /* @__PURE__ */ jsx(
                  UserCell,
                  {
                    userId: answer.participantId,
                    nameVariant: "paragraph",
                    nameSx: { color: themeColors.DarkGrey }
                  }
                ) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Author hidden" }),
                backgroundColor: "white",
                border: "middleGrey2"
              }
            ) })
          ] }),
          gridXs: 8
        },
        {
          content: answer.answer ? /* @__PURE__ */ jsx(Fragment, { children: question.type === QuestionType.ScaleQuestion ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: scaleLabel }) : question.type === QuestionType.SingleAnswer ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: answer ? (_c = (_b = question.choiceOptions) == null ? void 0 : _b[Number(answer.answer)]) != null ? _c : "NA" : "NA" }) : question.type === QuestionType.MultipleAnswer ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: answer && question.choiceOptions ? (_d = answer.answer.split(",").map((choice) => question.choiceOptions[Number(choice)]).join(", ")) != null ? _d : "NA" : "NA" }) : /* @__PURE__ */ jsx(Fragment, {}) }) : /* @__PURE__ */ jsx(Fragment, {}),
          gridXs: 4,
          sx: { display: "flex", justifyContent: "flex-start" }
        }
      ]
    }
  );
};
