"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useRef } from "react";
import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
export const NINOEntry = ({ niNumber, setNiNumber, disabled, helperText, error }) => {
  const { polyglot } = usePolyglot();
  const ninoTextField = useRef();
  const cachedNino = useRef("");
  if (typeof niNumber === "string") cachedNino.current = niNumber;
  const radioValue = useMemo(() => {
    if (niNumber === null) return "no";
    if (typeof niNumber === "string") return "yes";
    return "";
  }, [niNumber]);
  const hasNino = typeof niNumber === "string";
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.doYouHaveNINO") }),
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        value: radioValue,
        onChange: (event) => {
          if (event.target.value === "yes") {
            setNiNumber(cachedNino.current);
            setTimeout(() => {
              var _a;
              return (_a = ninoTextField.current) == null ? void 0 : _a.focus();
            });
          } else {
            setNiNumber(null);
          }
        },
        sx: { flexFlow: "row" },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "yes",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(
                Typography,
                {
                  sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                    color: themeColors.DarkGrey
                  }),
                  children: polyglot.t("General.yes")
                }
              ),
              disabled,
              sx: { width: 200 }
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "no",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(
                Typography,
                {
                  sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                    color: themeColors.DarkGrey
                  }),
                  children: polyglot.t("General.no")
                }
              ),
              disabled
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(Stack, { sx: { overflow: "hidden" }, children: /* @__PURE__ */ jsx(Stack, { sx: { mt: hasNino ? 0 : "-30%", transition: "margin-top 0.4s" }, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        ref: ninoTextField,
        label: polyglot.t("OnboardingByUser.NINO"),
        maxLength: 9,
        onChange: (e) => {
          if (niNumber !== null) setNiNumber(e.target.value.toUpperCase().replace(/[^A-Z\d]+/g, ""));
        },
        value: cachedNino.current,
        clearText: () => setNiNumber(""),
        disabled: disabled || !hasNino,
        helperText,
        error
      }
    ) }) })
  ] }) });
};
