"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { ListItemIcon } from "@mui/material";
import Lottie from "lottie-react";
import { useHistory, useLocation } from "react-router-dom";
import SettingsLoop from "@/animations/Settings/Settings_loop_30x30.json";
import SettingsLoopFill from "@/animations/Settings/Settings_loop_FILL_30x30.json";
import { SETTINGS_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import { NavItemStyle } from "@/v2/styles/list-button.styles";
const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
`;
export const CompanySettings = ({ headerName, redirectPath }) => {
  const routerHistory = useHistory();
  const { pathname } = useLocation();
  const lottieRef1 = useRef(null);
  const lottieRef2 = useRef(null);
  const isSettingsRoute = pathname.startsWith(SETTINGS_ROUTE);
  useEffect(() => {
    var _a, _b;
    (_a = lottieRef1.current) == null ? void 0 : _a.setSpeed(1.3);
    (_b = lottieRef2.current) == null ? void 0 : _b.setSpeed(1.3);
  }, []);
  return /* @__PURE__ */ jsxs(
    NavItemStyle,
    {
      onClick: () => routerHistory.push(redirectPath),
      onMouseOver: () => {
        var _a, _b;
        (_a = lottieRef1.current) == null ? void 0 : _a.play();
        (_b = lottieRef2.current) == null ? void 0 : _b.play();
      },
      onMouseEnter: () => {
        var _a, _b;
        (_a = lottieRef1.current) == null ? void 0 : _a.play();
        (_b = lottieRef2.current) == null ? void 0 : _b.play();
      },
      onMouseLeave: () => {
        var _a, _b;
        (_a = lottieRef1.current) == null ? void 0 : _a.stop();
        (_b = lottieRef2.current) == null ? void 0 : _b.stop();
      },
      children: [
        /* @__PURE__ */ jsxs(ListItemIcon, { sx: { display: "flex", justifyContent: "center" }, children: [
          /* @__PURE__ */ jsx(LottieContainer, { style: { display: isSettingsRoute ? "flex" : "none" }, children: /* @__PURE__ */ jsx(
            Lottie,
            {
              lottieRef: lottieRef2,
              animationData: SettingsLoopFill,
              loop: true,
              autoplay: false,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
            }
          ) }),
          /* @__PURE__ */ jsx(LottieContainer, { style: { display: isSettingsRoute ? "none" : "flex" }, children: /* @__PURE__ */ jsx(
            Lottie,
            {
              lottieRef: lottieRef1,
              animationData: SettingsLoop,
              loop: true,
              autoplay: false,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
            }
          ) })
        ] }),
        /* @__PURE__ */ jsx(Typography, { variant: isSettingsRoute ? "headline4" : "paragraphSmall", children: headerName })
      ]
    },
    pathname
  );
};
