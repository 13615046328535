"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, SwipeableDrawer, Typography } from "@mui/material";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function StudentLoanInfo({ open, setOpen }) {
  return /* @__PURE__ */ jsx(SwipeableDrawer, { anchor: "right", open, onClose: () => setOpen(false), onOpen: () => setOpen(true), children: /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        width: 500,
        px: 6,
        height: "100%",
        verticalAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      },
      children: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Types of Student Loan" }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt40 }), children: [
          "You have a ",
          /* @__PURE__ */ jsx("b", { children: "Plan 1" }),
          " if any of the following apply:"
        ] }),
        /* @__PURE__ */ jsxs(Box, { marginY: 2, paddingLeft: 3, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "\u25CF You lived in England or Wales and started your course before 1 September 2012." }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "\u25CF You lived in Northern Ireland when you started your course." })
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
          "You have a ",
          /* @__PURE__ */ jsx("b", { children: "Plan 2" }),
          " if:"
        ] }),
        /* @__PURE__ */ jsx(Box, { marginY: 1, paddingLeft: 3, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "\u25CF You lived in England or Wales and started your course on or after 1 September 2012." }) }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
          "You have a ",
          /* @__PURE__ */ jsx("b", { children: "Plan 4" }),
          " if:"
        ] }),
        /* @__PURE__ */ jsx(Box, { marginY: 1, paddingLeft: 3, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "\u25CF You lived in Scotland and applied through the Students Award Agency Scotland (SAAS) when you started your course." }) }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt40, color: themeColors.DarkGrey }), children: "You have a Postgraduate Loan if any of the following apply:" }),
        /* @__PURE__ */ jsxs(Box, { marginY: 1, paddingLeft: 3, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "\u25CF You lived in England and started your Postgraduate Master's course on or after 1 August 2016." }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "\u25CF You lived in Wales and started your Postgraduate Master's course on or after 1 August 2017." }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "\u25CF You lived in England or Wales and started your Postgraduate Doctoral course on or after 1 August 2018" })
        ] })
      ] })
    }
  ) });
}
