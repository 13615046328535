"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MenuItem, Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import axios from "axios";
import { FILE_UPLOAD_ENDPOINT } from "@/api-client/routes";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Document } from "@/images/fields/Document.svg";
import { ReactComponent as UploadDocIcon } from "@/images/icons/AddDocument.svg";
import { ReactComponent as LinkIcon } from "@/images/icons/link.svg";
import { ReactComponent as SpinIcon } from "@/images/side-bar-icons/Spin.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ActionSelectionItemDeleteButton } from "@/v2/components/action-selection-group.component";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { StyledMenu } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DocumentEndpoints } from "@/v2/feature/documents/document.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { StyledFormHelperText } from "@/v2/styles/form-helper-text.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function stringToAttachmentComponentValue(s) {
  try {
    return JSON.parse(s);
  } catch (e) {
    return null;
  }
}
export function attachmentComponentValueToString(value) {
  return value ? JSON.stringify(value) : "";
}
export const AttachmentComponent = (_a) => {
  var _b = _a, {
    sx,
    disabled,
    helperText,
    error,
    onChange,
    name,
    label,
    value,
    showSelectExisting = false
  } = _b, props = __objRest(_b, [
    "sx",
    "disabled",
    "helperText",
    "error",
    "onChange",
    "name",
    "label",
    "value",
    "showSelectExisting"
  ]);
  const { polyglot } = usePolyglot();
  const { data: allCompanyDocuments } = useApiClient(
    DocumentEndpoints.getDocumentsByCategory({ category: "Company Documents" }, showSelectExisting),
    {
      suspense: false
    }
  );
  const fileInputRef = useRef(null);
  const [fileIsUploading, setFileIsUploading] = useState(null);
  const [linkUrl, setLinkURL] = useState("");
  const [docId, setDocId] = useState(void 0);
  const [menuMode, setMenuMode] = useState("options");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuAnchorElement = useRef(null);
  const [showMessage] = useMessage();
  const docOptions = useMemo(() => {
    var _a2;
    return (_a2 = allCompanyDocuments == null ? void 0 : allCompanyDocuments.map((d) => {
      return { label: d.name, value: d.id };
    })) != null ? _a2 : [];
  }, [allCompanyDocuments]);
  let style = __spreadValues({}, props.textSx);
  if (disabled) style = __spreadProps(__spreadValues({}, style), { color: themeColors.Grey });
  const closeMenu = useCallback(() => setMenuAnchor(null), []);
  const updateValue = useCallback(
    (newValue) => {
      onChange == null ? void 0 : onChange(newValue);
    },
    [onChange]
  );
  useEffect(() => {
    if (!menuAnchor) {
      setLinkURL("");
      setMenuMode("options");
      setDocId(void 0);
    }
  }, [menuAnchor]);
  const uploadFile = useCallback(
    (file) => __async(void 0, null, function* () {
      let newValue = null;
      setFileIsUploading(file);
      try {
        const formData = new FormData();
        formData.append("file", file);
        const uploadedFile = (yield axios.post(FILE_UPLOAD_ENDPOINT, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })).data;
        newValue = {
          type: "file",
          name: file.name,
          size: file.size,
          uuid: uploadedFile.uuid
        };
      } catch (error2) {
        showMessage(`${polyglot.t("AttachmentComponent.errorMessages.upload")}. ${nestErrorMessage(error2)}`, "warning");
      }
      setFileIsUploading(null);
      updateValue(newValue);
    }),
    [showMessage, updateValue, polyglot]
  );
  const linkIsAValidURL = /^https?:\/\/\S+/.test(linkUrl);
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsxs(
      Stack,
      {
        ref: disabled ? void 0 : menuAnchorElement,
        onClick: () => setMenuAnchor(menuAnchorElement.current),
        sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10, cursor: disabled ? "default" : "pointer" },
        children: [
          /* @__PURE__ */ jsx(LinkIcon, __spreadValues({ stroke: disabled ? themeColors.Grey : themeColors.DarkGrey }, iconSize)),
          /* @__PURE__ */ jsx(Typography, __spreadProps(__spreadValues({}, props), { sx: style, children: label })),
          /* @__PURE__ */ jsx(
            "input",
            {
              ref: fileInputRef,
              type: "file",
              name,
              disabled,
              onChange: (e) => {
                var _a2;
                if (disabled) return;
                const file = (_a2 = e.target.files) == null ? void 0 : _a2[0];
                if (!file) return;
                uploadFile(file);
              },
              style: { display: "none" }
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(Stack, { children: fileIsUploading && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", p: "2px 10px 0" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { color: disabled ? themeColors.Grey : void 0 }, children: fileIsUploading.name }),
      /* @__PURE__ */ jsx(SpinIcon, { height: "16px", style: { marginLeft: "auto" } })
    ] }) }),
    /* @__PURE__ */ jsx(Stack, { children: value && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", p: "2px 10px 0" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { color: disabled ? themeColors.Grey : void 0 }, children: value.name }),
      /* @__PURE__ */ jsx(
        ActionSelectionItemDeleteButton,
        {
          disabled,
          onClick: () => updateValue(null),
          sx: { ml: "auto" }
        }
      )
    ] }) }),
    /* @__PURE__ */ jsx(StyledFormHelperText, { error, children: helperText }),
    /* @__PURE__ */ jsxs(
      StyledMenu,
      {
        MenuListProps: {
          "aria-labelledby": "menu"
        },
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        anchorEl: menuAnchor,
        open: !!menuAnchor,
        onClose: closeMenu,
        children: [
          menuMode === "options" && [
            /* @__PURE__ */ jsx(
              MenuItem,
              {
                onClick: () => {
                  var _a2;
                  (_a2 = fileInputRef.current) == null ? void 0 : _a2.click();
                  closeMenu();
                },
                children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: "8px" }, children: [
                  /* @__PURE__ */ jsx(UploadDocIcon, __spreadValues({ fill: themeColors.DarkGrey }, iconSize)),
                  polyglot.t("AttachmentComponent.upload")
                ] })
              },
              0
            ),
            /* @__PURE__ */ jsx(MenuItem, { onClick: () => setMenuMode("url-entry"), children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: "8px", mt: "10px" }, children: [
              /* @__PURE__ */ jsx(LinkIcon, __spreadValues({ stroke: themeColors.DarkGrey }, iconSize)),
              polyglot.t("AttachmentComponent.attach")
            ] }) }, 1),
            ...showSelectExisting ? [
              /* @__PURE__ */ jsx(MenuItem, { onClick: () => setMenuMode("select-existing"), children: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: "8px", mt: "10px" }, children: [
                /* @__PURE__ */ jsx(Document, __spreadValues({}, iconSize)),
                "Select existing"
              ] }) }, 2)
            ] : []
          ],
          menuMode === "url-entry" && /* @__PURE__ */ jsx(MenuItem, { children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: polyglot.t("AttachmentComponent.enter"),
              placeholder: "https://",
              type: "url",
              value: linkUrl,
              onChange: (e) => {
                setLinkURL(e.target.value);
              },
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  if (linkIsAValidURL) {
                    updateValue({ type: "link", name: linkUrl });
                  }
                  closeMenu();
                  e.stopPropagation();
                }
              },
              endAdornment: "clear-text",
              clearText: () => setMenuMode("options"),
              helperText: !!linkUrl && !linkIsAValidURL ? polyglot.t("AttachmentComponent.invalid") : "",
              error: !!linkUrl && !linkIsAValidURL,
              sx: { width: "400px" }
            }
          ) }),
          menuMode === "select-existing" && /* @__PURE__ */ jsx(MenuItem, { children: /* @__PURE__ */ jsx(
            AutocompleteComponent,
            {
              name: "selectDocument",
              label: "Select document",
              fullWidth: true,
              options: docOptions,
              value: docId,
              sx: { width: "350px" },
              onChange: (_, x) => {
                setDocId(x.value);
              },
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  const docObj = docOptions.find((d) => d.value === docId);
                  if (docObj) {
                    updateValue({ type: "document", id: docObj == null ? void 0 : docObj.value, name: docObj == null ? void 0 : docObj.label });
                  }
                  closeMenu();
                  e.stopPropagation();
                }
              },
              compareValue: docId != null ? docId : ""
            }
          ) })
        ]
      }
    )
  ] });
};
