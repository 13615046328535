"use strict";
export const StudentLoan = [
  { value: "None", label: "None" },
  { value: "PlanOne", label: "Plan One" },
  { value: "PlanTwo", label: "Plan Two" },
  { value: "PlanFour", label: "Plan Four" }
];
export const CalculationMethodStandard = "Standard";
export const CalculationMethodMonth1 = "Month 1";
export const MONTHLY_PAY_TEXT = "Monthly pay";
export const WEEKLY_PAY_TEXT = "Weekly pay";
