"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import {
  BulkActionModeLabelMap,
  BulkUploadDocumentActionsMode
} from "@/v2/feature/documents/documents.interface";
import { getDocumentTypeListBasedOnAudience } from "@/v2/feature/documents/documents.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const DocumentBulkUploadActionsFormDrawer = ({
  selectedDocuments,
  confirmAction,
  mode,
  isOpen,
  setIsOpen,
  documentTypes
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const { cachedUsers } = useCachedUsers();
  const [newOwner, setNewOwner] = useState(null);
  const [newDocumentType, setNewDocumentType] = useState(null);
  const belongsToUserOptions = pipe(
    cachedUsers,
    RA.map((u) => __spreadValues({ value: u.userId, label: u.displayName }, u))
  );
  const documentTypeOptions = useMemo(() => {
    return getDocumentTypeListBasedOnAudience(documentTypes, true, "personal");
  }, [documentTypes]);
  const handleButtonClick = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (mode === BulkUploadDocumentActionsMode.ASSIGN_OWNER && newOwner) {
        confirmAction({ mode, owner: newOwner });
      }
      if (mode === BulkUploadDocumentActionsMode.ASSIGN_TYPE && newDocumentType) {
        confirmAction({ mode, type: newDocumentType });
      }
      if (mode === BulkUploadDocumentActionsMode.DELETE) {
        confirmAction({ mode });
      }
    } catch (error) {
      showMessage(`Could not action on selected documents. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setIsOpen(false);
      },
      children: /* @__PURE__ */ jsxs("div", { children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
          (selectedDocuments == null ? void 0 : selectedDocuments.length) > 0 && /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
            selectedDocuments == null ? void 0 : selectedDocuments.length,
            " documents"
          ] }),
          mode === BulkUploadDocumentActionsMode.ASSIGN_OWNER && /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "singleownership",
              options: belongsToUserOptions,
              onChange: (_, value) => {
                if (value) {
                  setNewOwner(value.userId);
                } else {
                  setNewOwner(null);
                }
              },
              value: newOwner,
              label: "Document owner",
              error: false,
              helperText: false
            }
          ),
          mode === BulkUploadDocumentActionsMode.ASSIGN_TYPE && /* @__PURE__ */ jsx(
            AutocompleteComponent,
            {
              name: "documentType",
              label: polyglot.t("DocumentFormModal.documentType"),
              options: documentTypeOptions,
              value: newDocumentType,
              onChange: (_, x) => {
                if (x) {
                  setNewDocumentType(x);
                }
              },
              compareValue: (_a = newDocumentType == null ? void 0 : newDocumentType.value) != null ? _a : ""
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            loading,
            colorVariant: "primary",
            sizeVariant: "medium",
            fullWidth: true,
            onClick: handleButtonClick,
            name: BulkActionModeLabelMap[mode]
          }
        ) })
      ] })
    }
  );
};
