"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { AddExistingDeviceDrawer } from "@v2/feature/device/features/devices-company/components/add-existing-device-drawer.component";
import { primarySmallBtn } from "@v2/styles/buttons.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import LaptopImage from "@/images/devices/laptop.png";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MyDevicesEmptyOverview = ({ refresh }) => {
  const [isAddExistingOpen, setIsAddExistingOpen] = useState(false);
  const { hasScopes, getScopesContext } = useScopes();
  const [globalState] = useContext(GlobalContext);
  const scopesContext = getScopesContext(globalState.user);
  const hasDevicesEnrollScope = hasScopes(["devices.enroll", "devices:all"], scopesContext);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "column", lg: "row", xl: "row" },
        justifyContent: "space-between",
        gap: spacing.g60
      },
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { width: "100%", maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "70%", xl: "70%" } }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "flex-start", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx("img", { src: LaptopImage, width: 150, height: 150, alt: "laptop-default" }) }),
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: "Add your device" }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mt: spacing.m5 }), children: "If you already have a work laptop - add it to Zelt to make sure your device is secured. Our software will ensure you have full control and protection enabled." }),
            /* @__PURE__ */ jsx(
              Button,
              {
                sx: __spreadValues(__spreadValues({}, spacing.mt20), primarySmallBtn),
                onClick: () => setIsAddExistingOpen(true),
                disabled: !hasDevicesEnrollScope,
                children: "Add device"
              }
            )
          ] })
        ] }) }),
        isAddExistingOpen && /* @__PURE__ */ jsx(AddExistingDeviceDrawer, { isOpen: isAddExistingOpen, setIsOpen: setIsAddExistingOpen, refresh })
      ]
    }
  );
};
