"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { styled } from "@mui/material";
import { DEBOUNCE_300_MS } from "@v2/feature/documents/documents.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useDebouncedCallback } from "use-debounce";
import Search from "@/images/side-bar-icons/Search.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
const StyleSearch = styled("input")(() => __spreadProps(__spreadValues({
  width: "60px",
  height: "26px",
  backgroundColor: themeColors.Background,
  borderRadius: radius.br15,
  backgroundImage: ` url(${Search})`,
  backgroundPosition: "10px center",
  backgroundSize: "15px 15px",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexShrink: 0,
  paddingLeft: 28,
  paddingRight: 0,
  margin: "1px",
  // needed for the focus:outline to render correctly
  alignItems: "center",
  border: "none"
}, themeFonts.paragraph), {
  "&:hover": {
    backgroundColor: themeColors.GreyHover,
    border: "none",
    outline: "none"
  },
  "&:active": {
    border: "none",
    outline: "none"
  },
  "&:disabled": {
    backgroundColor: themeColors.Background,
    color: themeColors.Grey,
    fill: themeColors.Grey,
    boxShadow: "none",
    "&::placeholder": {
      color: themeColors.Grey
    }
  },
  "&:focus": {
    backgroundColor: themeColors.white,
    width: "260px",
    outline: `1px solid ${themeColors.Grey} !important`,
    border: "none",
    transition: "width 0.8s, transform 2s",
    "&::placeholder": {
      opacity: 0
    }
  },
  "&:not(:focus)": {
    transition: "width 0.4s"
  },
  "&::placeholder": __spreadProps(__spreadValues({}, themeFonts.paragraph), {
    border: "none"
  })
}));
export const TableSearch = ({
  disabled,
  query,
  handleChange,
  placeholder,
  style,
  handleBlur,
  debounceSearchWait = DEBOUNCE_300_MS
}) => {
  const { polyglot } = usePolyglot();
  const hasFocus = React.useRef(false);
  const inputRef = React.createRef();
  const clearFocusIfEmpty = useCallback(() => {
    var _a;
    if (((_a = inputRef.current) == null ? void 0 : _a.value) === "") {
      inputRef.current.blur();
    }
  }, [inputRef]);
  useEscapeKey(clearFocusIfEmpty);
  const debouncedSearch = useDebouncedCallback((value) => {
    handleChange(!value ? "" : value);
  }, debounceSearchWait);
  return /* @__PURE__ */ jsx(
    StyleSearch,
    {
      ref: inputRef,
      autoFocus: !!(query == null ? void 0 : query.length) || hasFocus.current,
      spellCheck: false,
      defaultValue: query,
      onChange: debouncedSearch.callback,
      placeholder: placeholder || (polyglot.has("TableSearch.placeholder") ? polyglot.t("TableSearch.placeholder") : "Search"),
      style: __spreadValues({
        width: (query == null ? void 0 : query.length) ? "260px" : style == null ? void 0 : style.width
      }, style),
      onFocus: () => hasFocus.current = true,
      onBlur: (e) => {
        hasFocus.current = false;
        if (handleBlur) handleBlur(e);
      },
      disabled
    }
  );
};
