"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from "@mui/material";
import { AbsenceAdjustmentsImportResultTable } from "@v2/feature/entity-import/wizard/components/absence-adjustments/absence-adjustments-result-table.component";
import { AttendanceImportResultTable } from "@v2/feature/entity-import/wizard/components/attendance/attendance-import-result-table.component";
import { EntityImportWizardData } from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface";
import { AbsenceImportResultTable } from "@/v2/feature/entity-import/wizard/components/absence/absence-import-result-table.component";
import { DeviceImportResultTable } from "@/v2/feature/entity-import/wizard/components/device/device-import-result-table.component";
import { NewDocumentImportResultTable } from "@/v2/feature/entity-import/wizard/components/new-document/new-document-import-result-table.component";
import { UserImportResultTable } from "@/v2/feature/entity-import/wizard/components/user/user-import-result-table.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ImportResultsSection = ({
  importState,
  completeImport,
  sx,
  onUpdateAndRevalidate,
  loading,
  setLoading,
  documentTypes
}) => {
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "left"
        },
        children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Review results" })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { children: [
      importState.domain === EntityImportWizardData.Users && /* @__PURE__ */ jsx(
        UserImportResultTable,
        {
          result: importState.result,
          completeImport,
          onUpdateAndRevalidate,
          loading
        }
      ),
      importState.domain === EntityImportWizardData.Absences && /* @__PURE__ */ jsx(
        AbsenceImportResultTable,
        {
          result: importState.result,
          completeImport,
          onUpdateAndRevalidate,
          loading,
          setLoading
        }
      ),
      importState.domain === EntityImportWizardData.AbsenceAdjustments && /* @__PURE__ */ jsx(
        AbsenceAdjustmentsImportResultTable,
        {
          result: importState.result,
          completeImport,
          onUpdateAndRevalidate,
          loading,
          setLoading
        }
      ),
      importState.domain === EntityImportWizardData.Devices && /* @__PURE__ */ jsx(
        DeviceImportResultTable,
        {
          result: importState.result,
          completeImport,
          loading
        }
      ),
      importState.domain === EntityImportWizardData.Attendances && /* @__PURE__ */ jsx(
        AttendanceImportResultTable,
        {
          result: importState.result,
          completeImport,
          onUpdateAndRevalidate,
          loading,
          setLoading
        }
      ),
      importState.domain === EntityImportWizardData.Documents && /* @__PURE__ */ jsx(
        NewDocumentImportResultTable,
        {
          result: importState.result,
          completeImport,
          onUpdateAndRevalidate,
          loading,
          setLoading,
          documentTypes
        }
      )
    ] })
  ] });
};
