"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { MultipleSelectCheckbox } from "@v2/components/forms/multiple-select-checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { WeekDayNames } from "@v2/feature/attendance/attendance.interface";
import { getScheduleSettingsValidationSchema } from "@v2/feature/attendance/attendance.util";
import { drawerContentSx, fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TimeInputFields } from "@/v2/components/forms/time-input.component";
export const AttendanceScheduleEditScheduleSettingsDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  attendanceTypes,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    AttendanceScheduleEditScheduleSettingsDrawerContent,
    {
      attendanceSchedule,
      refresh,
      isOpen,
      setIsOpen,
      attendanceTypes
    }
  ) });
};
const AttendanceScheduleEditScheduleSettingsDrawerContent = ({
  attendanceSchedule,
  refresh,
  isOpen,
  setIsOpen,
  attendanceTypes
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        const update = {
          isFlexible: values.isFlexible,
          fteEquivalent: Number(values.fteEquivalent),
          fteEquivalentInDays: Number(values.fteEquivalentInDays),
          attendanceTypesAllowedIds: values.attendanceTypesAllowedIds,
          excludedFromMinHoursAttendanceTypesIds: values.excludedFromMinHoursAttendanceTypesIds
        };
        setLoading(true);
        yield AttendanceAPI.updateAttendanceScheduleSettings(attendanceSchedule.id, update);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AttendanceScheduleEditSettingsDrawer.errorMessages.update", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [attendanceSchedule.id, showMessage, refresh, setIsOpen, polyglot]
  );
  const attendanceTypesAllowedIds = useMemo(() => attendanceSchedule.attendanceTypesAllowed.map((r) => r.id), [
    attendanceSchedule
  ]);
  const excludedFromMinHoursAttendanceTypesIds = useMemo(
    () => {
      var _a2;
      return (_a2 = attendanceSchedule.excludedFromMinHoursAttendanceTypes) == null ? void 0 : _a2.map((r) => r.id);
    },
    [attendanceSchedule]
  );
  const formik = useFormik({
    initialValues: {
      isFlexible: attendanceSchedule.isFlexible,
      fteEquivalent: attendanceSchedule.fteEquivalent,
      fteEquivalentInDays: attendanceSchedule.fteEquivalentInDays,
      attendanceTypesAllowedIds,
      excludedFromMinHoursAttendanceTypesIds
    },
    validationSchema: getScheduleSettingsValidationSchema(polyglot),
    onSubmit
  });
  const typeIdsInScheduleSet = useMemo(() => {
    var _a2;
    const typeIdsSet = /* @__PURE__ */ new Set();
    for (const day of WeekDayNames)
      for (const week of (_a2 = attendanceSchedule[day]) != null ? _a2 : [])
        for (const entry of week != null ? week : []) if (entry == null ? void 0 : entry.typeId) typeIdsSet.add(entry.typeId);
    return typeIdsSet;
  }, [attendanceSchedule]);
  const JobsOptions = useMemo(() => {
    return (attendanceTypes != null ? attendanceTypes : []).map((job) => ({
      value: job.id,
      label: job.name,
      disabled: typeIdsInScheduleSet.has(job.id)
    }));
  }, [attendanceTypes, typeIdsInScheduleSet]);
  const SelectedJobOptions = useMemo(() => {
    var _a2;
    const selectedJobs = (_a2 = formik.values.attendanceTypesAllowedIds) != null ? _a2 : [];
    return JobsOptions.filter((o) => selectedJobs.includes(o.value));
  }, [JobsOptions, formik.values.attendanceTypesAllowedIds]);
  const ExcludedMCHJobOptions = useMemo(() => {
    return (attendanceTypes != null ? attendanceTypes : []).map((job) => ({
      value: job.id,
      label: job.name
    }));
  }, [attendanceTypes]);
  const SelectedExcludedMCHJobOptions = useMemo(() => {
    var _a2;
    const selectedJobs = (_a2 = formik.values.excludedFromMinHoursAttendanceTypesIds) != null ? _a2 : [];
    return ExcludedMCHJobOptions.filter((o) => selectedJobs.includes(o.value));
  }, [ExcludedMCHJobOptions, formik.values.excludedFromMinHoursAttendanceTypesIds]);
  useEffect(() => {
    if (isOpen) formik.resetForm();
  }, [isOpen]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AttendanceModule.schedule") }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, fieldSx), children: /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        name: "is-flexible",
        onChange: (event) => {
          formik.setFieldValue("isFlexible", event.target.value === "true");
        },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "false",
              checked: !formik.values.isFlexible,
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsxs(Box, { children: [
                /* @__PURE__ */ jsx(Typography, { variant: !formik.values.isFlexible ? "headline3" : "paragraph", children: polyglot.t("ScheduleScheduleForm.definedHours") }),
                /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("ScheduleScheduleForm.definedHoursDesc") })
              ] }),
              sx: { mb: "20px" }
            },
            "defined-hours"
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "true",
              checked: formik.values.isFlexible,
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsxs(Box, { children: [
                /* @__PURE__ */ jsx(Typography, { variant: formik.values.isFlexible ? "headline3" : "paragraph", children: polyglot.t("ScheduleScheduleForm.flexibleHours") }),
                /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("ScheduleScheduleForm.flexibleHoursDesc") })
              ] })
            },
            "flexible-hours"
          )
        ]
      }
    ) }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("AttendanceModule.fteEquivalent") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AttendanceModule.fteEquivalentDesc") }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", gap: "10px", alignItems: "center" }), children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { width: "40%" }, children: polyglot.t("EditFTEEquivalentDrawer.FullTimeDays") }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            sx: { width: "50%" },
            label: polyglot.t("TimeUnitTypes.days"),
            name: "fteEquivalentInDays",
            value: formik.values.fteEquivalentInDays,
            onChange: formik.handleChange,
            error: formik.touched.fteEquivalentInDays && !!formik.errors.fteEquivalentInDays,
            helperText: (_a = formik.touched.fteEquivalentInDays && formik.errors.fteEquivalentInDays) != null ? _a : " ",
            endAdornment: "none"
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", gap: "10px", alignItems: "center", mt: "10px" }), children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { width: "40%" }, children: polyglot.t("EditFTEEquivalentDrawer.FullTimeHours") }),
        /* @__PURE__ */ jsx(
          TimeInputFields,
          {
            value: formik.values.fteEquivalent,
            onChange: (minutes) => formik.setFieldValue("fteEquivalent", minutes),
            polyglot,
            containerSx: { width: "50%" }
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        id: "group-select-jobs",
        options: JobsOptions,
        value: SelectedJobOptions,
        onChange: (_, values) => {
          const jobs = values.map((job) => job.value);
          formik.setFieldValue("attendanceTypesAllowedIds", jobs);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label,
        label: polyglot.t("AttendanceModule.allowedJobs"),
        helperText: formik.touched.attendanceTypesAllowedIds || formik.touched.attendanceTypesAllowedIds ? formik.errors.attendanceTypesAllowedIds || formik.errors.attendanceTypesAllowedIds : "",
        error: Boolean(
          (formik.touched.attendanceTypesAllowedIds || formik.touched.attendanceTypesAllowedIds) && (formik.errors.attendanceTypesAllowedIds || formik.errors.attendanceTypesAllowedIds)
        )
      }
    ),
    /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        id: "group-select-jobs",
        options: ExcludedMCHJobOptions,
        value: SelectedExcludedMCHJobOptions,
        onChange: (_, values) => {
          const jobs = values.map((job) => job.value);
          formik.setFieldValue("excludedFromMinHoursAttendanceTypesIds", jobs);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label,
        label: polyglot.t("AttendanceSchedule.scheduleRules.settings.excludedFromMinHoursAttendanceTypes"),
        helperText: formik.touched.excludedFromMinHoursAttendanceTypesIds || formik.touched.excludedFromMinHoursAttendanceTypesIds ? formik.errors.excludedFromMinHoursAttendanceTypesIds || formik.errors.excludedFromMinHoursAttendanceTypesIds : "",
        error: Boolean(
          (formik.touched.excludedFromMinHoursAttendanceTypesIds || formik.touched.excludedFromMinHoursAttendanceTypesIds) && (formik.errors.excludedFromMinHoursAttendanceTypesIds || formik.errors.excludedFromMinHoursAttendanceTypesIds)
        )
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", loading, fullWidth: true, children: polyglot.t("General.save") }) })
  ] }) });
};
