"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { Divider } from "@v2/components/divider.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@v2/components/typography/typography.component";
import {
  CustomBenefitAllowanceType,
  UserCustomBenefitPaymentStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import {
  groupPaymentsByCycle,
  isAllowanceBenefit,
  reformatCycleLabels
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { LocalDate } from "@v2/util/local-date";
export const BenefitPaymentsDrawer = ({
  isOpen,
  setIsOpen,
  userCustomBenefitPayments,
  type,
  benefitType,
  allowanceType,
  cycleStartDay,
  cycleStartMonth
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    BenefitPaymentsDrawerContent,
    {
      type,
      userCustomBenefitPayments,
      benefitType,
      allowanceType,
      cycleStartDay,
      cycleStartMonth
    }
  ) });
};
export const BenefitPaymentsDrawerContent = ({
  type,
  userCustomBenefitPayments,
  benefitType,
  allowanceType,
  cycleStartDay,
  cycleStartMonth
}) => {
  const { polyglot } = usePolyglot();
  const isAllowance = useMemo(() => isAllowanceBenefit(benefitType), [benefitType]);
  const paymentsToShow = useMemo(
    () => userCustomBenefitPayments.filter(
      (payment) => type === "used" || type === "repaid" ? payment.status === UserCustomBenefitPaymentStatus.Approved : payment.status === UserCustomBenefitPaymentStatus.Pending
    ).sort((p1, p2) => p1.date >= p2.date ? -1 : 1),
    [userCustomBenefitPayments, type]
  );
  const groupedPayments = useMemo(() => {
    if (isAllowance && allowanceType === CustomBenefitAllowanceType.Yearly) {
      if (cycleStartDay && cycleStartMonth) {
        const groupedByYearlyCycle = reformatCycleLabels(
          groupPaymentsByCycle(paymentsToShow, cycleStartDay, cycleStartMonth)
        );
        return groupedByYearlyCycle;
      }
      return paymentsToShow.reduce((groups, p) => {
        const yearString = new LocalDate(p.date).getDate().getFullYear().toString();
        if (!groups[yearString]) groups[yearString] = [];
        groups[yearString].push(p);
        return groups;
      }, {});
    }
    if (isAllowance && allowanceType === CustomBenefitAllowanceType.Monthly) {
      return paymentsToShow.reduce((groups, p) => {
        const label = new LocalDate(p.date).toLocaleDateString(void 0, {
          month: "short",
          year: "numeric"
        });
        if (!groups[label]) groups[label] = [];
        groups[label].push(p);
        return groups;
      }, {});
    }
    return { all: paymentsToShow };
  }, [isAllowance, allowanceType, paymentsToShow, cycleStartDay, cycleStartMonth]);
  return paymentsToShow.length > 0 ? /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: type === "used" ? polyglot.t("BenefitModule.used") : type === "requested" ? polyglot.t("BenefitModule.requested") : polyglot.t("BenefitModule.repaid") }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: "20px" }, children: Object.keys(groupedPayments).map((label) => {
      const groupTotal = groupedPayments[label].reduce((sum, p) => sum + p.amount, 0);
      return /* @__PURE__ */ jsxs(Box, { sx: { mt: "10px", display: "flex", flexDirection: "column", gap: "5px" }, children: [
        isAllowance && allowanceType && [CustomBenefitAllowanceType.Yearly, CustomBenefitAllowanceType.Monthly].includes(allowanceType) && /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: label }),
        groupedPayments[label].map((payment) => {
          return /* @__PURE__ */ jsx(StyledTooltip, { title: payment.notes, placement: "top", children: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 5px",
                borderRadius: "10px",
                ":hover": {
                  bgcolor: themeColors.Background
                  // cursor: 'pointer',
                }
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new LocalDate(payment.date).toLocaleDateString(void 0, {
                  day: "2-digit",
                  month: "short",
                  year: "numeric"
                }) }),
                /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: formatMoney({ amount: payment.amount, asDecimal: true }) })
              ]
            },
            payment.id
          ) }, `${payment.id}-tooltip`);
        }),
        /* @__PURE__ */ jsx(Divider, {}),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "0px 5px"
            },
            children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatMoney({ amount: groupTotal, asDecimal: true }) })
          },
          `${label}-total`
        )
      ] });
    }) })
  ] }) : /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: type === "used" ? polyglot.t("BenefitModule.used") : type === "requested" ? polyglot.t("BenefitModule.requested") : polyglot.t("BenefitModule.repaid") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: "30px" }, children: polyglot.t("BenefitModule.noPaymentsToShow") })
  ] });
};
