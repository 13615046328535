"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { differenceInDays } from "date-fns";
import { Redirect, useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { GROWTH_COMPANY_GOALS_ROUTE, GROWTH_ME_GOALS_ROUTE } from "@/lib/routes";
import { canAccessScopes } from "@/lib/scopes";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { GoalListItem } from "@/v2/feature/growth/goals/components/goal-list-item.component";
import { GoalMutationModal } from "@/v2/feature/growth/goals/components/goal-mutation-modal.component";
import { InfiniteScrollTrigger } from "@/v2/feature/growth/goals/components/infinite-scroll-trigger.component";
import { StatusChip } from "@/v2/feature/growth/goals/components/status-chip.component";
import { KeyResultListItem } from "@/v2/feature/growth/goals/features/key-results/components/key-result-list-item.component";
import { KeyResultMutationModal } from "@/v2/feature/growth/goals/features/key-results/components/key-result-mutation-modal.component";
import { KeyResultAPI, KeyResultEndpoints } from "@/v2/feature/growth/goals/features/key-results/key-results.api";
import { GoalAPI, GoalEndpoints } from "@/v2/feature/growth/goals/goals.api";
import { useGoalsReach } from "@/v2/feature/growth/goals/hooks/use-goals-reach.hook";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
const iconSize = { width: 14, height: 14 };
const pageSize = 10;
export const GoalPage = () => {
  const { polyglot } = usePolyglot();
  const reach = useGoalsReach();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const canEditGoals = canAccessScopes(user, ["goals:all"]);
  const canCreateKeyResults = canAccessScopes(user, ["goals:all"]);
  const params = useParams();
  const [isNewKeyResultDrawerOpen, setNewKeyResultDrawerOpen] = useState(false);
  const [openedKeyResultId, setOpenedKeyResultId] = useState(null);
  const [isEditGoalDrawerOpen, setIsEditGoalDrawerOpen] = useState(false);
  const [deleteGoalDrawerOpen, setDeleteGoalDrawerOpen] = useState(false);
  const [isEditKeyResultDrawerOpen, setEditKeyResultDrawerOpen] = useState(false);
  const [isDeleteKeyResultDrawerOpen, setDeleteKeyResultDrawerOpen] = useState(false);
  const [goalHasBeenDeleted, setGoalHasBeenDeleted] = useState(false);
  const [showMessage] = useMessage();
  const { data: goal, error: getGoalError, mutate: refreshGoal } = useApiClient(GoalEndpoints.getGoal(params.goalId), {
    suspense: false
  });
  const [page, setPage] = useState(1);
  const [pagedKeyResults, setPagedKeyResults] = useState({});
  const { data: paginatedKeyResults, isLoading: keyResultsLoading, mutate: refreshKeyResults } = useApiClient(
    KeyResultEndpoints.getKeyResults(params.goalId, reach, page),
    {
      suspense: false
    }
  );
  const keyResults = useMemo(() => {
    if (!paginatedKeyResults) return null;
    const pagedResults = __spreadProps(__spreadValues({}, pagedKeyResults), { [paginatedKeyResults.page]: paginatedKeyResults.items });
    return Object.values(pagedResults).flat();
  }, [pagedKeyResults, paginatedKeyResults]);
  const refreshPagedKeyResults = useCallback(() => {
    if (!paginatedKeyResults) return;
    setPagedKeyResults((prev) => {
      return __spreadProps(__spreadValues({}, prev), { [paginatedKeyResults.page]: paginatedKeyResults.items });
    });
  }, [paginatedKeyResults]);
  useEffect(() => {
    refreshPagedKeyResults();
  }, [refreshPagedKeyResults, paginatedKeyResults]);
  const refreshGoalAndKeyResults = useCallback(() => __async(void 0, null, function* () {
    setPage(1);
    yield Promise.all([refreshGoal == null ? void 0 : refreshGoal(), refreshKeyResults == null ? void 0 : refreshKeyResults()]).catch((error) => {
      showMessage(nestErrorMessage(error), "error");
    }).then(() => {
      refreshPagedKeyResults();
    });
  }), [refreshGoal, refreshKeyResults, showMessage, refreshPagedKeyResults]);
  const openedKeyResult = useMemo(() => {
    var _a;
    return (_a = keyResults == null ? void 0 : keyResults.find((keyResult) => keyResult.id === openedKeyResultId)) != null ? _a : null;
  }, [keyResults, openedKeyResultId]);
  const deleteKeyResult = useCallback(() => __async(void 0, null, function* () {
    if (!openedKeyResult) return;
    try {
      yield KeyResultAPI.removeKeyResult(openedKeyResult.goalId, openedKeyResult.id);
      setDeleteKeyResultDrawerOpen(false);
      showMessage(polyglot.t("KeyResultDrawer.keyResultDeleted"), "success");
      refreshGoalAndKeyResults();
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  }), [openedKeyResult, setDeleteKeyResultDrawerOpen, polyglot, showMessage, refreshGoalAndKeyResults]);
  const deleteGoal = useCallback(
    (goalId) => __async(void 0, null, function* () {
      yield GoalAPI.removeGoal(goalId).catch((error) => {
        showMessage(polyglot.t("DeleteGoalDrawer.errorMessage", { errorMessage: nestErrorMessage(error) }), "error");
      }).then(() => {
        showMessage(polyglot.t("DeleteGoalDrawer.successMessage"), "success");
      });
      setGoalHasBeenDeleted(true);
    }),
    [polyglot, showMessage, setGoalHasBeenDeleted]
  );
  const getGoalActionsOptions = useCallback(
    () => [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => setIsEditGoalDrawerOpen(true),
        label: polyglot.t("GoalPage.editGoal"),
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => setDeleteGoalDrawerOpen(true),
        label: polyglot.t("GoalPage.deleteGoal"),
        disabled: false
      }
    ],
    [polyglot]
  );
  const onReachedListEnd = useCallback(() => {
    if (keyResultsLoading) return;
    if (!(paginatedKeyResults == null ? void 0 : paginatedKeyResults.items.length)) return;
    if (paginatedKeyResults.items.length < pageSize) return;
    if (!(paginatedKeyResults == null ? void 0 : paginatedKeyResults.nextPage)) return;
    setPage(paginatedKeyResults.nextPage);
  }, [keyResultsLoading, paginatedKeyResults]);
  const dueDateLabel = useMemo(() => {
    if (!(goal == null ? void 0 : goal.dueDate)) return null;
    const daysDifference = differenceInDays(/* @__PURE__ */ new Date(), new Date(goal.dueDate));
    if (daysDifference === 0) return polyglot.t("GoalPage.dueToday");
    if (daysDifference > 90 || daysDifference < -90) return null;
    return daysDifference > 0 ? polyglot.t("GoalPage.overdueDaysAgo", { smart_count: Math.abs(daysDifference) }) : polyglot.t("GoalPage.noOfDaysLeft", { smart_count: Math.abs(daysDifference) });
  }, [goal, polyglot]);
  if ((getGoalError == null ? void 0 : getGoalError.status) === 404 || goalHasBeenDeleted) {
    return /* @__PURE__ */ jsx(Redirect, { to: reach === ReachType.Company ? GROWTH_COMPANY_GOALS_ROUTE : GROWTH_ME_GOALS_ROUTE });
  }
  if (!goal) return null;
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Typography, { variant: "headline1", sx: { display: "flex" }, children: [
          goal.name,
          /* @__PURE__ */ jsx(Box, { sx: { paddingLeft: "1rem", display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(StatusChip, { status: goal.status }) })
        ] }),
        actions: canEditGoals && /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: getGoalActionsOptions(),
            actionButtonDetails: {
              type: "iconButton",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "actions",
              icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
            }
          }
        ),
        showAction: true,
        sx: { padding: "0 1.25rem", flex: 1 }
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, noHorizontalPadding: true, sx: { padding: "0 1.25rem" }, children: [
      /* @__PURE__ */ jsx(
        KeyResultMutationModal,
        {
          mode: "create",
          goalId: goal.id,
          isOpen: isNewKeyResultDrawerOpen,
          setIsOpen: setNewKeyResultDrawerOpen,
          onClose: () => setNewKeyResultDrawerOpen(false),
          refresh: () => __async(void 0, null, function* () {
            yield Promise.all([
              refreshKeyResults == null ? void 0 : refreshKeyResults().catch((error) => {
                showMessage(`Error: ${nestErrorMessage(error)}`, "error");
              }),
              refreshGoal == null ? void 0 : refreshGoal().catch((error) => {
                showMessage(`Error: ${nestErrorMessage(error)}`, "error");
              })
            ]);
          })
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        GoalListItem,
        {
          goal,
          progressBar: {
            type: "circle",
            labelTextVariant: "headline1"
          },
          showTitle: false,
          showDescription: true,
          endSection: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: dueDateLabel }) }),
          sx: {
            border: "none",
            backgroundColor: themeColors.white,
            padding: 0
          }
        }
      ) }),
      /* @__PURE__ */ jsxs(
        Box,
        {
          className: "sub-title",
          sx: {
            margin: "55px 0 24px 0",
            padding: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("GoalPage.keyResults") }),
            canCreateKeyResults && /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                colorVariant: "secondary",
                sizeVariant: "small",
                onClick: () => setNewKeyResultDrawerOpen(true),
                children: polyglot.t("GoalPage.newKeyResult")
              }
            )
          ]
        }
      ),
      (keyResults == null ? void 0 : keyResults.length) === 0 && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "helperText", children: polyglot.t("GoalPage.noKeyResults") }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: "1.5rem" }, children: keyResults == null ? void 0 : keyResults.map((keyResult) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        KeyResultListItem,
        {
          keyResult,
          closeOptionsMenu: isEditKeyResultDrawerOpen || isDeleteKeyResultDrawerOpen,
          onClick: (keyResult2) => {
            setOpenedKeyResultId(keyResult2.id);
          },
          onProgressCreated: () => refreshGoalAndKeyResults(),
          onProgressUpdated: () => refreshGoalAndKeyResults(),
          onProgressDeleted: () => refreshGoalAndKeyResults(),
          onEditClick: (keyResult2) => {
            setOpenedKeyResultId(keyResult2.id);
            setEditKeyResultDrawerOpen(true);
          },
          onDeleteClick: (keyResult2) => {
            setOpenedKeyResultId(keyResult2.id);
            setDeleteKeyResultDrawerOpen(true);
          },
          hoverState: {
            enabled: true
          },
          sx: {
            backgroundColor: themeColors.white,
            border: `1px solid ${themeColors.middleGrey2}`
          }
        }
      ) }, keyResult.id)) }),
      /* @__PURE__ */ jsx(InfiniteScrollTrigger, { onReachedEnd: onReachedListEnd })
    ] }),
    /* @__PURE__ */ jsx(
      GoalMutationModal,
      {
        mode: "edit",
        goal,
        isOpen: isEditGoalDrawerOpen,
        setIsOpen: setIsEditGoalDrawerOpen,
        onClose: () => setIsEditGoalDrawerOpen(false),
        refresh: () => __async(void 0, null, function* () {
          yield refreshGoal == null ? void 0 : refreshGoal();
        })
      }
    ),
    /* @__PURE__ */ jsx(
      KeyResultMutationModal,
      {
        mode: "edit",
        goalId: goal.id,
        keyResult: openedKeyResult != null ? openedKeyResult : void 0,
        isOpen: isEditKeyResultDrawerOpen,
        setIsOpen: setEditKeyResultDrawerOpen,
        onClose: () => {
          setEditKeyResultDrawerOpen(false);
        },
        refresh: () => __async(void 0, null, function* () {
          refreshKeyResults == null ? void 0 : refreshKeyResults();
        })
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("DeleteGoalDrawer.title"),
        description: polyglot.t("DeleteGoalDrawer.description"),
        isOpen: deleteGoalDrawerOpen,
        setIsOpen: setDeleteGoalDrawerOpen,
        loading: false,
        action: () => deleteGoal(goal.id)
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("DeleteKeyResultDrawer.title"),
        description: polyglot.t("DeleteKeyResultDrawer.description"),
        isOpen: isDeleteKeyResultDrawerOpen,
        setIsOpen: setDeleteKeyResultDrawerOpen,
        loading: false,
        action: () => __async(void 0, null, function* () {
          return deleteKeyResult();
        })
      }
    )
  ] });
};
