"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { PolicyPayrollEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-payroll-edit-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AbsencePolicyPayrollSection = ({ absencePolicy, refresh }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(SettingsSectionContent, { title: polyglot.t("AbsencePolicyRouter.payroll"), onEdit: () => setIsEditDrawerOpen(true), children: [
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t("PayrollModule.payImplication"),
                value: absencePolicy.isPaid ? polyglot.t("PayrollModule.paid") : polyglot.t("PayrollModule.unpaid")
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t("PayrollModule.showOnPayslip"),
                value: absencePolicy.showOnPayslip ? polyglot.t("General.yes") : polyglot.t("General.no")
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t("PayItemModule.payCode"),
                value: (_a = absencePolicy.payCode) != null ? _a : polyglot.t("General.none"),
                hidden: !absencePolicy.showOnPayslip
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t("PayItemModule.payCodeMultiplier"),
                value: polyglot.t("AttendanceModule.dayRateTimesMultiplier", {
                  multiplier: (_b = absencePolicy.payCodeMultiplier) != null ? _b : 1
                }),
                hidden: !absencePolicy.showOnPayslip
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      PolicyPayrollEditDrawer,
      {
        isOpen: isEditDrawerOpen,
        setIsOpen: setIsEditDrawerOpen,
        absencePolicy,
        refresh
      }
    )
  ] });
};
