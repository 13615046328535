"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { OverlappingCounter } from "@/v2/feature/calendar/features/components/overlapping-counter.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export var EventKindEnum = /* @__PURE__ */ ((EventKindEnum2) => {
  EventKindEnum2["birthday"] = "birthday";
  EventKindEnum2["firstDay"] = "first-day";
  EventKindEnum2["workAnniversary"] = "work-anniversary";
  EventKindEnum2["lastDay"] = "last-day";
  return EventKindEnum2;
})(EventKindEnum || {});
export const SpecialEvents = {
  birthday: {
    title: "Birthday",
    color: themeColors.Orange
  },
  "first-day": {
    title: "First Day",
    color: themeColors.Green
  },
  "work-anniversary": {
    title: "Anniversary",
    color: themeColors.GreenMiddle
  },
  "last-day": {
    title: "Last Day",
    color: themeColors.GreyMiddle
  }
};
export const CalendarSpecialEvent = React.memo(
  ({ kind, activeView, overlappingEventsCounter, setSelectedEventOnDate, setIsOpen, entry }) => {
    const { polyglot } = usePolyglot();
    return /* @__PURE__ */ jsxs(
      Box,
      {
        sx: { display: "flex", width: "100%", justifyContent: "center", height: "48px", padding: 0, margin: 0 },
        onClick: () => {
          setSelectedEventOnDate(entry);
          setIsOpen(true);
        },
        children: [
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                alignItems: "center",
                backgroundColor: SpecialEvents[kind].color,
                display: "flex",
                width: "100%",
                overflow: "hidden",
                position: "relative",
                opacity: 1,
                padding: 0,
                margin: 0,
                boxSizing: "border-box",
                "&:hover::before": {
                  cursor: "pointer",
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  zIndex: 1
                },
                "& > *": {
                  position: "relative",
                  zIndex: 1
                }
              },
              children: /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: activeView === "Week" ? "flex" : "none",
                    alignItems: "center",
                    gap: spacing.g5,
                    px: spacing.p5
                  },
                  children: /* @__PURE__ */ jsxs(Box, { children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "white", children: polyglot.t("CalendarSpecialEvent.allDay") }),
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "white", children: SpecialEvents[kind].title })
                  ] })
                }
              )
            }
          ),
          overlappingEventsCounter > 0 && /* @__PURE__ */ jsx(OverlappingCounter, { overlappingEventsCounter, activeView })
        ]
      }
    );
  }
);
