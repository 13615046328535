"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const ResendAuthCodeLink = ({ sx }) => {
  const { polyglot } = usePolyglot();
  const [sendNewAuthCodeState, setSendNewAuthCodeState] = useState(null);
  const sendNewAuthCode = useCallback(() => {
    (() => __async(void 0, null, function* () {
      setSendNewAuthCodeState("sending");
      yield AuthAPI.requestMFAEnableCode();
      setSendNewAuthCodeState(/* @__PURE__ */ new Date());
    }))();
  }, []);
  useEffect(() => {
    if (sendNewAuthCodeState instanceof Date) {
      const allowAnotherSendAt = sendNewAuthCodeState.getTime() + 1e4;
      const timeToWait = Math.max(allowAnotherSendAt - Date.now(), 0);
      const t = setTimeout(() => setSendNewAuthCodeState(null), timeToWait);
      return () => clearTimeout(t);
    }
  }, [sendNewAuthCodeState]);
  return /* @__PURE__ */ jsxs(Box, { sx, children: [
    !sendNewAuthCodeState && /* @__PURE__ */ jsx(
      Typography,
      {
        title: polyglot.t("ResendAuthCodeLink.ifNotReceived"),
        onClick: () => sendNewAuthCode(),
        sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
          color: themeColors.Grey,
          "&:hover": { color: themeColors.DarkGrey, cursor: "pointer" }
        }),
        children: polyglot.t("ResendAuthCodeLink.resend")
      }
    ),
    sendNewAuthCodeState instanceof Date && /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
          color: themeColors.DarkGrey
        }),
        children: polyglot.t("ResendAuthCodeLink.newConfirmation")
      }
    )
  ] });
};
