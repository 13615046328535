"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EnableSSODisableMFAConflictConfirmDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  onConfirm
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("EnableSSODisableMFAConflictConfirmDrawer.enablesso") }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m15 }), children: polyglot.t("EnableSSODisableMFAConflictConfirmDrawer.switchssonote") }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: 3 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        onClick: () => {
          onConfirm();
          onClose();
        },
        sizeVariant: "medium",
        colorVariant: "primary",
        children: polyglot.t("EnableSSODisableMFAConflictConfirmDrawer.continue")
      }
    ) })
  ] }) });
};
