"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import "@v2/feature/app-layout/features/main-content/layouts/components/styles/content-2-columns.scss";
import { borders } from "@v2/styles/borders.styles";
export const Content2Columns = ({
  column1,
  column2,
  borderLeft,
  gap = "gap20"
}) => {
  return /* @__PURE__ */ jsxs("div", { className: `content-2-columns ${gap === "no-gap" ? "" : gap}`, children: [
    /* @__PURE__ */ jsx("div", { style: { minWidth: "100%" }, children: column1 }),
    /* @__PURE__ */ jsx("div", { style: { borderLeft: borderLeft ? borders.background : void 0, minWidth: "100%" }, children: column2 })
  ] });
};
