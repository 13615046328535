"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import {
  FieldStructure,
  ProfileCard
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { MissingInformationLabel } from "@/v2/feature/user/features/user-profile/details/components/missing-information-label.component";
import {
  calculateSkeletonHeight,
  cardHeaderSx,
  cardSx,
  definitionListSx,
  hazardSize
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import {
  BasicInformationForm,
  BasicInformationSchema
} from "@/v2/feature/user/features/user-profile/details/components/user-profile-basic-information-form.component";
import {
  getTranslatedFieldValue,
  isDataEmpty
} from "@/v2/feature/user/features/user-profile/details/user-profile.util";
import { UserAPI } from "@/v2/feature/user/user.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { hazardMissingField, tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const SCOPE = "user.basicInfo";
const iconSize = { width: 14, height: 14 };
const skeletonHeight = calculateSkeletonHeight(BasicInformationSchema);
export const BasicInformationCard = ({ userId, onSubmit, missingFields }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [data, setData] = useState(void 0);
  const [isEmpty, setEmpty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const getUserBasic = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const data2 = yield UserAPI.getUserBasicInfo(userId);
      setEmpty(isDataEmpty(data2, data2.customUpdates));
      setData(data2);
    } catch (error) {
      showMessage(polyglot.t("BasicInformationCard.errorMessages.load"), "error");
    } finally {
      setLoading(false);
    }
  }), [polyglot, showMessage, userId]);
  useEffect(() => {
    getUserBasic();
  }, [getUserBasic]);
  return loading || !data ? /* @__PURE__ */ jsx(
    SkeletonLoader,
    {
      variant: "rectangular",
      height: skeletonHeight,
      sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
    }
  ) : /* @__PURE__ */ jsxs(
    ProfileCard,
    {
      fieldStubs: ["emailAddress", "firstName", "lastName", "middleName", "displayName"],
      customUpdates: data == null ? void 0 : data.customUpdates,
      sx: cardSx,
      children: [
        /* @__PURE__ */ jsxs(Box, { component: "header", sx: cardHeaderSx, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("BasicInformationCard.basic") }),
            /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.basicInfo:read"], context: scopesContext, children: missingFields && !!missingFields.find((rec) => rec.cardId === CustomProfileFormType.Basic) && /* @__PURE__ */ jsx(Box, { sx: hazardMissingField, children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }) })
          ] }),
          /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(IconButton, { onClick: () => setIsOpen(true), title: "Edit", sx: tableIconButtonSx, children: isEmpty ? /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }) })
        ] }),
        /* @__PURE__ */ jsx(Box, { component: "section", children: isEmpty ? /* @__PURE__ */ jsx(MissingInformationLabel, {}) : /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
          /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("BasicInformationCard.emailAddress"),
              fieldValue: (data == null ? void 0 : data.emailAddress) && {
                isLink: true,
                label: data.emailAddress,
                icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                onClick: () => navigator.clipboard.writeText(data.emailAddress)
              },
              fieldStub: "emailAddress"
            }
          ),
          /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("BasicInformationCard.firstName"),
              fieldValue: polyglot.t((_a = data == null ? void 0 : data.firstName) != null ? _a : ""),
              fieldStub: "firstName"
            }
          ),
          /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("BasicInformationCard.lastName"),
              fieldValue: polyglot.t((_b = data == null ? void 0 : data.lastName) != null ? _b : ""),
              fieldStub: "lastName"
            }
          ),
          /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("BasicInformationCard.middleName"),
              fieldValue: polyglot.t((_c = data == null ? void 0 : data.middleName) != null ? _c : ""),
              fieldStub: "middleName"
            }
          ),
          /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("BasicInformationCard.displayName"),
              fieldValue: polyglot.t(data && ((_d = data.displayName) != null ? _d : `${data.firstName} ${data.lastName}`)),
              fieldStub: "displayName"
            }
          ),
          sortCustomFields(data.customUpdates).map(
            (f) => !f.field.isHidden && /* @__PURE__ */ jsx(
              FieldStructure,
              {
                fieldTitle: f.field.fieldName,
                fieldValue: getTranslatedFieldValue(f.value, polyglot)
              },
              f.field.fieldId
            )
          )
        ] }) }),
        data && /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
          BasicInformationForm,
          {
            userId,
            initialValues: data,
            onSubmit: (values) => {
              setData(values);
              setIsOpen(false);
              onSubmit(values);
            },
            onClose: () => setIsOpen(false),
            handleRefresh: () => getUserBasic()
          }
        ) }) })
      ]
    }
  );
};
