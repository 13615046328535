"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCParticipantAddModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  reach
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SCParticipantAddContent, { surveyCycle, onClose, refresh, reach }) });
};
const useParticipantSelectForm = (surveyCycle, onClose, refresh) => {
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      participantIds: []
    },
    validationSchema: yup.object({
      participantIds: yup.array().required("Required field").min(1, "Please choose at least one user")
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (!surveyCycle) {
          throw new Error("Survey cycle is not defined.");
        }
        const isPreOngoingState = [CycleState.Draft, CycleState.Scheduled].includes(surveyCycle.state);
        if (isPreOngoingState) {
          const updatedCycle = __spreadProps(__spreadValues({}, surveyCycle), {
            participantIds: [...surveyCycle.participantIds || [], ...values.participantIds]
          });
          yield SurveyCycleAPI.updateSurveyCycle(updatedCycle);
        }
        if (surveyCycle.state === CycleState.Ongoing) {
          yield SurveyCycleAPI.addParticipants(surveyCycle.id, values.participantIds);
        }
        showMessage("Successfully added new participants", "success");
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
const SCParticipantAddContent = ({
  surveyCycle,
  onClose,
  refresh,
  reach
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const formik = useParticipantSelectForm(surveyCycle, onClose, refresh);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Add participants" }),
    (surveyCycle == null ? void 0 : surveyCycle.state) === CycleState.Ongoing && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Newly added participants will receive an invite." }),
    /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: "Participant",
        selectedSpecificButtonLabel: "Select specific",
        value: (_b = (_a = formik.values) == null ? void 0 : _a.participantIds) != null ? _b : [],
        allInvalidUserIds: (_c = surveyCycle == null ? void 0 : surveyCycle.participantIds) != null ? _c : [],
        onChange: (userIds) => {
          formik.setFieldValue("participantIds", Array.from(new Set(userIds)));
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        excludeCustomRule: true,
        reach
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        disabled: !formik.isValid,
        fullWidth: true
      }
    ) })
  ] }) });
};
