"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { LoadingStateComponent } from "@v2/components/table/loading-state.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as Mistake } from "@/images/reports/Mistake.svg";
import { REPORT_COMPANY_REPORTS_OVERVIEW } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const ErrorComponent = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Mistake, {}),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mb: spacing.m10 }), children: polyglot.t("ErrorComponent.fail") })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mb: spacing.m30 }), children: polyglot.t("ErrorComponent.sorry") }),
    /* @__PURE__ */ jsxs(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "secondary",
        fullWidth: true,
        onClick: () => routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW),
        children: [
          polyglot.t("ErrorComponent.close"),
          " "
        ]
      }
    )
  ] });
};
export const LoadingTableComponent = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "80%" }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("LoadingTableComponent.results") }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: [1, 2, 3, 4].map((a) => /* @__PURE__ */ jsx(LoadingStateComponent, { showDivider: a !== 4 }, a)) })
  ] });
};
export const NoDataComponent = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mb: spacing.m10 }), children: polyglot.t("NoDataComponent.report") }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mb: spacing.m30 }), children: polyglot.t("NoDataComponent.sorry") }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "secondary",
        fullWidth: true,
        onClick: () => routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW),
        children: polyglot.t("NoDataComponent.close")
      }
    )
  ] });
};
