"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ZincIcon } from "@/images/side-bar-icons/zinc.svg";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { NewIdCheckPackageDrawer } from "@/v2/feature/id-check/components/new-id-check-package-drawer.page";
import { IdCheckEndpoints } from "@/v2/feature/id-check/id-check.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { underlinedLinkLight } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatAsGBPCurrency } from "@/v2/util/string-format.util";
export const ZINC_TERMS_LINK = "https://zincwork.com/terms-payg";
export const ZINC_CHECKS_LINK = "https://zincwork.com/checks";
export const EditIdVerify = ({ onSave, mode = "template", selectedPackageIds = [] }) => {
  const { polyglot } = usePolyglot();
  const {
    data: individualChecks,
    mutate: refreshIndividualChecks
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });
  const { data: idCheckPackages, isLoading: fetchingPackages, mutate: refreshIdCheckPackages } = useApiClient(
    IdCheckEndpoints.getIdCheckPackages(),
    {
      suspense: false
    }
  );
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const scopesContext = { userId: user.userId };
  const [selectedPackages, setSelectedPackages] = useState(
    (selectedPackageIds || []).map((id) => typeof id === "string" ? parseInt(id, 10) : id)
  );
  const [newPackageDrawerOpen, setNewPackageDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    if (refreshIdCheckPackages) yield refreshIdCheckPackages();
    if (refreshIndividualChecks) yield refreshIndividualChecks();
  }), [refreshIdCheckPackages, refreshIndividualChecks]);
  return /* @__PURE__ */ jsxs("div", { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("EditIdVerify.check") }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt10 }), children: [
      polyglot.t("EditIdVerify.poweredBy"),
      " ",
      /* @__PURE__ */ jsx(ZincIcon, __spreadValues({}, iconSize)),
      " ",
      polyglot.t("EditIdVerify.zincMex")
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt30, gap: spacing.g20 }, children: [
      idCheckPackages == null ? void 0 : idCheckPackages.map((pkg) => /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: `${pkg.name} (${formatAsGBPCurrency(pkg.price, 2)})`,
          checked: selectedPackages.includes(pkg.id),
          onChange: (_, checked) => {
            setSelectedPackages(
              checked ? [...selectedPackages, pkg.id] : selectedPackages.filter((id) => id !== pkg.id)
            );
          }
        },
        pkg.id
      )),
      checkScopes(user, ["user.onboard:all"], scopesContext) && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt10 }, children: /* @__PURE__ */ jsx(Link, { sx: __spreadProps(__spreadValues({}, underlinedLinkLight), { cursor: "pointer" }), onClick: () => setNewPackageDrawerOpen(true), children: polyglot.t("IdCheckPackage.editPackageList") }) })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { direction: "row", sx: { gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Link, { sx: __spreadValues({}, underlinedLinkLight), target: "_blank", href: ZINC_TERMS_LINK, children: polyglot.t("EditIdVerify.zinc") }),
      /* @__PURE__ */ jsx(Link, { sx: __spreadValues({}, underlinedLinkLight), target: "_blank", href: ZINC_CHECKS_LINK, children: polyglot.t("EditIdVerify.zincChecksInfo") })
    ] }),
    /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        title: !globalState.user.hasPaymentMethodOnFile && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.tooltip), { opacity: 1 }), children: [
          polyglot.t("EditIdVerify.cannotUse"),
          " ",
          /* @__PURE__ */ jsx(Link, { target: "_blank", href: "/settings/billing", children: polyglot.t("EditIdVerify.billing") })
        ] }),
        children: /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            type: "button",
            disabled: fetchingPackages || !globalState.user.hasPaymentMethodOnFile || selectedPackages.length === 0,
            fullWidth: true,
            colorVariant: "primary",
            sizeVariant: "medium",
            onClick: () => __async(void 0, null, function* () {
              if (selectedPackages.length === 0) {
                showMessage("Please select at least one package", "error");
                return;
              }
              const packageObjects = selectedPackages.map((id) => idCheckPackages == null ? void 0 : idCheckPackages.find((pkg) => pkg.id === id)).filter((pkg) => pkg !== void 0 && pkg !== null).map((pkg) => ({
                id: pkg.id,
                name: pkg.name,
                description: "",
                cost: pkg.price
              }));
              yield onSave(packageObjects);
            }),
            children: mode === "template" ? polyglot.t("General.save") : polyglot.t("General.invite")
          }
        ) })
      }
    ),
    newPackageDrawerOpen && /* @__PURE__ */ jsx(
      NewIdCheckPackageDrawer,
      {
        isOpen: newPackageDrawerOpen,
        setIsOpen: setNewPackageDrawerOpen,
        currentUser: user,
        idCheckPackage: null,
        individualChecks: individualChecks != null ? individualChecks : [],
        refresh
      }
    )
  ] });
};
