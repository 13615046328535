"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ViewUpcomingPayItemDrawer } from "@/component/dashboard/userDetails/pay-items/components/view-upcoming-pay-item-drawer.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const PayItemsPreviewList = ({
  oneOffPayments,
  refresh
}) => {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column" }, children: oneOffPayments.map((p) => /* @__PURE__ */ jsxs(
      Typography,
      {
        variant: "paragraph",
        onClick: () => {
          setSelectedPayment(p);
          setIsOpen(true);
        },
        sx: { cursor: "pointer", textDecoration: "underline" },
        children: [
          "Pay item - ",
          p.date
        ]
      },
      p.id
    )) }),
    isOpen && selectedPayment && /* @__PURE__ */ jsx(
      ViewUpcomingPayItemDrawer,
      {
        isOpen,
        setIsOpen,
        oneOffPayment: selectedPayment,
        refresh,
        onClose: () => setSelectedPayment(null)
      }
    )
  ] });
};
