"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { AttendanceAPI, AttendanceEndpoints } from "@/v2/feature/attendance/attendance.api";
import { TimeRangeFilterOption } from "@/v2/feature/attendance/attendance.interface";
import { buildTimesheetsFilterString } from "@/v2/feature/attendance/attendance.util";
import { UserTimesheetsDetail } from "@/v2/feature/attendance/company/sections/user-timesheets-detail.section";
import { ListSectionComponent } from "@/v2/feature/attendance/components/list-section.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
export const AttendanceTimesheetsComponent = ({ view }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getCachedUserById, loaded } = useCachedUsers();
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const usersRef = useRef(/* @__PURE__ */ new Map());
  const [users, setUsers] = useState(void 0);
  const [selectedUser, setSelectedUser] = useState(void 0);
  const [selectedUserAttendances, setSelectedUserAttendances] = useState(
    void 0
  );
  const [searchInput, setSearchInput] = useState("");
  const [timeRange, setTimeRange] = useState({ type: TimeRangeFilterOption.ThisWeek });
  const [scheduleFilter, setScheduleFilter] = useState("");
  const { data: attendanceSchedules } = useApiClient(
    view === "team" ? AttendanceEndpoints.getTeamTrackingAttendanceSchedules() : AttendanceEndpoints.getCompanyTrackingAttendanceSchedules(),
    {
      suspense: false
    }
  );
  const filterString = useMemo(
    () => buildTimesheetsFilterString(
      timeRange.type,
      timeRange.customStartDate ? new Date(timeRange.customStartDate) : void 0,
      timeRange.customEndDate ? new Date(timeRange.customEndDate) : void 0,
      scheduleFilter
    ),
    [timeRange, scheduleFilter]
  );
  const getUserAttendances = useCallback(() => __async(void 0, null, function* () {
    var _a;
    const { pageIndex, pageSize } = pagination;
    setLoading(true);
    setUserLoading(true);
    setSelectedUser(void 0);
    setSelectedUserAttendances(void 0);
    try {
      const requestsResponse = yield view === "team" ? AttendanceAPI.findTeamAttendancePerUserId(pageIndex, pageSize, searchInput, filterString) : AttendanceAPI.findAllAttendancePerUserId(pageIndex, pageSize, searchInput, filterString);
      setTotalPages(requestsResponse.totalPages);
      setTotalItems(requestsResponse.totalItems);
      setUsers(requestsResponse.items);
      if (!((_a = requestsResponse == null ? void 0 : requestsResponse.items) == null ? void 0 : _a.length)) {
        return;
      }
      const userAttendances = yield AttendanceAPI.getAttendanceTableByUserId(
        requestsResponse.items[0].userId,
        filterString
      );
      setSelectedUser(requestsResponse.items[0].userId);
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserTimesheets", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
      setUserLoading(false);
    }
  }), [polyglot, pagination, filterString, searchInput, showMessage, view]);
  useEffect(() => {
    getUserAttendances();
  }, [getUserAttendances]);
  const onPageChange = (pageIndex, pageSize) => {
    setPagination({ pageIndex, pageSize });
  };
  const listUsers = useMemo(() => {
    if (!users) return [];
    const usersMap = /* @__PURE__ */ new Map();
    users.forEach((user) => {
      usersMap.set(user.userId, { count: user.count, totalHours: user.totalHours });
    });
    usersRef.current = usersMap;
    return users.map((user) => {
      var _a, _b, _c, _d;
      const userData = usersRef.current.get(user.userId);
      const count = (_a = userData == null ? void 0 : userData.count) != null ? _a : user.count;
      const hours = (_b = userData == null ? void 0 : userData.totalHours) != null ? _b : user.totalHours;
      const showAlert = count > 0;
      const pending = count > 0 ? polyglot.t("AttendanceDomain.noOfPending", { smart_count: count }) : polyglot.t("AttendanceDomain.noPending");
      const totalHours = polyglot.t("AttendanceDomain.noOfHours", { smart_count: hours });
      return {
        id: user.userId,
        name: (_d = (_c = getCachedUserById(user.userId)) == null ? void 0 : _c.displayName) != null ? _d : "",
        details: `${pending} \u30FB ${totalHours}`,
        avatar: /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "s20" }),
        showAlert,
        alertColor: showAlert ? themeColors.ZeltYellow : "none"
      };
    });
  }, [users, getCachedUserById, polyglot]);
  const handleUserAttendance = (userId) => __async(void 0, null, function* () {
    setUserLoading(true);
    try {
      setSelectedUser(userId);
      const userAttendances = yield AttendanceAPI.getAttendanceTableByUserId(userId, filterString);
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserTimesheetsDetails", {
          nestError: nestErrorMessage(error)
        }),
        "error"
      );
      setSelectedUser(void 0);
    } finally {
      setUserLoading(false);
    }
  });
  const updateUserData = useCallback(
    (userId, newCount, newTotalHours) => {
      usersRef.current.set(userId, { count: newCount, totalHours: newTotalHours });
      if (!users) return;
      const updatedUsers = users.map((user) => {
        if (user.userId === userId) {
          return __spreadProps(__spreadValues({}, user), { count: newCount, totalHours: newTotalHours });
        }
        return user;
      });
      setUsers(updatedUsers);
    },
    [users]
  );
  const refreshUserDetails = () => __async(void 0, null, function* () {
    var _a, _b, _c;
    if (!selectedUser) return;
    setUserLoading(true);
    try {
      const userAttendances = yield AttendanceAPI.getAttendanceTableByUserId(selectedUser, filterString);
      if (userAttendances) {
        const pendingCount = userAttendances.data.filter((entry) => entry.status === "Submitted").length;
        const totalMinutes = (_c = (_b = (_a = userAttendances.header) == null ? void 0 : _a.jobs) == null ? void 0 : _b.reduce((total, job) => total + job.totalLength, 0)) != null ? _c : 0;
        const totalHours = Math.round(totalMinutes / 60);
        updateUserData(selectedUser, pendingCount, totalHours);
      }
      setSelectedUserAttendances(userAttendances);
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotFetchUserTimesheets", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setUserLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: { pt: 0, overflowY: "hidden" }, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        overflowY: "hidden",
        display: "grid",
        gridTemplateColumns: {
          sm: "1fr 1.5fr",
          md: "3fr 5fr",
          xl: "2.5fr 5fr"
        },
        height: "100%",
        width: "100%"
      },
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { minWidth: 0, overflow: "auto", borderRight: `1px solid ${themeColors.Background}` }, children: /* @__PURE__ */ jsx(
          ListSectionComponent,
          {
            searchInput,
            setSearchInput,
            timeRange,
            setTimeRange,
            attendanceSchedules,
            scheduleFilter,
            setScheduleFilter,
            setPagination,
            listUsers,
            pagination,
            totalPages,
            totalItems,
            onPageChange,
            handleUserAttendance,
            loading: loading || !loaded,
            selectedUser
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { minWidth: 0, overflow: "auto" }, children: /* @__PURE__ */ jsx(
          UserTimesheetsDetail,
          {
            selectedUserAttendances,
            selectedUser,
            loading: loading || userLoading,
            view,
            refresh: refreshUserDetails,
            filterString
          }
        ) })
      ]
    }
  ) }) });
};
