"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { TestModeBanner } from "@v2/feature/app-layout/components/test-mode-banner.component";
import { MainRouter } from "@v2/feature/app-layout/features/main-content/main.router";
import { CachedUsersProvider } from "@v2/feature/user/context/cached-users.context";
import { useSetLocalTimeHeaderInterceptor } from "@v2/infrastructure/api-client/api-client-request-interceptor.hook";
import { useApiErrorInterceptor } from "@v2/infrastructure/api-error/api-error-interceptor.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import axios from "axios";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { MessageWrapper } from "@/component/widgets/Message";
import { GlobalStateProvider } from "@/GlobalState";
import { LOGIN_ROUTE, TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX, USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { AccountStatus } from "@/lib/users";
import { getIntercomBootProps } from "@/utils/intercom";
import { UnpaidInvoicesBanner } from "@/v2/feature/app-layout/components/unpaid-invoices-banner.component";
import { MenuSection } from "@/v2/feature/app-layout/features/v2/menu/menu.section";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { TEMPLATE_CONTRACT_COMPANY_SIGN_PREFIX } from "@/v2/feature/auth/auth.interface";
import { TrialExpiredLayer } from "@/v2/feature/trial/components/trial-expired-layer.component";
import { UserAPI } from "@/v2/feature/user/user.api";
import { ErrorNotificationProvider } from "@/v2/infrastructure/error-notification-provider.context";
import { setSentryUser } from "@/v2/infrastructure/sentry/sentry.util";
import { showSidebar } from "@/v2/util/app-layout.util";
export const LayoutRootStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;
export const DashboardLayout = () => {
  var _a;
  const { replacePhrasesOnly, setUserLanguage } = usePolyglot();
  const { boot } = useIntercom();
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const [user, setUser] = useState(null);
  const [rawUserResponse, setRawUserResponse] = useState(null);
  const [showHiddenFeatures, setShowHiddenFeatures] = useState(null);
  const [unpaidInvoicesFeature, setUnpaidInvoicesFeature] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useApiErrorInterceptor();
  useSetLocalTimeHeaderInterceptor();
  const userIdFromUserContractSigningPath = useMemo(() => {
    var _a2;
    const currentPath = (_a2 = routerHistory == null ? void 0 : routerHistory.location) == null ? void 0 : _a2.pathname;
    const isContractSigningPath = Boolean(currentPath == null ? void 0 : currentPath.includes(TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX));
    const userSigningContract = Number(currentPath.substring(currentPath.lastIndexOf("/") + 1));
    if (!isContractSigningPath || !userSigningContract) return null;
    return userSigningContract;
  }, [(_a = routerHistory == null ? void 0 : routerHistory.location) == null ? void 0 : _a.pathname]);
  const isUserSigningContract = useCallback(
    (userId) => {
      if (!userId) return false;
      return userIdFromUserContractSigningPath === userId;
    },
    [userIdFromUserContractSigningPath]
  );
  const fetchUser = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b, _c, _d;
    try {
      const response = yield AuthAPI.getAuthMe(false);
      setRawUserResponse(response);
      const responseUserId = (_a2 = response == null ? void 0 : response.user) == null ? void 0 : _a2.userId;
      const responseCompanyId = (_c = (_b = response == null ? void 0 : response.user) == null ? void 0 : _b.company) == null ? void 0 : _c.companyId;
      if (responseUserId && responseCompanyId) setSentryUser(responseUserId, responseCompanyId);
      if (response.user && response.intercomHash) {
        const intercomSettings = getIntercomBootProps(
          response.user,
          response.intercomHash,
          response.companyStats
        );
        if (intercomSettings !== null && intercomSettings) {
          boot(intercomSettings);
        }
      }
    } catch (error) {
      const redirectUri = (routerLocation == null ? void 0 : routerLocation.pathname) && ((_d = routerLocation.pathname) == null ? void 0 : _d.length) > 1 ? `${routerLocation.pathname}${routerLocation.search}` : null;
      if (error.response && error.response.status === 401) {
        let loginRouteWithRedirect = LOGIN_ROUTE;
        let redirectIsContractSigningLink = false;
        let contractUserPwdSet;
        if (redirectUri) {
          loginRouteWithRedirect = `${LOGIN_ROUTE}?redirect=${encodeURIComponent(redirectUri)}`;
          redirectIsContractSigningLink = redirectUri.includes(TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX) || redirectUri.includes(TEMPLATE_CONTRACT_COMPANY_SIGN_PREFIX);
          if (redirectIsContractSigningLink) {
            const contractIdRedirectParams = redirectUri.split("/");
            const contractId = contractIdRedirectParams[contractIdRedirectParams.length - 2];
            if (contractId) {
              contractUserPwdSet = yield AuthAPI.isContractRecipientPasswordSet(contractId);
            }
          }
        }
        routerHistory.push(
          loginRouteWithRedirect,
          redirectIsContractSigningLink ? { contractRecipientHasSetPassword: contractUserPwdSet, contractSigningRedirect: true } : { contractSigningRedirect: false }
        );
      } else {
        throw error;
      }
    }
  }), []);
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);
  useEffect(() => {
    var _a2;
    if (!rawUserResponse) return;
    const response = rawUserResponse;
    if (response.user.accountStatus === AccountStatus.InvitedToOnboard && !isUserSigningContract((_a2 = response == null ? void 0 : response.user) == null ? void 0 : _a2.userId)) {
      routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId: response.user.userId }));
      return;
    }
    const showHiddenFeatures2 = response.showHiddenFeatures;
    const isSuperAdmin2 = Boolean(response && response.isSuperAdmin);
    setUser(response.user);
    setIsSuperAdmin(isSuperAdmin2);
    setShowHiddenFeatures(showHiddenFeatures2);
    setUnpaidInvoicesFeature(response.hasUnpaidInvoices);
  }, [isUserSigningContract, rawUserResponse, routerHistory]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      if (rawUserResponse) {
        yield replacePhrasesOnly();
        setUserLanguage(rawUserResponse.user.language);
      }
    }))();
  }, [rawUserResponse, replacePhrasesOnly, setUserLanguage]);
  useEffect(() => {
    UserAPI.fetchUserCache(true, false);
  });
  return /* @__PURE__ */ jsxs(LayoutRootStyle, { children: [
    unpaidInvoicesFeature && /* @__PURE__ */ jsx(UnpaidInvoicesBanner, {}),
    user && /* @__PURE__ */ jsx(
      GlobalStateProvider,
      {
        initialState: {
          user,
          loggedOut: true,
          showHiddenFeatures,
          message: [],
          paymentFailed: false,
          inspectorMode: false,
          drawerState: false,
          isTestModeEnabled: false,
          trialMode: { enabled: false, lapsed: false },
          alerts: {},
          showSettingBar: false,
          publicURL: null
        },
        children: /* @__PURE__ */ jsxs(ErrorNotificationProvider, { axios, children: [
          /* @__PURE__ */ jsxs(CachedUsersProvider, { children: [
            /* @__PURE__ */ jsx(TestModeBanner, {}),
            /* @__PURE__ */ jsx(TrialExpiredLayer, {}),
            showSidebar(routerLocation == null ? void 0 : routerLocation.pathname) && /* @__PURE__ */ jsx(MenuSection, {}),
            showHiddenFeatures !== null && rawUserResponse ? /* @__PURE__ */ jsx(MainRouter, { isSuperAdmin, userAPIResponse: rawUserResponse }) : null
          ] }),
          /* @__PURE__ */ jsx(MessageWrapper, {})
        ] })
      }
    )
  ] });
};
