"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { ReactComponent as Completed } from "@/images/side-bar-icons/ok-green.svg";
import {
  ReactComponent as Draft,
  ReactComponent as InProgress,
  ReactComponent as PartiallyCompleted
} from "@/images/side-bar-icons/WaitingFull.svg";
import { Typography } from "@/v2/components/typography/typography.component";
import { formatPayrunState } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrunStatus = ({ state, sx, color, iconSize = 16, variant = "paragraph" }) => {
  const { polyglot } = usePolyglot();
  const iconHW = { height: iconSize, width: iconSize };
  const icon = {
    [PayrunStates.draft]: () => /* @__PURE__ */ jsx(Draft, __spreadValues({}, iconHW)),
    [PayrunStates.inProgress]: () => /* @__PURE__ */ jsx(InProgress, __spreadValues({}, iconHW)),
    [PayrunStates.partiallyCompleted]: () => /* @__PURE__ */ jsx(PartiallyCompleted, __spreadValues({}, iconHW)),
    [PayrunStates.completed]: () => /* @__PURE__ */ jsx(Completed, __spreadProps(__spreadValues({}, iconHW), { style: { fill: themeColors.Green } }))
  }[state]();
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", gap: spacing.g5, alignItems: "center" }, sx), children: [
    icon,
    /* @__PURE__ */ jsx(Typography, { variant, sx: { color }, children: formatPayrunState(state, polyglot) })
  ] });
};
