"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { generatePath } from "react-router-dom";
import { DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE } from "@/lib/routes";
export const OffboardingDeviceGenericDrawer = ({ isOpen, setIsOpen, onClose, device }) => {
  const openDeviceDetailsPage = useCallback(() => {
    if (!(device == null ? void 0 : device.devicePossession)) return;
    const path = generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, {
      devicePossessionId: device.devicePossession.id
    });
    window.open(path, "_blank");
  }, [device]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: !(device == null ? void 0 : device.devicePossession) ? /* @__PURE__ */ jsx(Fragment, {}) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Manage device" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "This device can be managed only from the tech module" }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", onClick: openDeviceDetailsPage, fullWidth: true, children: "Go to device page" }) })
  ] }) });
};
