"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { groupBy, keyBy } from "lodash";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import { ResultOverviewDetailed } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/result-overview-detailed.component";
import { ResultsOverviewReviewScoreTable } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-review-score-table.component";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ManagerResultSection = ({
  setShowResult,
  revieweeId,
  cycle
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const { data: results, isLoading: resultsLoading } = useApiClient(
    ReviewResultEndpoints.getReviewOverviewResultByReviewee(revieweeId, cycle.id),
    {
      suspense: false
    }
  );
  const [resultByReviewerView, setResultByReviewerView] = useState(ReviewerTypes.Self);
  const filteredResults = useMemo(() => {
    var _a2, _b;
    return (_b = (_a2 = results == null ? void 0 : results.questionResponseSummary) != null ? _a2 : []) == null ? void 0 : _b.map((question) => {
      return __spreadProps(__spreadValues({}, question), { answers: question.answers.filter((a) => a.answerType === resultByReviewerView) });
    }).filter((q) => q.answers.length > 0);
  }, [results, resultByReviewerView]);
  const questionLookup = useMemo(() => {
    return keyBy(filteredResults != null ? filteredResults : [], "questionId");
  }, [filteredResults]);
  const sectionGroupedQuestionLookup = useMemo(() => {
    return groupBy(filteredResults != null ? filteredResults : [], "sectionId");
  }, [filteredResults]);
  const sectionLookup = useMemo(() => {
    var _a2;
    return keyBy((_a2 = results == null ? void 0 : results.sections) != null ? _a2 : [], "id");
  }, [results == null ? void 0 : results.sections]);
  const TabFilter = useMemo(
    () => [
      { name: polyglot.t("ReviewerTypes.self"), value: ReviewerTypes.Self },
      { name: polyglot.t("ReviewerTypes.manager"), value: ReviewerTypes.Manager },
      { name: polyglot.t("ReviewerTypes.peer"), value: ReviewerTypes.Peer },
      { name: polyglot.t("ReviewerTypes.upward"), value: ReviewerTypes.Upward }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      id: "manager-result-section",
      title: "Results",
      noHorizontalPadding: false,
      topHeaderPaddingSx: {
        px: spacing.px16,
        boxSizing: "border-box",
        backgroundColor: themeColors.TableHover,
        borderTopRightRadius: radius.br15,
        borderTopLeftRadius: radius.br15
      },
      loading: false,
      headerWidth: "100%",
      contentWidth: "100%",
      buttons: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sizeVariant: "small",
            colorVariant: "light",
            style: { backgroundColor: themeColors.white },
            onClick: () => setShowResult(false),
            children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey }))
          }
        )
      ],
      contentSx: {
        boxSizing: "border-box",
        backgroundColor: themeColors.TableHover,
        borderBottomRightRadius: radius.br15,
        borderBottomLeftRadius: radius.br15
      },
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                headerWidth: "100%",
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ResultsOverviewReviewScoreTable,
                      {
                        results,
                        resultsLoading: Boolean(resultsLoading),
                        reviewScore: results ? results.reviewerScoreSummary : [],
                        showBorder: true
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                headerWidth: "100%",
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TabFilterButtons,
                      {
                        filters: TabFilter,
                        setFilterValue: (filterValue) => {
                          setResultByReviewerView(filterValue);
                        },
                        filterValue: resultByReviewerView != null ? resultByReviewerView : ""
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ResultOverviewDetailed,
                      {
                        order: (_a = results == null ? void 0 : results.order) != null ? _a : [],
                        questionLookup,
                        sectionLookup,
                        sectionGroupedQuestionLookup,
                        growthFactors,
                        hidePerQuestionScore: true,
                        reviewCycle: cycle
                      }
                    )
                  }
                ]
              }
            ]
          }
        )
      ]
    }
  );
};
