"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { ReactComponent as NewWindow } from "@/images/new-theme-icon/NewWindow.svg";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { TIME_ME_CALENDAR_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { AbsenceAPI } from "@/v2/feature/absence/absence.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getOverlapText = (overlap, userIds, isViewMode, preventOwnOverlaps, polyglot) => {
  var _a;
  const startDate = getDateString(overlap.start, false, "d MMM");
  const endDate = overlap.end ? ` - ${getDateString(overlap.end, false, "d MMM")} ` : "";
  return overlap.user ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "10px" }, children: [
      /* @__PURE__ */ jsx(UserCell, { userId: (_a = overlap.user) == null ? void 0 : _a.userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }) }),
      preventOwnOverlaps && !isViewMode && userIds.includes(overlap.userId) && /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("AbsenceOverlapDisplay.requestOmitted"), placement: "top", children: /* @__PURE__ */ jsx(Mistake, { width: 15, height: 15 }) })
    ] }),
    /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
      startDate,
      " ",
      endDate && ` ${endDate}`
    ] })
  ] }) : null;
};
const getEventOverlapText = (eventOverlap) => {
  var _a;
  const startDate = getDateString(eventOverlap.startDate, false, "d MMM");
  const endDate = eventOverlap.endDate ? ` - ${getDateString(eventOverlap.endDate, false, "d MMM")} ` : "";
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            bgcolor: (_a = eventOverlap.color) != null ? _a : themeColors.Background,
            width: "20px",
            height: "20px",
            borderRadius: "100%"
          }
        }
      ),
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: eventOverlap.name })
    ] }),
    /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
      startDate,
      " ",
      endDate && ` ${endDate}`
    ] })
  ] });
};
const sortOverlapAbsences = (userIds) => {
  const userIdsSet = new Set(userIds);
  return (a, b) => {
    const aHas = userIdsSet.has(a.userId);
    const bHas = userIdsSet.has(b.userId);
    if (aHas === bHas) return 0;
    return aHas ? -1 : 1;
  };
};
export const AbsenceOverlapDisplay = ({
  userIds,
  absenceStart,
  absenceEnd,
  absenceId,
  showCalendarLink,
  isViewMode = false
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [overlaps, setOverlaps] = useState([]);
  const [eventsOverlap, setEventsOverlap] = useState([]);
  const [showOverlapText, setShowOverlapText] = useState(false);
  const [preventOwnOverlaps, setPreventOwnOverlaps] = useState(true);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        if (!userIds || userIds.length === 0) return;
        const overlapResult = yield AbsenceAPI.verifyCompanyOverlaps({
          absenceStart,
          absenceEnd,
          absenceId,
          userIds
        });
        if (overlapResult.isOverlapping && (overlapResult.absences.map((a) => a.absenceId !== absenceId).length > 0 || overlapResult.events.length > 0)) {
          const overlapsAbsences = overlapResult.absences;
          overlapsAbsences.sort(sortOverlapAbsences(userIds));
          setOverlaps(overlapResult.absences);
          setEventsOverlap(overlapResult.events);
          setShowOverlapText(true);
          setPreventOwnOverlaps(overlapResult.preventOwnOverlaps);
        } else {
          setShowOverlapText(false);
          setOverlaps([]);
          setEventsOverlap([]);
        }
      } catch (error) {
        console.error(polyglot.t("AbsenceOverlapDisplay.errorMessages"), error.message);
      }
    }))();
  }, [polyglot, userIds, absenceStart, absenceEnd, absenceId]);
  const filteredOverlaps = overlaps.filter((ol) => absenceId !== ol.absenceId);
  return filteredOverlaps.length + eventsOverlap.length === 0 ? null : /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        transition: "all 0.3s ease",
        transitionProperty: "opacity, margin-bottom",
        opacity: showOverlapText ? 1 : 0,
        marginBottom: showOverlapText ? spacing.m10 : 0
      },
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center", md: "center", lg: "center" },
              mb: spacing.m10
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: polyglot.t("AbsenceOverlapDisplay.overlaps") }),
              showCalendarLink && /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "link",
                  colorVariant: "text",
                  onClick: () => routerHistory.push(TIME_ME_CALENDAR_ROUTE),
                  endIcon: /* @__PURE__ */ jsx(NewWindow, __spreadValues({}, iconSize)),
                  children: polyglot.t("AbsenceOverlapDisplay.openCalendar")
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
          filteredOverlaps.map((ol) => getOverlapText(ol, userIds, isViewMode, preventOwnOverlaps, polyglot)),
          eventsOverlap.map((ov) => getEventOverlapText(ov))
        ] })
      ]
    }
  );
};
