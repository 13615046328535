"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack, SwipeableDrawer } from "@mui/material";
import { pick } from "lodash";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { TitledHeaderStack } from "@/v2/components/titled-header.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { UserPayrollStatusCell } from "@/v2/feature/payroll/components/user-payroll-status-cell.component";
import { getUserPayrollStatusSortOrder } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { SetupPayrollDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-intro/set-up-payroll-drawer.component";
import { IconMapping, PayrollStatusLabel } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { caseInsensitiveSort, filterByTextSearch } from "@/v2/util/array.util";
export const PayrollCompanyIntro = () => {
  const { polyglot } = usePolyglot();
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [showPayrollSetupDrawer, setShowPayrollSetupDrawer] = useState(false);
  const { data } = useApiClient(PayrollEndpoints.getCompanyPayrollOverview(), { suspense: false });
  const employees = data == null ? void 0 : data.employees;
  const filteredEmployees = useMemo(() => {
    if (!employees) return employees;
    if (!searchQuery && !categoryFilter) return employees;
    let result = employees;
    if (categoryFilter) {
      const sections = Object.fromEntries(
        categoryFilter.split("&").map((s) => s.split("="))
      );
      for (const [category, filter] of Object.entries(sections)) {
        if (category === polyglot.t("CompanyPayroll.department")) {
          const departmentIds = new Set(filter.split(",").map((s) => Number(s)));
          result = result.filter((user) => user.department && departmentIds.has(user.department.id));
        }
        if (category === polyglot.t("CompanyPayroll.entity")) {
          const entityIds = new Set(filter.split(",").map((s) => Number(s)));
          result = result.filter((user) => user.entity && entityIds.has(user.entity.id));
        }
        if (category === polyglot.t("CompanyPayroll.payrollStatus")) {
          const statuses = new Set(filter.split(","));
          result = result.filter((user) => statuses.has(user.status));
        }
      }
    }
    return filterByTextSearch(searchQuery, result, (item) => {
      var _a, _b;
      return [
        `${item.firstName} ${item.lastName}`,
        (_a = item.department) == null ? void 0 : _a.name,
        (_b = item.entity) == null ? void 0 : _b.legalName
      ];
    });
  }, [employees, searchQuery, categoryFilter, polyglot]);
  const starterColor = themeColors.GreenMiddle;
  const leaverColor = themeColors.RedDark;
  const getStatusLabel = useCallback((status) => {
    return {
      current: { label: PayrollStatusLabel.Current, icon: IconMapping[PayrollStatusLabel.Current] },
      starter: { label: PayrollStatusLabel.NewJoiner, icon: IconMapping[PayrollStatusLabel.NewJoiner] },
      leaver: { label: PayrollStatusLabel.Leaver, icon: IconMapping[PayrollStatusLabel.Leaver] }
    }[status];
  }, []);
  const columns = useMemo(() => {
    return [
      {
        id: "user",
        header: () => polyglot.t("CompanyPayroll.name"),
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortString(a, b, (item) => `${item.firstName} ${item.lastName}`, { sensitivity: "base" }),
        cell: (c) => /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId, name: pick(c.row.original, ["firstName", "lastName"]) })
      },
      {
        id: "department",
        header: () => polyglot.t("CompanyPayroll.department"),
        accessorFn: (x) => {
          var _a;
          return (_a = x.department) == null ? void 0 : _a.name;
        },
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.department) == null ? void 0 : _a.name;
        }, { sensitivity: "base" }),
        cell: (c) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = c.row.original.department) == null ? void 0 : _a.name) != null ? _b : "" });
        }
      },
      {
        id: "entity",
        header: () => polyglot.t("CompanyPayroll.entity"),
        accessorFn: (x) => {
          var _a;
          return (_a = x.entity) == null ? void 0 : _a.legalName;
        },
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item.entity) == null ? void 0 : _a.legalName;
        }, { sensitivity: "base" }),
        cell: (c) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = c.row.original.entity) == null ? void 0 : _a.legalName) != null ? _b : "" });
        }
      },
      {
        id: "status",
        header: () => polyglot.t("CompanyPayroll.payrollStatus"),
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => {
          const statusA = getStatusLabel(a.original.status).label;
          const statusB = getStatusLabel(b.original.status).label;
          const orderA = getUserPayrollStatusSortOrder(statusA);
          const orderB = getUserPayrollStatusSortOrder(statusB);
          return orderA - orderB;
        },
        cell: (c) => /* @__PURE__ */ jsx(UserPayrollStatusCell, { status: getStatusLabel(c.row.original.status) })
      },
      {
        id: "salary",
        header: () => polyglot.t("CompanyPayroll.monthlySalary"),
        accessorFn: (x) => x,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.salary) == null ? void 0 : _a.rate;
        }),
        cell: (c) => {
          var _a, _b;
          return /* @__PURE__ */ jsx(CurrencyCell, { value: (_a = c.row.original.salary) == null ? void 0 : _a.rate, currency: (_b = c.row.original.salary) == null ? void 0 : _b.currency });
        }
      }
    ];
  }, [getStatusLabel, polyglot]);
  const currentPeriod = useMemo(() => {
    if (!data) return "";
    return new Date(data.payrunEndDate).toLocaleDateString("en-GB", { month: "short", year: "2-digit" });
  }, [data]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("CompanyPayroll.salary") }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: () => {
              setShowPayrollSetupDrawer(true);
            },
            children: polyglot.t("CompanyPayroll.setupPayroll")
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { border: false, noHorizontalPadding: true, sx: { pb: 0, ml: spacing.ml50 }, children: data && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g20, flex: 1 }, children: [
      /* @__PURE__ */ jsx(
        TitledHeaderStack,
        {
          items: [
            {
              label: polyglot.t("CompanyPayroll.currentPeriod"),
              value: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: currentPeriod })
            },
            {
              label: polyglot.t("CompanyPayroll.employeeCount"),
              value: /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: data.employees.length }),
                /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
                  data.starters > 0 && /* @__PURE__ */ jsx(
                    Typography,
                    {
                      variant: "headline2",
                      sx: { color: starterColor },
                      children: `+${data.starters}`
                    }
                  ),
                  data.leavers > 0 && /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { color: leaverColor }, children: `-${data.leavers}` })
                ] })
              ] })
            }
          ]
        }
      ),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(TableSearch, { query: searchQuery, handleChange: (e) => setSearchQuery(e.target.value) }),
        /* @__PURE__ */ jsx(
          CategoryFilters,
          {
            filterTypes: {
              [polyglot.t("CompanyPayroll.department")]: [...data.departments].sort((a, b) => caseInsensitiveSort(a, b, (x) => x.name)).map((d) => ({ label: d.name, value: d.id })),
              [polyglot.t("CompanyPayroll.entity")]: [...data.entities].sort((a, b) => caseInsensitiveSort(a, b, (x) => x.legalName)).map((e) => ({ label: e.legalName, value: e.id })),
              [polyglot.t("CompanyPayroll.payrollStatus")]: [
                { label: polyglot.t("CompanyPayroll.payrollStatusStarter"), value: "starter" },
                { label: polyglot.t("CompanyPayroll.payrollStatusLeaver"), value: "leaver" },
                { label: polyglot.t("CompanyPayroll.payrollStatusCurrent"), value: "current" }
              ]
            },
            setFilterString: setCategoryFilter,
            filterString: categoryFilter
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Stack, { sx: { flex: 1 }, children: filteredEmployees && /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredEmployees,
          columnData: columns,
          initialPageSize: 100,
          fixedLastColumn: false,
          initialSort: [
            { id: "status", desc: false },
            { id: "user", desc: true }
          ],
          style: { flex: "1 1 0" }
        }
      ) })
    ] }) }),
    /* @__PURE__ */ jsx(
      SwipeableDrawer,
      {
        anchor: "right",
        open: showPayrollSetupDrawer,
        onOpen: () => {
        },
        onClose: () => {
          setShowPayrollSetupDrawer(false);
        },
        children: /* @__PURE__ */ jsx(SetupPayrollDrawer, {})
      }
    )
  ] });
};
