"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup, capitalize } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { CalendarSettingsAPI } from "@/v2/feature/calendar/calendar.api";
import {
  ViewSettingEnum,
  ViewSettingValueArray,
  ViewSettingsOptionLabel
} from "@/v2/feature/calendar/calendar.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const CalendarSettingSchema = Yup.object().shape({
  viewSetting: Yup.string().oneOf(ViewSettingValueArray).nullable().required()
});
export const CalendarSettingDrawer = ({ isOpen, setIsOpen, initialValues, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(CalendarSettingDrawerContent, { initialValues, setIsOpen, refresh }) });
};
const CalendarSettingDrawerContent = ({
  setIsOpen,
  initialValues,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValues && initialValues.viewSetting ? initialValues : { viewSetting: ViewSettingEnum.Company },
    enableReinitialize: true,
    validationSchema: CalendarSettingSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        let updatedValue = __spreadValues(__spreadValues({}, (initialValues == null ? void 0 : initialValues.id) ? { id: initialValues.id } : {}), values);
        yield CalendarSettingsAPI.upsertSettings(updatedValue);
        showMessage(polyglot.t("CalendarSettingDrawerContent.successMessages.update"), "success");
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("CalendarSettingDrawerContent.errorMessages.encounter", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        yield refresh();
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { marginBottom: spacing.m20 }, children: polyglot.t("CalendarSettingDrawerContent.title") }),
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
      RadioGroup,
      {
        name: "viewSetting",
        onChange: (e) => {
          formik.setFieldValue("viewSetting", e.target.value);
        },
        sx: { flexFlow: "column" },
        children: ViewSettingsOptionLabel(polyglot).map((item, idx, arr) => /* @__PURE__ */ jsx(
          FormControlLabel,
          {
            labelPlacement: "start",
            value: item.value,
            checked: formik.values.viewSetting === item.value,
            control: /* @__PURE__ */ jsx(StyledRadio, {}),
            disabled: false,
            label: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: capitalize(item.label) }) }),
            sx: {
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
              margin: 0,
              paddingY: spacing.p10,
              borderBottom: idx < arr.length - 1 ? borders.background : "none"
            }
          }
        ))
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
