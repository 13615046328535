"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitEndpoints } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { CustomBenefitType } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import {
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { AddEffectiveUserCustomBenefitDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/add-effective-user-custom-benefit-drawer.component";
import { UpdateEffectiveUserCustomBenefitDrawer } from "@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/update-effective-user-custom-benefit-drawer.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const ViewUserCustomBenefitDrawer = ({ isOpen, setIsOpen, userBenefit, onClose, refresh }) => {
  var _a;
  const { data: userFamilyMembers } = useApiClient(
    isRecurringBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.type) ? UserEndpoints.getUserFamilyMembers(userBenefit.userId) : null,
    { suspense: false }
  );
  const { data: canManage } = useApiClient(
    CustomBenefitEndpoints.canManageBenefitForUser(userBenefit.customBenefitId, userBenefit.userId),
    { suspense: false }
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    ViewUserCustomBenefitDrawerContent,
    {
      userBenefit,
      userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [],
      refresh,
      setIsViewOpen: setIsOpen,
      canManage: !!canManage
    }
  ) });
};
export const ViewUserCustomBenefitDrawerContent = ({
  userBenefit,
  userFamilyMembers,
  refresh,
  setIsViewOpen,
  canManage
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const hasOpeningBalance = isUsingOpeningBalance((_a = userBenefit.customBenefit) == null ? void 0 : _a.type);
  const isLoan = isLoanBenefit((_b = userBenefit.customBenefit) == null ? void 0 : _b.type);
  const isRecurring = isRecurringBenefit((_c = userBenefit.customBenefit) == null ? void 0 : _c.type);
  const dependantsList = useMemo(() => {
    var _a2;
    if (!(userBenefit == null ? void 0 : userBenefit.dependants) || !(userBenefit == null ? void 0 : userBenefit.dependantsList)) return "";
    return (_a2 = userFamilyMembers.filter((m) => userBenefit.dependantsList.includes(m.id)).map((m) => m.name).join(", ")) != null ? _a2 : "";
  }, [userFamilyMembers, userBenefit]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (_e = (_d = userBenefit.customBenefit) == null ? void 0 : _d.name) != null ? _e : polyglot.t("BenefitModule.userBenefit") }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("General.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: userBenefit.userId, sx: { mt: spacing.m5 }, nameVariant: "headline3" })
    ] }),
    !userBenefit.included && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("BenefitModule.employeeNotAMemberOfBenefit") }),
    userBenefit.effectiveDate && /* @__PURE__ */ jsx(
      LineItem,
      {
        name: polyglot.t("General.effectiveDate"),
        value: new LocalDate(userBenefit.effectiveDate).toLocaleDateString()
      }
    ),
    userBenefit.effectiveEndDate && /* @__PURE__ */ jsx(
      LineItem,
      {
        name: polyglot.t("General.effectiveEndDate"),
        value: new LocalDate(userBenefit.effectiveEndDate).toLocaleDateString()
      }
    ),
    ((_f = userBenefit.customBenefit) == null ? void 0 : _f.type) && /* @__PURE__ */ jsx(LineItem, { name: polyglot.t("General.type"), value: userBenefit.customBenefit.type }),
    hasOpeningBalance && /* @__PURE__ */ jsx(
      LineItem,
      {
        name: isLoan ? polyglot.t("BenefitModule.borrowed") : polyglot.t("BenefitModule.allowance"),
        value: userBenefit.openingBalance ? formatMoney({
          amount: userBenefit.openingBalance,
          asDecimal: true
        }) : null
      }
    ),
    isLoan && /* @__PURE__ */ jsx(LineItem, { name: polyglot.t("BenefitModule.numberOfInstallments"), value: userBenefit.numberOfInstallments }),
    isRecurring && /* @__PURE__ */ jsx(
      LineItem,
      {
        name: polyglot.t("BenefitModule.employerContribution"),
        value: userBenefit.employerContribution ? formatMoney({
          amount: userBenefit.employerContribution,
          asDecimal: true
        }) : null
      }
    ),
    isRecurring && /* @__PURE__ */ jsx(
      LineItem,
      {
        name: polyglot.t("BenefitModule.employeeContribution"),
        value: userBenefit.employeeContribution ? formatMoney({
          amount: userBenefit.employeeContribution,
          asDecimal: true
        }) : null
      }
    ),
    isRecurring && /* @__PURE__ */ jsx(LineItem, { name: polyglot.t("BenefitModule.dependants"), value: userBenefit.dependants }),
    isRecurring && dependantsList && /* @__PURE__ */ jsx(LineItem, { name: polyglot.t("BenefitModule.dependantsList"), value: dependantsList }),
    userBenefit.updatedBy && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("BenefitModule.lastUpdatedBy") }),
      /* @__PURE__ */ jsx(UserCell, { userId: userBenefit.updatedBy, sx: { mt: spacing.m5 }, nameVariant: "headline3" })
    ] }),
    userBenefit.createdBy && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("General.createdBy") }),
      /* @__PURE__ */ jsx(UserCell, { userId: userBenefit.createdBy, sx: { mt: spacing.m5 }, nameVariant: "headline3" })
    ] }),
    canManage && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      (userBenefit == null ? void 0 : userBenefit.included) && userBenefit.effectiveDate && userBenefit.customBenefit && ((_g = userBenefit.customBenefit) == null ? void 0 : _g.type) !== CustomBenefitType.Loan && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => {
            setIsUpdateOpen(true);
          },
          sizeVariant: "medium",
          colorVariant: "secondary",
          fullWidth: true,
          children: polyglot.t("BenefitModule.updateCurrent")
        }
      ),
      (!(userBenefit == null ? void 0 : userBenefit.included) || userBenefit.customBenefit && ((_h = userBenefit.customBenefit) == null ? void 0 : _h.type) === CustomBenefitType.Recurring) && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => {
            setIsAddOpen(true);
          },
          sizeVariant: "medium",
          colorVariant: userBenefit && userBenefit.effectiveDate ? "secondary" : "primary",
          fullWidth: true,
          children: userBenefit && userBenefit.effectiveDate ? polyglot.t("BenefitModule.change") : polyglot.t("BenefitModule.addUserToBenefit")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      AddEffectiveUserCustomBenefitDrawer,
      {
        isOpen: isAddOpen,
        setIsOpen: setIsAddOpen,
        userBenefit,
        onClose: () => {
          setIsViewOpen(false);
          setIsAddOpen(false);
        },
        refresh
      }
    ),
    /* @__PURE__ */ jsx(
      UpdateEffectiveUserCustomBenefitDrawer,
      {
        isOpen: isUpdateOpen,
        setIsOpen: setIsUpdateOpen,
        userBenefit,
        onClose: () => {
          setIsViewOpen(false);
          setIsUpdateOpen(false);
        },
        refresh
      }
    )
  ] });
};
const LineItem = ({ name, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "5px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: name }),
    /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: value != null ? value : /* @__PURE__ */ jsx(EmptyCell, {}) })
  ] });
};
