"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider } from "formik";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TimeTextField } from "@/v2/components/forms/time-text-field.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  getDeadlineByReviewerFields
} from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util";
import { useTimelineForm } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/hook/use-timeline-form.hook";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const TimelineScheduledModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  showFlags
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(TimelineScheduledContent, { reviewCycle, onClose, refresh, showFlags }) });
};
const TimelineScheduledContent = ({
  reviewCycle,
  onClose,
  refresh,
  showFlags
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const formik = useTimelineForm(reviewCycle, onClose, refresh, showFlags);
  const canUpdateStartDate = useMemo(
    () => (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Draft || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Scheduled,
    [reviewCycle == null ? void 0 : reviewCycle.state]
  );
  const Views = [
    { name: "Start now", value: "true" },
    { name: "Schedule for later", value: "false" }
  ];
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Timeline" }),
    canUpdateStartDate && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m5, mb: spacing.m10 }, children: /* @__PURE__ */ jsx(
      TabFilterButtons,
      {
        filters: Views,
        setFilterValue: (value) => {
          if (value === "true") {
            formik.setFieldValue("timelineSettings.startDate", null);
            formik.setFieldValue("timelineSettings.startTime", null);
            formik.setFieldValue("timelineSettings.startTimeMeridiem", null);
          }
          formik.setFieldValue("timelineSettings.startNow", value === "true");
        },
        filterValue: formik.values.timelineSettings.startNow.toString()
      }
    ) }),
    canUpdateStartDate && !formik.values.timelineSettings.startNow && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "timelineSettings.startDate",
        label: "Start date (GMT)",
        sx: { width: "100%" },
        disablePast: true,
        value: formik.values.timelineSettings.startDate,
        onChange: (date) => formik.setFieldValue("timelineSettings.startDate", date)
      }
    ) }),
    canUpdateStartDate && !formik.values.timelineSettings.startNow && /* @__PURE__ */ jsx(
      TimeTextField,
      {
        name: "timelineSettings.startTime",
        label: "Start time (GMT)",
        value: formik.values.timelineSettings.startTime,
        onChange: (value) => {
          formik.setFieldValue("timelineSettings.startTime", value);
        },
        selectedMeridiem: formik.values.timelineSettings.startTimeMeridiem,
        sx: { width: "100%" },
        onMeridiemChange: (newMeridiem) => {
          formik.setFieldValue("timelineSettings.startTimeMeridiem", newMeridiem);
        },
        error: ((_a = formik.touched.timelineSettings) == null ? void 0 : _a.startTime) && Boolean((_b = formik.errors.timelineSettings) == null ? void 0 : _b.startTime),
        helperText: ((_c = formik.touched.timelineSettings) == null ? void 0 : _c.startTime) && ((_d = formik.errors.timelineSettings) == null ? void 0 : _d.startTime)
      }
    ),
    getDeadlineByReviewerFields(showFlags, formik, polyglot)
  ] }) });
};
