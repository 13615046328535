"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TaskFormModal } from "@v2/feature/task/components/task-add-modal/task-form-modal.component";
import { TaskTable } from "@v2/feature/task/components/task-table/task-table.component";
import { TaskStatuses, TaskTabFilter } from "@v2/feature/task/task.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { format } from "date-fns";
import saveAs from "file-saver";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { ReactComponent as Mail } from "@/images/side-bar-icons/Mail.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { TaskLaunchModal } from "@/v2/feature/task/components/task-launch/task-launch-modal.component";
import { SelectDeselectRows } from "@/v2/feature/task/components/task-table/select-deselect-rows.component";
import { TaskViewModal } from "@/v2/feature/task/components/task-view/task-view-modal.component";
import { emojiOptions } from "@/v2/feature/task/subfeature/checklist.interface";
import { TaskAPI, TaskEndpoints } from "@/v2/feature/task/task.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 14, height: 14 };
export const TasksListPage = ({ reach, isManager }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const TabFilter = useMemo(
    () => [
      { name: polyglot.t("TasksListPage.inSevenDays"), value: TaskTabFilter.InSevenDays },
      { name: polyglot.t("TasksListPage.inThirtyDays"), value: TaskTabFilter.InThirtyDays },
      { name: polyglot.t("TasksListPage.all"), value: TaskTabFilter.All },
      { name: polyglot.t("TasksListPage.done"), value: TaskTabFilter.Done }
    ],
    [polyglot]
  );
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const { user } = state;
  const context = getScopesContext(user);
  const [paginationState, setPaginationState] = useState({ pageIndex: 1, pageSize: 100 });
  const [sortingState, setSortingState] = useState([]);
  const [tableState, setTableState] = useState({
    filterValue: TaskTabFilter.InSevenDays,
    searchInput: "",
    filterString: ""
  });
  const { filterValue, searchInput, filterString } = tableState;
  const { data: allTasks, isLoading, error, mutate: refreshTasks } = useApiClient(
    TaskEndpoints.listTasks(reach, paginationState, {
      sortField: sortingState[0],
      status: filterValue,
      search: searchInput,
      filterString
    }),
    {
      suspense: false
    }
  );
  const { data: allChecklists } = useApiClient(TaskEndpoints.getChecklists(), {
    suspense: false
  });
  const [loading, setLoading] = useState(true);
  const [isTaskFormModalOpen, setIsTaskFormModalOpen] = useState(false);
  const [isTaskViewModalOpen, setIsTaskViewModalOpen] = useState(false);
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const hasTaskAll = hasScopes(["task:all"], context);
  const [showMessage] = useMessage();
  const updateTableState = useCallback((newState) => {
    setTableState((prevState) => {
      if ("filterValue" in newState || "searchInput" in newState || "filterString" in newState) {
        setPaginationState((p) => __spreadProps(__spreadValues({}, p), { pageIndex: 1 }));
      }
      return __spreadValues(__spreadValues({}, prevState), newState);
    });
  }, []);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    yield refreshTasks == null ? void 0 : refreshTasks();
  }), [refreshTasks]);
  useEffect(() => {
    if (!isLoading || error) setLoading(false);
  }, [error, isLoading]);
  useEffect(() => {
    var _a2;
    if (error) {
      showMessage(polyglot.t("TasksListPage.errorMessages.badRequest", { errorMessage: (_a2 = error.data) == null ? void 0 : _a2.message }), "error");
    }
  }, [polyglot, error, showMessage]);
  const closeTaskFormModal = () => {
    setTaskToEdit(null);
    setIsTaskFormModalOpen(false);
  };
  const deleteTask = useCallback(
    (taskId) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.removeTask(taskId);
        showMessage(polyglot.t("TasksListPage.successMessages.delete"), "success");
        yield refresh();
      } catch (error2) {
        showMessage(
          polyglot.t("TasksListPage.errorMessages.remove", { errorMessage: nestErrorMessage(error2) }),
          "error"
        );
      }
    }),
    [showMessage, polyglot, refresh]
  );
  const remindAboutTask = useCallback(
    (taskId) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.remindAboutTask(taskId);
        showMessage(polyglot.t("PersonalTask.successMessages.remind"), "success");
        yield refresh();
      } catch (error2) {
        showMessage(
          polyglot.t("PersonalTask.errorMessages.remind", { errorMessage: nestErrorMessage(error2) }),
          "error"
        );
      }
    }),
    [showMessage, polyglot, refresh]
  );
  const confirmDeleteTask = () => {
    setIsRemovalModalOpen(true);
  };
  const deleteTaskAfterConfirmation = useCallback(
    (selectedTasks) => __async(void 0, null, function* () {
      try {
        setIsRemovalModalOpen(false);
        setAnchorEl(null);
        yield TaskAPI.removeTasks(selectedTasks);
        showMessage(polyglot.t("TasksListPage.successMessages.deleteMult"), "success");
        setSelectionModel([]);
        setIsTaskViewModalOpen(false);
        yield refresh();
      } catch (error2) {
        showMessage(
          polyglot.t("TasksListPage.errorMessages.remove", { errorMessage: nestErrorMessage(error2) }),
          "error"
        );
      }
    }),
    [showMessage, polyglot, refresh]
  );
  const updateTaskStatus = useCallback(
    (taskId, assignedUserId, status) => __async(void 0, null, function* () {
      try {
        yield TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
        showMessage(polyglot.t("TasksListPage.successMessages.updateStatus"), "success");
        yield refresh();
        setSelectedTask((prev) => prev ? __spreadProps(__spreadValues({}, prev), { status }) : null);
      } catch (error2) {
        showMessage(
          polyglot.t("TasksListPage.errorMessages.updateStatus", { errorMessage: nestErrorMessage(error2) }),
          "error"
        );
      }
    }),
    [showMessage, polyglot, refresh]
  );
  const exportTasksCSV = useCallback(
    (selectedList) => __async(void 0, null, function* () {
      try {
        const csvData = yield TaskAPI.exportTasks(selectedList);
        const blob = new Blob([csvData], { type: "text/csv" });
        saveAs(blob, `zelt-task-export${format(/* @__PURE__ */ new Date(), "ddMMyy")}.csv`);
      } catch (error2) {
        showMessage(
          `${polyglot.t("PeopleDirectoryPage.errorMessages.smthWrong")}. ${nestErrorMessage(error2)}`,
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  const remindAllOverdue = useCallback(() => __async(void 0, null, function* () {
    try {
      yield TaskAPI.remindAllOverdue();
      showMessage("A reminder to all overdue task assignees will be sent", "success");
    } catch (error2) {
      showMessage(`${polyglot.t("PeopleDirectoryPage.errorMessages.smthWrong")}. ${nestErrorMessage(error2)}`, "error");
    }
  }), [polyglot, showMessage]);
  const getTaskBulkActionsOptions = useMemo(() => {
    const actionOptions = [];
    if (hasTaskAll && (selectionModel == null ? void 0 : selectionModel.length) > 0)
      actionOptions.push({
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: (e) => {
          if (e) setAnchorEl(e.currentTarget);
          confirmDeleteTask();
        },
        label: polyglot.t("TasksListPage.deleteSelected"),
        disabled: false
      });
    if (hasTaskAll && (selectionModel == null ? void 0 : selectionModel.length) > 0)
      actionOptions.push({
        icon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
        handler: () => exportTasksCSV(selectionModel),
        label: polyglot.t("TasksListPage.exportSelected"),
        disabled: false
      });
    if (allTasks == null ? void 0 : allTasks.totalItems) {
      actionOptions.push({
        icon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
        handler: () => exportTasksCSV(),
        label: polyglot.t("TasksListPage.export"),
        disabled: false
      });
      actionOptions.push({
        icon: /* @__PURE__ */ jsx(Mail, __spreadValues({}, iconSize)),
        handler: () => remindAllOverdue(),
        label: polyglot.t("TasksListPage.remindAllOverdue"),
        disabled: false
      });
    }
    return actionOptions;
  }, [allTasks == null ? void 0 : allTasks.totalItems, exportTasksCSV, hasTaskAll, polyglot, selectionModel, remindAllOverdue]);
  const checklistOptions = useMemo(() => {
    let options = [];
    if (allChecklists) {
      const uniqueValues = /* @__PURE__ */ new Set();
      options = allChecklists.map((t) => {
        var _a2, _b, _c;
        const bgImgLabel = (_a2 = emojiOptions.find((emo) => emo.value === (t == null ? void 0 : t.bgImg))) == null ? void 0 : _a2.label;
        const label = (t == null ? void 0 : t.name) ? `${(_b = bgImgLabel != null ? bgImgLabel : t == null ? void 0 : t.bgImg) != null ? _b : "\u{1F4DD}"} ${t == null ? void 0 : t.name}` : void 0;
        return {
          value: (_c = t.id) == null ? void 0 : _c.toString(),
          label
        };
      }).filter((t) => t.value && t.label && !uniqueValues.has(t.value) && uniqueValues.add(t.value));
      options.unshift({
        value: "null",
        label: "No checklist"
      });
    }
    return options;
  }, [allChecklists]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: polyglot.t("TasksListPage.tasks"),
        showAction: true,
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: () => setIsLaunchModalOpen(true), children: "Checklist" }),
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsTaskFormModalOpen(true), children: polyglot.t("TasksListPage.newTask") })
        ] })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: __spreadProps(__spreadValues({}, spacing.pt20), { overflow: "hidden" }), children: [
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(
              TabFilterButtons,
              {
                filters: TabFilter,
                setFilterValue: (filterValue2) => updateTableState({ filterValue: filterValue2 }),
                filterValue,
                hasSearch: true,
                onFilterChange: ({ filterValue: filterValue2, searchInput: searchInput2 }) => {
                  updateTableState({
                    filterValue: filterValue2,
                    searchInput: searchInput2
                  });
                }
              }
            ),
            /* @__PURE__ */ jsx(
              CategoryFilters,
              {
                filterTypes: {
                  checklist: checklistOptions
                },
                setFilterString: (filterString2) => updateTableState({ filterString: filterString2 }),
                filterString
              }
            )
          ] }),
          (hasTaskAll || isManager) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", alignItems: "right", gap: spacing.g15 }, children: [
            /* @__PURE__ */ jsx(
              SelectDeselectRows,
              {
                selectionModel,
                setSelectionModel,
                rows: (_a = allTasks == null ? void 0 : allTasks.items) != null ? _a : []
              }
            ),
            getTaskBulkActionsOptions.length > 0 && /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: getTaskBulkActionsOptions,
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: polyglot.t("General.actions"),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end"
                }
              }
            )
          ] })
        ] }),
        allTasks && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
          TaskTable,
          {
            tasks: allTasks,
            pagination: [paginationState, setPaginationState],
            sorting: [sortingState, setSortingState],
            openSelectedTaskModal: (task) => {
              setSelectedTask(task);
              setIsTaskViewModalOpen(true);
            },
            openEditTaskModal: (task) => {
              setTaskToEdit(task);
              setIsTaskFormModalOpen(true);
            },
            markAsComplete: (task) => __async(void 0, null, function* () {
              if (task.id) {
                yield updateTaskStatus(task.id, task == null ? void 0 : task.assignedUserId, TaskStatuses.COMPLETE);
              }
            }),
            showUserLink: true,
            loading,
            setSelectionModel,
            selectionModel,
            currentUserId: user.userId,
            deleteAction: deleteTask,
            remindAction: remindAboutTask,
            reach,
            stickyHeader: true
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx(
        TaskFormModal,
        {
          isOpen: isTaskFormModalOpen,
          setIsOpen: setIsTaskFormModalOpen,
          onClose: closeTaskFormModal,
          reach,
          userId: void 0,
          formData: taskToEdit,
          refresh
        }
      ),
      selectedTask && /* @__PURE__ */ jsx(
        TaskViewModal,
        {
          isOpen: isTaskViewModalOpen,
          setIsOpen: setIsTaskViewModalOpen,
          onClose: () => {
            setSelectedTask(null);
            setIsTaskViewModalOpen(false);
          },
          task: selectedTask,
          action: updateTaskStatus,
          deleteTask: deleteTaskAfterConfirmation,
          remindAboutTask,
          editTask: () => {
            setTaskToEdit(selectedTask);
            setIsTaskFormModalOpen(true);
            setIsTaskViewModalOpen(false);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        TaskLaunchModal,
        {
          isOpen: isLaunchModalOpen,
          setIsOpen: setIsLaunchModalOpen,
          onClose: () => setIsLaunchModalOpen(false),
          refresh,
          reach
        }
      ),
      /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: isRemovalModalOpen,
          onClose: () => setIsRemovalModalOpen(false),
          anchorEl,
          takeAction: () => deleteTaskAfterConfirmation(selectionModel),
          message: polyglot.t("TasksListPage.confirmDelete", { smart_count: selectionModel.length }),
          callToAction: polyglot.t("TasksListPage.deleteTasks", { smart_count: selectionModel.length })
        }
      )
    ] })
  ] });
};
