"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const IconContentRadioCard = ({
  title,
  content,
  showDivider = false,
  iconMedia,
  action,
  titleSx,
  iconGap,
  textGap,
  cardOnClick,
  sx
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      onClick: cardOnClick,
      sx: __spreadValues(__spreadValues({
        display: "flex",
        alignItems: "center",
        gap: spacing.g20,
        width: "100%",
        justifyContent: "space-between",
        borderBottom: showDivider ? borders.middle : "none",
        cursor: "pointer"
      }, sx), spacing.py20),
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: iconGap != null ? iconGap : spacing.g15 }, children: [
          /* @__PURE__ */ jsx(Box, { children: !!iconMedia && iconMedia }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: textGap != null ? textGap : spacing.g5, justifyContent: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadValues({}, themeFonts.paragraph), titleSx), children: title }),
            content && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey }), children: content })
          ] })
        ] }),
        !!action && /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: action })
      ]
    }
  );
};
