"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useMemo } from "react";
import { AlertMessage } from "@v2/components/theme-components/alert.component";
import { Typography } from "@v2/components/typography/typography.component";
import { getDeviceLastActiveInDays } from "@v2/feature/device/device.util";
import { getEnrolmentDeviceStatus } from "@v2/feature/device/features/devices-company/components/configurable-device-view.component";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
export const SecurityDetailsInformation = ({ configurableDevice }) => {
  var _a;
  const devicePossession = configurableDevice.devicePossession;
  const device = devicePossession.device;
  const lastActiveString = useMemo(() => {
    var _a2;
    const deviceLastActiveInDays = getDeviceLastActiveInDays((_a2 = devicePossession.device) == null ? void 0 : _a2.lastCheckIn);
    if (!device.lastCheckIn) {
      return "NA";
    }
    const lastCheckInDate = new Date(device.lastCheckIn);
    if (isNaN(lastCheckInDate.getTime())) {
      return "N/A";
    }
    const dateString = device.inHouseMdm ? lastCheckInDate.toUTCString() : lastCheckInDate.toUTCString().split(" ").slice(0, 4).join(" ");
    return /* @__PURE__ */ jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: dateString }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "red", sx: { ml: spacing.g5 }, children: deviceLastActiveInDays.isOverdue && `${deviceLastActiveInDays.daysDifference} days ago` })
    ] });
  }, [device.inHouseMdm, device.lastCheckIn, (_a = devicePossession.device) == null ? void 0 : _a.lastCheckIn]);
  const formatDateToDDMMMYYYY = (dateString) => {
    if (!dateString) return null;
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return null;
      const day = date.getDate().toString().padStart(2, "0");
      const month = date.toLocaleString("en-US", { month: "short" });
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    } catch (error) {
      return null;
    }
  };
  const actionStatusAlert = useMemo(() => {
    const formatScheduledWipeDateTime = () => {
      const date = configurableDevice.scheduledWipeDate;
      const time = configurableDevice.scheduledWipeTime;
      if (!date || !time) return null;
      try {
        const formattedDate = formatDateToDDMMMYYYY(date);
        if (!formattedDate) return null;
        return `${formattedDate} at ${time} GMT`;
      } catch (error) {
        console.error("Error formatting scheduled wipe date and time:", error);
        return null;
      }
    };
    switch (configurableDevice.actionStatus) {
      case "lock":
        return {
          severity: "warning",
          message: "This device will be locked. The unlock PIN is stored in the Activity Log",
          actionTitle: "View Details"
        };
      case "wipe":
        return {
          severity: "error",
          message: "Device wipe in progress",
          actionTitle: "View Details"
        };
      case "scheduled_wipe":
        return {
          severity: "error",
          message: `This device will be wiped on ${formatScheduledWipeDateTime()}`,
          actionTitle: "View Details"
        };
      default:
        return null;
    }
  }, [configurableDevice.actionStatus, configurableDevice.scheduledWipeDate, configurableDevice.scheduledWipeTime]);
  const sections = useMemo(() => {
    const sectionItems = [];
    if (actionStatusAlert) {
      sectionItems.push({
        type: SectionItemType.Component,
        value: /* @__PURE__ */ jsx(
          Suspense,
          {
            fallback: /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                width: "31%",
                height: "100%",
                sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
              }
            ),
            children: /* @__PURE__ */ jsx(
              AlertMessage,
              {
                severity: actionStatusAlert.severity,
                message: actionStatusAlert.message,
                actionTitle: actionStatusAlert.actionTitle
              }
            )
          }
        )
      });
    }
    const items = [
      {
        type: SectionItemType.Pair,
        label: "Status",
        value: configurableDevice.actionStatus ? getEnrolmentDeviceStatus(configurableDevice.actionStatus) : "No action",
        hidden: !configurableDevice.actionStatus
      },
      {
        type: SectionItemType.Pair,
        label: "Last check in",
        value: lastActiveString,
        hidden: !device.lastCheckIn
      },
      {
        type: SectionItemType.TextLine,
        value: "No activity",
        hidden: Boolean(device.lastCheckIn)
      }
    ];
    return [
      {
        title: "",
        items: [...sectionItems, ...items]
      }
    ];
  }, [actionStatusAlert, configurableDevice.actionStatus, lastActiveString, device.lastCheckIn]);
  return /* @__PURE__ */ jsx(SettingsSubsectionContent, { sections });
};
