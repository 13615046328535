"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Typography } from "@v2/components/typography/typography.component";
import { StyledTextfield } from "@v2/styles/textfield.styles";
export const TimePickerComponent = ({
  label,
  value,
  onChange,
  textFieldKey = "time",
  fullWidth = false,
  disabled = false,
  error = void 0,
  helperText = void 0
}) => {
  const adjustedValue = useMemo(() => {
    if (!value) return "";
    if (value.includes("T")) return value.split("T")[1].slice(0, 5);
    return value;
  }, [value]);
  return /* @__PURE__ */ jsx(
    StyledTextfield,
    {
      id: "time",
      variant: "standard",
      label: /* @__PURE__ */ jsx(Typography, { variant: "headline3", color: "DarkGrey", children: label }),
      type: "time",
      value: adjustedValue,
      valueFont: "headline3",
      onChange,
      InputLabelProps: { shrink: true },
      sx: { width: fullWidth ? "100%" : "55px" },
      fullWidth: true,
      error,
      helperText,
      disabled
    },
    textFieldKey
  );
};
