"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import { keyBy, uniq } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Drag } from "@/images/growth/Drag.svg";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewCycleAPI, ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewTemplateEndpoints } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { PlaceholderBox } from "@/v2/feature/growth/reviews/features/components/placeholder-box.component";
import { QuestionBuilder } from "@/v2/feature/growth/reviews/features/components/question-builder.component";
import { CycleCreationMoveToSectionModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-move-to-section-modal.component";
import { CycleCreationQuestionFormModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-question-form-modal.component";
import { CycleCreationQuestionPreviewer } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-question-previewer.component";
import { CycleCreationSectionFormModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-section-form-modal.component";
import { QuestionSelectionModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/question-selection-modal.component";
import { TemplateSelectionModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/template-selection-modal.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { useCycleQuestionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-cycle-question-options.hook";
import { useCycleSectionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-cycle-section-options.hook";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const RCUpsertQuestionPage = ({
  cycleId,
  reach,
  reviewCycleProgress,
  refreshProgress
}) => {
  var _a, _b, _c, _d;
  const { data: bankCount } = useApiClient(ReviewTemplateEndpoints.getBankCount(), { suspense: false });
  const {
    data: reviewStructure,
    mutate: refreshReviewStructure,
    isLoading: reviewStructureLoading
  } = useApiClient(ReviewCycleEndpoints.getReviewCycleStructureById(cycleId), { suspense: false });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState(false);
  const [question, setQuestion] = useState(null);
  const [section, setSection] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState(false);
  const [isPreviewerOpen, setIsPreviewerOpen] = useState(false);
  const [previewReviewer, setPreviewReviewer] = useState(void 0);
  const [cycle, setCycle] = useState(void 0);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  useEffect(() => {
    if (reviewStructure) {
      setCycle(reviewStructure.cycle);
    }
  }, [reviewStructure]);
  const questionById = useMemo(() => keyBy((reviewStructure == null ? void 0 : reviewStructure.questions) || [], "id"), [reviewStructure == null ? void 0 : reviewStructure.questions]);
  const sectionById = useMemo(() => keyBy((reviewStructure == null ? void 0 : reviewStructure.sections) || [], "id"), [reviewStructure == null ? void 0 : reviewStructure.sections]);
  const sections = useMemo(() => (reviewStructure == null ? void 0 : reviewStructure.sections) || [], [reviewStructure == null ? void 0 : reviewStructure.sections]);
  const getReviewerSelects = useMemo(() => {
    var _a2;
    if (!(reviewStructure == null ? void 0 : reviewStructure.questions)) return [];
    const allReviewerSelects = (_a2 = reviewStructure == null ? void 0 : reviewStructure.questions) == null ? void 0 : _a2.flatMap((q) => q.reviewerSelect);
    return uniq(allReviewerSelects);
  }, [reviewStructure == null ? void 0 : reviewStructure.questions]);
  const isEditable = useMemo(
    () => Boolean((cycle == null ? void 0 : cycle.state) === CycleState.Draft || (cycle == null ? void 0 : cycle.state) === CycleState.Scheduled),
    [cycle]
  );
  const clearSelection = () => {
    setQuestion(null);
    setSection(null);
  };
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
      yield refreshProgress == null ? void 0 : refreshProgress();
      clearSelection();
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
  }), [refreshReviewStructure, refreshProgress, polyglot, showMessage]);
  const cloneQuestions = (selectedQuestionIds) => __async(void 0, null, function* () {
    try {
      yield ReviewCycleAPI.cloneQuestionsInCycle([...selectedQuestionIds], cycleId);
      yield refreshReviewStructure == null ? void 0 : refreshReviewStructure();
      yield refreshProgress == null ? void 0 : refreshProgress();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  });
  const moveQuestionInsideSection = (sectionId, questionId) => __async(void 0, null, function* () {
    try {
      yield ReviewCycleAPI.moveQuestionInsideSection({ cycleId, sectionId, questionId });
      refreshReviewStructure == null ? void 0 : refreshReviewStructure();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  });
  const getReviewerActionsOptions = useCallback(() => {
    return getReviewerSelects.map((type) => ({
      handler: () => {
        setIsPreviewerOpen(true);
        setPreviewReviewer(type);
      },
      label: type,
      disabled: false
    }));
  }, [getReviewerSelects]);
  const handleDragDrop = (result) => __async(void 0, null, function* () {
    const { source, destination, type } = result;
    if (!destination || !cycle) return;
    const updatedOrder = [...cycle.order];
    if (type === "section") {
      if (source.index < 0 || source.index >= updatedOrder.length) return;
      const [movedSection] = updatedOrder.splice(source.index, 1);
      updatedOrder.splice(destination.index, 0, movedSection);
      try {
        yield ReviewCycleAPI.updateOrderReviewCycle({ cycleId, order: updatedOrder });
        refreshReviewStructure == null ? void 0 : refreshReviewStructure();
        return;
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    } else if (type === "question") {
      const sourceDroppableId = source.droppableId;
      const destinationDroppableId = destination.droppableId;
      let movedQuestion;
      if (!sourceDroppableId || !destinationDroppableId) return;
      if (sourceDroppableId !== "individual-questions") {
        const sourceIndex = updatedOrder.findIndex((item) => item.id === sourceDroppableId);
        if (sourceIndex === -1) return;
        const sourceSection = updatedOrder[sourceIndex];
        if (!sourceSection.questions) return;
        const [removedQuestionId] = sourceSection.questions.splice(source.index, 1);
        movedQuestion = questionById[removedQuestionId];
      } else {
        const independentStartIndex = updatedOrder.findIndex((item) => item.type === "question");
        if (independentStartIndex === -1) return;
        movedQuestion = questionById[updatedOrder[source.index + independentStartIndex].id];
        updatedOrder.splice(source.index + independentStartIndex, 1);
      }
      if (!movedQuestion) return;
      if (destinationDroppableId !== "individual-questions") {
        const destinationIndex = updatedOrder.findIndex((item) => item.id === destinationDroppableId);
        if (destinationIndex === -1) return;
        const destinationSection = updatedOrder[destinationIndex];
        if (!destinationSection.questions) destinationSection.questions = [];
        destinationSection.questions.splice(destination.index, 0, movedQuestion.id);
        if (movedQuestion.sectionId !== destinationSection.id) {
          yield moveQuestionInsideSection(destinationSection.id, movedQuestion.id);
          return;
        }
      } else {
        const independentStartIndex = updatedOrder.findIndex((item) => item.type === "question");
        const insertIndex = independentStartIndex !== -1 ? independentStartIndex : updatedOrder.length;
        updatedOrder.splice(insertIndex, 0, { id: movedQuestion.id, type: "question", questions: [] });
        if (movedQuestion.sectionId) {
          try {
            yield ReviewCycleAPI.moveQuestionOutsideSection({
              cycleId,
              sectionId: movedQuestion.sectionId,
              questionId: movedQuestion.id
            });
            refreshReviewStructure == null ? void 0 : refreshReviewStructure();
            return;
          } catch (error) {
            showMessage(
              polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
              "error"
            );
          }
        }
      }
      setCycle((prev) => prev && __spreadProps(__spreadValues({}, prev), { order: updatedOrder }));
      try {
        yield ReviewCycleAPI.updateOrderReviewCycle({ cycleId, order: updatedOrder });
        refreshReviewStructure == null ? void 0 : refreshReviewStructure();
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
        refreshReviewStructure == null ? void 0 : refreshReviewStructure();
      }
    }
  });
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: cycle ? /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle: cycle, reachType: reach }) : /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text" }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading: reviewStructureLoading,
      children: [
        reviewCycleProgress && cycle && cycle.state === CycleState.Draft && /* @__PURE__ */ jsx(
          LinearProgress,
          {
            value: reviewCycleProgress || 0,
            variant: "determinate",
            sx: {
              "& .MuiLinearProgress-bar": {
                backgroundColor: themeColors.Green
              },
              backgroundColor: themeColors.Background
            }
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "100%",
              height: "100%",
              boxSizing: "border-box"
            },
            children: [
              /* @__PURE__ */ jsxs(
                Box,
                {
                  className: "sub-title",
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  },
                  children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("RCUpsertQuestionPage.questions") }),
                    getReviewerSelects.length > 1 && /* @__PURE__ */ jsx(
                      StyledMenuComponent,
                      {
                        options: getReviewerActionsOptions(),
                        actionButtonDetails: {
                          type: "button",
                          colorVariant: "secondary",
                          sizeVariant: "small",
                          title: "Preview"
                        }
                      }
                    )
                  ]
                }
              ),
              isEditable && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16, mt: spacing.m24 }, children: [
                /* @__PURE__ */ jsx(
                  PlaceholderBox,
                  {
                    title: polyglot.t("RCUpsertQuestionPage.startWithATemplate"),
                    countOne: polyglot.t("RCUpsertQuestionPage.totalTemplates", { templates: (_a = bankCount == null ? void 0 : bankCount.template) != null ? _a : 0 }),
                    countTwo: polyglot.t("RCUpsertQuestionPage.totalQuestions", {
                      questions: (_b = bankCount == null ? void 0 : bankCount.templateQuestions) != null ? _b : 0
                    }),
                    action: () => setIsTemplateModalOpen(true)
                  }
                ),
                /* @__PURE__ */ jsx(
                  PlaceholderBox,
                  {
                    title: polyglot.t("RCUpsertQuestionPage.selectQuestionsFromLibrary"),
                    countOne: polyglot.t("RCUpsertQuestionPage.totalFactors", { factors: (_c = bankCount == null ? void 0 : bankCount.templateQuestions) != null ? _c : 0 }),
                    countTwo: polyglot.t("RCUpsertQuestionPage.totalQuestions", {
                      questions: (_d = bankCount == null ? void 0 : bankCount.templateQuestions) != null ? _d : 0
                    }),
                    action: () => setIsQuestionBankOpen(true)
                  }
                )
              ] })
            ]
          }
        ),
        reviewStructureLoading ? /* @__PURE__ */ jsx(CycleSetupQuestionBuilderLoader, {}) : /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({}, cycle && cycle.order && cycle.order.length > 0 ? {} : { border: `1px dashed ${themeColors.GreyLight}` }), {
              mt: spacing.m24,
              height: "100%"
            }),
            children: [
              /* @__PURE__ */ jsxs(DragDropContext, { onDragEnd: handleDragDrop, children: [
                /* @__PURE__ */ jsx(Droppable, { droppableId: "sections", type: "section", children: (provided) => {
                  var _a2;
                  return /* @__PURE__ */ jsxs(
                    Box,
                    __spreadProps(__spreadValues({
                      ref: provided.innerRef
                    }, provided.droppableProps), {
                      sx: { display: "flex", flexDirection: "column", gap: spacing.s4 },
                      children: [
                        (_a2 = cycle == null ? void 0 : cycle.order) == null ? void 0 : _a2.map((item, index) => {
                          var _a3;
                          if (item.type === "section") {
                            const orderedQuestions = ((_a3 = item.questions) != null ? _a3 : []).map((questionId) => questionById[questionId]).filter((question2) => Boolean(question2));
                            return /* @__PURE__ */ jsx(Draggable, { draggableId: item.id, index, children: (provided2) => sectionById[item.id] ? /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ ref: provided2.innerRef }, provided2.draggableProps), { children: /* @__PURE__ */ jsx(
                              ReviewBuilderSection,
                              {
                                section: sectionById[item.id],
                                setSection,
                                setIsQuestionModalOpen,
                                setIsSectionModalOpen,
                                questions: orderedQuestions,
                                cycle,
                                setQuestion,
                                refresh,
                                setIsMoveToSectionOpen,
                                sections,
                                growthFactors,
                                isEditable,
                                sectionProvided: provided2
                              },
                              item.id
                            ) })) : /* @__PURE__ */ jsx(Fragment, {}) }, item.id);
                          }
                          return null;
                        }),
                        provided.placeholder
                      ]
                    })
                  );
                } }),
                /* @__PURE__ */ jsx(Droppable, { droppableId: "individual-questions", type: "question", children: (provided) => {
                  var _a2;
                  return /* @__PURE__ */ jsxs(
                    Box,
                    __spreadProps(__spreadValues({
                      ref: provided.innerRef
                    }, provided.droppableProps), {
                      sx: { mt: spacing.s3, display: "flex", flexDirection: "column", gap: spacing.s2 },
                      children: [
                        (_a2 = cycle == null ? void 0 : cycle.order) == null ? void 0 : _a2.filter((item) => item.type === "question").map((question2, index) => /* @__PURE__ */ jsx(Draggable, { draggableId: question2.id, index, children: (provided2) => questionById[question2.id] ? /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ ref: provided2.innerRef }, provided2.draggableProps), { children: /* @__PURE__ */ jsx(
                          QuestionComponent,
                          {
                            question: questionById[question2.id],
                            cycle,
                            setQuestion,
                            setIsQuestionModalOpen,
                            refresh,
                            setIsMoveToSectionOpen,
                            hasSections: Boolean(sections && sections.length),
                            growthFactors,
                            isEditable,
                            showBorder: true,
                            provided: provided2,
                            isLast: false
                          }
                        ) })) : /* @__PURE__ */ jsx(Fragment, {}) }, question2.id)),
                        provided.placeholder
                      ]
                    })
                  );
                } })
              ] }),
              isEditable && /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: __spreadValues({
                    display: "flex",
                    gap: spacing.g5,
                    mt: cycle && cycle.order ? spacing.m24 : "0px"
                  }, cycle && cycle.order && cycle.order.length > 0 ? {} : { padding: spacing.p16 }),
                  children: [
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsSectionModalOpen(true),
                        children: polyglot.t("RCUpsertQuestionPage.newSection")
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsQuestionModalOpen(true),
                        children: polyglot.t("RCUpsertQuestionPage.newQuestion")
                      }
                    )
                  ]
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TemplateSelectionModal,
          {
            isTemplateModalOpen,
            setIsTemplateModalOpen,
            cycleId,
            refresh,
            growthFactors
          }
        ),
        /* @__PURE__ */ jsx(
          QuestionSelectionModal,
          {
            setIsQuestionsModalOpen: setIsQuestionBankOpen,
            isQuestionsModalOpen: isQuestionBankOpen,
            action: cloneQuestions,
            excludeQuestions: []
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionFormModal,
          {
            setIsOpen: setIsQuestionModalOpen,
            isOpen: isQuestionModalOpen,
            question,
            onClose: () => {
              setIsQuestionModalOpen(false);
              clearSelection();
            },
            refresh,
            section,
            cycleId
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationSectionFormModal,
          {
            setIsOpen: setIsSectionModalOpen,
            isOpen: isSectionModalOpen,
            section,
            onClose: () => {
              setIsSectionModalOpen(false);
              clearSelection();
            },
            refresh,
            cycleId
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationMoveToSectionModal,
          {
            isOpen: isMoveToSectionOpen,
            setIsOpen: setIsMoveToSectionOpen,
            onClose: () => {
              setIsMoveToSectionOpen(false);
              clearSelection();
            },
            action: moveQuestionInsideSection,
            sections,
            questionId: question == null ? void 0 : question.id
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionPreviewer,
          {
            isPreviewerOpen,
            setIsPreviewerOpen,
            previewReviewer,
            allQuestions: (reviewStructure == null ? void 0 : reviewStructure.questions) || [],
            allSections: (reviewStructure == null ? void 0 : reviewStructure.sections) || [],
            order: (reviewStructure == null ? void 0 : reviewStructure.cycle.order) || []
          }
        )
      ]
    }
  );
};
const ReviewBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  questions,
  cycle,
  setQuestion,
  refresh,
  setIsMoveToSectionOpen,
  sections,
  growthFactors,
  isEditable,
  sectionProvided
}) => {
  const { polyglot } = usePolyglot();
  const sectionOptions = useCycleSectionOptions(section, setSection, setIsSectionModalOpen, cycle, refresh);
  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };
  return /* @__PURE__ */ jsx(Droppable, { droppableId: section.id, type: "question", children: (provided, snapshot) => {
    var _a;
    return /* @__PURE__ */ jsxs(
      Box,
      __spreadProps(__spreadValues({
        ref: provided.innerRef
      }, provided.droppableProps), {
        sx: {
          border: borders.middle,
          borderRadius: radius.br12,
          minHeight: "80px"
        },
        children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                backgroundColor: themeColors.Background,
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box",
                p: spacing.p16,
                borderTopRightRadius: radius.br12,
                borderTopLeftRadius: radius.br12
              },
              children: [
                /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s2 }, children: [
                  isEditable && /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({}, sectionProvided.dragHandleProps), { sx: { cursor: "grab" }, children: /* @__PURE__ */ jsx(Drag, __spreadValues({}, iconSize)) })),
                  /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        flexDirection: "column",
                        gap: spacing.s1
                      },
                      children: [
                        /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: section.name }),
                        section.description && /* @__PURE__ */ jsx(
                          Typography,
                          {
                            variant: "paragraph",
                            dangerouslySetInnerHTML: { __html: (_a = section.description) != null ? _a : "" }
                          }
                        )
                      ]
                    }
                  )
                ] }),
                isEditable && /* @__PURE__ */ jsx(
                  StyledMenuComponent,
                  {
                    options: sectionOptions,
                    actionButtonDetails: {
                      type: "iconButton",
                      colorVariant: "secondary",
                      sizeVariant: "small",
                      title: "actions",
                      icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                    }
                  }
                )
              ]
            }
          ),
          questions.length === 0 && !snapshot.isDraggingOver && /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px dashed ${themeColors.grey}`,
                borderRadius: radius.br12,
                color: themeColors.grey,
                opacity: 0.6
              }
            }
          ),
          questions.map((question, index) => /* @__PURE__ */ jsx(Draggable, { draggableId: question.id, index, children: (provided2) => /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ ref: provided2.innerRef }, provided2.draggableProps), { children: /* @__PURE__ */ jsx(
            QuestionComponent,
            {
              question,
              cycle,
              setQuestion,
              setIsQuestionModalOpen,
              refresh,
              setIsMoveToSectionOpen,
              hasSections: Boolean(sections && sections.length),
              growthFactors,
              isEditable,
              showBorder: false,
              provided: provided2,
              isLast: index === questions.length - 1
            }
          ) })) }, question.id)),
          provided.placeholder,
          isEditable && /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                bgcolor: themeColors.white,
                p: spacing.p16,
                borderBottomRightRadius: radius.br12,
                borderBottomLeftRadius: radius.br12
              },
              children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  colorVariant: "secondary",
                  sizeVariant: "medium",
                  startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                  onClick: newQuestionAction,
                  children: polyglot.t("RCUpsertQuestionPage.newQuestion")
                }
              )
            }
          )
        ]
      })
    );
  } });
};
const QuestionComponent = ({
  question,
  cycle,
  setQuestion,
  setIsQuestionModalOpen,
  refresh,
  setIsMoveToSectionOpen,
  hasSections,
  growthFactors,
  isEditable,
  showBorder,
  provided,
  isLast
}) => {
  const questionOptions = useCycleQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    cycle,
    refresh,
    hasSections
  );
  return /* @__PURE__ */ jsx(
    QuestionBuilder,
    {
      questionOptions,
      question,
      growthFactors,
      showAction: isEditable,
      showBorder,
      provided,
      isLast
    }
  );
};
const CycleSetupQuestionBuilderLoader = () => {
  return /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g10 }, spacing.mt20), children: [...Array(10)].map((e, i) => /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" })
  ] }, i)) });
};
