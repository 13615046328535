"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { PersonalAbsenceContent } from "@v2/feature/absence/me/policies/components/personal-absence-content.component";
import { LabelYearSelectChip } from "@v2/feature/absence/me/policies/components/year-select-chip.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { RootStyle } from "@/v2/styles/root.styles";
export const UserAbsencePage = ({ userId }) => {
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getUserAbsencePolicies(userId, true), {
    suspense: false
  });
  const [year, setYear] = useState("current");
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  useEffect(() => {
    var _a;
    if (!absencePolicies) return;
    setSelectedPolicy((_a = absencePolicies[0]) != null ? _a : null);
  }, [absencePolicies]);
  const { polyglot } = usePolyglot();
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "20px", alignItems: "center" }, children: [
          /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
            " ",
            polyglot.t("UserAbsencePage.absence")
          ] }),
          /* @__PURE__ */ jsx(LabelYearSelectChip, { year, setYear })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(
      PersonalAbsenceContent,
      {
        userId,
        year,
        absencePolicies: absencePolicies != null ? absencePolicies : null,
        setIsAbsenceDrawerOpen,
        isAbsenceDrawerOpen,
        selectedPolicy,
        setSelectedPolicy
      }
    )
  ] });
};
