"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewTemplateEndpoints } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { RCTemplatePreviewer } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/rc-template-previewer.component";
import { TemplateBlock } from "@/v2/feature/growth/surveys/features/components/template-block.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const TemplateSelectionModal = ({
  isTemplateModalOpen,
  setIsTemplateModalOpen,
  cycleId,
  refresh,
  growthFactors
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: isTemplateModalOpen,
      setIsOpen: setIsTemplateModalOpen,
      onClose: () => {
        setIsTemplateModalOpen(false);
      },
      widthPercentage: 95,
      sx: {
        px: 0
      },
      children: /* @__PURE__ */ jsx(
        TemplateSelectionContent,
        {
          setIsTemplateModalOpen,
          cycleId,
          refresh,
          growthFactors
        }
      )
    }
  );
};
const TemplateSelectionContent = ({
  setIsTemplateModalOpen,
  cycleId,
  refresh,
  growthFactors
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [isTemplateViewOpen, setIsTemplateViewOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(void 0);
  const { data: templates } = useApiClient(ReviewTemplateEndpoints.getReviewTemplatesCompanyId(searchInput), {
    suspense: false
  });
  const { data: templateObject } = useApiClient(
    selectedTemplate ? ReviewTemplateEndpoints.getReviewTemplateStructurebyId(selectedTemplate.id) : null,
    { suspense: false }
  );
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [activeId, setActiveId] = useState(void 0);
  const [isLoading, setIsLoading] = useState(false);
  const cloneTemplate = useCallback(
    (templateId) => __async(void 0, null, function* () {
      if (!templateId) {
        showMessage(polyglot.t("TemplateSelectionModal.warning"), "warning");
        return;
      }
      try {
        setIsLoading(true);
        yield ReviewCycleAPI.cloneTemplateInCycle(templateId, cycleId);
        yield refresh == null ? void 0 : refresh();
        setIsTemplateModalOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setIsLoading(false);
    }),
    [cycleId, refresh, setIsTemplateModalOpen, polyglot, showMessage]
  );
  if (!templates) return /* @__PURE__ */ jsx(Fragment, {});
  if (isTemplateViewOpen && selectedTemplate) {
    const { questions, sections } = templateObject || { sections: [], questions: [] };
    return /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          px: spacing.px16,
          boxSizing: "border-box"
        },
        children: /* @__PURE__ */ jsx(
          RCTemplatePreviewer,
          {
            templateName: selectedTemplate.name || polyglot.t("TemplateSelectionModal.warning"),
            questions: questions || [],
            sections: sections || [],
            order: selectedTemplate.order,
            growthFactors,
            addTemplateAction: () => __async(void 0, null, function* () {
              yield cloneTemplate(selectedTemplate.id);
            }),
            onBack: () => {
              setIsTemplateViewOpen(false);
            }
          }
        )
      }
    );
  }
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        position: "relative",
        width: "100%",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { px: spacing.px16, mb: spacing.m24 }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("TemplateSelectionModal.title") }),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                colorVariant: "primary",
                sizeVariant: "small",
                disabled: !activeId,
                onClick: () => __async(void 0, null, function* () {
                  yield cloneTemplate(activeId);
                }),
                loading: isLoading,
                children: polyglot.t("TemplateSelectionModal.addTemplate")
              }
            )
          ] }),
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              style: { width: "100%", boxSizing: "border-box", marginTop: spacing.m24 },
              query: searchInput,
              handleChange: (e) => setSearchInput(e.target.value),
              placeholder: polyglot.t("TemplateSelectionModal.searchPlaceholder")
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "grid",
              gridTemplateColumns: ["1fr", "1fr", "1fr", "repeat(4, 1fr)"],
              gap: spacing.s2,
              px: spacing.s2,
              boxSizing: "border-box"
            },
            children: templates.map((template) => /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  boxSizing: "border-box"
                },
                children: /* @__PURE__ */ jsx(
                  TemplateBlock,
                  {
                    template,
                    clickAction: () => setActiveId(template.id),
                    active: activeId,
                    viewAction: () => {
                      setIsTemplateViewOpen(true);
                      setSelectedTemplate(template);
                    }
                  }
                )
              },
              template.id
            ))
          }
        )
      ]
    }
  );
};
