"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { Typography } from "@/v2/components/typography/typography.component";
export const InsuranceSetupQuoteStep = ({ name, onNext, setQuoteData }) => {
  const { polyglot } = usePolyglot();
  const formik = useFormik({
    initialValues: {
      outPatientTreatment: false,
      therapies: false,
      mentalHealth: false,
      dentalCare: false,
      opticalCare: false,
      travelCover: false,
      additionalNotes: null,
      displayName: name,
      selectedUsers: []
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      outPatientTreatment: yup.boolean().notRequired(),
      therapies: yup.boolean().notRequired(),
      mentalHealth: yup.boolean().notRequired(),
      dentalCare: yup.boolean().notRequired(),
      opticalCare: yup.boolean().notRequired(),
      travelCover: yup.boolean().notRequired(),
      displayName: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      additionalNotes: yup.string().nullable().notRequired()
    }),
    onSubmit: (values) => {
      setQuoteData(values);
      onNext();
    }
  });
  const isContinueEnabled = () => {
    return formik.values.outPatientTreatment || formik.values.therapies || formik.values.mentalHealth || formik.values.dentalCare || formik.values.opticalCare || formik.values.travelCover;
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { sx: { margin: "auto", mt: spacing.mt10, width: "400px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.requestAQuote") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.mt20, mb: spacing.mb30, color: themeColors.DarkGrey }, children: polyglot.t("BenefitModule.requestAQuoteDesc") }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "outPatientTreatment",
        label: polyglot.t("BenefitModule.outPatientTreatment"),
        checked: formik.values.outPatientTreatment,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "therapies",
        label: polyglot.t("BenefitModule.therapies"),
        checked: formik.values.therapies,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "mentalHealth",
        label: polyglot.t("BenefitModule.mentalHealth"),
        checked: formik.values.mentalHealth,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "dentalCare",
        label: polyglot.t("BenefitModule.dentalCare"),
        checked: formik.values.dentalCare,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "opticalCare",
        label: polyglot.t("BenefitModule.opticalCare"),
        checked: formik.values.opticalCare,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "travelCover",
        label: polyglot.t("BenefitModule.travelCover"),
        checked: formik.values.travelCover,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "additionalNotes",
        label: polyglot.t("BenefitModule.additionalNotes"),
        value: formik.values.additionalNotes,
        onChange: formik.handleChange
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.mt40 }), children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        type: "submit",
        fullWidth: true,
        disabled: !isContinueEnabled(),
        children: polyglot.t("General.continue")
      }
    ) })
  ] }) }) });
};
