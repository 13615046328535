"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Stack } from "@mui/material";
import {
  RuleTypeOption
} from "@v2/feature/attendance/subfeatures/attendance-schedule-rule/attendance-schedule-rule.interface";
import { FormikProvider, useFormik, Form } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TimeInputFields } from "@/v2/components/forms/time-input.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AttendanceScheduleRuleAPI } from "@/v2/feature/attendance/subfeatures/attendance-schedule-rule/attendance-schedule-rule.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const validationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
  units: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
  payMultiplierId: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
});
export const BreakBetweenDaysRuleForm = ({
  rule,
  attendancePayMultipliers,
  scheduleId,
  refreshRules,
  setIsOpen
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const isEdit = !!rule;
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      name: (_a = rule == null ? void 0 : rule.name) != null ? _a : "",
      units: (_b = rule == null ? void 0 : rule.units) != null ? _b : 0,
      payMultiplierId: rule == null ? void 0 : rule.payMultiplierId
    },
    validationSchema: validationSchema(polyglot),
    validateOnMount: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        if (isEdit) {
          yield AttendanceScheduleRuleAPI.updateRule(scheduleId, rule.id, {
            name: values.name,
            units: values.units,
            payMultiplierId: values.payMultiplierId
          });
        } else {
          yield AttendanceScheduleRuleAPI.createRule(scheduleId, {
            ruleType: RuleTypeOption.BreakBetweenDays,
            name: values.name,
            units: values.units,
            payMultiplierId: values.payMultiplierId,
            useMinimumContractedHoursForUnits: false
          });
        }
        refreshRules();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setIsOpen(false);
      }
    })
  });
  const handleDelete = useCallback(
    (scheduleId2, ruleId) => __async(void 0, null, function* () {
      try {
        yield AttendanceScheduleRuleAPI.deleteRule(scheduleId2, ruleId);
        showMessage(polyglot.t("AttendanceSchedule.scheduleRules.successfulMessages.ruleDeleted"), "success");
        refreshRules();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setIsOpen(false);
      }
    }),
    [refreshRules, setIsOpen, polyglot, showMessage]
  );
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { style: { paddingTop: "16px", gap: "24px" }, children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: "Name",
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { direction: "row", gap: "16px", alignItems: "center", children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Minimum break between days (hours)" }),
      /* @__PURE__ */ jsx(
        TimeInputFields,
        {
          value: formik.values.units,
          onChange: (value) => formik.setFieldValue("units", value),
          polyglot,
          maxMinutes: 60 * 24
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "payMultiplierId",
        label: "Apply Multiplier",
        options: attendancePayMultipliers.map((multiplier) => ({
          label: `${multiplier.name} (${multiplier.value}\xD7)`,
          value: multiplier.id
        })),
        value: formik.values.payMultiplierId,
        onChange: formik.handleChange
      }
    ),
    formik.isValid && /* @__PURE__ */ jsxs(Stack, { direction: "column", gap: "4px", children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Rule statement" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AttendanceSchedule.scheduleRules.ruleStatements.breakBetweenDays", {
        units: formik.values.units && (formik.values.units / 60).toFixed(2).replace(/\.?0+$/, ""),
        pluralSuffix: formik.values.units && formik.values.units !== 60 ? "s" : "",
        multiplierName: (_c = attendancePayMultipliers.find(
          (multiplier) => multiplier.id === formik.values.payMultiplierId
        )) == null ? void 0 : _c.name
      }) })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", gap: "4px", children: [
      isEdit && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Delete",
          fullWidth: true,
          loading: false,
          sizeVariant: "medium",
          colorVariant: "danger",
          onClick: () => {
            rule && handleDelete(scheduleId, rule.id);
          },
          type: "button"
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Save",
          loading: formik.isSubmitting,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: !formik.isValid
        }
      )
    ] })
  ] }) }) });
};
