"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { FormControl, Stack, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { keyBy } from "lodash";
import * as Yup from "yup";
import { UserRoleAPI } from "@/api-client/user-role.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import {
  AutocompleteComponent
} from "@/v2/components/forms/autocomplete.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DepartmentFormDrawer } from "@/v2/feature/department/department-settings/components/department-form-drawer.component";
import { JobPositionFormDrawer } from "@/v2/feature/job-position/job-position-settings/features/components/job-position-form-drawer.component";
import { useAdminOnboardingFieldsContext } from "@/v2/feature/onboarding/context/admin-onboarding-fields.context";
import { SiteFormDrawer } from "@/v2/feature/site/site-settings/features/components/site-form-drawer.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import {
  getDefaultEmployeeOption,
  getRoleLastChangeTypeOptions
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { areAllValuesNull } from "@/v2/feature/user-onboarding/user-onboarding.util";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
const NewEmployeeRoleSchema = (defaultFieldsMap, polyglot) => {
  return Yup.object().shape({
    jobPositionId: defaultFieldsMap["role.jobTitle"] && defaultFieldsMap["role.jobTitle"].isRequired ? Yup.string().trim().nullable().required(polyglot.t("NewEmployeeRoleSection.errorMessages.jobTitleRequired")) : Yup.string().trim().nullable().notRequired(),
    departmentId: defaultFieldsMap["role.department"] && defaultFieldsMap["role.department"].isRequired ? Yup.number().nullable().integer().required(polyglot.t("NewEmployeeRoleSection.errorMessages.departmentIdRequired")) : Yup.number().optional().nullable().integer(),
    siteId: defaultFieldsMap["role.site"] && defaultFieldsMap["role.site"].isRequired ? Yup.number().nullable().integer().required(polyglot.t("NewEmployeeRoleSection.errorMessages.siteIdRequired")) : Yup.number().optional().nullable().integer(),
    managerId: defaultFieldsMap["role.manager"] && defaultFieldsMap["role.manager"].isRequired ? Yup.number().nullable().integer().required(polyglot.t("NewEmployeeRoleSection.errorMessages.managerIdRequired")) : Yup.number().optional().nullable().integer(),
    lastChangeType: defaultFieldsMap["role.lastChangeType"] && defaultFieldsMap["role.lastChangeType"].isRequired ? Yup.string().nullable().required(polyglot.t("NewEmployeeRoleSection.errorMessages.lastChangeTypeRequired")) : Yup.string().nullable().notRequired(),
    lastChangeReason: defaultFieldsMap["role.lastChangeReason"] && defaultFieldsMap["role.lastChangeReason"].isRequired ? Yup.string().nullable().required(polyglot.t("NewEmployeeRoleSection.errorMessages.lastChangeReasonRequired")) : Yup.string().nullable().optional(),
    effectiveDate: defaultFieldsMap["role.effectiveDate"] && defaultFieldsMap["role.effectiveDate"].isRequired ? Yup.string().test(dateFieldTest).required(polyglot.t("NewEmployeeRoleSection.errorMessages.effectiveDateRequired")) : Yup.string().test(dateFieldTest).optional()
  });
};
export const NewEmployeeRoleSection = ({
  role,
  departments,
  sites,
  jobPositions,
  onNext,
  startDate,
  sx,
  userId,
  refreshSites,
  refreshDept,
  refreshJobPosition,
  defaultJobPosition
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const scopesContext = { userId: user.userId };
  const [departmentDrawerOpen, setDepartmentDrawerOpen] = useState(false);
  const [siteDrawerOpen, setSiteDrawerOpen] = useState(false);
  const [jobDrawerOpen, setJobDrawerOpen] = useState(false);
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useAdminOnboardingFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Role);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Role);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const users = useMemo(() => {
    return nonTerminatedCachedUsers.filter((u) => u.userId !== userId).map((u) => __spreadValues({
      label: u.displayName,
      value: u.userId
    }, u));
  }, [nonTerminatedCachedUsers, userId]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2;
    const values = {};
    if (defaultFieldsMap["role.jobTitle"]) values.jobPositionId = (_a2 = role == null ? void 0 : role.jobPositionId) != null ? _a2 : null;
    if (defaultFieldsMap["role.department"]) values.departmentId = role == null ? void 0 : role.departmentId;
    if (defaultFieldsMap["role.site"]) values.siteId = (_b2 = role == null ? void 0 : role.siteId) != null ? _b2 : null;
    if (defaultFieldsMap["role.manager"]) values.managerId = (_c2 = role == null ? void 0 : role.managerId) != null ? _c2 : null;
    if (defaultFieldsMap["role.effectiveDate"])
      values.effectiveDate = (_d2 = role == null ? void 0 : role.effectiveDate) != null ? _d2 : new LocalDate(startDate).toDateString();
    if (defaultFieldsMap["role.lastChangeType"])
      values.lastChangeType = (_e2 = role == null ? void 0 : role.lastChangeType) != null ? _e2 : getDefaultEmployeeOption(polyglot).value;
    if (defaultFieldsMap["role.lastChangeReason"])
      values.lastChangeReason = (_f2 = role == null ? void 0 : role.lastChangeReason) != null ? _f2 : getDefaultEmployeeOption(polyglot).label;
    values.customUpdates = (_h2 = (_g2 = role == null ? void 0 : role.customUpdates) == null ? void 0 : _g2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _h2 : [];
    return values;
  }, [defaultFieldsMap, role, customFieldIds, polyglot, startDate]);
  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: NewEmployeeRoleSchema(defaultFieldsMap, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      const allValuesNull = areAllValuesNull(values);
      try {
        if (allValuesNull) {
          onNext();
        } else {
          if ("id" in values && values.id) {
            yield UserRoleAPI.patch(userId, values.id, values);
          } else {
            yield UserRoleAPI.create(userId, values);
            yield OnboardingAPI.assignTemplateTasksToUser(userId);
          }
          onNext();
        }
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeRoleSection.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  const currentJobPositionValue = useMemo(() => {
    return jobPositions.find((option) => option.value === Number(formik.values.jobPositionId));
  }, [formik.values.jobPositionId, jobPositions]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("NewEmployeeRoleSection.role") }),
      defaultFieldsMap["role.effectiveDate"] && /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_a = formik.values.effectiveDate) != null ? _a : null,
          onChange: (value) => formik.setFieldValue("effectiveDate", value),
          name: "effectiveDate",
          label: polyglot.t("RoleForm.effectiveDate"),
          error: !!formik.errors.effectiveDate && formik.touched.effectiveDate,
          helperText: formik.errors.effectiveDate && formik.touched.effectiveDate
        }
      ),
      defaultFieldsMap["role.jobTitle"] && /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "jobPositionId",
          label: polyglot.t("RoleForm.jobTitle"),
          options: jobPositions,
          value: currentJobPositionValue,
          compareValue: formik.values.jobPositionId,
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("jobPositionId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          helperText: hasSubmitted && formik.errors.jobPositionId,
          error: hasSubmitted && !!formik.errors.jobPositionId,
          editList: {
            handler: () => setJobDrawerOpen(true),
            isHidden: false
          },
          required: (_b = defaultFieldsMap["role.jobTitle"]) == null ? void 0 : _b.isRequired
        }
      ),
      defaultFieldsMap["role.department"] && /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "departmentId",
          label: polyglot.t("NewEmployeeRoleSection.departmentId"),
          options: departments,
          value: departments.find(({ value }) => value === formik.values.departmentId),
          compareValue: (_c = formik.values.departmentId) != null ? _c : "",
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("departmentId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          helperText: hasSubmitted && formik.errors.departmentId,
          error: hasSubmitted && !!formik.errors.departmentId,
          editList: {
            handler: () => setDepartmentDrawerOpen(true),
            isHidden: !checkScopes(user, ["company.settings:all"], scopesContext)
          },
          required: (_d = defaultFieldsMap["role.department"]) == null ? void 0 : _d.isRequired
        }
      ),
      defaultFieldsMap["role.site"] && /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "siteId",
          label: polyglot.t("NewEmployeeRoleSection.siteId"),
          options: sites,
          value: sites.find(({ value }) => value === formik.values.siteId),
          compareValue: (_e = formik.values.siteId) != null ? _e : "",
          onChange: (_, x) => {
            var _a2;
            formik.setFieldValue("siteId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
          },
          helperText: hasSubmitted && formik.errors.siteId,
          error: hasSubmitted && !!formik.errors.siteId,
          editList: {
            handler: () => setSiteDrawerOpen(true),
            isHidden: !checkScopes(user, ["company.settings:all"], scopesContext)
          },
          required: (_f = defaultFieldsMap["role.site"]) == null ? void 0 : _f.isRequired
        }
      ),
      defaultFieldsMap["role.manager"] && /* @__PURE__ */ jsx(
        SingleUserSelect,
        {
          name: "managerId",
          label: polyglot.t("NewEmployeeRoleSection.managerId"),
          options: users,
          disabled: saving,
          onChange: (_, selection) => {
            var _a2;
            formik.setFieldValue("managerId", (_a2 = selection == null ? void 0 : selection.value) != null ? _a2 : null);
          },
          value: formik.values.managerId,
          error: hasSubmitted && Boolean(formik.errors.managerId),
          helperText: hasSubmitted && formik.errors.managerId,
          required: (_g = defaultFieldsMap["role.manager"]) == null ? void 0 : _g.isRequired
        }
      ),
      defaultFieldsMap["role.lastChangeType"] && /* @__PURE__ */ jsx(FormControl, { fullWidth: true, error: !!formik.errors.lastChangeType, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "lastChangeType",
          label: polyglot.t("RoleForm.lastChangeType"),
          options: getRoleLastChangeTypeOptions(polyglot),
          value: formik.values.lastChangeType,
          compareValue: formik.values.lastChangeType,
          error: !!formik.errors.lastChangeType && formik.touched.lastChangeType,
          onChange: formik.handleChange,
          helperText: formik.errors.lastChangeType && formik.touched.lastChangeType,
          required: (_h = defaultFieldsMap["role.lastChangeType"]) == null ? void 0 : _h.isRequired
        }
      ) }),
      defaultFieldsMap["role.lastChangeReason"] && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "lastChangeReason",
          label: polyglot.t("RoleForm.lastChangeReason"),
          value: formik.values.lastChangeReason,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.lastChangeReason && !!formik.errors.lastChangeReason,
          helperText: (_i = formik.touched.lastChangeReason && formik.errors.lastChangeReason) != null ? _i : " ",
          clearText: () => formik.setFieldValue("lastChangeReason", ""),
          required: (_j = defaultFieldsMap["role.lastChangeReason"]) == null ? void 0 : _j.isRequired
        }
      ),
      /* @__PURE__ */ jsx(
        UserCustomField,
        {
          values: (_k = formik.values.customUpdates) != null ? _k : [],
          formFields: customFields != null ? customFields : [],
          onChange: (values) => formik.setFieldValue("customUpdates", values),
          disabled: saving,
          fieldSx: {}
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.continue"),
          loading: saving,
          colorVariant: "primary",
          sizeVariant: "large",
          fullWidth: true
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      SiteFormDrawer,
      {
        isOpen: siteDrawerOpen,
        setIsOpen: setSiteDrawerOpen,
        selectedSite: void 0,
        refreshSites: () => __async(void 0, null, function* () {
          if (refreshSites) yield refreshSites();
        }),
        onClose: () => setSiteDrawerOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(
      DepartmentFormDrawer,
      {
        isOpen: departmentDrawerOpen,
        setIsOpen: setDepartmentDrawerOpen,
        selectedDepartment: void 0,
        refreshDepartments: () => __async(void 0, null, function* () {
          if (refreshDept) yield refreshDept();
        }),
        onClose: () => setDepartmentDrawerOpen(false)
      }
    ),
    /* @__PURE__ */ jsx(
      JobPositionFormDrawer,
      {
        isOpen: jobDrawerOpen,
        setIsOpen: setJobDrawerOpen,
        selectedJobPosition: defaultJobPosition,
        onClose: () => {
          setJobDrawerOpen(false);
        },
        refresh: () => __async(void 0, null, function* () {
          if (refreshJobPosition) yield refreshJobPosition();
        })
      }
    )
  ] }) });
};
