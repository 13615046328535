"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/feature/dashboard/dashboard.scss";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { spacing } from "@/v2/styles/spacing.styles";
import "@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner.scss";
export const UserTimePlannerSmallLoader = () => {
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "small", isLoadingState: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column" }, children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", padding: spacing.p5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Time planner" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Policy summary" })
    ] }) }) }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          height: "100%",
          width: "100%",
          verticalAlign: "middle",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: spacing.m10
        },
        children: /* @__PURE__ */ jsx("div", { className: "utp-progress-bar", children: /* @__PURE__ */ jsx("progress", { value: "75", max: "100", style: { visibility: "hidden", height: 0, width: 0 }, children: "75%" }) })
      }
    )
  ] }) });
};
