"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useState } from "react";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DevicesStockTable } from "@v2/feature/device/components/devices-list-overview/devices-stock-table.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { SuperAdminAddStockDeviceDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-add-stock-device-drawer.component";
import { BackofficeRootStyle } from "@v2/styles/root.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { themeColors } from "@/v2/styles/colors.styles";
export const SuperAdminDeviceStockPage = ({ pageConfig }) => {
  var _a, _b;
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const [deviceModels, setDeviceModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const [stockDevices, setStockDevices] = useState([]);
  const fetchData = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [deviceModelsData, stockDeviceData] = yield Promise.all([
        DeviceAPI.getDeviceModels(true),
        DeviceAPI.getStockDevicesAsSuperadmin()
      ]);
      setDeviceModels(deviceModelsData);
      setStockDevices(stockDeviceData);
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Devices" }),
        views: /* @__PURE__ */ jsx(Fragment, {}),
        showAction: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsAddDrawerOpen(true), children: "Add device in stock" })
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, secondLevel: true, children: [
      Boolean(stockDevices && stockDevices.length > 0) ? /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "83%",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          children: /* @__PURE__ */ jsx(DevicesStockTable, { devices: stockDevices, refresh: fetchData })
        }
      ) : /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "No devices in stock." }),
      /* @__PURE__ */ jsx(
        SuperAdminAddStockDeviceDrawer,
        {
          isOpen: isAddDrawerOpen,
          setIsOpen: setIsAddDrawerOpen,
          deviceModels,
          refresh: fetchData
        }
      )
    ] })
  ] });
};
