"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomDEPTokenSteps } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component";
import { InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const UploadMdmTokenComponent = ({
  onNext,
  setFormData,
  formData
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [uploadedCorrectly, setUploadedCorrectly] = useState(false);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Upload Server Token" }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m20 }, children: "Upload the file you just downloaded from Apple Business Manager. This will enable the connection between Zelt MDM and your ABM." }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "block" }, children: /* @__PURE__ */ jsx(
          UploadInput,
          {
            sx: {
              display: "block"
            },
            onChange: (response) => __async(void 0, null, function* () {
              if (response) {
                const fetchData = () => __async(void 0, null, function* () {
                  try {
                    yield InHouseMdmAPI.getDepAccountDetailsAndSave(formData.mdmServerName);
                  } catch (error) {
                    showMessage("Error fetching dep account details", "error");
                  }
                });
                yield fetchData();
                setUploadedCorrectly(true);
                return;
              }
            }),
            endpoint: `/apiv2/zelt-mdm/dep/token/public/${formData.mdmServerName}`
          }
        ) }),
        uploadedCorrectly && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "large",
            colorVariant: "primary",
            style: { marginTop: spacing.m25 },
            onClick: () => {
              formData.type = CustomDEPTokenSteps.Download_key;
              setFormData(formData);
              onNext();
            },
            fullWidth: true,
            children: polyglot.t("General.next")
          }
        )
      ] })
    }
  );
};
