"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import "@rc-component/color-picker/assets/index.css";
import { useMemo, useState } from "react";
import { StrikethroughS } from "@mui/icons-material";
import CodeIcon from "@mui/icons-material/Code";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatLineSpacingIcon from "@mui/icons-material/FormatLineSpacing";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import { Box, Popover, Stack } from "@mui/material";
import ColorPicker from "@rc-component/color-picker";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { ReactComponent as Bold } from "@/images/fields/Bold.svg";
import { ReactComponent as Italic } from "@/images/fields/Italic.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  fontFamilyMap,
  fontFamilyOptions,
  pixelOptions,
  tableOptions,
  lineSpacingOptions
} from "@/v2/feature/templates/components/editor";
import { themeColors } from "@/v2/styles/colors.styles";
import { getSelectProps, iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditorButtons = ({ editor, setLink, color, setColor }) => {
  const [fontSize, setFontSize] = useState("12px");
  const [fontFamily, setFontFamily] = useState("arial");
  const [lineHeight, setLineHeight] = useState("1");
  const [showColorPicker, setShowColorPicker] = useState(null);
  const colorButton = useMemo(() => {
    return /* @__PURE__ */ jsxs(Box, { style: { display: "flex", flexDirection: "column" }, onClick: (e) => setShowColorPicker(e.currentTarget), children: [
      /* @__PURE__ */ jsx("span", { style: { color: "black", fontFamily: "arial" }, children: "A" }),
      /* @__PURE__ */ jsx("div", { style: { height: 5, width: 30, backgroundColor: color } })
    ] });
  }, [color]);
  const manageTable = (value) => {
    switch (value) {
      case "insert_table":
        editor == null ? void 0 : editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
        break;
      case "add_column":
        editor == null ? void 0 : editor.chain().focus().addColumnAfter().run();
        break;
      case "delete_column":
        editor == null ? void 0 : editor.chain().focus().deleteColumn().run();
        break;
      case "add_row":
        editor == null ? void 0 : editor.chain().focus().addRowBefore().run();
        break;
      case "delete_row":
        editor == null ? void 0 : editor.chain().focus().deleteRow().run();
        break;
      case "merge_cells":
        editor == null ? void 0 : editor.chain().focus().mergeCells().run();
        break;
      case "split_cell":
        editor == null ? void 0 : editor.chain().focus().splitCell().run();
        break;
    }
  };
  const setColorHandler = (color2) => {
    setColor(color2.toHexString());
    editor == null ? void 0 : editor.chain().focus().setColor(color2.toHexString()).run();
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        style: {
          display: "flex",
          gap: spacing.g3,
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          paddingTop: "10px",
          paddingBottom: "10px",
          borderBottom: `1px solid ${themeColors.Background}`
        },
        children: [
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().undo().run(),
              disabled: !editor.can().chain().focus().undo().run(),
              className: editor.isActive("bold") ? "is-active" : "",
              children: /* @__PURE__ */ jsx(UndoIcon, {})
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().redo().run(),
              disabled: !editor.can().chain().focus().redo().run(),
              className: editor.isActive("bold") ? "is-active" : "",
              children: /* @__PURE__ */ jsx(RedoIcon, {})
            }
          ),
          /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", type: "button", children: colorButton }),
          /* @__PURE__ */ jsx(Box, { sx: { flex: "0 0 75px", boxSizing: "border-box", p: 0, m: 0 }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              value: fontFamily,
              className: "small-icon-button",
              onChange: (e) => {
                var _a;
                const fontFamily2 = e.target.value;
                setFontFamily(fontFamily2);
                editor.chain().focus().setFontFamily((_a = fontFamilyMap) == null ? void 0 : _a[fontFamily2]).run();
              },
              variant: "standard",
              size: "small",
              options: fontFamilyOptions,
              name: "fontSize",
              maxLabelDisplayCharacters: 5,
              sx: {
                "&.MuiTextField-root": {
                  "& div.MuiInputBase-root.MuiInput-root": {
                    height: "32px",
                    minHeight: "32px"
                  }
                }
              },
              SelectProps: __spreadProps(__spreadValues({}, getSelectProps), {
                IconComponent: () => /* @__PURE__ */ jsx(ArrowDownACIcon, { style: { position: "static" } })
              })
            }
          ) }),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().toggleBold().run(),
              disabled: !editor.can().chain().focus().toggleBold().run(),
              className: editor.isActive("bold") ? "is-active" : "",
              children: /* @__PURE__ */ jsx(Bold, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().toggleItalic().run(),
              disabled: !editor.can().chain().focus().toggleItalic().run(),
              className: editor.isActive("italic") ? "is-active" : "",
              children: /* @__PURE__ */ jsx(Italic, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().toggleStrike().run(),
              disabled: !editor.can().chain().focus().toggleStrike().run(),
              className: editor.isActive("strike") ? "is-active" : "",
              children: /* @__PURE__ */ jsx(StrikethroughS, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: { flex: "0 0 75px", boxSizing: "border-box", p: 0, m: 0 }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              value: fontSize,
              className: "small-icon-button",
              onChange: (e) => {
                const fontSize2 = e.target.value;
                setFontSize(fontSize2);
                editor.chain().focus().setFontSize(fontSize2).run();
              },
              variant: "standard",
              size: "small",
              options: pixelOptions,
              SelectProps: __spreadProps(__spreadValues({}, getSelectProps), {
                IconComponent: () => /* @__PURE__ */ jsx(ArrowDownACIcon, { style: { position: "static" } })
              }),
              sx: {
                "&.MuiTextField-root": {
                  "& div.MuiInputBase-root.MuiInput-root": {
                    height: "32px",
                    minHeight: "32px"
                  }
                }
              },
              name: "fontSize"
            }
          ) }),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().toggleCode().run(),
              disabled: !editor.can().chain().focus().toggleCode().run(),
              children: /* @__PURE__ */ jsx(CodeIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", type: "button", onClick: setLink, children: /* @__PURE__ */ jsx(InsertLinkIcon, __spreadValues({}, iconSize)) }),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().toggleBulletList().run(),
              children: /* @__PURE__ */ jsx(FormatListBulletedIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().toggleOrderedList().run(),
              children: /* @__PURE__ */ jsx(FormatListNumberedIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().setTextAlign("left").run(),
              children: /* @__PURE__ */ jsx(FormatAlignLeftIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().setTextAlign("center").run(),
              children: /* @__PURE__ */ jsx(FormatAlignCenterIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().setTextAlign("right").run(),
              children: /* @__PURE__ */ jsx(FormatAlignRightIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              type: "button",
              onClick: () => editor.chain().focus().setTextAlign("justify").run(),
              children: /* @__PURE__ */ jsx(FormatAlignJustifyIcon, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: { flex: "0 0 60px", boxSizing: "border-box", p: 0, m: 0 }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              value: lineHeight,
              className: "small-icon-button",
              placeholderOverride: /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: /* @__PURE__ */ jsx(FormatLineSpacingIcon, { style: { position: "static", alignSelf: "center", fontSize: 24 } }) }),
              onChange: (e) => {
                const value = e.target.value;
                setLineHeight(value);
                editor == null ? void 0 : editor.commands.setLineHeight(value);
              },
              variant: "standard",
              size: "small",
              options: lineSpacingOptions,
              name: "lineHeight",
              sx: {
                "&.MuiTextField-root": {
                  "& div.MuiInputBase-root.MuiInput-root": {
                    height: "32px",
                    minHeight: "32px"
                  }
                }
              },
              SelectProps: __spreadProps(__spreadValues({}, getSelectProps), {
                IconComponent: () => /* @__PURE__ */ jsx(ArrowDownACIcon, { style: { position: "static" } })
              })
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { flex: "0 0 75px", boxSizing: "border-box", p: 0, m: 0 }, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              placeholderOverride: /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Table" }) }),
              className: "small-icon-button",
              value: "",
              onChange: (e) => {
                const value = e.target.value;
                manageTable(value);
              },
              variant: "standard",
              size: "small",
              options: tableOptions,
              name: "fontSize",
              sx: {
                "&.MuiTextField-root": {
                  "& div.MuiInputBase-root.MuiInput-root": {
                    height: "32px",
                    minHeight: "32px"
                  }
                }
              },
              SelectProps: __spreadProps(__spreadValues({}, getSelectProps), {
                IconComponent: () => /* @__PURE__ */ jsx(ArrowDownACIcon, { style: { position: "static" } })
              })
            }
          ) })
        ]
      }
    ),
    showColorPicker && /* @__PURE__ */ jsx(
      Popover,
      {
        open: true,
        anchorEl: showColorPicker,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        onClose: () => setShowColorPicker(null),
        children: /* @__PURE__ */ jsx(
          ColorPicker,
          {
            value: color,
            defaultValue: "black",
            onChangeComplete: (color2) => setColorHandler(color2)
          }
        )
      }
    )
  ] });
};
