"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { OverlapsRule } from "@v2/feature/absence/absence.interface";
import { AbsenceGeneralSettingsReadonly } from "@v2/feature/absence/subfeatures/settings/components/absence-general-settings-readonly.component";
import { AbsenceSettingsEditDrawer } from "@v2/feature/absence/subfeatures/settings/components/absence-settings-edit-drawer.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
export const getOverlapsRuleOptions = (polyglot) => [
  { value: OverlapsRule.Everyone, label: polyglot.t("AbsenceGeneralSettings.everyone") },
  { value: OverlapsRule.DirectReports, label: polyglot.t("AbsenceGeneralSettings.directReports") },
  { value: OverlapsRule.Department, label: polyglot.t("AbsenceGeneralSettings.department") },
  { value: OverlapsRule.Site, label: polyglot.t("AbsenceGeneralSettings.site") },
  { value: OverlapsRule.Entity, label: polyglot.t("AbsenceGeneralSettings.entity") }
];
export const AbsenceCompanySettingsGeneralPage = () => {
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  const {
    data: absenceSettings,
    isLoading,
    mutate: refreshSettings
  } = useApiClient(AbsenceEndpoints.getCompanyAbsenceSettings(), { suspense: false });
  const refreshAbsenceSettings = useCallback(() => __async(void 0, null, function* () {
    if (refreshSettings) yield refreshSettings();
  }), [refreshSettings]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { width: "600px", display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceCompanySettingsGeneralPage.other") }),
          /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setEdit((prev) => !prev), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, children: /* @__PURE__ */ jsxs(Box, { sx: { width: "600px" }, children: [
      absenceSettings && /* @__PURE__ */ jsx(AbsenceGeneralSettingsReadonly, { absenceSettings }),
      absenceSettings && /* @__PURE__ */ jsx(
        AbsenceSettingsEditDrawer,
        {
          isOpen: edit,
          setIsOpen: setEdit,
          absenceSettings,
          refresh: refreshAbsenceSettings
        }
      )
    ] }) })
  ] });
};
