"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  isPaycodeForAdditions,
  isPaycodeForDeductions,
  isPaycodeForOtherEmployerPayments
} from "@v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-flow.util";
import { sumBy } from "lodash";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { UserPayrollStatusCell } from "@/v2/feature/payroll/components/user-payroll-status-cell.component";
import { getUserPayrollStatusSortOrder } from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { EditPayrunEntryDrawer } from "@/v2/feature/payroll/features/payroll-global/components/edit-payrun-entry-drawer.component";
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints
} from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { getUserStatusFromGlobalPayrunEntry } from "@/v2/feature/payroll/features/payroll-global/global-payroll.util";
import { GlobalPayrollSalarySummaryDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/global-payroll-salary-summary-drawer.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { filterByTextSearch } from "@/v2/util/array.util";
export const GlobalPayrollTable = ({
  payrun,
  searchQuery,
  allowEdit,
  refreshPayrun,
  stickyHeaderHeight,
  containerStyle,
  tableStyle
}) => {
  const { getCachedUserById } = useCachedUsers();
  const [viewingSummaryDrawerForEntry, setViewingSummaryDrawerForEntry] = useState(null);
  const [editPayrunEntry, setEditPayrunEntry] = useState(null);
  const { userCurrency } = payrun;
  const payCodesByCode = useMemo(
    () => new Map(
      payrun.paycodes.filter((p) => isPaycodeForAdditions(p) || isPaycodeForDeductions(p) || isPaycodeForOtherEmployerPayments(p)).map((paycode) => [paycode.code, paycode])
    ),
    [payrun]
  );
  const paycodesInPayrun = useMemo(() => {
    const paycodeList = /* @__PURE__ */ new Set();
    for (const entry of payrun.entries) {
      entry.paylines.forEach((p) => paycodeList.add(p.code));
    }
    return paycodeList;
  }, [payrun]);
  const calculateEmployeeNet = useCallback(
    (paylines) => {
      let netTotal = 0;
      for (const payline of paylines) {
        const paycode = payCodesByCode.get(payline.code);
        if (!paycode) continue;
        if (paycode.credit === "Employee") {
          netTotal += payline.amount;
        }
        if (paycode.debit === "Employee") {
          netTotal -= payline.amount;
        }
      }
      return netTotal;
    },
    [payCodesByCode]
  );
  const [employeeNetValues, totalEmployeeNet] = useMemo(() => {
    const netValues = new Map(payrun.entries.map((entry) => [entry.userId, calculateEmployeeNet(entry.paylines)]));
    const total = sumBy([...netValues.values()]);
    return [netValues, total];
  }, [calculateEmployeeNet, payrun.entries]);
  const calculateTotalsForEachCode = (entries, payCodes) => {
    const totals = /* @__PURE__ */ new Map();
    payCodes.forEach(({ code }) => {
      const totalForCode = entries.reduce((sum, entry) => {
        const payline = entry.paylines.filter((p) => p.code === code);
        const total = sumBy(payline, (item) => item.amount);
        return sum + (payline ? total : 0);
      }, 0);
      totals.set(code, totalForCode);
    });
    return totals;
  };
  const totalsForEachCode = useMemo(() => {
    const relevantPayCodes = [...payCodesByCode.values()].filter(({ code }) => paycodesInPayrun.has(code));
    return calculateTotalsForEachCode(payrun.entries, relevantPayCodes);
  }, [payrun.entries, payCodesByCode, paycodesInPayrun]);
  const getUserDisplayName = useCallback(
    (userId) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );
  const columns = useMemo(() => {
    const buildColumnForPaycode = ({ code, name }) => {
      return {
        id: code,
        accessorFn: (x) => x,
        header: name || code,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(
          a,
          b,
          (item) => sumBy(
            item.paylines.filter((p) => p.code === code),
            (item2) => item2.amount
          )
        ),
        cell: (c) => {
          const payline = c.row.original.paylines.filter((p) => p.code === code);
          const total = sumBy(payline, (item) => item.amount);
          return payline.length ? /* @__PURE__ */ jsx(CurrencyCell, { value: total, formatOptions: { noCurrencySymbol: true } }) : /* @__PURE__ */ jsx(EmptyCell, { textAlign: "right" });
        },
        footer: () => /* @__PURE__ */ jsx(CurrencyCell, { value: totalsForEachCode.get(code), formatOptions: { noCurrencySymbol: true } })
      };
    };
    return [
      {
        id: "employee",
        header: () => "Employee",
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => /* @__PURE__ */ jsx(UserCell, { userId: c.row.original.userId }),
        footer: () => /* @__PURE__ */ jsx(Fragment, { children: "Totals" })
      },
      {
        id: "currency",
        header: () => "Currency",
        accessorFn: (x) => x,
        cell: (c) => /* @__PURE__ */ jsx(Fragment, { children: userCurrency && c.row.original.userId in userCurrency ? userCurrency[c.row.original.userId] : "" })
      },
      ...[...payCodesByCode.values()].sort((a, b) => a.order - b.order).filter(({ code }) => paycodesInPayrun.has(code)).map((p) => buildColumnForPaycode(p)),
      {
        id: "status",
        header: () => "Status",
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => {
          var _a, _b;
          const statusA = (_a = getUserStatusFromGlobalPayrunEntry(a.original, payrun)) == null ? void 0 : _a.label;
          const statusB = (_b = getUserStatusFromGlobalPayrunEntry(b.original, payrun)) == null ? void 0 : _b.label;
          const orderA = getUserPayrollStatusSortOrder(statusA);
          const orderB = getUserPayrollStatusSortOrder(statusB);
          return orderA - orderB;
        },
        cell: (c) => {
          const status = getUserStatusFromGlobalPayrunEntry(c.row.original, payrun);
          return /* @__PURE__ */ jsx(UserPayrollStatusCell, { status });
        }
      },
      {
        id: "total",
        header: () => "Employee net",
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => employeeNetValues.get(item.userId)),
        cell: (cellInfo) => {
          return /* @__PURE__ */ jsx(
            CurrencyCell,
            {
              cellSx: __spreadValues({}, themeFonts.headline3),
              value: employeeNetValues.get(cellInfo.row.original.userId),
              formatOptions: { noCurrencySymbol: true }
            }
          );
        },
        footer: () => /* @__PURE__ */ jsx(CurrencyCell, { value: totalEmployeeNet, formatOptions: { noCurrencySymbol: true } })
      }
    ];
  }, [
    payCodesByCode,
    totalsForEachCode,
    getUserDisplayName,
    paycodesInPayrun,
    employeeNetValues,
    totalEmployeeNet,
    userCurrency,
    payrun
  ]);
  const filteredRows = useMemo(() => {
    return filterByTextSearch(searchQuery, payrun.entries, (entry) => {
      const user = getCachedUserById(entry.userId);
      return user ? [UserCell.getDisplayedName(user)] : [];
    });
  }, [getCachedUserById, payrun.entries, searchQuery]);
  const editPayrunEntryFromDrawer = (entry) => {
    setEditPayrunEntry(entry);
    setViewingSummaryDrawerForEntry(null);
  };
  const userForPayrunEntryDrawer = useMemo(() => {
    if (!viewingSummaryDrawerForEntry) return null;
    return getCachedUserById(viewingSummaryDrawerForEntry.userId);
  }, [viewingSummaryDrawerForEntry, getCachedUserById]);
  useEffect(() => {
    setEditPayrunEntry(payrun.entries.find((e) => e.userId === (editPayrunEntry == null ? void 0 : editPayrunEntry.userId)) || null);
  }, [payrun, editPayrunEntry == null ? void 0 : editPayrunEntry.userId]);
  return /* @__PURE__ */ jsxs(Box, { sx: containerStyle, children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredRows,
        columnData: columns,
        showFooter: true,
        rowClick: allowEdit ? (row) => setViewingSummaryDrawerForEntry(row.original) : void 0,
        fixedFirstColumn: false,
        initialSort: [{ id: "status", desc: false }],
        stickyHeader: true,
        stickyHeaderHeight,
        style: tableStyle,
        containerStyle: { height: "100%" }
      }
    ),
    /* @__PURE__ */ jsx(
      EditPayrunEntryDrawer,
      {
        payrollId: payrun.payrollId,
        payrunClosed: payrun.state !== "DRAFT",
        savePaylineUpdates: (update) => __async(void 0, null, function* () {
          const { payrollId, taxYear, payPeriod, period } = payrun;
          yield GlobalPayrollAPI.updatePayrunEntries(payrollId, taxYear, payPeriod, period, update);
          yield refreshPayrun();
          return true;
        }),
        payrunEntry: editPayrunEntry,
        onClose: () => setEditPayrunEntry(null)
      }
    ),
    /* @__PURE__ */ jsx(
      GlobalPayrollSalarySummaryDrawer,
      {
        payslip: null,
        onClose: () => setViewingSummaryDrawerForEntry(null),
        providedPayrunEntry: viewingSummaryDrawerForEntry,
        providedPayrun: payrun,
        providedUser: userForPayrunEntryDrawer,
        handleEditAction: payrun.state === "DRAFT" ? editPayrunEntryFromDrawer : void 0,
        payrun
      }
    )
  ] });
};
export const GlobalPayrollTableByPeriod = ({
  payrollId,
  taxYear,
  payPeriod,
  period,
  searchQuery,
  stickyHeaderHeight
}) => {
  const { data: payrun, mutate } = useApiClient(
    GlobalPayrollEndpoints.getPayrun(payrollId, taxYear, payPeriod, period),
    {
      suspense: false
    }
  );
  const refreshPayrun = useCallback(() => __async(void 0, null, function* () {
    if (mutate) yield mutate();
  }), [mutate]);
  return /* @__PURE__ */ jsx(Fragment, { children: payrun && /* @__PURE__ */ jsx(
    GlobalPayrollTable,
    {
      payrun,
      searchQuery,
      refreshPayrun,
      allowEdit: true,
      stickyHeaderHeight
    }
  ) });
};
