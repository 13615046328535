"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useEventSource } from "@/hooks/event-source-hook";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { PayrollAPI, PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { formatCurrency } from "@/v2/util/currency-format.util";
const PayrollEmploymentAllowancePage = ({ payrollId, close }) => {
  const [employmentAllowance, setEmploymentAllowance] = useState();
  const [initialEAValue, setInitialEAValue] = useState();
  const [adjustedEAValue, setAdjustedEAValue] = useState();
  const [progressMsg, setProgressMsg] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [adjustingAllowance, setAdjustingAllowance] = useState(false);
  const [showMessage] = useMessage();
  useEventSource(PayrollEndpoints.requestEmploymentAllowanceProgress(payrollId).url, {
    onMessage: (data) => {
      setProgressMsg(data.msg);
      setRequesting(true);
      if (data.state === "failed") {
        setRequesting(false);
        showMessage(data.msg, "error");
      }
      if (data.state === "success") {
        setRequesting(false);
        showMessage("Employment allowance has been enabled", "success");
        close();
      }
    },
    onError: (error) => {
      setProgressMsg(`Failed to send request. ${error.message}`);
      setRequesting(false);
    }
  });
  const requestEmploymentAllowance = useCallback(
    (allowance) => {
      setRequesting(true);
      setProgressMsg("");
      PayrollAPI.requestEmploymentAllowance(payrollId, allowance);
    },
    [payrollId]
  );
  const refreshEmploymentAllowanceStatus = useCallback(() => __async(void 0, null, function* () {
    PayrollAPI.getEmploymentAllowance(payrollId).then(
      setEmploymentAllowance,
      (err) => showMessage(`Failed to retrieve Employer Allowance. ${nestErrorMessage(err)}`, "error")
    );
  }), [payrollId, showMessage]);
  const adjustEmploymentAllowance = useCallback(
    (newAllowance) => __async(void 0, null, function* () {
      setAdjustingAllowance(true);
      try {
        yield PayrollAPI.adjustEmploymentAllowance(payrollId, newAllowance);
        refreshEmploymentAllowanceStatus();
        showMessage(`Employer Allowance updated.`, "success");
        close();
      } catch (err) {
        showMessage(`Employer Allowance adjustment failed. ${nestErrorMessage(err)}`, "error");
        setAdjustingAllowance(false);
      }
    }),
    [payrollId, close, refreshEmploymentAllowanceStatus, showMessage]
  );
  useEffect(() => {
    refreshEmploymentAllowanceStatus();
  }, [refreshEmploymentAllowanceStatus]);
  useEffect(() => {
    var _a;
    if (!employmentAllowance) return;
    setInitialEAValue(employmentAllowance.maxAllowance);
    setAdjustedEAValue((_a = employmentAllowance.current) == null ? void 0 : _a.allowance);
  }, [employmentAllowance]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Employment Allowance" }),
    !employmentAllowance && /* @__PURE__ */ jsx(LoadingSpinner, {}),
    (employmentAllowance == null ? void 0 : employmentAllowance.current) && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        "Current Allowance: ",
        formatCurrency(employmentAllowance.current.allowance)
      ] }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "New allowance",
          name: "new-allowance",
          onChange: (e) => {
            var _a, _b;
            const n = (_b = (_a = e.target.value.match(/^£\d+/)) == null ? void 0 : _a[0]) == null ? void 0 : _b.slice(1, 5);
            setAdjustedEAValue(n ? Math.min(Number(n), employmentAllowance.maxAllowance) : void 0);
          },
          value: `\xA3${adjustedEAValue != null ? adjustedEAValue : ""}`,
          autoFocus: true,
          disabled: adjustingAllowance
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Adjust allowance",
          loading: adjustingAllowance,
          onClick: () => adjustedEAValue && adjustEmploymentAllowance(adjustedEAValue),
          sizeVariant: "large",
          colorVariant: "primary",
          fullWidth: true
        }
      ) })
    ] }),
    employmentAllowance && !employmentAllowance.current && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        "Zelt will submit a new Employer Payment Summary to HMRC indicating your eligibility to receive Employment Allowance up to the amount entered below (maximum",
        " ",
        formatCurrency(employmentAllowance.maxAllowance, { wholeNumber: true }),
        ")."
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Once accepted, the allowance will reduce Class 1 National Insurance contributions starting from the next pay run." }),
      requesting ? /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(LoadingSpinner, {}),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: progressMsg })
      ] }) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Allowance",
            name: "allowance",
            onChange: (e) => {
              var _a, _b;
              const n = (_b = (_a = e.target.value.match(/^£\d+/)) == null ? void 0 : _a[0]) == null ? void 0 : _b.slice(1, 5);
              setInitialEAValue(n ? Math.min(Number(n), employmentAllowance.maxAllowance) : null);
            },
            value: `\xA3${initialEAValue != null ? initialEAValue : ""}`,
            autoFocus: true
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            disabled: !initialEAValue,
            onClick: () => initialEAValue && requestEmploymentAllowance(initialEAValue),
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true,
            children: "Submit request"
          }
        ) })
      ] })
    ] })
  ] });
};
export const PayrollEmploymentAllowanceDrawer = ({ payrollId, isOpen, setIsOpen }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: isOpen ? /* @__PURE__ */ jsx(PayrollEmploymentAllowancePage, { payrollId, close: () => setIsOpen(false) }) : /* @__PURE__ */ jsx(Fragment, {}) });
};
