"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const buttonStyles = {
  paddingX: spacing.p10,
  paddingY: spacing.p5,
  borderRadius: radius.br20,
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  "&:hover": {
    cursor: "pointer"
  }
};
export const SwitchButton = ({
  color,
  action,
  options,
  selectedValue
}) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        width: "150px",
        height: "30px",
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: "flex",
        alignItems: "center"
      },
      children: options.map((option) => {
        const isActive = option.value === selectedValue;
        return /* @__PURE__ */ jsx(
          Box,
          {
            className: isActive ? "switch-no-hover" : "switch-hover",
            sx: __spreadProps(__spreadValues({}, buttonStyles), {
              color: isActive ? themeColors.white : themeColors.DarkGrey,
              background: isActive ? color : "none",
              transition: "all 0.2s ease-in-out"
            }),
            onClick: () => action(option.value),
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: { color: isActive ? themeColors.white : themeColors.DarkGrey },
                className: isActive ? "tnm-switch-active-text" : "tnm-switch-text",
                children: option.label
              }
            )
          },
          option.value
        );
      })
    }
  );
};
