"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { IconButton, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ReactComponent as Back } from "@/images/fields/Left.svg";
import { Typography } from "@/v2/components/typography/typography.component";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SideMenuHeader = ({ onBackClick, heading, caption, sx }) => {
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", alignItems: "top", gap: spacing.g20, flexShrink: 0 }, sx), children: [
    /* @__PURE__ */ jsx(IconButton, { sx: iconCTAButtonSx, onClick: () => onBackClick ? onBackClick() : routerHistory.goBack(), children: /* @__PURE__ */ jsx(Back, {}) }),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g2, mr: spacing.mr10 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: heading }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: caption })
    ] })
  ] });
};
