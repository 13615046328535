"use strict";
export var UserContractTypeEnum = /* @__PURE__ */ ((UserContractTypeEnum2) => {
  UserContractTypeEnum2["Payrolled"] = "Payrolled";
  UserContractTypeEnum2["NonPayrolled"] = "NonPayrolled";
  return UserContractTypeEnum2;
})(UserContractTypeEnum || {});
export const getContractTypeOptions = (polyglot) => {
  return [
    { value: "Full-time", label: polyglot.t("getContractTypeOptions.fullTime") },
    { value: "Part-time", label: polyglot.t("getContractTypeOptions.partTime") },
    { value: "Shifts", label: polyglot.t("getContractTypeOptions.shifts") }
  ];
};
export const getWorkerTypeOptions = (polyglot) => {
  return Object.values(UserContractTypeEnum).map((value) => ({
    value,
    label: polyglot.t(`UserContractTypes.${value.toLowerCase()}`)
  }));
};
export const getUnitTypeOptions = (polyglot) => {
  return [
    { value: "Months", label: polyglot.t("getUnitTypeOptions.months") },
    { value: "Weeks", label: polyglot.t("getUnitTypeOptions.weeks") },
    { value: "Days", label: polyglot.t("getUnitTypeOptions.days") }
  ];
};
export const EMPTY_CHECK = {
  requestId: "0",
  statusCode: 0,
  status: "unknown",
  packageId: "",
  packageName: "",
  packageDescription: "",
  checks: []
};
