"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Alert, AlertTitle, Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
export default function AlertError({ error, title }) {
  if (!error) return /* @__PURE__ */ jsx("div", {});
  let errors;
  const err = error;
  if (err.response && err.response.data && err.response.data.errors) {
    errors = err.response.data.errors;
  } else if (err.response && err.response.data && err.response.data.error) {
    errors = [new Error(err.response.data.error)];
  } else if (typeof error === "string") {
    errors = [new Error(error)];
  } else {
    errors = [error];
  }
  return /* @__PURE__ */ jsxs(Alert, { severity: "error", sx: { my: 1 }, children: [
    /* @__PURE__ */ jsx(AlertTitle, { children: title }),
    errors.map((error2) => /* @__PURE__ */ jsxs(Box, { sx: { pr: 4 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", sx: { mr: 1 }, children: [
        error2.name,
        ":"
      ] }),
      error2.message
    ] }, error2.name))
  ] });
}
