"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { ChipSelect } from "@v2/components/table/chip-select.component";
import { ColumnsDrawer } from "@v2/components/table/columns-drawer.component";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { ResultTableOldReports } from "@v2/feature/reports/components/result-table.component";
import {
  ErrorComponent,
  LoadingTableComponent
} from "@v2/feature/reports/features/create-report/sections/util-sections.component";
import { ReportsAPI } from "@v2/feature/reports/reports.api";
import { ReportEntity } from "@v2/feature/reports/reports.interface";
import { exportReportCSVOldReports } from "@v2/feature/reports/util/report.util";
import { TimeBalancesReportColumnsOptions } from "@v2/feature/reports/util/time-report.util";
import { UserAPI } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_OVERVIEW } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const TimeReportBalancesResults = ({
  formData,
  setFormData,
  filters
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const { data: absencePolicies } = useApiClient(AbsenceEndpoints.getAbsencePolicies(), {
    suspense: false
  });
  const [loading, setLoading] = useState(false);
  const [reportName, setReportName] = useState(polyglot.t("TimeReportBalancesResults.new"));
  const [filterString, setFilterString] = useState("");
  const [filteredData, setFilteredData] = useState(void 0);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState(
    void 0
  );
  const [selectedColumns, setSelectedColumns] = useState(
    TimeBalancesReportColumnsOptions(polyglot).map((o) => o.value)
  );
  const [selectedPolicy, setSelectedPolicy] = useState(void 0);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    if (absencePolicies) setSelectedPolicy(absencePolicies[0]);
  }, [absencePolicies]);
  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData == null ? void 0 : filteredData.filter(
        (data) => !searchInput || (data == null ? void 0 : data.employeeName) && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, filteredData]);
  const getFilteredData = useCallback(() => __async(void 0, null, function* () {
    setReportLoading(true);
    try {
      if (!selectedPolicy) return;
      const paramObj = {
        domain: formData.domain,
        entities: [ReportEntity.Balances],
        type: formData.type,
        filters: filterString,
        columns: { [ReportEntity.Balances]: selectedColumns },
        singleNumericFilter1: selectedPolicy.id
      };
      let filteredTimeBalances = yield ReportsAPI.generateTimeCreatedReport(paramObj);
      setReportError(false);
      setFilteredData(filteredTimeBalances[ReportEntity.Balances]);
    } catch (error) {
      setReportError(true);
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setReportLoading(false);
    }
  }), [selectedColumns, filterString, formData, showMessage, selectedPolicy]);
  useEffect(() => {
    getFilteredData();
  }, [getFilteredData]);
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!selectedPolicy) return;
      setLoading(true);
      const createReport = {
        domain: formData.domain,
        type: formData.type,
        entities: [ReportEntity.Balances],
        filters: filterString,
        columns: { [ReportEntity.Balances]: selectedColumns },
        fileName: reportName,
        singleNumericFilter1: selectedPolicy.id
      };
      yield ReportsAPI.saveReport(createReport);
      setFormData(__spreadProps(__spreadValues({}, formData), { filters: filterString, columns: { [ReportEntity.Balances]: selectedColumns } }));
      showMessage(polyglot.t("TimeReportBalancesResults.successMessages.save"), "success");
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  }), [
    polyglot,
    formData,
    filterString,
    selectedColumns,
    reportName,
    selectedPolicy,
    setFormData,
    showMessage,
    routerHistory
  ]);
  const exportCsv = useCallback(() => __async(void 0, null, function* () {
    if (!selectedPolicy || !filteredData) return;
    try {
      exportReportCSVOldReports(
        reportName,
        filteredData ? { [ReportEntity.Balances]: filteredData } : void 0,
        `report-Balances-${selectedPolicy.name}.csv`
      );
    } catch (e) {
      showMessage(polyglot.t("TimeReportBalancesResults.errorMessages.download"), "error");
    }
  }), [reportName, selectedPolicy, filteredData, showMessage, polyglot]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: [
        reportLoading && /* @__PURE__ */ jsx(LoadingTableComponent, {}),
        !reportLoading && reportError && /* @__PURE__ */ jsx(ErrorComponent, {}),
        !reportLoading && filteredData && /* @__PURE__ */ jsxs(Box, { sx: { width: "80%" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10, minHeight: "60px" }, children: /* @__PURE__ */ jsx(
            EditableTitle,
            {
              variant: "headline1",
              value: reportName,
              onChange: (value) => {
                setReportName(value);
              },
              maxLength: 50,
              editButtonTooltip: ((_c = (_b = (_a = state.user.features) == null ? void 0 : _a.report) == null ? void 0 : _b.tooltip) == null ? void 0 : _c.saveReportName) ? void 0 : {
                open: true,
                title: /* @__PURE__ */ jsxs(Stack, { sx: { alignItems: "flex-start", maxWidth: "200px" }, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.white, m: spacing.m10 }), children: polyglot.t("TimeReportBalancesResults.typeReport") }),
                  /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      sizeVariant: "medium",
                      colorVariant: "secondary",
                      onClick: () => __async(void 0, null, function* () {
                        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                          "report",
                          "tooltip",
                          "saveReportName",
                          true
                        );
                        dispatch({
                          type: GlobalStateActions.UPDATE_USER,
                          payload: updatedGlobalUser
                        });
                      }),
                      children: polyglot.t("TimeReportBalancesResults.okay")
                    }
                  )
                ] })
              }
            }
          ) }),
          /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex" }, spacing.mt20), { gap: spacing.g5 }), children: [
            /* @__PURE__ */ jsx(
              ChipSelect,
              {
                title: (_d = selectedPolicy == null ? void 0 : selectedPolicy.name) != null ? _d : polyglot.t("TimeReportBalancesResults.selectPolicy"),
                options: (_e = absencePolicies == null ? void 0 : absencePolicies.map((p) => ({
                  label: p.name,
                  handler: () => setSelectedPolicy(p)
                }))) != null ? _e : []
              }
            ),
            /* @__PURE__ */ jsx(
              ColumnsDrawer,
              {
                columnsOptions: TimeBalancesReportColumnsOptions(polyglot),
                selectedColumns,
                setSelectedColumns
              }
            ),
            /* @__PURE__ */ jsx(
              FiltersDrawer,
              {
                filtersOptions: [{ filters }],
                selectedFilters: filterString,
                setSelectedFilters: setFilterString,
                encodedFilterNames: true
              }
            ),
            /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
              var _a2, _b2;
              return setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
            } })
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: filteredAndSearchedData && /* @__PURE__ */ jsx(ResultTableOldReports, { filteredData: filteredAndSearchedData, loading: false }) }),
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: __spreadProps(__spreadValues({
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: spacing.g10
              }, spacing.mt40), {
                width: "50%"
              }),
              children: [
                /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    sizeVariant: "medium",
                    colorVariant: "primary",
                    name: polyglot.t("General.save"),
                    loading,
                    fullWidth: true,
                    onClick: handleSave
                  }
                ),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    fullWidth: true,
                    sizeVariant: "medium",
                    colorVariant: "secondary",
                    onClick: exportCsv,
                    startIcon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                    children: polyglot.t("TimeReportBalancesResults.downloadCsv")
                  }
                )
              ]
            }
          )
        ] })
      ]
    }
  );
};
