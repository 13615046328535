"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const MembersPage = ({ initialValues, onNext }) => {
  const { polyglot } = usePolyglot();
  const [members, setMembers] = useState(initialValues);
  const hasMember = !!members && (members.customRule || members.filter !== UserSelectFiltersOptions.None || !!members.userIds.length);
  return /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, alignItems: "center", width: "100%" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { width: "450px", gap: spacing.g30 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("MembersPage.members") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("MembersPage.membersDesc") }),
    /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("MembersPage.members"),
        selectedLabel: "",
        initialFilterValue: members == null ? void 0 : members.filter,
        ruleString: members == null ? void 0 : members.customRule,
        value: members == null ? void 0 : members.userIds,
        onChange: (userIds, filter, customRule) => {
          setMembers({
            userIds,
            filter,
            customRule
          });
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: hasMember && /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "large",
        colorVariant: "primary",
        type: "button",
        fullWidth: true,
        onClick: () => onNext(members),
        children: polyglot.t("General.continue")
      }
    ) })
  ] }) });
};
