"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { REQUESTS_SUBMIT_REQUEST_ROUTE } from "@/lib/routes";
import {
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const ChooseRequestPage = () => {
  var _a;
  const { data: forms, error, isLoading } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), {
    suspense: false
  });
  const routerHistory = useHistory();
  useEscapeKey(() => {
    routerHistory.goBack();
  });
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
    error && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: (_a = error.data) == null ? void 0 : _a.message }),
    /* @__PURE__ */ jsx(SettingsSectionContent, { title: "New request", contentWidth: "100%", headerWidth: "100%", children: /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        loadingAll: isLoading,
        sections: [
          {
            contentWidth: "100%",
            items: [
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (forms || []).map((item) => {
                  return /* @__PURE__ */ jsx(
                    SettingsItemCard,
                    {
                      title: item.name,
                      description: item.description,
                      boxSx: responsiveCardStyle,
                      boxAction: () => {
                        routerHistory.push(generatePath(REQUESTS_SUBMIT_REQUEST_ROUTE, { id: item.id }));
                      }
                    },
                    item.id
                  );
                }) })
              }
            ]
          }
        ]
      }
    ) })
  ] });
};
