"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ScopesControl } from "@/component/widgets/Scopes";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { MissingInformationLabel } from "@/v2/feature/user/features/user-profile/details/components/missing-information-label.component";
import { ShowHideButton } from "@/v2/feature/user/features/user-profile/details/components/show-hide-button.component";
import {
  cardHeaderSx,
  cardMaxWidth,
  definitionSx,
  definitionTermSx,
  definitionValueSx,
  showTableSx,
  tablecardSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { primarySmallBtn, secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CardStructure = ({
  cardTitle,
  cardScope,
  showHistoryButton = true,
  showEditButton = true,
  showDetails,
  setShowDetails,
  userId,
  addActionMenuOptions,
  addAction,
  editAction,
  newAction,
  showMissingInfo,
  cardFieldCustomUpdates,
  cardFieldStubs,
  cardFieldDetails,
  drawerDetails,
  addButtonTitle,
  newButtonTitle,
  tableSelect,
  tableRowData,
  tableColumn,
  missingInfoIcon,
  emptyText,
  tableRowClickHandler
}) => {
  const { polyglot } = usePolyglot();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  return /* @__PURE__ */ jsxs(ProfileCard, { fieldStubs: cardFieldStubs != null ? cardFieldStubs : [], customUpdates: cardFieldCustomUpdates, sx: tablecardSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, cardMaxWidth), children: [
      /* @__PURE__ */ jsxs(Box, { component: "header", sx: cardHeaderSx, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: cardTitle }),
          missingInfoIcon
        ] }),
        /* @__PURE__ */ jsx(ScopesControl, { scopes: cardScope, context: scopesContext, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
          showEditButton && !showMissingInfo && !showDetails && editAction && /* @__PURE__ */ jsx(IconButton, { onClick: editAction, title: "Edit", sx: tableIconButtonSx, children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }),
          !addActionMenuOptions && addAction && /* @__PURE__ */ jsx(Button, { onClick: addAction, sx: secondaryCTABtn, children: addButtonTitle }),
          addActionMenuOptions && /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: polyglot.t("General.actions"),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end"
              },
              options: addActionMenuOptions
            }
          )
        ] }) })
      ] }),
      /* @__PURE__ */ jsx(Box, { component: "section", children: emptyText ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: emptyText }) : showMissingInfo ? /* @__PURE__ */ jsx(MissingInformationLabel, {}) : cardFieldDetails }),
      newAction ? /* @__PURE__ */ jsx(Box, { component: "section", sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(Button, { onClick: newAction, sx: primarySmallBtn, children: newButtonTitle }) }) : /* @__PURE__ */ jsx(Fragment, {})
    ] }),
    !showMissingInfo && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Box, { children: showHistoryButton && /* @__PURE__ */ jsx(ShowHideButton, { showDetails, setShowDetails }) }),
      showDetails && /* @__PURE__ */ jsxs(Box, { sx: showTableSx, children: [
        tableSelect,
        /* @__PURE__ */ jsx(BasicTable, { rowData: tableRowData, columnData: tableColumn, rowClick: tableRowClickHandler })
      ] })
    ] }),
    drawerDetails
  ] });
};
export const FieldStructure = ({ fieldTitle, fieldValue, fieldStub }) => {
  const { getDefaultField, loading } = useProfileFields();
  const fieldData = useMemo(() => fieldStub ? getDefaultField(fieldStub) : void 0, [getDefaultField, fieldStub]);
  if ((fieldValue != null ? fieldValue : "") === "") {
    return null;
  }
  if (fieldStub && (loading || (fieldData == null ? void 0 : fieldData.isHidden))) return null;
  return /* @__PURE__ */ jsxs(Box, { sx: definitionSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: definitionTermSx, children: fieldTitle }),
    fieldValue && typeof fieldValue === "object" && "isLink" in fieldValue ? /* @__PURE__ */ jsxs(
      Stack,
      {
        sx: {
          width: "100%",
          gap: spacing.g10,
          flexFlow: "row",
          alignItems: "baseline",
          cursor: "pointer",
          fill: themeColors.DarkGrey,
          "&:hover": {
            color: themeColors.Grey,
            fill: themeColors.Grey,
            cursor: "pointer"
          }
        },
        onClick: () => fieldValue.onClick(),
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: __spreadProps(__spreadValues({}, definitionValueSx), { fill: "inherit" }), children: fieldValue.label }),
          /* @__PURE__ */ jsx(Stack, { sx: { fill: "inherit" }, children: fieldValue.icon })
        ]
      }
    ) : /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: definitionValueSx, children: fieldValue })
  ] });
};
export const ProfileCard = ({ customUpdates, fieldStubs, children, sx }) => {
  const { getDefaultField } = useProfileFields();
  const allDefaultFieldsHidden = useMemo(() => {
    if (!fieldStubs.length) return false;
    return fieldStubs.every((fieldStub) => {
      var _a;
      return (_a = getDefaultField(fieldStub)) == null ? void 0 : _a.isHidden;
    });
  }, [fieldStubs, getDefaultField]);
  const allCustomFieldsHidden = useMemo(() => {
    if (!(customUpdates == null ? void 0 : customUpdates.length)) return true;
    return customUpdates.every(({ field }) => field.isHidden);
  }, [customUpdates]);
  const isCardHidden = allDefaultFieldsHidden && allCustomFieldsHidden;
  return isCardHidden ? /* @__PURE__ */ jsx(Fragment, {}) : /* @__PURE__ */ jsx(Box, { sx, children });
};
