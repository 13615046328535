"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Typography } from "@mui/material";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PersonalPayroll } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/personal-payroll.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const UserPayslips = ({ userId, userOverviewConfig }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("UserPayslips.salary") })
      }
    ),
    /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "90%",
            height: "90vh",
            sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
          }
        ),
        children: /* @__PURE__ */ jsx(PersonalPayroll, { userId, inPayroll: userOverviewConfig.inPayroll })
      }
    )
  ] });
};
