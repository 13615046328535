"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { useCompanyConfigState } from "@/hooks/company-config.hook";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AccountingJournal } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/accounting/accounting-journal.section";
import { DownloadPayslip } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/download-payslips.section";
import { HMRCSubmission } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/hmrc-submission.section";
import { PayrunPayments } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/payrun-payments.section";
import { PensionContributions } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/pension/pension-contributions.section";
import {
  AccountingJournalStates,
  DownloadPayslipsStates,
  HMRCSubmissionStates,
  PayrunPaymentsStates,
  PensionContributionStates
} from "@/v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface";
import { PayrunStates } from "@/v2/feature/payroll/payroll.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrunProcessSection = ({
  localPayRun,
  hideTitle,
  completePayrun,
  onNext,
  sx
}) => {
  var _a, _b, _c;
  const { companyConfig } = useCompanyConfigState();
  const [completingPayrun, setCompletingPayrun] = useState(false);
  const [payrunProcessFinished, setPayrunProcessFinished] = useState(false);
  const [downloadPayslipsState, setDownloadPayslipsState] = useState(
    DownloadPayslipsStates.notDownloaded
  );
  const [hmrcSubmissionState, setHmrcSubmissionState] = useState(
    HMRCSubmissionStates.notSubmitted
  );
  const [externalProviders, setExternalProviders] = useState(null);
  const [pensionContributionsState, setPensionContributionsState] = useState(
    (_b = (_a = localPayRun.payrunPensions) == null ? void 0 : _a.reduce(
      (acc, current) => {
        acc[current.pensionSchemeId] = PensionContributionStates.notSubmitted;
        return acc;
      },
      {}
    )) != null ? _b : { 0: PensionContributionStates.notSubmitted }
  );
  const [accountingJournalState, setAccountingJournalState] = useState(
    AccountingJournalStates.notSubmitted
  );
  const [payrunPaymentsState, setPayrunPaymentsState] = useState(PayrunPaymentsStates.notCreated);
  const [showMessage] = useMessage();
  useEffect(() => {
    const isDownloadPayslipsFinished = downloadPayslipsState === DownloadPayslipsStates.downloaded;
    const isHMRCSubmissionSuccessful = [HMRCSubmissionStates.submitted, HMRCSubmissionStates.markedAsSent].includes(
      hmrcSubmissionState
    );
    const isPensionContributionSubmitted = !Object.keys(pensionContributionsState).some(
      (pensionSchemeId) => ![PensionContributionStates.submitted, PensionContributionStates.markedAsSent].includes(
        pensionContributionsState[Number(pensionSchemeId)]
      )
    );
    const isAccountingJournalSubmitted = [
      AccountingJournalStates.submitted,
      AccountingJournalStates.markedAsSent
    ].includes(accountingJournalState);
    const arePaymentsComplete = payrunPaymentsState === PayrunPaymentsStates.created;
    setPayrunProcessFinished(
      isDownloadPayslipsFinished && isHMRCSubmissionSuccessful && isPensionContributionSubmitted && isAccountingJournalSubmitted && arePaymentsComplete
    );
  }, [
    localPayRun.state,
    downloadPayslipsState,
    hmrcSubmissionState,
    pensionContributionsState,
    accountingJournalState,
    payrunPaymentsState
  ]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const pensionProviders = yield PensionAPI.getPensionExternalProviders(localPayRun.payrollId);
        setExternalProviders(pensionProviders);
      } catch (error) {
        showMessage(`Could not get pension status. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [localPayRun.payrollId, showMessage]);
  const isCompletedPayrun = localPayRun.state === PayrunStates.completed;
  const isPartiallyCompletedPayrun = localPayRun.state === PayrunStates.partiallyCompleted;
  const markPayrunAsCompleted = useCallback(() => __async(void 0, null, function* () {
    setCompletingPayrun(true);
    const completed = yield completePayrun(localPayRun);
    setCompletingPayrun(false);
    if (completed) {
      onNext == null ? void 0 : onNext();
    }
  }), [completePayrun, localPayRun, onNext]);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flex: 1, overflowY: "auto" }, sx), children: [
    !hideTitle && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Process payrun" }),
      !isCompletedPayrun && !payrunProcessFinished && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Payrun is being processed. This may take a few moments..." }),
      isCompletedPayrun && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Payrun is completed." })
    ] }),
    /* @__PURE__ */ jsx(
      HMRCSubmission,
      {
        payRun: localPayRun,
        hmrcSubmissionState,
        setHmrcSubmissionState,
        sx: { borderTopColor: "transparent" }
      }
    ),
    (_c = localPayRun.payrunPensions) == null ? void 0 : _c.map((payrunPension, index) => {
      var _a2, _b2;
      return /* @__PURE__ */ jsx(
        PensionContributions,
        {
          payRun: localPayRun,
          pensionSchemeId: payrunPension.pensionSchemeId,
          providerName: (_a2 = payrunPension.pensionScheme) == null ? void 0 : _a2.providerName,
          displayName: (_b2 = payrunPension.pensionScheme) == null ? void 0 : _b2.displayName,
          externalProviders,
          isPensionSchemeSetUp: Boolean(companyConfig == null ? void 0 : companyConfig.inPension),
          pensionContributionsState,
          setPensionContributionsState
        },
        index
      );
    }),
    /* @__PURE__ */ jsx(
      AccountingJournal,
      {
        payRun: localPayRun,
        accountingJournalState,
        setAccountingJournalState
      }
    ),
    /* @__PURE__ */ jsx(
      PayrunPayments,
      {
        payRun: localPayRun,
        payrunPaymentsState,
        setPayrunPaymentsState
      }
    ),
    /* @__PURE__ */ jsx(
      DownloadPayslip,
      {
        payRun: localPayRun,
        downloadPayslipsState,
        setDownloadPayslipsState
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { width: "min-content" }, children: [
      !isCompletedPayrun && payrunProcessFinished && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Complete payrun",
          loading: completingPayrun,
          onClick: () => markPayrunAsCompleted(),
          sizeVariant: "large",
          colorVariant: "primary",
          style: { marginTop: spacing.m30, marginBottom: spacing.m30, flex: `0 0 auto` }
        }
      ),
      !isCompletedPayrun && !payrunProcessFinished && !isPartiallyCompletedPayrun && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Complete payrun later",
          loading: completingPayrun,
          onClick: () => markPayrunAsCompleted(),
          sizeVariant: "large",
          colorVariant: "primary",
          style: { marginTop: spacing.m30, marginBottom: spacing.m30, flex: `0 0 auto` }
        }
      )
    ] })
  ] });
};
