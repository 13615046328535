"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as NewWindow } from "@/images/new-theme-icon/NewWindow.svg";
import { ReactComponent as FullScreen } from "@/images/side-bar-icons/FullScreen.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconCrossButtonSx, iconCrossPrimaryButtonSx } from "@/v2/styles/icon-button.styles";
import { ProfileStyledSwipeableDrawer } from "@/v2/styles/sidebar-edit.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ProfileModal = ({ isOpen, setIsOpen, onClose, openProfile, openInNewWindow, children }) => {
  return /* @__PURE__ */ jsx(
    ProfileStyledSwipeableDrawer,
    {
      anchor: "right",
      open: isOpen && !!children,
      transitionDuration: 400,
      PaperProps: {
        sx: { width: { xs: "auto", sm: 440, md: 440, lg: 440, xl: 440 } }
      },
      onOpen: () => {
        if (!isOpen) {
          setIsOpen(true);
        }
      },
      onClose,
      children: /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Box, { sx: { position: "fixed", right: 450, top: 10 }, children: /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, iconCrossButtonSx), { background: themeColors.white }), onClick: onClose, children: /* @__PURE__ */ jsx(Close, { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey }) }) }),
        openInNewWindow && /* @__PURE__ */ jsx(Box, { sx: { position: "fixed", right: 450, top: 60 }, children: /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, iconCrossButtonSx), { background: themeColors.white }), onClick: openInNewWindow, children: /* @__PURE__ */ jsx(NewWindow, {}) }) }),
        openProfile && /* @__PURE__ */ jsx(Box, { sx: { position: "fixed", right: 450, top: 110 }, children: /* @__PURE__ */ jsx(IconButton, { sx: iconCrossPrimaryButtonSx, onClick: openProfile, children: /* @__PURE__ */ jsx(FullScreen, {}) }) }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, spacing.px16), { py: spacing.p16, position: "relative" }), children })
      ] })
    }
  );
};
