"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SurveySectionAPI } from "@v2/feature/growth/surveys/api-client/survey-section.api";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const SectionSchema = () => Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().optional()
});
export const SectionModalContent = ({
  cycleId,
  onClose,
  section,
  refresh
}) => {
  var _a, _b, _c, _d;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const updateObject = { name: values.name, description: values.description };
      if (section) {
        yield SurveySectionAPI.updateSurveySectionInBank(section.id, cycleId, updateObject);
        showMessage("Section updated successfully", "success");
      } else {
        yield SurveySectionAPI.createSectionByCycleId(cycleId, updateObject);
        showMessage("Section created successfully", "success");
      }
    }),
    [cycleId, section, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      name: section ? section.name : "",
      description: (_a = section == null ? void 0 : section.description) != null ? _a : ""
    },
    enableReinitialize: true,
    validationSchema: SectionSchema(),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield onSubmit(values);
        yield refresh();
        onClose();
      } catch (error) {
        showMessage("Couldn't create the section", "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: section ? "Edit section" : "New section" }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        multiline: true,
        name: "name",
        label: "Name",
        value: formik.values.name,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_b = formik.touched.name && formik.errors.name) != null ? _b : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: "Description",
        value: (_c = formik.values.description) != null ? _c : "",
        onChange: (value) => formik.setFieldValue("description", value),
        error: formik.touched.description && !!formik.errors.description,
        helperText: (_d = formik.touched.description && formik.errors.description) != null ? _d : " "
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
