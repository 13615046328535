"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useContext, useMemo } from "react";
import { Avatar, Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as CompletedAction } from "@/images/side-bar-icons/CheckCircle.svg";
import { ReactComponent as ActionCardIcon } from "@/images/side-bar-icons/WaitingFull.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { styledChipSx } from "@/v2/styles/chip.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getIconForCard = (completed, pending, requiresAdminAction) => {
  if (completed) return /* @__PURE__ */ jsx(CompletedAction, { style: { height: 16, width: 16, flexShrink: 0, fill: themeColors.green } });
  if (pending)
    return /* @__PURE__ */ jsx(ActionCardIcon, { style: { height: 16, width: 16, flexShrink: 0, fill: themeColors.YellowHover } });
  if (requiresAdminAction)
    return /* @__PURE__ */ jsx(ActionCardIcon, { style: { height: 16, width: 16, flexShrink: 0, fill: themeColors.red } });
  return /* @__PURE__ */ jsx(ActionCardIcon, { style: { height: 16, width: 16, flexShrink: 0 } });
};
export const OnboardingActionCard = ({
  chips,
  completed,
  pending,
  disabled,
  label,
  owner,
  onStartClick,
  startButtonLabel,
  editButtonLabel,
  actionButton,
  completedButton,
  requiresAdminAction = false,
  showEdit = false,
  onEditClick,
  startBtnColor = "primary",
  editBtnColor = "secondary",
  sx
}) => {
  const { polyglot } = usePolyglot();
  const displayedChips = useMemo(() => {
    var _a;
    return (_a = chips == null ? void 0 : chips.filter(Boolean)) != null ? _a : [];
  }, [chips]);
  const [globalState] = useContext(GlobalContext);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", gap: spacing.g10, py: spacing.p15, alignItems: "center" }, sx), children: [
    getIconForCard(completed, pending, requiresAdminAction),
    /* @__PURE__ */ jsx(Typography, { variant: "headline3", color: "DarkGrey", sx: { whiteSpace: "nowrap" }, children: label }),
    !!(chips == null ? void 0 : chips.length) && /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", flexWrap: "wrap", gap: spacing.g4 }, children: displayedChips.map((chip, index) => /* @__PURE__ */ jsx(React.Fragment, { children: typeof chip === "string" || React.isValidElement(chip) ? /* @__PURE__ */ jsx(Box, { sx: styledChipSx, children: chip }) : /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, styledChipSx), { color: themeColors.Red }), children: chip.text }) }, index)) }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", ml: "auto", gap: spacing.g20 }, children: [
      owner === "company" && /* @__PURE__ */ jsx(Avatar, { src: globalState.user.company.avatar, sx: { width: 20, height: 20 } }),
      typeof owner === "number" && /* @__PURE__ */ jsx(UserAvatar, { userId: owner, size: "s20" }),
      /* @__PURE__ */ jsxs(Stack, { sx: { width: "100px", flexShrink: 0 }, children: [
        !completed && !disabled && actionButton,
        !completed && !disabled && !actionButton && onStartClick && /* @__PURE__ */ jsx(ButtonComponent, { onClick: onStartClick, sizeVariant: "small", colorVariant: startBtnColor, fullWidth: true, children: startButtonLabel != null ? startButtonLabel : polyglot.t("OnboardingByUser.start") }),
        completed && !disabled && completedButton,
        completed && showEdit && onEditClick && /* @__PURE__ */ jsx(ButtonComponent, { onClick: onEditClick, sizeVariant: "small", colorVariant: editBtnColor, fullWidth: true, children: editButtonLabel != null ? editButtonLabel : polyglot.t("OnboardingByUser.edit") })
      ] })
    ] })
  ] });
};
