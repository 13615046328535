"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import { DietaryRestrictionsSelect } from "@/v2/components/dietary-select.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserAPI } from "@/v2/feature/user/user.api";
export const AboutSchema = Yup.object().shape({
  about: Yup.string().optional().nullable(),
  hobbies: Yup.string().optional().nullable(),
  social: Yup.string().optional().nullable(),
  foodPreferences: Yup.string().optional().nullable(),
  dietaryRestrictions: Yup.string().nullable().notRequired()
});
export const AboutForm = ({
  initialValues,
  userId,
  usedForDataImport,
  onSubmit,
  onClose,
  handleRefresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const { getDefaultField } = useProfileFields();
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AboutSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          yield UserAPI.patchUserAboutInfo(userId, values);
          showMessage(polyglot.t("AboutForm.successMessages.update"), "success");
        }
        onSubmit == null ? void 0 : onSubmit(__spreadValues({}, values));
        handleRefresh();
        showMessage(polyglot.t("AboutForm.successMessages.update"), "success");
      } catch (error) {
        showMessage(polyglot.t("AboutForm.errorMessages.save"), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AboutForm.edit") }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "about", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        multiline: true,
        name: "about",
        label: polyglot.t("AboutForm.about"),
        value: formik.values.about,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.about && !!formik.errors.about,
        helperText: (_a = formik.touched.about && formik.errors.about) != null ? _a : " ",
        endAdornment: "none",
        disabled: loading,
        required: (_b = getDefaultField("about")) == null ? void 0 : _b.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "hobbies", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "hobbies",
        label: polyglot.t("AboutForm.hobbies"),
        value: formik.values.hobbies,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.hobbies && !!formik.errors.hobbies,
        helperText: (_c = formik.touched.hobbies && formik.errors.hobbies) != null ? _c : " ",
        clearText: () => formik.setFieldValue("hobbies", ""),
        disabled: loading,
        required: (_d = getDefaultField("hobbies")) == null ? void 0 : _d.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "social", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "social",
        label: polyglot.t("AboutForm.social"),
        value: formik.values.social,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.social && !!formik.errors.social,
        helperText: (_e = formik.touched.social && formik.errors.social) != null ? _e : " ",
        clearText: () => formik.setFieldValue("social", ""),
        disabled: loading,
        required: (_f = getDefaultField("social")) == null ? void 0 : _f.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "foodPreferences", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "foodPreferences",
        label: polyglot.t("AboutForm.foodPreferences"),
        value: formik.values.foodPreferences,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.foodPreferences && !!formik.errors.foodPreferences,
        helperText: (_g = formik.touched.foodPreferences && formik.errors.foodPreferences) != null ? _g : " ",
        clearText: () => formik.setFieldValue("foodPreferences", ""),
        disabled: loading,
        required: (_h = getDefaultField("foodPreferences")) == null ? void 0 : _h.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "dietaryRestrictions", children: /* @__PURE__ */ jsx(
      DietaryRestrictionsSelect,
      {
        name: "dietaryRestrictions",
        value: formik.values.dietaryRestrictions,
        onChange: (_, value) => formik.setFieldValue("dietaryRestrictions", value),
        disabled: loading,
        required: (_i = getDefaultField("dietaryRestrictions")) == null ? void 0 : _i.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: loading
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, disabled: loading, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
