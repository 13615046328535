"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { CardHeader, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE } from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
export const AddChecklistTask = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const [checklist, setChecklist] = useState(null);
  const [showMessage] = useMessage();
  const saveChecklist = () => __async(void 0, null, function* () {
    if (!checklist) return;
    setLoading(true);
    try {
      const result = yield TaskAPI.addChecklist(checklist);
      showMessage(polyglot.t("AddChecklistTask.successMessages.add"), "success");
      routerHistory.push(generatePath(SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, { checklistId: result.id }));
    } catch (error) {
      showMessage(`${polyglot.t("AddChecklistTask.errorMessages.create")}. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("AddChecklistTask.tasks") })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsxs(Card, { children: [
      /* @__PURE__ */ jsx(
        CardHeader,
        {
          title: polyglot.t("AddChecklistTask.checklistInfo"),
          action: /* @__PURE__ */ jsx(
            Button,
            {
              variant: "contained",
              onClick: () => __async(void 0, null, function* () {
                yield saveChecklist();
              }),
              disabled: !checklist || !checklist.name,
              children: polyglot.t("AddChecklistTask.newChecklist")
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(CardContent, { children: /* @__PURE__ */ jsx("div", { style: { width: "40%" }, children: /* @__PURE__ */ jsx(
        TextField,
        {
          id: "checklist-name",
          label: polyglot.t("AddChecklistTask.checklistName"),
          value: checklist ? checklist.name : "",
          fullWidth: true,
          size: "small",
          onChange: (event) => setChecklist(__spreadProps(__spreadValues({}, checklist), { name: event.target.value }))
        }
      ) }) })
    ] }) })
  ] });
};
