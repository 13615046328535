"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency, isZero } from "@/v2/util/currency-format.util";
export const CurrencyWithDiff = ({
  currentValue,
  previousValue,
  dimIfZero,
  showZeroDiff,
  formatOptions,
  currency
}) => {
  const { polyglot } = usePolyglot();
  const diffFromPrevious = (currentValue != null ? currentValue : 0) - (previousValue != null ? previousValue : 0);
  const currentValueSpan = useCallback(
    (dim) => /* @__PURE__ */ jsx("span", { style: { opacity: dim ? 0.3 : void 0 }, children: formatCurrency(currentValue, formatOptions, currency) }),
    [currentValue, formatOptions, currency]
  );
  const getDiffColor = useCallback((diff) => {
    if (diff && Math.abs(diff) < 1) {
      return "Grey";
    }
    if (diff > 0) return "green";
    if (diff < 0) return "red";
    return void 0;
  }, []);
  if (typeof previousValue === "undefined") {
    return currentValueSpan(dimIfZero && isZero(currentValue));
  }
  if (isZero(diffFromPrevious) && !showZeroDiff) {
    return currentValueSpan(dimIfZero && isZero(currentValue));
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g5, alignItems: "center" }, children: [
    currentValueSpan(false),
    /* @__PURE__ */ jsxs(
      Typography,
      {
        variant: "paragraphSmall",
        color: getDiffColor(diffFromPrevious),
        title: polyglot.t("PayrunTable.previousValue", { value: formatCurrency(previousValue, formatOptions) }),
        children: [
          diffFromPrevious > 0 ? "+" : "",
          diffFromPrevious.toFixed(2).replace(".00", "")
        ]
      }
    )
  ] });
};
export const ValueWithDiff = ({
  current,
  previous,
  showZeroDiff,
  dimIfZero,
  getValue,
  currency
}) => {
  return /* @__PURE__ */ jsx(
    CurrencyWithDiff,
    {
      currentValue: getValue(current),
      previousValue: previous && getValue(previous),
      showZeroDiff,
      dimIfZero,
      currency
    }
  );
};
