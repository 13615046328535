"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const BadgeLoaderHeader = () => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20, height: "127px" }, children: [
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: spacing.g10,
          height: "100%",
          width: "100%"
        },
        children: [
          /* @__PURE__ */ jsxs(
            "div",
            {
              style: {
                display: "flex",
                alignItems: "center",
                gap: spacing.g10,
                maxWidth: "210px",
                width: "100%"
              },
              children: [
                /* @__PURE__ */ jsx(SkeletonLoader, { variant: "circular", width: "40px", height: "40px", sx: { background: themeColors.Background } }),
                /* @__PURE__ */ jsx(
                  SkeletonLoader,
                  {
                    variant: "rectangular",
                    width: "170px",
                    height: "31px",
                    sx: { background: themeColors.Background, borderRadius: radius.br15 }
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "30px",
              height: "30px",
              sx: { background: themeColors.Background, borderRadius: radius.br8 }
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          display: "flex",
          alignItems: "center",
          gap: spacing.g10,
          height: "100%",
          width: "100%"
        },
        children: [
          /* @__PURE__ */ jsx(BadgeLoaderItem, {}),
          /* @__PURE__ */ jsx(BadgeLoaderItem, {}),
          /* @__PURE__ */ jsx(BadgeLoaderItem, {})
        ]
      }
    )
  ] });
};
const BadgeLoaderItem = () => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      style: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: spacing.g5,
        height: "100%",
        maxWidth: "114px",
        width: "100%"
      },
      children: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "100%",
          height: "68px",
          sx: { background: themeColors.Background, borderRadius: radius.br10 }
        }
      )
    }
  );
};
