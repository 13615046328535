"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { IconButton } from "@v2/components/forms/icon-button.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { UserSelect } from "@v2/components/user-select-type/user-select.component";
import { UserSelectFiltersOptions } from "@v2/components/user-select-type/user-select.interface";
import { AbsenceAPI, AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { AbsencePolicyAttachmentType } from "@v2/feature/absence/absence.dto";
import {
  convertMinutesToClockHours,
  getNextDateFromBEStringDate,
  isHourlyPolicy,
  syncStartAndEndHourWithTheSameDate
} from "@v2/feature/absence/absence.util";
import {
  AfternoonOnly,
  FullDay,
  fullOrAfternoonOnlyValues,
  fullOrHalfDayValues,
  fullOrMorningOnlyValues,
  MorningOnly,
  OtherLength
} from "@v2/feature/calendar/calendar.interface";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { useDebouncedCallback } from "use-debounce";
import * as yup from "yup";
import { UploadInput } from "@/component/forms/UploadInput";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Subtract.svg";
import { nestErrorMessage } from "@/lib/errors";
import { checkIsManagerOrAdmin } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsenceOverlapDisplay } from "@/v2/feature/absence/components/absence-overlap-display.component";
import { SideAccentedTextLabel } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/side-accented-text-label.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest, isValidISODateTimeString, isValidTimeString } from "@/v2/infrastructure/date/date-format.util";
import { spacing } from "@/v2/styles/spacing.styles";
function validateGetBalance(values) {
  if (!values.isHourlyLoggingPolicy && values.startFullOrHalfDay === "otherLength") {
    if (values.startFullOrHalfDay !== "otherLength") {
      return false;
    }
    if (!values.startHour || !values.endHour) {
      return false;
    }
  }
  if (values.noOfEmployees !== "single" || values.userIds.length !== 1 || !values.policyId || !values.start || // if single daily absence of type multiple days and no end date, don't check balance
  !values.isHourlyLoggingPolicy && values.multipleDays && !values.end || // if hourly absence and no start/end hour is selected, don't check balance
  values.isHourlyLoggingPolicy && (!values.startHour || !values.endHour) || // if hourly && not valid hours selected/typed
  values.isHourlyLoggingPolicy && (!values.startHour || !values.endHour || !isValidISODateTimeString(values.endHour) || !isValidISODateTimeString(values.endHour)) || // if hourly && endHour <= startHour
  values.isHourlyLoggingPolicy && (!values.startHour || !values.endHour || values.startHour >= values.endHour))
    return false;
  return true;
}
const getHalfDayOptions = (values, polyglot) => {
  const morningOnly = !values.isHourlyLoggingPolicy && Boolean(
    !values.end && values.startFullOrHalfDay === MorningOnly(polyglot).value || values.end && values.endFullOrHalfDay === MorningOnly(polyglot).value
  );
  const afternoonOnly = !values.isHourlyLoggingPolicy && values.startFullOrHalfDay === AfternoonOnly(polyglot).value;
  return { morningOnly, afternoonOnly };
};
const isSingleAbsence = (abs) => Boolean(!abs.multipleDays || abs.startFullOrHalfDay === "otherLength" || abs.startHour || abs.endHour);
function getStartAndEndHours(values, polyglot) {
  if (values.isHourlyLoggingPolicy || values.startFullOrHalfDay === "otherLength")
    return syncStartAndEndHourWithTheSameDate(values, polyglot);
  return { startHour: null, endHour: null, startHourTimestamp: null, endHourTimestamp: null };
}
const absenceValidationSchema = (absencePolicy, polyglot) => yup.object({
  userIds: yup.array(
    yup.number().typeError(polyglot.t("ValidationMessages.validValue")).integer(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("AbsenceDrawerPage.errorMessages.userRequired") + "456")
  ).min(1, polyglot.t("ValidationMessages.validValue")).required(polyglot.t("AbsenceDrawerPage.errorMessages.userRequired") + "123"),
  policyId: yup.number().integer().required(polyglot.t("AbsenceDrawerPage.errorMessages.policyRequired")),
  start: yup.string().test(dateFieldTest).required(polyglot.t("AbsenceDrawerPage.errorMessages.startDateRequired")),
  end: yup.string().test(dateFieldTest).nullable().notRequired(),
  startFullOrHalfDay: yup.string().required(polyglot.t("AbsenceDrawerPage.errorMessages.fullOrHalfRequired")),
  endFullOrHalfDay: yup.string().notRequired(),
  notes: yup.string().max(255, polyglot.t("AbsenceDrawerPage.errorMessages.maxChars")).nullable().notRequired(),
  isHourlyLoggingPolicy: yup.boolean().notRequired(),
  attachment: (absencePolicy == null ? void 0 : absencePolicy.attachmentType) && absencePolicy.attachmentType === AbsencePolicyAttachmentType.Required ? yup.string().typeError(polyglot.t("AbsenceDrawerPage.errorMessages.attachFile")).required(polyglot.t("AbsenceDrawerPage.errorMessages.attachFile")) : yup.string().nullable().notRequired(),
  startHour: yup.date().when("isHourlyLoggingPolicy", {
    is: true,
    then: yup.date().typeError(polyglot.t("AbsenceDrawerPage.errorMessages.timeValueInvalid")).required(polyglot.t("AbsenceDrawerPage.errorMessages.startHourRequired")),
    otherwise: yup.date().when("startFullOrHalfDay", {
      is: (val) => val === "otherLength",
      then: yup.date().typeError(polyglot.t("AbsenceDrawerPage.errorMessages.timeValueInvalid")).required(polyglot.t("AbsenceDrawerPage.errorMessages.startHourRequired")),
      otherwise: yup.date().nullable().notRequired()
    })
  }),
  endHour: yup.date().when("isHourlyLoggingPolicy", {
    is: true,
    then: yup.date().when("startHour", {
      is: (startHour) => {
        if (!startHour) return false;
        return startHour.toString() !== "Invalid Date";
      },
      then: yup.date().min(yup.ref("startHour"), polyglot.t("AbsenceDrawerPage.errorMessages.endHourInvalid")).typeError(polyglot.t("AbsenceDrawerPage.errorMessages.timeValueInvalid")).required(polyglot.t("AbsenceDrawerPage.errorMessages.endHourRequired")),
      otherwise: yup.date().typeError("Please select a valid time value").required(polyglot.t("AbsenceDrawerPage.errorMessages.endHourRequired"))
    }),
    otherwise: yup.date().when("startFullOrHalfDay", {
      is: (val) => val === "otherLength",
      then: yup.date().typeError(polyglot.t("AbsenceDrawerPage.errorMessages.timeValueInvalid")).required(polyglot.t("AbsenceDrawerPage.errorMessages.startHourRequired")),
      otherwise: yup.date().nullable().notRequired()
    })
  })
});
export const AbsenceDrawerPage = ({
  userId = void 0,
  absence = void 0,
  refresh,
  closePage,
  reach = "company",
  usedForDataImport,
  importHandler,
  policyId
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { hasScopes } = useScopes();
  const isAdmin = hasScopes(["absence:all"]);
  const isManager = checkIsManagerOrAdmin(state.user, ["absence:manager"]);
  const { data: absencePolicies } = useApiClient(
    userId ? AbsenceEndpoints.getUserAbsencePolicies(userId) : absence ? AbsenceEndpoints.getUserAbsencePolicies(absence.userId) : isAdmin ? AbsenceEndpoints.getAbsencePolicies() : isManager ? AbsenceEndpoints.getTeamAbsencePolicies() : null,
    { suspense: false }
  );
  const [receiptFilename, setReceiptFilename] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers({ refresh: true });
  const user = useMemo(() => {
    var _a2;
    const currentUserId = (_a2 = absence == null ? void 0 : absence.userId) != null ? _a2 : userId;
    return currentUserId ? getCachedUserById(currentUserId) : null;
  }, [absence == null ? void 0 : absence.userId, userId, getCachedUserById]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const getStartFullOrHalfDayType = useCallback(
    (absence2) => {
      if ((absence2 == null ? void 0 : absence2.startHour) || (absence2 == null ? void 0 : absence2.endHour)) return OtherLength(polyglot).value;
      if (absence2 == null ? void 0 : absence2.end) {
        if (absence2.afternoonOnly) return AfternoonOnly(polyglot).value;
        return FullDay(polyglot).value;
      }
      if (absence2 == null ? void 0 : absence2.morningOnly) return MorningOnly(polyglot).value;
      if (absence2 == null ? void 0 : absence2.afternoonOnly) return AfternoonOnly(polyglot).value;
      return FullDay(polyglot).value;
    },
    [polyglot]
  );
  const getEndFullOrHalfDayType = useCallback(
    (absence2) => {
      if ((absence2 == null ? void 0 : absence2.startHour) || (absence2 == null ? void 0 : absence2.endHour)) return OtherLength(polyglot).value;
      if (!(absence2 == null ? void 0 : absence2.end)) return FullDay(polyglot).value;
      if (absence2.morningOnly) return MorningOnly(polyglot).value;
      return FullDay(polyglot).value;
    },
    [polyglot]
  );
  const initialValues = useMemo(
    () => {
      var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i, _j;
      return {
        noOfEmployees: "single",
        absenceId: (_a2 = absence == null ? void 0 : absence.absenceId) != null ? _a2 : void 0,
        membersRule: UserSelectFiltersOptions.None,
        customRule: null,
        userIds: userId ? [userId] : (absence == null ? void 0 : absence.userId) ? [absence.userId] : [],
        policyId: (_c2 = (_b2 = absence == null ? void 0 : absence.policyId) != null ? _b2 : policyId) != null ? _c2 : void 0,
        multipleDays: !!(absence == null ? void 0 : absence.end) && !(absence == null ? void 0 : absence.startHour) && !(absence == null ? void 0 : absence.endHour),
        start: (absence == null ? void 0 : absence.start) ? absence.start : new LocalDate().toDateString(),
        end: (absence == null ? void 0 : absence.end) ? absence.end : null,
        startFullOrHalfDay: getStartFullOrHalfDayType(absence),
        endFullOrHalfDay: getEndFullOrHalfDayType(absence),
        notes: (_d2 = absence == null ? void 0 : absence.notes) != null ? _d2 : "",
        id: (_e2 = absence == null ? void 0 : absence.id) != null ? _e2 : void 0,
        isHourlyLoggingPolicy: isHourlyPolicy(
          absencePolicies == null ? void 0 : absencePolicies.find((p) => {
            var _a3;
            const pId = (_a3 = absence == null ? void 0 : absence.policyId) != null ? _a3 : policyId;
            return p.id === pId;
          })
        ),
        startHour: (_f2 = absence == null ? void 0 : absence.startHour) != null ? _f2 : null,
        endHour: (_g2 = absence == null ? void 0 : absence.endHour) != null ? _g2 : null,
        startHourTimestamp: (_h2 = absence == null ? void 0 : absence.startHourTimestamp) != null ? _h2 : null,
        endHourTimestamp: (_i = absence == null ? void 0 : absence.endHourTimestamp) != null ? _i : null,
        attachment: (_j = absence == null ? void 0 : absence.attachment) != null ? _j : null
      };
    },
    [userId, absence, policyId, getStartFullOrHalfDayType, getEndFullOrHalfDayType, absencePolicies]
  );
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: absenceValidationSchema(selectedPolicy, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2, _d2, _e2, _f2, _g2;
      if (values.userIds.length === 0) {
        showMessage(polyglot.t("AbsenceDrawerPage.errorMessages.userRequired"), "error");
        return;
      }
      if (!values.policyId) {
        showMessage(polyglot.t("AbsenceDrawerPage.errorMessages.policyRequired"), "error");
        return;
      }
      try {
        setIsSubmitting(true);
        const { morningOnly, afternoonOnly } = getHalfDayOptions(values, polyglot);
        const { startHour, endHour, startHourTimestamp, endHourTimestamp } = getStartAndEndHours(values, polyglot);
        const isSingleAbs = isSingleAbsence(values);
        const commonPayload = {
          policyId: values.policyId,
          start: values.start,
          end: isSingleAbs ? null : (_a2 = values.end) != null ? _a2 : null,
          morningOnly,
          afternoonOnly,
          startHour,
          endHour,
          startHourTimestamp,
          endHourTimestamp,
          notes: (_b2 = values.notes) != null ? _b2 : null,
          attachment: (_c2 = values.attachment) != null ? _c2 : null
        };
        if (usedForDataImport) {
          const policy = absencePolicies == null ? void 0 : absencePolicies.find((p) => p.id === values.policyId);
          const start = (_d2 = values.startHour) != null ? _d2 : values.start;
          const end = isSingleAbs && values.start && values.endHour ? `${values.start.slice(0, 10)}T${values.endHour.slice(11, 16)}` : (_f2 = (_e2 = values.endHour) != null ? _e2 : values.end) != null ? _f2 : null;
          importHandler == null ? void 0 : importHandler(
            __spreadProps(__spreadValues({}, commonPayload), {
              policyName: (_g2 = policy == null ? void 0 : policy.name) != null ? _g2 : "",
              userId: values.userIds[0],
              morningOnly: morningOnly ? "Yes" : "No",
              afternoonOnly: afternoonOnly ? "Yes" : "No",
              start,
              end,
              id: values.id,
              startHourTimestamp: null,
              endHourTimestamp: null
            }),
            policy
          );
        } else if (absence == null ? void 0 : absence.absenceId) {
          const updatePayload = __spreadProps(__spreadValues({}, commonPayload), {
            userId: values.userIds[0]
          });
          yield AbsenceAPI.updateAbsenceRecord(absence.absenceId, updatePayload);
          showMessage(polyglot.t("AbsenceDrawerPage.successMessages.update"), "success");
          if (refresh) yield refresh(commonPayload.policyId);
          closePage();
        } else {
          const createPayload = __spreadProps(__spreadValues({}, commonPayload), {
            userIds: values.userIds,
            membersRule: values.noOfEmployees === "single" ? UserSelectFiltersOptions.SelectSpecific : values.membersRule,
            customRule: values.noOfEmployees === "single" ? null : values.customRule
          });
          const response = yield AbsenceAPI.addMultipleAbsenceRecord(createPayload);
          if (response.success && response.method === "direct") {
            if (response.noOfCreatedAbsences === 0)
              showMessage(polyglot.t("AbsenceDrawerPage.errorMessages.noAbsenceHasBeenCreated"), "error");
            else if (response.noOfCreatedAbsences === 1 && createPayload.userIds.length === 1)
              showMessage(polyglot.t("AbsenceDrawerPage.successMessages.register"), "success");
            else showMessage(polyglot.t("AbsenceDrawerPage.successMessages.registerMultiple"), "success");
          } else if (response.success && response.method === "queue") {
            showMessage(polyglot.t("AbsenceDrawerPage.successMessages.absencesQueued"), "success");
          }
          if (response.noOfCreatedAbsences !== 0) {
            if (refresh) yield refresh(commonPayload.policyId);
            closePage();
          }
        }
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceDrawerPage.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  useEffect(() => {
    const policy = absencePolicies == null ? void 0 : absencePolicies.find((p) => p.id === formik.values.policyId);
    setSelectedPolicy(policy != null ? policy : null);
  }, [absencePolicies, formik.values.policyId]);
  const [
    requestAndRemainingBalanceDetails,
    setRequestAndRemainingBalanceDetails
  ] = useState(null);
  const getRequestAndRemainingBalanceDetails = useDebouncedCallback((values) => __async(void 0, null, function* () {
    var _a2, _b2;
    const isValid = validateGetBalance(values);
    if (!values.policyId || !isValid) {
      setRequestAndRemainingBalanceDetails(null);
      return;
    }
    try {
      const { morningOnly, afternoonOnly } = getHalfDayOptions(values, polyglot);
      const { startHour, endHour, startHourTimestamp, endHourTimestamp } = getStartAndEndHours(values, polyglot);
      const isSingleAbs = isSingleAbsence(values);
      const balanceImpactPayload = {
        id: (_a2 = absence == null ? void 0 : absence.absenceId) != null ? _a2 : void 0,
        userIds: values.userIds,
        policyId: values.policyId,
        start: values.start,
        end: isSingleAbs ? null : (_b2 = values.end) != null ? _b2 : null,
        notes: values.notes,
        attachment: values.attachment,
        morningOnly,
        afternoonOnly,
        startHour,
        endHour,
        startHourTimestamp,
        endHourTimestamp,
        membersRule: UserSelectFiltersOptions.SelectSpecific,
        customRule: null
      };
      const data = yield AbsenceAPI.getNewAbsenceBalanceImpactDetails(balanceImpactPayload);
      setRequestAndRemainingBalanceDetails(data != null ? data : null);
    } catch (error) {
      setRequestAndRemainingBalanceDetails(null);
    }
  }), 200);
  const isActiveMemberOfSelectedPolicy = useMemo(() => {
    return Boolean(
      formik.values.policyId && absencePolicies && absencePolicies.some((p) => p.id === formik.values.policyId)
    );
  }, [formik.values.policyId, absencePolicies]);
  const showAttachmentSection = useMemo(
    () => {
      var _a2;
      return Boolean(
        formik.values.policyId && absencePolicies && ((_a2 = absencePolicies.find((p) => p.id === formik.values.policyId)) == null ? void 0 : _a2.attachmentType)
      );
    },
    [formik.values.policyId, absencePolicies]
  );
  useEffect(() => {
    if (isActiveMemberOfSelectedPolicy) getRequestAndRemainingBalanceDetails.callback(formik.values);
  }, [getRequestAndRemainingBalanceDetails, isActiveMemberOfSelectedPolicy, formik.values]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: absence ? polyglot.t("AbsenceDrawerPage.edit") : polyglot.t("AbsenceDrawerPage.newRequest") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "policyId",
        label: polyglot.t("AbsenceDrawerPage.policyId"),
        options: (absencePolicies != null ? absencePolicies : []).map((policy) => ({
          label: policy.name,
          value: policy.id
        })),
        value: formik.values.policyId,
        compareValue: formik.values.policyId,
        onChange: (e) => {
          formik.handleChange(e);
          const policyId2 = e.target.value;
          const policy = absencePolicies == null ? void 0 : absencePolicies.find((p) => p.id === policyId2);
          const isHourly = isHourlyPolicy(policy);
          formik.setFieldValue("isHourlyLoggingPolicy", isHourly);
          formik.setFieldValue("userIds", absence ? [absence.userId] : userId ? [userId] : []);
          if (isHourly) {
            formik.setFieldValue("endFullOrHalfDay", false);
            formik.setFieldValue("startFullOrHalfDay", false);
            formik.setFieldValue("multipleDays", false);
            formik.setFieldValue("end", null);
          } else {
            formik.setFieldValue("startHour", null);
            formik.setFieldValue("endHour", null);
            formik.setFieldValue("startHourTimestamp", null);
            formik.setFieldValue("endHourTimestamp", null);
          }
        },
        error: Boolean(
          !!formik.errors.policyId && formik.touched.policyId || !isActiveMemberOfSelectedPolicy && formik.values.policyId
        ),
        helperText: formik.touched.policyId && formik.errors.policyId || (!isActiveMemberOfSelectedPolicy && formik.values.policyId ? "Not an active member of this policy anymore" : "")
      }
    ),
    !userId && !absence && isManager && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: formik.values.noOfEmployees !== "multiple" ? "active" : "secondary",
          onClick: () => {
            formik.setFieldValue("noOfEmployees", "single");
          },
          children: polyglot.t("AbsenceDrawerPage.single")
        },
        "single-employees"
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: formik.values.noOfEmployees === "multiple" ? "active" : "secondary",
          onClick: () => {
            formik.setFieldValue("noOfEmployees", "multiple");
          },
          children: polyglot.t("AbsenceDrawerPage.multipleEmployees")
        },
        "multiple-employees"
      )
    ] }),
    !userId && !absence && formik.values.noOfEmployees === "single" && /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userIds",
        options: reach,
        allValidUserIds: (_a = selectedPolicy == null ? void 0 : selectedPolicy.selectedMembersIds) != null ? _a : [],
        onChange: (_, x) => {
          formik.setFieldValue("userIds", (x == null ? void 0 : x.value) ? [Number(x.value)] : []);
        },
        value: formik.values.userIds[0],
        label: polyglot.t("AbsenceDrawerPage.employee"),
        error: formik.touched.userIds && Boolean(formik.errors.userIds),
        helperText: formik.touched.userIds && formik.errors.userIds,
        disabled: !selectedPolicy
      }
    ),
    !userId && !absence && formik.values.noOfEmployees === "multiple" && /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: polyglot.t("EditPolicyMembersDrawerContent.whoSelect"),
        selectedLabel: polyglot.t("EditPolicyMembersDrawerContent.selectedMembers"),
        value: formik.values.userIds,
        onChange: (userIds, filterValue, customRule) => {
          formik.setFieldValue("userIds", userIds);
          setValidationMessage("");
          formik.setFieldValue("membersRule", filterValue != null ? filterValue : UserSelectFiltersOptions.None);
          formik.setFieldValue("customRule", customRule != null ? customRule : "");
        },
        fieldSx: __spreadValues({}, spacing.mb20),
        error: !!validationMessage,
        helperText: !!validationMessage && validationMessage,
        initialFilterValue: UserSelectFiltersOptions.None,
        ruleString: formik.values.customRule,
        allValidUserIds: (_b = selectedPolicy == null ? void 0 : selectedPolicy.selectedMembersIds) != null ? _b : [],
        excludeEveryone: true,
        excludeCustomRule: true,
        disabled: !selectedPolicy
      }
    ),
    !userId && absence && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: polyglot.t("AbsenceDrawerPage.employee") }),
      /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m5, display: "flex", alignItem: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(UserAvatar, { userId: absence.userId, size: "s20" }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: user ? polyglot.t(user.displayName || `${(_c = user.firstName) != null ? _c : ""} ${(_d = user.lastName) != null ? _d : ""}`) : "" })
      ] })
    ] }),
    ((_e = selectedPolicy == null ? void 0 : selectedPolicy.instructions) == null ? void 0 : _e.length) && /* @__PURE__ */ jsx(SideAccentedTextLabel, { text: selectedPolicy.instructions, borderColor: selectedPolicy == null ? void 0 : selectedPolicy.color }),
    !formik.values.isHourlyLoggingPolicy && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: !formik.values.multipleDays ? "active" : "secondary",
          onClick: () => {
            formik.setFieldValue("multipleDays", false);
            formik.setFieldValue("end", null);
          },
          children: polyglot.t("AbsenceDrawerPage.oneDay")
        },
        "unlimited-allowance"
      ),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: formik.values.multipleDays ? "active" : "secondary",
          onClick: () => {
            formik.setFieldValue("multipleDays", true);
            formik.setFieldValue("end", getNextDateFromBEStringDate(formik.values.start));
            if (formik.values.startFullOrHalfDay === "otherLength")
              formik.setFieldValue("startFullOrHalfDay", "full");
          },
          children: polyglot.t("AbsenceDrawerPage.multipleDays")
        },
        "limited-allowance"
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_f = formik.values.start) != null ? _f : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("start", value);
              if (formik.values.multipleDays && (!formik.values.end || formik.values.end < value)) {
                formik.setFieldValue("end", getNextDateFromBEStringDate(value));
              }
            }
          },
          name: "start",
          label: formik.values.isHourlyLoggingPolicy ? polyglot.t("AbsenceDrawerPage.date") : polyglot.t("AbsenceDrawerPage.startDate"),
          error: !!formik.errors.start && Boolean(formik.touched.start),
          helperText: formik.errors.start && Boolean(formik.touched.start)
        }
      ) }),
      !formik.values.isHourlyLoggingPolicy && /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "startFullOrHalfDay",
          label: polyglot.t("AbsenceDrawerPage.length"),
          options: formik.values.multipleDays ? fullOrAfternoonOnlyValues(polyglot) : fullOrHalfDayValues(polyglot),
          value: formik.values.startFullOrHalfDay,
          compareValue: formik.values.startFullOrHalfDay,
          error: !!formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay,
          onChange: formik.handleChange,
          helperText: formik.errors.startFullOrHalfDay && formik.touched.startFullOrHalfDay
        }
      ) })
    ] }),
    !formik.values.isHourlyLoggingPolicy && formik.values.multipleDays && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_g = formik.values.end) != null ? _g : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("end", value);
            }
          },
          name: "end",
          label: polyglot.t("AbsenceDrawerPage.end"),
          error: !!formik.errors.end && Boolean(formik.touched.end),
          helperText: formik.errors.end && Boolean(formik.touched.end)
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "endFullOrHalfDay",
          label: polyglot.t("AbsenceDrawerPage.endFullOrHalfDay"),
          options: formik.values.multipleDays ? fullOrMorningOnlyValues(polyglot) : fullOrHalfDayValues(polyglot),
          value: formik.values.endFullOrHalfDay,
          compareValue: formik.values.endFullOrHalfDay,
          onChange: formik.handleChange,
          error: !!formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay,
          helperText: formik.errors.endFullOrHalfDay && formik.touched.endFullOrHalfDay
        }
      ) })
    ] }),
    (formik.values.isHourlyLoggingPolicy || formik.values.startFullOrHalfDay === "otherLength") && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(
        TimePickerComponent,
        {
          textFieldKey: "startHour",
          label: polyglot.t("AbsenceDrawerPage.startHour"),
          value: formik.values.startHour,
          onChange: (event) => {
            const time = event.target.value;
            if (isValidTimeString(time)) {
              const date = new LocalDate(`${formik.values.start}T${time}:00`);
              formik.setFieldValue("startHour", date.toFullString());
              formik.setFieldValue("startHourTimestamp", date.getDate());
            }
          },
          fullWidth: true,
          error: !!formik.errors.startHour && formik.touched.startHour,
          helperText: formik.touched.startHour ? formik.errors.startHour : void 0
        }
      ),
      /* @__PURE__ */ jsx(
        TimePickerComponent,
        {
          textFieldKey: "endHour",
          label: polyglot.t("AbsenceDrawerPage.endHour"),
          value: formik.values.endHour,
          onChange: (event) => {
            const time = event.target.value;
            if (isValidTimeString(time)) {
              const date = new LocalDate(`${formik.values.start}T${time}:00`);
              formik.setFieldValue("endHour", date.toFullString());
              formik.setFieldValue("endHourTimestamp", date.getDate());
            }
          },
          fullWidth: true,
          error: !!formik.errors.endHour && formik.touched.endHour,
          helperText: formik.touched.endHour ? formik.errors.endHour : void 0
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "notes",
        label: polyglot.t("AbsenceDrawerPage.notes"),
        value: formik.values.notes,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.notes && Boolean(formik.errors.notes),
        helperText: formik.touched.notes && formik.errors.notes,
        clearText: () => formik.setFieldValue("notes", "")
      }
    ),
    showAttachmentSection && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Attachment" }),
      formik.values.attachment && receiptFilename ? /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between"
          },
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
              /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
              /* @__PURE__ */ jsx(
                Box,
                {
                  component: "span",
                  sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { marginLeft: spacing.m10, color: themeColors.DarkGrey }),
                  children: receiptFilename
                }
              )
            ] }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => formik.setFieldValue("attachment", null),
                children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ) : /* @__PURE__ */ jsx(
        UploadInput,
        {
          displayText: formik.touched.attachment ? formik.errors.attachment : void 0,
          displayTextColor: "RedDark",
          displayTextVariant: "paragraphSmall",
          onChange: (resp) => {
            formik.setFieldValue("attachment", resp == null ? void 0 : resp.uuid);
            let filename = resp == null ? void 0 : resp.fileName;
            filename = filename == null ? void 0 : filename.substring((filename == null ? void 0 : filename.lastIndexOf("/")) + 1);
            setReceiptFilename(filename != null ? filename : "");
          }
        }
      )
    ] }),
    requestAndRemainingBalanceDetails && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          transition: "all 0.9s ease",
          transitionProperty: "opacity, margin-bottom",
          opacity: requestAndRemainingBalanceDetails ? 1 : 0
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: "5px", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AbsenceModule.thisRequest") }),
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: requestAndRemainingBalanceDetails.unit === "minute" ? convertMinutesToClockHours(requestAndRemainingBalanceDetails.requestValue, polyglot) : polyglot.t("General.noOfDays", {
              smart_count: requestAndRemainingBalanceDetails.requestValue
            }) })
          ] }),
          requestAndRemainingBalanceDetails.remainingBalance !== null && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: "5px", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("AbsenceModule.remainingBalance") }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "headline3",
                color: requestAndRemainingBalanceDetails.remainingBalance < 0 ? "RedDark" : "DarkGrey",
                children: requestAndRemainingBalanceDetails.unit === "minute" ? convertMinutesToClockHours(requestAndRemainingBalanceDetails.remainingBalance, polyglot) : polyglot.t("General.noOfDays", {
                  smart_count: requestAndRemainingBalanceDetails.remainingBalance
                })
              }
            )
          ] })
        ]
      }
    ),
    formik.values.userIds && /* @__PURE__ */ jsx(
      AbsenceOverlapDisplay,
      {
        absenceStart: formik.values.start,
        absenceEnd: (_h = formik.values.end) != null ? _h : null,
        absenceId: (absence == null ? void 0 : absence.absenceId) || null,
        showCalendarLink: true,
        userIds: formik.values.userIds,
        isViewMode: false
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      absence && /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "medium", fullWidth: true, onClick: closePage, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          sizeVariant: "medium",
          loading: isSubmitting,
          colorVariant: "primary",
          fullWidth: true
        }
      )
    ] })
  ] }) });
};
