"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { HolidayCalendarAPI } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE } from "@/lib/routes";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const DeleteHolidayCalendarDrawer = ({ isOpen, setIsOpen, holidayCalendar, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(HolidayDrawerContent, { holidayCalendar, refresh, setIsOpen })
    }
  ) });
};
const HolidayDrawerContent = ({ holidayCalendar, refresh, setIsOpen }) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const deleteHolidayCalendar = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield HolidayCalendarAPI.deleteHolidayCalendar(holidayCalendar.id);
      yield refresh();
      setIsOpen(false);
      history.push(SETTINGS_TIME_HOLIDAY_CALENDARS_ROUTE);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setLoading(false);
  }), [history, showMessage, polyglot, refresh, holidayCalendar, setIsOpen]);
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("HolidayCalendarModule.deleteCalendar") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("HolidayCalendarModule.deleteCalendarConfirmation", { name: holidayCalendar.name }) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.delete"),
          onClick: deleteHolidayCalendar,
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "danger"
        }
      )
    ] })
  ] });
};
