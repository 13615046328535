"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  TitleStatusComponent,
  getParticipantProgressStatus
} from "@v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { format } from "date-fns";
import { keyBy } from "lodash";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { useEventSource } from "@/hooks/event-source-hook";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/Action.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE,
  REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE
} from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { ProgressBar } from "@/v2/components/progress-bar.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import {
  StyledMenuComponent
} from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ReviewAnswerAPI } from "@/v2/feature/growth/reviews/api-client/review-answer.api";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import { ReviewEndpoints } from "@/v2/feature/growth/reviews/api-client/review.api";
import { ReviewBulkPublishModal } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/components/review-bulk-publish-modal.component";
import { DATE_FORMAT } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import {
  ReminderType,
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { eventSourceErrorHandler } from "@/v2/infrastructure/push-events/push-events.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { selectColumnSize } from "@/v2/styles/table.styles";
import { LocalDate } from "@/v2/util/local-date";
export const ReviewCycleDetailResultsPage = ({
  reviewCycle,
  cycleLoading,
  reach
}) => {
  var _a, _b, _c;
  const params = useParams();
  const { polyglot } = usePolyglot();
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const companyId = state.user.company.companyId;
  const channelName = `review-${environmentPrefix}-company_id-${companyId}`;
  const { hasScopes } = useScopes();
  const scopesContext = { userId: user.userId };
  const isAdmin = hasScopes(["reviews:all"], scopesContext);
  const cycleId = params.cycleId;
  const { data: progress, isLoading: loadingProgress, mutate: refreshProgress } = useApiClient(
    ReviewResultEndpoints.getReviewProgressByCycleId(cycleId, reach),
    {
      suspense: false
    }
  );
  const { data: participation, isLoading: loadingParticipation } = useApiClient(
    ReviewResultEndpoints.getReviewParticipationByCycleId(cycleId, reach),
    {
      suspense: false
    }
  );
  const [selectionModel, setSelectionModel] = useState([]);
  const [bulkPublishOpen, setBulkPublishOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { cachedUsers } = useCachedUsers();
  const [showMessage] = useMessage();
  const reviewUpdatedDataHandler = (data) => {
    if (data.message && data.companyId === companyId) {
      setSelectionModel([]);
      refreshProgress == null ? void 0 : refreshProgress();
      return;
    }
  };
  useEventSource((_a = ReviewEndpoints.getEventSourceUrlForBulkPublishReviews(channelName)) == null ? void 0 : _a.url, {
    onMessage: reviewUpdatedDataHandler,
    onError: eventSourceErrorHandler
  });
  const filteredData = useMemo(() => {
    if (!progress) return [];
    if (!searchQuery) return progress;
    const userMap = keyBy(cachedUsers, "userId");
    return progress.filter(
      (p) => {
        var _a2, _b2, _c2;
        return Boolean((_c2 = (_b2 = (_a2 = userMap[p.participant]) == null ? void 0 : _a2.displayName) == null ? void 0 : _b2.toLocaleLowerCase()) == null ? void 0 : _c2.includes(searchQuery == null ? void 0 : searchQuery.toLocaleLowerCase()));
      }
    );
  }, [cachedUsers, progress, searchQuery]);
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType: reach }),
      noHorizontalPadding: true,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      loading: cycleLoading || loadingParticipation,
      buttons: [
        isAdmin && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "secondary",
            sizeVariant: "small",
            onClick: () => __async(void 0, null, function* () {
              try {
                const response = yield ReviewAnswerAPI.getBulkAnswerExport(reviewCycle.id);
                const filename = `${reviewCycle.internalName}.csv`;
                const blob = new Blob([response], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              } catch (error) {
                showMessage(
                  polyglot.t("ReviewCycleDetailResultsPage.errorMessages.export", {
                    errorMessage: nestErrorMessage(error)
                  }),
                  "error"
                );
              }
            }),
            children: polyglot.t("ReviewCycleDetailResultsPage.exportAll")
          }
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            contentSx: { px: spacing.px16 },
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                title: /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("ReviewCycleDetailResultsPage.overview") }),
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("ReviewCycleDetailResultsPage.reviewCycleStartedOn"),
                    value: format(new LocalDate(reviewCycle.timelineSettings.startDate).getDate(), DATE_FORMAT)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("ReviewCycleDetailResultsPage.participants"),
                    value: `${reviewCycle.revieweeId.length} participants`
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ProgressBar,
                      {
                        progress: (_b = participation == null ? void 0 : participation.participant) != null ? _b : 0,
                        label: `${(_c = participation == null ? void 0 : participation.participant) != null ? _c : 0}%`
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            contentSx: { px: spacing.px16 },
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                title: /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("ReviewCycleDetailResultsPage.timeline") }),
                hidden: Boolean(reviewCycle.reviewerSelect.length === 0),
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(ReviewerProgress, { reviewCycle, participation })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                title: /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("ReviewCycleDetailResultsPage.results") }),
                headerSx: { px: spacing.px16, boxSizing: "border-box" },
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ReviewEntriesListComponent,
                      {
                        reviewCycle,
                        progress: filteredData,
                        loadingProgress,
                        setSelectionModel,
                        selectionModel,
                        reach,
                        setSearchQuery,
                        searchQuery,
                        setBulkPublishOpen
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          ReviewBulkPublishModal,
          {
            reviewCycle,
            isOpen: bulkPublishOpen,
            setIsOpen: setBulkPublishOpen,
            onClose: () => {
              setBulkPublishOpen(false);
            },
            refresh: refreshProgress,
            participantIds: selectionModel
          }
        )
      ]
    }
  );
};
const ReviewEntriesListComponent = ({
  reviewCycle,
  progress,
  loadingProgress,
  setSelectionModel,
  selectionModel,
  reach,
  setSearchQuery,
  searchQuery,
  setBulkPublishOpen
}) => {
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const showSelectAll = useMemo(() => {
    if (reviewCycle.state === CycleState.Completed || !progress || progress.length === 0) {
      return false;
    }
    return progress.some((row) => {
      const isReviewIncomplete = row.status.manager.total !== row.status.manager.complete || row.status.peer.total !== row.status.peer.complete || row.status.self.show && !row.status.self.complete || row.status.upward.total !== row.status.upward.complete;
      const isResultNotShared = !row.isResultShared;
      return isReviewIncomplete || isResultNotShared;
    });
  }, [reviewCycle.state, progress]);
  const tableColumns = useMemo(
    () => [
      ...showSelectAll ? [
        {
          id: "select",
          minSize: selectColumnSize,
          size: selectColumnSize,
          enableSorting: false,
          header: () => {
            const selectableParticipants = new Set(
              progress == null ? void 0 : progress.filter((row) => {
                const isReviewIncomplete = row.status.manager.total !== row.status.manager.complete || row.status.peer.total !== row.status.peer.complete || row.status.self.show && !row.status.self.complete || row.status.upward.total !== row.status.upward.complete;
                const isResultNotShared = !row.isResultShared;
                return isReviewIncomplete || isResultNotShared;
              }).map(({ participant }) => participant)
            );
            const allSelected = selectionModel.length > 0 && selectionModel.length === selectableParticipants.size && selectionModel.every((id) => selectableParticipants.has(id));
            return /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: "allSelected",
                checked: allSelected,
                value: "allSelceted",
                onChange: (_, checked) => {
                  setSelectionModel(checked ? [...selectableParticipants] : []);
                }
              }
            );
          },
          cell: ({ row: { original } }) => {
            var _a, _b;
            const isReviewIncomplete = original.status.manager.total !== original.status.manager.complete || original.status.peer.total !== original.status.peer.complete || original.status.self.show && !original.status.self.complete || original.status.upward.total !== original.status.upward.complete;
            const isResultNotShared = !original.isResultShared;
            return isReviewIncomplete || isResultNotShared ? /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: (_a = original.participant) == null ? void 0 : _a.toString(),
                checked: selectionModel.includes(original.participant),
                value: (_b = original.participant) == null ? void 0 : _b.toString(),
                onChange: () => {
                  let finalArray = [];
                  if (selectionModel == null ? void 0 : selectionModel.includes(original.participant)) {
                    finalArray = selectionModel.filter((sm) => sm !== original.participant);
                  } else finalArray = [...selectionModel, original.participant];
                  setSelectionModel(finalArray);
                }
              }
            ) : /* @__PURE__ */ jsx(Fragment, {});
          }
        }
      ] : [],
      {
        header: () => polyglot.t("ReviewEntriesListComponent.participants"),
        accessorFn: (row) => row,
        id: "participants",
        enableSorting: false,
        cell: ({
          row: {
            original: { participant }
          }
        }) => participant ? /* @__PURE__ */ jsx(UserCell, { userId: participant }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("ReviewEntriesListComponent.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => getParticipantProgressStatus(original, reviewCycle),
        maxSize: 200
      },
      {
        header: () => polyglot.t("ReviewEntriesListComponent.result"),
        accessorFn: (row) => row,
        id: "isResultShared",
        enableSorting: false,
        cell: ({
          row: {
            original: { isResultShared }
          }
        }) => isResultShared ? /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(ChipComponent, { name: "Published", border: "background" }) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      ...reviewCycle.state !== CycleState.Completed ? [
        {
          id: "action",
          header: () => "",
          accessorFn: (row) => row,
          enableSorting: false,
          cell: ({ row: { original } }) => {
            const isReviewIncomplete = original.status.manager.total !== original.status.manager.complete || original.status.peer.total !== original.status.peer.complete || original.status.self.show && !original.status.self.complete || original.status.upward.total !== original.status.upward.complete;
            return isReviewIncomplete || !original.isResultShared ? /* @__PURE__ */ jsx(
              "div",
              {
                style: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                },
                children: /* @__PURE__ */ jsx(
                  ActionMenu,
                  {
                    participantIds: [original.participant],
                    setSelectionModel,
                    reviewCycle,
                    type: "table",
                    progress,
                    setBulkPublishOpen
                  }
                )
              }
            ) : null;
          }
        }
      ] : []
    ],
    [setSelectionModel, setBulkPublishOpen, showSelectAll, reviewCycle, progress, selectionModel, polyglot]
  );
  const handleRowClick = useCallback(
    (row) => {
      var _a, _b;
      const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_DETAIL_RESULT_DETAIL_ROUTE : REVIEWS_TEAM_ONGOING_DETAIL_RESULT_DETAIL_ROUTE;
      routerHistory.push(
        generatePath(reachRoute, {
          revieweeId: (_b = (_a = row.original) == null ? void 0 : _a.participant) != null ? _b : "",
          cycleId: reviewCycle.id
        })
      );
    },
    [routerHistory, reviewCycle, reach]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: { px: spacing.px16 }, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          boxSizing: "border-box"
        },
        children: [
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchQuery,
              handleChange: (e) => {
                setSearchQuery(e.target.value);
              }
            }
          ),
          reviewCycle.state !== CycleState.Completed && selectionModel.length > 0 && /* @__PURE__ */ jsx(
            ActionMenu,
            {
              participantIds: selectionModel,
              setSelectionModel,
              reviewCycle,
              type: "page",
              progress,
              setBulkPublishOpen
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: progress != null ? progress : [],
        columnData: tableColumns,
        loading: loadingProgress,
        paginationSx: { px: spacing.px16, boxSizing: "border-box" },
        rowClick: handleRowClick,
        fixedSecondColumn: !!tableColumns.find((c) => c.id === "select")
      }
    )
  ] });
};
const ActionMenu = ({
  participantIds,
  reviewCycle,
  type,
  progress,
  setBulkPublishOpen,
  setSelectionModel
}) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const progressByParticipant = keyBy(progress, "participant");
  const getActionsOptions = useCallback(() => {
    const allReviewsAreNotCompleted = participantIds.every((id) => {
      const progress2 = progressByParticipant[id];
      if (!progress2) return false;
      const isReviewCompleted = progress2.status.manager.total === progress2.status.manager.complete && progress2.status.peer.total === progress2.status.peer.complete && (!progress2.status.self.show || progress2.status.self.complete) && progress2.status.upward.total === progress2.status.upward.complete;
      return !isReviewCompleted;
    });
    let menuOptions = [];
    if (allReviewsAreNotCompleted) {
      menuOptions.push(
        {
          handler: () => __async(void 0, null, function* () {
            try {
              const remindBody = { cycleId: reviewCycle.id, revieweeIds: participantIds };
              yield ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.All, remindBody);
              showMessage(polyglot.t("ReviewEntriesListActionMenu.successMessages.reminders"), "success");
            } catch (error) {
              showMessage(
                polyglot.t("ReviewEntriesListActionMenu.errorMessages.reminders", {
                  nestErrorMessage: nestErrorMessage(error)
                }),
                "error"
              );
            }
          }),
          label: "Remind all",
          disabled: false
        },
        {
          handler: () => __async(void 0, null, function* () {
            try {
              const remindBody = { cycleId: reviewCycle.id, revieweeIds: participantIds };
              yield ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.Overdue, remindBody);
              showMessage(polyglot.t("ReviewEntriesListActionMenu.successMessages.reminders"), "success");
            } catch (error) {
              showMessage(
                polyglot.t("ReviewEntriesListActionMenu.errorMessages.reminders", {
                  nestErrorMessage: nestErrorMessage(error)
                }),
                "error"
              );
            }
          }),
          label: "Remind overdue",
          disabled: false
        }
      );
    }
    const allParticipantsNotShared = participantIds.every(
      (id) => progressByParticipant[id] && !progressByParticipant[id].isResultShared
    );
    const atLeastOneReviewCompleted = participantIds.every((id) => {
      const progress2 = progressByParticipant[id];
      if (!progress2) return false;
      return progress2.status.manager.complete > 0 || progress2.status.peer.complete > 0 || progress2.status.self.show && progress2.status.self.complete || progress2.status.upward.complete > 0;
    });
    if (allParticipantsNotShared && atLeastOneReviewCompleted) {
      menuOptions.push({
        handler: () => {
          setSelectionModel(participantIds);
          setBulkPublishOpen(true);
        },
        label: "Publish results",
        disabled: false
      });
    }
    return menuOptions;
  }, [
    reviewCycle.id,
    showMessage,
    setSelectionModel,
    setBulkPublishOpen,
    participantIds,
    progressByParticipant,
    polyglot
  ]);
  const actionButtonDetails = type === "page" ? {
    type: "button",
    colorVariant: "secondary",
    sizeVariant: "small",
    title: "Actions",
    icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
    iconPosition: "end"
  } : {
    type: "iconButton",
    colorVariant: "secondary",
    sizeVariant: "small",
    title: "actions",
    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
  };
  return /* @__PURE__ */ jsx(StyledMenuComponent, { options: getActionsOptions(), actionButtonDetails });
};
const containerStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  boxSizing: "border-box",
  gap: "16px",
  justifyContent: "space-between",
  alignItems: "center"
};
const ReviewerProgress = ({
  reviewCycle,
  participation
}) => {
  const generateReviews = useCallback(
    (reviewCycle2, participation2) => {
      if (!reviewCycle2 || !participation2) return [];
      return Object.values(ReviewerTypes).map((type) => {
        var _a;
        const progress = participation2 == null ? void 0 : participation2[type.toLowerCase()];
        return {
          label: `${type} review `,
          completion: (_a = progress == null ? void 0 : progress.percent) != null ? _a : 0,
          isHidden: !reviewCycle2.reviewerSelect.includes(type) || (progress == null ? void 0 : progress.percent) === null,
          isOverdue: reviewCycle2.state === CycleState.Ongoing && Boolean((progress == null ? void 0 : progress.percent) ? progress.percent < 100 : true) && (progress == null ? void 0 : progress.overdue)
        };
      });
    },
    []
  );
  const reviews = useMemo(() => generateReviews(reviewCycle, participation), [
    participation,
    reviewCycle,
    generateReviews
  ]);
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(Box, { sx: containerStyle, children: reviews.map((review, index) => {
    if (review.isHidden) return null;
    return /* @__PURE__ */ jsx("div", { style: { flex: "1", minWidth: "200px" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: review.label }),
      /* @__PURE__ */ jsx(ProgressBar, { label: `${review.completion}%`, progress: review.completion, showRed: review.isOverdue })
    ] }) }, index);
  }) });
};
