"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { SubscriptionsTable } from "@v2/feature/billing/components/subscriptions-table.component";
import { UpdateSubscriptionDrawer } from "@v2/feature/billing/components/update-subscription-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { parentCardSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SubscriptionSection = ({
  subscriptions,
  subscriptionsBillingStats,
  subscriptionsStats,
  downgradeViability,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [isUpdateDrawerOpen, setIsUpdateDrawerOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Box, { sx: parentCardSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", mt: 1 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("SubscriptionSection.subscription") }),
      !isUpdateDrawerOpen && /* @__PURE__ */ jsx(IconButton, { title: "Update subscription", sx: tableIconButtonSx, onClick: () => setIsUpdateDrawerOpen(true), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({ my: spacing.m10 }, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: polyglot.t("SubscriptionSection.description") }),
    /* @__PURE__ */ jsx(SubscriptionsTable, { subscriptionsBillingStats }),
    isUpdateDrawerOpen && /* @__PURE__ */ jsx(
      UpdateSubscriptionDrawer,
      {
        isOpen: isUpdateDrawerOpen,
        setIsOpen: setIsUpdateDrawerOpen,
        subscriptions,
        subscriptionsStats,
        subscriptionsBillingStats,
        downgradeViability,
        refresh
      }
    )
  ] });
};
