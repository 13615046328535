"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { IconButton } from "@v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { AddApprovalStepDrawer } from "@v2/feature/approval-rule/approval-rule-settings/components/add-approval-step-drawer.component";
import { ApprovalRuleAPI } from "@v2/feature/approval-rule/approval-rule.api";
import { DefaultApprovalRule } from "@v2/feature/approval-rule/approval-rule.interface";
import { getApprovalStepSchema, getStepDescription } from "@v2/feature/approval-rule/approval-rule.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateDefaultApprovalRule } from "@v2/infrastructure/i18n/translate.util";
import { borders } from "@v2/styles/borders.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { Typography } from "@/v2/components/typography/typography.component";
const getValidationSchema = (polyglot) => yup.object({
  name: yup.string().notOneOf(Object.values(DefaultApprovalRule), polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
  description: yup.string().notRequired(),
  approvalSteps: yup.array().of(getApprovalStepSchema(polyglot)).required(polyglot.t("ValidationMessages.requiredField"))
});
export const ApprovalRuleDrawer = ({ isOpen, setIsOpen, approvalRule, afterClose, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDeleteOpened, setIsDeleteOpened] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteApprovalRule = useCallback(() => __async(void 0, null, function* () {
    if (!approvalRule) return;
    try {
      setDeleteLoading(true);
      yield ApprovalRuleAPI.deleteApprovalRule(approvalRule.id);
      showMessage(
        polyglot.t("ApprovalRuleDeleteDrawer.successMessages.delete", { approvalRuleName: approvalRule.name }),
        "success"
      );
      yield refresh();
      setIsOpen(false);
      setIsDeleteOpened(false);
    } catch (error) {
      showMessage(
        polyglot.t("ApprovalRuleDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  }), [refresh, showMessage, polyglot, approvalRule, setIsOpen]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose, children: (approvalRule == null ? void 0 : approvalRule.isDefault) ? /* @__PURE__ */ jsx(DefaultApprovalRuleDrawerContent, { approvalRule }) : /* @__PURE__ */ jsx(
      ApprovalRuleDrawerContent,
      {
        approvalRule,
        refresh,
        setIsOpen,
        openDeleteDrawer: () => setIsDeleteOpened(true)
      }
    ) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("ApprovalRuleDeleteDrawer.title"),
        description: polyglot.t("ApprovalRuleDeleteDrawer.confirmDeletion", {
          approvalRuleName: (_a = approvalRule == null ? void 0 : approvalRule.name) != null ? _a : ""
        }),
        isOpen: isDeleteOpened,
        setIsOpen: setIsDeleteOpened,
        loading: deleteLoading,
        action: deleteApprovalRule,
        afterClose
      }
    )
  ] });
};
const ApprovalRuleDrawerContent = ({
  approvalRule,
  refresh,
  setIsOpen,
  openDeleteDrawer
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const [isAddStepOpen, setIsAddStepOpen] = useState(false);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        const ruleData = {
          name: values.name,
          description: values.description,
          approvalSteps: values.approvalSteps
        };
        setLoading(true);
        if (approvalRule) yield ApprovalRuleAPI.updateApprovalRule(approvalRule.id, ruleData);
        else yield ApprovalRuleAPI.addApprovalRule(ruleData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AttendanceScheduleEditSettingsDrawer.errorMessages.update", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [approvalRule, showMessage, refresh, setIsOpen, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      name: (_a = approvalRule == null ? void 0 : approvalRule.name) != null ? _a : "",
      description: (_b = approvalRule == null ? void 0 : approvalRule.description) != null ? _b : "",
      approvalSteps: (_c = approvalRule == null ? void 0 : approvalRule.approvalSteps) != null ? _c : []
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit
  });
  const deleteApprovalStep = (stepIndex) => {
    formik.setFieldValue(
      "approvalSteps",
      formik.values.approvalSteps.filter((_, index) => index !== stepIndex)
    );
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: approvalRule ? polyglot.t("ApprovalRuleModule.approvalRule") : polyglot.t("ApprovalRuleModule.newRule") }),
      approvalRule && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: openDeleteDrawer, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }, "delete")
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("General.name"),
        name: "name",
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_d = formik.touched.name && formik.errors.name) != null ? _d : ""
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("General.description"),
        name: "description",
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: (_e = formik.touched.description && formik.errors.description) != null ? _e : ""
      }
    ),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { mb: "10px" }, children: "Approval steps" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Request will only be approved if all set steps are satisfied." })
    ] }),
    formik.values.approvalSteps.map((step, index) => /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: borders.background,
          pb: "10px"
        },
        children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: { width: "75%", cursor: "pointer" },
              onClick: () => {
                setSelectedStep(step);
                setSelectedStepIndex(index);
                setIsAddStepOpen(true);
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", sx: { mb: "5px" }, children: polyglot.t("ApprovalRuleModule.stepNo", { stepNo: index + 1 }) }),
                /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: getStepDescription(step, getCachedUserById, polyglot) })
              ]
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: { width: "20%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => deleteApprovalStep(index), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) })
        ]
      }
    )),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "filter",
          colorVariant: "secondary",
          onClick: () => {
            setSelectedStepIndex(null);
            setSelectedStep(null);
            setIsAddStepOpen(true);
          },
          children: polyglot.t("ApproverSelectComponent.addStep")
        }
      ),
      /* @__PURE__ */ jsx(
        AddApprovalStepDrawer,
        {
          isOpen: isAddStepOpen,
          setIsOpen: setIsAddStepOpen,
          addStep: (newStep, index) => {
            const updatedSteps = [...formik.values.approvalSteps];
            if (index === null) updatedSteps.push(newStep);
            else updatedSteps[index] = newStep;
            formik.setFieldValue("approvalSteps", updatedSteps);
          },
          existingSteps: formik.values.approvalSteps,
          step: selectedStep,
          stepIndex: selectedStepIndex,
          afterClose: () => {
            setSelectedStepIndex(null);
            setSelectedStep(null);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", loading, fullWidth: true, children: polyglot.t("General.save") }) })
  ] }) });
};
const DefaultApprovalRuleDrawerContent = ({ approvalRule }) => {
  var _a;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: approvalRule.name }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: (_a = approvalRule.description) != null ? _a : translateDefaultApprovalRule(approvalRule.name, polyglot) })
  ] });
};
