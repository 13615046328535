"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Redirect, Switch } from "react-router-dom";
import { AttendanceTeamActiveShiftsPage } from "./pages/attendance-team-active-shifts.page";
import { RouteScopesHas } from "@/component/widgets/Scopes";
import { ATTENDANCE_TEAM_ACTIVE_SHIFTS_ROUTE, ATTENDANCE_TEAM_TIMESHEETS_ROUTE } from "@/lib/routes";
import { AttendanceTeamTimesheetsPage } from "@/v2/feature/attendance/team/pages/attendance-team-timesheets.page";
export const AttendanceTeamRouter = ({ isManager }) => {
  return /* @__PURE__ */ jsxs(Switch, { children: [
    isManager && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:manager"], path: ATTENDANCE_TEAM_TIMESHEETS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(AttendanceTeamTimesheetsPage, {}) }),
      /* @__PURE__ */ jsx(RouteScopesHas, { scopes: ["attendance:manager"], path: ATTENDANCE_TEAM_ACTIVE_SHIFTS_ROUTE, exact: true, children: /* @__PURE__ */ jsx(AttendanceTeamActiveShiftsPage, {}) })
    ] }),
    /* @__PURE__ */ jsx(Redirect, { to: ATTENDANCE_TEAM_TIMESHEETS_ROUTE })
  ] });
};
