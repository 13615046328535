"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { BasicServerTable } from "@v2/components/table/server-side-table.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { generatePath, useHistory } from "react-router-dom";
import { ReactComponent as Location } from "@/images/side-bar-icons/Location.svg";
import { USER_PERSONAL_TAB } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { sortDate, sortString } from "@/v2/components/table/table-sorting.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { ActiveShiftsStartBadge } from "@/v2/feature/attendance/components/active-shifts-start-badge.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AttendanceActiveShiftsTable = ({
  loading,
  paginatedActiveShifts,
  pagination,
  setPagination,
  stickyHeader = true
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [sorting, setSorting] = useState([]);
  const [activeShifts, setActiveShifts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (!paginatedActiveShifts) {
      setActiveShifts([]);
      return;
    }
    setActiveShifts(paginatedActiveShifts.items);
    setTotalPages(paginatedActiveShifts.totalPages);
    setTotalItems(paginatedActiveShifts.totalItems);
  }, [paginatedActiveShifts]);
  const tableColumns = useMemo(() => {
    return [
      {
        id: "userId",
        header: polyglot.t("AttendanceDomain.ActiveShifts.employee"),
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.userDisplayName),
        minSize: 180,
        maxSize: 180,
        accessorFn: (row) => row.userId,
        cell: ({ row: { original } }) => {
          return original.userId ? /* @__PURE__ */ jsx(
            Box,
            {
              sx: { display: "flex", alignItems: "center", width: "100%", height: "100%" },
              className: "user-cell-container",
              children: /* @__PURE__ */ jsx(
                UserCell,
                {
                  userId: original.userId,
                  endAdornment: /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      sizeVariant: "small",
                      colorVariant: "secondary",
                      className: "preview-button",
                      onClick: (e) => {
                        routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: original.userId }));
                        e.stopPropagation();
                      },
                      children: polyglot.t("AttendanceDomain.ActiveShifts.profile")
                    }
                  )
                }
              )
            }
          ) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "startTime",
        header: polyglot.t("AttendanceDomain.ActiveShifts.start"),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.startTime) != null ? _a : item == null ? void 0 : item.scheduledStartTime;
        }),
        accessorFn: (row) => row.startTime,
        cell: ({ row: { original } }) => {
          return original.startTime ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10, width: "100%", height: "100%" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(original.startTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) }),
            /* @__PURE__ */ jsx(ActiveShiftsStartBadge, { startTime: original.startTime, scheduledStartTime: original.scheduledStartTime })
          ] }) : original.scheduledStartTime ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10, width: "100%", height: "100%" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "helperText", children: polyglot.t("AttendanceDomain.ActiveShifts.startAt", {
              time: new Date(original.scheduledStartTime).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
              })
            }) }),
            /* @__PURE__ */ jsx(ActiveShiftsStartBadge, { startTime: original.startTime, scheduledStartTime: original.scheduledStartTime })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "endTime",
        header: polyglot.t("AttendanceDomain.ActiveShifts.end"),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.endTime) != null ? _a : "";
        }),
        accessorFn: (row) => row.endTime,
        cell: ({ row: { original } }) => {
          return original.endTime ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(original.endTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "location",
        header: polyglot.t("AttendanceDomain.ActiveShifts.location"),
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.location) != null ? _a : "";
        }),
        accessorFn: (row) => row.location,
        cell: ({ row: { original } }) => {
          return original.location ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g4, width: "100%", height: "100%" }, children: [
            /* @__PURE__ */ jsx(Location, __spreadValues({}, iconSize)),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.location })
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "scheduleName",
        header: polyglot.t("AttendanceDomain.ActiveShifts.attendanceSchedule"),
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.scheduleName) != null ? _a : "";
        }),
        accessorFn: (row) => row.scheduleName,
        cell: ({ row: { original } }) => {
          return original.scheduleName ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.scheduleName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      }
    ];
  }, [polyglot, routerHistory]);
  return /* @__PURE__ */ jsx(
    BasicServerTable,
    {
      rowData: activeShifts,
      columnData: tableColumns,
      loading: loading || !activeShifts.length,
      pagination,
      setPagination,
      sorting,
      setSorting,
      totalPages,
      totalItems,
      stickyHeader,
      stickyHeaderHeight: "calc(100vh - 214px)"
    }
  );
};
