"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { QuestionType } from "@v2/feature/growth/reviews/interfaces/review-question.interface";
import { GridDisplayBarV2 } from "@v2/feature/growth/shared/components/grid-display-bar.component";
import {
  GrowthProgressLineChart
} from "@v2/feature/growth/shared/components/growth-progress-chart.component";
import { QuestionCell } from "@v2/feature/growth/shared/components/question-cell.component";
import {
  SurveyImpactLabel
} from "@v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { replaceParamsInQuestionText, stripHtml } from "@v2/util/string.util";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as InfoGrey } from "@/images/side-bar-icons/InfoGrey.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getGrowthChartLinePercentLabel = (item, measureByFilter) => {
  var _a, _b, _c;
  if (item.question.type === QuestionType.ScaleQuestion)
    return `${measureByFilter === "avg" ? (_a = item.normPercent) != null ? _a : 0 : measureByFilter === "positive" ? (_b = item.positivePercent) != null ? _b : 0 : (_c = item.npsPercent) != null ? _c : 0}%`;
  if ([QuestionType.SingleAnswer, QuestionType.MultipleAnswer].includes(item.question.type)) return "-";
  return " ";
};
const getGrowthChartLinePercentages = (item, measureByFilter, polyglot) => {
  var _a, _b, _c, _d;
  if (item.question.type === QuestionType.ScaleQuestion) {
    const percentages = [
      {
        value: measureByFilter === "avg" ? (_a = item.normPercent) != null ? _a : 0 : (_b = item.positivePercent) != null ? _b : 0,
        color: measureByFilter === "avg" ? "blue" : "green",
        showTooltip: measureByFilter !== "avg",
        label: "Positive sentiment"
      }
    ];
    if (measureByFilter !== "avg" && item.negativePercent)
      percentages.push({
        value: item.negativePercent,
        color: "red",
        showTooltip: true,
        label: "Negative sentiment"
      });
    return percentages;
  }
  if ([QuestionType.SingleAnswer, QuestionType.MultipleAnswer].includes(item.question.type)) {
    return (_d = (_c = item.choicePercentages) == null ? void 0 : _c.map((choiceData) => ({
      value: choiceData.percentage,
      color: "blue",
      label: polyglot.t("SurveyResult.choiceLabelWithAnswerCount", {
        label: choiceData.choiceLabel,
        smart_count: choiceData.count
      }),
      showTooltip: true
    }))) != null ? _d : [];
  }
  return [];
};
export const SurveyQuestionGridLine = ({
  item,
  growthFactors,
  impactResult,
  impactFilter,
  measureByFilter,
  setIsOpen,
  setSelectedQuestion,
  gridsXs,
  sx = {}
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : "Company";
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      sx,
      showBorder: true,
      onClick: setIsOpen && setSelectedQuestion ? () => {
        setSelectedQuestion(item);
        setIsOpen(true);
      } : void 0,
      cells: [
        {
          gridXs: gridsXs[0],
          content: /* @__PURE__ */ jsx(
            QuestionCell,
            {
              text: replaceParamsInQuestionText(stripHtml(item.question.questionText), { company_name }),
              chips: [
                ...item.question.factor ? [
                  {
                    text: item.question.factor,
                    icon: (_d = (_c = growthFactors[item.question.factor]) == null ? void 0 : _c.color) != null ? _d : themeColors.Grey
                  }
                ] : [],
                ...((_e = item.question.scaleConfig) == null ? void 0 : _e.type) ? [
                  {
                    text: (_f = item.question.scaleConfig) == null ? void 0 : _f.type
                  }
                ] : [],
                ...((_g = item.question) == null ? void 0 : _g.hasComment) ? [
                  {
                    icon: "comment",
                    text: item.noOfComments,
                    iconPosition: "end"
                  }
                ] : [],
                {
                  icon: "smile",
                  text: item.noOfAnswers,
                  iconPosition: "end"
                }
              ],
              textVariant: "headline3"
            }
          )
        },
        ...item.answersLowerThanThreshold ? [
          {
            gridXs: gridsXs[1],
            content: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("SurveyQuestionGridLine.notSufficientData") })
          }
        ] : [
          {
            gridXs: gridsXs[1],
            content: item.question.type === QuestionType.OpenEnded ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("SurveyQuestionGridLine.notAvailableForCommentQuestion") }) : /* @__PURE__ */ jsx(
              GrowthProgressLineChart,
              {
                label: getGrowthChartLinePercentLabel(item, measureByFilter),
                labelVariant: "paragraph",
                percentJustifyContent: "space-between",
                percentages: getGrowthChartLinePercentages(item, measureByFilter, polyglot)
              }
            )
          },
          {
            gridXs: gridsXs[2],
            content: item.question.type === QuestionType.ScaleQuestion && impactResult && ((_h = impactResult[item.question.id][impactFilter]) == null ? void 0 : _h.label) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.sm, alignItems: "center", paddingLeft: spacing.s2 }, children: [
              /* @__PURE__ */ jsx(ImpactIcon, { surveyImpactLabel: (_i = impactResult[item.question.id][impactFilter]) == null ? void 0 : _i.label }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: (_j = impactResult[item.question.id][impactFilter]) == null ? void 0 : _j.label }),
              /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("SurveyQuestionGridLine.impact"), children: /* @__PURE__ */ jsx(InfoGrey, __spreadValues({}, iconSize)) })
            ] }) : null
          }
        ]
      ]
    },
    item.question.id
  );
};
const getBoxColors = (surveyImpactLabel) => {
  switch (surveyImpactLabel) {
    case SurveyImpactLabel.High:
      return [themeColors.DarkGrey, themeColors.DarkGrey, themeColors.DarkGrey, themeColors.DarkGrey];
    case SurveyImpactLabel.Medium:
      return [themeColors.Grey, themeColors.DarkGrey, themeColors.DarkGrey, themeColors.DarkGrey];
    case SurveyImpactLabel.Low:
      return [themeColors.Grey, themeColors.Grey, themeColors.DarkGrey, themeColors.DarkGrey];
    case SurveyImpactLabel.None:
      return [themeColors.Grey, themeColors.Grey, themeColors.Grey, themeColors.DarkGrey];
    default:
      return [themeColors.Grey, themeColors.Grey, themeColors.Grey, themeColors.Grey];
  }
};
const ImpactIcon = ({ surveyImpactLabel }) => {
  const boxColors = getBoxColors(surveyImpactLabel);
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: "2px" }, children: boxColors.map((color, index) => /* @__PURE__ */ jsx(Box, { sx: { bgcolor: color, width: "4px", height: "1px" } }, index)) });
};
