"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { BillingInvoiceAPI } from "@/api-client/billing-invoice.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SuperAdminBillingNewOneOffChargeDrawer } from "@/v2/feature/super-admin/features/super-admin-billing-v2/components/super-admin-billing-new-one-off-charge-drawer.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconErrorButtonSx } from "@/v2/styles/icon-button.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
export function SuperAdminBillingOneOffChargesPage({ pageConfig }) {
  var _a, _b;
  const [charges, setCharges] = useState([]);
  const [isLoadingCharges, setIsLoadingCharges] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMessage] = useMessage();
  const [sorting, setSorting] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [newChargeDrawerOpen, setNewChargeDrawerOpen] = useState(false);
  const [chargeToDelete, setChargeToDelete] = useState();
  const [chargeToEdit, setChargeToEdit] = useState();
  const { data: companyList } = useApiClient(CompanyEndpoints.getAllCompaniesForSelectionList(), { suspense: false });
  const fetchCharges = useCallback(
    (params) => __async(this, null, function* () {
      try {
        setIsLoadingCharges(true);
        const { charges: charges2, pagination: paginationInfo } = yield BillingInvoiceAPI.getAllCharges(params);
        setCharges(charges2 || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
        setIsLoadingCharges(false);
      } catch (error) {
        showMessage(`Something went wrong fetching invoices. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const refresh = useCallback(() => __async(this, null, function* () {
    yield fetchCharges({
      page: pageIndex.toString(),
      pageSize: DEFAULT_PAGE_SIZE.toString(),
      searchQuery
    });
  }), [fetchCharges, pageIndex, searchQuery]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const confirmChargeDelete = (event, charge) => {
    setAnchorEl(event.currentTarget);
    setChargeToDelete(charge);
  };
  const handleDeleteOneOffCharge = useCallback(
    (companyId, chargeId) => __async(this, null, function* () {
      if (!chargeId) return;
      try {
        yield BillingInvoiceAPI.deleteChargeAsSuperadmin(companyId, chargeId);
        yield refresh();
        showMessage("Successfully deleted charge", "success");
      } catch (error) {
        showMessage(`Could not delete charge. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [refresh, showMessage]
  );
  const handleEditOneOffCharge = useCallback(
    (charge) => __async(this, null, function* () {
      setChargeToEdit(charge);
      setNewChargeDrawerOpen(true);
    }),
    [setChargeToEdit, setNewChargeDrawerOpen]
  );
  const handleNewOneOffCharge = useCallback(() => {
    setChargeToEdit(null);
    setNewChargeDrawerOpen(true);
  }, [setNewChargeDrawerOpen]);
  const billingChargeColumns = useMemo(
    () => [
      {
        header: () => "Company Name",
        accessorFn: (row) => {
          var _a2;
          return (_a2 = row.company) == null ? void 0 : _a2.name;
        },
        id: "companyName",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: original.company ? `${original.company.name} (${original.companyId})` : "\u2014" }) }),
        size: 150
      },
      {
        header: () => "Amount",
        accessorFn: (row) => row.amount,
        id: "amount",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: original.amount }) }),
        size: 70
      },
      {
        header: () => "Type",
        accessorFn: (row) => row.type,
        id: "type",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: original.type || "\u2014" }) }),
        size: 70
      },
      {
        header: () => "Status",
        accessorFn: (row) => row.status,
        id: "status",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: original.status === "paid" ? `Billed (${original.invoiceId})` : original.status !== "paid" ? original.invoiceId ? `Not paid (${original.invoiceId})` : `Not billed` : "\u2014" }) }),
        size: 70
      },
      {
        header: () => "Description",
        accessorFn: (row) => row.memo,
        id: "status",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: original.memo }) }),
        size: 70
      },
      {
        header: () => "Paid At",
        accessorFn: (row) => row.paidAt,
        id: "paidAt",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: dateAPItoDisplay(original.paidAt) }) }),
        size: 100
      },
      {
        header: () => "Created At",
        accessorFn: (row) => row.createdAt,
        id: "createdAt",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: dateAPItoDisplay(original.createdAt) }) }),
        size: 100
      },
      {
        header: () => "Action",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: original.status !== "paid" && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(StyledTooltip, { id: "edit-charge", title: "Edit unbilled charge", placement: "top", sx: { ml: "10px" }, children: /* @__PURE__ */ jsx(
            IconButton,
            {
              color: "secondary",
              size: "small",
              onClick: () => handleEditOneOffCharge(original),
              title: "Edit",
              children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize))
            },
            `edit-charge-${original.id}`
          ) }),
          /* @__PURE__ */ jsx(StyledTooltip, { id: "delete-charge", title: "Delete unbilled charge", placement: "top", sx: { ml: "10px" }, children: /* @__PURE__ */ jsx(
            IconButton,
            {
              onClick: (event) => confirmChargeDelete(event, original),
              color: "error",
              sx: tableIconErrorButtonSx,
              children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
            },
            `delete-charge-${original.id}`
          ) })
        ] }) }),
        size: 220
      }
    ],
    [handleEditOneOffCharge]
  );
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Billing" }),
        views: /* @__PURE__ */ jsx(Fragment, {}),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "small", onClick: () => handleNewOneOffCharge(), children: "Add new charge" }),
        showAction: true
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoadingCharges, secondLevel: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({
            display: "flex",
            justifyContent: "flex-start",
            width: "100%"
          }, spacing.mb20), {
            gap: "5px",
            alignItems: "center"
          }),
          children: /* @__PURE__ */ jsx(
            TableSearch,
            {
              style: { width: "20em" },
              query: searchQuery,
              handleChange: (e) => {
                var _a2, _b2;
                setSearchQuery((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
              },
              placeholder: "Enter company id or name..."
            }
          )
        }
      ),
      !isLoadingCharges && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
        BasicServerTable,
        {
          rowData: [...charges],
          columnData: billingChargeColumns,
          sorting,
          setSorting,
          pagination,
          setPagination,
          totalPages,
          totalItems
        }
      ) }),
      companyList && /* @__PURE__ */ jsx(
        SuperAdminBillingNewOneOffChargeDrawer,
        {
          isOpen: newChargeDrawerOpen,
          setIsOpen: setNewChargeDrawerOpen,
          refresh,
          companyList,
          chargeToEdit
        }
      ),
      anchorEl && chargeToDelete && /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: Boolean(anchorEl),
          onClose: () => {
            setAnchorEl(null);
            setChargeToDelete(null);
          },
          anchorEl,
          takeAction: () => __async(this, null, function* () {
            if (!chargeToDelete) return;
            yield handleDeleteOneOffCharge(chargeToDelete.companyId, chargeToDelete.id);
            setChargeToDelete(null);
            setAnchorEl(null);
          }),
          message: /* @__PURE__ */ jsxs(Box, { sx: { mb: "10px" }, children: [
            /* @__PURE__ */ jsx(Typography, { sx: { font: themeFonts.headline3, mb: "5px" }, children: "Delete one off charge?" }),
            /* @__PURE__ */ jsxs(Typography, { sx: { font: themeFonts.paragraphSmall }, children: [
              "Company: ",
              /* @__PURE__ */ jsx("b", { children: chargeToDelete.company.name })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { sx: { font: themeFonts.paragraphSmall }, children: [
              "Charge ID: ",
              /* @__PURE__ */ jsx("b", { children: chargeToDelete.id })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { sx: { font: themeFonts.paragraphSmall }, children: [
              "Charge Date: ",
              /* @__PURE__ */ jsx("b", { children: dateAPItoDisplay(chargeToDelete.createdAt) })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { sx: { font: themeFonts.paragraphSmall }, children: [
              "Charge memo:",
              chargeToDelete.memo
            ] })
          ] }),
          callToAction: "Delete"
        }
      )
    ] }) })
  ] });
}
