"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AvatarCardWidth = 110;
export const AvatarCard = ({
  title,
  titleSx = "paragraph",
  caption,
  captionSx = "paragraphSmall",
  onClick,
  subTitle,
  subTitleSx = "paragraphSmall",
  size = "s40",
  userId,
  freeWidth,
  publicURL
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        bgcolor: themeColors.Background,
        overflow: "hidden",
        py: spacing.p15,
        borderRadius: radius.br15,
        width: freeWidth ? "auto" : `${AvatarCardWidth}px`,
        cursor: onClick && "pointer",
        minHeight: "130px"
      },
      onClick,
      children: [
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(UserAvatar, { userId, size, publicURL }) }),
        title && /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: {
              marginTop: spacing.m10
            },
            variant: titleSx,
            children: title
          }
        ) }),
        subTitle && /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: subTitleSx, children: subTitle }) }),
        caption && /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: captionSx, children: caption }) })
      ]
    }
  );
};
