"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import { StockDeviceEditDrawer } from "@v2/feature/device/components/devices-list-overview/stock-device-edit-drawer.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
export const DevicesStockTable = ({ devices, refresh }) => {
  const [searchInput, setSearchInput] = useState("");
  const [deviceToEdit, setDeviceToEdit] = useState(void 0);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [deviceToDelete, setDeviceToDelete] = useState(void 0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      var _a;
      const modelName = device.modelName;
      const serialNumber = (_a = device.serialNumber) != null ? _a : void 0;
      return (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase())) || (serialNumber == null ? void 0 : serialNumber.toLowerCase().includes(searchInput.toLowerCase()));
    });
  }, [devices, searchInput]);
  const columnData = useMemo(
    () => [
      {
        header: () => "Model Name",
        accessorFn: (row) => row,
        id: "modelName",
        enableSorting: false,
        cell: (info) => {
          const device = info.getValue();
          return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: device.modelName });
        }
      },
      {
        header: () => "Model Number",
        accessorFn: (row) => row,
        id: "modelNumber",
        enableSorting: false,
        cell: (info) => {
          const device = info.getValue();
          return device.modelNumber ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: device.modelNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Serial Number",
        accessorFn: (row) => row,
        id: "serialNumber",
        enableSorting: false,
        cell: (info) => {
          const device = info.getValue();
          return device.serialNumber ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: device.serialNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 250
      },
      {
        header: () => "Storage",
        accessorFn: (row) => row,
        id: "storage",
        enableSorting: false,
        cell: (info) => {
          const device = info.getValue();
          return device.storage ? /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
            device.storage,
            " GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "RAM",
        accessorFn: (row) => row,
        id: "ram",
        enableSorting: false,
        cell: (info) => {
          const device = info.getValue();
          return device.ram ? /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
            device.ram,
            " GB"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Screen Size",
        accessorFn: (row) => row,
        id: "screenSize",
        enableSorting: false,
        cell: (info) => {
          const device = info.getValue();
          return device.screenSize ? /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
            device.screenSize,
            '"'
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => " ",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: (info) => {
          const device = info.getValue();
          return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-end", gap: 1 }, children: [
            /* @__PURE__ */ jsx(
              IconButton,
              {
                sx: tableIconButtonSx,
                onClick: () => {
                  setDeviceToDelete(device);
                  setIsDeleteModalOpen(true);
                },
                children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
              }
            ),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                sx: tableIconButtonSx,
                onClick: () => {
                  setDeviceToEdit(device);
                  setIsEditDrawerOpen(true);
                },
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ] });
        }
      }
    ],
    []
  );
  const deleteDevice = () => __async(void 0, null, function* () {
    try {
      if (!deviceToDelete) {
        showMessage("Could not delete device. A device was not selected.", "error");
        return;
      }
      yield DeviceAPI.deleteStockDeviceByDeviceIdAsSuperadmin(deviceToDelete == null ? void 0 : deviceToDelete.id);
      showMessage("Device deleted successfully.", "success");
      setDeviceToDelete(void 0);
      yield refresh();
      setIsDeleteModalOpen(false);
    } catch (error) {
      showMessage(`Could not delete the device. ${nestErrorMessage(error)}`, "error");
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    devices.length > 0 && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", justifyContent: "flex-start", width: "100%" }, spacing.mb20), children: /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => {
          var _a, _b;
          return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
        },
        style: { width: "350px", minWidth: "350px" }
      }
    ) }),
    /* @__PURE__ */ jsx(BasicTable, { rowData: filteredDevices, columnData }),
    /* @__PURE__ */ jsx(
      StockDeviceEditDrawer,
      {
        isOpen: isEditDrawerOpen,
        setIsOpen: setIsEditDrawerOpen,
        onClose: () => setDeviceToEdit(void 0),
        device: deviceToEdit,
        refresh
      }
    ),
    /* @__PURE__ */ jsx(
      Modal,
      {
        open: isDeleteModalOpen,
        onClose: () => {
          setDeviceToDelete(void 0);
          setIsDeleteModalOpen(false);
        },
        "aria-labelledby": "modal-modal-title",
        "aria-describedby": "modal-modal-description",
        children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              pt: 2,
              px: 4,
              pb: 3,
              borderRadius: 1
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Delete Device" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { my: spacing.s2 }, children: "Are you sure you want to delete this device?" }),
              deviceToDelete && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", justifyContent: "center" }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Device Details:" }),
                /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                  "- ",
                  deviceToDelete.modelName
                ] }),
                /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                  "- ",
                  deviceToDelete.modelNumber
                ] }),
                deviceToDelete.serialNumber && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                  "- ",
                  deviceToDelete.serialNumber
                ] })
              ] }),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "center", gap: spacing.s1, width: "100%", mt: spacing.s2 }, children: [
                /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "danger", onClick: deleteDevice, children: "Delete Device" }),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    sizeVariant: "medium",
                    colorVariant: "secondary",
                    onClick: () => {
                      setDeviceToDelete(void 0);
                      setIsDeleteModalOpen(false);
                    },
                    children: "Cancel"
                  }
                )
              ] })
            ]
          }
        )
      }
    )
  ] });
};
