"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { Typography } from "@/v2/components/typography/typography.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import {
  AnonymityType,
  MeasureBy,
  ShowBy
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { borders } from "@/v2/styles/borders.styles";
import { styledChipSx } from "@/v2/styles/chip.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const getStatus = () => {
  return {
    [CycleState.Draft]: getStatusChip(CycleState.Draft, "white", "DarkGrey"),
    [CycleState.Scheduled]: getStatusChip(CycleState.Scheduled, "DarkGrey", "white"),
    [CycleState.Launching]: getStatusChip(CycleState.Launching, "Background", "DarkGrey"),
    [CycleState.Finalising]: getStatusChip(CycleState.Finalising, "Background", "DarkGrey"),
    // doesnt exist for survey
    [CycleState.Paused]: getStatusChip(CycleState.Paused, "Background", "DarkGrey"),
    [CycleState.Ongoing]: getStatusChip(CycleState.Ongoing, "Green", "white"),
    [CycleState.Completed]: getStatusChip(CycleState.Completed, "Background", "DarkGrey")
  };
};
export const getStatusChip = (label, backgroundColor, color) => /* @__PURE__ */ jsx(
  Box,
  {
    sx: __spreadProps(__spreadValues({}, styledChipSx), {
      backgroundColor: themeColors[backgroundColor],
      border: backgroundColor === "white" ? borders.background : "none",
      width: "fit-content",
      display: "flex",
      justifyContent: "center"
    }),
    children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color, children: capitalize(label) })
  }
);
export const TitleStatusComponent = ({ surveyCycle }) => {
  var _a;
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (_a = surveyCycle.internalName) != null ? _a : "Overview" }),
    getStatus()[surveyCycle.state]
  ] });
};
export const getPercentByMeasurementQuestion = (resultItem, measureByFilter) => {
  var _a, _b, _c;
  if (measureByFilter === MeasureBy.Average) return (_a = resultItem && resultItem.normPercent) != null ? _a : null;
  if (measureByFilter === MeasureBy.NPS) return (_b = resultItem && resultItem.npsPercent) != null ? _b : null;
  return (_c = resultItem && resultItem.positivePercent) != null ? _c : null;
};
export const getPercentByMeasurementFactor = (resultItem, measureByFilter) => {
  var _a, _b, _c;
  if (measureByFilter === MeasureBy.Average) return (_a = resultItem && resultItem.normPercent) != null ? _a : null;
  if (measureByFilter === MeasureBy.NPS) return (_b = resultItem && resultItem.npsPercent) != null ? _b : null;
  return (_c = resultItem && resultItem.positivePercent) != null ? _c : null;
};
const processQuestions = (surveyHeatmap, loggedInUser) => {
  const uniqueQuestions = /* @__PURE__ */ new Map();
  surveyHeatmap.forEach((item) => {
    item.result.resultByQuestion.forEach((questionItem) => {
      const questionId = questionItem.question.id;
      const questionText = questionItem.question.questionText.replaceAll(
        "{company_name}",
        loggedInUser.company.name || "company"
      );
      uniqueQuestions.set(questionId, questionText);
    });
  });
  return Array.from(uniqueQuestions, ([id, text]) => ({ id, text }));
};
const processFactors = (surveyHeatmap) => {
  const uniqueFactors = /* @__PURE__ */ new Set();
  surveyHeatmap.forEach((item) => {
    item.result.resultByFactor.forEach((result) => {
      const factor = result.factor;
      if (factor) uniqueFactors.add(factor);
    });
  });
  return Array.from(uniqueFactors);
};
const generateSeriesQuestion = (surveyHeatmap, categories, measureByFilter) => {
  return surveyHeatmap.map((data) => {
    const dataPoints = categories.map((category) => {
      const resultItem = data.result.resultByQuestion.find((qItem) => qItem.question["id"] === category["id"]);
      return resultItem ? getPercentByMeasurementQuestion(resultItem, measureByFilter) : null;
    });
    return {
      name: data.name,
      data: dataPoints
    };
  });
};
const generateSeriesFactor = (surveyHeatmap, categories, measureByFilter) => {
  return surveyHeatmap.map((data) => {
    const dataPoints = categories.map((category) => {
      const resultItem = data.result.resultByFactor.find((fItem) => fItem.factor === category);
      return resultItem ? getPercentByMeasurementFactor(resultItem, measureByFilter) : null;
    });
    return {
      name: data.name,
      data: dataPoints
    };
  });
};
export const transformData = (surveyHeatmap, showByFilter, measureByFilter, loggedInUser) => {
  if (!surveyHeatmap) return { series: [{ name: "NA", data: [] }], categories: [] };
  if (showByFilter === ShowBy.Questions) {
    const questionArray = processQuestions(surveyHeatmap, loggedInUser);
    const series = generateSeriesQuestion(surveyHeatmap, questionArray, measureByFilter);
    return { series, categories: questionArray.map((q) => q.id) };
  } else {
    const factorArray = processFactors(surveyHeatmap);
    const series = generateSeriesFactor(surveyHeatmap, factorArray, measureByFilter);
    return { series, categories: factorArray };
  }
};
export const isDataEmpty = (surveyHeatmap, showByFilter) => {
  if (!surveyHeatmap) return true;
  if (showByFilter === ShowBy.Factor) return surveyHeatmap.every((item) => item.result.resultByFactor.length === 0);
  if (showByFilter === ShowBy.Questions)
    return surveyHeatmap.every((item) => item.result.resultByQuestion.length === 0);
  return true;
};
export const getAnonymitySetting = (visibilitySettings) => {
  if (visibilitySettings.anonymiseAnswers)
    return visibilitySettings.anonymityType === AnonymityType.Semi ? "Semi" : "Full";
  return "Not anonymous";
};
