"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { v4 } from "uuid";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { IconButton as CustomIconButton } from "@/v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomProfileFormAPI } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import {
  CustomProfileFieldType
} from "@/v2/feature/custom-fields/custom-profile-fields.dto";
import { sortSelectOptions } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { cleanPayCodeValue } from "@/v2/feature/payroll/features/payroll-global/global-payroll.util";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CustomFieldDrawer = ({ isOpen, setIsOpen, editingField, refresh, onClose }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const selectedField = editingField == null ? void 0 : editingField.field;
  const [isDeleteOpened, setIsDeleteOpened] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const closeFieldDrawer = useCallback(() => __async(void 0, null, function* () {
    setIsDeleteOpened(false);
    yield refresh();
    onClose();
  }), [refresh, onClose]);
  const deleteField = () => __async(void 0, null, function* () {
    if (!selectedField) return;
    try {
      setDeleteLoading(true);
      yield CustomProfileFormAPI.deleteField(selectedField.fieldId);
      showMessage(
        `${polyglot.t("CustomFieldDeleteDrawer.successMessages.delete", { fieldName: selectedField.fieldName })}`,
        "success"
      );
      closeFieldDrawer();
      setIsDeleteOpened(false);
    } catch (error) {
      showMessage(
        polyglot.t("CustomFieldDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: editingField ? /* @__PURE__ */ jsx(
      CustomFieldDrawerContent,
      {
        form: editingField.form,
        fieldType: editingField.fieldType,
        selectedField: editingField.field,
        onClose: closeFieldDrawer,
        openDeleteDrawer: () => setIsDeleteOpened(true)
      }
    ) : /* @__PURE__ */ jsx(Box, { sx: drawerContentSx, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("CustomFieldDrawer.errorMessages.noField") }) }) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("CustomFieldDeleteDrawer.title"),
        description: polyglot.t("CustomFieldDeleteDrawer.confirmDeletion", {
          fieldName: (selectedField == null ? void 0 : selectedField.fieldName) || ""
        }),
        isOpen: isDeleteOpened,
        setIsOpen: setIsDeleteOpened,
        loading: deleteLoading,
        action: deleteField
      }
    )
  ] });
};
const getFieldByType = (fieldType, field, setField, options, setOptions, setError, error, polyglot) => {
  const formattedFieldType = field.fieldType.replace(/\s/g, "").toLowerCase();
  switch (fieldType) {
    case CustomProfileFieldType.Input:
    case CustomProfileFieldType.Number:
    case CustomProfileFieldType.DateInput:
    case CustomProfileFieldType.DatePicker:
      return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          value: field.fieldName,
          label: polyglot.t(`getFieldByType.${formattedFieldType}`),
          onChange: (e) => {
            const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { fieldName: e.target.value });
            setError(false);
            setField(allFieldsUpdate);
          },
          autoFocus: true,
          required: true,
          helperText: !field.fieldName.trim() && error ? polyglot.t("CustomFieldDrawer.fieldNameRequired") : "",
          error: !field.fieldName.trim() && error
        }
      ) });
    case CustomProfileFieldType.Select:
    case CustomProfileFieldType.Multiselect:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g40 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            value: field.fieldName,
            label: polyglot.t(`getFieldByType.${formattedFieldType}`),
            onChange: (e) => {
              const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { fieldName: e.target.value });
              setError(false);
              setField(allFieldsUpdate);
            },
            autoFocus: true,
            required: true,
            helperText: !field.fieldName.trim() && error ? polyglot.t("CustomFieldDrawer.fieldNameRequired") : "",
            error: !field.fieldName.trim() && error
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g40 }, children: sortSelectOptions(options).map((o) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              value: o.value,
              label: o.key,
              onChange: (e) => {
                const currentOpt = { label: e.target.value, value: e.target.value, key: o.key };
                const opts = sortSelectOptions(
                  options.filter((opt) => opt.key !== o.key).concat(currentOpt)
                );
                const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { options: { allOptions: opts } });
                setField(allFieldsUpdate);
                setOptions(opts);
              },
              maxLength: 40
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: () => {
                const opts = sortSelectOptions(options.filter((opt) => opt.key !== o.key));
                const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { options: { allOptions: [...opts] } });
                setField(allFieldsUpdate);
                setOptions([...opts]);
              },
              children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
            }
          )
        ] }, o.key)) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: /* @__PURE__ */ jsxs(
          Button,
          {
            sx: secondaryCTABtn,
            onClick: () => setOptions([
              ...options,
              { label: "Next Option", value: "Next Option", key: `Answer ${options.length + 1}` }
            ]),
            children: [
              /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
              " \xA0",
              polyglot.t("getFieldByType.addAnswer")
            ]
          }
        ) })
      ] });
  }
};
export const CustomFieldDrawerContent = ({
  form,
  fieldType,
  selectedField,
  onClose,
  openDeleteDrawer
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [linkToPayroll, setLinkToPayroll] = useState(!!(selectedField == null ? void 0 : selectedField.paycode));
  const defaultOptions = [
    { label: polyglot.t("CustomFieldDrawer.optionA"), value: "Option A", key: "Answer 1" },
    { label: polyglot.t("CustomFieldDrawer.optionB"), value: "Option B", key: "Answer 2" }
  ];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [field, setField] = useState(
    selectedField != null ? selectedField : {
      fieldType,
      isRequired: false,
      fieldName: "",
      fieldId: v4(),
      formId: form.formId,
      companyId: form.companyId,
      placeholder: "",
      defaultValue: "",
      isHidden: false,
      isDefault: false,
      fieldStub: null,
      isEditable: true,
      options: {
        allOptions: []
      },
      paycode: null,
      displayOrder: null
    }
  );
  const [showMessage] = useMessage();
  const [options, setOptions] = useState(
    selectedField && ((_a = selectedField == null ? void 0 : selectedField.options) == null ? void 0 : _a.allOptions) ? (_b = selectedField == null ? void 0 : selectedField.options) == null ? void 0 : _b.allOptions : defaultOptions
  );
  const saveField = () => __async(void 0, null, function* () {
    var _a2, _b2;
    const action = selectedField ? "update" : "add";
    if (!field.fieldName.trim()) {
      setError(true);
      return;
    }
    try {
      setLoading(true);
      yield CustomProfileFormAPI.addFields(__spreadProps(__spreadValues({}, field), {
        paycode: linkToPayroll && field.paycode || null,
        options: field.fieldType === CustomProfileFieldType.Select || field.fieldType === CustomProfileFieldType.Multiselect ? Boolean(((_a2 = field.options) == null ? void 0 : _a2.allOptions) && ((_b2 = field.options) == null ? void 0 : _b2.allOptions.length) > 0) ? field.options : { allOptions: defaultOptions } : { allOptions: [] }
      }));
      showMessage(polyglot.t(`CustomFieldDrawer.successMessages.${action}`), "success");
      onClose();
    } catch (error2) {
      showMessage(
        polyglot.t(`CustomFieldDrawer.errorMessages.${action}`, { nestError: nestErrorMessage(error2) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { textTransform: selectedField ? "inherit" : "capitalize" }, children: selectedField ? selectedField.fieldName : polyglot.t("CustomFieldDrawer.new") }),
      selectedField && !field.isDefault && /* @__PURE__ */ jsx(
        CustomIconButton,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          title: polyglot.t("CustomFieldDrawer.delete"),
          onClick: openDeleteDrawer,
          children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
        },
        "delete"
      )
    ] }),
    !field.isDefault && /* @__PURE__ */ jsx(Box, { children: getFieldByType(fieldType, field, setField, options, setOptions, setError, error, polyglot) }),
    form.formName === "salary" && fieldType === "Number" && // allow custom salary number fields to link to pay codes
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g3 }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("CustomFieldDrawer.linkFieldValueToPaycode"),
          name: "linkToPayroll",
          checked: linkToPayroll,
          onChange: (_, checked) => setLinkToPayroll(checked)
        }
      ),
      linkToPayroll && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "paycode",
          label: polyglot.t("CustomFieldDrawer.paycode"),
          value: (_c = field.paycode) != null ? _c : "",
          onChange: (e) => setField(__spreadProps(__spreadValues({}, field), { paycode: cleanPayCodeValue(e.target.value) || null }))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: polyglot.t("CustomFieldDrawer.hideField"),
        description: polyglot.t("CustomFieldDrawer.hideFieldHelper"),
        name: "hidden",
        checked: field.isHidden,
        disabled: field.isRequired,
        value: "hidden",
        onChange: (_, checked) => {
          const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { isHidden: checked });
          setField(allFieldsUpdate);
        }
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: polyglot.t("CustomFieldDrawer.requiredField"),
        description: polyglot.t("CustomFieldDrawer.requiredFieldHelper"),
        name: "required",
        checked: field.isRequired,
        disabled: field.isHidden,
        value: "required",
        onChange: (_, checked) => {
          const allFieldsUpdate = __spreadProps(__spreadValues({}, field), { isRequired: checked });
          setField(allFieldsUpdate);
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: saveField,
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] });
};
