"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { getRuleTypeString } from "@v2/components/user-select-type/user-select.component";
import {
  getUserSelectOptionLabel,
  UserSelectFiltersOptions
} from "@v2/components/user-select-type/user-select.interface";
import { SettingsMembersList } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-members-list.component";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { EditPolicyMembersDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-members-edit-drawer.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { UserContractTypeEnum } from "@v2/feature/user/features/user-profile/user-profile.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
export const AbsencePolicyMembersSection = ({ absencePolicy, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), { suspense: false });
  const { data: entities } = useApiClient(CompanyEndpoints.getCompanyEntities(), { suspense: false });
  const { nonTerminatedCachedUsers: companyUsers } = useCachedUsers();
  const listOfUsers = useMemo(() => {
    var _a2;
    if (absencePolicy.membersRule === UserSelectFiltersOptions.Everyone)
      return companyUsers.map((u) => {
        var _a3;
        return {
          userId: u.userId,
          name: (_a3 = u.displayName) != null ? _a3 : `${u.firstName} ${u.lastName}`
        };
      });
    const selectedIds = (_a2 = absencePolicy.selectedMembersIds) != null ? _a2 : [];
    const userIdsSet = new Set(selectedIds);
    return companyUsers.filter((u) => userIdsSet.has(u.userId)).map((u) => {
      var _a3;
      return {
        userId: u.userId,
        name: (_a3 = u.displayName) != null ? _a3 : `${u.firstName} ${u.lastName}`
      };
    });
  }, [absencePolicy.membersRule, absencePolicy.selectedMembersIds, companyUsers]);
  const ruleString = getRuleTypeString(
    (_a = absencePolicy.customRule) != null ? _a : "",
    sites,
    departments,
    entities,
    [UserContractTypeEnum.Payrolled, UserContractTypeEnum.NonPayrolled],
    polyglot
  );
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyMembersSection.members"),
      onEdit: () => setIsEditDrawerOpen(true),
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyMembersSection.members"),
                    value: getUserSelectOptionLabel(absencePolicy.membersRule, polyglot)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyMembersSection.rule"),
                    value: ruleString,
                    hidden: !ruleString
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(SettingsMembersList, { members: listOfUsers })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          EditPolicyMembersDrawer,
          {
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            absencePolicy,
            refresh
          }
        )
      ]
    }
  );
};
