"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
export const LoanBorrowedDrawer = ({ isOpen, setIsOpen, userBenefit }) => {
  return userBenefit.openingBalance === null ? null : /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(LoanBorrowedDrawerContent, { userBenefit }) });
};
export const LoanBorrowedDrawerContent = ({ userBenefit }) => {
  var _a;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("BenefitModule.borrowed") }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: "30px", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: polyglot.t("General.date") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: polyglot.t("General.amount") })
    ] }, "headers"),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new LocalDate(userBenefit.effectiveDate).toLocaleDateString(void 0, {
        day: "2-digit",
        month: "short",
        year: "numeric"
      }) }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatMoney({ amount: (_a = userBenefit.openingBalance) != null ? _a : 0, asDecimal: true }) })
    ] })
  ] });
};
