"use strict";
import {
  ATTENDANCE_NOTIFICATIONS,
  DOCUMENT_NOTIFICATIONS,
  DOMAINS_WITH_BEFORE,
  DefaultNotifications,
  EMPLOYEE_INFO_NOTIFICATIONS,
  NotificationCategory,
  NotificationChannel,
  NotificationHumanName,
  NotificationSendTo,
  NotificationSystemName,
  NotificationWhen,
  DEVICE_NOTIFICATIONS,
  DOMAINS_WITH_AFTER
} from "@/v2/feature/notification/notification-settings/notification.interface";
export const getRecieverList = (p) => {
  const recieversList = [];
  if (p.sendAdmin) recieversList.push("Admin");
  if (p.sendManager) recieversList.push("Manager");
  return recieversList.length > 0 ? recieversList.toString().replaceAll(",", ", ") : "NA";
};
export const getCategoryForProfileNotification = (polyglot, domain) => {
  const polyglotPrefix = `NotificationSettingTable.category.`;
  let finalKey;
  if (EMPLOYEE_INFO_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.EMPLOYEE_INFORMATION}`;
    return polyglot.t(finalKey);
  } else if (DOCUMENT_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.DOCUMENTS}`;
    return polyglot.t(finalKey);
  } else if (ATTENDANCE_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.ATTENDANCE}`;
    return polyglot.t(finalKey);
  } else if (DEVICE_NOTIFICATIONS.includes(domain)) {
    finalKey = `${polyglotPrefix}${NotificationCategory.DEVICES}`;
    return polyglot.t(finalKey);
  } else {
    finalKey = `${polyglotPrefix}${NotificationCategory.EMPLOYEE_INFORMATION}`;
    return polyglot.t(finalKey);
  }
};
const getWhenForProfileNotification = (polyglot, domain, daysBefore, daysAfter, name, weeklyDay) => {
  let polyglotPrefix = "NotificationSettingTable.whenColumnValue.";
  let beforeWhenFilter = `${polyglotPrefix}daysBefore`;
  let polyglotDaysBeforeWhen = polyglot.t(beforeWhenFilter);
  let afterWhenFilter = `${polyglotPrefix}daysAfter`;
  let polyglotDaysAfterWhen = polyglot.t(afterWhenFilter);
  if (name && name === DefaultNotifications.onboardingReminder && domain === NotificationSystemName.onboarding || domain === NotificationSystemName.securityDigest) {
    return daysBefore === null && daysBefore !== 0 ? polyglot.t(`${polyglotPrefix}${NotificationWhen.ONCE}`) : daysBefore === 0 && weeklyDay === null ? polyglot.t(`${polyglotPrefix}${NotificationWhen.DAILY}`) : daysBefore === 0 && weeklyDay && weeklyDay >= 0 ? polyglot.t(`${polyglotPrefix}${NotificationWhen.WEEKLY}`) : "";
  }
  const hasValidDaysAfter = daysAfter && daysAfter > 0;
  const hasValidDaysBefore = daysBefore && daysBefore > 0;
  return DOMAINS_WITH_AFTER.includes(domain) && hasValidDaysAfter ? `${daysAfter} ${polyglotDaysAfterWhen}` : DOMAINS_WITH_BEFORE.includes(domain) && hasValidDaysBefore ? `${daysBefore} ${polyglotDaysBeforeWhen}` : polyglot.t(`${polyglotPrefix}${NotificationWhen.IMMEDIATE}`);
};
const getSendToAndApproverGroupForMapping = (polyglot, name, sendAdmin, sendManager, sendSpecificUsers, specificUserEmails, cachedUsers) => {
  const sendToValue = [];
  const sendToPolyglotPrefix = "NotificationSettingTable.sendToColumnValue.";
  const sendToApproverPresetValue = [];
  if (sendAdmin) {
    sendToValue.push(NotificationSendTo.ADMIN);
    sendToApproverPresetValue.push("admin");
  }
  if (sendManager) {
    sendToValue.push(NotificationSendTo.MANAGER);
    sendToApproverPresetValue.push("manager:1");
  }
  if (sendSpecificUsers) sendToValue.push(NotificationSendTo.SPECIFIC);
  const presets = sendToValue.includes(NotificationSendTo.EVERYONE) ? ["everyone"] : sendToApproverPresetValue;
  const userIds = sendToValue.includes(NotificationSendTo.SPECIFIC) ? cachedUsers.filter((user) => specificUserEmails == null ? void 0 : specificUserEmails.includes(user.userId)).map((user) => user.userId) : [];
  const APPROVER_GROUP_FOR_NOTIFICATION = {
    type: "anyof",
    autoApprove: false,
    presets,
    userIds
  };
  const sendTo = name && (name === DefaultNotifications.onboardingReminder || name === "User Security Digest") ? polyglot.t(`${sendToPolyglotPrefix}allEmployees`) : sendToValue.map((v) => polyglot.t(`${sendToPolyglotPrefix}${v}`)).join(", ");
  return [sendTo, APPROVER_GROUP_FOR_NOTIFICATION];
};
export const mapUserNotificationsToNotificationForTable = (polyglot, userNotifications, cachedUsers, slackChannels) => {
  var _a, _b, _c;
  const notificationTableFormat = [];
  const emailChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.EMAIL}`);
  const slackChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.SLACK_CHANNEL}`);
  for (const type in userNotifications) {
    if (Object.prototype.hasOwnProperty.call(userNotifications, type)) {
      const notificationEvent = userNotifications[type];
      const slackChannelValue = [];
      const { enabled, settings } = notificationEvent;
      const channelValue = [];
      if (settings == null ? void 0 : settings.email) channelValue.push(emailChannel);
      if (settings == null ? void 0 : settings.slack) {
        channelValue.push(slackChannel);
        if (((_a = settings == null ? void 0 : settings.slackChannels) == null ? void 0 : _a.length) > 0) {
          settings.slackChannels.forEach((slackChannel2) => {
            const channel = slackChannels == null ? void 0 : slackChannels.find((c) => c.value === slackChannel2);
            if (channel) {
              slackChannelValue.push(`#${channel.label}`);
            }
          });
        }
      }
      const sendToPolyglotPrefix = "NotificationSettingTable.sendToColumnValue.";
      const hasSlackChannels = (settings == null ? void 0 : settings.slack) && (slackChannelValue == null ? void 0 : slackChannelValue.length) > 0;
      const selectedSlackChannels = slackChannels == null ? void 0 : slackChannels.filter((c) => {
        var _a2;
        return (_a2 = settings == null ? void 0 : settings.slackChannels) == null ? void 0 : _a2.includes(c.value);
      });
      const sendToValue = [];
      if ((settings == null ? void 0 : settings.emails) && ((_b = settings == null ? void 0 : settings.emails) == null ? void 0 : _b.length) > 0) sendToValue.push(NotificationSendTo.SPECIFIC);
      if (!(settings == null ? void 0 : settings.emails) || ((_c = settings == null ? void 0 : settings.emails) == null ? void 0 : _c.length) === 0) sendToValue.push(NotificationSendTo.EVERYONE);
      const APPROVER_GROUP_FOR_NOTIFICATION = {
        type: "anyof",
        autoApprove: false,
        presets: enabled && sendToValue.includes(NotificationSendTo.EVERYONE) ? ["everyone"] : [],
        userIds: enabled && sendToValue.includes(NotificationSendTo.SPECIFIC) ? cachedUsers.filter((user) => {
          var _a2;
          return (_a2 = settings == null ? void 0 : settings.emails) == null ? void 0 : _a2.includes(user.userId);
        }).map((user) => user.userId) : []
      };
      let polyglotWhenPrefix = "NotificationSettingTable.whenColumnValue.";
      const tableEntry = {
        type: polyglot.t(`NotificationSettingTable.category.${NotificationCategory.PEOPLE}`),
        name: polyglot.t(`NotificationSettingTable.name.${type}`),
        systemName: NotificationSystemName[type],
        status: enabled,
        sendTo: sendToValue.map((v) => polyglot.t(`${sendToPolyglotPrefix}${v}`)).join(", "),
        sendToEditValue: APPROVER_GROUP_FOR_NOTIFICATION,
        when: polyglot.t(`${polyglotWhenPrefix}${NotificationWhen.IMMEDIATE}`),
        // defaults to immediate for all user notifications
        cronHour: settings == null ? void 0 : settings.cronHour,
        emailEnabled: settings == null ? void 0 : settings.email,
        slackEnabled: settings == null ? void 0 : settings.slack,
        slackChannels: selectedSlackChannels != null ? selectedSlackChannels : [],
        channel: (channelValue == null ? void 0 : channelValue.length) > 0 ? channelValue.join(", ").concat(hasSlackChannels ? " (" : "").concat(`${hasSlackChannels ? slackChannelValue.join(", ") : ""}`).concat(hasSlackChannels ? ")" : "") : ""
      };
      notificationTableFormat.push(tableEntry);
    }
  }
  return notificationTableFormat;
};
const convertNameToNotificationKey = (value) => {
  if (!value) return "";
  const words = value.split(" ");
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  return camelCaseWords.join("");
};
export const mapProfileNotificationsToNotificationForTable = (polyglot, profileNotifications, cachedUsers, slackChannels) => {
  var _a;
  const notificationTableFormat = [];
  const emailChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.EMAIL}`);
  const slackBot = polyglot.t(`NotificationSettingTable.channelColumnValue.SlackBot`);
  const slackChannel = polyglot.t(`NotificationSettingTable.channelColumnValue.${NotificationChannel.SLACK_CHANNEL}`);
  const defaultTypes = profileNotifications.filter((p) => p.domain !== "profileNotification");
  for (const profileNotification of defaultTypes) {
    const {
      id,
      domain,
      name,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      email,
      slack,
      slackChannels: slackChannels2,
      specificUserEmails,
      createdAt,
      daysBefore,
      daysAfter,
      weeklyDay
    } = profileNotification;
    const channelValue = [];
    if (email) channelValue.push(emailChannel);
    if (slack) channelValue.push(slackChannel);
    if ((slackChannels2 == null ? void 0 : slackChannels2.length) > 0) {
      const slackChannelNames = slackChannels2.map((slackChannel2) => `#${slackChannel2}`);
      channelValue.push(`Slack (${slackChannelNames.join(", ")})`);
    }
    const [sendToValue, APPROVER_GROUP_FOR_NOTIFICATION] = getSendToAndApproverGroupForMapping(
      polyglot,
      name,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      specificUserEmails,
      cachedUsers
    );
    const typeForProfileNotification = getCategoryForProfileNotification(polyglot, domain);
    const polyglotPrefix = `NotificationSettingTable.name.`;
    const tableEntry = {
      id,
      type: typeForProfileNotification,
      name: domain in NotificationHumanName ? polyglot.t(`${polyglotPrefix}${domain}`) : name && convertNameToNotificationKey(name) in DefaultNotifications ? polyglot.t(`${polyglotPrefix}${convertNameToNotificationKey(name)}`) : "",
      systemName: domain,
      status: slack || email,
      sendTo: sendToValue,
      sendToEditValue: APPROVER_GROUP_FOR_NOTIFICATION,
      when: getWhenForProfileNotification(
        polyglot,
        domain,
        daysBefore,
        daysAfter,
        name,
        weeklyDay
      ),
      channel: channelValue.join(", "),
      emailEnabled: email,
      slackEnabled: false,
      slackChannels: [],
      createdAt,
      daysBefore,
      daysAfter,
      weeklyDay
    };
    notificationTableFormat.push(tableEntry);
  }
  const customTypes = profileNotifications.filter((p) => p.domain === "profileNotification");
  for (const profileNotification of customTypes) {
    const {
      id,
      domain,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      email,
      slack,
      specificUserEmails,
      createdAt,
      daysBefore,
      daysAfter,
      name,
      cronHour,
      fieldId,
      formId,
      notificationText,
      sendSlackBot
    } = profileNotification;
    const channelValue = [];
    const slackChannelValue = [];
    if (email) channelValue.push(emailChannel);
    if (slack && sendSlackBot) channelValue.push(slackBot);
    if (slack) {
      if (!sendSlackBot) channelValue.push(slackChannel);
      if (((_a = profileNotification.slackChannels) == null ? void 0 : _a.length) > 0) {
        profileNotification.slackChannels.forEach((slackChannel2) => {
          const channel = slackChannels == null ? void 0 : slackChannels.find((c) => c.value === slackChannel2);
          if (channel) {
            slackChannelValue.push(`#${channel.label}`);
          }
        });
      }
    }
    const hasSlackChannels = slack && (slackChannelValue == null ? void 0 : slackChannelValue.length) > 0;
    const selectedSlackChannels = slackChannels == null ? void 0 : slackChannels.filter((c) => {
      var _a2;
      return (_a2 = profileNotification == null ? void 0 : profileNotification.slackChannels) == null ? void 0 : _a2.includes(c.value);
    });
    const [sendToValue, APPROVER_GROUP_FOR_NOTIFICATION] = getSendToAndApproverGroupForMapping(
      polyglot,
      void 0,
      sendAdmin,
      sendManager,
      sendSpecificUsers,
      specificUserEmails,
      cachedUsers
    );
    const typeForProfileNotification = NotificationCategory.PROFILE;
    const tableEntry = {
      id,
      type: typeForProfileNotification,
      name,
      systemName: domain,
      status: slack || email,
      sendTo: sendToValue,
      sendToEditValue: APPROVER_GROUP_FOR_NOTIFICATION,
      when: getWhenForProfileNotification(polyglot, domain, daysBefore, daysAfter),
      emailEnabled: email,
      notificationText,
      slackEnabled: slack,
      slackChannels: selectedSlackChannels != null ? selectedSlackChannels : [],
      channel: (channelValue == null ? void 0 : channelValue.length) > 0 ? channelValue.join(", ").concat(hasSlackChannels ? " (" : "").concat(`${hasSlackChannels ? slackChannelValue.join(", ") : ""}`).concat(hasSlackChannels ? ")" : "") : "",
      createdAt,
      daysBefore,
      daysAfter,
      cronHour,
      employeeTriggerEvent: `${fieldId}/${formId}`,
      sendSlackBot
    };
    notificationTableFormat.push(tableEntry);
  }
  return notificationTableFormat;
};
export const addDefaultUserDateFields = (existingOptions) => {
  const result = [...existingOptions];
  result.push({ value: "dob", label: "Birthday (anniversary of)" });
  result.push({ value: "startDate", label: "Start date (anniversary of)" });
  result.push({ value: "leaveDate", label: "Leave date (anniversary of)" });
  return result;
};
