"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditDevices = (props) => {
  const { polyglot } = usePolyglot();
  const { onSave, config } = props;
  const formik = useFormik({
    initialValues: {
      chooser: (config == null ? void 0 : config.chooser) || "admin",
      filter: (config == null ? void 0 : config.filter) || null
    },
    onSubmit: (values) => {
      onSave(values);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("EditDevices.title") }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt10 }), children: polyglot.t("EditDevices.desc") }),
    /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.mt30, gap: spacing.g20 }, children: /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        "aria-labelledby": "demo-radio-buttons-group-label",
        name: "radio-buttons-group",
        onChange: (event) => {
          formik.setFieldValue("chooser", event.target.value);
        },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "admin",
              checked: formik.values.chooser === "admin",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: polyglot.t("EditDevices.adminOrManager") })
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "new-joiner",
              checked: formik.values.chooser === "new-joiner",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: polyglot.t("EditDevices.new") })
            }
          )
        ]
      }
    ) }),
    formik.values.chooser && /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, style: { marginTop: "40px" }, colorVariant: "primary", sizeVariant: "large", children: polyglot.t("General.save") })
  ] }) });
};
