"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOwnership, DeviceType } from "@v2/feature/device/device.interface";
import { DeviceTypesValueLabelOptions } from "@v2/feature/device/device.util";
import { dateFieldTest, isoDateFormat } from "@v2/infrastructure/date/date-format.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const SuperAdminDeviceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  device,
  refreshDevice
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(SuperAdminDeviceDetailsDrawerContent, { device, refreshDevice, setIsOpen })
  }
) });
export const SuperAdminDeviceDetailsDrawerContent = ({
  device,
  refreshDevice,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const initialValues = {
    serialNumber: (_a = device.serialNumber) != null ? _a : null,
    internalNotes: (_b = device.internalNotes) != null ? _b : null,
    price: (_c = device.price) != null ? _c : null,
    type: (_d = device.type) != null ? _d : DeviceType.Laptop,
    contractLength: (_e = device.contractLength) != null ? _e : null,
    contractStartDate: (_f = device == null ? void 0 : device.contractStartDate) != null ? _f : null,
    externallyInvoiced: (_g = device == null ? void 0 : device.externallyInvoiced) != null ? _g : null
  };
  const validationSchema = yup.object({
    serialNumber: yup.string().nullable().notRequired(),
    internalNotes: yup.string().nullable().notRequired(),
    price: yup.number().nullable().notRequired(),
    type: yup.string().required("Device type is required"),
    contractLength: yup.number().integer().nullable().notRequired(),
    contractStartDate: yup.string().test(dateFieldTest).nullable().notRequired(),
    externallyInvoiced: yup.string().nullable().matches(isoDateFormat, "Please use YYYY-MM-DD format.").notRequired()
  });
  const patchDeviceEntity = useCallback(
    (formData) => __async(void 0, null, function* () {
      try {
        const {
          serialNumber,
          internalNotes,
          customerNotes,
          price,
          type,
          contractLength,
          contractStartDate,
          externallyInvoiced
        } = formData;
        setLoading(true);
        yield DeviceAPI.updateDeviceByIdAsSuperadmin(device.id, {
          serialNumber,
          internalNotes,
          customerNotes,
          price: Number(price),
          type,
          contractLength: Number(contractLength),
          contractStartDate,
          externallyInvoiced: externallyInvoiced ? externallyInvoiced : null
        });
        showMessage("Device successfully updated.", "success");
        yield refreshDevice();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Device could not be updated. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }),
    [setIsOpen, showMessage, device, refreshDevice]
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      return patchDeviceEntity(values);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Device details" }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: device.modelName }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 2 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Serial Number",
          name: "serialNumber",
          value: formik.values.serialNumber,
          onChange: formik.handleChange,
          error: formik.touched.serialNumber && Boolean(formik.errors.serialNumber),
          helperText: formik.touched.serialNumber && formik.errors.serialNumber,
          clearText: () => formik.setFieldValue("serialNumber", ""),
          size: "small"
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "type",
          label: "Type",
          options: DeviceTypesValueLabelOptions,
          value: (_h = formik.values.type) != null ? _h : void 0,
          compareValue: (_i = formik.values.type) != null ? _i : void 0,
          onChange: formik.handleChange,
          error: formik.touched.type && !!formik.errors.type,
          helperText: formik.touched.type && formik.errors.type
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Internal notes",
          name: "internalNotes",
          value: formik.values.internalNotes,
          onChange: formik.handleChange,
          error: formik.touched.internalNotes && Boolean(formik.errors.internalNotes),
          helperText: formik.touched.internalNotes && formik.errors.internalNotes,
          clearText: () => formik.setFieldValue("internalNotes", ""),
          size: "small"
        }
      ),
      device.ownership === DeviceOwnership.Rental && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Price",
          name: "price",
          value: formik.values.price,
          onChange: formik.handleChange,
          error: formik.touched.price && Boolean(formik.errors.price),
          helperText: formik.touched.price && formik.errors.price,
          clearText: () => formik.setFieldValue("price", ""),
          size: "small"
        }
      ),
      device.ownership === DeviceOwnership.Rental && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Contract length",
          name: "contractLength",
          value: formik.values.contractLength,
          onChange: formik.handleChange,
          error: formik.touched.contractLength && Boolean(formik.errors.contractLength),
          helperText: formik.touched.contractLength && formik.errors.contractLength,
          clearText: () => formik.setFieldValue("contractLength", ""),
          size: "small"
        }
      ),
      device.ownership === DeviceOwnership.Rental && /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_j = formik.values.contractStartDate) != null ? _j : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("contractStartDate", value);
            }
          },
          name: "contractStartDate",
          label: "Contract start date",
          error: !!formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate),
          helperText: formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate)
        }
      ),
      device.ownership === DeviceOwnership.Rental && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Externally invoiced",
          name: "externallyInvoiced",
          placeholder: "YYYY-MM-DD",
          value: formik.values.externallyInvoiced,
          onChange: formik.handleChange,
          error: formik.touched.externallyInvoiced && Boolean(formik.errors.externallyInvoiced),
          helperText: formik.touched.externallyInvoiced && formik.errors.externallyInvoiced,
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("externallyInvoiced", null),
          size: "small",
          disabled: true
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: "30px" }, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading, fullWidth: true, sizeVariant: "medium", colorVariant: "primary" }) })
  ] }) });
};
