"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Box from "@mui/material/Box";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewAPI } from "@/v2/feature/growth/reviews/api-client/review.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const ReviewBulkPublishModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  participantIds
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    ReviewBulkPublishContent,
    {
      reviewCycle,
      onClose,
      refresh,
      participantIds
    }
  ) });
};
const ReviewBulkPublishContent = ({
  reviewCycle,
  onClose,
  refresh,
  participantIds
}) => {
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const handleBulkPublish = () => __async(void 0, null, function* () {
    try {
      if (!reviewCycle) {
        showMessage(polyglot.t("ReviewBulkPublishModal.errorMessages.noCycleFound"), "info");
        return;
      }
      setLoading(true);
      yield ReviewAPI.bulkPublishReviewResult(participantIds, reviewCycle == null ? void 0 : reviewCycle.id);
      showMessage(polyglot.t("ReviewBulkPublishModal.successMessages.publish"), "success");
      refresh == null ? void 0 : refresh();
      onClose();
    } catch (error) {
      showMessage(
        polyglot.t("ReviewBulkPublishModal.errorMessages.publish", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
      " ",
      polyglot.t("ReviewBulkPublishModal.publishResults")
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ReviewBulkPublishModal.publishResultsDesc") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ReviewBulkPublishModal.publishResultsNote") }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "button",
        onClick: handleBulkPublish,
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("ReviewBulkPublishModal.confirm"),
        loading,
        fullWidth: true
      }
    ) })
  ] });
};
