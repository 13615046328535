"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { useQuestionFocus } from "@/v2/feature/growth/reviews/features/review-answer/hooks/use-question-focus.hook";
import {
  FocusState,
  QuestionComment
} from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/question-comment.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SingleAnswerQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  showError,
  isFocused,
  registerMethods
}) => {
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = (answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) || { answer: "", comment: "" };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);
  const optionKeys = useMemo(() => {
    return (question == null ? void 0 : question.answerOptions) ? Object.keys(question.answerOptions) : [];
  }, [question == null ? void 0 : question.answerOptions]);
  const handleChoiceChange = useCallback(
    (value) => {
      if (!entry) return;
      handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
    },
    [currentComment, question.id, entry, handleAnswerChange]
  );
  const handleCommentChange = useCallback(
    (value) => {
      if (!entry) return;
      handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
    },
    [currentAnswer, entry, question.id, handleAnswerChange]
  );
  const {
    hasKeyboardFocus,
    highlightedOptionIndex,
    setHighlightedOptionIndex,
    focusState,
    setFocusState,
    registerCommentFieldRef,
    registerOptionRef,
    handleContainerKeyDown,
    handleCommentKeyDown,
    containerRef
  } = useQuestionFocus({
    question,
    optionKeys,
    isFocused,
    registerMethods,
    handleOptionAction: handleChoiceChange
  });
  const getRadioStyles = useCallback(
    (key, index) => {
      const isSelected = key === currentAnswer;
      const isHighlighted = hasKeyboardFocus && focusState === FocusState.OPTIONS && highlightedOptionIndex === index;
      return {
        padding: 0,
        "& .MuiSvgIcon-root": {
          outline: isHighlighted && !isSelected ? `1px dashed ${themeColors.DarkGrey}` : "none",
          borderRadius: "50%"
        },
        "& .MuiSvgIcon-root, & .MuiTouchRipple-root": {
          transition: "all 0.2s ease"
        }
      };
    },
    [currentAnswer, hasKeyboardFocus, highlightedOptionIndex, focusState]
  );
  if (!question || !reviewType || !entry) return null;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      ref: containerRef,
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g24,
        outline: "none"
      },
      onKeyDown: handleContainerKeyDown,
      tabIndex: 0,
      "data-testid": "single-answer-container",
      children: [
        /* @__PURE__ */ jsx(FormControl, { component: "fieldset", sx: { width: "100%", margin: 0 }, children: /* @__PURE__ */ jsx(
          RadioGroup,
          {
            "aria-labelledby": "radio-buttons-group-label",
            name: "answer",
            value: currentAnswer || "",
            onChange: (e) => {
              handleChoiceChange(e.target.value);
            },
            children: (question == null ? void 0 : question.answerOptions) && Object.keys(question == null ? void 0 : question.answerOptions).map((a, index) => /* @__PURE__ */ jsx(
              Box,
              {
                onClick: () => {
                  setFocusState(FocusState.OPTIONS);
                  setHighlightedOptionIndex(index);
                  if (containerRef.current) {
                    containerRef.current.focus();
                  }
                },
                children: /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: a,
                    checked: a === currentAnswer,
                    control: /* @__PURE__ */ jsx(
                      StyledRadio,
                      {
                        inputRef: (el) => registerOptionRef(a, el),
                        tabIndex: -1,
                        onFocus: () => {
                          setFocusState(FocusState.OPTIONS);
                          setHighlightedOptionIndex(index);
                          if (containerRef.current) {
                            containerRef.current.focus();
                          }
                        },
                        sx: getRadioStyles(a, index)
                      }
                    ),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: question == null ? void 0 : question.answerOptions[a] }),
                    sx: {
                      py: spacing.p10,
                      margin: 0,
                      gap: spacing.g10,
                      px: 0
                    }
                  }
                )
              },
              a
            ))
          }
        ) }),
        showError && !currentAnswer && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: showError ? themeColors.darkRed : themeColors.DarkGrey }, children: "This field is required" }),
        question.hasComment && /* @__PURE__ */ jsx(
          QuestionComment,
          {
            question,
            currentComment,
            handleCommentChange,
            showError,
            registerCommentFieldRef,
            setFocusState,
            onKeyDown: handleCommentKeyDown
          }
        )
      ]
    }
  );
};
