"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  IconMapping,
  PayrollStatusLabel
} from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { ExternalPayRunEntryStateEnum } from "@/v2/feature/payroll/payroll-external.interface";
export function cleanPayCodeValue(rawCode) {
  return rawCode.trim().toUpperCase().replace(/^[^A-Z]+|[^A-Z0-9_]+/g, "");
}
export const getUserStatusFromGlobalPayrunEntry = (payrunEntry, payrun) => {
  const { membersByUserId, startDate, endDate } = payrun;
  const member = membersByUserId[payrunEntry.userId];
  if (!member) {
    return {
      label: PayrollStatusLabel.NotInPayroll,
      icon: IconMapping[PayrollStatusLabel.NotInPayroll]
    };
  }
  const { startDate: memberStartDate, leaveDate: memberLeaveDate } = member;
  if (memberStartDate && memberStartDate >= startDate && memberStartDate <= endDate) {
    return {
      label: PayrollStatusLabel.NewJoiner,
      icon: IconMapping[PayrollStatusLabel.NewJoiner]
    };
  }
  if (memberLeaveDate && memberLeaveDate >= startDate && memberLeaveDate <= endDate) {
    return {
      label: PayrollStatusLabel.Leaver,
      icon: IconMapping[PayrollStatusLabel.Leaver]
    };
  }
  return {
    label: PayrollStatusLabel.Current,
    icon: IconMapping[PayrollStatusLabel.Current]
  };
};
export const mapGlobalPayrollEntryToPayrunEntry = (userId, payrunResults) => {
  return payrunResults.map((pr) => {
    var _a, _b, _c, _d, _e, _f;
    const grossValue = pr.entries[0].paylines.find((pl) => pl.code === "BASIC");
    const basicValue = pr.entries[0].paylines.find((pl) => pl.code === "BASIC_FIXED");
    const taxValue = pr.entries[0].paylines.find((pl) => pl.code === "TAX");
    const totalCost = pr.entries[0].paylines.reduce((sum, payline) => sum + payline.amount, 0);
    const taxYearNumber = Number(pr.taxYear.replace("Year", ""));
    const grossPay = (_a = grossValue == null ? void 0 : grossValue.amount) != null ? _a : 0;
    const taxAmount = (_b = taxValue == null ? void 0 : taxValue.amount) != null ? _b : 0;
    const takeHomePay = grossPay - taxAmount;
    return __spreadProps(__spreadValues({}, pr), {
      name: "test",
      metadata: {
        paymentDate: pr.paymentDate,
        payPeriod: pr.payPeriod,
        period: pr.period,
        companyId: pr.companyId,
        payrollId: pr.payrollId,
        startDate: pr.startDate,
        endDate: pr.endDate,
        taxYear: taxYearNumber,
        taxYearStart: pr.taxYearStart,
        state: pr.state === "DRAFT" ? ExternalPayRunEntryStateEnum.Open : pr.state === "COMPLETED" ? ExternalPayRunEntryStateEnum.Finalised : ExternalPayRunEntryStateEnum.Open,
        adjustments: 0,
        aeNotEnroledWarning: false,
        employeeId: userId.toString(),
        employeeNi: 0,
        employeePensionContribution: 0,
        employerNi: 0,
        employerPensionContribution: 0,
        gross: (_c = grossValue == null ? void 0 : grossValue.amount) != null ? _c : 0,
        grossForNi: 0,
        grossForTax: 0,
        hasFps: true,
        isClosed: true,
        isLeaver: false,
        isNewStarter: true,
        netPay: 0,
        nilPaid: false,
        ordinal: 0,
        paymentMethod: "",
        payrollCode: pr.payrollId.toString(),
        takeHomePay,
        taxAmount: (_d = taxValue == null ? void 0 : taxValue.amount) != null ? _d : 0,
        totalCost
      },
      id: pr.id.toString(),
      url: "",
      totals: {
        basicPay: (_e = basicValue == null ? void 0 : basicValue.amount) != null ? _e : 0,
        gross: (_f = grossValue == null ? void 0 : grossValue.amount) != null ? _f : 0,
        grossForNi: 0,
        grossForTax: 0,
        employerNi: 0,
        employeeNi: 0,
        tax: 0,
        netPay: 0,
        adjustments: 0,
        additions: 0,
        deductions: 0,
        takeHomePay: 0,
        dednsFromNetPay: 0,
        pensionableEarnings: 0,
        pensionablePay: 0,
        employeePensionContribution: 0,
        employerPensionContribution: 0,
        empeePenContribnsNotPaid: 0,
        totalCost
      }
    });
  });
};
