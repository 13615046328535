"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UploadInput } from "@/component/forms/UploadInput";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ImportPayrunDrawer = ({
  isOpen,
  setIsOpen,
  payrun,
  onImportSuccess,
  onUploadFailed
}) => {
  const { polyglot } = usePolyglot();
  const { payrollId, taxYear, payPeriod, period } = payrun;
  const [problems, setProblems] = useState([]);
  const importTemplateLink = `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/payrun-entry-import-template.csv`;
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("GlobalPayroll.import.header") }),
    /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
      `${polyglot.t("GlobalPayroll.import.body")} `,
      /* @__PURE__ */ jsx("a", { href: importTemplateLink, rel: "noreferrer", style: { color: themeColors.DarkGrey }, children: polyglot.t("GlobalPayroll.import.thisTemplate") })
    ] }),
    /* @__PURE__ */ jsx(
      UploadInput,
      {
        endpoint: `/apiv2/company/salary/payroll/${payrollId}/global/payrun/${taxYear}/${payPeriod}/${period}/import`,
        onChange: (response, file, status) => {
          setProblems([]);
          if (response == null ? void 0 : response.problems.length) {
            setProblems(response.problems);
            return;
          }
          if (response == null ? void 0 : response.id) {
            onImportSuccess();
            return;
          }
          if (status === "error_upload") {
            onUploadFailed();
            return;
          }
        }
      }
    ),
    problems.length > 0 && /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mb: spacing.m10 }, children: polyglot.t("GlobalPayroll.import.errorMessages.body") }),
      problems.sort((a, b) => a.row - b.row).map((problem, idx) => /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: `Entry ${problem.row}: ${problem.description}` }, idx))
    ] })
  ] }) });
};
