"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DevicesSelectTable } from "@/v2/feature/device/components/devices-list-overview/devices-select-table.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const DeviceSelectPage = ({ selectedDevice, onContinueClick, onCloseClick }) => {
  const [inventoryPossessions, setInventoryPossessions] = useState([]);
  const [deviceTransits, setDeviceTransits] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(selectedDevice ? [selectedDevice.deviceId] : []);
  const refreshInventoryState = useCallback(() => {
    DeviceAPI.getInInventoryDevices().then(
      (devices) => devices.sort(
        (a, b) => {
          var _a, _b, _c, _d, _e, _f, _g, _h;
          return ((_b = (_a = a.device) == null ? void 0 : _a.modelName) != null ? _b : "").localeCompare((_d = (_c = b.device) == null ? void 0 : _c.modelName) != null ? _d : "") || ((_f = (_e = a.device) == null ? void 0 : _e.serialNumber) != null ? _f : "").localeCompare((_h = (_g = b.device) == null ? void 0 : _g.serialNumber) != null ? _h : "");
        }
      )
    ).then(setInventoryPossessions);
    DeviceAPI.getInTransitDevices().then(setDeviceTransits);
  }, []);
  useEffect(refreshInventoryState, [refreshInventoryState]);
  useEscapeKey(onCloseClick);
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, pt: spacing.p30, position: "absolute", zIndex: 10, inset: 0, background: themeColors.white }, children: [
    /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", right: spacing.m50 }), onClick: onCloseClick, children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey })) }),
    /* @__PURE__ */ jsxs(Stack, { sx: { width: "min(800px,90vw)", mx: "auto", overflow: "hidden" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mb: spacing.mb10 }), children: "Select from inventory" }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb20 }), children: "Make sure your new joiner has a device ready for when they start." }),
      /* @__PURE__ */ jsxs(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "83%",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          children: [
            /* @__PURE__ */ jsx(Stack, { sx: { overflowY: "auto" }, children: /* @__PURE__ */ jsx(
              DevicesSelectTable,
              {
                devicePossessions: inventoryPossessions,
                deviceTransits,
                rowClick: (device) => setSelectedDeviceIds([device.deviceId]),
                showNotes: true,
                refresh: () => Promise.resolve(),
                showAssignedTo: true,
                deviceLocation: "inventory",
                checkedDeviceIds: selectedDeviceIds,
                onDeviceCheckChange: (id, checked) => {
                  setSelectedDeviceIds(checked ? [id] : []);
                }
              }
            ) }),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "primary",
                style: {
                  marginTop: "20px",
                  marginBottom: "40px",
                  flexShrink: 0,
                  opacity: selectedDeviceIds.length ? 1 : 0
                },
                disabled: !selectedDeviceIds.length,
                onClick: () => {
                  const selectedDevices = inventoryPossessions.filter((d) => selectedDeviceIds.includes(d.deviceId));
                  if (selectedDevices.length === 1) onContinueClick(selectedDevices[0]);
                },
                children: "Continue"
              }
            )
          ]
        }
      )
    ] })
  ] });
};
