"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const AuthPageLiner = () => {
  return /* @__PURE__ */ jsxs(Stack, { sx: { display: { xs: "none", sm: "block", md: "block", lg: "block" } }, children: [
    /* @__PURE__ */ jsx(
      Typography,
      {
        variant: "headline1",
        sx: {
          color: themeColors.DarkGrey,
          fontSize: { xs: "2rem", sm: "3rem", md: "4rem", lg: "4rem" },
          lineHeight: { xs: "2.25rem", sm: "3.25rem", md: "4.25rem", lg: "4.25rem" },
          letterSpacing: { xs: "-2.18px", sm: "-1.58px", md: "-2.18px", lg: "-2.18px" },
          fontWeight: 400
        },
        children: "Welcome to Zelt!"
      }
    ),
    /* @__PURE__ */ jsxs(
      Typography,
      {
        variant: "headline1",
        sx: {
          color: themeColors.DarkGrey,
          fontSize: { xs: "2rem", sm: "3rem", md: "4rem", lg: "4rem" },
          lineHeight: { xs: "2.25rem", sm: "3.25rem", md: "4.25rem", lg: "4.25rem" },
          letterSpacing: { xs: "-2.18px", sm: "-1.58px", md: "-2.18px", lg: "-2.18px" },
          fontWeight: 400
        },
        children: [
          "Your all in one ",
          /* @__PURE__ */ jsx("br", {}),
          "people platform"
        ]
      }
    )
  ] });
};
