"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from "react";
import { capitalize } from "lodash";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/components/chart-heatmap/chart-heatmap-table.scss";
import { SurveyQuestionDrawer } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-question-drawer.component";
import {
  MeasureBy,
  ShowBy
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
const COLOR_RANGE = 5;
const HEAT_MAP_COLORS = ["#F5ABA3", "#FCEFED", "#F0FAF2", "#B3E5C0", "#74CF8C"];
const styles = {
  tableContainer: {
    overflowX: "auto",
    width: "100%"
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
    borderCollapse: "collapse"
  },
  headerRow: {
    textAlign: "left",
    borderBottom: borders.background,
    boxSizing: "border-box"
  },
  headerCell: {
    textAlign: "left",
    height: "60px",
    width: "550px",
    maxWidth: "550px",
    minWidth: "550px",
    verticalAlign: "bottom",
    paddingBottom: "15px",
    paddingRight: "16px",
    paddingLeft: "16px"
  },
  columnHeaderCell: {
    textAlign: "left",
    height: "60px",
    width: "60px",
    verticalAlign: "bottom",
    paddingBottom: "28px"
  },
  rotatedText: {
    display: "block",
    maxWidth: "118px",
    maxHeight: "60px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    transform: "rotate(-45deg)",
    textAlign: "left"
  },
  rowCell: {
    height: "60px",
    width: "550px",
    maxWidth: "550px",
    minWidth: "550px",
    paddingRight: "40px",
    paddingLeft: "16px",
    borderBottom: borders.background
  },
  dataCell: {
    height: "60px",
    width: "60px",
    maxWidth: "60px",
    boxSizing: "border-box",
    textAlign: "center",
    verticalAlign: "middle"
  },
  valueText: {
    display: "inline-block",
    maxWidth: "60px",
    maxHeight: "60px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "center",
    verticalAlign: "middle"
  }
};
export const ChartHeatmapTable = React.memo(
  ({
    series = [],
    categories = [],
    filter = "",
    loadingHeatmap = false,
    growthFactors = {},
    measureByFilter,
    surveyHeatmap,
    questionMap = {},
    showByFilter
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const getMinMaxValues = useCallback(
      (data) => {
        var _a;
        if (!data || data.length === 0 || !((_a = data[0]) == null ? void 0 : _a.data)) {
          return Array(categories.length || 1).fill({ min: 0, max: 100 });
        }
        const numberOfItems = data[0].data.length;
        if (numberOfItems === 0) {
          return [{ min: 0, max: 100 }];
        }
        return Array.from({ length: numberOfItems }, (_, idx) => {
          const rowData = data.map((item) => {
            var _a2;
            return (_a2 = item.data) == null ? void 0 : _a2[idx];
          }).filter((item) => item !== null && item !== void 0);
          if (!rowData.length) {
            return { min: 0, max: 100 };
          }
          return {
            min: Math.min(...rowData),
            max: Math.max(...rowData)
          };
        });
      },
      [categories.length]
    );
    const minMaxByRow = useMemo(() => getMinMaxValues(series), [getMinMaxValues, series]);
    const getColorForValue = useCallback(
      (value, categoryIndex) => {
        if (value === null || value === void 0) {
          return themeColors.Background;
        }
        const { min, max } = minMaxByRow[categoryIndex] || { min: 0, max: 100 };
        const stepSize = Math.round((max - min) / COLOR_RANGE);
        const thresholds = [min, ...[1, 2, 3, 4].map((factor) => min + factor * stepSize)];
        let colorIndex = thresholds.findIndex((threshold, index) => {
          return value >= threshold && (thresholds[index + 1] === void 0 || value < thresholds[index + 1]);
        });
        if (colorIndex === -1) {
          colorIndex = HEAT_MAP_COLORS.length - 1;
        }
        return HEAT_MAP_COLORS[colorIndex];
      },
      [minMaxByRow]
    );
    const handleQuestionSelect = useCallback(
      (itemName, categoryId) => {
        if (!surveyHeatmap) return;
        const selectedCategory = surveyHeatmap.find((cat) => cat.name === itemName);
        if (!selectedCategory) return;
        const question = selectedCategory.result.resultByQuestion.find((obj) => obj.question.id === categoryId) || null;
        setSelectedQuestion(question);
        setIsOpen(true);
      },
      [surveyHeatmap]
    );
    const handleCloseDrawer = useCallback(() => {
      setIsOpen(false);
      setSelectedQuestion(null);
    }, []);
    const tableWidth = useMemo(() => {
      const baseWidth = 610;
      const seriesLength = Math.max(1, series.length);
      const totalWidth = seriesLength * 60 + baseWidth;
      const windowWidth = typeof window !== "undefined" ? window.innerWidth : 1200;
      const maxWidth = windowWidth - 300;
      return totalWidth >= maxWidth ? "100%" : `${totalWidth}px`;
    }, [series.length]);
    const renderLoadingHeaders = useMemo(
      () => /* @__PURE__ */ jsxs("tr", { style: styles.headerRow, children: [
        /* @__PURE__ */ jsx("th", { style: styles.headerCell, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: capitalize(filter || "") }) }),
        Array.from({ length: 11 }).map((_, idx) => /* @__PURE__ */ jsx("th", { style: styles.columnHeaderCell, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", sx: styles.rotatedText }) }, `skeleton-header-${idx}`))
      ] }),
      [filter]
    );
    const renderDataHeaders = useMemo(
      () => /* @__PURE__ */ jsxs("tr", { style: styles.headerRow, children: [
        /* @__PURE__ */ jsx("th", { style: styles.headerCell, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: capitalize(filter || "") }) }),
        series.map((item) => /* @__PURE__ */ jsx("th", { style: styles.columnHeaderCell, children: /* @__PURE__ */ jsx(StyledTooltip, { title: capitalize(item.name || ""), placement: "top", children: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", sx: styles.rotatedText, children: capitalize(item.name || "") }) }) }) }, `header-${item.name}`))
      ] }),
      [filter, series]
    );
    const renderLoadingRows = useMemo(
      () => Array.from({ length: 11 }).map((_, rowIdx) => /* @__PURE__ */ jsxs("tr", { style: { borderBottom: borders.background }, children: [
        /* @__PURE__ */ jsx("td", { style: __spreadProps(__spreadValues({}, styles.rowCell), { borderBottom: borders.background }), children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", height: 60, width: "100%" }) }),
        Array.from({ length: 11 }).map((_2, colIdx) => /* @__PURE__ */ jsx("td", { style: styles.dataCell, children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", height: 60, width: 60 }) }, `skeleton-cell-${rowIdx}-${colIdx}`))
      ] }, `skeleton-row-${rowIdx}`)),
      []
    );
    const renderDataRows = useMemo(
      () => categories.map((category, index) => /* @__PURE__ */ jsxs("tr", { children: [
        /* @__PURE__ */ jsx("td", { style: styles.rowCell, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: showByFilter === ShowBy.Questions ? questionMap[category] || category : category }) }),
        series.map((item) => {
          var _a, _b;
          const value = (_b = (_a = item.data) == null ? void 0 : _a[index]) != null ? _b : null;
          const color = getColorForValue(value, index);
          const isClickable = showByFilter === ShowBy.Questions && value !== null;
          return /* @__PURE__ */ jsx(
            "td",
            {
              className: isClickable ? "heatmap-td" : "heatmap-noevent-td",
              style: __spreadProps(__spreadValues({}, styles.dataCell), {
                background: color,
                cursor: isClickable ? "pointer" : "default"
              }),
              onClick: () => {
                if (isClickable && item.name) {
                  handleQuestionSelect(item.name, category);
                }
              },
              children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: styles.valueText, children: value === null ? "\u2013" : value })
            },
            `cell-${category}-${item.name}`
          );
        })
      ] }, `row-${category}`)),
      [categories, series, getColorForValue, questionMap, showByFilter, handleQuestionSelect]
    );
    const drawerMeasureByFilter = useMemo(
      () => measureByFilter === MeasureBy.Average ? "avg" : measureByFilter.toLowerCase(),
      [measureByFilter]
    );
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx("div", { style: __spreadProps(__spreadValues({}, styles.tableContainer), { maxWidth: tableWidth }), children: /* @__PURE__ */ jsxs("table", { style: styles.table, children: [
        /* @__PURE__ */ jsx("thead", { children: loadingHeatmap ? renderLoadingHeaders : renderDataHeaders }),
        /* @__PURE__ */ jsx("tbody", { children: loadingHeatmap ? renderLoadingRows : renderDataRows })
      ] }) }),
      /* @__PURE__ */ jsx(
        SurveyQuestionDrawer,
        {
          isOpen,
          setIsOpen,
          onClose: handleCloseDrawer,
          growthFactors,
          questionItem: selectedQuestion,
          impactResult: void 0,
          impactFilter: "",
          measureByFilter: drawerMeasureByFilter,
          gridsXs: [6, 4]
        }
      )
    ] });
  }
);
