"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { getRuleTypeString } from "@v2/components/user-select-type/user-select.component";
import { SettingsReadOnlyLine } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { UserContractTypeEnum } from "@v2/feature/user/features/user-profile/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { useParams } from "react-router-dom";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { getUserSelectOptionLabel } from "@/v2/components/user-select-type/user-select.interface";
import { EditPaymentTypeMembersDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-setting-edit-members-drawer.component";
import { SettingsPageSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const PaymentTypeMembersSettingsPage = () => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const params = useParams();
  const { cachedUsers } = useCachedUsers();
  const typeId = Number(params.id);
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), { suspense: false });
  const { data: entities } = useApiClient(CompanyEndpoints.getCompanyEntities(), { suspense: false });
  const { data: paymentTypeSetting, mutate: refreshPaymentTypeSetting } = useApiClient(
    PaymentTypeSettingsEndpoints.getTypeSettingById(typeId),
    {
      suspense: false
    }
  );
  const members = useMemo(() => {
    var _a2, _b;
    const memberIds = new Set((_a2 = paymentTypeSetting == null ? void 0 : paymentTypeSetting.selectedMembersIds) != null ? _a2 : []);
    const viaBenefitsSet = new Set((_b = paymentTypeSetting == null ? void 0 : paymentTypeSetting.viaBenefit) != null ? _b : []);
    return cachedUsers.filter((u) => memberIds.has(u.userId)).map((u) => {
      var _a3;
      return {
        userId: u.userId,
        name: (_a3 = u.displayName) != null ? _a3 : `${u.firstName} ${u.lastName}`,
        viaBenefit: viaBenefitsSet.has(u.userId)
      };
    });
  }, [cachedUsers, paymentTypeSetting]);
  const filteredUsers = useMemo(() => {
    if (!searchInput) return members ? [...members] : [];
    const search = searchInput.toLowerCase();
    return members ? [...members.filter((u) => u && u.name.toLowerCase().includes(search))] : [];
  }, [members, searchInput]);
  const ruleString = getRuleTypeString(
    (_a = paymentTypeSetting == null ? void 0 : paymentTypeSetting.customRule) != null ? _a : "",
    sites,
    departments,
    entities,
    [UserContractTypeEnum.Payrolled, UserContractTypeEnum.NonPayrolled],
    polyglot
  );
  const tableColumns = useMemo(
    () => [
      {
        header: () => /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: polyglot.t("General.name") }),
        accessorFn: (row) => row,
        id: "name",
        enableSorting: true,
        cell: ({
          row: {
            original: { userId, name, viaBenefit }
          }
        }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId, size: "s20" }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t(name) }),
          viaBenefit && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "(via Benefit)" })
        ] }, userId)
      }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(SettingsPageSection, { title: polyglot.t("General.members"), onEdit: () => setIsEditDrawerOpen(true), children: [
    paymentTypeSetting && paymentTypeSetting.membersRule && /* @__PURE__ */ jsx(
      SettingsReadOnlyLine,
      {
        field: polyglot.t("AbsencePolicyMembersSection.members"),
        value: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: getUserSelectOptionLabel(paymentTypeSetting.membersRule, polyglot) })
      }
    ),
    ruleString && /* @__PURE__ */ jsx(
      SettingsReadOnlyLine,
      {
        field: polyglot.t("AbsencePolicyMembersSection.rule"),
        value: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: ruleString })
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("AbsencePolicyMembersSection.list") }),
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => setSearchInput(e.target.value),
        style: { width: "572px" }
      }
    ),
    filteredUsers && filteredUsers.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { width: "600px" }, children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredUsers,
        columnData: tableColumns,
        initialSort: [{ id: "displayName", desc: true }],
        fixedLastColumn: false
      }
    ) }),
    paymentTypeSetting && /* @__PURE__ */ jsx(
      EditPaymentTypeMembersDrawer,
      {
        isOpen: isEditDrawerOpen,
        setIsOpen: setIsEditDrawerOpen,
        paymentTypeSetting,
        refresh: refreshPaymentTypeSetting
      }
    )
  ] });
};
