"use strict";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
export function camelCaseToCapitalCase(inputString) {
  return inputString.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
    return str.toUpperCase();
  });
}
export function getDisplayName(user) {
  var _a, _b;
  return (_b = user && ((_a = user.displayName) != null ? _a : `${user.firstName} ${user.lastName}`)) != null ? _b : "";
}
export function undefinedForEmptyString(inputString) {
  const trimmedVal = inputString.trim();
  return typeof trimmedVal !== "string" || trimmedVal.length > 0 ? trimmedVal : null;
}
export function formatAsGBPCurrency(inputNumber, decimals) {
  return "\xA3 ".concat(new Intl.NumberFormat("en-GB", { minimumFractionDigits: decimals != null ? decimals : 0 }).format(inputNumber));
}
export function formatAsCompensationCurrency(currency, inputNumber) {
  const currencyCode = !currency || currency.trim() === "" ? DEFAULT_CURRENCY : currency;
  const cf = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return cf.format(inputNumber).replace(/^(\D+)/, "$1 ").replace(/\s+/, " ");
}
export function capitalize(inputString) {
  if (!inputString) return "";
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}
