"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const ThemeStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 3px)",
    right: "calc(50% - 3px)"
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themeColors.DarkGrey
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: themeColors.DarkGrey
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: themeColors.lightGrey,
    borderTopWidth: 2
  }
}));
export const ThemeStepIconRoot = styled("div")(({ ownerState }) => __spreadProps(__spreadValues({
  color: themeColors.lightGrey,
  display: "flex",
  height: 22,
  alignItems: "center"
}, ownerState.active && {
  color: themeColors.DarkGrey
}), {
  "& .ThemeStepIcon-completedIcon": {
    color: themeColors.DarkGrey,
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  },
  "& .ThemeStepIcon-circle": {
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  }
}));
export const ThemeStepper = styled(Stepper)(() => ({
  "&.MuiStepper-root": {
    marginTop: "20px",
    ".MuiStep-root": {
      position: "relative",
      paddingLeft: 0,
      paddingRight: 0,
      ".MuiStepLabel-iconContainer": {
        paddingRight: 0
      },
      ".MuiStepLabel-labelContainer": {
        position: "absolute",
        top: "-20px",
        display: "flex",
        transform: "translate(-50%, 0px)",
        // align:center
        width: "fit-content",
        ".MuiStepLabel-label": __spreadProps(__spreadValues({}, themeFonts.headline3), {
          whiteSpace: "nowrap"
        }),
        ".MuiStepLabel-label.Mui-disabled": __spreadProps(__spreadValues({}, themeFonts.paragraph), {
          color: themeColors.Grey
        })
      }
    },
    ".MuiStep-root:first-of-type": {
      ".MuiStepLabel-labelContainer": {
        transform: "unset"
        // align:left
      }
    },
    ".MuiStep-root:last-of-type": {
      ".MuiStepLabel-labelContainer": {
        transform: "translate(calc(-100% + 6px), 0px)"
        // aligh:right
      }
    }
  }
}));
