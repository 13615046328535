"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import { DaysOptions } from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserCalendarView = ({
  calendarData,
  weekDaysFromMonToSun,
  headerAction
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs("div", { className: "cs-separation", children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          width: "100%",
          cursor: "pointer"
        },
        onClick: () => headerAction == null ? void 0 : headerAction(),
        children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserCalendarView.calendar") }) })
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%" }, children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5, width: "100%" }, children: /* @__PURE__ */ jsxs("table", { className: "cw-table", children: [
      /* @__PURE__ */ jsx("thead", { children: /* @__PURE__ */ jsx("tr", { children: weekDaysFromMonToSun.map((d, idx) => /* @__PURE__ */ jsx("th", { className: "cw-table-head", children: /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
          },
          children: /* @__PURE__ */ jsx(
            Typography,
            {
              variant: "paragraphSmall",
              sx: {
                color: themeColors.DarkGrey,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              },
              children: DaysOptions(polyglot)[d]
            }
          )
        },
        `${idx}-${d}`
      ) }, `${d}-ucvb-week`)) }) }),
      /* @__PURE__ */ jsx("tbody", { children: calendarData })
    ] }) }) })
  ] });
};
