"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { Suspense, useCallback, useContext, useMemo } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { format, isBefore, isToday, isWithinInterval } from "date-fns";
import { debounce } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { USER_PERSONAL_TAB } from "@/lib/routes";
import { stickHeaderStyle } from "@/v2/components/table/basic-table.component";
import { PaginationDetail } from "@/v2/components/table/pagination-detail.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import {
  StickyStyleTD,
  StickyStyleTH,
  StyleHolidayHeader,
  StyleTD,
  StyleTH,
  StyleTR
} from "@/v2/feature/calendar/calendar-table.interface";
import {
  getBoxBackgroundColor,
  getEndFullOrHalfDayType,
  getStartFullOrHalfDayType,
  getTextColor,
  getWeekAlignment,
  getWeekWidth
} from "@/v2/feature/calendar/calendar.util";
import "@/v2/feature/calendar/features/style/calendar-timeline.css";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { convertUTCDateToLocalDate, isISODateSameAsLocaleDate } from "@/v2/infrastructure/date/date-format.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { Table, TableBody, TableHead, TableHeadRow } from "@/v2/styles/table.styles";
const CalendarEvent = React.lazy(
  () => import("@/v2/feature/calendar/features/components/calendar-event.component").then((module) => ({
    default: module.CalendarEvent
  }))
);
export const CalendarTimeline = ({
  timelineDays,
  usersCalendarEvents,
  calendarEvents,
  setIsOpen,
  setSelectedCalendarEvent,
  refreshAbsences,
  totalItems,
  activeView,
  advanceLoading,
  attendanceSettings,
  pagination,
  setPagination,
  totalPages,
  calendarAdvEventsTimeline,
  setLoading
}) => {
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const context = getScopesContext(state.user);
  const hasCalendarAll = hasScopes(["calendar:all"], context);
  const { polyglot } = usePolyglot();
  const today = useMemo(() => /* @__PURE__ */ new Date(), []);
  const isEventExist = calendarEvents.some((holiday) => {
    return timelineDays.some(
      (day) => holiday.endDate ? isWithinInterval(day, {
        start: new Date(holiday.startDate),
        end: new Date(holiday.endDate)
      }) || isISODateSameAsLocaleDate(holiday.startDate, day) || isISODateSameAsLocaleDate(holiday.endDate, day) : isISODateSameAsLocaleDate(holiday.startDate, day)
    );
  });
  const debouncedHandlePagination = useMemo(
    () => debounce((pageIndex) => {
      setLoading(true);
      setPagination((prev) => __spreadProps(__spreadValues({}, prev), {
        pageIndex
      }));
    }, 300),
    [setPagination, setLoading]
  );
  const handleNextPage = useCallback(() => {
    if (pagination.pageIndex < totalPages && !advanceLoading) {
      debouncedHandlePagination(pagination.pageIndex + 1);
    }
  }, [pagination.pageIndex, totalPages, advanceLoading, debouncedHandlePagination]);
  const handlePreviousPage = useCallback(() => {
    if (pagination.pageIndex > 1 && !advanceLoading) {
      debouncedHandlePagination(pagination.pageIndex - 1);
    }
  }, [pagination.pageIndex, advanceLoading, debouncedHandlePagination]);
  const handleState = useCallback(
    (page) => {
      const pageState = Math.max(1, Math.min(totalPages, page));
      debouncedHandlePagination(pageState);
    },
    [totalPages, debouncedHandlePagination]
  );
  return /* @__PURE__ */ jsxs("div", { style: { overflowX: "auto", whiteSpace: "nowrap" }, children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, stickHeaderStyle), { height: "calc(100vh - 210px)" }), children: /* @__PURE__ */ jsxs(Table, { children: [
      /* @__PURE__ */ jsx(CalendarHeader, { timelineDays, today, activeView }),
      /* @__PURE__ */ jsx(TableHead, { style: { display: !isEventExist ? "none" : "", position: "sticky", top: 26, zIndex: 3 }, children: /* @__PURE__ */ jsxs(TableHeadRow, { style: { borderBottom: isEventExist ? "none" : borders.middle }, children: [
        /* @__PURE__ */ jsx(
          StickyStyleTH,
          {
            className: "styled-th",
            style: {
              minWidth: "130px",
              maxWidth: "130px",
              width: "130px"
            }
          }
        ),
        timelineDays.map((day, idx) => {
          const convertedDateUTC = convertUTCDateToLocalDate(new Date(day));
          const filteredEvents = calendarEvents.filter((c) => {
            return c.endDate ? isWithinInterval(day, {
              start: new Date(c.startDate),
              end: new Date(c.endDate)
            }) || isISODateSameAsLocaleDate(c.startDate, day) || isISODateSameAsLocaleDate(c.endDate, day) : isISODateSameAsLocaleDate(c.startDate, day);
          });
          return /* @__PURE__ */ jsx(
            StyleHolidayHeader,
            {
              className: "timeline-days-styled-th",
              style: { paddingTop: spacing.p4, minWidth: "40px" },
              children: /* @__PURE__ */ jsxs(
                Box,
                {
                  className: "holiday-header",
                  sx: {
                    gap: spacing.g5,
                    marginTop: spacing.m2
                  },
                  children: [
                    filteredEvents.slice(0, 2).map((event, idx2) => /* @__PURE__ */ jsx(StyledTooltip, { title: event.name, children: /* @__PURE__ */ jsx(
                      Box,
                      {
                        sx: {
                          display: "flex",
                          justifyContent: getWeekAlignment(event, convertedDateUTC),
                          width: "100%",
                          position: "relative",
                          cursor: hasCalendarAll ? "pointer" : "default"
                        },
                        onClick: () => {
                          if (hasCalendarAll) {
                            setIsOpen(true);
                            const calendarEvent = __spreadProps(__spreadValues({}, event), {
                              startFullOrHalfDay: getStartFullOrHalfDayType(polyglot, event),
                              endFullOrHalfDay: getEndFullOrHalfDayType(polyglot, event),
                              multipleDays: !!event.endDate
                            });
                            setSelectedCalendarEvent(calendarEvent);
                          }
                        },
                        children: /* @__PURE__ */ jsx(
                          Box,
                          {
                            className: "holiday-box",
                            sx: {
                              backgroundColor: event.color ? event.color : themeColors.GreyLight,
                              alignItems: "center",
                              width: getWeekWidth(event, convertedDateUTC)
                            },
                            children: /* @__PURE__ */ jsx(
                              Typography,
                              {
                                variant: "paragraphSmall",
                                color: getTextColor(event),
                                className: "textOverflow",
                                sx: {
                                  display: activeView === "Week" ? "flex" : "none"
                                },
                                children: event.name
                              }
                            )
                          }
                        )
                      },
                      `${idx2}-event`
                    ) })),
                    filteredEvents.length > 2 && /* @__PURE__ */ jsx(
                      StyledTooltip,
                      {
                        title: filteredEvents.slice(2).map((event) => event.name).join(", "),
                        children: /* @__PURE__ */ jsx(Box, { className: "holiday-box", children: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("CalendarTimeline.events", { len: filteredEvents.length - 2 }) }) })
                      }
                    )
                  ]
                }
              )
            },
            `${idx}-header`
          );
        })
      ] }) }),
      /* @__PURE__ */ jsx(
        CalendarBody,
        {
          usersCalendarEvents,
          calendarAdvEventsTimeline,
          attendanceSettings,
          activeView,
          refreshAbsences,
          advanceLoading
        }
      )
    ] }) }),
    /* @__PURE__ */ jsx(
      PaginationDetail,
      {
        totalPageCount: totalPages,
        totalRecordCount: totalItems,
        current: pagination.pageIndex,
        onNextAction: handleNextPage,
        nextDisabled: pagination.pageIndex === totalPages || advanceLoading,
        onPreviousAction: handlePreviousPage,
        previousDisabled: pagination.pageIndex === 1 || advanceLoading,
        handleState,
        paginationState: pagination,
        setPaginationState: (pageSize, pageIndex) => {
          setPagination({ pageSize, pageIndex });
        },
        paginationArray: [20, 50]
      }
    )
  ] });
};
const CalendarHeader = React.memo(
  ({
    timelineDays,
    today,
    activeView
  }) => {
    const { polyglot } = usePolyglot();
    return /* @__PURE__ */ jsx(TableHead, { style: { position: "sticky", top: 0, zIndex: 3 }, children: /* @__PURE__ */ jsxs(TableHeadRow, { children: [
      /* @__PURE__ */ jsx(StickyStyleTH, { className: "styled-th", style: { minWidth: "130px", maxWidth: "130px", width: "130px" }, children: polyglot.t("CalendarTimeline.name") }),
      timelineDays.map((day, idx) => {
        const isTodayDate = isToday(day);
        const isBeforeTodayDate = isBefore(day, today);
        return /* @__PURE__ */ jsx(
          StyleTH,
          {
            className: "timeline-days-styled-th",
            style: {
              minWidth: activeView === "Month" ? "40px" : "130px",
              maxWidth: activeView === "Month" ? "40px" : "130px",
              width: activeView === "Month" ? "40px" : "130px"
            },
            children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(
              Box,
              {
                sx: __spreadValues({
                  borderRadius: radius.br10,
                  padding: "0px 5px 0px 5px"
                }, isTodayDate ? { backgroundColor: themeColors.DarkGrey, color: themeColors.white } : isBeforeTodayDate ? { color: themeColors.Grey } : { backgroundColor: themeColors.white, color: themeColors.Grey }),
                children: activeView === "Month" ? format(day, "EEE d") : format(day, "EEE, dd MMM")
              }
            ) })
          },
          `${idx}-names-people`
        );
      })
    ] }) });
  }
);
const CalendarBody = React.memo(
  ({
    usersCalendarEvents,
    calendarAdvEventsTimeline,
    attendanceSettings,
    activeView,
    refreshAbsences,
    advanceLoading
  }) => {
    const routerHistory = useHistory();
    const { polyglot } = usePolyglot();
    const handleUserClick = useCallback(
      (user) => {
        var _a;
        routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: (_a = user == null ? void 0 : user.userId) != null ? _a : "" }));
      },
      [routerHistory]
    );
    return /* @__PURE__ */ jsx(TableBody, { children: usersCalendarEvents == null ? void 0 : usersCalendarEvents.map((user, userIndex) => {
      var _a, _b, _c;
      return /* @__PURE__ */ jsxs(StyleTR, { children: [
        /* @__PURE__ */ jsx(StickyStyleTD, { className: "data-row-user", children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              gap: spacing.s1,
              display: "flex",
              alignItems: "center"
            },
            className: "user-name-avatar",
            onClick: () => handleUserClick(user),
            children: [
              /* @__PURE__ */ jsx(UserAvatar, { userId: user == null ? void 0 : user.userId, size: "s20" }),
              /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "paragraph",
                  sx: {
                    display: "block",
                    cursor: "pointer",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%"
                  },
                  children: polyglot.t((_b = user == null ? void 0 : user.name) != null ? _b : "")
                }
              )
            ]
          }
        ) }),
        (_c = user == null ? void 0 : user.entries) == null ? void 0 : _c.map((entry, entryIndex) => {
          const advEntries = calendarAdvEventsTimeline ? calendarAdvEventsTimeline[user.userId] : void 0;
          const advEntry = advEntries && (advEntries == null ? void 0 : advEntries[entryIndex]) ? advEntries == null ? void 0 : advEntries[entryIndex] : void 0;
          return /* @__PURE__ */ jsx(
            CalendarTableCell,
            {
              advEntry,
              entry,
              attendanceSettings,
              activeView,
              userId: user.userId,
              refreshAbsences,
              advanceLoading,
              userIndex,
              entryIndex
            },
            `${userIndex}-${entryIndex}`
          );
        })
      ] }, (_a = user == null ? void 0 : user.userId) != null ? _a : userIndex);
    }) });
  }
);
const CalendarTableCell = React.memo(
  ({
    advEntry,
    entry,
    attendanceSettings,
    activeView,
    userId,
    refreshAbsences,
    advanceLoading,
    userIndex,
    entryIndex
  }) => {
    const boxBackgroundColor = useMemo(() => getBoxBackgroundColor(advEntry, entry, attendanceSettings), [
      advEntry,
      entry,
      attendanceSettings
    ]);
    const MemoizedCalendarEvent = React.memo(CalendarEvent);
    if (advanceLoading) {
      return /* @__PURE__ */ jsx(
        StyleTD,
        {
          className: "data-row-entry",
          style: {
            minWidth: "48px"
          },
          children: /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: "47px", animation: "pulse" })
        },
        `${userIndex}-${entryIndex}-skeleton`
      );
    }
    return /* @__PURE__ */ jsx(
      StyleTD,
      {
        className: "data-row-entry",
        style: __spreadValues({
          minWidth: "48px"
        }, boxBackgroundColor),
        children: /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx("div", {}), children: /* @__PURE__ */ jsx(
          MemoizedCalendarEvent,
          {
            activeView,
            entry,
            advEntry,
            userId,
            refreshAbsences
          }
        ) })
      },
      `${userIndex}-${entryIndex}-entry`
    );
  }
);
