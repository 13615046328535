"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { getPublicHolidayCalendarOptions, NoPublicHolidayCalendarOption } from "@v2/feature/absence/absence.util";
import { getOverlapsRuleOptions } from "@v2/feature/absence/company/policies/pages/absence-company-settings-general.page";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const AbsenceSettingsEditDrawer = ({ isOpen, setIsOpen, absenceSettings, refresh }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      holidayCountry: (_b = (_a = getPublicHolidayCalendarOptions().find((option) => option.value === absenceSettings.holidayCountry)) == null ? void 0 : _a.value) != null ? _b : NoPublicHolidayCalendarOption.value,
      preventOwnOverlaps: absenceSettings.preventOwnOverlaps,
      showOverlap: absenceSettings.showOverlap,
      overlapsRule: absenceSettings.overlapsRule
    },
    validationSchema: yup.object({
      holidayCountry: yup.string().required(polyglot.t("AbsenceGeneralSettings.holidayCalendarIsRequired")),
      showOverlap: yup.boolean().nullable().notRequired(),
      overlapsRule: yup.string().required(polyglot.t("AbsenceGeneralSettings.overlapsRuleRequired"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const update = {
          holidayCountry: values.holidayCountry,
          preventOwnOverlaps: values.preventOwnOverlaps,
          showOverlap: values.showOverlap,
          overlapsRule: values.overlapsRule
        };
        yield AbsenceAPI.updateCompanyAbsenceSettings(update);
        showMessage(polyglot.t("AbsenceGeneralSettings.settingsUpdatedSuccessfully"), "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("AbsenceGeneralSettings.errorMessage", { nestError: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceCompanySettingsGeneralPage.other") }),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "holidayCountry",
            label: polyglot.t("AbsenceGeneralSettings.defaultHolidayCountry"),
            options: getPublicHolidayCalendarOptions(),
            value: formik.values.holidayCountry,
            compareValue: formik.values.holidayCountry,
            error: !!formik.errors.holidayCountry && formik.touched.holidayCountry,
            onChange: formik.handleChange,
            helperText: formik.touched.holidayCountry && Boolean(formik.errors.holidayCountry)
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("AbsenceGeneralSettings.requestingAbsence") }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AbsenceGeneralSettings.preventOwnOverlaps") }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: polyglot.t("AbsenceGeneralSettings.preventOwnOverlapsDesc") })
          ] }),
          /* @__PURE__ */ jsx(
            SwitchComponent,
            {
              checked: formik.values.preventOwnOverlaps,
              name: "prevent-own-overlaps",
              onChange: (_e, enabled) => {
                formik.setFieldValue("preventOwnOverlaps", enabled);
              }
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AbsenceGeneralSettings.overlapSummary") }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: polyglot.t("AbsenceGeneralSettings.overlapSummaryDesc") })
          ] }),
          /* @__PURE__ */ jsx(
            SwitchComponent,
            {
              checked: formik.values.showOverlap,
              name: "show-overlap",
              onChange: (_e, enabled) => {
                formik.setFieldValue("showOverlap", enabled);
              }
            }
          )
        ] }),
        formik.values.showOverlap && /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "overlapsRule",
            label: polyglot.t("AbsenceGeneralSettings.showOverlapWith"),
            options: getOverlapsRuleOptions(polyglot),
            value: formik.values.overlapsRule,
            error: !!formik.errors.overlapsRule && formik.touched.overlapsRule,
            onChange: formik.handleChange,
            helperText: formik.touched.overlapsRule && Boolean(formik.errors.overlapsRule)
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true
          }
        ) })
      ] }) })
    }
  );
};
