"use strict";
import {
  ATTENDANCE_COMPANY_TIMESHEETS_ROUTE,
  ATTENDANCE_TEAM_TIMESHEETS_ROUTE,
  ATTENDANCE_ME_TIMESHEETS_ROUTE,
  ATTENDANCE_COMPANY_ACTIVE_SHIFTS_ROUTE,
  ATTENDANCE_TEAM_ACTIVE_SHIFTS_ROUTE
} from "@/lib/routes";
export const TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES = {
  company: ATTENDANCE_COMPANY_ACTIVE_SHIFTS_ROUTE,
  team: ATTENDANCE_TEAM_ACTIVE_SHIFTS_ROUTE
};
export const TIME_ATTENDANCE_TIMESHEETS_ROUTES = {
  company: ATTENDANCE_COMPANY_TIMESHEETS_ROUTE,
  team: ATTENDANCE_TEAM_TIMESHEETS_ROUTE,
  me: ATTENDANCE_ME_TIMESHEETS_ROUTE
};
export const TIME_ATTENDANCE_TIMESHEETS_ROUTES_SCOPES = {
  company: ["attendance:all"],
  team: ["attendance:manager"],
  me: ["attendance"]
};
export const TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES_SCOPES = {
  company: ["attendance:all"],
  team: ["attendance:manager"]
};
