"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ReviewAnswerAPI {
  static saveAnswersAsDraft(reviewAnswer, entryId, cycleId, revieweeId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-answer/draft/${entryId}/${cycleId}/${revieweeId}`, reviewAnswer)).data;
    });
  }
  static submitAnswers(reviewAnswer, entryId, cycleId, revieweeId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-answer/submit/${entryId}/${cycleId}/${revieweeId}`, reviewAnswer)).data;
    });
  }
  static getAnswer(entryId, cycleId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/review-answer/${entryId}/${cycleId}`)).data;
    });
  }
  static getBulkAnswerExport(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/review-answer/${cycleId}/bulk-answer-export`, {
        responseType: "blob"
      })).data;
    });
  }
}
export class ReviewAnswerEndpoints {
  static getAnswer(entryId, cycleId) {
    return {
      url: `/apiv2/review-answer/${entryId}/${cycleId}`
    };
  }
}
