"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { StyledRadio } from "@v2/styles/radio.styles";
import useMessage from "@/hooks/notification.hook";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const MoveToSectionContent = ({
  onClose,
  action,
  sections,
  questionId
}) => {
  const { polyglot } = usePolyglot();
  const [selectedSectionId, setSelectedSectionId] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSave = () => __async(void 0, null, function* () {
    try {
      if (selectedSectionId && questionId) {
        setLoading(true);
        yield action(selectedSectionId, questionId);
        onClose();
        showMessage("Moved in to section successfully", "success");
      } else {
        showMessage("Please select a section first", "error");
      }
    } catch (error) {
      showMessage("Something went wrong", "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }, children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Select a section" }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m24 }, children: sections && sections.length > 0 ? /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
        RadioGroup,
        {
          name: "section-select",
          onChange: (event) => {
            setSelectedSectionId(event.target.value);
          },
          children: sections.map((section) => /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: section.id,
              checked: selectedSectionId === section.id,
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: section.name }) })
            },
            section.id
          ))
        }
      ) }) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "You dont have any section" }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        type: "button",
        onClick: onSave,
        loading,
        fullWidth: true
      }
    ) })
  ] });
};
