"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { MultiUserAvatar } from "@v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@v2/components/typography/typography.component";
import { BenefitPaymentActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/benefit-payment-action-item.component";
import { DeviceTransitActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/device-transit-action-item.component";
import { ExpenseRequestActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/expense-request-action-item.component";
import { InvoiceRequestActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/invoice-request-action-item.component";
import { PendingPHCancellationActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/pending-ph-cancellation-action-item.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateDomainTypes } from "@v2/infrastructure/i18n/translate.util";
import { iconSize } from "@v2/styles/menu.styles";
import { format, isPast } from "date-fns";
import { uniq } from "lodash";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortDate } from "@/v2/components/table/table-sorting.util";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { getDetailByDomain } from "@/v2/feature/dashboard/dashboard.util";
import { AttendanceActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/attendance-action-item.component";
import { BenefitRequestActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/benefit-request-action-item.component";
import { DocumentActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/document-action-item.component";
import { RequestFormActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/request-form-action-item.component";
import { ReviewActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/review-action-item.component";
import { ShiftActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/shift-action-item.component";
import { SurveyActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/survey-action-item.component";
import { TaskActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/task-action-item.component";
import { TimeActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-action-item.component";
import {
  DomainTypes,
  KindTypes
} from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { getBelongsToByMemberRule } from "@/v2/feature/document-new/document-new.util";
import { ActionItemsDateFilter, ActionItemsTabFilter } from "@/v2/feature/task/task.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserAPI } from "@/v2/feature/user/user.api";
import { ReachType } from "@/v2/interfaces/general.interface";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const ActionItemsList = ({
  reach,
  actionItems,
  refreshActionItems,
  loading,
  actionStatus,
  setActionStatus,
  timeUnitStatus,
  setTimeUnitStatus
}) => {
  const { polyglot } = usePolyglot();
  const [globalState, dispatch] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const TabFilter = useMemo(
    () => [
      ...reach === ReachType.Company ? [{ name: polyglot.t("TasksListPage.all"), value: ActionItemsTabFilter.All }] : [],
      ...reach === ReachType.Team ? [
        {
          name: polyglot.t("TasksListPage.pendingActionFromMyTeam"),
          value: ActionItemsTabFilter.PendingActionFromTeam
        },
        { name: polyglot.t("TasksListPage.requestedByMyTeam"), value: ActionItemsTabFilter.RequestedByTeam }
      ] : [],
      ...reach === ReachType.Me ? [
        { name: polyglot.t("TasksListPage.pendingActionFromMe"), value: ActionItemsTabFilter.PendingActionFromMe },
        { name: polyglot.t("TasksListPage.requestedByMe"), value: ActionItemsTabFilter.RequestedByMe }
      ] : [],
      { name: polyglot.t("TasksListPage.approvedRequest"), value: ActionItemsTabFilter.Approved }
    ],
    [polyglot, reach]
  );
  const refreshItems = useCallback(() => __async(void 0, null, function* () {
    if (refreshActionItems) yield refreshActionItems();
  }), [refreshActionItems]);
  const { getCachedUserById, loaded } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState(void 0);
  const [searchInput, setSearchInput] = useState("");
  const updateUserFeaturesActionItemsTabFilter = useCallback(
    (newFilterValue) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "user",
          "actionItems",
          `${reach}TabFilter`,
          newFilterValue
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        console.error(`Failed to save user preferences for action items tab filter:  ${nestErrorMessage(error)}`);
      }
    }),
    [dispatch, reach]
  );
  const updateUserFeaturesActionItemsTimeFilter = useCallback(
    (newFilterValue) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "user",
          "actionItems",
          `${reach}TimeFilter`,
          newFilterValue
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        console.error(`Failed to save user preferences for action items tab filter:  ${nestErrorMessage(error)}`);
      }
    }),
    [dispatch, reach]
  );
  const columns = useMemo(
    () => [
      {
        header: () => polyglot.t("UserActionList.employee"),
        accessorFn: (row) => row,
        id: "employeeName",
        enableSorting: false,
        maxSize: 110,
        cell: ({ row: { original } }) => {
          if (original.type === DomainTypes.Documents || original.type === DomainTypes.Contracts) {
            return /* @__PURE__ */ jsx("div", { children: original ? getBelongsToByMemberRule(original, "paragraph", void 0) : /* @__PURE__ */ jsx(EmptyCell, {}) });
          }
          if (original.type === DomainTypes.Reviews) {
            return /* @__PURE__ */ jsx("div", { children: original.reviewerId ? /* @__PURE__ */ jsx(UserCell, { userId: original.reviewerId }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
          }
          return /* @__PURE__ */ jsx(Box, { children: original.userId ? /* @__PURE__ */ jsx(UserCell, { userId: original.userId }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        id: "type",
        header: () => polyglot.t("UserActionList.type"),
        accessorFn: (row) => translateDomainTypes(row.type, polyglot),
        enableSorting: false,
        maxSize: 60,
        cell: ({ row: { original } }) => {
          if (!(original == null ? void 0 : original.type)) return /* @__PURE__ */ jsx(EmptyCell, {});
          return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: translateDomainTypes(original.type, polyglot) });
        }
      },
      {
        id: "detail",
        header: () => polyglot.t("UserActionList.detail"),
        accessorFn: (row) => getDetailByDomain(row, getCachedUserById, currentUser, polyglot),
        enableSorting: false,
        maxSize: 280,
        cell: ({ row: { original } }) => {
          if (!original) return /* @__PURE__ */ jsx(EmptyCell, {});
          return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: getDetailByDomain(original, getCachedUserById, currentUser, polyglot) });
        }
      },
      {
        id: "updatedAt",
        header: () => polyglot.t("UserActionList.dueDate"),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.dueDate || item.updatedAt),
        accessorFn: (row) => row,
        maxSize: 100,
        cell: ({ row: { original } }) => {
          const recordKey = "dueDate" in original && (original == null ? void 0 : original.dueDate) ? original == null ? void 0 : original.dueDate : original.updatedAt;
          const dateToDisplay = recordKey ? new LocalDate(recordKey).getDate() : new LocalDate().getDate();
          const isOverdue = isPast(dateToDisplay);
          return /* @__PURE__ */ jsx(Box, { children: recordKey ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: isOverdue ? "RedDark" : "DarkGrey", children: format(dateToDisplay, "d MMM yyyy") }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      ...actionStatus === ActionItemsTabFilter.Approved ? [
        {
          id: "approvedBy",
          header: () => polyglot.t("UserActionList.approvedBy"),
          accessorFn: (row) => row,
          maxSize: 100,
          enableSorting: false,
          cell: ({ row: { original } }) => {
            var _a, _b;
            const approvedOrRejectedBy = uniq([
              ...(_a = original.approvedByIds) != null ? _a : [],
              ...(_b = original.rejectedByIds) != null ? _b : []
            ]);
            return /* @__PURE__ */ jsx(Box, { children: approvedOrRejectedBy ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: approvedOrRejectedBy, showLimit: 5 }) }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
          }
        }
      ] : [
        {
          id: "approver",
          header: () => polyglot.t("UserActionList.approver"),
          accessorFn: (row) => row,
          enableSorting: false,
          maxSize: 100,
          cell: ({ row: { original } }) => {
            return /* @__PURE__ */ jsx(Box, { children: original.approverSteps ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
              MultiUserAvatar,
              {
                userIds: uniq(
                  original.approverSteps.flatMap((approverStep) => {
                    var _a;
                    return (_a = approverStep.approversIds) != null ? _a : [];
                  }).filter(
                    (userId) => !original.approvedByIds.includes(userId) && !original.rejectedByIds.includes(userId)
                  )
                ),
                showLimit: 5
              }
            ) }) : original.approverIds && original.approverIds.length > 1 ? /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: original.approverIds, showLimit: 5 }) : original.approverIds && original.approverIds.length === 1 ? /* @__PURE__ */ jsx(UserCell, { userId: original.approverIds[0] }) : original.approverId ? /* @__PURE__ */ jsx(UserCell, { userId: original.approverId }) : /* @__PURE__ */ jsx(EmptyCell, {}) });
          }
        }
      ]
    ],
    [currentUser, getCachedUserById, polyglot, actionStatus]
  );
  const handleRowClick = useCallback(
    (row) => __async(void 0, null, function* () {
      setSelectedRow(row.original);
    }),
    [setSelectedRow]
  );
  const filteredActionData = useMemo(() => {
    let filteredData = actionItems;
    if (searchInput) {
      filteredData = actionItems == null ? void 0 : actionItems.filter((a) => {
        var _a;
        const titleString = getDetailByDomain(a, getCachedUserById, currentUser, polyglot);
        const typeString = ((_a = a.type) == null ? void 0 : _a.toLowerCase()) || "";
        const searchString = searchInput.toLowerCase();
        return titleString && titleString.toLowerCase().includes(searchString) || typeString.includes(searchString);
      });
    }
    return filteredData;
  }, [searchInput, actionItems, currentUser, getCachedUserById, polyglot]);
  const timeSpanOptions = useMemo(() => {
    return [
      {
        handler: () => __async(void 0, null, function* () {
          setTimeUnitStatus == null ? void 0 : setTimeUnitStatus(ActionItemsDateFilter.InSevenDays);
          yield updateUserFeaturesActionItemsTimeFilter(ActionItemsDateFilter.InSevenDays);
        }),
        label: polyglot.t("TasksListPage.inSevenDays"),
        disabled: false
      },
      {
        handler: () => __async(void 0, null, function* () {
          setTimeUnitStatus == null ? void 0 : setTimeUnitStatus(ActionItemsDateFilter.InThirtyDays);
          yield updateUserFeaturesActionItemsTimeFilter(ActionItemsDateFilter.InThirtyDays);
        }),
        label: polyglot.t("TasksListPage.inThirtyDays"),
        disabled: false
      },
      {
        handler: () => __async(void 0, null, function* () {
          setTimeUnitStatus == null ? void 0 : setTimeUnitStatus(ActionItemsDateFilter.Overdue);
          yield updateUserFeaturesActionItemsTimeFilter(ActionItemsDateFilter.Overdue);
        }),
        label: polyglot.t("TasksListPage.overdue"),
        disabled: false
      }
    ];
  }, [polyglot, setTimeUnitStatus, updateUserFeaturesActionItemsTimeFilter]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          gap: spacing.g5
        },
        children: [
          reach !== ReachType.Me && /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: timeSpanOptions,
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: polyglot.t(`TasksListPage.${timeUnitStatus}`),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end",
                style: { borderRadius: radius.br16 }
              }
            }
          ),
          /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              filters: TabFilter,
              setFilterValue: (filterValue) => {
                setActionStatus(filterValue);
                updateUserFeaturesActionItemsTabFilter(filterValue);
              },
              filterValue: actionStatus != null ? actionStatus : ""
            }
          ),
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              handleChange: (e) => {
                setSearchInput(e.target.value);
              }
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          mt: spacing.s2
        },
        children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: filteredActionData ? [...filteredActionData] : [],
            columnData: columns,
            initialSort: [{ id: "updatedAt", desc: true }],
            loading: loading || !loaded,
            rowClick: handleRowClick,
            stickyHeader: true,
            stickyHeaderHeight: reach === ReachType.Me ? "calc(100vh - 161px)" : "calc(100vh - 361px)"
          }
        )
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Tasks && /* @__PURE__ */ jsx(TaskActionItem, { selectedRow, refresh: refreshItems, afterClose: () => setSelectedRow(void 0) }),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Expenses && /* @__PURE__ */ jsx(
      ExpenseRequestActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Invoices && /* @__PURE__ */ jsx(
      InvoiceRequestActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.CustomBenefitPayments && /* @__PURE__ */ jsx(
      BenefitPaymentActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.CustomBenefitRequests && /* @__PURE__ */ jsx(
      BenefitRequestActionItem,
      {
        benefitRequestTodo: selectedRow,
        refresh: refreshItems,
        onClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Absence && selectedRow.kind === KindTypes.PendingRequest && /* @__PURE__ */ jsx(TimeActionItem, { selectedRow, refresh: refreshItems, afterClose: () => setSelectedRow(void 0) }),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Absence && selectedRow.kind === KindTypes.PendingPHCancellation && /* @__PURE__ */ jsx(
      PendingPHCancellationActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Devices && selectedRow.kind === KindTypes.DeviceTransit && /* @__PURE__ */ jsx(
      DeviceTransitActionItem,
      {
        selectedRow,
        afterClose: () => setSelectedRow(void 0),
        refresh: refreshItems
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Documents && selectedRow.kind === KindTypes.MissingDocuments && /* @__PURE__ */ jsx(
      DocumentActionItem,
      {
        selectedRow,
        refresh: () => __async(void 0, null, function* () {
          yield refreshItems();
          setSelectedRow(void 0);
        }),
        afterClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Contracts && selectedRow.kind === KindTypes.PendingContracts && /* @__PURE__ */ jsx(
      DocumentActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Reviews && /* @__PURE__ */ jsx(ReviewActionItem, { selectedRow, afterClose: () => setSelectedRow(void 0) }),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Surveys && /* @__PURE__ */ jsx(SurveyActionItem, { selectedRow, afterClose: () => setSelectedRow(void 0) }),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Shifts && /* @__PURE__ */ jsx(
      ShiftActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => setSelectedRow(void 0)
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Attendance && /* @__PURE__ */ jsx(
      AttendanceActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => {
          setSelectedRow(void 0);
        }
      }
    ),
    (selectedRow == null ? void 0 : selectedRow.type) === DomainTypes.Requests && /* @__PURE__ */ jsx(
      RequestFormActionItem,
      {
        selectedRow,
        refresh: refreshItems,
        afterClose: () => setSelectedRow(void 0)
      }
    )
  ] });
};
