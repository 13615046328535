"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { DevicePossessionType } from "@v2/feature/device/device.interface";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { themeFonts } from "@v2/styles/fonts.styles";
import {
  deviceListSx,
  deviceSx,
  deviceTermSx,
  devicetitleSx,
  deviceValueSx
} from "@/v2/feature/device/features/device-cards/components/devices-styles.layout";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DevicePossessionInformationCard = ({ devicePossession }) => {
  var _a, _b, _c, _d, _e, _f;
  const { getCachedUserById } = useCachedUsers();
  switch (devicePossession.possessionType) {
    case DevicePossessionType.User:
      const user = getCachedUserById(devicePossession.possessionId);
      return /* @__PURE__ */ jsxs(Box, { component: "header", sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: devicetitleSx, children: "In use by" }),
        /* @__PURE__ */ jsxs(Box, { component: "dl", sx: deviceListSx, children: [
          user && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
            /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Employee" }),
            /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
              /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "s20" }),
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { marginLeft: spacing.m10 }), children: user.displayName })
            ] }) })
          ] }),
          ((_b = (_a = user == null ? void 0 : user.role) == null ? void 0 : _a.department) == null ? void 0 : _b.name) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
            /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Department" }),
            /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: user == null ? void 0 : user.role.department.name })
          ] }),
          ((_d = (_c = user == null ? void 0 : user.role) == null ? void 0 : _c.jobPosition) == null ? void 0 : _d.title) && /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
            /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Role" }),
            /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: (_f = (_e = user == null ? void 0 : user.role) == null ? void 0 : _e.jobPosition) == null ? void 0 : _f.title })
          ] })
        ] })
      ] });
    case DevicePossessionType.CompanySite:
      return /* @__PURE__ */ jsxs(Box, { component: "header", sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: devicetitleSx, children: "In use by" }),
        /* @__PURE__ */ jsx(Box, { component: "section", children: /* @__PURE__ */ jsx(Box, { component: "dl", sx: deviceListSx, children: /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
          /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Owner" }),
          /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: "Company Site" })
        ] }) }) })
      ] });
    case DevicePossessionType.ZeltStorage:
      return /* @__PURE__ */ jsxs(Box, { component: "header", sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: devicetitleSx, children: "In use by" }),
        /* @__PURE__ */ jsx(Box, { component: "section", children: /* @__PURE__ */ jsx(Box, { component: "dl", sx: deviceListSx, children: /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
          /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Owner" }),
          /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: "Zelt Storage" })
        ] }) }) })
      ] });
    case DevicePossessionType.ZeltStock:
      return /* @__PURE__ */ jsxs(Box, { component: "header", sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: devicetitleSx, children: "In use by" }),
        /* @__PURE__ */ jsx(Box, { component: "section", children: /* @__PURE__ */ jsx(Box, { component: "dl", sx: deviceListSx, children: /* @__PURE__ */ jsxs(Box, { sx: deviceSx, children: [
          /* @__PURE__ */ jsx(Typography, { component: "dt", variant: "body2", sx: deviceTermSx, children: "Owner" }),
          /* @__PURE__ */ jsx(Typography, { component: "dd", variant: "body2", sx: deviceValueSx, children: "Zelt Stock" })
        ] }) }) })
      ] });
    default:
      return /* @__PURE__ */ jsxs(Box, { component: "header", sx: { width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: devicetitleSx, children: "In use by" }),
        /* @__PURE__ */ jsx(Box, { component: "section", children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mb: 2 }), children: "Possession Information not available." }) })
      ] });
  }
};
