"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { nestErrorMessage } from "@/lib/errors";
import { APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { IconContentActionCard } from "@/v2/components/theme-components/icon-content-action-card.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppIntegrationAPI, AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { AppButton } from "@/v2/feature/app-integration/components/app-button.component";
import { AppIntroduction } from "@/v2/feature/app-integration/components/app-introduction.component";
import { AppRequestAPI, AppRequestEndpoints } from "@/v2/feature/app-integration/features/app-request/app-request.api";
import {
  RequestStatus
} from "@/v2/feature/app-integration/features/app-request/app-request.interface";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { borders } from "@/v2/styles/borders.styles";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppCard = ({
  title,
  subtitle,
  icon,
  avatarImg,
  buttonText,
  stub,
  showBorder,
  action,
  actionScope = [],
  context
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        gap: spacing.g20
      }, spacing.py20), {
        borderBottom: showBorder ? borders.background : "none"
      }),
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g15 }, children: [
          icon ? /* @__PURE__ */ jsx(Box, { children: icon }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx("img", { src: avatarImg, alt: stub, width: 40 }) }),
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mb: spacing.mb5 }), children: title }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: subtitle })
          ] })
        ] }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(ScopesControl, { scopes: actionScope, context, children: /* @__PURE__ */ jsx(Button, { sx: __spreadProps(__spreadValues({}, secondaryCTABtn), { width: "80px" }), onClick: action, children: buttonText != null ? buttonText : "Connect" }) }) })
      ]
    }
  );
};
export const AppsPage = () => {
  var _a, _b;
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [globalState, dispatch] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const context = getScopesContext(globalState.user);
  const isAppsAdmin = checkScopes(globalState.user, ["apps:all"], context);
  const [approveLoading, setApproveLoading] = useState(false);
  const [selectedRequested, setSelectedRequested] = useState(void 0);
  const [pendingRequest, setPendingRequest] = useState(void 0);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const {
    data: myApps,
    isValidating: myAppsLoading,
    error: myAppsError
  } = useApiClient(AppIntegrationEndpoints.getUserApps((_a = globalState == null ? void 0 : globalState.user) == null ? void 0 : _a.userId), { suspense: false });
  const {
    data: installedApps,
    isValidating: installedAppsLoading,
    error: installedAppsError
  } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), { suspense: false });
  const { data: requestsOnlyForUser, mutate: refreshRequestsForUser } = useApiClient(
    !isAppsAdmin ? AppRequestEndpoints.getAppRequestsForUser("Requested,Failed,Scheduled,Pending") : { url: void 0 },
    { suspense: false }
  );
  const { data: allCompanyRequests, mutate: refreshAppRequests } = useApiClient(
    !isAppsAdmin ? { url: void 0 } : AppRequestEndpoints.getAppRequestsForCompany(),
    {
      suspense: false
    }
  );
  const existingRequestsForUser = useMemo(() => {
    var _a2;
    const REQUESTS_REQUIRING_ATTENTION = [RequestStatus.Requested, RequestStatus.Failed, RequestStatus.Pending];
    return (_a2 = isAppsAdmin ? allCompanyRequests : requestsOnlyForUser) == null ? void 0 : _a2.filter(
      (r) => isAppsAdmin ? r.status && REQUESTS_REQUIRING_ATTENTION.includes(r.status) : r.requestedFor === globalState.user.userId && r.status && REQUESTS_REQUIRING_ATTENTION.includes(r.status)
    );
  }, [allCompanyRequests, globalState.user.userId, isAppsAdmin, requestsOnlyForUser]);
  useEffect(() => {
    if (isAppsAdmin) {
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { apps: { entries: existingRequestsForUser, type: "admin" } }
      });
    }
  }, [dispatch, existingRequestsForUser, isAppsAdmin]);
  const approveAppRequest = (requestInfo) => __async(void 0, null, function* () {
    setSelectedRequested(requestInfo);
    setApproveLoading(true);
    try {
      const { id } = requestInfo;
      if (!id) {
        showMessage("Unable to approve app request as there are missing details", "error");
        return;
      }
      yield AppRequestAPI.approveAppRequest(id);
      refreshAppRequests();
      refreshRequestsForUser();
      showMessage("Successfully approved & scheduled app access request!", "success");
    } catch (error) {
      showMessage(`Encountered an error while trying to approve access request: ${nestErrorMessage(error)}`, "error");
    } finally {
      setApproveLoading(false);
      setSelectedRequested(void 0);
    }
  });
  useEffect(() => {
    const pending = allCompanyRequests == null ? void 0 : allCompanyRequests.filter((c) => c.status === RequestStatus.Requested);
    setPendingRequest(pending);
  }, [allCompanyRequests]);
  useEffect(() => {
    if (myAppsError || installedAppsError) {
      showMessage("Could not load the list of apps. Refresh to try again.", "error");
    }
  }, [myAppsError, installedAppsError, showMessage]);
  const initiateOauthFlowForApp = (event, appStub) => {
    if (event) event.preventDefault();
    window.location.href = AppIntegrationAPI.getInitiateUrl(appStub);
  };
  const appConnectAction = (app, event) => {
    var _a2, _b2;
    if ((app == null ? void 0 : app.authorised) && !((_a2 = app == null ? void 0 : app.directory) == null ? void 0 : _a2.oauth))
      return routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, { appStub: app.stub }));
    if ((_b2 = app == null ? void 0 : app.directory) == null ? void 0 : _b2.oauth) return initiateOauthFlowForApp(event, app.stub);
  };
  const getActionForConnect = (app, event) => __async(void 0, null, function* () {
    try {
      const canConnect = yield AppIntegrationAPI.appConnectAllowed();
      if (canConnect) {
        appConnectAction(app, event);
      }
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        if (app.invalidatedAt) {
          appConnectAction(app, event);
        } else {
          setUpgradeModalOpen(true);
        }
      } else {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    }
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Overview" })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: (_b = myAppsLoading || installedAppsLoading) != null ? _b : false, children: [
      (myApps == null ? void 0 : myApps.length) === 0 && (installedApps == null ? void 0 : installedApps.length) === 0 ? /* @__PURE__ */ jsx(AppIntroduction, {}) : /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            gap: spacing.g40,
            alignItems: "flex-start",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }
          },
          children: /* @__PURE__ */ jsxs(Box, { sx: { width: { xs: "100%", sm: "100%", md: "60%", lg: "60%" } }, children: [
            /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g10, flexWrap: "wrap" }, children: installedApps && (installedApps == null ? void 0 : installedApps.length) > 0 ? installedApps.map((a, idx) => {
              var _a2, _b2;
              return /* @__PURE__ */ jsx(
                AppButton,
                {
                  app: a,
                  title: (_b2 = (_a2 = installedApps == null ? void 0 : installedApps.find((eachApp) => eachApp.stub === a.stub)) == null ? void 0 : _a2.name) != null ? _b2 : "",
                  handleClick: () => routerHistory.push(
                    generatePath(APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, {
                      appStub: a.stub
                    })
                  ),
                  avatarImg: `/app-icons-v2/images/${a.stub}.png`,
                  stub: a.stub,
                  expired: !!a.invalidatedAt,
                  expiredAction: getActionForConnect
                },
                `${a.stub}-${idx}`
              );
            }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "No apps are currently installed" }) }) }),
            pendingRequest && (pendingRequest == null ? void 0 : pendingRequest.length) > 0 && /* @__PURE__ */ jsxs(Box, { sx: { marginTop: spacing.m60 }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), spacing.mb20), children: "App requests" }),
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: pendingRequest == null ? void 0 : pendingRequest.map((r, idx) => {
                var _a2, _b2, _c;
                return /* @__PURE__ */ jsx(
                  IconContentActionCard,
                  {
                    iconMedia: /* @__PURE__ */ jsx(Waiting, { fill: themeColors.GreyMiddle }),
                    titleSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }),
                    iconGap: spacing.g10,
                    textGap: spacing.g5,
                    sx: { paddingBottom: 0 },
                    showDivider: idx < pendingRequest.length - 1,
                    title: "New account request",
                    content: `${r.requestedBy && ((_a2 = getCachedUserById(r.requestedBy)) == null ? void 0 : _a2.displayName)} asked for an account in ${(_c = (_b2 = installedApps == null ? void 0 : installedApps.find((eachApp) => eachApp.stub === r.requestInfo.appStub)) == null ? void 0 : _b2.name) != null ? _c : ""}. Approve their account creation or go to Requests tab for more options`,
                    action: /* @__PURE__ */ jsx(
                      LoaderButton,
                      {
                        loading: approveLoading && (selectedRequested == null ? void 0 : selectedRequested.id) === r.id,
                        sizeVariant: "medium",
                        colorVariant: "primary",
                        onClick: () => approveAppRequest(r),
                        name: "Approve",
                        fullWidth: true
                      }
                    )
                  },
                  `${r.id}-${idx}`
                );
              }) })
            ] })
          ] })
        }
      ),
      /* @__PURE__ */ jsx(
        UpgradeToProModal,
        {
          isOpen: upgradeModalOpen,
          setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
          planName: PlanNames.TECH_PRO,
          messageSuffix: "proGeneric"
        }
      )
    ] })
  ] });
};
