"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { ReactComponent as CaseIcon } from "@/images/icons/case.svg";
import { ChipComponentWithIcon } from "@/v2/components/chip/chip-with-icon.component";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { CustomRuleOptions, UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const ownerChipStyles = {
  gap: "4px",
  height: "22px",
  paddingTop: 0,
  paddingBottom: 0
};
export const OwnerChip = ({ ownerIds, customRule, membersRule, multiUserAvatar, usersOnly }) => {
  const { data: sitesForCompany } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departmentsForCompany } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false
  });
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const { polyglot } = usePolyglot();
  const { label, tooltipText } = useMemo(() => {
    var _a, _b, _c, _d, _e;
    if (!customRule || customRule.split("=").length < 2) return { label: "", tooltipText: "" };
    const [rawRuleType, ruleValue] = customRule.split("=");
    const ruleType = rawRuleType;
    const ruleIds = ruleValue.split(",");
    if (ruleType === CustomRuleOptions.Department) {
      if (ruleIds.length > 1) {
        const departments = departmentsForCompany == null ? void 0 : departmentsForCompany.filter((d) => ruleIds.includes(String(d.id)));
        return {
          label: `${ruleIds.length} ${polyglot.t("OwnerChip.departments")}`,
          tooltipText: departments == null ? void 0 : departments.map((d) => {
            return /* @__PURE__ */ jsxs(Fragment, { children: [
              d.name,
              /* @__PURE__ */ jsx("br", {})
            ] });
          })
        };
      }
      const department = departmentsForCompany == null ? void 0 : departmentsForCompany.find((d) => d.id === Number(ruleIds[0]));
      return { label: (_a = department == null ? void 0 : department.name) != null ? _a : "" };
    }
    if (ruleType === CustomRuleOptions.Site) {
      if (ruleIds.length > 1) {
        const sites = sitesForCompany == null ? void 0 : sitesForCompany.filter((s) => ruleIds.includes(String(s.id)));
        return {
          label: `${ruleIds.length} ${polyglot.t("OwnerChip.sites")}`,
          tooltipText: sites == null ? void 0 : sites.map((d) => {
            return /* @__PURE__ */ jsxs(Fragment, { children: [
              d.name,
              /* @__PURE__ */ jsx("br", {})
            ] });
          })
        };
      }
      const site = sitesForCompany == null ? void 0 : sitesForCompany.find((s) => s.id === Number(ruleIds[0]));
      return { label: (_b = site == null ? void 0 : site.name) != null ? _b : "" };
    }
    if (ruleType === CustomRuleOptions.Entity) {
      if (ruleIds.length > 1) {
        const entities = (_c = generalSettings == null ? void 0 : generalSettings.entities) == null ? void 0 : _c.filter((e) => ruleIds.includes(String(e.id)));
        return {
          label: `${ruleIds.length} ${polyglot.t("OwnerChip.entities")}`,
          tooltipText: entities == null ? void 0 : entities.map((e) => {
            return /* @__PURE__ */ jsxs(Fragment, { children: [
              e.legalName,
              /* @__PURE__ */ jsx("br", {})
            ] });
          })
        };
      }
      const entity = (_d = generalSettings == null ? void 0 : generalSettings.entities) == null ? void 0 : _d.find((e) => e.id === Number(ruleIds[0]));
      return { label: (_e = entity == null ? void 0 : entity.legalName) != null ? _e : "" };
    }
    return {};
  }, [sitesForCompany, departmentsForCompany, generalSettings, customRule, polyglot]);
  if (usersOnly || membersRule === UserSelectFiltersOptions.SelectSpecific) {
    return /* @__PURE__ */ jsx(
      ChipComponent,
      {
        name: /* @__PURE__ */ jsx(
          MultiUserAvatar,
          __spreadValues({
            userIds: ownerIds,
            avatarSize: "s16",
            textVariant: "paragraphSmall",
            avatarNameVariant: "paragraphSmall"
          }, multiUserAvatar)
        ),
        textVariant: "paragraphSmall",
        backgroundColor: "white",
        border: "middleGrey2",
        style: { paddingTop: "3px", paddingBottom: "3px" }
      }
    );
  }
  if (membersRule === UserSelectFiltersOptions.Everyone) {
    return /* @__PURE__ */ jsx(
      ChipComponentWithIcon,
      {
        name: polyglot.t("OwnerChip.company"),
        backgroundColor: "white",
        border: "middleGrey2",
        icon: /* @__PURE__ */ jsx(CaseIcon, { width: "16px" }),
        chipStyles: ownerChipStyles,
        textVariant: "paragraphSmall",
        textColor: "TextPrimary"
      }
    );
  }
  if (!label) return null;
  const chip = /* @__PURE__ */ jsx(
    ChipComponentWithIcon,
    {
      name: label,
      backgroundColor: "white",
      border: "middleGrey2",
      icon: /* @__PURE__ */ jsx(CaseIcon, { width: "16px" }),
      chipStyles: ownerChipStyles,
      textVariant: "paragraphSmall",
      textColor: "TextPrimary"
    }
  );
  if (!tooltipText) return chip;
  return /* @__PURE__ */ jsx(
    StyledTooltip,
    {
      title: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", children: tooltipText }),
      placement: "top",
      children: /* @__PURE__ */ jsx(Box, { children: chip })
    }
  );
};
