"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from "@mui/material";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const AppButton = ({
  app,
  avatarImg,
  stub,
  title,
  handleClick,
  expired = false,
  action,
  expiredAction
}) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        onClick: () => handleClick(),
        sx: {
          cursor: "pointer",
          borderRadius: radius.br20,
          border: `1px solid ${themeColors.Background}`,
          p: spacing.p25,
          width: "60px",
          height: "60px",
          display: "flex",
          alignItems: "center"
        },
        children: /* @__PURE__ */ jsx("img", { src: avatarImg, width: 60, alt: stub })
      }
    ),
    /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey, marginTop: spacing.m10, textAlign: "center" }),
        children: title
      }
    ),
    expired && /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: { display: "flex", alignItems: "center", flexWrap: "wrap", margin: "auto", justifyContent: "center" },
          children: [
            /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize)),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { ml: spacing.ml5 }), children: "Expired" })
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "auto",
            justifyContent: "center",
            mt: "5px"
          },
          children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "secondary",
              sizeVariant: "small",
              onClick: (event) => expiredAction(app, event),
              children: "Renew"
            }
          )
        }
      )
    ] }),
    !!action && !expired && action
  ] });
};
