"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import {
  USER_ONBOARDING_ABOUT,
  USER_ONBOARDING_CONTRACTS,
  USER_ONBOARDING_DOCUMENTS,
  USER_ONBOARDING_PAYROLL,
  USER_ONBOARDING_TASKS
} from "@/lib/routes";
import { TeamSlide } from "@/v2/components/team-slide.component";
import { ProfileModal } from "@/v2/components/theme-components/profile-modal.component";
import { UserProfileBar } from "@/v2/feature/user/components/user-profile-bar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserLifecycleStatuses } from "@/v2/feature/user/features/user-forms/user-lifecycle/user-lifecycle.interface";
import { OnboardingActionCard } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-action-card.component";
import { OnboardingUserStats } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-user-stats.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingOverview = ({
  companyName,
  progressPercent,
  requiredSteps,
  stepStatus,
  startDate,
  sx,
  taskCount,
  userId,
  publicURL,
  refreshState
}) => {
  var _a;
  const { polyglot, useInitLanguage } = usePolyglot();
  useInitLanguage();
  const router = useHistory();
  const routerLocation = useLocation();
  const { cachedUsers: companyUsers } = useCachedUsers();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserId, setSetSelectedUserId] = useState(null);
  const cards = useMemo(
    () => [...companyUsers].filter((u) => {
      var _a2;
      return ((_a2 = u.userEvent) == null ? void 0 : _a2.status) === UserLifecycleStatuses.Employed;
    }).sort(() => 0.5 - Math.random()).map((user) => {
      var _a2, _b;
      return {
        user,
        cardProps: {
          subTitle: (_b = (_a2 = user.role) == null ? void 0 : _a2.jobPosition) == null ? void 0 : _b.title
        },
        action: () => {
          setIsOpen(true);
          setSetSelectedUserId(user.userId);
        },
        publicURL
      };
    }),
    [companyUsers, publicURL]
  );
  useEffect(() => {
    var _a2;
    const shouldRefresh = Boolean((_a2 = routerLocation == null ? void 0 : routerLocation.state) == null ? void 0 : _a2.refresh);
    if (shouldRefresh) {
      refreshState == null ? void 0 : refreshState();
    }
  }, [refreshState, (_a = routerLocation == null ? void 0 : routerLocation.state) == null ? void 0 : _a.refresh]);
  const actionCards = useMemo(() => {
    return requiredSteps.map((step, idx) => {
      switch (step) {
        case "basic":
          return /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingOverview.completeProfile"),
              completed: stepStatus.basic.length === 0,
              onStartClick: () => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId })),
              showEdit: stepStatus.basic.length === 0,
              onEditClick: () => router.push(generatePath(USER_ONBOARDING_ABOUT, { userId }))
            },
            `action-card-${idx}`
          );
        case "benefits":
          return /* @__PURE__ */ jsx(OnboardingActionCard, { label: polyglot.t("OnboardingOverview.selectBenefits") }, `action-card-${idx}`);
        case "documents":
          return /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingOverview.uploadDocuments"),
              completed: stepStatus.missingDocument === 0,
              onStartClick: () => router.push(generatePath(USER_ONBOARDING_DOCUMENTS, { userId }))
            },
            `action-card-${idx}`
          );
        case "payroll":
          return /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingOverview.completePayroll"),
              completed: stepStatus.payroll.length === 0,
              onStartClick: () => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId })),
              showEdit: stepStatus.payroll.length === 0,
              onEditClick: () => router.push(generatePath(USER_ONBOARDING_PAYROLL, { userId }))
            },
            `action-card-${idx}`
          );
        case "tasks":
          return /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingOverview.tasks"),
              completed: stepStatus.incompleteTask === 0,
              onStartClick: () => router.push(generatePath(USER_ONBOARDING_TASKS, { userId }))
            },
            `action-card-${idx}`
          );
        case "contracts":
          return /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingOverview.contract"),
              completed: stepStatus.unsignedContract === 0,
              onStartClick: () => router.push(generatePath(USER_ONBOARDING_CONTRACTS, { userId }))
            },
            `action-card-${idx}`
          );
        default:
          return null;
      }
    });
  }, [polyglot, stepStatus, requiredSteps, router, userId]);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", gap: "140px" }, sx), children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: "0 0", maxWidth: "470px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.getStarted") }),
      /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10, textAlign: "justify", color: themeColors.DarkGrey }),
          children: polyglot.t("OnboardingOverview.welcomeMessage", { companyName })
        }
      ),
      cards && /* @__PURE__ */ jsx(TeamSlide, { cards, title: "Your colleagues", sx: { mt: spacing.mt40 }, width: 470 })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, maxWidth: "1000px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingOverview.onboardingTasks") }),
      /* @__PURE__ */ jsx(
        OnboardingUserStats,
        {
          progressPercent,
          startDate,
          pendingTaskCount: taskCount,
          sx: { mt: spacing.mt20, mb: spacing.mb5 }
        }
      ),
      /* @__PURE__ */ jsx(Stack, { sx: { mt: spacing.m25 }, children: actionCards.map((card, idx) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
        idx > 0 && /* @__PURE__ */ jsx("div", { style: { height: 1, backgroundColor: themeColors.lightGrey } }),
        card
      ] }, `action-card-${idx}`)) }),
      /* @__PURE__ */ jsx(
        ProfileModal,
        {
          isOpen,
          setIsOpen,
          onClose: () => {
            setIsOpen(false);
          },
          children: selectedUserId ? /* @__PURE__ */ jsx(UserProfileBar, { userId: selectedUserId }) : void 0
        }
      )
    ] })
  ] });
};
