"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableFilter } from "@v2/components/table/table-filter.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import {
  AccountingTransactionStatus,
  AccountingTransactionType,
  AccountType
} from "@v2/feature/payments/features/accounting-transaction/accounting-transaction.interface";
import { AccountingTransactionsAPI } from "@v2/feature/payments/features/accounting-transaction/accounting-transactions.api";
import { AccountingTransactionSummary } from "@v2/feature/super-admin/features/helper-dashboard/components/accounting-transactions-summary.component";
import {
  SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { filterStringToObject } from "@v2/feature/user/user.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, Route, Switch, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
const FILTERS = ["Step", "System", "Account", "Subaccount", "Status", "Transaction type", "Reconciled"];
const getSystemFromAccountingTransaction = (accountingTransaction, users, currentCompany) => {
  var _a;
  if (accountingTransaction.userId) {
    const user = users.find((user2) => user2.userId === accountingTransaction.userId);
    return user ? `${user.firstName} ${user.lastName}` : null;
  }
  if (accountingTransaction.companyId) {
    return (_a = currentCompany == null ? void 0 : currentCompany.name) != null ? _a : "COMPANY_NAME";
  }
  return "ZELT";
};
const getSubaccountFromAccountingTransaction = (accountingTransaction, users, currentCompany) => {
  var _a;
  if (accountingTransaction.subaccountUserId) {
    const user = users.find((user2) => user2.userId === accountingTransaction.subaccountUserId);
    return user ? `${user.firstName} ${user.lastName}` : null;
  }
  if (accountingTransaction.subaccountCompanyId) {
    return (_a = currentCompany == null ? void 0 : currentCompany.name) != null ? _a : "COMPANY_NAME";
  }
  return !accountingTransaction.companyId ? "ZELT" : null;
};
export const HelperAccountingTransactionsPage = ({
  users,
  company
}) => {
  var _a, _b;
  const params = useParams();
  const companyId = (_a = company == null ? void 0 : company.companyId) != null ? _a : Number(params.companyId);
  const [searchInput, setSearchInput] = useState("");
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState("Status=APPROVED,PAYMENT_RECEIVED");
  const [loading, setLoading] = useState(false);
  const [accountingTransactions, setAccountingTransactions] = useState([]);
  const [showMessage] = useMessage();
  const companyUsers = useMemo(() => {
    return users.filter((user) => user.company.companyId === companyId);
  }, [users, companyId]);
  const getFilterOptions = useCallback(() => __async(void 0, null, function* () {
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case "Step":
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: [1, 2, 3, 4].map((key) => ({ value: key, label: key })) });
          break;
        case "System":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: [
              ...new Set(
                accountingTransactions.map((transaction) => {
                  return getSystemFromAccountingTransaction(transaction, companyUsers, company);
                })
              )
            ].filter(Boolean).map((system) => ({ label: system, value: system }))
          });
          break;
        case "Account":
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: Object.keys(AccountType).map((key) => ({ value: key, label: key })) });
          break;
        case "Subaccount":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: [
              ...new Set(
                accountingTransactions.map((transaction) => {
                  return getSubaccountFromAccountingTransaction(transaction, companyUsers, company);
                })
              )
            ].filter(Boolean).map((subaccount) => ({ value: subaccount, label: subaccount }))
          });
          break;
        case "Status": {
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: Object.keys(AccountingTransactionStatus).map((key) => ({ value: key, label: key }))
          });
          break;
        }
        case "Transaction type": {
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: Object.keys(AccountingTransactionType).map((key) => ({ value: key, label: key }))
          });
          break;
        }
        case "Reconciled":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: [
              { value: true, label: "Yes" },
              { value: false, label: "No" }
            ]
          });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), [accountingTransactions, companyUsers, company]);
  const getCompanyAccountingTransactions = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const accountingTransactions2 = yield AccountingTransactionsAPI.getCompanyAccountingTransactionsAsSuperAdmin(
        companyId
      );
      setAccountingTransactions(accountingTransactions2);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get users carry over. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [companyId, showMessage]);
  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);
  const columns = useMemo(
    () => [
      {
        header: () => "Step",
        id: "step",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return original.id !== 0 ? /* @__PURE__ */ jsx(Fragment, { children: original.step }) : null;
        },
        maxSize: 10,
        minSize: 10
      },
      {
        header: () => "System",
        id: "system",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          const account = getSystemFromAccountingTransaction(original, companyUsers, company);
          return account ? /* @__PURE__ */ jsx(Fragment, { children: account }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => "Account",
        id: "account",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return /* @__PURE__ */ jsx(Fragment, { children: original.accountType });
        },
        maxSize: 80,
        minSize: 80
      },
      {
        header: () => "Subaccount",
        id: "subaccount",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          const subaccount = getSubaccountFromAccountingTransaction(original, companyUsers, company);
          return subaccount ? /* @__PURE__ */ jsx(Fragment, { children: subaccount }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 100,
        minSize: 100
      },
      {
        header: () => "Description",
        id: "description",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return /* @__PURE__ */ jsx(Fragment, { children: original.description });
        },
        maxSize: 200,
        minSize: 200
      },
      {
        header: () => "Status",
        id: "status",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return /* @__PURE__ */ jsx(Fragment, { children: original.status });
        },
        maxSize: 125,
        minSize: 125
      },
      {
        header: () => "Debit",
        id: "debit",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return /* @__PURE__ */ jsx(Box, { sx: { color: themeColors.green, fontWeight: 500 }, children: original.debit });
          return original.debit !== 0 ? /* @__PURE__ */ jsx(Fragment, { children: original.debit }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 40,
        minSize: 40
      },
      {
        header: () => "Credit",
        id: "credit",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0)
            return /* @__PURE__ */ jsx(Box, { sx: { color: themeColors.lightRed, fontWeight: 500 }, children: original.credit });
          return original.credit !== 0 ? /* @__PURE__ */ jsx(Fragment, { children: original.credit }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 40,
        minSize: 40
      },
      {
        header: () => "Amount",
        id: "amount",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0)
            return /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  color: original.amount === 0 ? themeColors.DarkGrey : themeColors.darkRed,
                  fontWeight: original.amount === 0 ? 500 : 700
                },
                children: original.amount
              }
            );
          return original.amount !== 0 ? /* @__PURE__ */ jsx(Fragment, { children: original.amount }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 40,
        minSize: 40
      },
      {
        header: () => "Transaction type",
        id: "transactionType",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return /* @__PURE__ */ jsx(Fragment, { children: original.transactionType });
        },
        maxSize: 80,
        minSize: 80
      },
      {
        header: () => "Reconciled",
        id: "reconciled",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          if (original.id === 0) return null;
          return original.reconciled ? /* @__PURE__ */ jsx(Fragment, { children: new Date(original.reconciled).toLocaleDateString() }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 60,
        minSize: 60
      }
    ],
    [company, companyUsers]
  );
  useEffect(() => {
    getCompanyAccountingTransactions();
  }, [getCompanyAccountingTransactions]);
  const filteredAccountingTransactions = useMemo(() => {
    let filteredRecords = [...accountingTransactions];
    if (searchInput) {
      filteredRecords = filteredRecords.filter((record) => {
        const account = getSystemFromAccountingTransaction(record, companyUsers, company);
        const subaccount = getSubaccountFromAccountingTransaction(record, companyUsers, company);
        return (account == null ? void 0 : account.toLowerCase().includes(searchInput.toLowerCase())) || (subaccount == null ? void 0 : subaccount.toLowerCase().includes(searchInput.toLowerCase()));
      });
    }
    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case "Step": {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.step && filterOptions[key].includes(String(transaction.step));
              });
              break;
            }
            case "System": {
              filteredRecords = filteredRecords.filter((transaction) => {
                const system = getSystemFromAccountingTransaction(transaction, companyUsers, company);
                return system && filterOptions[key].includes(system);
              });
              break;
            }
            case "Account": {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.accountType && filterOptions[key].includes(transaction.accountType);
              });
              break;
            }
            case "Subaccount": {
              filteredRecords = filteredRecords.filter((transaction) => {
                const subaccount = getSubaccountFromAccountingTransaction(transaction, companyUsers, company);
                return subaccount && filterOptions[key].includes(subaccount);
              });
              break;
            }
            case "Status": {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.status && filterOptions[key].includes(transaction.status);
              });
              break;
            }
            case "Transaction type": {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.transactionType && filterOptions[key].includes(transaction.transactionType);
              });
              break;
            }
            case "Reconciled": {
              filteredRecords = filteredRecords.filter((transaction) => {
                return transaction.step && filterOptions[key].includes(String(Boolean(transaction.reconciled)));
              });
              break;
            }
            default:
              break;
          }
        }
      }
    }
    const sums = filteredRecords.reduce(
      (acc, record) => {
        acc.credit += record.credit;
        acc.debit += record.debit;
        acc.amount += record.amount;
        return acc;
      },
      { credit: 0, debit: 0, amount: 0 }
    );
    filteredRecords.push({
      id: 0,
      step: 0,
      companyId: 0,
      userId: 0,
      subaccountCompanyId: 0,
      subaccountUserId: 0,
      accountType: AccountType.Deposit,
      description: "",
      status: AccountingTransactionStatus.DRAFT,
      debit: sums.debit,
      credit: sums.credit,
      amount: sums.amount,
      transactionType: AccountingTransactionType.Asset,
      reconciled: null,
      invoiceId: null
    });
    return filteredRecords;
  }, [accountingTransactions, searchInput, filterString, companyUsers, company]);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsxs(Route, { path: SUPER_ADMIN_HELPER_COMPANY_ACCOUNTING_TRANSACTIONS_ROUTE, children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: [
          "Helper page - Accounting transactions | ",
          (company == null ? void 0 : company.name) ? company.name : "",
          " [ ",
          companyId,
          " ]"
        ] }),
        showBack: true,
        backPath: generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: {}, children: [
      /* @__PURE__ */ jsx(
        AccountingTransactionSummary,
        {
          accountingTransactions: filteredAccountingTransactions,
          companyId,
          companyName: (_b = company == null ? void 0 : company.name) != null ? _b : "Company",
          users: companyUsers
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex" }, spacing.px40), { gap: spacing.gap10 }), children: [
        /* @__PURE__ */ jsx(TableFilter, { filterTypes, setFilterString, filterString }),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredAccountingTransactions,
          columnData: columns,
          hidePagination: true
        }
      ) })
    ] })
  ] }) });
};
