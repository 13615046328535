"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Card, CardActions, CardContent } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import {
  DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDER_ACTIVATION_ROUTE,
  DEVICES_ME_ORDERS_ROUTE
} from "@/lib/routes";
export const DevicesActivationPage = ({ deviceOrders, loading }) => {
  const history = useHistory();
  const location = useLocation();
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: "Devices Activation" }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsxs(Box, { children: [
      deviceOrders.length > 0 ? /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "We found multiple in transit devices. Select the device that you would like to activate please." }) : /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "We could not find any incoming devices. Perhaps someone else already confirmed the delivery of the device." }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap20, mt: spacing.mt20, width: 0.3 }, children: deviceOrders.map((order) => {
        var _a, _b;
        return /* @__PURE__ */ jsxs(Card, { children: [
          /* @__PURE__ */ jsxs(CardContent, { children: [
            order.id && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
              "Order Id ",
              order.id
            ] }),
            ((_a = order.device) == null ? void 0 : _a.modelName) && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: order.device.modelName }),
            ((_b = order.device) == null ? void 0 : _b.serialNumber) && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
              "Serial Number: ",
              order.device.serialNumber
            ] })
          ] }),
          /* @__PURE__ */ jsx(CardActions, { children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => {
                if (location.pathname.includes(DEVICES_ME_ORDERS_ROUTE))
                  history.push(generatePath(DEVICES_ME_ORDER_ACTIVATION_ROUTE, { orderId: order.id }));
                else history.push(generatePath(DEVICES_COMPANY_ORDER_ACTIVATION_ROUTE, { orderId: order.id }));
              },
              children: "Activate Device"
            }
          ) })
        ] });
      }) })
    ] }) })
  ] });
};
