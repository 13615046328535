"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI, AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { AllowanceAdjustmentDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/allowance-adjustment-drawer.component";
import { BalanceAdjustmentsDrawer } from "@v2/feature/absence/me/policies/policy-breakdown/components/balance-adjustments-drawer.component";
import { PolicyBreakdownCard } from "@v2/feature/absence/me/policies/policy-breakdown/components/policy-breakdown-card.component";
import { PolicyBreakdown } from "@v2/feature/absence/me/policies/policy-breakdown/components/policy-breakdown.component";
import { AbsenceDrawer } from "@v2/feature/absence/sections/absence-drawer/absence-drawer.section";
import { AbsenceViewDrawer } from "@v2/feature/absence/sections/absence-drawer/absence-view-drawer.page";
import { AbsenceTable, getInitialFilterString } from "@v2/feature/absence/sections/absence-table.section";
import { AbsenceUserFilterFeaturesUpdater } from "@v2/feature/absence/subfeatures/settings/components/absence-user-filter-feature-updater.component";
import { Content2Columns } from "@v2/feature/app-layout/features/main-content/layouts/components/content-2-columns.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { matchPath, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { USER_ABSENCE_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { iconSize } from "@/v2/styles/menu.styles";
const PERSONAL_ABSENCE_PAGE_SIZE = 100;
export const PersonalAbsenceContent = ({
  userId,
  year,
  absencePolicies,
  setIsAbsenceDrawerOpen,
  isAbsenceDrawerOpen,
  selectedPolicy,
  setSelectedPolicy
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const { data: userBalanceDetailedStats, mutate: refreshUserBalanceDetailedStats, isLoading } = useApiClient(AbsenceEndpoints.fetchUserAbsenceBalanceBreakdown(userId, { calendar: year, includeFormerPolicies: true }), {
    suspense: false
  });
  const readForAllYear = useRef(year);
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const [userAbsences, setUserAbsences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isViewAbsenceDrawerOpen, setIsViewAbsenceDrawerOpen] = useState(false);
  const [viewAbsence, setViewAbsence] = useState(void 0);
  const [showMessage] = useMessage();
  const [userBalanceStats, setUserBalanceStats] = useState(void 0);
  useEffect(() => {
    setUserBalanceStats(userBalanceDetailedStats != null ? userBalanceDetailedStats : void 0);
  }, [userBalanceDetailedStats]);
  const refreshBalances = useCallback(
    (policyId) => __async(void 0, null, function* () {
      var _a2;
      try {
        if (refreshUserBalanceDetailedStats && (policyId === "all" || readForAllYear.current !== year)) {
          yield refreshUserBalanceDetailedStats();
          readForAllYear.current = year;
        } else if (policyId && policyId !== "all") {
          const policyUpdatedData = yield AbsenceAPI.getUserAbsenceBalanceBreakdownByPolicyId(userId, {
            policyId,
            calendar: year
          });
          const policyUpdate = (_a2 = policyUpdatedData[policyId]) != null ? _a2 : null;
          setUserBalanceStats((prev) => {
            if (!prev) return policyUpdatedData;
            const update = __spreadValues({}, prev);
            update[policyId] = policyUpdate;
            return update;
          });
        }
      } catch (error) {
        showMessage(
          polyglot.t("PersonalAbsence.errorMessages.couldNotRefreshBalance", { nestError: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, refreshUserBalanceDetailedStats, showMessage, userId, year]
  );
  const initialFilterString = useMemo(
    () => {
      var _a2, _b2, _c2, _d, _e;
      return absencePolicies ? getInitialFilterString(
        (_e = (_d = (_c2 = (_b2 = (_a2 = globalState.user) == null ? void 0 : _a2.features) == null ? void 0 : _b2.timeAway) == null ? void 0 : _c2.requestsTable) == null ? void 0 : _d.personalFilters) != null ? _e : "",
        absencePolicies
      ) : "";
    },
    [globalState.user, absencePolicies]
  );
  const [drawerAbsence, setDrawerAbsence] = useState(void 0);
  const [filterString, setFilterString] = useState(initialFilterString);
  useEffect(() => {
    setFilterString((prev) => prev ? prev : initialFilterString);
  }, [initialFilterString]);
  const [searchInput, setSearchInput] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: PERSONAL_ABSENCE_PAGE_SIZE
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isAdjustmentsDrawerOpen, setIsAdjustmentsDrawerOpen] = useState(false);
  const [isAllowanceUpdateDrawerOpen, setIsAllowanceUpdateDrawerOpen] = useState(false);
  const location = useLocation();
  const shouldShowNewRequestButton = useMemo(
    () => matchPath(location.pathname, {
      path: USER_ABSENCE_ROUTE,
      exact: true
    }) && checkScopes(globalState.user, ["absence"], { userId }),
    [location.pathname, userId, globalState.user]
  );
  const canManage = useMemo(
    () => matchPath(location.pathname, {
      path: USER_ABSENCE_ROUTE,
      exact: true
    }) && checkScopes(globalState.user, ["absence:manager"], { userId }),
    [location.pathname, userId, globalState.user]
  );
  const refreshAbsences = useCallback(
    (policyId) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const userAbsencesResponse = yield AbsenceAPI.fetchUserAbsences(
          userId,
          policyId,
          year,
          pagination.pageIndex,
          pagination.pageSize,
          filterString,
          searchInput
        );
        setIsViewAbsenceDrawerOpen(false);
        setUserAbsences(userAbsencesResponse.items);
        setTotalPages(userAbsencesResponse.totalPages);
        setTotalItems(userAbsencesResponse.totalItems);
      } catch (error) {
        showMessage(
          polyglot.t("PersonalAbsence.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
      setLoading(false);
    }),
    [filterString, pagination.pageIndex, pagination.pageSize, searchInput, showMessage, userId, polyglot, year]
  );
  const refreshPageData = useCallback(
    (policyId) => __async(void 0, null, function* () {
      setLoading(true);
      yield Promise.all([refreshAbsences(policyId), refreshBalances(policyId)]);
    }),
    [refreshBalances, refreshAbsences]
  );
  useEffect(() => {
    if (!selectedPolicy) return;
    refreshPageData(selectedPolicy.id);
  }, [selectedPolicy, refreshPageData, selectedPolicy == null ? void 0 : selectedPolicy.id]);
  const deleteAbsence = (absenceId) => __async(void 0, null, function* () {
    yield AbsenceAPI.deleteAbsenceRecord(absenceId);
    setUserAbsences(userAbsences.filter((absence) => absence.absenceId !== absenceId));
  });
  const removeRequestHelper = (absence) => __async(void 0, null, function* () {
    try {
      yield deleteAbsence(absence.absenceId);
      showMessage(polyglot.t("PersonalAbsence.successMessages.remove"), "success");
    } catch (error) {
      showMessage(
        polyglot.t("PersonalAbsence.errorMessages.remove", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
    yield refreshPageData(absence.policyId);
  });
  const decideAbsence = useCallback(
    (absence, decision, rejectionNotes) => __async(void 0, null, function* () {
      let updatedAbsence;
      try {
        if (decision === AbsenceStatus.Approved) {
          const updatedAbsences = yield AbsenceAPI.approveAllAbsences([absence.absenceId]);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t("PersonalAbsence.successMessages.approve"), "success");
        } else if (decision === AbsenceStatus.Rejected) {
          const updatedAbsences = yield AbsenceAPI.rejectAllAbsences([absence.absenceId], rejectionNotes);
          updatedAbsence = updatedAbsences[0];
          showMessage(polyglot.t("PersonalAbsence.successMessages.reject"), "success");
        }
        yield refreshPageData(absence.policyId);
      } catch (err) {
        showMessage(nestErrorMessage(err), "error");
      }
      return updatedAbsence;
    }),
    [refreshPageData, showMessage, polyglot]
  );
  const handleApprove = useCallback((a) => decideAbsence(a, AbsenceStatus.Approved), [decideAbsence]);
  const handleReject = useCallback(
    (a, rejectionNotes) => decideAbsence(a, AbsenceStatus.Rejected, rejectionNotes),
    [decideAbsence]
  );
  const onRequestCancellation = useCallback(
    (absence) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.requestTimeRequestCancellation(absence.absenceId);
        showMessage(polyglot.t("PersonalAbsence.successMessages.cancel"), "success");
        yield refreshPageData(absence.policyId);
      } catch (error) {
        showMessage(
          polyglot.t("PersonalAbsence.errorMessages.cancel", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [refreshPageData, showMessage, polyglot]
  );
  const onForceApproval = useCallback(
    (absence, status) => __async(void 0, null, function* () {
      try {
        yield AbsenceAPI.forceApprovalByAbsencesIds([absence.absenceId], status);
        showMessage(
          polyglot.t(
            status === AbsenceStatus.Approved ? "PersonalAbsence.successMessages.approve" : "PersonalAbsence.successMessages.reject"
          ),
          "success"
        );
        yield refreshPageData(absence.policyId);
      } catch (error) {
        showMessage(
          polyglot.t("PersonalAbsence.errorMessages.cancel", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [refreshPageData, showMessage, polyglot]
  );
  const handleRowClicked = useCallback((original) => {
    setViewAbsence(original);
    setIsViewAbsenceDrawerOpen(true);
  }, []);
  const pageActions = useMemo(
    () => [
      {
        // title: polyglot.t('General.actions'),
        title: "",
        options: [
          {
            // icon: <Calendar {...iconSize} />,
            handler: () => __async(void 0, null, function* () {
              setIsAbsenceDrawerOpen(true);
              setDrawerAbsence(void 0);
            }),
            label: polyglot.t("AbsenceRequestsPage.newRequest")
          },
          // Only show edit allowance for non-unlimited policies
          ...(selectedPolicy == null ? void 0 : selectedPolicy.allowanceType) !== "Unlimited" ? [
            {
              handler: () => __async(void 0, null, function* () {
                setIsAllowanceUpdateDrawerOpen(true);
              }),
              label: polyglot.t("AbsenceRequestsPage.editAllowance")
            }
          ] : [],
          {
            handler: () => __async(void 0, null, function* () {
              setIsAdjustmentsDrawerOpen(true);
            }),
            label: polyglot.t("AbsenceRequestsPage.addAnAdjustment")
          }
        ]
      }
    ],
    [setIsAbsenceDrawerOpen, polyglot, selectedPolicy]
  );
  return /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, sx: { pt: 0 }, children: /* @__PURE__ */ jsx(
    Content2Columns,
    {
      column1: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: userBalanceStats && absencePolicies && absencePolicies.map((policy) => /* @__PURE__ */ jsx(
        PolicyBreakdownCard,
        {
          absencePolicy: policy,
          userBalanceDetailedStats: userBalanceStats,
          isActive: Boolean(selectedPolicy && policy.id === selectedPolicy.id),
          onClick: () => {
            setSelectedPolicy(policy);
            setPagination((prev) => ({
              pageIndex: 1,
              pageSize: prev.pageSize
            }));
          }
        },
        policy.id
      )) }),
      column2: /* @__PURE__ */ jsxs(Box, { children: [
        userBalanceStats && selectedPolicy && /* @__PURE__ */ jsx(
          PolicyBreakdown,
          {
            isCurrentYear: year === "current",
            userId,
            refresh: refreshBalances,
            absencePolicy: selectedPolicy,
            userBalanceDetailedStats: userBalanceStats
          }
        ),
        /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, noHorizontalPadding: true, sx: { paddingTop: "20px" }, children: [
          absencePolicies && (userAbsences.length > 0 || userAbsences.length === 0 && (filterString || searchInput || pagination.pageIndex > 1)) && /* @__PURE__ */ jsx(
            AbsenceTable,
            {
              rows: userAbsences,
              loading,
              onEdit: (absence) => {
                setDrawerAbsence(absence);
                setIsAbsenceDrawerOpen(true);
              },
              onDelete: removeRequestHelper,
              onApprove: handleApprove,
              onReject: handleReject,
              onRequestCancellation,
              onRowClick: handleRowClicked,
              onForceApproval,
              newRequestButton: shouldShowNewRequestButton ? canManage ? /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  headerOptions: pageActions,
                  actionButtonDetails: {
                    type: "button",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: polyglot.t("CalendarPage.actions"),
                    icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                    iconPosition: "end",
                    fullWidth: true
                  },
                  anchorOrigin: { horizontal: "left", vertical: "bottom" },
                  transformOrigin: { horizontal: "left", vertical: "top" }
                }
              ) : /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "small",
                  colorVariant: "primary",
                  onClick: () => {
                    setDrawerAbsence(void 0);
                    setIsAbsenceDrawerOpen(true);
                  },
                  children: polyglot.t("PersonalAbsence.newRequest")
                }
              ) : void 0,
              pagination,
              setPagination,
              totalPages,
              totalItems,
              searchInput,
              setDebouncedSearchInput: (value) => {
                setSearchInput(!value ? "" : value);
                setPagination((prev) => ({
                  pageIndex: 1,
                  pageSize: prev.pageSize
                }));
              },
              filterString,
              setFilterString,
              view: "user",
              userId,
              hideTimePolicyFilter: true,
              hidePolicyColumn: true
            }
          ),
          userAbsences.length === 0 && !filterString && !searchInput && pagination.pageIndex === 1 && /* @__PURE__ */ jsxs(Box, { sx: { pl: spacing.p5, display: "flex", flexDirection: "column", gap: spacing.m10 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("PersonalAbsence.trackTime") }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("PersonalAbsence.youCanRequest") }),
            shouldShowNewRequestButton && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "small",
                colorVariant: "primary",
                onClick: () => {
                  setIsAbsenceDrawerOpen(true);
                  setDrawerAbsence(void 0);
                },
                children: polyglot.t("PersonalAbsence.newRequest")
              }
            ) })
          ] })
        ] }),
        selectedPolicy && isAbsenceDrawerOpen && /* @__PURE__ */ jsx(
          AbsenceDrawer,
          {
            isOpen: isAbsenceDrawerOpen,
            onClose: () => {
              setIsAbsenceDrawerOpen(false);
              setDrawerAbsence(void 0);
            },
            refresh: refreshPageData,
            absence: drawerAbsence,
            userId,
            setIsAbsenceDrawerOpen,
            policyId: selectedPolicy.id
          }
        ),
        viewAbsence && isViewAbsenceDrawerOpen && !isAbsenceDrawerOpen && /* @__PURE__ */ jsx(
          AbsenceViewDrawer,
          {
            isOpen: isViewAbsenceDrawerOpen,
            absence: viewAbsence,
            setAbsence: setViewAbsence,
            afterClose: () => {
              setViewAbsence(void 0);
            },
            setIsAbsenceDrawerViewOpen: setIsViewAbsenceDrawerOpen,
            handleApprove,
            handleReject,
            onDelete: removeRequestHelper,
            onRequestCancellation,
            onForceApproval,
            onEdit: (absence) => {
              setDrawerAbsence(absence);
              setIsAbsenceDrawerOpen(true);
              setIsViewAbsenceDrawerOpen(false);
            },
            refresh: () => __async(void 0, null, function* () {
              return yield refreshPageData(viewAbsence.policyId);
            }),
            showCalendarLink: true
          }
        ),
        userBalanceDetailedStats && selectedPolicy && /* @__PURE__ */ jsx(
          BalanceAdjustmentsDrawer,
          {
            isOpen: isAdjustmentsDrawerOpen,
            setIsOpen: setIsAdjustmentsDrawerOpen,
            userId,
            absencePolicy: selectedPolicy,
            policyBreakdown: userBalanceDetailedStats[selectedPolicy.id],
            refresh: () => __async(void 0, null, function* () {
              return yield refreshPageData(selectedPolicy.id);
            }),
            isOnRegularSchedule: !!((_a = userBalanceDetailedStats[selectedPolicy.id]) == null ? void 0 : _a.isOnRegularSchedule),
            currentAverageWorkDayLength: (_c = (_b = userBalanceDetailedStats[selectedPolicy.id]) == null ? void 0 : _b.currentAverageWorkDayLength) != null ? _c : 480
          }
        ),
        userBalanceDetailedStats && selectedPolicy && userBalanceDetailedStats[selectedPolicy.id] && selectedPolicy && /* @__PURE__ */ jsx(
          AllowanceAdjustmentDrawer,
          {
            isOpen: isAllowanceUpdateDrawerOpen,
            setIsOpen: setIsAllowanceUpdateDrawerOpen,
            userId,
            absencePolicy: selectedPolicy,
            refresh: () => __async(void 0, null, function* () {
              return yield refreshPageData(selectedPolicy.id);
            }),
            policyBreakdown: userBalanceDetailedStats[selectedPolicy.id],
            currentCycle: userBalanceDetailedStats[selectedPolicy.id].currentCycle
          }
        ),
        /* @__PURE__ */ jsx(
          AbsenceUserFilterFeaturesUpdater,
          {
            filterString,
            initialFilterString,
            globalDispatch,
            view: "personal"
          }
        )
      ] }),
      gap: "gap40"
    }
  ) });
};
