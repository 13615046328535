"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { nestErrorMessage } from "@/lib/errors";
import { APP_STORE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CalendarEndpoints, CalendarSettingsAPI, GoogleCalendarAPI } from "@/v2/feature/calendar/calendar.api";
import { ViewSettingEnum, ViewSettingsLabel } from "@/v2/feature/calendar/calendar.interface";
import { CalendarSettingDrawer } from "@/v2/feature/calendar/features/settings/calendar-setting-drawer.component";
import { CalendarSettingsSyncDrawer } from "@/v2/feature/calendar/features/settings/calendar-settings-sync-drawer.component";
import { OutlookCalendarAPI } from "@/v2/feature/calendar/outlook-calendar.api";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { FieldStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { definitionListSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const CalendarSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [state] = useContext(GlobalContext);
  const { getScopesContext, hasScopes } = useScopes();
  const context = getScopesContext(state.user);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calenderSettings, setCalenderSettings] = useState(void 0);
  const hasAppsRead = hasScopes(["apps"], context);
  const [calendarSyncDrawerOpen, setCalendarSyncDrawerOpen] = useState(false);
  const { data: installedApps } = useApiClient(AppIntegrationEndpoints.getCalendarApps(hasAppsRead), {
    suspense: false
  });
  const microsoft365AppState = useMemo(() => {
    if (hasAppsRead) {
      const appState = installedApps && (installedApps == null ? void 0 : installedApps.length) > 0 ? installedApps == null ? void 0 : installedApps.find((app) => app.stub === "microsoft365") : null;
      return appState;
    }
  }, [installedApps, hasAppsRead]);
  const googleAppState = useMemo(() => {
    if (hasAppsRead) {
      const appState = installedApps && (installedApps == null ? void 0 : installedApps.length) > 0 ? installedApps == null ? void 0 : installedApps.find((app) => app.stub === "google-workspace") : null;
      return appState;
    }
  }, [installedApps, hasAppsRead]);
  const microsoft365Installed = useMemo(() => {
    return !!microsoft365AppState;
  }, [microsoft365AppState]);
  const googleConnected = useMemo(() => {
    return !!googleAppState;
  }, [googleAppState]);
  const microsoft365Expired = useMemo(() => {
    console.log("microsoft365AppState", microsoft365AppState);
    return microsoft365AppState && microsoft365AppState.invalidatedAt !== null;
  }, [microsoft365AppState]);
  const googleExpired = useMemo(() => {
    console.log("googleAppState", googleAppState);
    return googleAppState && googleAppState.invalidatedAt !== null;
  }, [googleAppState]);
  const neitherAppIsConnected = useMemo(() => {
    const googleActive = googleConnected && !googleExpired;
    const outlookActive = microsoft365Installed && !microsoft365Expired;
    return !(googleActive || outlookActive);
  }, [googleConnected, microsoft365Installed, googleExpired, microsoft365Expired]);
  const syncGoogleCalendarOnDemand = () => __async(void 0, null, function* () {
    try {
      yield GoogleCalendarAPI.syncCalendar();
      showMessage(polyglot.t("CalendarPage.successMessages.sync"), "success");
    } catch (err) {
      showMessage(`${nestErrorMessage(err)}`, "error");
    }
  });
  const syncOutlookCalendarOnDemand = () => __async(void 0, null, function* () {
    try {
      yield OutlookCalendarAPI.syncCalendar();
      showMessage(polyglot.t("CalendarPage.successMessages.create"), "success");
    } catch (err) {
      showMessage(`${nestErrorMessage(err)}`, "error");
    }
  });
  const syncCalendar = () => {
    if (microsoft365Installed) syncOutlookCalendarOnDemand();
    if (googleConnected) syncGoogleCalendarOnDemand();
  };
  const [showMessage] = useMessage();
  const getCalendarSetting = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const settings = yield CalendarSettingsAPI.getSettings();
      setCalenderSettings(settings);
    } catch (error) {
      showMessage(
        polyglot.t("CalendarSettingDrawerContent.errorMessages.encounter", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [showMessage, polyglot]);
  useEffect(() => {
    getCalendarSetting();
  }, [getCalendarSetting]);
  const connectedApp = useMemo(() => {
    const googleActive = googleConnected && !googleExpired;
    const outlookActive = microsoft365Installed && !microsoft365Expired;
    if (googleActive && outlookActive) return "both Google & Outlook calendars";
    if (outlookActive) return "Outlook calendar";
    if (googleActive) return "Google calendar";
    return void 0;
  }, [googleConnected, microsoft365Installed, googleExpired, microsoft365Expired]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { color: themeColors.DarkGrey }, children: polyglot.t("CalendarSettingsPage.settingsTilte") })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "500px" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", justifyContent: "space-between", alignItems: "center" }, spacing.mb20), children: [
          /* @__PURE__ */ jsxs(Typography, { variant: "headline2", children: [
            " ",
            polyglot.t("CalendarSettingsPage.configureView")
          ] }),
          /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setEditMode(true), children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) })
        ] }),
        /* @__PURE__ */ jsx(CalendarSettingReadonly, { initialValues: calenderSettings, loading }),
        /* @__PURE__ */ jsx(
          CalendarSettingDrawer,
          {
            initialValues: calenderSettings,
            isOpen: editMode,
            setIsOpen: setEditMode,
            refresh: () => getCalendarSetting()
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "500px", mt: spacing.mt40 }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", justifyContent: "space-between", alignItems: "center" }, spacing.mb20), children: [
          /* @__PURE__ */ jsxs(Typography, { variant: "headline2", children: [
            " ",
            polyglot.t("CalendarSettingsPage.integration")
          ] }),
          !neitherAppIsConnected && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => setCalendarSyncDrawerOpen(true),
              children: polyglot.t("CalendarSettingsPage.resyncCalendar")
            }
          ),
          neitherAppIsConnected && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "secondary",
              onClick: () => routerHistory.push(APP_STORE_ROUTE),
              children: polyglot.t("CalendarSettingsPage.gotoAppStore")
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          CalendarIntegrationSettingReadonly,
          {
            microsoft365Connected: !!microsoft365Installed,
            googleConnected: !!googleConnected,
            microsoft365Expired: !!microsoft365Expired,
            googleExpired: !!googleExpired
          }
        ),
        /* @__PURE__ */ jsx(
          CalendarSettingDrawer,
          {
            initialValues: calenderSettings,
            isOpen: editMode,
            setIsOpen: setEditMode,
            refresh: () => getCalendarSetting()
          }
        )
      ] }),
      connectedApp && /* @__PURE__ */ jsx(
        CalendarSettingsSyncDrawer,
        {
          isOpen: calendarSyncDrawerOpen,
          setIsOpen: setCalendarSyncDrawerOpen,
          syncAction: () => {
            setCalendarSyncDrawerOpen(false);
            syncCalendar();
          },
          appName: connectedApp
        }
      )
    ] })
  ] });
};
const CalendarSettingReadonly = ({
  initialValues,
  loading
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: __spreadValues({
        display: "flex",
        flexDirection: "column",
        gap: spacing.g10
      }, spacing.mt20),
      children: /* @__PURE__ */ jsx(
        ViewItem,
        {
          title: polyglot.t("CalendarSettingReadonly.calendarView"),
          value: loading ? /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "20%", height: "16px" }) : initialValues && initialValues.viewSetting ? ViewSettingsLabel(polyglot)[initialValues.viewSetting] : ViewSettingsLabel(polyglot)[ViewSettingEnum.Company]
        }
      )
    }
  );
};
const CalendarIntegrationSettingReadonly = ({
  microsoft365Connected,
  googleConnected,
  microsoft365Expired,
  googleExpired
}) => {
  const { polyglot } = usePolyglot();
  const { data: existingPublishLinks } = useApiClient(
    microsoft365Connected ? CalendarEndpoints.getOutlookCalendarPublishLinks() : { url: "" },
    {
      suspense: false
    }
  );
  const integrationStatus = useMemo(() => {
    const googleActive = googleConnected && !googleExpired;
    const googleInactive = googleConnected && googleExpired;
    const outlookActive = microsoft365Connected && !microsoft365Expired;
    const outlookInactive = microsoft365Connected && microsoft365Expired;
    if (googleActive && outlookActive)
      return polyglot.t("CalendarIntegrationSettingReadonly.bothGoogleAndOutlookAreConnected");
    if (googleActive && outlookInactive)
      return polyglot.t("CalendarIntegrationSettingReadonly.googleConnectedOutlookExpired");
    if (googleInactive && outlookActive)
      return polyglot.t("CalendarIntegrationSettingReadonly.googleExpiredOutlookConnected");
    if (googleInactive && outlookInactive)
      return polyglot.t("CalendarIntegrationSettingReadonly.bothGoogleAndOutlookAreExpired");
    if (outlookActive) return polyglot.t("CalendarIntegrationSettingReadonly.outlookConnected");
    if (googleActive) return polyglot.t("CalendarIntegrationSettingReadonly.googleConnected");
    if (googleInactive) return polyglot.t("CalendarIntegrationSettingReadonly.googleExpired");
    if (outlookInactive) return polyglot.t("CalendarIntegrationSettingReadonly.outlookExpired");
    return polyglot.t("CalendarIntegrationSettingReadonly.noCalendarIntegrations");
  }, [googleConnected, googleExpired, microsoft365Connected, microsoft365Expired, polyglot]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues({
        display: "flex",
        flexDirection: "column",
        gap: spacing.g10
      }, spacing.mt20),
      children: [
        /* @__PURE__ */ jsx(ViewItem, { title: integrationStatus, onlyTitle: true }),
        /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
          (existingPublishLinks == null ? void 0 : existingPublishLinks.icsURL) && /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("CalendarIntegrationSettingReadonly.icsCalendarLink"),
              fieldValue: (existingPublishLinks == null ? void 0 : existingPublishLinks.icsURL) && {
                isLink: true,
                label: truncateWithEllipses(existingPublishLinks.icsURL, 30),
                icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                onClick: () => navigator.clipboard.writeText(existingPublishLinks.icsURL)
              },
              fieldStub: "icsURL"
            }
          ),
          (existingPublishLinks == null ? void 0 : existingPublishLinks.htmlURL) && /* @__PURE__ */ jsx(
            FieldStructure,
            {
              fieldTitle: polyglot.t("CalendarIntegrationSettingReadonly.htmlCalendarLink"),
              fieldValue: (existingPublishLinks == null ? void 0 : existingPublishLinks.htmlURL) && {
                isLink: true,
                label: truncateWithEllipses(existingPublishLinks.htmlURL, 30),
                icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                onClick: () => navigator.clipboard.writeText(existingPublishLinks.htmlURL)
              },
              fieldStub: "htmlURL"
            }
          )
        ] })
      ]
    }
  );
};
export const ViewItem = ({
  title,
  value = "",
  onlyTitle = false
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "inline-grid",
        gridTemplateColumns: !onlyTitle ? "1.5fr 2.5fr" : "4fr 1fr",
        rowGap: spacing.g10,
        columnGap: spacing.g20
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: title }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: value })
      ]
    }
  );
};
