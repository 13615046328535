"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { themeColors } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
export const SettingsSectionContent = ({
  children,
  title,
  onEdit,
  buttons,
  headerWidth = "600px",
  contentWidth = "600px",
  buttonIcon = /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)),
  loading = false,
  noHorizontalPadding = true,
  topHeaderPaddingSx,
  contentSx,
  id
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", overflowY: "auto" }, children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: headerWidth,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            },
            children: [
              /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: typeof title === "string" ? /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { color: themeColors.DarkGrey }, children: title }) : title }),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
                buttons,
                onEdit && /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: onEdit, children: buttonIcon }, "edit")
              ] })
            ]
          }
        ),
        sx: __spreadValues({ width: headerWidth }, topHeaderPaddingSx)
      }
    ),
    /* @__PURE__ */ jsx(
      ContentWrapper,
      {
        sx: __spreadValues({ scrollbarWidth: "none" }, contentSx),
        noHorizontalPadding,
        loading,
        children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g48,
              width: contentWidth,
              boxSizing: "border-box"
            },
            id,
            children
          }
        )
      }
    )
  ] });
};
