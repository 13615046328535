"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GrowthForbiddenAccess = () => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      style: {
        backgroundColor: themeColors.Background,
        padding: "16px",
        borderRadius: radius.br8,
        maxWidth: "600px",
        minHeight: "40%",
        display: "flex",
        flexDirection: "column"
      },
      children: /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: spacing.g16
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "Forbidden access" }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "You dont have access to this page details" })
          ]
        }
      )
    }
  );
};
