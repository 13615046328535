"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const BigFilterButton = ({
  active,
  title,
  action,
  subtitle,
  loading
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        boxSizing: "border-box",
        width: "160px",
        height: "70px",
        borderRadius: radius.br12,
        border: borders.background,
        padding: spacing.s1,
        display: "flex",
        flexDirection: "column",
        gap: spacing.s1,
        alignItems: "flex-start",
        background: active ? themeColors.Background : themeColors.white,
        cursor: "pointer",
        "&:hover": {
          background: themeColors.Background
        }
      },
      onClick: action,
      role: "button",
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: active ? "headline3" : "paragraph", children: title }),
        loading ? /* @__PURE__ */ jsx(SkeletonLoader, { variant: "text", width: "100px" }) : /* @__PURE__ */ jsx(
          ChipComponent,
          {
            name: subtitle,
            textVariant: active ? "headline3" : "paragraph",
            backgroundColor: "white",
            border: "background",
            textColor: "DarkGrey"
          }
        )
      ]
    }
  );
};
