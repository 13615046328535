"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Close } from "@/images/app-icons/Close.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserAPI, UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ProfilePicture = ({ disabled, userId }) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const inputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState("");
  const inputKey = useRef(Date.now());
  const { data: src } = useApiClient(UserEndpoints.getUserAvatarURL(userId));
  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    const form = new FormData();
    form.append("file", e.target.files[0]);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", UserAPI.getUserAvatarURL(userId));
    xhr.send(form);
  };
  const clearImage = () => {
    inputKey.current = Date.now();
    setPreviewImage("");
    UserAPI.deleteUserAvatar(userId).catch(() => null);
  };
  useLayoutEffect(() => {
    if (src) {
      const previewURL = `${state.publicURL}/${src}`;
      setPreviewImage(previewURL);
    }
  }, [src, state.publicURL]);
  return /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
    previewImage && /* @__PURE__ */ jsxs(Stack, { sx: { position: "relative" }, children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          sx: __spreadProps(__spreadValues({}, secondaryCTABtn), {
            height: "60px",
            width: "60px",
            borderRadius: "50%",
            padding: 0,
            margin: 0
          }),
          onClick: () => {
            var _a;
            return (_a = inputRef.current) == null ? void 0 : _a.click();
          },
          disabled,
          children: /* @__PURE__ */ jsx(
            "img",
            {
              src: previewImage,
              alt: "",
              style: {
                height: "60px",
                width: "60px",
                border: `1px solid ${themeColors.lightGrey}`,
                borderRadius: "50%"
              }
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, secondaryCTABtn), {
            left: -4,
            top: -4,
            height: 16,
            width: 16,
            padding: 0,
            opacity: 0.5,
            position: "absolute"
          }),
          onClick: () => clearImage(),
          disabled,
          children: /* @__PURE__ */ jsx(Close, { height: 10, width: 10, stroke: themeColors.DarkGrey })
        }
      )
    ] }),
    !previewImage && /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "small",
        colorVariant: "primary",
        style: {
          height: "60px",
          width: "60px",
          borderRadius: "50%",
          padding: 0,
          margin: 0
        },
        onClick: () => {
          var _a;
          return (_a = inputRef.current) == null ? void 0 : _a.click();
        },
        disabled,
        children: /* @__PURE__ */ jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: /* @__PURE__ */ jsx(
          "path",
          {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M6 4.5L9 1.5H15L18 4.5H24V21H0V4.5H6ZM9.62132 3H14.3787L17.3787 6H22.5V19.5H1.5V6H6.62132L9.62132 3ZM11.25 6.75H12.75V11.25H17.25V12.75H12.75V17.25H11.25V12.75H6.75V11.25H11.25V6.75Z",
            fill: "white"
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(
      "input",
      {
        type: "file",
        ref: inputRef,
        onChange: handleFileChange,
        style: { display: "none" },
        disabled
      },
      inputKey.current
    ),
    !previewImage && !disabled && /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
          color: themeColors.Grey
        }),
        children: polyglot.t("OnboardingByUser.addProfilePicture")
      }
    )
  ] });
};
