"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewTemplateAPI } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UpsertReviewTemplateModal = ({
  isOpen,
  setIsOpen,
  onClose,
  template
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setIsOpen(false);
      },
      children: /* @__PURE__ */ jsx(CreateReviewTemplateContent, { onClose, template })
    }
  );
};
const CreateReviewTemplateContent = ({
  onClose,
  template
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const TemplateSchema = useMemo(
    () => Yup.object().shape({
      name: Yup.string().max(255, polyglot.t("validation.selectValid")).required()
    }),
    [polyglot]
  );
  const formik = useFormik({
    initialValues: {
      name: template ? template.name : ""
    },
    enableReinitialize: true,
    validationSchema: TemplateSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (template) {
          yield ReviewTemplateAPI.updateReviewTemplate(__spreadValues({ id: template.id }, values));
          showMessage("Successfully updated", "success");
        } else {
          const createdTemplate = yield ReviewTemplateAPI.createReviewTemplate(values);
          formik.resetForm();
          routerHistory.push(
            generatePath(SETTINGS_GROWTH_REVIEWS_TEMPLATE_BYID_ROUTE, {
              templateId: createdTemplate == null ? void 0 : createdTemplate.id
            })
          );
          showMessage("Successfully created", "success");
        }
        onClose();
      } catch (error) {
        showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, style: { height: "100%" }, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g20,
        height: "100%"
      },
      children: [
        /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
          template ? "Edit" : "New",
          " template"
        ] }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "name",
            label: "Name",
            value: formik.values.name,
            type: "text",
            onChange: formik.handleChange,
            error: formik.touched.name && !!formik.errors.name,
            helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " ",
            clearText: () => formik.setFieldValue("name", ""),
            disabled: loading,
            required: true
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        ) })
      ]
    }
  ) }) });
};
