"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Chip } from "@mui/material";
import { JsonViewer } from "@textea/json-viewer";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import useMessage from "@/hooks/notification.hook";
export const HelperAbsencePolicyBalanceDrawer = ({
  isOpen,
  setIsOpen,
  companyId,
  absencePolicies,
  users
}) => {
  var _a;
  const [showMessage] = useMessage();
  const currentYear = useMemo(() => (/* @__PURE__ */ new Date()).getFullYear(), []);
  const [year, setYear] = useState(currentYear);
  const [useEffectiveDate, setUseEffectiveDate] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(new LocalDate().toDateString());
  const [userId, setUserId] = useState(null);
  const [policyId, setPolicyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);
  const userOptions = useMemo(() => {
    return users.filter((u) => u.company.companyId === companyId).map((u) => ({
      value: u.userId,
      label: `${u.firstName} ${u.lastName} - [${u.userId}]`
    }));
  }, [users, companyId]);
  const policyOptions = useMemo(() => {
    return absencePolicies.map((p) => ({ value: p.id, label: p.name }));
  }, [absencePolicies]);
  const getBalance = useCallback(
    (userId2, policyId2, options) => __async(void 0, null, function* () {
      if (!userId2 || !policyId2) return null;
      try {
        setLoading(true);
        const balance2 = yield AbsenceAPI.getUserAbsenceBalanceBreakdownByPolicyIdAsSuperAdmin(
          companyId,
          userId2,
          policyId2,
          options.useEffectiveDate ? null : options.year,
          !options.useEffectiveDate ? null : options.effectiveDate
        );
        setBalance(balance2);
      } catch (error) {
        console.error(error);
        showMessage("Something went wrong", error);
        setBalance(null);
      }
      setLoading(false);
    }),
    [companyId, showMessage]
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Absence balance" }),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "user",
        label: "User",
        fullWidth: true,
        options: userOptions,
        filterSelectedOptions: true,
        value: (_a = userOptions.find((o) => o.value === userId)) != null ? _a : null,
        onChange: (_, x) => {
          setUserId((x == null ? void 0 : x.value) ? Number(x.value) : null);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        compareValue: userId != null ? userId : "",
        renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => {
          return /* @__PURE__ */ jsx(Chip, __spreadValues({ label: option.label }, getTagProps({ index })));
        })
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "policy",
        label: "Policy",
        options: policyOptions,
        value: policyId,
        onChange: (e) => setPolicyId(Number(e.target.value))
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Use date" }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: useEffectiveDate,
          name: "useEffectiveDate",
          onChange: (_e, enabled) => __async(void 0, null, function* () {
            setUseEffectiveDate(enabled);
          })
        }
      )
    ] }),
    useEffectiveDate ? /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "effectiveDate",
        label: "Effective date",
        onChange: (value) => {
          setEffectiveDate(value);
        },
        value: effectiveDate
      }
    ) : /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "year",
        label: "Year",
        options: [
          { value: currentYear - 3, label: (currentYear - 3).toString() },
          { value: currentYear - 2, label: (currentYear - 2).toString() },
          { value: currentYear - 1, label: (currentYear - 1).toString() },
          { value: currentYear, label: currentYear.toString() },
          { value: currentYear + 1, label: (currentYear + 1).toString() },
          { value: currentYear + 2, label: (currentYear + 2).toString() }
        ],
        value: year,
        onChange: (e) => setYear(Number(e.target.value))
      }
    ),
    balance && /* @__PURE__ */ jsx(Box, { sx: { mt: "20px" }, children: /* @__PURE__ */ jsx(JsonViewer, { value: balance, theme: "dark", rootName: "Balance" }) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        loading,
        colorVariant: "primary",
        name: "Get balance",
        onClick: () => __async(void 0, null, function* () {
          if (!userId || !policyId) return null;
          yield getBalance(userId, policyId, { year, effectiveDate, useEffectiveDate });
        }),
        fullWidth: true,
        disabled: !userId || !policyId
      }
    ) })
  ] }) });
};
