"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { QuestionsDrawer } from "@v2/feature/device/components/device-details/questions-drawer.component";
import { DeviceAPI, DeviceEndpoints } from "@v2/feature/device/device.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
const getValidationSchema = (inHouseMdm, isMobileAppleDevice) => yup.object({
  confirmationText: yup.string().test("matches-pin", "Confirmation must match the PIN code", function(value) {
    if (!inHouseMdm || isMobileAppleDevice) {
      return value === "Confirm";
    }
    return value === this.parent.pinCode;
  }).required("Confirmation is required"),
  pinCode: inHouseMdm && !isMobileAppleDevice ? yup.string().matches(/^\d{6}$/, "PIN code must be exactly 6 digits").required("Six-digit PIN code is required") : yup.string().nullable()
});
const ContentContainer = ({ children }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children });
const useLockDeviceStatus = (devicePossession) => {
  var _a;
  const { data } = useApiClient(
    DeviceEndpoints.getActivityLogForDeviceByDomainAndActionName(
      devicePossession.deviceId,
      ((_a = devicePossession.device) == null ? void 0 : _a.inHouseMdm) ? "deviceActionInHouseMdm" : "deviceAction",
      "lockDevice"
    ),
    { suspense: false }
  );
  return data != null ? data : false;
};
const useGetDeviceLockPinCode = (deviceId) => {
  const { data } = useApiClient(DeviceEndpoints.getDeviceLockPinCode(deviceId), { suspense: false });
  return data != null ? data : null;
};
const UnlockPinDisplay = ({ deviceId }) => {
  const { polyglot } = usePolyglot();
  const pinCodeFromDb = useGetDeviceLockPinCode(deviceId);
  return /* @__PURE__ */ jsxs(ContentContainer, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("LockDevice.unlockPinTitle") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.m5 }, children: polyglot.t("LockDevice.unlockPinMessage") }),
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: pinCodeFromDb ? pinCodeFromDb : "N/A" })
  ] });
};
const LockDeviceDrawerContent = (props) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const deviceName = ((_b = (_a = props.devicePossession) == null ? void 0 : _a.device) == null ? void 0 : _b.deviceName) || polyglot.t("LockDevice.defaultDeviceName");
  const hasActionToday = useLockDeviceStatus(props.devicePossession);
  const [isQuestionFromOpened, setIsQuestionFromOpened] = useState(false);
  const isInHouseMdm = (_d = (_c = props.devicePossession.device) == null ? void 0 : _c.inHouseMdm) != null ? _d : false;
  useEffect(() => {
    if (hasActionToday !== void 0) {
      setIsQuestionFromOpened(hasActionToday);
    }
  }, [hasActionToday]);
  const getLockMessage = () => {
    var _a2;
    return !((_a2 = props.devicePossession.device) == null ? void 0 : _a2.inHouseMdm) ? polyglot.t("LockDevice.messages.hexnode") : props.isMobileAppleDevice ? polyglot.t("LockDevice.messages.mobile") : polyglot.t("LockDevice.messages.macOS");
  };
  const formik = useFormik({
    initialValues: {
      confirmationText: "",
      pinCode: ""
    },
    validationSchema: getValidationSchema(isInHouseMdm, props.isMobileAppleDevice),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        yield DeviceAPI.lockDevice(props.devicePossession.id, getLockMessage(), Number(values.pinCode));
        showMessage(polyglot.t("LockDevice.messages.success"), "success");
        yield props.onClose();
      } catch (error) {
        showMessage(polyglot.t("LockDevice.messages.error"), "error");
      }
    })
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      QuestionsDrawer,
      {
        title: polyglot.t("LockDevice.action.executedTitle"),
        description: polyglot.t("LockDevice.action.exists"),
        isOpen: isQuestionFromOpened,
        setIsOpen: setIsQuestionFromOpened,
        loading: false,
        action: () => __async(void 0, null, function* () {
          setIsQuestionFromOpened(false);
        }),
        onClose: props.onClose,
        children: !props.isMobileAppleDevice && /* @__PURE__ */ jsx(UnlockPinDisplay, { deviceId: props.devicePossession.deviceId })
      }
    ),
    /* @__PURE__ */ jsx("form", { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("LockDevice.title") }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("LockDevice.warningMessage", { deviceName }) }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: getLockMessage() })
      ] }),
      isInHouseMdm && !props.isMobileAppleDevice && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { mb: spacing.m5 }, children: polyglot.t("LockDevice.pinCodeLabel") }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "pinCode",
            placeholder: polyglot.t("LockDevice.pinCodePlaceholder"),
            fullWidth: true,
            value: formik.values.pinCode,
            type: "number",
            onChange: (e) => {
              if (isInHouseMdm && !props.isMobileAppleDevice) {
                const value = e.target.value;
                if (value.length <= 6) {
                  formik.setFieldValue("pinCode", value);
                }
              } else {
                formik.handleChange(e);
              }
            },
            onBlur: formik.handleBlur,
            clearText: () => formik.setFieldValue("pinCode", ""),
            error: formik.touched.pinCode && Boolean(formik.errors.pinCode),
            helperText: formik.touched.pinCode && formik.errors.pinCode,
            inputProps: isInHouseMdm && !props.isMobileAppleDevice ? {
              maxLength: 6,
              pattern: "[0-9]*",
              inputMode: "numeric"
            } : {}
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.m5 } })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { mb: spacing.m5 }, children: isInHouseMdm && !props.isMobileAppleDevice ? polyglot.t("LockDevice.confirmTextPin") : polyglot.t("LockDevice.confirmationLabel") }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "confirmationText",
            placeholder: isInHouseMdm && !props.isMobileAppleDevice ? polyglot.t("LockDevice.confirmPlaceholderPin") : polyglot.t("LockDevice.confirmPlaceholder"),
            fullWidth: true,
            type: isInHouseMdm && !props.isMobileAppleDevice ? "number" : "text",
            value: formik.values.confirmationText,
            onChange: (e) => {
              if (isInHouseMdm && !props.isMobileAppleDevice) {
                const value = e.target.value;
                if (value.length <= 6) {
                  formik.setFieldValue("confirmationText", value);
                }
              } else {
                formik.handleChange(e);
              }
            },
            onBlur: formik.handleBlur,
            clearText: () => formik.setFieldValue("confirmationText", ""),
            error: formik.touched.confirmationText && Boolean(formik.errors.confirmationText),
            helperText: formik.touched.confirmationText && formik.errors.confirmationText,
            inputProps: isInHouseMdm && !props.isMobileAppleDevice ? {
              maxLength: 6,
              pattern: "[0-9]*",
              inputMode: "numeric"
            } : {}
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          disabled: !formik.isValid || formik.isSubmitting,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary",
          type: "submit",
          children: polyglot.t("LockDevice.submitButton")
        }
      ) })
    ] }) })
  ] });
};
export const LockDeviceDrawer = (props) => /* @__PURE__ */ jsx(DrawerModal, { isOpen: props.isOpen, setIsOpen: props.setIsOpen, onClose: props.onClose, children: /* @__PURE__ */ jsx(LockDeviceDrawerContent, __spreadValues({}, props)) });
