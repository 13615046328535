"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { Typography } from "@v2/components/typography/typography.component";
import { FormikProvider, useFormik } from "formik";
import { GlobalContext } from "@/GlobalState";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DevicePolicyZeltType } from "@/v2/feature/device/device.interface";
export const ZeltMdmAppsPolicyEditor = ({
  companyPolicies,
  updatePolicies,
  configurablePoliciesSelected,
  isOpen,
  setIsOpen
}) => {
  const [state] = useContext(GlobalContext);
  const initialValues = {
    apps: companyPolicies.filter((element) => element.type === DevicePolicyZeltType.apps && element.isInhouseMdm).filter((element) => !(element.externalId === 9 && state.user.company.companyId !== 618)).filter((element) => !(element.externalId === 18 && state.user.company.companyId !== 877)).filter((element) => !(element.externalId === 19 && state.user.company.companyId !== 618)).map((app) => {
      var _a, _b;
      const zeltManagedApp = {
        id: app.id,
        selected: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableApps) == null ? void 0 : _a.includes(app.id)) != null ? _b : false,
        name: app.name,
        externalId: app.externalId
      };
      return zeltManagedApp;
    })
  };
  const formik = useFormik({
    initialValues,
    onSubmit: (selectedApps) => __async(void 0, null, function* () {
      let appsToSave = [];
      selectedApps.apps.filter((app) => app.selected).map((app) => appsToSave.push(app.id));
      if (appsToSave.length === 0) {
        appsToSave.push(0);
      }
      const configurablePoliciesDto = __spreadProps(__spreadValues({}, configurablePoliciesSelected), {
        configurableApps: appsToSave
      });
      yield updatePolicies(configurablePoliciesDto);
      if (isOpen) {
        setIsOpen(false);
      }
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Managed apps" }),
    /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 3 }, children: formik.values.apps.map((app, index) => /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: app.name,
          name: `app-${index}-${app.id}`,
          checked: app.selected,
          onChange: (e) => {
            const inputElement = e.target;
            const checkboxName = inputElement.name;
            const isChecked = inputElement.checked;
            const [, checkboxIndex, appId] = checkboxName.match(/app-(\d+)-(\d+)/) || [];
            const indexValue = parseInt(checkboxIndex, 10);
            parseInt(appId, 10);
            const updatedApps = [...formik.values.apps];
            updatedApps[indexValue].selected = isChecked;
            formik.setValues(__spreadProps(__spreadValues({}, formik.values), {
              apps: updatedApps
            }));
          }
        },
        `${index}${app.id}`
      )) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: 2, width: "100%", alignItems: "center", marginTop: "30px" }, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => formik.handleSubmit(), fullWidth: true, colorVariant: "primary", sizeVariant: "medium", children: "Save" }) })
    ] })
  ] });
};
