"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  AnonymityType,
  ComparedBy,
  MeasureBy,
  ShowBy
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MeasureFilterToLabel = {
  [MeasureBy.NPS]: "NPS",
  [MeasureBy.Positive]: "Positive sentiment",
  [MeasureBy.Average]: "Average"
};
export const SurveyCycleDetailHeatmapFilters = ({
  setShowByFilter,
  dispatch,
  setFilter,
  setMeasureByFilter,
  showByFilter,
  measureByFilter,
  filter,
  surveyCycle
}) => {
  const [showMessage] = useMessage();
  const setShowByHandler = useCallback(
    (showValue) => __async(void 0, null, function* () {
      try {
        setShowByFilter(showValue);
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("surveys", "heatMap", "showBy", showValue);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, setShowByFilter, showMessage]
  );
  const getActions = useMemo(
    () => [
      { handler: () => __async(void 0, null, function* () {
        return yield setShowByHandler(ShowBy.Questions);
      }), label: capitalize(ShowBy.Questions) },
      { handler: () => __async(void 0, null, function* () {
        return yield setShowByHandler(ShowBy.Factor);
      }), label: capitalize(ShowBy.Factor) }
    ],
    [setShowByHandler]
  );
  const setFilterHandler = useCallback(
    (comparedValue) => __async(void 0, null, function* () {
      try {
        setFilter(comparedValue);
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "surveys",
          "heatMap",
          "comparedBy",
          comparedValue
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, setFilter, showMessage]
  );
  const getFilters = useMemo(
    () => [
      {
        handler: () => __async(void 0, null, function* () {
          return yield setFilterHandler(ComparedBy.Site);
        }),
        label: capitalize(ComparedBy.Site),
        disabled: false
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setFilterHandler(ComparedBy.Department);
        }),
        label: capitalize(ComparedBy.Department),
        disabled: false
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setFilterHandler(ComparedBy.Gender);
        }),
        label: capitalize(ComparedBy.Gender),
        disabled: false
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setFilterHandler(ComparedBy.Tenure);
        }),
        label: capitalize(ComparedBy.Tenure),
        disabled: false
      }
    ],
    [setFilterHandler]
  );
  const setMeasuredByHandler = useCallback(
    (measureValue) => __async(void 0, null, function* () {
      try {
        setMeasureByFilter(measureValue);
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("surveys", "heatMap", "measuredBy", measureValue);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, setMeasureByFilter, showMessage]
  );
  const measuredByOptions = useMemo(
    () => [
      {
        handler: () => __async(void 0, null, function* () {
          return yield setMeasuredByHandler(MeasureBy.NPS);
        }),
        label: MeasureBy.NPS
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setMeasuredByHandler(MeasureBy.Positive);
        }),
        label: MeasureBy.Positive
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setMeasuredByHandler(MeasureBy.Average);
        }),
        label: MeasureBy.Average
      }
    ],
    [setMeasuredByHandler]
  );
  const shouldShouldComparedBy = useMemo(() => {
    var _a, _b, _c;
    return Boolean(surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) && !Boolean((_a = surveyCycle.visibilitySettings) == null ? void 0 : _a.anonymiseAnswers) || ((_b = surveyCycle.visibilitySettings) == null ? void 0 : _b.anonymiseAnswers) && ((_c = surveyCycle.visibilitySettings) == null ? void 0 : _c.anonymityType) === AnonymityType.Semi;
  }, [surveyCycle.visibilitySettings]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Show by" }),
      /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: getActions,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: capitalize(showByFilter),
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end",
            style: { borderRadius: radius.br25 }
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Measured by" }),
      /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: measuredByOptions,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: MeasureFilterToLabel[measureByFilter],
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end",
            style: { borderRadius: radius.br25 }
          }
        }
      )
    ] }),
    shouldShouldComparedBy && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Compared by" }),
      /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: getFilters,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: capitalize(filter),
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end",
            style: { borderRadius: radius.br25 }
          }
        }
      )
    ] })
  ] });
};
