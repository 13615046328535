"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box, Divider, List, ListItem, ListItemText, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { OauthClientAPI, OauthClientEndpoints } from "@/api-client/oauth-client.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { PersistentNotification } from "@/v2/feature/entity-import/wizard/components/user/persistent-notification.component";
import {
  APIDescriptions,
  APIScopeMapping
} from "@/v2/feature/public-api/public-api.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ConsentPage = () => {
  var _a, _b;
  const qs = new URLSearchParams(useLocation().search);
  const noParamsAvailable = qs.size === 0;
  const clientId = qs.get("client_id");
  const responseType = qs.get("response_type");
  const stateValue = qs.get("state");
  const redirectUri = qs.get("redirect_uri");
  const { data: clientDetails, isValidating: loadingClient } = useApiClient(
    clientId && redirectUri && responseType === "code" ? OauthClientEndpoints.getSpecificClientForCompanyForConsentStart(clientId, redirectUri) : { url: void 0 },
    {
      suspense: false
    }
  );
  const { getCachedUserById } = useCachedUsers();
  const [state] = useContext(GlobalContext);
  const currentCompanyId = (_b = (_a = state == null ? void 0 : state.user) == null ? void 0 : _a.company) == null ? void 0 : _b.companyId;
  const [showMessage] = useMessage();
  const privateAppUsage = (clientDetails == null ? void 0 : clientDetails.companyId) === currentCompanyId;
  const publishedAppForOtherCompanies = (clientDetails == null ? void 0 : clientDetails.published) === true && (clientDetails == null ? void 0 : clientDetails.companyId) !== currentCompanyId;
  const clientCreatedBy = clientDetails ? getCachedUserById(clientDetails.createdBy) : void 0;
  const appName = clientDetails ? `app (${clientDetails.name})` : "app";
  const creatorName = clientCreatedBy ? `${clientCreatedBy.displayName} (${clientCreatedBy.emailAddress})` : "an employee";
  const onConsentHandler = () => __async(void 0, null, function* () {
    try {
      if (clientId && stateValue && redirectUri) {
        const response = yield OauthClientAPI.validateAuthorizationAttempt(clientId, stateValue, redirectUri);
        if (response.status === 200) {
          const redirectUrl = response.headers["location"];
          window.location.href = redirectUrl;
        } else {
          showMessage(`Failed to authorize - Unexpected response status: ${response.status}`, "error");
        }
      } else {
        showMessage(`Failed to authorize - Missing required params`, "error");
      }
    } catch (error) {
      console.error(error);
      showMessage(`Failed to authorize: ${nestErrorMessage(error)}`, "error");
    }
  });
  const onDenyHandler = () => {
    window.location.href = `${redirectUri}?error=access_denied`;
  };
  const AppLogoStack = ({ appLogo }) => {
    const appLogoLink = `${state.publicURL}/${appLogo}`;
    return /* @__PURE__ */ jsx(Stack, { direction: "row", justifyContent: "center", children: /* @__PURE__ */ jsx("img", { width: "100px", src: appLogoLink, alt: "zelt" }) });
  };
  const WhatCanAppDo = ({ configuredScopes }) => {
    const categorizeScopes = () => {
      const readDescriptions2 = [];
      const writeDescriptions2 = [];
      configuredScopes.forEach((scope) => {
        Object.entries(APIScopeMapping.read).forEach(([action, scopes]) => {
          if (scopes.includes(scope)) {
            const description = APIDescriptions.read[action];
            if (description && !readDescriptions2.includes(description)) {
              readDescriptions2.push(description);
            }
          }
        });
        Object.entries(APIScopeMapping.write).forEach(([action, scopes]) => {
          if (scopes.includes(scope)) {
            const description = APIDescriptions.write[action];
            if (description && !writeDescriptions2.includes(description)) {
              writeDescriptions2.push(description);
            }
          }
        });
      });
      return { readDescriptions: readDescriptions2, writeDescriptions: writeDescriptions2 };
    };
    const { readDescriptions, writeDescriptions } = categorizeScopes();
    return /* @__PURE__ */ jsxs(
      Box,
      {
        sx: { maxWidth: "600px", margin: "0 auto", p: 2, bgcolor: "background.paper", borderRadius: 2, boxShadow: 2 },
        children: [
          clientDetails && privateAppUsage && /* @__PURE__ */ jsxs("ul", { style: __spreadProps(__spreadValues({ paddingLeft: "16px" }, themeFonts.paragraph), { listStyle: "none" }), children: [
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Client ID:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: clientDetails.clientId })
            ] }),
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Client name:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: clientDetails.name })
            ] }),
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Description:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: clientDetails.description })
            ] }),
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Redirect URI:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: clientDetails.redirectionUri })
            ] }),
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Created by:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: creatorName })
            ] }),
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Created on:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: new Date(clientDetails.createdAt).toUTCString() })
            ] })
          ] }),
          clientDetails && publishedAppForOtherCompanies && /* @__PURE__ */ jsxs("ul", { style: __spreadProps(__spreadValues({ paddingLeft: "16px" }, themeFonts.paragraph), { listStyle: "none" }), children: [
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Client name:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: clientDetails.name })
            ] }),
            /* @__PURE__ */ jsxs("li", { children: [
              /* @__PURE__ */ jsx("b", { children: "Description:" }),
              " ",
              /* @__PURE__ */ jsx("i", { children: clientDetails.description })
            ] })
          ] }),
          /* @__PURE__ */ jsx(Divider, {}),
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline2), { mt: 2, textDecoration: "underline" }), children: [
            "What can ",
            /* @__PURE__ */ jsx("b", { children: appName }),
            " read?"
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: { maxHeight: "100px", overflowY: "auto", mb: 2 }, children: /* @__PURE__ */ jsx(List, { dense: true, children: readDescriptions.map((eachAbility, index) => /* @__PURE__ */ jsx(ListItem, { disablePadding: true, children: /* @__PURE__ */ jsx(ListItemText, { primary: eachAbility }) }, index)) }) }),
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline2), { mb: 2, textDecoration: "underline" }), children: [
            "What can ",
            /* @__PURE__ */ jsx("b", { children: appName }),
            " do?"
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: { maxHeight: "100px", overflowY: "auto" }, children: /* @__PURE__ */ jsx(List, { dense: true, children: writeDescriptions.map((eachAbility, index) => /* @__PURE__ */ jsx(ListItem, { disablePadding: true, children: /* @__PURE__ */ jsx(ListItemText, { primary: eachAbility }) }, index)) }) })
        ]
      }
    );
  };
  return /* @__PURE__ */ jsx(Stack, { sx: { margin: "auto", justifyContent: "center" }, children: noParamsAvailable ? /* @__PURE__ */ jsx(PersistentNotification, { inUse: true, message: "Cannot authorize unknown app", maxWidth: "700px" }) : loadingClient ? /* @__PURE__ */ jsx(
    SkeletonLoader,
    {
      variant: "rectangular",
      width: "90%",
      height: "90vh",
      sx: { borderRadius: "10px", mx: "auto", mt: 4 }
    }
  ) : !clientDetails ? /* @__PURE__ */ jsx(PersistentNotification, { inUse: true, message: "Cannot authorize unknown app", maxWidth: "700px" }) : /* @__PURE__ */ jsxs(Fragment, { children: [
    privateAppUsage && /* @__PURE__ */ jsx(
      PersistentNotification,
      {
        inUse: true,
        message: `This ${appName} was created by ${creatorName} within your Zelt account`,
        maxWidth: "700px"
      }
    ),
    /* @__PURE__ */ jsxs(
      Paper,
      {
        sx: {
          padding: 3,
          maxWidth: 700,
          width: "100%"
        },
        elevation: 3,
        children: [
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline2), { mb: 2 }), children: [
            clientDetails ? clientDetails.name : "Client",
            " is requesting access to your Zelt account."
          ] }),
          clientDetails && clientDetails.logo && /* @__PURE__ */ jsx(AppLogoStack, { appLogo: clientDetails.logo }),
          /* @__PURE__ */ jsx(WhatCanAppDo, { configuredScopes: clientDetails == null ? void 0 : clientDetails.scopes }),
          /* @__PURE__ */ jsxs(Stack, { direction: "row", sx: { margin: "auto", justifyContent: "center", gap: spacing.g30 }, children: [
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                colorVariant: "secondary",
                sizeVariant: "medium",
                style: { marginTop: "16px" },
                onClick: () => onDenyHandler(),
                children: "Deny"
              }
            ),
            /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: "Allow access",
                sizeVariant: "medium",
                colorVariant: "primary",
                style: { marginTop: "16px" },
                onClick: onConsentHandler,
                loading: false
              }
            )
          ] })
        ]
      }
    )
  ] }) });
};
