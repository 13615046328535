"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, capitalize } from "@mui/material";
import { iconSize } from "@v2/components/forms/editable-title.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { MeasureFilterToLabel } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-cycle-detail-results.page";
import { radius } from "@v2/styles/radius.styles";
import { GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { FiltersDrawer } from "@/v2/components/table/filters-drawer.component";
import { AnonymityType } from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const SurveyResultsFilters = ({
  existingFactors,
  showByFilter,
  setShowByFilter,
  measureByFilter,
  setMeasureByFilter,
  impactFilter,
  setImpactFilter,
  dispatch,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
  surveyCycle
}) => {
  var _a, _b;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const setShowByHandler = useCallback(
    (value) => __async(void 0, null, function* () {
      try {
        if (!setShowByFilter) return;
        setShowByFilter(value);
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("surveys", "results", "showBy", value);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, setShowByFilter, showMessage]
  );
  const showByOptions = useMemo(
    () => setShowByFilter ? [
      {
        handler: () => __async(void 0, null, function* () {
          return yield setShowByHandler("Question");
        }),
        label: "Question"
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setShowByHandler("Factor");
        }),
        label: "Factor"
      }
    ] : [],
    [setShowByHandler, setShowByFilter]
  );
  const setMeasuredByHandler = useCallback(
    (value) => __async(void 0, null, function* () {
      try {
        setMeasureByFilter(value);
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures("surveys", "results", "measuredBy", value);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, setMeasureByFilter, showMessage]
  );
  const measuredByOptions = useMemo(
    () => [
      {
        handler: () => __async(void 0, null, function* () {
          return yield setMeasuredByHandler("nps");
        }),
        label: MeasureFilterToLabel["nps"]
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setMeasuredByHandler("positive");
        }),
        label: MeasureFilterToLabel["positive"]
      },
      {
        handler: () => __async(void 0, null, function* () {
          return yield setMeasuredByHandler("avg");
        }),
        label: MeasureFilterToLabel["avg"]
      }
    ],
    [setMeasuredByHandler]
  );
  const impactOnOptions = useMemo(
    () => existingFactors.map((factor) => ({
      handler: () => __async(void 0, null, function* () {
        return yield setImpactFilter(factor);
      }),
      label: factor != null ? factor : ""
    })),
    [existingFactors, setImpactFilter]
  );
  const shouldShowFilters = useMemo(
    () => surveyCycle.visibilitySettings && !surveyCycle.visibilitySettings.anonymiseAnswers || surveyCycle.visibilitySettings && surveyCycle.visibilitySettings.anonymiseAnswers && surveyCycle.visibilitySettings.anonymityType === AnonymityType.Semi,
    [surveyCycle]
  );
  const updateResultsSelectedFilters = useCallback(
    (filterString2) => __async(void 0, null, function* () {
      try {
        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
          "surveys",
          "results",
          "selectedFilter",
          filterString2
        );
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser
        });
      } catch (error) {
        showMessage(`${polyglot.t("PeopleDirectoryPage.errorMessages.update")}. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [dispatch, showMessage, polyglot]
  );
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: { display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" },
      className: "hide-for-capture",
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "16px", alignItems: "center" }, children: [
          setShowByFilter && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Show by" }),
            /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: showByOptions,
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: showByFilter,
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end",
                  style: { borderRadius: radius.br25 }
                }
              }
            )
          ] }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Measured by" }),
            /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: measuredByOptions,
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: (_a = MeasureFilterToLabel[measureByFilter]) != null ? _a : capitalize(measureByFilter),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end",
                  style: { borderRadius: radius.br25 }
                }
              }
            )
          ] }),
          showByFilter === "Question" && impactFilter && existingFactors && existingFactors.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "8px", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "And the impact on" }),
            /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: impactOnOptions,
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: impactFilter,
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end",
                  style: { borderRadius: radius.br25 }
                }
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: Boolean(shouldShowFilters) && filtersAndTypesOptions && /* @__PURE__ */ jsx(
          FiltersDrawer,
          {
            filtersOptions: [{ filters: (_b = filtersAndTypesOptions == null ? void 0 : filtersAndTypesOptions.surveyFilters) != null ? _b : {} }],
            selectedFilters: filterString,
            setSelectedFilters: setFilterString,
            onApply: updateResultsSelectedFilters
          }
        ) })
      ]
    }
  );
};
