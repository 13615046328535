"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory, useLocation } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_MONEY_EXPENSES_ROUTE, SETTINGS_MONEY_INVOICES_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  PaymentCategoryEnum,
  isExpenseCheck
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import { PaymentTypeSettingsAPI } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
export const DeletePaymentTypeDrawer = ({
  isOpen,
  setIsOpen,
  paymentTypeSetting,
  refreshAllPaymentSettings
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(
      DeletePaymentTypeDrawerContent,
      {
        paymentTypeSetting,
        setIsOpen,
        refreshAllPaymentSettings
      }
    )
  }
) });
export const DeletePaymentTypeDrawerContent = ({
  paymentTypeSetting,
  setIsOpen,
  refreshAllPaymentSettings
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDelete, setIsDelete] = useState(false);
  const { pathname } = useLocation();
  const isExpense = isExpenseCheck(pathname);
  const history = useHistory();
  const removePaymentType = () => __async(void 0, null, function* () {
    if (paymentTypeSetting && paymentTypeSetting.id) {
      try {
        setIsDelete(true);
        yield PaymentTypeSettingsAPI.delete(paymentTypeSetting.id);
        showMessage(polyglot.t("PaymentSettingsNewTypeDrawer.successMessages.deleteType"), "success");
        refreshAllPaymentSettings == null ? void 0 : refreshAllPaymentSettings();
        history.push(
          paymentTypeSetting.type === PaymentCategoryEnum.EXPENSE ? SETTINGS_MONEY_EXPENSES_ROUTE : SETTINGS_MONEY_INVOICES_ROUTE
        );
      } catch (error) {
        showMessage(`Failed to delete ${paymentTypeSetting.type} type: ${nestErrorMessage(error)}`, "error");
      } finally {
        setIsOpen(false);
        setIsDelete(false);
      }
    }
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { mb: spacing.m30 }, children: paymentTypeSetting.name }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("PaymentSettingsPage.deleteConfirm", { settingType: isExpense ? "expense" : "invoice" }) }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, spacing.mt30), { display: "flex", gap: "10px" }), children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.delete"),
          sizeVariant: "medium",
          colorVariant: "danger",
          loading: isDelete,
          onClick: removePaymentType,
          fullWidth: true
        }
      )
    ] })
  ] });
};
