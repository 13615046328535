"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { COLOR_PICKER_COLORS_OPASITY_MAP, themeColors } from "@v2/styles/colors.styles";
import { rgba } from "polished";
import { ReactComponent as ArrowRight } from "@/images/side-bar-icons/ArrowRight.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { convertMinutesToHHMM } from "@/v2/feature/absence/absence.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const JobTypeBadgeList = ({ attendanceJobs }) => {
  const scrollContainerRef = useRef(null);
  const [showScrollRight, setShowScrollRight] = useState(false);
  useEffect(() => {
    const checkScroll = () => {
      const container2 = scrollContainerRef.current;
      if (container2) {
        const { scrollLeft, scrollWidth, clientWidth } = container2;
        setShowScrollRight(scrollLeft + clientWidth < scrollWidth);
      }
    };
    const container = scrollContainerRef.current;
    if (!container) return;
    checkScroll();
    container.addEventListener("scroll", checkScroll);
    window.addEventListener("resize", checkScroll);
    return () => {
      container.removeEventListener("scroll", checkScroll);
      window.removeEventListener("resize", checkScroll);
    };
  }, []);
  const scrollRight = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;
    const children = container.children;
    if (children.length === 0) return;
    const gap = 10;
    const card = children[0];
    const cardWidth = card.offsetWidth;
    const effectiveCardWidth = cardWidth + gap;
    const containerWidth = container.clientWidth;
    const visibleCardsCount = Math.floor((containerWidth + gap) / effectiveCardWidth);
    const currentIndex = Math.floor(container.scrollLeft / effectiveCardWidth);
    const totalCards = children.length;
    const hiddenCards = totalCards - (currentIndex + visibleCardsCount);
    if (hiddenCards <= 0) return;
    const batchFraction = 0.3;
    const batchCards = Math.ceil(visibleCardsCount * batchFraction);
    const scrollCards = Math.min(batchCards, hiddenCards);
    const scrollAmount = scrollCards * effectiveCardWidth;
    container.scrollBy({ left: scrollAmount, behavior: "smooth" });
  }, []);
  return /* @__PURE__ */ jsxs(Box, { sx: { position: "relative" }, children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        ref: scrollContainerRef,
        sx: {
          display: "flex",
          gap: spacing.g10,
          overflowX: "auto",
          pr: spacing.p5,
          // hide scrollbar
          scrollbarWidth: "none",
          // for Firefox
          msOverflowStyle: "none",
          // for IE 10+
          "&::-webkit-scrollbar": { display: "none" }
          // for Chrome, Safari, Opera
        },
        children: attendanceJobs.length > 0 && attendanceJobs.map((badge) => /* @__PURE__ */ jsx(JobTypeBadge, { badge }, badge.type))
      }
    ),
    showScrollRight && /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          position: "absolute",
          right: 0,
          top: 0,
          height: "66px",
          width: "38px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: themeColors.white
        },
        children: /* @__PURE__ */ jsx(IconButton, { colorVariant: "secondary", sizeVariant: "small", onClick: scrollRight, children: /* @__PURE__ */ jsx(ArrowRight, __spreadValues({}, iconSize)) })
      }
    )
  ] });
};
const JobTypeBadge = ({
  badge
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        boxSizing: "border-box",
        height: "68px",
        width: "114px",
        display: "flex",
        flexShrink: 0,
        flexDirection: "column",
        padding: spacing.p10,
        gap: spacing.g5,
        border: `1px solid ${themeColors.GreyMiddle}`,
        borderRadius: "10px"
      },
      children: [
        /* @__PURE__ */ jsx(JobTypeTag, { name: badge.type, color: badge.color }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: convertMinutesToHHMM(badge.totalLength) })
      ]
    }
  );
};
const JobTypeTag = ({ name, color }) => {
  const backgroundColor = color in COLOR_PICKER_COLORS_OPASITY_MAP ? rgba(color, COLOR_PICKER_COLORS_OPASITY_MAP[color]) : color;
  return /* @__PURE__ */ jsx(
    Typography,
    {
      variant: "paragraphSmall",
      sx: {
        paddingX: "3px",
        paddingY: "1px",
        borderRadius: radius.br8,
        width: "fit-content",
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        backgroundColor
      },
      children: name
    }
  );
};
