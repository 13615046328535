"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { sortNumeric } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
export const SuperAdminDeviceDetailsTransits = ({ deviceId }) => {
  const {
    data: deviceTransits,
    isLoading
  } = useApiClient(DeviceEndpoints.getTransitsByDeviceIdAsSuperAdmin(deviceId), { suspense: true });
  const columns = useMemo(() => {
    return [
      {
        id: "id",
        header: "ID",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.id }) })
      },
      {
        id: "companyId",
        header: "Company ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.companyId }) })
      },
      {
        id: "deviceId",
        header: "Device ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.deviceId }) })
      },
      {
        id: "status",
        header: "Status",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.status }) })
      },
      {
        id: "createdBy",
        header: "Created by",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.createdBy }) })
      },
      {
        id: "senderId",
        header: "Sender P ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.senderId }) })
      },
      {
        id: "receiverId",
        header: "Receiver P ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.receiverId }) })
      },
      {
        id: "deliveryAddress",
        header: "Delivery address",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.deliveryAddress ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.deliveryAddress }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "notes",
        header: "Notes",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.notes ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.notes }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "createdAt",
        header: "Created at",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(original.createdAt).toLocaleString() }) })
      },
      {
        id: "updatedAt",
        header: "Updated at",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(original.updatedAt).toLocaleString() }) })
      }
    ];
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "Device transits" }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: "5px" }, children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: deviceTransits != null ? deviceTransits : [],
        columnData: columns,
        loading: isLoading,
        hidePagination: true
      }
    ) })
  ] });
};
