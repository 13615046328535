"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Box, IconButton, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceContractLength } from "@v2/feature/device/device.interface";
import { RefinancingPlanPreviewDrawer } from "@v2/feature/super-admin/features/super-admin-refinancing/components/refinancing-plan-preview-drawer.component";
import { RefinancingAPI } from "@v2/feature/super-admin/features/super-admin-refinancing/refinancing.api";
import { buttonBoxSx, fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconButtonSx } from "@v2/styles/icon-button.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import { fixedNumber } from "@v2/util/number.util";
import locale from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { BillingEndpoints } from "@/api-client/billing.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Reject } from "@/images/side-bar-icons/Reject.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_RENTAL_TAX_RATE } from "@/v2/feature/device/device.util";
const getNoOfMonthsAlreadyPaid = (startDate) => {
  if (startDate.getTime() > Date.now()) return 0;
  const today = /* @__PURE__ */ new Date();
  const yearsDiff = today.getFullYear() - startDate.getFullYear();
  const monthsDiff = yearsDiff * 12 + (today.getMonth() - startDate.getMonth());
  return monthsDiff > 0 ? monthsDiff : 0;
};
export const RefinancePlanDrawer = ({ isOpen, setIsOpen, order, idempotencyId, refresh }) => {
  const [deviceOrder, setDeviceOrder] = useState(order);
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState(false);
  const refinancingPlan = useMemo(() => {
    return deviceOrder.refinancingPlan;
  }, [deviceOrder]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose: shouldRefreshOnClose ? refresh : void 0, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: refinancingPlan ? /* @__PURE__ */ jsx(
        ManageRepaymentPaymentDrawerContent,
        {
          order: deviceOrder,
          setShouldRefreshOnClose,
          closeDrawer: () => setIsOpen(false),
          refresh
        }
      ) : /* @__PURE__ */ jsx(
        CreateRefinancePlanDrawerContent,
        {
          order: deviceOrder,
          setShouldRefreshOnClose,
          setDeviceOrder,
          idempotencyId
        }
      )
    }
  ) });
};
const CreateRefinancePlanDrawerContent = ({
  order,
  setDeviceOrder,
  setShouldRefreshOnClose,
  idempotencyId
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { data: billingInfo } = useApiClient(BillingEndpoints.getBillingInfo());
  const [showMessage] = useMessage();
  const [isCreatePlanLoading, setIsCreatePlanLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isPreviewSuccessful, setIsPreviewSuccessful] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const noOfPaidInstallments = order.deliveryDate ? getNoOfMonthsAlreadyPaid(new Date(order.deliveryDate)) : 0;
  const noOfTotalInstalments = order.contractLength || DeviceContractLength.Months36;
  const noOfRemainingInstalments = noOfTotalInstalments - noOfPaidInstallments;
  const totalRepaymentAmount = fixedNumber(noOfRemainingInstalments * order.price, 2);
  const taxAmount = fixedNumber(totalRepaymentAmount * DEVICES_RENTAL_TAX_RATE, 2);
  const reference = [(_a = order.deviceModel) == null ? void 0 : _a.modelName, (_b = order.device) == null ? void 0 : _b.serialNumber].filter(Boolean).join(" ");
  const formik = useFormik({
    initialValues: {
      reference,
      description: "",
      totalRepaymentAmount,
      taxAmount,
      currency: "GBP",
      noOfInstalments: noOfRemainingInstalments,
      consentDate: new LocalDate().toDateString(),
      billingPhone: (_d = (_c = billingInfo == null ? void 0 : billingInfo.billingContact) == null ? void 0 : _c.phoneNumber) != null ? _d : "",
      addressLine1: "",
      addressLine2: "",
      postCode: "",
      city: "",
      countryCode: "GB",
      idempotencyId
    },
    validationSchema: yup.object({
      reference: yup.string().required("Reference is required."),
      description: yup.string().required("Description is required."),
      totalRepaymentAmount: yup.number().typeError("Invalid numeric value.").test(
        "maxDecimalPlaces",
        "Maximum of 2 decimal places allowed",
        (val) => !val || /^\d+(\.\d{1,2})?$/.test(val.toString())
      ).required("Total repayment amount is required."),
      taxAmount: yup.number().typeError("Invalid numeric value.").test(
        "maxDecimalPlaces",
        "Maximum of 2 decimal places allowed",
        (val) => !val || /^\d+(\.\d{1,2})?$/.test(val.toString())
      ).required("VAT is required."),
      currency: yup.string().min(3).max(3).required("Currency is required."),
      noOfInstalments: yup.number().integer("Number of instalments must be an integer.").typeError("Invalid numeric value.").required("Number of instalments is required."),
      consentDate: yup.string().test(dateFieldTest).required("Consent date is required."),
      billingPhone: yup.string().required("Billing phone is required."),
      addressLine1: yup.string().required("Address line 1 is required."),
      addressLine2: yup.string().nullable().notRequired(),
      postCode: yup.string().required("Post code is required."),
      city: yup.string().required("City is required."),
      countryCode: yup.string().min(2).max(2).required("Country code is required.")
    }),
    onSubmit: (values) => createRepaymentPlan(order.companyId, order.id, values)
  });
  const createRepaymentPlan = (companyId, orderId, formData) => __async(void 0, null, function* () {
    var _a2;
    const planData = {
      reference: formData.reference,
      description: formData.description,
      totalRepaymentAmount: Number(formData.totalRepaymentAmount),
      taxAmount: Number(formData.taxAmount),
      currency: formData.currency,
      noOfInstalments: Number(formData.noOfInstalments),
      consentDate: formData.consentDate,
      billingPhone: formData.billingPhone,
      addressLine1: formData.addressLine1,
      addressLine2: (_a2 = formData.addressLine2) != null ? _a2 : void 0,
      postCode: formData.postCode,
      city: formData.city,
      countryCode: formData.countryCode,
      idempotencyId: formData.idempotencyId
    };
    try {
      setIsCreatePlanLoading(true);
      const refinancingPlan = yield RefinancingAPI.createOrderRepaymentPlan(companyId, orderId, planData);
      setShouldRefreshOnClose(true);
      setDeviceOrder(__spreadProps(__spreadValues({}, order), { refinancingPlan }));
    } catch (error) {
      showMessage(`Could not create repayment plan. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsCreatePlanLoading(false);
    }
  });
  const previewPlan = (companyId, orderId, formData) => __async(void 0, null, function* () {
    try {
      setIsPreviewLoading(true);
      const previewPlan2 = yield RefinancingAPI.previewOrderRepaymentPlan(companyId, orderId, formData);
      setIsPreviewSuccessful(!!previewPlan2);
      setPreview(previewPlan2);
      setIsPreviewModalOpen(!!previewPlan2);
    } catch (error) {
      showMessage(`Could not preview repayment plan. ${nestErrorMessage(error)}`, "error");
      setIsPreviewSuccessful(false);
      setPreview(null);
    } finally {
      setIsPreviewLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Refinance" }),
    ((_e = order.company) == null ? void 0 : _e.entities) && ((_f = order.company.entities[0]) == null ? void 0 : _f.legalName) && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Company legal name",
        name: "companyName",
        value: order.company.entities[0].legalName,
        endAdornment: "none",
        disabled: true
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Reference",
        name: "reference",
        value: formik.values.reference,
        onChange: formik.handleChange,
        error: formik.touched.reference && Boolean(formik.errors.reference),
        helperText: formik.touched.reference && formik.errors.reference,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Description",
        name: "description",
        value: formik.values.description,
        onChange: formik.handleChange,
        error: formik.touched.description && Boolean(formik.errors.description),
        helperText: formik.touched.description && formik.errors.description,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: "Order details" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.m10, my: spacing.m10 }, children: [
      order.deliveryDate && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Delivery date" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: new Date(order.deliveryDate).toLocaleDateString() })
      ] }),
      order.contractLength && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Contract length" }),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          order.contractLength,
          " months"
        ] })
      ] }),
      order.contractLength && order.deliveryDate && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Months already paid" }),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          getNoOfMonthsAlreadyPaid(new Date(order.deliveryDate)),
          " months"
        ] })
      ] }),
      order.price && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Monthly rent" }),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          "\xA3",
          order.price
        ] })
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Total repayment amount (exc. VAT)",
        name: "totalRepaymentAmount",
        value: formik.values.totalRepaymentAmount,
        onChange: formik.handleChange,
        error: formik.touched.totalRepaymentAmount && Boolean(formik.errors.totalRepaymentAmount),
        helperText: formik.touched.totalRepaymentAmount && formik.errors.totalRepaymentAmount,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "VAT",
        name: "taxAmount",
        value: formik.values.taxAmount,
        onChange: formik.handleChange,
        error: formik.touched.taxAmount && Boolean(formik.errors.taxAmount),
        helperText: formik.touched.taxAmount && formik.errors.taxAmount,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Number of instalments",
        name: "noOfInstalments",
        value: formik.values.noOfInstalments,
        onChange: formik.handleChange,
        error: formik.touched.noOfInstalments && Boolean(formik.errors.noOfInstalments),
        helperText: formik.touched.noOfInstalments && formik.errors.noOfInstalments,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, locale, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_g = formik.values.consentDate) != null ? _g : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("consentDate", value);
          }
        },
        name: "consentDate",
        label: "Consent date",
        error: formik.touched.consentDate && !!formik.errors.consentDate,
        helperText: formik.touched.consentDate && formik.errors.consentDate
      }
    ) }, "consentDate") }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Billing phone number",
        name: "billingPhone",
        value: formik.values.billingPhone,
        onChange: formik.handleChange,
        error: formik.touched.billingPhone && Boolean(formik.errors.billingPhone),
        helperText: formik.touched.billingPhone && formik.errors.billingPhone,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: "Delivery address" }),
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: order.deliveryAddress })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Address line 1",
        name: "addressLine1",
        value: formik.values.addressLine1,
        onChange: formik.handleChange,
        error: formik.touched.addressLine1 && Boolean(formik.errors.addressLine1),
        helperText: formik.touched.addressLine1 && formik.errors.addressLine1,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Address line 2",
        name: "addressLine2",
        value: formik.values.addressLine2,
        onChange: formik.handleChange,
        error: formik.touched.addressLine2 && Boolean(formik.errors.addressLine2),
        helperText: formik.touched.addressLine2 && formik.errors.addressLine2,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Post code",
        name: "postCode",
        value: formik.values.postCode,
        onChange: formik.handleChange,
        error: formik.touched.postCode && Boolean(formik.errors.postCode),
        helperText: formik.touched.postCode && formik.errors.postCode,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "City",
        name: "city",
        value: formik.values.city,
        onChange: formik.handleChange,
        error: formik.touched.city && Boolean(formik.errors.city),
        helperText: formik.touched.city && formik.errors.city,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Country ISO Code",
        name: "countryCode",
        value: formik.values.countryCode,
        onChange: formik.handleChange,
        error: formik.touched.countryCode && Boolean(formik.errors.countryCode),
        helperText: formik.touched.countryCode && formik.errors.countryCode,
        type: "text",
        endAdornment: "none"
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { gap: spacing.g20 }), children: [
      isPreviewSuccessful === true ? /* @__PURE__ */ jsx(OkGreen, { width: 30, height: 30, style: { fill: themeColors.Green } }) : isPreviewSuccessful === false ? /* @__PURE__ */ jsx(Reject, { width: 30, height: 30, style: { fill: themeColors.Red } }) : null,
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          type: "button",
          name: "Preview",
          loading: isPreviewLoading,
          fullWidth: true,
          onClick: () => __async(void 0, null, function* () {
            yield previewPlan(order.companyId, order.id, formik.values);
          })
        }
      ),
      preview && /* @__PURE__ */ jsx(
        RefinancingPlanPreviewDrawer,
        {
          isOpen: isPreviewModalOpen,
          setIsOpen: setIsPreviewModalOpen,
          preview
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: "Create repayment plan",
        loading: isCreatePlanLoading,
        fullWidth: true,
        disabled: !isPreviewSuccessful
      }
    ) })
  ] }) });
};
const ManageRepaymentPaymentDrawerContent = ({
  order,
  setShouldRefreshOnClose,
  closeDrawer,
  refresh
}) => {
  var _a, _b, _c, _d;
  const [showMessage] = useMessage();
  const [fulfilmentStatus, setFulfilmentStatus] = useState(
    (_b = (_a = order.refinancingPlan) == null ? void 0 : _a.fulfilmentStatus) != null ? _b : null
  );
  const [fundingStatus, setFundingStatus] = useState(
    (_d = (_c = order.refinancingPlan) == null ? void 0 : _c.fundingStatus) != null ? _d : null
  );
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const [isFulfilling, setIsFulfilling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const updatePlanStatus = useCallback(() => __async(void 0, null, function* () {
    if (!order.refinancingPlan) return;
    try {
      setIsStatusUpdating(true);
      const updatedOrder = yield RefinancingAPI.updateAndReturnRepaymentPlanForOrder(
        order.companyId,
        order.refinancingPlan.id,
        order.id
      );
      setFundingStatus(updatedOrder.fundingStatus);
      setFulfilmentStatus(updatedOrder.fulfilmentStatus);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not update plan status. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsStatusUpdating(false);
    }
  }), [order, showMessage, setShouldRefreshOnClose]);
  const fulfilRefinancingPlan = useCallback(() => __async(void 0, null, function* () {
    if (!order.refinancingPlan) return;
    try {
      setIsFulfilling(true);
      const updatedOrder = yield RefinancingAPI.fulfilRepaymentPlanForOrder(
        order.companyId,
        order.refinancingPlan.id,
        order.id
      );
      setFundingStatus(updatedOrder.fundingStatus);
      setFulfilmentStatus(updatedOrder.fulfilmentStatus);
      setShouldRefreshOnClose(true);
    } catch (error) {
      showMessage(`Could not fulfil plan. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsFulfilling(false);
    }
  }), [order, showMessage, setShouldRefreshOnClose]);
  const deletePlan = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    setIsDeleting(true);
    if (!((_a2 = order.refinancingPlan) == null ? void 0 : _a2.id)) {
      showMessage("Missing refinancing plan id", "error");
      return;
    }
    try {
      yield RefinancingAPI.deleteOrderRepaymentPlan(order.companyId, order.id, order.refinancingPlan.id);
      setShouldRefreshOnClose(true);
      closeDrawer();
      yield refresh();
    } catch (error) {
      showMessage(`Could not delete plan. ${nestErrorMessage(error)}`, "error");
    }
    setIsDeleting(false);
  }), [closeDrawer, refresh, setShouldRefreshOnClose, order, showMessage]);
  return order.refinancingPlan ? /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Refinance" }),
      order.refinancingPlan.id && (order.refinancingPlan.fulfilmentStatus === "FAILED" || order.refinancingPlan.fundingStatus === "REJECTED") && /* @__PURE__ */ jsx(IconButton, { sx: iconButtonSx, onClick: deletePlan, disabled: isDeleting, children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize)) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: 1 }, children: /* @__PURE__ */ jsxs(Box, { sx: fieldSx, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Fulfilment Status" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mb: spacing.m10 }), children: fulfilmentStatus != null ? fulfilmentStatus : "N/A" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Funding Status" }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: fundingStatus != null ? fundingStatus : "N/A" })
      ] }) }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          name: "Update status",
          onClick: updatePlanStatus,
          loading: isStatusUpdating,
          type: "button"
        }
      )
    ] }),
    (!fulfilmentStatus || !["SUCCEEDED", "PENDING"].includes(fulfilmentStatus)) && /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: "Fulfil refinancing plan",
        onClick: fulfilRefinancingPlan,
        loading: isFulfilling,
        type: "button",
        fullWidth: true,
        disabled: fundingStatus !== "ACCEPTED"
      }
    ) })
  ] }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.Red }), children: "Something went wrong." });
};
