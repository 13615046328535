"use strict";
import { jsx } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewQuestionBankEndpoints } from "@/v2/feature/growth/reviews/api-client/review-question-bank.api";
import { GrowthQuestionLibraryContent } from "@/v2/feature/growth/shared/components/growth-question-library-content.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { stripHtml } from "@/v2/util/string.util";
export const QuestionSelectionModal = ({
  isQuestionsModalOpen,
  setIsQuestionsModalOpen,
  action,
  excludeQuestions
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const { data: questions, isLoading: questionsLoading } = useApiClient(
    ReviewQuestionBankEndpoints.getReviewQuestionFromBank(searchInput, filterString),
    {
      suspense: false
    }
  );
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const factorFilterTypes = useMemo(() => {
    if (!allGrowthFactors) return void 0;
    return {
      factor: allGrowthFactors == null ? void 0 : allGrowthFactors.map((factor) => {
        return { label: factor.name, value: factor.name };
      })
    };
  }, [allGrowthFactors]);
  const filteredQuestions = useMemo(() => {
    if (!excludeQuestions) return questions;
    const excludeQuestionIds = excludeQuestions.map((q) => q.id);
    return questions == null ? void 0 : questions.filter((q) => !excludeQuestionIds.includes(q.id));
  }, [excludeQuestions, questions]);
  const commonQuestionArray = useMemo(() => {
    if (!filteredQuestions) return [];
    return filteredQuestions.map((q) => {
      var _a;
      return {
        id: q.id,
        questionText: q.questionMain && q.questionMain.length > 0 ? stripHtml(q.questionMain) : stripHtml(q.questionSelf),
        factor: q.factor,
        reviewerSelect: q.reviewerSelect,
        visibilitySettings: q.visibilitySettings,
        type: q.type,
        scaleConfig: (_a = q.scaleConfig) != null ? _a : null
      };
    });
  }, [filteredQuestions]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: isQuestionsModalOpen,
      setIsOpen: setIsQuestionsModalOpen,
      onClose: () => {
        setIsQuestionsModalOpen(false);
      },
      widthPercentage: 45,
      sx: {
        p: 0,
        overflowY: "hidden"
      },
      loading: Boolean(questionsLoading),
      children: /* @__PURE__ */ jsx(
        GrowthQuestionLibraryContent,
        {
          questions: commonQuestionArray,
          setIsQuestionsModalOpen,
          action,
          setSearchInput,
          searchInput,
          allGrowthFactors,
          setFilterString,
          filterString,
          factorFilterTypes
        }
      )
    }
  );
};
