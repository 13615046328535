"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { iconSize } from "./document-bulk-upload-flow.component";
import { DocumentSettingsNewTypeForm } from "./document-settings-new-type-drawer.component";
import { DocumentEndpoints } from "../document.api";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as InfoGrey } from "@/images/side-bar-icons/InfoGrey.svg";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const DocumentConfirmBulkEditDrawer = ({
  isOpen,
  setIsOpen,
  onBulkEdit,
  drawerTitle,
  confirmationText
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    DocumentConfirmBulkEditDrawerContent,
    {
      onBulkEdit,
      setIsOpen,
      drawerTitle,
      confirmationText
    }
  ) });
};
export const DocumentConfirmBulkEditDrawerContent = ({
  onBulkEdit,
  setIsOpen,
  drawerTitle,
  confirmationText
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [newTypeDrawerIsOpen, setNewTypeDrawerIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const { data: allTypes, mutate: refreshAllTypes } = useApiClient(DocumentEndpoints.getTypesData(), {
    suspense: false
  });
  const [formState, setFormState] = useState({
    documentType: null,
    hideFromEmployee: false,
    privateDocument: false
  });
  const onActionConfirmClick = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    yield onBulkEdit(formState);
    setIsOpen(false);
    setLoading(false);
  }), [formState, onBulkEdit, setIsOpen]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: drawerTitle }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: confirmationText }),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "documentType",
        label: polyglot.t("DocumentFormModal.documentType"),
        options: allTypes != null ? allTypes : [],
        value: formState.documentType,
        onChange: (_, x) => {
          setFormState(__spreadProps(__spreadValues({}, formState), { documentType: x }));
        },
        compareValue: (_a = formState.documentType) != null ? _a : "",
        editList: {
          handler: () => setNewTypeDrawerIsOpen(true),
          isHidden: !hasScopes(["documents:all"], { userId: user.userId })
        }
      }
    ),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "visibilitySettings",
        label: polyglot.t("DocumentFormModal.visibilitySettings"),
        checked: (_b = formState.hideFromEmployee) != null ? _b : false,
        onChange: (e) => {
          const target = e.target;
          setFormState(__spreadProps(__spreadValues({}, formState), { hideFromEmployee: target.checked }));
        }
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "5px" }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "privateDocument",
          label: polyglot.t("DocumentFormModal.privateDocument"),
          checked: (_c = formState == null ? void 0 : formState.privateDocument) != null ? _c : false,
          onChange: (e) => {
            const target = e.target;
            setFormState(__spreadProps(__spreadValues({}, formState), { privateDocument: target.checked }));
          }
        }
      ),
      /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("DocumentFormModal.privateHelperText"), children: /* @__PURE__ */ jsx(InfoGrey, __spreadValues({}, iconSize)) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => setIsOpen(false),
          disabled: loading,
          fullWidth: true,
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.update"),
          disabled: loading,
          loading,
          onClick: onActionConfirmClick,
          type: "button",
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true
        }
      )
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: newTypeDrawerIsOpen, setIsOpen: setNewTypeDrawerIsOpen, children: /* @__PURE__ */ jsx(
      DocumentSettingsNewTypeForm,
      {
        initialValues: void 0,
        onClose: () => {
          setNewTypeDrawerIsOpen(false);
        },
        refreshDocumentTypes: () => refreshAllTypes == null ? void 0 : refreshAllTypes(),
        mode: "create"
      }
    ) })
  ] });
};
