"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ListItemStyle = styled(Typography)(() => __spreadProps(__spreadValues({
  display: "flex",
  width: "100%",
  height: "20px",
  alignItems: "center",
  textTransform: "capitalize",
  textDecoration: "none",
  cursor: "pointer"
}, themeFonts.paragraph), {
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    color: themeColors.Grey,
    fill: themeColors.Grey,
    borderRadius: 8
  },
  "&:target": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    background: "none",
    fill: themeColors.DarkGrey,
    borderRadius: 8
  }),
  "&.Mui-selected": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    "&:hover": __spreadProps(__spreadValues({}, themeFonts.headline3), {
      color: themeColors.DarkGrey
    })
  })
}));
export const activeSubStyle = __spreadProps(__spreadValues({}, themeFonts.headline3), {
  fill: themeColors.DarkGrey,
  "&:hover": {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    borderRadius: 8
  }
});
export const ListMainItemStyle = styled(Box)(() => __spreadProps(__spreadValues({}, themeFonts.headline3), {
  position: "relative",
  textTransform: "capitalize",
  backgroundColor: "none",
  textDecoration: "none",
  color: themeColors.DarkGrey,
  "&:hover": {
    color: themeColors.ZeltYellow,
    background: "none",
    fill: themeColors.ZeltYellow,
    borderRadius: "8px"
  },
  "&:focus": {
    color: themeColors.DarkGrey,
    background: "none"
  }
}));
export const MiniListItemStyle = styled(Typography)(() => __spreadProps(__spreadValues({
  display: "flex",
  width: "100%",
  height: "20px",
  alignItems: "center",
  textTransform: "capitalize",
  justifyContent: "center",
  textDecoration: "none"
}, themeFonts.paragraph), {
  color: themeColors.DarkGrey,
  marginBottom: spacing.m10,
  "&:hover": {
    color: themeColors.ZeltYellow,
    fill: themeColors.ZeltYellow,
    borderRadius: 8
  },
  "&:target": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    background: "none",
    fill: themeColors.DarkGrey,
    borderRadius: 8
  }),
  "&.Mui-selected": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    "&:hover": __spreadProps(__spreadValues({}, themeFonts.headline3), {
      color: themeColors.DarkGrey
    })
  })
}));
export const NavItemStyle = styled(Box)(() => __spreadProps(__spreadValues({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "60px",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  cursor: "pointer"
}, themeFonts.paragraph), {
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    color: themeColors.Grey,
    fill: themeColors.Grey,
    background: themeColors.GreyHover
  },
  "&:active": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    background: themeColors.GreyPress
  }),
  "&.Mui-selected": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    "&:hover": __spreadProps(__spreadValues({}, themeFonts.headline3), {
      color: themeColors.DarkGrey
    })
  })
}));
export const NavSubItemStyle = styled(Box)(() => __spreadProps(__spreadValues({
  display: "flex",
  height: "30px",
  alignItems: "center",
  justifyContent: "flex-start",
  textDecoration: "none",
  cursor: "pointer"
}, themeFonts.paragraph), {
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    borderRadius: radius.br15,
    cursor: "pointer"
  },
  "&:target": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    background: "none",
    fill: themeColors.DarkGrey,
    borderRadius: 8
  }),
  "&.Mui-selected": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    "&:hover": __spreadProps(__spreadValues({}, themeFonts.headline3), {
      color: themeColors.DarkGrey
    })
  })
}));
export const SubItemStyle = styled(Box)(() => __spreadProps(__spreadValues({
  display: "flex",
  gap: spacing.s1,
  height: "20px",
  alignItems: "center",
  textDecoration: "none",
  paddingLeft: "10px",
  cursor: "pointer"
}, themeFonts.paragraph), {
  color: themeColors.DarkGrey,
  fill: themeColors.DarkGrey,
  "&:hover": {
    borderRadius: 8
  },
  "&:target": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    background: "none",
    fill: themeColors.DarkGrey,
    borderRadius: 8
  }),
  "&.Mui-selected": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    fill: themeColors.DarkGrey,
    "&:hover": __spreadProps(__spreadValues({}, themeFonts.headline3), {
      color: themeColors.DarkGrey
    })
  })
}));
export const activeSubItemStyle = __spreadProps(__spreadValues({}, themeFonts.headline3), {
  backgroundColor: themeColors.Background,
  color: themeColors.DarkGrey,
  borderRadius: radius.br15,
  "&:hover": {
    color: themeColors.DarkGrey,
    borderRadius: 8
  }
});
export const deprecatedSubItemStyle = {
  backgroundColor: themeColors.GreyLight,
  borderRadius: radius.br15
};
