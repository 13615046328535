"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledRadioGroup } from "@/v2/components/theme-components/styled-radio-group.component";
import { EntityImportWizardData } from "@/v2/feature/entity-import/subfeatures/entity-import-wizard/entity-import-wizard.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const DataDomainChoiceSection = ({ importState, onNext, sx }) => {
  const { polyglot } = usePolyglot();
  const importDataDomainChoices = [
    { label: polyglot.t("importDataDomainChoices.people"), value: EntityImportWizardData.Users },
    { label: polyglot.t("importDataDomainChoices.payroll"), value: EntityImportWizardData.Payroll },
    { label: polyglot.t("importDataDomainChoices.time"), value: EntityImportWizardData.Absences },
    { label: polyglot.t("importDataDomainChoices.timeAdjustments"), value: EntityImportWizardData.AbsenceAdjustments },
    { label: polyglot.t("importDataDomainChoices.devices"), value: EntityImportWizardData.Devices },
    { label: polyglot.t("importDataDomainChoices.attendance"), value: EntityImportWizardData.Attendances },
    { label: polyglot.t("importDataDomainChoices.documents"), value: EntityImportWizardData.Documents }
  ];
  const DataDomainChoiceSchema = Yup.object().shape({
    dataDomainChoice: Yup.string().oneOf(importDataDomainChoices.map((o) => o.value)).required(polyglot.t("DataDomainChoiceSchema.errorMessages.pleaseChooseType"))
  });
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      dataDomainChoice: importState == null ? void 0 : importState.domain
    },
    validateOnMount: true,
    validationSchema: DataDomainChoiceSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      if (values.dataDomainChoice) {
        setSaving(true);
        try {
          onNext(values.dataDomainChoice);
        } catch (error) {
          showMessage(`New user could not be created. ${nestErrorMessage(error)}`, "error");
          setSaving(false);
        }
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("DataDomainChoiceSection.selectDataType") }),
    /* @__PURE__ */ jsx(
      StyledRadioGroup,
      {
        name: "dataDomainChoice",
        options: importDataDomainChoices,
        selectedValue: formik.values.dataDomainChoice,
        onChange: formik.handleChange
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("DataDomainChoiceSection.continue"),
        loading: saving,
        colorVariant: "primary",
        sizeVariant: "large",
        disabled: !formik.isValid,
        fullWidth: true
      }
    )
  ] }) }) });
};
