"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { MultipleSelectCheckbox } from "@v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  UserCustomBenefitRequestType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { isRecurringBenefit } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { UserInsuranceDependants } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { getAdditionalMembersOptions } from "@v2/feature/benefits/subfeature/insurance/insurance.util";
import { UserFamilyMemberType } from "@v2/feature/user/features/user-forms/user-family/user-family.interface";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const UserCustomBenefitRequestDrawer = ({
  isOpen,
  setIsOpen,
  userBenefit,
  onClose,
  refresh,
  type
}) => {
  var _a;
  const { data: userFamilyMembers } = useApiClient(
    isRecurringBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.type) ? UserEndpoints.getUserFamilyMembers(userBenefit.userId) : null,
    { suspense: false }
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    UserCustomBenefitRequestDrawerContent,
    {
      userBenefit,
      userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [],
      refresh,
      setIsOpen,
      type
    }
  ) });
};
export const UserCustomBenefitRequestDrawerContent = ({
  userBenefit,
  userFamilyMembers,
  refresh,
  setIsOpen,
  type
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isRecurringChange = useMemo(() => type === UserCustomBenefitRequestType.RecurringChange, [type]);
  const isLoanRequest = useMemo(() => type === UserCustomBenefitRequestType.LoanRequest, [type]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (isLoanRequest) {
          yield CustomBenefitAPI.requestUserBenefitLoan(
            userBenefit.userId,
            userBenefit.customBenefitId,
            userBenefit.id,
            values
          );
        } else if (isRecurringChange) {
          yield CustomBenefitAPI.requestUserBenefitChange(
            userBenefit.userId,
            userBenefit.customBenefitId,
            userBenefit.id,
            values
          );
        }
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [isRecurringChange, isLoanRequest, userBenefit, refresh, setIsOpen, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      amount: isLoanRequest ? 0 : null,
      numberOfInstallments: isLoanRequest ? 12 : null,
      notes: "",
      employerContribution: isRecurringChange ? userBenefit.employerContribution : null,
      employeeContribution: isRecurringChange ? userBenefit.employeeContribution : null,
      dependants: isRecurringChange ? userBenefit.dependants : null,
      dependantsList: isRecurringChange ? userBenefit.dependantsList : null,
      type,
      effectiveDate: new LocalDate().toDateString()
    },
    validationSchema: yup.object({
      amount: yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).min(0, polyglot.t("ValidationMessages.validValue")).when("type", {
        is: UserCustomBenefitRequestType.LoanRequest,
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.notRequired()
      }),
      numberOfInstallments: yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).integer(polyglot.t("ValidationMessages.validValue")).min(1, polyglot.t("ValidationMessages.validValue")).when("type", {
        is: UserCustomBenefitRequestType.LoanRequest,
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.notRequired()
      }),
      employerContribution: yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).min(0, polyglot.t("ValidationMessages.validValue")).when("type", {
        is: UserCustomBenefitRequestType.RecurringChange,
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.notRequired()
      }),
      employeeContribution: yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).min(0, polyglot.t("ValidationMessages.validValue")).when("type", {
        is: UserCustomBenefitRequestType.RecurringChange,
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.notRequired()
      }),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup.array().nullable().notRequired(),
      notes: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      effectiveDate: yup.string().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit
  });
  const dependantsListOptions = useMemo(() => {
    var _a2, _b2, _c2;
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (_a2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type)).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _a2 : [];
    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (_b2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => m.type === UserFamilyMemberType.Child).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _b2 : [];
    return (_c2 = userFamilyMembers == null ? void 0 : userFamilyMembers.map((m) => ({ value: m.id, label: m.name }))) != null ? _c2 : [];
  }, [userFamilyMembers, formik.values.dependants]);
  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];
    return formik.values.dependantsList.map((id) => {
      const familyMember = userFamilyMembers.find((m) => m.id === id);
      return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
    }).filter(Boolean);
  }, [formik.values.dependantsList, userFamilyMembers]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("BenefitModule.newRequest") }),
    type === UserCustomBenefitRequestType.LoanRequest && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: polyglot.t("General.amount"),
        value: formik.values.amount,
        onChange: formik.handleChange,
        error: formik.touched.amount && !!formik.errors.amount,
        helperText: (_a = formik.touched.amount && formik.errors.amount) != null ? _a : " "
      }
    ) }),
    type === UserCustomBenefitRequestType.LoanRequest && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "numberOfInstallments",
        label: polyglot.t("BenefitModule.numberOfInstallments"),
        value: formik.values.numberOfInstallments,
        onChange: formik.handleChange,
        error: formik.touched.numberOfInstallments && !!formik.errors.numberOfInstallments,
        helperText: (_b = formik.touched.numberOfInstallments && formik.errors.numberOfInstallments) != null ? _b : " "
      }
    ) }),
    type === UserCustomBenefitRequestType.RecurringChange && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employerContribution",
        label: polyglot.t("BenefitModule.employerContribution"),
        value: formik.values.employerContribution,
        onChange: formik.handleChange,
        error: formik.touched.employerContribution && !!formik.errors.employerContribution,
        helperText: (_c = formik.touched.employerContribution && formik.errors.employerContribution) != null ? _c : " "
      }
    ) }),
    type === UserCustomBenefitRequestType.RecurringChange && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employeeContribution",
        label: polyglot.t("BenefitModule.employeeContribution"),
        value: formik.values.employeeContribution,
        onChange: formik.handleChange,
        error: formik.touched.employeeContribution && !!formik.errors.employeeContribution,
        helperText: (_d = formik.touched.employeeContribution && formik.errors.employeeContribution) != null ? _d : " "
      }
    ) }),
    type === UserCustomBenefitRequestType.RecurringChange && userFamilyMembers.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "dependants",
        label: polyglot.t("BenefitModule.additionalMembers"),
        options: [{ label: polyglot.t("General.none"), value: "" }, ...getAdditionalMembersOptions(polyglot)],
        value: (_e = formik.values.dependants) != null ? _e : "",
        onChange: formik.handleChange,
        compareValue: formik.values.dependants,
        error: !!formik.errors.dependants && formik.touched.dependants,
        helperText: formik.touched.dependants && formik.errors.dependants
      }
    ) }) : type === UserCustomBenefitRequestType.RecurringChange ? /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("BenefitModule.additionalMembers") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("BenefitModule.includeAdditionalBeneficiaries") })
    ] }) : null,
    type === UserCustomBenefitRequestType.RecurringChange && userFamilyMembers.length > 0 && formik.values.dependants && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        label: polyglot.t("BenefitModule.additionalMembersList"),
        id: "dependantsList",
        limitTags: -1,
        options: dependantsListOptions,
        value: selectedDependantsList,
        onChange: (_, values) => {
          const updatedOptionList = values.map(({ value }) => value);
          formik.setFieldValue("dependantsList", updatedOptionList);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "notes",
        label: polyglot.t("General.notes"),
        value: formik.values.notes,
        onChange: formik.handleChange,
        error: formik.touched.notes && !!formik.errors.notes,
        helperText: (_f = formik.touched.notes && formik.errors.notes) != null ? _f : " "
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m40 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.request"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
