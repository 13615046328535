"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE } from "@/lib/routes";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { PermissionGroupEditDrawerPage } from "@/v2/feature/permission-group/components/permission-group-edit-drawer.component";
import { PermissionGroupAPI } from "@/v2/feature/permission-group/permission-group.api";
import { PERMISSION_GROUP_EDIT_DRAWER_MODES } from "@/v2/feature/permission-group/permission-group.interface";
import { pluralText } from "@/v2/util/string.util";
export function PermissionGroupsSettingsPage() {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [permissionGroupDrawerMode, setPermissionGroupDrawerMode] = useState(
    PERMISSION_GROUP_EDIT_DRAWER_MODES.scope
  );
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
  const [selectedPermissionGroupId, setSelectedPermissionGroupId] = useState();
  const refreshPermissionGroups = useCallback(() => __async(this, null, function* () {
    setLoading(true);
    try {
      const permissionGroupData = yield PermissionGroupAPI.listPermissionGroupsWithMembers();
      const sortedGroups = permissionGroupData.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
      setPermissionGroups(sortedGroups);
    } catch (error) {
      showMessage(`Permission group info could not be loaded: ${nestErrorMessage(error)}`, "error");
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refreshPermissionGroups();
  }, []);
  const editPermissionGroup = useCallback(
    (permissionGroup) => {
      routerHistory.push(
        generatePath(SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE, { permissionGroupId: permissionGroup.id })
      );
    },
    [routerHistory]
  );
  const addNewPermissionGroup = () => {
    setPermissionGroupDrawerMode(PERMISSION_GROUP_EDIT_DRAWER_MODES.add);
    setEditDrawerOpen(true);
  };
  const sortedPermissionGroups = useMemo(() => {
    const priority = {
      Admin: 4,
      Manager: 3,
      "Profile Owner": 2,
      "Other Profiles": 1,
      DEFAULT: 0
      // Lowest priority
    };
    return permissionGroups.sort((a, b) => {
      var _a, _b;
      const aPriority = (_a = priority[a.name]) != null ? _a : priority.DEFAULT;
      const bPriority = (_b = priority[b.name]) != null ? _b : priority.DEFAULT;
      if (aPriority !== bPriority) {
        return bPriority - aPriority;
      }
      return a.id - b.id;
    });
  }, [permissionGroups]);
  const tableData = useMemo(() => {
    return (searchInput.length > 0 ? sortedPermissionGroups.filter((eachGroup) => eachGroup.name.toLowerCase().includes(searchInput.toLowerCase())) : sortedPermissionGroups).map((grp) => {
      return {
        displayName: grp.name,
        description: grp.description,
        employees: grp.members.length,
        action: () => editPermissionGroup(grp),
        isDefault: grp.isDefault,
        id: grp.id
      };
    });
  }, [sortedPermissionGroups, searchInput, editPermissionGroup]);
  const deletePermissionGroup = useCallback(() => __async(this, null, function* () {
    try {
      setLoading(true);
      if (selectedPermissionGroupId) yield PermissionGroupAPI.deletePermissionGroup(selectedPermissionGroupId);
      else {
        throw new Error(polyglot.t("PermissionGroupDeleteDrawer.errorMessages.noGroup"));
      }
      refreshPermissionGroups();
      showMessage(polyglot.t("PermissionGroupDeleteDrawer.successMessages.delete"), "success");
      setDeleteDrawerOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("PermissionGroupDeleteDrawer.errorMessages.delete", {
          nestError: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [selectedPermissionGroupId, refreshPermissionGroups, showMessage, polyglot]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: "Permissions",
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => addNewPermissionGroup(), children: polyglot.t("permissionListingTable.addGroup") })
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      PermissionsTable,
                      {
                        refreshPermissionGroups,
                        setLoading,
                        setSearchInput,
                        searchInput,
                        setEditDrawerOpen,
                        editDrawerOpen,
                        permissionGroupDrawerMode
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: tableData.map((item) => /* @__PURE__ */ jsx(
                      SettingsItemCard,
                      {
                        title: item.displayName,
                        description: item.description,
                        boxSx: responsiveCardStyle,
                        contentItemsSets: [
                          {
                            name: `${item == null ? void 0 : item.employees} member${pluralText(item == null ? void 0 : item.employees)}`,
                            type: ContentItemType.chip,
                            textColor: "DarkGrey",
                            backgroundColor: "white",
                            border: "middle"
                          }
                        ],
                        boxAction: item.action,
                        advanceActionHidden: item.isDefault,
                        advanceActionOptions: [
                          {
                            label: "Delete",
                            icon: /* @__PURE__ */ jsx(Trash, {}),
                            handler: () => {
                              setSelectedPermissionGroupId(item.id);
                              setDeleteDrawerOpen(true);
                            },
                            disabled: false
                          }
                        ]
                      },
                      item.displayName
                    )) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("PermissionGroupDeleteDrawer.title"),
        description: polyglot.t("PermissionGroupDeleteDrawer.description"),
        loading,
        isOpen: deleteDrawerOpen,
        setIsOpen: setDeleteDrawerOpen,
        action: deletePermissionGroup
      }
    )
  ] });
}
const PermissionsTable = ({
  refreshPermissionGroups,
  setLoading,
  setSearchInput,
  searchInput,
  setEditDrawerOpen,
  editDrawerOpen,
  permissionGroupDrawerMode
}) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => {
          setSearchInput(e.target.value);
        }
      }
    ),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: editDrawerOpen, setIsOpen: setEditDrawerOpen, children: /* @__PURE__ */ jsx(
      PermissionGroupEditDrawerPage,
      {
        refreshPermissionGroup: () => __async(void 0, null, function* () {
        }),
        selectedPermission: void 0,
        selectedPermissionCategory: void 0,
        permissionGroupMembers: [],
        closePage: () => __async(void 0, null, function* () {
          setEditDrawerOpen(false);
          yield refreshPermissionGroups();
        }),
        mode: permissionGroupDrawerMode,
        setLoading
      }
    ) })
  ] });
};
