"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { ScopesControl } from "@/component/widgets/Scopes";
import { INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE, INSURANCE_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const InsuranceProviderDetails = ({ insurancePolicy, userInsurance }) => {
  var _a;
  const history = useHistory();
  const location = useLocation();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap40 }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap10 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Provider" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: insurancePolicy.providerName })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap10 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Policy start" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: new Date((_a = userInsurance == null ? void 0 : userInsurance.startDate) != null ? _a : insurancePolicy.startDate).toLocaleDateString(void 0, {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }) })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap10 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Policy end" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: new Date(insurancePolicy.endDate).toLocaleDateString(void 0, {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }) })
        ] })
      ] }),
      matchPath(location.pathname, INSURANCE_COMPANY_OVERVIEW_ROUTE) && /* @__PURE__ */ jsx(ScopesControl, { scopes: ["insurance:all"], children: /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "primary",
          onClick: () => history.push(INSURANCE_COMPANY_EMPLOYEE_LIST_ROUTE),
          children: "Edit Employees"
        }
      ) }) })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mt: spacing.mt40, mb: spacing.mb20, color: themeColors.DarkGrey }), children: "Contact Details" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap15 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "200px", color: themeColors.DarkGrey }), children: "Claims" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: insurancePolicy.claimsPhone })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "200px", color: themeColors.DarkGrey }), children: "Support" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: insurancePolicy.supportPhone })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "200px", color: themeColors.DarkGrey }), children: "Insurance broker" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: insurancePolicy.insuranceBrokerPhone })
      ] })
    ] })
  ] });
};
