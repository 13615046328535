"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import NewGrowthLoop from "@/animations/Growth/Growth_loop_30x30.json";
import NewGrowthFill from "@/animations/Growth/Growth_loop_FILL_30x30.json";
import NewHomeLoop from "@/animations/Home/Home_loop_30x30.json";
import NewHomeFill from "@/animations/Home/Home_loop_FILL_30x30.json";
import NewTechLoop from "@/animations/IT/IT_loop_30x30.json";
import NewTechFill from "@/animations/IT/IT_loop_FILL_30x30.json";
import NewMoneyLoop from "@/animations/Money/Money_loop_30x30.json";
import NewMoneyFill from "@/animations/Money/Money_loop_FILL_30x30.json";
import NewPeopleLoop from "@/animations/People/People_loop_30x30.json";
import NewPeopleFill from "@/animations/People/People_loop_FILL_30x30.json";
import NewReportsLoop from "@/animations/Reports/Reports_loop_30x30.json";
import NewReportsFill from "@/animations/Reports/Reports_loop_FILL_30x30.json";
import NewTimeLoop from "@/animations/Time/Time_loop_30x30.json";
import NewTimeFill from "@/animations/Time/Time_loop_FILL_30x30.json";
import { ReactComponent as NewDashboard } from "@/images/side-bar-icons/NewDashboard.svg";
import { ReactComponent as NewDashboardFilled } from "@/images/side-bar-icons/NewDashboardFilled.svg";
import { ReactComponent as NewGrowth } from "@/images/side-bar-icons/NewGrowth.svg";
import { ReactComponent as NewGrowthFilled } from "@/images/side-bar-icons/NewGrowthFilled.svg";
import { ReactComponent as NewIt } from "@/images/side-bar-icons/NewIt.svg";
import { ReactComponent as NewItFilled } from "@/images/side-bar-icons/NewItFilled.svg";
import { ReactComponent as NewMoney } from "@/images/side-bar-icons/NewMoney.svg";
import { ReactComponent as NewMoneyFilled } from "@/images/side-bar-icons/NewMoneyFilled.svg";
import { ReactComponent as NewPeople } from "@/images/side-bar-icons/NewPeople.svg";
import { ReactComponent as NewPeopleFilled } from "@/images/side-bar-icons/NewPeopleFilled.svg";
import { ReactComponent as NewReports } from "@/images/side-bar-icons/NewReports.svg";
import { ReactComponent as NewReportsFilled } from "@/images/side-bar-icons/NewReportsFilled.svg";
import { ReactComponent as NewTime } from "@/images/side-bar-icons/NewTime.svg";
import { ReactComponent as NewTimeFilled } from "@/images/side-bar-icons/NewTimeFilled.svg";
import { DASHBOARD_ROUTE } from "@/lib/routes";
import { canAccessScopes, checkScopes } from "@/lib/scopes";
import {
  TIME_ABSENCE_BALANCES_ROUTES,
  TIME_ABSENCE_BALANCES_SCOPES,
  TIME_ABSENCE_REQUESTS_ROUTES,
  TIME_ABSENCE_REQUESTS_SCOPES
} from "@/v2/feature/absence/absence-tabs.util";
import {
  TECH_APPS_OVERVIEW_ROUTES,
  TECH_APPS_OVERVIEW_SCOPES,
  TECH_APPS_REQUEST_ROUTES,
  TECH_APPS_REQUEST_SCOPES,
  TECH_APPS_STORE_ROUTES,
  TECH_APPS_STORE_SCOPES
} from "@/v2/feature/app-integration/app-integration-tabs.util";
import {
  MainMenuSectionEnum,
  RoleTypes
} from "@/v2/feature/app-layout/features/v2/menu/menu.interface";
import {
  TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES,
  TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES_SCOPES,
  TIME_ATTENDANCE_TIMESHEETS_ROUTES,
  TIME_ATTENDANCE_TIMESHEETS_ROUTES_SCOPES
} from "@/v2/feature/attendance/attendance-tabs.util";
import { MONEY_BENEFITS_ROUTES, MONEY_BENEFITS_SCOPES } from "@/v2/feature/benefits/benefits-tabs.util";
import { TIME_CALENDAR_ROUTES } from "@/v2/feature/calendar/calendar-tabs.util";
import {
  TECH_DEVICE_DIRECTORY_ROUTES,
  TECH_DEVICE_DIRECTORY_SCOPES,
  TECH_DEVICE_STORE_ROUTES,
  TECH_DEVICE_STORE_SCOPES
} from "@/v2/feature/device/devices-tabs.util";
import {
  PEOPLE_DOCUMENTS_NEW_OVERVIEW_ROUTES,
  PEOPLE_DOCUMENTS_NEW_OVERVIEW_SCOPES
} from "@/v2/feature/document-new/document-new-tabs.util";
import { MONEY_EXPENSE_SCOPES, MONEY_EXPENSES_OVERVIEW_ROUTES } from "@/v2/feature/expense/expense-tabs.util";
import { GROWTH_GOALS_ROUTES, GROWTH_GOALS_ROUTE_SCOPES } from "@/v2/feature/growth/goals/goals-tabs.util";
import {
  GROWTH_REVIEWS_INVITE_ROUTES,
  GROWTH_REVIEWS_INVITE_SCOPES,
  GROWTH_REVIEWS_ME_ROUTES,
  GROWTH_REVIEWS_ONGOING_ROUTES,
  GROWTH_REVIEWS_ONGOING_SCOPES,
  GROWTH_REVIEWS_RESULT_ROUTES,
  GROWTH_REVIEWS_RESULT_SCOPES
} from "@/v2/feature/growth/reviews/reviews-tabs.util";
import {
  GROWTH_SURVEYS_INVITE_ROUTES,
  GROWTH_SURVEYS_INVITE_SCOPES,
  GROWTH_SURVEYS_ME_ROUTES,
  GROWTH_SURVEYS_ONGOING_ROUTES,
  GROWTH_SURVEYS_ONGOING_SCOPES
} from "@/v2/feature/growth/surveys/surveys-tabs.util";
import { MONEY_INVOICE_SCOPES, MONEY_INVOICES_OVERVIEW_ROUTES } from "@/v2/feature/invoice/invoice-tabs.util";
import { MONEY_PAYMENTS_OVERVIEW_ROUTES, MONEY_PAYMENTS_SCOPES } from "@/v2/feature/payments/payments-tabs.util";
import {
  MONEY_SALARY_PAYROLL_HUB_ROUTES,
  MONEY_SALARY_SCOPES,
  MONEY_SALARY_SUMMARY_ROUTES
} from "@/v2/feature/payroll/payroll-tabs.util";
import {
  REPORT_REPORTS_DASHBOARD_PEOPLE_ROUTES,
  REPORT_REPORTS_INSIGHTS_ROUTES,
  REPORT_REPORTS_V2_NEW_OVERVIEW_ROUTES,
  REPORTS_COMPANY_SALARY_ROUTE
} from "@/v2/feature/reports/reports-tabs.util";
import { PEOPLE_TASKS_OVERVIEW_ROUTES, PEOPLE_TASKS_OVERVIEW_SCOPES } from "@/v2/feature/task/task-tabs.util";
import { UserContractTypeEnum } from "@/v2/feature/user/features/user-profile/user-profile.interface";
import {
  PEOPLE_COMPANY_ACTION_ITEMS_ROUTES,
  PEOPLE_DIRECTORY_ROUTES,
  PEOPLE_DIRECTORY_SCOPES,
  PEOPLE_OFFBOARDING_OVERVIEW_ROUTES,
  PEOPLE_ONBOARDING_OVERVIEW_ROUTES
} from "@/v2/feature/user/user-tabs.util";
const iconSize = { width: 20, height: 20 };
const createSubMenuItem = (title, stub, role, user, route, scopes, isHidden) => {
  return {
    title,
    path: getRoleCommonRoute(route, role),
    stub,
    isHidden: isHidden || !canAccessScopes(user, scopes) || !getRoleCommonRoute(route, role),
    hasChildren: false
  };
};
const createSubItem = ({
  title,
  stub,
  user,
  routes,
  scopes,
  isHidden,
  hasChildren,
  role,
  subMenu,
  deprecated
}) => {
  const routeExists = routes.find((r) => getRoleCommonRoute(r, role));
  return {
    title,
    stub,
    path: !hasChildren && routeExists ? routeExists[role] : void 0,
    isHidden: isHidden || !canAccessScopes(user, scopes) || !routeExists,
    hasChildren,
    subMenu: subMenu ? subMenu.map(
      (item) => createSubMenuItem(item.title, item.stub, role, user, item.route, item.scopes, item.isHidden)
    ) : void 0,
    deprecated
  };
};
const createSection = ({
  title,
  stub,
  path,
  icon,
  iconActive,
  loopAnimation,
  loopFilledAnimation,
  isHidden,
  hasChildren,
  subItems
}) => {
  return {
    title,
    path,
    stub,
    icon,
    iconActive,
    loopAnimation,
    loopFilledAnimation,
    isHidden,
    hasChildren,
    subItems
  };
};
const scopeChecks = [
  {
    domain: MainMenuSectionEnum.people,
    scopes: {
      me: ["user"],
      team: ["documents:manager", "task:manager"],
      company: ["user.onboard:all", "task:all", "documents:all", "company.settings:all"]
    }
  },
  {
    domain: MainMenuSectionEnum.time,
    scopes: {
      me: ["absence", "attendance"],
      team: ["absence:manager", "absence:read:manager", "attendance:manager"],
      company: ["absence:all", "attendance:all"]
    }
  },
  {
    domain: MainMenuSectionEnum.money,
    scopes: {
      me: ["payroll", "pension", "insurance", "payroll:read", "expenses", "invoices"],
      team: ["payments:manager", "expenses:manager", "invoices:manager"],
      company: [
        "payroll:read:all",
        "payroll:all",
        "payroll.selectedEntity:all",
        "pension:all",
        "insurance:all",
        "payments:all",
        "expenses:all",
        "invoices:all"
      ]
    }
  },
  {
    domain: MainMenuSectionEnum.growth,
    scopes: {
      me: ["reviews", "surveys", "goals"],
      team: ["reviews:manager", "surveys:manager", "goals:manager"],
      company: ["reviews:all", "surveys:all", "goals:all"]
    }
  },
  {
    domain: MainMenuSectionEnum.report,
    scopes: {
      company: ["reports:all"],
      team: ["reports:manager"]
    }
  },
  {
    domain: MainMenuSectionEnum.tech,
    scopes: {
      me: ["devices", "apps"],
      company: ["devices:all", "apps:all"]
    }
  }
];
export const getRolesByDomain = (domain, user) => {
  var _a, _b, _c;
  let roles = [];
  const currentScopeCheck = scopeChecks.find((sc) => sc.domain === domain);
  if (currentScopeCheck) {
    if (currentScopeCheck.scopes.me && ((_a = user.scopes2) == null ? void 0 : _a.some((eachScope) => currentScopeCheck.scopes.me.includes(eachScope.scope)))) {
      roles.push(RoleTypes.me);
    }
    if (currentScopeCheck.scopes.team && user.reports && user.reports.length > 0 && ((_b = user.scopes2) == null ? void 0 : _b.some((eachScope) => currentScopeCheck.scopes.team.includes(eachScope.scope)))) {
      roles.push(RoleTypes.team);
    }
    if (currentScopeCheck.scopes.company && ((_c = user.scopes2) == null ? void 0 : _c.some((eachScope) => currentScopeCheck.scopes.company.includes(eachScope.scope)))) {
      roles.push(RoleTypes.company);
    }
  }
  return roles.reverse();
};
const MainMenuSection = (user, polyglot) => {
  var _a, _b;
  return {
    dashboard: {
      title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.home"),
      path: DASHBOARD_ROUTE,
      stub: "",
      icon: /* @__PURE__ */ jsx(NewDashboard, __spreadValues({}, iconSize)),
      iconActive: /* @__PURE__ */ jsx(NewDashboardFilled, __spreadValues({}, iconSize)),
      loopAnimation: NewHomeLoop,
      loopFilledAnimation: NewHomeFill,
      isHidden: false,
      hasChildren: false
    },
    people: {
      title: polyglot.t("getSidebarConfig.people"),
      stub: "people",
      path: "/people",
      icon: /* @__PURE__ */ jsx(NewPeople, __spreadValues({}, iconSize)),
      iconActive: /* @__PURE__ */ jsx(NewPeopleFilled, __spreadValues({}, iconSize)),
      loopAnimation: NewPeopleLoop,
      loopFilledAnimation: NewPeopleFill,
      isHidden: false,
      hasChildren: true
    },
    time: {
      title: polyglot.t("getSidebarConfig.time"),
      stub: "time",
      path: "/time",
      icon: /* @__PURE__ */ jsx(NewTime, __spreadValues({}, iconSize)),
      iconActive: /* @__PURE__ */ jsx(NewTimeFilled, __spreadValues({}, iconSize)),
      loopAnimation: NewTimeLoop,
      loopFilledAnimation: NewTimeFill,
      isHidden: !checkScopes(user, ["absence"], { userId: user.userId }),
      hasChildren: true
    },
    growth: {
      title: polyglot.t("getSidebarConfig.growth"),
      stub: "growth",
      path: "/growth",
      icon: /* @__PURE__ */ jsx(NewGrowth, __spreadValues({}, iconSize)),
      iconActive: /* @__PURE__ */ jsx(NewGrowthFilled, __spreadValues({}, iconSize)),
      loopAnimation: NewGrowthLoop,
      loopFilledAnimation: NewGrowthFill,
      isHidden: !checkScopes(user, ["reviews", "surveys", "goals"], { userId: user.userId }),
      hasChildren: true
    },
    money: {
      title: polyglot.t("getSidebarConfig.money"),
      stub: "money",
      path: "/money",
      icon: /* @__PURE__ */ jsx(NewMoney, __spreadValues({}, iconSize)),
      iconActive: /* @__PURE__ */ jsx(NewMoneyFilled, __spreadValues({}, iconSize)),
      loopAnimation: NewMoneyLoop,
      loopFilledAnimation: NewMoneyFill,
      isHidden: Boolean((_a = user == null ? void 0 : user.navigation) == null ? void 0 : _a.salary.isHidden) && Boolean((_b = user == null ? void 0 : user.navigation) == null ? void 0 : _b.benefits.isHidden) && !canAccessScopes(user, ["payments:manager"]) && !canAccessScopes(user, ["expenses"]) && !canAccessScopes(user, ["invoices"]),
      hasChildren: true
    },
    tech: {
      title: polyglot.t("getSidebarConfig.tech"),
      stub: "tech",
      path: "/tech",
      icon: /* @__PURE__ */ jsx(NewIt, __spreadValues({}, iconSize)),
      iconActive: /* @__PURE__ */ jsx(NewItFilled, __spreadValues({}, iconSize)),
      loopAnimation: NewTechLoop,
      loopFilledAnimation: NewTechFill,
      isHidden: !checkScopes(user, ["apps", "apps:all"], { userId: user.userId }) && !checkScopes(user, ["devices", "devices:all", "devices.store"], { userId: user.userId }),
      hasChildren: true
    },
    report: {
      title: polyglot.t("getSidebarConfig.reports"),
      stub: "report",
      path: "/report",
      icon: /* @__PURE__ */ jsx(NewReports, __spreadValues({}, iconSize)),
      iconActive: /* @__PURE__ */ jsx(NewReportsFilled, __spreadValues({}, iconSize)),
      isHidden: !canAccessScopes(user, ["reports:manager", "payroll:manager", "company.settings:all"]),
      loopAnimation: NewReportsLoop,
      loopFilledAnimation: NewReportsFill,
      hasChildren: true
    }
  };
};
const getRoleCommonRoute = (allRoutes, role) => {
  if (!role) return void 0;
  return allRoutes[role];
};
const getIsHiddenByRouteAndScope = (roleScopes, user, role) => {
  const currentRoleScope = roleScopes.map((row) => row[role]).flat().filter(Boolean);
  if (!currentRoleScope || currentRoleScope.length === 0) return true;
  if (role === RoleTypes.team && user.reports && user.reports.length > 0) {
    return !canAccessScopes(user, currentRoleScope);
  }
  return !canAccessScopes(user, currentRoleScope);
};
export const getSidebarConfig = (user, role, polyglot, showHiddenFeatures = false) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  return user.navigation ? [
    createSection(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.dashboard])),
    createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.people]), {
      subItems: [...(_b = (_a = getPeopleConfig(user, role, polyglot)) == null ? void 0 : _a.subItems) != null ? _b : []]
    })),
    createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.time]), {
      subItems: [...(_d = (_c = getTimeConfig(user, role, polyglot)) == null ? void 0 : _c.subItems) != null ? _d : []]
    })),
    createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.growth]), {
      subItems: [...(_f = (_e = getGrowthConfig(user, role, polyglot)) == null ? void 0 : _e.subItems) != null ? _f : []]
    })),
    createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.money]), {
      subItems: [...(_h = (_g = getMoneyConfig(user, role, polyglot)) == null ? void 0 : _g.subItems) != null ? _h : []]
    })),
    createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.tech]), {
      subItems: [...(_j = (_i = getTechConfig(user, role, polyglot)) == null ? void 0 : _i.subItems) != null ? _j : []]
    })),
    createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.report]), {
      subItems: [...(_l = (_k = getReportsConfig(user, role, polyglot, showHiddenFeatures)) == null ? void 0 : _k.subItems) != null ? _l : []]
    }))
  ] : [];
};
export const getPeopleConfig = (user, role, polyglot) => {
  if (!user || !role) return void 0;
  const subItems = [
    createSubItem({
      title: polyglot.t("getSidebarConfig.directory"),
      stub: "directory",
      user,
      routes: [PEOPLE_DIRECTORY_ROUTES],
      scopes: ["user:read:all"],
      isHidden: getIsHiddenByRouteAndScope([PEOPLE_DIRECTORY_SCOPES], user, role),
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.onboarding"),
      stub: "onboarding",
      user,
      routes: [PEOPLE_ONBOARDING_OVERVIEW_ROUTES],
      scopes: ["user.onboard:all"],
      isHidden: false,
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.offboarding"),
      stub: "offboarding",
      user,
      routes: [PEOPLE_OFFBOARDING_OVERVIEW_ROUTES],
      scopes: ["user.onboard:all"],
      isHidden: false,
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.actionItems"),
      stub: "action-items",
      user,
      routes: [PEOPLE_COMPANY_ACTION_ITEMS_ROUTES],
      scopes: ["user:read:all"],
      isHidden: false,
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.tasks"),
      stub: "tasks",
      user,
      routes: [PEOPLE_TASKS_OVERVIEW_ROUTES],
      scopes: ["task"],
      isHidden: getIsHiddenByRouteAndScope([PEOPLE_TASKS_OVERVIEW_SCOPES], user, role),
      hasChildren: false,
      role
    }),
    createSubItem({
      title: "Documents",
      stub: "documents-new",
      user,
      routes: [PEOPLE_DOCUMENTS_NEW_OVERVIEW_ROUTES],
      scopes: ["documents:read"],
      isHidden: getIsHiddenByRouteAndScope([PEOPLE_DOCUMENTS_NEW_OVERVIEW_SCOPES], user, role),
      hasChildren: false,
      role
    })
  ];
  return createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.people]), {
    subItems
  }));
};
export const getTimeConfig = (user, role, polyglot) => {
  if (!user || !role) return void 0;
  const subMenu = {
    absence: [
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.requests"),
        stub: "requests",
        route: TIME_ABSENCE_REQUESTS_ROUTES,
        scopes: ["absence"],
        isHidden: getIsHiddenByRouteAndScope([TIME_ABSENCE_REQUESTS_SCOPES], user, role)
      },
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.balances"),
        stub: "balances",
        route: TIME_ABSENCE_BALANCES_ROUTES,
        scopes: ["absence"],
        isHidden: getIsHiddenByRouteAndScope([TIME_ABSENCE_BALANCES_SCOPES], user, role)
      }
    ],
    attendance: [
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.timesheets"),
        stub: "timesheets",
        route: TIME_ATTENDANCE_TIMESHEETS_ROUTES,
        scopes: ["attendance"],
        isHidden: getIsHiddenByRouteAndScope([TIME_ATTENDANCE_TIMESHEETS_ROUTES_SCOPES], user, role)
      },
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.activeShifts"),
        stub: "active-shifts",
        route: TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES,
        scopes: ["attendance"],
        isHidden: getIsHiddenByRouteAndScope([TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES_SCOPES], user, role)
      }
    ]
  };
  const subItems = [
    createSubItem({
      title: polyglot.t("getSidebarConfig.calendar"),
      stub: "calendar",
      user,
      routes: [TIME_CALENDAR_ROUTES],
      scopes: ["absence"],
      isHidden: false,
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.absence"),
      stub: "absence",
      user,
      routes: [TIME_ABSENCE_REQUESTS_ROUTES, TIME_ABSENCE_BALANCES_ROUTES],
      scopes: ["absence"],
      isHidden: getIsHiddenByRouteAndScope([TIME_ABSENCE_REQUESTS_SCOPES], user, role) && getIsHiddenByRouteAndScope([TIME_ABSENCE_BALANCES_SCOPES], user, role),
      hasChildren: true,
      role,
      subMenu: subMenu.absence
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.attendance"),
      stub: "attendance",
      user,
      routes: [TIME_ATTENDANCE_TIMESHEETS_ROUTES, TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES],
      scopes: ["attendance"],
      isHidden: getIsHiddenByRouteAndScope([TIME_ATTENDANCE_TIMESHEETS_ROUTES_SCOPES], user, role) && getIsHiddenByRouteAndScope([TIME_ATTENDANCE_ACTIVE_SHIFTS_ROUTES_SCOPES], user, role),
      hasChildren: true,
      role,
      subMenu: subMenu.attendance
    })
  ];
  return createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.time]), {
    subItems
  }));
};
export const getMoneyConfig = (user, role, polyglot) => {
  var _a, _b, _c, _d, _e, _f;
  if (!user || !role) return void 0;
  const subItems = [
    createSubItem({
      title: polyglot.t("getSidebarConfig.payments"),
      stub: "payments",
      user,
      routes: [MONEY_PAYMENTS_OVERVIEW_ROUTES],
      scopes: ["payments:manager"],
      isHidden: getIsHiddenByRouteAndScope([MONEY_PAYMENTS_SCOPES], user, role),
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.payroll"),
      stub: "salary",
      user,
      routes: [
        {
          company: MONEY_SALARY_PAYROLL_HUB_ROUTES.company,
          me: Boolean(user.contractType === UserContractTypeEnum.Payrolled) && // eslint-disable-next-line no-extra-boolean-cast
          !Boolean((_b = (_a = user == null ? void 0 : user.navigation) == null ? void 0 : _a.salary) == null ? void 0 : _b.isHidden) ? MONEY_SALARY_SUMMARY_ROUTES.me : void 0
        }
      ],
      scopes: ["payroll:read", "payroll.selectedEntity:all"],
      isHidden: getIsHiddenByRouteAndScope([MONEY_SALARY_SCOPES], user, role) || Boolean((_d = (_c = user == null ? void 0 : user.navigation) == null ? void 0 : _c.salary) == null ? void 0 : _d.isHidden),
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.benefits"),
      stub: "benefits",
      user,
      routes: [MONEY_BENEFITS_ROUTES],
      scopes: ["insurance", "pension"],
      isHidden: getIsHiddenByRouteAndScope([MONEY_BENEFITS_SCOPES], user, role) || Boolean((_f = (_e = user.navigation) == null ? void 0 : _e.benefits) == null ? void 0 : _f.isHidden),
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.expenses"),
      stub: "expenses",
      user,
      routes: [MONEY_EXPENSES_OVERVIEW_ROUTES],
      scopes: ["expenses"],
      isHidden: getIsHiddenByRouteAndScope([MONEY_EXPENSE_SCOPES], user, role),
      hasChildren: false,
      role
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.invoices"),
      stub: "invoices",
      user,
      routes: [MONEY_INVOICES_OVERVIEW_ROUTES],
      scopes: ["invoices"],
      isHidden: getIsHiddenByRouteAndScope([MONEY_INVOICE_SCOPES], user, role),
      hasChildren: false,
      role
    })
  ];
  return createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.money]), {
    subItems
  }));
};
export const getReportsConfig = (user, role, polyglot, _showHiddenFeatures) => {
  if (!user || !role) return void 0;
  const subMenu = {
    insights: [
      {
        title: polyglot.t("getSidebarConfig.people"),
        stub: "people",
        route: REPORT_REPORTS_DASHBOARD_PEOPLE_ROUTES,
        scopes: ["company.settings:all"],
        isHidden: false
      },
      {
        title: polyglot.t("getSidebarConfig.salary"),
        stub: "salary",
        route: REPORTS_COMPANY_SALARY_ROUTE,
        scopes: ["payroll:all"],
        isHidden: false
      }
    ]
  };
  const subItems = [
    createSubItem({
      title: polyglot.t("getSidebarConfig.insights"),
      stub: "insights",
      user,
      routes: [REPORT_REPORTS_INSIGHTS_ROUTES],
      scopes: ["company.settings:all", "payroll:all"],
      isHidden: false,
      hasChildren: true,
      role,
      subMenu: subMenu.insights
    }),
    createSubItem({
      title: polyglot.t("getSidebarConfig.reportsV2"),
      stub: "reports-advanced",
      user,
      routes: [REPORT_REPORTS_V2_NEW_OVERVIEW_ROUTES],
      scopes: ["reports:manager"],
      isHidden: !canAccessScopes(user, ["reports:manager"]),
      hasChildren: false,
      role
    })
  ];
  return createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.report]), {
    subItems
  }));
};
export const getGrowthConfig = (user, role, polyglot) => {
  if (!user || !role) return void 0;
  const subMenu = {
    reviews: [
      {
        title: "Invites",
        stub: "invite",
        route: GROWTH_REVIEWS_INVITE_ROUTES,
        scopes: ["reviews"],
        isHidden: getIsHiddenByRouteAndScope([GROWTH_REVIEWS_INVITE_SCOPES], user, role)
      },
      {
        title: "Results",
        stub: "result",
        route: GROWTH_REVIEWS_RESULT_ROUTES,
        scopes: ["reviews"],
        isHidden: getIsHiddenByRouteAndScope([GROWTH_REVIEWS_RESULT_SCOPES], user, role)
      }
    ],
    surveys: [
      {
        title: "Invites",
        stub: "invite",
        route: GROWTH_SURVEYS_INVITE_ROUTES,
        scopes: ["surveys"],
        isHidden: getIsHiddenByRouteAndScope([GROWTH_SURVEYS_INVITE_SCOPES], user, role)
      }
    ],
    goals: [
      {
        title: "Goals",
        stub: "goals",
        route: GROWTH_GOALS_ROUTES,
        scopes: ["goals"],
        isHidden: getIsHiddenByRouteAndScope([GROWTH_GOALS_ROUTE_SCOPES], user, role)
      }
    ]
  };
  const subItems = [
    createSubItem({
      title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.reviews"),
      stub: "reviews",
      user,
      routes: [GROWTH_REVIEWS_ONGOING_ROUTES, GROWTH_REVIEWS_ME_ROUTES],
      scopes: ["reviews"],
      isHidden: getIsHiddenByRouteAndScope([GROWTH_REVIEWS_ONGOING_SCOPES], user, role),
      hasChildren: role === RoleTypes.me,
      role,
      subMenu: role === RoleTypes.me ? subMenu.reviews : void 0
    }),
    createSubItem({
      title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.surveys"),
      stub: "surveys",
      user,
      routes: [GROWTH_SURVEYS_ONGOING_ROUTES, GROWTH_SURVEYS_ME_ROUTES],
      scopes: ["surveys"],
      isHidden: getIsHiddenByRouteAndScope([GROWTH_SURVEYS_ONGOING_SCOPES], user, role),
      hasChildren: role === RoleTypes.me,
      role,
      subMenu: role === RoleTypes.me ? subMenu.surveys : void 0
    }),
    createSubItem({
      title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.goals"),
      stub: "goals",
      user,
      routes: [GROWTH_GOALS_ROUTES],
      scopes: ["goals"],
      isHidden: getIsHiddenByRouteAndScope([GROWTH_GOALS_ROUTE_SCOPES], user, role),
      hasChildren: false,
      role,
      subMenu: void 0
    })
  ];
  return createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.growth]), {
    subItems
  }));
};
export const getTechConfig = (user, role, polyglot) => {
  if (!user || !role) return void 0;
  const subMenu = {
    devices: [
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.directory"),
        stub: "directory",
        route: TECH_DEVICE_DIRECTORY_ROUTES,
        scopes: ["devices"],
        isHidden: getIsHiddenByRouteAndScope([TECH_DEVICE_DIRECTORY_SCOPES], user, role)
      },
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.store"),
        stub: "store",
        route: TECH_DEVICE_STORE_ROUTES,
        scopes: ["devices.store", "devices:all"],
        isHidden: getIsHiddenByRouteAndScope([TECH_DEVICE_STORE_SCOPES], user, role)
      }
    ],
    apps: [
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.overview"),
        stub: "overview",
        route: TECH_APPS_OVERVIEW_ROUTES,
        scopes: ["apps", "apps:all"],
        isHidden: getIsHiddenByRouteAndScope([TECH_APPS_OVERVIEW_SCOPES], user, role)
      },
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.requests"),
        stub: "requests",
        route: TECH_APPS_REQUEST_ROUTES,
        scopes: ["apps:all"],
        isHidden: getIsHiddenByRouteAndScope([TECH_APPS_REQUEST_SCOPES], user, role)
      },
      {
        title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.store"),
        stub: "store",
        route: TECH_APPS_STORE_ROUTES,
        scopes: ["apps", "apps:all"],
        isHidden: getIsHiddenByRouteAndScope([TECH_APPS_STORE_SCOPES], user, role)
      }
    ]
  };
  const subItems = [
    createSubItem({
      title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.devices"),
      stub: "devices",
      user,
      routes: [TECH_DEVICE_DIRECTORY_ROUTES, TECH_DEVICE_STORE_ROUTES],
      scopes: ["devices", "devices.store"],
      isHidden: getIsHiddenByRouteAndScope([TECH_DEVICE_DIRECTORY_SCOPES], user, role) && getIsHiddenByRouteAndScope([TECH_DEVICE_STORE_SCOPES], user, role),
      hasChildren: true,
      role,
      subMenu: subMenu.devices
    }),
    createSubItem({
      title: polyglot == null ? void 0 : polyglot.t("getSidebarConfig.apps"),
      stub: "apps",
      user,
      routes: [TECH_APPS_OVERVIEW_ROUTES, TECH_APPS_REQUEST_ROUTES, TECH_APPS_STORE_ROUTES],
      scopes: ["apps"],
      isHidden: getIsHiddenByRouteAndScope([TECH_APPS_OVERVIEW_SCOPES], user, role) && getIsHiddenByRouteAndScope([TECH_APPS_REQUEST_SCOPES], user, role) && getIsHiddenByRouteAndScope([TECH_APPS_STORE_SCOPES], user, role),
      hasChildren: true,
      role,
      subMenu: subMenu.apps
    })
  ];
  return createSection(__spreadProps(__spreadValues({}, MainMenuSection(user, polyglot)[MainMenuSectionEnum.tech]), {
    subItems
  }));
};
