"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Lock } from "@/images/side-bar-icons/Lock.svg";
import { TooltipPopper } from "@/v2/components/theme-components/notification-tooltip-popper.component";
import { DevicePolicyType } from "@/v2/feature/device/device.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import {
  cardTitleIconSx,
  detailHelperSx,
  detailListSx,
  detailSx,
  detailTermSx,
  detailValueSx,
  lockBoxSx,
  lockIconSx,
  parentCardSx
} from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const DevicesSettingsCard = ({
  setEditPoliciesCard,
  selectedPasscodePolicy,
  selectedEncryptionPolicy,
  selectedFirewallPolicy,
  selectedOsUpdatesPolicy,
  restrictions,
  appsPolicies
}) => {
  var _a, _b, _c;
  const getPolicyRestrictionMessage = (policyType) => `Upgrade To Devices Pro plan to apply ${policyType} policy.`;
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          width: "100%",
          maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" },
          paddingBottom: spacing.p60
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: cardTitleIconSx, children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Security" }),
            /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setEditPoliciesCard(true), children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) })
          ] }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m30 }), children: "Configure security settings for any devices connected to Hexnode MDM or any Windows devices." }),
          /* @__PURE__ */ jsxs(Box, { component: "section", children: [
            /* @__PURE__ */ jsx(Box, { component: "dl", sx: detailListSx, children: selectedPasscodePolicy && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
              /* @__PURE__ */ jsx(Typography, { component: "dt", sx: detailTermSx, children: "Passcode" }),
              /* @__PURE__ */ jsxs(Box, { component: "dd", sx: __spreadProps(__spreadValues({}, detailValueSx), { position: "relative" }), children: [
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.label }),
                (selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: detailHelperSx, children: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.description }),
                restrictions.disableEnrollDevices && /* @__PURE__ */ jsx(Box, { sx: lockBoxSx, children: /* @__PURE__ */ jsx(
                  TooltipPopper,
                  {
                    title: /* @__PURE__ */ jsx(Fragment, { children: getPolicyRestrictionMessage(DevicePolicyType.passcode) }),
                    position: "bottom",
                    children: /* @__PURE__ */ jsx(Box, { sx: lockIconSx, children: /* @__PURE__ */ jsx(Lock, __spreadProps(__spreadValues({}, iconSize), { fill: "white", stroke: "white" })) })
                  }
                ) })
              ] })
            ] }) }),
            /* @__PURE__ */ jsx(Box, { component: "dl", sx: detailListSx, children: selectedEncryptionPolicy && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
              /* @__PURE__ */ jsx(Typography, { component: "dt", sx: detailTermSx, children: "Encryption" }),
              /* @__PURE__ */ jsxs(Box, { component: "dd", sx: __spreadProps(__spreadValues({}, detailValueSx), { position: "relative" }), children: [
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: selectedEncryptionPolicy == null ? void 0 : selectedEncryptionPolicy.label }),
                (selectedEncryptionPolicy == null ? void 0 : selectedEncryptionPolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: detailHelperSx, children: selectedEncryptionPolicy == null ? void 0 : selectedEncryptionPolicy.description }),
                restrictions.disableEnrollDevices && /* @__PURE__ */ jsx(Box, { sx: lockBoxSx, children: /* @__PURE__ */ jsx(
                  TooltipPopper,
                  {
                    title: /* @__PURE__ */ jsx(Fragment, { children: getPolicyRestrictionMessage(DevicePolicyType.encryption) }),
                    position: "bottom",
                    children: /* @__PURE__ */ jsx(Box, { sx: lockIconSx, children: /* @__PURE__ */ jsx(Lock, __spreadProps(__spreadValues({}, iconSize), { fill: "white", stroke: "white" })) })
                  }
                ) })
              ] })
            ] }) }),
            /* @__PURE__ */ jsx(Box, { component: "dl", sx: detailListSx, children: selectedFirewallPolicy && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
              /* @__PURE__ */ jsx(Typography, { component: "dt", sx: detailTermSx, children: "Windows Defender" }),
              /* @__PURE__ */ jsxs(Box, { component: "dd", sx: __spreadProps(__spreadValues({}, detailValueSx), { position: "relative" }), children: [
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: selectedFirewallPolicy == null ? void 0 : selectedFirewallPolicy.label }),
                (selectedFirewallPolicy == null ? void 0 : selectedFirewallPolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: detailHelperSx, children: selectedFirewallPolicy == null ? void 0 : selectedFirewallPolicy.description }),
                restrictions.disableEnrollDevices && /* @__PURE__ */ jsx(Box, { sx: lockBoxSx, children: /* @__PURE__ */ jsx(
                  TooltipPopper,
                  {
                    title: /* @__PURE__ */ jsx(Fragment, { children: getPolicyRestrictionMessage(DevicePolicyType.firewall) }),
                    position: "bottom",
                    children: /* @__PURE__ */ jsx(Box, { sx: lockIconSx, children: /* @__PURE__ */ jsx(Lock, __spreadProps(__spreadValues({}, iconSize), { fill: "white", stroke: "white" })) })
                  }
                ) })
              ] })
            ] }) }),
            /* @__PURE__ */ jsx(Box, { component: "dl", sx: detailListSx, children: selectedOsUpdatesPolicy && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
              /* @__PURE__ */ jsx(Typography, { component: "dt", sx: detailTermSx, children: "OS updates" }),
              /* @__PURE__ */ jsxs(Box, { component: "dd", sx: __spreadProps(__spreadValues({}, detailValueSx), { position: "relative" }), children: [
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.label }),
                (selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: detailHelperSx, children: selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.description }),
                restrictions.disableEnrollDevices && /* @__PURE__ */ jsx(Box, { sx: lockBoxSx, children: /* @__PURE__ */ jsx(
                  TooltipPopper,
                  {
                    title: /* @__PURE__ */ jsx(Fragment, { children: getPolicyRestrictionMessage(DevicePolicyType.updates) }),
                    position: "bottom",
                    children: /* @__PURE__ */ jsx(Box, { sx: lockIconSx, children: /* @__PURE__ */ jsx(Lock, __spreadProps(__spreadValues({}, iconSize), { fill: "white", stroke: "white" })) })
                  }
                ) })
              ] })
            ] }) })
          ] })
        ]
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: parentCardSx, children: [
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, cardTitleIconSx), { position: "relative" }), children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Managed Apps" }),
        /* @__PURE__ */ jsx(Box, { sx: lockBoxSx, children: restrictions.disableEnrollDevices && /* @__PURE__ */ jsx(TooltipPopper, { title: /* @__PURE__ */ jsx(Fragment, { children: getPolicyRestrictionMessage(DevicePolicyType.apps) }), position: "bottom", children: /* @__PURE__ */ jsx(Box, { sx: lockIconSx, children: /* @__PURE__ */ jsx(Lock, __spreadProps(__spreadValues({}, iconSize), { fill: "white", stroke: "white" })) }) }) })
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m30 }), children: "Managed Apps contain sensitive company information and require more control. Selected apps will install automatically on your connected devices. If any apps are missing device will be flagged as non-compliant and reinstall will be attempted every 10 minutes. Managed apps are automatically updated every 24 hours." }),
      /* @__PURE__ */ jsx(Box, { component: "section", children: /* @__PURE__ */ jsx(Box, { component: "dl", sx: detailListSx, children: appsPolicies && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", sx: detailTermSx, children: "Apps" }),
        /* @__PURE__ */ jsx(Box, { component: "dd", sx: __spreadProps(__spreadValues({}, detailValueSx), { position: "relative" }), children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: ((_c = (_b = (_a = appsPolicies == null ? void 0 : appsPolicies.filter((devicePolicy) => !devicePolicy.isInhouseMdm && !devicePolicy.hide)) == null ? void 0 : _a.map((appPolicy) => appPolicy.name)) == null ? void 0 : _b.toString()) == null ? void 0 : _c.replaceAll(",", ", ")) || "none" }) })
      ] }) }) })
    ] })
  ] });
};
