"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { PersonalAbsenceContent } from "@v2/feature/absence/me/policies/components/personal-absence-content.component";
import { LabelYearSelectChip } from "@v2/feature/absence/me/policies/components/year-select-chip.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const PersonalAbsencePage = ({ absencePolicies, userId }) => {
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const { polyglot } = usePolyglot();
  const { getScopesContext } = useScopes();
  const [globalState] = useContext(GlobalContext);
  const context = getScopesContext({ userId });
  const hasAbsenceScope = checkScopes(globalState.user, ["absence"], context);
  const [isAbsenceDrawerOpen, setIsAbsenceDrawerOpen] = useState(false);
  const [year, setYear] = useState("current");
  useEffect(() => {
    var _a;
    if (absencePolicies) setSelectedPolicy((_a = absencePolicies[0]) != null ? _a : null);
  }, [absencePolicies]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "20px", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceModule.requests") }),
          /* @__PURE__ */ jsx(LabelYearSelectChip, { year, setYear })
        ] }),
        showAction: hasAbsenceScope,
        actions: /* @__PURE__ */ jsx(ScopesControl, { scopes: ["absence"], context, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setIsAbsenceDrawerOpen(true), sizeVariant: "small", colorVariant: "primary", children: polyglot.t("PersonalAbsencePage.newRequest") }) })
      }
    ),
    /* @__PURE__ */ jsx(
      PersonalAbsenceContent,
      {
        userId,
        year,
        absencePolicies,
        setIsAbsenceDrawerOpen,
        isAbsenceDrawerOpen,
        selectedPolicy,
        setSelectedPolicy
      }
    )
  ] });
};
