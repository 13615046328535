"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { Typography } from "@v2/components/typography/typography.component";
import { QuestionsDrawer } from "@v2/feature/device/components/device-details/questions-drawer.component";
import { WipeMethod } from "@v2/feature/device/device.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { StyledRadio } from "@v2/styles/radio.styles";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DeviceAPI, DeviceEndpoints } from "@/v2/feature/device/device.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const useActionPerformedToday = (devicePossession) => {
  var _a;
  const { data } = useApiClient(
    DeviceEndpoints.getActivityLogForDeviceByDomainAndActionName(
      devicePossession.deviceId,
      ((_a = devicePossession.device) == null ? void 0 : _a.inHouseMdm) ? "deviceActionInHouseMdm" : "deviceAction",
      "wipeDevice"
    ),
    { suspense: false }
  );
  return data != null ? data : false;
};
const WipeDeviceForm = ({ onSubmit }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getInitialWipeMethod = () => WipeMethod.WipeNow;
  const validationSchema = Yup.object({
    wipeMethod: Yup.string().required(polyglot.t("WipeDevice.validation.required")),
    confirmationText: Yup.string().required(polyglot.t("WipeDevice.validation.required")).oneOf(["Confirm"], polyglot.t("WipeDevice.validation.required")),
    wipeLaterDate: Yup.mixed().when("wipeMethod", {
      is: "wipeLater",
      then: Yup.mixed().required(polyglot.t("WipeDevice.validation.required")),
      otherwise: Yup.mixed().nullable()
    }),
    wipeLaterTime: Yup.string().when("wipeMethod", {
      is: "wipeLater",
      then: Yup.string().required(polyglot.t("WipeDevice.validation.required")).test("valid-time", polyglot.t("WipeDevice.validation.invalidTime"), isValidTimeString),
      otherwise: Yup.string().nullable()
    })
  });
  const formik = useFormik({
    initialValues: {
      wipeMethod: getInitialWipeMethod(),
      wipeLaterDate: dayjs().format("YYYY-MM-DD"),
      wipeLaterTime: null,
      confirmationText: ""
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      if (isSubmitting) return;
      setIsSubmitting(true);
      try {
        yield onSubmit(values);
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, [formik.values.confirmationText]);
  useEffect(() => {
    if (formik.values.wipeMethod === "wipeNow") {
      formik.setFieldTouched("wipeLaterDate", false);
      formik.setFieldTouched("wipeLaterTime", false);
    }
    formik.validateForm();
  }, [formik.values.wipeMethod]);
  const handleConfirmationTextChange = (e) => {
    formik.handleChange(e);
    formik.setFieldTouched("confirmationText", true, true);
  };
  const clearConfirmationText = () => {
    formik.setFieldValue("confirmationText", "", true);
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "confirmationText",
        placeholder: polyglot.t("WipeDevice.confirmPlaceholder", {
          confirmText: polyglot.t("WipeDevice.confirmText")
        }),
        fullWidth: true,
        value: formik.values.confirmationText,
        type: "text",
        onChange: handleConfirmationTextChange,
        onBlur: formik.handleBlur,
        error: formik.touched.confirmationText && Boolean(formik.errors.confirmationText),
        helperText: formik.touched.confirmationText && formik.errors.confirmationText,
        clearText: clearConfirmationText
      }
    ) }),
    /* @__PURE__ */ jsxs(FormControl, { sx: { width: "100%", marginTop: "12px" }, children: [
      /* @__PURE__ */ jsxs(
        RadioGroup,
        {
          "aria-labelledby": "wipe-method-group-label",
          name: "wipeMethod",
          value: formik.values.wipeMethod,
          onChange: formik.handleChange,
          sx: { gap: "10px" },
          children: [
            /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: "wipeNow",
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ReassignDevice.wipeNowLabel") })
              },
              "wipeNow"
            ),
            /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: "wipeLater",
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ReassignDevice.wipeLaterLabel") })
              },
              "wipeLater"
            )
          ]
        }
      ),
      formik.values.wipeMethod === "wipeLater" && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20, width: "100%", mt: 2 }, children: [
        /* @__PURE__ */ jsx(Box, { sx: { flex: 1 }, children: /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            inputFormat: "DD/MM/YYYY",
            value: formik.values.wipeLaterDate,
            onChange: (value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue("wipeLaterDate", value, true);
              }
            },
            name: "wipeLaterDate",
            label: polyglot.t("ReassignDevice.dateLabel"),
            error: formik.touched.wipeLaterDate && Boolean(formik.errors.wipeLaterDate),
            helperText: formik.touched.wipeLaterDate && formik.errors.wipeLaterDate,
            disablePast: true
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { flex: 1 }, children: /* @__PURE__ */ jsx(
          TimePickerComponent,
          {
            label: polyglot.t("ReassignDevice.timeLabel"),
            textFieldKey: "wipeLaterTime",
            value: (_a = formik.values.wipeLaterTime) != null ? _a : "",
            onChange: (event) => {
              const time = event.target.value;
              formik.setFieldValue("wipeLaterTime", time, true);
            },
            error: formik.touched.wipeLaterTime && Boolean(formik.errors.wipeLaterTime),
            helperText: formik.touched.wipeLaterTime && formik.errors.wipeLaterTime ? formik.errors.wipeLaterTime : void 0,
            fullWidth: true
          }
        ) })
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        disabled: !formik.isValid || isSubmitting,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => formik.handleSubmit(),
        children: polyglot.t("WipeDevice.submitButton")
      }
    ) })
  ] });
};
const WipeDeviceDrawerContent = (props) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const hasActionToday = useActionPerformedToday(props.devicePossession);
  const [isQuestionFromOpened, setIsQuestionFromOpened] = useState(hasActionToday != null ? hasActionToday : false);
  const [, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (hasActionToday !== void 0) {
      setIsQuestionFromOpened(hasActionToday);
    }
  }, [hasActionToday]);
  const handleWipeDevice = (values) => __async(void 0, null, function* () {
    var _a;
    if (!((_a = props.devicePossession) == null ? void 0 : _a.id)) {
      showMessage(polyglot.t("WipeDevice.messages.error"), "error");
      return;
    }
    setIsSubmitting(true);
    try {
      if (values.wipeMethod === "wipeNow") {
        yield DeviceAPI.wipeDevice(props.devicePossession.id);
        showMessage(polyglot.t("WipeDevice.messages.success"), "success");
      } else if (values.wipeMethod === "wipeLater") {
        if (!values.wipeLaterDate || !values.wipeLaterTime) {
          showMessage(polyglot.t("WipeDevice.messages.invalidDateTime"), "error");
          return;
        }
        yield DeviceAPI.wipeDevice(props.devicePossession.id, {
          wipeLaterDate: values.wipeLaterDate,
          wipeLaterTime: values.wipeLaterTime
        });
        showMessage(polyglot.t("WipeDevice.messages.scheduledSuccess"), "success");
      }
      yield props.onClose();
    } catch (error) {
      console.error("Error wiping device:", error);
      showMessage(polyglot.t("WipeDevice.messages.error"), "error");
    } finally {
      setIsSubmitting(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(
      QuestionsDrawer,
      {
        title: polyglot.t("WipeDevice.action.executedTitle"),
        description: polyglot.t("WipeDevice.action.exists"),
        isOpen: isQuestionFromOpened,
        setIsOpen: setIsQuestionFromOpened,
        loading: false,
        action: () => __async(void 0, null, function* () {
          setIsQuestionFromOpened(false);
        }),
        onClose: props.onClose
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("WipeDevice.title") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Red }, children: polyglot.t("WipeDevice.messages.details") }),
    /* @__PURE__ */ jsx(WipeDeviceForm, { onSubmit: handleWipeDevice })
  ] });
};
export const WipeDeviceDrawer = ({ isOpen, onClose, devicePossession, setIsOpen }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  WipeDeviceDrawerContent,
  {
    devicePossession,
    onClose,
    isOpen,
    setIsOpen
  }
) });
