"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ChartFactory } from "@/v2/components/charts/utils/chart-factory.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const ChartWrapper = ({
  type,
  chartData,
  options,
  loading = false
}) => {
  var _a;
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  try {
    if (type !== (chartData == null ? void 0 : chartData.type)) {
      throw new Error(`Conflicting chart types: ${type} and ${chartData == null ? void 0 : chartData.type} do not match`);
    }
    if (!type || loading || !chartData) {
      return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", height: (_a = options == null ? void 0 : options.height) != null ? _a : "100%" }, children: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
        }
      ) });
    }
    const chart = ChartFactory.createChart(type);
    return chart.render(chartData, options);
  } catch (error) {
    console.error(error);
    showMessage(polyglot.t("Charts.errorMessages.somethingWentWrong", { errorMessage: error.message }), "error");
    return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("Charts.somethingWentWrong") }) });
  }
};
