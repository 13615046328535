"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from "react";
import { Box } from "@mui/material";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { UserAPI } from "@/v2/feature/user/user.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export var LanguageOption = /* @__PURE__ */ ((LanguageOption2) => {
  LanguageOption2["en"] = "en";
  LanguageOption2["ar"] = "ar";
  LanguageOption2["es"] = "es";
  LanguageOption2["fr"] = "fr";
  LanguageOption2["de"] = "de";
  LanguageOption2["pt"] = "pt";
  LanguageOption2["it"] = "it";
  return LanguageOption2;
})(LanguageOption || {});
export const languageOptions = [
  { value: "en" /* en */, label: "English" },
  { value: "ar" /* ar */, label: "\u0627\u0644\u0639\u0631\u0628\u064A\u0629" },
  { value: "es" /* es */, label: "Espa\xF1ol (Google Translate)" },
  { value: "fr" /* fr */, label: "Fran\xE7ais (Google Translate)" },
  { value: "de" /* de */, label: "Deutsch (Google Translate)" },
  { value: "pt" /* pt */, label: "Portugu\xEAs (Google Translate)" },
  { value: "it" /* it */, label: "Italiano (Google Translate)" }
];
const UserLocaleSettingsSchema = Yup.object().shape({
  language: Yup.string().oneOf([...Object.values(LanguageOption), null]).nullable().required()
});
export const UserLocaleSettingsDrawer = ({ isOpen, setIsOpen, initialValues, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(UserLocaleSettingsDrawerContent, { initialValues, setIsOpen, refresh }) });
};
const UserLocaleSettingsDrawerContent = ({
  initialValues,
  setIsOpen,
  refresh
}) => {
  const { polyglot, changeLocale } = usePolyglot();
  const [showMessage] = useMessage();
  const [, dispatch] = useContext(GlobalContext);
  const refreshAuthUser = useCallback(() => __async(void 0, null, function* () {
    var _a;
    const response = yield AuthAPI.getAuthMe(false);
    const authUser = (_a = response == null ? void 0 : response.user) != null ? _a : null;
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: authUser
    });
    if (authUser.language) {
      changeLocale(authUser.language);
    }
  }), [dispatch, changeLocale]);
  const formik = useFormik({
    initialValues: { language: initialValues ? initialValues.language : "en" },
    enableReinitialize: true,
    validationSchema: UserLocaleSettingsSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        yield UserAPI.patchUserLocaleSettings(values);
        showMessage(polyglot.t("UserLocaleSettingsDrawerContent.successMessages.update"), "success");
        yield refreshAuthUser();
        yield refresh == null ? void 0 : refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("UserLocaleSettingsDrawerContent.errorMessages.encounter", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserLocaleSettingsDrawerContent.edit") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "language",
        label: polyglot.t("UserLocaleSettingsDrawerContent.label"),
        options: languageOptions,
        value: formik.values.language,
        compareValue: formik.values.language,
        error: !!formik.errors.language && formik.touched.language,
        onChange: formik.handleChange,
        helperText: formik.touched.language && formik.errors.language
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading: false,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
