"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Button, IconButton, Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { presetApproverToString } from "./approver-select.interface";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SpecificUserModal } from "@/v2/components/user-select-type/components/specific-user-modal.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { styledChipSx } from "@/v2/styles/chip.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const DeleteButton = (_a) => {
  var _b = _a, { sx } = _b, props = __objRest(_b, ["sx"]);
  return /* @__PURE__ */ jsx(
    IconButton,
    __spreadProps(__spreadValues({
      sx: __spreadValues(__spreadProps(__spreadValues({}, secondaryCTABtn), {
        backgroundColor: "#ffffffc8",
        p: 0,
        height: "30px",
        width: "30px"
      }), sx)
    }, props), {
      children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
    })
  );
};
const EmptyApprover = {
  type: "anyof",
  autoApprove: false,
  presets: [],
  userIds: []
};
export const ApproverSelectComponent = ({
  hideEveryone = true,
  hideAutoApprove,
  hidePresets,
  hideSpecific,
  userModalTitle = "Select approvers",
  onChange,
  value,
  sx
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isSpecificOpen, setSpecificIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState(UserSelectFiltersOptions.None);
  const presets = useMemo(() => new Set(value == null ? void 0 : value.presets), [value]);
  const updateAutoApprove = useCallback(
    (autoApprove) => {
      onChange == null ? void 0 : onChange({ type: "anyof", autoApprove, presets: [], userIds: [] });
    },
    [onChange]
  );
  const updatePresetApprover = useCallback(
    (approver, action) => {
      presets[action](approver);
      onChange == null ? void 0 : onChange(__spreadProps(__spreadValues(__spreadValues({}, EmptyApprover), value), {
        autoApprove: false,
        presets: [...presets]
      }));
    },
    [value, onChange, presets]
  );
  const updateSpecificApprovers = useCallback(
    (userIds) => {
      onChange == null ? void 0 : onChange(__spreadProps(__spreadValues(__spreadValues({}, EmptyApprover), value), {
        autoApprove: false,
        userIds
      }));
    },
    [value, onChange]
  );
  const hasApprover = (value == null ? void 0 : value.autoApprove) || !!(value == null ? void 0 : value.presets.length) || !!(value == null ? void 0 : value.userIds.length);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx, children: [
      hasApprover && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g10, mb: spacing.mb20 }, children: [
        (value == null ? void 0 : value.autoApprove) && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("ApproverSelectComponent.autoApprove") }),
          /* @__PURE__ */ jsx(DeleteButton, { sx: { ml: "auto" }, onClick: () => updateAutoApprove(false) })
        ] }),
        value == null ? void 0 : value.presets.map((preset) => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: presetApproverToString(preset, polyglot) }),
          /* @__PURE__ */ jsx(DeleteButton, { sx: { ml: "auto" }, onClick: () => updatePresetApprover(preset, "delete") })
        ] }, preset)),
        !!(value == null ? void 0 : value.userIds.length) && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: value.userIds, showLimit: 5 }),
          /* @__PURE__ */ jsx(Button, { sx: __spreadProps(__spreadValues({}, styledChipSx), { ml: "auto" }), onClick: () => setSpecificIsOpen(true), children: polyglot.t("ApproverSelectComponent.selectSpecific") }),
          /* @__PURE__ */ jsx(DeleteButton, { onClick: () => updateSpecificApprovers([]) })
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g5, flexWrap: "wrap" }, children: [
        !(value == null ? void 0 : value.presets.includes("everyone")) && !(hidePresets == null ? void 0 : hidePresets.includes("everyone")) && !hideEveryone && /* @__PURE__ */ jsx(Button, { sx: styledChipSx, onClick: () => updatePresetApprover("everyone", "add"), children: polyglot.t("ApproverSelectComponent.Everyone") }),
        !(value == null ? void 0 : value.presets.includes("manager:1")) && !(hidePresets == null ? void 0 : hidePresets.includes("manager:1")) && /* @__PURE__ */ jsx(Button, { sx: styledChipSx, onClick: () => updatePresetApprover("manager:1", "add"), children: polyglot.t("ApproverSelectComponent.Manager") }),
        !(value == null ? void 0 : value.presets.includes("admin")) && !(hidePresets == null ? void 0 : hidePresets.includes("admin")) && /* @__PURE__ */ jsx(Button, { sx: styledChipSx, onClick: () => updatePresetApprover("admin", "add"), children: polyglot.t("ApproverSelectComponent.Admin") }),
        !(value == null ? void 0 : value.userIds.length) && !hideSpecific && /* @__PURE__ */ jsx(Button, { sx: styledChipSx, onClick: () => setSpecificIsOpen(true), children: polyglot.t("ApproverSelectComponent.selectSpecific") }),
        !(value == null ? void 0 : value.autoApprove) && !hideAutoApprove && /* @__PURE__ */ jsx(Button, { sx: styledChipSx, onClick: () => updateAutoApprove(true), children: polyglot.t("ApproverSelectComponent.autoApprove") })
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      SpecificUserModal,
      {
        isSpecificOpen,
        setSpecificIsOpen,
        label: userModalTitle,
        value: (_a = value == null ? void 0 : value.userIds) != null ? _a : [],
        onSelect: (userIds) => {
          updateSpecificApprovers(userIds);
          setSpecificIsOpen(false);
          setFilterValue(filterValue);
        }
      }
    )
  ] });
};
