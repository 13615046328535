"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { ColorBubble } from "@v2/feature/absence/absence.util";
import { SelectWorkOptionItem } from "@v2/feature/attendance/company/components/select-work-option-item.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const SelectWorkOptions = ({
  formik,
  logDate,
  attendanceSchedule,
  entries,
  setEntries
}) => {
  const { polyglot } = usePolyglot();
  const TypeOptions = useMemo(
    () => attendanceSchedule.attendanceTypesAllowed.map((type) => ({
      value: type.id,
      label: type.name,
      icon: /* @__PURE__ */ jsx(ColorBubble, { color: type.color, colorWidth: 10 })
    })),
    [attendanceSchedule == null ? void 0 : attendanceSchedule.attendanceTypesAllowed]
  );
  const deleteEntry = useCallback(
    (index) => {
      setEntries(entries.filter((e, i) => i !== index));
    },
    [entries, setEntries]
  );
  const updateEntry = useCallback(
    (index, entry) => {
      const updatedEntries = [...entries];
      updatedEntries[index] = entry;
      setEntries(updatedEntries);
    },
    [entries, setEntries]
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    entries && entries.length > 0 && /* @__PURE__ */ jsx(Stack, { gap: spacing.g24, children: entries.map((entry, index) => /* @__PURE__ */ jsx(
      SelectWorkOptionItem,
      {
        entry,
        index,
        formik,
        logDate,
        typeOptions: TypeOptions,
        updateEntry,
        deleteEntry
      },
      index
    )) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.g24 }, children: /* @__PURE__ */ jsxs(
      ButtonComponent,
      {
        colorVariant: "secondary",
        sizeVariant: "filter",
        onClick: () => {
          setEntries([
            ...entries.map((e, index) => __spreadProps(__spreadValues({}, e), { priority: index })),
            {
              typeId: "",
              startHour: "",
              endHour: "",
              startHourTimestamp: null,
              endHourTimestamp: null,
              priority: entries.length,
              notes: ""
            }
          ]);
        },
        children: [
          "+ ",
          polyglot.t("General.add")
        ]
      }
    ) })
  ] });
};
