"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { Link, generatePath, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { LOGIN_ROUTE, REGISTER_ORGANIZATION } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { InlineLink } from "@/v2/components/inline-link.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const pipeDriveInfoMessage = "In order to install Pipedrive app, you need to signup / login into Zelt application.";
const validationSchema = yup.object({
  workEmail: yup.string().email("Enter a valid email address").required("Email is required")
});
export const AuthSignupPage = () => {
  const routerHistory = useHistory();
  const searchParams = useLocation().search;
  const [infoMessage, setInfoMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(searchParams);
    if (queryParams.get("info") === "pipedrive-install") {
      setInfoMessage(pipeDriveInfoMessage);
    }
  }, [searchParams]);
  const formik = useFormik({
    initialValues: {
      workEmail: ""
    },
    validationSchema,
    onSubmit: (formData) => __async(void 0, null, function* () {
      setIsLoading(true);
      routerHistory.push(
        generatePath(REGISTER_ORGANIZATION, {
          email: formData.workEmail
        })
      );
      setIsLoading(false);
      setError("");
    })
  });
  return /* @__PURE__ */ jsx(AuthLayout, { title: "Get started with Zelt", submit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    infoMessage && /* @__PURE__ */ jsx(Alert, { severity: "info", children: infoMessage }),
    error && /* @__PURE__ */ jsx(Alert, { severity: "error", children: error }),
    /* @__PURE__ */ jsxs(Stack, { spacing: spacing.m25, sx: { mt: 1 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Work email",
          name: "workEmail",
          value: formik.values.workEmail,
          onChange: formik.handleChange,
          error: formik.touched.workEmail && Boolean(formik.errors.workEmail),
          helperText: formik.touched.workEmail && formik.errors.workEmail,
          fullWidth: true,
          size: "small",
          endAdornment: "none",
          autoFocus: true
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30, width: { xs: "50%", sm: "100%", md: "100%", lg: "100%" } }, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "submit",
          name: "Next",
          fullWidth: true,
          loading: isLoading,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: [
        "Already have an account? \xA0",
        /* @__PURE__ */ jsx(Link, { to: LOGIN_ROUTE, children: "Login" })
      ] }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mt: spacing.m30 }), children: [
        "By continuing, you are agreeing to our ",
        /* @__PURE__ */ jsx(InlineLink, { href: "https://zelt.app/legal/privacy-policy/", title: "Privacy Policy", openInNewWindow: true }),
        " and ",
        /* @__PURE__ */ jsx(InlineLink, { href: "https://zelt.app/legal/terms/", title: "Terms and Conditions", openInNewWindow: true })
      ] })
    ] })
  ] }) });
};
