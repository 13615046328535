"use strict";
import { useCallback, useEffect, useRef, useState } from "react";
export const useKeyboardNavigation = ({
  itemIds,
  onFocusChange,
  shouldSkipNavigation = () => false,
  scrollBehavior = { behavior: "smooth", block: "center" },
  autoFocus = false
}) => {
  const [focusedIndex, setFocusedIndex] = useState(null);
  const itemRefs = useRef(/* @__PURE__ */ new Map());
  const isNavigationInProgress = useRef(false);
  const isInitialMount = useRef(true);
  const registerItemRef = useCallback((id, element) => {
    if (element) {
      itemRefs.current.set(id, element);
    } else {
      itemRefs.current.delete(id);
    }
  }, []);
  const isItemFocused = useCallback(
    (id) => {
      if (focusedIndex === null) return false;
      return itemIds[focusedIndex] === id;
    },
    [focusedIndex, itemIds]
  );
  const focusItem = useCallback(
    (index) => {
      if (isInitialMount.current && !autoFocus) {
        isInitialMount.current = false;
        return;
      }
      if (index < 0 || index >= itemIds.length) return;
      isNavigationInProgress.current = true;
      setFocusedIndex(index);
      const itemId = itemIds[index];
      const element = itemRefs.current.get(itemId);
      if (element) {
        if (document.activeElement && document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        requestAnimationFrame(() => {
          element.focus({ preventScroll: true });
          element.scrollIntoView(scrollBehavior);
          setTimeout(() => {
            isNavigationInProgress.current = false;
          }, 100);
        });
      } else {
        isNavigationInProgress.current = false;
      }
      if (onFocusChange) {
        onFocusChange(index);
      }
    },
    [itemIds, onFocusChange, autoFocus, scrollBehavior]
  );
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key !== "ArrowUp" && e.key !== "ArrowDown") return;
      if (shouldSkipNavigation(e)) return;
      const direction = e.key === "ArrowUp" ? "up" : "down";
      const currentIndex = focusedIndex !== null ? focusedIndex : -1;
      let newIndex;
      if (direction === "up") {
        e.preventDefault();
        newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
      } else {
        e.preventDefault();
        newIndex = currentIndex < itemIds.length - 1 ? currentIndex + 1 : itemIds.length - 1;
      }
      if (newIndex !== currentIndex) {
        focusItem(newIndex);
      }
    },
    [focusedIndex, focusItem, itemIds.length, shouldSkipNavigation]
  );
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, { capture: true });
    isInitialMount.current = false;
    return () => {
      window.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, [handleKeyDown]);
  const getItemRefMap = useCallback(() => {
    return itemRefs.current;
  }, []);
  return {
    focusedIndex,
    isNavigationInProgress: isNavigationInProgress.current,
    registerItemRef,
    setFocusedIndex,
    focusItem,
    navigationProgressRef: isNavigationInProgress,
    isItemFocused,
    getItemRefMap
  };
};
