"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserFamilyMembersSection } from "@/v2/feature/user/features/user-profile/details/components/user-family/user-family-members-section.component";
import {
  FAMILY_INFORMATION_MARITAL,
  getMaritalOptions
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
export const FamilySchema = Yup.object().shape({
  maritalStatus: Yup.string().oneOf(FAMILY_INFORMATION_MARITAL).notRequired()
});
export const FamilyForm = ({
  initialValues,
  userFamilyMembers,
  refreshMembers,
  usedForDataImport,
  userId,
  onSubmit,
  onClose,
  handleRefresh
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const { getDefaultField } = useProfileFields();
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: FamilySchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          yield UserAPI.patchUserFamilyInfo(userId, __spreadValues({}, values));
          showMessage(polyglot.t("FamilyForm.successMessages.update"), "success");
        }
        onSubmit == null ? void 0 : onSubmit(__spreadValues({}, values));
        handleRefresh();
      } catch (error) {
        showMessage(polyglot.t("FamilyForm.errorMessages.save", { msg: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("FamilyForm.edit") }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "maritalStatus", children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "maritalStatus",
        label: polyglot.t("FamilyForm.maritalStatus"),
        options: getMaritalOptions(polyglot),
        value: formik.values.maritalStatus,
        compareValue: formik.values.maritalStatus,
        error: !!formik.errors.maritalStatus && formik.touched.maritalStatus,
        onChange: formik.handleChange,
        helperText: formik.errors.maritalStatus && formik.touched.maritalStatus,
        required: (_a = getDefaultField("maritalStatus")) == null ? void 0 : _a.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values)
      }
    ),
    /* @__PURE__ */ jsx(
      UserFamilyMembersSection,
      {
        userId,
        userFamilyMembers,
        refreshMembers
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
