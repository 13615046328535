"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SmallLoader } from "@v2/components/loader.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { TooltipPopper } from "@v2/components/theme-components/notification-tooltip-popper.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AddToPensionRetrospectivelyDrawer } from "@v2/feature/benefits/subfeature/pension/components/add-to-pension-retrospectively-drawer.component";
import { DeleteEmployeePensionModal } from "@v2/feature/benefits/subfeature/pension/components/delete-employee-pension-modal.component";
import { LeavePensionModal } from "@v2/feature/benefits/subfeature/pension/components/leave-pension-modal.component";
import { PensionAPI, PensionEndpoints } from "@v2/feature/benefits/subfeature/pension/pension.api";
import {
  LeavingEmployeeStates
} from "@v2/feature/benefits/subfeature/pension/pension.interface";
import {
  getActionRequiredFromLastAssessment,
  getPensionLogoByProviderName,
  getPensionProviderFromUserPensionAndDefaultSchema,
  getWorkerGroupFromUserPensionAndDefaultSchema,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { EditPayrollRecordDrawer } from "@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component";
import { PayrollEndpoints } from "@v2/feature/payroll/payroll.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { secondaryTableSmallBtn } from "@v2/styles/buttons.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { dateAPItoDisplay } from "@v2/util/date-format.util";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Delete } from "@/images/side-bar-icons/Delete.svg";
import { ReactComponent as InfoCircleGray } from "@/images/side-bar-icons/InfoCircleGray.svg";
import { ReactComponent as Logout } from "@/images/side-bar-icons/Logout.svg";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  AssessmentStatusEligible,
  AssessmentStatusEntitled,
  AssessmentStatusNoDuties,
  AssessmentStatusNonEligible,
  ReliefAtSource,
  StatusToStateMapping
} from "@/lib/pensions";
import { checkScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
const ActionButtonName = (polyglot) => ({
  [AssessmentStatusEligible.value]: polyglot.t("PensionModule.enrol"),
  [AssessmentStatusNonEligible.value]: polyglot.t("PensionModule.optIn"),
  [AssessmentStatusEntitled.value]: polyglot.t("PensionModule.joinVoluntarily"),
  [AssessmentStatusNoDuties.value]: polyglot.t("PensionModule.joinVoluntarily")
});
const PensionProviderReadOnlyLabel = ({
  userPension,
  pensionSchemes
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const provider = userPension ? pensionSchemes.find((pensionScheme) => pensionScheme.externalId === userPension.pensionProviderId) : null;
  const pensionDisplayName = provider == null ? void 0 : provider.displayName;
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("PensionModule.pensionProvider") }),
    (userPension == null ? void 0 : userPension.pensionProviderName) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap5 }, children: [
      /* @__PURE__ */ jsx(Box, { children: getPensionLogoByProviderName(userPension.pensionProviderName, 20) }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: (_a = PensionProvidersValueToLabel[userPension.pensionProviderName]) != null ? _a : userPension.pensionProviderName }),
      pensionDisplayName && /* @__PURE__ */ jsxs(Typography, { variant: "headline3", children: [
        "(",
        pensionDisplayName,
        ")"
      ] })
    ] }) : /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "N/A" })
  ] });
};
export const ManageEmployeeMembershipDrawer = ({
  isOpen,
  setIsOpen,
  userPension,
  setUserPension,
  pensionSchemes,
  onClose,
  refresh
}) => {
  const [shouldRefreshOnClose, setShouldRefreshOnClose] = useState(false);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        if (onClose) onClose();
        if (shouldRefreshOnClose) yield refresh();
      }),
      children: /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "90%",
              height: "90vh",
              sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
            }
          ),
          children: /* @__PURE__ */ jsx(
            ManageEmployeeMembershipDrawerContent,
            {
              setIsOpen,
              userPension,
              setUserPension,
              pensionSchemes,
              refreshData: refresh,
              setShouldRefreshOnClose
            }
          )
        }
      )
    }
  );
};
const ManageEmployeeMembershipDrawerContent = ({
  setIsOpen,
  userPension,
  setUserPension,
  pensionSchemes: allPensionSchemes,
  refreshData,
  setShouldRefreshOnClose
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
  const { data: minimumDateAllowed } = useApiClient(
    PensionEndpoints.getMinimumEmployeePensionStartDate(userPension.userId, userPension.payrollId)
  );
  const { data: totalNoOfPayruns } = useApiClient(
    PayrollEndpoints.countPayrunsByPayrollId(userPension.payrollId)
  );
  const { data: userPayroll, mutate: refreshUserPayroll } = useApiClient(
    PayrollEndpoints.getActiveUserPayroll(userPension.userId)
  );
  const { polyglot } = usePolyglot();
  const minimumStartDateAllowed = useMemo(() => {
    let minDate = new LocalDate().toDateString();
    if (minimumDateAllowed) minDate = minimumDateAllowed;
    return minDate;
  }, [minimumDateAllowed]);
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const [loading, setLoading] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [payrollEditOpen, setPayrollEditOpen] = useState(false);
  const [isAddRetrospectivelyDrawerOpen, setIsAddRetrospectivelyDrawerOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workerGroupOptions, setWorkerGroupOptions] = useState([]);
  const [availablePensionSchemes, setAvailablePensionSchemes] = useState([]);
  const pensionSchemes = useMemo(() => allPensionSchemes.filter((p) => p.payrollId === userPension.payrollId), [
    allPensionSchemes,
    userPension.payrollId
  ]);
  const defaultPensionScheme = useMemo(() => {
    var _a2;
    return (_a2 = pensionSchemes.find((pensionScheme) => pensionScheme.isDefault)) != null ? _a2 : null;
  }, [
    pensionSchemes
  ]);
  const defaultWorkerGroup = useMemo(() => {
    var _a2;
    return (defaultPensionScheme == null ? void 0 : defaultPensionScheme.workerGroups) ? (_a2 = defaultPensionScheme.workerGroups.find((wG) => wG.externalId === defaultPensionScheme.defaultWorkerGroup)) != null ? _a2 : null : null;
  }, [defaultPensionScheme]);
  const hasPensionAllScope = checkScopes(state.user, ["pension:all"], getScopesContext(state.user));
  const PensionActionsMenuStructure = (polyglot2) => [
    {
      label: polyglot2.t("PensionModule.leavePension"),
      handler: () => {
        setIsLeaveModalOpen(true);
      },
      icon: /* @__PURE__ */ jsx(Logout, __spreadValues({}, iconSize)),
      scopes: ["pension"],
      context: getScopesContext({ userId: state.user.userId })
    },
    {
      label: polyglot2.t("PensionModule.deleteRecord"),
      handler: () => {
        setIsDeleteModalOpen(true);
      },
      icon: /* @__PURE__ */ jsx(Delete, __spreadValues({}, iconSize)),
      scopes: ["pension:all"]
    }
  ];
  const getWorkerGroupOptionsByProviderId = useCallback(
    (providerId) => {
      var _a2;
      const provider = pensionSchemes.find((p) => p.externalId === providerId);
      return (_a2 = provider == null ? void 0 : provider.workerGroups.map((wG) => ({
        label: wG.name,
        value: wG.externalId
      }))) != null ? _a2 : [];
    },
    [pensionSchemes]
  );
  const setPensionSchemesAndWorkerGroups = useCallback(() => {
    const availablePensionSchemes2 = pensionSchemes.map((pensionScheme) => {
      var _a2;
      const providerLabel = (_a2 = PensionProvidersValueToLabel[pensionScheme.providerName]) != null ? _a2 : pensionScheme.providerName;
      const label = pensionScheme.displayName ? `${providerLabel} (${pensionScheme.displayName})` : providerLabel;
      return {
        // TODO: should use local id instead of external id - but for this we should update defaultWorkerGroup to be the local id in DB
        value: pensionScheme.externalId,
        label,
        icon: getPensionLogoByProviderName(pensionScheme.providerName)
      };
    });
    setAvailablePensionSchemes(availablePensionSchemes2);
    setWorkerGroupOptions(
      // if userProvider is set, get provider workerGroups
      (userPension == null ? void 0 : userPension.pensionProviderId) && pensionSchemes.length > 0 ? getWorkerGroupOptionsByProviderId(userPension.pensionProviderId) : (
        // else if default provider found get default provider's worker groups
        (defaultPensionScheme == null ? void 0 : defaultPensionScheme.workerGroups) ? getWorkerGroupOptionsByProviderId(defaultPensionScheme.externalId) : (
          // else if userPension workerGroupId and workerGroupName are set, use them (probably this should never happen)
          (userPension == null ? void 0 : userPension.workerGroupId) && userPension.workerGroupName ? [{ value: userPension.workerGroupId, label: userPension.workerGroupName }] : []
        )
      )
    );
  }, [
    pensionSchemes,
    userPension.pensionProviderId,
    userPension.workerGroupId,
    userPension.workerGroupName,
    getWorkerGroupOptionsByProviderId,
    defaultPensionScheme == null ? void 0 : defaultPensionScheme.workerGroups,
    defaultPensionScheme == null ? void 0 : defaultPensionScheme.externalId
  ]);
  useEffect(() => {
    setPensionSchemesAndWorkerGroups();
  }, [setPensionSchemesAndWorkerGroups]);
  const enrollmentDate = useMemo(() => {
    return userPension.startDate ? userPension.startDate : minimumStartDateAllowed;
  }, [userPension, minimumStartDateAllowed]);
  const formik = useFormik({
    initialValues: {
      // if updating -> use userPension data, if creating -> fallback on default scheme and worker group
      pensionProvider: getPensionProviderFromUserPensionAndDefaultSchema(userPension, defaultPensionScheme),
      workerGroupId: getWorkerGroupFromUserPensionAndDefaultSchema(userPension, defaultWorkerGroup),
      enrollmentDate,
      employerContribution: String(
        (_a = (userPension == null ? void 0 : userPension.inPension) ? userPension.employerContribution : defaultWorkerGroup == null ? void 0 : defaultWorkerGroup.employerContribution) != null ? _a : 3
      ),
      employeeContribution: String(
        (_b = (userPension == null ? void 0 : userPension.inPension) ? userPension.employeeContribution : defaultWorkerGroup == null ? void 0 : defaultWorkerGroup.employeeContribution) != null ? _b : 5
      )
    },
    validationSchema: Yup.object({
      pensionProvider: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      workerGroupId: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      enrollmentDate: Yup.string().test(dateFieldTest).test(
        "enrollmentDate",
        polyglot.t("PensionModule.enrollmentDateNonEarlierThan", { date: minimumStartDateAllowed }),
        (val) => Boolean(
          val && (val >= minimumStartDateAllowed || (userPension == null ? void 0 : userPension.startDate) && val === userPension.startDate)
        )
      ).required(polyglot.t("ValidationMessages.requiredField")),
      employerContribution: Yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      employeeContribution: Yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      return upsertPension(values);
    })
  });
  const upsertPension = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2;
      try {
        if (Number.parseFloat(values.employeeContribution) + Number.parseFloat(values.employerContribution) < 8) {
          showMessage(polyglot.t("PensionModule.contributionsMinimum8Percent"), "error");
          return;
        }
        if (!((_a2 = userPension.lastAssessment) == null ? void 0 : _a2.status)) {
          showMessage(polyglot.t("PensionModule.youShouldAssessEmployee"), "error");
          return;
        }
        if (!values.enrollmentDate) {
          showMessage(polyglot.t("PensionModule.noEnrollmentDate"), "error");
          return;
        }
        setLoading(true);
        const employerContribution = Number.parseFloat(values.employerContribution);
        const employeeContribution = Number.parseFloat(values.employeeContribution);
        if (!(userPension == null ? void 0 : userPension.inPension)) {
          yield PensionAPI.createEmployeePension(userPension.userId, {
            startDate: values.enrollmentDate,
            employeeState: StatusToStateMapping[(_b2 = userPension.lastAssessment) == null ? void 0 : _b2.status],
            aeStatusAtJoining: (_c2 = userPension.lastAssessment) == null ? void 0 : _c2.status,
            pensionId: values.pensionProvider,
            workerGroupId: values.workerGroupId,
            employeeContribution,
            employerContribution
          });
        } else {
          yield PensionAPI.updateEmployeePension(userPension.userId, {
            startDate: values.enrollmentDate,
            employeeContribution,
            employerContribution,
            workerGroupId: values.workerGroupId
          });
        }
        showMessage(
          !!(userPension == null ? void 0 : userPension.inPension) ? polyglot.t("PensionModule.updatedSuccess") : polyglot.t("PensionModule.enrolledSuccess"),
          "success"
        );
        yield refreshData();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [
      polyglot,
      refreshData,
      setIsOpen,
      showMessage,
      userPension == null ? void 0 : userPension.inPension,
      (_c = userPension.lastAssessment) == null ? void 0 : _c.status,
      userPension.userId
    ]
  );
  const assessEmployeeForAutoEnrolment = useCallback(
    (employeeId) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const updatedUserPension = yield PensionAPI.assessEmployeeForAE(employeeId);
        showMessage(polyglot.t("PensionModule.assessedSuccessfully"), "success");
        setUserPension(updatedUserPension);
        setShouldRefreshOnClose(true);
      } catch (error) {
        showMessage(
          polyglot.t("PensionModule.assessedSuccessfully", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, showMessage, setShouldRefreshOnClose, setUserPension]
  );
  const disableEnrollDueToNoNINO = useMemo(() => {
    var _a2;
    if (userPension == null ? void 0 : userPension.inPension) return false;
    const provider = pensionSchemes.find((p) => p.externalId === formik.values.pensionProvider);
    if (!provider) return false;
    return provider.pensionRule === ReliefAtSource.value && !((_a2 = userPayroll == null ? void 0 : userPayroll.payrollValues) == null ? void 0 : _a2.niNumber);
  }, [formik.values.pensionProvider, pensionSchemes, (_d = userPayroll == null ? void 0 : userPayroll.payrollValues) == null ? void 0 : _d.niNumber, userPension == null ? void 0 : userPension.inPension]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("PensionModule.enrolToPension") }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("PensionModule.assessment") }),
      /* @__PURE__ */ jsx(
        TooltipPopper,
        {
          position: "bottom",
          title: "By law, any employee who is 22 years old or over (but under State Pension age) and earns more than \xA310,000 a year\n        (for the current tax year) must be put into a pension scheme.",
          children: /* @__PURE__ */ jsx(Box, { sx: { cursor: "pointer" }, children: /* @__PURE__ */ jsx(InfoCircleGray, __spreadValues({}, iconSize)) })
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(SmallLoader, { loading, sx: drawerContentSx, spinnerSx: { my: 10 }, children: [
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("General.employee") }),
        /* @__PURE__ */ jsx(UserCell, { userId: userPension.userId, sx: { mt: spacing.m5 }, nameVariant: "headline3" })
      ] }),
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("PensionModule.workerType") }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.gap5 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: (_f = (_e = userPension == null ? void 0 : userPension.lastAssessment) == null ? void 0 : _e.status) != null ? _f : "N/A" }),
          ((_g = userPension == null ? void 0 : userPension.lastAssessment) == null ? void 0 : _g.reason) && /* @__PURE__ */ jsx(TooltipPopper, { position: "bottom", title: userPension == null ? void 0 : userPension.lastAssessment.reason, children: /* @__PURE__ */ jsx(InfoCircleGray, { width: "12px", height: "12px" }) })
        ] })
      ] }),
      hasPensionAllScope && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("PensionModule.actionRequired") }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: getActionRequiredFromLastAssessment(userPension == null ? void 0 : userPension.lastAssessment) })
      ] }),
      hasPensionAllScope && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("PensionModule.assessmentDate") }),
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: ((_h = userPension == null ? void 0 : userPension.lastAssessment) == null ? void 0 : _h.assessmentDate) ? dateAPItoDisplay(userPension.lastAssessment.assessmentDate) : polyglot.t("PensionModule.notAssessed") })
        ] }),
        hasPensionAllScope && ((_i = userPension == null ? void 0 : userPension.lastAssessment) == null ? void 0 : _i.employeeState) && // If leaver, don't show assess option
        !Object.keys(LeavingEmployeeStates).includes(
          (_j = userPension == null ? void 0 : userPension.lastAssessment) == null ? void 0 : _j.employeeState
        ) && /* @__PURE__ */ jsxs(
          IconButton,
          {
            disableRipple: true,
            sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { cursor: loading ? "default" : "pointer" }),
            onClick: (event) => __async(void 0, null, function* () {
              if (event) event.preventDefault();
              yield assessEmployeeForAutoEnrolment(userPension.userId);
            }),
            children: [
              /* @__PURE__ */ jsx(RefreshIcon, { sx: { fontSize: 18, padding: -2, color: "navy" } }),
              /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
            ]
          }
        )
      ] }),
      !(userPension == null ? void 0 : userPension.lastAssessment) && /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        totalNoOfPayruns === 0 && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            onClick: () => {
              setIsAddRetrospectivelyDrawerOpen(true);
            },
            fullWidth: true,
            children: polyglot.t("PensionModule.addRetrospectively")
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("PensionModule.assess"),
            loading,
            onClick: (event) => __async(void 0, null, function* () {
              if (event) event.preventDefault();
              yield assessEmployeeForAutoEnrolment(userPension.userId);
            }),
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true
          }
        ),
        /* @__PURE__ */ jsx(
          AddToPensionRetrospectivelyDrawer,
          {
            isOpen: isAddRetrospectivelyDrawerOpen,
            setIsOpen: setIsAddRetrospectivelyDrawerOpen,
            userPension,
            pensionSchemes,
            lastAssessment: (_k = userPension == null ? void 0 : userPension.lastAssessment) != null ? _k : void 0,
            refresh: refreshData
          }
        )
      ] })
    ] }),
    (userPension == null ? void 0 : userPension.lastAssessment) && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { mt: spacing.mt10 }, children: polyglot.t("PensionModule.pensionScheme") }),
      /* @__PURE__ */ jsxs(SmallLoader, { loading, sx: drawerContentSx, spinnerSx: { my: 10 }, children: [
        hasPensionAllScope && !(userPension == null ? void 0 : userPension.inPension) ? /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "pensionProvider",
            label: polyglot.t("PensionModule.pensionProvider"),
            options: availablePensionSchemes,
            value: formik.values.pensionProvider,
            compareValue: formik.values.pensionProvider,
            onChange: (event) => {
              formik.handleChange(event);
              const providerId = event.target.value;
              const newSchemeWorkerGroups = getWorkerGroupOptionsByProviderId(providerId);
              formik.setFieldValue("workerGroupId", "");
              formik.setFieldValue("employerContribution", "");
              formik.setFieldValue("employeeContribution", "");
              setWorkerGroupOptions(newSchemeWorkerGroups);
            },
            error: !!formik.errors.pensionProvider && formik.touched.pensionProvider,
            helperText: formik.touched.pensionProvider && formik.errors.pensionProvider
          }
        ) : /* @__PURE__ */ jsx(PensionProviderReadOnlyLabel, { userPension, pensionSchemes }),
        hasPensionAllScope && /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "workerGroupId",
            label: polyglot.t("PensionModule.workerGroup"),
            options: workerGroupOptions,
            value: formik.values.workerGroupId,
            compareValue: formik.values.workerGroupId,
            onChange: (e) => {
              var _a2, _b2;
              formik.handleChange(e);
              const workerGroupId = e.target.value;
              const provider = pensionSchemes.find((p) => p.externalId === formik.values.pensionProvider);
              const workerGroup = provider == null ? void 0 : provider.workerGroups.find((wG) => wG.externalId === workerGroupId);
              formik.setFieldValue("employerContribution", (_a2 = String(workerGroup == null ? void 0 : workerGroup.employerContribution)) != null ? _a2 : "");
              formik.setFieldValue("employeeContribution", (_b2 = String(workerGroup == null ? void 0 : workerGroup.employeeContribution)) != null ? _b2 : "");
            },
            error: !!formik.errors.workerGroupId && formik.touched.workerGroupId,
            helperText: formik.touched.workerGroupId && formik.errors.workerGroupId
          }
        ),
        hasPensionAllScope ? /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            name: "enrollmentDate",
            label: polyglot.t("PensionModule.enrollmentDate"),
            inputFormat: "DD/MM/YYYY",
            value: (_l = formik.values.enrollmentDate) != null ? _l : null,
            onChange: (value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue("enrollmentDate", value);
              }
            },
            minDate: (_m = new LocalDate(minimumStartDateAllowed).getDate()) != null ? _m : new LocalDate().getDate(),
            error: !!formik.errors.enrollmentDate && Boolean(formik.touched.enrollmentDate),
            helperText: !!formik.touched.enrollmentDate && formik.errors.enrollmentDate
          }
        ) : /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("PensionModule.enrollmentDate") }),
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formik.values.enrollmentDate ? dateAPItoDisplay(formik.values.enrollmentDate) : "N/A" })
        ] }),
        hasPensionAllScope ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap20 }, children: [
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "employerContribution",
              label: polyglot.t("BenefitModule.employerContribution"),
              value: formik.values.employerContribution,
              onChange: formik.handleChange,
              error: formik.touched.employerContribution && !!formik.errors.employerContribution,
              helperText: formik.touched.employerContribution && formik.errors.employerContribution,
              InputProps: {
                startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: "%" })
              }
            }
          ),
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "employeeContribution",
              label: polyglot.t("BenefitModule.employeeContribution"),
              value: formik.values.employeeContribution,
              onChange: formik.handleChange,
              error: formik.touched.employeeContribution && !!formik.errors.employeeContribution,
              helperText: formik.touched.employeeContribution && formik.errors.employeeContribution,
              InputProps: {
                startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: "%" })
              }
            }
          )
        ] }) : /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("BenefitModule.employerContribution") }),
            /* @__PURE__ */ jsxs(Typography, { variant: "headline3", children: [
              formik.initialValues.employerContribution,
              "%"
            ] })
          ] }),
          /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "employeeContribution",
              label: polyglot.t("BenefitModule.employeeContribution"),
              value: formik.values.employeeContribution,
              onChange: formik.handleChange,
              error: formik.touched.employeeContribution && !!formik.errors.employeeContribution,
              helperText: formik.touched.employeeContribution && formik.errors.employeeContribution,
              InputProps: {
                startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: "%" })
              }
            }
          )
        ] }),
        disableEnrollDueToNoNINO && /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexFlow: "row", alignItems: "center", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(MistakeIcon, __spreadValues({}, iconSize)),
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", color: "RedDark", children: polyglot.t("PensionModule.missingNINO") })
          ] }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("PensionModule.missingNINODesc") }),
          /* @__PURE__ */ jsx(Button, { sx: __spreadProps(__spreadValues({}, secondaryTableSmallBtn), { mt: spacing.mt10 }), onClick: () => setPayrollEditOpen(true), children: polyglot.t("PensionModule.editTaxInfo") })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
          (userPension == null ? void 0 : userPension.inPension) && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "medium",
                  title: polyglot.t("General.actions"),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end"
                },
                options: PensionActionsMenuStructure(polyglot)
              }
            ),
            isLeaveModalOpen && /* @__PURE__ */ jsx(
              LeavePensionModal,
              {
                isOpen: isLeaveModalOpen,
                setIsOpen: setIsLeaveModalOpen,
                userId: userPension.userId,
                userPension,
                refresh: refreshData
              }
            ),
            isDeleteModalOpen && /* @__PURE__ */ jsx(
              DeleteEmployeePensionModal,
              {
                isOpen: isDeleteModalOpen,
                setIsOpen: setIsDeleteModalOpen,
                userId: userPension.userId,
                refresh: refreshData
              }
            )
          ] }),
          !(userPension == null ? void 0 : userPension.inPension) && totalNoOfPayruns === 0 && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "secondary",
              disabled: disableEnrollDueToNoNINO,
              onClick: () => {
                setIsAddRetrospectivelyDrawerOpen(true);
              },
              fullWidth: true,
              children: polyglot.t("PensionModule.addRetrospectively")
            }
          ),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: (userPension == null ? void 0 : userPension.inPension) ? polyglot.t("General.update") : ((_n = userPension == null ? void 0 : userPension.lastAssessment) == null ? void 0 : _n.status) && ActionButtonName(polyglot)[(_o = userPension.lastAssessment) == null ? void 0 : _o.status] || polyglot.t("PensionModule.enrol"),
              loading,
              disabled: disableEnrollDueToNoNINO,
              sizeVariant: "medium",
              colorVariant: "primary",
              fullWidth: true
            }
          )
        ] })
      ] })
    ] }),
    userPayroll && /* @__PURE__ */ jsx(
      EditPayrollRecordDrawer,
      {
        isOpen: payrollEditOpen,
        close: () => setPayrollEditOpen(false),
        payrollRecord: userPayroll,
        mode: "append",
        userId: userPension.userId,
        onUpdateFinished: () => {
          refreshUserPayroll();
        }
      }
    )
  ] }) });
};
