"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { ColumnsDrawer } from "@v2/components/table/columns-drawer.component";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { ResultTableOldReports } from "@v2/feature/reports/components/result-table.component";
import {
  ErrorComponent,
  LoadingTableComponent
} from "@v2/feature/reports/features/create-report/sections/util-sections.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import {
  ReportEntity
} from "@v2/feature/reports/reports.interface";
import {
  getAllColumnsOptionsPeopleCurrentReports,
  getOnlyAllowedColumnsStringsPeopleCurrentReports,
  getPeopleReportFilters
} from "@v2/feature/reports/util/people-report.util";
import { exportCurrentReportCSV } from "@v2/feature/reports/util/report.util";
import { UserAPI } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_OVERVIEW } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
export const PeopleCurrentCreateReportResults = ({
  formData,
  setFormData,
  customFields,
  additionalColumns
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false
  });
  const { hasScopes } = useScopes();
  const [loading, setLoading] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const [reportName, setReportName] = useState(polyglot.t("PeopleCurrentCreateReportResults.new"));
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState("");
  const [filteredData, setFilteredData] = useState(void 0);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState(
    void 0
  );
  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  useEffect(() => {
    setSelectedColumnsStrings(
      getOnlyAllowedColumnsStringsPeopleCurrentReports(
        formData.entities,
        customFields,
        hasScopes,
        additionalColumns,
        polyglot
      )
    );
    setAllDataLoaded(true);
  }, [formData.entities, customFields, hasScopes, additionalColumns, polyglot]);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportError, setReportError] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state, dispatch] = useContext(GlobalContext);
  const selectableColumns = useMemo(() => {
    return getAllColumnsOptionsPeopleCurrentReports(
      formData.entities,
      customFields,
      hasScopes,
      additionalColumns != null ? additionalColumns : [],
      polyglot
    );
  }, [polyglot, formData.entities, customFields, hasScopes, additionalColumns]);
  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);
  const getFilteredData = useCallback(
    (selectedColumns, filterString2) => __async(void 0, null, function* () {
      setReportLoading(true);
      try {
        const paramObj = {
          domain: formData.domain,
          type: formData.type,
          entities: formData.entities,
          start: formData.start,
          end: formData.end,
          dateType: formData.dateType,
          filters: filterString2,
          columns: { [ReportEntity.User]: selectedColumns }
        };
        let filteredUsers = yield ReportsAPI.generatePeopleCreatedCurrentReport(paramObj);
        setReportError(false);
        setFilteredData(filteredUsers);
      } catch (error) {
        setReportError(true);
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setReportLoading(false);
      }
    }),
    [formData, showMessage]
  );
  useEffect(() => {
    if (allDataLoaded) getFilteredData(selectedColumnsStrings, filterString);
  }, [allDataLoaded, getFilteredData, selectedColumnsStrings, filterString]);
  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData == null ? void 0 : filteredData.filter(
        (data) => !searchInput || (data == null ? void 0 : data.employeeName) && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, filteredData]);
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const createReport = {
        domain: formData.domain,
        type: formData.type,
        entities: formData.entities,
        start: formData.start,
        end: formData.end,
        dateType: formData.dateType,
        filters: filterString,
        columns: { [ReportEntity.User]: selectedColumnsStrings },
        fileName: reportName
      };
      yield ReportsAPI.saveReport(createReport);
      setFormData(__spreadProps(__spreadValues({}, formData), { filters: filterString }));
      showMessage(polyglot.t("PeopleCurrentCreateReportResults.successMessages.save"), "success");
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  }), [polyglot, formData, filterString, selectedColumnsStrings, reportName, setFormData, showMessage, routerHistory]);
  const exportCsv = useCallback(() => {
    try {
      exportCurrentReportCSV(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t("PeopleCurrentCreateReportResults.errorMessages.download"), "error");
    }
  }, [polyglot, reportName, filteredData, showMessage]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: [
        reportLoading && /* @__PURE__ */ jsx(LoadingTableComponent, {}),
        !reportLoading && reportError && /* @__PURE__ */ jsx(ErrorComponent, {}),
        !reportLoading && filteredData && /* @__PURE__ */ jsxs(Box, { sx: { width: "80%" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10, minHeight: "60px" }, children: /* @__PURE__ */ jsx(
            EditableTitle,
            {
              variant: "headline1",
              value: reportName,
              onChange: (value) => {
                setReportName(value);
              },
              maxLength: 50,
              editButtonTooltip: ((_c = (_b = (_a = state.user.features) == null ? void 0 : _a.report) == null ? void 0 : _b.tooltip) == null ? void 0 : _c.saveReportName) ? void 0 : {
                open: true,
                title: /* @__PURE__ */ jsxs(Stack, { sx: { alignItems: "flex-start", maxWidth: "200px" }, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.white, m: spacing.m10 }), children: polyglot.t("PeopleCurrentCreateReportResults.type") }),
                  /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      onClick: () => __async(void 0, null, function* () {
                        const updatedGlobalUser = yield UserAPI.updateOwnUserFeatures(
                          "report",
                          "tooltip",
                          "saveReportName",
                          true
                        );
                        dispatch({
                          type: GlobalStateActions.UPDATE_USER,
                          payload: updatedGlobalUser
                        });
                      }),
                      sizeVariant: "medium",
                      colorVariant: "secondary",
                      children: polyglot.t("PeopleCurrentCreateReportResults.okay")
                    }
                  )
                ] })
              }
            }
          ) }),
          /* @__PURE__ */ jsx(
            StyledTooltip,
            {
              open: isTooltipOpen,
              title: /* @__PURE__ */ jsxs(Stack, { sx: { alignItems: "flex-start", maxWidth: "200px" }, children: [
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.white, m: spacing.m10 }), children: polyglot.t("PeopleCurrentCreateReportResults.filter") }),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    sizeVariant: "small",
                    colorVariant: "tooltip",
                    onClick: () => {
                      setIsTooltipOpen(false);
                    },
                    children: polyglot.t("PeopleCurrentCreateReportResults.okay")
                  }
                )
              ] }),
              children: /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: __spreadProps(__spreadValues({}, spacing.mt20), {
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.g10,
                    width: "fit-content"
                  }),
                  children: [
                    /* @__PURE__ */ jsx(
                      ColumnsDrawer,
                      {
                        columnsOptions: selectableColumns,
                        selectedColumns: selectedColumnsStrings,
                        setSelectedColumns: setSelectedColumnsStrings
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      FiltersDrawer,
                      {
                        filtersOptions: [{ filters: filterTypes }],
                        selectedFilters: filterString,
                        setSelectedFilters: setFilterString,
                        encodedFilterNames: true
                      }
                    ),
                    /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
                      var _a2, _b2;
                      return setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
                    } })
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: filteredAndSearchedData && /* @__PURE__ */ jsx(
            ResultTableOldReports,
            {
              filteredData: filteredAndSearchedData,
              loading: false
            }
          ) }),
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: __spreadProps(__spreadValues({
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: spacing.g10
              }, spacing.mt40), {
                width: "50%"
              }),
              children: [
                /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    sizeVariant: "medium",
                    colorVariant: "primary",
                    name: "Save",
                    loading,
                    fullWidth: true,
                    onClick: handleSave
                  }
                ),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    fullWidth: true,
                    sizeVariant: "medium",
                    colorVariant: "secondary",
                    onClick: exportCsv,
                    startIcon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                    children: polyglot.t("PeopleCurrentCreateReportResults.downloadCsv")
                  }
                )
              ]
            }
          )
        ] })
      ]
    }
  );
};
