"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ZeltMdmAdditionalPasswordSecurityPolicyEditor } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-additional-password-policy-editor.component";
import { ZeltMdmPasscodeSecurityPolicyEditor } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/editors/zelt-mdm-passcode-policy-editor.component";
import { ZeltPolicyPayloadIdentifier } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { themeColors } from "@/v2/styles/colors.styles";
export const ZeltMdmPasscodeAndAdditionalPasswordSecurityPolicyViewer = ({
  updatePolicies,
  configurablePoliciesSelected
}) => {
  const [isOpenPasscodeEditor, setIsOpenPasscodeEditor] = useState(false);
  const [isOpenAdditionalPasswordEditor, setIsOpenAdditionalPasswordEditor] = useState(false);
  const getPasscodePolicy = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
    return {
      minLength: (_b = (_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _a.minLength) != null ? _b : 0,
      maxInactivity: (_d = (_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _c.maxInactivity) != null ? _d : 0,
      minComplexChars: (_f = (_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _e.minComplexChars) != null ? _f : 0,
      maxPINAgeInDays: (_h = (_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _g.maxPINAgeInDays) != null ? _h : 0,
      pinHistory: (_j = (_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _i.pinHistory) != null ? _j : 0,
      allowSimple: (_l = (_k = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _k.allowSimple) != null ? _l : true,
      requireAlphanumeric: (_n = (_m = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurablePasscode) == null ? void 0 : _m.requireAlphanumeric) != null ? _n : false,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.PASSWORD_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const passwordValues = getPasscodePolicy();
  const getAdditionalPasswordPolicy = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
    return {
      allowAutoUnlock: ((_a = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _a.allowAutoUnlock) !== void 0 ? (_b = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _b.allowAutoUnlock : true,
      allowFingerprintForUnlock: ((_c = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _c.allowFingerprintForUnlock) !== void 0 ? (_d = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _d.allowFingerprintForUnlock : true,
      allowPasswordProximityRequests: ((_e = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _e.allowPasswordProximityRequests) !== void 0 ? (_f = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _f.allowPasswordProximityRequests : true,
      allowPasswordSharing: ((_g = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _g.allowPasswordSharing) !== void 0 ? (_h = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _h.allowPasswordSharing : true,
      allowPasswordAutoFill: ((_i = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _i.allowPasswordAutoFill) !== void 0 ? (_j = configurablePoliciesSelected == null ? void 0 : configurablePoliciesSelected.configurableAdditionalPasswordPolicy) == null ? void 0 : _j.allowPasswordAutoFill : true,
      PayloadIdentifier: ZeltPolicyPayloadIdentifier.ADDITIONAL_PASSWORD_RESTRICTIONS_PAYLOAD_IDENTIFIER.valueOf()
    };
  };
  const additionalPasswordValues = getAdditionalPasswordPolicy();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "Passcode" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setIsOpenPasscodeEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Min length" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, passwordValues.minLength === 0 ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: passwordValues.minLength === 0 ? "Not set" : `${passwordValues.minLength} characters`
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Min complex char" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, passwordValues.minComplexChars === 0 ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: passwordValues.minComplexChars === 0 ? "Not set" : passwordValues.minComplexChars
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Require alphanumeric" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, !passwordValues.requireAlphanumeric ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: !passwordValues.requireAlphanumeric ? "Not set" : "Enabled"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Enforce strong passcode" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, passwordValues.allowSimple ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: passwordValues.allowSimple ? "Not set" : "Enabled"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Max passcode age" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, passwordValues.maxPINAgeInDays === 0 ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: passwordValues.maxPINAgeInDays === 0 ? "Not set" : `${passwordValues.maxPINAgeInDays} days`
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Passcode history" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, passwordValues.pinHistory === 0 ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: passwordValues.pinHistory === 0 ? "Not set" : passwordValues.pinHistory
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Autolock after" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, passwordValues.maxInactivity === 0 ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: passwordValues.maxInactivity === 0 ? "Not set" : `${passwordValues.maxInactivity} minutes`
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)"
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "Additional password security" }),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                onClick: () => {
                  setIsOpenAdditionalPasswordEditor(true);
                },
                title: "Edit",
                sx: tableIconButtonSx,
                children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            mt: spacing.m5,
            display: "grid",
            gridTemplateColumns: "calc(25%) calc(75%)",
            color: themeColors.DarkGrey
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "TouchID unlock" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, additionalPasswordValues.allowFingerprintForUnlock ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: additionalPasswordValues.allowFingerprintForUnlock ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Auto unlock" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, additionalPasswordValues.allowAutoUnlock ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: additionalPasswordValues.allowAutoUnlock ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Requesting password from nearby devices" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, additionalPasswordValues.allowPasswordProximityRequests ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: additionalPasswordValues.allowPasswordProximityRequests ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Password sharing via Airdrop" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, additionalPasswordValues.allowPasswordSharing ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: additionalPasswordValues.allowPasswordSharing ? "Allowed" : "Restricted"
              }
            ),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Auto-filling passwords" }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: __spreadValues({}, additionalPasswordValues.allowPasswordAutoFill ? __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mt: spacing.m10 }) : __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 })),
                children: additionalPasswordValues.allowPasswordAutoFill ? "Allowed" : "Restricted"
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isOpenPasscodeEditor, setIsOpen: setIsOpenPasscodeEditor, children: /* @__PURE__ */ jsx(
      ZeltMdmPasscodeSecurityPolicyEditor,
      {
        configurablePoliciesSelected,
        updatePolicies,
        isOpen: isOpenPasscodeEditor,
        setIsOpen: setIsOpenPasscodeEditor
      }
    ) }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isOpenAdditionalPasswordEditor, setIsOpen: setIsOpenAdditionalPasswordEditor, children: /* @__PURE__ */ jsx(
      ZeltMdmAdditionalPasswordSecurityPolicyEditor,
      {
        configurablePoliciesSelected,
        updatePolicies,
        isOpen: isOpenAdditionalPasswordEditor,
        setIsOpen: setIsOpenAdditionalPasswordEditor
      }
    ) })
  ] });
};
