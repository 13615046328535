"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, capitalize } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CustomProfileFormAPI } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { ShowCustomFieldComponent } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const CustomSectionForm = ({
  userId,
  form,
  fields,
  onSubmit,
  onClose,
  usedForDataImport,
  usedForOnboarding,
  importHandler
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [customUpdates, setCustomUpdates] = useState(fields);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCustomUpdates(fields);
  }, [fields]);
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: () => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          yield CustomProfileFormAPI.setFormValues(userId, form.formId, customUpdates);
          onSubmit(customUpdates);
        } else {
          importHandler == null ? void 0 : importHandler(customUpdates);
        }
        onClose == null ? void 0 : onClose();
      } catch (error) {
        showMessage(`Update could not be saved. ${nestErrorMessage(error)}.`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: usedForOnboarding ? capitalize(form.formName) : `Edit ${form.formName}` }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: sortCustomFields(customUpdates).map((f, idx) => /* @__PURE__ */ jsx(
      ShowCustomFieldComponent,
      {
        field: f,
        setCustomUpdates,
        autoFocus: idx === 0
      },
      f.field.fieldId
    )) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      !usedForOnboarding && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: onClose,
          disabled: loading,
          children: "Cancel"
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: usedForOnboarding ? polyglot.t("General.continue") : "Save",
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
