"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import {
  DeviceOSValueLabelOptions,
  DeviceTypesValueLabelOptions,
  ManufacturerValueLabelOptions,
  processMdmServerName
} from "@v2/feature/device/device.util";
import { useDepAccountDetails } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook";
import { InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const EditTechSpecsDrawerContent = ({
  device,
  devicePossessionId,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { depAccountDetails } = useDepAccountDetails(
    globalState.user.company.companyId + globalState.user.company.name
  );
  const initialValues = {
    type: device.type,
    deviceName: device.deviceName,
    modelName: device.modelName,
    modelNumber: device.modelNumber,
    manufacturer: device.manufacturer,
    os: device.os,
    ram: device.ram,
    storage: device.storage,
    screenSize: device.screenSize,
    cpu: device.cpu,
    gpu: device.gpu,
    serialNumber: device.serialNumber,
    internalNotes: device.internalNotes,
    customerNotes: device.customerNotes,
    isDep: (_a = device.isDep) != null ? _a : false,
    depDeviceUsername: (_b = device.depDeviceUsername) != null ? _b : null
  };
  const validationSchema = yup.object({
    type: yup.string().required("You must specify a value."),
    modelName: yup.string().required("You must specify a value."),
    deviceName: yup.string().nullable().notRequired(),
    modelNumber: yup.string().nullable().notRequired(),
    manufacturer: yup.string().nullable().notRequired(),
    os: yup.string().nullable().notRequired(),
    ram: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    storage: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    screenSize: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    cpu: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    gpu: yup.number().nullable().typeError("You must specify a number.").notRequired(),
    serialNumber: yup.string().nullable().notRequired(),
    customerNotes: yup.string().nullable().notRequired(),
    internalNotes: yup.string().nullable().notRequired(),
    isDep: yup.boolean().nullable().notRequired(),
    depDeviceUsername: yup.string().nullable().notRequired()
  });
  const updateDeviceTechSpecs = (deviceUpdate) => __async(void 0, null, function* () {
    var _a2, _b2, _c2, _d2, _e2, _f2;
    try {
      setLoading(true);
      const update = {
        type: deviceUpdate.type,
        deviceName: deviceUpdate.deviceName,
        modelName: deviceUpdate.modelName,
        modelNumber: (_a2 = deviceUpdate.modelNumber) != null ? _a2 : null,
        os: deviceUpdate.os && deviceUpdate.os !== "none" ? deviceUpdate.os : null,
        manufacturer: (_b2 = deviceUpdate.manufacturer) != null ? _b2 : null,
        serialNumber: (_c2 = deviceUpdate.serialNumber) != null ? _c2 : null,
        ram: deviceUpdate.ram ? Number(deviceUpdate.ram) : null,
        storage: deviceUpdate.storage ? Number(deviceUpdate.storage) : null,
        screenSize: deviceUpdate.screenSize ? Number(deviceUpdate.screenSize) : null,
        cpu: deviceUpdate.cpu ? Number(deviceUpdate.cpu) : null,
        gpu: deviceUpdate.gpu ? Number(deviceUpdate.gpu) : null,
        internalNotes: (_d2 = deviceUpdate.internalNotes) != null ? _d2 : null,
        customerNotes: (_e2 = deviceUpdate.customerNotes) != null ? _e2 : null,
        isDep: (_f2 = deviceUpdate.isDep) != null ? _f2 : false,
        depDeviceUsername: deviceUpdate.isDep ? deviceUpdate.depDeviceUsername : null
      };
      if (depAccountDetails && deviceUpdate.isDep) {
        if (deviceUpdate.depDeviceUsername && deviceUpdate.serialNumber) {
          const depEvent = {
            depDeviceUsername: deviceUpdate.depDeviceUsername,
            depTokenName: processMdmServerName(globalState.user.company.companyId + globalState.user.company.name),
            deviceId: device.id,
            serialNumber: deviceUpdate.serialNumber
          };
          yield InHouseMdmAPI.assignDeviceToABM(depEvent);
        } else {
          showMessage(`Serial number and device username have to be provided to assign to DEP`, "error");
          return;
        }
      }
      yield DeviceAPI.updateDeviceTechSpecsByPossessionId(devicePossessionId, update);
      showMessage("Device successfully updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      yield updateDeviceTechSpecs(values);
    }),
    enableReinitialize: true
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Edit tech specs" }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: DeviceTypesValueLabelOptions,
        value: (_c = formik.values.type) != null ? _c : void 0,
        compareValue: (_d = formik.values.type) != null ? _d : void 0,
        onChange: formik.handleChange,
        error: formik.touched.type && !!formik.errors.type,
        helperText: formik.touched.type && formik.errors.type
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Device Name",
        name: "deviceName",
        value: formik.values.deviceName,
        onChange: formik.handleChange,
        error: formik.touched.deviceName && Boolean(formik.errors.deviceName),
        helperText: formik.touched.deviceName && formik.errors.deviceName,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Model Number",
        name: "modelNumber",
        value: formik.values.modelNumber,
        onChange: formik.handleChange,
        error: formik.touched.modelNumber && Boolean(formik.errors.modelNumber),
        helperText: formik.touched.modelNumber && formik.errors.modelNumber,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Model Name",
        name: "modelName",
        value: formik.values.modelName,
        onChange: formik.handleChange,
        error: formik.touched.modelName && Boolean(formik.errors.modelName),
        helperText: formik.touched.modelName && formik.errors.modelName,
        size: "small",
        endAdornment: "none",
        placeholder: "MacBook Air"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Serial Number",
        name: "serialNumber",
        value: formik.values.serialNumber,
        onChange: formik.handleChange,
        error: formik.touched.serialNumber && Boolean(formik.errors.serialNumber),
        helperText: formik.touched.serialNumber && formik.errors.serialNumber,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "manufacturer",
        label: "Manufacturer",
        options: ManufacturerValueLabelOptions,
        value: (_e = formik.values.manufacturer) != null ? _e : null,
        compareValue: (_f = formik.values.manufacturer) != null ? _f : null,
        error: !!formik.errors.manufacturer && formik.touched.manufacturer,
        onChange: formik.handleChange,
        helperText: formik.touched.manufacturer && formik.errors.manufacturer
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "os",
        label: "OS",
        options: [{ label: "None", value: "none" }, ...DeviceOSValueLabelOptions],
        value: (_g = formik.values.os) != null ? _g : void 0,
        compareValue: (_h = formik.values.os) != null ? _h : void 0,
        error: !!formik.errors.os && formik.touched.os,
        onChange: formik.handleChange,
        helperText: formik.touched.os && formik.errors.os
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "RAM (GB)",
        name: "ram",
        value: formik.values.ram,
        onChange: formik.handleChange,
        error: formik.touched.ram && !!formik.errors.ram,
        helperText: formik.touched.ram && formik.errors.ram,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "CPU Cores",
        name: "cpu",
        value: formik.values.cpu,
        onChange: formik.handleChange,
        error: formik.touched.cpu && Boolean(formik.errors.cpu),
        helperText: formik.touched.cpu && formik.errors.cpu,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "GPU Cores",
        name: "gpu",
        value: formik.values.gpu,
        onChange: formik.handleChange,
        error: formik.touched.gpu && Boolean(formik.errors.gpu),
        helperText: formik.touched.gpu && formik.errors.gpu,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Storage (GB)",
        name: "storage",
        value: formik.values.storage,
        onChange: formik.handleChange,
        error: formik.touched.storage && Boolean(formik.errors.storage),
        helperText: formik.touched.storage && formik.errors.storage,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Screen Size (inch)",
        name: "screenSize",
        value: formik.values.screenSize,
        onChange: formik.handleChange,
        error: formik.touched.screenSize && Boolean(formik.errors.screenSize),
        helperText: formik.touched.screenSize && formik.errors.screenSize,
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    depAccountDetails && /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        name: "isDep",
        label: "Device is added in the connected Apple Business Manager",
        checked: (_i = formik.values.isDep) != null ? _i : void 0,
        onChange: formik.handleChange
      }
    ),
    depAccountDetails && formik.values.isDep && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Device username",
        name: "depDeviceUsername",
        value: formik.values.depDeviceUsername,
        onChange: formik.handleChange,
        error: formik.touched.depDeviceUsername && Boolean(formik.errors.depDeviceUsername),
        helperText: formik.touched.depDeviceUsername && formik.errors.depDeviceUsername,
        size: "small",
        endAdornment: "none",
        placeholder: "TestAccount"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading, fullWidth: true, colorVariant: "primary", sizeVariant: "medium" }) })
  ] }) });
};
