"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  UserCustomBenefitRequestType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE } from "@/lib/routes";
export const BenefitOptOutDrawer = ({ isOpen, setIsOpen, userId, userBenefit, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(BenefitOptOutDrawerContent, { userId, userBenefit, setIsOpen, refresh }) });
};
export const BenefitOptOutDrawerContent = ({ userId, setIsOpen, userBenefit, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const location = useLocation();
  const isMePage = useMemo(() => location.pathname.includes("/me/"), [location.pathname]);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setIsLoading(true);
        const { optedOut } = yield CustomBenefitAPI.requestUserBenefitOptOut(
          userId,
          userBenefit.customBenefitId,
          userBenefit.id,
          values
        );
        if (optedOut)
          history.push(isMePage ? BENEFITS_ME_ROUTE : generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId }));
        else yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setIsLoading(false);
    }),
    [history, isMePage, userId, userBenefit.customBenefitId, userBenefit.id, refresh, setIsOpen, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      type: UserCustomBenefitRequestType.OptOut,
      effectiveDate: new LocalDate().toDateString(),
      notes: "",
      amount: null,
      numberOfInstallments: null,
      employerContribution: null,
      employeeContribution: null,
      dependants: null,
      dependantsList: null
    },
    validationSchema: yup.object({
      type: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      effectiveDate: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      notes: yup.string().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("BenefitModule.optOut") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: "20px" }, children: polyglot.t("BenefitModule.wantToOptOut") }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: "20px" }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "notes",
        label: polyglot.t("General.notes"),
        value: formik.values.notes,
        onChange: formik.handleChange,
        error: formik.touched.notes && !!formik.errors.notes,
        helperText: (_a = formik.touched.notes && formik.errors.notes) != null ? _a : " "
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px", mt: "20px" }, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.cancel"),
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => setIsOpen(false),
          loading: false,
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("BenefitModule.optOut"),
          sizeVariant: "medium",
          colorVariant: "primary",
          loading: isLoading,
          fullWidth: true
        }
      )
    ] })
  ] }) });
};
