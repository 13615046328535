"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import { Box } from "@mui/system";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceDetailsComponent } from "@v2/feature/device/features/device-cards/components/device-details.component";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { checkScopes } from "@/lib/scopes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const DeviceMdmDetailsPage = ({ configurableDevice, ownerName, locationAddress, loading, refresh }) => {
  var _a, _b;
  const [deviceName, setDeviceName] = useState(
    (_b = (_a = configurableDevice == null ? void 0 : configurableDevice.devicePossession.device) == null ? void 0 : _a.deviceName) != null ? _b : "New device"
  );
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const updateDeviceName = useCallback(
    (deviceId, deviceNameParam) => __async(void 0, null, function* () {
      try {
        yield DeviceAPI.updateDeviceName(deviceId, {
          deviceName: deviceNameParam
        });
      } catch (error) {
        showMessage("Could not set device name.", "error");
      }
      showMessage("Device name successfully updated", "success");
    }),
    [showMessage]
  );
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(Box, { style: { display: "flex", alignItems: "center", width: "100%" }, children: (configurableDevice == null ? void 0 : configurableDevice.devicePossession.deviceId) ? /* @__PURE__ */ jsx(Fragment, { children: checkScopes(globalState.user, ["devices:all"], scopesContext) ? /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Box, { sx: { minWidth: "110px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Details of\xA0" }) }),
        /* @__PURE__ */ jsx(
          EditableTitle,
          {
            variant: "headline1",
            value: deviceName,
            onChange: (value) => __async(void 0, null, function* () {
              setDeviceName(value);
              if (configurableDevice == null ? void 0 : configurableDevice.devicePossession.deviceId) {
                yield updateDeviceName(configurableDevice.devicePossession.deviceId, value);
                yield refresh();
              }
            }),
            maxLength: 64
          }
        )
      ] }) : /* @__PURE__ */ jsx(Box, { sx: { minWidth: "110px" }, children: /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
        "Details of ",
        deviceName
      ] }) }) }) : /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Details" }) }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      loading,
      children: configurableDevice && /* @__PURE__ */ jsx(
        DeviceDetailsComponent,
        {
          configurableDevice,
          ownerName,
          locationAddress,
          refresh
        }
      )
    }
  );
};
