"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, FormControlLabel, FormLabel, RadioGroup, Stack } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import {
  AnonymityThreshold,
  AnonymityType
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCParticipantAnonymityModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SCParticipantAnonymityModalContent, { surveyCycle, onClose, refresh }) });
};
const useAnonymitySettingForm = (surveyCycle, onClose, refresh) => {
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      visibilitySettings: (surveyCycle == null ? void 0 : surveyCycle.visibilitySettings) || {
        anonymiseAnswers: false,
        anonymityType: null,
        anonymityThreshold: null
      }
    },
    validationSchema: yup.object({
      visibilitySettings: yup.object({
        anonymiseAnswers: yup.boolean().notRequired(),
        anonymityType: yup.string().nullable().notRequired(),
        anonymityThreshold: yup.number().nullable().notRequired()
      })
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (!surveyCycle) {
          throw new Error("Survey cycle is not defined.");
        }
        const isPreOngoingState = [CycleState.Draft, CycleState.Scheduled].includes(surveyCycle.state);
        if (isPreOngoingState) {
          const updatedCycle = __spreadProps(__spreadValues({}, surveyCycle), {
            visibilitySettings: values.visibilitySettings
          });
          yield SurveyCycleAPI.updateSurveyCycle(updatedCycle);
        }
        showMessage("Successfully updated anonymity settings", "success");
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
const SCParticipantAnonymityModalContent = ({
  surveyCycle,
  onClose,
  refresh
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const formik = useAnonymitySettingForm(surveyCycle, onClose, refresh);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Edit anonymity settings" }),
    /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Anonymity" }),
        /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: formik.values.visibilitySettings.anonymiseAnswers,
            name: "anonymous-survey",
            onChange: (_e, enabled) => {
              formik.setFieldValue("visibilitySettings", { anonymiseAnswers: enabled });
              if (enabled) {
                formik.setFieldValue("visibilitySettings.anonymityType", AnonymityType.Full);
                formik.setFieldValue("visibilitySettings.anonymityThreshold", AnonymityThreshold.Three);
              } else {
                formik.setFieldValue("visibilitySettings.anonymityType", null);
                formik.setFieldValue("visibilitySettings.anonymityThreshold", null);
              }
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Direct employee attribution will not be possible. Participant list will not be editable after survey is launched." })
    ] }),
    /* @__PURE__ */ jsxs(
      Stack,
      {
        direction: "column",
        gap: spacing.g8,
        sx: {
          visibility: Boolean((_a = formik.values.visibilitySettings) == null ? void 0 : _a.anonymiseAnswers) ? "visible" : "hidden",
          opacity: Boolean((_b = formik.values.visibilitySettings) == null ? void 0 : _b.anonymiseAnswers) ? 1 : 0,
          transition: "opacity 0.3s ease, visibility 0.3s ease"
        },
        children: [
          /* @__PURE__ */ jsx(FormLabel, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Anonymity type" }) }),
          /* @__PURE__ */ jsxs(
            RadioGroup,
            {
              "aria-labelledby": "anonymity type",
              name: "anonymity-type",
              id: "anonymity-type",
              onChange: (event) => {
                formik.setFieldValue("visibilitySettings.anonymityType", event.target.value);
              },
              sx: { display: "flex", flexDirection: "column", gap: "10px" },
              children: [
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    id: "full-anonymity-type",
                    labelPlacement: "end",
                    value: AnonymityType.Full,
                    checked: Boolean(
                      formik.values.visibilitySettings.anonymityType !== null && formik.values.visibilitySettings.anonymityType === AnonymityType.Full
                    ),
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
                      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Full" }),
                      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", id: "full-type-description", children: "No respondent data is collected" })
                    ] })
                  },
                  "full-anonymity-type"
                ),
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    id: "semi-anonymity-type",
                    labelPlacement: "end",
                    value: AnonymityType.Semi,
                    checked: formik.values.visibilitySettings.anonymityType === AnonymityType.Semi,
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
                      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Semi" }),
                      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", id: "semi-type-description", children: "Participant's department, site, gender and tenure is saved." })
                    ] })
                  },
                  "semi-anonymity-type"
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsxs(
      Stack,
      {
        direction: "column",
        gap: spacing.g8,
        sx: {
          visibility: Boolean((_c = formik.values.visibilitySettings) == null ? void 0 : _c.anonymiseAnswers) ? "visible" : "hidden",
          opacity: Boolean((_d = formik.values.visibilitySettings) == null ? void 0 : _d.anonymiseAnswers) ? 1 : 0,
          transition: "opacity 0.3s ease, visibility 0.3s ease"
        },
        children: [
          /* @__PURE__ */ jsxs(FormLabel, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Anonymity threshold" }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Minimum answers required to view results for any question or chart" })
          ] }),
          /* @__PURE__ */ jsxs(
            RadioGroup,
            {
              name: "anonymity-threshold",
              onChange: (event) => {
                formik.setFieldValue("visibilitySettings.anonymityThreshold", Number(event.target.value));
              },
              sx: { display: "flex", flexDirection: "column" },
              children: [
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: AnonymityThreshold.Three,
                    checked: Boolean(
                      formik.values.visibilitySettings.anonymityThreshold !== null && formik.values.visibilitySettings.anonymityThreshold === AnonymityThreshold.Three
                    ),
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: AnonymityThreshold.Three })
                  },
                  "three-min-answer"
                ),
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: AnonymityThreshold.Five,
                    checked: formik.values.visibilitySettings.anonymityThreshold === AnonymityThreshold.Five,
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: AnonymityThreshold.Five })
                  },
                  "five-min-answer"
                ),
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "end",
                    value: AnonymityThreshold.Ten,
                    checked: formik.values.visibilitySettings.anonymityThreshold === AnonymityThreshold.Ten,
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: AnonymityThreshold.Ten })
                  },
                  "ten-min-answer"
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        disabled: !formik.isValid,
        fullWidth: true
      }
    ) })
  ] }) });
};
