"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, List, ListItem, ListItemText } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
const formatKeyToLabel = (key) => {
  return key.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^configurable/, "").trim().replace(/^\w/, (c) => c.toUpperCase());
};
export const SimpleInHouseMdmDeviceDialog = ({ open, onClose, appliedPolicies }) => {
  return /* @__PURE__ */ jsxs(
    Dialog,
    {
      open,
      onClose,
      PaperProps: {
        sx: {
          backgroundColor: "#FFFFFF",
          color: "black",
          maxWidth: "600px",
          maxHeight: "80vh",
          overflowY: "auto",
          overflowX: "hidden"
        }
      },
      children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "headline2",
            sx: { padding: "16px", backgroundColor: "#f5f5f5", borderBottom: "1px solid #e0e0e0", textAlign: "center" },
            children: "Company Policies"
          }
        ),
        /* @__PURE__ */ jsx(
          DialogContent,
          {
            sx: {
              padding: "16px",
              width: "100%",
              maxHeight: "calc(80vh - 150px)",
              overflowY: "auto",
              overflowX: "hidden"
            },
            children: /* @__PURE__ */ jsx(List, { sx: { width: "100%" }, children: appliedPolicies && Object.keys(appliedPolicies).filter((key) => key.startsWith("configurable")).map((key) => /* @__PURE__ */ jsx(ListItem, { sx: { wordWrap: "break-word", overflowWrap: "break-word" }, children: /* @__PURE__ */ jsx(
              ListItemText,
              {
                primary: formatKeyToLabel(key),
                secondary: /* @__PURE__ */ jsx("pre", { style: { whiteSpace: "pre-wrap", wordWrap: "break-word", margin: 0 }, children: JSON.stringify(
                  appliedPolicies[key],
                  null,
                  2
                ) }),
                primaryTypographyProps: { sx: { wordWrap: "break-word", fontWeight: "bold" } },
                secondaryTypographyProps: { sx: { wordWrap: "break-word", paddingLeft: "16px" } }
              }
            ) }, key)) })
          }
        ),
        /* @__PURE__ */ jsx(DialogActions, { sx: { padding: "16px", justifyContent: "center" }, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: onClose, children: "Close" }) })
      ]
    }
  );
};
