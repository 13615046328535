"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { MultipleChoiceQuestionReadonly } from "@/v2/feature/growth/shared/components/readonly-questions/multiple-choice-question-readonly.component";
import { OpenEndedQuestionReadonly } from "@/v2/feature/growth/shared/components/readonly-questions/open-ended-question-readonly.components";
import { ScaleQuestionReadonly } from "@/v2/feature/growth/shared/components/readonly-questions/scale-question-readonly.component";
import { SingleAnswerQuestionReadonly } from "@/v2/feature/growth/shared/components/readonly-questions/single-answer-question-readonly.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { borders } from "@/v2/styles/borders.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GrowthPreviewer = ({
  questionsLookup,
  allSections,
  order
}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Preview" }),
    order.length > 0 ? order.map((item) => /* @__PURE__ */ jsx(RenderQuestionOrSection, { item, questionsLookup, sections: allSections, order })) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Add some sections and question to preview them." })
  ] });
};
const RenderQuestionOrSection = ({
  item,
  questionsLookup,
  sections,
  order
}) => {
  const renderQuestion = (questionId) => {
    const question = questionsLookup && questionsLookup[questionId];
    if (!question) return null;
    return /* @__PURE__ */ jsx(QuestionComponent, { question }, question.id);
  };
  const renderSection = (sectionId) => {
    var _a;
    const section = sections == null ? void 0 : sections.find((s) => s.id === sectionId);
    if (!section) return null;
    const orderSection = order.find((o) => o.id === sectionId && o.type === "section");
    if (!orderSection) return null;
    const orderedQuestions = ((_a = orderSection.questions) != null ? _a : []).map((questionId) => questionsLookup[questionId]).filter((question) => Boolean(question));
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16, paddingBottom: spacing.pb20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: section.name }),
      orderedQuestions.map((q) => /* @__PURE__ */ jsx(QuestionComponent, { question: q }, q.id))
    ] });
  };
  const renderQuestionOrSection = () => {
    var _a;
    if (item.type === "question" && questionsLookup[item.id]) {
      return renderQuestion(item.id);
    } else if (item.type === "section" && ((_a = item.questions) != null ? _a : []).some((id) => questionsLookup.hasOwnProperty(id))) {
      return /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m24 }, children: renderSection(item.id) });
    } else return /* @__PURE__ */ jsx(Fragment, {});
  };
  return /* @__PURE__ */ jsx(Fragment, { children: renderQuestionOrSection() });
};
const QuestionComponent = ({ question }) => {
  const getQuestionByType = (question2) => {
    switch (question2.type) {
      case QuestionType.SingleAnswer:
        return /* @__PURE__ */ jsx(
          SingleAnswerQuestionReadonly,
          {
            questionText: question2.questionText,
            options: question2.options,
            hasComment: question2.hasComment,
            isCommentRequired: question2.isCommentRequired
          }
        );
      case QuestionType.OpenEnded:
        return /* @__PURE__ */ jsx(OpenEndedQuestionReadonly, { questionText: question2.questionText });
      case QuestionType.MultipleAnswer:
        return /* @__PURE__ */ jsx(
          MultipleChoiceQuestionReadonly,
          {
            questionText: question2.questionText,
            options: question2.options,
            hasComment: question2.hasComment,
            isCommentRequired: question2.isCommentRequired
          }
        );
      case QuestionType.ScaleQuestion:
        return /* @__PURE__ */ jsx(
          ScaleQuestionReadonly,
          {
            questionText: question2.questionText,
            scaleConfig: question2.scaleConfig,
            hasComment: question2.hasComment,
            isCommentRequired: question2.isCommentRequired
          }
        );
      default:
        return null;
    }
  };
  return /* @__PURE__ */ jsx(Box, { sx: { padding: spacing.p16, border: borders.background, borderRadius: radius.br8 }, children: getQuestionByType(question) });
};
