"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ListView } from "@/images/new-theme-icon/ListView.svg";
import { ReactComponent as OrgChartView } from "@/images/new-theme-icon/OrgChartView.svg";
import { SwitchButton } from "@/v2/components/split-switch-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { iconSize } from "@/v2/feature/onboarding/onboarding-template-edit.page";
import { PeopleListView } from "@/v2/feature/user/pages/components/people-list-view.component";
import { PeopleOrgView } from "@/v2/feature/user/pages/components/people-org-view.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
const ViewSwitcher = ({
  setShowMode,
  showMode
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        minWidth: "60px",
        height: "30px",
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: "flex",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx(SwitchButton, { isActive: showMode === "list", color: themeColors.DarkGrey, action: () => setShowMode("list"), children: /* @__PURE__ */ jsx(ListView, __spreadProps(__spreadValues({}, iconSize), { fill: showMode === "list" ? themeColors.white : themeColors.DarkGrey })) }),
        /* @__PURE__ */ jsx(SwitchButton, { isActive: showMode === "org", color: themeColors.DarkGrey, action: () => setShowMode("org"), children: /* @__PURE__ */ jsx(OrgChartView, __spreadProps(__spreadValues({}, iconSize), { fill: showMode === "org" ? themeColors.white : themeColors.DarkGrey })) })
      ]
    }
  );
};
export function PeopleDirectoryPage() {
  const { polyglot } = usePolyglot();
  const [showMode, setShowMode] = useState("list");
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("PeopleDirectoryPage.directory") }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(ViewSwitcher, { showMode, setShowMode })
      }
    ),
    showMode === "list" && /* @__PURE__ */ jsx(PeopleListView, {}),
    showMode === "org" && /* @__PURE__ */ jsx(PeopleOrgView, {})
  ] });
}
