"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useContext, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { MultiUserAvatar } from "@v2/components/theme-components/multi-user-avatar.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { AbsenceLengthUnit, AbsenceStatus } from "@v2/feature/absence/absence.interface";
import { convertMinutesToClockHours, getPeriodFromAbsence, isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import { AbsenceDrawerActionButtons } from "@v2/feature/absence/components/absence-drawer-action-buttons.component";
import { AbsenceDrawerPHButtons } from "@v2/feature/absence/components/absence-drawer-ph-buttons.component";
import { AbsenceOverlapDisplay } from "@v2/feature/absence/components/absence-overlap-display.component";
import { RequestPHCancellationDrawer } from "@v2/feature/absence/components/request-ph-cancellation-drawer.component";
import { AttachmentInDrawer } from "@v2/feature/absence/sections/absence-drawer/attachment-in-drawer.component";
import { ApproversList } from "@v2/feature/approval-rule/components/approvers-list.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { AbsenceDrawerPayItemPreview } from "@v2/feature/payroll/features/pay-item/components/absence-drawer-pay-item-preview.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateAbsenceStatuses } from "@v2/infrastructure/i18n/translate.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const AbsenceViewDrawer = ({
  isOpen,
  absence,
  setAbsence,
  setIsAbsenceDrawerViewOpen,
  handleApprove,
  handleReject,
  onDelete,
  onRejectCancellation,
  onRequestCancellation,
  onForceApproval,
  onEdit,
  showCalendarLink = true,
  onClose,
  afterClose,
  loading,
  refresh
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen: setIsAbsenceDrawerViewOpen,
      onClose,
      afterClose,
      loading,
      children: /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "90%",
              height: "90vh",
              sx: { borderRadius: "10px", mx: "auto", mt: 4 }
            }
          ),
          children: absence ? /* @__PURE__ */ jsx(
            AbsenceViewDrawerContent,
            {
              absence,
              setAbsence,
              handleApprove,
              handleReject,
              onDelete,
              onRejectCancellation,
              onRequestCancellation,
              onForceApproval,
              onEdit,
              showCalendarLink,
              refresh
            }
          ) : /* @__PURE__ */ jsx(AbsenceNotFound, {})
        }
      )
    }
  );
};
export const AbsenceViewDrawerContent = ({
  absence,
  setAbsence,
  handleApprove,
  handleReject,
  onDelete,
  onRejectCancellation,
  onRequestCancellation,
  onForceApproval,
  onEdit,
  showCalendarLink,
  refresh
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { data: userBalanceBreakdown } = useApiClient(
    (absence == null ? void 0 : absence.status) === AbsenceStatus.Pending && !absence.isPublicHoliday ? AbsenceEndpoints.fetchUserAbsenceBalanceBreakdown(absence.userId, {
      cycleStartYear: absence.carryOverYear,
      policyId: absence.policyId
    }) : null
  );
  const [isRequestCancellationOpen, setIsRequestCancellationOpen] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const absenceUser = useMemo(() => getCachedUserById(absence.userId), [getCachedUserById, absence.userId]);
  const createdByUser = useMemo(
    () => absence.createdBy ? absence.createdBy !== absence.userId ? getCachedUserById(absence.createdBy) : absenceUser : null,
    [absence.createdBy, absence.userId, absenceUser, getCachedUserById]
  );
  const userIdsInOverlapsDisplay = useMemo(() => [absence.userId], [absence.userId]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", gap: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: ((_a = absence.policy) == null ? void 0 : _a.name) ? absence.policy.name : absence.isPublicHoliday ? absence.notes : polyglot.t("AbsenceViewDrawerContent.timeEntry") }),
      absence.canRequestPHDeletion && /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setIsRequestCancellationOpen(true), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
    ] }),
    /* @__PURE__ */ jsx(
      RequestPHCancellationDrawer,
      {
        isOpen: isRequestCancellationOpen,
        setIsOpen: setIsRequestCancellationOpen,
        absence,
        refresh
      }
    ),
    absenceUser && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.employee"),
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: absenceUser.userId, size: "s20" }),
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t(absenceUser.displayName || `${absenceUser.firstName} ${absenceUser.lastName}`) })
        ] })
      }
    ),
    createdByUser && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.createdBy"),
        value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(UserAvatar, { userId: createdByUser.userId, size: "s20" }),
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t(createdByUser.displayName || `${createdByUser.firstName} ${createdByUser.lastName}`) })
        ] })
      },
      "created-by"
    ),
    /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.period"),
        value: getPeriodFromAbsence(absence)
      },
      "period"
    ),
    absence.notes && /* @__PURE__ */ jsx(DrawerViewerItem, { title: polyglot.t("General.notes"), value: absence.notes }, "notes"),
    absence.createdAt && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.requestedOn"),
        value: getDateString(absence.createdAt)
      },
      "requested-on"
    ),
    /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.requestLength"),
        value: absence.totalLength > 0 ? polyglot.t("AbsenceViewDrawerContent.requestLengthValue", {
          smart_count: absence.workdayCount,
          noOfHours: convertMinutesToClockHours(absence.totalLength, polyglot)
        }) : polyglot.t("AbsenceViewDrawerContent.requestLengthValueDayOnly", {
          smart_count: absence.workdayCount
        })
      },
      "requestLength"
    ),
    ((_b = absence.policy) == null ? void 0 : _b.allowanceType) && !isUnlimitedPolicy(absence.policy) && absence.status === AbsenceStatus.Pending && userBalanceBreakdown && ((_c = userBalanceBreakdown[absence.policyId]) == null ? void 0 : _c.currentBalance) !== null && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.remainingBalance"),
        value: ((_d = userBalanceBreakdown[absence.policyId]) == null ? void 0 : _d.lengthUnit) === AbsenceLengthUnit.Day ? polyglot.t("General.noOfDays", {
          smart_count: userBalanceBreakdown[absence.policyId].currentBalanceInDays
        }) : convertMinutesToClockHours(userBalanceBreakdown[absence.policyId].currentBalance, polyglot)
      },
      "remainingBalance"
    ),
    !absence.isPublicHoliday && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.status"),
        value: absence.status !== AbsenceStatus.Approved ? translateAbsenceStatuses(absence.status, polyglot) : absence.cancellationRequested ? polyglot.t("AbsenceViewDrawerContent.pendingCancellation") : translateAbsenceStatuses(AbsenceStatus.Approved, polyglot)
      },
      "status"
    ),
    absence.isPublicHoliday && absence.status === AbsenceStatus.Pending && /* @__PURE__ */ jsx(Typography, { variant: "headline3", color: "DarkGrey", children: polyglot.t("AbsenceViewDrawerContent.deletionRequested") }),
    absence.isPublicHoliday && absence.status === AbsenceStatus.Rejected && /* @__PURE__ */ jsx(Typography, { variant: "headline3", color: "DarkGrey", children: polyglot.t("AbsenceViewDrawerContent.deletionApproved") }),
    absence.status === AbsenceStatus.Pending && /* @__PURE__ */ jsx(ApproversList, { approverSteps: absence.approverSteps }),
    (absence == null ? void 0 : absence.approvedByIds) && absence.approvedByIds.length > 0 && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("General.approvedBy"),
        value: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: absence.approvedByIds })
      },
      "approvedBy"
    ),
    (absence == null ? void 0 : absence.rejectedByIds) && absence.rejectedByIds.length > 0 && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("General.rejectedBy"),
        value: /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: absence.rejectedByIds })
      },
      "rejectedBy"
    ),
    absence.status === AbsenceStatus.Rejected && absence.approvalNotes && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: polyglot.t("AbsenceViewDrawerContent.rejectionNotes"),
        value: absence.approvalNotes
      },
      "rejection-notes"
    ),
    absence.approvedOnTimestamp && [AbsenceStatus.Approved, AbsenceStatus.Rejected].includes(absence.status) && /* @__PURE__ */ jsx(
      DrawerViewerItem,
      {
        title: absence.status === AbsenceStatus.Approved ? polyglot.t("AbsenceViewDrawerContent.dateApproved") : polyglot.t("AbsenceViewDrawerContent.dateRejected"),
        value: getDateString(absence.approvedOnTimestamp)
      },
      "date-approved"
    ),
    absence.attachment && (absence.userId === globalState.user.userId || absence.canEdit || absence.isInFullApproversList) && /* @__PURE__ */ jsx(AttachmentInDrawer, { entityWithAttachment: absence, fileLabel: polyglot.t("DocumentModule.attachment") }),
    /* @__PURE__ */ jsx(
      AbsenceOverlapDisplay,
      {
        absenceId: absence.absenceId,
        absenceEnd: absence.end,
        absenceStart: absence.start,
        showCalendarLink,
        userIds: userIdsInOverlapsDisplay,
        isViewMode: true
      }
    ),
    !absence.isPublicHoliday && /* @__PURE__ */ jsx(ScopesControl, { scopes: ["payroll:all"], context: { userId: absence.userId }, children: /* @__PURE__ */ jsx(AbsenceDrawerPayItemPreview, { userId: absence.userId, absenceId: absence.absenceId }) }),
    absence.isPublicHoliday ? /* @__PURE__ */ jsx(AbsenceDrawerPHButtons, { absence, refresh }) : /* @__PURE__ */ jsx(
      AbsenceDrawerActionButtons,
      {
        absence,
        setAbsence,
        handleApprove,
        handleReject,
        onDelete,
        onRejectCancellation,
        onRequestCancellation,
        onForceApproval,
        onEdit
      }
    )
  ] });
};
const AbsenceNotFound = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceViewDrawerContent.timeEntry") }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({ display: "flex", flexDirection: "column" }, spacing.gap20), { marginTop: spacing.m30 }), children: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
      polyglot.t("AbsenceViewDrawerContent.absenceNotFound"),
      " "
    ] }) })
  ] });
};
