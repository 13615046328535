"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { capitalize } from "lodash";
import { ReactComponent as Inprogress } from "@/images/fields/Inprogress.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { EntryState } from "@/v2/feature/growth/reviews/interfaces/review-entry.interface";
import { borders } from "@/v2/styles/borders.styles";
import { styledChipSx } from "@/v2/styles/chip.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const getReviewType = (entry) => {
  if (entry.reviewerManagerId) return ReviewerTypes.Manager;
  if (entry.reviewerPeerId) return ReviewerTypes.Peer;
  if (entry.reviewerSelfId) return ReviewerTypes.Self;
  return ReviewerTypes.Upward;
};
export const getReviewDueDate = (entry) => {
  return new LocalDate(entry.endDate) || new LocalDate();
};
export const getEntryStatus = () => {
  return {
    [EntryState.NotStarted]: getStatusChip("Not started", /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Grey } }))),
    [EntryState.Draft]: getStatusChip("In progress", /* @__PURE__ */ jsx(Inprogress, __spreadValues({}, iconSize))),
    [EntryState.Submitted]: getStatusChip(
      EntryState.Submitted,
      /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } }))
    )
  };
};
export const getStatusChip = (label, icon) => /* @__PURE__ */ jsxs(
  Box,
  {
    sx: __spreadProps(__spreadValues({}, styledChipSx), {
      background: themeColors.white,
      border: borders.background,
      width: "fit-content",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: spacing.g4
    }),
    children: [
      /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center" }, children: icon }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: capitalize(label) })
    ]
  }
);
