"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionType } from "@v2/feature/device/device.interface";
import { primarySmallBtn, secondarySmallBtn } from "@v2/styles/buttons.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { themeColors } from "@/v2/styles/colors.styles";
export const DeviceActivationStepCredentials = ({
  deviceOrder,
  goToNextStep
}) => {
  const [loading, setLoading] = useState(true);
  const [isUserOwner, setIsUserOwner] = useState(true);
  const [credentials, setCredentials] = useState(null);
  const [showMessage] = useMessage();
  const getOwnerCredentials = useCallback(
    (devicePossession) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const credentials2 = yield DeviceAPI.getDeviceAssignedExternalUserCredentials(
          devicePossession.possessionId
          // userId
        );
        setCredentials(credentials2);
      } catch (error) {
        showMessage(`Could not retrieve user credentials. ${nestErrorMessage(error)}`, "error");
        setCredentials(null);
      } finally {
        setLoading(false);
      }
    }),
    [showMessage]
  );
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      var _a;
      if (((_a = deviceOrder.possession) == null ? void 0 : _a.possessionType) !== DevicePossessionType.User) {
        setIsUserOwner(false);
        setLoading(false);
        return;
      }
      yield getOwnerCredentials(deviceOrder.possession);
    }))();
  }, [getOwnerCredentials, deviceOrder]);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading, noHeader: true, border: false, children: /* @__PURE__ */ jsx(Box, { sx: { height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "400px", gap: spacing.gap20 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Credentials" }),
    !isUserOwner && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "You can retrieve device credentials only for devices assigned to users." }) }),
    isUserOwner && credentials && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb10, color: themeColors.DarkGrey }), children: "Unlock your laptop using these details" }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
        "Username: ",
        /* @__PURE__ */ jsx("b", { children: credentials.username })
      ] }),
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
        "Password: ",
        /* @__PURE__ */ jsx("b", { children: credentials.password })
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt10, color: themeColors.DarkGrey }), children: "You can always get these credentials again from your device page in Zelt by clicking Device actions." })
    ] }),
    isUserOwner && !credentials && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "User credentials could not be found." }),
      deviceOrder.possession && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt10, color: themeColors.DarkGrey }), children: "You can retry or you can skip this step and then try again using the Device Actions button." })
    ] }),
    isUserOwner && !credentials ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10 }, children: [
      deviceOrder.possession && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Retry",
          colorVariant: "primary",
          sizeVariant: "small",
          onClick: () => getOwnerCredentials(deviceOrder.possession),
          loading
        }
      ),
      /* @__PURE__ */ jsx(Button, { sx: secondarySmallBtn, onClick: goToNextStep, children: "Skip" })
    ] }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Button, { sx: primarySmallBtn, onClick: goToNextStep, children: "Continue" }) })
  ] }) }) });
};
