"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SupportedDeviceType } from "@v2/feature/device/device.interface";
import { FieldValueComponent } from "@v2/feature/device/device.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
export const OsUpdatesPolicyViewDrawer = ({
  setIsOpen,
  isOpen,
  onClose,
  policyName,
  isApple,
  isWindows,
  configurationPayloadOption,
  policy,
  allPolicies
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [selectedPolicyDto, setSelectedPolicyDto] = useState(void 0);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (allPolicies && (allPolicies == null ? void 0 : allPolicies.length) > 0 && policy) {
        const supportedDeviceType = isWindows ? SupportedDeviceType.windows : SupportedDeviceType.android;
        const selectedPolicy = allPolicies.filter((p) => p.supportedDeviceType.toString() === supportedDeviceType.toString()).find((p) => policy.includes(p.id));
        setSelectedPolicyDto(selectedPolicy);
      }
    } catch (error) {
      showMessage("Something went wrong. Could not load the company policies.", "error");
    } finally {
      setLoading(false);
    }
  }), [allPolicies, isWindows, policy, showMessage]);
  useEffect(() => {
    if (!isApple) {
      refresh();
    }
  }, [isApple, refresh]);
  const getInstallActionType = (action) => {
    switch (action) {
      case "Default":
        return "Download or install the update, depending on the current state";
      case "InstallASAP":
        return "Download the software update, install immediately and trigger the restart countdown notification";
      case "InstallForceRestart":
        return "Perform the Default action, and then force a restart";
      default:
        return "Unknown action";
    }
  };
  if (isApple && configurationPayloadOption) {
    return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: policyName }),
      /* @__PURE__ */ jsxs(
        Box,
        {
          component: "dl",
          sx: { display: "flex", flexDirection: "column", mt: spacing.m20, padding: 0, gap: spacing.g15 },
          children: [
            /* @__PURE__ */ jsx(
              FieldValueComponent,
              {
                title: polyglot.t(`InHouseMdmPolicyViewDrawer.automaticUpdates`),
                value: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: configurationPayloadOption.automaticOsUpdate ? "Active" : "Inactive" })
              }
            ),
            /* @__PURE__ */ jsx(
              FieldValueComponent,
              {
                title: polyglot.t(`InHouseMdmPolicyViewDrawer.osUpdateAction`),
                value: getInstallActionType(configurationPayloadOption.installAction)
              }
            )
          ]
        }
      )
    ] }) });
  } else {
    return /* @__PURE__ */ jsx(DrawerModal, { setIsOpen, isOpen, onClose, loading, children: /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: policyName }),
      /* @__PURE__ */ jsx(
        Box,
        {
          component: "dl",
          sx: { display: "flex", flexDirection: "column", mt: spacing.m20, padding: 0, gap: spacing.g15 },
          children: selectedPolicyDto && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: selectedPolicyDto.description })
        }
      )
    ] }) });
  }
};
