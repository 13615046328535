"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Badge, Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { generatePath, Route, Switch, useParams } from "react-router-dom";
import { CompanyDepartmentAPI } from "@/api-client/company-department.api";
import { CompensationAPI } from "@/api-client/compensation.api";
import { ContractsAPI } from "@/api-client/contracts.api";
import { SiteAPI } from "@/api-client/site.api";
import { UserRoleAPI } from "@/api-client/user-role.api";
import useMessage from "@/hooks/notification.hook";
import cover from "@/images/illustration_devices.png";
import { ReactComponent as Delete } from "@/images/side-bar-icons/Delete.svg";
import { nestErrorMessage } from "@/lib/errors";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { sortDate, sortString } from "@/v2/components/table/table-sorting.util";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { displayUserName } from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const RECORD_TABS = [{ value: "Contract" }, { value: "Role" }, { value: "Compensation" }];
export const HelperCompanyRecordsRouter = ({
  company
}) => {
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const params = useParams();
  const companyId = Number(params.companyId);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("Contract");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [companyContracts, setCompanyContracts] = useState([]);
  const [companyRoles, setCompanyRoles] = useState([]);
  const [companyDepartments, setCompanyDepartments] = useState([]);
  const [companySites, setCompanySites] = useState([]);
  const [companyCompensation, setCompanyCompensation] = useState([]);
  const [companyUserMap, setCompanyUserMap] = useState({});
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [contracts, roles, compensation, departments, sites, usersForCompany] = yield Promise.all([
        ContractsAPI.getContractsByCompanyId(companyId),
        UserRoleAPI.getRolesByCompanyId(companyId),
        CompensationAPI.getCompensationByCompanyId(companyId),
        CompanyDepartmentAPI.getCompanyDepartments(),
        SiteAPI.listSites(),
        UserAPI.getUserSuperadminBasicByCompany(companyId)
      ]);
      setCompanyContracts(contracts);
      setCompanyRoles(roles);
      setCompanyDepartments(departments);
      setCompanySites(sites);
      setCompanyCompensation(compensation);
      resetSelectionModel();
      const usersMap = usersForCompany.reduce((map, user) => {
        map[user.userId] = user;
        return map;
      }, {});
      setCompanyUserMap(usersMap);
    } catch (error) {
      showMessage(`Error loading company records: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage, companyId]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const resetSelectionModel = () => {
    setSelectionModel([]);
  };
  const apiMap = {
    Contract: ContractsAPI.bulkDeleteContractsByIds,
    Role: UserRoleAPI.bulkDeleteRolesByIds,
    Compensation: CompensationAPI.bulkDeleteCompensationByIds
  };
  const deleteCompanyRecords = () => __async(void 0, null, function* () {
    const apiFunction = apiMap[currentTab];
    if (apiFunction) {
      try {
        yield apiFunction(companyId, selectionModel);
        showMessage(`Successfully deleted ${selectionModel.length} ${currentTab} records`, "success");
        resetSelectionModel();
        refresh();
      } catch (error) {
        showMessage(`Error deleting records: ${nestErrorMessage(error)}`, "error");
      }
    } else {
      showMessage("Deletion not yet available", "warning");
    }
    setIsRemovalModalOpen(false);
    setAnchorEl(null);
  });
  const confirmTriggerDeleteCompanyRecords = (event) => {
    setAnchorEl(event.currentTarget);
    setIsRemovalModalOpen(true);
  };
  const tabRecordCount = (tabValue) => {
    var _a;
    const recordMap = {
      Contract: companyContracts == null ? void 0 : companyContracts.length,
      Role: companyRoles == null ? void 0 : companyRoles.length,
      Compensation: companyCompensation == null ? void 0 : companyCompensation.length
    };
    return (_a = recordMap[tabValue]) != null ? _a : "";
  };
  const contractColumns = useMemo(
    () => [
      {
        enableSorting: false,
        id: "selectCell",
        minSize: 20,
        maxSize: 20,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              id: (_a = original.id) == null ? void 0 : _a.toString(),
              label: void 0,
              name: "selectedRecord",
              checked: selectionModel.includes(Number(original.id)),
              onChange: () => {
                let finalSelection = [];
                if (original.id) {
                  if (selectionModel.find((recordId) => recordId === original.id)) {
                    finalSelection = selectionModel.filter((recordId) => recordId !== original.id);
                  } else finalSelection = [...selectionModel, original.id];
                  setSelectionModel(finalSelection);
                }
              }
            }
          );
        },
        header: () => {
          return /* @__PURE__ */ jsx(
            Box,
            {
              onClick: (e) => e.stopPropagation(),
              sx: {
                display: selectionModel.length > 0 ? "" : "none",
                justifyContent: "center",
                alignItems: "center"
              },
              children: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  id: "deselectAll",
                  label: void 0,
                  name: "selectedRecord",
                  checked: (selectionModel == null ? void 0 : selectionModel.length) > 0,
                  onChange: () => {
                    resetSelectionModel();
                  }
                }
              )
            }
          );
        }
      },
      {
        header: () => "Employee name",
        id: "employeeName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return item.userId ? (_a = companyUserMap[item.userId]) == null ? void 0 : _a.displayName : "";
        }),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return original.userId && /* @__PURE__ */ jsx("div", { children: (_a = companyUserMap[original.userId]) == null ? void 0 : _a.displayName });
        }
      },
      {
        header: () => "User ID",
        id: "userId",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 30,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.userId) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Effective date",
        id: "effectiveDate",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item == null ? void 0 : item.effectiveDate),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.effectiveDate) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Employment Contract",
        id: "contract",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.contract ? item.contract : ""),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.contract) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Worker type",
        id: "type",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.type ? item.type : ""),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.type) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "FTE% (schedule)",
        id: "fte",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original == null ? void 0 : original.attendanceSchedule) == null ? void 0 : _a.ftePercent) != null ? _b : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        headerName: "Holiday calendar",
        id: "holidayCalendar",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.publicHolidays) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        headerName: "Entity",
        id: "entity",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.entityId) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        headerName: "Change reason",
        id: "changeReason",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.changeReason) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      }
    ],
    [companyUserMap, selectionModel]
  );
  const roleColumns = useMemo(
    () => [
      {
        accessorFn: (row) => row,
        id: "selectCell",
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: "selectedRecord",
              checked: selectionModel.includes(Number(original.id)),
              onChange: () => {
                let finalSelection = [];
                if (original) {
                  if (selectionModel.find((recordId) => recordId === original.id)) {
                    finalSelection = selectionModel.filter((recordId) => recordId !== original.id);
                  } else finalSelection = [...selectionModel, original.id];
                  setSelectionModel(finalSelection);
                }
              }
            }
          ) }, original.id);
        },
        header: () => {
          return /* @__PURE__ */ jsx(
            Box,
            {
              onClick: (e) => e.stopPropagation(),
              sx: {
                display: selectionModel.length > 0 ? "" : "none",
                justifyContent: "center",
                alignItems: "center"
              },
              children: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  id: "deselectAll",
                  label: void 0,
                  name: "selectedRecord",
                  checked: (selectionModel == null ? void 0 : selectionModel.length) > 0,
                  onChange: () => {
                    resetSelectionModel();
                  }
                }
              )
            }
          );
        }
      },
      {
        header: () => "Employee name",
        id: "employeeName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return item.userId ? (_a = companyUserMap[item.userId]) == null ? void 0 : _a.displayName : "";
        }),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return original.userId && /* @__PURE__ */ jsx("div", { children: (_a = companyUserMap[original.userId]) == null ? void 0 : _a.displayName });
        }
      },
      {
        header: () => "User ID",
        id: "userId",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 30,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.userId) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Effective date",
        id: "effectiveDate",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item == null ? void 0 : item.effectiveDate),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original == null ? void 0 : original.effectiveDate) == null ? void 0 : _a.toString().split("T")[0]) != null ? _b : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Job title",
        id: "jobTitle",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return ((_a = item == null ? void 0 : item.jobPosition) == null ? void 0 : _a.title) ? (_b = item == null ? void 0 : item.jobPosition) == null ? void 0 : _b.title : "";
        }),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original == null ? void 0 : original.jobPosition) == null ? void 0 : _a.title) != null ? _b : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Department",
        accessorFn: (row) => row,
        id: "department",
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { departmentId }
          }
        }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: departmentId ? (_a = companyDepartments.find((d) => d.id === departmentId)) == null ? void 0 : _a.name : "" });
        }
      },
      {
        header: () => "Site",
        accessorFn: (row) => row,
        id: "site",
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { siteId }
          }
        }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { style: { textTransform: "capitalize" }, children: siteId ? (_a = companySites.find((s) => s.id === siteId)) == null ? void 0 : _a.name : "" });
        }
      },
      {
        header: () => "Reports to",
        accessorFn: (row) => row.managerId,
        id: "reportsTo",
        size: 80,
        enableSorting: false,
        cell: ({
          row: {
            original: { managerId }
          }
        }) => {
          const reportsTo = managerId ? companyUserMap[managerId] : void 0;
          return /* @__PURE__ */ jsx("div", { children: reportsTo ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(UserAvatar, { userId: reportsTo.userId, size: "s20" }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { marginLeft: spacing.m10 }), children: displayUserName(reportsTo) })
          ] }) : "-" });
        }
      }
    ],
    [companyDepartments, companySites, companyUserMap, selectionModel]
  );
  const compensationColumns = useMemo(
    () => [
      {
        enableSorting: false,
        id: "selectCell",
        minSize: 20,
        maxSize: 20,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          var _a, _b, _c;
          return /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              id: (_b = (_a = original == null ? void 0 : original.id) == null ? void 0 : _a.toString()) != null ? _b : "0",
              label: void 0,
              name: "selectedRecord",
              checked: selectionModel.includes((_c = Number(original == null ? void 0 : original.id)) != null ? _c : false),
              onChange: () => {
                let finalSelection = [];
                if (original && original.id) {
                  if (selectionModel.find((recordId) => recordId === original.id)) {
                    finalSelection = selectionModel.filter((recordId) => recordId !== original.id);
                  } else finalSelection = [...selectionModel, original.id];
                  setSelectionModel(finalSelection);
                }
              }
            }
          );
        },
        header: () => {
          return /* @__PURE__ */ jsx(
            Box,
            {
              onClick: (e) => e.stopPropagation(),
              sx: {
                display: selectionModel.length > 0 ? "" : "none",
                justifyContent: "center",
                alignItems: "center"
              },
              children: /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  id: "deselectAll",
                  label: void 0,
                  name: "selectedRecord",
                  checked: (selectionModel == null ? void 0 : selectionModel.length) > 0,
                  onChange: () => {
                    resetSelectionModel();
                  }
                }
              )
            }
          );
        }
      },
      {
        header: () => "Employee name",
        id: "employeeName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return item.userId ? (_a = companyUserMap[item.userId]) == null ? void 0 : _a.displayName : "";
        }),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return original.userId && /* @__PURE__ */ jsx("div", { children: (_a = companyUserMap[original.userId]) == null ? void 0 : _a.displayName });
        }
      },
      {
        header: () => "User ID",
        id: "userId",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 30,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.userId) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Effective date",
        id: "effectiveDate",
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item == null ? void 0 : item.effectiveDate),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.effectiveDate) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Pay schedule",
        id: "paySchedule",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.paySchedule ? item.paySchedule : ""),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original.paySchedule) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Salary basis",
        id: "salaryBasis",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.salaryBasis ? item.salaryBasis : ""),
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.salaryBasis) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Rate",
        id: "rate",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.rate) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Prorated Rate",
        id: "proratedRate",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.proratedRate) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Units",
        id: "units",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.units) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Salary per annum",
        id: "salaryPerAnnum",
        enableSorting: false,
        accessorFn: (row) => row,
        size: 60,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = original == null ? void 0 : original.salaryPerPaySchedule) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      }
    ],
    [companyUserMap, selectionModel]
  );
  const currentTabColumns = useMemo(() => {
    switch (currentTab) {
      case "Contract":
        return contractColumns;
      case "Role":
        return roleColumns;
      case "Compensation":
        return compensationColumns;
      default:
        return [];
    }
  }, [compensationColumns, contractColumns, currentTab, roleColumns]);
  const currentTabData = useMemo(() => {
    switch (currentTab) {
      case "Contract":
        return companyContracts;
      case "Role":
        return companyRoles;
      case "Compensation":
        return companyCompensation;
      default:
        return [];
    }
  }, [companyCompensation, companyContracts, companyRoles, currentTab]);
  const switchTab = (tabValue) => {
    resetSelectionModel();
    setCurrentTab(tabValue);
  };
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_RECORDS_ROUTE, children: /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: [
          "Helper page - Records | ",
          (company == null ? void 0 : company.name) ? company.name : "",
          " [ ",
          companyId,
          " ]"
        ] }),
        showBack: true,
        backPath: generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "row" }, children: [
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "left"
            },
            children: /* @__PURE__ */ jsx(
              Tabs,
              {
                value: currentTab,
                scrollButtons: "auto",
                variant: "scrollable",
                allowScrollButtonsMobile: true,
                onChange: (e, value) => switchTab(value),
                children: RECORD_TABS.map((tab) => {
                  var _a, _b, _c;
                  return /* @__PURE__ */ jsx(
                    Tab,
                    {
                      disableRipple: true,
                      label: /* @__PURE__ */ jsxs(
                        "span",
                        {
                          style: {
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            paddingRight: (
                              // @ts-ignore
                              currentTabData && ((_a = currentTabData[tab.value.toLowerCase()]) == null ? void 0 : _a.length) > 0 ? 10 : 0
                            )
                          },
                          children: [
                            tab.value,
                            /* @__PURE__ */ jsxs(Box, { sx: { ml: spacing.ml10 }, children: [
                              " (",
                              tabRecordCount(tab.value),
                              ")"
                            ] }),
                            currentTabData && ((_b = currentTabData[tab.value.toLowerCase()]) == null ? void 0 : _b.length) > 0 && /* @__PURE__ */ jsx(
                              Badge,
                              {
                                badgeContent: (
                                  // @ts-ignore
                                  currentTabData && ((_c = currentTabData[tab.value.toLowerCase()]) == null ? void 0 : _c.length) > 0 ? (
                                    // @ts-ignore
                                    currentTabData[tab.value.toLowerCase()].length
                                  ) : ""
                                ),
                                color: "primary",
                                sx: { ml: 2 }
                              }
                            )
                          ]
                        }
                      ),
                      value: tab.value
                    },
                    tab.value
                  );
                })
              }
            )
          }
        ),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              mr: 4
            },
            children: [
              /* @__PURE__ */ jsx(IconButton, { color: "secondary", onClick: () => refresh(), children: /* @__PURE__ */ jsx(RefreshIcon, {}) }),
              /* @__PURE__ */ jsxs(
                IconButton,
                {
                  sx: { display: (selectionModel == null ? void 0 : selectionModel.length) > 0 ? "" : "none" },
                  onClick: (e) => confirmTriggerDeleteCompanyRecords(e),
                  children: [
                    /* @__PURE__ */ jsx(Delete, {}),
                    " ",
                    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: (selectionModel == null ? void 0 : selectionModel.length) ? `(${selectionModel == null ? void 0 : selectionModel.length})` : "" })
                  ]
                }
              )
            ]
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
        currentTabData && currentTab && currentTab === "Contract" && /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: currentTabData != null ? currentTabData : [],
            columnData: currentTabColumns,
            loading,
            initialSort: [{ id: "employeeName", desc: true }]
          }
        ),
        currentTabData && currentTab && currentTab === "Role" && /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: currentTabData != null ? currentTabData : [],
            columnData: currentTabColumns,
            loading,
            initialSort: [{ id: "employeeName", desc: true }]
          }
        ),
        currentTabData && currentTab && currentTab === "Compensation" && /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: currentTabData != null ? currentTabData : [],
            columnData: currentTabColumns,
            loading,
            initialSort: [{ id: "employeeName", desc: true }]
          }
        )
      ] }),
      !currentTabData && /* @__PURE__ */ jsx(
        EmptyStateComponent,
        {
          header: !currentTabData ? "No tab selected" : "No data",
          subheader: !currentTab ? "Please select a tab from above in order to see relevant company records" : "No company records",
          cover
        }
      ),
      /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: isRemovalModalOpen,
          onClose: () => setIsRemovalModalOpen(false),
          anchorEl,
          takeAction: () => __async(void 0, null, function* () {
            deleteCompanyRecords();
          }),
          message: `Are you sure you want to delete the ${selectionModel.length} selected ${currentTab} records?`,
          callToAction: "Yes"
        }
      )
    ] })
  ] }) }) });
};
