"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Fade, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import Amazon from "@/images/referral/amazon.png";
import Stars from "@/images/referral/Stars.png";
import { DASHBOARD_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const submissionUrl = "https://api.hsforms.com/submissions/v3/integration/submit/20337724/1d10c235-511c-43cc-a56e-940e228f16c1";
const ReferSchema = Yup.object().shape({
  name: Yup.string().nullable().required("Name is required"),
  email: Yup.string().nullable().required("Email is required")
});
const ReferralSuccessState = ({ resetForm }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, spacing.px20), { py: spacing.p30 }), children: [
    /* @__PURE__ */ jsx(Box, { sx: { height: "150px", display: "flex", justifyContent: "flex-start" }, children: /* @__PURE__ */ jsx("img", { src: Stars, alt: "star-illustration", width: "auto" }) }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mt: spacing.m30 }), children: polyglot.t("ReferralSuccessState.congratulations") }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10 }), children: polyglot.t("ReferralSuccessState.successFull") }),
    /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        onClick: () => {
          resetForm();
        },
        children: polyglot.t("ReferralSuccessState.referAgain")
      }
    ) })
  ] });
};
const Reward = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("Reward.1000") }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
      polyglot.t("Reward.referFriend"),
      " "
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30, display: "flex", gap: spacing.g5, justifyContent: "center" }, children: /* @__PURE__ */ jsx("img", { src: Amazon, height: 175, width: "auto", alt: "amazon-reward" }) })
  ] });
};
export const ReferAndEarnModal = ({
  isOpen,
  setIsOpen,
  isAdminUser,
  user
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const [showSuccessState, setShowSuccessState] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: { name: null, email: null },
    enableReinitialize: true,
    validationSchema: ReferSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setShowSuccessState(false);
        setLoading(true);
        const data = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "email",
              value: values.email
            },
            {
              objectTypeId: "0-1",
              name: "company",
              value: values.name
            },
            {
              objectTypeId: "0-1",
              name: "referred_by_email_address",
              value: user.emailAddress
            },
            {
              objectTypeId: "0-1",
              name: "referred_by_name",
              value: `${user.firstName} ${user.lastName}`
            },
            {
              objectTypeId: "0-1",
              name: "referral_type",
              value: isAdminUser ? "Customer Admin" : "Customer Employee"
            }
          ],
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: "I agree to allow Zelt Company to store and process my personal data.",
              communications: [
                {
                  value: true,
                  subscriptionTypeId: 999,
                  text: "I agree to receive marketing communications from Example Company."
                }
              ]
            }
          }
        };
        const res = yield fetch(submissionUrl, {
          method: "POST",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" }
        });
        if (res.status === 200) {
          setShowSuccessState(true);
        } else {
          showMessage(polyglot.t("ReferAndEarnModal.errorMessages.refer"), "error");
          setShowSuccessState(false);
        }
      } catch (error) {
        showMessage(polyglot.t("ReferAndEarnModal.errorMessages.refer"), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const resetForm = () => {
    formik.resetForm();
    setShowSuccessState(false);
  };
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      sx: __spreadProps(__spreadValues({ pt: 0 }, spacing.px20), { pb: spacing.p30 }),
      onClose: () => {
        formik.resetForm();
        routerHistory.push(DASHBOARD_ROUTE);
        setShowSuccessState(false);
        setIsOpen(false);
      },
      children: showSuccessState ? /* @__PURE__ */ jsx(ReferralSuccessState, { resetForm }) : /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsx(Fade, { in: true, timeout: 1e3, children: /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadValues({
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between"
            }, spacing.pl20),
            children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadValues({}, themeFonts.headline1), spacing.mt40), children: polyglot.t("ReferAndEarnModal.referEarn") }),
              /* @__PURE__ */ jsx("img", { src: Stars, alt: "star-illustration", width: "auto", height: 160, style: { marginTop: "-20px" } })
            ]
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.px20), children: [
          /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(Reward, {}) }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues(__spreadValues({}, themeFonts.headline1), spacing.mt40), { color: themeColors.DarkGrey }), children: polyglot.t("ReferAndEarnModal.whoToRefer") }),
          /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m30 }), children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "name",
              label: polyglot.t("ReferAndEarnModal.name"),
              value: formik.values.name,
              type: "text",
              onChange: formik.handleChange,
              error: formik.touched.name && !!formik.errors.name,
              helperText: (_a = formik.touched.name && formik.errors.name) != null ? _a : " ",
              clearText: () => formik.setFieldValue("name", "")
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              name: "email",
              label: polyglot.t("ReferAndEarnModal.email"),
              value: formik.values.email,
              type: "text",
              onChange: formik.handleChange,
              error: formik.touched.email && !!formik.errors.email,
              helperText: (_b = formik.touched.email && formik.errors.email) != null ? _b : " ",
              clearText: () => formik.setFieldValue("email", "")
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("ReferAndEarnModal.referNow"),
              loading,
              fullWidth: true,
              sizeVariant: "medium",
              colorVariant: "primary"
            }
          ) })
        ] })
      ] }) }) }) })
    }
  );
};
