"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { useLocation } from "react-router-dom";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  PaymentSettingsNewTypeDrawer
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component";
import { DeletePaymentTypeDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-delete-drawer.component";
import {
  SettingsPageSection,
  DescriptionLine
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import {
  isExpenseCheck
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
export const PaymentTypeGeneralSettingsPage = ({
  paymentTypeSetting,
  refresh,
  refreshAllPaymentSettings
}) => {
  const { polyglot } = usePolyglot();
  const { pathname } = useLocation();
  const isExpense = isExpenseCheck(pathname);
  const type = isExpense ? "expense" : "invoices";
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editMode, setEditMode] = useState(void 0);
  const [isDelete, setIsDelete] = useState(false);
  const [typeForEdit, setTypeForEdit] = useState(void 0);
  const { payrolled, requireAttachment } = paymentTypeSetting;
  const handleEditForName = () => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setEditMode("name");
  };
  const handleEdit = (mode) => {
    setIsEditOpen(true);
    setTypeForEdit(paymentTypeSetting);
    setEditMode(mode);
  };
  return /* @__PURE__ */ jsxs(SettingsPageSection, { title: polyglot.t("General.general"), onEdit: handleEditForName, children: [
    /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.name"), value: paymentTypeSetting.name }),
    /* @__PURE__ */ jsx(
      DescriptionLine,
      {
        field: polyglot.t("PaymentSettingsPage.requiresAttachment"),
        value: polyglot.t(requireAttachment ? "General.yes" : "General.no")
      }
    ),
    isExpense && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("PaymentSettingsPage.paymentHeader") }),
        /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => handleEdit("payment"), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) }, "edit")
      ] }),
      /* @__PURE__ */ jsx(
        DescriptionLine,
        {
          field: polyglot.t("PaymentSettingsPage.subtitles.method"),
          value: polyglot.t(payrolled ? "PaymentSettingsPage.methodPayroll" : "PaymentSettingsPage.methodDirect")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", mt: spacing.mt20 }, children: /* @__PURE__ */ jsxs(Typography, { variant: "headline2", sx: { color: themeColors.DarkGrey }, children: [
      polyglot.t("PaymentSettingsPage.deleteHeader"),
      " ",
      type
    ] }) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
      polyglot.t("PaymentSettingsPage.deleteSubtitle", { settingType: type }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "text",
          onClick: () => {
            setIsDelete(true);
          },
          style: {
            margin: 0,
            padding: 0,
            color: themeColors.RedDark,
            display: "inline",
            textDecoration: "underline"
          },
          children: polyglot.t("PaymentSettingsPage.deleteHere")
        }
      )
    ] }) }),
    paymentTypeSetting && /* @__PURE__ */ jsx(
      DeletePaymentTypeDrawer,
      {
        isOpen: isDelete,
        setIsOpen: setIsDelete,
        paymentTypeSetting,
        refreshAllPaymentSettings
      }
    ),
    isEditOpen && /* @__PURE__ */ jsx(
      PaymentSettingsNewTypeDrawer,
      {
        isOpen: isEditOpen,
        setIsOpen: setIsEditOpen,
        refreshIndividualSetting: refresh,
        typeForEdit,
        editMode
      }
    )
  ] });
};
