"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, Link } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as Edit } from "@/images/side-bar-icons/Edit.svg";
import { ReactComponent as Reject } from "@/images/side-bar-icons/Reject.svg";
import { ReactComponent as Star } from "@/images/side-bar-icons/Star.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { USER_DETAILS_PROFILE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortDate } from "@/v2/components/table/table-sorting.util";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { convertMinutesToHHMM } from "@/v2/feature/absence/absence.util";
import { AttendanceAPI } from "@/v2/feature/attendance/attendance.api";
import { AttendanceStatus, ScheduleTrackingType } from "@/v2/feature/attendance/attendance.interface";
import { getAttendanceStatusIcon } from "@/v2/feature/attendance/attendance.util";
import { AttendanceEmptyState } from "@/v2/feature/attendance/company/components/empty-state/attendance-empty-state.component";
import { TrackTimeDrawer } from "@/v2/feature/attendance/company/components/track-time-drawer.component";
import { JobTypeBadgeList } from "@/v2/feature/attendance/components/attendance-job-type-badge-list.component";
import { BadgeLoaderHeader } from "@/v2/feature/attendance/components/badge-loader-header.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserTimesheetsDetail = ({
  selectedUserAttendances,
  selectedUser,
  loading,
  view,
  refresh,
  filterString
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const { getCachedUserById, loaded } = useCachedUsers();
  const user = selectedUser ? getCachedUserById(selectedUser) : void 0;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [mode, setMode] = useState(void 0);
  const [showMessage] = useMessage();
  const { getScopesContext, hasScopes } = useScopes();
  const generateDynamicColumns = (data) => {
    const uniqueNames = /* @__PURE__ */ new Set();
    data.forEach((userData) => {
      userData.loggedHoursByType.forEach((entry) => {
        uniqueNames.add(entry.name);
      });
    });
    return Array.from(uniqueNames).map((name) => ({
      id: name,
      header: () => name,
      accessorFn: (row) => row,
      enableSorting: false,
      maxSize: 40,
      cell: ({ row: { original } }) => {
        const entry = original.loggedHoursByType.find((e) => e.name === name);
        return entry ? /* @__PURE__ */ jsx("div", { style: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: /* @__PURE__ */ jsx("div", { children: convertMinutesToHHMM(entry.totalLength) }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
      }
    }));
  };
  const handleSelectedRequest = (request, mode2) => {
    setSelectedRequest(request);
    setMode(mode2);
    setIsDrawerOpen(true);
  };
  const handleRowClick = useCallback(({ original }) => {
    handleSelectedRequest(original, "view");
  }, []);
  const columns = useMemo(() => {
    const dateColumn = [
      {
        id: "logDate",
        header: () => polyglot.t("AttendanceDomain.date"),
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.logDate),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => original.logDate ? /* @__PURE__ */ jsx("div", { children: getDateString(original.logDate, false, "EEE, d MMM yyyy") }) : /* @__PURE__ */ jsx(EmptyCell, {})
      }
    ];
    const staticColumns = [
      {
        header: () => polyglot.t("AttendanceDomain.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => original.status ? /* @__PURE__ */ jsx("div", { children: getAttendanceStatusIcon(original.status, false, polyglot) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("AttendanceDomain.schedule"),
        accessorFn: (row) => row,
        id: "schedule",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a2;
          return ((_a2 = original == null ? void 0 : original.schedule) == null ? void 0 : _a2.name) ? /* @__PURE__ */ jsx("div", { children: original == null ? void 0 : original.schedule.name }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => " ",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const canManage = hasScopes(["absence:manager"], getScopesContext({ userId: original.userId }));
          return /* @__PURE__ */ jsx(
            RowActions,
            {
              row: original,
              canApproveOrReject: original.canApproveOrReject,
              canForceApproveOrReject: original.canForceApproveOrReject,
              canManage,
              onEdit: canManage ? () => handleSelectedRequest(original, "edit") : () => {
              },
              refresh
            }
          );
        }
      }
    ];
    return selectedUserAttendances ? [...dateColumn, ...generateDynamicColumns(selectedUserAttendances == null ? void 0 : selectedUserAttendances.data), ...staticColumns] : [...dateColumn, ...staticColumns];
  }, [selectedUserAttendances, polyglot, hasScopes, getScopesContext, refresh]);
  const isLoading = useMemo(() => Boolean(loading || !loaded), [loaded, loading]);
  const isLinkedToPayrun = useMemo(() => {
    return !!((selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) && selectedUserAttendances.data.length > 0 && selectedUserAttendances.data.some((entry) => entry.hasEntryLinkedToPayrun));
  }, [selectedUserAttendances]);
  const { canBulkApproveOrReject, canForceBulkApproveOrReject, canAutopopulate, canClearEntries } = useMemo(() => {
    var _a2, _b2;
    const statuses = (_b2 = (_a2 = selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) == null ? void 0 : _a2.map((entry) => entry.status).filter(Boolean)) != null ? _b2 : [];
    if (!(selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) || selectedUserAttendances.data.length === 0 || !statuses.length) {
      const isClockInSchedule = (selectedUserAttendances == null ? void 0 : selectedUserAttendances.header.scheduleTrackingType) === ScheduleTrackingType.ClockInClockOut;
      return {
        canBulkApproveOrReject: false,
        canForceBulkApproveOrReject: false,
        canAutopopulate: !isClockInSchedule,
        canClearEntries: false
      };
    }
    const submittedEntries = selectedUserAttendances.data.filter(
      (attendance) => attendance.status === AttendanceStatus.Submitted
    );
    const canBulkApproveOrReject2 = submittedEntries.length > 0 && submittedEntries.every((entry) => entry.canApproveOrReject);
    const canForceBulkApproveOrReject2 = submittedEntries.length > 0 && submittedEntries.every((entry) => entry.canForceApproveOrReject);
    return {
      canBulkApproveOrReject: canBulkApproveOrReject2,
      canForceBulkApproveOrReject: canForceBulkApproveOrReject2,
      canAutopopulate: false,
      canClearEntries: !isLinkedToPayrun
    };
  }, [selectedUserAttendances == null ? void 0 : selectedUserAttendances.data, selectedUserAttendances == null ? void 0 : selectedUserAttendances.header.scheduleTrackingType, isLinkedToPayrun]);
  const handleApproveAll = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        yield AttendanceAPI.approveUsersRequestByWeekOrMonth(selectedUser, filterString);
        yield refresh();
        showMessage(polyglot.t("AttendanceDomain.approvedAll"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotApproveAll", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, filterString, refresh, selectedUser, showMessage]);
  const handleRejectAll = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        yield AttendanceAPI.rejectUsersRequestByWeekOrMonth(selectedUser, filterString);
        yield refresh();
        showMessage(polyglot.t("AttendanceDomain.rejectedAll"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotRejectAll", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, filterString, refresh, selectedUser, showMessage]);
  const handleForceApproveAll = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        yield AttendanceAPI.forceApproveUsersRequestByWeekOrMonth(selectedUser, filterString);
        yield refresh();
        showMessage(polyglot.t("AttendanceDomain.approvedAll"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotApproveAll", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, filterString, refresh, selectedUser, showMessage]);
  const handleForceRejectAll = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        yield AttendanceAPI.forceRejectUsersRequestByWeekOrMonth(selectedUser, filterString);
        yield refresh();
        showMessage(polyglot.t("AttendanceDomain.rejectedAll"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotRejectAll", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, filterString, refresh, selectedUser, showMessage]);
  const handleDeleteAll = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        yield AttendanceAPI.deleteUserAttendancesByRange(selectedUser, filterString);
        yield refresh();
        showMessage(polyglot.t("AttendanceDomain.deletedAll"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.couldNotDeleteAll", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, filterString, refresh, selectedUser, showMessage]);
  const autopopulateFromSchedule = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedUser) {
        yield AttendanceAPI.autopopulateUserAttendancesByRange(selectedUser, filterString);
        yield refresh();
      }
    } catch (error) {
      showMessage(
        polyglot.t("AttendanceDomain.errors.somethingWentWrong", { nestError: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [polyglot, selectedUser, showMessage, refresh, filterString]);
  return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Box, { children: [
    selectedUserAttendances || isLoading ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        TimesheetsDetailHeader,
        {
          loading: isLoading,
          userId: selectedUser,
          user,
          selectedUserAttendances,
          handleApproveAll,
          handleRejectAll,
          handleForceApproveAll,
          handleForceRejectAll,
          handleDeleteAll,
          handleAutopopulateFromSchedule: autopopulateFromSchedule,
          canBulkApproveOrReject,
          canForceBulkApproveOrReject,
          canAutopopulate,
          canClearEntries
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mx20, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: (selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) ? [...selectedUserAttendances == null ? void 0 : selectedUserAttendances.data] : [],
          columnData: columns,
          hidePagination: true,
          loading: isLoading,
          rowClick: handleRowClick,
          maxUnpaginatedRows: 31,
          stickyHeader: true,
          stickyHeaderHeight: ((_a = selectedUserAttendances == null ? void 0 : selectedUserAttendances.header) == null ? void 0 : _a.jobs) && ((_b = selectedUserAttendances == null ? void 0 : selectedUserAttendances.header) == null ? void 0 : _b.jobs.length) > 0 ? ((_c = selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) == null ? void 0 : _c.length) && ((_d = selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) == null ? void 0 : _d.length) > 31 ? "calc(100vh - 218px)" : "calc(100vh - 168px)" : ((_e = selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) == null ? void 0 : _e.length) && ((_f = selectedUserAttendances == null ? void 0 : selectedUserAttendances.data) == null ? void 0 : _f.length) > 31 ? "calc(100vh - 132px)" : "calc(100vh - 82px)"
        }
      ) })
    ] }) : /* @__PURE__ */ jsx(AttendanceEmptyState, {}),
    /* @__PURE__ */ jsx(
      TrackTimeDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        userId: (_g = selectedRequest == null ? void 0 : selectedRequest.userId) != null ? _g : void 0,
        logDate: (_h = selectedRequest == null ? void 0 : selectedRequest.logDate) != null ? _h : void 0,
        refresh,
        onClose: () => {
          setIsDrawerOpen(false);
        },
        afterClose: () => {
          setSelectedRequest(null);
          setMode(void 0);
        },
        mode,
        view
      }
    )
  ] }) });
};
const TimesheetsDetailHeader = ({
  loading,
  userId,
  user,
  selectedUserAttendances,
  handleApproveAll,
  handleRejectAll,
  handleForceApproveAll,
  handleForceRejectAll,
  handleDeleteAll,
  handleAutopopulateFromSchedule,
  canBulkApproveOrReject,
  canForceBulkApproveOrReject,
  canAutopopulate,
  canClearEntries
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Box, { sx: spacing.pad20, children: loading ? /* @__PURE__ */ jsx(BadgeLoaderHeader, {}) : /* @__PURE__ */ jsxs(Stack, { rowGap: spacing.g20, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
      userId && user && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(UserAvatar, { userId, size: "s40" }, userId),
        /* @__PURE__ */ jsx(
          Link,
          {
            to: generatePath(USER_DETAILS_PROFILE_ROUTE, { userId }),
            style: { textDecoration: "none", color: "inherit" },
            children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t((_a = user.displayName) != null ? _a : "") })
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g8 }, children: [
        (canBulkApproveOrReject || canForceBulkApproveOrReject || canClearEntries || canAutopopulate) && /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              ...canBulkApproveOrReject ? [
                {
                  icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    return yield handleRejectAll();
                  }),
                  label: polyglot.t("AttendanceDomain.rejectAll")
                }
              ] : [],
              ...canForceBulkApproveOrReject ? [
                {
                  icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    return yield handleForceRejectAll();
                  }),
                  label: polyglot.t("General.forceRejectAll")
                }
              ] : [],
              ...canClearEntries ? [
                {
                  icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    return yield handleDeleteAll();
                  }),
                  label: polyglot.t("AttendanceDomain.clearAll")
                }
              ] : [],
              ...canAutopopulate ? [
                {
                  icon: /* @__PURE__ */ jsx(Star, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    return yield handleAutopopulateFromSchedule();
                  }),
                  label: polyglot.t("AttendanceDomain.autopopulate")
                }
              ] : []
            ],
            actionButtonDetails: {
              type: "iconButton",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: polyglot.t("General.actions"),
              icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
            }
          }
        ),
        (canBulkApproveOrReject || canForceBulkApproveOrReject) && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "primary",
            sizeVariant: "small",
            onClick: canBulkApproveOrReject ? handleApproveAll : handleForceApproveAll,
            children: canBulkApproveOrReject ? polyglot.t("AttendanceDomain.approveAll") : polyglot.t("General.forceApproveAll")
          }
        )
      ] })
    ] }),
    ((_b = selectedUserAttendances == null ? void 0 : selectedUserAttendances.header) == null ? void 0 : _b.jobs) && ((_c = selectedUserAttendances == null ? void 0 : selectedUserAttendances.header) == null ? void 0 : _c.jobs.length) > 0 && /* @__PURE__ */ jsx(JobTypeBadgeList, { attendanceJobs: ((_d = selectedUserAttendances == null ? void 0 : selectedUserAttendances.header) == null ? void 0 : _d.jobs) || [] })
  ] }) });
};
const RowActions = ({
  row,
  canManage,
  canApproveOrReject,
  canForceApproveOrReject,
  onEdit,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const approveRequest = useCallback(() => __async(void 0, null, function* () {
    if (!canApproveOrReject) return;
    try {
      yield AttendanceAPI.approveUserAttendanceEntriesByLogDate(row.userId, row.logDate, AttendanceStatus.Approved);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.approve"), "success");
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.approve", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [row, showMessage, refresh, polyglot, canApproveOrReject]);
  const forceApproveRequest = useCallback(() => __async(void 0, null, function* () {
    if (!canForceApproveOrReject) return;
    try {
      yield AttendanceAPI.forceApprovalForAttendanceByLogDate(row.userId, row.logDate, AttendanceStatus.Approved);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.approve"), "success");
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.approve", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [row, showMessage, refresh, polyglot, canForceApproveOrReject]);
  const forceRejectRequest = useCallback(() => __async(void 0, null, function* () {
    if (!canForceApproveOrReject) return;
    try {
      yield AttendanceAPI.forceApprovalForAttendanceByLogDate(row.userId, row.logDate, AttendanceStatus.Rejected);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.reject"), "success");
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.reject", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [row, showMessage, refresh, polyglot, canForceApproveOrReject]);
  const rejectRequest = useCallback(() => __async(void 0, null, function* () {
    if (!canApproveOrReject) return;
    try {
      yield AttendanceAPI.approveUserAttendanceEntriesByLogDate(row.userId, row.logDate, AttendanceStatus.Rejected);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.reject"), "success");
      yield refresh();
    } catch (error) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.reject", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [row, showMessage, refresh, polyglot, canApproveOrReject]);
  const deleteRequest = useCallback(() => __async(void 0, null, function* () {
    if (!canManage) return;
    try {
      yield AttendanceAPI.deleteUserAttendanceEntriesByLogDate(row.userId, row.logDate);
      showMessage(polyglot.t("ViewTrackTimeDrawerContent.successMessages.delete"), "success");
      yield refresh();
    } catch (e) {
      showMessage(
        polyglot.t("ViewTrackTimeDrawerContent.errorMessages.delete", { errorMessage: nestErrorMessage(e) }),
        "error"
      );
    }
  }), [showMessage, row, refresh, polyglot, canManage]);
  const actionButtonTitle = useMemo(() => {
    if (row.hasEntryLinkedToPayrun) {
      return polyglot.t("ViewTrackTimeDrawerContent.attendanceLinkedToPayrun");
    }
    return polyglot.t("General.actions");
  }, [row.hasEntryLinkedToPayrun, polyglot]);
  return /* @__PURE__ */ jsx(Box, { onClick: (e) => e.stopPropagation(), sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options: [
        {
          icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
          handler: onEdit,
          label: polyglot.t("General.edit"),
          hidden: !canManage || !onEdit
        },
        {
          icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
          handler: approveRequest,
          label: polyglot.t("General.approve"),
          hidden: !canApproveOrReject
        },
        {
          icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
          handler: forceApproveRequest,
          label: polyglot.t("General.forceApprove"),
          hidden: !canForceApproveOrReject
        },
        {
          icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
          handler: rejectRequest,
          label: polyglot.t("General.reject"),
          hidden: !canApproveOrReject
        },
        {
          icon: /* @__PURE__ */ jsx(Reject, __spreadValues({}, iconSize)),
          handler: forceRejectRequest,
          label: polyglot.t("General.forceReject"),
          hidden: !canForceApproveOrReject
        },
        {
          icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
          handler: deleteRequest,
          label: polyglot.t("General.delete"),
          hidden: !canManage
        }
      ],
      actionButtonDetails: {
        type: "iconButton",
        colorVariant: "secondary",
        sizeVariant: "small",
        title: actionButtonTitle,
        icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
      },
      disabled: row.hasEntryLinkedToPayrun
    }
  ) });
};
