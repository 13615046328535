"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { TemplateAPI } from "@/api-client/templates.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoTransparent.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from "@/lib/routes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { ContractModal } from "@/v2/feature/templates/components/contract-modal.component";
import { MissingTemplateFieldModal } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { RenameTemplateModal } from "@/v2/feature/templates/components/rename-template-modal.component";
import { TemplateModal } from "@/v2/feature/templates/components/template-modal.component";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { OnboardingEndpoints } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { localFormat } from "@/v2/util/date-format.util";
export const TemplatesPage = () => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [templates, setTemplates] = useState([]);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [openRenameTemplateModal, setOpenRenameTemplateModal] = useState(false);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [missingFields, setMissingFields] = useState();
  const [contractRecipientUserId, setContractRecipientUserId] = useState();
  const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [loading, setLoading] = useState(true);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const { data: contractTemplateCurrentlyUsedInOnboarding } = useApiClient(
    selectedTemplate ? OnboardingEndpoints.isContractTemplateCurrentlyUsedInOnboarding(selectedTemplate == null ? void 0 : selectedTemplate.id) : { url: void 0 },
    {
      suspense: false
    }
  );
  const [_state] = useContext(GlobalContext);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const routerHistory = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const fetchTemplates = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const templates2 = yield TemplateAPI.getTemplates(params);
        setTemplates(templates2 || []);
        setLoading(false);
      } catch (error) {
        showMessage(
          polyglot.t("TemplatesPage.errorMessages.fetch", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setLoading(false);
      }
    }),
    [polyglot, showMessage]
  );
  const deleteTemplate = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield TemplateAPI.deleteTemplate(params);
        showMessage(polyglot.t("TemplatesPage.successMessages.delete"), "success");
        yield fetchTemplates();
        setIsRemovalModalOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("TemplatesPage.errorMessages.delete", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setLoading(false);
      }
    }),
    [polyglot, showMessage, fetchTemplates]
  );
  const duplicateTemplate = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        yield TemplateAPI.duplicateTemplate({ id: params.templateId });
        showMessage(polyglot.t("TemplatesPage.successMessages.duplicate"), "success");
        yield fetchTemplates();
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t("TemplatesPage.errorMessages.duplicate", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      }
    }),
    [polyglot, showMessage, fetchTemplates]
  );
  const onMissingField = (params) => {
    const { contractRecipientUserId: contractRecipientUserId2, templateVerificationResult } = params;
    setContractRecipientUserId(contractRecipientUserId2);
    setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
    setMissingFields(templateVerificationResult.fields);
    setOpenMissingFieldContractModal(true);
  };
  const refreshMissingFieldsForTemplate = useCallback(
    (templateId, contractRecipientUserId2, companySignatoryUserId) => __async(void 0, null, function* () {
      try {
        const templateResult = yield TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId: contractRecipientUserId2,
          companySignatoryUserId
        });
        setMissingFields(templateResult.fields);
      } catch (error) {
        showMessage(
          polyglot.t("TemplatesPage.errorMessages.verify", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [polyglot, showMessage]
  );
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const documentTypes2 = yield DocumentAPI.listDocumentTypes();
        setDocumentTypes(documentTypes2);
        yield fetchTemplates({ searchQuery });
      } catch (error) {
        showMessage(
          polyglot.t("TemplatesPage.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }))();
  }, [polyglot, showMessage, fetchTemplates, searchQuery]);
  const CONTRACT_IN_USE_CONFIRMATION_MESSAGE = useMemo(() => {
    const defaultMessage = polyglot.t("DocumentTemplateConfirmRemove.normalConfirmMessage");
    if (!contractTemplateCurrentlyUsedInOnboarding) {
      return defaultMessage;
    } else if (contractTemplateCurrentlyUsedInOnboarding) {
      return polyglot.t("DocumentTemplateConfirmRemove.templateUsedInOnboardingConfirmMessage");
    }
    return defaultMessage;
  }, [contractTemplateCurrentlyUsedInOnboarding, polyglot]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, sx: __spreadValues({}, spacing.pt20), children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: "Templates",
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => {
                setOpenTemplateModal(true);
              },
              children: polyglot.t("TemplatesPage.newTemplate")
            }
          )
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchQuery,
                        handleChange: (e) => {
                          var _a, _b;
                          return setSearchQuery((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                        },
                        placeholder: polyglot.t("TemplatesPage.search")
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: templates.map((item) => {
                      var _a, _b;
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: item.name,
                          loading,
                          boxSx: responsiveCardStyle,
                          contentItemsSets: [
                            {
                              name: `${(_b = (_a = documentTypes.find((d) => d.value === item.type)) == null ? void 0 : _a.label) != null ? _b : ""}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            },
                            ...item.createdBy ? [
                              {
                                type: ContentItemType.component,
                                contentComponent: /* @__PURE__ */ jsx(
                                  ChipComponent,
                                  {
                                    name: /* @__PURE__ */ jsx(UserCell, { userId: item.createdBy, nameColor: "DarkGrey" }),
                                    backgroundColor: "white",
                                    textColor: "DarkGrey",
                                    textVariant: "paragraph",
                                    border: "middle"
                                  }
                                )
                              }
                            ] : [],
                            {
                              name: `${localFormat("dd MMM yyyy", new Date(item.createdAt))}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ],
                          boxAction: () => {
                            routerHistory.push(
                              generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, {
                                templateId: item.id
                              }),
                              { returnPath: generatePath(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE) }
                            );
                          },
                          advanceActionOptions: [
                            {
                              icon: /* @__PURE__ */ jsx(InfoIcon, __spreadValues({}, iconSize)),
                              handler: () => {
                                setSelectedTemplate(item);
                                setOpenRenameTemplateModal(true);
                              },
                              label: polyglot.t("TemplatesPage.rename"),
                              disabled: false
                            },
                            {
                              icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
                              handler: () => {
                                duplicateTemplate({ templateId: item.id });
                              },
                              label: polyglot.t("TemplatesPage.duplicate"),
                              disabled: false
                            },
                            {
                              icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                              handler: () => {
                                setSelectedTemplate(item);
                                setIsRemovalModalOpen(true);
                              },
                              label: polyglot.t("TemplatesPage.delete"),
                              disabled: false
                            }
                          ]
                        },
                        item.name
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    openTemplateModal && /* @__PURE__ */ jsx(TemplateModal, { open: openTemplateModal, setOpen: setOpenTemplateModal, documentTypes }),
    selectedTemplate && openContractModal && /* @__PURE__ */ jsx(
      ContractModal,
      {
        open: openContractModal,
        setOpen: setOpenContractModal,
        contractTemplate: selectedTemplate,
        onMissingField
      }
    ),
    selectedTemplate && missingFields && contractRecipientUserId && openMissingFieldContractModal && /* @__PURE__ */ jsx(
      MissingTemplateFieldModal,
      {
        open: openMissingFieldContractModal,
        setOpen: setOpenMissingFieldContractModal,
        templateId: selectedTemplate.id,
        missingFields,
        contractRecipientId: contractRecipientUserId,
        companySignatoryUserId: contractCompanySignatoryUserId,
        refreshMissingFields: refreshMissingFieldsForTemplate
      }
    ),
    selectedTemplate && openRenameTemplateModal && /* @__PURE__ */ jsx(
      RenameTemplateModal,
      {
        documentTypes,
        open: openRenameTemplateModal,
        setOpen: setOpenRenameTemplateModal,
        template: selectedTemplate,
        onUpdate: fetchTemplates
      }
    ),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("DocumentTemplateConfirmRemove.title"),
        description: CONTRACT_IN_USE_CONFIRMATION_MESSAGE,
        isOpen: isRemovalModalOpen,
        setIsOpen: setIsRemovalModalOpen,
        loading,
        action: () => __async(void 0, null, function* () {
          if (selectedTemplate == null ? void 0 : selectedTemplate.id) yield deleteTemplate({ templateId: selectedTemplate == null ? void 0 : selectedTemplate.id });
        })
      }
    )
  ] });
};
