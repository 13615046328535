"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { themeColors } from "@/v2/styles/colors.styles";
export const SuperAdminAddStockDeviceDrawer = ({
  isOpen,
  setIsOpen,
  deviceModels,
  refresh
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(SuperAdminAddDeviceDrawerContent, { deviceModels, refresh, close: () => setIsOpen(false) })
  }
) });
export const SuperAdminAddDeviceDrawerContent = ({
  deviceModels,
  refresh,
  close
}) => {
  var _a, _b;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const deviceModelOptions = deviceModels.map((deviceModel) => ({
    value: deviceModel.id,
    label: `Model ID: ${deviceModel.id} | ${deviceModel.modelName}`
  }));
  const formik = useFormik({
    initialValues: {
      deviceModelId: void 0,
      quantity: 1
    },
    validationSchema: yup.object({
      deviceModelId: yup.number().typeError("You must select a Device Model").required("Device Model is required"),
      quantity: yup.number().typeError("You must specify a number").required("Quantity is required")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      return addDeviceInStock(values);
    })
  });
  const addDeviceInStock = (_0) => __async(void 0, [_0], function* ({ deviceModelId, quantity }) {
    if (!deviceModelId || !quantity) {
      showMessage("Please select a device model and quantity.", "error");
      return;
    }
    try {
      setLoading(true);
      yield DeviceAPI.addDeviceInStockAsSuperadmin(deviceModelId, Number(quantity));
      showMessage("Device successfully added.", "success");
      yield refresh();
      close();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Add to stock" }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "deviceModelId",
        label: "Select Model",
        options: deviceModelOptions,
        value: (_a = formik.values.deviceModelId) != null ? _a : "",
        onChange: formik.handleChange,
        compareValue: (_b = formik.values.deviceModelId) != null ? _b : "",
        error: !!formik.errors.deviceModelId && formik.touched.deviceModelId,
        helperText: formik.errors.deviceModelId && formik.touched.deviceModelId
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Quantity",
        name: "quantity",
        value: formik.values.quantity,
        onChange: formik.handleChange,
        error: formik.touched.quantity && Boolean(formik.errors.quantity),
        helperText: formik.touched.quantity && formik.errors.quantity,
        size: "small",
        inputProps: { inputmode: "numeric", pattern: "[0-9]*" },
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Add device in stock",
        loading,
        disabled: !formik.values.deviceModelId || !formik.values.quantity,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
