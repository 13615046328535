"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const JumpcloudApiKeyInstructions = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", sx: { mb: spacing.m5 }, children: "a) Log into your jumpcloud.com account." }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", sx: { mb: spacing.m5 }, children: "b) Click on your avatar (picture icon) in the top right corner." }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", sx: { mb: spacing.m5 }, children: "c) Select the My API key option from the resulting menu (this requires you to have admin permissions)." }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", sx: { mb: spacing.m5 }, children: "d) If a token is already generated, clicking the same will copy it, if not, click the Generate a new API key button" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", sx: { mb: spacing.m5 }, children: "e) Once copied paste the same token above." })
  ] });
};
