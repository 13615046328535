"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { OauthClientEndpoints } from "@/api-client/oauth-client.api";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ReactComponent as WaitingEmpty } from "@/images/side-bar-icons/WaitingEmpty.svg";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PublishRequestsApproveRejectDrawerForm } from "@/v2/feature/security/security-settings/features/developer-hub/components/publish-requests-approve-reject-drawer.page";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { toTitleCase } from "@/v2/util/string.util";
export const PublishRequestsBackofficePage = () => {
  const {
    data: publishRequests,
    isLoading: loadingRequests,
    mutate: refresh
  } = useApiClient(OauthClientEndpoints.getPublishRequestsForAllCompanies(), { suspense: false });
  const [searchInput, setSearchInput] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const filteredRequests = useMemo(() => {
    if (!publishRequests) return [];
    if (!searchInput) return publishRequests;
    const search = searchInput.toLowerCase();
    return publishRequests.filter((request) => {
      var _a, _b, _c, _d, _e, _f;
      const requestIdMatch = request.id.toLowerCase().includes(search);
      const clientIdMatch = request.clientId.toLowerCase().includes(search);
      const companyNameMatch = (_b = (_a = request.company) == null ? void 0 : _a.name) == null ? void 0 : _b.toLowerCase().includes(search);
      const companyIdMatch = (_d = (_c = request.company) == null ? void 0 : _c.companyId) == null ? void 0 : _d.toString().includes(search);
      const oauthClientNameMatch = (_f = (_e = request.oauthClient) == null ? void 0 : _e.name) == null ? void 0 : _f.toLowerCase().includes(search);
      return requestIdMatch || clientIdMatch || companyNameMatch || companyIdMatch || oauthClientNameMatch;
    });
  }, [searchInput, publishRequests]);
  const columnData = useMemo(
    () => [
      {
        header: () => "Client ID",
        accessorFn: (row) => {
          var _a, _b;
          return {
            name: (_a = row.oauthClient) == null ? void 0 : _a.name,
            clientId: (_b = row.oauthClient) == null ? void 0 : _b.clientId
          };
        },
        id: "clientId",
        enableSorting: true,
        cell: (info) => {
          const value = info.getValue();
          return /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: value.name }),
            /* @__PURE__ */ jsx("br", {}),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: value.clientId })
          ] });
        }
      },
      {
        header: () => "Company",
        accessorFn: (row) => {
          var _a;
          return {
            name: (_a = row.company) == null ? void 0 : _a.name,
            companyId: row.companyId
          };
        },
        id: "companyId",
        enableSorting: true,
        cell: (info) => {
          const value = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: `${value.name} (${value.companyId})` }) });
        }
      },
      // {
      //   header: () => 'Requested Scopes',
      //   accessorFn: (row) => row,
      //   id: 'requestedScopes',
      //   enableSorting: false,
      //   cell: ({ row: { original } }) => (
      //     <List dense>
      //       {original.requestedScopes.map((scope) => (
      //         <ListItem key={scope} sx={{ pl: 0 }}>
      //           <ListItemText primary={<Typography variant="paragraph">{scope}</Typography>} />
      //         </ListItem>
      //       ))}
      //     </List>
      //   ),
      // },
      {
        header: () => "Scopes / Reasons",
        accessorFn: (row) => row.scopeReasons,
        id: "scopeReasons",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Fragment, { children: original.scopeReasons ? /* @__PURE__ */ jsx(List, { dense: true, children: Object.entries(original.scopeReasons).map(([scope, reason]) => /* @__PURE__ */ jsx(ListItem, { sx: { pl: 0 }, children: /* @__PURE__ */ jsx(
          ListItemText,
          {
            primary: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: scope }),
            secondary: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: reason })
          }
        ) }, scope)) }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "Reviewed by",
        accessorFn: (row) => row.reviewedBy,
        id: "reviewedBy",
        enableSorting: true,
        cell: (info) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: info.getValue() }) })
      },
      {
        header: () => "Reviewed at",
        accessorFn: (row) => row.reviewedAt,
        id: "reviewedAt",
        enableSorting: false,
        cell: (info) => /* @__PURE__ */ jsx(Box, { children: info.getValue() ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(info.getValue()).toUTCString() }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "Notes",
        accessorFn: (row) => row.reviewNotes,
        id: "reviewNotes",
        enableSorting: false,
        cell: (info) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: info.getValue() }) })
      },
      {
        header: () => "Created At",
        accessorFn: (row) => row.createdAt,
        id: "createdAt",
        enableSorting: false,
        cell: (info) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(info.getValue()).toUTCString() }) })
      },
      {
        header: () => "Status",
        accessorFn: (row) => row.status,
        id: "status",
        enableSorting: true,
        cell: (info) => {
          const status = info.getValue();
          const getStatusIcon = (status2) => {
            switch (status2) {
              case "pending":
                return /* @__PURE__ */ jsx(WaitingEmpty, {});
              case "approved":
                return /* @__PURE__ */ jsx(OkGreen, { fill: themeColors.Green });
              case "rejected":
                return /* @__PURE__ */ jsx(Rejected, { fill: themeColors.Red });
              default:
                return null;
            }
          };
          return /* @__PURE__ */ jsxs(Box, { display: "flex", alignItems: "center", children: [
            getStatusIcon(status),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { ml: 1 }, children: toTitleCase(status || "") })
          ] });
        }
      }
    ],
    []
  );
  const handleRowClick = useCallback((row) => {
    if (row.original.status !== "pending") return;
    setSelectedRequest(row.original);
    setIsDrawerOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Publish requests for Partner API" }), showAction: true }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loadingRequests, sx: {}, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        TableSearch,
        {
          placeholder: "Search",
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          },
          style: { width: "250px", minWidth: "250px" }
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: "10px" }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredRequests,
          columnData,
          loading: loadingRequests,
          hidePagination: true,
          rowClick: handleRowClick
        }
      ) }),
      selectedRequest && /* @__PURE__ */ jsx(DrawerModal, { isOpen: isDrawerOpen, setIsOpen: setIsDrawerOpen, children: /* @__PURE__ */ jsx(
        PublishRequestsApproveRejectDrawerForm,
        {
          initialValues: selectedRequest,
          onClose: () => {
            setIsDrawerOpen(false);
            refresh();
          }
        }
      ) })
    ] })
  ] });
};
