"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { getAbsencePolicyPayrollValidationSchema } from "@v2/feature/absence/company/policies/pages/absence-policy.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsenceAPI } from "@/v2/feature/absence/absence.api";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const PolicyPayrollEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { data: entityPaycodes } = useApiClient(PayrollEndpoints.getEntityPaycodes(absencePolicy.companyId), {
    suspense: false
  });
  const payCodeOptions = useMemo(
    () => {
      var _a2;
      return (_a2 = entityPaycodes == null ? void 0 : entityPaycodes.flatMap((entity, index) => [
        {
          value: `group-${index}`,
          label: entity.entityName,
          disabled: true
        },
        ...entity.paycodes.map((code) => ({
          value: code.code,
          label: `${code.code} - ${code.name}`
        }))
      ])) != null ? _a2 : [];
    },
    [entityPaycodes]
  );
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const updatedValues = __spreadProps(__spreadValues({}, values), {
          showOnPayslip: values.isPaid ? values.showOnPayslip : false,
          payCode: values.isPaid && values.showOnPayslip ? values.payCode : "",
          payCodeMultiplier: values.isPaid && values.showOnPayslip && values.payCodeMultiplier ? Number(values.payCodeMultiplier) : 1
        });
        yield AbsenceAPI.updateAbsencePolicyPayroll(absencePolicy.id, {
          isPaid: updatedValues.isPaid,
          showOnPayslip: updatedValues.showOnPayslip,
          payCode: updatedValues.payCode,
          payCodeMultiplier: updatedValues.payCodeMultiplier
        });
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      isPaid: absencePolicy.isPaid,
      // If policy is unpaid, force showOnPayslip to false regardless of DB value
      showOnPayslip: absencePolicy.isPaid ? absencePolicy.showOnPayslip : false,
      payCode: (_a = absencePolicy.payCode) != null ? _a : "",
      payCodeMultiplier: (_b = absencePolicy.payCodeMultiplier) != null ? _b : 1
    },
    validationSchema: getAbsencePolicyPayrollValidationSchema(polyglot),
    onSubmit
  });
  useEffect(() => {
    if (!formik.values.isPaid) {
      formik.setFieldValue("showOnPayslip", false);
      formik.setFieldValue("payCode", "");
      formik.setFieldValue("payCodeMultiplier", 1);
    }
  }, [formik.values.isPaid]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsencePolicyRouter.payroll") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "isPaid",
        label: polyglot.t("PayrollModule.payImplication"),
        value: formik.values.isPaid ? "paid" : "unpaid",
        onChange: (e) => {
          const isPaid = e.target.value === "paid";
          formik.setFieldValue("isPaid", isPaid);
        },
        options: [
          { value: "paid", label: polyglot.t("PayrollModule.paid") },
          { value: "unpaid", label: polyglot.t("PayrollModule.unpaid") }
        ]
      }
    ),
    formik.values.isPaid && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", mt: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("PayrollModule.showOnPayslip") }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: formik.values.showOnPayslip,
          onChange: (_e, checked) => {
            formik.setFieldValue("showOnPayslip", checked);
            if (!checked) {
              formik.setFieldValue("payCode", "");
              formik.setFieldValue("payCodeMultiplier", 1);
            }
          }
        }
      )
    ] }),
    formik.values.isPaid && formik.values.showOnPayslip && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "payCode",
          label: polyglot.t("PayItemModule.payCode"),
          value: formik.values.payCode,
          onChange: (e) => formik.setFieldValue("payCode", e.target.value),
          options: payCodeOptions
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "payCodeMultiplier",
          label: polyglot.t("PayItemModule.payCodeMultiplier"),
          value: formik.values.payCodeMultiplier,
          onChange: formik.handleChange,
          error: formik.touched.payCodeMultiplier && !!formik.errors.payCodeMultiplier,
          helperText: (_c = formik.touched.payCodeMultiplier && formik.errors.payCodeMultiplier) != null ? _c : " ",
          endAdornment: "none"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] }) }) });
};
