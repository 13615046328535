"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { ManageInsurancePolicyDrawer } from "@v2/feature/super-admin/features/super-admin-insurance/components/manage-insurance-policy-drawer.component";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Document } from "@/images/side-bar-icons/Document.svg";
import { ReactComponent as EnvelopeSimple } from "@/images/side-bar-icons/EnvelopeSimple.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
export const SuperAdminInsuranceQuotesPage = ({
  companies,
  users,
  pageConfig
}) => {
  var _a, _b;
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [quoteId, setQuoteId] = useState(null);
  const [showMessage] = useMessage();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsLoading(true);
      const quotes2 = yield InsuranceAPI.getAllInsuranceQuotesAsSuperAdmin();
      setQuotes(quotes2);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get insurance quotes. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Company name",
        accessorFn: (row) => row,
        id: "companyId",
        cell: (info) => {
          const quote = info.getValue();
          const company = companies.find((company2) => quote.companyId === company2.companyId);
          return company ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: company.name }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Policy type",
        accessorFn: (row) => row,
        id: "type",
        cell: (info) => {
          const quote = info.getValue();
          return quote.type ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: quote.type }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Number of members",
        accessorFn: (row) => row,
        id: "noOfMembers",
        cell: (info) => {
          const quote = info.getValue();
          return quote.noOfMembers ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: quote.noOfMembers }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Date requested",
        accessorFn: (row) => row,
        id: "dateRequested",
        cell: (info) => {
          const quote = info.getValue();
          return quote.dateRequested ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: new Date(quote.dateRequested).toLocaleDateString("en-GB") }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Requested by",
        accessorFn: (row) => row,
        id: "requestedBy",
        cell: (info) => {
          const quote = info.getValue();
          const user = users.find((user2) => user2.userId === quote.requestedBy);
          return user ? /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }),
              children: `${user.firstName} ${user.lastName}`
            }
          ) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        cell: (info) => {
          const quote = info.getValue();
          return quote.status ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: quote.status }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 180,
        minSize: 120
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        cell: (info) => {
          const quote = info.getValue();
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: [
                {
                  icon: /* @__PURE__ */ jsx(EnvelopeSimple, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    try {
                      setIsLoading(true);
                      yield InsuranceAPI.createPMI(quote.id);
                      showMessage("Full PMI created successfully.", "success");
                      yield refresh();
                    } catch (error) {
                      console.error(error);
                      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                    } finally {
                      setIsLoading(false);
                    }
                  }),
                  label: "Send full PMI"
                },
                {
                  icon: /* @__PURE__ */ jsx(Document, __spreadValues({}, iconSize)),
                  handler: () => {
                    setQuoteId(quote.id);
                    setIsDrawerOpen(true);
                  },
                  label: "Create policy",
                  hidden: !!quote.policyId
                },
                {
                  icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                  handler: () => __async(void 0, null, function* () {
                    try {
                      yield InsuranceAPI.deleteInsuranceQuoteByIdAsSuperAdmin(quote.id);
                      yield refresh();
                    } catch (error) {
                      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                    }
                  }),
                  label: "Delete"
                }
              ],
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
              }
            }
          ) });
        },
        maxSize: 100,
        minSize: 80
      }
    ];
  }, [companies, users, refresh, showMessage]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Health Insurance" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoading, secondLevel: true, children: [
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(BasicTable, { rowData: quotes, columnData }) }),
      quoteId && isDrawerOpen && /* @__PURE__ */ jsx(
        ManageInsurancePolicyDrawer,
        {
          quoteId,
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          refresh,
          onSave: () => setQuoteId(null)
        }
      )
    ] })
  ] });
};
