"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import useMessage from "@/hooks/notification.hook";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { ReviewSectionBankAPI } from "@/v2/feature/growth/reviews/api-client/review-section-bank.api";
import { SectionModalContent } from "@/v2/feature/growth/reviews/features/components/section-modal-content.component";
export const ReviewTemplateSectionModal = ({
  isOpen,
  setIsOpen,
  onClose,
  section,
  refresh,
  templateId
}) => {
  const [showMessage] = useMessage();
  const onSubmit = (values) => __async(void 0, null, function* () {
    if (section) {
      const updatedValues = { id: section.id, name: values.name, templateId, description: values.description };
      yield ReviewSectionBankAPI.updateReviewSectionInBank(updatedValues);
      showMessage("Section updated successfully", "success");
    } else {
      const updatedValues = { name: values.name, templateId, description: values.description };
      yield ReviewSectionBankAPI.createReviewSectionInBank(updatedValues);
      showMessage("Section created successfully", "success");
    }
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setIsOpen(false);
      },
      children: /* @__PURE__ */ jsx(
        SectionModalContent,
        {
          onClose,
          section,
          refresh,
          onSubmit
        }
      )
    }
  );
};
