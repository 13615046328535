"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DepWizardHelper } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-wizard-helper";
import {
  DepTokenStatus
} from "@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto";
import {
  conditionalSx,
  definitionSx,
  drawerContentSx
} from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_DEVICES_DEP_ROUTE } from "@/lib/routes";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { InHouseMdmAPI } from "@/v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CustomDepActionsDrawer = ({
  mdmServerName,
  depAccountDetailsPar,
  onClose,
  onRefresh
}) => {
  var _a, _b, _c;
  const routerHistory = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [depAccountDetails] = useState(depAccountDetailsPar);
  const [showMessage] = useMessage();
  const [isDeleteDepTokenDrawerOpen, setIsDeleteDepTokenDrawerOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [isAutoSync, setAutoSync] = useState((_a = depAccountDetails == null ? void 0 : depAccountDetails.isAutoSyncEnabled) != null ? _a : false);
  const handleRenew = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      routerHistory.push(SETTINGS_DEVICES_DEP_ROUTE);
      yield onClose();
    } catch (error) {
      showMessage(`Could not renew auto enrolment token.${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(Fragment, { children: isDeleteDepTokenDrawerOpen ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Disconnect Auto enrolment" }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: spacing.m10, display: "flex", flexDirection: "column" }), children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        "If you disconnect token from the MDM, Zelt can no longer access your Apple Business Manager account for Auto-Enrolment. Please note:",
        /* @__PURE__ */ jsxs("ul", { children: [
          /* @__PURE__ */ jsx("li", { children: "Devices that are presently enroled will not be impacted" }),
          /* @__PURE__ */ jsx("li", { children: "Devices that are not enroled will not be enrolled automatically" })
        ] })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "confirm",
          label: "",
          placeholder: "Type \u201CConfirm\u201D here to disconnect device",
          fullWidth: true,
          value: confirmationText,
          type: "text",
          onChange: (event) => setConfirmationText(event.target.value),
          clearText: () => setConfirmationText("")
        }
      ) })
    ] }) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Confirm",
        fullWidth: true,
        loading: isLoading,
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: confirmationText !== "Confirm",
        onClick: () => __async(void 0, null, function* () {
          try {
            if (mdmServerName) {
              setLoading(true);
              yield InHouseMdmAPI.deleteDepPublicKey(mdmServerName);
            }
            showMessage("Action launched successfully", "success");
            setIsDeleteDepTokenDrawerOpen(false);
            onClose();
          } catch (error) {
            showMessage("The launch of the action failed", "error");
          } finally {
            setLoading(false);
          }
        })
      }
    ) })
  ] }) : /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Auto enrolment" }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: spacing.m15, display: "flex", flexDirection: "column" }), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey }, children: "Organisation" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { color: themeColors.black }, children: (_b = depAccountDetails == null ? void 0 : depAccountDetails.org_name) != null ? _b : "N/A" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey, mt: spacing.m10 }, children: "MDM Server name" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { color: themeColors.black }, children: (_c = depAccountDetails == null ? void 0 : depAccountDetails.server_name) != null ? _c : "N/A" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey, mt: spacing.m10 }, children: "Renew by" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { color: themeColors.black }, children: (depAccountDetails == null ? void 0 : depAccountDetails.access_token_expiry) ? DepWizardHelper.formatDate(depAccountDetails == null ? void 0 : depAccountDetails.access_token_expiry) : "N/A" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey, mt: spacing.m10 }, children: "Connection status" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { color: themeColors.black }, children: (depAccountDetails == null ? void 0 : depAccountDetails.status) === DepTokenStatus.Active ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        " ",
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Active" })
      ] }) : (depAccountDetails == null ? void 0 : depAccountDetails.status) === DepTokenStatus.Inactive ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Inactive" })
      ] }) : "Unknown" })
    ] }) }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "ABM devices" }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: spacing.m15, display: "flex", flexDirection: "column" }), children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey, mt: spacing.m10 }, children: "Here, you can synchronize your inventory with Apple Business Manager manually, ensuring that you have the latest devices assigned to your token. All new devices will be automatically created in the 'inventory' subsection of devices, keeping your device list organized and up to date" }) }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsxs(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "small",
          disabled: isLoading,
          onClick: () => __async(void 0, null, function* () {
            try {
              setLoading(true);
              yield InHouseMdmAPI.syncDepDevices(mdmServerName);
              showMessage("Action launched successfully", "success");
            } catch (error) {
              showMessage("The launch of the action failed", "error");
            } finally {
              setLoading(false);
            }
          }),
          children: [
            /* @__PURE__ */ jsx(RefreshIcon, { sx: __spreadValues({}, iconSize) }),
            "Sync with ABM"
          ]
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: conditionalSx, children: /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "autoSync",
          label: "Auto sync with ABM",
          checked: isAutoSync,
          onChange: (e, checked) => __async(void 0, null, function* () {
            try {
              setLoading(true);
              setAutoSync(checked);
              yield InHouseMdmAPI.setAutoSyncDepDevice(mdmServerName, checked);
              yield onRefresh();
              showMessage("Action launched successfully", "success");
            } catch (error) {
              showMessage("The launch of the action failed", "error");
            } finally {
              setLoading(false);
            }
          })
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Renew",
          fullWidth: true,
          loading: isLoading,
          sizeVariant: "medium",
          colorVariant: "primary",
          onClick: () => handleRenew()
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Disconnect",
          fullWidth: true,
          loading: isLoading,
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => setIsDeleteDepTokenDrawerOpen(true)
        }
      )
    ] })
  ] }) }) });
};
