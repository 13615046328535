"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { GrowthChip } from "@v2/feature/growth/shared/components/growth-chip.component";
import { generatePath, Link } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "@/images/icons/calendar.svg";
import { GROWTH_COMPANY_GOAL_ROUTE, GROWTH_ME_GOAL_ROUTE } from "@/lib/routes";
import { GrowthFactorsGroup } from "@/v2/feature/growth/goals/components/growth-factors-group.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import { OwnerChip } from "@/v2/feature/growth/goals/components/owner-chip.component";
import { StatusChip } from "@/v2/feature/growth/goals/components/status-chip.component";
import { useGoalsReach } from "@/v2/feature/growth/goals/hooks/use-goals-reach.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { LocalDate } from "@/v2/util/local-date";
export const GoalListItem = ({
  goal,
  progressBar,
  showDescription,
  hoverState,
  withLink,
  endSection,
  sx,
  showTitle = true
}) => {
  const reach = useGoalsReach();
  const { polyglot } = usePolyglot();
  const goalListItem = useMemo(() => {
    const goalPageLink = generatePath(reach === ReachType.Company ? GROWTH_COMPANY_GOAL_ROUTE : GROWTH_ME_GOAL_ROUTE, {
      goalId: goal.id
    });
    const listItem = /* @__PURE__ */ jsx(
      ListItem,
      {
        progressBar: {
          progress: goal.lastProgress,
          progressType: "percentage",
          type: progressBar == null ? void 0 : progressBar.type,
          labelTextVariant: progressBar == null ? void 0 : progressBar.labelTextVariant
        },
        details: {
          title: showTitle ? goal.name : void 0,
          description: showDescription ? goal.description : null,
          chips: /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(OwnerChip, { customRule: goal.customRule, membersRule: goal.membersRule, ownerIds: goal.ownerIds }),
            goal.keyResultsCount > 0 ? /* @__PURE__ */ jsx(
              GrowthChip,
              {
                options: {
                  textColor: "black2",
                  textVariant: "paragraphSmall",
                  text: `${goal.keyResultsCount} ${polyglot.t(
                    goal.keyResultsCount === 1 ? "GoalListItem.keyResult" : "GoalListItem.keyResults"
                  )}`,
                  border: "middleGrey2"
                },
                sx: { height: "22px" }
              }
            ) : null,
            goal.dueDate ? /* @__PURE__ */ jsx(
              GrowthChip,
              {
                options: {
                  textColor: "black2",
                  textVariant: "paragraphSmall",
                  text: new LocalDate(goal.dueDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }),
                  icon: /* @__PURE__ */ jsx(CalendarIcon, { width: "12px" }),
                  border: "middleGrey2"
                },
                sx: { height: "22px" }
              }
            ) : null,
            /* @__PURE__ */ jsx(GrowthFactorsGroup, { factorIds: goal.factorIds })
          ] })
        },
        hoverState,
        endSection: endSection != null ? endSection : /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", justifyContent: "end" }, children: /* @__PURE__ */ jsx(StatusChip, { status: goal.status }) }),
        gridLayout: [7, 3, 2],
        sx
      }
    );
    if (withLink) {
      return /* @__PURE__ */ jsx(
        Link,
        {
          to: goalPageLink,
          title: goal.name,
          style: {
            width: "100%",
            textDecoration: "none",
            color: "inherit"
          },
          children: listItem
        }
      );
    }
    return listItem;
  }, [goal, showDescription, hoverState, reach, withLink, polyglot, endSection, progressBar, showTitle, sx]);
  return goalListItem;
};
