"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { AppDetailsEndpoints } from "@v2/feature/app-integration/features/app-details/app-details.api";
import { AuthEndpoints } from "@v2/feature/auth/auth.api";
import { SSOConfigureDrawer } from "@v2/feature/security/security-settings/components/sso-configure-drawer.component";
import { SSOAppsEnum } from "@v2/feature/security/security-settings/security.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import {
  SETTINGS_SECURITY_AUDIT_TRAIL_REPORT,
  SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE,
  SETTINGS_SECURITY_SSO_DETAIL_ROUTE
} from "@/lib/routes";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const SecuritySettingPage = () => {
  const { polyglot } = usePolyglot();
  const { data: mfaEnabled, isLoading: loadingMFa } = useApiClient(AuthEndpoints.mfaCheck(), {
    suspense: false
  });
  const { data: ssoState, isLoading: loadingSSO, mutate: refreshSSO } = useApiClient(
    AppDetailsEndpoints.getSSOMetadata(),
    {
      suspense: false
    }
  );
  const [editSSO, setEditSSO] = useState(false);
  const routerHistory = useHistory();
  const refreshSSOState = useCallback(() => __async(void 0, null, function* () {
    if (refreshSSO) yield refreshSSO();
  }), [refreshSSO]);
  const { certificate: _certificate, apikey: _apikey, enabledEntry } = useMemo(() => {
    var _a, _b, _c, _d, _e;
    const gwEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "google-workspace");
    const ssoGwEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "sso-google-workspace");
    const ssoAzureAdEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "sso-azure-ad");
    const ssoOktaEntry = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.app === "sso-okta");
    const enabledEntry2 = ssoState == null ? void 0 : ssoState.find((eachEntry) => eachEntry.state.enabled);
    const apikey = (_b = (_a = ssoOktaEntry == null ? void 0 : ssoOktaEntry.state) == null ? void 0 : _a.apikey) != null ? _b : "";
    const ssoProvider = enabledEntry2 ? SSOAppsEnum[enabledEntry2.app] : "None";
    let certificate = "";
    if (gwEntry) certificate = (_c = gwEntry == null ? void 0 : gwEntry.state) == null ? void 0 : _c.certificate;
    if (ssoGwEntry) certificate = (_d = ssoGwEntry == null ? void 0 : ssoGwEntry.state) == null ? void 0 : _d.certificate;
    if (ssoAzureAdEntry) certificate = (_e = ssoAzureAdEntry == null ? void 0 : ssoAzureAdEntry.state) == null ? void 0 : _e.certificate;
    return { certificate, apikey, enabledEntry: enabledEntry2, ssoProvider };
  }, [ssoState]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: loadingSSO || loadingMFa, children: [
    /* @__PURE__ */ jsx(SettingsSectionContent, { title: polyglot.t("SettingsRouterPageConfig.security"), contentWidth: "100%", children: /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            items: [
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: polyglot.t("SSOMetadata.singleSignOn"),
                    description: polyglot.t("SSOMetadata.subtitle"),
                    boxSx: responsiveCardStyle,
                    contentItemsSets: [
                      {
                        name: enabledEntry ? "Enabled" : "Disabled",
                        type: ContentItemType.chip,
                        textColor: enabledEntry ? "DarkGreen" : "darkRed",
                        backgroundColor: "white",
                        border: "middle"
                      }
                    ],
                    boxAction: () => routerHistory.push(SETTINGS_SECURITY_SSO_DETAIL_ROUTE)
                  },
                  "sso"
                ) })
              },
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: polyglot.t("SecurityAuditReportSection.auditTrail"),
                    description: polyglot.t("SecurityAuditReportSection.subtitle"),
                    boxSx: responsiveCardStyle,
                    boxAction: () => routerHistory.push(SETTINGS_SECURITY_AUDIT_TRAIL_REPORT)
                  },
                  "audit-trail"
                ) })
              },
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: polyglot.t("DeveloperHub.title"),
                    description: polyglot.t("DeveloperHub.subtitle"),
                    boxSx: responsiveCardStyle,
                    boxAction: () => routerHistory.push(SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE)
                  },
                  "developer-hub"
                ) })
              }
            ],
            contentWidth: "100%"
          }
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(
      SSOConfigureDrawer,
      {
        isOpen: editSSO,
        setIsOpen: setEditSSO,
        onClose: () => setEditSSO(false),
        mfaEnabled: !!mfaEnabled,
        ssoState: ssoState != null ? ssoState : [],
        onUpdate: () => __async(void 0, null, function* () {
          yield refreshSSOState();
        })
      }
    )
  ] });
};
