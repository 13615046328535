"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_REQUEST_FORMS_EDIT_ROUTE, SETTINGS_REQUEST_FORMS_NEW_ROUTE } from "@/lib/routes";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { RequestFormsAPI, RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { pluralText } from "@/v2/util/string.util";
export const RequestFormsPage = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [checkingRequestCreation, setCheckingRequestCreation] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [deleteDrawerOpen, setDeleteDrawerOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState();
  const [searchInput, setSearchInput] = useState("");
  const { data: templates, mutate: refreshForms, isValidating } = useApiClient(
    RequestFormsEndpoints.getFormTemplates(),
    {
      suspense: false
    }
  );
  const canRequestBeCreatedThenForwardOrDeny = () => __async(void 0, null, function* () {
    try {
      setCheckingRequestCreation(true);
      yield RequestFormsAPI.canRequestBeCreatedOnPlan();
      routerHistory.push(SETTINGS_REQUEST_FORMS_NEW_ROUTE);
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    } finally {
      setCheckingRequestCreation(false);
    }
    return true;
  });
  const filteredForms = useMemo(() => {
    if (!templates || templates === null) return [];
    if (!searchInput) return templates;
    return templates.filter((tem) => tem.name.toLowerCase().includes(searchInput.toLowerCase()));
  }, [templates, searchInput]);
  const deleteRequestForm = useCallback(() => __async(void 0, null, function* () {
    if (!selectedFormId) return;
    try {
      setDeleteLoading(true);
      yield RequestFormsAPI.deleteFormTemplate(selectedFormId);
      refreshForms == null ? void 0 : refreshForms(filteredForms == null ? void 0 : filteredForms.filter((t) => t.id !== selectedFormId));
      showMessage(polyglot.t("RequestFromDeleteDrawer.successMessages.delete"), "success");
      setDeleteDrawerOpen(false);
    } catch (error) {
      showMessage(
        polyglot.t("RequestFromDeleteDrawer.errorMessages.delete", {
          nestError: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  }), [selectedFormId, filteredForms, refreshForms, showMessage, polyglot]);
  return /* @__PURE__ */ jsxs(ContentWrapper, { children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("RequestFormsPage.requests"),
        contentWidth: "100%",
        headerWidth: "100%",
        loading: isValidating,
        buttons: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "primary",
              sizeVariant: "small",
              onClick: () => canRequestBeCreatedThenForwardOrDeny(),
              loading: checkingRequestCreation,
              children: polyglot.t("RequestFormsPage.new")
            }
          )
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          setSearchInput(e.target.value);
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: (filteredForms == null ? void 0 : filteredForms.length) === 0 ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("RequestFormsPage.noFormToShow") }) : /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (filteredForms || []).map((item) => /* @__PURE__ */ jsx(
                      SettingsItemCard,
                      {
                        title: item.name,
                        description: item.description,
                        boxSx: responsiveCardStyle,
                        boxAction: (event) => {
                          routerHistory.push(generatePath(SETTINGS_REQUEST_FORMS_EDIT_ROUTE, { formId: item.id }));
                          event == null ? void 0 : event.stopPropagation();
                        },
                        contentItemsSets: [
                          {
                            name: `${item.layout.items.length || 0} item${pluralText(
                              item.layout.items.length || 0
                            )}`,
                            type: ContentItemType.chip,
                            textColor: "DarkGrey",
                            backgroundColor: "white",
                            border: "middle"
                          }
                        ],
                        advanceActionOptions: [
                          {
                            label: polyglot.t("General.delete"),
                            icon: /* @__PURE__ */ jsx(Trash, {}),
                            handler: () => {
                              setSelectedFormId(item.id);
                              setDeleteDrawerOpen(true);
                            },
                            disabled: false
                          }
                        ]
                      },
                      item.id
                    )) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("RequestFromDeleteDrawer.title"),
        description: polyglot.t("RequestFromDeleteDrawer.description"),
        isOpen: deleteDrawerOpen,
        setIsOpen: setDeleteDrawerOpen,
        loading: deleteLoading,
        action: deleteRequestForm
      }
    ),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.PEOPLE_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] });
};
