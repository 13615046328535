"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { countFilters } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { FilterEditSection } from "@/v2/feature/reports/reports-advanced/components/filter-edit.section";
import { ReportFiltersSelection } from "@/v2/feature/reports/reports-advanced/components/report-filters-selection.component";
import { ReportFilters } from "@/v2/feature/reports/reports-advanced/components/report-filters.component";
import { ReportsEndpoints } from "@/v2/feature/reports/reports.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { deepCopy } from "@/v2/infrastructure/object/object.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsUpsertTriggersModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  reach
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose,
      widthPercentage: 95,
      sx: { overflow: "hidden", px: spacing.s3, height: "100%", boxSizing: "border-box" },
      children: /* @__PURE__ */ jsx(ParticipantsUpsertTriggersContent, { reviewCycle, onClose, refresh, reach })
    }
  );
};
const ParticipantsUpsertTriggersContent = ({
  reviewCycle,
  onClose,
  refresh,
  reach
}) => {
  var _a, _b, _c, _d;
  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getGrowthReportsFiltersOptions(), {
    suspense: false
  });
  const [view, setView] = useState("filters-list");
  const [filterToEdit, setFilterToEdit] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(
    deepCopy((_a = reviewCycle.enrolmentTriggerFilters) != null ? _a : {}) || {}
  );
  const [reportResponse, setReportResponse] = useState(void 0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const generateReport = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsGenerating(true);
      const response = yield ReviewCycleAPI.generateRollingReviewParticipants(selectedFilters, reach);
      setReportResponse(response);
    } catch (error) {
      setReportResponse(void 0);
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsGenerating(false);
  }), [selectedFilters, polyglot, showMessage, reach]);
  useEffect(() => {
    generateReport();
  }, [generateReport]);
  const handleTriggerFilterUpsert = () => __async(void 0, null, function* () {
    try {
      if (!(reviewCycle && reviewCycle.id)) {
        showMessage("Cycle not found", "error");
        return;
      }
      yield ReviewCycleAPI.updateEnrolmentTrigger(reviewCycle == null ? void 0 : reviewCycle.id, selectedFilters);
      showMessage("Successfully updated the enrolment trigger", "success");
      refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  });
  const tableColumns = useMemo(
    () => [
      {
        header: () => "Display name",
        accessorFn: (row) => row.displayName,
        id: "displayName",
        enableSorting: false,
        cell: ({
          row: {
            original: { displayName }
          }
        }) => {
          return displayName ? /* @__PURE__ */ jsx("div", { children: displayName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Job title",
        accessorFn: (row) => row.jobTitle,
        id: "jobTitle",
        enableSorting: false,
        cell: ({
          row: {
            original: { jobTitle }
          }
        }) => {
          return jobTitle ? /* @__PURE__ */ jsx("div", { children: jobTitle }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      }
    ],
    []
  );
  const isSaveDisabled = useMemo(() => {
    return Boolean(
      reviewCycle && reviewCycle.state !== CycleState.Draft && (selectedFilters === null || selectedFilters && countFilters(selectedFilters) === 0)
    );
  }, [reviewCycle, selectedFilters]);
  return /* @__PURE__ */ jsxs(Box, { style: __spreadProps(__spreadValues({}, drawerContentSx), { width: "100%" }), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Add enrolment trigger" }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        style: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100%"
        },
        children: [
          /* @__PURE__ */ jsx(Box, { sx: { width: "30%", maxHeight: `${window.innerHeight - 80}px`, overflowY: "auto" }, children: view === "filters-list" ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
            /* @__PURE__ */ jsx(
              ReportFilters,
              {
                openAddFilters: () => {
                  setView("filters-selection");
                },
                selectedFilters,
                setSelectedFilters,
                editFilter: (selectedFilter) => {
                  setFilterToEdit(selectedFilter);
                  setView("filter-edit");
                },
                reportFilters: (_b = filtersAndColumns == null ? void 0 : filtersAndColumns.filters) != null ? _b : []
              }
            ),
            /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxDrawerSx), { pb: 0 }), children: /* @__PURE__ */ jsx(
              LoaderButton,
              {
                type: "button",
                sizeVariant: "medium",
                disabled: isSaveDisabled,
                onClick: () => handleTriggerFilterUpsert(),
                colorVariant: "primary",
                name: polyglot.t("General.save"),
                loading: isGenerating || isLoading,
                fullWidth: true
              }
            ) })
          ] }) : view === "filters-selection" ? /* @__PURE__ */ jsx(
            ReportFiltersSelection,
            {
              goBack: () => {
                setView("filters-list");
              },
              goToEdit: () => {
                setView("filter-edit");
              },
              reportFilters: (_c = filtersAndColumns == null ? void 0 : filtersAndColumns.filters) != null ? _c : [],
              setFilterToEdit
            }
          ) : view === "filter-edit" ? /* @__PURE__ */ jsx(
            FilterEditSection,
            {
              setSelectedFilters,
              goBack: () => {
                setView("filters-list");
                setFilterToEdit(null);
              },
              reportFilters: (_d = filtersAndColumns == null ? void 0 : filtersAndColumns.filters) != null ? _d : [],
              filter: filterToEdit
            }
          ) : (
            // FILTERS END
            null
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { width: "60%", maxHeight: `${window.innerHeight - 135}px`, height: "100%" }, children: /* @__PURE__ */ jsx(BasicTable, { rowData: reportResponse || [], columnData: tableColumns, loading: isLoading || isGenerating }) })
        ]
      }
    )
  ] });
};
