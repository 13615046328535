"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const InsuranceRemoveFromPolicyDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  refresh,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(InsuranceRemoveFromPolicyDrawerContent, { userInsurance, onClose, refresh })
    }
  ) });
};
const InsuranceRemoveFromPolicyDrawerContent = ({ userInsurance, onClose, refresh }) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(new LocalDate().toDateString());
  const [showMessage] = useMessage();
  const removeFromPolicy = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield InsuranceAPI.removeUserFromPolicy(userInsurance.userId, userInsurance.policyId, endDate);
      yield refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setLoading(false);
  }), [showMessage, polyglot, refresh, userInsurance, onClose, endDate]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Remove from insurance" }),
    /* @__PURE__ */ jsxs(Box, { sx: { mb: "10px", mt: "20px" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Employee" }),
      /* @__PURE__ */ jsx(UserCell, { userId: userInsurance.userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: "30px" }, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "endDate",
        label: "End date",
        onChange: (value) => {
          setEndDate(value);
        },
        value: endDate
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Remove",
        loading,
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        onClick: removeFromPolicy
      }
    ) })
  ] });
};
