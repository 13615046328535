"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { LocalDate } from "@v2/util/local-date";
import { ReactComponent as AppsIcon } from "@/images/offboarding/Apps.svg";
import { ReactComponent as DeviceIcon } from "@/images/offboarding/Device.svg";
import { ReactComponent as InsuranceIcon } from "@/images/offboarding/Insurance.svg";
import { ReactComponent as PensionIcon } from "@/images/offboarding/Pension.svg";
import { ReactComponent as WalletIcon } from "@/images/offboarding/Wallet.svg";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { getScheduledActionDateString } from "@/v2/feature/app-integration/app-integration.util";
import { MoneyEnum, ProgressStatus } from "@/v2/feature/offboarding/offboarding.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const getOffboardingTask = (key, value) => {
  switch (key) {
    case "devices":
      return `Return ${value} ${Number(value) > 1 ? "devices" : "devices"}`;
    case "apps":
      return value && Number(value) > 1 ? `Disconnect from ${value} apps` : `Disconnect from ${value} app`;
    case "inPayroll":
    case "inPension":
    case "inInsurance":
      return `Offboard from ${MoneyEnum[key]}`;
    default:
      return "";
  }
};
export const getOffboardingTaskIcon = (key) => {
  switch (key) {
    case "devices":
      return /* @__PURE__ */ jsx(DeviceIcon, {});
    case "apps":
      return /* @__PURE__ */ jsx(AppsIcon, {});
    case "inPayroll":
      return /* @__PURE__ */ jsx(WalletIcon, {});
    case "inPension":
      return /* @__PURE__ */ jsx(PensionIcon, {});
    case "inInsurance":
      return /* @__PURE__ */ jsx(InsuranceIcon, {});
    default:
      return /* @__PURE__ */ jsx(Fragment, {});
  }
};
export function userStatusIs(status, choices) {
  if (!status) return false;
  return choices.includes(status);
}
export const getActionDate = (date) => {
  const dateObj = new LocalDate(date);
  return dateObj.toDateString() === new LocalDate().toDateString() ? void 0 : dateObj.toDateString();
};
export const getStatusToIcon = (status) => {
  switch (status) {
    case ProgressStatus.None:
      return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "\u2013" });
    case ProgressStatus.Pending:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Clock, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Grey })),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Pending" })
      ] });
    case ProgressStatus.Completed:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Green })),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Completed" })
      ] });
    case ProgressStatus.Missing:
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Mistake, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Red", children: "Missing" })
      ] });
    default:
      return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "\u2013" });
  }
};
export function getUserAppDtoForInstalledAppViaScheduledAction(installedApp, matchingAction) {
  const userAppDto = {
    stub: installedApp.stub,
    userStatus: matchingAction ? getScheduledActionDateString(matchingAction) : "",
    scheduledAction: true
  };
  return userAppDto;
}
