"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Autocomplete, Box, FormControl, Paper, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomProfileFormType } from "@v2/feature/custom-fields/custom-profile-fields.dto";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { capitalize, keyBy } from "lodash";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { GenderSelect } from "@/v2/components/gender-select.component";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import { useUserFieldsContext } from "@/v2/feature/user/context/user-fields.context";
import { UserAPI, UserEndpoints } from "@/v2/feature/user/user.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { getCountriesForNationality } from "@/v2/infrastructure/country/country.util";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { translateCustomField, translateCustomFieldCategory } from "@/v2/infrastructure/i18n/translate.util";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { StyledMenuItem } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: "14px", height: "14px" };
const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map(
    (r) => {
      var _a;
      return pipe(
        (_a = r.options) != null ? _a : [],
        RA.map((x) => __spreadProps(__spreadValues({}, x), { group: r.label }))
      );
    }
  ),
  RA.flatten
);
export const UserPersonalInfoSection = ({ onNext, sx, userId }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { data: personalInfo, isLoading: personalInfoLoading } = useApiClient(
    UserEndpoints.getUserPersonalInfo(userId),
    {
      suspense: false
    }
  );
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useUserFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Details);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Details);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const getPersonalInfoSchema = useCallback(
    (polyglot2) => {
      return {
        dob: defaultFieldsMap["dob"] && defaultFieldsMap["dob"].isRequired ? Yup.string().test(dateFieldTest).nullable().required(polyglot2.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
        nationality: defaultFieldsMap["nationality"] && defaultFieldsMap["nationality"].isRequired ? Yup.string().nullable().required(polyglot2.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
        gender: defaultFieldsMap["gender"] && defaultFieldsMap["gender"].isRequired ? Yup.string().nullable().required(polyglot2.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
        passportNumber: defaultFieldsMap["passportNumber"] && defaultFieldsMap["passportNumber"].isRequired ? Yup.string().nullable().required(polyglot2.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
        personalEmail: defaultFieldsMap["personalEmail"] && defaultFieldsMap["personalEmail"].isRequired ? Yup.string().email(polyglot2.t("OnboardingByUser.validations.validValue")).required(polyglot2.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired(),
        phone: defaultFieldsMap["phone"] && defaultFieldsMap["phone"].isRequired ? Yup.string().nullable().required(polyglot2.t("OnboardingByUser.validations.requiredField")) : Yup.string().nullable().notRequired()
      };
    },
    [defaultFieldsMap]
  );
  const validationSchema = useMemo(() => {
    return Yup.object().shape(__spreadValues({}, getPersonalInfoSchema(polyglot)));
  }, [polyglot, getPersonalInfoSchema]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2;
    const values = {
      dob: void 0,
      gender: void 0,
      nationality: void 0,
      passportNumber: void 0,
      personalEmail: void 0,
      phone: void 0,
      customUpdates: []
    };
    if (personalInfo && !personalInfoLoading) {
      if (defaultFieldsMap["dob"]) values.dob = (_a2 = personalInfo.dob) != null ? _a2 : null;
      if (defaultFieldsMap["gender"]) values.gender = (_b2 = personalInfo.gender) != null ? _b2 : null;
      if (defaultFieldsMap["nationality"]) values.nationality = (_c2 = personalInfo.nationality) != null ? _c2 : null;
      if (defaultFieldsMap["passportNumber"]) values.passportNumber = (_d2 = personalInfo.passportNumber) != null ? _d2 : null;
      if (defaultFieldsMap["personalEmail"]) values.personalEmail = (_e2 = personalInfo.personalEmail) != null ? _e2 : null;
      if (defaultFieldsMap["phone"]) values.phone = (_f2 = personalInfo.phone) != null ? _f2 : null;
      values.customUpdates = (_h2 = (_g2 = personalInfo.customUpdates) == null ? void 0 : _g2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _h2 : [];
    }
    return values;
  }, [personalInfo, defaultFieldsMap, customFieldIds, personalInfoLoading]);
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        yield UserAPI.patchUserPersonalInfo(userId, values);
        onNext(values);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingByUser.errors.basicInfoNotSaved", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (_a = capitalize(translateCustomFieldCategory(CustomProfileFormType.Details, polyglot))) != null ? _a : polyglot.t("OnboardingByUser.personalInfo") }),
    defaultFieldsMap["dob"] && /* @__PURE__ */ jsx(
      TypeableDateComponent,
      {
        name: "dob",
        label: (_b = translateCustomField(defaultFieldsMap["dob"].fieldName, polyglot)) != null ? _b : polyglot.t("OnboardingByUser.dob"),
        value: formik.values.dob,
        onChange: (value) => formik.setFieldValue("dob", value),
        onBlur: () => formik.setFieldTouched("dob", true),
        disabled: saving,
        error: hasSubmitted && !!formik.errors.dob,
        helperText: hasSubmitted && formik.errors.dob,
        required: defaultFieldsMap["dob"].isRequired
      }
    ),
    defaultFieldsMap["nationality"] && /* @__PURE__ */ jsx(FormControl, { fullWidth: true, children: /* @__PURE__ */ jsx(
      Autocomplete,
      {
        fullWidth: true,
        options: countryOptions,
        getOptionLabel: (option) => option.label,
        value: (_c = countryOptions.find(({ value }) => value === formik.values.nationality)) != null ? _c : null,
        renderOption: (props, option) => {
          return option.value === formik.values.nationality ? /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: option.label }),
                /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
              ]
            }
          ) })) : /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: option.label }) }));
        },
        groupBy: (option) => option.group,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("nationality", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        renderInput: (params) => {
          var _a2, _b2;
          return /* @__PURE__ */ jsx(
            StyledAutoTextfield,
            __spreadProps(__spreadValues({}, params), {
              variant: "standard",
              size: "small",
              value: (_a2 = countryOptions.find(({ value }) => value === formik.values.nationality)) != null ? _a2 : null,
              InputLabelProps: { shrink: true },
              label: polyglot.t("PersonalInformationForm.nationality"),
              name: "nationality",
              error: !!formik.errors.nationality && formik.touched.nationality,
              helperText: formik.errors.nationality && formik.touched.nationality,
              required: (_b2 = defaultFieldsMap["nationality"]) == null ? void 0 : _b2.isRequired
            })
          );
        },
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, {}),
        clearIcon: /* @__PURE__ */ jsx(ClearIcon, {}),
        PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children })
      }
    ) }),
    defaultFieldsMap["gender"] && /* @__PURE__ */ jsx(
      GenderSelect,
      {
        name: "gender",
        value: (_d = formik.values.gender) != null ? _d : void 0,
        onChange: (value) => formik.setFieldValue("gender", value),
        disabled: saving,
        error: hasSubmitted && !!formik.errors.gender,
        helperText: hasSubmitted && formik.errors.gender,
        required: defaultFieldsMap["gender"].isRequired
      }
    ),
    defaultFieldsMap["passportNumber"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "passportNumber",
        label: (_e = translateCustomField(defaultFieldsMap["passportNumber"].fieldName, polyglot)) != null ? _e : polyglot.t("OnboardingByUser.passportNumber"),
        value: formik.values.passportNumber,
        onChange: formik.handleChange,
        disabled: saving,
        clearText: () => formik.setFieldValue("passportNumber", ""),
        helperText: hasSubmitted && formik.errors.passportNumber,
        error: hasSubmitted && !!formik.errors.passportNumber,
        required: defaultFieldsMap["passportNumber"].isRequired
      }
    ),
    defaultFieldsMap["personalEmail"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "personalEmail",
        label: (_f = translateCustomField(defaultFieldsMap["personalEmail"].fieldName, polyglot)) != null ? _f : polyglot.t("OnboardingByUser.personalEmail"),
        type: "email",
        value: formik.values.personalEmail,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("personalEmail", ""),
        helperText: hasSubmitted && formik.errors.personalEmail,
        error: hasSubmitted && !!formik.errors.personalEmail,
        required: defaultFieldsMap["personalEmail"].isRequired
      }
    ),
    defaultFieldsMap["phone"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "phone",
        label: (_g = translateCustomField(defaultFieldsMap["phone"].fieldName, polyglot)) != null ? _g : polyglot.t("OnboardingByUser.phone"),
        type: "tel",
        value: formik.values.phone,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("phone", ""),
        helperText: hasSubmitted && formik.errors.phone,
        error: hasSubmitted && !!formik.errors.phone,
        required: defaultFieldsMap["phone"].isRequired
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_h = formik.values.customUpdates) != null ? _h : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        sizeVariant: "large",
        colorVariant: "primary",
        loading: saving,
        fullWidth: true
      }
    )
  ] }) }) });
};
