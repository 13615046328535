"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { sortNumeric } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { SuperAdminDeviceDetailsDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-possession-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@v2/styles/colors.styles";
export const SuperAdminDeviceDetailsPossessions = ({ deviceId }) => {
  const {
    data: devicePossessions,
    isLoading,
    mutate: refresh
  } = useApiClient(DeviceEndpoints.getPossessionsByDeviceIdAsSuperAdmin(deviceId), { suspense: true });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPossession, setSelectedPossession] = useState(null);
  const refreshPossessions = useCallback(() => __async(void 0, null, function* () {
    if (refresh) refresh();
  }), [refresh]);
  const columns = useMemo(() => {
    const getColor = (possession) => {
      if (possession.startDate && !possession.endDate) return themeColors.PastelGreen;
      else if (possession.startDate && possession.endDate) return themeColors.GreyMiddle;
      else return themeColors.BlueLight;
    };
    return [
      {
        id: "id",
        header: "ID",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { bgcolor: getColor(original) }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.id }) })
      },
      {
        id: "companyId",
        header: "Company ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.companyId }) })
      },
      {
        id: "deviceId",
        header: "Device ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.deviceId }) })
      },
      {
        id: "possessionType",
        header: "Possession type",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.possessionType }) })
      },
      {
        id: "possessionId",
        header: "Possession ID",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.possessionId }) })
      },
      {
        id: "startDate",
        header: "Start date",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.startDate ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.startDate }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "endDate",
        header: "End date",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.endDate ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.endDate }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "deliveryAddress",
        header: "Delivery address",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.deliveryAddress ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.deliveryAddress }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        id: "createdAt",
        header: "Created at",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(original.createdAt).toLocaleString() }) })
      },
      {
        id: "updatedAt",
        header: "Updated at",
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new Date(original.updatedAt).toLocaleString() }) })
      }
    ];
  }, []);
  const handleRowClick = useCallback((row) => {
    setSelectedPossession(row.original);
    setIsOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "Device possessions" }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          onClick: () => {
            setSelectedPossession(null);
            setIsOpen(true);
          },
          children: "Add possession"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: "5px" }, children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: devicePossessions != null ? devicePossessions : [],
        columnData: columns,
        loading: isLoading,
        hidePagination: true,
        rowClick: handleRowClick
      }
    ) }),
    /* @__PURE__ */ jsx(
      SuperAdminDeviceDetailsDrawer,
      {
        isOpen,
        setIsOpen,
        deviceId,
        devicePossession: selectedPossession,
        refreshPossessions
      }
    )
  ] });
};
