"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const EmptyStateBox = ({
  title,
  subtitle,
  buttonTitle,
  onClick,
  style
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      style: __spreadValues({
        backgroundColor: themeColors.Background,
        padding: "16px",
        borderRadius: radius.br8,
        maxWidth: "600px",
        minHeight: "50%",
        display: "flex",
        flexDirection: "column"
      }, style),
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              gap: spacing.g16
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: title }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: subtitle })
            ]
          }
        ),
        onClick && /* @__PURE__ */ jsx(Box, { sx: { mt: "auto" }, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick, children: buttonTitle }) })
      ]
    }
  );
};
