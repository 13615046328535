"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DisenrollDeviceDrawer = ({ isOpen, onClose, devicePossession, setIsOpen }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(DisenrollDeviceDrawerContent, { devicePossession, closePage: onClose }) });
};
const DisenrollDeviceDrawerContent = ({
  devicePossession,
  closePage
}) => {
  const [confirmationText, setConfirmationText] = useState("");
  const [showMessage] = useMessage();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: "Disenrol device" }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Red }), children: "Device will be disenrolled from MDM" }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt40), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "confirm",
        label: "",
        placeholder: "Type \u201CConfirm\u201D here to disenroll device",
        fullWidth: true,
        value: confirmationText,
        type: "text",
        onChange: (event) => setConfirmationText(event.target.value),
        clearText: () => setConfirmationText("")
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        disabled: confirmationText !== "Confirm",
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "secondary",
        onClick: () => __async(void 0, null, function* () {
          try {
            if (devicePossession && devicePossession.id) yield DeviceAPI.disenrollDevice(devicePossession.id);
            showMessage("Action launched successfully", "success");
            closePage();
          } catch (error) {
            showMessage("The launch of the action failed", "error");
          }
        }),
        children: "Disenrol device"
      }
    ) })
  ] });
};
