"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { getAverageNumberOfWorkingDaysPerScheduleWeek } from "@v2/feature/attendance/attendance-schedule.util";
import { AttendanceScheduleEditScheduleSettingsDrawer } from "@v2/feature/attendance/company/components/edit-schedule/attendance-schedule-edit-schedule-settings-drawer.component";
import { AttendanceSchedulePatternSubSection } from "@v2/feature/attendance/settings/components/attendance-schedule-pattern-subsection.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
export const AttendanceSettingsScheduleSection = ({
  schedule,
  refreshSchedule,
  refreshAllSchedules,
  jobTypes,
  attendancePayMultipliers
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [edit, setEdit] = useState(false);
  const averageNumberOfWorkingDaysPerSchedule = getAverageNumberOfWorkingDaysPerScheduleWeek(schedule);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AttendanceDomain.schedule"),
      onEdit: () => {
        setEdit(true);
      },
      contentWidth: "100%",
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("General.type"),
                    value: schedule.isFlexible ? polyglot.t("ScheduleScheduleForm.flexible") : polyglot.t("ScheduleScheduleForm.definedHours")
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("EditFTEEquivalentDrawer.WorkingHoursPaid"),
                    value: polyglot.t("EditFTEEquivalentDrawer.avgHoursPerWeek", {
                      noOfHours: convertMinutesToClockHours(schedule.totalTime, polyglot),
                      smart_count: schedule.noOfWeeks
                    })
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("EditFTEEquivalentDrawer.FullTimeHours"),
                    value: polyglot.t("EditFTEEquivalentDrawer.avgHoursPerWeek", {
                      noOfHours: convertMinutesToClockHours(schedule.fteEquivalent, polyglot),
                      smart_count: 1
                    })
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("EditFTEEquivalentDrawer.WorkingDays"),
                    value: polyglot.t("EditFTEEquivalentDrawer.avgDaysPerWeek", {
                      noOfDays: averageNumberOfWorkingDaysPerSchedule,
                      smart_count: schedule.noOfWeeks
                    })
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("EditFTEEquivalentDrawer.FullTimeDays"),
                    value: polyglot.t("EditFTEEquivalentDrawer.avgDaysPerWeek", {
                      noOfDays: schedule.fteEquivalentInDays,
                      smart_count: 1
                    })
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("SettingsSectionReadonly.allowed"),
                    value: schedule.attendanceTypesAllowed.map((r) => r.name).join(", "),
                    hidden: schedule.attendanceTypesAllowed.length === 0
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AttendanceSchedule.scheduleRules.settings.excludedFromMinHoursAttendanceTypes"),
                    value: (_a = schedule.excludedFromMinHoursAttendanceTypes) == null ? void 0 : _a.map((r) => r.name).join(", ")
                  }
                ]
              },
              {
                title: polyglot.t("AttendanceDomain.pattern"),
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: "Set up a rotating attendance schedule below to define working time."
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      AttendanceSchedulePatternSubSection,
                      {
                        schedule,
                        refreshSchedule,
                        jobTypes,
                        attendancePayMultipliers
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          AttendanceScheduleEditScheduleSettingsDrawer,
          {
            isOpen: edit,
            setIsOpen: setEdit,
            attendanceSchedule: schedule,
            attendanceTypes: jobTypes,
            refresh: () => __async(void 0, null, function* () {
              yield Promise.all([refreshSchedule(), refreshAllSchedules()]);
            })
          }
        )
      ]
    }
  );
};
