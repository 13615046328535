"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PayrollEmploymentAllowanceDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/employer-allowance-settings/payroll-employer-allowance-drawer.component";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { primarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
export const PayrollEmploymentAllowance = ({
  payrollId,
  disabled,
  setOtherEdit,
  setShowEditButton,
  empAllowanceDrawerOpen,
  setEmpAllowanceDrawerOpen
}) => {
  const [employmentAllowance, setEmploymentAllowance] = useState();
  const [showMessage] = useMessage();
  const refreshEmploymentAllowanceStatus = useCallback(() => __async(void 0, null, function* () {
    try {
      const employmentAllowance2 = yield PayrollAPI.getEmploymentAllowance(payrollId);
      setEmploymentAllowance(employmentAllowance2);
      setShowEditButton(!!(employmentAllowance2 == null ? void 0 : employmentAllowance2.current));
    } catch (error) {
      showMessage(`Failed to retrieve Employer Allowance. ${nestErrorMessage(error)}`, "error");
    }
  }), [payrollId, setShowEditButton, showMessage]);
  useEffect(() => {
    if (!empAllowanceDrawerOpen) {
      refreshEmploymentAllowanceStatus();
    }
  }, [refreshEmploymentAllowanceStatus, empAllowanceDrawerOpen]);
  if (!employmentAllowance) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
        "Employment Allowance allows",
        " ",
        /* @__PURE__ */ jsx(
          "a",
          {
            href: "https://www.gov.uk/claim-employment-allowance",
            target: "_blank",
            rel: "noreferrer",
            style: { color: themeColors.DarkGrey },
            children: "eligible employers"
          }
        ),
        " ",
        "to reduce their annual National\xA0Insurance liability by up to",
        " ",
        formatCurrency(employmentAllowance.maxAllowance, { wholeNumber: true }),
        "."
      ] }),
      employmentAllowance.current ? /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
          "Allowance: ",
          formatCurrency(employmentAllowance.current.allowance)
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
          "Remaining: ",
          formatCurrency(employmentAllowance.current.allowance - employmentAllowance.current.claimed)
        ] })
      ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
        !employmentAllowance.canRequest && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Employment allowance can only be requested after PAYE details have been configured." }),
        /* @__PURE__ */ jsx(
          Button,
          {
            sx: __spreadProps(__spreadValues({}, primarySmallBtn), { width: "140px" }),
            disabled: disabled || !employmentAllowance.canRequest,
            onClick: () => setEmpAllowanceDrawerOpen(true),
            children: "Request"
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      PayrollEmploymentAllowanceDrawer,
      {
        payrollId,
        isOpen: empAllowanceDrawerOpen,
        setIsOpen: () => {
          setEmpAllowanceDrawerOpen(false);
          setOtherEdit(false);
        }
      }
    )
  ] });
};
