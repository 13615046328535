"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { EditUserPayrollNonUK } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-user-payroll/edit-user-payroll-non-uk.page";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { CloseButton } from "@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingPayrollNonUK = ({ userId, userPayroll, payrollSchema, onClose }) => {
  const { polyglot } = usePolyglot();
  const savePayrollRecord = useCallback(
    (update) => __async(void 0, null, function* () {
      try {
        yield PayrollAPI.updateUserPayrollRecord(userId, update);
        return true;
      } catch (error) {
        return false;
      }
    }),
    [userId]
  );
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 0, mx: "auto", width: "500px", maxWidth: "90vw", mt: spacing.mt40 }, children: [
      /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.headline1), {
            color: themeColors.DarkGrey
          }),
          children: polyglot.t("OnboardingByUser.payrollOnboarding")
        }
      ),
      /* @__PURE__ */ jsx(CloseButton, { onClick: onClose })
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, overflowY: "auto" }, children: /* @__PURE__ */ jsx(Stack, { sx: { mx: "auto", width: "500px", maxWidth: "90vw", my: spacing.my40, gap: spacing.g30 }, children: /* @__PURE__ */ jsx(
      EditUserPayrollNonUK,
      {
        payrollSchema,
        remotePayrollValues: userPayroll == null ? void 0 : userPayroll.remotePayrollValues,
        savePayrollRecord,
        close: onClose,
        isUserUpdating: false
      }
    ) }) })
  ] });
};
