"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import "@/images/side-bar-icons/Mistake.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ONBOARDING_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoadingSpinner, SmallLoader } from "@/v2/components/loader.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import "@/v2/components/theme-components/styled-tooltip.component";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import {
  EditAppAccount
} from "@/v2/feature/onboarding/onboarding-apps/edit-app-account-schedule.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const OnboardingAppsPage = () => {
  var _a, _b;
  const [onboarding, setOnboarding] = useState();
  const [drawerProps, setDrawerProps] = useState(null);
  const { getCachedUserById } = useCachedUsers();
  const params = useParams();
  const userId = Number(params.userId);
  const user = userId && getCachedUserById(userId);
  const routerHistory = useHistory();
  const updatingApps = useRef(/* @__PURE__ */ new Set()).current;
  const [showMessage] = useMessage();
  const refreshAppState = useCallback(() => {
    if (!userId) return;
    OnboardingAPI.getOnboardingUser(userId).then(
      (state) => {
        updatingApps.clear();
        setOnboarding(state);
      },
      (err) => showMessage(`Failed to retrieve onboarding apps. ${nestErrorMessage(err)}`, "error")
    );
  }, [showMessage, updatingApps, userId]);
  useEffect(() => {
    if (!drawerProps) {
      refreshAppState();
    }
  }, [drawerProps, refreshAppState]);
  const columns = useMemo(() => {
    return [
      {
        id: "apps",
        header: () => "Apps",
        accessorFn: (row) => row.name,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: (c) => /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(
            "img",
            {
              src: `/app-icons-v2/images/${c.row.original.stub}.png`,
              style: { maxWidth: "20px", maxHeight: "20px" },
              alt: ""
            }
          ),
          /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: c.row.original.name })
        ] })
      },
      {
        id: "status",
        header: () => "Status",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.accountStatus),
        cell: (c) => {
          var _a2;
          return /* @__PURE__ */ jsx(
            SmallLoader,
            {
              loading: updatingApps.has(c.row.original.stub),
              spinnerSx: { placeContent: "flex-start", m: 0 },
              size: "20px",
              children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: (_a2 = c.row.original.accountStatus) != null ? _a2 : "No account" })
            }
          );
        }
      },
      {
        id: "action",
        header: void 0,
        enableSorting: false,
        accessorFn: (row) => row,
        cell: (c) => {
          const appStub = c.row.original.stub;
          if (updatingApps.has(appStub)) {
            return null;
          }
          if (!c.row.original.installed && 0)
            return /* @__PURE__ */ jsx(
              StyledTooltip,
              {
                title: /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g20, m: spacing.m10 }, children: [
                  /* @__PURE__ */ jsxs(
                    Typography,
                    {
                      sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.white, display: "flex", alignItems: "end" }),
                      children: [
                        /* @__PURE__ */ jsx(
                          "img",
                          {
                            src: `/app-icons-v2/images/${c.row.original.stub}.png`,
                            style: { maxWidth: "20px", maxHeight: "20px", margin: "4px 10px 0 0" },
                            alt: ""
                          }
                        ),
                        "App is not connected to Zelt"
                      ]
                    }
                  ),
                  /* @__PURE__ */ jsx(ScopesControl, { scopes: AppScopes.VIEW_APP_INTEGRATION, children: /* @__PURE__ */ jsxs(
                    ButtonComponent,
                    {
                      sizeVariant: "small",
                      colorVariant: "tooltip",
                      onClick: () => routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_ROUTE, { appStub })),
                      children: [
                        "Connect ",
                        c.row.original.name,
                        "..."
                      ]
                    }
                  ) })
                ] }),
                sx: { ml: "auto" },
                children: /* @__PURE__ */ jsx(Mistake, __spreadProps(__spreadValues({}, iconSize), { style: { display: "flex", marginLeft: "auto" } }))
              }
            );
          if (!!c.row.original.accountStatus) {
            return null;
          }
          return /* @__PURE__ */ jsx(ScopesControl, { scopes: AppScopes.CREATE_APP_USER, children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              style: { marginLeft: "auto" },
              sizeVariant: "small",
              colorVariant: "primary",
              disabled: !!drawerProps,
              onClick: () => {
                if (!user || !(onboarding == null ? void 0 : onboarding.template.apps)) return;
                setDrawerProps({
                  app: c.row.original,
                  user,
                  defaultScheduleOffset: onboarding.template.apps.scheduleOffsetFromStartDate
                });
              },
              children: "Set up"
            }
          ) });
        }
      }
    ];
  }, [drawerProps, onboarding == null ? void 0 : onboarding.template.apps, routerHistory, updatingApps, user]);
  const close = useCallback(() => routerHistory.goBack(), [routerHistory]);
  const rows = useMemo(() => {
    var _a2, _b2;
    if (!((_b2 = (_a2 = onboarding == null ? void 0 : onboarding.state) == null ? void 0 : _a2.apps) == null ? void 0 : _b2.items)) return null;
    return [...onboarding.state.apps.items].sort(
      (a, b) => a.name.localeCompare(b.name, void 0, { sensitivity: "base" })
    );
  }, [(_b = (_a = onboarding == null ? void 0 : onboarding.state) == null ? void 0 : _a.apps) == null ? void 0 : _b.items]);
  useEscapeKey(close);
  if (!user) {
    routerHistory.replace(ONBOARDING_ROUTE);
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsxs(
    Stack,
    {
      sx: { background: themeColors.white, flex: 1, inset: 0, overflow: "hidden", position: "absolute", zIndex: 10 },
      children: [
        /* @__PURE__ */ jsx(
          TopHeader,
          {
            title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { ml: "max(0px, calc(50vw - 500px))" }), children: "Create app accounts" }),
            showBack: true
          }
        ),
        /* @__PURE__ */ jsxs(Stack, { sx: { width: "min(90vw,800px)", mx: "auto", mt: "100px", overflow: "hidden" }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb20 }), children: "Activate accounts now or schedule them for later" }),
          !rows && /* @__PURE__ */ jsx(LoadingSpinner, {}),
          rows && /* @__PURE__ */ jsxs(Stack, { sx: { overflow: "auto", pb: spacing.pb20 }, children: [
            /* @__PURE__ */ jsx(BasicTable, { rowData: rows, columnData: columns, loading: !rows, hidePagination: true }),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                sizeVariant: "medium",
                colorVariant: "primary",
                style: { width: "400px", marginTop: "40px", flexShrink: 0 },
                fullWidth: true,
                onClick: close,
                children: "Complete"
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ jsx(DrawerModal, { isOpen: !!drawerProps, setIsOpen: () => setDrawerProps(null), children: /* @__PURE__ */ jsx(Fragment, { children: drawerProps && /* @__PURE__ */ jsx(
          EditAppAccount,
          __spreadProps(__spreadValues({}, drawerProps), {
            onFinish: () => {
              updatingApps.add(drawerProps.app.stub);
              setDrawerProps(null);
            }
          })
        ) }) })
      ]
    }
  );
};
