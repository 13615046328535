"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useCallback } from "react";
import { Box } from "@mui/material";
import { BenefitRequestTodoComponent } from "@v2/feature/dashboard/features/sections/user-todos/components/version-big/benefit-request-todo.component";
import { DeviceTransitTaskComponent } from "@v2/feature/dashboard/features/sections/user-todos/components/version-big/device-transit-task.component";
import { ExpenseRequestTodoComponent } from "@v2/feature/dashboard/features/sections/user-todos/components/version-big/expense-request-todo.component";
import { InvoiceRequestTodoComponent } from "@v2/feature/dashboard/features/sections/user-todos/components/version-big/invoice-request-todo.component";
import { PendingBenefitPaymentTodoComponent } from "@v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-benefit-payment-todo.component";
import { PendingPHDeletionRequestTodo } from "@v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-ph-deletion-request-todo.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import { PEOPLE_ME_ACTION_ITEMS } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { UserTodosEmptyState } from "@/v2/feature/dashboard/features/sections/user-todos/components/user-todos-empty-state.component";
import { MissingDocumentsTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/missing-documents-todo.component";
import { OverdueTaskTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/overdue-task.todo.component";
import { PendingContractsTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-contracts-todo.component";
import { PendingRequestTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-request-todo.component";
import { PendingReviewsTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-reviews-todo.component";
import { PendingSurveysTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-surveys-todo.component";
import { SubmittedAttendanceTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/submitted-attendance-todo.component";
import { SubmittedRequestBigTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/submitted-request-todo.component";
import { UnfinishedAttendanceShiftTodo } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/unfinished-attendance-shift-todo.component";
import "@/v2/feature/dashboard/features/sections/user-todos/user-todos.scss";
import { KindTypes } from "@/v2/feature/dashboard/interfaces/dashboard.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const UserTodosVersionBig = ({
  todos,
  refreshtodoList,
  readOnly = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const limit = 5;
  const refreshTodos = useCallback(() => __async(void 0, null, function* () {
    if (refreshtodoList) yield refreshtodoList();
  }), [refreshtodoList]);
  return /* @__PURE__ */ jsx(WidgetLayout, { size: "big", readOnly, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          width: "100%",
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          cursor: "pointer"
        },
        onClick: () => routerHistory.push(PEOPLE_ME_ACTION_ITEMS),
        children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserTodosVersionBig.action") }) })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { height: "100%" }, children: todos && ((_a = todos == null ? void 0 : todos.personal) == null ? void 0 : _a.totalCount) === 0 ? /* @__PURE__ */ jsx(UserTodosEmptyState, {}) : /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column"
        },
        children: [
          (_c = (_b = todos == null ? void 0 : todos.personal) == null ? void 0 : _b.todosItems) == null ? void 0 : _c.map((todo, idx) => {
            var _a2;
            const setLastChild = ((_a2 = todos == null ? void 0 : todos.personal) == null ? void 0 : _a2.todosItems.length) === idx + 1;
            switch (todo.kind) {
              case KindTypes.IncompleteTasks:
                return /* @__PURE__ */ jsx(
                  OverdueTaskTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `overdue-task-${idx}`
                );
              case KindTypes.PendingBenefitPayment:
                return /* @__PURE__ */ jsx(
                  PendingBenefitPaymentTodoComponent,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `benefit-payment-${idx}`
                );
              case KindTypes.BenefitRequest:
                return /* @__PURE__ */ jsx(
                  BenefitRequestTodoComponent,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `benefit-request-${idx}`
                );
              case KindTypes.ExpenseRequest:
                return /* @__PURE__ */ jsx(
                  ExpenseRequestTodoComponent,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `expense-request-${idx}`
                );
              case KindTypes.InvoiceRequest:
                return /* @__PURE__ */ jsx(
                  InvoiceRequestTodoComponent,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `invoice-request-${idx}`
                );
              case KindTypes.PendingRequest:
                return /* @__PURE__ */ jsx(
                  PendingRequestTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `pending-req-${idx}`
                );
              case KindTypes.PendingPHCancellation:
                return /* @__PURE__ */ jsx(
                  PendingPHDeletionRequestTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `pending-ph-req-${idx}`
                );
              case KindTypes.SubmittedAttendance:
                return /* @__PURE__ */ jsx(
                  SubmittedAttendanceTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `submitted-req-${idx}`
                );
              case KindTypes.UnfinishedAttendance:
                return /* @__PURE__ */ jsx(
                  UnfinishedAttendanceShiftTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `unfinished-shift-${idx}`
                );
              case KindTypes.MissingDocuments:
                return /* @__PURE__ */ jsx(
                  MissingDocumentsTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `missing-docs-${idx}`
                );
              case KindTypes.PendingContracts:
                return /* @__PURE__ */ jsx(
                  PendingContractsTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `pending-contracts-${idx}`
                );
              case KindTypes.PendingReviews:
                return /* @__PURE__ */ jsx(PendingReviewsTodo, { setLastChild, todo }, `pending-reviews-${idx}`);
              case KindTypes.PendingSurveys:
                return /* @__PURE__ */ jsx(PendingSurveysTodo, { setLastChild, todo }, `pending-surveys-${idx}`);
              case KindTypes.SubmittedRequestForm:
                return /* @__PURE__ */ jsx(
                  SubmittedRequestBigTodo,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `submitted-request-${idx}`
                );
              case KindTypes.DeviceTransit:
                return /* @__PURE__ */ jsx(
                  DeviceTransitTaskComponent,
                  {
                    todo,
                    setLastChild,
                    refreshTodos
                  },
                  `device-transit-${idx}`
                );
              default:
                return /* @__PURE__ */ jsx(Fragment, {}, idx);
            }
          }),
          Boolean(((_d = todos == null ? void 0 : todos.personal) == null ? void 0 : _d.totalCount) && ((_e = todos == null ? void 0 : todos.personal) == null ? void 0 : _e.totalCount) - limit > 0) && /* @__PURE__ */ jsx(
            "div",
            {
              onClick: () => routerHistory.push(PEOPLE_ME_ACTION_ITEMS),
              role: "button",
              className: "clickable",
              style: { padding: spacing.p5 },
              children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey }, children: ((_f = todos == null ? void 0 : todos.personal) == null ? void 0 : _f.totalCount) ? polyglot.t("UserTodosVersionBig.more", {
                smart_count: ((_g = todos == null ? void 0 : todos.personal) == null ? void 0 : _g.totalCount) && ((_h = todos == null ? void 0 : todos.personal) == null ? void 0 : _h.totalCount) - limit
              }) : "" })
            }
          )
        ]
      }
    ) })
  ] }) });
};
