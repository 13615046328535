"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import "./document-editor.component.css";
import "@rc-component/color-picker/assets/index.css";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, MenuItem, Popover } from "@mui/material";
import { default as Document } from "@tiptap/extension-document";
import { default as Dropcursor } from "@tiptap/extension-dropcursor";
import { default as FontFamily } from "@tiptap/extension-font-family";
import { default as Gapcursor } from "@tiptap/extension-gapcursor";
import { default as Link } from "@tiptap/extension-link";
import { default as Table } from "@tiptap/extension-table";
import { default as TableCell } from "@tiptap/extension-table-cell";
import { default as TableHeader } from "@tiptap/extension-table-header";
import { default as TableRow } from "@tiptap/extension-table-row";
import { default as Text } from "@tiptap/extension-text";
import { default as TextAlign } from "@tiptap/extension-text-align";
import { default as TextStyle } from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import { default as StarterKit } from "@tiptap/starter-kit";
import FontSizeExtension from "tiptap-extension-font-size";
import useMessage from "@/hooks/notification.hook";
import { EditorButtons } from "@/v2/components/forms/document-editor/components/editor-buttons/editor-buttons.component";
import { ImageDimensionsEditor } from "@/v2/components/forms/document-editor/components/image-dimensions-editor/image-dimensions-editor.component";
import { SideBar } from "@/v2/components/forms/document-editor/components/side-bar/side-bar.components";
import {
  cleanHTML
} from "@/v2/components/forms/document-editor/document-editor.util";
import {
  ColorExtension,
  setDefaultColor
} from "@/v2/components/forms/document-editor/tiptap/extensions/color.extension";
import { ClickExtension } from "@/v2/components/forms/document-editor/tiptap/extensions/on-click.extension";
import { CopyExtension } from "@/v2/components/forms/document-editor/tiptap/extensions/on-copy.extension";
import { HandleDropExtension } from "@/v2/components/forms/document-editor/tiptap/extensions/on-drop.extension";
import { HoverExtension } from "@/v2/components/forms/document-editor/tiptap/extensions/on-hover.extension";
import {
  parseFontFamily,
  PasteExtension
} from "@/v2/components/forms/document-editor/tiptap/extensions/on-paste.extension";
import { LineHeightMark } from "@/v2/components/forms/document-editor/tiptap/marks/line-height.mark";
import { SmartFieldMark } from "@/v2/components/forms/document-editor/tiptap/marks/smart-field.mark";
import { CustomImage } from "@/v2/components/forms/document-editor/tiptap/nodes/image.node";
import { CustomParagraphNode } from "@/v2/components/forms/document-editor/tiptap/nodes/paragraph.node";
import {
  SMART_FIELD_CUSTOM_MARKER,
  SmartFieldNode,
  updateSignature,
  updateStylingOnCompletedFields
} from "@/v2/components/forms/document-editor/tiptap/nodes/smart-field.node";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
export const DocumentEditor = React.memo(
  ({ onChange, content, mode, action, onSign, signer, signature, editable = true }) => {
    const [color, setColor] = useState("black");
    const [showMessage] = useMessage();
    const [anchorEl, setAnchorEl] = useState();
    const internalOnSign = useRef();
    useEffect(() => {
      internalOnSign.current = onSign;
    }, [onSign]);
    const [showImageModal, setShowImageModal] = useState(false);
    const [editorPosition, setEditorPosition] = useState({ top: 0, left: 0 });
    const handleHover = (view, event) => {
      var _a, _b, _c;
      if (((_a = event == null ? void 0 : event.target) == null ? void 0 : _a.localName) === "a" || ((_c = (_b = event.target) == null ? void 0 : _b.parentElement) == null ? void 0 : _c.localName) === "a") {
        setAnchorEl({ top: event == null ? void 0 : event.y, left: event == null ? void 0 : event.x, option: "link", target: event.target });
      }
    };
    const handleClick = (view, event) => {
      var _a;
      if (((_a = event == null ? void 0 : event.target) == null ? void 0 : _a.localName) === "img") {
        setAnchorEl({
          top: event == null ? void 0 : event.clientY,
          left: event == null ? void 0 : event.clientX,
          option: "image",
          height: event.target.height,
          width: event.target.width,
          text: event.target.innerText,
          target: event.target
        });
      }
    };
    const showErrorMessage = (message) => {
      showMessage(message, "error");
    };
    const handleSmartFieldClick = (type) => {
      var _a;
      if (type.includes("sign")) (_a = internalOnSign.current) == null ? void 0 : _a.call(internalOnSign);
    };
    const editor = useEditor({
      editorProps: {
        attributes: {
          class: "ProseMirror-document tiptap-document"
        }
      },
      extensions: [
        Document,
        CustomParagraphNode,
        Text,
        TextStyle,
        ColorExtension,
        FontFamily.configure({
          types: ["textStyle"]
        }),
        Gapcursor.configure({
          types: ["span"]
        }),
        Dropcursor,
        Table.configure({
          resizable: true,
          HTMLAttributes: {
            style: "width:100%"
          }
        }),
        TableRow,
        TableHeader,
        TableCell,
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: false
          }
        }),
        CustomImage.configure({
          inline: true,
          allowBase64: true
        }),
        FontSizeExtension,
        LineHeightMark,
        TextAlign.configure({
          types: ["heading", "paragraph"]
        }),
        Link.configure({
          openOnClick: false,
          autolink: true,
          protocols: ["https"]
        }),
        HandleDropExtension.configure({
          handleError: showErrorMessage,
          mode
        }),
        PasteExtension.configure({
          handleError: showErrorMessage
        }),
        HoverExtension.configure({
          onHover: handleHover
        }),
        ClickExtension.configure({
          onClick: handleClick
        }),
        CopyExtension,
        SmartFieldMark,
        SmartFieldNode.configure({
          handleClick: handleSmartFieldClick,
          signer,
          mode
        })
      ],
      editable,
      content: parseFontFamily(content),
      onUpdate(props) {
        onChange(cleanHTML(props.editor.getHTML()));
      },
      onSelectionUpdate({ editor: editor2 }) {
        const color2 = editor2.getAttributes("textStyle").color;
        setColor(color2 ? color2 : "black");
      }
    });
    const setLink = useCallback(() => {
      if (!editor) {
        return;
      }
      const previousUrl = editor == null ? void 0 : editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);
      if (url === null) {
        return;
      }
      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().setColor("black").run();
        return;
      }
      editor.chain().focus().extendMarkRange("link").setLink({ href: url }).setColor("blue").run();
    }, [editor]);
    const handleLinkOption = (option) => {
      switch (option) {
        case "edit":
          setLink();
          break;
        case "remove":
          editor == null ? void 0 : editor.chain().focus().extendMarkRange("link").unsetLink().setColor("black").run();
          break;
      }
      setAnchorEl(void 0);
    };
    const handleImage = (option) => {
      switch (option) {
        case "edit":
          setShowImageModal(true);
          break;
        case "remove":
          editor == null ? void 0 : editor.chain().focus().deleteSelection().run();
          setAnchorEl(void 0);
          break;
      }
    };
    const handlePopover = (option) => {
      switch (anchorEl == null ? void 0 : anchorEl.option) {
        case "link":
          handleLinkOption(option);
          break;
        case "image":
          handleImage(option);
          break;
      }
    };
    const scrollToSignatureAndOpenModal = () => {
      if (!editor) return;
      const contentElement = editor.view.dom;
      const elements = contentElement.querySelectorAll(`.${SMART_FIELD_CUSTOM_MARKER}-sign`);
      for (const element of elements) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
      onSign == null ? void 0 : onSign();
    };
    useEffect(() => {
      var _a, _b;
      if (!editor) {
        return;
      }
      if (!content) {
        return;
      }
      const innerHTML = signature ? (_b = (_a = editor == null ? void 0 : editor.view) == null ? void 0 : _a.dom) == null ? void 0 : _b.innerHTML : content;
      let initialContent = updateStylingOnCompletedFields(mode, setDefaultColor(parseFontFamily(innerHTML)));
      if (signature && signature !== "") {
        initialContent = updateSignature(mode, signature, initialContent);
      }
      editor.chain().focus().setContent(initialContent, false).setColor("black").setTextSelection(0).run();
    }, [content, editor, mode, action, signature]);
    useEffect(() => {
      if (!editor) {
        return;
      }
      editor.setEditable(editable);
      setAnchorEl(void 0);
    }, [editor, editable]);
    const editorButtons = useMemo(() => {
      if (!editor) {
        return null;
      }
      return /* @__PURE__ */ jsx(EditorButtons, { editor, setLink, color, setColor });
    }, [editor, color, setLink, setColor]);
    useEffect(() => {
      if (!editor) {
        return void 0;
      }
      const updateEditorPosition = () => {
        const { x, width, top } = editor.view.dom.getBoundingClientRect();
        if (top <= 0) return;
        setEditorPosition({ top, left: x + width });
      };
      updateEditorPosition();
      window.addEventListener("resize", updateEditorPosition);
      return () => {
        window.removeEventListener("resize", updateEditorPosition);
      };
    }, [editor]);
    if (!editor) {
      return null;
    }
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Box, { className: "document-container", children: [
        editable && /* @__PURE__ */ jsx(SideBar, {}),
        /* @__PURE__ */ jsxs(Box, { style: { display: "flex", flexDirection: "column", width: "100%", marginLeft: editable ? "300px" : 0 }, children: [
          /* @__PURE__ */ jsx(Box, { children: editable && editorButtons }),
          /* @__PURE__ */ jsx(
            Box,
            {
              style: {
                display: "flex",
                backgroundColor: themeColors.lightGrey,
                justifyContent: "center",
                minWidth: "100%",
                overflowY: "scroll"
              },
              children: /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    mt: "1%",
                    justifyContent: "center",
                    alignContent: "center"
                  },
                  className: "document-editor-container",
                  onClick: () => {
                    if (!editor.isFocused) {
                      editor.view.dom.focus();
                    }
                  },
                  children: /* @__PURE__ */ jsx(EditorContent, { editor })
                }
              )
            }
          )
        ] })
      ] }),
      anchorEl !== void 0 && editable && !showImageModal && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
        Popover,
        {
          id: "basic-menu",
          onClose: () => setAnchorEl(void 0),
          anchorPosition: anchorEl,
          open: true,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          sx: { minWidth: "36px" },
          style: {
            top: anchorEl == null ? void 0 : anchorEl.top,
            left: anchorEl == null ? void 0 : anchorEl.left
          },
          disableRestoreFocus: true,
          children: [
            /* @__PURE__ */ jsx(MenuItem, { onClick: () => handlePopover("edit"), children: anchorEl.text ? `Edit: ${anchorEl.text}` : "Edit" }),
            /* @__PURE__ */ jsx(MenuItem, { onClick: () => handlePopover("remove"), children: anchorEl.text ? `Remove: ${anchorEl.text}` : "Remove" })
          ]
        }
      ) }),
      /* @__PURE__ */ jsx(
        ImageDimensionsEditor,
        {
          editor,
          anchorEl,
          setAnchorEl,
          setShowImageModal,
          showImageModal
        }
      ),
      mode === "readonly" && (action == null ? void 0 : action.includes("sign")) && signer && (content.includes("{{additional_signature}}") || content.includes("{{employee_signature}}")) && editorPosition !== void 0 && /* @__PURE__ */ jsx(
        Box,
        {
          style: {
            position: "absolute",
            top: editorPosition.top,
            left: editorPosition.left,
            cursor: "pointer",
            height: "25px",
            width: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          },
          className: "smart-field-custom-marker",
          onClick: () => scrollToSignatureAndOpenModal(),
          children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Sign" })
        }
      )
    ] });
  }
);
