"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { canInviteAgainStatuses } from "@v2/feature/user/user.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { TemplateAPI } from "@/api-client/templates.api";
import { UserDetailsScopes } from "@/component/dashboard/userDetails/user-details.scopes";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Eye } from "@/images/side-bar-icons/Eye.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  ADMIN_USER_DOCUMENTS_NEW_ROUTE,
  ONBOARDING_NEW_USER_ROUTE,
  ONBOARDING_ROUTE,
  ONBOARDING_USER_STATUS_APPS_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ROUTE,
  ONBOARDING_USER_STATUS_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE,
  USER_APPS_TAB,
  USER_DEVICES_PAGE,
  USER_PAYROLL_PAYSLIPS,
  USER_TASKS_PAGE,
  USER_WORK_TAB
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { AccountStatus, copyInviteLink, getInviteLink, inviteUser } from "@/lib/users";
import { AppIconsList } from "@/v2/components/app-icons-list.component";
import { Divider } from "@/v2/components/divider.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DeviceScopes } from "@/v2/feature/device/device.scopes";
import { DocumentsScopes } from "@/v2/feature/documents/documents.scopes";
import { EditIdVerify } from "@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component";
import { OnboardingScopes } from "@/v2/feature/onboarding/onboarding.scopes";
import { DocPreviewer } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { PayrollScopes } from "@/v2/feature/payroll/payroll.scopes";
import { TaskScopes } from "@/v2/feature/task/task.scopes";
import { ContractModal } from "@/v2/feature/templates/components/contract-modal.component";
import { MissingTemplateFieldModal } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { TemplatesScopes } from "@/v2/feature/templates/templates.scopes";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import {
  ID_CHECK_COMPLETED_STATES,
  MANUAL_RTW_CHECK
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import {
  OnboardingActionCard
} from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-action-card.component";
import { OnboardingUserStats } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-overview/components/onboarding-user-stats.component";
import {
  doesErrorRequireCompanyToIncreaseFixedSeatsLimit,
  doesErrorRequireCompanyToUpgrade
} from "@/v2/infrastructure/restrictions/restriction.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
const OnboardingUserStatus = ({ onboarding }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const { getCachedUserById, refreshCachedUsers } = useCachedUsers();
  const user = getCachedUserById(onboarding.userId);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [seatsLimitError, setSeatsLimitError] = useState(false);
  const [devicesStats, setDevicesStats] = useState();
  const [idCheckState, setIdCheckState] = useState();
  const [loadingIdCheck, setLoadingIdCheck] = useState(true);
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [contractStatus, setContractStatus] = useState();
  const [loadingContractData, setLoadingContractData] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [contractModalStep2, setContractModalStep2] = useState(false);
  const [contractModalStep3, setContractModalStep3] = useState(false);
  const [contractModalStep4, setContractModalStep4] = useState(false);
  const [contractModalStep5, setContractModalStep5] = useState(false);
  const [initiateAdvanceIdCheck, setInitiateAdvanceIdCheck] = useState(false);
  const [contractTemplate, setContractTemplate] = useState();
  const [downloadingIdCheckReport, setDownloadingIdCheckReport] = useState(false);
  const [selectedIdCheckReportBuffer, setSelectedIdCheckReportBuffer] = useState();
  const [idCheckReportPreviewModalOpen, setIdCheckReportPreviewModalOpen] = useState(false);
  const onboardingUserForScopeCheck = __spreadValues(__spreadValues({}, user), onboarding.userPermissions);
  const onboardingUserHasTaskScope = checkScopes(onboardingUserForScopeCheck, ["task"], { userId: onboarding.userId });
  const onboardingUserHasDocumentScope = checkScopes(onboardingUserForScopeCheck, ["documents:read"], {
    userId: onboarding.userId
  });
  const onboardingUserHasPayrollScope = checkScopes(onboardingUserForScopeCheck, ["payroll:read"], {
    userId: onboarding.userId
  });
  const [canInviteUser, canSendContract] = useMemo(
    () => [
      checkScopes(currentUser, OnboardingScopes.INVITE_USER),
      checkScopes(currentUser, TemplatesScopes.SEND_CONTRACT)
    ],
    [currentUser]
  );
  const idCheckButtonText = useMemo(() => {
    if (!Array.isArray(idCheckState) || idCheckState.length === 0) {
      return polyglot.t("OnboardingUserStatus.invite");
    }
    const hasPendingChecks = idCheckState.some((check) => check.status === "pending");
    if (hasPendingChecks) {
      return polyglot.t("OnboardingUserStatus.invited");
    }
    const allChecksComplete = idCheckState.every(
      (check) => check.status && ID_CHECK_COMPLETED_STATES.has(check.status)
    );
    if (allChecksComplete) {
      return polyglot.t("OnboardingUserStatus.result");
    }
    return polyglot.t("OnboardingUserStatus.invite");
  }, [polyglot, idCheckState]);
  const idCheckChips = useMemo(() => {
    if (!Array.isArray(onboarding.template.idverify)) return [];
    return onboarding.template.idverify.map((config) => {
      var _a2;
      return (_a2 = config.check) == null ? void 0 : _a2.name;
    }).filter(Boolean).map((name) => `${name} checks`);
  }, [onboarding]);
  const refreshIdentityCheckResult = useCallback((userId) => __async(void 0, null, function* () {
    setLoadingIdCheck(true);
    if (userId) {
      const result = yield OnboardingAPI.refreshIdentityCheckResult(userId);
      const idCheckForOnboarding = (result == null ? void 0 : result.length) > 0 ? result : void 0;
      setIdCheckState(idCheckForOnboarding);
    }
    setLoadingIdCheck(false);
  }), []);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      const canReadDeviceInventory = checkScopes(currentUser, DeviceScopes.READ_COMPANY_INVENTORY);
      if (onboarding.template.device && !onboarding.state.device && canReadDeviceInventory) {
        const result = yield DeviceAPI.getDevicesInventoryStats();
        setDevicesStats(result);
      }
      if (onboarding.template.idverify) {
        yield refreshIdentityCheckResult(onboarding.userId);
      }
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [currentUser, onboarding, refreshIdentityCheckResult, showMessage, polyglot]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const [taskCount, tasksCompleted] = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i;
    if (!user) return [0, 0];
    let count = 0, completed = 0;
    count++;
    if (onboarding.hasEmploymentInfo) {
      completed++;
    }
    count++;
    if (![AccountStatus.Created, AccountStatus.Invited].includes(user.accountStatus)) {
      completed++;
    }
    if (onboarding.template.device) {
      count++;
      if (onboarding.state.device) completed++;
    }
    if (onboarding.template.apps) {
      count++;
      if ((_a2 = onboarding.state.apps) == null ? void 0 : _a2.items.every((item) => !!item.accountStatus)) completed++;
    }
    if (onboarding.template.contracts) {
      const { templateId } = onboarding.template.contracts;
      count++;
      if ((_b2 = onboarding.state.contracts) == null ? void 0 : _b2.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step2) {
      const { templateId } = onboarding.template.contracts_step2;
      count++;
      if ((_c2 = onboarding.state.contracts_step2) == null ? void 0 : _c2.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step3) {
      const { templateId } = onboarding.template.contracts_step3;
      count++;
      if ((_d2 = onboarding.state.contracts_step3) == null ? void 0 : _d2.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step4) {
      const { templateId } = onboarding.template.contracts_step4;
      count++;
      if ((_e2 = onboarding.state.contracts_step4) == null ? void 0 : _e2.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.contracts_step5) {
      const { templateId } = onboarding.template.contracts_step5;
      count++;
      if ((_f2 = onboarding.state.contracts_step5) == null ? void 0 : _f2.items.some((item) => item.templateId === templateId)) completed++;
    }
    if (onboarding.template.tasks) {
      count++;
      if ((_g2 = onboarding.state.tasks) == null ? void 0 : _g2.items.every((item) => item.status === "Complete")) completed++;
    }
    if (onboarding.template.documents) {
      count++;
      if ((_h2 = onboarding.state.documents) == null ? void 0 : _h2.items.every((item) => item.uploaded > 0)) completed++;
    }
    if (onboarding.template.payroll) {
      count++;
      if ((_i = onboarding.state.payroll) == null ? void 0 : _i.createdAt) completed++;
    }
    return [count, completed];
  }, [onboarding, user]);
  const startDeviceSelection = useCallback(
    (deviceStore) => {
      const path = {
        new: () => generatePath(ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE, { userId: onboarding.userId }),
        existing: () => generatePath(ONBOARDING_USER_STATUS_DEVICE_ROUTE, { userId: onboarding.userId })
      }[deviceStore]();
      routerHistory.push(path);
    },
    [onboarding.userId, routerHistory]
  );
  const getDeviceChipLabels = useCallback((state2) => {
    if (!state2.device) return [];
    return state2.device.items.map((p) => p.serialNumber ? `${p.modelName} / ${p.serialNumber}` : p.modelName);
  }, []);
  const closeUserStatusPage = useCallback(() => routerHistory.goBack(), [routerHistory]);
  useEscapeKey(closeUserStatusPage);
  const inviteUserWrapper = useCallback(() => __async(void 0, null, function* () {
    try {
      yield inviteUser(onboarding.userId, showMessage, polyglot, "onboard");
      yield refreshCachedUsers();
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(false);
      } else if (doesErrorRequireCompanyToIncreaseFixedSeatsLimit(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(true);
      } else {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    }
  }), [showMessage, polyglot, onboarding.userId, refreshCachedUsers]);
  const copyInviteLinkHandler = useCallback(() => __async(void 0, null, function* () {
    try {
      const inviteLink = yield getInviteLink(onboarding.userId, showMessage, polyglot, "onboard");
      if (!inviteLink) return;
      yield copyInviteLink(inviteLink, showMessage, polyglot);
      yield refreshCachedUsers();
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(false);
      } else if (doesErrorRequireCompanyToIncreaseFixedSeatsLimit(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(true);
      } else {
        showMessage(
          polyglot.t("OnboardingUserInvite.errorMessages.copyInvite", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }
  }), [onboarding.userId, showMessage, polyglot, refreshCachedUsers]);
  const initateIdentityChecks = useCallback(() => __async(void 0, null, function* () {
    setLoadingIdCheck(true);
    try {
      if ((user == null ? void 0 : user.accountStatus) && !canInviteAgainStatuses.includes(user.accountStatus) && !(currentUser == null ? void 0 : currentUser.hasPaymentMethodOnFile)) {
        setUpgradeModalOpen(true);
      } else {
        const result = yield OnboardingAPI.initiateIdentityChecks(onboarding.userId);
        setIdCheckState(result);
        showMessage(polyglot.t("OnboardingUserStatus.successMessages.invite"), "success");
      }
    } catch (error) {
      showMessage(
        polyglot.t("OnboardingUserStatus.errorMessages.initiate", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoadingIdCheck(false);
    }
  }), [onboarding, user == null ? void 0 : user.accountStatus, currentUser == null ? void 0 : currentUser.hasPaymentMethodOnFile, showMessage, polyglot]);
  const updateContractStatus = (templateResult) => {
    setContractStatus((prevStatus) => {
      const updatedStatus = prevStatus ? [...prevStatus] : [];
      const index = prevStatus == null ? void 0 : prevStatus.findIndex(
        (status) => {
          var _a2, _b2;
          return ((_a2 = status == null ? void 0 : status.template) == null ? void 0 : _a2.id) === ((_b2 = templateResult == null ? void 0 : templateResult.template) == null ? void 0 : _b2.id);
        }
      );
      if (index !== void 0 && index !== null && index >= 0) {
        updatedStatus[index] = templateResult;
        return updatedStatus;
      }
      return prevStatus ? [...prevStatus, templateResult] : [templateResult];
    });
  };
  const refreshMissingFieldsForTemplate = useCallback(
    (templateId, contractRecipientUserId, companySignatoryUserId) => __async(void 0, null, function* () {
      try {
        setLoadingContractData(true);
        const templateResult = yield TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId,
          companySignatoryUserId: companySignatoryUserId ? companySignatoryUserId : void 0
        });
        const { template } = templateResult;
        if (template) setContractTemplate(template);
        updateContractStatus(templateResult);
      } catch (error) {
        showMessage(
          polyglot.t("OnboardingUserStatus.errorMessages.verify", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoadingContractData(false);
      }
    }),
    [polyglot, showMessage]
  );
  const contractStatusForStep1 = useMemo(() => {
    var _a2, _b2;
    return ((_b2 = (_a2 = onboarding.template) == null ? void 0 : _a2.contracts) == null ? void 0 : _b2.templateId) ? contractStatus == null ? void 0 : contractStatus.find(
      (status) => {
        var _a3;
        return status.template && status.template.id === ((_a3 = onboarding.template.contracts) == null ? void 0 : _a3.templateId);
      }
    ) : void 0;
  }, [contractStatus, onboarding.template.contracts]);
  const contractStatusForStep2 = useMemo(() => {
    var _a2, _b2;
    return ((_b2 = (_a2 = onboarding.template) == null ? void 0 : _a2.contracts_step2) == null ? void 0 : _b2.templateId) ? contractStatus == null ? void 0 : contractStatus.find(
      (status) => {
        var _a3;
        return status.template && status.template.id === ((_a3 = onboarding.template.contracts_step2) == null ? void 0 : _a3.templateId);
      }
    ) : void 0;
  }, [contractStatus, onboarding.template.contracts_step2]);
  const contractStatusForStep3 = useMemo(() => {
    var _a2, _b2;
    return ((_b2 = (_a2 = onboarding.template) == null ? void 0 : _a2.contracts_step3) == null ? void 0 : _b2.templateId) ? contractStatus == null ? void 0 : contractStatus.find(
      (status) => {
        var _a3;
        return status.template && status.template.id === ((_a3 = onboarding.template.contracts_step3) == null ? void 0 : _a3.templateId);
      }
    ) : void 0;
  }, [contractStatus, onboarding.template.contracts_step3]);
  const contractStatusForStep4 = useMemo(() => {
    var _a2, _b2;
    return ((_b2 = (_a2 = onboarding.template) == null ? void 0 : _a2.contracts_step4) == null ? void 0 : _b2.templateId) ? contractStatus == null ? void 0 : contractStatus.find(
      (status) => {
        var _a3;
        return status.template && status.template.id === ((_a3 = onboarding.template.contracts_step4) == null ? void 0 : _a3.templateId);
      }
    ) : void 0;
  }, [contractStatus, onboarding.template.contracts_step4]);
  const contractStatusForStep5 = useMemo(() => {
    var _a2, _b2;
    return ((_b2 = (_a2 = onboarding.template) == null ? void 0 : _a2.contracts_step5) == null ? void 0 : _b2.templateId) ? contractStatus == null ? void 0 : contractStatus.find(
      (status) => {
        var _a3;
        return status.template && status.template.id === ((_a3 = onboarding.template.contracts_step5) == null ? void 0 : _a3.templateId);
      }
    ) : void 0;
  }, [contractStatus, onboarding.template.contracts_step5]);
  useEffect(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i, _j;
    const { template, userId } = onboarding;
    if ((_a2 = template == null ? void 0 : template.contracts) == null ? void 0 : _a2.templateId) refreshMissingFieldsForTemplate((_b2 = template == null ? void 0 : template.contracts) == null ? void 0 : _b2.templateId, userId, 0);
    if ((_c2 = template == null ? void 0 : template.contracts_step2) == null ? void 0 : _c2.templateId)
      refreshMissingFieldsForTemplate((_d2 = template == null ? void 0 : template.contracts_step2) == null ? void 0 : _d2.templateId, userId, 0);
    if ((_e2 = template == null ? void 0 : template.contracts_step3) == null ? void 0 : _e2.templateId)
      refreshMissingFieldsForTemplate((_f2 = template == null ? void 0 : template.contracts_step3) == null ? void 0 : _f2.templateId, userId, 0);
    if ((_g2 = template == null ? void 0 : template.contracts_step4) == null ? void 0 : _g2.templateId)
      refreshMissingFieldsForTemplate((_h2 = template == null ? void 0 : template.contracts_step4) == null ? void 0 : _h2.templateId, userId, 0);
    if ((_i = template == null ? void 0 : template.contracts_step5) == null ? void 0 : _i.templateId)
      refreshMissingFieldsForTemplate((_j = template == null ? void 0 : template.contracts_step5) == null ? void 0 : _j.templateId, userId, 0);
  }, [onboarding, refreshMissingFieldsForTemplate]);
  const handlePreviewIdCheckReport = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    if (!Array.isArray(idCheckState) || idCheckState.length === 0) return;
    setDownloadingIdCheckReport(true);
    try {
      for (const check of idCheckState) {
        const { requestId } = check;
        if (!requestId || requestId === MANUAL_RTW_CHECK) continue;
        const reportResponse = yield OnboardingAPI.downloadIdCheckReport(check);
        if ((_a2 = reportResponse == null ? void 0 : reportResponse.file) == null ? void 0 : _a2.data) {
          setSelectedIdCheckReportBuffer(reportResponse.file);
          setIdCheckReportPreviewModalOpen(true);
        }
      }
    } catch (error) {
      showMessage(
        polyglot.t("IdCheckPackage.errorMessages.failedToDownloadReport", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setDownloadingIdCheckReport(false);
    }
  }), [idCheckState, polyglot, showMessage]);
  const deviceChips = useMemo(() => {
    if (!devicesStats) return [];
    return !!onboarding.state.device ? getDeviceChipLabels(onboarding.state) : [polyglot.t("OnboardingUserStatus.orderOrSelect")];
  }, [polyglot, devicesStats, getDeviceChipLabels, onboarding.state]);
  const onMissingField = (params) => {
    const { templateVerificationResult } = params;
    updateContractStatus(templateVerificationResult);
    setOpenMissingFieldContractModal(true);
  };
  const alreadyInvited = useMemo(() => {
    if (!user) return false;
    return ![AccountStatus.Created, AccountStatus.Invited].includes(user.accountStatus);
  }, [user]);
  if (!user) {
    routerHistory.replace(ONBOARDING_ROUTE);
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  const divider = /* @__PURE__ */ jsx(Divider, { color: "#f8f3ef" });
  const modalForCurrentContract = (contractConfig) => {
    return contractConfig.templateId === (contractTemplate == null ? void 0 : contractTemplate.id);
  };
  const addMissingScopeChip = (chips, hasScope, missingScope, chipType = "warning") => {
    if (hasScope) {
      return chips;
    }
    const missingScopeChip = {
      text: `Missing ${missingScope} scope`,
      type: chipType
    };
    return [...chips, missingScopeChip];
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingUserStatus.onboardUser", {
          firstName: polyglot.t((_a = user.firstName) != null ? _a : ""),
          lastName: polyglot.t((_b = user.lastName) != null ? _b : "")
        }) }),
        backPath: ONBOARDING_ROUTE,
        showBack: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, border: false, sx: __spreadProps(__spreadValues({}, spacing.pt20), { mx: spacing.mx40 }), children: [
      /* @__PURE__ */ jsx(
        OnboardingUserStats,
        {
          progressPercent: taskCount && 100 * tasksCompleted / taskCount,
          pendingTaskCount: taskCount - tasksCompleted,
          startDate: user.startDate
        }
      ),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt40, maxWidth: "800px" }, children: [
        /* @__PURE__ */ jsx(
          OnboardingActionCard,
          {
            label: polyglot.t("OnboardingUserStatus.employmentInfo"),
            owner: "company",
            completed: onboarding.hasEmploymentInfo,
            startButtonLabel: polyglot.t("General.start"),
            onStartClick: (
              //canSetupUser && (
              () => routerHistory.push(ONBOARDING_NEW_USER_ROUTE, { newUserId: user.userId })
            ),
            completedButton: /* @__PURE__ */ jsx(ScopesControl, { scopes: UserDetailsScopes.VIEW_USER_WORK, context: { userId: onboarding.userId }, children: /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                colorVariant: "secondary",
                fullWidth: true,
                sizeVariant: "small",
                onClick: () => routerHistory.push(generatePath(USER_WORK_TAB, { userId: onboarding.userId })),
                children: polyglot.t("OnboardingUserStatus.view")
              }
            ) })
          }
        ),
        divider,
        /* @__PURE__ */ jsx(
          OnboardingActionCard,
          {
            label: polyglot.t("OnboardingUserStatus.inviteNewJoiner"),
            owner: "company",
            completed: alreadyInvited,
            startButtonLabel: polyglot.t("General.invite"),
            disabled: !canInviteUser,
            actionButton: /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: [
                  {
                    handler: () => __async(void 0, null, function* () {
                      return yield inviteUserWrapper();
                    }),
                    label: polyglot.t("General.invite"),
                    disabled: false
                  },
                  {
                    handler: () => __async(void 0, null, function* () {
                      return yield copyInviteLinkHandler();
                    }),
                    label: polyglot.t("General.copyInvite"),
                    disabled: false
                  }
                ],
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "primary",
                  sizeVariant: "small",
                  title: polyglot.t("General.invite"),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end",
                  fullWidth: true
                }
              }
            ),
            completedButton: /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                options: [
                  {
                    handler: () => __async(void 0, null, function* () {
                      return yield inviteUserWrapper();
                    }),
                    label: polyglot.t("General.inviteAgain"),
                    disabled: false
                  },
                  {
                    handler: () => __async(void 0, null, function* () {
                      return yield copyInviteLinkHandler();
                    }),
                    label: polyglot.t("General.copyInvite"),
                    disabled: false
                  }
                ],
                actionButtonDetails: {
                  type: "button",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: polyglot.t("General.invite"),
                  icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                  iconPosition: "end",
                  fullWidth: true
                }
              }
            )
          }
        ),
        onboarding.template.apps && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.apps"),
              owner: "company",
              chips: [/* @__PURE__ */ jsx(AppIconsList, { stubs: onboarding.template.apps.stubs })],
              completed: !!((_c = onboarding.state.apps) == null ? void 0 : _c.items.every((item) => !!item.accountStatus)),
              disabled: !onboarding.hasEmploymentInfo,
              onStartClick: () => routerHistory.push(generatePath(ONBOARDING_USER_STATUS_APPS_ROUTE, { userId: onboarding.userId })),
              completedButton: /* @__PURE__ */ jsx(ScopesControl, { scopes: AppScopes.VIEW_USER_APPS, children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  onClick: () => routerHistory.push(generatePath(USER_APPS_TAB, { userId: onboarding.userId })),
                  colorVariant: "secondary",
                  fullWidth: true,
                  sizeVariant: "small",
                  children: polyglot.t("OnboardingUserStatus.view")
                }
              ) })
            }
          )
        ] }),
        onboarding.template.contracts && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.contract"),
              owner: "company",
              completed: !!((_d = onboarding.state.contracts) == null ? void 0 : _d.items[0]),
              requiresAdminAction: (contractStatusForStep1 == null ? void 0 : contractStatusForStep1.hasMissingWorkFields) || (contractStatusForStep1 == null ? void 0 : contractStatusForStep1.hasAdditionalSignatory),
              startButtonLabel: (contractStatusForStep1 == null ? void 0 : contractStatusForStep1.hasMissingWorkFields) || (contractStatusForStep1 == null ? void 0 : contractStatusForStep1.hasAdditionalSignatory) ? polyglot.t("OnboardingUserStatus.complete") : polyglot.t("OnboardingUserStatus.prepare"),
              chips: addMissingScopeChip(
                [onboarding.template.contracts.templateName],
                onboardingUserHasDocumentScope,
                "document view"
              ),
              disabled: loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract,
              onStartClick: () => __async(void 0, null, function* () {
                if (!onboarding.template.contracts) return;
                const templateResult = yield TemplateAPI.verifyTemplateParameters({
                  templateId: onboarding.template.contracts.templateId,
                  contractRecipientUserId: onboarding.userId
                });
                updateContractStatus(templateResult);
                const {
                  hasMissingPersonalFields,
                  hasMissingWorkFields,
                  hasAdditionalSignatory,
                  template
                } = templateResult;
                if (template) setContractTemplate(template);
                if (hasAdditionalSignatory) {
                  setOpenContractModal(true);
                  return;
                }
                if (hasMissingPersonalFields || hasMissingWorkFields) {
                  setOpenMissingFieldContractModal(true);
                  return;
                }
                routerHistory.push(
                  generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                    templateId: onboarding.template.contracts.templateId,
                    userId: onboarding.userId
                  }),
                  { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                );
              })
            }
          )
        ] }),
        onboarding.template.contracts_step2 && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.contract"),
              owner: "company",
              completed: !!((_e = onboarding.state.contracts_step2) == null ? void 0 : _e.items[0]),
              requiresAdminAction: (contractStatusForStep2 == null ? void 0 : contractStatusForStep2.hasMissingWorkFields) || (contractStatusForStep2 == null ? void 0 : contractStatusForStep2.hasAdditionalSignatory),
              startButtonLabel: (contractStatusForStep2 == null ? void 0 : contractStatusForStep2.hasMissingWorkFields) || (contractStatusForStep2 == null ? void 0 : contractStatusForStep2.hasAdditionalSignatory) ? polyglot.t("OnboardingUserStatus.complete") : polyglot.t("OnboardingUserStatus.prepare"),
              chips: addMissingScopeChip(
                [onboarding.template.contracts_step2.templateName],
                onboardingUserHasDocumentScope,
                "document"
              ),
              disabled: loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract,
              onStartClick: () => __async(void 0, null, function* () {
                if (!onboarding.template.contracts_step2) return;
                const templateResult = yield TemplateAPI.verifyTemplateParameters({
                  templateId: onboarding.template.contracts_step2.templateId,
                  contractRecipientUserId: onboarding.userId
                });
                updateContractStatus(templateResult);
                const {
                  hasMissingPersonalFields,
                  hasMissingWorkFields,
                  hasAdditionalSignatory,
                  template
                } = templateResult;
                if (template) setContractTemplate(template);
                if (hasAdditionalSignatory) {
                  setContractModalStep2(true);
                  return;
                }
                if (hasMissingPersonalFields || hasMissingWorkFields) {
                  setOpenMissingFieldContractModal(true);
                  return;
                }
                routerHistory.push(
                  generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                    templateId: onboarding.template.contracts_step2.templateId,
                    userId: onboarding.userId
                  }),
                  { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                );
              })
            }
          )
        ] }),
        onboarding.template.contracts_step3 && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.contract"),
              owner: "company",
              completed: !!((_f = onboarding.state.contracts_step3) == null ? void 0 : _f.items[0]),
              requiresAdminAction: (contractStatusForStep3 == null ? void 0 : contractStatusForStep3.hasMissingWorkFields) || (contractStatusForStep3 == null ? void 0 : contractStatusForStep3.hasAdditionalSignatory),
              startButtonLabel: (contractStatusForStep3 == null ? void 0 : contractStatusForStep3.hasMissingWorkFields) || (contractStatusForStep3 == null ? void 0 : contractStatusForStep3.hasAdditionalSignatory) ? polyglot.t("OnboardingUserStatus.complete") : polyglot.t("OnboardingUserStatus.prepare"),
              chips: addMissingScopeChip(
                [onboarding.template.contracts_step3.templateName],
                onboardingUserHasDocumentScope,
                "document"
              ),
              disabled: loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract,
              onStartClick: () => __async(void 0, null, function* () {
                if (!onboarding.template.contracts_step3) return;
                const templateResult = yield TemplateAPI.verifyTemplateParameters({
                  templateId: onboarding.template.contracts_step3.templateId,
                  contractRecipientUserId: onboarding.userId
                });
                updateContractStatus(templateResult);
                const {
                  hasMissingPersonalFields,
                  hasMissingWorkFields,
                  hasAdditionalSignatory,
                  template
                } = templateResult;
                if (template) setContractTemplate(template);
                if (hasAdditionalSignatory) {
                  setContractModalStep3(true);
                  return;
                }
                if (hasMissingPersonalFields || hasMissingWorkFields) {
                  setOpenMissingFieldContractModal(true);
                  return;
                }
                routerHistory.push(
                  generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                    templateId: onboarding.template.contracts_step3.templateId,
                    userId: onboarding.userId
                  }),
                  { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                );
              })
            }
          )
        ] }),
        onboarding.template.contracts_step4 && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.contract"),
              owner: "company",
              completed: !!((_g = onboarding.state.contracts_step4) == null ? void 0 : _g.items[0]),
              requiresAdminAction: (contractStatusForStep4 == null ? void 0 : contractStatusForStep4.hasMissingWorkFields) || (contractStatusForStep4 == null ? void 0 : contractStatusForStep4.hasAdditionalSignatory),
              startButtonLabel: (contractStatusForStep4 == null ? void 0 : contractStatusForStep4.hasMissingWorkFields) || (contractStatusForStep4 == null ? void 0 : contractStatusForStep4.hasAdditionalSignatory) ? polyglot.t("OnboardingUserStatus.complete") : polyglot.t("OnboardingUserStatus.prepare"),
              chips: addMissingScopeChip(
                [onboarding.template.contracts_step4.templateName],
                onboardingUserHasDocumentScope,
                "document"
              ),
              disabled: loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract,
              onStartClick: () => __async(void 0, null, function* () {
                if (!onboarding.template.contracts_step4) return;
                const templateResult = yield TemplateAPI.verifyTemplateParameters({
                  templateId: onboarding.template.contracts_step4.templateId,
                  contractRecipientUserId: onboarding.userId
                });
                updateContractStatus(templateResult);
                const {
                  hasMissingPersonalFields,
                  hasMissingWorkFields,
                  hasAdditionalSignatory,
                  template
                } = templateResult;
                if (template) setContractTemplate(template);
                if (hasAdditionalSignatory) {
                  setContractModalStep4(true);
                  return;
                }
                if (hasMissingPersonalFields || hasMissingWorkFields) {
                  setOpenMissingFieldContractModal(true);
                  return;
                }
                routerHistory.push(
                  generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                    templateId: onboarding.template.contracts_step4.templateId,
                    userId: onboarding.userId
                  }),
                  { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                );
              })
            }
          )
        ] }),
        onboarding.template.contracts_step5 && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.contract"),
              owner: "company",
              completed: !!((_h = onboarding.state.contracts_step5) == null ? void 0 : _h.items[0]),
              requiresAdminAction: (contractStatusForStep5 == null ? void 0 : contractStatusForStep5.hasMissingWorkFields) || (contractStatusForStep5 == null ? void 0 : contractStatusForStep5.hasAdditionalSignatory),
              startButtonLabel: (contractStatusForStep5 == null ? void 0 : contractStatusForStep5.hasMissingWorkFields) || (contractStatusForStep5 == null ? void 0 : contractStatusForStep5.hasAdditionalSignatory) ? polyglot.t("OnboardingUserStatus.complete") : polyglot.t("OnboardingUserStatus.prepare"),
              chips: addMissingScopeChip(
                [onboarding.template.contracts_step5.templateName],
                onboardingUserHasDocumentScope,
                "document"
              ),
              disabled: loadingContractData || !onboarding.hasEmploymentInfo || !canSendContract,
              onStartClick: () => __async(void 0, null, function* () {
                if (!onboarding.template.contracts_step5) return;
                const templateResult = yield TemplateAPI.verifyTemplateParameters({
                  templateId: onboarding.template.contracts_step5.templateId,
                  contractRecipientUserId: onboarding.userId
                });
                updateContractStatus(templateResult);
                const {
                  hasMissingPersonalFields,
                  hasMissingWorkFields,
                  hasAdditionalSignatory,
                  template
                } = templateResult;
                if (template) setContractTemplate(template);
                if (hasAdditionalSignatory) {
                  setContractModalStep5(true);
                  return;
                }
                if (hasMissingPersonalFields || hasMissingWorkFields) {
                  setOpenMissingFieldContractModal(true);
                  return;
                }
                routerHistory.push(
                  generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_SEND_ROUTE, {
                    templateId: onboarding.template.contracts_step5.templateId,
                    userId: onboarding.userId
                  }),
                  { returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }) }
                );
              })
            }
          )
        ] }),
        onboarding.template.device && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.devices"),
              owner: "company",
              chips: deviceChips,
              completed: !!onboarding.state.device,
              disabled: !onboarding.hasEmploymentInfo,
              actionButton: /* @__PURE__ */ jsxs(Fragment, { children: [
                devicesStats && !devicesStats.noOfInventoryDevices && /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    onClick: () => startDeviceSelection("new"),
                    sizeVariant: "small",
                    fullWidth: true,
                    colorVariant: "primary",
                    children: polyglot.t("General.start")
                  }
                ),
                devicesStats && !!devicesStats.noOfInventoryDevices && /* @__PURE__ */ jsx(
                  StyledMenuComponent,
                  {
                    options: [
                      {
                        label: polyglot.t("OnboardingUserStatus.orderNew"),
                        handler: () => startDeviceSelection("new")
                      },
                      {
                        label: polyglot.t("OnboardingUserStatus.chooseExisting"),
                        handler: () => startDeviceSelection("existing")
                      }
                    ],
                    actionButtonDetails: {
                      type: "button",
                      colorVariant: "primary",
                      sizeVariant: "small",
                      fullWidth: true,
                      title: polyglot.t("General.complete")
                    }
                  }
                )
              ] }),
              completedButton: /* @__PURE__ */ jsx(ScopesControl, { scopes: DeviceScopes.VIEW_USER_DEVICES, children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  onClick: () => routerHistory.push(generatePath(USER_DEVICES_PAGE, { userId: onboarding.userId })),
                  colorVariant: "secondary",
                  fullWidth: true,
                  sizeVariant: "small",
                  children: polyglot.t("OnboardingUserStatus.view")
                }
              ) })
            }
          )
        ] }),
        onboarding.state.documents && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.documents"),
              owner: onboarding.userId,
              completed: onboarding.state.documents.items.every((item) => item.uploaded > 0),
              completedButton: /* @__PURE__ */ jsx(ScopesControl, { scopes: DocumentsScopes.VIEW_USER_DOCUMENTS, children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  colorVariant: "secondary",
                  fullWidth: true,
                  sizeVariant: "small",
                  onClick: () => routerHistory.push(generatePath(ADMIN_USER_DOCUMENTS_NEW_ROUTE, { userId: onboarding.userId })),
                  children: polyglot.t("OnboardingUserStatus.view")
                }
              ) }),
              chips: addMissingScopeChip(
                onboarding.state.documents.items.filter(({ uploaded }) => !uploaded).map(({ typeLabel }) => typeLabel),
                onboardingUserHasDocumentScope,
                "document"
              ),
              disabled: !onboarding.hasEmploymentInfo
            }
          )
        ] }),
        onboarding.state.payroll && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.payroll"),
              owner: onboarding.userId,
              completed: !!onboarding.state.payroll.createdAt,
              disabled: !onboarding.hasEmploymentInfo,
              chips: addMissingScopeChip([], onboardingUserHasPayrollScope, "payroll"),
              completedButton: /* @__PURE__ */ jsx(ScopesControl, { scopes: PayrollScopes.VIEW_USER_PAYSLIPS, children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  onClick: () => routerHistory.push(generatePath(USER_PAYROLL_PAYSLIPS, { userId: onboarding.userId })),
                  colorVariant: "secondary",
                  fullWidth: true,
                  sizeVariant: "small",
                  children: polyglot.t("OnboardingUserStatus.view")
                }
              ) })
            }
          )
        ] }),
        onboarding.state.tasks && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.tasks"),
              owner: onboarding.userId,
              completed: onboarding.state.tasks.items.every((item) => item.status === "Complete"),
              chips: addMissingScopeChip(
                [...new Set(onboarding.state.tasks.items.map(({ checklistName }) => checklistName))],
                onboardingUserHasTaskScope,
                "task"
              ),
              disabled: !onboarding.hasEmploymentInfo,
              completedButton: /* @__PURE__ */ jsx(ScopesControl, { scopes: TaskScopes.VIEW_USER_TASKS, children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  onClick: () => routerHistory.push(generatePath(USER_TASKS_PAGE, { userId: onboarding.userId })),
                  colorVariant: "secondary",
                  fullWidth: true,
                  sizeVariant: "small",
                  children: polyglot.t("OnboardingUserStatus.view")
                }
              ) })
            }
          )
        ] }),
        onboarding.template.idverify && /* @__PURE__ */ jsxs(Fragment, { children: [
          divider,
          /* @__PURE__ */ jsx(
            OnboardingActionCard,
            {
              label: polyglot.t("OnboardingUserStatus.identityChecks"),
              owner: "company",
              completed: Array.isArray(idCheckState) && idCheckState.length > 0 && idCheckState.every((check) => check.status && ID_CHECK_COMPLETED_STATES.has(check.status)),
              disabled: loadingIdCheck,
              chips: idCheckChips,
              startButtonLabel: idCheckButtonText,
              actionButton: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  disabled: loadingIdCheck || Array.isArray(idCheckState) && idCheckState.some((check) => check.status === "pending"),
                  colorVariant: "secondary",
                  fullWidth: true,
                  sizeVariant: "small",
                  onClick: initateIdentityChecks,
                  children: idCheckButtonText
                }
              ),
              completedButton: /* @__PURE__ */ jsx(Stack, { direction: "row", sx: { gap: spacing.g5 }, children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  disabled: loadingIdCheck || downloadingIdCheckReport || Array.isArray(idCheckState) && (idCheckState.some((check) => check.status === "pending") || idCheckState.some((check) => check.requestId === MANUAL_RTW_CHECK)),
                  colorVariant: "secondary",
                  fullWidth: true,
                  sizeVariant: "small",
                  startIcon: /* @__PURE__ */ jsx(Eye, __spreadValues({}, iconSize)),
                  onClick: handlePreviewIdCheckReport,
                  loading: downloadingIdCheckReport,
                  children: idCheckButtonText
                }
              ) })
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ jsx(
        UpgradeToProModal,
        {
          isOpen: upgradeModalOpen,
          setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
          planName: PlanNames.PEOPLE_PRO,
          messageSuffix: "proPeople",
          markup: onboarding.template.idverify && !(currentUser == null ? void 0 : currentUser.hasPaymentMethodOnFile) ? /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Red, mb: spacing.mb20, mt: spacing.mt20 }), children: [
            polyglot.t("OnboardingUserStatus.cannotUse"),
            " ",
            /* @__PURE__ */ jsx(Link, { target: "_blank", href: "/settings/billing", children: polyglot.t("OnboardingUserStatus.billing") })
          ] }) : void 0,
          seatsLimitError
        }
      ),
      onboarding.template.contracts && (contractStatusForStep1 == null ? void 0 : contractStatusForStep1.fields) && modalForCurrentContract(onboarding.template.contracts) && /* @__PURE__ */ jsx(
        MissingTemplateFieldModal,
        {
          open: openMissingFieldContractModal,
          setOpen: setOpenMissingFieldContractModal,
          templateId: onboarding.template.contracts.templateId,
          missingFields: contractStatusForStep1 == null ? void 0 : contractStatusForStep1.fields,
          contractRecipientId: onboarding.userId,
          returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }),
          companySignatoryUserId: contractStatusForStep1 == null ? void 0 : contractStatusForStep1.companySignatoryUserId,
          refreshMissingFields: refreshMissingFieldsForTemplate
        }
      ),
      onboarding.template.contracts && (contractStatusForStep1 == null ? void 0 : contractStatusForStep1.hasAdditionalSignatory) && contractTemplate && openContractModal && modalForCurrentContract(onboarding.template.contracts) && /* @__PURE__ */ jsx(
        ContractModal,
        {
          open: openContractModal,
          setOpen: setOpenContractModal,
          contractTemplate,
          onMissingField,
          existingRecipient: onboarding.userId
        }
      ),
      onboarding.template.contracts_step2 && (contractStatusForStep2 == null ? void 0 : contractStatusForStep2.fields) && modalForCurrentContract(onboarding.template.contracts_step2) && /* @__PURE__ */ jsx(
        MissingTemplateFieldModal,
        {
          open: openMissingFieldContractModal,
          setOpen: setOpenMissingFieldContractModal,
          templateId: onboarding.template.contracts_step2.templateId,
          missingFields: contractStatusForStep2 == null ? void 0 : contractStatusForStep2.fields,
          contractRecipientId: onboarding.userId,
          returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }),
          companySignatoryUserId: contractStatusForStep2 == null ? void 0 : contractStatusForStep2.companySignatoryUserId,
          refreshMissingFields: refreshMissingFieldsForTemplate
        }
      ),
      onboarding.template.contracts_step2 && (contractStatusForStep2 == null ? void 0 : contractStatusForStep2.hasAdditionalSignatory) && contractTemplate && contractModalStep2 && modalForCurrentContract(onboarding.template.contracts_step2) && /* @__PURE__ */ jsx(
        ContractModal,
        {
          open: contractModalStep2,
          setOpen: setContractModalStep2,
          contractTemplate,
          onMissingField,
          existingRecipient: onboarding.userId
        }
      ),
      onboarding.template.contracts_step3 && (contractStatusForStep3 == null ? void 0 : contractStatusForStep3.fields) && modalForCurrentContract(onboarding.template.contracts_step3) && /* @__PURE__ */ jsx(
        MissingTemplateFieldModal,
        {
          open: openMissingFieldContractModal,
          setOpen: setOpenMissingFieldContractModal,
          templateId: onboarding.template.contracts_step3.templateId,
          missingFields: contractStatusForStep3 == null ? void 0 : contractStatusForStep3.fields,
          contractRecipientId: onboarding.userId,
          returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }),
          companySignatoryUserId: contractStatusForStep3 == null ? void 0 : contractStatusForStep3.companySignatoryUserId,
          refreshMissingFields: refreshMissingFieldsForTemplate
        }
      ),
      onboarding.template.contracts_step3 && (contractStatusForStep3 == null ? void 0 : contractStatusForStep3.hasAdditionalSignatory) && contractTemplate && contractModalStep3 && modalForCurrentContract(onboarding.template.contracts_step3) && /* @__PURE__ */ jsx(
        ContractModal,
        {
          open: contractModalStep3,
          setOpen: setContractModalStep3,
          contractTemplate,
          onMissingField,
          existingRecipient: onboarding.userId
        }
      ),
      onboarding.template.contracts_step4 && (contractStatusForStep4 == null ? void 0 : contractStatusForStep4.fields) && modalForCurrentContract(onboarding.template.contracts_step4) && /* @__PURE__ */ jsx(
        MissingTemplateFieldModal,
        {
          open: openMissingFieldContractModal,
          setOpen: setOpenMissingFieldContractModal,
          templateId: onboarding.template.contracts_step4.templateId,
          missingFields: contractStatusForStep4 == null ? void 0 : contractStatusForStep4.fields,
          contractRecipientId: onboarding.userId,
          returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }),
          companySignatoryUserId: contractStatusForStep4 == null ? void 0 : contractStatusForStep4.companySignatoryUserId,
          refreshMissingFields: refreshMissingFieldsForTemplate
        }
      ),
      onboarding.template.contracts_step4 && (contractStatusForStep4 == null ? void 0 : contractStatusForStep4.hasAdditionalSignatory) && contractTemplate && contractModalStep4 && modalForCurrentContract(onboarding.template.contracts_step4) && /* @__PURE__ */ jsx(
        ContractModal,
        {
          open: contractModalStep4,
          setOpen: setContractModalStep4,
          contractTemplate,
          onMissingField,
          existingRecipient: onboarding.userId
        }
      ),
      onboarding.template.contracts_step5 && (contractStatusForStep5 == null ? void 0 : contractStatusForStep5.fields) && modalForCurrentContract(onboarding.template.contracts_step5) && /* @__PURE__ */ jsx(
        MissingTemplateFieldModal,
        {
          open: openMissingFieldContractModal,
          setOpen: setOpenMissingFieldContractModal,
          templateId: onboarding.template.contracts_step5.templateId,
          missingFields: contractStatusForStep5 == null ? void 0 : contractStatusForStep5.fields,
          contractRecipientId: onboarding.userId,
          returnPath: generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: onboarding.userId }),
          companySignatoryUserId: contractStatusForStep5 == null ? void 0 : contractStatusForStep5.companySignatoryUserId,
          refreshMissingFields: refreshMissingFieldsForTemplate
        }
      ),
      onboarding.template.contracts_step5 && (contractStatusForStep5 == null ? void 0 : contractStatusForStep5.hasAdditionalSignatory) && contractTemplate && contractModalStep5 && modalForCurrentContract(onboarding.template.contracts_step5) && /* @__PURE__ */ jsx(
        ContractModal,
        {
          open: contractModalStep5,
          setOpen: setContractModalStep5,
          contractTemplate,
          onMissingField,
          existingRecipient: onboarding.userId
        }
      ),
      Array.isArray(onboarding.template.idverify) && onboarding.template.idverify.some((config) => !!config.check) && /* @__PURE__ */ jsx(DrawerModal, { isOpen: initiateAdvanceIdCheck, setIsOpen: setInitiateAdvanceIdCheck, children: /* @__PURE__ */ jsx(
        EditIdVerify,
        {
          onSave: (checkPackages, checkCountry) => __async(void 0, null, function* () {
            if (checkPackages) {
              const result = yield OnboardingAPI.initiateIdentityChecksFromUserProfile(
                onboarding.userId,
                checkPackages,
                checkCountry
              );
              setIdCheckState(result);
            }
            showMessage(polyglot.t("OnboardingUserStatus.successMessages.invite"), "success");
            setInitiateAdvanceIdCheck(false);
          }),
          mode: "profile"
        }
      ) }),
      idCheckReportPreviewModalOpen && selectedIdCheckReportBuffer && /* @__PURE__ */ jsx(
        DocPreviewer,
        {
          fileBuffer: selectedIdCheckReportBuffer,
          contentType: "application/pdf",
          visible: idCheckReportPreviewModalOpen,
          onClose: () => {
            setIdCheckReportPreviewModalOpen(false);
            setSelectedIdCheckReportBuffer(void 0);
          },
          title: "id-check-report"
        }
      )
    ] })
  ] });
};
export const OnboardingUserStatusPage = () => {
  const userId = useUserIdParam();
  const [onboarding, setOnboarding] = useState();
  const refreshOnboardingState = useCallback(() => __async(void 0, null, function* () {
    const result = yield OnboardingAPI.getOnboardingUser(userId);
    setOnboarding(result);
  }), [userId]);
  useEffect(() => {
    refreshOnboardingState();
  }, [refreshOnboardingState]);
  if (!onboarding) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(OnboardingUserStatus, { onboarding });
};
