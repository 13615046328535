"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Link } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const RemoteApiTokenInstructions = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: [
      "a) Log in to",
      " ",
      /* @__PURE__ */ jsx(Link, { target: "_blank", href: "https://employ.remote.com/sign-in", children: "Remote.com" })
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "b) Go to the integrations page using the menu on the left, under the company tab." }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "c) Under the My Integrations section, click on Remote API." }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "d) Click on either the Generate token button in the card or on the Generate API token button in the header" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "e) A popup will open, and we will ask for the API token name. In order to generate the access token" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "f) After creating an API token, it will show a table with all the tokens linked to the company, including the new active access token you just created" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "g) Click on the 3 dot menu on the right side of the table to copy the newly generated API token, and paste it here." })
  ] });
};
