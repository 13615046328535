"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DEFAULT_PAGE_SIZE } from "@v2/components/table/server-side-table.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { ReactComponent as Next } from "@/images/side-bar-icons/NextBtn.svg";
import { iconSize } from "@/v2/components/forms/editable-title.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/scss/components/pagination-detail.scss";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PaginationDetail = ({
  totalPageCount,
  totalRecordCount,
  current,
  onNextAction,
  nextDisabled,
  onPreviousAction,
  previousDisabled,
  paginationState,
  setPaginationState,
  handleState,
  showCompact = false,
  paginationSx,
  paginationArray = [20, 50, 100]
}) => {
  const { polyglot } = usePolyglot();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currenPageIndex, setCurrentPageIndex] = useState(current);
  const onChange = (e) => {
    const pageValidation = Number(e.target.value) > totalPageCount ? totalPageCount : Number(e.target.value) < 1 ? 1 : Number(e.target.value);
    setCurrentPageIndex(pageValidation);
  };
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      const page = currenPageIndex ? Number(currenPageIndex) : 0;
      handleState(page);
    }
  };
  useEffect(() => {
    setCurrentPageIndex(current);
  }, [current]);
  const currentPageEndPageSize = (current - 1) * paginationState.pageSize + paginationState.pageSize;
  const currentPageRangeEnd = currentPageEndPageSize > totalRecordCount ? totalRecordCount : currentPageEndPageSize;
  const currentPageStartPageSize = (current - 1) * paginationState.pageSize;
  const currentPageRangeStart = currentPageStartPageSize + 1;
  const getNewPageIndex = (pageSize) => {
    const newPageIndex = Math.ceil(currentPageRangeStart / pageSize);
    const totalPages = Math.ceil(totalRecordCount / pageSize);
    return newPageIndex >= totalPages ? totalPages : newPageIndex <= 1 ? 1 : newPageIndex;
  };
  const getActions = () => {
    return paginationArray.map((pageSize) => ({
      handler: () => setPaginationState(pageSize, getNewPageIndex(pageSize)),
      label: polyglot.has(`PaginationDetail.${pageSize}`) ? polyglot.t(`PaginationDetail.${pageSize}`) : `${pageSize}`,
      disabled: false
    }));
  };
  return totalRecordCount > 0 ? totalRecordCount > DEFAULT_PAGE_SIZE ? /* @__PURE__ */ jsxs(
    Box,
    {
      className: "pagination-sticky-strip",
      sx: __spreadValues({
        gap: showCompact || isMobileScreen ? spacing.g10 : spacing.g20
      }, paginationSx),
      children: [
        !(showCompact || isMobileScreen) && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
          currentPageRangeStart,
          " - ",
          currentPageRangeEnd,
          " ",
          polyglot.has("PaginationDetail.of") ? polyglot.t("PaginationDetail.of") : "of",
          " ",
          totalRecordCount
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          !(showCompact || isMobileScreen) && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
            " ",
            polyglot.has("PaginationDetail.itemsPerPage") ? polyglot.t("PaginationDetail.itemsPerPage") : "objects per page"
          ] }),
          /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: getActions(),
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: paginationState.pageSize.toString(),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end"
              }
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              backgroundColor: themeColors.Background,
              color: themeColors.DarkGrey,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: radius.br15
            },
            children: [
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  onClick: onPreviousAction,
                  title: "Previous",
                  disabled: previousDisabled,
                  sizeVariant: "small",
                  colorVariant: "secondary",
                  style: { borderTopLeftRadius: radius.br15, borderRadius: radius.br15 },
                  children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
                }
              ),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "center", gap: spacing.g5 }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.has("PaginationDetail.page") ? polyglot.t("PaginationDetail.page") : "Page" }),
                /* @__PURE__ */ jsx(
                  "input",
                  {
                    type: "number",
                    value: currenPageIndex,
                    onKeyDown: onEnterPress,
                    onChange,
                    className: "pagination-input"
                  }
                ),
                /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                  polyglot.has("PaginationDetail.of") ? polyglot.t("PaginationDetail.of") : "of",
                  " ",
                  totalPageCount
                ] })
              ] }),
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  onClick: onNextAction,
                  disabled: nextDisabled,
                  title: "Next",
                  sizeVariant: "small",
                  colorVariant: "secondary",
                  style: { borderTopRightRadius: radius.br15, borderRadius: radius.br15 },
                  children: /* @__PURE__ */ jsx(Next, __spreadValues({}, iconSize))
                }
              )
            ]
          }
        )
      ]
    }
  ) : /* @__PURE__ */ jsx(
    Box,
    {
      className: "pagination-sticky-strip",
      sx: __spreadValues({
        gap: showCompact ? spacing.g10 : spacing.g20
      }, paginationSx),
      children: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        currentPageRangeStart,
        " - ",
        currentPageRangeEnd,
        " ",
        polyglot && polyglot.has("PaginationDetail.of") ? polyglot.t("PaginationDetail.of") : "of",
        " ",
        totalRecordCount,
        " ",
        polyglot && polyglot.has("PaginationDetail.records") ? polyglot.t("PaginationDetail.records") : "records"
      ] })
    }
  ) : /* @__PURE__ */ jsx(Fragment, {});
};
