"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import cover from "@/images/app-empty.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { nestErrorMessage } from "@/lib/errors";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import {
  APP_GROUP_MANAGEMENT_DRAWER_MODES
} from "@/v2/feature/app-integration/app-integration.interface";
import { AppDetailConfigurationModal } from "@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component";
import { AppDetailsTable } from "@/v2/feature/app-integration/features/app-details/components/app-details-table.component";
import { AppNameHeader } from "@/v2/feature/app-integration/features/app-details/components/app-name-header.component";
import { AppGroupManagementDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-group-management-drawer/app-group-management-drawer.section";
import { AppRequestAPI, AppRequestEndpoints } from "@/v2/feature/app-integration/features/app-request/app-request.api";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { primarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { useLocalStorage } from "@/v2/util/use-local-storage.util";
export const AppGroupManagementPage = ({
  teamAccessUserList,
  externalUserList,
  appStub,
  app,
  error,
  onAppChange,
  hasAppsAllOrAppOwner
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation();
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [groupForEditing, setGroupForEditing] = useState(void 0);
  const [loading, setLoading] = useState(false);
  const [groupsTabInError, setGroupsInTabError] = useState(false);
  const [drawerMode, setDrawerMode] = useState(void 0);
  const [groupMembers, setGroupMembers] = useState(void 0);
  const [groupMemberships, setGroupMemberships] = useLocalStorage(
    `${appStub}-appGroupMemberships-${(_a = currentUser == null ? void 0 : currentUser.company) == null ? void 0 : _a.companyId}`,
    []
  );
  const { data: pendingRequests, mutate: refreshAppRequests } = useApiClient(
    AppRequestEndpoints.getAppRequestsForCompany("Pending"),
    {
      suspense: false
    }
  );
  const checkForAnyMatchingPendingRequests = useCallback(() => __async(void 0, null, function* () {
    for (const eachUser of teamAccessUserList) {
      const matchingPendingRequest = pendingRequests == null ? void 0 : pendingRequests.find(
        (eachRequest) => eachRequest.requestedFor === eachUser.userId && eachRequest.requestInfo.appStub === appStub && eachUser.userStatus === "Active"
      );
      if (matchingPendingRequest && matchingPendingRequest.id) {
        yield AppRequestAPI.completePendingAppRequest(matchingPendingRequest.id);
        refreshAppRequests();
        showMessage("Found user matching pending app acccess request; marked request complete!", "success");
      }
    }
  }), [teamAccessUserList, pendingRequests, appStub, refreshAppRequests, showMessage]);
  useEffect(() => {
    checkForAnyMatchingPendingRequests();
  }, [teamAccessUserList, pendingRequests, checkForAnyMatchingPendingRequests]);
  useEffect(() => {
    if (routerLocation.state && routerLocation.state.app && routerLocation.state.needToRefreshDirectory) {
      const stubToRefresh = app == null ? void 0 : app.stub;
      routerHistory.replace({});
      if (stubToRefresh) onAppChange(stubToRefresh);
    }
  }, [app == null ? void 0 : app.stub, onAppChange, routerHistory, routerLocation.state]);
  const filteredGroupMembers = useCallback(
    (groupDetails) => {
      if (appStub === "google-workspace")
        return Object.values(groupDetails).filter((eachMember) => eachMember.email);
      if (appStub === "jira")
        return Object.values(groupDetails).filter(
          (eachMember) => eachMember.accountType === "atlassian"
        );
      if (appStub === "microsoft365")
        return Object.values(groupDetails).filter((eachMember) => eachMember);
      return [];
    },
    [appStub]
  );
  const groupManagementColumns = useMemo(
    () => [
      {
        header: () => "Group",
        id: "name",
        maxSize: 300,
        enableSorting: false,
        accessorFn: (row) => row.name,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5
              }),
              children: `${original == null ? void 0 : original.name}` || "N/A"
            }
          );
        }
      },
      {
        header: () => "Email",
        id: "email",
        enableSorting: false,
        accessorFn: (row) => row.email,
        cell: ({ row: { original } }) => {
          var _a2;
          return /* @__PURE__ */ jsx(Box, { sx: { mt: "15px", mb: "15px" }, children: /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5,
                mt: "5px",
                mb: "5px"
              }),
              children: (_a2 = original == null ? void 0 : original.email) != null ? _a2 : ""
            }
          ) });
        }
      },
      {
        header: () => "Members",
        id: "directMemberCount",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: (original == null ? void 0 : original.members) ? filteredGroupMembers(original.members).length : 0
          }
        ) })
      },
      {
        header: () => "",
        id: "action",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            onClick: () => {
              setIsDrawerOpen(true);
              setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.edit);
              setGroupMembers(original.members);
              setGroupForEditing(original);
            },
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ],
    [filteredGroupMembers, hasAppsAllOrAppOwner]
  );
  const activeTeamMembers = (teamList) => {
    return teamList == null ? void 0 : teamList.filter(
      (eachUser) => {
        var _a2;
        return ((_a2 = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a2.some((eachEmail) => ["Active", "Invited"].includes(eachEmail.status))) || eachUser.userStatus === "Active";
      }
    );
  };
  const teamMembersWithNoAccess = (teamList) => {
    return teamList.filter(
      (eachUser) => {
        var _a2;
        return ((_a2 = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a2.some((eachEmail) => ["No acess"].includes(eachEmail.status))) || eachUser.userStatus === "No access";
      }
    );
  };
  const addUsersToGroup = () => {
    setIsDrawerOpen(true);
    setDrawerMode(APP_GROUP_MANAGEMENT_DRAWER_MODES.add);
  };
  const fetchData = useCallback(
    (loading2 = true) => __async(void 0, null, function* () {
      if ((app == null ? void 0 : app.authorised) && (app == null ? void 0 : app.allowsGroupManagement)) {
        try {
          if (loading2) setLoading(true);
          const groupData = yield AppIntegrationAPI.getGroupMemberships(appStub);
          setGroupMemberships(groupData);
          setGroupsInTabError(false);
        } catch (error2) {
          setGroupsInTabError(true);
          showMessage(`Failed to fetch group data: ${nestErrorMessage(error2)}`, "error");
        } finally {
          if (loading2) setLoading(false);
        }
      }
    }),
    [app == null ? void 0 : app.allowsGroupManagement, app == null ? void 0 : app.authorised, appStub, setGroupMemberships, showMessage]
  );
  useEffect(() => {
    fetchData(!groupMemberships || groupMemberships.length === 0 ? true : false);
  }, [app == null ? void 0 : app.allowsGroupManagement, app == null ? void 0 : app.authorised, appStub]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(AppNameHeader, { title: polyglot.t("AppGroupManagementPage.title"), app }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => addUsersToGroup(), children: "New account" }),
        showAction: hasAppsAllOrAppOwner && (app == null ? void 0 : app.allowsGroupManagement)
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: loading != null ? loading : false, children: [
      appStub && appStub === "github" && error && !(app == null ? void 0 : app.configuration) && (app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            mt: spacing.mt40
          },
          children: [
            "Please add your organisation name to finish integration setup",
            /* @__PURE__ */ jsx(Button, { sx: __spreadValues(__spreadValues({}, primarySmallBtn), spacing.mt40), onClick: () => setShowModal(true), children: "Add organisation" })
          ]
        }
      ),
      /* @__PURE__ */ jsxs(Box, { children: [
        !error && !groupsTabInError && /* @__PURE__ */ jsx(
          AppDetailsTable,
          {
            column: groupManagementColumns,
            row: groupMemberships != null ? groupMemberships : [],
            loading: loading != null ? loading : false,
            hidePagination: true
          }
        ),
        error && !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
          EmptyStateComponent,
          {
            header: "Authorize this app",
            subheader: "Zelt allows you to manage your whole toolstack from one place.",
            detail: "To start using your app connect your account in Settings first.",
            cover
          }
        ),
        groupsTabInError && (app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
          EmptyStateComponent,
          {
            header: "Re-authorize this app",
            subheader: "Zelt now allows you to manage your Google Groups",
            detail: "Please reconnect Zelt to your Google Account, as additional permissions are needed in order to manage Google Groups from Zelt",
            cover
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        AppDetailConfigurationModal,
        {
          open: showModal,
          handleClose: () => setShowModal(false),
          app,
          appStub,
          refresh: () => onAppChange(appStub)
        }
      ),
      /* @__PURE__ */ jsx(
        AppGroupManagementDrawer,
        {
          appStub,
          selectedGroupMembership: groupForEditing,
          externalUserList,
          usersWithoutAccess: teamMembersWithNoAccess(teamAccessUserList),
          usersWithAccess: activeTeamMembers(teamAccessUserList),
          isOpen: isDrawerOpen,
          onClose: () => setIsDrawerOpen(false),
          refreshApp: () => onAppChange(appStub),
          setIsDrawerOpen,
          mode: drawerMode,
          groupMembers,
          groupList: groupMemberships
        }
      )
    ] })
  ] });
};
