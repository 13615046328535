"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { isBefore, isToday } from "date-fns";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { TIME_ME_CALENDAR_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import { getDatesBetweenMonths, getTodayAndDateAfter30Days } from "@/v2/feature/dashboard/dashboard.util";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { UserCalendarEmptyState } from "@/v2/feature/dashboard/features/sections/user-calendar/components/user-calendar-empty-state.component";
import { UserCalendarView } from "@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-calendar-view.component";
import { UserEventCard } from "@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-event-card.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import {
  getCategorizedData,
  getCurrentDateAndFutureEvents,
  getFilteredData
} from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util";
import { TimeDrawerContent } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/time-drawer-content.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserCalendarVersionBig = ({
  calendarBig,
  readOnly = false,
  refreshWidgetData
}) => {
  const { polyglot } = usePolyglot();
  const today = new LocalDate().getDate();
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const [state] = useContext(GlobalContext);
  const [selectedAbsence, setSelectedAbsence] = useState(void 0);
  const weekDaysFromMonToSun = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const signedInUser = getCachedUserById(state.user.userId);
  const routerHistory = useHistory();
  const filteredData = useMemo(() => {
    var _a;
    const remainingMonthRange = getTodayAndDateAfter30Days();
    return getFilteredData(
      remainingMonthRange,
      nonTerminatedCachedUsers,
      calendarBig.view,
      signedInUser,
      (_a = calendarBig == null ? void 0 : calendarBig.time) == null ? void 0 : _a.requests,
      calendarBig == null ? void 0 : calendarBig.fellowReportees
    );
  }, [
    calendarBig == null ? void 0 : calendarBig.time.requests,
    nonTerminatedCachedUsers,
    calendarBig == null ? void 0 : calendarBig.view,
    signedInUser,
    calendarBig == null ? void 0 : calendarBig.fellowReportees
  ]);
  const structuredData = useMemo(() => {
    const weekRange = getTodayAndDateAfter30Days();
    const datesArray = getDatesBetweenMonths(weekRange[0], weekRange[1]);
    let eventData = [];
    datesArray.map((d) => {
      const currentDate = new LocalDate(d).getDate();
      return getCategorizedData(eventData, filteredData, currentDate);
    });
    return eventData != null ? eventData : [];
  }, [filteredData]);
  const todayAndFutureEvents = useMemo(() => {
    const todayDate = new LocalDate().getDate();
    return getCurrentDateAndFutureEvents(structuredData, todayDate);
  }, [structuredData]);
  const calendarData = useMemo(() => {
    const getDaysInMonth = (year, month) => {
      return new Date(year, month + 1, 0).getDate();
    };
    const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth());
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1).getDay();
    const result = [];
    let week = [];
    const startDayIndex = firstDay === 0 ? 6 : firstDay - 1;
    for (let day = 0; day < startDayIndex; day++) {
      week.push(/* @__PURE__ */ jsx("td", { className: "cw-table-data" }, `empty-${day}`));
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new LocalDate(new Date(today.getFullYear(), today.getMonth(), day)).getDate();
      const isBeforeToday = isBefore(currentDate, today);
      week.push(
        /* @__PURE__ */ jsx("td", { className: "cw-table-data", children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center"
            },
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "headline3",
                sx: {
                  color: isToday(currentDate) ? themeColors.white : isBeforeToday ? themeColors.Grey : themeColors.DarkGrey,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: isToday(currentDate) ? themeColors.DarkGrey : "transparent",
                  borderRadius: radius.br20,
                  width: "25px",
                  height: "25px"
                },
                children: day
              }
            )
          },
          `${day}-${day}-day`
        ) }, `day-${day}`)
      );
      if ((startDayIndex + day) % 7 === 0) {
        result.push(/* @__PURE__ */ jsx("tr", { children: week }, `week-${result.length}`));
        week = [];
      }
    }
    if (week.length > 0) {
      result.push(/* @__PURE__ */ jsx("tr", { children: week }, `week-${result.length}`));
    }
    return result;
  }, [today]);
  const hasEvents = Boolean(todayAndFutureEvents && todayAndFutureEvents.length > 0);
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly, size: "big", children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      "div",
      {
        className: "cs-calendar-grid",
        style: {
          pointerEvents: readOnly ? "none" : "inherit"
        },
        children: [
          /* @__PURE__ */ jsx(
            UserCalendarView,
            {
              calendarData,
              weekDaysFromMonToSun,
              headerAction: () => routerHistory.push(TIME_ME_CALENDAR_ROUTE)
            }
          ),
          hasEvents ? /* @__PURE__ */ jsxs(
            "div",
            {
              style: {
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: hasEvents ? "space-between" : "inherit"
              },
              children: [
                /* @__PURE__ */ jsx("div", { className: "cw-event-list", children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("UserCalendarVersionBig.upcoming") }) }),
                /* @__PURE__ */ jsxs(Box, { children: [
                  /* @__PURE__ */ jsx(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        flexDirection: "column"
                      },
                      children: [...todayAndFutureEvents].slice(0, 6).map((event, idx) => /* @__PURE__ */ jsx(
                        UserEventCard,
                        {
                          event,
                          type: event.type,
                          setSelectedAbsence
                        },
                        `calendar-big${idx}`
                      ))
                    }
                  ),
                  todayAndFutureEvents && todayAndFutureEvents.length > 6 && /* @__PURE__ */ jsx(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        mt: spacing.m3
                      },
                      children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(
                        "a",
                        {
                          href: TIME_ME_CALENDAR_ROUTE,
                          rel: "noopener noreferrer",
                          style: __spreadValues({ color: themeColors.DarkGrey, textDecoration: "none" }, themeFonts.paragraph),
                          children: polyglot.t("UserCalendarVersionBig.more", { smart_count: todayAndFutureEvents.length - 6 })
                        }
                      ) })
                    }
                  )
                ] })
              ]
            }
          ) : /* @__PURE__ */ jsxs(
            "div",
            {
              style: {
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column"
              },
              children: [
                /* @__PURE__ */ jsx("div", { className: "cw-event-list", children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("UserCalendarVersionBig.upcoming") }) }),
                /* @__PURE__ */ jsx(Box, { sx: { height: "100%", verticalAlign: "middle" }, children: /* @__PURE__ */ jsx(UserCalendarEmptyState, {}) })
              ]
            }
          )
        ]
      }
    ),
    selectedAbsence && selectedAbsence.absenceId && /* @__PURE__ */ jsx(
      TimeDrawerContent,
      {
        absenceId: selectedAbsence.absenceId,
        userId: selectedAbsence.userId,
        refresh: () => __async(void 0, null, function* () {
          if (refreshWidgetData) yield refreshWidgetData();
        }),
        afterClose: () => {
          setSelectedAbsence(void 0);
        }
      }
    )
  ] }) });
};
