"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { HelperAbsencePolicyDrawer } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-policy-drawer.component";
export const HelperAbsencePoliciesTable = ({
  absencePolicies,
  isLoadingPolicies,
  users
}) => {
  const [isPolicyDrawerOpen, setIsPolicyDrawerOpen] = useState(false);
  const [selectedPolicyToShowInDrawer, setSelectedPolicyToShowInDrawer] = useState(null);
  const policyTableColumns = useMemo(
    () => [
      {
        header: () => "ID",
        accessorFn: (row) => row,
        id: "id",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.id }) })
      },
      {
        header: () => "Name",
        accessorFn: (row) => row,
        id: "name",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.name }) })
      },
      {
        header: () => "Full name",
        accessorFn: (row) => row,
        id: "fullName",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.fullName }) })
      },
      {
        header: () => "CarryOverEnabled",
        accessorFn: (row) => row,
        id: "carryOverEnabled",
        enableSorting: true,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.carryOverEnabled ? "True" : "False" }) })
      },
      {
        header: () => "CarryOverLimit",
        accessorFn: (row) => row,
        id: "CarryOverLimit",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.carryOverEnabled ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.carryOverLimit }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => "CarryOverExpiration",
        accessorFn: (row) => row,
        id: "carryOverExpirationDay",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.carryOverEnabled ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: `${original.carryOverExpirationDay}/${original.carryOverExpirationMonth}` }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      }
    ],
    []
  );
  const handleRowClick = useCallback((row) => {
    setSelectedPolicyToShowInDrawer(row.original);
    setIsPolicyDrawerOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "Absence policies" }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", height: "200px", maxHeight: "200px", overflow: "auto" }, children: [
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: absencePolicies != null ? absencePolicies : [],
          columnData: policyTableColumns,
          loading: isLoadingPolicies,
          hidePagination: true,
          rowClick: handleRowClick,
          noDataMessage: "Sorry, we couldn't find anything..."
        }
      ),
      /* @__PURE__ */ jsx(
        HelperAbsencePolicyDrawer,
        {
          isOpen: isPolicyDrawerOpen,
          setIsOpen: setIsPolicyDrawerOpen,
          absencePolicy: selectedPolicyToShowInDrawer,
          onClose: () => setSelectedPolicyToShowInDrawer(null),
          users
        }
      )
    ] })
  ] });
};
