"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PensionProviderCell = ({ providerName, displayName }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap5, alignItems: "center" }, children: [
    getPensionLogoByProviderName(providerName),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: displayName || PensionProvidersValueToLabel[providerName] || providerName })
  ] });
};
