"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewAPI } from "@/v2/feature/growth/reviews/api-client/review.api";
import { ResultsOverviewCommentModal } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-comment-modal.component";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ResultsOverviewReviewComment = ({
  results,
  refreshResults,
  loggedInUser,
  reachType
}) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(void 0);
  const [selectedComment, setSelectedComment] = useState(void 0);
  const [showMessage] = useMessage();
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const publicText = reachType === ReachType.Me ? "Leave a comment for your records or to discuss with your manager" : "Leave a 'public' comment to share feedback with participant or 'private' to keep it between managers/admins.";
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2, marginTop: spacing.s3 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: publicText }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: { display: "flex", alignItems: "center", gap: spacing.s1, marginBottom: spacing.s1 },
        className: "hide-for-capture",
        children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "secondary",
              sizeVariant: "small",
              onClick: () => {
                setIsOpen(true);
                setMode("Public");
              },
              children: "Add public comment"
            }
          ),
          isManager && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "secondary",
              sizeVariant: "small",
              onClick: () => {
                setIsOpen(true);
                setMode("Private");
              },
              children: "Add private comment"
            }
          )
        ]
      }
    ),
    (results == null ? void 0 : results.comments) && results.comments.map((commentObj) => {
      var _a;
      return /* @__PURE__ */ jsx(
        GridDisplayBarV2,
        {
          showBorder: true,
          cells: [
            {
              content: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.s2 }, children: [
                /* @__PURE__ */ jsx(
                  Typography,
                  {
                    variant: "paragraph",
                    dangerouslySetInnerHTML: { __html: (_a = commentObj == null ? void 0 : commentObj.comment) != null ? _a : "" },
                    sx: { padding: 0, margin: 0, overflowWrap: "break-word" }
                  }
                ),
                /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
                  /* @__PURE__ */ jsx(
                    ChipComponent,
                    {
                      name: commentObj.isPublic ? "Public comment" : "Private comment",
                      backgroundColor: "white",
                      border: "background",
                      textVariant: "paragraph",
                      textColor: "DarkGrey"
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    ChipComponent,
                    {
                      name: /* @__PURE__ */ jsx(
                        UserCell,
                        {
                          userId: commentObj.commentBy,
                          nameVariant: "paragraph",
                          nameSx: { color: themeColors.DarkGrey }
                        }
                      ),
                      backgroundColor: "white",
                      border: "background",
                      textColor: "DarkGrey"
                    }
                  )
                ] })
              ] }),
              gridXs: 8
            },
            {
              content: Number(commentObj.commentBy) === Number(loggedInUser) ? /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  className: "hide-for-capture",
                  options: [
                    {
                      icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
                      handler: () => {
                        setSelectedComment(commentObj);
                        setIsOpen(true);
                        setMode(commentObj.isPublic ? "Public" : "Private");
                      },
                      label: polyglot.t("General.edit")
                    },
                    {
                      icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                      handler: () => __async(void 0, null, function* () {
                        try {
                          if (results) {
                            yield ReviewAPI.deleteComment(results.reviewId, results.cycleId, commentObj);
                            refreshResults == null ? void 0 : refreshResults();
                            showMessage("Comment deleted successfully", "success");
                          }
                        } catch (error) {
                          showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                        }
                      }),
                      label: polyglot.t("General.delete")
                    }
                  ],
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              ) : /* @__PURE__ */ jsx(Fragment, {}),
              gridXs: 4,
              sx: { display: "flex", justifyContent: "flex-end" }
            }
          ]
        }
      );
    }),
    /* @__PURE__ */ jsx(
      ResultsOverviewCommentModal,
      {
        isOpen,
        setIsOpen,
        onClose: () => {
          setIsOpen(false);
          setSelectedComment(void 0);
        },
        results,
        refreshResults,
        isPublic: Boolean(mode === "Public"),
        selectedComment
      }
    )
  ] });
};
