"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { StyledTooltip } from "@v2/components/theme-components/styled-tooltip.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as InfoGrey } from "@/images/side-bar-icons/InfoGrey.svg";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const ContractEndDatePickerComponent = (_a) => {
  var _b = _a, {
    effectiveDate,
    value,
    onChange,
    disabled
  } = _b, props = __objRest(_b, [
    "effectiveDate",
    "value",
    "onChange",
    "disabled"
  ]);
  const { polyglot } = usePolyglot();
  const ensureContractEndDateIsValid = useCallback(() => {
    const contractEndDate = value;
    let updatedContractEndDate = contractEndDate;
    if (!effectiveDate) {
      updatedContractEndDate = null;
    }
    if (contractEndDate && effectiveDate && effectiveDate > contractEndDate) {
      updatedContractEndDate = effectiveDate;
    }
    if (updatedContractEndDate !== contractEndDate) {
      onChange(updatedContractEndDate);
    }
  }, [effectiveDate, onChange, value]);
  useEffect(() => {
    ensureContractEndDateIsValid();
  }, [ensureContractEndDateIsValid]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "5px" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: "5px" }, children: [
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "hasContractEndDate",
          label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ContractForm.hasEndDate") }),
          checked: !!value,
          disabled,
          onChange: (_, checked) => {
            onChange(checked ? effectiveDate : null);
          }
        }
      ),
      /* @__PURE__ */ jsx(StyledTooltip, { title: polyglot.t("ContractForm.hasEndDateHint"), children: /* @__PURE__ */ jsx(InfoGrey, __spreadValues({}, iconSize)) })
    ] }),
    value && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      __spreadProps(__spreadValues({}, props), {
        value,
        onChange,
        minDate: effectiveDate,
        disabled
      })
    ) })
  ] });
};
