"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import { OwnerChip } from "@/v2/feature/growth/goals/components/owner-chip.component";
import { KeyResultProgressMutationDrawer } from "@/v2/feature/growth/goals/features/key-result-progress/components/key-result-progress-mutation-drawer.component";
import { KeyResultProgressAPI } from "@/v2/feature/growth/goals/features/key-result-progress/key-result-progress.api";
import { KeyResultProgressType } from "@/v2/feature/growth/goals/features/key-results/key-result.interface";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const iconSize = { width: 14, height: 14 };
export const KeyResultProgressListItem = ({
  progress,
  keyResult,
  onProgressUpdated,
  onProgressDeleted,
  sx
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const goalId = keyResult == null ? void 0 : keyResult.goalId;
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const isAdmin = canAccessScopes(user, ["goals:all"]);
  const canDeleteProgressUpdate = user.userId === progress.createdBy || isAdmin;
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const [isDeleteProgressDrawerOpen, setIsDeleteProgressDrawerOpen] = useState(false);
  const [isEditProgressDrawerOpen, setIsEditProgressDrawerOpen] = useState(false);
  useEffect(() => {
    if (!isDeleteProgressDrawerOpen) return;
    setIsOptionsMenuOpen(false);
  }, [isDeleteProgressDrawerOpen]);
  const getProgressActionsOptions = useCallback(
    () => [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => setIsEditProgressDrawerOpen(true),
        label: polyglot.t("KeyResultProgressItem.actionsMenu.edit"),
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => setIsDeleteProgressDrawerOpen(true),
        label: polyglot.t("KeyResultProgressItem.actionsMenu.delete"),
        disabled: false
      }
    ],
    [polyglot]
  );
  const deleteProgressItem = useCallback(() => __async(void 0, null, function* () {
    try {
      yield KeyResultProgressAPI.removeKeyResultProgress(goalId, progress.keyResultId, progress.id);
      onProgressDeleted == null ? void 0 : onProgressDeleted();
      showMessage(polyglot.t("KeyResultProgressItem.keyResultProgressDeleted"), "success");
      setIsDeleteProgressDrawerOpen(false);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  }), [setIsDeleteProgressDrawerOpen, goalId, progress, onProgressDeleted, showMessage, polyglot]);
  const isPercentageBased = keyResult.progressType === KeyResultProgressType.Percentage;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        "&:hover": {
          "& .end-section": {
            opacity: 1
          }
        }
      },
      children: [
        /* @__PURE__ */ jsx(
          ListItem,
          {
            details: {
              description: progress.comment,
              chips: /* @__PURE__ */ jsx(OwnerChip, { ownerIds: [progress.createdBy], usersOnly: true })
            },
            progressBar: {
              type: "circle",
              progress: progress.value,
              progressType: isPercentageBased ? "percentage" : "number",
              progressTarget: isPercentageBased ? 100 : keyResult.progressTarget
            },
            hoverState: {
              enabled: true
            },
            endSection: /* @__PURE__ */ jsx(
              Box,
              {
                className: "end-section",
                sx: {
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0.25rem",
                  opacity: isOptionsMenuOpen ? 1 : 0,
                  transition: "opacity 0.1s ease"
                },
                children: canDeleteProgressUpdate && /* @__PURE__ */ jsx(
                  StyledMenuComponent,
                  {
                    options: getProgressActionsOptions(),
                    onClick: () => __async(void 0, null, function* () {
                      return setIsOptionsMenuOpen(true);
                    }),
                    onClose: () => __async(void 0, null, function* () {
                      return setIsOptionsMenuOpen(false);
                    }),
                    actionButtonDetails: {
                      type: "iconButton",
                      colorVariant: "secondary",
                      sizeVariant: "small",
                      title: "actions",
                      icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                    }
                  }
                )
              }
            ),
            endSectionSx: { display: "flex", justifyContent: "end" },
            sx: __spreadValues({ padding: "1rem" }, sx),
            gridLayout: [6, 4, 2]
          },
          progress.id
        ),
        /* @__PURE__ */ jsx(
          KeyResultProgressMutationDrawer,
          {
            keyResult,
            progress,
            mode: "edit",
            isOpen: isEditProgressDrawerOpen,
            onProgressUpdated: () => {
              setIsEditProgressDrawerOpen(false);
              onProgressUpdated == null ? void 0 : onProgressUpdated();
            },
            onClosed: () => {
              setIsOptionsMenuOpen(false);
              setIsEditProgressDrawerOpen(false);
            }
          }
        ),
        /* @__PURE__ */ jsx(
          DeleteDrawer,
          {
            title: polyglot.t("DeleteKeyResultProgressDrawer.title"),
            description: polyglot.t("DeleteKeyResultProgressDrawer.description"),
            isOpen: isDeleteProgressDrawerOpen,
            setIsOpen: setIsDeleteProgressDrawerOpen,
            loading: false,
            action: deleteProgressItem
          }
        )
      ]
    }
  );
};
