"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_ATTENDANCE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const AttendanceScheduleDeleteConfirmationDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const deleteSchedule = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield AttendanceAPI.deleteAttendanceScheduleById(attendanceSchedule.id);
      yield refresh();
      history.push(SETTINGS_ATTENDANCE_ROUTE);
    } catch (error) {
      showMessage(`Could not delete schedule. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [attendanceSchedule.id, refresh, history, showMessage]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AttendanceSchedule.deleteAttendanceSchedule") }),
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: attendanceSchedule.name }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          type: "button",
          sizeVariant: "medium",
          colorVariant: "danger",
          name: polyglot.t("General.delete"),
          loading,
          onClick: deleteSchedule
        }
      )
    ] })
  ] }) });
};
