"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { getDefaultBenefitImageByCategory } from "@v2/feature/benefits/benefits.util";
import { DeleteCustomBenefitDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/delete-custom-benefit-drawer.component";
import { EditCustomBenefitBalanceDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-balance-drawer.component";
import { EditCustomBenefitGeneralDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/benefits-settings-details/custom-benefit/components/edit-custom-benefit-general-drawer.component";
import {
  BenefitsSettingsPageSection,
  DescriptionLine
} from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import {
  isAllowanceBenefit,
  isRecurringBenefit,
  isYearlyAllowanceBenefit,
  getYearlyAllowancePeriod
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateCustomBenefitAllowanceType } from "@v2/infrastructure/i18n/translate.util";
import { themeColors } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
export const CustomBenefitSettingsGeneralPage = ({ category, customBenefit, refreshBenefit }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditBalanceOpen, setIsEditBalanceOpen] = useState(false);
  const isRecurring = isRecurringBenefit(customBenefit.type);
  const isAllowance = isAllowanceBenefit(customBenefit.type);
  const isYearlyAllowance = isYearlyAllowanceBenefit(customBenefit.allowanceType);
  return /* @__PURE__ */ jsxs(
    BenefitsSettingsPageSection,
    {
      title: polyglot.t("General.general"),
      onEdit: () => {
        setIsEditOpen(true);
      },
      children: [
        /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.name"), value: customBenefit.name }),
        customBenefit.description && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.description"), value: customBenefit.description }),
        customBenefit.type && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.type"), value: customBenefit.type }),
        /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("General.category"), value: (_a = customBenefit.category) != null ? _a : category }),
        customBenefit.benefitLink && /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("BenefitModule.benefitLink"), value: customBenefit.benefitLink }),
        /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.logo"),
            value: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItem: "center", gap: spacing.g10 }, children: (customBenefit == null ? void 0 : customBenefit.logoUuid) && (customBenefit == null ? void 0 : customBenefit.url) ? /* @__PURE__ */ jsx("img", { src: customBenefit == null ? void 0 : customBenefit.url, width: 16, height: 16, alt: "benefit-logo" }) : getDefaultBenefitImageByCategory(customBenefit.category, "25px", "auto") })
          }
        ),
        (isRecurring || isAllowance) && /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", mt: "20px" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: isRecurring ? "Contributions" : "Allowance" }),
          /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setIsEditBalanceOpen(true), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) }, "edit")
        ] }),
        isRecurring && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.employerContribution"),
            value: customBenefit.defaultEmployerContribution ? formatMoney({ amount: customBenefit.defaultEmployerContribution, asDecimal: true }) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        isRecurring && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.employeeContribution"),
            value: customBenefit.defaultEmployeeContribution ? formatMoney({ amount: customBenefit.defaultEmployeeContribution, asDecimal: true }) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        isAllowance && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.standardAllowance"),
            value: customBenefit.defaultOpeningBalance ? formatMoney({ amount: customBenefit.defaultOpeningBalance, asDecimal: true }) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        isAllowance && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.allowanceType"),
            value: customBenefit.allowanceType ? translateCustomBenefitAllowanceType(customBenefit.allowanceType, polyglot) : /* @__PURE__ */ jsx(EmptyCell, {})
          }
        ),
        isAllowance && isYearlyAllowance && /* @__PURE__ */ jsx(
          DescriptionLine,
          {
            field: polyglot.t("BenefitModule.cyclePeriod"),
            value: getYearlyAllowancePeriod(customBenefit)
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { mt: "30px" }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "text",
            onClick: () => setIsDeleteOpen(true),
            style: { margin: 0, padding: 0, color: themeColors.RedDark },
            children: polyglot.t("BenefitModule.deleteBenefit")
          }
        ) }),
        /* @__PURE__ */ jsx(
          EditCustomBenefitGeneralDrawer,
          {
            isOpen: isEditOpen,
            setIsOpen: setIsEditOpen,
            customBenefit,
            refresh: refreshBenefit
          }
        ),
        /* @__PURE__ */ jsx(DeleteCustomBenefitDrawer, { isOpen: isDeleteOpen, setIsOpen: setIsDeleteOpen, customBenefit }),
        (isRecurring || isAllowance) && /* @__PURE__ */ jsx(
          EditCustomBenefitBalanceDrawer,
          {
            isOpen: isEditBalanceOpen,
            setIsOpen: setIsEditBalanceOpen,
            customBenefit,
            refresh: refreshBenefit
          }
        )
      ]
    }
  );
};
