"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const OpenEndedQuestionReadonly = ({ questionText }) => {
  if (!questionText) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
    /* @__PURE__ */ jsx(RichTextField, { value: null, disabled: true })
  ] });
};
