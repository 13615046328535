"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { Typography } from "@v2/components/typography/typography.component";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { SiteAPI } from "@/api-client/site.api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DevicePossessionType, ReturnDeviceReason } from "@/v2/feature/device/device.interface";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { formatAddress } from "@/v2/util/user-data.util";
export const ChangeOwnerCompanyInventoryForm = ({
  devicePossession,
  closePage
}) => {
  const [senderAddress, setSenderAddress] = useState("");
  const [sitesOptions, setSitesOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [siteId, setSiteId] = useState(null);
  const [siteAddress, setSiteAddress] = useState("");
  const [state, dispatch] = useContext(GlobalContext);
  const [siteError, setSiteError] = useState(false);
  const [siteAddressError, setSiteAddressError] = useState(false);
  const [showMessage] = useMessage();
  const initiateDeviceReturn = useCallback(() => __async(void 0, null, function* () {
    let isReturnInitiated = false;
    if (!siteId) {
      setSiteError(true);
      return;
    }
    if (!siteAddress) {
      setSiteAddressError(true);
      return;
    }
    try {
      setIsSubmitting(true);
      yield DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        ReturnDeviceReason.ReturnToCompanySite,
        senderAddress,
        siteAddress,
        siteId,
        void 0
      );
      showMessage("Successfully requested a return", "success");
      isReturnInitiated = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      yield closePage();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not initiate return device flow. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setIsSubmitting(false);
    }
  }), [siteId, siteAddress, devicePossession.id, senderAddress, showMessage, state.user.userId, dispatch, closePage]);
  const fetchUserAddress = useCallback(
    (assignedUserId) => __async(void 0, null, function* () {
      try {
        const userAddress = yield UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            var _a;
            return { value: site.id, label: site.name, address: (_a = site.address) != null ? _a : "" };
          })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "siteId",
        label: "Site",
        options: sitesOptions,
        value: siteId,
        compareValue: siteId,
        onChange: (e) => __async(void 0, null, function* () {
          var _a, _b;
          const siteId2 = e.target.value;
          const siteAddress2 = (_b = (_a = sitesOptions.find((s) => s.value === siteId2)) == null ? void 0 : _a.address) != null ? _b : "";
          setSiteId(siteId2);
          setSiteAddress(siteAddress2);
          setSiteError(false);
          if (siteAddress2) setSiteAddressError(false);
        }),
        error: siteError,
        helperText: siteError && "Site is a required field"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "siteAddress",
        label: "Site address",
        value: siteAddress,
        onChange: (e) => {
          setSiteAddress(e.target.value);
          setSiteAddressError(false);
        },
        error: siteAddressError,
        helperText: siteAddressError && "Site address is a required field"
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "This device will be moved to company inventory. Please ask the current owner to remove all personal files. You can initiate a wipe of this device later." }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        colorVariant: "primary",
        sizeVariant: "medium",
        fullWidth: true,
        loading: isSubmitting,
        onClick: () => __async(void 0, null, function* () {
          return yield initiateDeviceReturn();
        })
      }
    ) })
  ] });
};
