"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import {
  cardTitleIconSx,
  detailHelperSx,
  detailListSx,
  detailSx,
  detailTermSx,
  detailValueSx
} from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const AndroidSettingsCard = ({
  setEditPoliciesCard,
  selectedPasscodePolicy,
  selectedOsUpdatesPolicy
}) => {
  return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" },
        paddingBottom: spacing.p60
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: cardTitleIconSx, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Security" }),
          /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setEditPoliciesCard(true), children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) })
        ] }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m30 }), children: "Configure security settings for Android devices connected to Hexnode MDM." }),
        /* @__PURE__ */ jsxs(Box, { component: "section", children: [
          /* @__PURE__ */ jsx(Box, { component: "dl", sx: detailListSx, children: selectedPasscodePolicy && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
            /* @__PURE__ */ jsx(Typography, { component: "dt", sx: detailTermSx, children: "Passcode" }),
            /* @__PURE__ */ jsxs(Box, { component: "dd", sx: __spreadProps(__spreadValues({}, detailValueSx), { position: "relative" }), children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.label }),
              (selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: detailHelperSx, children: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.description })
            ] })
          ] }) }),
          /* @__PURE__ */ jsx(Box, { component: "dl", sx: detailListSx, children: selectedOsUpdatesPolicy && /* @__PURE__ */ jsxs(Box, { sx: detailSx, children: [
            /* @__PURE__ */ jsx(Typography, { component: "dt", sx: detailTermSx, children: "OS updates" }),
            /* @__PURE__ */ jsxs(Box, { component: "dd", sx: __spreadProps(__spreadValues({}, detailValueSx), { position: "relative" }), children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.label }),
              (selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.description) && /* @__PURE__ */ jsx(Typography, { sx: detailHelperSx, children: selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.description })
            ] })
          ] }) })
        ] })
      ]
    }
  ) });
};
