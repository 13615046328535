"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { sortDate, sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { UserAccountTypes } from "@v2/feature/user/user.interface";
import moment from "moment";
import CsvDownloader from "react-csv-downloader";
import { useDebouncedCallback } from "use-debounce";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableFilter } from "@/v2/components/table/table-filter.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { TableColumn } from "@/v2/feature/super-admin/components/helper/table-helper";
import { UserAPI } from "@/v2/feature/user/user.api";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const formatDate = (date) => moment(date).format("DD/MM/YYYY");
const getUserRoleLabel = (accountType) => {
  switch (true) {
    case accountType.Admin: {
      return UserAccountTypes.Admin;
    }
    case accountType.ProfileOwner: {
      return UserAccountTypes.ProfileOwner;
    }
    case accountType.OtherProfiles: {
      return UserAccountTypes.OtherProfiles;
    }
    case accountType.Manager: {
      return UserAccountTypes.Manager;
    }
    default: {
      return UserAccountTypes.OtherProfiles;
    }
  }
};
const formatUsers = (users) => users.map((user) => {
  var _a, _b, _c, _d, _e;
  return {
    userId: user.userId,
    companyName: user.company.name,
    name: `${user.firstName} ${user.lastName}`,
    email: user.emailAddress,
    personalEmail: (_a = user.personalEmail) != null ? _a : "-",
    accountStatus: (_b = user.accountStatus) != null ? _b : "-",
    userStatus: (_d = (_c = user.userEvents[0]) == null ? void 0 : _c.status) != null ? _d : "-",
    lastTimeOnline: user.lastTimeOnline,
    totalActions: (_e = user.totalActions) != null ? _e : 0,
    role: getUserRoleLabel(user.accountType),
    startDate: user.startDate,
    leaveDate: user.leaveDate,
    createdAt: user.createdAt
  };
});
export const SuperAdminUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [showMessage] = useMessage();
  const [sorting, setSorting] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filterString, setFilterString] = useState("Status=Employed,Hired");
  const [filterTypes, setFilterTypes] = useState({});
  const getFilterOptions = useCallback(() => __async(void 0, null, function* () {
    const USER_STATUSES = [
      { label: "Employed", value: "Employed" },
      { label: "Hired", value: "Hired" },
      { label: "Terminated", value: "Terminated" },
      { label: "Leave", value: "Leave" }
    ];
    const USER_ROLES = [
      { label: UserAccountTypes.Admin, value: "Admin" },
      { label: UserAccountTypes.ProfileOwner, value: "ProfileOwner" },
      { label: UserAccountTypes.OtherProfiles, value: "OtherProfiles" },
      { label: UserAccountTypes.Manager, value: "Manager" }
    ];
    const FILTERS = ["Status", "Role"];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case "Status":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: USER_STATUSES
          });
          break;
        case "Role":
          filters = __spreadProps(__spreadValues({}, filters), {
            [filter]: USER_ROLES
          });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), []);
  const debouncedFilter = useDebouncedCallback((filterString2) => __async(void 0, null, function* () {
    try {
      setFilterString(filterString2);
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage("Failed to handle filter", "error");
    }
  }), 500);
  const handleFilter = useCallback((event) => debouncedFilter.callback(event), [debouncedFilter]);
  const fetchUsers = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setIsLoadingUsers(true);
        const { users: users2, pagination: paginationInfo } = yield UserAPI.getAllUsersAsSuperAdmin(params);
        setUsers(users2 || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
        setIsLoadingUsers(false);
      } catch (error) {
        showMessage(`Something went wrong fetching users. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const fetchAllUsersForExport = useCallback(() => __async(void 0, null, function* () {
    try {
      const { users: users2 } = yield UserAPI.getAllUsersAsSuperAdmin({
        page: "1",
        pageSize: "50",
        searchQuery: "",
        shouldLimitResult: "false"
      });
      return formatUsers(users2);
    } catch (error) {
      console.error("Something went wrong fetching users for export", error.message);
      return [];
    }
  }), []);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      yield fetchUsers({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery,
        filter: filterString
      });
      getFilterOptions();
    }))();
  }, [pageIndex, searchQuery, fetchUsers, getFilterOptions, filterString]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const formattedUsers = useMemo(() => formatUsers(users), [users]);
  const usersColumn = useMemo(
    () => [
      new TableColumn().define({
        header: "User Id",
        id: "userId",
        size: 140,
        fieldName: "userId",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.userId)
      }),
      new TableColumn().define({
        header: "User Name",
        id: "name",
        size: 170,
        fieldName: "name",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name)
      }),
      new TableColumn().define({
        header: "Company",
        id: "companyName",
        size: 150,
        fieldName: "companyName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.companyName)
      }),
      new TableColumn().define({
        header: "Email",
        id: "email",
        size: 150,
        fieldName: "email",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.email)
      }),
      new TableColumn().define({
        header: "Account Status",
        id: "accountStatus",
        size: 140,
        fieldName: "accountStatus",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.accountStatus)
      }),
      new TableColumn().define({
        header: "Last Time Online",
        id: "lastTimeOnline",
        size: 140,
        fieldName: "lastTimeOnline",
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.lastTimeOnline)
      }),
      new TableColumn().define({
        header: "User Status",
        id: "userStatus",
        size: 140,
        fieldName: "userStatus",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.userStatus)
      }),
      new TableColumn().define({
        header: "Personal Email",
        id: "personalEmail",
        size: 140,
        fieldName: "personalEmail",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.personalEmail)
      }),
      new TableColumn().define({
        header: "Role",
        id: "role",
        size: 140,
        fieldName: "role",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.role)
      }),
      new TableColumn().define({
        header: "Total Actions",
        id: "totalActions",
        size: 140,
        fieldName: "totalActions",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalActions)
      }),
      new TableColumn().define({
        header: "Start Date",
        id: "startDate",
        size: 140,
        fieldName: "startDate",
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.startDate)
      }),
      new TableColumn().define({
        header: "Leave Date",
        id: "leaveDate",
        size: 140,
        fieldName: "leaveDate",
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.leaveDate)
      }),
      new TableColumn().define({
        header: "Date Created",
        id: "createdAt",
        size: 140,
        fieldName: "createdAt",
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt)
      })
    ],
    []
  );
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Users" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoadingUsers, children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({
              display: "flex",
              justifyContent: "flex-start"
            }, spacing.mb20), {
              gap: "5px",
              alignItems: "center"
            }),
            children: [
              /* @__PURE__ */ jsx(
                TableSearch,
                {
                  style: { width: "20em" },
                  query: searchQuery,
                  handleChange: (e) => {
                    var _a, _b;
                    setSearchQuery((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                    setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                  },
                  placeholder: "Enter id, name, email or company name",
                  debounceSearchWait: 500
                }
              ),
              filterTypes && /* @__PURE__ */ jsx(TableFilter, { filterTypes, setFilterString: handleFilter, filterString })
            ]
          }
        ),
        /* @__PURE__ */ jsx(CsvDownloader, { filename: "users", separator: ",", datas: fetchAllUsersForExport, children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({
              display: "flex",
              alignItems: "center",
              gap: spacing.g5
            }, secondaryCTABtn), {
              cursor: "pointer",
              height: "20px"
            }),
            children: [
              /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
              " ",
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Export" })
            ]
          }
        ) })
      ] }),
      !isLoadingUsers && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
        BasicServerTable,
        {
          rowData: [...formattedUsers],
          columnData: usersColumn,
          sorting,
          setSorting,
          pagination,
          setPagination,
          totalPages,
          totalItems
        }
      ) })
    ] }) })
  ] });
};
