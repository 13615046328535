"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppDetailsAPI } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import {
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { detailTermSx, detailValueSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
import { toTitleCase } from "@/v2/util/string.util";
export const AppAutomaticOnboardingConfiguration = ({ appDetails, mode }) => {
  var _a;
  const [edit, setEdit] = useState(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [onboardingConfiguration, setOnboardingConfiguration] = useState(null);
  const [onboardingTemplateOptions, setOnboardingTemplateOptions] = useState([]);
  const fetchOnboardingConfiguration = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b;
    try {
      setLoading(true);
      const onboardingConfiguration2 = yield AppDetailsAPI.getOnboardingAutomaticConfiguration((_a2 = appDetails == null ? void 0 : appDetails.stub) != null ? _a2 : "");
      setOnboardingConfiguration(onboardingConfiguration2);
      const templateOptions = (_b = onboardingConfiguration2 == null ? void 0 : onboardingConfiguration2.all) == null ? void 0 : _b.map((eachTemplate) => {
        return {
          label: eachTemplate == null ? void 0 : eachTemplate.name,
          value: eachTemplate == null ? void 0 : eachTemplate.templateId
        };
      });
      templateOptions.push({ label: "No selected template", value: 0 });
      setOnboardingTemplateOptions(templateOptions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Failed to fetch onboarding configuration: ${JSON.stringify(error)}`, "error");
    }
  }), [appDetails == null ? void 0 : appDetails.stub, showMessage]);
  useEffect(() => {
    fetchOnboardingConfiguration();
  }, [fetchOnboardingConfiguration]);
  const updateOnboardingTemplates = (values) => __async(void 0, null, function* () {
    var _a2, _b;
    try {
      setLoading(true);
      const updatedOnboardingConfiguration = yield AppDetailsAPI.updateOnboardingTemplateSelectionForWebhook(
        (_a2 = appDetails == null ? void 0 : appDetails.stub) != null ? _a2 : "",
        {
          selected: (_b = values.selected) != null ? _b : 0,
          enabled: values.enabled
        }
      );
      showMessage("Successfully updated Onboarding configuration", "success");
      setEdit(false);
      setOnboardingConfiguration(updatedOnboardingConfiguration);
    } catch (error) {
      showMessage(`Failed to update Onboarding configuration: ${JSON.stringify(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const selectedTemplateId = useMemo(() => {
    var _a2, _b;
    return (_b = (_a2 = onboardingConfiguration == null ? void 0 : onboardingConfiguration.all) == null ? void 0 : _a2.find((t) => t.templateId === (onboardingConfiguration == null ? void 0 : onboardingConfiguration.selected))) == null ? void 0 : _b.templateId;
  }, [onboardingConfiguration == null ? void 0 : onboardingConfiguration.all, onboardingConfiguration == null ? void 0 : onboardingConfiguration.selected]);
  const selectedTemplate = useMemo(() => {
    var _a2;
    return (_a2 = onboardingConfiguration == null ? void 0 : onboardingConfiguration.all) == null ? void 0 : _a2.find((t) => t.templateId === (onboardingConfiguration == null ? void 0 : onboardingConfiguration.selected));
  }, [onboardingConfiguration == null ? void 0 : onboardingConfiguration.all, onboardingConfiguration == null ? void 0 : onboardingConfiguration.selected]);
  const formik = useFormik({
    initialValues: {
      selected: selectedTemplateId,
      all: (_a = onboardingConfiguration == null ? void 0 : onboardingConfiguration.all) != null ? _a : [],
      enabled: !!(onboardingConfiguration == null ? void 0 : onboardingConfiguration.enabled)
    },
    enableReinitialize: true,
    onSubmit: updateOnboardingTemplates
  });
  const cancelEdit = () => {
    formik.resetForm();
    setEdit(false);
  };
  const subtitleText = mode === "candidate" ? "hired candidates" : "new employments";
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "550px", mt: spacing.m60 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      mode && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: [
        toTitleCase(mode.toString()),
        " onboarding"
      ] }),
      /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { display: `${edit ? "none" : ""}` }), onClick: () => setEdit(true), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: mode && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mt: spacing.m10 }), children: `Select an onboarding flow for ${subtitleText} in ${appDetails == null ? void 0 : appDetails.name} to automate hiring admin tasks.` }) }),
    !edit ? /* @__PURE__ */ jsxs(Box, { component: "dl", sx: { definitionListSx }, children: [
      selectedTemplate && /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: "30px", display: "flex", alignItems: "center" }), children: [
        /* @__PURE__ */ jsx(Typography, { component: "dt", sx: definitionTermSx, children: "Template" }),
        /* @__PURE__ */ jsx(Typography, { component: "dd", sx: definitionValueSx, children: selectedTemplate ? selectedTemplate == null ? void 0 : selectedTemplate.name : "Not selected" })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: "30px", display: "flex", alignItems: "center" }), children: [
        /* @__PURE__ */ jsxs(Typography, { component: "dt", sx: definitionTermSx, children: [
          "Automatically import ",
          subtitleText,
          " to Zelt"
        ] }),
        /* @__PURE__ */ jsx(Typography, { component: "dd", sx: definitionValueSx, children: !!(onboardingConfiguration == null ? void 0 : onboardingConfiguration.enabled) ? "Enabled" : "Disabled" })
      ] })
    ] }) : /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
      selectedTemplate && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", mt: "30px", alignItems: "center" }, component: "section", children: [
        /* @__PURE__ */ jsx(Box, { sx: { minWidth: "190px", maxWidth: "190px" }, children: /* @__PURE__ */ jsx(Typography, { sx: detailTermSx, children: "Template" }) }),
        /* @__PURE__ */ jsx(Box, { sx: detailValueSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "selected",
            options: onboardingTemplateOptions != null ? onboardingTemplateOptions : [],
            value: formik.values.selected,
            compareValue: formik.values.selected,
            error: !!formik.errors.selected && formik.touched.selected,
            onChange: formik.handleChange,
            helperText: formik.touched.selected && Boolean(formik.errors.selected)
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", mt: "30px", alignItems: "center" }, component: "section", children: [
        /* @__PURE__ */ jsx(Box, { sx: { minWidth: "190px", maxWidth: "190px" }, children: /* @__PURE__ */ jsxs(Typography, { sx: detailTermSx, children: [
          "Automatically import ",
          subtitleText,
          " to Zelt"
        ] }) }),
        /* @__PURE__ */ jsx(Box, { sx: detailValueSx, children: /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: formik.values.enabled,
            name: "automaticOnboardingEnabled",
            onChange: (_e, enabled) => {
              formik.setFieldValue("enabled", enabled);
            },
            disabled: loading
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", my: spacing.m50, gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            disabled: false,
            onClick: () => cancelEdit(),
            children: "Cancel"
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            disabled: !formik.isValid || !formik.dirty || loading,
            name: "Save",
            loading,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        )
      ] })
    ] }) }) })
  ] });
};
