"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminSalaryPage = ({ companies, pageConfig }) => {
  const [companyId, setCompanyId] = useState();
  const [payrolls, setPayrolls] = useState();
  const [payrollId, setPayrollId] = useState();
  const [schedules, setSchedules] = useState([]);
  const [selectedScheduleIdx, setSelectedScheduleIdx] = useState(-1);
  const [movingUsers, setMovingUsers] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    setPayrolls(void 0);
    setPayrollId(void 0);
    if (!companyId) return;
    PayrollAPI.getCompanyPayrollsAsSuperAdmin(companyId).then(setPayrolls);
  }, [companyId]);
  const companyList = useMemo(() => {
    return [...companies].sort((a, b) => a.name.localeCompare(b.name, void 0, { sensitivity: "base" })).map((c) => ({ label: c.name, value: c.companyId }));
  }, [companies]);
  const scheduleList = useMemo(() => {
    return schedules.map((a, idx) => ({
      label: a.name,
      value: idx
    }));
  }, [schedules]);
  const fetchCompanyPayrollInfo = useCallback(() => __async(void 0, null, function* () {
    setSchedules([]);
    setSelectedScheduleIdx(-1);
    if (!companyId || !payrollId) {
      return;
    }
    try {
      const [schedules2] = yield Promise.all([
        PayrollExternalApi.getPaySchedulesAsSuperadmin(companyId, payrollId, "Year2024")
      ]);
      setSchedules(schedules2.filter((s) => s.isConfigured));
    } catch (err) {
      showMessage(err, "error");
    }
  }), [companyId, payrollId, showMessage]);
  useEffect(() => {
    fetchCompanyPayrollInfo();
  }, [fetchCompanyPayrollInfo]);
  const selectedSchedule = schedules == null ? void 0 : schedules[selectedScheduleIdx];
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Salary" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: pageConfig.header.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { secondLevel: true, children: /* @__PURE__ */ jsxs(Stack, { sx: { width: "500px", gap: "20px" }, children: [
      "Company:",
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "companies",
          options: companyList,
          value: companyId != null ? companyId : 0,
          onChange: (e) => setCompanyId(e.target.value),
          disabled: movingUsers
        }
      ),
      "Payroll:",
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "payrolls",
          options: (payrolls == null ? void 0 : payrolls.items.map((p) => {
            var _a, _b;
            return {
              label: (_b = (_a = p.entity) == null ? void 0 : _a.legalName) != null ? _b : `PayrollID ${p.id}`,
              value: p.id
            };
          })) || [],
          value: payrollId != null ? payrollId : 0,
          onChange: (e) => setPayrollId(e.target.value),
          disabled: movingUsers
        }
      ),
      scheduleList && /* @__PURE__ */ jsxs(Fragment, { children: [
        "Move users to a different pay schedule:",
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "schedules",
            options: scheduleList,
            value: selectedScheduleIdx != null ? selectedScheduleIdx : -1,
            onChange: (e) => setSelectedScheduleIdx(e.target.value),
            disabled: movingUsers
          }
        ),
        selectedSchedule && companyId && payrollId && /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Move users",
            type: "button",
            sizeVariant: "large",
            colorVariant: "primary",
            loading: movingUsers,
            onClick: () => __async(void 0, null, function* () {
              setMovingUsers(true);
              const { payPeriod } = selectedSchedule;
              if (payPeriod !== "Monthly" && payPeriod !== "Weekly") {
                throw new Error("Pay period not supported");
              }
              try {
                const res = yield PayrollExternalApi.moveUsersToPaySchedule(
                  companyId,
                  payrollId,
                  payPeriod,
                  selectedSchedule.ordinal
                );
                showMessage(`${res.count} users moved to new pay schedule`, "info");
                fetchCompanyPayrollInfo();
              } catch (err) {
                showMessage(err, "error");
              }
              setMovingUsers(false);
            })
          }
        ),
        /* @__PURE__ */ jsx("div", { style: { overflow: "auto", height: "400px" }, children: /* @__PURE__ */ jsx(Typography, { sx: { whiteSpace: "pre" }, children: schedules[selectedScheduleIdx] && JSON.stringify(schedules[selectedScheduleIdx], void 0, " ") }) })
      ] })
    ] }) })
  ] });
};
