"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ContractorOverviewCard } from "@v2/feature/payments/features/accounting-transaction/components/contractor-overview-card.component";
import { useHistory, useLocation } from "react-router-dom";
import { PAYMENT_ME_NEW_EXPENSE_ROUTE, PAYMENT_ME_NEW_INVOICE_ROUTE } from "@/lib/routes";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { NewPaymentButton } from "@/v2/feature/payments/pages/components/new-payment-button.component";
import {
  PaymentsTransactionsTable,
  PaymentsTransactionsTableSkeleton
} from "@/v2/feature/payments/pages/payments-list.page";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function PaymentsTeamPage() {
  const routerLocation = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const routerHistory = useHistory();
  const showOverview = false;
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Payments" }),
        showBack: routerLocation.pathname.includes("/settings"),
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          NewPaymentButton,
          {
            onExpenseClick: () => routerHistory.push(PAYMENT_ME_NEW_EXPENSE_ROUTE),
            onInvoiceClick: () => routerHistory.push(PAYMENT_ME_NEW_INVOICE_ROUTE)
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: spacing.pt20, children: [
      showOverview && /* @__PURE__ */ jsx(Box, { sx: { ml: spacing.ml40, mt: spacing.mt20, mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(ContractorOverviewCard, {}) }),
      /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(PaymentsTransactionsTableSkeleton, {}) }),
          children: /* @__PURE__ */ jsx(
            PaymentsTransactionsTable,
            {
              isModalOpen,
              setIsModalOpen,
              setLoading,
              setShowEmpty,
              showEmpty,
              setSelectionModel,
              selectionModel,
              mode: "payment",
              reach: "team"
            }
          )
        }
      )
    ] })
  ] });
}
