"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as DownArrow } from "@/images/fields/DownArrow.svg";
import { ReactComponent as UpArrow } from "@/images/fields/UpArrow.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ReviewSectionBankAPI } from "@/v2/feature/growth/reviews/api-client/review-section-bank.api";
import { ReviewTemplateAPI } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { iconSize } from "@/v2/styles/menu.styles";
export const useTemplateSectionOptions = (template, section, setSection, refreshTemplate, setIsSectionModalOpen) => {
  const [showMessage] = useMessage();
  const currentOrder = useMemo(() => (template == null ? void 0 : template.order) ? [...template.order] : [], [template == null ? void 0 : template.order]);
  const moveSection = useCallback(
    (direction) => __async(void 0, null, function* () {
      if (!template) return;
      const sectionIndex = currentOrder.findIndex((o) => o.type === "section" && o.id === section.id);
      if (sectionIndex < 0) return;
      const newOrder = [...currentOrder];
      if (direction === "up" && sectionIndex > 0) {
        [newOrder[sectionIndex - 1], newOrder[sectionIndex]] = [newOrder[sectionIndex], newOrder[sectionIndex - 1]];
      } else if (direction === "down" && sectionIndex < newOrder.length - 1) {
        [newOrder[sectionIndex], newOrder[sectionIndex + 1]] = [newOrder[sectionIndex + 1], newOrder[sectionIndex]];
      } else {
        showMessage(`Illegal move`, "info");
        return;
      }
      try {
        yield ReviewTemplateAPI.updateOrderReviewTemplate({ templateId: template.id, order: newOrder });
        yield refreshTemplate == null ? void 0 : refreshTemplate();
        showMessage(`Successfully moved section ${direction}`, "success");
      } catch (error) {
        showMessage(`Error moving section: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [currentOrder, refreshTemplate, showMessage, section.id, template]
  );
  return useMemo(() => {
    const sectionIndex = currentOrder.findIndex((o) => o.type === "section" && o.id === section.id);
    const isSectionFirst = sectionIndex === 0;
    const isSectionLast = sectionIndex === currentOrder.length - 1 || currentOrder[sectionIndex + 1] && currentOrder[sectionIndex + 1].type === "question";
    return [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          setSection(section);
          setIsSectionModalOpen(true);
        }),
        label: "Edit",
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(UpArrow, __spreadValues({}, iconSize)),
        handler: () => moveSection("up"),
        label: "Move up",
        disabled: isSectionFirst
      },
      {
        icon: /* @__PURE__ */ jsx(DownArrow, __spreadValues({}, iconSize)),
        handler: () => moveSection("down"),
        label: "Move down",
        disabled: isSectionLast
      },
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => __async(void 0, null, function* () {
          try {
            yield ReviewSectionBankAPI.deleteSectionById(template.id, section.id);
            setSection(null);
            yield refreshTemplate == null ? void 0 : refreshTemplate();
            showMessage("Successfully delete the section", "success");
          } catch (error) {
            showMessage(`Error deleting section: ${nestErrorMessage(error)}`, "error");
          }
        }),
        label: "Delete",
        disabled: false
      }
    ];
  }, [setIsSectionModalOpen, moveSection, refreshTemplate, section, setSection, showMessage, template, currentOrder]);
};
