"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __restKey = (key) => typeof key === "symbol" ? key : key + "";
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GrowthScalePointFormModal } from "@/v2/feature/growth/growth-scale/components/growth-scale-point-form-modal.component";
import { GrowthScaleAPI } from "@/v2/feature/growth/growth-scale/growth-scale.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GrowthScaleFormModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  growthScale,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(GrowthScaleFormContent, { onClose, growthScale, refresh }) });
};
const useScaleModalForm = (growthScale, setLoading, onClose, refresh) => {
  const [showMessage] = useMessage();
  const initialValues = growthScale ? {
    type: growthScale.type,
    points: growthScale.points,
    value: growthScale.value,
    sentiments: growthScale.sentiments
  } : {
    type: "",
    points: {},
    value: {},
    sentiments: {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      type: yup.string().nullable().required("Name is required"),
      points: yup.object().test("len", "Points should have at least one entry", (value) => {
        return value && Object.keys(value).length > 0;
      }),
      value: yup.object().test("len", "value should have at least one entry", (value) => {
        return value && Object.keys(value).length > 0;
      }),
      sentiments: yup.object().test("len", "Sentiments should have at least one entry", (value) => {
        return value && Object.keys(value).length > 0;
      })
    }),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (growthScale) {
          const updateObject = __spreadValues({ id: growthScale.id }, values);
          yield GrowthScaleAPI.updateScale(updateObject);
          showMessage("Scale updated successfully", "success");
        } else {
          const createObject = __spreadValues({}, values);
          yield GrowthScaleAPI.createScale(createObject);
          showMessage("Scale created successfully", "success");
        }
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return formik;
};
const GrowthScaleFormContent = ({ onClose, growthScale, refresh }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedScalePoint, setSelectedScalePoint] = useState(void 0);
  const formik = useScaleModalForm(growthScale, setLoading, onClose, refresh);
  const removePoint = (index) => {
    const _a2 = formik.values.points, { [index]: _ } = _a2, remainingPoints = __objRest(_a2, [__restKey(index)]);
    const _b = formik.values.value, { [index]: __ } = _b, remainingValues = __objRest(_b, [__restKey(index)]);
    const _c = formik.values.sentiments, { [index]: ___ } = _c, remainingSentiments = __objRest(_c, [__restKey(index)]);
    formik.setFieldValue("points", remainingPoints);
    formik.setFieldValue("value", remainingValues);
    formik.setFieldValue("sentiments", remainingSentiments);
    formik.validateForm();
  };
  const addPoint = useCallback(
    (values) => {
      formik.setFieldValue(`points.${selectedScalePoint}`, values.points);
      formik.setFieldValue(`value.${selectedScalePoint}`, values.value);
      formik.setFieldValue(`sentiments.${selectedScalePoint}`, values.sentiments);
      formik.validateForm();
    },
    [formik, selectedScalePoint]
  );
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: growthScale ? `Edit ${growthScale.type}` : "New scale type" }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "type",
        label: "Name",
        value: formik.values.type,
        onChange: formik.handleChange,
        error: formik.touched.type && !!formik.errors.type,
        helperText: (_a = formik.touched.type && formik.errors.type) != null ? _a : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Scale points" }),
    formik.values.points && Object.entries(formik.values.points) && Object.entries(formik.values.points).length > 0 && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column" }, children: Object.entries(formik.values.points).map(([key, value], idx) => /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          justifyContent: "space-between",
          paddingY: spacing.p8,
          borderBottom: borders.light,
          ":hover": {
            bgcolor: themeColors.TableHover
          }
        },
        onClick: (e) => {
          setSelectedScalePoint(key);
          setShowAdd(true);
          e.stopPropagation();
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
            /* @__PURE__ */ jsxs(Typography, { variant: "paragraphSmall", color: "Grey", children: [
              "Point ",
              idx + 1
            ] }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: value })
          ] }),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              colorVariant: "secondary",
              sizeVariant: "small",
              onClick: (e) => {
                removePoint(key);
                e.stopPropagation();
              },
              children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
            }
          )
        ]
      },
      key
    )) }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        colorVariant: "secondary",
        sizeVariant: "small",
        onClick: () => {
          const newIndex = Object.keys(formik.values.points).length.toString();
          setSelectedScalePoint(newIndex);
          setShowAdd(true);
        },
        children: "Add point"
      }
    ) }),
    (formik.errors.points || formik.errors.value || formik.errors.sentiments) && formik.submitCount > 0 && formik.values.points && Object.keys(formik.values.points || {}).length < 1 && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "darkRed", children: "Scale must have at least one scale point" }) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8, width: "100%" }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        loading,
        colorVariant: "primary",
        fullWidth: true
      }
    ) }) }),
    /* @__PURE__ */ jsx(
      GrowthScalePointFormModal,
      {
        isOpen: showAdd,
        setIsOpen: setShowAdd,
        onClose: () => {
          setShowAdd(false);
        },
        afterClose: () => {
          setSelectedScalePoint(void 0);
        },
        growthScalePoint: selectedScalePoint,
        growthScale: formik.values || void 0,
        addPoint
      }
    )
  ] }) });
};
