"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Switch, Typography } from "@mui/material";
import { sortBoolean, sortDate, sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import moment from "moment";
import CsvDownloader from "react-csv-downloader";
import { CompanyAPI } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { dateDiffInDays, dateIncludedInLastXHours } from "@/lib/date-time.lib";
import { nestErrorMessage } from "@/lib/errors";
import { AccountStatus } from "@/lib/users";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable, DEFAULT_PAGE_SIZE } from "@/v2/components/table/server-side-table.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SSOAppsEnum } from "@/v2/feature/security/security-settings/security.interface";
import { TableColumn } from "@/v2/feature/super-admin/components/helper/table-helper";
import { DisableSSOConfirmDrawer } from "@/v2/feature/super-admin/features/super-admin-companies/components/confirm-disable-sso-for-company-modal.component";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const formatDate = (date) => moment(date).format("DD/MM/YYYY");
const formatCompanies = (companies) => companies.map((company) => {
  var _a, _b, _c, _d, _e;
  const { activeCompanyUsers, activeAccounts } = company.users.reduce(
    (result, user) => {
      if (user.lastTimeOnline) result.activeCompanyUsers.push(user);
      if ([AccountStatus.Active, AccountStatus.Invited, AccountStatus.InvitedToOnboard].includes(user.accountStatus))
        result.activeAccounts += 1;
      return result;
    },
    { activeCompanyUsers: [], activeAccounts: 0 }
  );
  const lastTimeOnline = activeCompanyUsers.filter((user) => user.lastTimeOnline ? (/* @__PURE__ */ new Date()).getTime() > new Date(user.lastTimeOnline).getTime() : null).sort((a, b) => {
    if (!b.lastTimeOnline) return 1;
    if (!a.lastTimeOnline) return -1;
    return new Date(b.lastTimeOnline).getTime() - new Date(a.lastTimeOnline).getTime();
  });
  const dayActiveUsers = activeCompanyUsers.filter((user) => {
    return user && user.lastTimeOnline && dateIncludedInLastXHours(user.lastTimeOnline, 24);
  });
  const weekActiveUsers = activeCompanyUsers.filter((user) => {
    return user && user.lastTimeOnline ? dateDiffInDays(/* @__PURE__ */ new Date(), new Date(user.lastTimeOnline)) < 7 : false;
  });
  const monthActiveUsers = activeCompanyUsers.filter((user) => {
    return user && user.lastTimeOnline ? dateDiffInDays(/* @__PURE__ */ new Date(), new Date(user.lastTimeOnline)) < 30 : false;
  });
  const totalActions = activeCompanyUsers.reduce((acc, user) => {
    var _a2;
    return acc + ((_a2 = user.totalActions) != null ? _a2 : 0);
  }, 0);
  return {
    companyId: company.companyId,
    name: company.name,
    createdAt: company.createdAt,
    totalUsers: company.users.length,
    activeUsers: activeCompanyUsers.length,
    lastTimeOnline: (_b = (_a = lastTimeOnline[0]) == null ? void 0 : _a.lastTimeOnline) != null ? _b : null,
    dau: dayActiveUsers.length,
    wau: weekActiveUsers.length,
    mau: monthActiveUsers.length,
    totalActions,
    noOfActiveDevices: company.noOfActiveDevices,
    noOfInstalledApps: company.noOfInstalledApps,
    isActive: (_c = company.isActive) != null ? _c : false,
    hasSupport: (_d = company.hasSupport) != null ? _d : false,
    prioritySupport: (_e = company.prioritySupport) != null ? _e : false,
    activeAccounts,
    ssoMetadata: company.ssoMetadata
  };
});
export const SuperAdminCompaniesPage = () => {
  const [companies, setCompanies] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [showMessage] = useMessage();
  const [sorting, setSorting] = useState([]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [companyIdToDisableSSO, setCompanyIdToDisableSSO] = useState(null);
  const [ssoMetadataForCompanyToDisable, setSSOMetadataForCompanyToDisable] = useState(null);
  const [confirmingDisableSSO, setConfirmingDisableSSO] = useState(false);
  const handleStatusChange = (companyId) => __async(void 0, null, function* () {
    try {
      yield CompanyAPI.changeActiveStatus(companyId);
      showMessage("Company status updated successfully", "success");
      yield fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery
      });
    } catch (error) {
      showMessage(`Something went wrong while changing status: ${nestErrorMessage(error)}`, "error");
    }
  });
  const handleChatSupportChange = (companyId) => __async(void 0, null, function* () {
    try {
      yield CompanyAPI.changeChatSupportStatus(companyId);
      showMessage("Company chat support successfully updated", "success");
      yield fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery
      });
    } catch (error) {
      showMessage(`Something went wrong while chat support: ${nestErrorMessage(error)}`, "error");
    }
  });
  const handlePrioritySupportChange = (companyId) => __async(void 0, null, function* () {
    try {
      yield CompanyAPI.changeCompanyPrioritySupport(companyId);
      showMessage("Company chat support successfully updated", "success");
      yield fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery
      });
    } catch (error) {
      showMessage(`Something went wrong while chat support: ${nestErrorMessage(error)}`, "error");
    }
  });
  const fetchCompanies = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setIsLoadingCompanies(true);
        const { companies: companies2, pagination: paginationInfo } = yield CompanyAPI.getAllCompaniesAsSuperAdmin(params);
        setCompanies(companies2 || []);
        const { totalPages: totalPages2, totalCount } = paginationInfo;
        setTotalPages(totalPages2);
        setTotalItems(totalCount);
        setIsLoadingCompanies(false);
      } catch (error) {
        showMessage(`Something went wrong fetching companies. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  const fetchAllCompaniesForExport = useCallback(() => __async(void 0, null, function* () {
    try {
      const { companies: companies2 } = yield CompanyAPI.getAllCompaniesAsSuperAdmin({
        page: "1",
        pageSize: "50",
        searchQuery: "",
        shouldLimitResult: "false"
      });
      return formatCompanies(companies2);
    } catch (error) {
      console.error("Something went wrong fetching companies for export", error.message);
      return [];
    }
  }), []);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      yield fetchCompanies({
        page: pageIndex.toString(),
        pageSize: DEFAULT_PAGE_SIZE.toString(),
        searchQuery
      });
    }))();
  }, [pageIndex, searchQuery, fetchCompanies]);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );
  const companiesColumn = useMemo(
    () => [
      new TableColumn().define({
        header: "Id",
        id: "companyId",
        size: 140,
        fieldName: "companyId",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.companyId)
      }),
      new TableColumn().define({
        header: "Company",
        id: "name",
        size: 180,
        fieldName: "name",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name)
      }),
      new TableColumn().define({
        header: "Date Created",
        id: "createdAt",
        size: 140,
        fieldName: "createdAt",
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.createdAt)
      }),
      new TableColumn().define({
        header: "Active Accounts",
        id: "activeAccounts",
        size: 140,
        fieldName: "activeAccounts",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.activeAccounts)
      }),
      new TableColumn().define({
        header: "Registered Users",
        id: "totalUsers",
        size: 140,
        fieldName: "totalUsers",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalUsers)
      }),
      // new TableColumn<CompanyDetails>().define({
      //   header: 'Active Users',
      //   id: 'activeUsers',
      //   size: 140,
      //   fieldName: 'activeUsers',
      //   enableSorting: true,
      //   sortingFn: (a, b) => sortNumeric(a, b, (item) => item.activeUsers),
      // }),
      new TableColumn().define({
        header: "Total Actions",
        id: "totalActions",
        size: 140,
        fieldName: "totalActions",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalActions)
      }),
      new TableColumn().define({
        header: "Last time online",
        id: "lastTimeOnline",
        size: 140,
        fieldName: "lastTimeOnline",
        formatter: formatDate,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.lastTimeOnline)
      }),
      new TableColumn().define({
        header: "DAU",
        id: "dau",
        size: 140,
        fieldName: "dau",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.dau)
      }),
      new TableColumn().define({
        header: "WAU",
        id: "wau",
        size: 140,
        fieldName: "wau",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.wau)
      }),
      new TableColumn().define({
        header: "MAU",
        id: "mau",
        size: 140,
        fieldName: "mau",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.mau)
      }),
      new TableColumn().define({
        header: "Active Devices",
        id: "noOfActiveDevices",
        size: 140,
        fieldName: "noOfActiveDevices",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.noOfActiveDevices)
      }),
      new TableColumn().define({
        header: "Active Apps",
        id: "noOfInstalledApps",
        size: 140,
        fieldName: "noOfInstalledApps",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.noOfInstalledApps)
      }),
      {
        header: () => "Action",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isActive),
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Switch,
          {
            checked: original.isActive,
            onChange: () => __async(void 0, null, function* () {
              yield handleStatusChange(original.companyId);
            }),
            inputProps: { "aria-label": "controlled" },
            size: "small"
          }
        ) }) : EmptyCell,
        size: 220
      },
      {
        header: () => "Chat support",
        accessorFn: (row) => row,
        id: "support",
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.hasSupport),
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Switch,
          {
            checked: original.hasSupport,
            onChange: () => __async(void 0, null, function* () {
              yield handleChatSupportChange(original.companyId);
            }),
            inputProps: { "aria-label": "controlled" },
            size: "small"
          }
        ) }) : EmptyCell,
        size: 220
      },
      {
        header: () => "Priority support",
        accessorFn: (row) => row,
        id: "prioritySupport",
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.prioritySupport),
        cell: ({ row: { original } }) => original.companyId ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(
          Switch,
          {
            checked: original.prioritySupport,
            onChange: () => __async(void 0, null, function* () {
              yield handlePrioritySupportChange(original.companyId);
            }),
            inputProps: { "aria-label": "controlled" },
            size: "small"
          }
        ) }) : EmptyCell,
        size: 220
      },
      {
        header: () => "SSO status",
        accessorFn: (row) => row,
        id: "ssoStatus",
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => Boolean(item.ssoMetadata)),
        cell: ({ row: { original } }) => {
          var _a, _b;
          return original.companyId && ((_a = original == null ? void 0 : original.ssoMetadata) == null ? void 0 : _a.length) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx("p", { children: ((_b = original.ssoMetadata[0]) == null ? void 0 : _b.appStub) ? SSOAppsEnum[original.ssoMetadata[0].appStub] : "" }),
            /* @__PURE__ */ jsx(
              Switch,
              {
                checked: !!original.ssoMetadata,
                onChange: (event) => {
                  var _a2;
                  if ((_a2 = original.ssoMetadata) == null ? void 0 : _a2.length) {
                    event.preventDefault();
                    setConfirmingDisableSSO(true);
                    setCompanyIdToDisableSSO(original.companyId);
                    setSSOMetadataForCompanyToDisable(original.ssoMetadata);
                  } else {
                    console.warn("Unable to disable SSO: ", original.ssoMetadata);
                  }
                },
                inputProps: { "aria-label": "controlled" },
                size: "small"
              }
            )
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      }
    ],
    [handleStatusChange, handleChatSupportChange, handlePrioritySupportChange]
  );
  const formattedCompanies = useMemo(() => formatCompanies(companies), [companies]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Companies" }),
        views: /* @__PURE__ */ jsx(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoadingCompanies, children: [
      /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({
              display: "flex",
              justifyContent: "space-between",
              width: "100%"
            }, spacing.mb20), {
              gap: "5px",
              alignItems: "center"
            }),
            children: [
              /* @__PURE__ */ jsx(
                TableSearch,
                {
                  style: { width: "16em" },
                  query: searchQuery,
                  handleChange: (e) => {
                    var _a, _b;
                    setSearchQuery((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                    setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                  },
                  placeholder: "Enter company name or id..."
                }
              ),
              /* @__PURE__ */ jsx(CsvDownloader, { filename: "companies", separator: ",", datas: fetchAllCompaniesForExport, children: /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: __spreadProps(__spreadValues({
                    display: "flex",
                    alignItems: "center",
                    gap: spacing.g5
                  }, secondaryCTABtn), {
                    cursor: "pointer",
                    height: "20px"
                  }),
                  children: [
                    /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                    " ",
                    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Export" })
                  ]
                }
              ) })
            ]
          }
        ),
        !isLoadingCompanies && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
          BasicServerTable,
          {
            rowData: formattedCompanies,
            columnData: companiesColumn,
            sorting,
            setSorting,
            pagination,
            setPagination,
            totalPages,
            totalItems
          }
        ) })
      ] }),
      ssoMetadataForCompanyToDisable && companyIdToDisableSSO && confirmingDisableSSO && /* @__PURE__ */ jsx(
        DisableSSOConfirmDrawer,
        {
          isOpen: confirmingDisableSSO,
          setIsOpen: setConfirmingDisableSSO,
          ssoMetadata: ssoMetadataForCompanyToDisable,
          companyId: companyIdToDisableSSO,
          afterAction: () => __async(void 0, null, function* () {
            setCompanyIdToDisableSSO(null);
            setSSOMetadataForCompanyToDisable(null);
            setConfirmingDisableSSO(false);
            yield fetchCompanies();
          })
        }
      )
    ] })
  ] });
};
