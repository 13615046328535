"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Typography } from "@v2/components/typography/typography.component";
import { DeleteHolidayCalendarDrawer } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/components/delete-holiday-calendar-drawer.component";
import { HolidayCalendarGeneralDrawer } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holiday-calendar-general-drawer.component";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
export const HolidayCalendarGeneralSection = ({ holidayCalendar, refreshAll }) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  return /* @__PURE__ */ jsxs(SettingsSectionContent, { title: polyglot.t("General.general"), onEdit: () => setIsOpen(true), children: [
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            items: [
              {
                type: SectionItemType.Pair,
                label: polyglot.t("General.name"),
                value: holidayCalendar.name
              }
            ]
          },
          {
            title: polyglot.t("HolidayCalendarModule.deleteCalendar"),
            items: [
              {
                type: SectionItemType.TextLine,
                value: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                  polyglot.t("HolidayCalendarModule.deleteCalendarDesc"),
                  " ",
                  /* @__PURE__ */ jsx(
                    "span",
                    {
                      style: { color: themeColors.Red, textDecoration: "underline", cursor: "pointer" },
                      onClick: () => {
                        setIsDeleteOpen(true);
                      },
                      children: polyglot.t("General.here")
                    }
                  ),
                  "."
                ] })
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      DeleteHolidayCalendarDrawer,
      {
        isOpen: isDeleteOpen,
        setIsOpen: setIsDeleteOpen,
        holidayCalendar,
        refresh: refreshAll
      }
    ),
    /* @__PURE__ */ jsx(
      HolidayCalendarGeneralDrawer,
      {
        isOpen,
        setIsOpen,
        holidayCalendar,
        refresh: refreshAll
      }
    )
  ] });
};
