"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  RequiredEnrolmentValueEnum
} from "@/v2/feature/device/features/devices-settings/device-settings.interface";
import { DeviceSettingsAPI } from "@/v2/feature/device/features/devices-settings/devices-settings.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const EnrolmentTabFilter = [
  { name: "All devices", value: RequiredEnrolmentValueEnum.AllDevices },
  { name: "Only company", value: RequiredEnrolmentValueEnum.OnlyCompany }
];
export const EnrolmentSettingsDrawer = ({
  generalSettings,
  onClose
}) => {
  var _a;
  const [isLoading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(
    (_a = generalSettings == null ? void 0 : generalSettings.requiredEnrolment) != null ? _a : RequiredEnrolmentValueEnum.AllDevices
  );
  const [showMessage] = useMessage();
  const handleSettings = () => __async(void 0, null, function* () {
    var _a2;
    try {
      setLoading(true);
      const updateSettings = {
        requiredEnrolment: filterValue,
        hiddenDeviceModels: (_a2 = generalSettings == null ? void 0 : generalSettings.hiddenDeviceModels) != null ? _a2 : null
      };
      yield DeviceSettingsAPI.updateCompanyDeviceSettings(updateSettings);
      yield onClose();
      showMessage(`Successfully updated devices settings`, "success");
    } catch (error) {
      showMessage(`Could not update devices settings.${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey, mb: spacing.m30 }), children: "Enrolment" }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, spacing.mt20), { display: "flex", flexDirection: "column", gap: spacing.g10 }), children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey }), children: "Require enrolment from" }),
      /* @__PURE__ */ jsx(TabFilterButtons, { filters: EnrolmentTabFilter, setFilterValue, filterValue })
    ] }),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        fullWidth: true,
        loading: isLoading,
        onClick: () => handleSettings(),
        style: { marginTop: "40px" },
        colorVariant: "primary",
        sizeVariant: "medium"
      }
    )
  ] });
};
