"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { ReactComponent as Success } from "@/images/side-bar-icons/ok-green.svg";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { MissingAddress } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-address.component";
import { MissingBankAccount } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-bank-account.component";
import { MissingCompensation } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-compensation.component";
import { MissingContract } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-contract.component";
import { MissingPersonal } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-personal.component";
import { MissingTaxInformation } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-tax-information.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const PayrollMissingInformationPage = ({ payrollRecord, refreshPayroll }) => {
  const { polyglot } = usePolyglot();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const handleRefresh = useCallback(() => __async(void 0, null, function* () {
    setIsRefreshing(true);
    yield refreshPayroll == null ? void 0 : refreshPayroll();
    setIsRefreshing(false);
  }), [refreshPayroll]);
  const sections = useMemo(() => {
    var _a, _b;
    const missingPayrollFields = (_b = (_a = payrollRecord.missingFields) == null ? void 0 : _a.payroll) != null ? _b : [];
    const hasMissingField = (cardId) => missingPayrollFields.some((f) => f.cardId === cardId);
    const result = [];
    if (hasMissingField(CustomProfileFormType.Details))
      result.push(/* @__PURE__ */ jsx(MissingPersonal, { userId: payrollRecord.userId, refreshPayroll: handleRefresh }));
    if (hasMissingField(CustomProfileFormType.Address))
      result.push(/* @__PURE__ */ jsx(MissingAddress, { userId: payrollRecord.userId, refreshPayroll: handleRefresh }));
    if (hasMissingField(CustomProfileFormType.BankAccount))
      result.push(
        /* @__PURE__ */ jsx(
          MissingBankAccount,
          {
            userId: payrollRecord.userId,
            defaultName: `${payrollRecord.user.firstName} ${payrollRecord.user.lastName}`,
            refreshPayroll: handleRefresh
          }
        )
      );
    if (hasMissingField(CustomProfileFormType.Salary))
      result.push(/* @__PURE__ */ jsx(MissingCompensation, { userId: payrollRecord.userId, refreshPayroll: handleRefresh }));
    if (hasMissingField(CustomProfileFormType.Contract))
      result.push(/* @__PURE__ */ jsx(MissingContract, { userId: payrollRecord.userId, refreshPayroll: handleRefresh }));
    if (hasMissingField(CustomProfileFormType.TaxInformation))
      result.push(/* @__PURE__ */ jsx(MissingTaxInformation, { userId: payrollRecord.userId, refreshPayroll: handleRefresh }));
    return result;
  }, [payrollRecord, handleRefresh]);
  return /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("PayrollMissing.missingInfo") }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt10 }), children: polyglot.t("PayrollMissing.pleaseCompleteInformation") }),
    /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20, gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: polyglot.t("PayrollMissing.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: payrollRecord.userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }) })
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20 }, children: [
      isRefreshing && /* @__PURE__ */ jsx(LoadingSpinner, {}),
      !isRefreshing && sections.length === 0 && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10, mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Success, { height: "1em", style: { fill: themeColors.Green, flexShrink: 0 } }),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("PayrollMissing.allInfoPresent") })
      ] }),
      !isRefreshing && sections.length > 0 && sections.map((section, idx) => /* @__PURE__ */ jsx(
        Stack,
        {
          sx: { py: spacing.p10, borderTop: idx ? `1px solid ${themeColors.lightGrey}` : void 0 },
          children: section
        },
        idx
      ))
    ] })
  ] });
};
export const PayrollMissingInformationDrawer = ({
  close,
  isOpen,
  payrollRecord,
  refreshPayroll
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: !!isOpen && !!payrollRecord, setIsOpen: () => close(), children: payrollRecord ? /* @__PURE__ */ jsx(PayrollMissingInformationPage, { payrollRecord, refreshPayroll }) : /* @__PURE__ */ jsx(LoadingSpinner, {}) });
};
