"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { JobLevelAPI } from "@/v2/feature/job-level/job-level.api";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const LevelFormDrawer = ({
  isOpen,
  setIsOpen,
  refresh,
  trackName,
  selectedLevel,
  onClose
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDeleteOpened, setIsDeleteOpened] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const deleteLevel = useCallback(() => __async(void 0, null, function* () {
    try {
      setDeleteLoading(true);
      if (selectedLevel == null ? void 0 : selectedLevel.levelId) {
        yield JobLevelAPI.deleteJobLevel(selectedLevel == null ? void 0 : selectedLevel.levelId);
        showMessage(
          polyglot.t("LevelDeleteDrawer.successMessages.delete", {
            levelName: selectedLevel.levelName,
            trackName
          }),
          "success"
        );
        yield refresh == null ? void 0 : refresh();
        setIsDeleteOpened(false);
        onClose();
      }
    } catch (error) {
      showMessage(
        polyglot.t("LevelDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  }), [selectedLevel, trackName, refresh, onClose, showMessage, polyglot]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
      LevelFormContent,
      {
        initialValue: selectedLevel,
        refresh,
        trackName,
        selectedLevel,
        openDeleteDrawer: () => setIsDeleteOpened(true),
        onClose
      }
    ) }),
    /* @__PURE__ */ jsx(
      DeleteDrawer,
      {
        title: polyglot.t("LevelDeleteDrawer.title"),
        description: polyglot.t("LevelDeleteDrawer.confirmDeletion", {
          levelName: selectedLevel == null ? void 0 : selectedLevel.levelName,
          trackName
        }),
        isOpen: isDeleteOpened,
        setIsOpen: setIsDeleteOpened,
        loading: deleteLoading,
        action: deleteLevel,
        onClose: () => setIsDeleteOpened(false)
      }
    )
  ] });
};
const LevelFormContent = ({
  initialValue,
  refresh,
  trackName,
  selectedLevel,
  openDeleteDrawer,
  onClose
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      levelName: (initialValue == null ? void 0 : initialValue.levelName) || "",
      levelDescription: (initialValue == null ? void 0 : initialValue.levelDescription) || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      levelName: Yup.string().required(polyglot.t("validation.requiredField")),
      levelDescription: Yup.string()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      const action = (selectedLevel == null ? void 0 : selectedLevel.levelId) ? "update" : "add";
      try {
        setLoading(true);
        if (trackName === "") {
          throw new Error(polyglot.t("LevelFormContent.errorMessages.noTrack"));
        }
        const jobTrackObj = {
          trackName,
          levelName: values.levelName,
          levelDescription: values.levelDescription
        };
        if (selectedLevel == null ? void 0 : selectedLevel.levelId) {
          yield JobLevelAPI.updateJobLevel(__spreadProps(__spreadValues({}, jobTrackObj), { levelId: selectedLevel.levelId }));
        } else {
          yield JobLevelAPI.createJobLevel(jobTrackObj);
        }
        yield refresh == null ? void 0 : refresh();
        showMessage(polyglot.t(`LevelFormContent.successMessages.${action}`), "success");
        onClose();
      } catch (error) {
        showMessage(
          polyglot.t(`LevelFormContent.errorMessages.${action}`, { nestError: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadValues({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%"
        }, spacing.mb20),
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (initialValue == null ? void 0 : initialValue.levelId) ? polyglot.t("LevelFormContent.edit") : polyglot.t("LevelFormContent.new") }),
          (initialValue == null ? void 0 : initialValue.levelId) && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: openDeleteDrawer, children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) })
        ]
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "levelName",
          label: polyglot.t("LevelFormContent.name"),
          value: formik.values.levelName,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.levelName && !!formik.errors.levelName,
          helperText: (_a = formik.touched.levelName && formik.errors.levelName) != null ? _a : " ",
          clearText: () => formik.setFieldValue("levelName", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "levelDescription",
          multiline: true,
          label: polyglot.t("LevelFormContent.descripton"),
          value: formik.values.levelDescription,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.levelDescription && !!formik.errors.levelDescription,
          helperText: (_b = formik.touched.levelDescription && formik.errors.levelDescription) != null ? _b : " ",
          maxValue: 2,
          endAdornment: "none"
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(LoaderButton, { loading, sizeVariant: "medium", colorVariant: "primary", fullWidth: true, type: "submit", children: polyglot.t("General.save") }) })
    ] })
  ] }) }) });
};
