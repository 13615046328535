"use strict";
import { useEffect, useRef, useState } from "react";
export const useScrollHandler = () => {
  const containerRef = useRef(null);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [showScrollBottomButton, setShowScrollBottomButton] = useState(true);
  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const scrollPosition = scrollTop + clientHeight;
      const totalScrollableHeight = scrollHeight;
      const scrolledPercentage = scrollPosition / totalScrollableHeight * 100;
      if (scrolledPercentage > 70) {
        setShowScrollTopButton(true);
        setShowScrollBottomButton(false);
      } else {
        setShowScrollTopButton(false);
        setShowScrollBottomButton(true);
      }
    }
  };
  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth"
      });
    }
  };
  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  return { containerRef, showScrollBottomButton, showScrollTopButton, scrollToBottom, scrollToTop };
};
