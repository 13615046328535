"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { generatePath } from "react-router-dom";
import { GlobalStateActions } from "@/GlobalState";
import { DASHBOARD_ROUTE, LOGIN_2FA_ROUTE, LOGIN_ROUTE, USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { PARTNER_API_PREFIX } from "@/v2/feature/auth/auth.interface";
import { clearApiClientCache } from "@/v2/infrastructure/api-client/api-client.hook";
import {
  SignInFlow,
  signinWithAzureAD,
  signinWithGoogle,
  signinWithOkta
} from "@/v2/infrastructure/firebase/identity-platform.util";
import { equalsIgnoreCase } from "@/v2/util/string.util";
const postSuccesfulLoginActions = (loginResponse, routerHistory, routerLocation, username, continueOnboarding) => __async(void 0, null, function* () {
  const queries = new URLSearchParams(routerLocation.search);
  if (queries.has("fromUri")) {
    const uri = queries.get("fromUri");
    if (uri && uri.search(/^\/[a-z]/) === 0) {
      window.location.href = uri;
      return;
    }
  } else if (queries.has("code")) {
    const params = routerLocation.search.split("&");
    const code = params[0];
    const appStub = params[1].replace("appStub=", "");
    if (appStub) {
      try {
        yield AppIntegrationAPI.installApps([appStub]);
        window.location = AppIntegrationAPI.getHandleCodeUrl(appStub, code);
      } catch (e) {
      }
    }
  }
  const authMe = yield AuthAPI.getAuthMe();
  if (continueOnboarding) {
    routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId: authMe.user.userId }));
    return;
  }
  if (queries.has("redirect")) {
    const redirect = queries.get("redirect");
    const isPartnerAPIOAuthFlow = redirect == null ? void 0 : redirect.includes(PARTNER_API_PREFIX);
    console.info("Handling redirect: ", redirect, isPartnerAPIOAuthFlow);
    if (redirect) {
      routerHistory.push(redirect);
      return;
    }
  }
  routerHistory.push(DASHBOARD_ROUTE);
});
export const performLogin = (_0, _1, _2, _3) => __async(void 0, [_0, _1, _2, _3], function* (routerHistory, routerLocation, { username, password, mfaCode, deviceId }, continueOnboarding) {
  const loginResponse = yield AuthAPI.login(username, password, mfaCode, deviceId);
  if ("mfaType" in loginResponse) {
    routerHistory.push(LOGIN_2FA_ROUTE, {
      username,
      password,
      mfaType: loginResponse.mfaType
    });
    return;
  }
  yield postSuccesfulLoginActions(loginResponse, routerHistory, routerLocation, username, continueOnboarding);
});
export const performLogout = (routerHistory, dispatch, swrConfig, shutdownIntercom) => __async(void 0, null, function* () {
  clearApiClientCache(swrConfig);
  dispatch({
    type: GlobalStateActions.LOG_OUT,
    payload: true
  });
  yield AuthAPI.logout();
  shutdownIntercom();
  routerHistory.push(LOGIN_ROUTE);
});
export const getSSOLoginResponse = (_0, _1, ..._2) => __async(void 0, [_0, _1, ..._2], function* (userEmail, ssoCheckResult, flow = SignInFlow.Popup, deviceId) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  let ssoResult;
  if ((ssoCheckResult == null ? void 0 : ssoCheckResult.app) === "sso-google-workspace") {
    ssoResult = yield signinWithGoogle(userEmail, ssoCheckResult, flow);
    if (userEmail && (ssoResult == null ? void 0 : ssoResult.user.email) && equalsIgnoreCase(userEmail, (_a = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _a.email) && (ssoResult == null ? void 0 : ssoResult.operationType) === "signIn" && ((_b = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _b.emailVerified) === true) {
      const idToken = yield ssoResult.user.getIdToken();
      return yield AuthAPI.ssoSamlLogin((_c = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _c.email.toLocaleLowerCase(), idToken, deviceId);
    } else {
      throw new Error("Failed to authorize");
    }
  } else if ((ssoCheckResult == null ? void 0 : ssoCheckResult.app) === "sso-okta") {
    ssoResult = yield signinWithOkta(userEmail, ssoCheckResult, flow);
    console.debug(
      `ssoResult: emailFromLoginPage: ${userEmail}, emailFromSSOProvider: ${(_d = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _d.email}, emailVerified: ${(_e = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _e.emailVerified}, provider: ${ssoResult == null ? void 0 : ssoResult.providerId}, operation: ${ssoResult == null ? void 0 : ssoResult.operationType}`
    );
    if (userEmail && (ssoResult == null ? void 0 : ssoResult.user.email) && equalsIgnoreCase(userEmail, (_f = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _f.email) && (ssoResult == null ? void 0 : ssoResult.operationType) === "signIn" && ((_g = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _g.emailVerified) === true) {
      const idToken = yield ssoResult.user.getIdToken();
      return yield AuthAPI.ssoOidcLogin((_h = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _h.email.toLocaleLowerCase(), idToken, deviceId);
    } else {
      throw new Error("Failed to authorize");
    }
  } else if ((ssoCheckResult == null ? void 0 : ssoCheckResult.app) === "sso-azure-ad") {
    ssoResult = yield signinWithAzureAD(userEmail, ssoCheckResult, flow);
    if (userEmail && (ssoResult == null ? void 0 : ssoResult.user.email) && equalsIgnoreCase(userEmail, (_i = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _i.email) && (ssoResult == null ? void 0 : ssoResult.operationType) === "signIn" && ((_j = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _j.emailVerified) === true) {
      const idToken = yield ssoResult.user.getIdToken();
      return yield AuthAPI.ssoSamlLogin((_k = ssoResult == null ? void 0 : ssoResult.user) == null ? void 0 : _k.email.toLocaleLowerCase(), idToken, deviceId);
    } else {
    }
  } else {
    throw new Error(`SSO Provider not yet configured: ${JSON.stringify(ssoCheckResult)}`);
  }
});
export const performSSOLogin = (userEmail, routerHistory, routerLocation, ssoCheckResult, continueOnboarding) => __async(void 0, null, function* () {
  let loginResult = yield getSSOLoginResponse(userEmail, ssoCheckResult);
  yield postSuccesfulLoginActions(
    loginResult,
    routerHistory,
    routerLocation,
    userEmail,
    continueOnboarding
  );
});
