"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@v2/components/typography/typography.component";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { REQUESTS_CHOOSE_REQUEST_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { ActionItemsList } from "@/v2/feature/action-items/components/action-items-list.component";
import { ChartCategoryBarCount } from "@/v2/feature/action-items/components/chart-category-bar-count.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DashboardEndpoints } from "@/v2/feature/dashboard/dashboard.api";
import { RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { ActionItemsDateFilter, ActionItemsTabFilter } from "@/v2/feature/task/task.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { translateDomainTypes } from "@/v2/infrastructure/i18n/translate.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ActionItemsAdminManagerPage = ({ reach }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const routerHistory = useHistory();
  const [actionStatus, setActionStatus] = useState(ActionItemsTabFilter.All);
  const [timeUnitStatus, setTimeUnitStatus] = useState(ActionItemsDateFilter.InSevenDays);
  useEffect(() => {
    var _a2, _b, _c, _d, _e, _f;
    const tabFilter = (_c = (_b = (_a2 = currentUser == null ? void 0 : currentUser.features) == null ? void 0 : _a2.user) == null ? void 0 : _b.actionItems) == null ? void 0 : _c[`${reach}TabFilter`];
    const timeFilter = (_f = (_e = (_d = currentUser == null ? void 0 : currentUser.features) == null ? void 0 : _d.user) == null ? void 0 : _e.actionItems) == null ? void 0 : _f[`${reach}TimeFilter`];
    const tabDefault = reach === ReachType.Team ? ActionItemsTabFilter.PendingActionFromTeam : ActionItemsTabFilter.All;
    setActionStatus(
      tabFilter && Object.values(ActionItemsTabFilter).includes(tabFilter) ? tabFilter : tabDefault
    );
    setTimeUnitStatus(
      timeFilter && Object.values(ActionItemsDateFilter).includes(timeFilter) ? timeFilter : ActionItemsDateFilter.InSevenDays
    );
  }, [reach, currentUser]);
  const { data: actionItems, isLoading: loading, mutate: refreshActionItems } = useApiClient(
    DashboardEndpoints.getAllActionItems(reach, actionStatus, timeUnitStatus),
    {
      suspense: false
    }
  );
  const { data: requestForms } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), {
    suspense: false
  });
  const countByApprovers = useMemo(() => {
    if (!(actionItems == null ? void 0 : actionItems.countByApprovers)) return [];
    return actionItems.countByApprovers.map((item) => ({
      category: /* @__PURE__ */ jsx(UserCell, { userId: item.category, nameVariant: "headline3" }),
      count: item.count,
      progress: item.progress
    }));
  }, [actionItems]);
  const requestByDomains = useMemo(() => {
    if (!(actionItems == null ? void 0 : actionItems.requestByDomains)) return [];
    return actionItems.requestByDomains.map((item) => ({
      category: translateDomainTypes(item.category, polyglot),
      count: item.count,
      progress: item.progress
    }));
  }, [actionItems, polyglot]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserActionList.actions") }),
        showAction: !!(requestForms == null ? void 0 : requestForms.length),
        actions: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => routerHistory.push(REQUESTS_CHOOSE_REQUEST_ROUTE),
              children: polyglot.t("UserActionList.new")
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: __spreadProps(__spreadValues({}, spacing.pt20), { overflow: "hidden" }), children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            gap: spacing.s2,
            "@media (max-width: 600px)": {
              flexDirection: "column",
              gap: spacing.s3
            }
          },
          children: [
            /* @__PURE__ */ jsx(
              ChartCategoryBarCount,
              {
                title: "Requests",
                subtitle: "Pending requests by domain",
                data: requestByDomains != null ? requestByDomains : [],
                loading: Boolean(loading)
              }
            ),
            /* @__PURE__ */ jsx(
              ChartCategoryBarCount,
              {
                title: "Approvers",
                subtitle: "Pending requests by first approver",
                data: countByApprovers != null ? countByApprovers : [],
                loading: Boolean(loading)
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        ActionItemsList,
        {
          reach,
          actionItems: (_a = actionItems == null ? void 0 : actionItems.todos) != null ? _a : [],
          refreshActionItems,
          loading: Boolean(loading),
          actionStatus,
          setActionStatus,
          timeUnitStatus,
          setTimeUnitStatus
        }
      )
    ] }) })
  ] });
};
