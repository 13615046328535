"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { ScopesControl } from "@/component/widgets/Scopes";
import { ReactComponent as CheckCircle } from "@/images/side-bar-icons/CheckCircle.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ActionCard = ({
  avatarImg,
  avatarIcon,
  title,
  titleClick,
  description,
  active,
  actionAltIcon,
  showAction,
  actionText,
  stub,
  actionClick,
  showDivider = false,
  actionScope = [],
  context
}) => /* @__PURE__ */ jsxs(
  Box,
  {
    sx: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      borderBottom: showDivider ? borders.middle : "none",
      paddingBottom: spacing.p25,
      minHeight: "100px",
      cursor: "pointer"
    },
    onClick: titleClick ? () => titleClick() : void 0,
    children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "15px" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { width: "auto" }, children: [
          avatarImg && /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "50px" }, children: /* @__PURE__ */ jsx("img", { src: avatarImg, width: 50, alt: stub }) }),
          avatarIcon && /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "30px" }, children: avatarIcon })
        ] }),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mb: "5px", color: themeColors.DarkGrey }),
              onClick: titleClick ? () => titleClick() : void 0,
              children: title
            }
          ),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: description })
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Box, { children: [
        active && /* @__PURE__ */ jsx(Box, { sx: { mt: "5px", ml: 7 }, children: /* @__PURE__ */ jsx(CheckCircle, __spreadValues({}, iconSize)) }),
        !active && showAction && /* @__PURE__ */ jsx(ScopesControl, { scopes: actionScope, context, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: (event) => {
              event.stopPropagation();
              if (actionClick) actionClick();
            },
            children: actionText
          }
        ) }),
        !active && !showAction && actionAltIcon && /* @__PURE__ */ jsx(Box, { sx: { mt: "5px", ml: 7 }, children: actionAltIcon })
      ] })
    ]
  }
);
