"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ReviewCycleAPI {
  static createReviewCycle(reviewCycleBody) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle`, reviewCycleBody)).data;
    });
  }
  static updateReviewCycle(reviewCycleBody) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle`, reviewCycleBody)).data;
    });
  }
  static launchReviewCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/${cycleId}/launch`)).data;
    });
  }
  static activateReviewCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/${cycleId}/activate`)).data;
    });
  }
  static sendReminderByCycleId(type, cycleId, reach) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/${cycleId}/remind?type=${type}&reach=${reach}`)).data;
    });
  }
  static sendReminderByRevieweeIds(type, remindBody) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/remind?type=${type}`, remindBody)).data;
    });
  }
  // addReviewees add participants and entries in an pre-ongoing cycle
  static addRevieweesToPreOngoing(cycleId, toAddRevieweeIds) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/add-reviewees`, toAddRevieweeIds)).data;
    });
  }
  // addReviewers add participants and entries in an pre-ongoing cycle
  static addReviewersToPreOngoing(cycleId, participants) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/add-reviewers`, participants)).data;
    });
  }
  // removeReviewers add participants and entries in an pre-ongoing cycle
  static removeReviewersToPreOngoing(cycleId, participants) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/delete-reviewers`, participants)).data;
    });
  }
  // addReviewees add participants and entries in an ongoing cycle
  static addRevieweesToOngoing(cycleId, toAddRevieweeIds) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/ongoing/add-reviewees`, toAddRevieweeIds)).data;
    });
  }
  // deleteReviewees removes participants in an pre-ongoing cycle
  static deleteRevieweesToPreOngoing(cycleId, toDeleteRevieweeIds) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/pre-ongoing/delete-reviewees`, toDeleteRevieweeIds)).data;
    });
  }
  // deleteReviewees deletes participants and entries in an ongoing cycle
  static deleteReviewees(cycleId, toDeleteRevieweeIds) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/ongoing/delete-reviewees`, toDeleteRevieweeIds)).data;
    });
  }
  // addReviwers add reviewers and creates entries in an ongoing cycle
  static addReviewers(cycleId, reviewers) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/add-reviewers`, reviewers)).data;
    });
  }
  // removeReviewers deletes reviewers and deletes entries in an ongoing cycle
  static removeReviewers(cycleId, reviewers) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/remove-reviewers`, reviewers)).data;
    });
  }
  static cloneTemplateInCycle(templateId, cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/clone-template/${templateId}/${cycleId}`)).data;
    });
  }
  static cloneQuestionsInCycle(questionBankIds, cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/clone-questions/${cycleId}`, questionBankIds)).data;
    });
  }
  static updateOrderReviewCycle(updateBody) {
    return __async(this, null, function* () {
      return (yield axios.patch("/apiv2/review-cycle/order", updateBody)).data;
    });
  }
  static updateTimelinelinesReviewCycle(cycleId, timelineSettings) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/timeline-settings`, timelineSettings)).data;
    });
  }
  static moveQuestionOutsideSection(updateBody) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/remove-from-section`, updateBody)).data;
    });
  }
  static moveQuestionInsideSection(updateBody) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/add-to-section`, updateBody)).data;
    });
  }
  static finaliseCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/finalise`)).data;
    });
  }
  static reopenCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/reopen`)).data;
    });
  }
  static duplicateCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/${cycleId}/duplicate`)).data;
    });
  }
  static deleteCycle(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/review-cycle/${cycleId}`)).data;
    });
  }
  static updateStateOfRollingReview(cycleId) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/rolling-state`)).data;
    });
  }
  static updateEnrolmentTrigger(cycleId, filter) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/review-cycle/${cycleId}/enrolment-trigger`, filter)).data;
    });
  }
  static generateRollingReviewParticipants(selectedFilters, reach) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/review-cycle/generate/rolling-particpants?reach=${reach}`, { selectedFilters })).data;
    });
  }
}
export class ReviewCycleEndpoints {
  static getEventSourceUrlForReviewCycleLaunch(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-reviewCycleLaunch`
    };
  }
  static getEventSourceUrlForReviewCycleFinalise(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-finaliseCycle`
    };
  }
  static getEventSourceUrlForAddRevieweesInCycle(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-addRevieweesInCycle`
    };
  }
  static getEventSourceUrlForAddReviewersInCycle(channelName) {
    return {
      url: `/apiv2/push-events/${channelName}-addReviewersInCycle`
    };
  }
  static getReviewParticipantsByCycleId(cycleId, reach) {
    return {
      url: `/apiv2/review-cycle/participants/${cycleId}?reach=${reach}`
    };
  }
  static getReviewCycleById(cycleId, reach) {
    return {
      url: `/apiv2/review-cycle/${cycleId}?reach=${reach}`
    };
  }
  static getCycleProgressById(cycleId, reach) {
    return {
      url: `/apiv2/review-cycle/progress/${cycleId}?reach=${reach}`
    };
  }
  static getUserCycleById(cycleId, userId) {
    return {
      url: `/apiv2/review-cycle/user/${cycleId}/${userId}`
    };
  }
  static getReviewLaunchDetailsByCycleId(cycleId) {
    return {
      url: `/apiv2/review-cycle/${cycleId}/launch`
    };
  }
  static getReviewCycleStructureById(cycleId) {
    return {
      url: `/apiv2/review-cycle/structure/${cycleId}`
    };
  }
  static getOngoingCycles(reach, search, filterString) {
    const urlParams = new URLSearchParams(__spreadValues(__spreadValues({
      reach
    }, search && { search }), filterString && { filterString }));
    return {
      url: `/apiv2/review-cycle/ongoing?${urlParams.toString()}`
    };
  }
}
