"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { generatePath, useHistory } from "react-router-dom";
import { BillingSubscriptionAPI } from "@/api-client/billing-subscription.api";
import { BillingAPI } from "@/api-client/billing.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { SETTINGS_BILLING_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import {
  SubscriptionType
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export var PlanNames = /* @__PURE__ */ ((PlanNames2) => {
  PlanNames2["PEOPLE_PRO"] = "People Pro";
  PlanNames2["APPS_PRO"] = "Apps Pro";
  PlanNames2["DEVICES_PRO"] = "Devices Pro";
  PlanNames2["MONEY_PRO"] = "Money Pro";
  PlanNames2["TECH_PRO"] = "Tech Pro";
  PlanNames2["GROWTH_PRO"] = "Growth Pro";
  return PlanNames2;
})(PlanNames || {});
export const UPGRADE_REQUIRED_ERROR = "PLAN UPGRADE REQUIRED";
export const SEAT_LIMIT_ERROR = "SEATS LIMIT REACHED";
export const UpgradeToProModal = ({
  isOpen,
  setIsDrawerOpen,
  planName,
  messageSuffix,
  markup,
  seatsLimitError
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const SUPPORT_EMAIL = "support@zelt.app";
  const [subscriptionDetailsForCompany, setSubscriptionDetailsForCompany] = useState(
    null
  );
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const ANNUAL_BILLING_MESSAGE_SUFFIX = "Your billing is set to annual, if you proceed - one of our team will be in touch to update your current contract with this new subscription.";
  const upgradeMessage = useMemo(() => {
    return {
      proApps: () => polyglot.t("UpgradeToProModal.proApps", { planName }),
      proPeople: () => polyglot.t("UpgradeToProModal.proPeople", { planName }),
      proMoney: () => polyglot.t("UpgradeToProModal.proMoney", { planName }),
      proPermissions: () => polyglot.t("UpgradeToProModal.proPermissions", { planName }),
      proForms: () => polyglot.t("UpgradeToProModal.proForms", { planName }),
      proTemplates: () => polyglot.t("UpgradeToProModal.proTemplates", { planName }),
      proCustomisation: () => polyglot.t("UpgradeToProModal.proCustomisation", { planName }),
      proGeneric: () => polyglot.t("UpgradeToProModal.proGeneric", { planName })
    }[messageSuffix]();
  }, [polyglot, messageSuffix, planName]);
  const seatLimitMessage = useMemo(() => {
    return seatsLimitError ? polyglot.t("UpgradeToProModal.seatsLimitError") : null;
  }, [seatsLimitError, polyglot]);
  const fetchCompanySubscriptionDetails = useCallback(() => __async(void 0, null, function* () {
    const subDetails = yield BillingSubscriptionAPI.getCompanySubscriptionDetails();
    setSubscriptionDetailsForCompany(subDetails);
  }), []);
  useEffect(() => {
    fetchCompanySubscriptionDetails();
  }, [fetchCompanySubscriptionDetails]);
  const companySubscription = useMemo(() => {
    return subscriptionDetailsForCompany ? subscriptionDetailsForCompany.companySubscription : null;
  }, [subscriptionDetailsForCompany]);
  const companyOnAnnualBilling = (companySubscription == null ? void 0 : companySubscription.type) === SubscriptionType.ANNUALLY;
  const handleAnnualBillingCustomerUpgradeIntercept = (companyId, currentUser) => __async(void 0, null, function* () {
    var _a;
    const manualUpgradeBody = {
      companyId,
      companyName: (_a = currentUser.company.name) != null ? _a : "",
      upgradingUserId: currentUser.userId,
      upgradingUser: currentUser,
      upgradeTime: /* @__PURE__ */ new Date()
    };
    return BillingAPI.manualUpgradeIntercept(manualUpgradeBody);
  });
  const handleButtonClick = () => __async(void 0, null, function* () {
    if (companyOnAnnualBilling) {
      const result = yield handleAnnualBillingCustomerUpgradeIntercept(companySubscription.companyId, state.user);
      if (result) {
        showMessage("Someone from the billing team will reach out to you soon", "success");
        setIsDrawerOpen(false);
      } else {
        showMessage("Failed to notify the billing team - please try again", "error");
      }
      return;
    }
    if (seatsLimitError) {
      const subject = encodeURIComponent(`Contract Plan - Seats limit reached`);
      const body = encodeURIComponent(
        `Hello Support,

We have reached our fixed seats limit on the current plan. Please assist us with upgrading our plan or increasing the seats limit.

Thank you.`
      );
      window.location.href = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    } else {
      routerHistory.push(generatePath(SETTINGS_BILLING_ROUTE));
    }
  });
  const upgradeTitle = useMemo(() => {
    if (seatsLimitError && companyOnAnnualBilling) {
      return polyglot.t("UpgradeToProModal.fixedAnnualPlanSeatsFullTitle");
    }
    if (seatsLimitError) {
      return polyglot.t("UpgradeToProModal.seatsLimitTitle");
    }
    return polyglot.t("UpgradeToProModal.toPro");
  }, [seatsLimitError, companyOnAnnualBilling, polyglot]);
  const upgradeText = useMemo(() => {
    if (seatsLimitError && companyOnAnnualBilling) {
      return polyglot.t("UpgradeToProModal.fixedAnnualPlanSeatsFullText") + ` ${ANNUAL_BILLING_MESSAGE_SUFFIX}`;
    } else if (seatsLimitError && !companyOnAnnualBilling) {
      return `${seatLimitMessage} ${upgradeMessage}`;
    } else if (!seatsLimitError && !companyOnAnnualBilling) {
      return `${polyglot.t("UpgradeToProModal.discoveredPro")} ${upgradeMessage}`;
    }
    return `${upgradeMessage}${companyOnAnnualBilling ? ` ${ANNUAL_BILLING_MESSAGE_SUFFIX}` : ""}`;
  }, [
    seatsLimitError,
    companyOnAnnualBilling,
    seatLimitMessage,
    upgradeMessage,
    ANNUAL_BILLING_MESSAGE_SUFFIX,
    polyglot
  ]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen: setIsDrawerOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: upgradeTitle }),
    /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", sx: { lineHeight: "24px" }, children: [
      upgradeText,
      markup
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "primary", onClick: handleButtonClick, children: seatsLimitError && !companyOnAnnualBilling ? polyglot.t("UpgradeToProModal.contactSupport") : polyglot.t("UpgradeToProModal.upgrade") }) })
  ] }) });
};
