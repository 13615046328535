"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import ShowMoreText from "react-show-more-text";
import { CircleProgress } from "@/v2/components/circle-progress.component";
import { ProgressBar } from "@/v2/components/progress-bar.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
const ListItemDetails = ({ title, description, onClick, chips = null, chipsSx = {} }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      onClick,
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        ".show-more-less": {
          textDecoration: "underline"
        }
      },
      children: [
        title ? /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: title }) : null,
        description ? /* @__PURE__ */ jsx(Box, { sx: { minHeight: "20px" }, children: /* @__PURE__ */ jsx(
          ShowMoreText,
          {
            lines: 2,
            more: polyglot.t("General.more"),
            less: polyglot.t("General.showLess"),
            className: "paragraph",
            anchorClass: "show-more-less",
            truncatedEndingComponent: "... ",
            children: /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  "p, span": {
                    margin: 0
                  },
                  color: themeColors.TextPrimary
                },
                dangerouslySetInnerHTML: { __html: typeof description === "string" ? description : "" }
              }
            )
          }
        ) }) : null,
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: __spreadValues({
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "0.25rem"
            }, chipsSx),
            children: chips
          }
        )
      ]
    }
  );
};
export const ListItem = ({
  progressBar,
  details,
  endSection,
  endSectionSx,
  hoverState,
  gridLayout,
  onProgressSectionClick,
  sx
}) => {
  const gridsXs = useMemo(() => gridLayout ? gridLayout : [7, 4, 1], [gridLayout]);
  const progressLabel = useMemo(() => {
    if (!progressBar) return 0;
    if ((progressBar == null ? void 0 : progressBar.progressType) === "percentage") {
      return /* @__PURE__ */ jsxs(Fragment, { children: [
        progressBar.progress,
        "%"
      ] });
    }
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      progressBar.progress,
      "/",
      progressBar.progressTarget
    ] });
  }, [progressBar]);
  const progressPercent = useMemo(() => {
    if (!progressBar) return 0;
    if ((progressBar == null ? void 0 : progressBar.progressType) === "percentage") {
      return progressBar.progress;
    }
    if (!progressBar.progressTarget) return 0;
    return progressBar.progress / progressBar.progressTarget * 100;
  }, [progressBar]);
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      sx: __spreadValues({
        ".MuiGrid-item": { padding: 0 },
        marginTop: 0,
        marginLeft: 0,
        padding: "1rem 1.5rem",
        width: "100%",
        backgroundColor: themeColors.TableHover,
        border: `1px solid ${themeColors.middleGrey2}`,
        borderRadius: "12px",
        "&:hover": (hoverState == null ? void 0 : hoverState.enabled) ? {
          backgroundColor: hoverState.color ? themeColors[hoverState.color] : themeColors.TableHover,
          cursor: "pointer"
        } : void 0
      }, sx),
      cells: [
        {
          content: /* @__PURE__ */ jsx(ListItemDetails, __spreadValues({}, details)),
          gridXs: gridsXs[0]
        },
        {
          content: /* @__PURE__ */ jsx(
            Box,
            {
              onClick: onProgressSectionClick,
              style: {
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "end",
                width: "100%"
              },
              children: progressBar ? /* @__PURE__ */ jsxs(Fragment, { children: [
                progressBar.type === "bar" && /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { letterSpacing: "1px" }, children: progressLabel }),
                /* @__PURE__ */ jsx(
                  Box,
                  {
                    sx: {
                      width: progressBar.type === "bar" ? "12.5rem" : void 0,
                      display: "flex",
                      alignItems: "center"
                    },
                    children: progressBar.type === "bar" || !progressBar.type ? /* @__PURE__ */ jsx(
                      ProgressBar,
                      {
                        label: ``,
                        progress: progressPercent,
                        color: themeColors.PositiveDark,
                        styles: { display: "inline-block" },
                        barStyle: { backgroundColor: themeColors.middleGrey2 },
                        gap: "0.25rem"
                      }
                    ) : /* @__PURE__ */ jsx(
                      CircleProgress,
                      {
                        variant: "determinate",
                        value: progressPercent,
                        color: "GreenMiddle",
                        backgroundColor: "Background",
                        size: "24px"
                      }
                    )
                  }
                ),
                progressBar.type === "circle" && /* @__PURE__ */ jsx(
                  Typography,
                  {
                    variant: progressBar.labelTextVariant ? progressBar.labelTextVariant : "headline2",
                    sx: { letterSpacing: "1px" },
                    children: progressLabel
                  }
                )
              ] }) : null
            }
          ),
          gridXs: gridsXs[1]
        },
        {
          content: /* @__PURE__ */ jsx(Box, { sx: endSectionSx, children: endSection }),
          gridXs: gridsXs[2]
        }
      ]
    }
  );
};
