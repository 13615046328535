"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { linkButtonSx } from "@v2/styles/buttons.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as Info } from "@/images/auth/Info.svg";
import { SETTINGS_BILLING_ROUTE } from "@/lib/routes";
export const UnpaidInvoicesBanner = () => {
  const history = useHistory();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({
        width: "100%",
        backgroundColor: themeColors.Red
      }, themeFonts.paragraph), {
        color: themeColors.black,
        display: "flex",
        justifyContent: "center",
        gap: spacing.g5,
        paddingY: spacing.p10,
        position: "absolute",
        zIndex: 5,
        alignItems: "center",
        opacity: "60%"
      }),
      children: [
        /* @__PURE__ */ jsx(Info, __spreadValues({ fill: "#000" }, iconSize)),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "There are outstanding invoices in your account. Please" }),
        /* @__PURE__ */ jsx(
          Button,
          {
            onClick: () => history.push(SETTINGS_BILLING_ROUTE),
            sx: __spreadProps(__spreadValues(__spreadValues({}, themeFonts.paragraph), linkButtonSx), { color: themeColors.black }),
            children: "make payment"
          }
        ),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "to avoid deactivation." })
      ]
    }
  );
};
