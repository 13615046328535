"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomDEPTokenSteps } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/create-custom-dep-wizard.component";
import { DepWizardHelper } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-wizard-helper";
import { InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const CustomDepTokenResult = ({
  onNext,
  setFormData,
  formData
}) => {
  var _a, _b;
  const [depAccountDetails, setDepAccountDetails] = useState(void 0);
  const [finishedSuccessfully, setFinishedSuccessfully] = useState(false);
  const [showMessage] = useMessage();
  useEffect(() => {
    const fetchData = () => __async(void 0, null, function* () {
      try {
        const response = yield InHouseMdmAPI.getDepAccountDetails(formData.mdmServerName);
        setDepAccountDetails(response);
        if (response && response.access_token_expiry) {
          setFinishedSuccessfully(true);
        }
      } catch (error) {
        showMessage("Error fetching dep account details", "error");
      }
    });
    fetchData();
  }, [formData.mdmServerName, showMessage]);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Auto enrolment overview" }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              mt: spacing.m5,
              display: "grid",
              gridTemplateColumns: "calc(25%) calc(75%)",
              color: themeColors.DarkGrey
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Status" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 }), children: finishedSuccessfully ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
                /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
                " Successfully connected"
              ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
                /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
                " Failed to connect"
              ] }) }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Organisation" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 }), children: (_a = depAccountDetails == null ? void 0 : depAccountDetails.org_name) != null ? _a : "N/A" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "MDM Server name" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 }), children: (_b = depAccountDetails == null ? void 0 : depAccountDetails.server_name) != null ? _b : "N/A" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: "Renew by" }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.black, mt: spacing.m10 }), children: (depAccountDetails == null ? void 0 : depAccountDetails.access_token_expiry) ? DepWizardHelper.formatDate(depAccountDetails == null ? void 0 : depAccountDetails.access_token_expiry) : "N/A" })
            ]
          }
        ) }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "large",
            colorVariant: "primary",
            style: { marginTop: spacing.m25 },
            disabled: !finishedSuccessfully,
            onClick: () => {
              formData.type = CustomDEPTokenSteps.Result;
              setFormData(formData);
              onNext();
            },
            fullWidth: true,
            children: "Finish"
          }
        )
      ] })
    }
  );
};
