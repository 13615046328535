"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { Form, useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { CalendarAPI } from "@/v2/feature/calendar/calendar.api";
import { FieldStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import {
  definitionListSx,
  fieldSx,
  titleSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { truncateWithEllipses } from "@/v2/util/string.util";
export const OutlookCalendarPublishDrawer = ({
  isOpen,
  setIsOpen,
  mode,
  publishLinks,
  refreshCalendarPublishLinks
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    OutlookCalendarPublishDrawerContent,
    {
      setIsOpen,
      mode,
      existingPublishLinks: publishLinks,
      refreshCalendarPublishLinks
    }
  ) });
};
export const OutlookCalendarPublishDrawerContent = ({
  setIsOpen,
  mode,
  existingPublishLinks,
  refreshCalendarPublishLinks
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield CalendarAPI.updatePublishLinksForOutlookCalendar(values);
        yield refreshCalendarPublishLinks();
        setIsOpen(false);
        showMessage(polyglot.t("OutlookCalendarPublishDrawer.successMessages.publish"), "success");
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, refreshCalendarPublishLinks, setIsOpen, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      icsURL: (_a = existingPublishLinks == null ? void 0 : existingPublishLinks.icsURL) != null ? _a : "",
      htmlURL: (_b = existingPublishLinks == null ? void 0 : existingPublishLinks.htmlURL) != null ? _b : ""
    },
    validationSchema: yup.object({
      icsURL: yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      htmlURL: yup.string().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    enableReinitialize: true,
    onSubmit
  });
  return /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t(mode === "publish" ? "OutlookCalendarPublishDrawer.header" : "CalendarPage.subscribe") }),
      mode === "publish" && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("OutlookCalendarPublishDrawer.body") }),
        /* @__PURE__ */ jsx("br", {}),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          polyglot.t("OutlookCalendarPublishDrawer.belowBody"),
          /* @__PURE__ */ jsx("b", { children: polyglot.t("OutlookCalendarPublishDrawer.bodyBold") }),
          polyglot.t("OutlookCalendarPublishDrawer.belowBodyContinued")
        ] }),
        /* @__PURE__ */ jsx("br", {}),
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("OutlookCalendarPublishDrawer.bodyFinal") })
      ] })
    ] }),
    mode === "publish" && /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "icsURL",
          label: polyglot.t("OutlookCalendarPublishDrawer.icsURL"),
          value: formik.values.icsURL,
          onChange: formik.handleChange,
          error: formik.touched.icsURL && !!formik.errors.icsURL,
          helperText: (_c = formik.touched.icsURL && formik.errors.icsURL) != null ? _c : " ",
          endAdornment: "none"
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "htmlURL",
          label: polyglot.t("OutlookCalendarPublishDrawer.htmlURL"),
          value: formik.values.htmlURL,
          onChange: formik.handleChange,
          error: formik.touched.htmlURL && !!formik.errors.htmlURL,
          helperText: (_d = formik.touched.htmlURL && formik.errors.htmlURL) != null ? _d : " ",
          endAdornment: "none"
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m40 }, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("OutlookCalendarPublishDrawer.publish"),
          loading,
          fullWidth: true,
          sizeVariant: "large",
          colorVariant: "primary"
        }
      ) })
    ] }),
    mode === "subscribe" && /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
      (existingPublishLinks == null ? void 0 : existingPublishLinks.icsURL) && /* @__PURE__ */ jsx(
        FieldStructure,
        {
          fieldTitle: polyglot.t("CalendarIntegrationSettingReadonly.icsCalendarLink"),
          fieldValue: (existingPublishLinks == null ? void 0 : existingPublishLinks.icsURL) && {
            isLink: true,
            label: truncateWithEllipses(existingPublishLinks.icsURL, 30),
            icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
            onClick: () => navigator.clipboard.writeText(existingPublishLinks.icsURL)
          },
          fieldStub: "icsURL"
        }
      ),
      (existingPublishLinks == null ? void 0 : existingPublishLinks.htmlURL) && /* @__PURE__ */ jsx(
        FieldStructure,
        {
          fieldTitle: polyglot.t("CalendarIntegrationSettingReadonly.htmlCalendarLink"),
          fieldValue: (existingPublishLinks == null ? void 0 : existingPublishLinks.htmlURL) && {
            isLink: true,
            label: truncateWithEllipses(existingPublishLinks.htmlURL, 30),
            icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
            onClick: () => navigator.clipboard.writeText(existingPublishLinks.htmlURL)
          },
          fieldStub: "htmlURL"
        }
      )
    ] })
  ] });
};
