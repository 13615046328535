"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SpecificUserModal } from "@/v2/components/user-select-type/components/specific-user-modal.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ParticipantsEmptyState } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-empty-state.component";
import { ParticipantsSharedTable } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/shared/participants-shared-table.component";
import {
  ReviewerTypes
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsScheduledView = ({
  reviewCycle,
  isEditable,
  refresh,
  reviewParticipants,
  reviewParticipantsLoading,
  reach
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const [isRevieweeAddOpen, setIsRevieweeAddOpen] = useState(false);
  const [showMessage] = useMessage();
  const totalManagers = useMemo(() => {
    if (!reviewParticipants) return 0;
    return reviewParticipants.reduce((acc, participant) => {
      var _a2;
      return acc + (((_a2 = participant.managerIds) == null ? void 0 : _a2.length) || 0);
    }, 0);
  }, [reviewParticipants]);
  const totalPeers = useMemo(() => {
    if (!reviewParticipants) return 0;
    return reviewParticipants.reduce((acc, participant) => {
      var _a2;
      return acc + (((_a2 = participant.peerIds) == null ? void 0 : _a2.length) || 0);
    }, 0);
  }, [reviewParticipants]);
  const totalUpwards = useMemo(() => {
    if (!reviewParticipants) return 0;
    return reviewParticipants.reduce((acc, participant) => {
      var _a2;
      return acc + (((_a2 = participant.upwardIds) == null ? void 0 : _a2.length) || 0);
    }, 0);
  }, [reviewParticipants]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    reviewCycle && reviewCycle.revieweeId && ((_a = reviewCycle.revieweeId) == null ? void 0 : _a.length) > 0 ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: "Participants",
              items: [
                {
                  type: SectionItemType.Pair,
                  label: "Reviewer select",
                  value: (reviewCycle == null ? void 0 : reviewCycle.reviewerSelect) ? reviewCycle.reviewerSelect.join(", ") : "NA"
                },
                {
                  type: SectionItemType.Pair,
                  label: "Total participants",
                  value: (reviewCycle == null ? void 0 : reviewCycle.revieweeId) ? `${reviewCycle == null ? void 0 : reviewCycle.revieweeId.length} ${(reviewCycle == null ? void 0 : reviewCycle.revieweeId.length) > 1 ? "participants" : "participant"}` : "No participant was selected"
                },
                {
                  type: SectionItemType.Pair,
                  hidden: !((_b = reviewCycle.reviewerSelect) == null ? void 0 : _b.includes(ReviewerTypes.Self)),
                  label: "Self",
                  value: (reviewCycle == null ? void 0 : reviewCycle.revieweeId) ? `${reviewCycle == null ? void 0 : reviewCycle.revieweeId.length}  ${(reviewCycle == null ? void 0 : reviewCycle.revieweeId.length) > 1 ? "participants" : "participant"}` : "\u2013"
                },
                {
                  type: SectionItemType.Pair,
                  hidden: !((_c = reviewCycle.reviewerSelect) == null ? void 0 : _c.includes(ReviewerTypes.Manager)),
                  label: "Manager",
                  value: totalManagers ? `${totalManagers} ${totalManagers > 1 ? "participants" : "participant"}` : "\u2013"
                },
                {
                  type: SectionItemType.Pair,
                  hidden: !((_d = reviewCycle.reviewerSelect) == null ? void 0 : _d.includes(ReviewerTypes.Peer)),
                  label: "Peer",
                  value: totalPeers ? `${totalPeers} ${totalPeers > 1 ? "participants" : "participant"}` : "\u2013"
                },
                {
                  type: SectionItemType.Pair,
                  hidden: !((_e = reviewCycle.reviewerSelect) == null ? void 0 : _e.includes(ReviewerTypes.Upward)),
                  label: "Upward",
                  value: totalUpwards ? `${totalUpwards} ${totalUpwards > 1 ? "participants" : "participant"}` : "\u2013"
                },
                {
                  type: SectionItemType.Component,
                  value: isEditable ? /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      sizeVariant: "small",
                      colorVariant: "primary",
                      onClick: () => setIsRevieweeAddOpen(true),
                      style: { marginTop: spacing.m16 },
                      children: "Add more participants"
                    }
                  ) : null
                }
              ]
            }
          ]
        }
      ),
      /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Completed || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Ongoing ? "Participants part of this cycle" : "Participants who will be invited",
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    ParticipantsSharedTable,
                    {
                      reviewCycle,
                      reviewParticipants,
                      reviewParticipantsLoading,
                      refresh,
                      isEditable
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    ] }) : /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            contentWidth: "100%",
            headerWidth: "100%",
            title: "Participants",
            items: [
              {
                type: SectionItemType.Component,
                value: /* @__PURE__ */ jsx(ParticipantsEmptyState, { setIsOpen: setIsRevieweeAddOpen })
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      SpecificUserModal,
      {
        isSpecificOpen: isRevieweeAddOpen,
        setSpecificIsOpen: setIsRevieweeAddOpen,
        label: "Add participants",
        value: (_f = reviewCycle.revieweeId) != null ? _f : [],
        onSelect: (userIds) => __async(void 0, null, function* () {
          try {
            const newUsers = userIds.filter((u) => {
              var _a2;
              return !((_a2 = reviewCycle.revieweeId) != null ? _a2 : []).includes(u);
            });
            if (!reviewCycle) {
              throw new Error("Review cycle is not defined.");
            }
            const isPreOngoingState = [CycleState.Draft, CycleState.Scheduled].includes(reviewCycle.state);
            if (isPreOngoingState) {
              yield ReviewCycleAPI.addRevieweesToPreOngoing(reviewCycle.id, newUsers);
            }
            if (reviewCycle.state === CycleState.Ongoing) {
              yield ReviewCycleAPI.addRevieweesToOngoing(reviewCycle.id, newUsers);
            }
            showMessage("Successfully added new participants", "success");
            yield refresh();
            setIsRevieweeAddOpen(false);
          } catch (error) {
            showMessage(nestErrorMessage(error), "error");
          }
        }),
        onClose: () => setIsRevieweeAddOpen(false),
        excludeUsers: (_g = reviewCycle.revieweeId) != null ? _g : [],
        reach
      }
    )
  ] });
};
