"use strict";
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  CALCULATION_METHOD_OPTIONS,
  PAYCODE_TYPE_OPTIONS
} from "@v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycodes-table.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AccountingCodeItem } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/accounting-settings/components/accounting-code-item.component";
import {
  MAX_PAYLINE_CODE_LENGTH,
  MAX_PAYLINE_DESCRIPTION_LENGTH
} from "@/v2/feature/payroll/payroll-external.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollNewPayCodesPage = ({
  createCompanyPaycode,
  accountingConfig,
  nominalCodes,
  reimportNominalCodes,
  payCodesNames,
  close
}) => {
  const [updatingPayCode, setUpdatingPayCode] = useState();
  const [calculationMethod, setCalculationMethod] = useState("fixedAmount");
  const validationSchema = useMemo(() => {
    return yup.object({
      code: yup.string().required("Pay code identifier is required").notOneOf(payCodesNames, "Pay code already exists"),
      title: yup.string().required("Pay code name is required"),
      isDeduction: yup.boolean().required("Choose a pay code type"),
      multiple: calculationMethod === "multipleHourly" || calculationMethod === "multipleDaily" ? yup.number().min(0.1, "Must be a positive number").required() : yup.number(),
      percentage: calculationMethod === "percentage" ? yup.number().min(0.1, "Must be a positive number").required() : yup.number()
    });
  }, [calculationMethod, payCodesNames]);
  const formik = useFormik({
    initialValues: {
      title: "",
      code: "",
      isDeduction: false,
      isNiable: false,
      isTaxable: false,
      isPensionable: false,
      isAttachable: false,
      accountingField: void 0,
      multiple: 1,
      // For 'multiple' method
      percentage: 0,
      // For 'percentage' method
      defaultValue: 0
      // Initial value for `defaultValue`
    },
    validationSchema,
    onSubmit: (_a) => __async(void 0, null, function* () {
      var _b = _a, { accountingField } = _b, newPayCode = __objRest(_b, ["accountingField"]);
      const extendedPayCode = {
        title: newPayCode.title,
        code: newPayCode.code,
        isDeduction: newPayCode.isDeduction,
        isNiable: newPayCode.isNiable,
        isTaxable: newPayCode.isTaxable,
        isPensionable: newPayCode.isPensionable,
        isAttachable: newPayCode.isAttachable,
        defaultValue: calculationMethod === "fixedAmount" ? newPayCode.defaultValue : calculationMethod === "multipleHourly" || calculationMethod === "multipleDaily" ? formik.values.multiple || 1 : calculationMethod === "percentage" ? formik.values.percentage || 0 : 0,
        calculationType: calculationMethod === "fixedAmount" ? "FixedAmount" : calculationMethod === "percentage" ? "PercentageOfGross" : calculationMethod === "multipleHourly" ? "MultipleOfHourlyRate" : calculationMethod === "multipleDaily" ? "MultipleOfDailyRate" : "FixedAmount",
        // Fallback
        multiplierType: "None",
        hourlyRateMultiplier: calculationMethod === "multipleHourly" ? formik.values.multiple || 1 : 0,
        dailyRateMultiplier: calculationMethod === "multipleDaily" ? formik.values.multiple || 1 : 0
      };
      setUpdatingPayCode("adding");
      const savedPaycode = yield createCompanyPaycode(extendedPayCode, accountingField);
      setUpdatingPayCode(void 0);
      if (savedPaycode) close();
    })
  });
  useLayoutEffect(() => {
    const cleanCode = formik.values.code.toUpperCase();
    if (cleanCode !== formik.values.code) {
      formik.setFieldValue("code", cleanCode);
    }
  }, [formik]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "New code" }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "code",
        label: "Pay code",
        type: "text",
        onChange: formik.handleChange,
        value: formik.values.code,
        error: formik.touched.code && !!formik.errors.code,
        helperText: formik.touched.code && formik.errors.code,
        disabled: !!updatingPayCode,
        maxLength: MAX_PAYLINE_CODE_LENGTH,
        autoFocus: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "title",
        label: "Name",
        type: "text",
        onChange: formik.handleChange,
        value: formik.values.title,
        error: formik.touched.title && !!formik.errors.title,
        helperText: formik.touched.title && formik.errors.title,
        disabled: !!updatingPayCode,
        maxLength: MAX_PAYLINE_DESCRIPTION_LENGTH
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: PAYCODE_TYPE_OPTIONS,
        onChange: (e) => formik.setFieldValue("isDeduction", e.target.value === "deduction"),
        value: formik.values.isDeduction ? "deduction" : "addition",
        disabled: !!updatingPayCode
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "calculationMethod",
        label: "Calculation Method",
        options: CALCULATION_METHOD_OPTIONS,
        onChange: (e) => setCalculationMethod(e.target.value),
        value: calculationMethod,
        disabled: !!updatingPayCode
      }
    ),
    (calculationMethod === "multipleHourly" || calculationMethod === "multipleDaily") && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "multiple",
        label: calculationMethod === "multipleHourly" ? "Hour multiple" : "Day multiple",
        type: "number",
        onChange: formik.handleChange,
        value: formik.values.multiple,
        error: formik.touched.multiple && !!formik.errors.multiple,
        helperText: formik.touched.multiple && formik.errors.multiple,
        disabled: !!updatingPayCode
      }
    ),
    calculationMethod === "percentage" && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "percentage",
        label: "Percentage",
        type: "number",
        onChange: formik.handleChange,
        value: formik.values.percentage,
        error: formik.touched.percentage && !!formik.errors.percentage,
        helperText: formik.touched.percentage && formik.errors.percentage,
        disabled: !!updatingPayCode
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Subject to" }),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isNiable",
          label: "National Insurance",
          checked: formik.values.isNiable,
          onChange: formik.handleChange,
          disabled: !!updatingPayCode
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isTaxable",
          label: "Tax",
          checked: formik.values.isTaxable,
          onChange: formik.handleChange,
          disabled: !!updatingPayCode
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isPensionable",
          label: "Pension",
          checked: formik.values.isPensionable,
          onChange: formik.handleChange,
          disabled: !!updatingPayCode
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isAttachable",
          label: "Attachment Orders",
          checked: formik.values.isAttachable,
          onChange: formik.handleChange,
          disabled: !!updatingPayCode
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      AccountingCodeItem,
      {
        payCode: formik.values.code,
        nominalCodes,
        reimportNominalCodes,
        accountingConfig,
        accountingField: formik.values.accountingField,
        setAccountingField: (value) => {
          formik.setFieldValue("accountingField", value);
        },
        disabled: !!updatingPayCode
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", loading: !!updatingPayCode, fullWidth: true, sizeVariant: "medium", colorVariant: "primary" }) })
  ] }) });
};
