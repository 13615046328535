"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/system";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const NotFoundBlock = ({ title, subtitle }) => {
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: title }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: subtitle })
  ] });
};
