"use strict";
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  startOfISOWeek,
  endOfISOWeek,
  format
} from "date-fns";
const getDaysInAWeekOptions = { weekStartsOn: 1 };
export function Z_startOfWeek(d) {
  return startOfWeek(d, getDaysInAWeekOptions);
}
export function Z_endOfWeek(d) {
  return endOfWeek(d, getDaysInAWeekOptions);
}
export function getWeekDays(d) {
  return eachDayOfInterval({ start: Z_startOfWeek(d), end: Z_endOfWeek(d) });
}
export function getMonthDays(d) {
  return eachDayOfInterval({ start: startOfMonth(d), end: endOfMonth(d) });
}
export function getWeekRange(weekNo, year) {
  const startDate = startOfISOWeek(new Date(year, 0, (weekNo - 1) * 7 + 1));
  const endDate = endOfISOWeek(startDate);
  const weekStart = format(startDate, "dd MMM");
  const weekEnd = format(endDate, "dd MMM");
  return `${weekStart} - ${weekEnd}`;
}
