"use strict";
import { jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Chip } from "@mui/material";
import { parseISO } from "date-fns";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { convertMinutesToHHhMMmString } from "@/v2/util/time.util";
const THRESHOLD = 10;
const SIXTY_MINUTES = 60;
const ONE_MINUTE_MS = 6e4;
const getTimeOnlyInMinutes = (date) => date.getHours() * SIXTY_MINUTES + date.getMinutes();
const calculateTimeDifference = (time1, time2) => {
  return getTimeOnlyInMinutes(time1) - getTimeOnlyInMinutes(time2);
};
export const ActiveShiftsStartBadge = ({ startTime, scheduledStartTime }) => {
  const { polyglot } = usePolyglot();
  const [currentTime, setCurrentTime] = useState(/* @__PURE__ */ new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(/* @__PURE__ */ new Date());
    }, ONE_MINUTE_MS);
    return () => clearInterval(intervalId);
  }, []);
  const badgeProps = useMemo(() => {
    if (!scheduledStartTime) {
      return { isVisible: false, label: "", color: "" };
    }
    const scheduledTime = parseISO(scheduledStartTime);
    if (!startTime) {
      const minutesDiff2 = calculateTimeDifference(currentTime, scheduledTime);
      if (minutesDiff2 > THRESHOLD) {
        return {
          isVisible: true,
          label: polyglot.t("AttendanceDomain.ActiveShifts.status.late", {
            time: convertMinutesToHHhMMmString(minutesDiff2)
          }),
          color: themeColors.RedDark
        };
      }
      return { isVisible: false, label: "", color: "" };
    }
    const actualStartTime = parseISO(startTime);
    const minutesDiff = calculateTimeDifference(actualStartTime, scheduledTime);
    if (minutesDiff < -THRESHOLD) {
      return {
        isVisible: true,
        label: polyglot.t("AttendanceDomain.ActiveShifts.status.early", {
          time: convertMinutesToHHhMMmString(Math.abs(minutesDiff))
        }),
        color: themeColors.DarkGreen
      };
    } else if (minutesDiff > THRESHOLD) {
      return {
        isVisible: true,
        label: polyglot.t("AttendanceDomain.ActiveShifts.status.late", {
          time: convertMinutesToHHhMMmString(minutesDiff)
        }),
        color: themeColors.RedDark
      };
    } else if (minutesDiff >= -THRESHOLD && minutesDiff <= THRESHOLD) {
      return {
        isVisible: true,
        label: polyglot.t("AttendanceDomain.ActiveShifts.status.onTime"),
        color: themeColors.DarkGreen
      };
    }
    return { isVisible: false, label: "", color: "" };
  }, [startTime, scheduledStartTime, currentTime, polyglot]);
  if (!badgeProps.isVisible || !badgeProps.label || !badgeProps.color) return null;
  return /* @__PURE__ */ jsx(
    Chip,
    {
      label: badgeProps.label,
      size: "small",
      sx: {
        color: badgeProps.color,
        border: `1px solid ${badgeProps.color}`,
        borderRadius: spacing.s8,
        backgroundColor: "transparent"
      }
    }
  );
};
