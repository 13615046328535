"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { JobLevelAPI } from "@/v2/feature/job-level/job-level.api";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const TrackFormDrawer = ({ isOpen, setIsOpen, refresh, onClose }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(TrackFormContent, { initialValue: void 0, refresh, onClose }) });
};
const TrackFormContent = ({ initialValue, refresh, onClose }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: { trackName: initialValue ? initialValue.trackName : "" },
    enableReinitialize: true,
    validationSchema: Yup.object({
      trackName: Yup.string().required()
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const jobTrackObj = {
          trackName: values.trackName,
          levelName: polyglot.t("TrackFormContent.placeholder"),
          levelDescription: polyglot.t("TrackFormContent.pleaseWriteDesc")
        };
        yield JobLevelAPI.createJobTrack(jobTrackObj);
        showMessage(polyglot.t("TrackFormContent.successMessages.add"), "success");
        yield refresh == null ? void 0 : refresh();
        onClose();
      } catch (error) {
        showMessage(polyglot.t("TrackFormContent.errorMessages.add", { nestError: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("TrackFormContent.newTrack") }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column" }, spacing.mt20), children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "trackName",
          label: polyglot.t("TrackFormContent.name"),
          value: formik.values.trackName,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.trackName && !!formik.errors.trackName,
          helperText: (_a = formik.touched.trackName && formik.errors.trackName) != null ? _a : " ",
          clearText: () => formik.setFieldValue("trackName", "")
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt30), children: /* @__PURE__ */ jsx(LoaderButton, { loading, sizeVariant: "medium", colorVariant: "primary", fullWidth: true, type: "submit", children: polyglot.t("General.save") }) })
    ] })
  ] }) });
};
