"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ClickBig } from "@/images/onboarding-intro/ClickBig.svg";
import { ReactComponent as NotiBig } from "@/images/onboarding-intro/NotiBig.svg";
import { ReactComponent as RocketBig } from "@/images/onboarding-intro/RocketBig.svg";
import { ReactComponent as TimeAwayBig } from "@/images/onboarding-intro/TimeAwayBig.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  IntroCardGroup
} from "@/v2/components/theme-components/icon-content-action-card.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { goToEditTemplate } from "@/v2/feature/onboarding/onboarding.util";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingIntro = ({ loading, refresh }) => {
  const { polyglot } = usePolyglot();
  const OnboardingIntroCards = [
    {
      title: polyglot.t("OnboardingIntro.moreConsistent"),
      content: polyglot.t("OnboardingIntro.moreConsistentDesc"),
      iconMedia: /* @__PURE__ */ jsx(RocketBig, {})
    },
    {
      title: polyglot.t("OnboardingIntro.moreAutomated"),
      content: polyglot.t("OnboardingIntro.moreAutomatedDesc"),
      iconMedia: /* @__PURE__ */ jsx(ClickBig, {})
    },
    {
      title: polyglot.t("OnboardingIntro.RightTime"),
      content: polyglot.t("OnboardingIntro.RightTimeDesc"),
      iconMedia: /* @__PURE__ */ jsx(TimeAwayBig, {})
    },
    {
      title: polyglot.t("OnboardingIntro.automaticReminders"),
      content: polyglot.t("OnboardingIntro.automaticRemindersDesc"),
      iconMedia: /* @__PURE__ */ jsx(NotiBig, {})
    }
  ];
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [initialisingOnboarding, setInitialisingOnboarding] = useState(false);
  const setupOnboarding = useCallback(() => __async(void 0, null, function* () {
    setInitialisingOnboarding(true);
    try {
      const firstTemplate = yield OnboardingAPI.createOnboardingTemplate({
        name: "Onboarding flow",
        template: {
          basic: true
        }
      });
      goToEditTemplate(routerHistory, firstTemplate.templateId, "first-template");
      refresh == null ? void 0 : refresh();
    } catch (error) {
      showMessage(
        polyglot.t("OnboardingIntro.errorMessages.initialise", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
      setInitialisingOnboarding(false);
    }
  }), [polyglot, refresh, routerHistory, showMessage]);
  const header = /* @__PURE__ */ jsx(
    TopHeader,
    {
      title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingIntro.onboarding") })
    }
  );
  if (loading) {
    return /* @__PURE__ */ jsx(RootStyle, { children: header });
  }
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    header,
    /* @__PURE__ */ jsx(ContentWrapper, { border: false, sx: { paddingBottom: 0, pt: 0 }, children: /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ height: "100%" }, spacing.px40), children: /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          flex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        },
        children: [
          /* @__PURE__ */ jsx(Stack, { sx: { maxWidth: "600px" }, children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("OnboardingIntro.onboardingDesc") }) }),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("General.start"),
              loading: initialisingOnboarding,
              style: { flexShrink: 0, marginTop: "30px" },
              colorVariant: "primary",
              sizeVariant: "large",
              onClick: () => {
                setupOnboarding();
              }
            }
          ),
          /* @__PURE__ */ jsx(IntroCardGroup, { cards: OnboardingIntroCards, sx: { mt: "auto" } })
        ]
      }
    ) }) })
  ] });
};
