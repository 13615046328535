"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { iconSize } from "@/v2/feature/entity-import/wizard/import-wizard-flow.page";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { pluralText } from "@/v2/util/string.util";
export const ImportedDeviceTableCell = ({ rowData, fieldKey, rowErrors, rawCellValue, statusColumn }) => {
  const getErrorTextForTooltip = (errorObj) => {
    return Object.keys(errorObj.constraints).map((eachKey) => errorObj.constraints[eachKey]).join(", ");
  };
  const errorForColumn = rowErrors == null ? void 0 : rowErrors.find((e) => e.property === fieldKey);
  const getStatus = () => {
    if (statusColumn === 0) {
      return /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
        "\xA0 Valid"
      ] });
    } else if (statusColumn && statusColumn > 0) {
      return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: [
        /* @__PURE__ */ jsx(MistakeIcon, __spreadValues({}, iconSize)),
        "\xA0 ",
        statusColumn,
        " issue",
        pluralText(statusColumn)
      ] });
    } else {
      return /* @__PURE__ */ jsx(Fragment, {});
    }
  };
  const getValueForCell = () => {
    if (!rowData && rawCellValue && rawCellValue.length > 0) return rawCellValue;
    else return rowData && fieldKey ? rowData[fieldKey] : "";
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mr: spacing.mr10 }), children: statusColumn === 0 || statusColumn && statusColumn >= 0 ? getStatus() : getValueForCell() }),
    errorForColumn && /* @__PURE__ */ jsx(StyledTooltip, { title: getErrorTextForTooltip(errorForColumn), children: /* @__PURE__ */ jsx(MistakeIcon, __spreadValues({}, iconSize)) })
  ] });
};
