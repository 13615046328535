"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewAPI } from "@/v2/feature/growth/reviews/api-client/review.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const UnpublishReviewModal = ({
  isOpen,
  setIsOpen,
  onClose,
  results,
  refreshResults
}) => {
  if (!results) return null;
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(UnpublishReviewModalContent, { results, refreshResults, onClose }) });
};
export const UnpublishReviewModalContent = ({
  results,
  refreshResults,
  onClose
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const handleUnpublish = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield ReviewAPI.unpublishReviewResult(results == null ? void 0 : results.reviewId, results.cycleId);
      refreshResults == null ? void 0 : refreshResults();
      onClose();
      showMessage(`${polyglot.t("UnpublishReviewModalContent.successMessages.unpublish")}`, "success");
    } catch (error) {
      showMessage(
        `${polyglot.t("UnpublishReviewModalContent.errorMessages.unpublish")}. ${nestErrorMessage(error)}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [results, onClose, refreshResults, showMessage, polyglot]);
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UnpublishReviewModalContent.title") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UnpublishReviewModalContent.description") }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          onClick: () => handleUnpublish(),
          colorVariant: "primary",
          name: polyglot.t("General.yes"),
          loading,
          fullWidth: true
        }
      )
    ] })
  ] });
};
