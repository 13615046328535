"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useContext } from "react";
import { Menu, MenuItem, styled } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useSWRConfig } from "swr";
import { GlobalContext } from "@/GlobalState";
import { USER_DETAILS_ROUTE } from "@/lib/routes";
import { performLogout } from "@/v2/feature/auth/auth.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledMenu = styled((props) => /* @__PURE__ */ jsx(
  Menu,
  __spreadValues({
    elevation: 0,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    }
  }, props)
))(() => ({
  "& .MuiPaper-root": {
    boxShadow: "0px 2px 20px 0px rgba(13, 13, 14, 0.1)",
    textAlign: "left",
    borderRadius: radius.br10,
    "& .MuiMenu-list": __spreadProps(__spreadValues({}, spacing.pad20), {
      gap: "50px"
    }),
    "& .MuiMenuItem-root": __spreadProps(__spreadValues({
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      padding: "0px",
      minHeight: "20px",
      gap: spacing.g10
    }, themeFonts.paragraph), {
      fill: themeColors.DarkGrey,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        color: themeColors.Grey,
        fill: themeColors.Grey
      }
    })
  }
}));
export const UserAvatarMenu = ({ anchorEl, open, onClose, userId }) => {
  const { polyglot } = usePolyglot();
  const swrConfig = useSWRConfig();
  const routerHistory = useHistory();
  const { shutdown } = useIntercom();
  const goToUserProfile = useCallback(() => {
    (() => __async(void 0, null, function* () {
      routerHistory.push(generatePath(USER_DETAILS_ROUTE, { userId }));
    }))();
  }, [routerHistory, userId]);
  const [, dispatch] = useContext(GlobalContext);
  const closeMenu = useCallback(() => onClose == null ? void 0 : onClose(), [onClose]);
  const options = [
    { label: polyglot.t("UserAvatarMenu.profile"), handler: () => goToUserProfile(), disabled: false },
    {
      label: polyglot.t("UserAvatarMenu.logout"),
      handler: () => performLogout(routerHistory, dispatch, swrConfig, shutdown),
      disabled: false
    }
  ];
  return /* @__PURE__ */ jsx(
    StyledMenu,
    {
      id: `btnMenuOptions`,
      MenuListProps: {
        "aria-labelledby": "btnMenuOptions"
      },
      anchorEl,
      open,
      onClose: closeMenu,
      onClick: closeMenu,
      anchorOrigin: { horizontal: "right", vertical: "center" },
      transformOrigin: { horizontal: "left", vertical: "center" },
      disableAutoFocusItem: true,
      children: options && options.map((option, i) => /* @__PURE__ */ jsx(
        MenuItem,
        {
          id: `btn${option.label}_${i}`,
          onClick: (e) => {
            option.handler();
            onClose == null ? void 0 : onClose();
            e.stopPropagation();
          },
          disableRipple: true,
          disabled: (option == null ? void 0 : option.disabled) || false,
          sx: { marginTop: i > 0 ? "10px" : 0 },
          children: option.label
        },
        option.label
      ))
    }
  );
};
