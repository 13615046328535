"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useRef, useState } from "react";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import {
  HMRC_ACCOUNT,
  mapPaymentToCSVFormatByInstitution,
  yappilyInstitutionToLogo
} from "@v2/feature/payments/payments.util";
import { UserBankAccountAPI } from "@v2/feature/user/features/user-forms/user-bank-account/user-bank-account.api";
import { getCSVRows, toCsv } from "@v2/util/csv.util";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PAYMENTS_TEAM_CREATE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StepperHeader } from "@/v2/components/stepper-header.component";
import { IconContentRadioCard } from "@/v2/components/theme-components/icon-content-radio-card.component";
import { StyledRadio } from "@/v2/components/theme-components/styled-radio.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { PayerDetailsDrawer } from "@/v2/feature/payments/features/make-payment/components/payer-details-drawer.component";
import { usePaymentContext } from "@/v2/feature/payments/features/make-payment/payment.context";
import { PaymentsEndpoints } from "@/v2/feature/payments/payments.api";
import {
  GOLDMAN_SACHS_INSTITUTION,
  YapilyInstitutions
} from "@/v2/feature/payments/payments.interface";
import { PAYMENTS_CREATE_ROUTE, PAYMENTS_ROUTE } from "@/v2/feature/payments/payments.router";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const InstitutionButton = ({
  institution,
  showBorder,
  setSelectedInstitution,
  selectedInstitution
}) => {
  var _a, _b, _c, _d, _e;
  const supportsBulkPayment = !!((_b = (_a = institution.config) == null ? void 0 : _a.bulkPayment) == null ? void 0 : _b.supportsImmediatePayments) || !!((_d = (_c = institution.config) == null ? void 0 : _c.bulkPayment) == null ? void 0 : _d.supportsScheduledPayments);
  return /* @__PURE__ */ jsx(
    IconContentRadioCard,
    {
      cardOnClick: () => setSelectedInstitution(institution.id),
      title: institution.name,
      content: supportsBulkPayment ? "Supports bulk payment" : null,
      iconMedia: institution.id === YapilyInstitutions.GoldmanSachs || ((_e = institution == null ? void 0 : institution.media[0]) == null ? void 0 : _e.source) ? yappilyInstitutionToLogo(institution) : /* @__PURE__ */ jsx(AccountBalanceOutlinedIcon, { sx: { fontSize: "40px" } }),
      action: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "end" }, children: /* @__PURE__ */ jsx(
        StyledRadio,
        {
          name: institution.name,
          id: institution.id,
          value: institution.id,
          onChange: (e) => {
            setSelectedInstitution(e.target.value);
          },
          selectedValue: selectedInstitution
        }
      ) }),
      showDivider: showBorder,
      sx: { minHeight: "40px", maxHeight: "40px" }
    }
  );
};
const OtherBanksButton = ({
  setSelectedInstitution,
  selectedInstitution
}) => {
  return /* @__PURE__ */ jsx(
    IconContentRadioCard,
    {
      cardOnClick: () => setSelectedInstitution(YapilyInstitutions.OtherBanks),
      title: "Other banks",
      content: "Download your payments as CSV",
      action: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: 1, justifyContent: "center", alignItems: "end" }, children: /* @__PURE__ */ jsx(
        StyledRadio,
        {
          name: YapilyInstitutions.OtherBanks,
          id: YapilyInstitutions.OtherBanks,
          value: YapilyInstitutions.OtherBanks,
          onChange: () => {
            setSelectedInstitution(YapilyInstitutions.OtherBanks);
          },
          selectedValue: selectedInstitution
        }
      ) }),
      sx: { minHeight: "40px", maxHeight: "40px" }
    }
  );
};
function BankList({ onChange, shouldFilterByPaymentType }) {
  var _a;
  const [state] = usePaymentContext();
  const routerLocation = useLocation();
  const [selectedInstitution, setSelectedInstitution] = useState(void 0);
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const payments = routerLocation.state;
  const paymentType = (payments == null ? void 0 : payments.length) === 1 ? "INITIATE_DOMESTIC_SINGLE_PAYMENT" : "INITIATE_BULK_PAYMENT";
  const csvRef = useRef(void 0);
  const [isPayerDetailsOpen, setIsPayerDetailsOpen] = useState(false);
  const [payerBankDetails, setPayerBankDetails] = useState(null);
  const [csvFile, setCSVFile] = useState({
    name: "default.csv",
    data: []
  });
  const { data: allPaymentInstitutions } = useApiClient(PaymentsEndpoints.getPaymentInstitution());
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const institutionsFilteredByPaymentType = allPaymentInstitutions == null ? void 0 : allPaymentInstitutions.filter(
    (institution) => institution.features.includes(paymentType)
  );
  const institutionsToShow = shouldFilterByPaymentType ? institutionsFilteredByPaymentType != null ? institutionsFilteredByPaymentType : [] : [...allPaymentInstitutions != null ? allPaymentInstitutions : [], GOLDMAN_SACHS_INSTITUTION];
  const institutionObj = (_a = institutionsToShow == null ? void 0 : institutionsToShow.find((i) => i.id === selectedInstitution)) != null ? _a : void 0;
  const fetchCurrentUserBankAccounts = useCallback(() => __async(this, null, function* () {
    if (!(state == null ? void 0 : state.payments) || state.payments.length === 0) return {};
    try {
      const userIds = Array.from(
        new Set(state.payments.map((payment) => payment.userId).filter((userId) => !!userId))
      );
      return UserBankAccountAPI.findCurrentByUserIds(userIds);
    } catch (error) {
      showMessage("Could not load users bank details.", "error");
    }
    return {};
  }), [state == null ? void 0 : state.payments, showMessage]);
  const formatPaymentsToCSV = useCallback(
    (institutionId, payments2, directDetails) => __async(this, null, function* () {
      try {
        const payeesMappings = yield fetchCurrentUserBankAccounts();
        return payments2.map((payment) => {
          var _a2;
          const payee = payment.userId ? getCachedUserById(payment.userId) : void 0;
          let companyName = !(payment == null ? void 0 : payment.userId) ? HMRC_ACCOUNT.accountName : "";
          const payeeBankAccount = payment.userId ? (_a2 = payeesMappings[payment.userId]) != null ? _a2 : null : HMRC_ACCOUNT;
          if (payment.userId && !payee || !payeeBankAccount)
            showMessage(
              `Could not find some details for user with userId = ${payment.userId}. You should introduce those details manually`,
              "error"
            );
          return mapPaymentToCSVFormatByInstitution(
            institutionId,
            payment,
            payeeBankAccount,
            payee,
            companyName,
            directDetails != null ? directDetails : payerBankDetails
          );
        });
      } catch (error) {
        showMessage(`Could not load users bank accounts. ${nestErrorMessage(error)}`, "error");
        return [];
      }
    }),
    [fetchCurrentUserBankAccounts, getCachedUserById, showMessage, payerBankDetails]
  );
  useEffect(() => {
    if ((csvFile == null ? void 0 : csvFile.data) && csvFile.name !== "default.csv" && csvRef.current && csvRef.current.link) {
      csvRef.current.link.click();
    }
  }, [csvFile]);
  const handlePayerDetailsSubmit = (details) => __async(this, null, function* () {
    setPayerBankDetails(details);
    setIsPayerDetailsOpen(false);
    setTimeout(() => {
      exportPaymentsCSV(details);
    }, 300);
  });
  const exportPaymentsCSV = (directDetails = null) => __async(this, null, function* () {
    var _a2;
    if (!institutionObj && selectedInstitution !== YapilyInstitutions.OtherBanks || !state.payments || state.payments.length === 0) {
      showMessage("No payments selected.", "error");
      return;
    }
    if ((selectedInstitution === YapilyInstitutions.HSBCNet || selectedInstitution === YapilyInstitutions.GoldmanSachs) && !directDetails && !payerBankDetails) {
      setIsPayerDetailsOpen(true);
      return;
    }
    setIsCsvLoading(true);
    try {
      const csvPayments = yield formatPaymentsToCSV(
        (_a2 = institutionObj == null ? void 0 : institutionObj.id) != null ? _a2 : YapilyInstitutions.OtherBanks,
        state.payments,
        directDetails
      );
      if (!csvPayments || csvPayments.length === 0) return;
      const data = toCsv(getCSVRows(csvPayments));
      setCSVFile({ name: `payments-${(/* @__PURE__ */ new Date()).toLocaleDateString()}.csv`, data });
    } catch (error) {
      showMessage(`Something wrong happened. ${error.message}`, "error");
    } finally {
      setIsCsvLoading(false);
    }
  });
  const validatePayment = () => {
    var _a2, _b, _c, _d, _e;
    if (state.payments && ((_a2 = state.payments) == null ? void 0 : _a2.length) > 1) {
      const bankObj = institutionsToShow.find((ins) => ins.id === selectedInstitution);
      return bankObj ? !!((_c = (_b = bankObj.config) == null ? void 0 : _b.bulkPayment) == null ? void 0 : _c.supportsImmediatePayments) || !!((_e = (_d = bankObj.config) == null ? void 0 : _d.bulkPayment) == null ? void 0 : _e.supportsScheduledPayments) : false;
    }
    if (selectedInstitution === YapilyInstitutions.GoldmanSachs) {
      return false;
    }
    return true;
  };
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Grid, { container: true, columnSpacing: 4, children: [
      institutionsToShow.map(
        (institution, i) => institution.id !== YapilyInstitutions.SiliconValleyBank ? /* @__PURE__ */ jsx(Grid, { item: true, sm: 12, md: 6, children: /* @__PURE__ */ jsx(
          InstitutionButton,
          {
            institution,
            showBorder: i < institutionsToShow.length - 1,
            setSelectedInstitution,
            selectedInstitution
          }
        ) }, i) : null
      ),
      /* @__PURE__ */ jsx(Grid, { item: true, sm: 12, md: 6, children: /* @__PURE__ */ jsx(OtherBanksButton, { setSelectedInstitution, selectedInstitution }) }, "other_banks")
    ] }),
    (institutionObj || selectedInstitution === YapilyInstitutions.OtherBanks) && selectedInstitution !== void 0 && /* @__PURE__ */ jsxs(Grid, { container: true, columnSpacing: 4, children: [
      /* @__PURE__ */ jsx(Grid, { item: true, sm: 12, md: 6, sx: __spreadValues({}, spacing.mt20), onClick: () => exportPaymentsCSV(), children: /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", loading: isCsvLoading, children: "Download CSV" }) }),
      institutionObj && validatePayment() && /* @__PURE__ */ jsx(
        Grid,
        {
          item: true,
          sm: 12,
          md: 6,
          sx: __spreadValues({}, spacing.mt20),
          onClick: () => {
            if (selectedInstitution === YapilyInstitutions.HSBCNet && !payerBankDetails) {
              setIsPayerDetailsOpen(true);
              return;
            }
            onChange(institutionObj);
          },
          children: /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "primary", children: "Continue to pay" })
        }
      ),
      /* @__PURE__ */ jsx(
        CSVLink,
        {
          ref: csvRef,
          filename: csvFile.name,
          data: Array.isArray(csvFile.data) || typeof csvFile.data === "string" ? csvFile.data : []
        }
      ),
      /* @__PURE__ */ jsx(
        PayerDetailsDrawer,
        {
          isOpen: isPayerDetailsOpen,
          setIsOpen: setIsPayerDetailsOpen,
          onSubmit: handlePayerDetailsSubmit,
          institution: selectedInstitution
        }
      )
    ] })
  ] });
}
function BankListSkeleton() {
  return /* @__PURE__ */ jsx(Grid, { container: true, columnSpacing: 4, children: Array.from(Array(22).keys()).map((i) => /* @__PURE__ */ jsx(Grid, { item: true, sm: 12, md: 6, children: /* @__PURE__ */ jsx(
    Skeleton,
    {
      width: "100%",
      height: 80,
      animation: "wave",
      sx: { backgroundColor: themeColors.Background }
    },
    i
  ) }, i)) });
}
export function SelectBankForm({ shouldFilterByPaymentType = false }) {
  const [, setState] = usePaymentContext();
  const [globalState] = useContext(GlobalContext);
  const scopesContext = { userId: globalState.user.userId };
  const { hasScopes } = useScopes();
  const isAdminUser = hasScopes(["payments:all"], scopesContext);
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, noHeader: true, border: false, sx: __spreadValues({ paddingTop: spacing.p30 }, spacing.px40), children: [
    /* @__PURE__ */ jsx(
      StepperHeader,
      {
        content: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: "Which bank are you sending the wire from?" }),
        contentSx: { width: "90%", textAlign: "center" },
        showStepBack: false,
        showBack: true,
        backAction: () => {
          routerHistory.push(PAYMENTS_ROUTE);
        },
        backSx: { width: "10%", textAlign: "right" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { display: "flex", justifyContent: "center", sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "800px" }, children: /* @__PURE__ */ jsx(Suspense, { fallback: /* @__PURE__ */ jsx(BankListSkeleton, {}), children: /* @__PURE__ */ jsx(
      BankList,
      {
        onChange: (institution) => {
          setState(({ payments, requests }) => {
            return {
              payments,
              requests,
              institution
            };
          });
          routerHistory.push(isAdminUser ? PAYMENTS_CREATE_ROUTE : PAYMENTS_TEAM_CREATE_ROUTE);
        },
        shouldFilterByPaymentType
      }
    ) }) }) })
  ] }) });
}
