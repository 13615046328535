"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { TableSearch } from "@v2/components/table/table-search.component";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { GrowthFactorFormModal } from "@/v2/feature/growth/growth-factor/components/growth-factor-form-modal.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { iconSize } from "@/v2/feature/onboarding/onboarding-template-edit.page";
import { PayrollLogoZelt } from "@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const GrowthFactorPage = () => {
  const { data: allGrowthFactors, isLoading, mutate: refreshFactors } = useApiClient(
    GrowthFactorEndpoints.getGrowthFactorsByCompanyId(),
    {
      suspense: false
    }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFactor, setSelectedFactor] = useState(void 0);
  const [searchInput, setSearchInput] = useState("");
  const filteredFactors = useMemo(() => {
    if (!searchInput || !allGrowthFactors) return allGrowthFactors != null ? allGrowthFactors : [];
    const search = searchInput.toLowerCase();
    return allGrowthFactors.filter((f) => f.name.toLowerCase().includes(search));
  }, [searchInput, allGrowthFactors]);
  const getGrowthFactorActionsOptions = useCallback((original) => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => {
          setSelectedFactor(original);
          setIsOpen(true);
        },
        label: "Edit",
        disabled: false
      }
    ];
  }, []);
  return /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: "Factors",
        contentWidth: "100%",
        headerWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsOpen(true), children: "New factor" })
        ],
        children: /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            loadingAll: isLoading,
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TableSearch,
                      {
                        query: searchInput,
                        handleChange: (e) => {
                          setSearchInput(e.target.value);
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (filteredFactors || []).map((item) => {
                      var _a;
                      const avatar = item.companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
                        /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "0.9em", width: "0.9em", padding: ".25em" }),
                        "Zelt"
                      ] }) : /* @__PURE__ */ jsx(UserCell, { userId: item.createdBy });
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                            /* @__PURE__ */ jsx(
                              "div",
                              {
                                style: {
                                  backgroundColor: (_a = item.color) != null ? _a : themeColors.Grey,
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: radius.br25
                                }
                              }
                            ),
                            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: item.name })
                          ] }),
                          boxSx: responsiveCardStyle,
                          contentItemsSets: [
                            {
                              type: ContentItemType.component,
                              contentComponent: /* @__PURE__ */ jsx(
                                ChipComponent,
                                {
                                  name: avatar,
                                  backgroundColor: "white",
                                  textColor: "DarkGrey",
                                  textVariant: "paragraph",
                                  border: "middle"
                                }
                              )
                            },
                            {
                              name: `${new LocalDate(item.createdAt).getDate().toLocaleDateString(void 0, {
                                day: "numeric",
                                month: "short",
                                year: "numeric"
                              })}`,
                              type: ContentItemType.chip,
                              textColor: "DarkGrey",
                              backgroundColor: "white",
                              border: "middle"
                            }
                          ],
                          advanceActionHidden: !item.companyId,
                          advanceActionOptions: getGrowthFactorActionsOptions(item)
                        },
                        item.name
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      GrowthFactorFormModal,
      {
        growthFactor: selectedFactor,
        setIsOpen,
        isOpen,
        onClose: () => {
          setIsOpen(false);
          setSelectedFactor(void 0);
        },
        refresh: () => __async(void 0, null, function* () {
          yield refreshFactors == null ? void 0 : refreshFactors();
        })
      }
    )
  ] });
};
