"use strict";
import { themeColors } from "@/v2/styles/colors.styles";
export const FONT_PRIMARY = "Roboto, sans-serif !important";
const FONT_SECONDARY = "Dancing Script, cursive !important";
export const HEADER_FONT_PRIMARY = "Roboto, sans-serif !important";
const BASE_FONT_SIZE = 16;
const pxtorem = (px) => {
  return `${px / BASE_FONT_SIZE}rem`;
};
export const themeFonts = {
  headline1: {
    fontFamily: HEADER_FONT_PRIMARY,
    fontSize: pxtorem(24),
    fontWeight: 500,
    letterSpacing: pxtorem(0),
    lineHeight: "130%",
    color: themeColors.DarkGrey
  },
  headline2: {
    fontFamily: HEADER_FONT_PRIMARY,
    fontSize: pxtorem(18),
    fontWeight: 500,
    letterSpacing: pxtorem(0),
    lineHeight: "130%"
  },
  headline3: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(14),
    fontWeight: 500,
    letterSpacing: pxtorem(0),
    lineHeight: "150%"
  },
  headline4: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(12),
    fontWeight: 500,
    letterSpacing: pxtorem(0),
    lineHeight: "130%"
  },
  bodyLarge: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(16),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "150%"
  },
  paragraph: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(14),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "150%"
  },
  paragraphSmall: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(12),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "130%"
  },
  //check later
  signature: {
    fontFamily: FONT_SECONDARY,
    fontSize: 26
  },
  tooltip: {
    fontFamily: FONT_PRIMARY,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "-0.3px !important",
    lineHeight: "16px",
    color: themeColors.white
  },
  // custom avatar fonst
  avatar100: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(24),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "150%"
  },
  avatar40: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(18),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "150%"
  },
  avatar20: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(10),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "130%"
  },
  avatar16: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(8),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "130%"
  },
  avatar14: {
    fontFamily: FONT_PRIMARY,
    fontSize: pxtorem(6),
    fontWeight: 400,
    letterSpacing: pxtorem(0),
    lineHeight: "130%"
  }
};
