"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { ApprovalRuleDrawer } from "@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component";
import { ScheduleTrackingType } from "@v2/feature/attendance/attendance.interface";
import { getScheduleApprovalValidationSchema } from "@v2/feature/attendance/attendance.util";
import { drawerContentSx, fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useScopes from "@/hooks/scopes.hook";
import { Typography } from "@/v2/components/typography/typography.component";
const getApprovalRulesOptions = (approvalRules) => approvalRules.map((rule) => ({
  label: rule.name,
  value: rule.id
}));
export const ScheduleApprovalForm = ({
  attendanceSchedule,
  approvalRules,
  refreshApprovalRules,
  onSubmit,
  buttonName,
  loading
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      approvalRuleId: attendanceSchedule.approvalRuleId
    },
    validationSchema: getScheduleApprovalValidationSchema(polyglot),
    onSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ScheduleApprovalForm.approverNotified") }),
    attendanceSchedule.trackingType === ScheduleTrackingType.None && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ScheduleApprovalForm.notRequired") }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "approvalRuleId",
        label: polyglot.t("ScheduleApprovalForm.approvalRuleId"),
        options: getApprovalRulesOptions(approvalRules),
        value: (_a = formik.values.approvalRuleId) != null ? _a : "",
        onChange: formik.handleChange,
        error: !!formik.errors.approvalRuleId && formik.touched.approvalRuleId,
        helperText: formik.touched.approvalRuleId && formik.errors.approvalRuleId,
        editList: {
          isHidden: !hasScopes(["company.settings:all"]),
          handler: () => {
            setIsApprovalDrawerOpen(true);
          }
        }
      }
    ) }),
    /* @__PURE__ */ jsx(
      ApprovalRuleDrawer,
      {
        isOpen: isApprovalDrawerOpen,
        setIsOpen: setIsApprovalDrawerOpen,
        approvalRule: null,
        refresh: () => __async(void 0, null, function* () {
          if (refreshApprovalRules) yield refreshApprovalRules();
        })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { name: buttonName, loading, fullWidth: true, sizeVariant: "medium", colorVariant: "primary" }) })
  ] }) });
};
