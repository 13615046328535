"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { UserHeader } from "@/v2/components/user-header.component";
import { MONTHLY_PAY_TEXT, WEEKLY_PAY_TEXT } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.interface";
import { getOptionalPayCodesInUse } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayrollLocalApi } from "@/v2/feature/payroll/payroll-local.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { sum } from "@/v2/util/array.util";
import { fixedAmount, formatCurrency, removeTrailingZeroes } from "@/v2/util/currency-format.util";
const SummaryRow = ({ description, amount, subtract, hideIfZero, sx, textSx }) => {
  if (hideIfZero && !amount) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  const numericAmount = (amount != null ? amount : 0) * (subtract ? -1 : 1);
  return /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ flexFlow: "row", justifyContent: "space-between", gap: spacing.g10 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadValues({}, themeFonts.paragraph), textSx), children: description }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadValues({}, themeFonts.paragraph), textSx), children: formatCurrency(numericAmount) })
  ] });
};
const isUserProratedInPayrun = (payrunEntry) => {
  if (!payrunEntry) return false;
  const payrunStartDate = new Date(payrunEntry.startDate);
  const payrunEndDate = new Date(payrunEntry.endDate);
  const { starterDetails, leaverDetails } = payrunEntry.employmentDetails;
  if ((leaverDetails == null ? void 0 : leaverDetails.hasLeft) && leaverDetails.leaveDate) {
    const leaveDate = new Date(leaverDetails.leaveDate);
    if (leaveDate <= payrunEndDate) {
      return true;
    }
  }
  if (starterDetails == null ? void 0 : starterDetails.startDate) {
    const startDate = new Date(starterDetails.startDate);
    if (startDate >= payrunStartDate) {
      return true;
    }
  }
  return false;
};
const deconstructPaylineTags = (tags) => {
  const result = {};
  for (const tag of tags) {
    const [key, value] = tag.split("=");
    result[key] = value;
  }
  return result;
};
export const SalarySummaryPage = ({ user, payrunEntry, localPayRun, customPayCodes }) => {
  var _a, _b;
  const getOptionalPayCodeSummaryRows = useCallback(
    (payrunEntry2, paycodes, kind, taxBasis) => {
      const BASICPayCodeFirst = (a, b) => {
        var _a2, _b2, _c, _d;
        const aIsMainPay = ((_a2 = a.description) == null ? void 0 : _a2.includes(MONTHLY_PAY_TEXT)) || ((_b2 = a.description) == null ? void 0 : _b2.includes(WEEKLY_PAY_TEXT));
        const bIsMainPay = ((_c = b.description) == null ? void 0 : _c.includes(MONTHLY_PAY_TEXT)) || ((_d = b.description) == null ? void 0 : _d.includes(WEEKLY_PAY_TEXT));
        if (aIsMainPay && !bIsMainPay) return -1;
        if (!aIsMainPay && bIsMainPay) return 1;
        return 0;
      };
      return getOptionalPayCodesInUse(paycodes != null ? paycodes : [], [payrunEntry2], kind === "deduction").filter(
        taxBasis === "pretax" ? (paycode) => paycode.isTaxable || paycode.isNiable || paycode.isPensionable : (paycode) => !(paycode.isTaxable || paycode.isNiable || paycode.isPensionable)
      ).flatMap(
        ({ code }) => (
          // extract all the pay lines matching the pay code
          payrunEntry2.payOptions.regularPayLines.filter(
            (payline) => payline.code === code && !payline.isAutoGeneratedBasicPayLine
          )
        )
      ).sort(
        (a, b) => (
          // group paycodes together (with BASIC always first so it is next to the normal salary) and then sort by their display name
          BASICPayCodeFirst(a, b) || a.code.localeCompare(b.code) || (a.description || a.code).localeCompare(b.description || b.code, void 0, { sensitivity: "base" })
        )
      ).map((pl) => {
        var _a2, _b2, _c, _d, _e, _f, _g, _h;
        const tags = deconstructPaylineTags((_a2 = pl.tags) != null ? _a2 : []);
        const descriptionUnits = "units" in tags && tags.units === "quantity" ? "" : "h";
        const hasRateInfoInPayline = ((_b2 = pl.description) == null ? void 0 : _b2.includes("*")) && ((_c = pl.description) == null ? void 0 : _c.includes("hrs")) || ((_d = pl.description) == null ? void 0 : _d.includes("*")) && ((_e = pl.description) == null ? void 0 : _e.includes("%")) || ((_f = pl.description) == null ? void 0 : _f.includes("\xD7"));
        const description = hasRateInfoInPayline ? (_g = pl.description) != null ? _g : pl.code || "" : pl.rate && pl.multiplier ? `${pl.description || pl.code} (${fixedAmount(pl.rate)} \xD7 ${removeTrailingZeroes(
          (_h = fixedAmount(pl.multiplier)) != null ? _h : "0"
        )}${descriptionUnits})` : pl.description || pl.code || "";
        return /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description,
            amount: pl.value,
            subtract: kind === "deduction",
            hideIfZero: pl.value === 0 && kind === "addition" ? false : true
          }
        );
      });
    },
    []
  );
  const getRegularPaylineTotal = useCallback(
    (paylineCode) => {
      const entries = payrunEntry.payOptions.regularPayLines.filter(({ code }) => code === paylineCode);
      return entries.length ? sum(entries, ({ value }) => value) : void 0;
    },
    [payrunEntry.payOptions.regularPayLines]
  );
  const isProrated = isUserProratedInPayrun(payrunEntry);
  const grossText = "Gross";
  const payPeriodValue = (_a = payrunEntry.payOptions.regularPayLines.find(
    ({ isAutoGeneratedBasicPayLine }) => isAutoGeneratedBasicPayLine
  )) == null ? void 0 : _a.value;
  const [proratedDetail, setProratedDetail] = useState(null);
  const fetchProratedDetail = useCallback(() => __async(void 0, null, function* () {
    if (isProrated) {
      const { userId } = user;
      const { entryId } = payrunEntry;
      console.log("DEBUG - fetching prorated detail for entryId", entryId, "and userId", userId);
      console.log("DEBUG - payPeriodValue: ", payPeriodValue);
      const proratedDetail2 = yield PayrollLocalApi.getInPayrunProratedSalaryDetail(entryId, userId);
      setProratedDetail(proratedDetail2);
    }
  }), [isProrated, payPeriodValue, payrunEntry, user]);
  useEffect(() => {
    fetchProratedDetail();
  }, [fetchProratedDetail, isProrated, localPayRun, user]);
  const proratedSuffix = proratedDetail ? proratedDetail.payPerSchedule && proratedDetail.workPercentage && isProrated && proratedDetail.proratedSalary === payPeriodValue ? ` (${formatCurrency(proratedDetail.payPerSchedule)} * ${proratedDetail.workPercentage}%)` : ` (${proratedDetail.totalWorkHours.toFixed(2)} hr * ${formatCurrency(proratedDetail.hourlyRate)})` : "";
  const payPeriodLine = `${payrunEntry.payPeriod} pay`;
  const periodDescriptionWithSuffix = isProrated ? `${payPeriodLine}${proratedSuffix}` : payPeriodLine;
  return /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsx(UserHeader, { user }),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g40, mt: spacing.mt40 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey }), children: "Description" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey }), children: "Amount" })
        ] }),
        payPeriodValue && payPeriodValue !== 0 && /* @__PURE__ */ jsx(SummaryRow, { description: periodDescriptionWithSuffix, amount: payPeriodValue != null ? payPeriodValue : 0 }),
        getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, "addition", "pretax"),
        getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, "deduction", "pretax"),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Pension contribution",
            amount: (_b = getRegularPaylineTotal("PENSION")) != null ? _b : getRegularPaylineTotal("PENSIONSS"),
            subtract: true,
            hideIfZero: getRegularPaylineTotal("PENSIONRAS") !== void 0
          }
        ),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: grossText,
            amount: payrunEntry.totals.gross,
            textSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey })
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
        /* @__PURE__ */ jsx(SummaryRow, { description: "National insurance", amount: payrunEntry.totals.employeeNi, subtract: true }),
        /* @__PURE__ */ jsx(SummaryRow, { description: "PAYE tax", amount: payrunEntry.totals.tax, subtract: true }),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Net pay",
            amount: payrunEntry.totals.netPay,
            textSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey })
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
        getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, "addition", "posttax"),
        getOptionalPayCodeSummaryRows(payrunEntry, customPayCodes, "deduction", "posttax"),
        /* @__PURE__ */ jsx(SummaryRow, { description: "Student loan", amount: payrunEntry.totals.studentLoanRecovered, subtract: true, hideIfZero: true }),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Postgraduate loan",
            amount: payrunEntry.totals.postgradLoanRecovered,
            subtract: true,
            hideIfZero: true
          }
        ),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Pension contribution",
            amount: getRegularPaylineTotal("PENSIONRAS"),
            subtract: true,
            hideIfZero: getRegularPaylineTotal("PENSIONRAS") === void 0
          }
        ),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Take home pay",
            amount: payrunEntry.totals.takeHomePay,
            textSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey })
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Employer NI",
            amount: payrunEntry.totals.employerNi,
            textSx: { color: themeColors.Grey }
          }
        ),
        /* @__PURE__ */ jsx(
          SummaryRow,
          {
            description: "Employer Pension",
            amount: payrunEntry.totals.employerPensionContribution,
            textSx: { color: themeColors.Grey }
          }
        )
      ] })
    ] })
  ] });
};
