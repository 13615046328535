"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { AttendanceScheduleSelect } from "@v2/components/attendance-schedule-select.component";
import { HolidayCalendarEndpoints } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { CustomCountryEnum } from "@v2/infrastructure/country/country.interface";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import { keyBy } from "lodash";
import * as Yup from "yup";
import { ContractsAPI } from "@/api-client/contracts.api";
import {
  UserContractKinds
} from "@/component/dashboard/userDetails/validations/userFormDefinitions";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ContractEndDatePickerComponent } from "@/v2/components/contract-end-date-picker.component";
import { EntitySelect } from "@/v2/components/entity-select.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { getPublicHolidayCalendarOptions } from "@/v2/feature/absence/absence.util";
import { useAdminOnboardingFieldsContext } from "@/v2/feature/onboarding/context/admin-onboarding-fields.context";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import {
  getUnitTypeOptions,
  getWorkerTypeOptions,
  UserContractTypeEnum
} from "@/v2/feature/user/features/user-profile/user-profile.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const NewEmployeeContractSchema = (defaultFieldsMap, polyglot) => {
  return Yup.object().shape({
    type: defaultFieldsMap["contract.type"] && defaultFieldsMap["contract.type"].isRequired ? Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.workerTypeRequired")) : Yup.string().trim().optional(),
    contract: defaultFieldsMap["contract.contract"] && defaultFieldsMap["contract.contract"].isRequired ? Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.contractTypeRequired")) : Yup.string().trim().optional(),
    entityId: defaultFieldsMap["contract.entity"] && defaultFieldsMap["contract.entity"].isRequired ? Yup.number().nullable().required(polyglot.t("NewEmployeeContractSection.errorMessages.entityRequired")) : Yup.number().nullable().optional(),
    attendanceScheduleId: defaultFieldsMap["contract.attendanceSchedule"] && defaultFieldsMap["contract.attendanceSchedule"].isRequired ? Yup.number().nullable().required(polyglot.t("NewEmployeeContractSection.errorMessages.attendanceScheduleRequired")) : Yup.number().nullable().optional(),
    publicHolidays: defaultFieldsMap["contract.publicHolidays"] && defaultFieldsMap["contract.publicHolidays"].isRequired ? Yup.string().required(polyglot.t("NewEmployeeContractSection.errorMessages.publicCalendarRequired")) : Yup.string().optional(),
    probationPeriodUnit: defaultFieldsMap["contract.probationPeriod"] && defaultFieldsMap["contract.probationPeriod"].isRequired ? Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.probationValueRequired")) : Yup.string().trim().optional(),
    probationPeriodLength: defaultFieldsMap["contract.probationPeriod"] && defaultFieldsMap["contract.probationPeriod"].isRequired ? Yup.number().required(polyglot.t("NewEmployeeContractSection.errorMessages.probationValueRequired")).integer() : Yup.number().nullable().optional(),
    noticePeriodUnit: defaultFieldsMap["contract.noticePeriod"] && defaultFieldsMap["contract.noticePeriod"].isRequired ? Yup.string().trim().required(polyglot.t("NewEmployeeContractSection.errorMessages.noticeValueRequired")) : Yup.string().trim().optional(),
    noticePeriodLength: defaultFieldsMap["contract.noticePeriod"] && defaultFieldsMap["contract.noticePeriod"].isRequired ? Yup.number().required(polyglot.t("NewEmployeeContractSection.errorMessages.noticeValueRequired")).integer() : Yup.number().nullable().optional(),
    effectiveDate: defaultFieldsMap["contract.effectiveDate"] && defaultFieldsMap["contract.effectiveDate"].isRequired ? Yup.string().test(dateFieldTest).required(polyglot.t("NewEmployeeContractSection.errorMessages.effectiveDateRequired")) : Yup.string().test(dateFieldTest).optional(),
    changeReason: defaultFieldsMap["contract.changeReason"] && defaultFieldsMap["contract.changeReason"].isRequired ? Yup.string().nullable().required(polyglot.t("NewEmployeeContractSection.errorMessages.changeReasonRequired")) : Yup.string().nullable().notRequired(),
    contractEndDate: Yup.date().nullable().when(["effectiveDate"], (effectiveDate, schema) => {
      return schema.nullable().min(effectiveDate, "Invalid end date. Please chose a date equal or greater than the effective date.").notRequired();
    })
  });
};
export const NewEmployeeContractSection = ({
  contract,
  onNext,
  startDate,
  sx,
  userId
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r;
  const { data: holidayCalendars } = useApiClient(HolidayCalendarEndpoints.getHolidayCalendars(), { suspense: false });
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useAdminOnboardingFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Contract);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Contract);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const customHolidayCalendarsOptions = useMemo(() => {
    if (!holidayCalendars) return [];
    return holidayCalendars.map((hc) => ({ label: hc.name, value: hc.id }));
  }, [holidayCalendars]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2, _j2, _k2, _l2, _m2, _n2;
    const values = {};
    if (defaultFieldsMap["contract.type"]) values.type = (_a2 = contract == null ? void 0 : contract.type) != null ? _a2 : UserContractTypeEnum.Payrolled;
    if (defaultFieldsMap["contract.contract"]) values.contract = (_b2 = contract == null ? void 0 : contract.contract) != null ? _b2 : "Full-time";
    if (defaultFieldsMap["contract.ftePercent"]) values.ftePercent = (_c2 = contract == null ? void 0 : contract.ftePercent) != null ? _c2 : 100;
    if (defaultFieldsMap["contract.entity"]) values.entityId = (_d2 = contract == null ? void 0 : contract.entityId) != null ? _d2 : null;
    if (defaultFieldsMap["contract.attendanceSchedule"])
      values.attendanceScheduleId = (_e2 = contract == null ? void 0 : contract.attendanceScheduleId) != null ? _e2 : null;
    if (defaultFieldsMap["contract.publicHolidays"]) {
      values.publicHolidays = (_f2 = contract == null ? void 0 : contract.publicHolidays) != null ? _f2 : "uk";
      values.holidayCalendarId = values.publicHolidays === CustomCountryEnum.code ? values.holidayCalendarId : null;
    }
    if (defaultFieldsMap["contract.probationPeriod"])
      values.probationPeriodUnit = (_g2 = contract == null ? void 0 : contract.probationPeriodUnit) != null ? _g2 : "Months";
    if (defaultFieldsMap["contract.probationPeriod"])
      values.probationPeriodLength = (_h2 = contract == null ? void 0 : contract.probationPeriodLength) != null ? _h2 : 1;
    if (defaultFieldsMap["contract.noticePeriod"]) values.noticePeriodUnit = (_i2 = contract == null ? void 0 : contract.noticePeriodUnit) != null ? _i2 : "Months";
    if (defaultFieldsMap["contract.noticePeriod"]) values.noticePeriodLength = (_j2 = contract == null ? void 0 : contract.noticePeriodLength) != null ? _j2 : 1;
    if (defaultFieldsMap["contract.changeReason"]) values.changeReason = (_k2 = contract == null ? void 0 : contract.changeReason) != null ? _k2 : void 0;
    if (defaultFieldsMap["contract.effectiveDate"])
      values.effectiveDate = (_l2 = contract == null ? void 0 : contract.effectiveDate) != null ? _l2 : new LocalDate(startDate).toDateString();
    values.customUpdates = (_n2 = (_m2 = contract == null ? void 0 : contract.customUpdates) == null ? void 0 : _m2.filter((update) => customFieldIds.has(update.fieldId))) != null ? _n2 : [];
    return values;
  }, [defaultFieldsMap, contract, customFieldIds, startDate]);
  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: NewEmployeeContractSchema(defaultFieldsMap, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      setSaving(true);
      try {
        if ("id" in values) {
          const update = {
            id: Number(values.id),
            effectiveDate: values.effectiveDate,
            contract: values.contract,
            type: values.type,
            ftePercent: Number(values.ftePercent),
            publicHolidays: values.publicHolidays,
            holidayCalendarId: values.publicHolidays === CustomCountryEnum.code ? values.holidayCalendarId : null,
            changeReason: values.changeReason,
            noticePeriodLength: values.noticePeriodLength ? Number(values.noticePeriodLength) : 0,
            noticePeriodUnit: values.noticePeriodUnit,
            probationPeriodLength: values.probationPeriodLength ? Number(values.probationPeriodLength) : 0,
            probationPeriodUnit: values.probationPeriodUnit,
            entityId: values.entityId,
            attendanceScheduleId: values.attendanceScheduleId,
            contractEndDate: values.contractEndDate,
            customUpdates: values.customUpdates
          };
          yield ContractsAPI.updateById(userId, update);
        } else {
          const create = {
            effectiveDate: values.effectiveDate,
            contract: values.contract,
            type: values.type,
            ftePercent: Number(values.ftePercent),
            publicHolidays: values.publicHolidays,
            holidayCalendarId: values.publicHolidays === CustomCountryEnum.code ? values.holidayCalendarId : null,
            changeReason: values.changeReason,
            noticePeriodLength: values.noticePeriodLength ? Number(values.noticePeriodLength) : 0,
            noticePeriodUnit: values.noticePeriodUnit,
            probationPeriodLength: values.probationPeriodLength ? Number(values.probationPeriodLength) : 0,
            probationPeriodUnit: values.probationPeriodUnit,
            entityId: values.entityId,
            attendanceScheduleId: values.attendanceScheduleId,
            contractEndDate: values.contractEndDate,
            customUpdates: values.customUpdates
          };
          yield ContractsAPI.createUserContract(userId, create);
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeContractSection.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: polyglot.t("NewEmployeeContractSection.contract") }),
    defaultFieldsMap["contract.effectiveDate"] && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_a = formik.values.effectiveDate) != null ? _a : null,
        onChange: (value) => formik.setFieldValue("effectiveDate", value),
        name: "effectiveDate",
        label: polyglot.t("ContractForm.effectiveDate"),
        error: !!formik.errors.effectiveDate && formik.touched.effectiveDate,
        helperText: formik.errors.effectiveDate && formik.touched.effectiveDate
      }
    ),
    defaultFieldsMap["contract.type"] && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: polyglot.t("NewEmployeeContractSection.workerType"),
        options: getWorkerTypeOptions(polyglot),
        value: formik.values.type,
        onChange: formik.handleChange,
        disabled: saving,
        helperText: hasSubmitted && formik.errors.type,
        error: hasSubmitted && !!formik.errors.type,
        required: (_b = defaultFieldsMap["contract.type"]) == null ? void 0 : _b.isRequired
      }
    ),
    defaultFieldsMap["contract.contract"] && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "contract",
        label: polyglot.t("NewEmployeeContractSection.contractLabel"),
        options: UserContractKinds(polyglot),
        value: formik.values.contract,
        onChange: formik.handleChange,
        disabled: saving,
        helperText: hasSubmitted && formik.errors.contract,
        error: hasSubmitted && !!formik.errors.contract,
        required: (_c = defaultFieldsMap["contract.contract"]) == null ? void 0 : _c.isRequired
      }
    ),
    defaultFieldsMap["contract.entity"] && /* @__PURE__ */ jsx(
      EntitySelect,
      {
        name: "entityId",
        label: polyglot.t("NewEmployeeContractSection.entityId"),
        value: formik.values.entityId,
        disabled: saving,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.entityId,
        helperText: hasSubmitted && formik.errors.entityId,
        autoSelectSingleEntity: (entityId) => formik.setFieldValue("entityId", entityId),
        required: (_d = defaultFieldsMap["contract.entity"]) == null ? void 0 : _d.isRequired
      }
    ),
    defaultFieldsMap["contract.attendanceSchedule"] && /* @__PURE__ */ jsx(
      AttendanceScheduleSelect,
      {
        name: "attendanceScheduleId",
        label: polyglot.t("NewEmployeeContractSection.attendanceScheduleId"),
        value: formik.values.attendanceScheduleId,
        disabled: saving,
        onChange: formik.handleChange,
        error: hasSubmitted && !!formik.errors.attendanceScheduleId,
        helperText: hasSubmitted && formik.errors.attendanceScheduleId,
        autoSelectSingleEntity: (attendanceScheduleId) => formik.setFieldValue("attendanceScheduleId", attendanceScheduleId),
        required: (_e = defaultFieldsMap["contract.attendanceSchedule"]) == null ? void 0 : _e.isRequired
      }
    ),
    defaultFieldsMap["contract.publicHolidays"] && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "publicHolidays",
        label: polyglot.t("NewEmployeeContractSection.publicHolidays"),
        options: getPublicHolidayCalendarOptions({ includeCustom: true }),
        value: formik.values.publicHolidays,
        disabled: saving,
        onChange: formik.handleChange,
        helperText: hasSubmitted && formik.errors.publicHolidays,
        error: hasSubmitted && !!formik.errors.publicHolidays,
        required: (_f = defaultFieldsMap["contract.publicHolidays"]) == null ? void 0 : _f.isRequired
      }
    ),
    formik.values.publicHolidays === CustomCountryEnum.code && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "holidayCalendarId",
        label: polyglot.t("HolidayCalendarModule.customHolidayCalendar"),
        options: customHolidayCalendarsOptions,
        value: formik.values.holidayCalendarId,
        disabled: saving,
        onChange: formik.handleChange,
        helperText: hasSubmitted && formik.errors.holidayCalendarId,
        error: hasSubmitted && !!formik.errors.holidayCalendarId
      }
    ),
    defaultFieldsMap["contract.probationPeriod"] && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "probationPeriodLength",
          label: polyglot.t("NewEmployeeContractSection.probationPeriodLength"),
          type: "tel",
          value: (_h = (_g = formik.values.probationPeriodLength) == null ? void 0 : _g.toString()) != null ? _h : "",
          disabled: saving,
          onChange: (e) => {
            var _a2, _b2;
            const newValue = Number((_b2 = (_a2 = e.target.value.match(/^\d{1,2}/)) == null ? void 0 : _a2[0]) != null ? _b2 : "nan");
            formik.setFieldValue("probationPeriodLength", Number.isInteger(newValue) ? newValue : void 0);
          },
          clearText: () => formik.setFieldValue("probationPeriodLength", 0),
          helperText: hasSubmitted && formik.errors.probationPeriodLength,
          error: hasSubmitted && !!formik.errors.probationPeriodLength,
          required: (_i = defaultFieldsMap["contract.probationPeriod"]) == null ? void 0 : _i.isRequired
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "probationPeriodUnit",
          label: polyglot.t("NewEmployeeContractSection.probationPeriodUnit"),
          options: getUnitTypeOptions(polyglot),
          value: formik.values.probationPeriodUnit,
          disabled: saving,
          onChange: formik.handleChange,
          helperText: hasSubmitted && formik.errors.probationPeriodUnit,
          error: hasSubmitted && !!formik.errors.probationPeriodUnit,
          required: (_j = defaultFieldsMap["contract.probationPeriod"]) == null ? void 0 : _j.isRequired
        }
      )
    ] }),
    defaultFieldsMap["contract.noticePeriod"] && /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "noticePeriodLength",
          label: polyglot.t("NewEmployeeContractSection.noticePeriodUnit"),
          type: "tel",
          value: (_l = (_k = formik.values.noticePeriodLength) == null ? void 0 : _k.toString()) != null ? _l : "",
          disabled: saving,
          onChange: (e) => {
            var _a2, _b2;
            const newValue = Number((_b2 = (_a2 = e.target.value.match(/^\d{1,2}/)) == null ? void 0 : _a2[0]) != null ? _b2 : "nan");
            formik.setFieldValue("noticePeriodLength", Number.isInteger(newValue) ? newValue : void 0);
          },
          clearText: () => formik.setFieldValue("noticePeriodLength", 0),
          helperText: hasSubmitted && formik.errors.noticePeriodLength,
          error: hasSubmitted && !!formik.errors.noticePeriodLength,
          required: (_m = defaultFieldsMap["contract.noticePeriod"]) == null ? void 0 : _m.isRequired
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "noticePeriodUnit",
          label: polyglot.t("NewEmployeeContractSection.noticePeriodMeasure"),
          options: getUnitTypeOptions(polyglot),
          value: formik.values.noticePeriodUnit,
          disabled: saving,
          onChange: formik.handleChange,
          helperText: hasSubmitted && formik.errors.noticePeriodUnit,
          error: hasSubmitted && !!formik.errors.noticePeriodUnit,
          required: (_n = defaultFieldsMap["contract.noticePeriod"]) == null ? void 0 : _n.isRequired
        }
      )
    ] }),
    defaultFieldsMap["contract.changeReason"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "changeReason",
        label: polyglot.t("ContractForm.changeReason"),
        value: formik.values.changeReason,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.changeReason && !!formik.errors.changeReason,
        helperText: (_o = formik.touched.changeReason && formik.errors.changeReason) != null ? _o : " ",
        clearText: () => formik.setFieldValue("changeReason", ""),
        required: (_p = defaultFieldsMap["contract.changeReason"]) == null ? void 0 : _p.isRequired
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_q = formik.values.customUpdates) != null ? _q : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      ContractEndDatePickerComponent,
      {
        name: "contractEndDate",
        label: "End date",
        value: (_r = formik.values.contractEndDate) != null ? _r : null,
        onChange: (value) => formik.setFieldValue("contractEndDate", value),
        effectiveDate: formik.values.effectiveDate,
        error: !!formik.errors.contractEndDate,
        helperText: formik.errors.contractEndDate
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        colorVariant: "primary",
        sizeVariant: "large",
        fullWidth: true
      }
    )
  ] }) }) });
};
