"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TimePickerComponent } from "@v2/components/forms/time-picker.component";
import { isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { StyledFormHelperText } from "@/v2/styles/form-helper-text.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
export const SelectWorkOptionItem = ({
  entry,
  index,
  formik,
  logDate,
  typeOptions,
  updateEntry,
  deleteEntry
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const isFieldError = useCallback(
    (fieldName) => {
      var _a2, _b2, _c2, _d2;
      return Boolean(
        ((_b2 = (_a2 = formik.touched.attendanceEntries) == null ? void 0 : _a2[index]) == null ? void 0 : _b2[fieldName]) && ((_d2 = (_c2 = formik.errors.attendanceEntries) == null ? void 0 : _c2[index]) == null ? void 0 : _d2[fieldName])
      );
    },
    [index, formik.touched, formik.errors]
  );
  const anyFieldError = useMemo(() => {
    return isFieldError("typeId") || isFieldError("startHour") || isFieldError("startHourTimestamp") || isFieldError("endHour") || isFieldError("endHourTimestamp") || isFieldError("notes");
  }, [isFieldError]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "flex-end" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: { flex: 1.25 }, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "typeId",
            label: polyglot.t("AttendanceDomain.job"),
            options: typeOptions,
            value: entry.typeId,
            onChange: (e) => {
              updateEntry(index, __spreadProps(__spreadValues({}, entry), { typeId: e.target.value }));
            },
            error: isFieldError("typeId")
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { flex: 0.75 }, children: /* @__PURE__ */ jsx(
          TimePickerComponent,
          {
            textFieldKey: "startHour",
            label: polyglot.t("AttendanceDomain.from"),
            value: entry.startHour,
            onChange: (event) => {
              const time = event.target.value;
              if (isValidTimeString(time)) {
                const date = new LocalDate(`${logDate}T${time}:00`);
                updateEntry(index, __spreadProps(__spreadValues({}, entry), { startHour: date.toFullString(), startHourTimestamp: date.getDate() }));
              }
            },
            fullWidth: true,
            error: isFieldError("startHour") || isFieldError("startHourTimestamp")
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { flex: 0.75 }, children: /* @__PURE__ */ jsx(
          TimePickerComponent,
          {
            textFieldKey: "endHour",
            label: polyglot.t("AttendanceDomain.to"),
            value: entry.endHour,
            onChange: (event) => {
              const time = event.target.value;
              if (isValidTimeString(time)) {
                const date = new LocalDate(`${logDate}T${time}:00`);
                updateEntry(index, __spreadProps(__spreadValues({}, entry), { endHour: date.toFullString(), endHourTimestamp: date.getDate() }));
              }
            },
            fullWidth: true,
            error: isFieldError("endHour") || isFieldError("endHourTimestamp")
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { flex: 0.25 }, children: /* @__PURE__ */ jsx(IconButton, { sx: iconButtonSx, onClick: () => deleteEntry(index), children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)) }) })
      ] }),
      /* @__PURE__ */ jsx(
        RichTextField,
        {
          value: entry.notes,
          error: isFieldError("notes"),
          placeholder: polyglot.t("AttendanceDomain.addNote"),
          helperText: ((_b = (_a = formik.touched.attendanceEntries) == null ? void 0 : _a[index]) == null ? void 0 : _b.notes) && ((_d = (_c = formik.errors.attendanceEntries) == null ? void 0 : _c[index]) == null ? void 0 : _d.notes),
          onChange: (value) => {
            updateEntry(index, __spreadProps(__spreadValues({}, entry), { notes: value }));
          }
        }
      )
    ] }),
    anyFieldError && /* @__PURE__ */ jsx(StyledFormHelperText, { error: true, children: polyglot.t("AttendanceDomain.errors.makeSureAllAreValid") })
  ] });
};
