"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { ReactComponent as AlertOkay } from "@/images/side-bar-icons/AlertOkay.svg";
import { ReactComponent as AlertWaiting } from "@/images/side-bar-icons/AlertWaiting.svg";
import { ReactComponent as CleanCircle } from "@/images/side-bar-icons/CleanCircle.svg";
import { ReactComponent as InfoBlack } from "@/images/side-bar-icons/InfoBlack.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getAlertColor = (type) => {
  switch (type) {
    case "success":
      return themeColors.green;
    case "error":
      return themeColors.red;
    case "info":
      return themeColors.black;
    case "warning":
      return themeColors.orange;
    default:
      return themeColors.DarkGrey;
  }
};
const getIcon = (type) => {
  switch (type) {
    case "success":
      return /* @__PURE__ */ jsx(AlertOkay, { style: { fill: themeColors.white } });
    case "error":
      return /* @__PURE__ */ jsx(CleanCircle, { style: { fill: themeColors.white } });
    case "info":
      return /* @__PURE__ */ jsx(InfoBlack, { style: { fill: themeColors.white } });
    default:
      return /* @__PURE__ */ jsx(AlertWaiting, {});
  }
};
export const SnackBarMessage = ({ showMessage, handleClose }) => /* @__PURE__ */ jsx(
  Snackbar,
  {
    open: showMessage.show,
    autoHideDuration: 6e4,
    message: showMessage.message,
    onClose: handleClose,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    children: /* @__PURE__ */ jsx(
      MuiAlert,
      {
        sx: {
          width: "100%",
          backgroundColor: getAlertColor(showMessage.type),
          borderRadius: radius.br10,
          boxShadow: "none"
        },
        elevation: 10,
        variant: "filled",
        onClose: handleClose,
        icon: false,
        children: /* @__PURE__ */ jsxs(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
              padding: 0,
              display: "flex",
              alignItems: "center",
              gap: spacing.g10,
              color: themeColors.white
            }),
            children: [
              getIcon(showMessage.type),
              showMessage.message
            ]
          }
        )
      }
    )
  }
);
