"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { nanoid } from "nanoid";
import { OauthClientEndpoints } from "@/api-client/oauth-client.api";
import { ReactComponent as ExternalLink } from "@/images/icons/external-link-alt-solid.svg";
import { ReactComponent as Copy } from "@/images/side-bar-icons/Copy.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortNumeric } from "@/v2/components/table/table-sorting.util";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  DescriptionLine,
  SettingsPageSection
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/settings-page-section.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { LocalDate } from "@/v2/util/local-date";
export const DeveloperHubCredentialsSettingsPage = ({ oauthApp }) => {
  const { polyglot } = usePolyglot();
  const { data: activeTokens } = useApiClient(OauthClientEndpoints.getAllTokenPairsForClientId(oauthApp.clientId), {
    suspense: false
  });
  const initiateCodeFlow = (client) => {
    const PARTNER_API_AUTHORISE_CODE_FLOW_ROUTE = "/partner-api/authorize?response_type=code&client_id=:clientId&state=:state&redirect_uri=:redirectUri";
    const codeFlowPath = PARTNER_API_AUTHORISE_CODE_FLOW_ROUTE.replace(":clientId", client.clientId).replace(":state", nanoid(9)).replace(":redirectUri", client.redirectionUri);
    window.open(codeFlowPath, "_blank");
  };
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("DeveloperHub.accessToken"),
        accessorFn: (row) => row,
        id: "accessToken",
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.access_token }) })
      },
      {
        header: () => polyglot.t("DeveloperHub.expiringOn"),
        accessorFn: (row) => row,
        id: "atExpiringOn",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.expiryForAT),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.expiryForAT ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new LocalDate(original.expiryForAT).toDateTimeString() }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => polyglot.t("DeveloperHub.refreshToken"),
        accessorFn: (row) => row,
        id: "refreshToken",
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.refresh_token }) })
      },
      {
        header: () => polyglot.t("DeveloperHub.expiringOn"),
        accessorFn: (row) => row,
        id: "rtExpiringOn",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.expiryForRT),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.expiryForRT ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new LocalDate(original.expiryForRT).toDateTimeString() }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      },
      {
        header: () => polyglot.t("DeveloperHub.tokenGeneratedOn"),
        accessorFn: (row) => row,
        id: "tokenGeneratedOn",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.tokenPairGeneratedOn),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: original.tokenPairGeneratedOn ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new LocalDate(original.tokenPairGeneratedOn).toDateTimeString() }) : /* @__PURE__ */ jsx(EmptyCell, {}) })
      }
    ];
  }, [polyglot]);
  return /* @__PURE__ */ jsxs(SettingsPageSection, { title: polyglot.t("DeveloperHub.appCredentials"), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("DeveloperHub.credentialsPageExplainer") }),
    /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("DeveloperHub.appId"), value: oauthApp.id }),
    /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("DeveloperHub.dateOfCreation"), value: dateAPItoDisplay(oauthApp.createdAt) }),
    /* @__PURE__ */ jsx(
      DescriptionLine,
      {
        field: polyglot.t("DeveloperHub.clientId"),
        value: oauthApp.clientId && {
          isLink: true,
          label: oauthApp.clientId,
          icon: /* @__PURE__ */ jsx(Copy, __spreadValues({}, iconSize)),
          onClick: () => navigator.clipboard.writeText(oauthApp.clientId)
        }
      }
    ),
    /* @__PURE__ */ jsx(DescriptionLine, { field: polyglot.t("DeveloperHub.clientSecret"), value: "*".repeat(10) }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        startIcon: /* @__PURE__ */ jsx(ExternalLink, __spreadValues({}, iconSize)),
        sizeVariant: "small",
        colorVariant: "secondary",
        onClick: () => initiateCodeFlow(oauthApp),
        children: "Code flow"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: spacing.mt20, mb: spacing.mb20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { color: themeColors.DarkGrey }, children: polyglot.t("DeveloperHub.activeTokens") }),
      (activeTokens == null ? void 0 : activeTokens.length) && (activeTokens == null ? void 0 : activeTokens.length) > 0 ? /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: activeTokens != null ? activeTokens : [],
          columnData: columns,
          loading: false,
          hidePagination: true,
          externalTdAndThPadding: "0 0px",
          style: { width: "900px" }
        }
      ) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", sx: { mt: "8px" }, children: polyglot.t("DeveloperHub.noTokensYet") }) })
    ] })
  ] });
};
