"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { DEFAULT_PAGE_SIZE } from "@v2/components/table/server-side-table.component";
import { AttendanceShiftAPI } from "@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ActiveShiftsPageContent } from "@/v2/feature/attendance/components/active-shifts-page-content.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AttendanceCompanyActiveShiftsPage = () => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const { data: extendedActiveShifts, isLoading } = useApiClient(
    AttendanceShiftAPI.getCompanyTodayActiveShifts(pagination.pageIndex, pagination.pageSize),
    {
      suspense: false
    }
  );
  const exportCompanyTodayActiveShiftsCSV = useCallback(() => __async(void 0, null, function* () {
    try {
      window.location.href = AttendanceShiftAPI.exportCompanyTodayActiveShifts();
    } catch (error) {
      console.error(error);
      showMessage(polyglot.t("AttendanceDomain.ActiveShifts.errorMessages.badRequest"), "error");
    }
  }), [polyglot, showMessage]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AttendanceDomain.ActiveShifts.title") }),
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: exportCompanyTodayActiveShiftsCSV, children: polyglot.t("General.export") }),
        showAction: !!((_b = (_a = extendedActiveShifts == null ? void 0 : extendedActiveShifts.paginatedActiveShifts) == null ? void 0 : _a.items) == null ? void 0 : _b.length)
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: { pt: spacing.p8, overflow: "hidden" }, children: /* @__PURE__ */ jsx(
      ActiveShiftsPageContent,
      {
        extendedActiveShifts,
        loading: !!isLoading,
        pagination,
        setPagination
      }
    ) })
  ] });
};
