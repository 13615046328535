"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { AbsencePolicyTenureAllocation } from "@v2/feature/absence/absence.dto";
import { getAbsencePolicyTenureAllocationOptions, isHourlyPolicy } from "@v2/feature/absence/absence.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyTenureEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [localRules, setLocalRules] = useState([]);
  useEffect(() => {
    if (isOpen)
      setLocalRules(
        absencePolicy.tenureAdditionSettings ? Object.keys(absencePolicy.tenureAdditionSettings).map((year) => {
          var _a2;
          return [
            Number(year),
            String((_a2 = absencePolicy.tenureAdditionSettings[Number(year)]) != null ? _a2 : 0)
          ];
        }) : [[1, "0"]]
      );
  }, [absencePolicy, isOpen]);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        tenureEnabled: true,
        tenureAdditionSettings: values.tenureAdditionSettings,
        tenureAllocation: values.tenureAllocation
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyTenure(absencePolicy.id, policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, absencePolicy.id, refresh, setIsOpen, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      tenureEnabled: true,
      tenureAdditionSettings: absencePolicy.tenureAdditionSettings ? absencePolicy.tenureAdditionSettings : {},
      tenureAllocation: (_a = absencePolicy.tenureAllocation) != null ? _a : AbsencePolicyTenureAllocation.Anniversary
    },
    validationSchema: yup.object({
      tenureEnabled: yup.boolean().required(polyglot.t("ValidationMessages.requiredField")),
      tenureAdditionSettings: yup.object().when("tenureEnabled", {
        is: true,
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
      tenureAllocation: yup.string().oneOf(Object.values(AbsencePolicyTenureAllocation), polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("PolicyTenureEditDrawer.tenureBonus") }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("PolicyTenureEditDrawer.tenureBonusDesc") }),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "tenureAllocation",
            label: polyglot.t("AbsenceModule.tenureAllocation"),
            options: getAbsencePolicyTenureAllocationOptions(polyglot),
            value: (_b = formik.values.tenureAllocation) != null ? _b : AbsencePolicyTenureAllocation.Anniversary,
            onChange: formik.handleChange,
            compareValue: formik.values.tenureAllocation,
            error: !!formik.errors.tenureAllocation && formik.touched.tenureAllocation,
            helperText: formik.touched.tenureAllocation && formik.errors.tenureAllocation
          }
        ),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { mb: "5px" }, children: polyglot.t("AbsenceModule.tenureAllocationRules") }),
          /* @__PURE__ */ jsx(
            TenureRules,
            {
              allowanceType: absencePolicy.allowanceType,
              localRules,
              setLocalRules
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true,
            onClick: () => {
              const objectRules = localRules.reduce((acc, [year, units]) => {
                acc[year] = Number.parseFloat(units);
                return acc;
              }, {});
              formik.setFieldValue("tenureAdditionSettings", objectRules);
              formik.handleSubmit();
            }
          }
        ) })
      ] }) })
    }
  );
};
const TenureRules = ({
  allowanceType,
  localRules,
  setLocalRules
}) => {
  const { polyglot } = usePolyglot();
  const isHourly = isHourlyPolicy({ allowanceType });
  const handleRemoveRule = (indexToRemove) => {
    setLocalRules([...localRules != null ? localRules : []].filter((_, index) => indexToRemove !== index));
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: [
    localRules.map((item, index) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 2 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("PolicyTenureEditDrawer.afterMonths"),
          name: `localRules[${index}][0]`,
          value: item[0],
          onChange: (e) => {
            const reg = new RegExp(/^\d+$/);
            if (e.target.value && !reg.test(e.target.value)) return;
            setLocalRules((prev) => {
              const newYear = Number(e.target.value);
              const updatedArray = [...prev];
              updatedArray[index][0] = newYear;
              return updatedArray;
            });
          }
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: isHourly ? polyglot.t("PolicyTenureEditDrawer.addExtraHours") : polyglot.t("PolicyTenureEditDrawer.addExtraDays"),
          name: `localRules[${index}][1]`,
          value: item[1],
          onChange: (e) => {
            const reg = new RegExp(/^\d*\.?\d*$/);
            if (e.target.value && !reg.test(e.target.value)) return;
            setLocalRules((prev) => {
              const newYear = e.target.value;
              const updatedArray = [...prev];
              updatedArray[index][1] = newYear;
              return updatedArray;
            });
          }
        }
      ),
      localRules.length > 1 && /* @__PURE__ */ jsx(
        IconButton,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          onClick: () => handleRemoveRule(index),
          title: polyglot.t("General.remove"),
          style: { marginTop: "14px" },
          children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
        }
      )
    ] }, index)),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "small",
        colorVariant: "secondary",
        onClick: () => {
          setLocalRules((prev) => [...prev, [0, "0"]]);
        },
        children: polyglot.t("PolicyTenureEditDrawer.addRule")
      }
    )
  ] });
};
