"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  getCurrentBalanceFromBreakdown,
  getTotalAllowanceBreakdown
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
const BreakdownField = ({ name, value, color }) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
  /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { width: "60%", color }, children: name }),
  /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { width: "40%", display: "flex", justifyContent: "end", color }, children: value })
] });
const textOverflowSx = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "90%"
};
export const PolicyBreakdownCard = ({
  isActive,
  absencePolicy,
  userBalanceDetailedStats,
  onClick
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const policyBreakdown = userBalanceDetailedStats[absencePolicy.id];
  const totalAllowance = getTotalAllowanceBreakdown(policyBreakdown, polyglot);
  const balance = getCurrentBalanceFromBreakdown(absencePolicy, policyBreakdown, polyglot);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        bgcolor: isActive ? themeColors.Background : themeColors.white,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        p: spacing.p10,
        transition: "transform 0.1s linear",
        "&:hover": isActive ? void 0 : {
          cursor: "pointer",
          transform: "scale(1.05)"
        }
      },
      onClick: isActive ? void 0 : onClick,
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                width: "8px",
                height: "8px",
                backgroundColor: (_a = absencePolicy.color) != null ? _a : themeColors.Grey,
                borderRadius: radius.br25
              }
            }
          ),
          /* @__PURE__ */ jsx(Typography, { variant: isActive ? "headline3" : "paragraph", sx: textOverflowSx, children: absencePolicy.name })
        ] }),
        balance ? /* @__PURE__ */ jsx(
          BreakdownField,
          {
            name: polyglot.t("AllowanceDrawer.remaining"),
            value: balance,
            color: isActive ? themeColors.DarkGrey : themeColors.Grey
          }
        ) : /* @__PURE__ */ jsx(
          BreakdownField,
          {
            name: polyglot.t("AllowanceDrawer.allowance"),
            value: totalAllowance != null ? totalAllowance : polyglot.t("AllowanceDrawer.unlimited"),
            color: isActive ? themeColors.DarkGrey : themeColors.Grey
          }
        )
      ]
    }
  );
};
