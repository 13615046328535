"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useHistory } from "react-router-dom";
import { AlertMessage } from "@/v2/components/theme-components/alert.component";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const UnapprovedAttendanceEntriesWarning = ({
  payrollId,
  payrunType
}) => {
  const { polyglot } = usePolyglot();
  const { data: attendanceEntries } = useApiClient(
    PayrollEndpoints.getSubmittedAttendanceEntriesForPayrun(payrollId, payrunType),
    {
      suspense: false
    }
  );
  const router = useHistory();
  return /* @__PURE__ */ jsx(Fragment, { children: attendanceEntries && attendanceEntries.length > 0 && /* @__PURE__ */ jsx(
    AlertMessage,
    {
      message: polyglot.t("CompanyPayroll.unapprovedAttendanceEntriesWarning"),
      actionTitle: polyglot.t("CompanyPayroll.seeAttendance"),
      onAction: () => {
        router.push(`/time/company/timesheets/requests`);
      },
      severity: "warning"
    }
  ) });
};
