"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionType, DevicePossessionTypeOptions } from "@v2/feature/device/device.interface";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Trash } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
const validationSchema = yup.object({
  companyId: yup.number().nullable().typeError("Please select a valid company id").integer("Please select a valid company id").notRequired(),
  possessionType: yup.string().oneOf(Object.values(DevicePossessionType), "Please select a valid possession type.").required("Device possession type is required"),
  possessionId: yup.number().typeError("This field should be an integer").integer("This field should be an integer").required("Possession ID is required"),
  startDate: yup.string().nullable().notRequired(),
  endDate: yup.string().nullable().notRequired(),
  deliveryAddress: yup.string().notRequired()
});
export const SuperAdminDeviceDetailsDrawer = ({
  isOpen,
  setIsOpen,
  deviceId,
  devicePossession,
  refreshPossessions
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(
      SuperAdminDeviceDetailsDrawerContent,
      {
        deviceId,
        devicePossession,
        refreshPossessions,
        setIsOpen
      }
    )
  }
) });
export const SuperAdminDeviceDetailsDrawerContent = ({
  deviceId,
  devicePossession,
  refreshPossessions,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const [anchorEl, setAnchorEl] = useState(null);
  const updatePossession = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2;
      if (!devicePossession) return;
      const updateData = {
        companyId: values.companyId ? Number(values.companyId) : null,
        possessionType: values.possessionType,
        possessionId: Number(values.possessionId),
        startDate: (_a2 = values.startDate) != null ? _a2 : null,
        endDate: (_b2 = values.endDate) != null ? _b2 : null,
        deliveryAddress: (_c2 = values.deliveryAddress) != null ? _c2 : ""
      };
      try {
        setLoading(true);
        yield DeviceAPI.updateDevicePossessionAsSuperAdmin(devicePossession.deviceId, devicePossession.id, updateData);
        yield refreshPossessions();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
      setLoading(false);
    }),
    [showMessage, refreshPossessions, devicePossession, setIsOpen]
  );
  const addPossession = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2;
      if (devicePossession) return;
      const createData = {
        companyId: values.companyId ? Number(values.companyId) : null,
        possessionType: values.possessionType,
        possessionId: Number(values.possessionId),
        startDate: (_a2 = values.startDate) != null ? _a2 : null,
        endDate: (_b2 = values.endDate) != null ? _b2 : null,
        deliveryAddress: (_c2 = values.deliveryAddress) != null ? _c2 : ""
      };
      try {
        setLoading(true);
        yield DeviceAPI.createDevicePossessionAsSuperAdmin(deviceId, createData);
        yield refreshPossessions();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
      setLoading(false);
    }),
    [devicePossession, deviceId, refreshPossessions, showMessage, setIsOpen]
  );
  const formik = useFormik({
    initialValues: {
      companyId: (_a = devicePossession == null ? void 0 : devicePossession.companyId) != null ? _a : null,
      possessionType: (_b = devicePossession == null ? void 0 : devicePossession.possessionType) != null ? _b : DevicePossessionType.ZeltStock,
      possessionId: (_c = devicePossession == null ? void 0 : devicePossession.possessionId) != null ? _c : 0,
      startDate: (_d = devicePossession == null ? void 0 : devicePossession.startDate) != null ? _d : null,
      endDate: (_e = devicePossession == null ? void 0 : devicePossession.endDate) != null ? _e : null,
      deliveryAddress: (_f = devicePossession == null ? void 0 : devicePossession.deliveryAddress) != null ? _f : ""
    },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      return devicePossession ? updatePossession(values) : addPossession(values);
    })
  });
  const deletePossession = useCallback(() => __async(void 0, null, function* () {
    if (!devicePossession) return;
    try {
      setLoading(true);
      setAnchorEl(null);
      yield DeviceAPI.deleteDevicePossessionAsSuperAdmin(devicePossession.id, devicePossession.deviceId);
      yield refreshPossessions();
      setIsOpen(false);
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
    setLoading(false);
  }), [devicePossession, showMessage, refreshPossessions, setIsOpen]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Possession details" }),
      devicePossession && /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: tableIconButtonSx,
          onClick: (event) => {
            setAnchorEl(event.currentTarget);
          },
          children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: [
      "Device ID: ",
      deviceId
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2, mt: 2 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Company ID",
          name: "companyId",
          value: formik.values.companyId,
          onChange: formik.handleChange,
          error: formik.touched.companyId && Boolean(formik.errors.companyId),
          helperText: formik.touched.companyId && formik.errors.companyId,
          size: "small",
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("companyId", null)
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "possessionType",
          label: "Possession type",
          options: DevicePossessionTypeOptions,
          value: formik.values.possessionType,
          compareValue: formik.values.possessionType,
          onChange: formik.handleChange,
          error: formik.touched.possessionType && !!formik.errors.possessionType,
          helperText: formik.touched.possessionType && formik.errors.possessionType
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Possession ID",
          name: "possessionId",
          value: formik.values.possessionId,
          onChange: formik.handleChange,
          error: formik.touched.possessionId && Boolean(formik.errors.possessionId),
          helperText: formik.touched.possessionId && formik.errors.possessionId,
          size: "small",
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("possessionId", 0)
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Start date",
          name: "startDate",
          placeholder: "YYYY-MM-DD",
          value: formik.values.startDate,
          onChange: formik.handleChange,
          error: formik.touched.startDate && Boolean(formik.errors.startDate),
          helperText: formik.touched.startDate && formik.errors.startDate,
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("startDate", null),
          size: "small"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "End date",
          name: "endDate",
          placeholder: "YYYY-MM-DD",
          value: formik.values.endDate,
          onChange: formik.handleChange,
          error: formik.touched.endDate && Boolean(formik.errors.endDate),
          helperText: formik.touched.endDate && formik.errors.endDate,
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("endDate", null),
          size: "small"
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Delivery address",
          name: "deliveryAddress",
          value: formik.values.deliveryAddress,
          onChange: formik.handleChange,
          error: formik.touched.deliveryAddress && Boolean(formik.errors.deliveryAddress),
          helperText: formik.touched.deliveryAddress && formik.errors.deliveryAddress,
          size: "small",
          endAdornment: "clear-text",
          clearText: () => formik.setFieldValue("deliveryAddress", "")
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: "30px" }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: devicePossession ? "Update" : "Create",
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: !!anchorEl,
        onClose: () => {
          setAnchorEl(null);
        },
        anchorEl,
        takeAction: deletePossession,
        message: "Are you sure you want to delete this possession?",
        callToAction: "Delete"
      }
    )
  ] }) });
};
