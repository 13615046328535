"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Autocomplete, Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionType, DeviceType } from "@v2/feature/device/device.interface";
import {
  DeviceOSAndroid,
  DeviceOSLinux,
  DeviceOSMacOS,
  DeviceOSValueLabelOptions,
  DeviceOSWindows,
  DeviceOSiOS,
  DeviceOSiPadOS
} from "@v2/feature/device/device.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import {
  buttonSettingSx,
  nonLabelfieldSx
} from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeFonts } from "@v2/styles/fonts.styles";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { cardTitleIconSx, settingCardSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const validationSchema = yup.object({
  assignToEmployee: yup.boolean().required(),
  userId: yup.number().nullable().notRequired(),
  siteId: yup.number().nullable().notRequired(),
  deviceName: yup.string().nullable().notRequired(),
  modelName: yup.string().required("Model name is required"),
  os: yup.string().required("Platform is required"),
  serialNumber: yup.string().required("Serial Number is required"),
  type: yup.string().required("Device type is required")
});
const MacOSModelNames = ["MacBook Air", "MacBook Pro"];
const WindowsLinuxModelNames = [
  "Acer Aspire",
  "ASUS ExpertBook",
  "Dell Inspiron",
  "Dell Latitude",
  "Dell XPS",
  "HP Pro",
  "Lenovo ThinkPad",
  "Lenovo IdeaPad",
  "Microsoft Surface"
];
const IOSModelNames = ["iPhone"];
const IPadOSModelNames = ["iPad"];
const AndroidModelNames = ["Samsung Galaxy", "Google Pixel", "OnePlus", "Oppo Find", "Xiaomi"];
const DefaultModelNameOptions = [
  ...MacOSModelNames,
  ...WindowsLinuxModelNames,
  ...IOSModelNames,
  ...IPadOSModelNames,
  ...AndroidModelNames
];
export const AboutDevice = ({ setProgress, setDevicePossession, userId }) => {
  var _a, _b, _c, _d;
  const [sitesOptions, setSitesOptions] = useState([]);
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const [modelNameOptions, setModelNameOptions] = useState(DefaultModelNameOptions);
  const initialValues = {
    assignToEmployee: true,
    userId: userId != null ? userId : null,
    siteId: null,
    modelName: "",
    deviceName: "",
    serialNumber: "",
    os: "",
    type: DeviceType.Laptop,
    isDep: null
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      return addExistingDevice(values);
    })
  });
  const addExistingDevice = (values) => __async(void 0, null, function* () {
    try {
      if (!values.modelName) {
        showMessage("Device model name is required.", "error");
        return;
      }
      if (!values.serialNumber) {
        showMessage("Serial Number is required.", "error");
        return;
      }
      setLoading(true);
      const existingDeviceData = {
        possessionType: values.assignToEmployee ? DevicePossessionType.User : DevicePossessionType.CompanySite,
        possessionId: values.assignToEmployee ? values.userId : values.siteId,
        modelName: values.modelName,
        serialNumber: !!values.serialNumber ? values.serialNumber : null,
        deviceName: !!values.deviceName ? values.deviceName : null,
        os: !!values.os ? values.os : null,
        type: values.type
      };
      const newPossession = yield DeviceAPI.addExistingDevice(existingDeviceData);
      setDevicePossession(newPossession);
      showMessage("Device added successfully.", "success");
      setProgress(50);
    } catch (error) {
      showMessage(`Could not add the device. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesOptions(
          sites.map((site) => {
            var _a2;
            return { value: site.id, label: site.name, address: (_a2 = site.address) != null ? _a2 : "" };
          })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage]);
  const updateOsInputOption = (e) => {
    switch (e.target.value) {
      case DeviceOSMacOS.value:
        setModelNameOptions(MacOSModelNames);
        break;
      case DeviceOSWindows.value:
      case DeviceOSLinux.value:
        setModelNameOptions(WindowsLinuxModelNames);
        break;
      case DeviceOSiOS.value:
        setModelNameOptions(IOSModelNames);
        break;
      case DeviceOSiPadOS.value:
        setModelNameOptions(IPadOSModelNames);
        break;
      case DeviceOSAndroid.value:
        setModelNameOptions(AndroidModelNames);
        break;
      default:
        setModelNameOptions(DefaultModelNameOptions);
    }
    formik.handleChange(e);
  };
  return /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    /* @__PURE__ */ jsxs(Box, { sx: settingCardSx, children: [
      /* @__PURE__ */ jsx(Box, { sx: cardTitleIconSx, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: "Tell us about your device" }) }),
      /* @__PURE__ */ jsxs(Box, { sx: { minHeight: 50, display: "flex", justifyContent: "space-between", alignItems: "top" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "40%", mt: 1 }), children: "Currently used by" }),
        !userId ? /* @__PURE__ */ jsxs(Box, { sx: { flex: "display", flexDirection: "column", gap: 1, width: "100%" }, children: [
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              name: "assignToEmployee",
              label: "Device in use by an employee",
              checked: formik.values.assignToEmployee,
              onChange: formik.handleChange,
              sx: { mb: 1 }
            }
          ),
          formik.values.assignToEmployee ? /* @__PURE__ */ jsx(Box, { sx: { mb: 2 }, children: /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "userId",
              options: "company",
              onChange: (_, x) => {
                var _a2;
                const data = x;
                formik.setFieldValue("userId", (_a2 = data == null ? void 0 : data.value) != null ? _a2 : null);
              },
              value: formik.values.userId,
              label: "",
              error: Boolean(formik.errors.userId),
              helperText: formik.errors.userId
            }
          ) }) : /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "siteId",
              label: "Site",
              options: sitesOptions,
              value: formik.values.siteId,
              compareValue: formik.values.siteId,
              error: !!formik.errors.siteId && formik.touched.siteId,
              helperText: formik.touched.siteId && formik.errors.siteId,
              onChange: (e) => __async(void 0, null, function* () {
                var _a2, _b2;
                yield formik.handleChange(e);
                const siteAddress = (_b2 = (_a2 = sitesOptions.find((s) => s.value === e.target.value)) == null ? void 0 : _a2.address) != null ? _b2 : "";
                yield formik.setFieldValue("receiverAddress", siteAddress);
              })
            }
          )
        ] }) : /* @__PURE__ */ jsxs(
          Typography,
          {
            variant: "body1",
            sx: __spreadProps(__spreadValues({
              mt: 0.5,
              width: "100%",
              display: "flex",
              gap: spacing.g5
            }, themeFonts.headline3), {
              alignItems: "center"
            }),
            children: [
              /* @__PURE__ */ jsx(UserAvatar, { userId, size: "s20" }),
              (_b = (_a = getCachedUserById(userId)) == null ? void 0 : _a.displayName) != null ? _b : "Unknown"
            ]
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, nonLabelfieldSx), { display: "flex", justifyContent: "space-between", alignItems: "center" }), children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "40%" }), children: "Platform" }),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "os",
            label: "",
            options: DeviceOSValueLabelOptions,
            value: (_c = formik.values.os) != null ? _c : void 0,
            compareValue: (_d = formik.values.os) != null ? _d : void 0,
            error: !!formik.errors.os && formik.touched.os,
            onChange: updateOsInputOption,
            helperText: formik.errors.os && formik.touched.os
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, nonLabelfieldSx), { display: "flex", justifyContent: "space-between", alignItems: "center" }), children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "40%" }), children: "Model Name" }),
        /* @__PURE__ */ jsx(
          Autocomplete,
          {
            id: "model-name",
            freeSolo: true,
            options: modelNameOptions,
            sx: { width: "100%" },
            onChange: (event) => {
              const target = event.target;
              formik.setFieldValue("modelName", target.textContent);
            },
            value: formik.values.modelName,
            renderInput: (params) => {
              var _a2;
              return /* @__PURE__ */ jsx(
                TextField,
                __spreadProps(__spreadValues({}, params), {
                  variant: "standard",
                  name: "modelName",
                  value: (_a2 = formik.values.modelName) != null ? _a2 : "",
                  onChange: formik.handleChange,
                  placeholder: "MacBook Air",
                  fullWidth: true,
                  size: "small",
                  error: formik.touched.modelName && Boolean(formik.errors.modelName),
                  helperText: formik.touched.modelName && formik.errors.modelName
                })
              );
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, nonLabelfieldSx), { display: "flex", justifyContent: "space-between", alignItems: "center" }), children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "40%" }), children: "Device Name" }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "deviceName",
            value: formik.values.deviceName,
            onChange: formik.handleChange,
            error: formik.touched.deviceName && Boolean(formik.errors.deviceName),
            helperText: formik.touched.deviceName && formik.errors.deviceName,
            size: "small",
            endAdornment: "none",
            placeholder: "My device",
            required: true
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, nonLabelfieldSx), { display: "flex", justifyContent: "space-between", alignItems: "center" }), children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "40%" }), children: "Serial Number" }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "serialNumber",
            value: formik.values.serialNumber,
            onChange: formik.handleChange,
            error: formik.touched.serialNumber && Boolean(formik.errors.serialNumber),
            helperText: formik.touched.serialNumber && formik.errors.serialNumber,
            size: "small",
            endAdornment: "none",
            placeholder: "00000000000",
            required: true
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues(__spreadProps(__spreadValues({}, buttonSettingSx), { maxWidth: "30%", display: "flex", justifyContent: "flex-start" }), spacing.mt40), children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, onClick: () => routerHistory.goBack(), children: "Cancel" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          colorVariant: "primary",
          sizeVariant: "medium",
          name: "Save",
          fullWidth: true,
          loading,
          onClick: () => formik.handleSubmit(),
          disabled: !formik.values.modelName
        }
      )
    ] })
  ] });
};
