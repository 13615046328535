"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import ReactApexChart from "react-apexcharts";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { PRIMARY_CHART_COLOR, SECONDARY_CHART_COLOR, themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import "@/v2/components/charts/styles/apex-charts.styles.css";
export function ChartPie({ series, labels }) {
  const { polyglot } = usePolyglot();
  const chartOptions = __spreadProps(__spreadValues({
    labels,
    stroke: { show: false },
    tooltip: {
      y: {
        formatter: (val) => `${val}`
      },
      style: { fontFamily: "Roboto, sans-serif !important", fontWeight: 400, fontSize: "12px" },
      custom: function({ series: series2, seriesIndex }) {
        return `<div class="arrow_box">${series2[seriesIndex]}</div>`;
      }
    },
    show: true,
    dataLabels: {
      enabled: false
    },
    label: "Total"
  }, themeFonts.paragraph), {
    colors: [...PRIMARY_CHART_COLOR, ...SECONDARY_CHART_COLOR],
    plotOptions: { pie: { customScale: 0.9, donut: { size: "85%" } } },
    noData: { text: polyglot.t("Charts.noData") },
    legend: {
      horizontalAlign: "right",
      position: "right",
      fontFamily: "Roboto, sans-serif !important",
      fontWeight: 400,
      fontSize: "12px",
      labels: { colors: [themeColors.DarkGrey] },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: void 0,
        radius: 16,
        customHTML: void 0,
        onClick: void 0,
        offsetX: 0,
        offsetY: 0
      }
    },
    chart: {
      fontFamily: "Roboto, sans-serif !important",
      toolbar: {
        show: false
      },
      offsetY: 20,
      offsetX: -8
    }
  });
  return /* @__PURE__ */ jsx(ReactApexChart, { type: "pie", series, options: chartOptions, height: "400px" });
}
