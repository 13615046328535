"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { intersection } from "lodash";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Drag } from "@/images/growth/Drag.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  ReviewerTypesOrderMap,
  getReviewerTypesChips
} from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { NonSelfReviewerTypes, ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import {
  QuestionTypeMap
} from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { stripHtml } from "@/v2/util/string.util";
export const QuestionBuilder = ({
  questionOptions,
  question,
  growthFactors,
  showAction,
  showBorder,
  provided,
  isLast
}) => {
  var _a, _b;
  const questionText = useMemo(() => {
    if (!question) return "";
    return Boolean(intersection(NonSelfReviewerTypes, question.reviewerSelect).length > 0) ? stripHtml(question.questionMain) : stripHtml(question.questionSelf);
  }, [question]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({
        bgcolor: themeColors.white,
        p: spacing.p16,
        display: "flex",
        alignItems: "center",
        gap: spacing.s2
      }, showBorder ? { border: borders.middle, borderRadius: radius.br12 } : {
        borderBottom: showAction || !isLast ? borders.middle : "none",
        borderRadius: !showAction && isLast ? radius.br12 : "none"
      }), {
        borderCollapse: "collapse"
      }),
      children: [
        showAction && /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({}, provided.dragHandleProps), { sx: { cursor: "grab", paddingRight: spacing.p8 }, children: /* @__PURE__ */ jsx(Drag, __spreadValues({}, iconSize)) })),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            },
            children: [
              /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    flexDirection: "column",
                    gap: spacing.g8
                  },
                  children: [
                    /* @__PURE__ */ jsx(
                      Box,
                      {
                        sx: {
                          display: "flex",
                          alignItems: "center",
                          gap: spacing.g4
                        },
                        children: /* @__PURE__ */ jsxs(Typography, { variant: "headline3", children: [
                          " ",
                          questionText
                        ] })
                      }
                    ),
                    /* @__PURE__ */ jsxs(
                      Box,
                      {
                        sx: {
                          display: "flex",
                          alignItems: "center",
                          gap: spacing.g4
                        },
                        children: [
                          question.factor && /* @__PURE__ */ jsx(
                            ChipComponent,
                            {
                              backgroundColor: "white",
                              border: "middle",
                              name: /* @__PURE__ */ jsxs(
                                Box,
                                {
                                  sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    gap: spacing.g4
                                  },
                                  children: [
                                    /* @__PURE__ */ jsx(
                                      "div",
                                      {
                                        style: {
                                          backgroundColor: (_b = (_a = growthFactors[question.factor]) == null ? void 0 : _a.color) != null ? _b : themeColors.Grey,
                                          height: "8px",
                                          width: "8px",
                                          borderRadius: radius.br25
                                        }
                                      }
                                    ),
                                    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: question.factor })
                                  ]
                                }
                              )
                            }
                          ),
                          question.type && /* @__PURE__ */ jsx(
                            ChipComponent,
                            {
                              backgroundColor: "white",
                              border: "middle",
                              name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: QuestionTypeMap[question.type] })
                            }
                          ),
                          question.type && question.type === "scaleQuestion" && question.scaleConfig && /* @__PURE__ */ jsx(
                            ChipComponent,
                            {
                              backgroundColor: "white",
                              border: "middle",
                              name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: question.scaleConfig.type })
                            }
                          ),
                          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey, gap: spacing.g4, display: "flex" }, children: question && question.reviewerSelect ? question.reviewerSelect.sort((a, b) => ReviewerTypesOrderMap[a] - ReviewerTypesOrderMap[b]).map((r) => {
                            const hideVisibility = r !== ReviewerTypes.Self && (r === ReviewerTypes.Manager && question.visibilitySettings.hideManagerResult || r === ReviewerTypes.Upward && question.visibilitySettings.hideUpwardResult || r === ReviewerTypes.Peer && question.visibilitySettings.hidePeerResult);
                            return getReviewerTypesChips(hideVisibility)[r];
                          }) : "" }),
                          question.hasComment && /* @__PURE__ */ jsx(
                            ChipComponent,
                            {
                              backgroundColor: "white",
                              border: "middle",
                              name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Comment" })
                            }
                          )
                        ]
                      }
                    )
                  ]
                }
              ),
              showAction && /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: questionOptions,
                  actionButtonDetails: {
                    type: "iconButton",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "actions",
                    icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
