"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { SelectFieldCell, TextFieldCell } from "@/v2/components/table/basic-table.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { NO_VAT_VALUE } from "@/v2/feature/payments/expenses.util";
import { TaxRateOptions } from "@/v2/feature/payments/payments.interface";
import { getInvoiceTotalsForIndividualLineItem, isTaxIncluded } from "@/v2/feature/payments/utils/invoice.util";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function getNewLineItemValidationSchema(polyglot) {
  return yup.object({
    accountingCode: yup.string().nullable().when("$selectedInvoiceType", {
      is: (selectedInvoiceType) => {
        var _a;
        return ((_a = selectedInvoiceType == null ? void 0 : selectedInvoiceType.accountingCodeConfig) == null ? void 0 : _a.length) >= 1;
      },
      then: yup.string().nullable().required(polyglot.t("ValidationMessages.requiredField")),
      otherwise: yup.string().nullable()
    }),
    accountingCodeDescription: yup.string().nullable(),
    description: yup.string().nullable().max(200, polyglot.t("ValidationMessages.maxLength", { max: 200 })),
    amount: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
    gross: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
    taxRate: yup.number().oneOf(
      TaxRateOptions.map((option) => Number(option.value)),
      polyglot.t("ValidationMessages.validValue")
    ).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
  });
}
const createEmptyLineItem = () => ({
  id: uuidv4(),
  accountingCode: void 0,
  accountingCodeDescription: "",
  description: null,
  amount: void 0,
  gross: void 0,
  taxRate: void 0,
  isTaxIncluded: false
});
export const InvoiceLineItemTable = ({
  invoice,
  accountingCodeOptions,
  updateLineItems,
  errorInLineItems,
  selectedInvoiceType
}) => {
  const { polyglot } = usePolyglot();
  const [lineItems, setLineItems] = useState(invoice.lineItems || []);
  const [errors, setErrors] = useState({});
  const validationSchema = useMemo(() => getNewLineItemValidationSchema(polyglot), [polyglot]);
  const handleFieldChange = useCallback(
    (id, field, value) => {
      const updatedItems = lineItems.map((item) => {
        var _a, _b, _c;
        if (item.id !== id) return item;
        let updatedItem = __spreadProps(__spreadValues({}, item), { [field]: value });
        if ((field === "amount" || field === "gross") && !updatedItem.taxRate) {
          updatedItem.taxRate = NO_VAT_VALUE;
        }
        if (field === "accountingCode" && String(value).length > 0) {
          const matchingType = accountingCodeOptions.find((o) => o.value === value);
          updatedItem.accountingCodeDescription = (matchingType == null ? void 0 : matchingType.label) || "";
        }
        if (field === "gross") {
          const amountForLineItem = value ? Number(value) : 0;
          const { totalGross, totalAmount } = getInvoiceTotalsForIndividualLineItem(
            amountForLineItem,
            (_a = item.taxRate) != null ? _a : 0
          );
          updatedItem.amount = totalAmount;
          updatedItem.gross = totalGross;
        }
        if (field === "amount") {
          const amountValue = value ? Number(value) : 0;
          const { totalGross, totalAmount } = getInvoiceTotalsForIndividualLineItem(amountValue, (_b = item.taxRate) != null ? _b : 0);
          updatedItem.amount = totalAmount;
          updatedItem.gross = totalGross;
        }
        if (field === "taxRate") {
          updatedItem.isTaxIncluded = isTaxIncluded(value);
          const { totalGross, totalAmount } = getInvoiceTotalsForIndividualLineItem((_c = item.amount) != null ? _c : 0, value);
          updatedItem.amount = totalAmount;
          updatedItem.gross = totalGross;
        }
        return updatedItem;
      });
      setLineItems(updatedItems);
      const itemToValidate = updatedItems.find((item) => item.id === id);
      if (itemToValidate) {
        validationSchema.validate(itemToValidate, {
          context: { selectedInvoiceType },
          abortEarly: false
        }).then(() => {
          setErrors((prev) => {
            const newErrors = __spreadValues({}, prev);
            Object.keys(newErrors).forEach((key) => {
              if (key.startsWith(`${id}-`)) delete newErrors[key];
            });
            return newErrors;
          });
        }).catch((error) => {
          if (error instanceof yup.ValidationError) {
            setErrors((prev) => __spreadValues(__spreadValues({}, prev), error.inner.reduce(
              (acc, err) => __spreadProps(__spreadValues({}, acc), {
                [`${id}-${err.path}`]: err.message
              }),
              {}
            )));
          }
        });
      }
      updateLineItems(updatedItems);
    },
    [lineItems, updateLineItems, accountingCodeOptions, validationSchema, selectedInvoiceType]
  );
  const deleteLineItemById = useCallback(
    (id) => {
      const updatedItems = lineItems.filter((item) => item.id !== id);
      setLineItems(updatedItems);
      updateLineItems(updatedItems);
    },
    [lineItems, updateLineItems]
  );
  const validateAllFields = useCallback(() => __async(void 0, null, function* () {
    const allErrors = {};
    for (const item of lineItems) {
      try {
        yield validationSchema.validate(item, {
          context: {
            selectedInvoiceType
          },
          abortEarly: false
        });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          error.inner.forEach((err) => {
            if (err.path && item.id) {
              allErrors[`${item.id}-${err.path}`] = err.message;
            }
          });
        }
      }
    }
    setErrors(allErrors);
  }), [lineItems, validationSchema, selectedInvoiceType]);
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("NewExpensePage.description"),
        accessorFn: (row) => row.description,
        id: "description",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          TextFieldCell,
          {
            id: original.id,
            name: "description",
            value: original.description || "",
            update: handleFieldChange,
            error: Boolean(errors[`${original.id}-description`])
          }
        )
      },
      ...accountingCodeOptions && accountingCodeOptions.length > 0 ? [
        {
          header: () => polyglot.t("NewExpensePage.accountingCode"),
          accessorFn: (row) => row,
          id: "accountingCode",
          enableSorting: false,
          cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
            SelectFieldCell,
            {
              id: original.id,
              name: "accountingCode",
              value: original.accountingCode,
              options: accountingCodeOptions,
              update: handleFieldChange,
              error: Boolean(errors[`${original.id}-accountingCode`])
            }
          )
        }
      ] : [],
      {
        header: () => polyglot.t("NewInvoicePage.taxRate"),
        accessorFn: (row) => row,
        id: "taxRate",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          SelectFieldCell,
          {
            id: original.id,
            name: "taxRate",
            value: original.taxRate,
            options: TaxRateOptions,
            update: handleFieldChange,
            error: Boolean(errors[`${original.id}-taxRate`]),
            maxLabelDisplayCharacters: 25
          }
        )
      },
      {
        header: () => polyglot.t("PayItemModule.amount"),
        accessorFn: (row) => row,
        id: "gross",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          TextFieldCell,
          {
            id: original.id,
            name: "gross",
            value: original.amount,
            update: handleFieldChange,
            type: "number",
            error: Boolean(errors[`${original.id}-gross`])
          }
        )
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: ({ row }) => /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              visibility: lineItems.length > 1 ? "visible" : "hidden"
            },
            children: /* @__PURE__ */ jsx(
              IconButton,
              {
                sizeVariant: "small",
                colorVariant: "secondary",
                onClick: () => {
                  if (row.original.id) deleteLineItemById(row.original.id);
                },
                children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
              }
            )
          }
        )
      }
    ];
  }, [polyglot, accountingCodeOptions, deleteLineItemById, errors, handleFieldChange, lineItems.length]);
  const addEmptyRow = useCallback(() => {
    const newLineItem = createEmptyLineItem();
    const updatedItems = [...lineItems, newLineItem];
    setLineItems(updatedItems);
    updateLineItems(updatedItems);
    validateAllFields();
  }, [lineItems, updateLineItems, validateAllFields]);
  useEffect(() => {
    if (invoice.lineItems.length === 0 && lineItems.length === 0) {
      addEmptyRow();
    }
  }, [invoice.lineItems, lineItems.length, addEmptyRow]);
  useEffect(() => {
    if (errorInLineItems) {
      validateAllFields();
    }
  }, [errorInLineItems, validateAllFields]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: lineItems,
        columnData: columns,
        loading: false,
        externalTdAndThPadding: "0px 0.5px",
        hideHover: true,
        hidePagination: true
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", mt: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "secondary", onClick: addEmptyRow, children: polyglot.t("NewInvoicePage.addLineItems") }),
      /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "paragraphSmall",
          color: "RedDark",
          sx: {
            opacity: Object.keys(errors).length > 0 ? 1 : 0,
            transition: "opacity 0.3s ease"
          },
          children: polyglot.t("NewExpensePage.addMissingDetails")
        }
      )
    ] })
  ] });
};
