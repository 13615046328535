"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { SETTINGS_TASK_CHECKLIST_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditTasks = ({ checklistIds, onSave }) => {
  const { polyglot } = usePolyglot();
  const validationSchema = yup.object({
    checklistId: yup.number().integer().min(1, polyglot.t("EditTasks.errorMessages.checklistRequired"))
  });
  const [checklists, setChecklists] = useState();
  const routerHistory = useHistory();
  const refreshTaskTypes = useCallback(() => {
    TaskAPI.getChecklists().then(setChecklists);
  }, []);
  const formik = useFormik({
    initialValues: {
      checklistIds: checklistIds != null ? checklistIds : []
    },
    validationSchema,
    onSubmit(values) {
      onSave(values.checklistIds);
    }
  });
  useEffect(refreshTaskTypes, [refreshTaskTypes]);
  const checklistOptions = useMemo(() => {
    return checklists == null ? void 0 : checklists.sort((a, b) => {
      var _a, _b, _c;
      return (_c = (_a = a.name) != null ? _a : "") == null ? void 0 : _c.localeCompare((_b = b.name) != null ? _b : "", void 0, { sensitivity: "base" });
    }).map((c) => {
      var _a;
      return {
        label: (_a = c.name) != null ? _a : "",
        value: c.id
      };
    });
  }, [checklists]);
  const selectedValues = useMemo(() => {
    return checklistOptions && formik.values.checklistIds ? checklistOptions == null ? void 0 : checklistOptions.filter((c) => {
      var _a;
      return (_a = formik.values.checklistIds) == null ? void 0 : _a.includes(c.value);
    }) : [];
  }, [formik.values.checklistIds, checklistOptions]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("EditTasks.select") }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt10 }), children: polyglot.t("EditTasks.checklistCollection") }),
    !checklistOptions && /* @__PURE__ */ jsx(LoadingSpinner, {}),
    checklistOptions && checklistOptions.length === 0 && /* @__PURE__ */ jsxs(Stack, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt20 }), children: polyglot.t("EditTasks.noChecklists") }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          type: "button",
          fullWidth: true,
          colorVariant: "primary",
          sizeVariant: "large",
          style: { marginTop: "40px" },
          onClick: () => routerHistory.push(SETTINGS_TASK_CHECKLIST_ROUTE),
          children: "Go to checklists"
        }
      )
    ] }),
    checklistOptions && checklistOptions.length > 0 && /* @__PURE__ */ jsxs("form", { onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ minHeight: "65px" }, spacing.mt20), children: /* @__PURE__ */ jsx(
        MultipleSelectCheckbox,
        {
          id: "checklistIds",
          label: polyglot.t("EditTasks.select"),
          limitTags: 5,
          options: checklistOptions,
          value: selectedValues,
          onChange: (_, values) => {
            const updatedOptionList = values.map(({ value }) => value);
            formik.setFieldValue("checklistIds", updatedOptionList, true);
          },
          isOptionEqualToValue: (x, y) => x.value === y.value,
          getOptionLabel: ({ label }) => label,
          disableClearable: true
        }
      ) }),
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, style: { marginTop: "40px" }, colorVariant: "primary", sizeVariant: "large", children: polyglot.t("General.save") })
    ] })
  ] });
};
