"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { compareDesc } from "date-fns";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as CalendarIcon } from "@/images/icons/calendar.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { canAccessScopes } from "@/lib/scopes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { GrowthFactorsGroup } from "@/v2/feature/growth/goals/components/growth-factors-group.component";
import { ListItem } from "@/v2/feature/growth/goals/components/list-item.component";
import { OwnerChip } from "@/v2/feature/growth/goals/components/owner-chip.component";
import { KeyResultProgressListItem } from "@/v2/feature/growth/goals/features/key-result-progress/components/key-result-progress-list-item.component";
import { KeyResultProgressMutationDrawer } from "@/v2/feature/growth/goals/features/key-result-progress/components/key-result-progress-mutation-drawer.component";
import { KeyResultProgressType } from "@/v2/feature/growth/goals/features/key-results/key-result.interface";
import { GrowthChip } from "@/v2/feature/growth/shared/components/growth-chip.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { LocalDate } from "@/v2/util/local-date";
const iconSize = { width: 14, height: 14 };
export const KeyResultListItem = ({
  keyResult,
  hoverState,
  onClick,
  onProgressCreated,
  onProgressUpdated,
  onProgressDeleted,
  onEditClick,
  onDeleteClick,
  sx
}) => {
  const { polyglot } = usePolyglot();
  const isPercentageBased = keyResult.progressType === KeyResultProgressType.Percentage;
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const [isNewUpdateDrawerOpen, setIsNewUpdateDrawerOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const isAdmin = canAccessScopes(user, ["goals:all"]);
  const isKeyResultOwner = useMemo(() => {
    if (!keyResult) return false;
    return keyResult.ownerIds.includes(user.userId);
  }, [keyResult, user]);
  const canPostProgressUpdates = isKeyResultOwner || isAdmin;
  const canEditKeyResult = isAdmin;
  const getKeyResultActionOptions = useCallback(
    () => [
      {
        icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
        handler: () => {
          setIsOptionsMenuOpen(false);
          onEditClick == null ? void 0 : onEditClick(keyResult);
        },
        label: polyglot.t("KeyResultListItem.edit"),
        disabled: false
      },
      {
        icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
        handler: () => {
          setIsOptionsMenuOpen(false);
          onDeleteClick == null ? void 0 : onDeleteClick(keyResult);
        },
        label: polyglot.t("KeyResultListItem.delete"),
        disabled: false
      }
    ],
    [polyglot, keyResult, onEditClick, onDeleteClick]
  );
  const progressItems = useMemo(() => {
    return keyResult.progressItems.sort((a, b) => {
      return compareDesc(new Date(a.createdAt), new Date(b.createdAt));
    });
  }, [keyResult]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          "&:hover": {
            "& .end-section": {
              opacity: 1
            }
          }
        },
        onClick: () => {
          onClick == null ? void 0 : onClick(keyResult);
        },
        children: /* @__PURE__ */ jsx(
          ListItem,
          {
            hoverState,
            progressBar: {
              type: "circle",
              progress: keyResult.lastProgress,
              progressType: isPercentageBased ? "percentage" : "number",
              progressTarget: isPercentageBased ? 100 : keyResult.progressTarget
            },
            onProgressSectionClick: () => setIsExpanded(!isExpanded),
            details: {
              title: keyResult.name,
              description: keyResult.description ? keyResult.description : null,
              onClick: () => setIsExpanded(!isExpanded),
              chips: /* @__PURE__ */ jsxs(Fragment, { children: [
                /* @__PURE__ */ jsx(
                  OwnerChip,
                  {
                    ownerIds: keyResult.ownerIds,
                    customRule: keyResult.customRule,
                    membersRule: keyResult.membersRule
                  }
                ),
                keyResult.dueDate ? /* @__PURE__ */ jsx(
                  GrowthChip,
                  {
                    options: {
                      textColor: "black2",
                      textVariant: "paragraphSmall",
                      text: new LocalDate(keyResult.dueDate).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }),
                      icon: /* @__PURE__ */ jsx(CalendarIcon, { width: "12px" }),
                      border: "middleGrey2"
                    },
                    sx: { height: "22px" }
                  }
                ) : null,
                /* @__PURE__ */ jsx(GrowthFactorsGroup, { factorIds: keyResult.factorIds })
              ] })
            },
            endSection: /* @__PURE__ */ jsxs(
              Box,
              {
                className: "end-section",
                sx: {
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0.25rem",
                  opacity: isOptionsMenuOpen ? 1 : 0,
                  transition: "opacity 0.1s ease"
                },
                children: [
                  canEditKeyResult && /* @__PURE__ */ jsx(
                    StyledMenuComponent,
                    {
                      options: getKeyResultActionOptions(),
                      actionButtonDetails: {
                        type: "iconButton",
                        colorVariant: "secondary",
                        sizeVariant: "small",
                        title: "actions",
                        icon: /* @__PURE__ */ jsx(ActionsSmall, { width: 14, height: 14 })
                      },
                      onClick: () => __async(void 0, null, function* () {
                        return setIsOptionsMenuOpen(true);
                      }),
                      onClose: () => __async(void 0, null, function* () {
                        return setIsOptionsMenuOpen(false);
                      }),
                      sx: {
                        button: {
                          backgroundColor: themeColors.Background,
                          "&:hover": {
                            backgroundColor: themeColors.Background
                          }
                        }
                      }
                    }
                  ),
                  canPostProgressUpdates && /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      sizeVariant: "small",
                      colorVariant: "primary",
                      onClick: () => setIsNewUpdateDrawerOpen(true),
                      children: polyglot.t("KeyResultListItem.newUpdate")
                    }
                  )
                ]
              }
            ),
            gridLayout: [7, 3, 2],
            sx: __spreadValues({
              padding: "1rem",
              borderRadius: isExpanded ? "12px 12px 0 0" : "12px"
            }, sx)
          }
        )
      }
    ),
    isExpanded && progressItems.map((progressItem, idx) => {
      const isLastItem = idx === progressItems.length - 1;
      return /* @__PURE__ */ jsx(
        KeyResultProgressListItem,
        {
          keyResult,
          progress: progressItem,
          onProgressUpdated,
          onProgressDeleted,
          sx: {
            borderWidth: "0 1px 1px 1px",
            borderRadius: isLastItem ? "0 0 12px 12px" : 0,
            backgroundColor: "white"
          }
        }
      );
    }),
    /* @__PURE__ */ jsx(
      KeyResultProgressMutationDrawer,
      {
        keyResult,
        mode: "create",
        isOpen: isNewUpdateDrawerOpen,
        onProgressCreated: () => __async(void 0, null, function* () {
          setIsNewUpdateDrawerOpen(false);
          onProgressCreated == null ? void 0 : onProgressCreated();
        }),
        onClosed: () => {
          setIsNewUpdateDrawerOpen(false);
        }
      }
    )
  ] });
};
