"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const IconContentActionCard = ({
  title,
  content,
  showDivider = false,
  iconMedia,
  action,
  titleSx,
  iconGap,
  textGap,
  sx
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues(__spreadValues({
        display: "flex",
        alignItems: "flex-start",
        gap: spacing.g20,
        width: "100%",
        justifyContent: "space-between",
        borderBottom: showDivider ? borders.background : "none"
      }, spacing.pb20), sx),
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: iconGap != null ? iconGap : spacing.g15 }, children: [
          !!iconMedia && /* @__PURE__ */ jsx(Box, { children: iconMedia }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: textGap != null ? textGap : spacing.g5 }, children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues(__spreadValues({}, themeFonts.headline3), titleSx), { color: themeColors.DarkGrey }), children: title }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: content })
          ] })
        ] }),
        !!action && /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: action })
      ]
    }
  );
};
export const IconContentActionCardGroup = ({ cards, sx }) => {
  if (cards.length === 0) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsx(Grid, { container: true, spacing: "40px", sx, children: cards.map((card, idx) => /* @__PURE__ */ jsx(Grid, { item: true, sm: 12, md: 6, children: /* @__PURE__ */ jsx(
    IconContentActionCard,
    __spreadProps(__spreadValues({}, card), {
      titleSx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }),
      iconGap: spacing.g20,
      textGap: spacing.g10,
      sx: { paddingBottom: 0 }
    })
  ) }, `action-card-${idx}`)) });
};
export const IntroCardGroup = ({ cards, sx }) => {
  if (cards.length === 0) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsx(Grid, { container: true, spacing: "40px", sx, children: cards.map((card, idx) => /* @__PURE__ */ jsx(Grid, { item: true, sm: 12, md: 6, children: /* @__PURE__ */ jsx(
    IntroCard,
    __spreadProps(__spreadValues({}, card), {
      titleSx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }),
      textGap: spacing.g5,
      sx: { paddingBottom: 0 }
    })
  ) }, `icon-card-${idx}`)) });
};
export const IntroCard = ({
  title,
  content,
  showDivider = false,
  iconMedia,
  action,
  titleSx,
  textGap,
  sx,
  titleTag,
  titleTagText,
  titleTagTextSx
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues(__spreadValues({
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        justifyContent: "space-between",
        borderBottom: showDivider ? borders.background : "none"
      }, spacing.pb20), sx),
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, flexDirection: "column" }, children: [
          /* @__PURE__ */ jsx(Box, { children: !!iconMedia && iconMedia }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: textGap != null ? textGap : spacing.g5 }, children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues(__spreadValues({}, themeFonts.headline3), titleSx), { color: themeColors.DarkGrey }), children: title }),
              titleTag && /* @__PURE__ */ jsx(Typography, { sx: titleTagTextSx, children: titleTagText })
            ] }),
            /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: content })
          ] })
        ] }),
        !!action && /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: action })
      ]
    }
  );
};
