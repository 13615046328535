"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import "@/v2/feature/dashboard/features/sections/user-shift/user-shift.scss";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserShiftLoader = () => {
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "small", isLoadingState: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "column",
          padding: spacing.p5
        },
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Shift" }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: format(new LocalDate().getDate(), "EEEE dd") })
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { height: "100%", padding: spacing.p5 }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: spacing.g35
        },
        children: [0, 1, 2].map((num) => /* @__PURE__ */ jsx("div", { className: "us-loader-container", children: /* @__PURE__ */ jsx("div", { className: "us-progress" }) }, `${num}-shift`))
      }
    ) })
  ] }) });
};
