"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { BenefitPaymentActionItem } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/benefit-payment-action-item.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Salary } from "@/images/dashboard-icons/Salary.svg";
import { TodoCard } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const PendingBenefitPaymentTodoComponent = ({
  todo,
  setLastChild,
  refreshTodos
}) => {
  const { polyglot } = usePolyglot();
  const [selectedRow, setSelectedRow] = useState(void 0);
  const getTitle = (name, benefitName, amount, currency) => {
    const formattedAmount = formatMoney({ amount, currency });
    return polyglot.t("BenefitModule.benefitPaymentRequested", { name, benefitName, amount: formattedAmount });
  };
  return /* @__PURE__ */ jsxs(Box, { children: [
    todo.customBenefitPayments.map((payment) => /* @__PURE__ */ jsx(
      TodoCard,
      {
        icon: /* @__PURE__ */ jsx(Salary, __spreadValues({}, iconSize)),
        title: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "paragraph",
            sx: {
              display: "inline-block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%"
            },
            children: getTitle(payment.userName, payment.customBenefitName, payment.amount, payment.currency)
          }
        ),
        actionOnclick: () => setSelectedRow(payment),
        showBorder: !setLastChild,
        userAvatar: payment.userId ? /* @__PURE__ */ jsx(UserAvatar, { userId: payment.userId, size: "s16" }, payment.userId) : /* @__PURE__ */ jsx(Fragment, {})
      },
      `benefit-payment-${payment.id}`
    )),
    selectedRow && /* @__PURE__ */ jsx(
      BenefitPaymentActionItem,
      {
        selectedRow,
        refresh: () => __async(void 0, null, function* () {
          setSelectedRow(void 0);
          if (refreshTodos) yield refreshTodos();
        }),
        afterClose: () => setSelectedRow(void 0)
      }
    )
  ] });
};
