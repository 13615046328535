"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { FocusState, QuestionComment } from "./question-comment.component";
import { useQuestionFocus } from "../../hooks/use-question-focus.hook";
import { Typography } from "@/v2/components/typography/typography.component";
import { getSentimentColor } from "@/v2/feature/growth/growth-scale/growth-scale.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ScaleQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  showError,
  isFocused,
  registerMethods
}) => {
  var _a, _b;
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = (answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) || { answer: "", comment: "" };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);
  const [scaleKey, setScaleKey] = useState(currentAnswer);
  useEffect(() => {
    setScaleKey(currentAnswer);
  }, [currentAnswer]);
  const optionKeys = useMemo(() => {
    return (question == null ? void 0 : question.scaleConfig) ? Object.keys(question.scaleConfig.value) : [];
  }, [question == null ? void 0 : question.scaleConfig]);
  const handleScaleChange = useCallback(
    (value) => {
      if (!entry) return;
      handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
    },
    [question.id, currentComment, entry, handleAnswerChange]
  );
  const handleCommentChange = useCallback(
    (value) => {
      if (!entry) return;
      handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
    },
    [currentAnswer, entry, question.id, handleAnswerChange]
  );
  const {
    hasKeyboardFocus,
    highlightedOptionIndex,
    setHighlightedOptionIndex,
    focusState,
    setFocusState,
    registerCommentFieldRef,
    registerOptionRef,
    handleContainerKeyDown,
    handleCommentKeyDown,
    containerRef
  } = useQuestionFocus({
    question,
    optionKeys,
    isFocused,
    registerMethods,
    handleOptionAction: handleScaleChange
  });
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && focusState === FocusState.COMMENT && containerRef.current) {
        e.preventDefault();
        containerRef.current.focus();
        setFocusState(FocusState.OPTIONS);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [focusState, setFocusState, containerRef]);
  if (!question || !reviewType || !entry || !question.scaleConfig) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    Box,
    {
      ref: containerRef,
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g24,
        boxSizing: "border-box",
        outline: "none"
      },
      onKeyDown: handleContainerKeyDown,
      tabIndex: 0,
      "data-testid": "scale-question-container",
      children: [
        /* @__PURE__ */ jsxs("div", { style: { display: "flex", flexDirection: "column", gap: spacing.g8, boxSizing: "border-box" }, children: [
          question.scaleConfig && Object.entries(question.scaleConfig.value).length > 5 && ((_a = question.scaleConfig) == null ? void 0 : _a.points) && scaleKey && /* @__PURE__ */ jsx(Box, { sx: { visibility: scaleKey ? "visible" : "hidden", minHeight: "20px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: scaleKey ? question.scaleConfig.points[scaleKey] : "" }) }),
          /* @__PURE__ */ jsx(Box, { style: { display: "flex", flexGrow: 1, gap: spacing.g4, boxSizing: "border-box" }, children: ((_b = question.scaleConfig) == null ? void 0 : _b.value) && Object.entries(question.scaleConfig.value).map(([key, value], index) => {
            var _a2;
            const isSelected = currentAnswer === key;
            const isHighlighted = hasKeyboardFocus && focusState === FocusState.OPTIONS && highlightedOptionIndex === index;
            return /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  gap: spacing.s2,
                  width: "100%",
                  boxSizing: "border-box"
                },
                children: [
                  /* @__PURE__ */ jsx(
                    Box,
                    {
                      ref: (el) => registerOptionRef(key, el),
                      sx: {
                        flex: 1,
                        minHeight: "44px",
                        maxHeight: "44px",
                        height: "44px",
                        display: "flex",
                        width: "100%",
                        cursor: "pointer",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        boxSizing: "border-box",
                        backgroundColor: isSelected && question.scaleConfig ? (_a2 = getSentimentColor()[question.scaleConfig.sentiments[key]]) != null ? _a2 : themeColors.DarkGrey : themeColors.Background,
                        borderRadius: radius.br8,
                        userSelect: "none",
                        border: showError && !currentAnswer ? `1px solid ${themeColors.darkRed}` : isHighlighted && !isSelected ? `0.5px dashed ${themeColors.DarkGrey}` : "none",
                        outline: "none",
                        transition: "all 0.2s ease"
                      },
                      onClick: (e) => {
                        e.preventDefault();
                        handleScaleChange(key);
                        setScaleKey(key);
                        setHighlightedOptionIndex(index);
                        if (containerRef.current) {
                          containerRef.current.focus();
                        }
                      },
                      children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: value })
                    },
                    key
                  ),
                  question.scaleConfig && Object.entries(question.scaleConfig.value).length < 6 && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", textAlign: "center", children: question.scaleConfig.points[key] })
                ]
              },
              key
            );
          }) }),
          showError && !currentAnswer && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: showError ? themeColors.darkRed : themeColors.DarkGrey }, children: "This field is required" })
        ] }),
        question.hasComment && /* @__PURE__ */ jsx(
          QuestionComment,
          {
            question,
            currentComment,
            handleCommentChange,
            showError,
            registerCommentFieldRef,
            setFocusState,
            onKeyDown: handleCommentKeyDown
          }
        )
      ]
    }
  );
};
