"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { AlertMessage } from "@/v2/components/theme-components/alert.component";
import { PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const NextPayrunAttendanceEntriesWithoutPaycodeWarning = ({ payrollId }) => {
  const { polyglot } = usePolyglot();
  const { data: attendanceEntriesWithoutPaycode } = useApiClient(
    PayrollEndpoints.getApprovedAttendanceEntriesWithoutPaycodeForNextPayrun(payrollId),
    {
      suspense: false
    }
  );
  return /* @__PURE__ */ jsx(Fragment, { children: attendanceEntriesWithoutPaycode && attendanceEntriesWithoutPaycode.length > 0 && /* @__PURE__ */ jsx(
    AlertMessage,
    {
      message: polyglot.t("CompanyPayroll.approvedTimesheetsWithoutPaycodeWarning"),
      severity: "warning"
    }
  ) });
};
