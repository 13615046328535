"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { dateDiffInDays } from "@/lib/date-time.lib";
import { AlertMessage } from "@/v2/components/theme-components/alert.component";
import { PensionEndpoints } from "@/v2/feature/benefits/subfeature/pension/pension.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
const DEFER_LIMIT_IN_DAYS = 90;
export const isEmployeeNotInPensionForMoreThanXDays = (employee, entityId, date, employeesWithPensionWarningSet) => {
  return !!(employee.entityId === entityId && employee.inPayroll && employeesWithPensionWarningSet.has(employee.userId) && employee.user.startDate && dateDiffInDays(new Date(date), new Date(employee.user.startDate)) > DEFER_LIMIT_IN_DAYS);
};
export const UsersNotEnrolledInPension = ({
  employeeList,
  entityId,
  date
}) => {
  const { polyglot } = usePolyglot();
  const router = useHistory();
  const { data: employeesWithPensionWarning } = useApiClient(
    PensionEndpoints.getEmployeesWithPensionWarning(entityId),
    {
      suspense: false
    }
  );
  const employeesNotInPensionMoreThanDeferLimit = useMemo(() => {
    var _a;
    const employeesWithPensionWarningSet = new Set((_a = employeesWithPensionWarning == null ? void 0 : employeesWithPensionWarning.map((e) => e.userId)) != null ? _a : []);
    return employeeList.filter(
      (e) => isEmployeeNotInPensionForMoreThanXDays(e, entityId, date, employeesWithPensionWarningSet)
    );
  }, [employeeList, entityId, date, employeesWithPensionWarning]);
  return employeesNotInPensionMoreThanDeferLimit.length > 0 ? /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    AlertMessage,
    {
      severity: "error",
      message: polyglot.t("PayrunTable.usersNotEnrolledInPension", {
        count: employeesNotInPensionMoreThanDeferLimit.length,
        employees: employeesNotInPensionMoreThanDeferLimit.map((e) => e.user.displayName).join(", ")
      }),
      actionTitle: polyglot.t("PayrunTable.seePension"),
      onAction: () => {
        router.push("/money/company/benefits/overview");
      }
    }
  ) }) : /* @__PURE__ */ jsx(Fragment, {});
};
