"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DevicesTransitsTableSuperAdmin } from "@v2/feature/device/components/devices-list-overview/devices-transits-table-superadmin.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { getCompanyNamesKeyedByCompanyIds, getUserNamesKeyedByUserIds } from "@v2/feature/device/device.util";
import { BackofficeRootStyle } from "@v2/styles/root.styles";
import { keyBy } from "lodash";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { themeColors } from "@/v2/styles/colors.styles";
export const SuperAdminDevicesTransitsPage = ({
  users,
  companies,
  pageConfig
}) => {
  var _a, _b;
  const [zeltDeviceTransits, setZeltDeviceTransits] = useState([]);
  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  const companiesNames = useMemo(() => getCompanyNamesKeyedByCompanyIds(companies), [companies]);
  const [sites, setSitesById] = useState({});
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const refreshZeltTransits = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const deviceTransits = yield DeviceAPI.getZeltTransitsAsSuperadmin();
      const sitesData = yield SiteAPI.listSitesAsSuperAdmin();
      setZeltDeviceTransits(deviceTransits);
      setSitesById(keyBy(sitesData, "id"));
    } catch (error) {
      showMessage(`Could not retrieve Zelt devices transits. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refreshZeltTransits();
  }, [refreshZeltTransits]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: "Devices" }),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, secondLevel: true, children: Boolean(zeltDeviceTransits && zeltDeviceTransits.length > 0) ? /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "83%",
            height: "100%",
            sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
          }
        ),
        children: /* @__PURE__ */ jsx(
          DevicesTransitsTableSuperAdmin,
          {
            userNames,
            companiesNames,
            deviceTransits: zeltDeviceTransits,
            refresh: refreshZeltTransits,
            sites
          }
        )
      }
    ) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "No active transits." }) })
  ] });
};
