"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { BasicServerTable } from "@v2/components/table/server-side-table.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI, DeviceEndpoints } from "@v2/feature/device/device.api";
import {
  getDeviceOwnerAsSuperadminByDevicePossession,
  getMdmServer,
  getUserNamesKeyedByUserIds,
  isEnroled
} from "@v2/feature/device/device.util";
import { SuperAdminOverviewDeviceDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-overview-device-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { secondaryCTABtn } from "@v2/styles/buttons.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { pipe } from "fp-ts/function";
import { keyBy } from "lodash";
import CsvDownloader from "react-csv-downloader";
import { Subject } from "rxjs";
import * as RX from "rxjs/operators";
import { SiteEndpoints } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminAllDevicesOverviewPage = ({ users, companies, pageConfig }) => {
  var _a, _b;
  const DEFAULT_PAGE_SIZE = 20;
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [devicePossessions, setDevicePossessions] = useState([]);
  const [matchings, setMatchings] = useState({});
  const [devicePossessionToBeEdited, setDevicePossessionToBeEdited] = useState(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [sites, setSitesById] = useState({});
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState(null);
  const [changeSearchInput$] = useState(() => new Subject());
  const { data: siteData } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: superAdminDevicesResponse, mutate: refreshDevicePossessions } = useApiClient(
    DeviceEndpoints.getAllDevicesAsSuperAdmin(null, searchInput, pagination.pageIndex, pagination.pageSize),
    {
      suspense: false
    }
  );
  const refreshDevicePossessionsData = useCallback(() => __async(void 0, null, function* () {
    if (refreshDevicePossessions) yield refreshDevicePossessions();
  }), [refreshDevicePossessions]);
  useEffect(() => {
    if (siteData) {
      setSitesById(keyBy(siteData, "id"));
    }
  }, [siteData]);
  const fetchData = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield refreshDevicePossessionsData();
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [refreshDevicePossessionsData, showMessage]);
  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  useEffect(() => {
    const subscription = pipe(
      changeSearchInput$,
      RX.debounceTime(1e3),
      RX.tap((value) => setSearchInput(value)),
      RX.tap(() => fetchData())
    ).subscribe();
    return () => subscription.unsubscribe();
  }, [changeSearchInput$, fetchData]);
  const getCompanyNameById = useCallback(
    (companyId) => {
      var _a2;
      if (!companyId) return void 0;
      return (_a2 = companies.find((company) => company.companyId === companyId)) == null ? void 0 : _a2.name;
    },
    [companies]
  );
  const formatDevicePossessionForCsv = () => __async(void 0, null, function* () {
    var _a2, _b2;
    const dps = yield DeviceAPI.getAllDevicesAsSuperAdminWithoutPagination(null, searchInput);
    return (_b2 = (_a2 = dps.devicePossessions) == null ? void 0 : _a2.items) == null ? void 0 : _b2.map((devicePossession) => {
      var _a3, _b3, _c, _d, _e, _f, _g, _h, _i;
      return {
        "Device Id": ((_a3 = devicePossession.device) == null ? void 0 : _a3.id) || "",
        "Serial number": ((_b3 = devicePossession.device) == null ? void 0 : _b3.serialNumber) || "",
        "Company name": (_c = getCompanyNameById(devicePossession.companyId)) != null ? _c : devicePossession.possessionType || "",
        "Owned By": getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites) || "",
        "Device model": ((_d = devicePossession.device) == null ? void 0 : _d.modelName) || "",
        Ownership: ((_e = devicePossession.device) == null ? void 0 : _e.ownership) || "",
        Price: ((_f = devicePossession.device) == null ? void 0 : _f.price) ? `\xA3${devicePossession.device.price}` : "",
        "Contract Length": ((_g = devicePossession.device) == null ? void 0 : _g.contractLength) ? `${devicePossession.device.contractLength} months` : "",
        "Contract start": ((_h = devicePossession.device) == null ? void 0 : _h.contractStartDate) || "",
        isEnrolled: isEnroled((_i = devicePossession.device) == null ? void 0 : _i.enrollmentStatus),
        MDM: getMdmServer(devicePossession.device, matchings)
      };
    });
  });
  const tableData = devicePossessions;
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Device Id",
        accessorFn: (row) => row,
        id: "device.id",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return possession.device ? /* @__PURE__ */ jsx(Box, { children: possession.device.id }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 55
      },
      {
        header: () => "Serial number",
        accessorFn: (row) => row,
        id: "device.id",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return possession.device ? /* @__PURE__ */ jsx(Box, { children: possession.device.serialNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 55
      },
      {
        header: () => "Company name",
        accessorFn: (row) => row,
        id: "companyId",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const companyName = (_a2 = getCompanyNameById(possession.companyId)) != null ? _a2 : possession.possessionType;
          return companyName ? /* @__PURE__ */ jsx(Box, { children: companyName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 150
      },
      {
        header: () => "Owned By",
        accessorFn: (row) => row,
        id: "possessionId",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(possession, userNames, sites);
          return /* @__PURE__ */ jsx(Box, { children: ownedBy });
        },
        size: 150
      },
      {
        header: () => "Device model",
        accessorFn: (row) => row,
        id: "device.modelName",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const modelName = (_a2 = possession.device) == null ? void 0 : _a2.modelName;
          return modelName ? /* @__PURE__ */ jsx(Box, { children: modelName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 125
      },
      {
        header: () => "Ownership",
        accessorFn: (row) => row,
        id: "device.ownership",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const ownership = (_a2 = possession.device) == null ? void 0 : _a2.ownership;
          return ownership ? /* @__PURE__ */ jsx(Box, { children: ownership }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Price",
        accessorFn: (row) => row,
        id: "device.price",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const price = (_a2 = possession.device) == null ? void 0 : _a2.price;
          return price ? /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            price
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Contract Length",
        accessorFn: (row) => row,
        id: "device.contractLength",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const contractLength = (_a2 = possession.device) == null ? void 0 : _a2.contractLength;
          return contractLength ? /* @__PURE__ */ jsxs(Box, { children: [
            contractLength,
            " months"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Contract start",
        accessorFn: (row) => row,
        id: "device.contractStart",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const contractStartDate = (_a2 = possession.device) == null ? void 0 : _a2.contractStartDate;
          return contractStartDate ? /* @__PURE__ */ jsx(Box, { children: contractStartDate }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "MDM",
        accessorFn: (row) => row,
        id: "device.mdm",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          const mdmResult = getMdmServer(possession.device, matchings);
          return /* @__PURE__ */ jsx(Box, { children: mdmResult });
        },
        size: 75
      }
    ];
  }, [getCompanyNameById, userNames, sites, matchings]);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2, _c, _d, _e, _f, _g, _h;
    try {
      const devicePossessions2 = (_b2 = (_a2 = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.devicePossessions) == null ? void 0 : _a2.items) != null ? _b2 : [];
      setDevicePossessions(devicePossessions2);
      setMatchings((_c = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.matchings) != null ? _c : {});
      setTotalPages((_e = (_d = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.devicePossessions) == null ? void 0 : _d.totalPages) != null ? _e : 1);
      setTotalItems((_g = (_f = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.devicePossessions) == null ? void 0 : _f.totalItems) != null ? _g : 0);
      setDevicePossessionToBeEdited((_h = devicePossessions2.find((d) => d.id === (devicePossessionToBeEdited == null ? void 0 : devicePossessionToBeEdited.id))) != null ? _h : null);
    } catch (error) {
      showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, "error");
    }
  }), [devicePossessionToBeEdited, showMessage, superAdminDevicesResponse]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield refresh();
      } catch (error) {
        showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }))();
  }, [refresh, showMessage]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Devices" })
      }
    ),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, secondLevel: true, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({ display: "flex", justifyContent: "flex-start", width: "100%" }, spacing.mb20), { gap: 2 }), children: /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput != null ? searchInput : void 0,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            }
          }
        ) }),
        /* @__PURE__ */ jsx(
          CsvDownloader,
          {
            filename: "all_devices",
            separator: ",",
            datas: () => __async(void 0, null, function* () {
              return formatDevicePossessionForCsv();
            }),
            disabled: devicePossessions.length === 0,
            children: /* @__PURE__ */ jsxs(
              Box,
              {
                sx: __spreadProps(__spreadValues({
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.g5
                }, secondaryCTABtn), {
                  cursor: "pointer",
                  height: "20px"
                }),
                children: [
                  /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                  " ",
                  /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Export" })
                ]
              }
            )
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        BasicServerTable,
        {
          rowData: tableData,
          columnData,
          rowClick: (row) => {
            setDevicePossessionToBeEdited(row.original);
            setIsEditDrawerOpen(true);
          },
          pagination,
          setPagination,
          totalPages,
          totalItems
        }
      ),
      devicePossessionToBeEdited && /* @__PURE__ */ jsx(
        SuperAdminOverviewDeviceDrawer,
        {
          isOpen: isEditDrawerOpen,
          setIsOpen: setIsEditDrawerOpen,
          devicePossession: devicePossessionToBeEdited,
          setDevicePossession: setDevicePossessionToBeEdited,
          userNames,
          matchings,
          sites,
          refresh
        }
      )
    ] })
  ] });
};
