"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { WidgetLayout } from "@v2/feature/dashboard/features/components/widget-layout.component";
import { WidgetInfoMessage } from "@v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { ATTENDANCE_ME_TIMESHEETS_ROUTE } from "@/lib/routes";
export const UserShiftHandlerWidgetContent = ({
  clockInEarlyCapMinutes,
  relevantDate,
  readOnly,
  shiftOrScheduledShift,
  theFollowingScheduledDay,
  isDayStarted,
  isDayEnded,
  isShiftEntryStarted,
  time,
  shiftLoggedHours,
  isStartingShiftEntry,
  isFetchingLocation,
  lessThanEarlyCapMinutesUntilStart,
  startShiftEntry,
  endShiftEntry,
  endDay,
  isEndingShiftEntry,
  currentType,
  isEndingDay,
  isLastEntryLogged
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly, size: "small", children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          width: "100%",
          cursor: "pointer"
        },
        onClick: () => routerHistory.push(ATTENDANCE_ME_TIMESHEETS_ROUTE),
        children: /* @__PURE__ */ jsxs(
          "div",
          {
            style: {
              display: "flex",
              flexDirection: "column",
              padding: spacing.p5
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AttendanceDomain.AttendanceShift.Shift") }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: format(relevantDate, "EEEE dd") })
            ]
          }
        )
      }
    ),
    currentType && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: currentType.name }),
    shiftOrScheduledShift ? /* @__PURE__ */ jsxs("div", { style: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(
        WidgetInfoMessage,
        {
          isScheduledForToday: shiftOrScheduledShift,
          scheduleDay: theFollowingScheduledDay,
          isStarted: isDayStarted,
          time,
          isEnded: isDayEnded,
          clockInEarlyCapMinutes
        }
      ),
      /* @__PURE__ */ jsxs("div", { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: "Schedule" }),
        /* @__PURE__ */ jsxs("div", { style: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
          shiftLoggedHours && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { minWidth: "70px", textAlign: "start" }, children: shiftLoggedHours[0] }),
          shiftLoggedHours && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { minWidth: "70px", textAlign: "start" }, children: shiftLoggedHours[1] }),
          shiftLoggedHours && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { minWidth: "70px", textAlign: "start" }, children: shiftLoggedHours[2] })
        ] })
      ] }),
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifyContent: "space-between",
            gap: spacing.g20
          },
          children: !isDayEnded && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, mt: spacing.m5, width: "100%" }, children: [
            !isShiftEntryStarted && /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("AttendanceDomain.AttendanceShift.Actions.Start"),
                loading: isStartingShiftEntry || isFetchingLocation,
                type: "button",
                fullWidth: true,
                onClick: startShiftEntry,
                disabled: !lessThanEarlyCapMinutesUntilStart,
                colorVariant: "primary",
                sizeVariant: "medium"
              }
            ),
            isDayStarted && isLastEntryLogged && /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("AttendanceDomain.AttendanceShift.Actions.CompleteDay"),
                loading: isEndingDay,
                type: "button",
                fullWidth: true,
                onClick: endDay,
                colorVariant: "danger",
                sizeVariant: "medium"
              }
            ),
            isShiftEntryStarted && /* @__PURE__ */ jsx(
              LoaderButton,
              {
                name: polyglot.t("AttendanceDomain.AttendanceShift.Actions.Finish"),
                loading: isEndingShiftEntry || isFetchingLocation,
                type: "button",
                fullWidth: true,
                onClick: endShiftEntry,
                colorVariant: "danger",
                sizeVariant: "medium"
              }
            )
          ] })
        }
      )
    ] }) : /* @__PURE__ */ jsx(
      "div",
      {
        style: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          verticalAlign: "center",
          height: "100%"
        },
        children: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "paragraph",
            sx: {
              color: themeColors.Grey
            },
            children: polyglot.t("AttendanceDomain.AttendanceShift.NotScheduled")
          }
        )
      }
    )
  ] }) });
};
