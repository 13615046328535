"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Popover, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NotificationModal = ({ isOpen, onClose, anchorEl, takeAction, message, callToAction }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    Popover,
    {
      open: isOpen,
      anchorEl,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right"
      },
      onClose,
      PaperProps: { sx: { boxShadow: "0px 2px 20px 0px #0D0D0E1A", border: "0px solid #e6eaed", width: 260 } },
      children: /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.pad20), children: [
        typeof message === "string" ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: message }) : /* @__PURE__ */ jsx(Box, { children: message }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: spacing.m10,
              gap: spacing.g10
            },
            children: [
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  onClick: (event) => {
                    onClose();
                    event == null ? void 0 : event.stopPropagation();
                  },
                  children: polyglot.has("NotificationModal.no") ? polyglot.t("NotificationModal.no") : "No"
                }
              ),
              /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  colorVariant: "primary",
                  sizeVariant: "small",
                  onClick: (event) => {
                    takeAction();
                    event == null ? void 0 : event.stopPropagation();
                  },
                  children: callToAction != null ? callToAction : polyglot.t("General.yes")
                }
              )
            ]
          }
        )
      ] })
    }
  );
};
