"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { SelectComponent } from "@v2/components/forms/select.component";
import { ApprovalRuleDrawer } from "@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useScopes from "@/hooks/scopes.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
const getApprovalRulesOptions = (approvalRules) => approvalRules.map((rule) => ({
  label: rule.name,
  value: rule.id
}));
export const ApprovalPage = ({ initialValues, onNext, approvalRules, refreshApprovalRules }) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);
  const [approvalRule, setApprovalRule] = useState(initialValues !== 0 ? initialValues : "");
  return /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, alignItems: "center", width: "100%" }, children: /* @__PURE__ */ jsxs(Stack, { sx: { width: "450px", gap: spacing.g30 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("ApprovalPage.approval") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ApprovalPage.approvalDesc") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "approvalRule",
        label: polyglot.t("ApprovalRuleModule.approvalRule"),
        options: getApprovalRulesOptions(approvalRules != null ? approvalRules : []),
        value: approvalRule,
        onChange: (e) => {
          const ruleId = e.target.value ? Number(e.target.value) : "";
          setApprovalRule(ruleId);
        },
        editList: {
          isHidden: !hasScopes(["company.settings:all"]),
          handler: () => {
            setIsApprovalDrawerOpen(true);
          }
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ApprovalRuleDrawer,
      {
        isOpen: isApprovalDrawerOpen,
        setIsOpen: setIsApprovalDrawerOpen,
        approvalRule: null,
        refresh: () => __async(void 0, null, function* () {
          if (refreshApprovalRules) yield refreshApprovalRules();
        })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "large",
        colorVariant: "primary",
        type: "button",
        fullWidth: true,
        onClick: () => {
          if (approvalRule) onNext(approvalRule);
        },
        disabled: !approvalRule,
        children: polyglot.t("General.continue")
      }
    ) })
  ] }) });
};
