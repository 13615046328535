"use strict";
export var CustomProfileFormType = /* @__PURE__ */ ((CustomProfileFormType2) => {
  CustomProfileFormType2["Basic"] = "basic";
  CustomProfileFormType2["Details"] = "details";
  CustomProfileFormType2["Family"] = "family";
  CustomProfileFormType2["About"] = "about";
  CustomProfileFormType2["Other"] = "other";
  CustomProfileFormType2["Role"] = "role";
  CustomProfileFormType2["Contract"] = "contract";
  CustomProfileFormType2["Lifecycle"] = "lifecycle";
  CustomProfileFormType2["IdentityCheck"] = "identity-check";
  CustomProfileFormType2["Salary"] = "salary";
  CustomProfileFormType2["TaxInformation"] = "taxInformation";
  CustomProfileFormType2["BankAccount"] = "bank-account";
  CustomProfileFormType2["Equity"] = "equity";
  CustomProfileFormType2["Address"] = "address";
  CustomProfileFormType2["Emergency"] = "emergency";
  CustomProfileFormType2["WorkContact"] = "work-contact";
  return CustomProfileFormType2;
})(CustomProfileFormType || {});
export var CustomProfileFieldType = /* @__PURE__ */ ((CustomProfileFieldType2) => {
  CustomProfileFieldType2["Input"] = "Input";
  CustomProfileFieldType2["Number"] = "Number";
  CustomProfileFieldType2["DateInput"] = "Date input";
  CustomProfileFieldType2["DatePicker"] = "Date picker";
  CustomProfileFieldType2["Select"] = "Select";
  CustomProfileFieldType2["Multiselect"] = "Multiselect";
  return CustomProfileFieldType2;
})(CustomProfileFieldType || {});
