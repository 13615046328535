"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { TabFilterButtons } from "@v2/components/tab-filter-buttons.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { CategoryFilters } from "@v2/components/table/category-filters.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortString } from "@v2/components/table/table-sorting.util";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { DeferEnrollmentDrawer } from "@v2/feature/benefits/subfeature/pension/components/defer-enrollment-drawer.component";
import { ManageEmployeeMembershipDrawer } from "@v2/feature/benefits/subfeature/pension/components/manage-employee-membership-drawer.component";
import { EmployeePensionEnrollmentStatus } from "@v2/feature/benefits/subfeature/pension/pension.interface";
import { getLastActionFromReport } from "@v2/feature/benefits/subfeature/pension/pension.util";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { filterStringToObject } from "@v2/feature/user/user.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx, tablePrimaryIconButtonSx } from "@v2/styles/icon-button.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { LocalDate } from "@v2/util/local-date";
import { keyBy } from "lodash";
import { useParams } from "react-router-dom";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { ReactComponent as WaitingFull } from "@/images/side-bar-icons/WaitingFull.svg";
import { PensionProviderCell } from "@/v2/feature/benefits/subfeature/pension/components/pension-provider-cell.component";
import { themeColors } from "@/v2/styles/colors.styles";
export function isLessThanXDaysInFuture(referenceDate, targetDate, Xdays) {
  const XDaysInMs = Xdays * 24 * 60 * 60 * 1e3;
  const timestampXDaysInFuture = new Date(referenceDate).getTime() + XDaysInMs;
  return timestampXDaysInFuture > targetDate.getTime();
}
const TabFilter = [
  { name: "All", value: "All" },
  { name: "Enrolled", value: "Enrolled" },
  { name: "Not enrolled", value: "NotEnrolled" }
];
export const EmployeeMemberships = ({
  userPensions,
  companyPensions,
  parentPensionSchemeId,
  refreshMemberships
}) => {
  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDeferralDrawerOpen, setIsDeferralDrawerOpen] = useState(false);
  const [selectedUserPension, setSelectedUserPension] = useState(null);
  const params = useParams();
  const pensionScheme = useMemo(() => {
    var _a;
    return params.pensionSchemeId ? (_a = companyPensions.find((p) => p.id === Number(params.pensionSchemeId))) != null ? _a : null : null;
  }, [params, companyPensions]);
  const [filterValue, setFilterValue] = useState("All");
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const pensionsByExternalId = useMemo(() => {
    return keyBy(companyPensions, "externalId");
  }, [companyPensions]);
  const entityFilterOptions = useMemo(() => {
    const payrollIds = [
      ...new Set(companyPensions.filter((p) => p.id === parentPensionSchemeId).map((p) => p.payrollId))
    ];
    return payrollIds.map((payrollId) => {
      var _a, _b, _c;
      const pension = companyPensions.find((p) => p.payrollId === payrollId);
      return {
        label: (_c = (_b = (_a = pension == null ? void 0 : pension.payroll) == null ? void 0 : _a.entity) == null ? void 0 : _b.legalName) != null ? _c : `PayrollID ${payrollId}`,
        value: payrollId
      };
    });
  }, [companyPensions, parentPensionSchemeId]);
  const { getCachedUserById } = useCachedUsers();
  const filteredMemberships = useMemo(() => {
    const payrollsWithPensions = new Set(companyPensions.map((p) => p.payrollId));
    let filteredMemberships2 = [...userPensions].filter(
      (p) => payrollsWithPensions.has(p.payrollId) && (!pensionScheme || !p.inPension || p.pensionProviderId === pensionScheme.externalId)
    );
    if (filterValue !== "All") {
      filteredMemberships2 = filteredMemberships2.filter((membership) => {
        if (filterValue === "Enrolled") return membership == null ? void 0 : membership.inPension;
        if (filterValue === "NotEnrolled") return !(membership == null ? void 0 : membership.inPension);
        return true;
      });
    }
    if (entityFilterOptions && filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions.entity) {
        filteredMemberships2 = filteredMemberships2.filter(
          (m) => m.payrollId && filterOptions.entity.includes(String(m.payrollId))
        );
      }
    }
    if (searchInput) {
      filteredMemberships2 = filteredMemberships2.filter((membership) => {
        var _a, _b, _c, _d;
        const search = searchInput.toLowerCase();
        const user = getCachedUserById(membership.userId);
        const pensionProvider = membership.pensionProviderId ? pensionsByExternalId[membership.pensionProviderId] : null;
        return (membership == null ? void 0 : membership.pensionProviderName) && membership.pensionProviderName.toLowerCase().includes(search) || ((_b = (_a = membership == null ? void 0 : membership.payroll) == null ? void 0 : _a.entity) == null ? void 0 : _b.legalName) && ((_d = (_c = membership == null ? void 0 : membership.payroll) == null ? void 0 : _c.entity) == null ? void 0 : _d.legalName.toLowerCase().includes(search)) || user && user.displayName.toLowerCase().includes(search) || (pensionProvider == null ? void 0 : pensionProvider.displayName) && (pensionProvider == null ? void 0 : pensionProvider.displayName.toLowerCase().includes(search));
      });
    }
    return filteredMemberships2;
  }, [
    companyPensions,
    userPensions,
    filterValue,
    entityFilterOptions,
    filterString,
    searchInput,
    pensionScheme,
    getCachedUserById,
    pensionsByExternalId
  ]);
  const currentUserColumn = useMemo(
    () => [
      {
        header: () => "Name",
        id: "name",
        accessorFn: (row) => {
          var _a;
          const user = row.userId ? getCachedUserById(row.userId) : null;
          return (_a = user == null ? void 0 : user.displayName) != null ? _a : "";
        },
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.pensionProviderName),
        cell: ({ row: { original } }) => {
          var _a;
          const user = original.userId ? getCachedUserById(original.userId) : null;
          return user ? /* @__PURE__ */ jsx("div", { children: polyglot.t((_a = user.displayName) != null ? _a : "") }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Scheme",
        id: "enrolledIn",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.pensionProviderName),
        cell: ({ row: { original } }) => original.pensionProviderName ? /* @__PURE__ */ jsx(PensionProviderCell, { providerName: original.pensionProviderName }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Display name",
        id: "displayName",
        accessorFn: (row) => {
          var _a;
          const pensionProvider = row.pensionProviderId ? pensionsByExternalId[row.pensionProviderId] : null;
          return (_a = pensionProvider == null ? void 0 : pensionProvider.displayName) != null ? _a : "";
        },
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          const pensionProvider = item.pensionProviderId ? pensionsByExternalId[item.pensionProviderId] : null;
          return (pensionProvider == null ? void 0 : pensionProvider.displayName) ? pensionProvider.displayName : null;
        }),
        cell: ({ row: { original } }) => {
          const pensionProvider = original.pensionProviderId ? pensionsByExternalId[original.pensionProviderId] : null;
          return (pensionProvider == null ? void 0 : pensionProvider.displayName) ? /* @__PURE__ */ jsx(Box, { children: pensionProvider.displayName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Entity",
        id: "entity",
        accessorFn: (row) => {
          var _a, _b, _c, _d;
          return ((_b = (_a = row == null ? void 0 : row.payroll) == null ? void 0 : _a.entity) == null ? void 0 : _b.legalName) ? polyglot.t((_d = (_c = row == null ? void 0 : row.payroll) == null ? void 0 : _c.entity) == null ? void 0 : _d.legalName) : `PayrollID: ${row.payrollId}`;
        },
        cell: ({ row: { original } }) => {
          var _a, _b, _c, _d;
          return original.payrollId ? /* @__PURE__ */ jsx("div", { children: ((_b = (_a = original == null ? void 0 : original.payroll) == null ? void 0 : _a.entity) == null ? void 0 : _b.legalName) ? polyglot.t((_d = (_c = original == null ? void 0 : original.payroll) == null ? void 0 : _c.entity) == null ? void 0 : _d.legalName) : `PayrollID: ${original.payrollId}` }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Auto-enrollment State",
        id: "aeState",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a, _b;
          return (_b = (_a = item == null ? void 0 : item.lastAssessment) == null ? void 0 : _a.employee) == null ? void 0 : _b.metadata.aeState;
        }),
        cell: ({ row: { original } }) => {
          var _a, _b, _c;
          return /* @__PURE__ */ jsx("div", { children: (_c = (_b = (_a = original == null ? void 0 : original.lastAssessment) == null ? void 0 : _a.employee) == null ? void 0 : _b.metadata.aeState) != null ? _c : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Eligibility",
        id: "eligibility",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => {
          var _a;
          return (_a = item == null ? void 0 : item.lastAssessment) == null ? void 0 : _a.status;
        }),
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original == null ? void 0 : original.lastAssessment) == null ? void 0 : _a.status) != null ? _b : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Last Action",
        id: "lastAction",
        accessorFn: (row) => getLastActionFromReport(row == null ? void 0 : row.lastAssessment),
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getLastActionFromReport(item == null ? void 0 : item.lastAssessment)),
        cell: ({ row: { original } }) => {
          const lastAction = getLastActionFromReport(original == null ? void 0 : original.lastAssessment);
          return lastAction ? /* @__PURE__ */ jsx(Box, { sx: { width: "150px" }, children: lastAction }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Status",
        id: "status",
        enableSorting: true,
        sortingFn: (a, b) => sortString(
          a,
          b,
          (item) => (item == null ? void 0 : item.inPension) ? EmployeePensionEnrollmentStatus.Enrolled : EmployeePensionEnrollmentStatus.NotEnrolled
        ),
        cell: ({ row: { original } }) => {
          const isInPension = !!(original == null ? void 0 : original.inPension);
          const isScheduledForRemoval = !isInPension && (original == null ? void 0 : original.lastAssessment) && new Date(original.lastAssessment.assessmentDate).getTime() > Date.now();
          return /* @__PURE__ */ jsxs(Box, { sx: { alignItems: "center", display: "flex", gap: 0.5 }, children: [
            isInPension ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : isScheduledForRemoval ? /* @__PURE__ */ jsx(WaitingFull, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.YellowPress } })) : /* @__PURE__ */ jsx(WaitingFull, __spreadValues({}, iconSize)),
            isInPension ? EmployeePensionEnrollmentStatus.Enrolled : isScheduledForRemoval ? `Leaving on ${new LocalDate(original.lastAssessment.assessmentDate).toDateString()}` : EmployeePensionEnrollmentStatus.NotEnrolled
          ] });
        }
      },
      {
        header: () => "Worker Group",
        id: "workerGroupName",
        accessorFn: (row) => row.workerGroupName,
        cell: ({ row: { original } }) => {
          return original.workerGroupName !== null ? /* @__PURE__ */ jsx("div", { children: original.workerGroupName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Employer",
        id: "employerContribution",
        cell: ({ row: { original } }) => {
          return original.employerContribution !== null ? /* @__PURE__ */ jsxs("div", { children: [
            original.employerContribution,
            "%"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Employee",
        id: "employeeContribution",
        cell: ({ row: { original } }) => {
          return original.employeeContribution !== null ? /* @__PURE__ */ jsxs("div", { children: [
            original.employeeContribution,
            "%"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                gap: spacing.gap5
              },
              children: [
                !original.inPension && /* @__PURE__ */ jsx(
                  StyledMenuComponent,
                  {
                    options: [
                      {
                        handler: () => {
                          setSelectedUserPension(original);
                          setIsDeferralDrawerOpen(true);
                        },
                        label: "Defer enrollment",
                        // disabled if user was already deferred/enrolled once
                        disabled: Boolean(
                          original.pensionProviderId || original.pensionProviderName || original.startDate || original.scheduledForEnrollment
                        )
                      }
                    ],
                    actionButtonDetails: {
                      type: "iconButton",
                      colorVariant: "secondary",
                      sizeVariant: "small",
                      title: "actions",
                      icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    disableRipple: true,
                    sx: !original.inPension ? tablePrimaryIconButtonSx : tableIconButtonSx,
                    onClick: () => {
                      setSelectedUserPension(original);
                      setIsDrawerOpen(true);
                    },
                    children: !original.inPension ? /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
                  }
                )
              ]
            }
          );
        }
      }
    ],
    [getCachedUserById, pensionsByExternalId, polyglot]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        },
        children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              filters: TabFilter,
              setFilterValue,
              filterValue,
              onFilterChange: ({ filterValue: filterValue2 }) => {
                setFilterValue(filterValue2);
              }
            }
          ),
          /* @__PURE__ */ jsx(
            CategoryFilters,
            {
              filterTypes: { entity: entityFilterOptions },
              setFilterString,
              filterString
            }
          ),
          /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
            var _a, _b;
            return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
          } })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { marginTop: spacing.m15 }, children: /* @__PURE__ */ jsx(BasicTable, { rowData: filteredMemberships, columnData: currentUserColumn, hidePagination: true }) }),
    isDrawerOpen && selectedUserPension && /* @__PURE__ */ jsx(
      ManageEmployeeMembershipDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        userPension: selectedUserPension,
        setUserPension: setSelectedUserPension,
        pensionSchemes: companyPensions,
        onClose: () => setSelectedUserPension(null),
        refresh: refreshMemberships
      }
    ),
    isDeferralDrawerOpen && selectedUserPension && /* @__PURE__ */ jsx(
      DeferEnrollmentDrawer,
      {
        isOpen: isDeferralDrawerOpen,
        setIsOpen: setIsDeferralDrawerOpen,
        userPension: selectedUserPension,
        onClose: () => setSelectedUserPension(null),
        refresh: refreshMemberships
      }
    )
  ] });
};
