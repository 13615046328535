"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { EditBillingContactDrawer } from "@v2/feature/billing/components/edit-billing-contact-drawer.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { capitalize } from "lodash";
import { BillingPaymentMethodAPI } from "@/api-client/billing-payment-method";
import { BillingAPI } from "@/api-client/billing.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { nestErrorMessage } from "@/lib/errors";
import { EMPTY_PAYMENT_METHOD } from "@/models/subscription.model";
const extractCardExpiry = (paymentMethod) => {
  if (!paymentMethod) return "";
  const { card } = paymentMethod;
  if (!card) return "";
  if (!card.exp_month || !card.exp_year) return "";
  return `${card.exp_month}/${card.exp_year}`;
};
export const BillingInformationSection = () => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isEditBillingContactOpen, setIsEditBillingContactOpen] = useState(false);
  const [billingInfo, setBillingInfo] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingContact, setBillingContact] = useState(null);
  const setPaymentInfo = useCallback((paymentMethods) => {
    if (!paymentMethods[0]) {
      setPaymentMethod(EMPTY_PAYMENT_METHOD);
    } else {
      setPaymentMethod(paymentMethods[0]);
    }
  }, []);
  const setBillingContactInfo = useCallback((billingInfo2) => {
    var _a2, _b2, _c2, _d2, _e, _f, _g, _h;
    const billingContact2 = billingInfo2 == null ? void 0 : billingInfo2.billingContact;
    const paymentMethod2 = billingInfo2 == null ? void 0 : billingInfo2.paymentMethods[0];
    if (!billingContact2 && !paymentMethod2) {
      setBillingContact(null);
      return;
    }
    const billingAddressFields = paymentMethod2 ? [
      (_a2 = paymentMethod2 == null ? void 0 : paymentMethod2.billingAddress) == null ? void 0 : _a2.line1,
      (_b2 = paymentMethod2 == null ? void 0 : paymentMethod2.billingAddress) == null ? void 0 : _b2.line2,
      (_c2 = paymentMethod2 == null ? void 0 : paymentMethod2.billingAddress) == null ? void 0 : _c2.postal_code,
      (_d2 = paymentMethod2 == null ? void 0 : paymentMethod2.billingAddress) == null ? void 0 : _d2.city,
      (_e = paymentMethod2 == null ? void 0 : paymentMethod2.billingAddress) == null ? void 0 : _e.state,
      (_f = paymentMethod2 == null ? void 0 : paymentMethod2.billingAddress) == null ? void 0 : _f.country
    ].filter(Boolean) : [];
    setBillingContact({
      userId: billingContact2 == null ? void 0 : billingContact2.userId,
      contactFullName: (billingContact2 == null ? void 0 : billingContact2.user) ? `${billingContact2.user.firstName} ${billingContact2.user.lastName}` : void 0,
      billingAddress: billingAddressFields.length > 0 ? billingAddressFields.join(", ") : void 0,
      invoicesSentTo: (_g = billingContact2 == null ? void 0 : billingContact2.invoiceEmail) != null ? _g : void 0,
      phoneNumber: (_h = billingContact2 == null ? void 0 : billingContact2.phoneNumber) != null ? _h : void 0
    });
  }, []);
  const fetchBillingInfo = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    try {
      const billingInfo2 = yield BillingAPI.getBillingInformation();
      setBillingInfo(billingInfo2);
      setBillingContactInfo(billingInfo2);
      setPaymentInfo((_a2 = billingInfo2 == null ? void 0 : billingInfo2.paymentMethods) != null ? _a2 : []);
    } catch (error) {
      showMessage(
        `${polyglot.t("BillingInformationSection.errorMessages.infoNotFetch")}. ${nestErrorMessage(error)}`,
        "error"
      );
    }
  }), [polyglot, setBillingContactInfo, setPaymentInfo, showMessage]);
  useEffect(() => {
    fetchBillingInfo();
  }, [fetchBillingInfo]);
  const getPaymentSetupLink = () => __async(void 0, null, function* () {
    try {
      const paymentSetupLink = yield BillingPaymentMethodAPI.getSetupLink();
      if (!(paymentSetupLink == null ? void 0 : paymentSetupLink.url)) throw new Error("Link not received");
      window.location.replace(paymentSetupLink.url);
    } catch (error) {
      const errorAsError = error;
      console.error("Failed to obtain payment method setup link", errorAsError.message);
      showMessage(
        `${polyglot.t("BillingInformationSection.errorMessages.setupSession")} ${nestErrorMessage(error) || errorAsError.message}`,
        "error"
      );
    }
  });
  return billingInfo ? /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "400px", bgcolor: themeColors.Background, p: spacing.p30, borderRadius: "10px" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("BillingInformationSection.billingInformation") }),
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                label: polyglot.t("BillingInformationSection.editContact"),
                handler: () => {
                  setIsEditBillingContactOpen(true);
                }
              },
              {
                label: polyglot.t("BillingInformationSection.updatePayment"),
                handler: getPaymentSetupLink
              }
            ],
            actionButtonDetails: {
              type: "iconButton",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "actions",
              icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g15, mt: spacing.m20 }, children: [
        (billingContact == null ? void 0 : billingContact.userId) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }), children: polyglot.t("BillingInformationSection.billingContact") }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5, width: "240px" }, children: [
            /* @__PURE__ */ jsx(UserAvatar, { userId: billingContact.userId, size: "s20" }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: billingContact.contactFullName })
          ] })
        ] }),
        (billingContact == null ? void 0 : billingContact.billingAddress) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }), children: polyglot.t("BillingInformationSection.billingAddress") }),
          /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "240px" }, children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: billingContact.billingAddress }) })
        ] }),
        (billingContact == null ? void 0 : billingContact.invoicesSentTo) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }), children: polyglot.t("BillingInformationSection.invoicesSentTo") }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { width: "240px" }), children: billingContact.invoicesSentTo })
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }), children: polyglot.t("BillingInformationSection.phoneNumber") }),
          (billingContact == null ? void 0 : billingContact.phoneNumber) ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { width: "240px" }), children: billingContact == null ? void 0 : billingContact.phoneNumber }) : /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.headline3), {
                width: "240px",
                color: themeColors.Red
              }),
              children: polyglot.t("BillingInformationSection.missingPhoneNumber")
            }
          )
        ] }),
        ((_a = paymentMethod == null ? void 0 : paymentMethod.card) == null ? void 0 : _a.last4) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }), children: polyglot.t("BillingInformationSection.cardNumber") }),
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { width: "240px" }), children: [
            "**** **** **** ",
            paymentMethod.card.last4
          ] })
        ] }),
        ((_b = paymentMethod == null ? void 0 : paymentMethod.card) == null ? void 0 : _b.brand) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }), children: polyglot.t("BillingInformationSection.paymentMethod") }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { width: "240px" }), children: capitalize(paymentMethod.card.brand) })
        ] }),
        ((_c = paymentMethod == null ? void 0 : paymentMethod.card) == null ? void 0 : _c.last4) && (billingContact == null ? void 0 : billingContact.contactFullName) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }), children: polyglot.t("BillingInformationSection.cardHolder") }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { width: "240px" }), children: billingContact.contactFullName })
        ] }),
        ((_d = paymentMethod == null ? void 0 : paymentMethod.card) == null ? void 0 : _d.exp_month) && paymentMethod.card.exp_month > -1 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g20 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { width: "140px" }) }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { width: "240px" }), children: extractCardExpiry(paymentMethod) })
        ] })
      ] })
    ] }),
    isEditBillingContactOpen && /* @__PURE__ */ jsx(
      EditBillingContactDrawer,
      {
        isOpen: isEditBillingContactOpen,
        setIsOpen: setIsEditBillingContactOpen,
        billingContact: billingInfo == null ? void 0 : billingInfo.billingContact,
        refresh: fetchBillingInfo
      }
    )
  ] }) : null;
};
