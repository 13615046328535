"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { AbsencePolicyAttachmentType } from "@v2/feature/absence/absence.dto";
import { AbsencePolicyAllowanceType } from "@v2/feature/absence/absence.interface";
import { SettingsSectionContent } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { PolicyGeneralEditDrawer } from "@v2/feature/absence/subfeatures/settings/policy-details/edit-drawers/policy-general-edit-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { AbsenceAPI } from "@/v2/feature/absence/absence.api";
import { AbsencePolicyArchiveConfirmationDrawer } from "@/v2/feature/absence/subfeatures/settings/components/absence-policy-archive-confirmation-drawer.component";
import { AbsencePolicyDeleteConfirmationDrawer } from "@/v2/feature/absence/subfeatures/settings/components/absence-policy-delete-confirmation-drawer.component";
import { AbsencePolicyUnarchiveConfirmationDrawer } from "@/v2/feature/absence/subfeatures/settings/components/absence-policy-unarchive-confirmation-drawer.component";
import { UpdatePolicyInstructionsDrawer } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/update-policy-instructions-drawer.component";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
function translateAttachmentValue(attachmentType, polyglot) {
  if (attachmentType === AbsencePolicyAttachmentType.Required) return polyglot.t("General.required");
  if (attachmentType === AbsencePolicyAttachmentType.Optional) return polyglot.t("General.optional");
  return polyglot.t("General.none");
}
export const AbsencePolicyGeneralSection = ({
  absencePolicy,
  refreshPolicy,
  refreshPolicies,
  refreshArchivedPolicies
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState(false);
  const [isArchiveDrawerOpen, setIsArchiveDrawerOpen] = useState(false);
  const [isUnarchiveDrawerOpen, setIsUnarchiveDrawerOpen] = useState(false);
  const [isUpdateInstructionsOpen, setIsUpdateInstructionsOpen] = useState(false);
  const [requestsCount, setRequestsCount] = useState(1);
  const [showMessage] = useMessage();
  const policyCyclePeriod = useMemo(() => {
    if (!absencePolicy) return "";
    const startDate = new Date(
      (/* @__PURE__ */ new Date()).getFullYear(),
      absencePolicy.cycleStartMonth - 1,
      absencePolicy.cycleStartDay
    );
    const endDate = new Date(startDate);
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate() - 1);
    const startDateString = startDate.toLocaleDateString(void 0, { month: "long", day: "numeric" });
    const endDateString = endDate.toLocaleDateString(void 0, { month: "long", day: "numeric" });
    return `${startDateString} - ${endDateString}`;
  }, [absencePolicy]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      if (!absencePolicy) {
        setRequestsCount(0);
        return;
      }
      try {
        const requestsCount2 = yield AbsenceAPI.getCountOfPolicyRequests(absencePolicy.id);
        setRequestsCount(requestsCount2);
      } catch (error) {
        showMessage(
          polyglot.t("PolicyGeneralEditDrawer.couldNotGetCount", {
            name: absencePolicy.name,
            nestError: nestErrorMessage(error)
          }),
          "error"
        );
      }
    }))();
  }, [polyglot, showMessage, absencePolicy]);
  const buttonsForInstructions = useMemo(() => {
    const buttons = [
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: tableIconButtonSx,
          onClick: () => {
            setIsUpdateInstructionsOpen(true);
          },
          title: polyglot.t("General.edit"),
          children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize))
        }
      )
    ];
    if (absencePolicy.instructions) {
      buttons.push(
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            onClick: () => __async(void 0, null, function* () {
              yield AbsenceAPI.deleteAbsencePolicyInstructionsById(absencePolicy.id);
              yield refreshPolicy();
              showMessage(polyglot.t("AbsencePolicyGeneralSection.successMessages.deleteInstruction"), "success");
            }),
            title: polyglot.t("General.remove"),
            children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
          }
        )
      );
    }
    return buttons;
  }, [absencePolicy.id, absencePolicy.instructions, polyglot, refreshPolicy, showMessage]);
  const showNegativeBalance = useMemo(() => {
    return [AbsencePolicyAllowanceType.Limited, AbsencePolicyAllowanceType.LimitedHourly].includes(
      absencePolicy.allowanceType
    );
  }, [absencePolicy.allowanceType]);
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: polyglot.t("AbsencePolicyGeneralSection.general"),
      onEdit: () => setIsEditDrawerOpen(true),
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyGeneralSection.color"),
                    value: /* @__PURE__ */ jsx(
                      Box,
                      {
                        sx: {
                          bgcolor: absencePolicy.color,
                          width: "20px",
                          height: "20px",
                          borderRadius: "10px"
                        }
                      }
                    )
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyGeneralSection.name"),
                    value: (_a = absencePolicy.fullName) != null ? _a : ""
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyGeneralSection.displayName"),
                    value: absencePolicy.name
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyGeneralSection.visibility"),
                    value: absencePolicy.isPublic ? polyglot.t("AbsencePolicyGeneralSection.public") : polyglot.t("AbsencePolicyGeneralSection.hidden")
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyGeneralSection.cyclePeriod"),
                    value: policyCyclePeriod
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyGeneralSection.attachment"),
                    value: translateAttachmentValue(absencePolicy.attachmentType, polyglot)
                  },
                  {
                    type: SectionItemType.Pair,
                    label: polyglot.t("AbsencePolicyGeneralSection.allowNegativeBalance"),
                    value: absencePolicy.allowNegativeBalance ? polyglot.t("AbsencePolicyGeneralSection.allow") : polyglot.t("AbsencePolicyGeneralSection.notAllow"),
                    hidden: !showNegativeBalance
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyGeneralSection.instructions"),
                buttons: buttonsForInstructions,
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: /* @__PURE__ */ jsx(
                      Typography,
                      {
                        variant: "paragraph",
                        color: absencePolicy.instructions ? "black" : "grey",
                        sx: { fontStyle: absencePolicy.instructions ? "italic" : "" },
                        children: (_b = absencePolicy.instructions) != null ? _b : polyglot.t("AbsencePolicyGeneralSection.instructionsEmpty")
                      }
                    )
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyGeneralSection.archivePolicy"),
                hidden: !!absencePolicy.deletedAt,
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                      polyglot.t("AbsencePolicyGeneralSection.archiveDescriptionPart1"),
                      /* @__PURE__ */ jsx(
                        "span",
                        {
                          onClick: () => {
                            setIsArchiveDrawerOpen(true);
                          },
                          style: { color: "red", textDecoration: "underline", cursor: "pointer" },
                          children: polyglot.t("AttendanceSchedule.here")
                        }
                      ),
                      polyglot.t("AbsencePolicyGeneralSection.archiveDescriptionPart2")
                    ] })
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyGeneralSection.deletePolicy"),
                hidden: !!absencePolicy.deletedAt,
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                      polyglot.t("AbsencePolicyGeneralSection.deletePolicyDescription"),
                      /* @__PURE__ */ jsx(
                        "span",
                        {
                          onClick: () => {
                            setIsDeleteDrawerOpen(true);
                          },
                          style: { color: "red", textDecoration: "underline", cursor: "pointer" },
                          children: polyglot.t("AttendanceSchedule.here")
                        }
                      )
                    ] })
                  }
                ]
              },
              {
                title: polyglot.t("AbsencePolicyUnarchiveConfirmationDrawer.unarchivePolicy"),
                hidden: !absencePolicy.deletedAt,
                items: [
                  {
                    type: SectionItemType.TextLine,
                    value: /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                      polyglot.t("AbsencePolicyGeneralSection.unarchiveDescriptionPart1"),
                      /* @__PURE__ */ jsx(
                        "span",
                        {
                          onClick: () => {
                            setIsUnarchiveDrawerOpen(true);
                          },
                          style: { color: "red", textDecoration: "underline", cursor: "pointer" },
                          children: polyglot.t("AttendanceSchedule.here")
                        }
                      )
                    ] })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          PolicyGeneralEditDrawer,
          {
            isOpen: isEditDrawerOpen,
            setIsOpen: setIsEditDrawerOpen,
            absencePolicy,
            refreshPolicy,
            refreshPolicies,
            refreshArchivedPolicies
          }
        ),
        /* @__PURE__ */ jsx(
          AbsencePolicyDeleteConfirmationDrawer,
          {
            isOpen: isDeleteDrawerOpen,
            setIsOpen: setIsDeleteDrawerOpen,
            absencePolicy,
            refresh: () => __async(void 0, null, function* () {
              yield Promise.all([refreshPolicy(), refreshPolicies()]);
            }),
            requestsCount
          }
        ),
        /* @__PURE__ */ jsx(
          AbsencePolicyArchiveConfirmationDrawer,
          {
            isOpen: isArchiveDrawerOpen,
            setIsOpen: setIsArchiveDrawerOpen,
            absencePolicy,
            refresh: () => __async(void 0, null, function* () {
              yield Promise.all([refreshPolicy(), refreshPolicies(), refreshArchivedPolicies()]);
            })
          }
        ),
        /* @__PURE__ */ jsx(
          AbsencePolicyUnarchiveConfirmationDrawer,
          {
            isOpen: isUnarchiveDrawerOpen,
            setIsOpen: setIsUnarchiveDrawerOpen,
            absencePolicy,
            refresh: () => __async(void 0, null, function* () {
              yield Promise.all([refreshPolicy(), refreshPolicies(), refreshArchivedPolicies()]);
            })
          }
        ),
        /* @__PURE__ */ jsx(
          UpdatePolicyInstructionsDrawer,
          {
            isOpen: isUpdateInstructionsOpen,
            onClose: () => setIsUpdateInstructionsOpen(false),
            setIsOpen: setIsUpdateInstructionsOpen,
            absencePolicy,
            refresh: () => __async(void 0, null, function* () {
              yield refreshPolicy();
            })
          }
        )
      ]
    }
  );
};
