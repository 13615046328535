"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { CustomCountryEnum } from "@v2/infrastructure/country/country.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { ContractsAPI } from "@/api-client/contracts.api";
import { UserContractSchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { DateLabelComponent, getDateString } from "@/v2/components/forms/date-label.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { sortCustomFields } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { getCountryNameForCode } from "@/v2/feature/payments/payments.util";
import { useProfileFormUpdate } from "@/v2/feature/user/context/user-profile.context";
import {
  CardStructure,
  FieldStructure
} from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import {
  calculateSkeletonHeight,
  cardSx,
  definitionListSx,
  definitionValueSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { ContractForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-contract-form.component";
import {
  buildColumnsForCustomFields,
  getTranslatedFieldValue
} from "@/v2/feature/user/features/user-profile/details/user-profile.util";
import {
  translategetContractTypeOptions,
  translategetEmploymentTypeOptions
} from "@/v2/infrastructure/i18n/translate.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const skeletonHeight = (polyglot) => calculateSkeletonHeight(UserContractSchema(polyglot));
const SCOPE = "user.contract:all";
const iconSize = { width: 14, height: 14 };
export const UserContractCard = ({
  userId,
  // missingFields = [],
  handleSubmit = () => {
  }
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowModalMode, setRowModalMode] = useState("add");
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState();
  const setFormCurrentlyEditing = useProfileFormUpdate();
  const [modalInitialValues, setModalInitialValues] = useState(null);
  const loadInitialData = useCallback(() => __async(void 0, null, function* () {
    var _a;
    if (!userId) return;
    setLoading(true);
    try {
      const userContracts = yield ContractsAPI.findByUserId(userId);
      setData(userContracts);
      if (!((_a = userContracts == null ? void 0 : userContracts.entries) == null ? void 0 : _a.length)) setShowDetails(false);
    } catch (error) {
      showMessage(polyglot.t("UserContractCard.errorMessages.load"), "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage, userId, polyglot]);
  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);
  const editContractRow = useCallback((rowData) => {
    setModalInitialValues(rowData);
    setRowModalMode("edit");
    setIsModalOpen(true);
  }, []);
  const addContractRow = useCallback(() => {
    setModalInitialValues(null);
    setRowModalMode("add");
    setIsModalOpen(true);
  }, []);
  const sortByEffectiveDate = useCallback(
    (entries, sortOrder = "oldest-last") => {
      const sorted = [...entries].sort(
        (a, b) => b.effectiveDate.localeCompare(a.effectiveDate) || b.createdAt.localeCompare(a.createdAt) || b.id - a.id
      );
      if (sortOrder === "oldest-first") sorted.reverse();
      return sorted;
    },
    []
  );
  const nextContractStart = useMemo(() => {
    var _a;
    if (!data) return void 0;
    const { effectiveRecord, entries } = data;
    const sortedEntries = sortByEffectiveDate(entries);
    const effectiveRecordIdx = sortedEntries.findIndex(({ id }) => id === (effectiveRecord == null ? void 0 : effectiveRecord.id));
    return (_a = sortedEntries[effectiveRecordIdx - 1]) == null ? void 0 : _a.effectiveDate;
  }, [data, sortByEffectiveDate]);
  const getHolidayCalendarName = useCallback((contract) => {
    var _a;
    if (!contract.publicHolidays) return null;
    const country = getCountryNameForCode(contract.publicHolidays);
    if (!country) return null;
    if (country !== CustomCountryEnum.code) return country;
    return ((_a = contract.holidayCalendar) == null ? void 0 : _a.name) ? contract.holidayCalendar.name : null;
  }, []);
  const columnData = useMemo(
    () => [
      {
        header: () => polyglot.t("UserContractCard.employmentContract"),
        accessorFn: identity,
        id: "contract",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.contract ? original.contract : "" })
      },
      {
        header: () => polyglot.t("UserContractCard.workerType"),
        accessorFn: identity,
        id: "workerType",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: translategetEmploymentTypeOptions(original.type ? original.type : "", polyglot) })
      },
      {
        header: () => polyglot.t("UserContractCard.publicHolidays"),
        accessorFn: identity,
        id: "publicHolidays",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const holidayCalendar = getHolidayCalendarName(original);
          return holidayCalendar ? /* @__PURE__ */ jsx("div", { children: holidayCalendar }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("UserContractCard.entity"),
        accessorFn: identity,
        id: "entity",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original.entity) == null ? void 0 : _a.legalName) != null ? _b : "" });
        }
      },
      {
        header: () => polyglot.t("UserContractCard.attendanceScheduleId"),
        accessorFn: identity,
        id: "attendanceScheduleId",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: (_b = (_a = original.attendanceSchedule) == null ? void 0 : _a.name) != null ? _b : "" });
        }
      },
      {
        header: () => polyglot.t("UserContractCard.changeReason"),
        accessorFn: identity,
        id: "changeReason",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.changeReason ? original.changeReason : "" })
      },
      {
        header: () => polyglot.t("UserContractCard.probationPeriod"),
        accessorFn: identity,
        id: "probationPeriod",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsxs("div", { children: [
            (_a = original.probationPeriodLength) != null ? _a : "",
            " ",
            (_b = original.probationPeriodUnit) != null ? _b : ""
          ] });
        }
      },
      {
        header: () => polyglot.t("UserContractCard.noticePeriod"),
        accessorFn: identity,
        id: "noticePeriod",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsxs("div", { children: [
            (_a = original.noticePeriodLength) != null ? _a : "",
            " ",
            (_b = original.noticePeriodUnit) != null ? _b : ""
          ] });
        }
      },
      ...buildColumnsForCustomFields(data == null ? void 0 : data.entries, { size: 80 }),
      {
        header: () => polyglot.t("UserContractCard.contractEndDate"),
        accessorFn: identity,
        id: "contractEndDate",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.contractEndDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: original.contractEndDate }) : "" })
      },
      {
        header: () => polyglot.t("UserContractCard.effectiveDate"),
        accessorFn: identity,
        id: "effectiveDate",
        enableSorting: false,
        cell: ({
          row: {
            original: { effectiveDate }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: 0.5 }, children: effectiveDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveDate }) : "-" })
      },
      {
        header: () => "",
        accessorFn: identity,
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            title: "edit",
            onClick: () => {
              setRowModalMode("edit");
              editContractRow(original);
            },
            sx: tableIconButtonSx,
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) }) })
      }
    ],
    [data == null ? void 0 : data.entries, polyglot, getHolidayCalendarName, scopesContext, editContractRow]
  );
  return pipe(
    data ? O.some(data) : O.none,
    O.fold(
      () => /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          height: skeletonHeight(polyglot),
          sx: [cardSx, { borderRadius: 1, backgroundColor: themeColors.Background }]
        }
      ),
      ({ entries, effectiveRecord }) => {
        var _a, _b, _c;
        return /* @__PURE__ */ jsx(
          CardStructure,
          {
            cardTitle: polyglot.t("UserContractCard.contract"),
            cardScope: [SCOPE],
            showDetails,
            setShowDetails,
            userId,
            addAction: addContractRow,
            editAction: effectiveRecord && (() => {
              setRowModalMode("edit");
              editContractRow(effectiveRecord);
            }),
            showMissingInfo: !effectiveRecord,
            cardFieldCustomUpdates: effectiveRecord == null ? void 0 : effectiveRecord.customUpdates,
            cardFieldStubs: [
              "contract.effectiveDate",
              "contract.contract",
              "contract.attendanceSchedule",
              "contract.publicHolidays",
              "contract.entity",
              "contract.noticePeriod",
              "contract.probationPeriod",
              "contract.contractEndDate"
            ],
            cardFieldDetails: effectiveRecord && /* @__PURE__ */ jsxs(Box, { component: "dl", sx: definitionListSx, children: [
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.workerType"),
                  fieldValue: translategetEmploymentTypeOptions(effectiveRecord.type, polyglot),
                  fieldStub: "contract.effectiveDate"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.employmentContract"),
                  fieldValue: translategetContractTypeOptions((_a = effectiveRecord.contract) != null ? _a : "", polyglot),
                  fieldStub: "contract.contract"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.attendanceScheduleId"),
                  fieldValue: (_b = effectiveRecord.attendanceSchedule) == null ? void 0 : _b.name,
                  fieldStub: "contract.attendanceSchedule"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.publicHolidays"),
                  fieldValue: getHolidayCalendarName(effectiveRecord),
                  fieldStub: "contract.publicHolidays"
                }
              ),
              /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.entity"),
                  fieldValue: (_c = effectiveRecord.entity) == null ? void 0 : _c.legalName,
                  fieldStub: "contract.entity"
                }
              ),
              typeof effectiveRecord.probationPeriodLength === "number" && /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.probationPeriod"),
                  fieldValue: `${effectiveRecord.probationPeriodLength} ${effectiveRecord.probationPeriodUnit}`,
                  fieldStub: "contract.probationPeriod"
                }
              ),
              typeof effectiveRecord.noticePeriodLength === "number" && /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.noticePeriod"),
                  fieldValue: `${effectiveRecord.noticePeriodLength} ${effectiveRecord.noticePeriodUnit}`,
                  fieldStub: "contract.noticePeriod"
                }
              ),
              sortCustomFields(effectiveRecord.customUpdates).map(
                (f) => !f.field.isHidden && /* @__PURE__ */ jsx(
                  FieldStructure,
                  {
                    fieldTitle: f.field.fieldName,
                    fieldValue: getTranslatedFieldValue(f.value, polyglot)
                  },
                  f.field.fieldId
                )
              ),
              !!effectiveRecord.contractEndDate && /* @__PURE__ */ jsx(
                FieldStructure,
                {
                  fieldTitle: polyglot.t("UserContractCard.contractEndDate"),
                  fieldValue: /* @__PURE__ */ jsx(DateLabelComponent, { date: effectiveRecord.contractEndDate, titleSx: definitionValueSx }),
                  fieldStub: "contract.contractEndDate"
                }
              ),
              nextContractStart && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt10 }), children: [
                polyglot.t("UserContractCard.newContractMessage"),
                " ",
                getDateString(nextContractStart)
              ] })
            ] }),
            drawerDetails: /* @__PURE__ */ jsx(ScopesControl, { scopes: [SCOPE], context: scopesContext, children: /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, children: /* @__PURE__ */ jsx(
              ContractForm,
              {
                initialValues: modalInitialValues,
                isEffectiveRecord: (modalInitialValues == null ? void 0 : modalInitialValues.id) === (effectiveRecord == null ? void 0 : effectiveRecord.id),
                userId,
                loadInitialData,
                rowModalMode,
                handleSubmit,
                setFormCurrentlyEditing,
                setIsModalOpen,
                setLoading,
                loading,
                onClose: () => setIsModalOpen(false)
              }
            ) }) }),
            addButtonTitle: polyglot.t("UserContractCard.newContract"),
            tableRowData: sortByEffectiveDate(entries, "oldest-last"),
            tableColumn: columnData
          }
        );
      }
    )
  );
};
