"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
export const DeleteDrawer = ({
  title,
  description,
  isOpen,
  setIsOpen,
  loading,
  action,
  onClose,
  afterClose
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: title }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: description }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          fullWidth: true,
          disabled: loading,
          onClick: () => {
            setIsOpen(false);
            onClose == null ? void 0 : onClose();
          },
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          fullWidth: true,
          type: "button",
          sizeVariant: "medium",
          colorVariant: "danger",
          name: polyglot.t("General.delete"),
          loading,
          onClick: () => __async(void 0, null, function* () {
            return yield action();
          })
        }
      )
    ] })
  ] }) });
};
