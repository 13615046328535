"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { addDays, getISOWeek, isMonday, isToday, previousMonday } from "date-fns";
import { useHistory } from "react-router-dom";
import { ATTENDANCE_ME_TIMESHEETS_ROUTE } from "@/lib/routes";
import { PlusOneNextDayComponent } from "@/v2/components/plus-one-next-day.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ScheduleTrackingType, WeekDayNames } from "@/v2/feature/attendance/attendance.interface";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { UserAttendanceViewDrawer } from "@/v2/feature/dashboard/features/sections/user-attendance/components/user-attendance-view-drawer.component";
import "@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.scss";
import {
  calculateSummary,
  getAttendanceEntry,
  getDayWithDateArray,
  getMondayOfCurrentWeek,
  getWorkTimeRangeFromScheduleByDay
} from "@/v2/feature/dashboard/features/sections/user-attendance/user-attendance.util";
import { DaysOptions } from "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserAttendance = ({
  attendanceData,
  readOnly = false,
  refreshWidgetData,
  shiftWidgetData
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const { attendanceSchedule, currentWeekAttendanceEntries, companyAttendanceSettings } = attendanceData;
  const weekStart = getMondayOfCurrentWeek();
  const monday = useMemo(
    () => isMonday(new LocalDate().getDate()) ? new LocalDate().getDate() : previousMonday(new LocalDate().getDate()),
    []
  );
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const currentWeekNo = useMemo(() => getISOWeek(weekStart), [weekStart]);
  const getTodaySetting = (day, companyAttendanceSettings2) => {
    const dayToSettingMap = {
      monday: "workingMonday",
      tuesday: "workingTuesday",
      wednesday: "workingWednesday",
      thursday: "workingThursday",
      friday: "workingFriday",
      saturday: "workingSaturday",
      sunday: "workingSunday"
    };
    const settingKey = dayToSettingMap[day];
    return companyAttendanceSettings2[settingKey];
  };
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly, size: "big", children: /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          width: "100%",
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          cursor: "pointer"
        },
        onClick: () => routerHistory.push(ATTENDANCE_ME_TIMESHEETS_ROUTE),
        children: /* @__PURE__ */ jsx("div", { style: { padding: spacing.p5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserAttendance.attendance") }) })
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g3, justifyContent: "space-between", overflowX: "auto" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "50px", width: "100%", pt: spacing.p10, pb: spacing.p10 }, children: /* @__PURE__ */ jsxs(
        "div",
        {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            textAlign: "left",
            gap: spacing.g15,
            paddingRight: spacing.p5,
            paddingLeft: spacing.p5
          },
          children: [
            /* @__PURE__ */ jsxs("div", { style: { width: "40px", height: "40px" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserAttendance.week") }),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: currentWeekNo })
            ] }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserAttendance.start") }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserAttendance.end") }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserAttendance.totalHours") })
          ]
        }
      ) }),
      WeekDayNames.map((day, idx) => {
        const currentDate = addDays(monday, idx);
        const dayAttendanceEntries = getAttendanceEntry(currentWeekAttendanceEntries, currentDate);
        const hasLoggedEntries = dayAttendanceEntries.length > 0;
        let loggedHoursByType = {
          workEntries: ["", ""],
          hasOvernightEntry: false,
          totalLength: ""
        };
        if (hasLoggedEntries) loggedHoursByType = calculateSummary(dayAttendanceEntries, currentDate, polyglot);
        const workSchedule = (
          // [start, end, totalLength, isOvernight]
          attendanceSchedule && hasLoggedEntries && dayAttendanceEntries ? [
            loggedHoursByType.workEntries[0],
            loggedHoursByType.workEntries[1],
            loggedHoursByType.totalLength,
            loggedHoursByType.hasOvernightEntry
          ] : attendanceSchedule ? getWorkTimeRangeFromScheduleByDay(
            attendanceSchedule,
            day,
            new LocalDate(currentDate).toDateString(),
            polyglot
          ) : ["", "", "", false]
        );
        const [dayStart, dayEnd, dayLength, dayHasOvernightEntry] = workSchedule != null ? workSchedule : ["", "", "", false];
        const itsToday = isToday(currentDate);
        const [dayString, dateNumber] = getDayWithDateArray(weekStart, day);
        const isZeroHoursSchedule = attendanceSchedule.totalTime === 0 && getTodaySetting(day, companyAttendanceSettings);
        const isClickable = isZeroHoursSchedule || workSchedule;
        return /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              bgcolor: isClickable ? themeColors.white : "transparent",
              "&:hover": {
                background: isClickable || attendanceSchedule.trackingType === ScheduleTrackingType.Regular ? themeColors.GreyPress : "transparent",
                cursor: isClickable || attendanceSchedule.trackingType === ScheduleTrackingType.Regular ? "pointer" : "default"
              },
              borderRadius: "8px",
              pt: spacing.p10,
              pb: spacing.p10,
              width: "100%"
            },
            onClick: isClickable || attendanceSchedule.trackingType === ScheduleTrackingType.Regular ? () => {
              setIsViewOpen(true);
              setSelectedDate(new LocalDate(currentDate).toDateString());
            } : void 0,
            children: /* @__PURE__ */ jsxs(
              "div",
              {
                style: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: spacing.g15,
                  paddingRight: spacing.p5,
                  paddingLeft: spacing.p5
                },
                children: [
                  /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: __spreadValues({
                        width: "40px",
                        height: "40px"
                      }, isClickable ? { paddingRight: spacing.p5, paddingLeft: spacing.p5 } : {}),
                      children: [
                        /* @__PURE__ */ jsx(
                          Typography,
                          {
                            variant: isClickable ? "headline3" : "paragraph",
                            sx: {
                              textAlign: "left",
                              color: isClickable ? themeColors.DarkGrey : themeColors.Grey,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden"
                            },
                            children: DaysOptions(polyglot)[dayString]
                          }
                        ),
                        /* @__PURE__ */ jsx(
                          Typography,
                          {
                            variant: isClickable ? "headline3" : "paragraph",
                            sx: {
                              display: "inline",
                              textAlign: "left",
                              backgroundColor: itsToday ? themeColors.DarkGrey : "transparent",
                              color: itsToday ? themeColors.white : isClickable ? themeColors.DarkGrey : themeColors.Grey,
                              borderRadius: radius.br10,
                              paddingLeft: itsToday ? spacing.p5 : 0,
                              paddingRight: itsToday ? spacing.p5 : 0
                            },
                            children: dateNumber
                          }
                        )
                      ]
                    }
                  ),
                  workSchedule || isZeroHoursSchedule ? /* @__PURE__ */ jsxs(Fragment, { children: [
                    /* @__PURE__ */ jsx(
                      Typography,
                      {
                        variant: "paragraph",
                        sx: {
                          textAlign: "left",
                          paddingRight: spacing.p5,
                          paddingLeft: spacing.p5,
                          color: hasLoggedEntries && dayAttendanceEntries ? themeColors.DarkGrey : themeColors.Grey
                        },
                        children: workSchedule ? dayStart : isZeroHoursSchedule ? "\u2014" : polyglot.t("AttendanceDomain.notScheduled")
                      }
                    ),
                    /* @__PURE__ */ jsxs(
                      Typography,
                      {
                        variant: "paragraph",
                        sx: {
                          textAlign: "left",
                          paddingRight: spacing.p5,
                          paddingLeft: spacing.p5,
                          color: hasLoggedEntries && dayAttendanceEntries ? themeColors.DarkGrey : themeColors.Grey
                        },
                        children: [
                          workSchedule ? dayEnd : isZeroHoursSchedule ? "\u2014" : polyglot.t("AttendanceDomain.notScheduled"),
                          dayHasOvernightEntry && /* @__PURE__ */ jsx(PlusOneNextDayComponent, {})
                        ]
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      Typography,
                      {
                        variant: "paragraph",
                        sx: {
                          textAlign: "left",
                          paddingRight: spacing.p5,
                          paddingLeft: spacing.p5,
                          color: hasLoggedEntries && dayAttendanceEntries ? themeColors.DarkGrey : themeColors.Grey
                        },
                        children: workSchedule ? dayLength : isZeroHoursSchedule ? "\u2014" : ""
                      }
                    )
                  ] }) : /* @__PURE__ */ jsx(
                    Typography,
                    {
                      variant: "paragraph",
                      sx: {
                        color: themeColors.Grey,
                        wordBreak: "break-word",
                        textAlign: "left"
                      },
                      children: workSchedule ? dayStart : polyglot.t("AttendanceDomain.notWorking")
                    }
                  )
                ]
              }
            )
          },
          day
        );
      })
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isViewOpen, setIsOpen: setIsViewOpen, children: /* @__PURE__ */ jsx(
      UserAttendanceViewDrawer,
      {
        selectedDate,
        attendanceSchedule,
        refreshWidgetData,
        setIsViewOpen,
        shiftWidgetData
      }
    ) })
  ] }) });
};
