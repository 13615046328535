"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { generatePath, useHistory } from "react-router-dom";
import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from "@/lib/routes";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import {
  formatDueDateMessage,
  formatPayrunPeriod
} from "@/v2/feature/payroll/features/payroll-company/payroll-i18n.util";
import { navigateToPayrunsView } from "@/v2/feature/payroll/payroll-router.util";
import { PayrollAPI, PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const PayrollCompanyListPayrolls = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [loadingPayroll, setLoadingPayroll] = useState();
  const { data } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });
  const payrolls = data == null ? void 0 : data.items;
  const viewPayrunsForPayroll = useCallback(
    (payrollId) => {
      navigateToPayrunsView(routerHistory, "push", payrollId);
    },
    [routerHistory]
  );
  const onPayrollStartClick = useCallback(
    (payrollId) => __async(void 0, null, function* () {
      setLoadingPayroll(payrollId);
      try {
        const payrolls2 = yield PayrollAPI.getCompanyPayrolls();
        const payroll = payrolls2.items.find((p) => p.id === payrollId);
        if (!payroll) {
          throw new Error(polyglot.t("CompanyPayrollList.payrollNotFound"));
        }
        if (payroll.currentPayRun) {
          viewPayrunsForPayroll(payroll.id);
          return;
        }
        if (payroll.nextPayRun) {
          viewPayrunsForPayroll(payroll.id);
          return;
        }
        routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId }));
      } catch (e) {
        setLoadingPayroll(void 0);
      }
    }),
    [routerHistory, viewPayrunsForPayroll, polyglot]
  );
  const loading = !payrolls;
  const getPayrun = (payrun, activePaySchedule) => {
    if (payrun) return formatDueDateMessage(payrun.paymentDate, polyglot);
    if (activePaySchedule) return formatDueDateMessage(activePaySchedule.firstPaymentDate, polyglot);
    return [void 0, false];
  };
  return /* @__PURE__ */ jsx(ContentWrapper, { loading, sx: { px: "40px" }, children: /* @__PURE__ */ jsx(SettingsSectionContent, { title: polyglot.t("CompanyPayroll.salary"), contentWidth: "100%", headerWidth: "100%", children: /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      loadingAll: loading,
      sections: [
        {
          contentWidth: "100%",
          items: [
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (payrolls || []).map((item) => {
                var _a;
                const activePaySchedule = item.schedule;
                const payrun = (_a = item.currentPayRun) != null ? _a : item.nextPayRun;
                const [dueDateMessage, isOverdue] = getPayrun(payrun, activePaySchedule);
                return /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: item.displayName,
                    boxSx: responsiveCardStyle,
                    boxAction: () => {
                      onPayrollStartClick(item.id);
                    },
                    boxActionDisabled: !!loadingPayroll,
                    contentItemsSets: [
                      {
                        name: polyglot.t("CompanyPayroll.noOfEmployees", {
                          smart_count: item.employeeCount
                        }),
                        type: ContentItemType.chip,
                        textColor: "DarkGrey",
                        backgroundColor: "white",
                        border: "middle"
                      },
                      ...dueDateMessage ? [
                        {
                          name: dueDateMessage,
                          type: ContentItemType.chip,
                          textColor: isOverdue ? "Red" : "DarkGrey",
                          backgroundColor: "white",
                          border: "middle"
                        }
                      ] : [],
                      ...!activePaySchedule ? [
                        {
                          name: polyglot.t("CompanyPayrollList.noPayrollSchedule"),
                          type: ContentItemType.chip,
                          textColor: "DarkGrey",
                          backgroundColor: "white",
                          border: "middle"
                        }
                      ] : [],
                      ...payrun ? [
                        {
                          name: formatPayrunPeriod(payrun, polyglot.locale()),
                          type: ContentItemType.chip,
                          textColor: "DarkGrey",
                          backgroundColor: "white",
                          border: "middle"
                        }
                      ] : [],
                      ...!payrun && activePaySchedule ? [
                        {
                          name: formatPayrunPeriod(activePaySchedule, polyglot.locale()),
                          type: ContentItemType.chip,
                          textColor: "DarkGrey",
                          backgroundColor: "white",
                          border: "middle"
                        }
                      ] : []
                    ]
                  },
                  item.displayName
                );
              }) })
            }
          ]
        }
      ]
    }
  ) }) });
};
