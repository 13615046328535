"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ReactComponent as BasketBig } from "@/images/app-intro/BasketBig.svg";
import { ReactComponent as BenefitsBig } from "@/images/app-intro/BenefitsBig.svg";
import { ReactComponent as Centre } from "@/images/app-intro/Centre.svg";
import { ReactComponent as NotiBig } from "@/images/app-intro/NotiBig.svg";
import { ReactComponent as OnboardingBig } from "@/images/app-intro/OnboardingBig.svg";
import { APP_STORE_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  IntroCardGroup
} from "@/v2/components/theme-components/icon-content-action-card.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const APPS_EMPTY_STATE_FEATURE_LIST = [
  {
    iconMedia: /* @__PURE__ */ jsx(Centre, { width: 40, height: 40 }),
    title: "Central access control",
    content: "Create and remove accounts and modify permissions in real-time and or schedule future changes with one click."
  },
  {
    iconMedia: /* @__PURE__ */ jsx(BasketBig, { width: 40, height: 40 }),
    title: "Remove unused accounts",
    content: "Identify accounts that do not belong to an employee or show no recent activity. Integrate via API and delete accounts in apps programmatically."
  },
  {
    iconMedia: /* @__PURE__ */ jsx(OnboardingBig, { width: 40, height: 40 }),
    title: "On and offboard",
    content: "Select when and which apps should be ready for your new joiner. "
  },
  {
    iconMedia: /* @__PURE__ */ jsx(BenefitsBig, { width: 40, height: 40 }),
    title: "Data-driven company data",
    content: "Extract KPIs from your workplace tools to understand the usage better. "
  },
  {
    iconMedia: /* @__PURE__ */ jsx(NotiBig, { width: 40, height: 40 }),
    title: "Self-service access",
    content: "Your team can request access to any connected app. App owners just need to approve and Zelt creates the account."
  }
];
export const AppIntroduction = () => {
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { maxWidth: "600px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: "Get more value out of your favourite workplace tools by connecting them to Zelt. Manage access centrally, streamline access requests and understand how your team is using your apps." }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          style: { marginTop: spacing.m30 },
          onClick: () => routerHistory.push(APP_STORE_ROUTE),
          children: "Connect"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(IntroCardGroup, { cards: APPS_EMPTY_STATE_FEATURE_LIST, sx: { mt: "auto" } })
  ] });
};
