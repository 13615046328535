"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { InsurancePolicyDocuments } from "@v2/feature/benefits/subfeature/insurance/components/insurance-policy-documents.component";
import { InsuranceProviderDetails } from "@v2/feature/benefits/subfeature/insurance/components/insurance-provider-details.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { UserInsurancePolicyStatus } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { generatePath, matchPath, useLocation } from "react-router-dom";
import { ScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { BENEFITS_ME_ROUTE, USER_BENEFITS_OVERVIEW_ROUTE, USER_BENEFITS_ROUTE } from "@/lib/routes";
export const InsuranceMeOverviewPage = ({ userId, userInsurance, refresh, loading }) => {
  var _a;
  const [showMessage] = useMessage();
  const location = useLocation();
  const [isOptOutLoading, setIsOptOutLoading] = useState(false);
  const optOut = useCallback(() => __async(void 0, null, function* () {
    if (!userInsurance || userInsurance.status !== UserInsurancePolicyStatus.Active) return;
    try {
      setIsOptOutLoading(true);
      yield InsuranceAPI.optOutFromInsurancePolicy(userInsurance.userId, userInsurance.policyId);
      yield refresh();
    } catch (error) {
      showMessage(`Something went wrong. Could not opt out. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsOptOutLoading(false);
    }
  }), [refresh, userInsurance, showMessage]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Health insurance" }),
        showAction: true,
        actions: (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.Active ? /* @__PURE__ */ jsx(ScopesControl, { scopes: ["insurance"], context: { userId }, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: "Opt out",
            loading: isOptOutLoading,
            sizeVariant: "small",
            colorVariant: "secondary",
            onClick: optOut
          }
        ) }) : null,
        showBack: true,
        backPath: matchPath(location.pathname, USER_BENEFITS_ROUTE) ? generatePath(USER_BENEFITS_OVERVIEW_ROUTE, { userId }) : BENEFITS_ME_ROUTE
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      (userInsurance == null ? void 0 : userInsurance.status) === UserInsurancePolicyStatus.PendingOptOut && /* @__PURE__ */ jsx(Typography, { variant: "headline2", color: "Grey", children: "Pending Opt Out" }),
      (userInsurance == null ? void 0 : userInsurance.policy) && /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb40 }, children: /* @__PURE__ */ jsx(InsuranceProviderDetails, { insurancePolicy: userInsurance == null ? void 0 : userInsurance.policy, userInsurance }) }),
      ((_a = userInsurance == null ? void 0 : userInsurance.policy) == null ? void 0 : _a.uploadedDocuments) && userInsurance.policy.uploadedDocuments.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(InsurancePolicyDocuments, { insurancePolicy: userInsurance == null ? void 0 : userInsurance.policy }) })
    ] })
  ] });
};
