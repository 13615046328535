"use strict";
import { capitalize } from "lodash";
export var KeyResultProgressType = /* @__PURE__ */ ((KeyResultProgressType2) => {
  KeyResultProgressType2["Percentage"] = "Percentage";
  KeyResultProgressType2["Number"] = "Number";
  return KeyResultProgressType2;
})(KeyResultProgressType || {});
export var KeyResultStatus = /* @__PURE__ */ ((KeyResultStatus2) => {
  KeyResultStatus2["Planned"] = "Planned";
  KeyResultStatus2["Active"] = "Active";
  KeyResultStatus2["Achieved"] = "Achieved";
  return KeyResultStatus2;
})(KeyResultStatus || {});
export const keyResultStatusOptions = [
  {
    value: "Planned" /* Planned */,
    label: capitalize("Planned" /* Planned */)
  },
  {
    value: "Active" /* Active */,
    label: capitalize("Active" /* Active */)
  },
  {
    value: "Achieved" /* Achieved */,
    label: capitalize("Achieved" /* Achieved */)
  }
];
export const keyResultProgressTypeOptions = [
  {
    value: "Number" /* Number */,
    label: capitalize("Number" /* Number */)
  },
  {
    value: "Percentage" /* Percentage */,
    label: capitalize("Percent")
  }
];
