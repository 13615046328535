"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { capitalize } from "lodash";
import { ReactComponent as Mistake } from "@/images/reports/Mistake.svg";
import { ReactComponent as CheckCircle } from "@/images/side-bar-icons/CheckCircle.svg";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  DocumentNewStatus
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { DocumentStatus } from "@/v2/feature/documents/documents.interface";
import {
  ADDITIONAL_SIGNATORY_PLACEHOLDER,
  EMPLOYEE_SIGNATORY_PLACEHOLDER,
  OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER,
  SignatoryType,
  WITNESS_DETAILS_PLACEHOLDER
} from "@/v2/feature/templates/templates.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { translateDocumentStatus } from "@/v2/infrastructure/i18n/translate.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
const iconSize = { width: 14, height: 14 };
export const documentExpiringSoon = (document) => {
  var _a, _b;
  const sevenDays = 7 * 24 * 60 * 60 * 1e3;
  if (!((_a = document == null ? void 0 : document.documentSettings) == null ? void 0 : _a.expiryDate)) return false;
  const expiryDate = new Date((_b = document == null ? void 0 : document.documentSettings) == null ? void 0 : _b.expiryDate);
  const today = /* @__PURE__ */ new Date();
  return expiryDate.getTime() - today.getTime() <= sevenDays;
};
export const documentExpired = (document) => {
  var _a, _b;
  if (!((_a = document == null ? void 0 : document.documentSettings) == null ? void 0 : _a.expiryDate)) return false;
  const expiryDate = new Date((_b = document == null ? void 0 : document.documentSettings) == null ? void 0 : _b.expiryDate);
  const today = /* @__PURE__ */ new Date();
  return expiryDate.getTime() < today.getTime();
};
const getTooltipTextForPartiallySignedContract = (document, companySignatoryUser, recipientUser, signatoriesRequired) => {
  const { contract } = document;
  const signedParties = [];
  const pendingSignatories = [];
  const requiredCompanySignature = signatoriesRequired && (signatoriesRequired == null ? void 0 : signatoriesRequired.length) > 0 && signatoriesRequired.includes(SignatoryType.additional);
  const requiredRecipientSignature = signatoriesRequired && (signatoriesRequired == null ? void 0 : signatoriesRequired.length) > 0 && signatoriesRequired.includes(SignatoryType.recipient);
  if (requiredCompanySignature && (contract == null ? void 0 : contract.companySignatureTimestamp) && companySignatoryUser)
    signedParties.push(companySignatoryUser == null ? void 0 : companySignatoryUser.displayName);
  if (requiredRecipientSignature && (contract == null ? void 0 : contract.recipientSignatureTimestamp) && recipientUser)
    signedParties.push(recipientUser == null ? void 0 : recipientUser.displayName);
  if (requiredCompanySignature && !(contract == null ? void 0 : contract.companySignatureTimestamp) && companySignatoryUser)
    pendingSignatories.push(companySignatoryUser == null ? void 0 : companySignatoryUser.displayName);
  if (requiredRecipientSignature && !(contract == null ? void 0 : contract.recipientSignatureTimestamp) && recipientUser)
    pendingSignatories.push(recipientUser == null ? void 0 : recipientUser.displayName);
  if (pendingSignatories.length === 0) return "";
  let tooltipText = signedParties.length > 0 ? `Signed by:  ${signedParties.join(", ")}; ` : "";
  tooltipText += pendingSignatories.length > 0 ? `Pending signature: ${pendingSignatories.join(", ")};` : "";
  return tooltipText.replace(/;$/, "").trim();
};
const getSignedStatus = (document, signatoriesRequired) => {
  var _a;
  const { contract } = document;
  const fileUuidFromAttachment = document.fileUuid;
  const finalUuid = (_a = document.fileUuid) != null ? _a : fileUuidFromAttachment;
  const contractFinalised = !!((contract == null ? void 0 : contract.id) && finalUuid);
  const signatories = [];
  const partiesSigned = [];
  if (contract && signatoriesRequired && (signatoriesRequired == null ? void 0 : signatoriesRequired.length) > 0) {
    if (signatoriesRequired.includes(SignatoryType.additional) && contract.companySignatory) {
      signatories.push(contract.companySignatory);
      if (contract.companySignatureTimestamp) {
        partiesSigned.push(contract.companySignatory);
      }
    }
    if (signatoriesRequired.includes(SignatoryType.recipient) && contract.recipient) {
      signatories.push(contract.recipient);
      if (contract.recipientSignatureTimestamp) {
        partiesSigned.push(contract.recipient);
      }
    }
  }
  const noPendingSignatures = partiesSigned.length === signatories.length;
  return contract && contract.id ? noPendingSignatures && contractFinalised ? DocumentStatus.Signed : noPendingSignatures && !contractFinalised ? DocumentStatus.MissingInformation : `${partiesSigned.length}/${signatories.length} Signed` : DocumentStatus.Uploaded;
};
export const getNewDocumentStatus = (document, contract, signatoriesRequired) => {
  var _a;
  let status = DocumentStatus.Pending;
  const fileUuidFromAttachment = document.fileUuid;
  const finalUuid = (_a = document.fileUuid) != null ? _a : fileUuidFromAttachment;
  if (finalUuid) status = DocumentStatus.Uploaded;
  if (document.contractId) {
    if ((contract == null ? void 0 : contract.companySignatureTimestamp) && (contract == null ? void 0 : contract.recipientSignatureTimestamp)) {
      status = DocumentStatus.Signed;
    } else if (!(contract == null ? void 0 : contract.companySignatureTimestamp) && (contract == null ? void 0 : contract.recipientSignatureTimestamp) || (contract == null ? void 0 : contract.companySignatureTimestamp) && !(contract == null ? void 0 : contract.recipientSignatureTimestamp)) {
      status = getSignedStatus(document, signatoriesRequired);
    } else if (!(contract == null ? void 0 : contract.companySignatureTimestamp) && !(contract == null ? void 0 : contract.recipientSignatureTimestamp)) {
      status = getSignedStatus(document, signatoriesRequired);
    }
  }
  if (documentExpiringSoon(document)) status = DocumentStatus.expiringSoon;
  if (documentExpired(document)) status = DocumentStatus.expired;
  return status;
};
const signatoriesRequiredForContract = (templateText) => {
  const signatories = [];
  if (templateText.includes(EMPLOYEE_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.recipient);
  if (templateText.includes(OLD_ADDITIONAL_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.additional);
  if (templateText.includes(ADDITIONAL_SIGNATORY_PLACEHOLDER)) signatories.push(SignatoryType.additional);
  if (templateText.includes(WITNESS_DETAILS_PLACEHOLDER)) signatories.push(SignatoryType.witness);
  return signatories;
};
export const companySignatureRequired = (row, signatoriesRequired) => {
  var _a;
  return signatoriesRequired && (signatoriesRequired == null ? void 0 : signatoriesRequired.includes(SignatoryType.additional)) && ((_a = row.contract) == null ? void 0 : _a.companySignatory);
};
export const recipientSignatureRequired = (row, signatoriesRequired) => {
  var _a;
  return signatoriesRequired && (signatoriesRequired == null ? void 0 : signatoriesRequired.includes(SignatoryType.recipient)) && ((_a = row.contract) == null ? void 0 : _a.recipient);
};
const allPartiesHaveSignedContract = (row, signatoriesRequired) => {
  const { contractId, contract } = row;
  const companySignatureExpected = companySignatureRequired(row, signatoriesRequired);
  const recipientSignatureExpected = recipientSignatureRequired(row, signatoriesRequired);
  if (!contractId || !contract) {
    return false;
  }
  if (!companySignatureExpected && !recipientSignatureExpected || !Array.isArray(signatoriesRequired) || signatoriesRequired.length === 0) {
    return true;
  }
  if (companySignatureExpected && !contract.companySignatureTimestamp) {
    return false;
  }
  if (recipientSignatureExpected && !contract.recipientSignatureTimestamp) {
    return false;
  }
  return true;
};
export const DisplayNewDocumentStatus = ({ document }) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const { contract } = document;
  const signatoriesRequired = (contract == null ? void 0 : contract.templateText) ? signatoriesRequiredForContract(contract.templateText) : null;
  const hideTooltip = !document.contractId || !!(document.contractId && allPartiesHaveSignedContract(document, signatoriesRequired)) || !!(document.contractId && document.contract && signatoriesRequired && signatoriesRequired.length === 0);
  const documentStatus = useMemo(() => {
    return getNewDocumentStatus(document, contract, signatoriesRequired);
  }, [contract, document, signatoriesRequired]);
  if (documentStatus === DocumentStatus.Pending)
    return /* @__PURE__ */ jsxs(
      Typography,
      {
        variant: "paragraph",
        color: "Grey",
        sx: {
          gap: "5px",
          display: "flex",
          alignItems: "center"
        },
        children: [
          /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.GreyMiddle } })),
          " ",
          translateDocumentStatus(documentStatus, polyglot)
        ]
      }
    );
  else
    return /* @__PURE__ */ jsx(
      StyledTooltip,
      {
        disabled: hideTooltip,
        title: (contract == null ? void 0 : contract.companySignatory) && !(contract == null ? void 0 : contract.companySignatureTimestamp) || !(contract == null ? void 0 : contract.recipientSignatureTimestamp) ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: __spreadValues({}, themeFonts.tooltip), children: getTooltipTextForPartiallySignedContract(
          document,
          (contract == null ? void 0 : contract.companySignatory) ? getCachedUserById(contract == null ? void 0 : contract.companySignatory) : void 0,
          (contract == null ? void 0 : contract.recipient) ? getCachedUserById(contract == null ? void 0 : contract.recipient) : void 0,
          signatoriesRequired
        ) }) : void 0,
        children: /* @__PURE__ */ jsxs(
          Typography,
          {
            variant: "paragraph",
            sx: {
              gap: "5px",
              display: "flex",
              alignItems: "center",
              color: [DocumentStatus.expiringSoon, DocumentStatus.expired].includes(documentStatus) ? themeColors.Red : themeColors.Grey
            },
            children: [
              documentStatus === DocumentStatus.Uploaded && /* @__PURE__ */ jsx(CheckCircle, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
              [DocumentStatus.expiringSoon, DocumentStatus.expired].includes(documentStatus) && /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize)),
              translateDocumentStatus(documentStatus, polyglot)
            ]
          }
        )
      }
    );
};
const getSignFraction = (doc) => {
  var _a, _b, _c, _d;
  if (!doc.contractId || doc.status !== DocumentNewStatus.Pending) return "";
  return doc.contract && ((_a = doc.contract) == null ? void 0 : _a.totalSignaturesRequired) && ((_b = doc.contract) == null ? void 0 : _b.totalSignaturesRequired) > 0 ? `${(_c = doc.contract) == null ? void 0 : _c.signaturesObtained}/${(_d = doc.contract) == null ? void 0 : _d.totalSignaturesRequired}` : "";
};
export const DisplayDocumentNewStatus = ({
  document,
  nameVariant = "paragraph"
}) => {
  var _a, _b;
  const statusIcons = {
    [DocumentNewStatus.Pending]: /* @__PURE__ */ jsx(Clock, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.DarkGrey } })),
    [DocumentNewStatus.Ready]: /* @__PURE__ */ jsx(CheckCircle, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
    [DocumentNewStatus.Expired]: /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize))
  };
  const statusColors = {
    [DocumentNewStatus.Pending]: themeColors.DarkGrey,
    [DocumentNewStatus.Ready]: themeColors.DarkGrey,
    [DocumentNewStatus.Expired]: themeColors.Red
  };
  const icon = (_a = statusIcons[document.status]) != null ? _a : /* @__PURE__ */ jsx(Fragment, {});
  const textColor = (_b = statusColors[document.status]) != null ? _b : themeColors.DarkGrey;
  return /* @__PURE__ */ jsxs(
    Typography,
    {
      variant: nameVariant,
      sx: {
        gap: "5px",
        display: "flex",
        alignItems: "center",
        color: textColor
      },
      children: [
        icon,
        capitalize(document.status),
        " ",
        getSignFraction(document)
      ]
    }
  );
};
