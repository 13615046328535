"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Cancel } from "@/images/side-bar-icons/Cancel.svg";
import { ReactComponent as CheckCircle } from "@/images/side-bar-icons/CheckCircle.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import {
  mapProfileNotificationsToNotificationForTable,
  mapUserNotificationsToNotificationForTable
} from "@/v2/feature/notification/notification.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonBoxSx, tableSecondaryIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
export const NotificationStatus = ({ status }) => {
  const { polyglot } = usePolyglot();
  return status ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: [
    /* @__PURE__ */ jsx(CheckCircle, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { ml: spacing.m10 }), children: polyglot.t("NotificationStatus.on") })
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: [
    /* @__PURE__ */ jsx(Cancel, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Grey } })),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { ml: spacing.m10, color: themeColors.Grey }), children: polyglot.t("NotificationStatus.off") })
  ] });
};
export const NotificationSettingTable = ({
  userNotifications,
  profileNotifications,
  slackChannels,
  onEditClick,
  loading
}) => {
  const { polyglot } = usePolyglot();
  const [tableData, setTableData] = useState([]);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    const userNotificationData = userNotifications ? mapUserNotificationsToNotificationForTable(polyglot, userNotifications, nonTerminatedCachedUsers, slackChannels) : [];
    const profileNotificationData = profileNotifications ? mapProfileNotificationsToNotificationForTable(
      polyglot,
      profileNotifications,
      nonTerminatedCachedUsers,
      slackChannels
    ) : [];
    const filteredData = [...userNotificationData, ...profileNotificationData].filter((n) => {
      var _a, _b, _c;
      const s = searchInput.toLowerCase();
      return ((_a = n.type) == null ? void 0 : _a.toLowerCase().includes(s)) || ((_b = n.name) == null ? void 0 : _b.toLowerCase().includes(s)) || ((_c = n.channel) == null ? void 0 : _c.toLowerCase().includes(s));
    }).sort((a, b) => {
      var _a, _b, _c, _d;
      const typeComparison = ((_a = a.type) != null ? _a : "").localeCompare((_b = b.type) != null ? _b : "");
      if (typeComparison !== 0) {
        return typeComparison;
      }
      return ((_c = a.id) != null ? _c : 0) - ((_d = b.id) != null ? _d : 0);
    });
    setTableData(filteredData);
  }, [userNotifications, profileNotifications, slackChannels, searchInput, nonTerminatedCachedUsers, polyglot]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => polyglot.t("NotificationSettingTable.type"),
        accessorFn: (row) => row,
        id: "type",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.type),
        cell: (info) => {
          return /* @__PURE__ */ jsx("div", { children: info.getValue().type });
        }
      },
      {
        header: () => polyglot.t("NotificationSettingTable.notification"),
        accessorFn: (row) => row,
        id: "notification",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: (info) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = info.getValue()) == null ? void 0 : _a.name });
        }
      },
      {
        header: () => polyglot.t("NotificationSettingTable.status"),
        accessorFn: (row) => row,
        id: "status",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.status.toString()),
        cell: (info) => {
          var _a;
          return /* @__PURE__ */ jsx(NotificationStatus, { status: (_a = info.getValue()) == null ? void 0 : _a.status });
        }
      },
      {
        header: () => polyglot.t("NotificationSettingTable.sendTo"),
        accessorFn: (row) => row,
        id: "sendTo",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.sendTo),
        cell: (info) => {
          var _a, _b;
          return ((_a = info.getValue()) == null ? void 0 : _a.status) ? /* @__PURE__ */ jsx("div", { children: (_b = info.getValue()) == null ? void 0 : _b.sendTo }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("NotificationSettingTable.when"),
        accessorFn: (row) => row,
        id: "when",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.when),
        cell: (info) => {
          var _a, _b;
          return ((_a = info.getValue()) == null ? void 0 : _a.status) ? /* @__PURE__ */ jsx("div", { children: (_b = info.getValue()) == null ? void 0 : _b.when }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => polyglot.t("NotificationSettingTable.channel"),
        accessorFn: (row) => row,
        id: "channel",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.channel),
        cell: (info) => {
          var _a, _b;
          return ((_a = info.getValue()) == null ? void 0 : _a.status) ? /* @__PURE__ */ jsx("div", { children: (_b = info.getValue()) == null ? void 0 : _b.channel }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "action",
        enableSorting: false,
        cell: (info) => {
          return /* @__PURE__ */ jsx(Box, { sx: tableIconButtonBoxSx, children: /* @__PURE__ */ jsx(IconButton, { sx: tableSecondaryIconButtonSx, onClick: () => onEditClick(info.getValue()), children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }) });
        }
      }
    ];
  }, [onEditClick, polyglot]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: "5px",
          alignItems: "center"
        },
        children: /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            },
            style: { width: "250px", minWidth: "250px" }
          }
        )
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(BasicTable, { rowData: [...tableData], columnData, loading, hidePagination: true }) })
  ] });
};
