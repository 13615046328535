"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DocumentTypeAPI } from "@/v2/feature/documents/document-type.api";
import {
  getDocumentTypeAvailableToOptions
} from "@/v2/feature/documents/documents.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { LocalDate } from "@/v2/util/local-date";
export const DocumentSettingsNewTypeForm = ({
  initialValues,
  onClose,
  refreshDocumentTypes,
  mode = "create"
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const defaultInitialValues = {
    id: 0,
    documentTypeName: "",
    value: "",
    availableTo: void 0
  };
  const formik = useFormik({
    initialValues: initialValues != null ? initialValues : defaultInitialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      documentTypeName: Yup.string().required("Required"),
      availableTo: Yup.string().required("Required")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        const customDocumentTypePayload = {
          id: values.id,
          value: values.value,
          label: values.documentTypeName,
          category: values.availableTo === "company" ? "Company Documents" : values.availableTo === "personal" ? "Personal Documents" : "Other",
          availableTo: values == null ? void 0 : values.availableTo,
          icon: "",
          bgColor: "",
          canUserAdd: false,
          onePersonOnly: false,
          canBelongToEveryone: false,
          onboardingDocument: false,
          needsVerification: false,
          default: false,
          visible: true,
          createdAt: new LocalDate().toDateString()
        };
        if (mode === "create") {
          yield DocumentTypeAPI.createNewCustomDocumentType(customDocumentTypePayload);
          showMessage(polyglot.t("DocumentSettingsNewTypeForm.successMessages.create"), "success");
        } else {
          yield DocumentTypeAPI.updateCustomDocumentType(customDocumentTypePayload);
          showMessage(polyglot.t("DocumentSettingsNewTypeForm.successMessages.update"), "success");
        }
        refreshDocumentTypes();
        onClose();
      } catch (error) {
        showMessage(
          polyglot.t("DocumentSettingsNewTypeForm.errorMessages.fail", {
            mode,
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: mode === "edit" ? polyglot.t("DocumentSettingsNewTypeForm.editType") : polyglot.t("DocumentSettingsNewTypeForm.newType") }),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "documentTypeName",
        label: polyglot.t("DocumentSettingsNewTypeForm.documentTypeName"),
        value: formik.values.documentTypeName,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.documentTypeName && !!formik.errors.documentTypeName,
        helperText: (_a = formik.touched.documentTypeName && formik.errors.documentTypeName) != null ? _a : " ",
        clearText: () => formik.setFieldValue("documentTypeName", ""),
        required: true
      }
    ) }),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "availableTo",
        label: polyglot.t("DocumentSettingsNewTypeForm.availableTo"),
        options: getDocumentTypeAvailableToOptions(polyglot),
        value: formik.values.availableTo,
        compareValue: formik.values.availableTo,
        error: !!formik.errors.availableTo && formik.touched.availableTo,
        onChange: formik.handleChange,
        helperText: formik.errors.availableTo && formik.touched.availableTo,
        required: true
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        colorVariant: "primary",
        sizeVariant: "medium"
      }
    ) })
  ] }) });
};
