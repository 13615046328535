"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { translateColumnsOptions } from "@/v2/infrastructure/i18n/translate.util";
export const ColumnsDrawer = ({
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
  fullWidth = false,
  onApply = void 0
}) => {
  const { polyglot } = usePolyglot();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const noOfSelectedColumns = useMemo(() => new Set(selectedColumns).size, [selectedColumns]);
  return /* @__PURE__ */ jsxs(Box, { sx: { width: fullWidth ? "100%" : "auto" }, children: [
    /* @__PURE__ */ jsx(
      ColumnsSideDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        columnsOptions,
        selectedColumns,
        setSelectedColumns,
        onApply
      }
    ),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        onClick: () => {
          setIsDrawerOpen(true);
        },
        sizeVariant: "filter",
        colorVariant: "active",
        fullWidth,
        children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
          polyglot.t("ColumnsDrawer.columns"),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "15px",
                minWidth: "16px",
                height: "16px",
                bgcolor: "white"
              },
              children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mx: "2px" }, children: noOfSelectedColumns })
            }
          )
        ] })
      }
    )
  ] });
};
const ColumnsSideDrawer = ({
  isOpen,
  setIsOpen,
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
  onApply
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        ColumnsFilterDrawerContent,
        {
          columnsOptions,
          selectedColumns,
          setSelectedColumns,
          setIsOpen,
          onApply
        }
      )
    }
  ) });
};
const ColumnsFilterDrawerContent = ({
  columnsOptions,
  selectedColumns,
  setSelectedColumns,
  setIsOpen,
  onApply
}) => {
  const { polyglot } = usePolyglot();
  const [localSelectedColumns, setLocalSelectedColumns] = useState([...selectedColumns]);
  const [filteredColumnsOptions, setFilteredColumnsOptions] = useState([
    ...columnsOptions
  ]);
  const sortedColumnsOptions = useMemo(() => {
    return [...filteredColumnsOptions].sort((a, b) => {
      if (a.required === b.required) return 0;
      return a.required ? -1 : 1;
    });
  }, [filteredColumnsOptions]);
  const [searchInput, setSearchInput] = useState("");
  const requiredColumns = useMemo(() => columnsOptions.filter((o) => o.required), [columnsOptions]);
  useEffect(() => {
    if (!searchInput) {
      setFilteredColumnsOptions([...columnsOptions]);
    } else {
      const searchString = searchInput.toLowerCase();
      setFilteredColumnsOptions(columnsOptions.filter((option) => option.label.toLowerCase().includes(searchString)));
    }
  }, [searchInput, columnsOptions]);
  const applyColumnsSelection = useCallback(() => __async(void 0, null, function* () {
    setSelectedColumns([...localSelectedColumns]);
    setIsOpen(false);
    if (onApply) onApply([...localSelectedColumns]);
  }), [setIsOpen, setSelectedColumns, localSelectedColumns, onApply]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("ColumnsDrawer.columns") }),
      /* @__PURE__ */ jsx(Box, { children: localSelectedColumns.length > requiredColumns.length ? /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => setLocalSelectedColumns(requiredColumns.map((o) => o.value)),
          sizeVariant: "link",
          colorVariant: "text",
          children: polyglot.t("ColumnsFilterDrawerContent.clearAll")
        }
      ) : /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          onClick: () => setLocalSelectedColumns(columnsOptions.map((o) => o.value)),
          sizeVariant: "link",
          colorVariant: "text",
          children: polyglot.t("ColumnsFilterDrawerContent.selectAll")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => {
          var _a;
          setSearchInput((_a = e.target.value) != null ? _a : "");
        },
        style: { width: "350px", minWidth: "350px" }
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: sortedColumnsOptions.map((option) => {
      const checked = localSelectedColumns.some((column) => column === option.value);
      return /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: translateColumnsOptions(option.value, polyglot),
          name: option.label,
          checked,
          value: option.value,
          disabled: checked && option.required,
          onChange: () => {
            setLocalSelectedColumns((currentState) => {
              if (currentState.some((column) => column === option.value))
                return currentState.filter((column) => column !== option.value || option.required);
              return [...currentState, option.value];
            });
          }
        },
        option.value
      );
    }) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          fullWidth: true,
          onClick: () => {
            setIsOpen(false);
          },
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, onClick: applyColumnsSelection, children: polyglot.t("General.apply") })
    ] })
  ] });
};
