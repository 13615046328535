"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import Dropzone from "react-dropzone-uploader";
import { FILE_UPLOAD_ENDPOINT } from "@/api-client/routes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Warning } from "@/images/side-bar-icons/Warning.svg";
import { nestErrorMessage } from "@/lib/errors";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { useBulkUpload } from "@/v2/feature/documents/bulk-upload-documents.context";
import { DocumentBulkUploadActionsFormDrawer } from "@/v2/feature/documents/components/document-bulk-upload-actions-form-modal.component";
import { DocumentBulkUploadResultTable } from "@/v2/feature/documents/components/document-bulk-upload-table-result.component";
import { DocumentFormModal } from "@/v2/feature/documents/components/document-form-modal.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import {
  BulkUploadDocumentActionsMode,
  DocumentUploadStatus
} from "@/v2/feature/documents/documents.interface";
import {
  initialiseState,
  OTHER_TYPE,
  reduceDocumentFormModal
} from "@/v2/feature/documents/documents.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { borders } from "@/v2/styles/borders.styles";
import { neutralColors, themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
const initialState = initialiseState([]);
const mapBulkFilesToZeltDocuments = (documents, companyId, uploadedBy, editHandler) => {
  const createdAt = new LocalDate().toDateString();
  return documents.map((d) => {
    var _a, _b, _c;
    return {
      id: 0,
      fileName: d.file.meta.name,
      fileUuid: d.file.meta.id,
      name: d.file.meta.name,
      size: d.file.meta.size,
      type: (_c = (_a = d.documentType) == null ? void 0 : _a.value) != null ? _c : (_b = OTHER_TYPE) == null ? void 0 : _b.value,
      belongsTo: d.assignee,
      fileUrl: "",
      canAllEmployeesSee: false,
      companyId,
      isExternalResource: false,
      programmaticRecordId: null,
      programmaticRecordType: null,
      createdAt,
      updatedAt: createdAt,
      uploadedBy,
      insurancePolicyId: null,
      visibilitySettings: d == null ? void 0 : d.visibilitySettings,
      expirySettings: d == null ? void 0 : d.expirySettings,
      note: d.note,
      dzProps: {
        file: d.file.file,
        meta: d.file.meta,
        remove: d.file.remove,
        edit: editHandler,
        state: d.state
      }
    };
  });
};
const Layout = ({
  input,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  submitButton,
  dropzoneProps,
  files
}) => {
  const { polyglot } = usePolyglot();
  const MAX_FILES = 50;
  return /* @__PURE__ */ jsx(Box, { sx: { width: "100%", mt: spacing.mt20 }, children: /* @__PURE__ */ jsxs(Stack, { children: [
    files.length <= MAX_FILES && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({}, dropzoneProps), { children: files.length <= 0 && input })) }),
    files.length > MAX_FILES && /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex" }, spacing.mt20), { alignItems: "center", gap: spacing.g10 }), children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Warning, { width: 16, height: 16, style: { fill: themeColors.orange } }) }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: polyglot.t("BulkFileUploadInput.warningMessageTooManyFiles", { filesLength: files.length }) })
    ] })
  ] }) });
};
const CustomSubmitButton = ({
  onSubmit,
  disabled,
  filesStillPendingMapping,
  allFilesUploaded,
  someFilesFailed,
  finishBulkUploadSession,
  documentsSelected,
  setBulkActionDrawerOpen,
  setBulkActionDrawerMode,
  loading
}) => {
  const { polyglot } = usePolyglot();
  const bulkActionsForDocument = useMemo(() => {
    const optionsAvailable = [];
    if (setBulkActionDrawerOpen && setBulkActionDrawerMode) {
      const bulkActionHandler = (mode) => {
        setBulkActionDrawerMode(mode);
        setBulkActionDrawerOpen(true);
      };
      optionsAvailable.push({
        handler: () => bulkActionHandler(BulkUploadDocumentActionsMode.DELETE),
        label: polyglot.t("BulkFileUploadInput.buttonText.bulkDelete"),
        disabled: false
      });
      optionsAvailable.push({
        handler: () => bulkActionHandler(BulkUploadDocumentActionsMode.ASSIGN_OWNER),
        label: polyglot.t("BulkFileUploadInput.buttonText.bulkAssignOwner"),
        disabled: false
      });
      optionsAvailable.push({
        handler: () => bulkActionHandler(BulkUploadDocumentActionsMode.ASSIGN_TYPE),
        label: polyglot.t("BulkFileUploadInput.buttonText.bulkAssignType"),
        disabled: false
      });
    }
    return optionsAvailable;
  }, [setBulkActionDrawerOpen, setBulkActionDrawerMode, polyglot]);
  return !documentsSelected ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-start", width: "100%" }, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-start", width: "30%" }, children: /* @__PURE__ */ jsx(
    LoaderButton,
    {
      loading,
      sizeVariant: "large",
      colorVariant: "primary",
      fullWidth: true,
      onClick: () => {
        if (!allFilesUploaded || someFilesFailed) onSubmit([]);
        if (allFilesUploaded && finishBulkUploadSession) finishBulkUploadSession();
      },
      disabled: disabled || filesStillPendingMapping > 0,
      children: filesStillPendingMapping > 0 ? polyglot.t("BulkFileUploadInput.buttonText.mapPendingDocs", {
        documentCount: filesStillPendingMapping
      }) : filesStillPendingMapping === 0 && !allFilesUploaded && !someFilesFailed ? polyglot.t("BulkFileUploadInput.buttonText.continue") : allFilesUploaded ? polyglot.t("BulkFileUploadInput.buttonText.done") : someFilesFailed ? polyglot.t("BulkFileUploadInput.buttonText.retry") : ""
    }
  ) }) }) : /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-start", width: "100%" }, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-start", width: "30%" }, children: /* @__PURE__ */ jsx(
    StyledMenuComponent,
    {
      options: bulkActionsForDocument,
      actionButtonDetails: {
        type: "button",
        colorVariant: "secondary",
        sizeVariant: "medium",
        title: polyglot.t("BulkFileUploadInput.buttonText.bulkActions"),
        icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
        iconPosition: "end"
      }
    }
  ) }) });
};
export function BulkFileUploadInput({
  onComplete,
  finishBulkUploadSession,
  maxFiles = 50,
  maxSizeBytes = 10485760,
  value,
  onChange,
  endpoint = FILE_UPLOAD_ENDPOINT,
  disabled = false,
  skipUpload,
  displayText,
  _existingFiles,
  sx
}) {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [resetKey, setResetKey] = useState(1);
  const [drag, setDrag] = useState(false);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { state: bulkUploadState, dispatch: bulkUploadDispatch } = useBulkUpload();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState({
    success: 0,
    error: 0,
    errorDetails: []
  });
  const currentUser = globalState.user;
  const [_filterValue, setFilterValue] = useState("");
  const [documentTypes, setDocumentTypes] = useState([]);
  const { hasScopes, getScopesContext } = useScopes();
  const [selectionModel, setSelectionModel] = useState([]);
  const [bulkActionDrawerOpen, setBulkActionDrawerOpen] = useState(false);
  const [bulkActionDrawerMode, setBulkActionDrawerMode] = useState();
  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers({ refresh: true });
  const allUserOptions = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);
  const currentUserIsDocumentAdmin = hasScopes(
    ["documents:all", "documents:manager"],
    getScopesContext({ userId: (_a = globalState.user) == null ? void 0 : _a.userId })
  );
  const handleEdit = (document) => {
    documentDispatch({ kind: "bulk_upload_edit_document", value: document, userList: allUserOptions });
    setOpen(true);
    setFilterValue("company");
  };
  function readFileAsBase64(file) {
    return __async(this, null, function* () {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve((reader == null ? void 0 : reader.result).split(",")[1]);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });
  }
  const handleChangeStatus = (file, status) => {
    var _a2, _b2;
    if (status === "done") {
      onChange(((_a2 = file.xhr) == null ? void 0 : _a2.response) ? JSON.parse((_b2 = file.xhr) == null ? void 0 : _b2.response) : "", file.file);
      setDrag(false);
      bulkUploadDispatch({
        type: "ADD_DOCUMENT",
        payload: {
          documentId: file.meta.id,
          file,
          documentType: OTHER_TYPE,
          assignee: [],
          state: DocumentUploadStatus.PENDING
        }
      });
    } else if (status === "removed") {
      onChange();
      setDrag(false);
      bulkUploadDispatch({
        type: "REMOVE_DOCUMENT",
        payload: file.meta.id
      });
    } else if (status === "error_file_size") {
      onChange();
      setResetKey(resetKey + 1);
      setDrag(false);
      showMessage("File cannot be bigger than 10MB; please try uploading a smaller file.", "error");
    }
  };
  useEffect(() => {
    (() => __async(this, null, function* () {
      try {
        const types = yield DocumentAPI.listDocumentTypes();
        setDocumentTypes(types);
        documentDispatch({ kind: "set_types", value: types });
      } catch (error) {
        console.error(`Something bad happened. ${nestErrorMessage(error)}`);
      }
    }))();
  }, []);
  useEffect(() => {
    if (!value) {
      setResetKey(resetKey + 1);
    }
  }, [value]);
  const allFilesUploaded = useMemo(() => {
    var _a2, _b2;
    return ((_a2 = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments) == null ? void 0 : _a2.length) > 0 && ((_b2 = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments) == null ? void 0 : _b2.every((doc) => doc.state === DocumentUploadStatus.UPLOADED));
  }, [bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments]);
  const someFilesFailed = useMemo(() => {
    var _a2;
    return (_a2 = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments) == null ? void 0 : _a2.some((doc) => doc.state === DocumentUploadStatus.FAILED);
  }, [bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments]);
  const filesStillPendingMapping = useMemo(() => {
    var _a2;
    return (_a2 = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments) == null ? void 0 : _a2.filter((doc) => doc.state === DocumentUploadStatus.PENDING).length;
  }, [bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments]);
  const filesSelected = useMemo(() => {
    return (selectionModel == null ? void 0 : selectionModel.length) > 0;
  }, [selectionModel]);
  const handleBulkDelete = () => {
    const selectedFiles = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments.filter((d) => selectionModel.includes(d.documentId));
    if (selectedFiles) {
      selectedFiles.forEach((f) => f.file.remove());
      showMessage(`Removed ${selectedFiles == null ? void 0 : selectedFiles.length} document(s).`, "success");
      setBulkActionDrawerOpen(false);
      setSelectionModel([]);
    }
  };
  const handleBulkAssignOwner = (newOwner) => {
    const newOwnerUser = getCachedUserById(newOwner);
    const selectedFiles = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments.filter((d) => selectionModel.includes(d.documentId));
    if (selectedFiles) {
      for (const eachFile of selectedFiles) {
        const documentId = eachFile.file.meta.id;
        if (bulkUploadDispatch && documentId) {
          const ownershipList = [
            {
              documentId: 0,
              User: {
                userId: newOwner,
                firstName: newOwnerUser == null ? void 0 : newOwnerUser.firstName,
                lastName: newOwnerUser == null ? void 0 : newOwnerUser.lastName,
                displayName: newOwnerUser == null ? void 0 : newOwnerUser.displayName
              }
            }
          ];
          bulkUploadDispatch({
            type: "UPDATE_DOCUMENT",
            payload: {
              documentId,
              updatedData: {
                assignee: ownershipList,
                state: DocumentUploadStatus.MAPPED
              }
            }
          });
        }
      }
      showMessage(`Successfully updated owner for ${selectedFiles == null ? void 0 : selectedFiles.length} document(s).`, "success");
      setBulkActionDrawerOpen(false);
      setSelectionModel([]);
    }
  };
  const handleBulkAssignType = (newType) => {
    const selectedFiles = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments.filter((d) => selectionModel.includes(d.documentId));
    if (selectedFiles) {
      for (const eachFile of selectedFiles) {
        const documentId = eachFile.file.meta.id;
        if (bulkUploadDispatch && documentId) {
          bulkUploadDispatch({
            type: "UPDATE_DOCUMENT",
            payload: {
              documentId,
              updatedData: {
                documentType: newType,
                state: DocumentUploadStatus.MAPPED
              }
            }
          });
        }
      }
      showMessage(`Successfully updated type for ${selectedFiles == null ? void 0 : selectedFiles.length} document(s).`, "success");
      setBulkActionDrawerOpen(false);
      setSelectionModel([]);
    }
  };
  const handleBulkActionsDrawerConfirmAction = ({ mode, owner, type }) => {
    switch (mode) {
      case "delete":
        handleBulkDelete();
        break;
      case "assignType":
        if (type) handleBulkAssignType(type);
        break;
      case "assignOwner":
        if (owner) handleBulkAssignOwner(owner);
        break;
      default:
        break;
    }
  };
  const filesToUpload = useMemo(() => {
    return bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments.map((d) => d.file);
  }, [bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Dropzone,
      {
        maxFiles,
        maxSizeBytes,
        multiple: true,
        getUploadParams: skipUpload ? void 0 : () => ({ url: endpoint }),
        onChangeStatus: handleChangeStatus,
        LayoutComponent: (props) => /* @__PURE__ */ jsx(Layout, __spreadValues({}, props)),
        styles: {
          dropzone: {
            border: "none",
            boxShadow: "none",
            overflow: "hidden",
            minHeight: "55px"
          },
          input: { border: "none", boxShadow: "none", backgroundColor: neutralColors.n0 },
          inputLabel: { width: "inherit", position: "inherit", backgroundColor: neutralColors.n0 }
        },
        inputContent: /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadValues({
              alignItems: "center",
              minHeight: 55,
              cursor: disabled ? "not-allowed" : "pointer",
              width: "100%",
              mt: spacing.mt20
            }, sx),
            children: [
              /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    pointerEvents: disabled ? "inherit" : "none",
                    opacity: disabled ? "0.4" : "1",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "20px"
                  },
                  children: [
                    /* @__PURE__ */ jsx(
                      Box,
                      {
                        sx: {
                          width: "100%",
                          height: "50px",
                          border: drag ? borders.dashHover : borders.dash,
                          borderRadius: radius.br10,
                          color: themeColors.DarkGrey,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          pointerEvents: disabled ? "inherit" : "none",
                          opacity: disabled ? "0.4" : "1"
                        },
                        onDragEnter: () => {
                          setDrag(true);
                        },
                        onDragLeave: () => {
                          if (!value) {
                            setDrag(false);
                          }
                        },
                        children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: polyglot.t("UploadInput.drop") })
                      }
                    ),
                    !drag && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: polyglot.t("UploadInput.or") }) }),
                    !drag && /* @__PURE__ */ jsx(
                      Box,
                      {
                        sx: {
                          width: "100%",
                          height: "50px",
                          borderRadius: radius.br10,
                          backgroundColor: themeColors.Background,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            backgroundColor: themeColors.TableHover
                          }
                        },
                        children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: polyglot.t("UploadInput.click") })
                      }
                    )
                  ]
                }
              ),
              /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 }, children: displayText && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: displayText }) })
            ]
          }
        )
      },
      resetKey
    ),
    (bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments.length) > 0 && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mb: spacing.mb20 }), children: polyglot.t("BulkFileUploadInput.layoutMessage") }),
      /* @__PURE__ */ jsx(
        DocumentBulkUploadResultTable,
        {
          documents: mapBulkFilesToZeltDocuments(
            bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments,
            currentUser.company.companyId,
            currentUser.userId,
            handleEdit
          ),
          onEditClick: function(document) {
            var _a2;
            if ((_a2 = document.dzProps) == null ? void 0 : _a2.edit) document.dzProps.edit(document);
          },
          onDelete: (file) => {
            if (!file) return;
            file.remove();
          },
          documentTypes,
          uploadResult,
          setSelectionModel,
          selectionModel
        }
      ),
      /* @__PURE__ */ jsx(
        CustomSubmitButton,
        {
          filesStillPendingMapping,
          allFilesUploaded,
          someFilesFailed,
          finishBulkUploadSession,
          documentsSelected: filesSelected,
          setBulkActionDrawerOpen,
          setBulkActionDrawerMode,
          loading,
          disabled: ((_b = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments) == null ? void 0 : _b.length) > 50 || loading,
          files: filesToUpload,
          onSubmit: (files) => __async(this, null, function* () {
            var _a2, _b2, _c, _d, _e, _f;
            let result = __spreadValues({}, uploadResult);
            const filesToProcess = (_a2 = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments) == null ? void 0 : _a2.filter(
              (d) => ["mapped", "failed"].includes(d.state)
            );
            for (const eachFile of filesToProcess) {
              setLoading(true);
              bulkUploadDispatch({
                type: "UPDATE_DOCUMENT",
                payload: {
                  documentId: eachFile.file.meta.id,
                  updatedData: { state: DocumentUploadStatus.PROCESSING }
                }
              });
              try {
                const mapping = (_b2 = bulkUploadState == null ? void 0 : bulkUploadState.bulkDocuments) == null ? void 0 : _b2.find((d) => d.documentId === eachFile.file.meta.id);
                if (mapping) {
                  const fileData = yield readFileAsBase64((_c = eachFile.file) == null ? void 0 : _c.file);
                  yield DocumentAPI.uploadDocument(fileData, mapping);
                }
                result.success += 1;
                setUploadResult((previousUploadResult) => __spreadValues(__spreadValues({}, previousUploadResult), result));
                bulkUploadDispatch({
                  type: "UPDATE_DOCUMENT",
                  payload: {
                    documentId: (_d = eachFile.file) == null ? void 0 : _d.meta.id,
                    updatedData: { state: DocumentUploadStatus.UPLOADED }
                  }
                });
              } catch (error) {
                result.error += 1;
                result.errorDetails.push({
                  documentId: (_e = eachFile.file) == null ? void 0 : _e.meta.id,
                  errorMessage: nestErrorMessage(error)
                });
                setUploadResult((previousUploadResult) => __spreadValues(__spreadValues({}, previousUploadResult), result));
                bulkUploadDispatch({
                  type: "UPDATE_DOCUMENT",
                  payload: {
                    documentId: (_f = eachFile.file) == null ? void 0 : _f.meta.id,
                    updatedData: { state: DocumentUploadStatus.FAILED }
                  }
                });
              }
            }
            setLoading(false);
            onComplete == null ? void 0 : onComplete(result == null ? void 0 : result.success);
          }),
          extra: {
            active: true,
            reject: true,
            dragged: [],
            accept: "",
            multiple: true,
            minSizeBytes: 1024,
            maxSizeBytes: 50024,
            maxFiles: 50
          }
        }
      )
    ] }),
    nonTerminatedCachedUsers && /* @__PURE__ */ jsx(
      DocumentFormModal,
      {
        mode: "edit",
        state: documentState,
        currentUserIsAdmin: currentUserIsDocumentAdmin,
        dispatch: documentDispatch,
        newDocumentTypes: documentState.types,
        onSubmit: () => __async(this, null, function* () {
        }),
        users: nonTerminatedCachedUsers,
        canSelectEveryone: false,
        canChangeOwnership: true,
        setOpen,
        open,
        setLoading,
        getDocuments: () => __async(this, null, function* () {
        }),
        setFilterValue,
        filterValue: "personal",
        bulkUpload: true,
        bulkUploadDispatch
      }
    ),
    bulkActionDrawerMode && /* @__PURE__ */ jsx(
      DocumentBulkUploadActionsFormDrawer,
      {
        selectedDocuments: selectionModel,
        confirmAction: handleBulkActionsDrawerConfirmAction,
        mode: bulkActionDrawerMode,
        isOpen: bulkActionDrawerOpen,
        setIsOpen: setBulkActionDrawerOpen,
        documentTypes: documentState.types
      }
    )
  ] });
}
