"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { formatBytes, formatDuration } from "react-dropzone-uploader";
import { ReactComponent as Close } from "@/images/fields/Close.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Subtract.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 12, height: 12 };
export const CustomPreview = ({
  fileWithMeta: { remove, restart },
  meta: { name = "", percent = 0, size = 0, status, duration },
  isUpload,
  canRestart
}) => {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [title, setTitle] = useState(`${name || "?"}, ${formatBytes(size)}`);
  const progressRef = useRef(() => {
  });
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(percent);
        setBuffer(10);
      }
    };
    if (duration) setTitle(`${title}, ${formatDuration(duration)}`);
    if (status === "error_upload_params" || status === "exception_upload" || status === "error_upload") {
      setTitle(`${title} (upload failed)`);
    }
    if (status === "aborted") setTitle(`${title} (cancelled)`);
  }, [status]);
  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 55
      },
      children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", width: "100%" }, children: status === "done" ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(DocumentIcon, __spreadValues({}, iconSize)),
          /* @__PURE__ */ jsx(
            Box,
            {
              component: "span",
              sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { marginLeft: spacing.m10, color: themeColors.DarkGrey }),
              children: title
            }
          )
        ] }),
        /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: remove, title: "Remove", children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize)) })
      ] }) : !["error_upload_params", "exception_upload", "error_upload", "aborted", "ready"].includes(status) || status === "preparing" || status === "uploading" ? /* @__PURE__ */ jsxs(Box, { sx: { display: "block", width: "100%" }, children: [
        isUpload && /* @__PURE__ */ jsx(Box, { sx: { paddingBottom: spacing.p15 }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Loading..." }) }),
        isUpload && /* @__PURE__ */ jsx(
          LinearProgress,
          {
            variant: "determinate",
            value: status === "headers_received" ? 100 : percent,
            valueBuffer: buffer,
            sx: {
              height: 4,
              width: "100%",
              mr: 2,
              backgroundColor: themeColors.TableHover,
              "& .MuiLinearProgress-bar": {
                backgroundColor: themeColors.ZeltYellow
              }
            }
          }
        )
      ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.darkRed }), children: "Download error" }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: remove, children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey, stroke: themeColors.DarkGrey })) }),
          canRestart && /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { marginLeft: spacing.m5 }), onClick: restart, children: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)) })
        ] })
      ] }) })
    }
  );
};
