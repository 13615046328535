"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { CompanyDepartmentAPI } from "@/api-client/index.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/side-bar-icons/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  flexContainerStyle,
  SettingsItemCard,
  responsiveCardStyle
} from "@/v2/components/settings/settings-item-card.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DepartmentFormDrawer } from "@/v2/feature/department/department-settings/components/department-form-drawer.component";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DepartmentSettingsPage = () => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState("");
  const [showMessage] = useMessage();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const refreshDepartments = useCallback(() => __async(void 0, null, function* () {
    setLoading(true);
    try {
      const departments2 = yield CompanyDepartmentAPI.getCompanyDepartments();
      setDepartments(departments2);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refreshDepartments();
  }, []);
  const sortedDepartments = useMemo(
    () => departments.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
    [departments]
  );
  const filteredDepartments = useMemo(() => {
    return searchInput.length > 0 ? sortedDepartments.filter((department) => department.name.toLowerCase().includes(searchInput.toLowerCase())) : sortedDepartments;
  }, [sortedDepartments, searchInput]);
  const openForm = useCallback((department) => {
    setSelectedDepartment(department);
    setIsFormOpen(true);
  }, []);
  const closeForm = useCallback(() => {
    setIsFormOpen(false);
    setTimeout(() => setSelectedDepartment(void 0), 500);
  }, []);
  const openDelete = useCallback((department) => {
    setSelectedDepartment(department);
    setIsDeleteOpen(true);
  }, []);
  const closeDelete = useCallback(() => {
    setIsDeleteOpen(false);
    setTimeout(() => setSelectedDepartment(void 0), 500);
  }, []);
  const deleteDepartment = useCallback(() => __async(void 0, null, function* () {
    try {
      setDeleteLoading(true);
      if (selectedDepartment) yield CompanyDepartmentAPI.deleteCompanyDepartment(selectedDepartment.id);
      else throw new Error(polyglot.t("DepartmentDeleteDrawer.errorMessages.noDepartment"));
      showMessage(
        polyglot.t("DepartmentDeleteDrawer.successMessages.delete", { departmentName: selectedDepartment.name }),
        "success"
      );
      yield refreshDepartments();
      closeDelete();
    } catch (error) {
      showMessage(
        polyglot.t("DepartmentDeleteDrawer.errorMessages.delete", { nestError: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setDeleteLoading(false);
    }
  }), [polyglot, selectedDepartment, refreshDepartments, showMessage, closeDelete]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Departments" }),
        showAction: true,
        actions: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setIsFormOpen(true), children: polyglot.t("DepartmentSettingsPage.newDepartment") }),
        showBack: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            loadingAll: loading,
            sections: [
              {
                contentWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(Box, { sx: flexContainerStyle, children: (filteredDepartments || []).map((department) => {
                      return /* @__PURE__ */ jsx(
                        SettingsItemCard,
                        {
                          title: department.name,
                          boxSx: responsiveCardStyle,
                          advanceActionHidden: !department.companyId,
                          advanceActionOptions: [
                            {
                              icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
                              handler: () => {
                                openForm(department);
                              },
                              label: polyglot.t("General.edit"),
                              disabled: false
                            },
                            {
                              icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                              handler: () => {
                                openDelete(department);
                              },
                              label: polyglot.t("General.delete"),
                              disabled: false
                            }
                          ]
                        },
                        department.id
                      );
                    }) })
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          DepartmentFormDrawer,
          {
            isOpen: isFormOpen,
            setIsOpen: setIsFormOpen,
            selectedDepartment,
            refreshDepartments,
            onClose: closeForm
          }
        ),
        /* @__PURE__ */ jsx(
          DeleteDrawer,
          {
            title: polyglot.t("DepartmentDeleteDrawer.title"),
            description: polyglot.t("DepartmentDeleteDrawer.confirmDeletion", {
              departmentName: selectedDepartment == null ? void 0 : selectedDepartment.name
            }),
            action: deleteDepartment,
            isOpen: isDeleteOpen,
            setIsOpen: setIsDeleteOpen,
            loading: deleteLoading,
            onClose: closeDelete
          }
        )
      ] })
    ] })
  ] });
};
