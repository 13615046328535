"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { themeFonts } from "@v2/styles/fonts.styles";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { AboutDevice } from "@/v2/feature/device/features/order-device/components/about-device.component";
import { ProtectDevice } from "@/v2/feature/device/features/order-device/components/protect-device.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { ThemeSlider } from "@/v2/styles/slider.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const EnrollExistingDevice = ({
  initialDevicePossession = null,
  refreshAppData
}) => {
  const routerLocation = useLocation();
  const [globalState] = useContext(GlobalContext);
  const userId = routerLocation.pathname.includes("me") ? globalState.user.userId : void 0;
  const [progress, setProgress] = useState(50);
  const [devicePossession, setDevicePossession] = useState(initialDevicePossession);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Add existing" }),
        onBack: refreshAppData
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsxs(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "90%",
            height: "90vh",
            sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
          }
        ),
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ width: "100%" }, spacing.mb20), children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(
                Typography,
                {
                  sx: progress > 0 ? __spreadValues({}, themeFonts.headline3) : __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }),
                  children: "Details"
                }
              ),
              /* @__PURE__ */ jsx(
                Typography,
                {
                  sx: progress >= 50 ? __spreadValues({}, themeFonts.headline3) : __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }),
                  children: "Protect"
                }
              ),
              /* @__PURE__ */ jsx(
                Typography,
                {
                  sx: progress >= 100 ? __spreadValues({}, themeFonts.headline3) : __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }),
                  children: "Sync"
                }
              )
            ] }),
            /* @__PURE__ */ jsx(
              ThemeSlider,
              {
                defaultValue: progress,
                value: progress,
                "aria-label": "Small",
                marks: [{ value: 0 }, { value: 50 }, { value: 100 }]
              }
            )
          ] }),
          progress === 0 && /* @__PURE__ */ jsx(AboutDevice, { setProgress, setDevicePossession, userId }),
          progress >= 50 && devicePossession && /* @__PURE__ */ jsx(ProtectDevice, { setProgress, devicePossession })
        ]
      }
    ) })
  ] });
};
