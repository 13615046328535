"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { MultipleSelectCheckbox } from "@v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { UserInsuranceDependants } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { getAdditionalMembersOptions } from "@v2/feature/benefits/subfeature/insurance/insurance.util";
import { UserFamilyMemberType } from "@v2/feature/user/features/user-forms/user-family/user-family.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const AddEffectiveUserCustomBenefitDrawer = ({
  isOpen,
  setIsOpen,
  userBenefit,
  refresh,
  onClose
}) => {
  const { data: userFamilyMembers } = useApiClient(
    userBenefit.customBenefit && isRecurringBenefit(userBenefit.customBenefit.type) ? UserEndpoints.getUserFamilyMembers(userBenefit.userId) : null,
    { suspense: false }
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    AddEffectiveUserCustomBenefitDrawerContent,
    {
      userBenefit,
      refresh,
      userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : [],
      onClose
    }
  ) });
};
export const AddEffectiveUserCustomBenefitDrawerContent = ({
  userBenefit,
  refresh,
  userFamilyMembers,
  onClose
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const hasOpeningBalance = isUsingOpeningBalance((_a = userBenefit.customBenefit) == null ? void 0 : _a.type);
  const isLoan = isLoanBenefit((_b = userBenefit.customBenefit) == null ? void 0 : _b.type);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2;
      const data = {
        effectiveDate: values.effectiveDate,
        employerContribution: values.employerContribution ? Number(values.employerContribution) : null,
        employeeContribution: values.employeeContribution ? Number(values.employeeContribution) : null,
        dependants: values.dependants ? values.dependants : null,
        dependantsList: values.dependants ? (_a2 = values.dependantsList) != null ? _a2 : null : null,
        openingBalance: hasOpeningBalance ? Number(values.openingBalance) : null,
        numberOfInstallments: isLoan ? Number(values.numberOfInstallments) : null
      };
      try {
        setLoading(true);
        yield CustomBenefitAPI.addUserToCustomBenefit(userBenefit.userId, userBenefit.customBenefitId, data);
        yield refresh();
        onClose();
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [
      hasOpeningBalance,
      isLoan,
      userBenefit.userId,
      userBenefit.customBenefitId,
      refresh,
      showMessage,
      polyglot,
      onClose
    ]
  );
  const formik = useFormik({
    initialValues: {
      employerContribution: (_c = userBenefit == null ? void 0 : userBenefit.employerContribution) != null ? _c : null,
      employeeContribution: (_d = userBenefit == null ? void 0 : userBenefit.employeeContribution) != null ? _d : null,
      dependants: (_e = userBenefit == null ? void 0 : userBenefit.dependants) != null ? _e : null,
      dependantsList: (_f = userBenefit == null ? void 0 : userBenefit.dependantsList) != null ? _f : null,
      openingBalance: (_g = userBenefit == null ? void 0 : userBenefit.openingBalance) != null ? _g : null,
      numberOfInstallments: (_h = userBenefit == null ? void 0 : userBenefit.numberOfInstallments) != null ? _h : null,
      effectiveDate: new LocalDate().toDateString()
    },
    validationSchema: yup.object({
      effectiveDate: yup.string().test(dateFieldTest).required(polyglot.t("ValidationMessages.requiredField")),
      employerContribution: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired(),
      employeeContribution: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired(),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup.array().of(yup.number().integer().typeError(polyglot.t("ValidationMessages.validValue"))).nullable().notRequired(),
      openingBalance: hasOpeningBalance ? yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")) : yup.number().nullable().notRequired(),
      numberOfInstallments: isLoan ? yup.number().typeError(polyglot.t("ValidationMessages.validValue")).integer(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")) : yup.number().nullable().notRequired()
    }),
    onSubmit
  });
  const dependantsListOptions = useMemo(() => {
    var _a2, _b2, _c2;
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (_a2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type)).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _a2 : [];
    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (_b2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => m.type === UserFamilyMemberType.Child).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _b2 : [];
    return (_c2 = userFamilyMembers == null ? void 0 : userFamilyMembers.map((m) => ({ value: m.id, label: m.name }))) != null ? _c2 : [];
  }, [userFamilyMembers, formik.values.dependants]);
  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];
    return formik.values.dependantsList.map((id) => {
      const familyMember = userFamilyMembers.find((m) => m.id === id);
      return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
    }).filter(Boolean);
  }, [formik.values.dependantsList, userFamilyMembers]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (_j = (_i = userBenefit.customBenefit) == null ? void 0 : _i.name) != null ? _j : polyglot.t("BenefitModule.userBenefit") }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("General.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: userBenefit.userId, sx: { mt: spacing.m5 }, nameVariant: "headline3" })
    ] }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "effectiveDate",
        label: polyglot.t("General.effectiveDate"),
        required: true,
        onChange: (value) => {
          formik.setFieldValue("effectiveDate", value);
        },
        value: formik.values.effectiveDate,
        error: formik.touched.effectiveDate && !!formik.errors.effectiveDate,
        helperText: formik.touched.effectiveDate && formik.errors.effectiveDate
      }
    ),
    hasOpeningBalance && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "openingBalance",
        label: isLoan ? polyglot.t("BenefitModule.borrowed") : polyglot.t("BenefitModule.allowance"),
        value: formik.values.openingBalance,
        onChange: formik.handleChange,
        error: formik.touched.openingBalance && !!formik.errors.openingBalance,
        helperText: (_k = formik.touched.openingBalance && formik.errors.openingBalance) != null ? _k : " ",
        endAdornment: "none"
      }
    ),
    isLoan && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "numberOfInstallments",
        label: polyglot.t("BenefitModule.numberOfInstallments"),
        value: formik.values.numberOfInstallments,
        onChange: formik.handleChange,
        error: formik.touched.numberOfInstallments && !!formik.errors.numberOfInstallments,
        helperText: (_l = formik.touched.numberOfInstallments && formik.errors.numberOfInstallments) != null ? _l : " ",
        endAdornment: "none"
      }
    ),
    !hasOpeningBalance && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employerContribution",
        label: polyglot.t("BenefitModule.employerContribution"),
        value: formik.values.employerContribution,
        onChange: formik.handleChange,
        error: formik.touched.employerContribution && !!formik.errors.employerContribution,
        helperText: (_m = formik.touched.employerContribution && formik.errors.employerContribution) != null ? _m : " ",
        endAdornment: "none"
      }
    ),
    !hasOpeningBalance && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employeeContribution",
        label: polyglot.t("BenefitModule.employeeContribution"),
        value: formik.values.employeeContribution,
        onChange: formik.handleChange,
        error: formik.touched.employeeContribution && !!formik.errors.employeeContribution,
        helperText: (_n = formik.touched.employeeContribution && formik.errors.employeeContribution) != null ? _n : " ",
        endAdornment: "none"
      }
    ),
    hasOpeningBalance ? null : userFamilyMembers.length > 0 ? /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "dependants",
        label: polyglot.t("BenefitModule.additionalMembers"),
        options: [{ label: polyglot.t("General.none"), value: "" }, ...getAdditionalMembersOptions(polyglot)],
        value: (_o = formik.values.dependants) != null ? _o : "",
        onChange: formik.handleChange,
        compareValue: formik.values.dependants,
        error: !!formik.errors.dependants && formik.touched.dependants,
        helperText: formik.touched.dependants && formik.errors.dependants
      }
    ) : /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("BenefitModule.additionalMembers") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("BenefitModule.includeAdditionalBeneficiaries") })
    ] }),
    userFamilyMembers.length > 0 && formik.values.dependants && /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        label: polyglot.t("BenefitModule.additionalMembersList"),
        id: "dependantsList",
        limitTags: -1,
        options: dependantsListOptions,
        value: selectedDependantsList,
        onChange: (_, values) => {
          const updatedOptionList = values.map(({ value }) => value);
          formik.setFieldValue("dependantsList", updatedOptionList);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("BenefitModule.addNewEffectiveRecord"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
