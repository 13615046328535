"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  UserCustomBenefitPaymentStatus
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { checkScopes } from "@/lib/scopes";
import { getBenefitPaymentStatusComponent } from "@/v2/feature/benefits/components/get-benefit-payment-status.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const UserBenefitPaymentDrawer = ({
  isOpen,
  setIsOpen,
  remainingBalance,
  userBenefitPayment,
  refresh,
  onClose,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(
    UserBenefitPaymentDrawerContent,
    {
      userBenefitPayment,
      remainingBalance,
      refresh,
      setIsOpen
    }
  ) });
};
const UserBenefitPaymentDrawerContent = ({
  userBenefitPayment,
  remainingBalance,
  refresh,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [state] = useContext(GlobalContext);
  const currentUser = state.user;
  const { getScopesContext } = useScopes();
  const { userId } = currentUser;
  const scopesContext = getScopesContext({ userId });
  const isBenefitsAdmin = checkScopes(currentUser, ["insurance:all"], scopesContext);
  const isCurrentUserInPendingApproverStep = (_d = (_c = (_b = (_a = userBenefitPayment == null ? void 0 : userBenefitPayment.approverSteps) == null ? void 0 : _a.find((s) => !s.isComplete)) == null ? void 0 : _b.approversIds) == null ? void 0 : _c.includes(userId)) != null ? _d : false;
  const canTakeActionOnRequest = isBenefitsAdmin || isCurrentUserInPendingApproverStep;
  const rejectButtonText = isBenefitsAdmin && !isCurrentUserInPendingApproverStep ? "Force reject" : "Reject";
  const approveButtonText = isBenefitsAdmin && !isCurrentUserInPendingApproverStep ? "Force approve" : "Approve";
  const approvePayment = useCallback(() => __async(void 0, null, function* () {
    if (!userBenefitPayment) return;
    try {
      setLoadingApprove(true);
      yield CustomBenefitAPI.approveOrRejectCustomBenefitPaymentById(
        userBenefitPayment.customBenefitId,
        userBenefitPayment.userId,
        userBenefitPayment.userCustomBenefitId,
        userBenefitPayment.id,
        UserCustomBenefitPaymentStatus.Approved
      );
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoadingApprove(false);
    }
  }), [userBenefitPayment, refresh, setIsOpen, showMessage, polyglot]);
  const rejectPayment = useCallback(() => __async(void 0, null, function* () {
    if (!userBenefitPayment) return;
    try {
      setLoadingReject(true);
      yield CustomBenefitAPI.approveOrRejectCustomBenefitPaymentById(
        userBenefitPayment.customBenefitId,
        userBenefitPayment.userId,
        userBenefitPayment.userCustomBenefitId,
        userBenefitPayment.id,
        UserCustomBenefitPaymentStatus.Rejected
      );
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoadingReject(false);
    }
  }), [userBenefitPayment, setIsOpen, showMessage, polyglot, refresh]);
  return userBenefitPayment ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Benefit payment requested" }),
    userBenefitPayment.userId && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Employee" }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: "5px" }, children: /* @__PURE__ */ jsx(UserAvatar, { userId: userBenefitPayment.userId, size: "s20", showName: true, nameVariant: "headline3" }) })
    ] }),
    ((_e = userBenefitPayment.customBenefit) == null ? void 0 : _e.name) && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Benefit" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: userBenefitPayment.customBenefit.name })
    ] }),
    userBenefitPayment.notes && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Notes" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: userBenefitPayment.notes })
    ] }),
    remainingBalance !== null && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Current available balance" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatMoney({ amount: remainingBalance }) })
    ] }),
    userBenefitPayment.amount && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Amount" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatMoney({ amount: userBenefitPayment.amount }) })
    ] }),
    userBenefitPayment.status && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Status" }),
      getBenefitPaymentStatusComponent(userBenefitPayment.status, __spreadValues({}, themeFonts.paragraph))
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: canTakeActionOnRequest && userBenefitPayment.status === UserCustomBenefitPaymentStatus.Pending && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: loadingReject,
          colorVariant: "secondary",
          name: rejectButtonText,
          fullWidth: true,
          onClick: rejectPayment
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading: loadingApprove,
          colorVariant: "primary",
          name: approveButtonText,
          fullWidth: true,
          onClick: approvePayment
        }
      )
    ] }) })
  ] }) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Benefit payment requested" }),
    /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "50vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    )
  ] });
};
