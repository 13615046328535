"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { AppIntegrationAPI } from "@v2/feature/app-integration/app-integration.api";
import { AppBasicAuthConnectDrawer } from "@v2/feature/app-integration/features/app-details/components/app-basic-auth-connect-drawer.component";
import { generatePath, useHistory } from "react-router-dom";
import { ScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Document } from "@/images/side-bar-icons/Document.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { nestErrorMessage } from "@/lib/errors";
import { APP_INTEGRATION_DETAILS_SETTINGS_ROUTE } from "@/lib/routes";
import { AppScopes } from "@/v2/feature/app-integration/app.scopes";
import { AppCard } from "@/v2/feature/app-integration/apps.page";
import { AppNameHeader } from "@/v2/feature/app-integration/features/app-details/components/app-name-header.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { primarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
export const AppAboutPage = ({ currentUser, appStub, app }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const { hasScopes, getScopesContext } = useScopes();
  const [basicAuthConnectDrawerOpen, setBasicAuthConnectDrawerOpen] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [showMessage] = useMessage();
  const initiateOauthFlowForApp = (event, appStub2) => {
    if (event) event.preventDefault();
    window.location.href = AppIntegrationAPI.getInitiateUrl(appStub2);
  };
  const getActionForConnect = (event) => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    try {
      const canConnect = yield AppIntegrationAPI.appConnectAllowed();
      if (canConnect) {
        if ((app == null ? void 0 : app.authorised) && !((_a2 = app == null ? void 0 : app.directory) == null ? void 0 : _a2.oauth))
          return routerHistory.push(generatePath(APP_INTEGRATION_DETAILS_SETTINGS_ROUTE, { appStub }));
        if (!(app == null ? void 0 : app.authorised) && !((_b2 = app == null ? void 0 : app.directory) == null ? void 0 : _b2.oauth)) return setBasicAuthConnectDrawerOpen(true);
        if ((_c2 = app == null ? void 0 : app.directory) == null ? void 0 : _c2.oauth) return initiateOauthFlowForApp(event, appStub);
      }
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    }
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(AppNameHeader, { title: polyglot.t("AppAboutPage.title"), app }),
        showAction: !(app == null ? void 0 : app.authorised) && currentUser && hasScopes(AppScopes.CREATE_APP_INTEGRATION, { userId: currentUser.userId }),
        actions: appStub !== "quickbooks" ? /* @__PURE__ */ jsx(
          ScopesControl,
          {
            scopes: ["apps.connect", "apps:all"],
            context: getScopesContext({ userId: state.user.userId }),
            children: /* @__PURE__ */ jsx(
              Button,
              {
                sx: primarySmallBtn,
                onClick: (event) => __async(void 0, null, function* () {
                  getActionForConnect(event);
                }),
                children: "Connect"
              }
            )
          }
        ) : /* @__PURE__ */ jsx(
          ScopesControl,
          {
            scopes: ["apps.connect", "apps:all"],
            context: getScopesContext({ userId: state.user.userId }),
            children: /* @__PURE__ */ jsx(
              Button,
              {
                variant: "text",
                disableRipple: true,
                sx: {
                  width: "200px",
                  ml: 1,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent"
                  }
                },
                onClick: (event) => {
                  getActionForConnect(event);
                },
                startIcon: /* @__PURE__ */ jsx("img", { src: "/app-icons-v2/images/ctqb_transparent_btn.png", alt: appStub, width: "200px" })
              }
            )
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "600px" }, children: [
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: [
          "About ",
          app == null ? void 0 : app.name
        ] }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mt: "10px" }), children: app == null ? void 0 : app.shortDescription }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey, mt: "30px" }), children: "Features" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: /* @__PURE__ */ jsx("ul", { style: { listStyle: "none", paddingLeft: 0 }, children: app == null ? void 0 : app.features.map((eachFeature) => {
          return /* @__PURE__ */ jsxs("li", { style: { lineHeight: "30px" }, children: [
            /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
            "\xA0\xA0",
            eachFeature
          ] });
        }) }) }),
        (app == null ? void 0 : app.externalLinks) && ((_a = app == null ? void 0 : app.externalLinks) == null ? void 0 : _a.length) > 0 && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey, mt: "30px" }), children: "About this integration" }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mt: "10px" }), children: (app == null ? void 0 : app.externalLinks) && ((_b = app == null ? void 0 : app.externalLinks) == null ? void 0 : _b.length) > 0 && ((_c = app == null ? void 0 : app.externalLinks) == null ? void 0 : _c.map((eachLink, idx) => {
          return /* @__PURE__ */ jsx(
            AppCard,
            {
              title: eachLink.type === "support" ? "Support article" : "Marketplace listing",
              subtitle: eachLink.type === "support" ? "Helpful post about this integration" : `Check this integration on ${app.name} marketplace`,
              icon: /* @__PURE__ */ jsx(Document, __spreadValues({}, iconSize)),
              action: () => window.open(eachLink.link, "_blank"),
              stub: "",
              showBorder: true,
              buttonText: "Read"
            },
            `${eachLink.link}-${idx}`
          );
        })) })
      ] }),
      /* @__PURE__ */ jsx(
        UpgradeToProModal,
        {
          isOpen: upgradeModalOpen,
          setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
          planName: PlanNames.TECH_PRO,
          messageSuffix: "proGeneric"
        }
      ),
      !((_d = app == null ? void 0 : app.directory) == null ? void 0 : _d.oauth) && /* @__PURE__ */ jsx(
        AppBasicAuthConnectDrawer,
        {
          isOpen: basicAuthConnectDrawerOpen,
          setIsOpen: setBasicAuthConnectDrawerOpen,
          appDetails: app,
          currentUser
        }
      )
    ] })
  ] });
};
