"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box, FormControl } from "@mui/material";
import { QuestionComment, FocusState } from "./question-comment.component";
import { useQuestionFocus } from "../../hooks/use-question-focus.hook";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MultipleChoiceQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  showError,
  isFocused,
  registerMethods
}) => {
  var _a;
  const { currentAnswer, currentComment } = useMemo(() => {
    const answerObject = (answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) || { answer: "", comment: "" };
    return { currentAnswer: answerObject.answer, currentComment: answerObject.comment };
  }, [answerArray, question.id]);
  const selectedOptions = useMemo(() => {
    var _a2;
    return (_a2 = currentAnswer == null ? void 0 : currentAnswer.split(",").filter((v) => v)) != null ? _a2 : [];
  }, [currentAnswer]);
  const optionKeys = useMemo(() => {
    return (question == null ? void 0 : question.answerOptions) ? Object.keys(question.answerOptions) : [];
  }, [question == null ? void 0 : question.answerOptions]);
  const handleChoiceChange = useCallback(
    (value) => {
      if (!entry) return;
      handleAnswerChange(question.id, { updatedAnswer: value, comment: currentComment }, entry);
    },
    [currentComment, question.id, entry, handleAnswerChange]
  );
  const toggleOption = useCallback(
    (key) => {
      var _a2;
      let finalArray = [];
      let selectedValues = (_a2 = currentAnswer == null ? void 0 : currentAnswer.split(",").filter((v) => v)) != null ? _a2 : [];
      if (selectedValues.includes(key)) {
        finalArray = selectedValues.filter((value) => value !== key);
      } else {
        finalArray = [...selectedValues, key];
      }
      handleChoiceChange(finalArray.join(","));
    },
    [currentAnswer, handleChoiceChange]
  );
  const handleCommentChange = useCallback(
    (value) => {
      if (!entry) return;
      handleAnswerChange(question.id, { updatedAnswer: currentAnswer, comment: value }, entry);
    },
    [currentAnswer, entry, question.id, handleAnswerChange]
  );
  const {
    hasKeyboardFocus,
    highlightedOptionIndex,
    setHighlightedOptionIndex,
    focusState,
    setFocusState,
    registerCommentFieldRef,
    registerOptionRef,
    handleContainerKeyDown,
    handleCommentKeyDown,
    containerRef
  } = useQuestionFocus({
    question,
    optionKeys,
    isFocused,
    registerMethods,
    handleOptionAction: toggleOption
  });
  const getCheckboxStyles = useCallback(
    (key, index) => {
      const isHighlighted = hasKeyboardFocus && focusState === FocusState.OPTIONS && highlightedOptionIndex === index;
      return {
        "& .MuiCheckbox-root": {
          outline: isHighlighted ? `1px dashed ${themeColors.DarkGrey}` : "none",
          borderRadius: "4px",
          transition: "all 0.2s ease"
        }
      };
    },
    [hasKeyboardFocus, highlightedOptionIndex, focusState]
  );
  if (!question || !reviewType || !entry) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    Box,
    {
      ref: containerRef,
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g24,
        outline: "none"
      },
      onKeyDown: handleContainerKeyDown,
      tabIndex: 0,
      "data-testid": "multiple-choice-container",
      children: [
        /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%", margin: 0 }, error: false, disabled: false, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: (question == null ? void 0 : question.answerOptions) && ((_a = Object.keys(question == null ? void 0 : question.answerOptions)) == null ? void 0 : _a.map((a, index) => /* @__PURE__ */ jsx(
          Box,
          {
            sx: { padding: "5px" },
            onClick: () => {
              setFocusState(FocusState.OPTIONS);
              setHighlightedOptionIndex(index);
              if (containerRef.current) {
                containerRef.current.focus();
              }
            },
            children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                name: a,
                label: question == null ? void 0 : question.answerOptions[a],
                disabled: false,
                checked: Boolean(selectedOptions.find((s) => s === a)),
                onChange: () => toggleOption(a),
                inputRef: (el) => registerOptionRef(a, el),
                onFocus: () => {
                  setFocusState(FocusState.OPTIONS);
                  setHighlightedOptionIndex(index);
                  if (containerRef.current) {
                    containerRef.current.focus();
                  }
                },
                tabIndex: -1,
                sx: getCheckboxStyles(a, index)
              }
            )
          },
          a
        ))) }) }),
        showError && !currentAnswer && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: showError ? themeColors.darkRed : themeColors.DarkGrey }, children: "This field is required" }),
        question.hasComment && /* @__PURE__ */ jsx(
          QuestionComment,
          {
            question,
            currentComment,
            handleCommentChange,
            showError,
            registerCommentFieldRef,
            setFocusState,
            onKeyDown: handleCommentKeyDown
          }
        )
      ]
    }
  );
};
