"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, FormControl, List, ListItem } from "@mui/material";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { USER_OFFBOARDING_ROUTE } from "@/lib/routes";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { OffboardingAPI } from "@/v2/feature/offboarding/offboarding.api";
import { getOffboardingTask, getOffboardingTaskIcon } from "@/v2/feature/offboarding/offboarding.util";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { getAllDefaultTerminationChangeReasonOptions } from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { spacing } from "@/v2/styles/spacing.styles";
const getOffboardingSchema = (polyglot) => Yup.object().shape({
  userId: Yup.number().nullable().required(polyglot.t("OffboardingDrawer.errorMessages.employeeRequired")),
  terminationDate: Yup.string().test(dateFieldTest).required(polyglot.t("OffboardingDrawer.errorMessages.endDateRquired")),
  changeReason: Yup.string(),
  note: Yup.string().nullable()
});
export const OffboardingDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  terminationUserEvent = null,
  pushToOffboarding = true,
  refresh = void 0
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [duesLoading, setDuesLoading] = useState(false);
  const [dues, setDues] = useState(void 0);
  const [existingChangeReasons, setExistingChangeReasons] = useState([]);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const formik = useFormik({
    initialValues: {
      terminationDate: (_a = terminationUserEvent == null ? void 0 : terminationUserEvent.effectiveDate) != null ? _a : new LocalDate().toDateString(),
      changeReason: (_b = terminationUserEvent == null ? void 0 : terminationUserEvent.changeReason) != null ? _b : "other",
      note: (_c = terminationUserEvent == null ? void 0 : terminationUserEvent.note) != null ? _c : null,
      userId
    },
    enableReinitialize: true,
    validationSchema: getOffboardingSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        if (!values.userId) return;
        setLoading(true);
        yield OffboardingAPI.postUserOffboard(values.userId, values);
        if (refresh) yield refresh();
        if (pushToOffboarding)
          routerHistory.push({
            pathname: generatePath(USER_OFFBOARDING_ROUTE, { userId: values.userId }),
            state: { values }
          });
        showMessage(polyglot.t("OffboardingDrawer.successMessages.offboard"), "success");
        setIsOpen(false);
      } catch (error) {
        showMessage(`${polyglot.t("OffboardingDrawer.errorMessages.offboard")}. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const changeReasonOptions = useMemo(() => {
    const defaultOptions = getAllDefaultTerminationChangeReasonOptions(polyglot);
    return [...defaultOptions, ...existingChangeReasons.map((value) => ({ label: value, value }))].sort(
      (a, b) => (a == null ? void 0 : a.label) && (b == null ? void 0 : b.label) && a.label > b.label ? 1 : -1
    );
  }, [polyglot, existingChangeReasons]);
  const getOffboardingDetail = useCallback(() => __async(void 0, null, function* () {
    try {
      if (formik.values.userId) {
        setDuesLoading(true);
        const offboardingDues = yield OffboardingAPI.getOffboardingDues(formik.values.userId);
        setDues(offboardingDues);
      }
    } catch (error) {
      showMessage(`${polyglot.t("OffboardingDrawer.errorMessages.fetch")}. ${nestErrorMessage(error)}`, "error");
    } finally {
      setDuesLoading(false);
    }
  }), [polyglot, formik.values.userId, showMessage]);
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const changeReasons = yield OffboardingAPI.getOffboardingChangeReasons();
        setExistingChangeReasons(changeReasons);
      } catch (error) {
        console.error(error);
      }
    }))();
  }, []);
  useEffect(() => {
    getOffboardingDetail();
  }, [getOffboardingDetail]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("OffboardingDrawer.terminateEmployee") }),
    !userId && /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: "company",
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("userId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        value: formik.values.userId,
        label: polyglot.t("OffboardingDrawer.employee"),
        error: formik.touched.userId && Boolean(formik.errors.userId),
        helperText: formik.touched.userId && formik.errors.userId
      }
    ),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_d = formik.values.terminationDate) != null ? _d : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("terminationDate", value);
          }
        },
        name: "terminationDate",
        label: polyglot.t("OffboardingDrawer.terminationDate"),
        error: !!formik.errors.terminationDate && formik.touched.terminationDate,
        helperText: formik.errors.terminationDate && formik.touched.terminationDate
      }
    ) }),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "changeReason",
        label: polyglot.t("OffboardingDrawer.changeReason"),
        options: changeReasonOptions,
        value: (_g = changeReasonOptions.find((d) => d.value === formik.values.changeReason)) != null ? _g : {
          label: (_e = formik.values.changeReason) != null ? _e : "",
          value: (_f = formik.values.changeReason) != null ? _f : ""
        },
        freeSolo: true,
        onBlur: (e) => {
          var _a2, _b2;
          const value = (_b2 = (_a2 = e.target) == null ? void 0 : _a2.value) != null ? _b2 : "";
          formik.setFieldValue("changeReason", value);
        },
        onChange: (_, x) => {
          var _a2;
          const value = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : "";
          formik.setFieldValue("changeReason", value);
        },
        error: !!formik.touched.changeReason && !!formik.errors.changeReason,
        helperText: formik.touched.changeReason && formik.errors.changeReason
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "note",
        label: polyglot.t("OffboardingDrawer.note"),
        value: formik.values.note,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.note && !!formik.errors.note,
        helperText: (_h = formik.touched.note && formik.errors.note) != null ? _h : " ",
        clearText: () => formik.setFieldValue("note", "")
      }
    ),
    !terminationUserEvent && dues && Object.keys(dues).length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("OffboardingDrawer.tasks") }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: (_i = Object.keys(dues)) == null ? void 0 : _i.map((key) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
        getOffboardingTaskIcon(key),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: getOffboardingTask(key, dues[key]) })
      ] }, key)) })
    ] }),
    terminationUserEvent && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Updating the termination date may require adjustments to the following:" }),
      /* @__PURE__ */ jsxs(List, { dense: true, sx: { listStyleType: "disc", pl: spacing.s4, py: 0, mt: 0 }, children: [
        /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item", py: 0, my: 0 }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Zelt account deactivation" }) }),
        /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Connected apps deactivation" }) }),
        /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Device return shipment" }) }),
        /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Tasks due dates" }) }),
        /* @__PURE__ */ jsx(ListItem, { sx: { display: "list-item" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Accrued holiday pay" }) })
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Ensure you apply all relevant changes for this Leaver." })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: terminationUserEvent ? polyglot.t("General.update") : polyglot.t("OffboardingDrawer.offboard"),
        loading,
        fullWidth: true,
        disabled: duesLoading,
        colorVariant: "primary",
        sizeVariant: "medium"
      }
    ) })
  ] }) }) });
};
