"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DevicesTable } from "@v2/feature/device/components/devices-table/devices-table.component";
import { DeviceOrderDrawer } from "@v2/feature/device/features/devices-company/components/device-order-drawer.component";
import { OffboardingDeviceGenericDrawer } from "@v2/feature/offboarding/offboarding-process/devices/offboarding-device-generic-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { generatePath, useHistory } from "react-router-dom";
import { USER_DEVICES_PAGE, USER_OFFBOARDING_ROUTE } from "@/lib/routes";
import { DeviceEndpoints } from "@/v2/feature/device/device.api";
export const OffboardingDevicesPage = ({ userId, refresh, loading }) => {
  var _a;
  const {
    data: devices,
    mutate: refreshDevicesList,
    isLoading: devicesLoading
  } = useApiClient(DeviceEndpoints.getOffboardingDevices(userId), { suspense: false });
  const { polyglot } = usePolyglot();
  const [selectedDeviceRow, setSelectedDeviceRow] = useState(null);
  const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState(false);
  const [isGenericDrawerOpen, setIsGenericDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const routerHistory = useHistory();
  const filteredDevicePossessions = useMemo(() => {
    if (!searchInput || !devices) return devices != null ? devices : [];
    const search = searchInput.toLowerCase();
    return devices.filter(
      (d) => {
        var _a2, _b, _c, _d, _e, _f;
        return ((_c = (_b = (_a2 = d.devicePossession) == null ? void 0 : _a2.device) == null ? void 0 : _b.modelName) == null ? void 0 : _c.toLowerCase().includes(search)) || ((_f = (_e = (_d = d.devicePossession) == null ? void 0 : _d.device) == null ? void 0 : _e.serialNumber) == null ? void 0 : _f.includes(search));
      }
    );
  }, [devices, searchInput]);
  const refreshDevices = useCallback(() => __async(void 0, null, function* () {
    if (refreshDevicesList) yield refreshDevicesList();
  }), [refreshDevicesList]);
  const refreshAll = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refresh(), refreshDevices()]);
  }), [refreshDevices, refresh]);
  const handleRowClick = useCallback(
    (row) => {
      setSelectedDeviceRow(row.original);
      routerHistory.push(generatePath(USER_DEVICES_PAGE, { userId }));
    },
    [routerHistory, userId]
  );
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("OffboardingPage.devices") }),
        showBack: true,
        backPath: generatePath(USER_OFFBOARDING_ROUTE, { userId })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsx(
        TableSearch,
        {
          query: searchInput,
          handleChange: (e) => {
            setSearchInput(e.target.value);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        DevicesTable,
        {
          devices: filteredDevicePossessions,
          handleRowClick,
          loading: devicesLoading,
          hiddenColumns: ["modelNumber", "customerNotes", "osVersion", "storage"],
          refresh: refreshAll
        }
      ),
      /* @__PURE__ */ jsx(
        DeviceOrderDrawer,
        {
          isOpen: isOrderDrawerOpen,
          setIsOpen: setIsOrderDrawerOpen,
          onClose: () => {
            setSelectedDeviceRow(null);
          },
          deviceOrder: (selectedDeviceRow == null ? void 0 : selectedDeviceRow.order) ? __spreadProps(__spreadValues({}, selectedDeviceRow.order), {
            possession: selectedDeviceRow.devicePossession
          }) : null,
          refresh: refreshAll,
          reach: "offboarding",
          sitesById: (_a = selectedDeviceRow == null ? void 0 : selectedDeviceRow.sites.reduce((dict, site) => {
            dict[site.id] = site;
            return dict;
          }, {})) != null ? _a : {}
        }
      ),
      /* @__PURE__ */ jsx(
        OffboardingDeviceGenericDrawer,
        {
          isOpen: isGenericDrawerOpen,
          setIsOpen: setIsGenericDrawerOpen,
          device: selectedDeviceRow != null ? selectedDeviceRow : null,
          onClose: () => {
            setSelectedDeviceRow(null);
          }
        }
      )
    ] })
  ] });
};
