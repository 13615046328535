"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as CalendarBig } from "@/images/reports/CalendarBig.svg";
import { ReactComponent as ReportBig } from "@/images/reports/ReportBig.svg";
import { ReactComponent as TableBig } from "@/images/reports/TableBig.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  IntroCardGroup
} from "@/v2/components/theme-components/icon-content-action-card.component";
import { parentCardSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReportsEmptyState = ({ onClick }) => {
  const { polyglot } = usePolyglot();
  const ReportsCards = [
    {
      title: polyglot.t("ReportsEmptyState.buildYourOwn"),
      content: polyglot.t("ReportsEmptyState.buildYourOwnDesc"),
      iconMedia: /* @__PURE__ */ jsx(ReportBig, {})
    },
    {
      title: polyglot.t("ReportsEmptyState.stayOnTop"),
      content: polyglot.t("ReportsEmptyState.stayOnTopDesc"),
      iconMedia: /* @__PURE__ */ jsx(CalendarBig, {})
    },
    {
      title: polyglot.t("ReportsEmptyState.previewData"),
      content: polyglot.t("ReportsEmptyState.previewDataDesc"),
      iconMedia: /* @__PURE__ */ jsx(TableBig, {})
    }
  ];
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({}, spacing.px40), {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between"
      }),
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: parentCardSx, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ReportsEmptyState.create") }),
          /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsxs(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick, disabled: true, children: [
            polyglot.t("ReportsEmptyState.new"),
            " ",
            /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "(deprecated)" })
          ] }) })
        ] }),
        /* @__PURE__ */ jsx(IntroCardGroup, { cards: ReportsCards, sx: { mt: "auto" } })
      ]
    }
  );
};
