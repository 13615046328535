"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useMemo } from "react";
import { Box } from "@mui/material";
import { Divider } from "@v2/components/divider.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { AbsenceLengthUnit } from "@v2/feature/absence/absence.interface";
import {
  getAccrualBreakdownValue,
  getAccrualCliffReason,
  getAccrualRoundingAdjustment,
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getOneOffAdjustmentFromBreakdown
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { ReactComponent as Warning } from "@/images/side-bar-icons/Warning.svg";
import { Typography } from "@/v2/components/typography/typography.component";
export const AccrualDrawer = ({ isOpen, setIsOpen, absencePolicy, policyBreakdown }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(AccrualDrawerContent, { absencePolicy, policyBreakdown })
    }
  ) });
};
const AccrualDrawerContent = ({ absencePolicy, policyBreakdown }) => {
  const { polyglot } = usePolyglot();
  const {
    totalAccrual,
    accruedFromAllowance,
    accruedFromPH,
    accrualStart,
    accrualEnd,
    carriedOver,
    expiredCarryOver,
    oneOffAdjustments,
    roundingAdjustment,
    accrualCliffReason,
    toil
  } = useMemo(() => {
    var _a, _b, _c, _d;
    const totalAccrual2 = getAccrualBreakdownValue(absencePolicy, policyBreakdown, "total", polyglot);
    const accruedFromAllowance2 = getAccrualBreakdownValue(absencePolicy, policyBreakdown, "regular", polyglot, true);
    const accruedFromPH2 = getAccrualBreakdownValue(absencePolicy, policyBreakdown, "publicHolidays", polyglot, true);
    const accrualCliffReason2 = getAccrualCliffReason(policyBreakdown, polyglot);
    const carriedOver2 = ((_a = policyBreakdown.carryOver) == null ? void 0 : _a.allowedUnitsThisCycle) ? getCarryOverFromBreakdown(absencePolicy, policyBreakdown, polyglot, true) : null;
    const expiredCarryOver2 = ((_b = policyBreakdown.carryOver) == null ? void 0 : _b.expiredCarryOver) ? getExpiredCarryOver(absencePolicy, policyBreakdown, polyglot, true) : null;
    const oneOffAdjustments2 = policyBreakdown.oneOffAdjustment ? getOneOffAdjustmentFromBreakdown(absencePolicy, policyBreakdown, polyglot, true) : null;
    const accrualStart2 = policyBreakdown.proRatedAbsenceAllowance.accrualStartDate ? new LocalDate(policyBreakdown.proRatedAbsenceAllowance.accrualStartDate).toLocaleDateString() : null;
    const accrualEnd2 = policyBreakdown.proRatedAbsenceAllowance.accrualEndDate ? new LocalDate(policyBreakdown.proRatedAbsenceAllowance.accrualEndDate).toLocaleDateString() : null;
    const roundingAdjustment2 = getAccrualRoundingAdjustment(absencePolicy, policyBreakdown, polyglot, true);
    const showBalanceInDays = Boolean(policyBreakdown.lengthUnit === AbsenceLengthUnit.Day);
    const toil2 = ((_c = policyBreakdown == null ? void 0 : policyBreakdown.toil) == null ? void 0 : _c.toilValueInMinutes) && showBalanceInDays ? policyBreakdown.toil.toilValueInMinutes / policyBreakdown.currentAverageWorkDayLength : ((_d = policyBreakdown == null ? void 0 : policyBreakdown.toil) == null ? void 0 : _d.toilValueInMinutes) && !showBalanceInDays ? policyBreakdown.toil.toilValueInMinutes / 60 : null;
    return {
      totalAccrual: totalAccrual2,
      accruedFromAllowance: accruedFromAllowance2,
      accruedFromPH: accruedFromPH2,
      accrualStart: accrualStart2,
      accrualEnd: accrualEnd2,
      carriedOver: carriedOver2,
      expiredCarryOver: expiredCarryOver2,
      oneOffAdjustments: oneOffAdjustments2,
      roundingAdjustment: roundingAdjustment2,
      accrualCliffReason: accrualCliffReason2,
      toil: toil2
    };
  }, [absencePolicy, policyBreakdown, polyglot]);
  return absencePolicy.allowance !== null && policyBreakdown.holidayAccrued !== null ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AllowanceDrawer.accruedSoFar") }),
    accrualStart !== null && /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.accrualCycleStart"), value: accrualStart }),
    accrualEnd !== null && /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.accrualCycleEnd"), value: accrualEnd }),
    /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.accruedAllowance"), value: accruedFromAllowance }),
    /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.accruedPH"), value: accruedFromPH }),
    roundingAdjustment !== null && /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.accrualRounding"), value: roundingAdjustment }),
    accrualCliffReason !== null && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.s1, alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Warning, { width: 16, height: 16, style: { fill: themeColors.orange } }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: accrualCliffReason })
    ] }),
    oneOffAdjustments !== null && /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.adjustments"), value: oneOffAdjustments }),
    carriedOver !== null && /* @__PURE__ */ jsx(
      PolicyLine,
      {
        label: polyglot.t("AllowanceDrawer.carriedOverInto", { year: policyBreakdown.holidayYear }),
        value: carriedOver
      }
    ),
    expiredCarryOver !== null && /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.carryOverExpired"), value: expiredCarryOver }),
    toil !== null && /* @__PURE__ */ jsx(
      PolicyLine,
      {
        label: polyglot.t("AllowanceDrawer.toilLong"),
        value: polyglot.t("AllowanceDrawer.noOfDays", { smart_count: toil != null ? toil : 0 })
      }
    ),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(PolicyLine, { label: polyglot.t("AllowanceDrawer.accruedSoFar"), value: totalAccrual })
  ] }) : null;
};
const PolicyLine = ({
  label,
  value,
  boldLabel = false,
  sx = {}
}) => value !== null ? /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ width: "100%", display: "flex", justifyContent: "space-between" }, sx), children: [
  /* @__PURE__ */ jsx(Typography, { variant: boldLabel ? "headline3" : "paragraph", children: label }),
  /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: value })
] }) : null;
