"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
const _PayrollAPI = class _PayrollAPI {
  static addUserPayrollRecord(userId, payrollRecord) {
    return __async(this, null, function* () {
      yield axios.post(`/apiv2/users/${userId}/payroll`, payrollRecord);
    });
  }
  static updateUserPayrollRecord(userId, updatedPayrollRecord) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/users/${userId}/payroll`, updatedPayrollRecord);
    });
  }
  static getActiveUserPayroll(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/payroll`)).data || null;
    });
  }
  static getUserPayrollSchema(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/payroll/schema`)).data || null;
    });
  }
  static getPayrollSchemaForCountry(countryCode) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/schemas/${countryCode}`)).data || null;
    });
  }
  static getUserPayrollRecords(userId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/users/${userId}/payrolls`)).data;
    });
  }
  static updateAccounting(payrollId, accounting) {
    return __async(this, null, function* () {
      return (yield axios.put(`/apiv2/company/payroll/accounting?payrollId=${payrollId}`, accounting)).data;
    });
  }
  static getAccounting(payrollId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/accounting?payrollId=${payrollId}`)).data;
    });
  }
  static initiateAccountingAppConnection(appStub, payrollId) {
    return `/apiv2/company/payroll/accounting/app/${appStub}/initiate?payrollId=${payrollId}`;
  }
  static assignAccountingAppToPayroll(payrollId, config) {
    return __async(this, null, function* () {
      yield axios.put(`/apiv2/company/payroll/accounting/app/link?payrollId=${payrollId}`, config);
    });
  }
  static disconnectAccountingApp(payrollId) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/company/payroll/accounting/app?payrollId=${payrollId}`);
    });
  }
  static getPayrollNominalCodes(payrollId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/nominal-code?payrollId=${payrollId}`)).data;
    });
  }
  static refreshNominalCodes(payrollId) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/payroll/nominal-code/refresh?payrollId=${payrollId}`)).data;
    });
  }
  static getPayroll() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/company/payroll")).data || void 0;
    });
  }
  static getCompanyPayrolls() {
    return __async(this, null, function* () {
      return (yield axios.get("/apiv2/company/payroll/all")).data;
    });
  }
  static getCompanyPayrollsAsSuperAdmin(companyId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/superadmin/companies/${companyId}/all`)).data;
    });
  }
  static setPayrollTestMode(payrollId, testMode) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/company/payroll/test-mode?payrollId=${payrollId}`, { testMode });
    });
  }
  static setPayrollPayslipPreview(payrollId, payslipPreview) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/company/payroll/payslip-preview?payrollId=${payrollId}`, { payslipPreview });
    });
  }
  static getEmploymentAllowance(payrollId) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/company/payroll/employment-allowance?payrollId=${payrollId}`)).data;
    });
  }
  static requestEmploymentAllowance(payrollId, allowance) {
    return __async(this, null, function* () {
      return axios.post(`/apiv2/company/payroll/employment-allowance?payrollId=${payrollId}`, { allowance });
    });
  }
  static adjustEmploymentAllowance(payrollId, allowance) {
    return __async(this, null, function* () {
      return axios.patch(`/apiv2/company/payroll/employment-allowance?payrollId=${payrollId}`, { allowance });
    });
  }
  static createCompanyPaycode(payrollId, code) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/payroll/paycodes/${payrollId}`, { code })).data;
    });
  }
  static updateCompanyPaycodes(payrollId, codes) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/company/payroll/paycodes/${payrollId}`, { codes });
    });
  }
  static deleteCompanyPaycode(payrollId, code) {
    return __async(this, null, function* () {
      yield axios.delete(`/apiv2/company/payroll/paycodes/${payrollId}?code=${code}`);
    });
  }
  // static async addUserToPayroll(userId: number): Promise<void> {
  //   await axios.post(`/apiv2/company/payroll/member`, { userId });
  // }
  // static async removeUserFromPayroll(userId: number): Promise<void> {
  //   await axios.delete(`/apiv2/company/payroll/member/${userId}`);
  // }
  static addUsersToPayroll(payrollId, userIds) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/payroll/${payrollId}/member/add`, { userIds })).data;
    });
  }
  static removeUsersFromPayroll(payrollId, userIds) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/company/payroll/${payrollId}/member/remove`, { userIds })).data;
    });
  }
  static setGeneralSettings(payrollId, values) {
    return __async(this, null, function* () {
      return (yield axios.patch(`/apiv2/company/payroll/settings/general?payrollId=${payrollId}`, values)).data;
    });
  }
};
/**
 * Fetch the list of payrun entries for this user directly from Staffology
 */
_PayrollAPI.getUserPayruns = (userId) => __async(_PayrollAPI, null, function* () {
  return (yield axios.get(`/apiv2/users/${userId}/payroll/payruns`)).data;
});
export let PayrollAPI = _PayrollAPI;
export class PayrollEndpoints {
  static getActiveUserPayroll(userId) {
    return {
      url: `/apiv2/users/${userId}/payroll`
    };
  }
  static getUserPayrolls(userId) {
    return {
      url: `/apiv2/users/${userId}/payrolls`
    };
  }
  static requestEmploymentAllowanceProgress(payrollId) {
    return {
      url: `/apiv2/company/payroll/employment-allowance/progress?payrollId=${payrollId}`
    };
  }
  static getCompanyPaycodes(payrollId, excludeSystemAndControl) {
    return {
      url: `/apiv2/company/payroll/paycodes/${payrollId}?excludeSystemAndControl=${excludeSystemAndControl}`
    };
  }
  static getEntityPaycodes(companyId) {
    return {
      url: `/apiv2/company/payroll/entities/${companyId}/paycodes`
    };
  }
  static countPayrunsByPayrollId(payrollId) {
    return {
      url: `/apiv2/payroll/payruns/payrolls/${payrollId}/count`
    };
  }
  // Returns config for payroll the current user is enrolled in
  static getCurrentUserPayrollConfig() {
    return {
      url: "/apiv2/company/payroll/current-user-payroll/config"
    };
  }
  static getAccounting(payrollId) {
    return {
      url: `/apiv2/company/payroll/accounting?payrollId=${payrollId}`
    };
  }
  static getNominalCodes(payrollId) {
    return {
      url: `/apiv2/company/payroll/nominal-code?payrollId=${payrollId}`
    };
  }
  static getPayrollSchemaForCountry(countryCode) {
    return {
      url: `/apiv2/company/payroll/schemas/${countryCode}`
    };
  }
  static getCompanyPayroll(payrollId) {
    return {
      url: `/apiv2/company/payroll?payrollId=${payrollId}`
    };
  }
  static getCompanyPayrolls(optionalPayrollId) {
    return {
      url: `/apiv2/company/payroll/all${optionalPayrollId ? `?payrollId=${optionalPayrollId}` : ""}`
    };
  }
  static getCompanyCurrentPayrollId() {
    return {
      url: "/apiv2/company/payroll/current"
    };
  }
  static getCompanyPayrollOverview() {
    return {
      url: "/apiv2/company/payroll/overview"
    };
  }
  static getApprovedAttendanceEntriesWithoutPaycodeForNextPayrun(payrollId) {
    return {
      url: `/apiv2/company/payroll/attendance-entries-without-paycode/next-payrun?payrollId=${payrollId}`
    };
  }
  static getSubmittedAttendanceEntriesForPayrun(payrollId, payrunType) {
    return {
      url: `/apiv2/company/payroll/submitted-attendance-entries?payrollId=${payrollId}&payrunType=${payrunType}`
    };
  }
}
