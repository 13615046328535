"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { keyBy, uniq } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Drag } from "@/images/growth/Drag.svg";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewTemplateAPI, ReviewTemplateEndpoints } from "@/v2/feature/growth/reviews/api-client/review-template.api";
import { PlaceholderBox } from "@/v2/feature/growth/reviews/features/components/placeholder-box.component";
import { QuestionBuilder } from "@/v2/feature/growth/reviews/features/components/question-builder.component";
import { CycleCreationQuestionPreviewer } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/cycle-creation-question-previewer.component";
import { QuestionSelectionModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-questions/components/question-selection-modal.component";
import { ReviewTemplateMoveToSectionModal } from "@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-move-to-section-modal.component";
import { ReviewTemplateQuestionModal } from "@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-question-modal.component";
import { ReviewTemplateSectionModal } from "@/v2/feature/growth/reviews/features/review-template/review-template-builder/components/review-template-section-modal.component";
import { useTemplateQuestionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-template-question-options.hook";
import { useTemplateSectionOptions } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-template-section-options.hook";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewTemplateBuilderPage = ({
  templateObject,
  refreshTemplate,
  templateLoading
}) => {
  var _a, _b;
  const params = useParams();
  const templateId = params.templateId;
  const { data: bankCount } = useApiClient(ReviewTemplateEndpoints.getBankCount(), { suspense: false });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const [question, setQuestion] = useState(null);
  const [section, setSection] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState(false);
  const [isPreviewerOpen, setIsPreviewerOpen] = useState(false);
  const [previewReviewer, setPreviewReviewer] = useState(void 0);
  const [template, setTemplate] = useState(void 0);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  useEffect(() => {
    if (templateObject) {
      setTemplate(templateObject.template);
    }
  }, [templateObject]);
  const questions = useMemo(() => (templateObject == null ? void 0 : templateObject.questions) || [], [templateObject == null ? void 0 : templateObject.questions]);
  const sections = useMemo(() => (templateObject == null ? void 0 : templateObject.sections) || [], [templateObject == null ? void 0 : templateObject.sections]);
  const getReviewerSelects = useMemo(() => {
    if (!questions) return [];
    const allReviewerSelects = questions == null ? void 0 : questions.flatMap((q) => q.reviewerSelect);
    return uniq(allReviewerSelects);
  }, [questions]);
  const questionById = useMemo(() => keyBy((templateObject == null ? void 0 : templateObject.questions) || [], "id"), [templateObject == null ? void 0 : templateObject.questions]);
  const sectionById = useMemo(() => keyBy((templateObject == null ? void 0 : templateObject.sections) || [], "id"), [templateObject == null ? void 0 : templateObject.sections]);
  const clearSelection = () => {
    setQuestion(null);
    setSection(null);
  };
  const addQuestionsToTemplateHandle = (selectedQuestionIds) => __async(void 0, null, function* () {
    try {
      yield ReviewTemplateAPI.addQuestionsToTemplate([...selectedQuestionIds], templateId);
      refreshTemplate == null ? void 0 : refreshTemplate();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  });
  const moveQuestionInsideSection = (sectionId, questionId) => __async(void 0, null, function* () {
    try {
      yield ReviewTemplateAPI.moveQuestionInsideSection({ templateId, sectionId, questionId });
      refreshTemplate == null ? void 0 : refreshTemplate();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  });
  const getReviewerActionsOptions = useCallback(() => {
    return getReviewerSelects.map((type) => ({
      handler: () => {
        setIsPreviewerOpen(true);
        setPreviewReviewer(type);
      },
      label: type,
      disabled: false
    }));
  }, [getReviewerSelects]);
  const handleDragDrop = (result) => __async(void 0, null, function* () {
    const { source, destination, type } = result;
    if (!destination || !template) return;
    const updatedOrder = [...template.order];
    if (type === "section") {
      if (source.index < 0 || source.index >= updatedOrder.length) return;
      const [movedSection] = updatedOrder.splice(source.index, 1);
      updatedOrder.splice(destination.index, 0, movedSection);
      try {
        yield ReviewTemplateAPI.updateOrderReviewTemplate({ templateId, order: updatedOrder });
        refreshTemplate == null ? void 0 : refreshTemplate();
        return;
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    } else if (type === "question") {
      const sourceDroppableId = source.droppableId;
      const destinationDroppableId = destination.droppableId;
      if (!sourceDroppableId || !destinationDroppableId) return;
      let movedQuestion;
      const sourceObject = updatedOrder.find((item) => item.id === sourceDroppableId);
      if (sourceDroppableId !== "individual-questions") {
        const sourceIndex = updatedOrder.findIndex((item) => item.id === sourceDroppableId);
        if (sourceIndex === -1) return;
        const sourceSection = updatedOrder[sourceIndex];
        if (!sourceSection.questions) return;
        const [removedQuestionId] = sourceSection.questions.splice(source.index, 1);
        movedQuestion = questionById[removedQuestionId];
      } else {
        const independentStartIndex = updatedOrder.findIndex((item) => item.type === "question");
        if (independentStartIndex === -1) return;
        movedQuestion = questionById[updatedOrder[source.index + independentStartIndex].id];
        updatedOrder.splice(source.index + independentStartIndex, 1);
      }
      if (!movedQuestion) return;
      if (destinationDroppableId !== "individual-questions") {
        const destinationIndex = updatedOrder.findIndex((item) => item.id === destinationDroppableId);
        const destinationObject = updatedOrder.find((item) => item.id === destinationDroppableId);
        if (destinationIndex === -1) return;
        const destinationSection = updatedOrder[destinationIndex];
        if (!destinationSection.questions) destinationSection.questions = [];
        destinationSection.questions.splice(destination.index, 0, movedQuestion.id);
        if ((destinationObject == null ? void 0 : destinationObject.type) === "section" && destinationObject.id !== sourceDroppableId) {
          yield moveQuestionInsideSection(destinationSection.id, movedQuestion.id);
          return;
        }
      } else {
        const independentStartIndex = updatedOrder.findIndex((item) => item.type === "question");
        const insertIndex = independentStartIndex !== -1 ? independentStartIndex : updatedOrder.length;
        updatedOrder.splice(insertIndex, 0, { id: movedQuestion.id, type: "question", questions: [] });
        if ((sourceObject == null ? void 0 : sourceObject.type) === "section") {
          try {
            yield ReviewTemplateAPI.moveQuestionOutsideSection({
              templateId,
              sectionId: sourceObject.id,
              questionId: movedQuestion.id
            });
            refreshTemplate == null ? void 0 : refreshTemplate();
            return;
          } catch (error) {
            showMessage(
              polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }),
              "error"
            );
          }
        }
      }
    }
    setTemplate((prev) => prev && __spreadProps(__spreadValues({}, prev), { order: updatedOrder }));
    try {
      yield ReviewTemplateAPI.updateOrderReviewTemplate({ templateId, order: updatedOrder });
      refreshTemplate == null ? void 0 : refreshTemplate();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      refreshTemplate == null ? void 0 : refreshTemplate();
    }
  });
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        height: "100%",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            className: "sub-title",
            sx: {
              px: spacing.p16,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: spacing.m4
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("ReviewTemplateBuilderPage.questions") }),
              getReviewerSelects.length > 1 && /* @__PURE__ */ jsx(
                StyledMenuComponent,
                {
                  options: getReviewerActionsOptions(),
                  actionButtonDetails: {
                    type: "button",
                    colorVariant: "secondary",
                    sizeVariant: "small",
                    title: "Preview"
                  }
                }
              )
            ]
          }
        ),
        (template == null ? void 0 : template.companyId) && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m24, width: "100%", boxSizing: "border-box", px: spacing.p16 }, children: /* @__PURE__ */ jsx(
          PlaceholderBox,
          {
            title: polyglot.t("ReviewTemplateBuilderPage.selectQuestionsFromLibrary"),
            countOne: polyglot.t("ReviewTemplateBuilderPage.totalFactors", {
              factors: (_a = bankCount == null ? void 0 : bankCount.totalCategories) != null ? _a : 0
            }),
            countTwo: polyglot.t("ReviewTemplateBuilderPage.totalQuestions", {
              questions: (_b = bankCount == null ? void 0 : bankCount.totalQuestions) != null ? _b : 0
            }),
            action: () => setIsQuestionBankOpen(true)
          }
        ) }),
        templateLoading ? /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              mt: spacing.m24,
              height: "100%",
              px: spacing.px16
            },
            children: /* @__PURE__ */ jsx(TemplatepQuestionBuilderLoader, {})
          }
        ) : /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({}, template && template.order ? {} : { margin: spacing.m16, border: `1px dashed ${themeColors.Background}` }), {
              mt: spacing.m24,
              height: "100%",
              px: spacing.px16
            }),
            children: [
              /* @__PURE__ */ jsxs(DragDropContext, { onDragEnd: handleDragDrop, children: [
                /* @__PURE__ */ jsx(Droppable, { droppableId: "sections", type: "section", children: (provided) => {
                  var _a2;
                  return /* @__PURE__ */ jsxs(
                    Box,
                    __spreadProps(__spreadValues({
                      ref: provided.innerRef
                    }, provided.droppableProps), {
                      sx: { display: "flex", flexDirection: "column", gap: spacing.s4 },
                      children: [
                        (_a2 = template == null ? void 0 : template.order) == null ? void 0 : _a2.map((item, index) => {
                          var _a3;
                          if (item.type === "section") {
                            const orderedQuestions = ((_a3 = item.questions) != null ? _a3 : []).map((questionId) => questionById[questionId]).filter((question2) => Boolean(question2));
                            return /* @__PURE__ */ jsx(Draggable, { draggableId: item.id, index, children: (provided2) => /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ ref: provided2.innerRef }, provided2.draggableProps), { children: sectionById[item.id] ? /* @__PURE__ */ jsx(
                              TemplateBuilderSection,
                              {
                                section: sectionById[item.id],
                                setSection,
                                setIsQuestionModalOpen,
                                setIsSectionModalOpen,
                                questions: orderedQuestions,
                                template,
                                setQuestion,
                                refreshTemplate,
                                setIsMoveToSectionOpen,
                                sections,
                                growthFactors,
                                sectionProvided: provided2
                              },
                              item.id
                            ) : /* @__PURE__ */ jsx(Fragment, {}) })) }, item.id);
                          }
                          return null;
                        }),
                        provided.placeholder
                      ]
                    })
                  );
                } }),
                /* @__PURE__ */ jsx(Droppable, { droppableId: "individual-questions", type: "question", children: (provided) => {
                  var _a2;
                  return /* @__PURE__ */ jsxs(
                    Box,
                    __spreadProps(__spreadValues({
                      ref: provided.innerRef
                    }, provided.droppableProps), {
                      sx: { mt: spacing.s3, display: "flex", flexDirection: "column", gap: spacing.s2 },
                      children: [
                        (_a2 = template == null ? void 0 : template.order) == null ? void 0 : _a2.filter((item) => item.type === "question").map((question2, index) => /* @__PURE__ */ jsx(Draggable, { draggableId: question2.id, index, children: (provided2) => /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ ref: provided2.innerRef }, provided2.draggableProps), { children: questionById[question2.id] ? /* @__PURE__ */ jsx(
                          QuestionComponent,
                          {
                            question: questionById[question2.id],
                            template,
                            sectionId: null,
                            setQuestion,
                            setIsQuestionModalOpen,
                            refreshTemplate,
                            setIsMoveToSectionOpen,
                            hasSections: Boolean(sections && sections.length > 0),
                            growthFactors,
                            showBorder: true,
                            provided: provided2
                          },
                          question2.id
                        ) : /* @__PURE__ */ jsx(Fragment, {}) })) }, question2.id)),
                        provided.placeholder
                      ]
                    })
                  );
                } })
              ] }),
              (template == null ? void 0 : template.companyId) && /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    gap: spacing.g5,
                    mt: template && template.order ? spacing.m24 : "0px",
                    padding: spacing.p16
                  },
                  children: [
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsSectionModalOpen(true),
                        children: polyglot.t("ReviewTemplateBuilderPage.newSection")
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsQuestionModalOpen(true),
                        children: polyglot.t("ReviewTemplateBuilderPage.newQuestion")
                      }
                    )
                  ]
                }
              ),
              /* @__PURE__ */ jsx(
                ReviewTemplateQuestionModal,
                {
                  setIsOpen: setIsQuestionModalOpen,
                  isOpen: isQuestionModalOpen,
                  question,
                  onClose: () => {
                    setIsQuestionModalOpen(false);
                    clearSelection();
                  },
                  refresh: () => __async(void 0, null, function* () {
                    yield refreshTemplate == null ? void 0 : refreshTemplate();
                  }),
                  section,
                  templateId
                }
              ),
              /* @__PURE__ */ jsx(
                ReviewTemplateSectionModal,
                {
                  setIsOpen: setIsSectionModalOpen,
                  isOpen: isSectionModalOpen,
                  section,
                  onClose: () => {
                    setIsSectionModalOpen(false);
                    clearSelection();
                  },
                  refresh: () => __async(void 0, null, function* () {
                    yield refreshTemplate == null ? void 0 : refreshTemplate();
                  }),
                  templateId
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          QuestionSelectionModal,
          {
            setIsQuestionsModalOpen: setIsQuestionBankOpen,
            isQuestionsModalOpen: isQuestionBankOpen,
            action: addQuestionsToTemplateHandle,
            excludeQuestions: questions || []
          }
        ),
        /* @__PURE__ */ jsx(
          ReviewTemplateMoveToSectionModal,
          {
            isOpen: isMoveToSectionOpen,
            setIsOpen: setIsMoveToSectionOpen,
            onClose: () => {
              setIsMoveToSectionOpen(false);
              clearSelection();
            },
            action: moveQuestionInsideSection,
            sections,
            questionId: question == null ? void 0 : question.id
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionPreviewer,
          {
            isPreviewerOpen,
            setIsPreviewerOpen,
            previewReviewer,
            allQuestions: questions || [],
            allSections: sections || [],
            order: (template == null ? void 0 : template.order) || []
          }
        )
      ]
    }
  );
};
const TemplateBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  setIsMoveToSectionOpen,
  questions,
  template,
  setQuestion,
  refreshTemplate,
  sections,
  growthFactors,
  sectionProvided
}) => {
  const { polyglot } = usePolyglot();
  const sectionOptions = useTemplateSectionOptions(
    template,
    section,
    setSection,
    refreshTemplate,
    setIsSectionModalOpen
  );
  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };
  return /* @__PURE__ */ jsx(Droppable, { droppableId: section.id, type: "question", children: (provided, snapshot) => {
    var _a;
    return /* @__PURE__ */ jsxs(
      Box,
      __spreadProps(__spreadValues({
        ref: provided.innerRef
      }, provided.droppableProps), {
        sx: {
          border: borders.middle,
          borderRadius: radius.br12,
          minHeight: "80px"
        },
        children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                backgroundColor: themeColors.Background,
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box",
                p: spacing.p16,
                borderTopRightRadius: radius.br12,
                borderTopLeftRadius: radius.br12
              },
              children: [
                /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s2 }, children: [
                  /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({}, sectionProvided.dragHandleProps), { sx: { cursor: "grab" }, children: /* @__PURE__ */ jsx(Drag, __spreadValues({}, iconSize)) })),
                  /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        flexDirection: "column",
                        gap: spacing.s1
                      },
                      children: [
                        /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: section.name }),
                        section.description && /* @__PURE__ */ jsx(
                          Typography,
                          {
                            variant: "paragraph",
                            dangerouslySetInnerHTML: { __html: (_a = section.description) != null ? _a : "" }
                          }
                        )
                      ]
                    }
                  )
                ] }),
                /* @__PURE__ */ jsx(
                  StyledMenuComponent,
                  {
                    options: sectionOptions,
                    actionButtonDetails: {
                      type: "iconButton",
                      colorVariant: "secondary",
                      sizeVariant: "small",
                      title: "actions",
                      icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                    }
                  }
                )
              ]
            }
          ),
          questions.length === 0 && !snapshot.isDraggingOver && /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px dashed ${themeColors.grey}`,
                borderRadius: radius.br12,
                color: themeColors.grey,
                opacity: 0.6
              }
            }
          ),
          questions.map((question, index) => /* @__PURE__ */ jsx(Draggable, { draggableId: question.id, index, children: (provided2) => /* @__PURE__ */ jsx(Box, __spreadProps(__spreadValues({ ref: provided2.innerRef }, provided2.draggableProps), { children: /* @__PURE__ */ jsx(
            QuestionComponent,
            {
              question,
              template,
              sectionId: section.id,
              setQuestion,
              setIsQuestionModalOpen,
              refreshTemplate,
              setIsMoveToSectionOpen,
              hasSections: Boolean(sections && sections.length > 0),
              growthFactors,
              showBorder: false,
              provided: provided2
            }
          ) })) }, question.id)),
          provided.placeholder,
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                bgcolor: themeColors.white,
                p: spacing.p16,
                borderBottomRightRadius: radius.br12,
                borderBottomLeftRadius: radius.br12
              },
              children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  colorVariant: "secondary",
                  sizeVariant: "medium",
                  startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                  onClick: newQuestionAction,
                  children: polyglot.t("ReviewTemplateBuilderPage.newQuestion")
                }
              )
            }
          )
        ]
      })
    );
  } });
};
const QuestionComponent = ({
  question,
  template,
  sectionId,
  setQuestion,
  setIsQuestionModalOpen,
  refreshTemplate,
  setIsMoveToSectionOpen,
  hasSections,
  growthFactors,
  showBorder,
  provided
}) => {
  const questionOptions = useTemplateQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    template,
    sectionId,
    refreshTemplate,
    hasSections
  );
  return /* @__PURE__ */ jsx(
    QuestionBuilder,
    {
      questionOptions,
      question,
      growthFactors,
      showAction: Boolean(template.companyId),
      showBorder,
      provided,
      isLast: false
    }
  );
};
const TemplatepQuestionBuilderLoader = () => {
  return /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g10 }, spacing.mt20), children: [...Array(10)].map((e, i) => /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" }),
    /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "100%", height: 15, rowGap: "5px" })
  ] }, i)) });
};
