"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DeleteDrawer } from "@/v2/components/delete-drawer.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { JobPositionFormDrawer } from "@/v2/feature/job-position/job-position-settings/features/components/job-position-form-drawer.component";
import { JobPositionSettingTable } from "@/v2/feature/job-position/job-position-settings/features/components/job-position-table.component";
import { JobPositionAPI, JobPositionEndpoints } from "@/v2/feature/job-position/job-position.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@/v2/styles/root.styles";
export const JobPositionSettingPage = () => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const {
    data: jobPositions,
    isValidating: loadingJobPositions,
    mutate: refreshJobPositions
  } = useApiClient(JobPositionEndpoints.listJobPositions(), { suspense: false });
  const nextPossibleId = useMemo(() => {
    var _a;
    if (!jobPositions || jobPositions.length === 0) return "1";
    const sortedJobPositions = jobPositions.sort((a, b) => {
      const aNumeric = parseInt(a.internalCode.replace(/\D/g, ""), 10) || 0;
      const bNumeric = parseInt(b.internalCode.replace(/\D/g, ""), 10) || 0;
      return aNumeric - bNumeric;
    });
    const lastId = (_a = sortedJobPositions[sortedJobPositions.length - 1]) == null ? void 0 : _a.internalCode;
    if (lastId) {
      const numericPart = lastId.replace(/\D/g, "");
      const nextNumericValue = numericPart === "" ? 1 : Number(numericPart) + 1;
      return lastId.replace(numericPart, String(nextNumericValue));
    }
    return "1";
  }, [jobPositions]);
  const DEFAULT_NEW_JOB_POSITION = useMemo(() => {
    return {
      id: void 0,
      internalCode: nextPossibleId,
      title: "",
      description: "",
      levelId: void 0
    };
  }, [nextPossibleId]);
  const [postSave, setPostSave] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedJobPosition, setSelectedJobPosition] = useState(DEFAULT_NEW_JOB_POSITION);
  const refreshJobPositionData = useCallback(() => __async(void 0, null, function* () {
    if (!refreshJobPositions) return;
    setPostSave(false);
    yield refreshJobPositions();
    setPostSave(true);
  }), [refreshJobPositions]);
  const openForm = useCallback((jobPosition) => {
    setSelectedJobPosition(jobPosition);
    setIsFormOpen(true);
  }, []);
  const closeForm = useCallback(() => {
    setIsFormOpen(false);
    setTimeout(() => setSelectedJobPosition(DEFAULT_NEW_JOB_POSITION), 500);
  }, [DEFAULT_NEW_JOB_POSITION]);
  const openDelete = useCallback((jobPosition) => {
    setSelectedJobPosition(jobPosition);
    setIsDeleteOpen(true);
  }, []);
  const closeDelete = useCallback(() => {
    setIsDeleteOpen(false);
    setTimeout(() => setSelectedJobPosition(DEFAULT_NEW_JOB_POSITION), 500);
  }, [DEFAULT_NEW_JOB_POSITION]);
  const deletePosition = useCallback(() => __async(void 0, null, function* () {
    try {
      setDeleteLoading(true);
      if (selectedJobPosition && selectedJobPosition.id) yield JobPositionAPI.deleteJobPosition(selectedJobPosition.id);
      else {
        throw new Error(polyglot.t("JobPositionDeleteDrawer.errorMessages.noPositionToDelete"));
      }
      showMessage(polyglot.t("JobPositionDeleteDrawer.successMessages.delete"), "success");
      yield refreshJobPositions == null ? void 0 : refreshJobPositions();
      closeDelete();
    } catch (error) {
      showMessage(`${polyglot.t("JobPositionDeleteDrawer.errorMessages.delete")}: ${nestErrorMessage(error)}`, "error");
    } finally {
      setDeleteLoading(false);
    }
  }), [polyglot, selectedJobPosition, refreshJobPositions, showMessage, closeDelete]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Job positions" }),
        showBack: true,
        showAction: true,
        actions: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "primary",
            onClick: () => openForm(DEFAULT_NEW_JOB_POSITION),
            children: polyglot.t("JobPositionSettingsPage.new")
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(
        JobPositionSettingTable,
        {
          jobPositions: jobPositions != null ? jobPositions : [],
          loading: !postSave ? loadingJobPositions || loadingJobPositions : false,
          openFormDrawer: openForm,
          openDeleteDrawer: openDelete
        }
      ),
      /* @__PURE__ */ jsx(
        JobPositionFormDrawer,
        {
          isOpen: isFormOpen,
          setIsOpen: setIsFormOpen,
          selectedJobPosition,
          onClose: closeForm,
          refresh: refreshJobPositionData
        }
      ),
      /* @__PURE__ */ jsx(
        DeleteDrawer,
        {
          title: polyglot.t("JobPositionDeleteDrawer.title"),
          description: polyglot.t("JobPositionDeleteDrawer.confirmDeletion", {
            jobPositionName: selectedJobPosition.title
          }),
          action: deletePosition,
          isOpen: isDeleteOpen,
          setIsOpen: setIsDeleteOpen,
          loading: deleteLoading,
          onClose: closeDelete
        }
      )
    ] })
  ] });
};
