"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import {
  AttachmentComponent,
  attachmentComponentValueToString,
  stringToAttachmentComponentValue
} from "@/v2/components/forms/attachment.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  RequestFormStatus
} from "@/v2/feature/requests/features/request-forms/request-forms.interface";
import { RequestFormsAPI } from "@/v2/feature/requests/request-forms.api";
import { spacing } from "@/v2/styles/spacing.styles";
export function RequestTemplateComponent({ component, disabled, formik }) {
  var _a, _b, _c;
  const hasSubmitted = !!(formik == null ? void 0 : formik.submitCount);
  switch (component.type) {
    case "text":
      return /* @__PURE__ */ jsx(
        RichTextField,
        {
          disabled,
          label: component.label,
          onChange: (value) => {
            formik == null ? void 0 : formik.setFieldValue(component.id, value, true);
          },
          value: (_a = formik == null ? void 0 : formik.values[component.id]) != null ? _a : "",
          helperText: hasSubmitted && formik.errors[component.id],
          error: hasSubmitted && !!formik.errors[component.id]
        }
      );
    case "select":
      return /* @__PURE__ */ jsx(
        SelectComponent,
        {
          disabled,
          label: component.label,
          name: component.id,
          onChange: formik == null ? void 0 : formik.handleChange,
          value: (_b = formik == null ? void 0 : formik.values[component.id]) != null ? _b : null,
          options: component.options.map((o) => ({ label: o, value: o })),
          helperText: hasSubmitted && formik.errors[component.id],
          error: hasSubmitted && !!formik.errors[component.id]
        }
      );
    case "date":
      return /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          disabled,
          label: component.label,
          name: component.id,
          onChange: (value) => formik == null ? void 0 : formik.setFieldValue(component.id, value),
          value: (_c = formik == null ? void 0 : formik.values[component.id]) != null ? _c : null,
          helperText: hasSubmitted && formik.errors[component.id],
          error: hasSubmitted && !!formik.errors[component.id],
          sx: { width: "100%" }
        }
      );
    case "attachment":
      return /* @__PURE__ */ jsx(
        AttachmentComponent,
        {
          variant: "paragraph",
          disabled,
          label: component.label,
          name: component.id,
          value: stringToAttachmentComponentValue(formik == null ? void 0 : formik.values[component.id]),
          onChange: (value) => formik == null ? void 0 : formik.setFieldValue(component.id, attachmentComponentValueToString(value)),
          helperText: hasSubmitted && formik.errors[component.id],
          error: hasSubmitted && !!formik.errors[component.id]
        }
      );
    default:
      return /* @__PURE__ */ jsx(Fragment, {});
  }
}
export const RequestFormComponent = ({
  template,
  previewMode,
  sx,
  onClose,
  onSubmit,
  approvalRules
}) => {
  const { polyglot } = usePolyglot();
  const [submitting, setSubmitting] = useState(false);
  const showApprovalSection = useMemo(() => {
    const approvalRule = approvalRules.find((a) => a.id === template.approvalRuleId);
    return approvalRule ? /* @__PURE__ */ jsxs(Stack, { gap: "5px", children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("ApprovalRuleModule.approvalRule") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: approvalRule.name })
    ] }) : null;
  }, [template.approvalRuleId, polyglot, approvalRules]);
  const [initialValues, validationSchema] = useMemo(() => {
    const values = {};
    const schema = {};
    for (const item of template.layout.items) {
      values[item.id] = "";
      if (item.required) {
        schema[item.id] = yup.string().required("Required field");
      }
    }
    return [values, yup.object(schema)];
  }, [template.layout.items]);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      if ("id" in template) {
        const data = {
          formTemplateId: template.id,
          values
        };
        setSubmitting(true);
        try {
          const submittedForm = yield RequestFormsAPI.submitForm(data);
          onSubmit == null ? void 0 : onSubmit(submittedForm.status === RequestFormStatus.Pending);
        } catch (error) {
          return;
        } finally {
          setSubmitting(false);
        }
      }
      onClose == null ? void 0 : onClose();
    })
  });
  return /* @__PURE__ */ jsx(Stack, { sx: __spreadValues({ overflow: "auto" }, sx), children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(
    Form,
    {
      style: {
        display: "flex",
        flexDirection: "column",
        gap: "20px"
      },
      children: [
        /* @__PURE__ */ jsxs(Stack, { children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: spacing.s2
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: template.name }),
                !previewMode && /* @__PURE__ */ jsx(
                  LoaderButton,
                  {
                    loading: submitting,
                    sizeVariant: "small",
                    colorVariant: "primary",
                    name: polyglot.t("General.submit")
                  }
                )
              ]
            }
          ),
          template.description && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { whiteSpace: "pre-line" }, children: template.description })
        ] }),
        template.layout.items.map((item) => /* @__PURE__ */ jsx(
          RequestTemplateComponent,
          {
            component: item,
            formik,
            disabled: submitting || previewMode && item.type === "attachment"
          },
          item.id
        )),
        showApprovalSection
      ]
    }
  ) }) });
};
