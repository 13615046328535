"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, FormControl, IconButton, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import {
  DeliveryMethod,
  DeviceContractLength,
  DevicePossessionType,
  OrderDeliveryCountry
} from "@v2/feature/device/device.interface";
import {
  DeliveryCountryOptions,
  DeliveryMethodsOptions,
  DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS,
  DeviceOwnershipOptions,
  DevicePurchaseOption,
  DeviceRentOption,
  getFullPriceDetails,
  getRentalPaymentDetails
} from "@v2/feature/device/device.util";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { LocalDate } from "@v2/util/local-date";
import { formatAddress } from "@v2/util/user-data.util";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { SiteAPI } from "@/api-client/site.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { getModelImage } from "@/v2/feature/device/device.util";
import { MissingPaymentMethodDrawer } from "@/v2/feature/device/features/devices-settings/features/zelt-mdm/components/missing-payment-method-drawer.component";
import { ZeltMdmUpgradeToPro } from "@/v2/feature/device/features/devices-settings/features/zelt-mdm/components/zelt-mdm-upgrade-to-pro.component";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { fieldSx, titleSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserAPI } from "@/v2/feature/user/user.api";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { StyledFormCheckbox } from "@/v2/styles/checkbox.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 14, height: 14 };
const getDeviceDetails = (deviceModel, close) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline3), children: "Device" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "100%", mt: spacing.m10, justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { width: "20%", alignItems: "center" }, children: /* @__PURE__ */ jsx(Box, { children: getModelImage(deviceModel == null ? void 0 : deviceModel.type, deviceModel == null ? void 0 : deviceModel.modelName, { width: "60px", height: "auto" }) }) }),
      /* @__PURE__ */ jsxs(Box, { sx: { width: "55%", display: "inline-block" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mb: spacing.m10, color: themeColors.DarkGrey }), children: deviceModel.modelName }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey, mb: spacing.m5 }), children: [
          "Version:",
          deviceModel == null ? void 0 : deviceModel.modelVersion
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey, mb: spacing.m5 }), children: [
          "Size:",
          deviceModel == null ? void 0 : deviceModel.screenSize,
          '"'
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey, mb: spacing.m5 }), children: [
          "Storage:",
          deviceModel == null ? void 0 : deviceModel.storage,
          "GB"
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey, mb: spacing.m5 }), children: [
          "RAM:",
          deviceModel == null ? void 0 : deviceModel.ram,
          "GB"
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey, mb: spacing.m5 }), children: [
          "GPU:",
          deviceModel == null ? void 0 : deviceModel.gpuCores,
          "-cores"
        ] })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { width: "15%", display: "flex", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
        IconButton,
        {
          onClick: () => {
            close();
          },
          title: "Edit",
          sx: tableIconButtonSx,
          children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
        }
      ) })
    ] })
  ] });
};
export const OrderDeviceSchema = Yup.object().shape({
  deliveryMethod: Yup.string().required(),
  contractLength: Yup.number().required("Contract length is required"),
  deviceModelId: Yup.number().required("Device model is required"),
  possessionType: Yup.string().required(),
  possessionId: Yup.number().required("Deliver to is required"),
  delivery: Yup.object().shape({
    address: Yup.string().required("Delivery Address is required"),
    date: Yup.string().test(dateFieldTest).required("Delivery date is required")
  }),
  phoneNumber: Yup.string().nullable().required("Phone number length is required"),
  isPurchase: Yup.boolean().required()
});
export const OrderDevicesForm = ({ deviceModel, close, reach, possessionId }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showMissingPaymentMethod, setShowMissingPaymentMethod] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [termsAcceptedError, setTermsAcceptedError] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: globalState.user.userId });
  const [showMessage] = useMessage();
  const hasDeviceAll = hasScopes(["devices:all"]);
  const hasDevicesPriceScope = hasScopes(["devices.price:read", "devices:all"], scopesContext);
  const [sitesOptions, setSitesOptions] = useState([]);
  const [canRentDevices, setCanRentDevices] = useState(false);
  const [onlyBuy] = useState(!!(deviceModel.fullPrice && !deviceModel.price36 && !deviceModel.price24));
  useEffect(() => {
    const checkIfDeviceOrderIsAllowed = () => __async(void 0, null, function* () {
      try {
        yield DeviceAPI.deviceOrderAllowed();
        setCanRentDevices(true);
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setCanRentDevices(false);
        }
      }
    });
    checkIfDeviceOrderIsAllowed();
  }, []);
  const formik = useFormik({
    initialValues: {
      deliveryMethod: DeliveryMethod.ShippingToEmployee,
      contractLength: DeviceContractLength.Months36,
      deviceModelId: deviceModel.id,
      possessionType: DevicePossessionType.User,
      possessionId: reach !== "me" ? possessionId : globalState.user.userId,
      phoneNumber: phone,
      delivery: {
        address,
        date: new LocalDate(dayjs().add(deviceModel.deliveryDays, "day").toDate()).toDateString(),
        country: OrderDeliveryCountry.UK
      },
      isPurchase: onlyBuy
    },
    validationSchema: OrderDeviceSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        if (!values.possessionId) {
          showMessage("No owner selected", "error");
          return;
        }
        setLoading(true);
        const delivery = __spreadValues({}, values.delivery);
        const possessionDetails = {
          possessionType: values.possessionType,
          possessionId: values.possessionId
        };
        yield DeviceAPI.orderDevice(
          deviceModel.id,
          possessionDetails,
          delivery,
          values.contractLength,
          values.phoneNumber,
          values.isPurchase
        );
        showMessage("Device successfully ordered.", "success");
        yield close(true);
      } catch (error) {
        const defaultError = "Device could not be ordered. Please try again.";
        showMessage(nestErrorMessage(error) || defaultError, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const fetchUserAddress = useCallback(
    (assignedUserId) => __async(void 0, null, function* () {
      let addressLine = "";
      try {
        const userAddress = yield UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
          const effectiveAddress = userAddress.effectiveRecord;
          addressLine = formatAddress(effectiveAddress);
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
      return addressLine;
    }),
    [showMessage]
  );
  const fetchUserPhone = useCallback(
    (assignedUserId) => __async(void 0, null, function* () {
      var _a2;
      try {
        const userPersonalInfo = yield UserAPI.getUserPersonalInfo(assignedUserId);
        return (_a2 = userPersonalInfo == null ? void 0 : userPersonalInfo.phone) != null ? _a2 : "";
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
      return "";
    }),
    [showMessage]
  );
  const getSites = useCallback(() => __async(void 0, null, function* () {
    try {
      const sites = yield SiteAPI.listSites();
      setSitesOptions(
        sites.map((site) => {
          var _a2;
          return { value: site.id, label: site.name, address: (_a2 = site.address) != null ? _a2 : "" };
        })
      );
    } catch (error) {
      showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage]);
  const setUserDetails = useCallback(
    (userId) => __async(void 0, null, function* () {
      const userAddress = yield fetchUserAddress(userId);
      setAddress(userAddress);
      const userPhone = yield fetchUserPhone(userId);
      setPhone(userPhone);
    }),
    [fetchUserAddress, fetchUserPhone]
  );
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      yield getSites();
      if (reach === "me") {
        yield setUserDetails(globalState.user.userId);
      } else if (possessionId) {
        yield setUserDetails(possessionId);
      }
      if (!hasDeviceAll) setTermsAccepted(true);
    }))();
  }, [setUserDetails, reach, hasDeviceAll, getSites, possessionId, globalState.user.userId]);
  useEffect(() => {
    formik.validateForm();
  }, [termsAccepted]);
  return /* @__PURE__ */ jsx(Fragment, { children: showMissingPaymentMethod ? /* @__PURE__ */ jsx(MissingPaymentMethodDrawer, {}) : /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    showMissingPaymentMethod && /* @__PURE__ */ jsx(MissingPaymentMethodDrawer, {}),
    showUpgrade ? /* @__PURE__ */ jsx(ZeltMdmUpgradeToPro, {}) : /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: hasDeviceAll ? "Order device" : "Request Device" }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
        reach !== "me" && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "deliveryMethod",
              label: "Delivery method",
              options: DeliveryMethodsOptions.filter((o) => o.value !== DeliveryMethod.Handover),
              value: formik.values.deliveryMethod,
              compareValue: formik.values.deliveryMethod,
              error: !!formik.errors.deliveryMethod && formik.touched.deliveryMethod,
              onChange: (e) => {
                formik.handleChange(e);
                formik.setFieldValue(
                  "possessionType",
                  e.target.value === DeliveryMethod.ShippingToSite ? DevicePossessionType.CompanySite : DevicePossessionType.User
                );
                formik.setFieldValue("possessionId", void 0);
                formik.setFieldValue("delivery.address", "");
                formik.setFieldValue("phoneNumber", "");
              },
              helperText: formik.touched.deliveryMethod && formik.errors.deliveryMethod,
              disabled: true
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: [DeliveryMethod.ShippingToEmployee, DeliveryMethod.Handover].includes(
            formik.values.deliveryMethod
          ) ? /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "possessionId",
              options: "company",
              onChange: (_, option) => __async(void 0, null, function* () {
                const userId = option == null ? void 0 : option.value;
                yield formik.setFieldValue("possessionId", userId != null ? userId : null);
                if (userId) {
                  const [address2, phoneNumber] = yield Promise.all([
                    fetchUserAddress(Number(userId)),
                    fetchUserPhone(Number(userId))
                  ]);
                  yield formik.setFieldValue("delivery.address", address2);
                  yield formik.setFieldValue("phoneNumber", phoneNumber);
                }
              }),
              disabled: !!possessionId,
              value: formik.values.possessionId,
              label: "Assigned user",
              error: formik.touched.possessionId && Boolean(formik.errors.possessionId),
              helperText: formik.touched.possessionId && formik.errors.possessionId
            }
          ) : /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "possessionId",
              label: "Site",
              options: sitesOptions,
              value: formik.values.possessionId,
              compareValue: formik.values.possessionId,
              error: !!formik.errors.possessionId && formik.touched.possessionId,
              helperText: formik.touched.possessionId && formik.errors.possessionId,
              onChange: (e) => __async(void 0, null, function* () {
                var _a2, _b2;
                yield formik.handleChange(e);
                const siteAddress = (_b2 = (_a2 = sitesOptions.find((s) => s.value === e.target.value)) == null ? void 0 : _a2.address) != null ? _b2 : "";
                yield formik.setFieldValue("delivery.address", siteAddress);
              })
            }
          ) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            inputFormat: "DD/MM/YYYY",
            value: (_a = formik.values.delivery.date) != null ? _a : dayjs(),
            onChange: (value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue("delivery.date", value);
              }
            },
            name: "delivery.date",
            label: "Delivery date",
            error: !!((_b = formik.touched.delivery) == null ? void 0 : _b.date) && !!((_c = formik.errors.delivery) == null ? void 0 : _c.date),
            helperText: ((_d = formik.touched.delivery) == null ? void 0 : _d.date) && ((_e = formik.errors.delivery) == null ? void 0 : _e.date),
            disablePast: true,
            minDate: dayjs().add(
              ((_f = formik.values.delivery) == null ? void 0 : _f.country) === OrderDeliveryCountry.UK ? (_g = deviceModel.deliveryDays) != null ? _g : 7 : (_h = deviceModel.deliveryDaysEu) != null ? _h : 14,
              "day"
            )
          }
        ) }) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "delivery.address",
            label: "Delivery address",
            value: formik.values.delivery.address || address,
            type: "text",
            onChange: formik.handleChange,
            error: ((_i = formik.touched.delivery) == null ? void 0 : _i.address) && !!((_j = formik.errors.delivery) == null ? void 0 : _j.address),
            helperText: ((_k = formik.touched.delivery) == null ? void 0 : _k.address) && ((_l = formik.errors.delivery) == null ? void 0 : _l.address),
            clearText: () => formik.setFieldValue("delivery.address", "")
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "delivery.country",
            label: "Delivery Country",
            options: DeliveryCountryOptions,
            value: (_m = formik.values.delivery.country) != null ? _m : OrderDeliveryCountry.UK,
            onChange: (e) => {
              var _a2, _b2;
              formik.handleChange(e);
              const deliveryDate = /* @__PURE__ */ new Date();
              if (e.target.value === OrderDeliveryCountry.EU) {
                deliveryDate.setDate(deliveryDate.getDate() + ((_a2 = deviceModel.deliveryDaysEu) != null ? _a2 : 14));
                formik.setFieldValue("delivery.date", dayjs(deliveryDate));
              } else {
                deliveryDate.setDate(deliveryDate.getDate() + ((_b2 = deviceModel.deliveryDays) != null ? _b2 : 7));
                formik.setFieldValue("delivery.date", dayjs(deliveryDate));
              }
            },
            error: ((_n = formik.touched.delivery) == null ? void 0 : _n.country) && !!((_o = formik.errors.delivery) == null ? void 0 : _o.country),
            helperText: ((_p = formik.touched.delivery) == null ? void 0 : _p.country) && ((_q = formik.errors.delivery) == null ? void 0 : _q.country)
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "phoneNumber",
            label: "Phone Number",
            value: (_r = formik.values.phoneNumber) != null ? _r : phone,
            type: "text",
            onChange: formik.handleChange,
            error: formik.touched.phoneNumber && !!formik.errors.phoneNumber,
            helperText: (_s = formik.touched.phoneNumber && formik.errors.phoneNumber) != null ? _s : " ",
            clearText: () => formik.setFieldValue("phoneNumber", "")
          }
        ) }),
        deviceModel.fullPrice && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "deviceOwnership",
            label: "Checkout option",
            options: DeviceOwnershipOptions,
            disabled: onlyBuy,
            value: formik.values.isPurchase ? DevicePurchaseOption.value : DeviceRentOption.value,
            onChange: (e) => {
              const ownershipChoice = e == null ? void 0 : e.target.value;
              formik.setFieldValue("isPurchase", ownershipChoice === "purchase");
            }
          }
        ) }),
        !formik.values.isPurchase && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "contractLength",
            label: "Contract Length",
            compareValue: formik.values.contractLength,
            options: DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS,
            value: formik.values.contractLength,
            onChange: formik.handleChange
          }
        ) }),
        deviceModel && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: getDeviceDetails(deviceModel, () => close(false)) }),
        deviceModel && hasDevicesPriceScope && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: formik.values.isPurchase ? getFullPriceDetails(deviceModel, formik.values.delivery.country) : getRentalPaymentDetails(
          deviceModel,
          formik.values.contractLength,
          formik.values.delivery.country
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: !formik.values.isPurchase && hasDeviceAll && /* @__PURE__ */ jsxs(
          Box,
          {
            sx: { display: "flex", alignItems: "flex-start", gap: spacing.g10, cursor: "pointer" },
            onClick: () => {
              if (termsAccepted) {
                setTermsAccepted(false);
              } else {
                setTermsAccepted(true);
                setTermsAcceptedError(false);
              }
            },
            children: [
              /* @__PURE__ */ jsx(
                StyledFormCheckbox,
                {
                  sx: {
                    "&label.MuiFormControlLabel-root": {
                      display: "flex",
                      alignItems: "flex-start !important"
                    }
                  },
                  name: "terms",
                  checked: termsAccepted
                }
              ),
              /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: [
                "Agree with the Framework Agreement for the Rental of Devices.",
                " ",
                /* @__PURE__ */ jsx(
                  "a",
                  {
                    style: { textDecoration: "none", color: themeColors.ZeltYellow },
                    target: "_blank",
                    rel: "noreferrer",
                    href: "https://zelt.app/legal-devices-framework-agreement-summary/",
                    children: "Read more here."
                  }
                )
              ] })
            ]
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({
              opacity: termsAcceptedError ? 1 : 0
            }, themeFonts.paragraph), {
              color: themeColors.RedDark,
              mb: spacing.m10,
              transition: "opacity 0.3s"
            }),
            children: "Please agree to the terms & conditions"
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: hasDeviceAll ? "Order" : "Request",
            loading,
            fullWidth: true,
            type: "button",
            onClick: () => {
              if (!globalState.user.hasPaymentMethodOnFile) setShowMissingPaymentMethod(true);
              else if (!termsAccepted && !formik.values.isPurchase) setTermsAcceptedError(true);
              else if (!canRentDevices && !formik.values.isPurchase || !globalState.user.hasPaymentMethodOnFile && hasDeviceAll)
                setShowUpgrade(true);
              else {
                formik.handleSubmit();
              }
            },
            colorVariant: "primary",
            sizeVariant: "medium"
          }
        )
      ] })
    ] })
  ] }) }) });
};
