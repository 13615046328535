"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const RepayLoanPaymentDrawer = ({ isOpen, setIsOpen, userCustomBenefit, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(RepayLoanPaymentDrawerContent, { userCustomBenefit, refresh, setIsOpen }) });
};
export const RepayLoanPaymentDrawerContent = ({ userCustomBenefit, refresh, setIsOpen }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const repayLoanPayment = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield CustomBenefitAPI.repayLoanPayment(
          userCustomBenefit.userId,
          userCustomBenefit.customBenefitId,
          userCustomBenefit.id,
          Number(values.amount)
        );
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setLoading(false);
    }),
    [polyglot, userCustomBenefit, refresh, setIsOpen, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      amount: ""
    },
    validationSchema: yup.object({
      amount: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).min(0, polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: repayLoanPayment
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { mb: "20px" }, children: polyglot.t("BenefitModule.addRepayment") }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: polyglot.t("General.amount"),
        value: formik.values.amount,
        onChange: formik.handleChange,
        error: formik.touched.amount && !!formik.errors.amount,
        helperText: (_a = formik.touched.amount && formik.errors.amount) != null ? _a : " "
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: "30px" }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        loading,
        colorVariant: "primary",
        name: polyglot.t("BenefitModule.makePayment"),
        fullWidth: true
      }
    ) })
  ] }) });
};
