"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { getApiErrorMessage } from "@v2/infrastructure/api-error/api-error.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { nestErrorMessage } from "@/lib/errors";
import { USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortDate } from "@/v2/components/table/table-sorting.util";
import { TaskViewModal } from "@/v2/feature/task/components/task-view/task-view-modal.component";
import { getTaskStatus } from "@/v2/feature/task/components/task.util";
import { TaskAPI } from "@/v2/feature/task/task.api";
import { CachedUsersProvider } from "@/v2/feature/user/context/cached-users.context";
import { CloseButton } from "@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingTasksPage = () => {
  const { polyglot } = usePolyglot();
  const userId = useUserIdParam();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [tasks, setTasks] = useState();
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskViewModalOpen, setIsTaskViewModalOpen] = useState(false);
  const returnToOnboardingSummary = useCallback(() => {
    routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
  }, [routerHistory, userId]);
  const loadTasks = useCallback(() => __async(void 0, null, function* () {
    if (!Number.isInteger(userId)) {
      return;
    }
    try {
      const fetchedTasks = yield TaskAPI.listTasks(userId);
      const sortedTaskItems = fetchedTasks.sort((a, b) => {
        const aStatus = a.status === "Complete" ? 1 : 0;
        const bStatus = b.status === "Complete" ? 1 : 0;
        return aStatus - bStatus;
      });
      const incompleteTasks = fetchedTasks.filter((task) => task.status !== "Complete");
      if (incompleteTasks.length === 0) returnToOnboardingSummary();
      else setTasks(sortedTaskItems);
    } catch (e) {
      showMessage(
        polyglot.t("OnboardingByUser.errors.taskRetrieveFailed", {
          errorMessage: getApiErrorMessage(e)
        }),
        "error"
      );
      setTasks(void 0);
    }
  }), [polyglot, returnToOnboardingSummary, showMessage, userId]);
  const updateTaskStatus = (taskId, assignedUserId, status) => __async(void 0, null, function* () {
    try {
      yield TaskAPI.updateTaskStatus(taskId, assignedUserId, { status });
      showMessage(polyglot.t("TaskActionItem.successMessages.updateStatus"), "success");
      yield loadTasks == null ? void 0 : loadTasks();
      if (selectedTask) setSelectedTask(__spreadProps(__spreadValues({}, selectedTask), { status }));
    } catch (error) {
      showMessage(
        polyglot.t("TaskActionItem.errorMessages.updateStatus", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  useEffect(() => {
    loadTasks();
  }, [loadTasks]);
  const columnData = useMemo(() => {
    return [
      {
        id: "task",
        header: () => polyglot.t("OnboardingByUser.task"),
        accessorFn: (row) => row.name,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: original.name })
      },
      {
        id: "due-date",
        header: () => polyglot.t("OnboardingByUser.dueDate"),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item.dueDate),
        cell: (c) => {
          const dueDate = c.getValue().dueDate;
          return dueDate ? /* @__PURE__ */ jsx(DateLabelComponent, { date: dueDate }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        id: "status",
        header: () => polyglot.t("TaskTable.status"),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx("div", { children: (_a = getTaskStatus(original, polyglot)) != null ? _a : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      }
    ];
  }, [polyglot]);
  const closeTaskViewModal = useCallback(() => {
    setSelectedTask(null);
    setIsTaskViewModalOpen(false);
  }, []);
  return /* @__PURE__ */ jsxs(CachedUsersProvider, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flex: 0, mx: "auto", width: "800px", maxWidth: "90vw", mt: spacing.mt40 }, children: [
        /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.headline1), {
              color: themeColors.DarkGrey
            }),
            children: polyglot.t("OnboardingByUser.completeTasks")
          }
        ),
        /* @__PURE__ */ jsx(CloseButton, { onClick: () => returnToOnboardingSummary() })
      ] }),
      /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, overflowY: "auto", mt: spacing.mt30 }, children: /* @__PURE__ */ jsxs(Stack, { sx: { mx: "auto", width: "800px", maxWidth: "90vw" }, children: [
        !tasks && /* @__PURE__ */ jsx(LoadingSpinner, {}),
        !!(tasks == null ? void 0 : tasks.length) && /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb30, color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.completeTasksBellowLong") }),
          /* @__PURE__ */ jsx(
            BasicTable,
            {
              rowData: tasks,
              columnData,
              hidePagination: true,
              rowClick: (row) => {
                if (!selectedTask) {
                  setSelectedTask(row.original);
                  setIsTaskViewModalOpen(true);
                }
              }
            }
          )
        ] }),
        (tasks == null ? void 0 : tasks.length) === 0 && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb30, color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.noTasksToComplete") })
      ] }) })
    ] }),
    tasks && selectedTask && /* @__PURE__ */ jsx(
      TaskViewModal,
      {
        isOpen: isTaskViewModalOpen,
        setIsOpen: setIsTaskViewModalOpen,
        onClose: closeTaskViewModal,
        task: selectedTask,
        action: updateTaskStatus,
        onboardingClose: (taskCompleted) => __async(void 0, null, function* () {
          if (!taskCompleted) {
            return;
          }
          yield loadTasks();
        })
      }
    )
  ] });
};
