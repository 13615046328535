"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { getWeekRange } from "@/v2/util/date-fns/week.util";
export const TimesheetsWeeklyHeaderLoader = ({ weekNo, year }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "block", width: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g20 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserAttendancePage.weekNum", { weekNum: weekNo }) + ": " + getWeekRange(weekNo, year) }) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
        StyledMenuComponent,
        {
          options: [],
          disabled: true,
          actionButtonDetails: {
            type: "button",
            colorVariant: "secondary",
            sizeVariant: "small",
            title: polyglot.t("General.actions"),
            icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
            iconPosition: "end"
          }
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, spacing.mt20), {
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gap: spacing.g5,
          width: "100%"
        }),
        children: [0, 1, 2, 3, 4, 5, 6].map((i) => /* @__PURE__ */ jsx(ScheduleTitleLoader, {}, i))
      }
    )
  ] });
};
const ScheduleTitleLoader = () => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        flex: 1,
        height: "95px",
        background: themeColors.Background,
        borderRadius: radius.br8
      },
      children: /* @__PURE__ */ jsx("div", { style: { padding: "15px 10px 15px 10px" }, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5, alignItems: "center" }, children: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "30%",
          height: "16px",
          sx: { background: themeColors.Background, borderRadius: radius.br10 }
        }
      ) }) })
    }
  );
};
