"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollSetting = ({
  name,
  label,
  formik,
  tooltip,
  type = "text",
  autoComplete = "off",
  autoFocus,
  disabled,
  mode = "text",
  customFormat,
  dateRange,
  component
}) => {
  var _a, _b, _c;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(StyledTooltip, { title: tooltip || "", children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m15 }), children: label }) }),
    mode === "label" && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mt: spacing.m15, whiteSpace: "nowrap" }), children: (_a = customFormat == null ? void 0 : customFormat(formik.values[name], formik)) != null ? _a : formik.values[name] || "" }),
    mode === "text" && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name,
        type,
        autoComplete,
        autoFocus,
        disabled,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue(name, ""),
        value: formik.values[name] || "",
        error: !!formik.touched[name] && !!formik.errors[name],
        helperText: formik.touched[name] && ((_b = formik.errors[name]) == null ? void 0 : _b.toString()),
        sx: { mt: spacing.m15 }
      }
    ),
    mode === "date" && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name,
        disabled,
        onChange: (newValue) => {
          formik.setFieldValue(name, newValue);
        },
        minDate: dateRange == null ? void 0 : dateRange.min,
        maxDate: dateRange == null ? void 0 : dateRange.max,
        value: formik.values[name],
        error: !!formik.touched[name] && !!formik.errors[name],
        helperText: formik.touched[name] && ((_c = formik.errors[name]) == null ? void 0 : _c.toString()),
        sx: { mt: spacing.m15 }
      }
    ),
    mode === "custom" && component,
    typeof mode !== "string" && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name,
        options: mode,
        value: formik.values[name] ? String(formik.values[name]) : "",
        onChange: formik.handleChange,
        disabled,
        sx: { mt: spacing.m15 }
      }
    )
  ] });
};
