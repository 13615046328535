"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SurveyCycleEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { ChartHeatmapTable } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/components/chart-heatmap/chart-heatmap-table.component";
import { SurveyCycleDetailHeatmapFilters } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/components/survey-cycle-detail-heatmap.filters.component";
import {
  TitleStatusComponent,
  isDataEmpty,
  transformData
} from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util";
import {
  AnonymityType,
  ComparedBy,
  MeasureBy,
  ShowBy
} from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { borders } from "@/v2/styles/borders.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { captureExcel, capturePdf } from "@/v2/util/export-reports.util";
import { replaceParamsInQuestionText, stripHtml } from "@/v2/util/string.util";
export const SurveyCycleDetailHeatmapPage = ({
  surveyCycle,
  cycleLoading,
  reach,
  growthFactors
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : "Company";
  const [filter, setFilter] = useState(
    !((_c = surveyCycle.visibilitySettings) == null ? void 0 : _c.anonymiseAnswers) || surveyCycle.visibilitySettings && surveyCycle.visibilitySettings.anonymityType && surveyCycle.visibilitySettings.anonymityType === AnonymityType.Semi ? ((_f = (_e = (_d = state.user.features) == null ? void 0 : _d.surveys) == null ? void 0 : _e.heatMap) == null ? void 0 : _f.comparedBy) || ComparedBy.Site : ComparedBy.None
  );
  const { data: surveyHeatmap, isLoading: validatingHeatmap } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleHeatmap(surveyCycle.id, filter, reach),
    {
      suspense: false
    }
  );
  const reportName = useMemo(() => `${surveyCycle.internalName}-heatmap`, [surveyCycle]);
  const generateExcel = () => {
    var _a2, _b2;
    const uniqueFactors = /* @__PURE__ */ new Set();
    const uniqueQuestions = /* @__PURE__ */ new Set();
    const dynamicNames = /* @__PURE__ */ new Set();
    const scoreSurvey = (surveyHeatmap || []).find((survey) => survey.name === "score");
    if (scoreSurvey) {
      (_a2 = scoreSurvey.result) == null ? void 0 : _a2.resultByQuestion.forEach((item) => {
        uniqueQuestions.add(item.question.id);
      });
      (_b2 = scoreSurvey.result) == null ? void 0 : _b2.resultByFactor.forEach((item) => {
        uniqueFactors.add(item.factor);
      });
    }
    (surveyHeatmap || []).forEach((survey) => {
      dynamicNames.add(survey.name);
    });
    const dynamicColumnsArray = Array.from(dynamicNames).flatMap((name) => [
      `${name}:NPS`,
      `${name}:Avg`,
      `${name}:Positive`
    ]);
    const header = ["Type", "Text", ...dynamicColumnsArray];
    const data = [];
    data.push(header);
    scoreSurvey == null ? void 0 : scoreSurvey.result.resultByQuestion.forEach((item) => {
      const row = [];
      row.push("Question");
      row.push(replaceParamsInQuestionText(stripHtml(item.question.questionText), { company_name }));
      dynamicColumnsArray.forEach((col) => {
        var _a3;
        const [surveyName, metric] = col.split(":");
        const survey = (surveyHeatmap || []).find((s) => s.name === surveyName);
        if (survey) {
          const questionResult = (_a3 = survey.result) == null ? void 0 : _a3.resultByQuestion.find((q) => q.question.id === item.question.id);
          if (questionResult) {
            if (metric === "NPS") {
              row.push(questionResult.npsPercent !== null ? (questionResult.npsPercent / 100).toFixed(2) : "0.00");
            } else if (metric === "Avg") {
              row.push(questionResult.normPercent !== null ? (questionResult.normPercent / 100).toFixed(2) : "0.00");
            } else if (metric === "Positive") {
              row.push(
                questionResult.positivePercent !== null ? (questionResult.positivePercent / 100).toFixed(2) : "0.00"
              );
            }
          } else {
            row.push("\u2013");
          }
        } else {
          row.push("\u2013");
        }
      });
      data.push(row);
    });
    scoreSurvey == null ? void 0 : scoreSurvey.result.resultByFactor.forEach((item) => {
      const row = [];
      row.push("Factor");
      row.push(item.factor);
      dynamicColumnsArray.forEach((col) => {
        const [surveyName, metric] = col.split(":");
        const survey = (surveyHeatmap || []).find((s) => s.name === surveyName);
        if (survey) {
          const factorResult = survey.result.resultByFactor.find((f) => f.factor === item.factor);
          if (factorResult) {
            if (metric === "NPS") {
              row.push(factorResult.npsPercent !== null ? (factorResult.npsPercent / 100).toFixed(2) : "0.00");
            } else if (metric === "Avg") {
              row.push(factorResult.normPercent !== null ? (factorResult.normPercent / 100).toFixed(2) : "0.00");
            } else if (metric === "Positive") {
              row.push(
                factorResult.positivePercent !== null ? (factorResult.positivePercent / 100).toFixed(2) : "0.00"
              );
            }
          } else {
            row.push("n/a");
          }
        } else {
          row.push("n/a");
        }
      });
      data.push(row);
    });
    captureExcel(data, reportName);
  };
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { surveyCycle }),
      noHorizontalPadding: true,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      contentSx: { overflowX: "hidden" },
      buttons: [
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                handler: generateExcel,
                label: "Excel report"
              },
              {
                handler: () => capturePdf(reportName),
                label: "PDF report"
              }
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Export",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ],
      children: /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: "Heatmap",
              headerSx: { px: spacing.px16, overflowX: "hidden" },
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    HeatMapByCategories,
                    {
                      surveyHeatmap,
                      setFilter,
                      filter,
                      validatingHeatmap: Boolean(validatingHeatmap || cycleLoading),
                      surveyCycle,
                      state,
                      dispatch,
                      reportName,
                      growthFactors
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    }
  );
};
const HeatMapByCategories = ({
  surveyHeatmap,
  setFilter,
  filter,
  validatingHeatmap,
  surveyCycle,
  state,
  dispatch,
  reportName,
  growthFactors
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [showByFilter, setShowByFilter] = useState(
    ((_c = (_b = (_a = state.user.features) == null ? void 0 : _a.surveys) == null ? void 0 : _b.heatMap) == null ? void 0 : _c.showBy) || ShowBy.Questions
  );
  const [measureByFilter, setMeasureByFilter] = useState(
    ((_f = (_e = (_d = state.user.features) == null ? void 0 : _d.surveys) == null ? void 0 : _e.heatMap) == null ? void 0 : _f.measuredBy) || MeasureBy.NPS
  );
  const { user } = state;
  const emptyCheck = useMemo(() => isDataEmpty(surveyHeatmap, showByFilter), [surveyHeatmap, showByFilter]);
  const transformedData = useMemo(() => transformData(surveyHeatmap, showByFilter, measureByFilter, user), [
    surveyHeatmap,
    showByFilter,
    measureByFilter,
    user
  ]);
  const questionMap = useMemo(() => {
    const map = {};
    surveyHeatmap == null ? void 0 : surveyHeatmap.forEach((category) => {
      var _a2, _b2;
      (_b2 = (_a2 = category.result) == null ? void 0 : _a2.resultByQuestion) == null ? void 0 : _b2.forEach((questionResult) => {
        const { questionText, id } = questionResult.question;
        const questionTextReplaced = questionText.replaceAll("{company_name}", user.company.name || "company");
        map[id] = questionTextReplaced;
      });
    });
    return map;
  }, [surveyHeatmap, user]);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16, width: "100%" }, children: [
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.px16), children: /* @__PURE__ */ jsx(
      SurveyCycleDetailHeatmapFilters,
      {
        setShowByFilter,
        dispatch,
        setFilter,
        setMeasureByFilter,
        showByFilter,
        measureByFilter,
        filter,
        surveyCycle
      }
    ) }),
    /* @__PURE__ */ jsx(
      Box,
      {
        id: reportName,
        sx: {
          width: "100%",
          borderRadius: radius.br12,
          paddingY: spacing.s3,
          justifyContent: "flex-end",
          boxSizing: "border-box"
        },
        children: emptyCheck && !validatingHeatmap ? /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              borderTop: borders.light,
              borderBottom: borders.light,
              paddingY: spacing.s2
            },
            children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "No data available." })
          }
        ) : /* @__PURE__ */ jsx(
          ChartHeatmapTable,
          {
            series: transformedData.series,
            categories: transformedData.categories,
            filter: showByFilter,
            loadingHeatmap: validatingHeatmap,
            growthFactors,
            measureByFilter,
            surveyHeatmap,
            questionMap,
            showByFilter
          }
        )
      }
    )
  ] });
};
