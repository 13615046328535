"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import { AlertMessage } from "@/v2/components/theme-components/alert.component";
import { GlobalPayrollEndpoints } from "@/v2/feature/payroll/features/payroll-global/global-payroll.api";
import { PayrollLocalEndpoints } from "@/v2/feature/payroll/payroll-local.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
const getAttendanceEntriesWithoutPaycodeEndpoint = (payrollType, payrunId, payrollId) => {
  if (payrollType === "Global" && typeof payrollId === "number") {
    return GlobalPayrollEndpoints.getGlobalPayrunAttendanceEntriesWithoutPaycode(payrollId, payrunId);
  } else if (payrollType === "UK") {
    return PayrollLocalEndpoints.getUKPayrunAttendanceEntriesWithoutPaycode(payrunId);
  }
  return null;
};
export const InPayrunAttendanceEntriesWithoutPaycodeWarning = ({
  payrunId,
  payrollType,
  payrollId
}) => {
  const { data: attendanceEntriesWithoutPaycode } = useApiClient(
    getAttendanceEntriesWithoutPaycodeEndpoint(payrollType, payrunId, payrollId),
    {
      suspense: false
    }
  );
  return /* @__PURE__ */ jsx(Fragment, { children: attendanceEntriesWithoutPaycode && attendanceEntriesWithoutPaycode.length > 0 && /* @__PURE__ */ jsx(
    AlertMessage,
    {
      message: "Approved timesheets without pay codes. There are some approved timesheets that are missing pay code values",
      severity: "warning"
    }
  ) });
};
