"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { BankForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-bank-information-form.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const MissingBankAccount = ({ userId, defaultName, refreshPayroll }) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("PayrollMissing.bankAccount") }),
      /* @__PURE__ */ jsx(IconButton, { colorVariant: "secondary", sizeVariant: "small", onClick: () => setIsModalOpen(true), children: /* @__PURE__ */ jsx(Plus, { width: "14px", height: "14px" }) })
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, closeIcon: "back", children: /* @__PURE__ */ jsx(
      BankForm,
      {
        initialValues: null,
        userId,
        defaultName,
        getUserBankAccounts: () => Promise.resolve(),
        rowModalMode: "add",
        handleSubmit: () => refreshPayroll == null ? void 0 : refreshPayroll(),
        setFormCurrentlyEditing: () => {
        },
        setIsModalOpen,
        loading,
        setLoading,
        onClose: () => setIsModalOpen(false),
        showEmployee: true
      }
    ) })
  ] });
};
