"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { MultipleSelectCheckbox } from "@v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { CustomBenefitType } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import {
  isAllowanceBenefit,
  isLoanBenefit,
  isRecurringBenefit,
  isUsingOpeningBalance
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { UserInsuranceDependants } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { getAdditionalMembersOptions } from "@v2/feature/benefits/subfeature/insurance/insurance.util";
import { UserFamilyMemberType } from "@v2/feature/user/features/user-forms/user-family/user-family.interface";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserEndpoints } from "@v2/feature/user/user.api";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
export const UpdateUserCustomBenefitDrawer = ({ isOpen, setIsOpen, userBenefit, refresh, onClose }) => {
  const { data: userFamilyMembers } = useApiClient(UserEndpoints.getUserFamilyMembers(userBenefit.userId), {
    suspense: false
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    UpdateUserCustomBenefitDrawerContent,
    {
      userBenefit,
      refresh,
      setIsOpen,
      userFamilyMembers: userFamilyMembers != null ? userFamilyMembers : []
    }
  ) });
};
export const UpdateUserCustomBenefitDrawerContent = ({
  userBenefit,
  refresh,
  setIsOpen,
  userFamilyMembers
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();
  const [anchorEl, setAnchorEl] = useState(null);
  const { polyglot } = usePolyglot();
  const [mode, setMode] = useState(null);
  const hasInsuranceAll = hasScopes(["insurance"]);
  const isRecurring = isRecurringBenefit((_a = userBenefit.customBenefit) == null ? void 0 : _a.type);
  const isAllowance = isAllowanceBenefit((_b = userBenefit.customBenefit) == null ? void 0 : _b.type);
  const isLoan = isLoanBenefit((_c = userBenefit.customBenefit) == null ? void 0 : _c.type);
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2;
      if (!((_a2 = userBenefit.customBenefit) == null ? void 0 : _a2.type)) {
        showMessage("Could not determine the benefit type.", "error");
        return;
      }
      const hasOpeningBalance = isUsingOpeningBalance(userBenefit.customBenefit.type);
      const data = {
        employerContribution: values.employerContribution ? Number(values.employerContribution) : null,
        employeeContribution: values.employeeContribution ? Number(values.employeeContribution) : null,
        dependants: values.dependants ? values.dependants : null,
        dependantsList: values.dependants ? (_b2 = values.dependantsList) != null ? _b2 : null : null,
        openingBalance: hasOpeningBalance ? Number(values.openingBalance) : null,
        numberOfInstallments: ((_c2 = userBenefit.customBenefit) == null ? void 0 : _c2.type) === CustomBenefitType.Loan ? Number(values.numberOfInstallments) : null
      };
      try {
        setLoading(true);
        yield CustomBenefitAPI.updateUserCustomBenefitById(
          userBenefit.userId,
          userBenefit.customBenefitId,
          userBenefit.id,
          data
        );
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, refresh, setIsOpen, showMessage, userBenefit]
  );
  const formik = useFormik({
    initialValues: {
      employerContribution: (_d = userBenefit == null ? void 0 : userBenefit.employerContribution) != null ? _d : null,
      employeeContribution: (_e = userBenefit == null ? void 0 : userBenefit.employeeContribution) != null ? _e : null,
      dependants: (_f = userBenefit == null ? void 0 : userBenefit.dependants) != null ? _f : null,
      dependantsList: (_g = userBenefit == null ? void 0 : userBenefit.dependantsList) != null ? _g : null,
      openingBalance: (_h = userBenefit == null ? void 0 : userBenefit.openingBalance) != null ? _h : null,
      numberOfInstallments: (_i = userBenefit == null ? void 0 : userBenefit.numberOfInstallments) != null ? _i : null
    },
    validationSchema: yup.object({
      employerContribution: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired(),
      employeeContribution: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired(),
      dependants: yup.string().nullable().notRequired(),
      dependantsList: yup.array().of(yup.number().integer().typeError(polyglot.t("ValidationMessages.validValue"))).nullable().notRequired(),
      openingBalance: yup.number().typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired(),
      numberOfInstallments: yup.number().integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).nullable().notRequired()
    }),
    onSubmit
  });
  const onDelete = useCallback(() => __async(void 0, null, function* () {
    try {
      yield CustomBenefitAPI.deleteUserCustomBenefit(userBenefit.userId, userBenefit.customBenefitId, userBenefit.id);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [polyglot, userBenefit, refresh, setIsOpen, showMessage]);
  const dependantsListOptions = useMemo(() => {
    var _a2, _b2, _c2;
    if (formik.values.dependants === UserInsuranceDependants.SpousePartner)
      return (_a2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => [UserFamilyMemberType.Partner, UserFamilyMemberType.Spouse].includes(m.type)).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _a2 : [];
    if (formik.values.dependants === UserInsuranceDependants.Children)
      return (_b2 = userFamilyMembers == null ? void 0 : userFamilyMembers.filter((m) => m.type === UserFamilyMemberType.Child).map((m) => ({
        value: m.id,
        label: m.name
      }))) != null ? _b2 : [];
    return (_c2 = userFamilyMembers == null ? void 0 : userFamilyMembers.map((m) => ({ value: m.id, label: m.name }))) != null ? _c2 : [];
  }, [userFamilyMembers, formik.values.dependants]);
  const selectedDependantsList = useMemo(() => {
    if (!formik.values.dependantsList || !userFamilyMembers) return [];
    return formik.values.dependantsList.map((id) => {
      const familyMember = userFamilyMembers.find((m) => m.id === id);
      return familyMember ? { label: familyMember.name, value: familyMember.id } : null;
    }).filter(Boolean);
  }, [formik.values.dependantsList, userFamilyMembers]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (_k = (_j = userBenefit.customBenefit) == null ? void 0 : _j.name) != null ? _k : polyglot.t("BenefitModule.userBenefit") }),
      hasInsuranceAll && /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: iconButtonSx,
          onClick: (e) => {
            setAnchorEl(e.currentTarget);
            setMode("delete");
            e.stopPropagation();
          },
          children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("General.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: userBenefit.userId, sx: { mt: spacing.m5 }, nameSx: __spreadValues({}, themeFonts.headline3) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { my: spacing.m20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: polyglot.t("General.effectiveDate") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", sx: { fontWeight: "500" }, children: userBenefit.effectiveDate })
    ] }),
    (isAllowance || isLoan) && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "openingBalance",
        label: polyglot.t("General.amount"),
        value: formik.values.openingBalance,
        onChange: formik.handleChange,
        error: formik.touched.openingBalance && !!formik.errors.openingBalance,
        helperText: (_l = formik.touched.openingBalance && formik.errors.openingBalance) != null ? _l : " ",
        endAdornment: "none"
      }
    ) }),
    isLoan && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "numberOfInstallments",
        label: polyglot.t("BenefitModule.numberOfInstallments"),
        value: formik.values.numberOfInstallments,
        onChange: formik.handleChange,
        error: formik.touched.numberOfInstallments && !!formik.errors.numberOfInstallments,
        helperText: (_m = formik.touched.numberOfInstallments && formik.errors.numberOfInstallments) != null ? _m : " ",
        endAdornment: "none"
      }
    ) }),
    isRecurring && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employerContribution",
        label: polyglot.t("BenefitModule.employerContribution"),
        value: formik.values.employerContribution,
        onChange: formik.handleChange,
        error: formik.touched.employerContribution && !!formik.errors.employerContribution,
        helperText: (_n = formik.touched.employerContribution && formik.errors.employerContribution) != null ? _n : " ",
        endAdornment: "none"
      }
    ) }),
    isRecurring && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "employeeContribution",
        label: polyglot.t("BenefitModule.employeeContribution"),
        value: formik.values.employeeContribution,
        onChange: formik.handleChange,
        error: formik.touched.employeeContribution && !!formik.errors.employeeContribution,
        helperText: (_o = formik.touched.employeeContribution && formik.errors.employeeContribution) != null ? _o : " ",
        endAdornment: "none"
      }
    ) }),
    isRecurring && userFamilyMembers.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "dependants",
        label: polyglot.t("BenefitModule.additionalMembers"),
        options: [{ label: polyglot.t("General.none"), value: "" }, ...getAdditionalMembersOptions(polyglot)],
        value: formik.values.dependants,
        onChange: formik.handleChange,
        error: !!formik.errors.dependants && formik.touched.dependants,
        helperText: formik.touched.dependants && formik.errors.dependants
      }
    ) }) : isRecurring ? /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("BenefitModule.additionalMembers") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("BenefitModule.includeAdditionalBeneficiaries") })
    ] }) : null,
    isRecurring && userFamilyMembers.length > 0 && formik.values.dependants && /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { mt: spacing.m10 }), children: /* @__PURE__ */ jsx(
      MultipleSelectCheckbox,
      {
        label: polyglot.t("BenefitModule.additionalMembersList"),
        id: "dependantsList",
        limitTags: -1,
        options: dependantsListOptions,
        value: selectedDependantsList,
        onChange: (_, values) => {
          const updatedOptionList = values.map(({ value }) => value);
          formik.setFieldValue("dependantsList", updatedOptionList);
        },
        isOptionEqualToValue: (x, y) => x.value === y.value,
        getOptionLabel: ({ label }) => label
      }
    ) }),
    hasInsuranceAll && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m40 }, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.update"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: Boolean(anchorEl),
        onClose: () => {
          setAnchorEl(null);
          setTimeout(() => setMode(null), 400);
        },
        anchorEl,
        takeAction: () => __async(void 0, null, function* () {
          if (mode === "delete") yield onDelete();
          setMode(null);
        }),
        message: polyglot.t("BenefitModule.deleteUserFromBenefit"),
        callToAction: polyglot.t("General.yes")
      }
    )
  ] }) });
};
