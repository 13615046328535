"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { ProfileTab } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { ScopesControl } from "@/component/widgets/Scopes";
import useScopes from "@/hooks/scopes.hook";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { UserProfileProvider } from "@/v2/feature/user/context/user-profile.context";
import { UserContractCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-contract-card.component";
import { CustomSections } from "@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-card.component";
import { IdentityChecksCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-identity-checks-card.component";
import { UserProfileLifecycleCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-lifecycle-card.component";
import { RoleCard } from "@/v2/feature/user/features/user-profile/details/components/user-profile-role-card.component";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function UserWork({ userId, onUpdate, missingFields }) {
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId });
  const finallyRefreshUserObject = (section) => __async(this, null, function* () {
    if (["user.basicInfo", "user.role", "user.lifecycle"].includes(section)) {
    }
    yield onUpdate();
  });
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Work" }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: { pt: spacing.p25 }, children: /* @__PURE__ */ jsx(UserProfileProvider, { children: /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.role:read"], context: scopesContext, children: /* @__PURE__ */ jsx(RoleCard, { userId, onSubmit: () => finallyRefreshUserObject("user.role") }) }),
      /* @__PURE__ */ jsx(
        ScopesControl,
        {
          scopes: ["user.contract", "user.contract:read", "user.contract:read:manager"],
          context: scopesContext,
          children: /* @__PURE__ */ jsx(UserContractCard, { userId, handleSubmit: () => finallyRefreshUserObject("user.contract") })
        }
      ),
      /* @__PURE__ */ jsx(
        CustomSections,
        {
          userId,
          formTab: ProfileTab.Work,
          onSubmit: () => finallyRefreshUserObject(""),
          missingFields
        }
      ),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.lifecycle", "user.lifecycle:read"], context: scopesContext, children: /* @__PURE__ */ jsx(
        UserProfileLifecycleCard,
        {
          userId,
          handleSubmit: () => finallyRefreshUserObject("user.lifecycle"),
          missingFields
        }
      ) }),
      /* @__PURE__ */ jsx(ScopesControl, { scopes: ["user.rightWork:read"], context: scopesContext, children: /* @__PURE__ */ jsx(IdentityChecksCard, { userId, missingFields }) })
    ] }) }) })
  ] });
}
