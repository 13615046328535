"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { v4 as uuidv4 } from "uuid";
import { BillingDiscountAPI } from "@/api-client/billing-discount.api";
import useMessage from "@/hooks/notification.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const SuperAdminBillingDiscountEditForm = ({ discount, onSave }) => {
  var _a, _b;
  const [discountName, setDiscountName] = useState(discount == null ? void 0 : discount.description);
  const [discountType, setDiscountType] = useState(discount == null ? void 0 : discount.discountType);
  const [discountAmount, setDiscountAmount] = useState(Number(discount == null ? void 0 : discount.amountValue));
  const [discountStartDate, setDiscountStartDate] = useState((_a = discount == null ? void 0 : discount.startDate) != null ? _a : "");
  const [discountEndDate, setDiscountEndDate] = useState((_b = discount == null ? void 0 : discount.endDate) != null ? _b : "");
  const [isSaving, setIsSaving] = useState(false);
  const [showMessage] = useMessage();
  const handleNameChange = (e) => {
    setDiscountName(e.target.value);
  };
  const handleTypeChange = (e) => {
    setDiscountType(e.target.value);
  };
  const handleAmountChange = (e) => {
    setDiscountAmount(Number(e.target.value));
  };
  const handleStartDateChange = (newDate) => {
    setDiscountStartDate(newDate);
  };
  const handleEndDateChange = (newDate) => {
    setDiscountEndDate(newDate);
  };
  const saveDiscount = () => __async(void 0, null, function* () {
    try {
      const discountPayload = {
        id: Number(discount == null ? void 0 : discount.id),
        amountValue: Number(discountAmount),
        description: discountName,
        discountType,
        startDate: discountStartDate,
        endDate: discountEndDate
      };
      setIsSaving(true);
      yield BillingDiscountAPI.editDiscount(discountPayload);
      showMessage("Discount updated successfully", "success");
      if (onSave) {
        yield onSave();
      }
      setIsSaving(false);
    } catch (e) {
      console.error("::: Failed to update discount ::", e);
      showMessage("Failed to update discount", "error");
      setIsSaving(false);
    }
  });
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Box, { sx: { marginTop: 5 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey, mt: 4, mb: 4 }), children: "Edit discount" }),
    /* @__PURE__ */ jsx(
      TextField,
      {
        disabled: true,
        sx: { mb: 4 },
        variant: "outlined",
        fullWidth: true,
        label: "Discount ID",
        defaultValue: discount == null ? void 0 : discount.id
      },
      11
    ),
    /* @__PURE__ */ jsx(
      TextField,
      {
        required: true,
        sx: { mb: 4 },
        variant: "outlined",
        fullWidth: true,
        onChange: handleNameChange,
        label: "Discount Name",
        value: discountName,
        defaultValue: discountName
      },
      12
    ),
    /* @__PURE__ */ jsxs(
      TextField,
      {
        select: true,
        sx: { mb: 4 },
        variant: "outlined",
        fullWidth: true,
        onChange: handleTypeChange,
        label: "Discount Type",
        value: discountType,
        defaultValue: discountType,
        children: [
          /* @__PURE__ */ jsx(MenuItem, { value: "none", children: "None" }),
          /* @__PURE__ */ jsx(MenuItem, { value: "fixed", children: "Fixed (\xA3)" }),
          /* @__PURE__ */ jsx(MenuItem, { value: "percentage", children: "Percentage (%)" })
        ]
      },
      13
    ),
    /* @__PURE__ */ jsx(
      TextField,
      {
        sx: { mb: 4 },
        variant: "outlined",
        fullWidth: true,
        onChange: handleAmountChange,
        label: "Discount Amount",
        value: discountAmount,
        defaultValue: discountAmount
      },
      14
    ),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: discountStartDate,
        onChange: (value) => {
          handleStartDateChange(value);
        },
        name: "startDate",
        label: "Start date"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: discountEndDate,
        onChange: (value) => {
          handleEndDateChange(value);
        },
        name: "endDate",
        label: "End date"
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        type: "button",
        loading: isSaving,
        sizeVariant: "large",
        colorVariant: "primary",
        onClick: saveDiscount,
        fullWidth: true
      },
      uuidv4()
    ) })
  ] }) });
};
