"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as IDVerifyIcon } from "@/images/onboarding-step-icons/IDBig.svg";
import { ActionSelectionItem } from "@/v2/components/action-selection-group.component";
import { EditIdVerify } from "@/v2/feature/onboarding/onboarding-items/id-verify/edit-id-verify.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const IDVerifyOnboardingItem = ({ template, setDrawerOpen, setDrawerView, onDelete }) => {
  const { polyglot } = usePolyglot();
  const idVerifyChips = useMemo(() => {
    var _a;
    const identityChecks = (_a = template.template.idverify) != null ? _a : [];
    return identityChecks.map((config) => {
      var _a2;
      return (_a2 = config.check) == null ? void 0 : _a2.name;
    }).filter(Boolean);
  }, [template.template.idverify]);
  return /* @__PURE__ */ jsx(
    ActionSelectionItem,
    {
      step: {
        Icon: IDVerifyIcon,
        title: polyglot.t("IDVerifyOnboardingItem.title"),
        description: polyglot.t("IDVerifyOnboardingItem.desc")
      },
      chips: idVerifyChips,
      addButtonIcon: template.template.idverify && template.template.idverify.length > 0 ? "edit" : "plus",
      onAddClick: () => {
        var _a, _b;
        const selectedPackageIds = (_b = (_a = template.template.idverify) == null ? void 0 : _a.map((config) => {
          var _a2;
          return (_a2 = config.check) == null ? void 0 : _a2.id;
        }).filter((id) => !!id)) != null ? _b : [];
        setDrawerView(
          /* @__PURE__ */ jsx(
            EditIdVerify,
            {
              selectedPackageIds,
              onSave: (checkPackages) => __async(void 0, null, function* () {
                if (checkPackages.length === 0) {
                  template.template.idverify = [];
                } else {
                  template.template.idverify = checkPackages.map((pkg) => ({
                    check: pkg,
                    enabled: true
                  }));
                }
                setDrawerOpen(false);
              })
            }
          )
        );
        setDrawerOpen(true);
      },
      sx: {
        py: spacing.py20
      },
      onDeleteClick: template.template.idverify && onDelete
    }
  );
};
