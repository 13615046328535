"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { ScrollToBottomButton } from "@/v2/components/scroll-to-bottom-button.component";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  ReviewerTypesOrderMap,
  getReviewerTypesChips
} from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import {
  QuestionTypeMap
} from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { useScrollHandler } from "@/v2/hook/use-scroll-handler.hook";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GrowthQuestionLibraryContent = ({
  questions,
  setIsQuestionsModalOpen,
  action,
  setSearchInput,
  searchInput,
  allGrowthFactors,
  setFilterString,
  filterString,
  factorFilterTypes
}) => {
  const [selectedQuestionIds, setSelectedQuestionIds] = useState(/* @__PURE__ */ new Set());
  const { containerRef, showScrollBottomButton, scrollToBottom, showScrollTopButton, scrollToTop } = useScrollHandler();
  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedQuestionIds(new Set(questions == null ? void 0 : questions.map(({ id }) => id)));
    } else {
      setSelectedQuestionIds(/* @__PURE__ */ new Set());
    }
  };
  const handleSelectQuestion = (id, checked) => {
    const newSelectedQuestionIds = new Set(selectedQuestionIds);
    if (checked) {
      newSelectedQuestionIds.add(id);
    } else {
      newSelectedQuestionIds.delete(id);
    }
    setSelectedQuestionIds(newSelectedQuestionIds);
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      ref: containerRef,
      sx: {
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
        overflowY: "auto",
        maxHeight: "calc(100vh - 10px)"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2, px: spacing.s2 }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", pt: spacing.s2 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Question library" }),
            /* @__PURE__ */ jsx(
              ButtonComponent,
              {
                colorVariant: "primary",
                sizeVariant: "small",
                disabled: selectedQuestionIds.size === 0,
                onClick: () => __async(void 0, null, function* () {
                  if (selectedQuestionIds) yield action(selectedQuestionIds);
                  setIsQuestionsModalOpen(false);
                }),
                children: "Add questions"
              }
            )
          ] }),
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              style: { width: "100%", boxSizing: "border-box", marginTop: spacing.s2 },
              query: searchInput,
              handleChange: (e) => setSearchInput(e.target.value),
              placeholder: "Search question"
            }
          ),
          factorFilterTypes && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
            CategoryFilters,
            {
              filterTypes: factorFilterTypes,
              setFilterString,
              filterString
            }
          ) })
        ] }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              mt: spacing.s2,
              px: spacing.s2,
              py: spacing.p12,
              display: "flex",
              alignItems: "center",
              gap: spacing.s1
            },
            children: [
              /* @__PURE__ */ jsx(
                CheckboxComponent,
                {
                  labelPlacement: "end",
                  checked: selectedQuestionIds.size > 0 && selectedQuestionIds.size === (questions == null ? void 0 : questions.length),
                  disabled: (questions == null ? void 0 : questions.length) === 0,
                  onChange: (_, checked) => handleSelectAll(checked),
                  sx: { width: "100%" }
                }
              ),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "All questions" })
            ]
          }
        ),
        questions ? /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              maxHeight: "100%",
              display: "flex",
              flexDirection: "column",
              gap: spacing.s2,
              px: spacing.px16,
              boxSizing: "border-box"
            },
            children: questions.map((question) => /* @__PURE__ */ jsx(
              QuestionRow,
              {
                question,
                allGrowthFactors,
                selectedQuestionIds,
                handleSelectQuestion
              },
              question.id
            ))
          }
        ) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: spacing.px16, children: "No questions found" }),
        /* @__PURE__ */ jsx(
          ScrollToBottomButton,
          {
            showDown: showScrollBottomButton,
            showUp: showScrollTopButton,
            onScrollBottom: scrollToBottom,
            onScrollTop: scrollToTop
          }
        )
      ]
    }
  );
};
const QuestionRow = ({
  question,
  allGrowthFactors,
  selectedQuestionIds,
  handleSelectQuestion
}) => {
  var _a, _b;
  const factorColor = (_b = (_a = allGrowthFactors == null ? void 0 : allGrowthFactors.find((factor) => factor.name === question.factor)) == null ? void 0 : _a.color) != null ? _b : themeColors.Grey;
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box"
      },
      children: /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            gap: spacing.s1
          },
          children: [
            /* @__PURE__ */ jsx(
              Box,
              {
                sx: {
                  display: "flex",
                  alignItems: "center"
                },
                children: /* @__PURE__ */ jsx(
                  CheckboxComponent,
                  {
                    role: "checkbox",
                    id: "question-check",
                    checked: selectedQuestionIds.has(question.id),
                    onChange: (_, checked) => handleSelectQuestion(question.id, checked),
                    sx: { mr: "auto" }
                  }
                )
              }
            ),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: spacing.s1,
                  borderRadius: radius.br12,
                  border: borders.middle,
                  boxSizing: "border-box",
                  p: spacing.s2,
                  width: "100%",
                  "&:hover": {
                    backgroundColor: themeColors.TableHover,
                    cursor: "pointer"
                  }
                },
                children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: question.questionText }),
                  /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                        boxSizing: "border-box",
                        gap: spacing.s1
                      },
                      children: [
                        question.factor && /* @__PURE__ */ jsx(
                          ChipComponent,
                          {
                            backgroundColor: "white",
                            border: "middle",
                            name: /* @__PURE__ */ jsxs(
                              Box,
                              {
                                sx: {
                                  display: "flex",
                                  alignItems: "center",
                                  gap: spacing.g4
                                },
                                children: [
                                  /* @__PURE__ */ jsx(
                                    "div",
                                    {
                                      style: {
                                        backgroundColor: factorColor != null ? factorColor : themeColors.Grey,
                                        height: "8px",
                                        width: "8px",
                                        borderRadius: radius.br25
                                      }
                                    }
                                  ),
                                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: question.factor })
                                ]
                              }
                            )
                          }
                        ),
                        question.type && /* @__PURE__ */ jsx(
                          ChipComponent,
                          {
                            backgroundColor: "white",
                            border: "middle",
                            name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: QuestionTypeMap[question.type] })
                          }
                        ),
                        question.type && question.type === "scaleQuestion" && question.scaleConfig && /* @__PURE__ */ jsx(
                          ChipComponent,
                          {
                            backgroundColor: "white",
                            border: "middle",
                            name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: question.scaleConfig.type })
                          }
                        )
                      ]
                    }
                  ),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey, gap: spacing.g4, display: "flex" }, children: question.reviewerSelect && question.visibilitySettings ? question.reviewerSelect.sort((a, b) => ReviewerTypesOrderMap[a] - ReviewerTypesOrderMap[b]).map((r) => {
                    var _a2, _b2, _c;
                    const hideVisibility = r !== ReviewerTypes.Self && Boolean(
                      r === ReviewerTypes.Manager && ((_a2 = question.visibilitySettings) == null ? void 0 : _a2.hideManagerResult) || r === ReviewerTypes.Upward && ((_b2 = question.visibilitySettings) == null ? void 0 : _b2.hideUpwardResult) || r === ReviewerTypes.Peer && ((_c = question.visibilitySettings) == null ? void 0 : _c.hidePeerResult)
                    );
                    return getReviewerTypesChips(hideVisibility)[r];
                  }) : "" })
                ]
              }
            )
          ]
        }
      )
    }
  );
};
