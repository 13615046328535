"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, FormControl, Stack } from "@mui/material";
import { ApproversList } from "@v2/feature/approval-rule/components/approvers-list.component";
import {
  getExpenseTotalsBasedOnLineItems,
  getExpenseTypeOptions,
  getTaxRateForTotalSection,
  getTaxRateFromSelectedOption,
  NO_VAT_VALUE
} from "@v2/feature/payments/expenses.util";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { popularCurrencyShortOptions } from "@v2/infrastructure/currency/currency.interface";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { ExpenseAPI } from "@/api-client/expense.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  CUSTOM_BENEFIT_EXPENSE_ROUTE,
  EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE,
  EXPENSE_ME_EDIT_EXPENSE_ROUTE,
  EXPENSE_TEAM_EDIT_EXPENSE_ROUTE,
  EXPENSES_COMPANY_OVERVIEW_ROUTE,
  EXPENSES_ME_OVERVIEW_ROUTE,
  EXPENSES_ME_ROUTE,
  EXPENSES_TEAM_OVERVIEW_ROUTE,
  USER_EXPENSES_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { Divider } from "@/v2/components/divider.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UploadPublicImage } from "@/v2/components/uploadPublicImage/upload-public-image.component";
import { SideAccentedTextLabel } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/side-accented-text-label.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { CustomBenefitAPI } from "@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  ExpenseLineItemTable,
  getNewLineItemValidationSchema
} from "@/v2/feature/payments/pages/components/expense-line-item-table.component";
import {
  DEFAULT_CURRENCY,
  ExpenseStatus
} from "@/v2/feature/payments/payments.interface";
import { PaymentSettingsNewTypeDrawer } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component";
import { PaymentTypeSettingsEndpoints } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
export const NewExpensePage = ({
  preselectedEmployee,
  fromUserProfile = false,
  reach = "company",
  userBenefit,
  maxAmount,
  editMode = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { getScopesContext, hasScopes } = useScopes();
  const [submitting, setSubmitting] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [approversSets, setApproversSets] = useState([]);
  const [fromUserIsMemberOfBenefitForSelectedPolicy, setFromUserIsMemberOfBenefitForSelectedPolicy] = useState(false);
  const [memberOfBenefit, setMemberOfBenefit] = useState(void 0);
  const { data, mutate: refreshExpensePaymentTypeSettingsData } = useApiClient(
    PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId(),
    {
      suspense: false
    }
  );
  const [globalState] = useContext(GlobalContext);
  const { user, publicURL } = globalState;
  const scopesContext = getScopesContext({ userId: user.userId });
  const hasInsuranceOwnerScope = hasScopes(["insurance"], scopesContext);
  const expenseToEdit = useMemo(
    () => {
      var _a2;
      return ((_a2 = routerHistory.location.state) == null ? void 0 : _a2.expenseToEdit) || null;
    },
    [routerHistory]
  );
  const currentUserIsExpenseAdmin = hasScopes(
    ["expenses:all", "expenses:manager"],
    getScopesContext({ userId: user == null ? void 0 : user.userId })
  );
  const currentUserIsManagerOfExpenseOwner = (expenseToEdit == null ? void 0 : expenseToEdit.from) ? checkScopes(user, ["expenses:manager"], { userId: expenseToEdit.from }) : false;
  if (editMode && expenseToEdit && expenseToEdit.from !== user.userId && !currentUserIsExpenseAdmin && !currentUserIsManagerOfExpenseOwner) {
    routerHistory.push({
      pathname: generatePath(EXPENSES_ME_ROUTE)
    });
  }
  const [typeDrawerOpen, setIsTypeDrawerOpen] = useState(false);
  const isExpenseAdmin = checkScopes(user, ["expenses:all"], { userId: user.userId });
  const benefitPaymentTypeIds = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = userBenefit == null ? void 0 : userBenefit.customBenefit) == null ? void 0 : _a2.paymentTypes) == null ? void 0 : _b2.map((paymentType) => paymentType.id);
    },
    [userBenefit]
  );
  const existingTypeId = useMemo(() => {
    if (editMode && (expenseToEdit == null ? void 0 : expenseToEdit.typeId)) return expenseToEdit.typeId;
    if (!editMode && benefitPaymentTypeIds && benefitPaymentTypeIds.length === 1) return benefitPaymentTypeIds[0];
    return null;
  }, [benefitPaymentTypeIds, editMode, expenseToEdit]);
  const redirectDraftExpenseToEditModeBasedOnReach = useCallback(
    (draftExpense) => {
      switch (reach) {
        case "user":
          routerHistory.push(generatePath(EXPENSE_ME_EDIT_EXPENSE_ROUTE, { id: draftExpense.id }));
          break;
        case "team":
          routerHistory.push(generatePath(EXPENSE_TEAM_EDIT_EXPENSE_ROUTE, { id: draftExpense.id }));
          break;
        case "company":
          routerHistory.push(generatePath(EXPENSE_COMPANY_EDIT_EXPENSE_ROUTE, { id: draftExpense.id }));
          break;
      }
    },
    [reach, routerHistory]
  );
  const { totalAmount: initialValueForAmount, totalTaxAmount: initialValueForTaxAmount } = expenseToEdit && expenseToEdit.lineItems ? getExpenseTotalsBasedOnLineItems(expenseToEdit.lineItems) : { totalAmount: 0, totalTaxAmount: 0 };
  const firstNonEmptyTaxRateOption = expenseToEdit ? (_c = (_b = (_a = expenseToEdit == null ? void 0 : expenseToEdit.lineItems) == null ? void 0 : _a.find((item) => item.taxRate && ![NO_VAT_VALUE, 0].includes(item.taxRate))) == null ? void 0 : _b.taxRate) != null ? _c : NO_VAT_VALUE : NO_VAT_VALUE;
  const formik = useFormik({
    initialValues: {
      typeId: existingTypeId,
      from: (_e = (_d = expenseToEdit == null ? void 0 : expenseToEdit.from) != null ? _d : preselectedEmployee) != null ? _e : void 0,
      status: (_f = expenseToEdit == null ? void 0 : expenseToEdit.status) != null ? _f : ExpenseStatus.Pending,
      date: (_g = expenseToEdit == null ? void 0 : expenseToEdit.date) != null ? _g : new LocalDate().toDateString(),
      gross: (_h = expenseToEdit == null ? void 0 : expenseToEdit.gross) != null ? _h : 0,
      taxRate: firstNonEmptyTaxRateOption,
      taxAmount: expenseToEdit ? initialValueForTaxAmount : 0,
      // only calculated for display - not sent for creation
      amount: expenseToEdit ? initialValueForAmount : 0,
      isTaxIncluded: true,
      notes: (_i = expenseToEdit == null ? void 0 : expenseToEdit.notes) != null ? _i : null,
      currency: ((_j = expenseToEdit == null ? void 0 : expenseToEdit.currency) != null ? _j : preselectedEmployee === user.userId) ? (_k = user.currency) != null ? _k : DEFAULT_CURRENCY : DEFAULT_CURRENCY,
      customBenefitId: (_m = (_l = expenseToEdit == null ? void 0 : expenseToEdit.customBenefitId) != null ? _l : userBenefit == null ? void 0 : userBenefit.customBenefitId) != null ? _m : null,
      userCustomBenefitId: (_o = (_n = expenseToEdit == null ? void 0 : expenseToEdit.userCustomBenefitId) != null ? _n : userBenefit == null ? void 0 : userBenefit.id) != null ? _o : null,
      attachment: (_p = expenseToEdit == null ? void 0 : expenseToEdit.attachment) != null ? _p : null,
      lineItems: (_q = expenseToEdit == null ? void 0 : expenseToEdit.lineItems) != null ? _q : [],
      selectedExpenseType: null
    },
    enableReinitialize: editMode,
    validationSchema: yup.object({
      // TODO: missing validation for typeId, etc
      isTaxIncluded: yup.boolean(),
      from: yup.number().integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      taxRate: yup.number().nullable().required(polyglot.t("ValidationMessages.requiredField")),
      attachment: yup.string().nullable().when("typeId", {
        is: (typeId) => {
          const selectedExpenseType2 = expenseTypes == null ? void 0 : expenseTypes.find((type) => type.id === typeId);
          return (selectedExpenseType2 == null ? void 0 : selectedExpenseType2.requireAttachment) === true;
        },
        then: yup.string().nullable().required("Receipt is required"),
        otherwise: yup.string().nullable()
      }),
      currency: yup.string().required("Currency is required"),
      gross: maxAmount || maxAmount === 0 ? yup.number().nullable().typeError(polyglot.t("ValidationMessages.validValue")).min(0.01, polyglot.t("ValidationMessages.greaterThan", { num: 1 })).max(maxAmount, polyglot.t("ValidationMessages.lessOrEqual", { num: maxAmount })).required(polyglot.t("ValidationMessages.requiredField")) : yup.number().nullable().min(0.01, polyglot.t("ValidationMessages.greaterThan", { num: 1 })).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      notes: yup.string().nullable().max(255, polyglot.t("ValidationMessages.maxLength", { num: 255 })).required(polyglot.t("ValidationMessages.requiredField")),
      lineItems: yup.array().of(getNewLineItemValidationSchema(polyglot)).min(1, polyglot.t("ValidationMessages.minLength", { num: 1 })).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2, _d2;
      try {
        setSubmitting(true);
        const {
          date,
          isTaxIncluded,
          gross,
          taxRate,
          amount,
          from,
          typeId,
          notes,
          status,
          currency,
          attachment,
          lineItems
        } = values;
        if (!amount || !date || !typeId || !from) return;
        const inDraftMode = status === ExpenseStatus.Draft && savingDraft;
        const expenseData = {
          date,
          isTaxIncluded,
          notes: notes != null ? notes : null,
          from,
          amount: Number(amount),
          gross: Number(gross),
          taxRate: isTaxIncluded ? getTaxRateFromSelectedOption(taxRate) : null,
          currency,
          typeId: +typeId,
          attachment,
          status: inDraftMode ? ExpenseStatus.Draft : ExpenseStatus.Pending,
          customBenefitId: (_b2 = (_a2 = expenseToEdit == null ? void 0 : expenseToEdit.customBenefitId) != null ? _a2 : userBenefit == null ? void 0 : userBenefit.customBenefitId) != null ? _b2 : null,
          userCustomBenefitId: (_d2 = (_c2 = expenseToEdit == null ? void 0 : expenseToEdit.userCustomBenefitId) != null ? _c2 : userBenefit == null ? void 0 : userBenefit.id) != null ? _d2 : null,
          lineItems
        };
        let createdExpense;
        if (editMode && (expenseToEdit == null ? void 0 : expenseToEdit.id)) {
          yield ExpenseAPI.updateExpense(expenseToEdit == null ? void 0 : expenseToEdit.id, expenseData);
        } else {
          createdExpense = yield ExpenseAPI.createExpense(expenseData);
        }
        if (!inDraftMode) {
          showMessage(polyglot.t("NewExpensePage.successMessages.expenseCreation"), "success");
          routerHistory.push(
            reach === "team" ? EXPENSES_TEAM_OVERVIEW_ROUTE : fromUserProfile && preselectedEmployee ? generatePath(USER_EXPENSES_ROUTE, { userId: preselectedEmployee }) : preselectedEmployee ? EXPENSES_ME_OVERVIEW_ROUTE : EXPENSES_COMPANY_OVERVIEW_ROUTE
          );
        } else {
          showMessage(polyglot.t("NewExpensePage.successMessages.expenseEdit"), "success");
          if ((createdExpense == null ? void 0 : createdExpense.id) && !editMode) {
            redirectDraftExpenseToEditModeBasedOnReach(createdExpense);
          }
        }
      } catch (error) {
        showMessage(
          polyglot.t(
            editMode ? "NewExpensePage.errorMessages.expenseEdit" : "NewExpensePage.errorMessages.expenseCreation",
            { errorMessage: nestErrorMessage(error) }
          ),
          "error"
        );
      } finally {
        setSubmitting(false);
      }
    }),
    validateOnMount: true
  });
  useEffect(() => {
    const fetchApprovalList = () => __async(void 0, null, function* () {
      if (formik.values.from && formik.values.typeId) {
        const list = yield ExpenseAPI.getApprovalListForNewExpense(formik.values.from, formik.values.typeId);
        setApproversSets(list);
      } else {
        setApproversSets([]);
      }
    });
    fetchApprovalList();
  }, [formik.values.from, formik.values.typeId]);
  const expenseTypes = useMemo(() => {
    if (data == null ? void 0 : data.settings) {
      if (!preselectedEmployee && !formik.values.from) return data == null ? void 0 : data.settings;
      if (preselectedEmployee)
        return data.settings.filter((setting) => {
          var _a2;
          return (_a2 = setting == null ? void 0 : setting.selectedMembersIds) == null ? void 0 : _a2.includes(preselectedEmployee);
        });
      if (formik.values.from)
        return data.settings.filter(
          (setting) => {
            var _a2;
            return formik.values.from ? (_a2 = setting == null ? void 0 : setting.selectedMembersIds) == null ? void 0 : _a2.includes(formik.values.from) : true;
          }
        );
    } else {
      return [];
    }
  }, [data, formik.values.from, preselectedEmployee]);
  const expenseTypeOptions = useMemo(
    () => getExpenseTypeOptions(expenseTypes != null ? expenseTypes : [], benefitPaymentTypeIds),
    [expenseTypes, benefitPaymentTypeIds]
  );
  const selectedExpenseType = useMemo(() => {
    return formik.values.typeId ? expenseTypes == null ? void 0 : expenseTypes.find((type) => type.id === formik.values.typeId) : null;
  }, [expenseTypes, formik.values.typeId]);
  useEffect(() => {
    if (formik.values.typeId && expenseTypes) {
      const selectedType = expenseTypes.find((type) => type.id === formik.values.typeId);
      formik.setFieldValue("selectedExpenseType", selectedType);
    }
  }, [formik.values.typeId, expenseTypes]);
  const selectedTypeHasAccountingCode = useMemo(() => {
    var _a2;
    if (formik.values.typeId) {
      return (selectedExpenseType == null ? void 0 : selectedExpenseType.accountingCodeConfig) && ((_a2 = selectedExpenseType == null ? void 0 : selectedExpenseType.accountingCodeConfig) == null ? void 0 : _a2.length) >= 1;
    }
  }, [formik.values.typeId, selectedExpenseType == null ? void 0 : selectedExpenseType.accountingCodeConfig]);
  const accountingCodeOptions = useMemo(() => {
    var _a2, _b2;
    if (formik.values.typeId && selectedExpenseType) {
      return selectedTypeHasAccountingCode ? (_b2 = (_a2 = selectedExpenseType.accountingCodeConfig) == null ? void 0 : _a2.map((eachOption) => {
        if ((eachOption == null ? void 0 : eachOption.accountingCodeDescription) && (eachOption == null ? void 0 : eachOption.accountingCode)) {
          return {
            label: eachOption.accountingCodeDescription,
            value: eachOption.accountingCode
          };
        }
        return null;
      }).filter((option) => option !== null)) != null ? _b2 : [] : [];
    }
    return [];
  }, [formik.values.typeId, selectedExpenseType, selectedTypeHasAccountingCode]);
  const updateLineItems = useCallback(
    (updatedValues) => {
      formik.setFieldValue("lineItems", updatedValues, false);
      const { totalGross, totalAmount, totalTaxAmount } = getExpenseTotalsBasedOnLineItems(updatedValues);
      formik.setFieldValue("gross", totalGross);
      formik.setFieldValue("amount", totalAmount);
      formik.setFieldValue("taxAmount", totalTaxAmount);
      formik.validateForm();
    },
    [formik]
  );
  const handleSaveDraft = useCallback(() => {
    setSavingDraft(true);
    formik.setFieldValue("status", ExpenseStatus.Draft);
    formik.handleSubmit();
  }, [formik]);
  const handleSubmit = useCallback(() => {
    setSavingDraft(false);
    formik.handleSubmit();
  }, [formik]);
  const multipleLineItems = useMemo(() => {
    var _a2, _b2;
    return Boolean(((_b2 = (_a2 = formik.values) == null ? void 0 : _a2.lineItems) == null ? void 0 : _b2.length) > 1);
  }, [
    (_s = (_r = formik.values) == null ? void 0 : _r.lineItems) == null ? void 0 : _s.length
  ]);
  const errorInLineItems = useMemo(() => {
    var _a2, _b2;
    const touchedLineItems = Boolean(
      formik.touched && formik.touched.lineItems && ((_a2 = formik.touched.lineItems) == null ? void 0 : _a2.length) > 0
    );
    const errorInFields = Boolean(formik.errors && formik.errors.lineItems && ((_b2 = formik.errors.lineItems) == null ? void 0 : _b2.length) > 0);
    return errorInFields && touchedLineItems;
  }, [formik.errors, formik.touched]);
  const groupedItems = (formik.values.lineItems || []).filter((item) => item.taxRate !== null && item.amount !== null && item.gross !== null).reduce((acc, item) => {
    const taxRate = item.taxRate;
    const gross = item.gross;
    const amount = item.amount;
    if (!acc[taxRate]) {
      acc[taxRate] = 0;
    }
    acc[taxRate] += gross - amount;
    return acc;
  }, {});
  const submittingExpenseOnBehalfOfSelf = formik.values.from === user.userId;
  const redirectToFileExpenseAgainstBenefit = useCallback(() => {
    let redirectLink = formik.values.from && submittingExpenseOnBehalfOfSelf && memberOfBenefit ? generatePath(CUSTOM_BENEFIT_EXPENSE_ROUTE, { id: memberOfBenefit }) : void 0;
    if (redirectLink) routerHistory.push(redirectLink);
  }, [formik.values.from, memberOfBenefit, routerHistory, submittingExpenseOnBehalfOfSelf]);
  const checkUserMembership = useCallback(() => __async(void 0, null, function* () {
    if (!userBenefit && formik.values.from && formik.values.typeId && submittingExpenseOnBehalfOfSelf && hasInsuranceOwnerScope) {
      const result = yield CustomBenefitAPI.checkUserBenefitMembership(formik.values.from, formik.values.typeId);
      setFromUserIsMemberOfBenefitForSelectedPolicy(result && submittingExpenseOnBehalfOfSelf ? true : false);
      setMemberOfBenefit(result);
    }
  }), [formik.values.from, formik.values.typeId, hasInsuranceOwnerScope, submittingExpenseOnBehalfOfSelf, userBenefit]);
  useEffect(() => {
    checkUserMembership();
  }, [checkUserMembership, formik.values.from, formik.values.typeId, selectedExpenseType, userBenefit]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t(editMode ? "NewExpensePage.titleEdit" : "NewExpensePage.title") }),
        showBack: true,
        sx: { width: "650px", mx: "auto" }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { width: "100%", overflowY: "auto", mx: "auto" }, children: /* @__PURE__ */ jsx(Box, { sx: { width: "650px", mx: "auto" }, children: /* @__PURE__ */ jsx(
      ContentWrapper,
      {
        noHorizontalPadding: true,
        sx: {
          display: "flex",
          justifyContent: "center",
          width: "650px",
          px: "9px",
          pt: "2px"
        },
        loading: false,
        children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: [
          /* @__PURE__ */ jsx(Box, { sx: { width: "650px", mx: "auto", mt: spacing.mt20, minHeight: "50vh", overflowY: "auto" }, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
            (userBenefit == null ? void 0 : userBenefit.customBenefit) && /* @__PURE__ */ jsxs(Box, { sx: { mb: "20px" }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: userBenefit.customBenefit.name ? userBenefit.customBenefit.name : polyglot.t("BenefitModule.benefit") }),
              (maxAmount || maxAmount === 0) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Remaining allowance:" }),
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatMoney({
                  amount: maxAmount,
                  asDecimal: true
                }) })
              ] })
            ] }),
            !userBenefit && fromUserIsMemberOfBenefitForSelectedPolicy && /* @__PURE__ */ jsx(
              SideAccentedTextLabel,
              {
                text: polyglot.t("NewExpensePage.userIsMemberOfBenefitRedirectText"),
                linkText: polyglot.t("NewExpensePage.userIsMemberOfBenefitRedirectLink"),
                linkAction: redirectToFileExpenseAgainstBenefit,
                borderColor: themeColors.Blue
              }
            ),
            !preselectedEmployee && /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              SingleUserSelect,
              {
                name: "from",
                options: reach,
                value: formik.values.from,
                onChange: (_, x) => __async(void 0, null, function* () {
                  var _a2;
                  const userId = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
                  yield formik.setFieldValue("from", userId);
                }),
                label: polyglot.t("NewExpensePage.employee"),
                error: formik.touched.from && Boolean(formik.errors.from),
                helperText: formik.touched.from && formik.errors.from,
                disabled: !!preselectedEmployee
              }
            ) }),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              DatePickerComponent,
              {
                inputFormat: "DD/MM/YYYY",
                value: (_t = formik.values.date) != null ? _t : null,
                onChange: (value) => {
                  if (dayjs(value).isValid()) {
                    formik.setFieldValue("date", value);
                  }
                },
                name: "date",
                label: polyglot.t("NewExpensePage.date"),
                error: formik.touched.date && Boolean(formik.errors.date),
                helperText: formik.touched.date && formik.errors.date
              }
            ) }),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                name: "typeId",
                label: polyglot.t("NewExpensePage.expenseType"),
                options: expenseTypeOptions,
                value: (_u = formik.values.typeId) != null ? _u : "",
                onChange: (e) => {
                  formik.setFieldValue("typeId", e.target.value);
                },
                editList: !userBenefit ? {
                  handler: () => setIsTypeDrawerOpen(true),
                  isHidden: !isExpenseAdmin
                } : void 0
              }
            ) }),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                name: "currency",
                label: polyglot.t("NewExpensePage.currency"),
                options: popularCurrencyShortOptions,
                value: (_v = formik.values.currency) != null ? _v : DEFAULT_CURRENCY,
                onChange: (e) => {
                  formik.setFieldValue("currency", e.target.value);
                },
                error: formik.touched.currency && Boolean(formik.errors.currency),
                helperText: formik.touched.currency && formik.errors.currency
              }
            ) }),
            /* @__PURE__ */ jsx(
              ExpenseLineItemTable,
              {
                expense: formik.values,
                accountingCodeOptions: accountingCodeOptions != null ? accountingCodeOptions : [],
                updateLineItems,
                errorInLineItems,
                selectedExpenseType: selectedExpenseType != null ? selectedExpenseType : void 0
              }
            ),
            /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({}, fieldSx), size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                name: "notes",
                label: polyglot.t("NewExpensePage.notes"),
                value: formik.values.notes,
                type: "text",
                onChange: formik.handleChange,
                error: formik.touched.notes && Boolean(formik.errors.notes),
                helperText: formik.touched.notes && formik.errors.notes,
                clearText: () => formik.setFieldValue("notes", ""),
                multiline: true,
                minRows: 1
              }
            ) }),
            /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
              /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("NewExpensePage.receipt") }),
              formik.errors.attachment && /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "RedDark", children: formik.errors.attachment })
            ] }),
            /* @__PURE__ */ jsx(Divider, {}),
            /* @__PURE__ */ jsx(
              UploadPublicImage,
              {
                disabled: false,
                uploadedFileName: "Receipt uploaded",
                value: formik.values.attachment ? `${publicURL}/${formik.values.attachment}` : void 0,
                onChange: (temp) => __async(void 0, null, function* () {
                  formik.setFieldValue("attachment", temp);
                }),
                url: ExpenseAPI.getExpenseUploadReceiptURL(),
                refresh: () => __async(void 0, null, function* () {
                }),
                width: "100%",
                restrictedFormats: ["image/webp", "image/jpeg", "image/jpg", "image/png", "application/pdf"],
                handleDelete: () => __async(void 0, null, function* () {
                  try {
                    if (formik.values.attachment) {
                      yield ExpenseAPI.deleteUploadReceipt(formik.values.attachment);
                      formik.setFieldValue("attachment", null);
                    }
                  } catch (error) {
                    showMessage(
                      `${polyglot.t("CompanySettingsGeneralEdit.errorMessages.delete")}: ${nestErrorMessage(
                        error
                      )}`,
                      "error"
                    );
                  }
                })
              }
            ),
            /* @__PURE__ */ jsx(Divider, {}),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("NewInvoicePage.amount") }),
                Object.keys(groupedItems).length > 0 ? Object.keys(groupedItems).map((taxRate) => /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                  polyglot.t("NewInvoicePage.taxAmount"),
                  " ",
                  getTaxRateForTotalSection(Number(taxRate))
                ] }, taxRate)) : /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
                  polyglot.t("NewInvoicePage.taxAmount"),
                  !multipleLineItems ? getTaxRateForTotalSection(formik.values.taxRate) : ""
                ] }),
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("NewInvoicePage.gross") })
              ] }),
              /* @__PURE__ */ jsxs(Stack, { direction: "column", sx: { alignItems: "flex-end" }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatCurrency(
                  formik.values.amount ? formik.values.amount : 0,
                  void 0,
                  (_w = formik.values.currency) != null ? _w : DEFAULT_CURRENCY
                ) }),
                Object.keys(groupedItems).length > 0 ? Object.keys(groupedItems).map((taxRate) => {
                  var _a2;
                  return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: formatCurrency(
                    groupedItems[Number(taxRate)],
                    void 0,
                    (_a2 = formik.values.currency) != null ? _a2 : DEFAULT_CURRENCY
                  ) }, taxRate);
                }) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: formatCurrency(
                  formik.values.taxRate ? formik.values.taxAmount : 0,
                  void 0,
                  (_x = formik.values.currency) != null ? _x : DEFAULT_CURRENCY
                ) }),
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatCurrency(
                  (_y = formik.values.gross) != null ? _y : 0,
                  void 0,
                  (_z = formik.values.currency) != null ? _z : DEFAULT_CURRENCY
                ) })
              ] })
            ] }),
            /* @__PURE__ */ jsx(ApproversList, { approverSteps: approversSets, sx: { mt: "20px" } }),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  mt: spacing.mt10,
                  gap: spacing.g5
                },
                children: [
                  (expenseToEdit && expenseToEdit.status === ExpenseStatus.Draft || !expenseToEdit) && /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      fullWidth: true,
                      sizeVariant: "medium",
                      colorVariant: "secondary",
                      onClick: () => handleSaveDraft(),
                      loading: submitting,
                      children: polyglot.t("OnboardingUserInvite.saveDraft")
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      fullWidth: true,
                      loading: submitting,
                      colorVariant: "primary",
                      sizeVariant: "medium",
                      onClick: () => handleSubmit(),
                      children: polyglot.t("General.submit")
                    }
                  )
                ]
              }
            )
          ] }) }) }) }),
          typeDrawerOpen && /* @__PURE__ */ jsx(
            PaymentSettingsNewTypeDrawer,
            {
              isOpen: typeDrawerOpen,
              setIsOpen: setIsTypeDrawerOpen,
              refreshAllSettings: refreshExpensePaymentTypeSettingsData,
              typeForEdit: void 0
            }
          )
        ] })
      }
    ) }) })
  ] });
};
