"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ReactComponent as Status } from "@/images/reviews/Status.svg";
import { ReactComponent as Download } from "@/images/side-bar-icons/Download.svg";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { capturePNG } from "@/v2/util/export-reports.util";
export const ChartDisplay = ({
  title,
  subtitle,
  chart,
  wideChart = false,
  caption,
  hasTooltip = false,
  tooltipText,
  hasDownload
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      id: `download-chart-${title}`,
      sx: {
        padding: spacing.p16,
        border: borders.background,
        background: themeColors.white,
        borderRadius: radius.br12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { height: "80px", display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }, children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: title }),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, className: "hide-for-capture", children: [
              hasTooltip && /* @__PURE__ */ jsx(StyledTooltip, { title: tooltipText, placement: "right", children: /* @__PURE__ */ jsx(Status, __spreadValues({}, iconSize)) }),
              hasDownload && /* @__PURE__ */ jsx(
                "div",
                {
                  onClick: () => capturePNG(`download-chart-${title}`),
                  "aria-label": "Download chart as PNG",
                  role: "button",
                  style: { cursor: "pointer" },
                  children: /* @__PURE__ */ jsx(Download, __spreadValues({}, iconSize))
                }
              )
            ] })
          ] }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: subtitle })
        ] }),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: wideChart ? { height: "284px", width: "100%" } : {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "284px",
              width: "100%"
            },
            children: chart
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "flex-start", height: "20px", width: "100%" }, children: caption && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: caption }) })
      ]
    }
  );
};
