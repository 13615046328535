"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOrderStatus } from "@v2/feature/device/device.interface";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { useHistory } from "react-router-dom";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TECH_ROUTE } from "@/lib/routes";
import { themeColors } from "@/v2/styles/colors.styles";
export const DeviceActivationStepTLC = ({ deviceOrder }) => {
  const [errorOnConfirming, setErrorOnConfirming] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const [state, dispatch] = useContext(GlobalContext);
  const setDeviceDelivered = useCallback(() => __async(void 0, null, function* () {
    let isDeliveryConfirmed = false;
    try {
      if (deviceOrder.status === DeviceOrderStatus.Delivered) {
        history.push(TECH_ROUTE);
        return;
      }
      setLoading(true);
      yield DeviceAPI.confirmDeviceOrderDelivery(deviceOrder.id, new LocalDate().toDateString());
      showMessage("Delivery confirmed successfully.", "success");
      isDeliveryConfirmed = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      history.push(TECH_ROUTE);
    } catch (error) {
      if (isDeliveryConfirmed) {
        showMessage(
          "Could not update sidebar alerts. Refresh the page please to get the latest state of the page.",
          "error"
        );
      } else {
        setErrorOnConfirming(true);
        showMessage(`Could not confirm delivery. ${nestErrorMessage(error)}`, "error");
      }
    } finally {
      setLoading(false);
    }
  }), [deviceOrder, showMessage, state.user.userId]);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading, noHeader: true, border: false, children: /* @__PURE__ */ jsx(Box, { sx: { height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "400px", gap: spacing.gap20 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Take good care of your device. Keep it clean, dry and safe." }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: errorOnConfirming ? "Retry" : "All done!",
        colorVariant: "primary",
        sizeVariant: "small",
        onClick: setDeviceDelivered,
        loading
      }
    ) })
  ] }) }) });
};
