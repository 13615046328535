"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { ApprovalRuleDrawer } from "@v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
const getValidationSchema = (polyglot) => yup.object({
  approvalRuleId: yup.number().integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
});
export const EditCustomBenefitApprovalDrawer = ({
  isOpen,
  setIsOpen,
  customBenefit,
  approvalRules,
  refresh,
  refreshApprovalRules
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(
      EditCustomBenefitApprovalDrawerContent,
      {
        setIsOpen,
        refresh,
        approvalRules,
        customBenefit,
        refreshApprovalRules
      }
    )
  }
) });
export const EditCustomBenefitApprovalDrawerContent = ({
  setIsOpen,
  refresh,
  approvalRules,
  customBenefit,
  refreshApprovalRules
}) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const [isApprovalDrawerOpen, setIsApprovalDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const approvalRuleOptions = useMemo(
    () => approvalRules.map((rule) => ({ value: rule.id, label: rule.name })),
    [approvalRules]
  );
  const formik = useFormik({
    initialValues: {
      approvalRuleId: customBenefit.approvalRuleId
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const update = {
          approvalRuleId: values.approvalRuleId
        };
        yield CustomBenefitAPI.updateCustomBenefitApproval(customBenefit.id, update);
        showMessage(polyglot.t("BenefitModule.benefitUpdated"), "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("BenefitModule.editBenefit") }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "approvalRuleId",
        label: polyglot.t("BenefitModule.approvalRule"),
        options: approvalRuleOptions,
        value: formik.values.approvalRuleId,
        onChange: formik.handleChange,
        compareValue: formik.values.approvalRuleId,
        error: !!formik.errors.approvalRuleId && formik.touched.approvalRuleId,
        helperText: formik.touched.approvalRuleId && formik.errors.approvalRuleId,
        editList: {
          isHidden: !hasScopes(["company.settings:all"]),
          handler: () => {
            setIsApprovalDrawerOpen(true);
          }
        }
      }
    ),
    /* @__PURE__ */ jsx(
      ApprovalRuleDrawer,
      {
        isOpen: isApprovalDrawerOpen,
        setIsOpen: setIsApprovalDrawerOpen,
        approvalRule: null,
        refresh: () => __async(void 0, null, function* () {
          if (refreshApprovalRules) yield refreshApprovalRules();
        })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
