"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as Back } from "@/images/side-bar-icons/BackBtn.svg";
import { ReactComponent as Next } from "@/images/side-bar-icons/NextBtn.svg";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
export const BidirectionalButton = ({
  navigateBack,
  navigateNext,
  buttonTitle,
  disableNext,
  disableBack
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        backgroundColor: themeColors.Background,
        color: themeColors.DarkGrey,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: radius.br8
      },
      children: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            disableRipple: true,
            disableFocusRipple: true,
            onClick: navigateBack,
            title: "Previous",
            sx: iconCTAButtonSx,
            disabled: disableBack,
            children: /* @__PURE__ */ jsx(Back, __spreadValues({}, iconSize))
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "center", minWidth: "100px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: buttonTitle }) }),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            disableRipple: true,
            disableFocusRipple: true,
            onClick: navigateNext,
            title: "Next",
            sx: iconCTAButtonSx,
            disabled: disableNext,
            children: /* @__PURE__ */ jsx(Next, __spreadValues({}, iconSize))
          }
        )
      ]
    }
  );
};
