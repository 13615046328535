"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortBoolean, sortString } from "@v2/components/table/table-sorting.util";
import { Typography } from "@v2/components/typography/typography.component";
import { NumericYearSelectChip } from "@v2/feature/absence/me/policies/components/year-select-chip.component";
import { HolidayDrawer } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/components/holiday-drawer.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
export const HolidaysTable = ({ calendarId, holidays, refresh }) => {
  const { polyglot } = usePolyglot();
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [year, setYear] = useState((/* @__PURE__ */ new Date()).getFullYear());
  const filteredAndSortedHolidays = useMemo(() => {
    const filteredByYear = holidays.filter(
      (h) => h.isRecurring || new LocalDate(h.date).getDate().getFullYear() === year
    );
    const search = searchInput ? searchInput.toLowerCase() : null;
    const filteredBySearch = search ? filteredByYear.filter((c) => c.name.toLowerCase().includes(search)) : filteredByYear;
    filteredBySearch.sort((a, b) => a.date.slice(5) > b.date.slice(5) ? 1 : -1);
    return filteredBySearch;
  }, [year, holidays, searchInput]);
  const columns = useMemo(() => {
    return [
      {
        header: () => polyglot.t("HolidayCalendarModule.holidayName"),
        accessorFn: (row) => row.name,
        id: "name",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.name }) })
      },
      {
        header: () => polyglot.t("General.date"),
        accessorFn: (row) => row,
        id: "date",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => `${year}-${item.date.slice(5)}`),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: new LocalDate(original.date).toLocaleDateString(void 0, {
          month: "short",
          day: "numeric"
        }) }) })
      },
      {
        header: () => polyglot.t("HolidayCalendarModule.occurs"),
        accessorFn: (row) => row,
        id: "isRecurring",
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.isRecurring),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.isRecurring ? polyglot.t("HolidayCalendarModule.everyYear") : polyglot.t("HolidayCalendarModule.once") }) })
      }
    ];
  }, [year, polyglot]);
  const handleRowClick = useCallback((row) => {
    setSelectedHoliday(row.original);
    setIsOpen(true);
  }, []);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "5px" }, children: [
      /* @__PURE__ */ jsx(
        NumericYearSelectChip,
        {
          year,
          setYear,
          options: [
            { label: "2022", year: 2022 },
            { label: "2023", year: 2023 },
            { label: "2024", year: 2024 },
            { label: "2025", year: 2025 },
            { label: "2026", year: 2026 }
          ]
        }
      ),
      /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
        var _a, _b;
        return setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
      } })
    ] }),
    holidays.length > 0 ? /* @__PURE__ */ jsxs(Box, { sx: { mt: "24px" }, children: [
      /* @__PURE__ */ jsx(BasicTable, { rowData: filteredAndSortedHolidays, columnData: columns, rowClick: handleRowClick }),
      /* @__PURE__ */ jsx(
        HolidayDrawer,
        {
          isOpen,
          setIsOpen,
          calendarId,
          holiday: selectedHoliday,
          refresh,
          onClose: () => setSelectedHoliday(null)
        }
      )
    ] }) : /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", sx: { mt: "8px" }, children: polyglot.t("HolidayCalendarModule.noHolidays") }) })
  ] });
};
