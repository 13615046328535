"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/material";
import { TabFilterButtons } from "@v2/components/tab-filter-buttons.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceEndpoints } from "@v2/feature/device/device.api";
import { DeviceOwnership } from "@v2/feature/device/device.interface";
import {
  getDeviceOwnerAsSuperadminByDevicePossession,
  getUserNamesKeyedByUserIds
} from "@v2/feature/device/device.util";
import { DEBOUNCE_300_MS } from "@v2/feature/documents/documents.util";
import { SuperAdminOverviewDeviceDrawer } from "@v2/feature/super-admin/features/super-admin-devices/components/super-admin-overview-device-drawer.component";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { secondaryCTABtn } from "@v2/styles/buttons.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { pipe } from "fp-ts/function";
import { keyBy } from "lodash";
import CsvDownloader from "react-csv-downloader";
import { Subject } from "rxjs";
import * as RX from "rxjs/operators";
import { SiteAPI } from "@/api-client/site.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { timeSince } from "@/lib/moment.lib";
import { iconSize } from "@/v2/styles/menu.styles";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
export const SuperAdminHexnodeDevicesOverviewPage = ({ users, companies, pageConfig }) => {
  var _a, _b, _c;
  const [devicePossessions, setDevicePossessions] = useState([]);
  const [matchings, setMatchings] = useState({});
  const [devicePossessionToBeEdited, setDevicePossessionToBeEdited] = useState(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [sites, setSitesById] = useState({});
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("all");
  const [changeSearchInput$] = useState(() => new Subject());
  const { data: superAdminDevicesResponse, mutate: refreshDevicePossessions } = useApiClient(
    DeviceEndpoints.getAllDevicesAsSuperAdmin(false),
    {
      suspense: false
    }
  );
  const fetchData = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    try {
      setLoading(true);
      const [sitesData] = yield Promise.all([SiteAPI.listSitesAsSuperAdmin()]);
      setSitesById(keyBy(sitesData, "id"));
      const devicePossessions2 = (_b2 = (_a2 = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.devicePossessions) == null ? void 0 : _a2.items) != null ? _b2 : [];
      const matchings2 = (_c2 = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.matchings) != null ? _c2 : {};
      const filteredDevicePossessions = devicePossessions2.filter((dp) => dp.deviceId in matchings2);
      setDevicePossessions(filteredDevicePossessions);
      setMatchings(matchings2);
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage, (_a = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.devicePossessions) == null ? void 0 : _a.items, superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.matchings]);
  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  useEffect(() => {
    pipe(
      changeSearchInput$,
      RX.debounceTime(DEBOUNCE_300_MS),
      ($) => $.subscribe((value) => setSearchInput(value)),
      (s) => () => s.unsubscribe()
    );
    fetchData();
  }, [changeSearchInput$, fetchData]);
  const getCompanyNameById = useCallback(
    (companyId) => {
      var _a2;
      if (!companyId) return void 0;
      return (_a2 = companies.find((company) => company.companyId === companyId)) == null ? void 0 : _a2.name;
    },
    [companies]
  );
  const filterDevicePossessions = useCallback(
    (devicePossessions2) => devicePossessions2.filter((devicePossession) => {
      var _a2, _b2, _c2, _d;
      const userName = getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites);
      const modelName = (_a2 = devicePossession.device) == null ? void 0 : _a2.modelName;
      const serialNumber = (_b2 = devicePossession.device) == null ? void 0 : _b2.serialNumber;
      const companyName = (_d = getCompanyNameById((_c2 = devicePossession.companyId) != null ? _c2 : 0)) != null ? _d : devicePossession.possessionType;
      return (userName == null ? void 0 : userName.toLowerCase().includes(searchInput.toLowerCase())) || (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase())) || (serialNumber == null ? void 0 : serialNumber.toLowerCase().includes(searchInput.toLowerCase())) || (companyName == null ? void 0 : companyName.toLowerCase().includes(searchInput.toLowerCase()));
    }),
    [userNames, sites, searchInput, getCompanyNameById]
  );
  const formatDevicePossessionForCsv = (devicePossessions2) => devicePossessions2.map((devicePossession) => {
    var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i, _j;
    return {
      possessionId: devicePossession.id,
      deviceId: (_a2 = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a2.id,
      company: (_b2 = getCompanyNameById(devicePossession.companyId)) != null ? _b2 : devicePossession.possessionType,
      ownedBy: getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites),
      serialNumber: ((_c2 = devicePossession.device) == null ? void 0 : _c2.serialNumber) || "",
      modelName: ((_d = devicePossession.device) == null ? void 0 : _d.modelName) || "",
      isEnrolled: ((_e = devicePossession.device) == null ? void 0 : _e.enrollmentStatus) === "enrolled",
      lastCheckIn: ((_f = devicePossession.device) == null ? void 0 : _f.lastCheckIn) ? timeSince(new Date((_g = devicePossession.device) == null ? void 0 : _g.lastCheckIn)) : "",
      price: `\xA3${(_h = devicePossession.device) == null ? void 0 : _h.price}`,
      contractLength: ((_i = devicePossession.device) == null ? void 0 : _i.contractLength) ? `${(_j = devicePossession.device) == null ? void 0 : _j.contractLength} months` : ""
    };
  });
  const tableData = useMemo(() => {
    const searchFilteredDevices = filterDevicePossessions(devicePossessions);
    if (filterString === "all") return searchFilteredDevices;
    return searchFilteredDevices.filter((devicePossession) => {
      var _a2;
      return ((_a2 = devicePossession.device) == null ? void 0 : _a2.ownership) === filterString;
    });
  }, [devicePossessions, filterString, filterDevicePossessions]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Possession Id",
        accessorFn: (row) => row,
        id: "id",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: possession.id });
        },
        size: 75
      },
      {
        header: () => "Device Id",
        accessorFn: (row) => row,
        id: "device.id",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return possession.device ? /* @__PURE__ */ jsx(Box, { children: possession.device.id }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 55
      },
      {
        header: () => "Company",
        accessorFn: (row) => row,
        id: "companyId",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const companyName = (_a2 = getCompanyNameById(possession.companyId)) != null ? _a2 : possession.possessionType;
          return companyName ? /* @__PURE__ */ jsx(Box, { children: companyName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 150
      },
      {
        header: () => "Owned By",
        accessorFn: (row) => row,
        id: "possessionId",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(possession, userNames, sites);
          return /* @__PURE__ */ jsx(Box, { children: ownedBy });
        },
        size: 150
      },
      {
        header: () => "Serial Number",
        accessorFn: (row) => row,
        id: "device.serialNumber",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const serialNumber = (_a2 = possession.device) == null ? void 0 : _a2.serialNumber;
          return serialNumber ? /* @__PURE__ */ jsx(Box, { children: serialNumber }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 125
      },
      {
        header: () => "Model Name",
        accessorFn: (row) => row,
        id: "device.modelName",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const modelName = (_a2 = possession.device) == null ? void 0 : _a2.modelName;
          return modelName ? /* @__PURE__ */ jsx(Box, { children: modelName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 125
      },
      {
        header: () => "Encryption",
        accessorFn: (row) => row,
        id: "device.encryptionEnabled",
        enableSorting: false,
        cell: (info) => {
          var _a2, _b2;
          const possession = info.getValue();
          const encryptionEnabled = (_a2 = possession.device) == null ? void 0 : _a2.encryptionEnabled;
          const isEnrolled = ((_b2 = possession.device) == null ? void 0 : _b2.enrollmentStatus) === "enrolled";
          return encryptionEnabled ? /* @__PURE__ */ jsx(CheckIcon, { color: "success" }) : isEnrolled ? /* @__PURE__ */ jsx(WarningAmberIcon, { color: "error" }) : /* @__PURE__ */ jsx(LinkOffIcon, { color: "warning" });
        },
        size: 75
      },
      {
        header: () => "Last Check-In",
        accessorFn: (row) => row,
        id: "device.lastCheckIn",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const lastCheckIn = (_a2 = possession.device) == null ? void 0 : _a2.lastCheckIn;
          return lastCheckIn ? /* @__PURE__ */ jsx(Box, { children: timeSince(new Date(lastCheckIn)) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      },
      {
        header: () => "Price",
        accessorFn: (row) => row,
        id: "device.price",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const price = (_a2 = possession.device) == null ? void 0 : _a2.price;
          return price ? /* @__PURE__ */ jsxs(Box, { children: [
            "\xA3",
            price
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Contract Length",
        accessorFn: (row) => row,
        id: "device.contractLength",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const contractLength = (_a2 = possession.device) == null ? void 0 : _a2.contractLength;
          return contractLength ? /* @__PURE__ */ jsxs(Box, { children: [
            contractLength,
            " months"
          ] }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "Ownership",
        accessorFn: (row) => row,
        id: "device.ownership",
        enableSorting: false,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const ownership = (_a2 = possession.device) == null ? void 0 : _a2.ownership;
          return ownership ? /* @__PURE__ */ jsx(Box, { children: ownership }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 75
      },
      {
        header: () => "External id",
        accessorFn: (row) => row,
        id: "device.externalId",
        enableSorting: true,
        cell: (info) => {
          var _a2;
          const possession = info.getValue();
          const externalId = ((_a2 = possession.device) == null ? void 0 : _a2.id) ? matchings[possession.device.id] : null;
          return externalId ? /* @__PURE__ */ jsx(Box, { children: externalId }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        size: 100
      }
    ];
  }, [getCompanyNameById, userNames, sites, matchings]);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b2, _c2, _d, _e;
    try {
      if (refreshDevicePossessions) {
        const response = yield refreshDevicePossessions();
        const devicePossessions2 = (_b2 = (_a2 = response == null ? void 0 : response.devicePossessions) == null ? void 0 : _a2.items) != null ? _b2 : [];
        const matchings2 = (_c2 = superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.matchings) != null ? _c2 : {};
        const filteredDevicePossessions = devicePossessions2.filter((dp) => dp.id in matchings2);
        setDevicePossessions(filteredDevicePossessions);
        setDevicePossessions(devicePossessions2);
        setMatchings((_d = response == null ? void 0 : response.matchings) != null ? _d : {});
        setDevicePossessionToBeEdited((_e = devicePossessions2.find((d) => d.id === (devicePossessionToBeEdited == null ? void 0 : devicePossessionToBeEdited.id))) != null ? _e : null);
      }
    } catch (error) {
      showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, "error");
    }
  }), [devicePossessionToBeEdited == null ? void 0 : devicePossessionToBeEdited.id, refreshDevicePossessions, showMessage, superAdminDevicesResponse == null ? void 0 : superAdminDevicesResponse.matchings]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: "Devices" }),
    ((_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_c = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _c.tabs }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, secondLevel: true, children: [
      devicePossessions.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({ display: "flex", justifyContent: "flex-start", width: "100%" }, spacing.mb20), { gap: 2 }), children: /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            filters: [
              { name: "All", value: "all" },
              { name: "Rental", value: DeviceOwnership.Rental },
              { name: "Company", value: DeviceOwnership.Company }
            ],
            filterValue: filterString,
            setFilterValue: setFilterString,
            hasSearch: true,
            onFilterChange: ({ filterValue, searchInput: searchInput2 }) => {
              setFilterString(filterValue);
              setSearchInput(searchInput2);
            }
          }
        ) }),
        /* @__PURE__ */ jsx(
          CsvDownloader,
          {
            filename: "hexnode_devices",
            separator: ",",
            datas: () => formatDevicePossessionForCsv(filterDevicePossessions(tableData)),
            children: /* @__PURE__ */ jsxs(
              Box,
              {
                sx: __spreadProps(__spreadValues({
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.s1
                }, secondaryCTABtn), {
                  cursor: "pointer",
                  height: "20px"
                }),
                children: [
                  /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Export" })
                ]
              }
            )
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: tableData,
          columnData,
          rowClick: (row) => {
            setDevicePossessionToBeEdited(row.original);
            setIsEditDrawerOpen(true);
          }
        }
      ),
      devicePossessionToBeEdited && /* @__PURE__ */ jsx(
        SuperAdminOverviewDeviceDrawer,
        {
          isOpen: isEditDrawerOpen,
          setIsOpen: setIsEditDrawerOpen,
          devicePossession: devicePossessionToBeEdited,
          setDevicePossession: setDevicePossessionToBeEdited,
          userNames,
          matchings,
          sites,
          refresh
        }
      )
    ] })
  ] });
};
