"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { AppleDevices, DevicePossessionType } from "@v2/feature/device/device.interface";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const NotEnrollledRisk = ({
  enrollPath,
  devicePossession
}) => {
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const handleEnol = () => __async(void 0, null, function* () {
    var _a, _b;
    if ((devicePossession == null ? void 0 : devicePossession.possessionType) === DevicePossessionType.User) {
      if (devicePossession && devicePossession.device && devicePossession.device.os && !AppleDevices.includes((_b = (_a = devicePossession.device) == null ? void 0 : _a.os) == null ? void 0 : _b.toLowerCase())) {
        routerHistory.push(
          generatePath(enrollPath, {
            userId: devicePossession == null ? void 0 : devicePossession.possessionId,
            devicePossessionId: devicePossession == null ? void 0 : devicePossession.id
          })
        );
      }
    } else {
      showMessage("Can not enrol device that is not owned by an user.", "error");
    }
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Mistake, { width: 20, height: 20, fill: themeColors.GreyMiddle }),
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: "Device not enroled" })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Download Zelt MDM and secure your sensitive data, recover lost devices and configure settings. See your device status live on Zelt and manage it remotely when needed." }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex", alignItems: "center" }, spacing.mt20), { gap: spacing.g5, maxWidth: "35%" }), children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => __async(void 0, null, function* () {
        return yield handleEnol();
      }), fullWidth: true, children: "Download" }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          fullWidth: true,
          onClick: () => window.open("https://zelt.app/blog/protect-your-device-with-zelt-mdm", "_blank"),
          children: "Learn more"
        }
      )
    ] })
  ] });
};
