"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { useQuestionFocus } from "../../hooks/use-question-focus.hook";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { isHTMLValid } from "@/v2/feature/growth/shared/utils/growth-common.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const OpenEndedQuestion = ({
  question,
  reviewType,
  answerArray,
  handleAnswerChange,
  entry,
  showError,
  isFocused,
  registerMethods
}) => {
  const currentAnswer = useMemo(() => {
    var _a;
    return ((_a = answerArray == null ? void 0 : answerArray.find((ans) => ans.questionId === question.id)) == null ? void 0 : _a.comment) || "";
  }, [
    answerArray,
    question.id
  ]);
  const handleInputChange = useCallback(
    (value) => {
      if (!entry) return;
      handleAnswerChange(question.id, { updatedAnswer: null, comment: value }, entry);
    },
    [question.id, entry, handleAnswerChange]
  );
  const {
    handleContainerKeyDown,
    containerRef,
    registerRichTextFieldRef
    // Using the correct ref from the hook
  } = useQuestionFocus({
    question,
    optionKeys: [],
    // Open-ended questions don't have options
    isFocused,
    registerMethods,
    handleOptionAction: () => {
    }
    // No options to handle
  });
  if (!question || !reviewType || !entry) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    Box,
    {
      ref: containerRef,
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g24,
        outline: "none"
      },
      onKeyDown: handleContainerKeyDown,
      tabIndex: 0,
      "data-testid": "open-ended-container",
      children: [
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          RichTextField,
          {
            value: currentAnswer,
            onChange: (value) => {
              handleInputChange(value);
            },
            error: showError && !isHTMLValid(currentAnswer),
            helperText: showError && !isHTMLValid(currentAnswer) ? "This field is required" : "",
            inputRef: registerRichTextFieldRef
          },
          question.id
        ) }),
        showError && !isHTMLValid(currentAnswer) && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.darkRed }, children: "This field is required" })
      ]
    }
  );
};
