"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { ReactComponent as ArrowDown } from "@/images/fields/Dropdown.svg";
import { ReactComponent as ArrowUp } from "@/images/fields/UpArrow.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const ScrollToBottomButton = ({ showDown, showUp, onScrollBottom, onScrollTop }) => {
  if (!showDown && !showUp) return null;
  return /* @__PURE__ */ jsx(
    IconButton,
    {
      colorVariant: "primary",
      sizeVariant: "small",
      onClick: showDown ? onScrollBottom : onScrollTop,
      style: { position: "fixed", bottom: "20px", right: "20px", textAlign: "center" },
      children: showDown ? /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(ArrowUp, __spreadValues({}, iconSize))
    }
  );
};
