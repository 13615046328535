"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/system";
import { Typography } from "@/v2/components/typography/typography.component";
import { SummaryDisplayBar } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/summary-display-bar.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const renderSummarySection = (item, sectionLookup, sectionGroupedQuestionLookup, growthFactors, hidePerQuestionScore) => {
  var _a;
  const section = sectionLookup[item.id];
  if (!section) return /* @__PURE__ */ jsx(Fragment, {});
  const sectionQuestions = sectionGroupedQuestionLookup[item.id] || [];
  if (!sectionQuestions.length) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: section.name }),
      section.description && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", dangerouslySetInnerHTML: { __html: (_a = section.description) != null ? _a : "" } })
    ] }),
    sectionQuestions.map((question) => /* @__PURE__ */ jsx(
      SummaryDisplayBar,
      {
        question,
        hidePerQuestionScore,
        showBorder: true,
        growthFactors,
        sx: {
          background: themeColors.TableHover
        }
      },
      question.questionId
    ))
  ] }, item.id);
};
export const ResultOverviewSummary = ({
  order,
  questionLookup,
  sectionLookup,
  sectionGroupedQuestionLookup,
  growthFactors,
  hidePerQuestionScore
}) => {
  return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column" }, children: (order || []).filter(Boolean).map((item, idx) => {
    const showBorder = Boolean(item.type === "question" && order[idx - 1] && order[idx - 1].type === "section");
    return item.type === "question" ? /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, showBorder ? { borderTop: borders.middle, paddingTop: spacing.s5 } : {}), {
          marginBottom: spacing.s2
        }),
        children: /* @__PURE__ */ jsx(
          SummaryDisplayBar,
          {
            question: questionLookup[item.id],
            growthFactors,
            showBorder: true,
            hidePerQuestionScore,
            sx: {
              background: themeColors.TableHover
            }
          }
        )
      },
      idx
    ) : item.type === "section" ? /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadValues({}, idx === 0 ? { marginTop: spacing.s4, marginBottom: spacing.s5 } : { marginY: spacing.s5 }),
        children: renderSummarySection(
          item,
          sectionLookup,
          sectionGroupedQuestionLookup,
          growthFactors,
          hidePerQuestionScore
        )
      },
      idx
    ) : null;
  }) });
};
