"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SurveyResultsFilters } from "@v2/feature/growth/surveys/features/components/survey-results-filters.component";
import { SurveyResultsByFactor } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-results-by-factor.component";
import { SurveyResultsByQuestion } from "@v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/components/survey-results-by-question.component";
import { jsPDF } from "jspdf";
import { groupBy, keyBy } from "lodash";
import * as XLSX from "sheetjs-style";
import { GlobalContext } from "@/GlobalState";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { iconSize } from "@/v2/components/forms/editable-title.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { TitleStatusComponent } from "@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { borders } from "@/v2/styles/borders.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
import { replaceParamsInQuestionText, stripHtml } from "@/v2/util/string.util";
export const MeasureFilterToLabel = {
  nps: "NPS",
  positive: "Positive sentiment",
  avg: "Average"
};
export const SurveyCycleDetailResultsPage = ({
  surveyCycle,
  cycleLoading,
  growthFactors,
  surveyResult,
  loadingResult,
  filtersAndTypesOptions,
  setFilterString,
  filterString
}) => {
  var _a, _b;
  const [state, dispatch] = useContext(GlobalContext);
  const { getCachedUserById } = useCachedUsers();
  const reportName = useMemo(() => `${surveyCycle.internalName}-result`, [surveyCycle.internalName]);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : "Company";
  const generateExcel = () => {
    if (!surveyResult || !surveyCycle) return;
    const { resultByQuestion, resultByFactor, impactResult } = surveyResult;
    const uniqueFactors = /* @__PURE__ */ new Set();
    (resultByQuestion || []).forEach((item) => {
      const impacts = impactResult ? impactResult[item.question.id] || {} : {};
      Object.keys(impacts).forEach((factor) => {
        uniqueFactors.add(factor);
      });
    });
    const factorsArray = Array.from(uniqueFactors);
    const header = [
      "Type",
      "Text",
      "Question Factor",
      "Average Score",
      "NPS Score",
      "Positive Sentiment",
      "Neutral Sentiment",
      "Negative Sentiment",
      ...factorsArray.map((factor) => `Impact (${factor})`)
    ];
    const data = [];
    data.push(header);
    (resultByQuestion || []).forEach((item) => {
      const row = [];
      row.push("Question");
      row.push(replaceParamsInQuestionText(stripHtml(item.question.questionText), { company_name }));
      row.push(item.question.factor);
      row.push(item.normPercent ? (item.normPercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.npsPercent ? (item.npsPercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.positivePercent ? (item.positivePercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.neutralPercent ? (item.neutralPercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.negativePercent ? (item.negativePercent / 100).toFixed(2) : 0 .toFixed(2));
      const impacts = impactResult ? impactResult[item.question.id] || null : null;
      factorsArray.forEach((factor) => {
        const impact = impacts ? impacts[factor] : null;
        const coefficient = impact && typeof impact.coefficient === "number" ? impact.coefficient.toFixed(2) : "\u2013";
        row.push(coefficient);
      });
      data.push(row);
    });
    (resultByFactor || []).forEach((item) => {
      const row = [];
      row.push("Factor");
      row.push(item.factor);
      row.push("\u2013");
      row.push(item.normPercent ? (item.normPercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.npsPercent ? (item.npsPercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.positivePercent ? (item.positivePercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.neutralPercent ? (item.neutralPercent / 100).toFixed(2) : 0 .toFixed(2));
      row.push(item.negativePercent ? (item.negativePercent / 100).toFixed(2) : 0 .toFixed(2));
      factorsArray.forEach(() => {
        row.push("\u2013");
      });
      data.push(row);
    });
    const allAnswersData = [
      [
        "Answer ID",
        "Entry ID",
        "Employee Name",
        "Question text",
        "Question type",
        "Factor",
        "Answer value",
        "Answer label",
        "Comment",
        "Timestamp",
        "Employee metadata - Department",
        "Employee metadata - Site",
        "Employee metadata - Gender",
        "Employee metadata - Tenure"
      ]
    ];
    resultByQuestion == null ? void 0 : resultByQuestion.forEach((questionObject) => {
      questionObject.answers.forEach((answer) => {
        var _a2, _b2, _c, _d, _e;
        const commentText = answer.comment ? stripHtml(answer.comment) : "\u2013";
        const answerLabel = questionObject.question.type === QuestionType.OpenEnded ? commentText : getAnswerLabel(questionObject.question, answer);
        const answerValue = questionObject.question.type === QuestionType.OpenEnded ? commentText : answer.answer || "\u2013";
        const employee = answer.participantId ? ((_a2 = getCachedUserById(answer.participantId)) == null ? void 0 : _a2.displayName) || "\u2013" : "Anonymous";
        const department = ((_b2 = answer == null ? void 0 : answer.userMetaData) == null ? void 0 : _b2.departmentName) || "\u2013";
        const site = ((_c = answer == null ? void 0 : answer.userMetaData) == null ? void 0 : _c.siteName) || "\u2013";
        const gender = ((_d = answer == null ? void 0 : answer.userMetaData) == null ? void 0 : _d.gender) || "\u2013";
        const tenure = ((_e = answer == null ? void 0 : answer.userMetaData) == null ? void 0 : _e.startDate) || "\u2013";
        const row = [
          answer.id,
          answer.entryId,
          employee,
          replaceParamsInQuestionText(stripHtml(questionObject.question.questionText), { company_name }),
          questionObject.question.type,
          questionObject.question.factor || "\u2013",
          answerValue,
          answerLabel,
          commentText,
          new LocalDate(answer.updatedAt).toDateTimeString(),
          department,
          site,
          gender,
          tenure
        ];
        allAnswersData.push(row);
      });
    });
    const workbook = XLSX.utils.book_new();
    const resultsSheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, resultsSheet, "Results");
    const answersSheet = XLSX.utils.aoa_to_sheet(allAnswersData);
    XLSX.utils.book_append_sheet(workbook, answersSheet, "Detailed");
    XLSX.writeFile(workbook, `${reportName}.xlsx`);
  };
  const generatePdfReport = (surveyResult2, surveyCycle2, reportName2) => {
    var _a2;
    if (!surveyResult2) return;
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFont("Times-Roman", "bold");
    const startDate = ((_a2 = surveyCycle2.timelineSettings) == null ? void 0 : _a2.startDate) ? new LocalDate(surveyCycle2.timelineSettings.startDate).toLocaleDateString() : new LocalDate().toLocaleDateString();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 14;
    const maxWidth = pageWidth - margin * 2;
    const lineHeight = 8;
    const textLineHeight = 6;
    let yPosition = 22;
    const checkAndAddPage = () => {
      if (yPosition + lineHeight > pageHeight - margin) {
        doc.addPage();
        yPosition = margin + lineHeight;
      }
    };
    doc.setFontSize(18);
    doc.text(`${surveyCycle2.displayName}`, margin, yPosition);
    yPosition += textLineHeight;
    doc.setFont("Times-Roman", "normal");
    doc.setFontSize(12);
    doc.text(`Start Date: ${startDate}`, margin, yPosition);
    yPosition += textLineHeight * 2;
    if ((surveyResult2 == null ? void 0 : surveyResult2.resultByQuestion) && (surveyResult2 == null ? void 0 : surveyResult2.resultByQuestion.length) === 0 && (surveyResult2 == null ? void 0 : surveyResult2.resultByFactor.length) === 0) {
      yPosition += textLineHeight;
      doc.setFont("Times-Roman", "normal");
      doc.text(`Not sufficient data to show results.`, margin, yPosition);
    }
    if ((surveyResult2 == null ? void 0 : surveyResult2.resultByQuestion) && surveyResult2.resultByQuestion.length > 0) {
      doc.setFont("Times-Roman", "bold");
      doc.setFontSize(14);
      doc.text("Results by Question", margin, yPosition);
      yPosition += 4;
      doc.setLineWidth(1);
      doc.setDrawColor(0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
      yPosition += lineHeight / 2;
      surveyResult2.resultByQuestion.forEach((questionObject, idx) => {
        yPosition += lineHeight;
        const { questionText, type } = questionObject.question;
        const questionStrippedHtml = replaceParamsInQuestionText(stripHtml(questionText), { company_name });
        doc.setFont("Times-Roman", "bold");
        doc.setFontSize(12);
        const splitQuestionText = doc.splitTextToSize(`${questionStrippedHtml}`, maxWidth);
        splitQuestionText.forEach((line) => {
          checkAndAddPage();
          doc.text(line, margin, yPosition);
          yPosition += textLineHeight;
        });
        if (questionObject.answersLowerThanThreshold) {
          yPosition += 2;
          doc.setFont("Times-Roman", "normal");
          doc.text(`Not sufficient data to show results.`, margin, yPosition);
          yPosition += textLineHeight;
          yPosition += 2;
          yPosition += lineHeight / 2;
          checkAndAddPage();
          return;
        }
        yPosition += textLineHeight / 2;
        questionObject.answers.forEach((answer) => {
          var _a3;
          const { comment } = answer;
          const username = !answer.participantId ? "Author hidden" : ((_a3 = getCachedUserById(answer.participantId)) == null ? void 0 : _a3.displayName) || "NA";
          const commentText = comment ? stripHtml(comment) : "";
          const answerLabel = type === QuestionType.OpenEnded ? commentText : getAnswerLabel(questionObject.question, answer);
          doc.setFont("Times-Roman", "normal");
          doc.setFontSize(12);
          const answerText = `[${username}] ${answerLabel}`;
          const splitAnswerText = doc.splitTextToSize(answerText, maxWidth);
          splitAnswerText.forEach((line) => {
            checkAndAddPage();
            doc.text(line, margin, yPosition);
            yPosition += textLineHeight;
          });
          if (commentText && type !== QuestionType.OpenEnded) {
            doc.setFont("Times-Italic", "italic");
            const splitCommentText = doc.splitTextToSize(`[Comment]: ${commentText}`, maxWidth);
            splitCommentText.forEach((line) => {
              checkAndAddPage();
              doc.text(line, margin, yPosition);
              yPosition += textLineHeight;
            });
            doc.setFont("Times-Roman", "normal");
          }
          yPosition += 2;
        });
        yPosition += lineHeight / 2;
        checkAndAddPage();
        if (idx !== (surveyResult2 == null ? void 0 : surveyResult2.resultByQuestion.length) - 1) {
          doc.setLineWidth(0.5);
          doc.setDrawColor(0);
          doc.line(margin, yPosition, pageWidth - margin, yPosition);
          yPosition += lineHeight / 2;
        }
      });
    }
    if (surveyResult2.resultByFactor.length > 0) {
      doc.addPage();
      yPosition = margin;
      yPosition += textLineHeight;
      doc.setFont("Times-Roman", "bold");
      doc.setFontSize(14);
      doc.text("Results by Factor", margin, yPosition);
      yPosition += 4;
      doc.setLineWidth(1);
      doc.setDrawColor(0);
      doc.line(margin, yPosition, pageWidth - margin, yPosition);
      yPosition += lineHeight / 2;
      surveyResult2.resultByFactor.forEach((factor, idx) => {
        yPosition += lineHeight / 2;
        doc.setFont("Times-Roman", "bold");
        doc.setFontSize(12);
        doc.text(`Factor: ${factor.factor}`, margin, yPosition);
        yPosition += textLineHeight;
        doc.setFont("Times-Roman", "normal");
        doc.text(`Average Score: ${factor.normPercent}%`, margin, yPosition);
        yPosition += textLineHeight;
        doc.text(`NPS Score: ${factor.npsPercent}%`, margin, yPosition);
        yPosition += textLineHeight;
        doc.text(`Positive Score: ${factor.positivePercent}%`, margin, yPosition);
        yPosition += textLineHeight;
        doc.text(`Number of Questions: ${factor.noOfQuestions}`, margin, yPosition);
        yPosition += textLineHeight;
        checkAndAddPage();
        yPosition += lineHeight / 2;
        if (idx !== surveyResult2.resultByFactor.length - 1) {
          doc.setLineWidth(0.5);
          doc.setDrawColor(0);
          doc.line(margin, yPosition, pageWidth - margin, yPosition);
          yPosition += lineHeight / 2;
        }
      });
    }
    doc.save(`${reportName2}.pdf`);
  };
  const getAnswerLabel = (question, answer) => {
    var _a2, _b2, _c, _d, _e, _f;
    if (!(answer == null ? void 0 : answer.answer)) return "";
    if (question.type === QuestionType.ScaleQuestion)
      return `${(_b2 = (_a2 = question.scaleConfig) == null ? void 0 : _a2.points) == null ? void 0 : _b2[answer.answer]} (${((_d = (_c = question.scaleConfig) == null ? void 0 : _c.value) == null ? void 0 : _d[answer.answer]) || 0} out of ${Math.max(...Object.values(((_e = question.scaleConfig) == null ? void 0 : _e.value) || []).map(Number))})`;
    if (question.type === QuestionType.MultipleAnswer)
      return answer.answer.split(",").map((choice) => question.choiceOptions[Number(choice)]).join(", ");
    if (question.type === QuestionType.SingleAnswer) return ((_f = question.choiceOptions) == null ? void 0 : _f[Number(answer.answer)]) || "NA";
    return "";
  };
  return /* @__PURE__ */ jsx(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { surveyCycle }),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      loading: cycleLoading || loadingResult,
      buttons: [
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              {
                handler: generateExcel,
                label: "Excel report"
              },
              {
                handler: () => generatePdfReport(surveyResult, surveyCycle, reportName),
                label: "PDF report"
              }
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Export",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        )
      ],
      children: /* @__PURE__ */ jsx(
        SettingsSubsectionContent,
        {
          sections: [
            {
              contentWidth: "100%",
              headerWidth: "100%",
              title: "Results",
              items: [
                {
                  type: SectionItemType.Component,
                  value: /* @__PURE__ */ jsx(
                    SurveyResultList,
                    {
                      surveyResult,
                      growthFactors,
                      state,
                      dispatch,
                      filtersAndTypesOptions: filtersAndTypesOptions || [],
                      setFilterString,
                      filterString,
                      surveyCycle
                    }
                  )
                }
              ]
            }
          ]
        }
      )
    }
  );
};
const SurveyResultList = ({
  surveyResult,
  growthFactors,
  state,
  dispatch,
  filtersAndTypesOptions,
  setFilterString,
  filterString,
  surveyCycle
}) => {
  var _a, _b, _c, _d, _e, _f;
  const [showByFilter, setShowByFilter] = useState(
    ((_c = (_b = (_a = state.user.features) == null ? void 0 : _a.surveys) == null ? void 0 : _b.results) == null ? void 0 : _c.showBy) || "Question"
  );
  const [measureByFilter, setMeasureByFilter] = useState(
    ((_f = (_e = (_d = state.user.features) == null ? void 0 : _d.surveys) == null ? void 0 : _e.results) == null ? void 0 : _f.measuredBy) || "nps"
  );
  const existingFactors = useMemo(() => {
    const factorsArray = surveyResult == null ? void 0 : surveyResult.resultByQuestion.map((r) => r.question.factor).filter(Boolean);
    return [...new Set(factorsArray)];
  }, [surveyResult]);
  const [impactFilter, setImpactFilter] = useState(existingFactors[0] || "");
  useEffect(() => {
    var _a2;
    setImpactFilter((_a2 = existingFactors[0]) != null ? _a2 : "");
  }, [existingFactors]);
  const questionResultsById = useMemo(() => {
    var _a2;
    return keyBy((_a2 = surveyResult == null ? void 0 : surveyResult.resultByQuestion) != null ? _a2 : [], "question.id");
  }, [surveyResult.resultByQuestion]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(
      SurveyResultsFilters,
      {
        existingFactors,
        showByFilter,
        setShowByFilter,
        measureByFilter,
        setMeasureByFilter,
        impactFilter,
        setImpactFilter,
        dispatch,
        filtersAndTypesOptions: filtersAndTypesOptions || [],
        setFilterString,
        filterString,
        surveyCycle
      }
    ),
    /* @__PURE__ */ jsx("div", { style: { paddingTop: spacing.s3 }, children: showByFilter === "Factor" && (surveyResult == null ? void 0 : surveyResult.resultByFactor) && (surveyResult == null ? void 0 : surveyResult.resultByFactor.length) > 0 ? /* @__PURE__ */ jsx(
      SurveyResultsByFactor,
      {
        resultByFactor: surveyResult == null ? void 0 : surveyResult.resultByFactor,
        growthFactors,
        measureByFilter
      }
    ) : showByFilter === "Question" && (surveyResult == null ? void 0 : surveyResult.resultByQuestion) && (surveyResult == null ? void 0 : surveyResult.resultByQuestion.length) > 0 ? (surveyResult.order || []).filter(Boolean).map((item, idx) => {
      const showBorder = Boolean(
        item.type === "question" && surveyResult.order[idx - 1] && surveyResult.order[idx - 1].type === "section"
      );
      return item.type === "question" ? /* @__PURE__ */ jsx(
        Box,
        {
          sx: __spreadProps(__spreadValues({}, showBorder ? { borderTop: borders.middle, paddingTop: spacing.s5 } : {}), {
            marginBottom: spacing.s2
          }),
          children: /* @__PURE__ */ jsx(
            SurveyResultsByQuestion,
            {
              question: questionResultsById[item.id],
              impactResult: surveyResult == null ? void 0 : surveyResult.impactResult,
              growthFactors,
              impactFilter,
              measureByFilter
            },
            idx
          )
        },
        idx
      ) : item.type === "section" ? /* @__PURE__ */ jsx(Box, { sx: { marginBottom: spacing.s5 }, children: renderSummarySection(
        item,
        surveyResult == null ? void 0 : surveyResult.sections,
        surveyResult == null ? void 0 : surveyResult.resultByQuestion,
        surveyResult == null ? void 0 : surveyResult.impactResult,
        growthFactors,
        measureByFilter,
        impactFilter
      ) }, idx) : null;
    }) : /* @__PURE__ */ jsx(EmptyState, {}) })
  ] });
};
const renderSummarySection = (item, sections, resultByQuestion, impactResult, growthFactors, measureByFilter, impactFilter, emptyStateMessage = "No questions available.") => {
  var _a;
  const sectionLookup = sections.reduce((acc, section2) => {
    acc[section2.id] = section2;
    return acc;
  }, {});
  const section = sectionLookup[item.id];
  if (!section) return /* @__PURE__ */ jsx(Fragment, {});
  const sectionGroupedQuestionLookup = groupBy(resultByQuestion != null ? resultByQuestion : [], "question.sectionId");
  const sectionQuestions = sectionGroupedQuestionLookup[item.id] || [];
  if (!sectionQuestions.length) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: section.name }),
      section.description && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", dangerouslySetInnerHTML: { __html: (_a = section.description) != null ? _a : "" } })
    ] }),
    sectionQuestions.map((question, idx) => /* @__PURE__ */ jsx(
      SurveyResultsByQuestion,
      {
        question,
        impactResult,
        growthFactors,
        impactFilter,
        measureByFilter,
        emptyStateMessage
      },
      idx
    ))
  ] }, item.id);
};
const EmptyState = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("SurveyCycleDetailResultsPage.notSufficientData") }) });
};
