"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { TitledHeaderStack } from "@/v2/components/titled-header.component";
import { ActiveShiftsHeaderSkeleton } from "@/v2/feature/attendance/components/active-shifts-header-skeleton.component";
import { AttendanceActiveShiftsTable } from "@/v2/feature/attendance/components/attendance-active-shifts-table.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const ActiveShiftsPageContent = ({
  extendedActiveShifts,
  loading,
  pagination,
  setPagination
}) => {
  const { polyglot } = usePolyglot();
  const [totalItems, setTotalItems] = useState(void 0);
  const [clockedInCount, setClockedInCount] = useState(void 0);
  useEffect(() => {
    var _a, _b;
    if (loading || !extendedActiveShifts) return;
    if (((_a = extendedActiveShifts.paginatedActiveShifts) == null ? void 0 : _a.totalItems) !== void 0 && ((_b = extendedActiveShifts.paginatedActiveShifts) == null ? void 0 : _b.totalItems) !== totalItems) {
      setTotalItems(extendedActiveShifts.paginatedActiveShifts.totalItems);
    }
    if (extendedActiveShifts.clockedInCount !== void 0 && extendedActiveShifts.clockedInCount !== clockedInCount) {
      setClockedInCount(extendedActiveShifts.clockedInCount);
    }
  }, [extendedActiveShifts, loading, totalItems, clockedInCount]);
  const hasItems = useMemo(() => {
    return totalItems !== void 0 && totalItems > 0;
  }, [totalItems]);
  if (!hasItems && !loading) {
    return /* @__PURE__ */ jsx(
      EmptyStateBox,
      {
        title: polyglot.t("AttendanceDomain.ActiveShifts.emptyState.title"),
        subtitle: polyglot.t("AttendanceDomain.ActiveShifts.emptyState.subtitle")
      }
    );
  }
  if (loading && !hasItems) {
    return /* @__PURE__ */ jsxs(Stack, { gap: spacing.g40, children: [
      /* @__PURE__ */ jsx(ActiveShiftsHeaderSkeleton, {}),
      /* @__PURE__ */ jsx(
        AttendanceActiveShiftsTable,
        {
          loading: true,
          paginatedActiveShifts: null,
          pagination,
          setPagination
        }
      )
    ] });
  }
  return /* @__PURE__ */ jsxs(Stack, { gap: spacing.g40, children: [
    /* @__PURE__ */ jsx(
      TitledHeaderStack,
      {
        sx: { gap: spacing.g40 },
        items: [
          {
            label: polyglot.t("AttendanceDomain.ActiveShifts.expected"),
            value: polyglot.t("AttendanceDomain.ActiveShifts.employeeCount", {
              smart_count: totalItems
            })
          },
          {
            label: polyglot.t("AttendanceDomain.ActiveShifts.clockedIn"),
            value: polyglot.t("AttendanceDomain.ActiveShifts.employeeCount", {
              smart_count: clockedInCount
            })
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      AttendanceActiveShiftsTable,
      {
        loading,
        paginatedActiveShifts: extendedActiveShifts == null ? void 0 : extendedActiveShifts.paginatedActiveShifts,
        pagination,
        setPagination
      }
    )
  ] });
};
