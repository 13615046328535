"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { Typography } from "@v2/components/typography/typography.component";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { spacing } from "@v2/styles/spacing.styles";
export const SettingsMembersList = ({ members }) => {
  const { polyglot } = usePolyglot();
  const sortedMembers = useMemo(() => {
    var _a;
    return (_a = members == null ? void 0 : members.sort((a, b) => a.name > b.name ? 1 : -1)) != null ? _a : [];
  }, [members]);
  const [searchInput, setSearchInput] = useState("");
  const filteredUsers = useMemo(() => {
    if (!searchInput) return sortedMembers;
    const search = searchInput.toLowerCase();
    return [...sortedMembers.filter((u) => u.name.toLowerCase().includes(search))];
  }, [searchInput, sortedMembers]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("General.name") }),
        accessorFn: (row) => row,
        id: "displayName",
        enableSorting: true,
        cell: ({
          row: {
            original: { userId }
          }
        }) => /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(UserAvatar, { userId, size: "s20", showName: true }) }, userId)
      }
    ],
    [polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "10px" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { mt: "10px" }, children: polyglot.t("AbsencePolicyMembersSection.list") }),
    /* @__PURE__ */ jsx(
      TableSearch,
      {
        query: searchInput,
        handleChange: (e) => setSearchInput(e.target.value),
        style: { width: "572px" }
      }
    ),
    filteredUsers.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { width: "600px" }, children: /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: filteredUsers,
        columnData: tableColumns,
        initialSort: [{ id: "displayName", desc: true }],
        fixedLastColumn: false
      }
    ) })
  ] });
};
