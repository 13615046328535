"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { MissingEmergency } from "@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-emergency.component";
import { MissingRole } from "@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-role.component";
import { MissingWorkContact } from "@v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-work-contact.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { uniqBy } from "lodash";
import { ReactComponent as Success } from "@/images/side-bar-icons/ok-green.svg";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { MissingAboutInformation } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-about-information.component";
import { MissingAddress } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-address.component";
import { MissingBankAccount } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-bank-account.component";
import { MissingBasicInfo } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-basic-info.component";
import { MissingCompensation } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-compensation.component";
import { MissingContract } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-contract.component";
import { MissingCustomSection } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-custom-section.component";
import { MissingFamilyInformation } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-family-information.component";
import { MissingPersonal } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-personal.component";
import { MissingTaxInformation } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/missing-tax-information.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const DEFAULT_CARDS = Object.values(CustomProfileFormType);
export const MissingFieldInformationViewDrawer = ({
  widgetData,
  user,
  refreshWidgetData
}) => {
  const { polyglot } = usePolyglot();
  const sections = useMemo(() => {
    var _a, _b, _c, _d;
    const missingFieldsArray = [
      ...(_b = (_a = widgetData.missingInformation) == null ? void 0 : _a.payroll) != null ? _b : [],
      ...(_d = (_c = widgetData.missingInformation) == null ? void 0 : _c.personal) != null ? _d : []
    ];
    const missingFields = uniqBy(missingFieldsArray, "cardId");
    const defaultMissingFields = missingFields.filter(
      (mf) => DEFAULT_CARDS.includes(mf.cardId)
    );
    const customMissingFields = missingFields.filter(
      (mf) => !DEFAULT_CARDS.includes(mf.cardId)
    );
    const result = [];
    defaultMissingFields.forEach((field) => {
      switch (field.cardId) {
        case CustomProfileFormType.Basic:
          result.push(
            /* @__PURE__ */ jsx(
              MissingBasicInfo,
              {
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.Details:
          result.push(
            /* @__PURE__ */ jsx(
              MissingPersonal,
              {
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.Family:
          result.push(
            /* @__PURE__ */ jsx(
              MissingFamilyInformation,
              {
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.About:
          result.push(
            /* @__PURE__ */ jsx(
              MissingAboutInformation,
              {
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.Other:
          break;
        case CustomProfileFormType.Role:
          result.push(
            /* @__PURE__ */ jsx(
              MissingRole,
              {
                userId: user.userId,
                refreshData: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.Contract:
          result.push(
            /* @__PURE__ */ jsx(
              MissingContract,
              {
                onlyAdd: true,
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.Lifecycle:
          break;
        case CustomProfileFormType.IdentityCheck:
          break;
        case CustomProfileFormType.Salary:
          result.push(
            /* @__PURE__ */ jsx(
              MissingCompensation,
              {
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.BankAccount:
          result.push(
            /* @__PURE__ */ jsx(
              MissingBankAccount,
              {
                userId: user.userId,
                defaultName: `${user.firstName} ${user.lastName}`,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.TaxInformation:
          result.push(
            /* @__PURE__ */ jsx(
              MissingTaxInformation,
              {
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.Equity:
          break;
        case CustomProfileFormType.Address:
          result.push(
            /* @__PURE__ */ jsx(
              MissingAddress,
              {
                userId: user.userId,
                refreshPayroll: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.Emergency:
          result.push(
            /* @__PURE__ */ jsx(
              MissingEmergency,
              {
                userId: user.userId,
                refreshData: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
        case CustomProfileFormType.WorkContact:
          result.push(
            /* @__PURE__ */ jsx(
              MissingWorkContact,
              {
                userId: user.userId,
                refreshData: () => __async(void 0, null, function* () {
                  yield refreshWidgetData == null ? void 0 : refreshWidgetData();
                })
              }
            )
          );
          break;
      }
    });
    if (customMissingFields.length > 0) {
      result.push(
        /* @__PURE__ */ jsx(
          MissingCustomSection,
          {
            userId: user.userId,
            missingFields: customMissingFields,
            refreshPayroll: () => __async(void 0, null, function* () {
              yield refreshWidgetData == null ? void 0 : refreshWidgetData();
            })
          }
        )
      );
    }
    return result;
  }, [user, refreshWidgetData, widgetData]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("MissingFieldInformationViewDrawer.missingInfo") }),
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("MissingFieldInformationViewDrawer.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId: user.userId, nameVariant: "headline3" })
    ] }),
    /* @__PURE__ */ jsx(Stack, { gap: "10px", children: sections.length > 0 ? sections.map((section, idx) => /* @__PURE__ */ jsx(Stack, { children: section }, idx)) : /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Success, { height: "1em", style: { fill: themeColors.Green, flexShrink: 0 } }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("MissingFieldInformationViewDrawer.allInfoPresent") })
    ] }) })
  ] });
};
