"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_TIME_ROUTE } from "@/lib/routes";
export const AbsencePolicyUnarchiveConfirmationDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const history = useHistory();
  const unarchivePolicy = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield AbsenceAPI.unarchiveAbsencePolicyById(absencePolicy.id);
      yield refresh();
      history.push(SETTINGS_TIME_ROUTE);
    } catch (error) {
      showMessage(
        polyglot.t("AbsencePolicyUnarchiveConfirmationDrawer.couldNotUnarchive", {
          nestError: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }), [absencePolicy.id, refresh, history, showMessage, polyglot]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("AbsencePolicyUnarchiveConfirmationDrawer.unarchivePolicy") }),
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("AbsencePolicyUnarchiveConfirmationDrawer.unarchivePolicyDesc") }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.m30 }), children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "button",
          sizeVariant: "medium",
          colorVariant: "primary",
          name: polyglot.t("General.unarchive"),
          loading,
          onClick: unarchivePolicy
        }
      )
    ] })
  ] }) });
};
