"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ZeltMdmScreenSettingsSecurityPolicyViewer } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/viewers/zelt-mdm-screen-settings-policy-viewer.component";
import { NoAppliedPolicies } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_DEVICES_GENERAL_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ScreenSettingsZeltMdmPage = () => {
  const [showMessage] = useMessage();
  const [
    configurablePolicies,
    setConfigurablePolicies
  ] = useState();
  const routerHistory = useHistory();
  const getDeviceSettingsByZeltMdm = useCallback(() => __async(void 0, null, function* () {
    const appliedPoliciesWithConfigurableFeature = yield DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature();
    setConfigurablePolicies(appliedPoliciesWithConfigurableFeature);
  }), []);
  const updatePolicies = (configurablePolicies2) => __async(void 0, null, function* () {
    try {
      yield DeviceAPI.updateCompanyDeviceAppliedPoliciesByZeltMdm(NoAppliedPolicies, false, configurablePolicies2);
      showMessage("Successfully updated settings", "success");
      yield getDeviceSettingsByZeltMdm();
      routerHistory.push(SETTINGS_DEVICES_GENERAL_ROUTE);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  });
  useEffect(() => {
    getDeviceSettingsByZeltMdm();
  }, [getDeviceSettingsByZeltMdm]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Screen settings" }) }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, children: /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: spacing.g35
        },
        children: /* @__PURE__ */ jsx(
          ZeltMdmScreenSettingsSecurityPolicyViewer,
          {
            updatePolicies,
            configurablePoliciesSelected: configurablePolicies
          }
        )
      }
    ) })
  ] });
};
