"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Divider } from "@v2/components/divider.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI, AbsenceEndpoints } from "@v2/feature/absence/absence.api";
import { isHourlyPolicy } from "@v2/feature/absence/absence.util";
import {
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getExpiredUnusedCarryOver,
  getRemainingCarryOver,
  getUsedCarryOver
} from "@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx, fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { getAllowancesUnits } from "@v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { round2Digits } from "@v2/util/number.util";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
export const BalanceCarryOverDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  absencePolicy,
  policyBreakdown,
  refresh,
  isOnRegularSchedule,
  currentAverageWorkDayLength
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AllowanceDrawer.carryOver") }),
        /* @__PURE__ */ jsx(
          BalanceCarryOverDrawerContent,
          {
            userId,
            policyBreakdown,
            absencePolicy,
            refreshAllowances: refresh,
            isOnRegularSchedule,
            currentAverageWorkDayLength
          }
        )
      ] })
    }
  ) });
};
const BalanceCarryOverDrawerContent = ({
  userId,
  absencePolicy,
  policyBreakdown,
  refreshAllowances,
  isOnRegularSchedule,
  currentAverageWorkDayLength
}) => {
  const selectedYear = useMemo(() => policyBreakdown.holidayYear, [policyBreakdown]);
  const { data: userAllowances, mutate: refreshUserAllowance } = useApiClient(
    AbsenceEndpoints.getUserAbsencePolicyAllowances(absencePolicy.id, userId, selectedYear)
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    yield Promise.all([refreshAllowances(absencePolicy.id), refreshUserAllowance ? refreshUserAllowance() : void 0]);
  }), [absencePolicy.id, refreshAllowances, refreshUserAllowance]);
  const isHourly = isHourlyPolicy(absencePolicy);
  return isEditMode ? /* @__PURE__ */ jsx(
    EditMode,
    {
      userId,
      absencePolicy,
      effectiveYear: selectedYear,
      allowances: userAllowances != null ? userAllowances : null,
      refresh,
      setIsEditMode,
      isOnRegularSchedule,
      currentAverageWorkDayLength,
      isHourly
    }
  ) : /* @__PURE__ */ jsx(
    ReadMode,
    {
      userId,
      absencePolicy,
      policyBreakdown,
      setIsEditMode,
      year: selectedYear,
      isHourly
    }
  );
};
const ReadMode = ({
  userId,
  setIsEditMode,
  absencePolicy,
  policyBreakdown,
  year,
  isHourly
}) => {
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const isAdminOrManager = hasScopes(["absence:manager"], { userId });
  const unusedExpiredCarryOverDetails = useMemo(() => {
    return getExpiredCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, policyBreakdown, absencePolicy]);
  const unusedUnitsLastCycle = useMemo(() => {
    return getExpiredUnusedCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, policyBreakdown, absencePolicy]);
  const allowedUnitsThisCycle = useMemo(() => {
    return getCarryOverFromBreakdown(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, absencePolicy, policyBreakdown]);
  const carryOverUsed = useMemo(() => {
    return getUsedCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, policyBreakdown, absencePolicy]);
  const carryOverRemaining = useMemo(() => {
    return getRemainingCarryOver(absencePolicy, policyBreakdown, polyglot, true);
  }, [polyglot, absencePolicy, policyBreakdown]);
  return /* @__PURE__ */ jsxs(Box, { style: __spreadProps(__spreadValues({}, drawerContentSx), { gap: spacing.s1 }), children: [
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AllowanceDrawer.unusedIn", { year: year - 1 }) }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: unusedUnitsLastCycle })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AllowanceDrawer.companyLimit") }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: policyBreakdown.carryOver.companyLimit === null ? polyglot.t("AllowanceDrawer.unlimited") : isHourly ? polyglot.t("AllowanceDrawer.noOfHours", { smart_count: policyBreakdown.carryOver.companyLimit }) : polyglot.t("AllowanceDrawer.noOfDays", { smart_count: policyBreakdown.carryOver.companyLimit }) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AllowanceDrawer.carriedOverInto", { year }) }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: allowedUnitsThisCycle })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between", mb: spacing.m15 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AllowanceDrawer.carryOverUsedIn", { year }) }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: carryOverUsed })
    ] }),
    /* @__PURE__ */ jsx(Divider, { style: { marginBottom: spacing.m10 } }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("AllowanceDrawer.carryOverAvailableToday") }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: carryOverRemaining })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AllowanceDrawer.carryOverExpiry") }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: policyBreakdown.carryOver.expiryDate ? new LocalDate(policyBreakdown.carryOver.expiryDate).toLocaleDateString() : polyglot.t("AllowanceDrawer.noExpiry") })
    ] }),
    policyBreakdown.carryOver.expiryDate && /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AllowanceDrawer.carryOverExpired") }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: unusedExpiredCarryOverDetails })
    ] }),
    isAdminOrManager && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setIsEditMode(true), sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, children: polyglot.t("General.edit") }) })
  ] });
};
const EditMode = ({
  userId,
  absencePolicy,
  effectiveYear,
  allowances,
  refresh,
  setIsEditMode,
  isOnRegularSchedule,
  currentAverageWorkDayLength,
  isHourly
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const updateCarryOverAdjustment = useCallback(
    (userCarryOverAdjustment) => __async(void 0, null, function* () {
      if (!allowances) return;
      setLoading(true);
      try {
        yield AbsenceAPI.updateAbsencePolicyUserAllowanceCarryOverAdjustment(absencePolicy.id, userId, {
          effectiveYear,
          userCarryOverAdjustment
        });
        yield refresh();
        setIsEditMode(false);
      } catch (error) {
        showMessage(
          polyglot.t("AllowanceDrawer.errors.couldNotUpdateCarryOver", { nestError: nestErrorMessage(error) }),
          "error"
        );
      }
      setLoading(false);
    }),
    [polyglot, allowances, absencePolicy.id, userId, effectiveYear, refresh, showMessage, setIsEditMode]
  );
  const formik = useFormik({
    initialValues: {
      unit: isOnRegularSchedule && !isHourly && currentAverageWorkDayLength ? "day" : "hour",
      value: (_c = isOnRegularSchedule && !isHourly && currentAverageWorkDayLength ? round2Digits(((_a = allowances == null ? void 0 : allowances.userCarryOverAdjustment) != null ? _a : 0) / currentAverageWorkDayLength) : round2Digits(((_b = allowances == null ? void 0 : allowances.userCarryOverAdjustment) != null ? _b : 0) / 60)) != null ? _c : 0
    },
    validationSchema: Yup.object({
      value: Yup.number().typeError(polyglot.t("AllowanceDrawer.errors.inputValidValue")).required(polyglot.t("AllowanceDrawer.errors.validValueRequired")),
      unit: Yup.string().oneOf(["hour", "day"]).required(polyglot.t("AllowanceDrawer.errors.validValueRequired"))
    }),
    onSubmit: (_0) => __async(void 0, [_0], function* ({ unit, value }) {
      const multiplier = unit === "day" && currentAverageWorkDayLength ? currentAverageWorkDayLength : 60;
      const carryOverAdjustment = Math.round(Number(value) * multiplier);
      yield updateCarryOverAdjustment(carryOverAdjustment);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", gap: spacing.g10 }), children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "unit",
          label: polyglot.t("BalanceCarryOverDrawer.unit"),
          options: getAllowancesUnits(polyglot),
          value: formik.values.unit,
          onChange: formik.handleChange,
          disabled: !isOnRegularSchedule || isHourly || !currentAverageWorkDayLength
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("BalanceCarryOverDrawer.value"),
          name: "value",
          value: formik.values.value,
          onChange: (e) => {
            formik.setFieldValue("value", e.target.value);
          },
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
