"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Edit } from "@mui/icons-material";
import { Box, Button, FormControlLabel, RadioGroup } from "@mui/material";
import dayjs from "dayjs";
import useMessage from "@/hooks/notification.hook";
import { Divider } from "@/v2/components/divider.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
import { formatMoney } from "@/v2/feature/payments/utils/money.util";
import {
  DiscountTypeLabels,
  DiscountTypeValues,
  discountTypeOptions
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface";
import { discountExpired } from "@/v2/feature/super-admin/features/super-admin-billing-v2/components/super-admin-billing-company-subscription-edit-form.component";
import { drawerContentSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
export const SuperAdminBillingCompanySubscriptionEditModuleForm = ({
  selectedModule,
  companyName,
  subscriptionCurrency,
  updateSubscriptionInParentDrawer,
  handleDeleteModule
}) => {
  var _a, _b, _c, _d;
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [updatedModule, setUpdatedModule] = useState(__spreadValues({}, selectedModule));
  const [discountType, setDiscountType] = useState((_a = selectedModule.discountType) != null ? _a : DiscountTypeValues.PERCENTAGE);
  const [discountEnabled, setDiscountEnabled] = useState(!!selectedModule.discountValue);
  const [showMessage] = useMessage();
  const [isEditingListPrice, setIsEditingListPrice] = useState(false);
  const [listPriceErrors, setListPriceErrors] = useState({ GBP: "", USD: "" });
  const validatePrice = (value, currency) => {
    if (value < 0) {
      setListPriceErrors((prev) => __spreadProps(__spreadValues({}, prev), { [currency]: "Price cannot be negative" }));
      return false;
    }
    setListPriceErrors((prev) => __spreadProps(__spreadValues({}, prev), { [currency]: "" }));
    return true;
  };
  const handleListPriceChange = (value, currency) => {
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue) && validatePrice(numberValue, currency)) {
      const newCost = __spreadProps(__spreadValues({}, updatedModule.cost), { [currency]: numberValue });
      setUpdatedModule((prev) => __spreadProps(__spreadValues({}, prev), {
        cost: newCost,
        pricePerSeat: discountEnabled ? getDiscountedCost(newCost, discountType, updatedModule.discountValue, updatedModule.discountEndDate) : newCost
      }));
    }
  };
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsSavingChanges(true);
      if (updateSubscriptionInParentDrawer && updatedModule) updateSubscriptionInParentDrawer(updatedModule);
      setIsSavingChanges(false);
      showMessage("Customer subscription updated successfully", "success");
    } catch (e) {
      showMessage("Failed to update customer plan", "error");
      console.error(":::: error ::::", e);
      setIsSavingChanges(false);
    }
  }), [updateSubscriptionInParentDrawer, updatedModule, showMessage]);
  const discountOptions = useMemo(() => {
    return discountTypeOptions.map((option) => ({
      label: DiscountTypeLabels[option],
      value: option
    }));
  }, []);
  const getDiscountedCost = (currentCost, currentDiscountType, discountValue, discountEndDate) => {
    if (!discountEnabled || !currentDiscountType || !discountValue || discountEndDate && discountExpired(discountEndDate))
      return currentCost;
    const ensureNotLessThanZero = (value) => value < 0 ? 0 : value;
    if (currentDiscountType === DiscountTypeValues.FIXED) {
      return __spreadProps(__spreadValues({}, currentCost), {
        GBP: ensureNotLessThanZero(currentCost.GBP - discountValue),
        USD: ensureNotLessThanZero(currentCost.USD - discountValue)
      });
    }
    if (currentDiscountType === DiscountTypeValues.PERCENTAGE) {
      const discountGBPAmount = currentCost.GBP * discountValue / 100;
      const discountUSDAmount = currentCost.USD * discountValue / 100;
      return __spreadProps(__spreadValues({}, currentCost), {
        GBP: ensureNotLessThanZero(currentCost.GBP - discountGBPAmount),
        USD: ensureNotLessThanZero(currentCost.USD - discountUSDAmount)
      });
    }
    return currentCost;
  };
  const effectivePricePerSeat = updatedModule && updatedModule.cost && updatedModule.discountType && updatedModule.discountValue ? getDiscountedCost(
    updatedModule.cost,
    updatedModule.discountType,
    updatedModule.discountValue,
    updatedModule.discountEndDate
  )[subscriptionCurrency] : 0;
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Plan" }),
    /* @__PURE__ */ jsx(DrawerViewerItem, { title: "Company", value: companyName != null ? companyName : "" }, "companyName"),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(DrawerViewerItem, { title: "Module", value: (_b = selectedModule.planName) != null ? _b : "" }, "moduleName"),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", mb: spacing.m10 }, children: isEditingListPrice ? /* @__PURE__ */ jsxs(Box, { sx: { width: "100%", display: "flex", flexDirection: "column" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mb: 1 }, children: "List Price" }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: 2 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "GBP Price",
            name: "gbpPrice",
            type: "number",
            value: updatedModule.cost.GBP,
            onChange: (e) => handleListPriceChange(e.target.value, "GBP"),
            error: !!listPriceErrors.GBP,
            helperText: listPriceErrors.GBP,
            size: "small",
            endAdornment: "none",
            sx: { flex: 1 }
          }
        ),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "USD Price",
            name: "usdPrice",
            type: "number",
            value: updatedModule.cost.USD,
            onChange: (e) => handleListPriceChange(e.target.value, "USD"),
            error: !!listPriceErrors.USD,
            helperText: listPriceErrors.USD,
            size: "small",
            endAdornment: "none",
            sx: { flex: 1 }
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "secondary",
            onClick: () => setIsEditingListPrice(false),
            children: "Done"
          }
        )
      ] })
    ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        DrawerViewerItem,
        {
          title: "List price",
          value: updatedModule.cost && subscriptionCurrency ? (_c = formatCurrency(
            updatedModule.cost[subscriptionCurrency],
            void 0,
            subscriptionCurrency
          )) != null ? _c : "" : ""
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          onClick: () => setIsEditingListPrice(true),
          sx: {
            minWidth: 10,
            padding: 1,
            maxWidth: 40,
            borderRadius: 1,
            backgroundColor: themeColors.lightGrey,
            color: themeColors.DarkGrey
          },
          children: /* @__PURE__ */ jsx(Edit, { sx: { height: 20, width: "20px" } })
        },
        updatedModule.planDefinitionId
      )
    ] }) }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Discount" }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: discountEnabled,
          name: "discountEnabled",
          onChange: (_e, enabled) => {
            setDiscountEnabled(enabled);
            const moduleToUpdate = __spreadValues(__spreadValues({}, updatedModule), !enabled ? { discountType: void 0, discountValue: void 0, pricePerSeat: updatedModule.cost } : {});
            setUpdatedModule(moduleToUpdate);
          }
        }
      )
    ] }),
    discountEnabled ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, fieldSx), { display: "flex", gap: spacing.g10, mb: spacing.m20 }), children: /* @__PURE__ */ jsx(
        RadioGroup,
        {
          name: "discount-type",
          onChange: (event) => {
            setDiscountType(event.target.value);
            const moduleToUpdate = __spreadValues(__spreadValues({}, updatedModule), event.target.value ? {
              discountType: event.target.value,
              pricePerSeat: getDiscountedCost(
                updatedModule.cost,
                event.target.value,
                updatedModule.discountValue
              )
            } : {});
            setUpdatedModule(moduleToUpdate);
          },
          children: discountOptions.map((option) => /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: option.value,
              checked: discountType === option.value,
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: option.label }),
              sx: { mb: spacing.m5 }
            },
            option.value
          ))
        }
      ) }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: discountType ? DiscountTypeLabels[discountType] : "Discount amount",
          name: "discount",
          type: "number",
          value: updatedModule.discountValue,
          onChange: (e) => {
            if (!e.target.value) return;
            const newDiscount = +e.target.value;
            const moduleToUpdate = __spreadProps(__spreadValues({}, updatedModule), {
              discountType,
              discountValue: newDiscount,
              pricePerSeat: getDiscountedCost(updatedModule.cost, discountType, newDiscount)
            });
            setUpdatedModule(moduleToUpdate);
          },
          fullWidth: true,
          size: "small",
          endAdornment: "none"
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Box, { width: "85%", children: /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            inputFormat: "DD/MM/YYYY",
            value: (_d = updatedModule.discountEndDate) != null ? _d : null,
            onChange: (value) => {
              if (dayjs(value).isValid()) {
                setUpdatedModule((prev) => __spreadProps(__spreadValues({}, prev), { discountEndDate: new Date(value) }));
              }
            },
            name: "endDate",
            label: "End date (Optional)"
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: updatedModule.discountEndDate && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "small",
            colorVariant: "secondary",
            onClick: () => {
              setUpdatedModule((prev) => __spreadProps(__spreadValues({}, prev), { discountEndDate: null }));
            },
            children: "Clear"
          }
        ) })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, width: "50%", children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Effective price",
          name: "effectivePrice",
          value: effectivePricePerSeat !== void 0 && effectivePricePerSeat !== null ? formatMoney({
            amount: effectivePricePerSeat,
            currency: subscriptionCurrency
          }) : "Invalid discount",
          fullWidth: true,
          disabled: true,
          size: "small",
          endAdornment: "none"
        }
      ) })
    ] }) : /* @__PURE__ */ jsx(Fragment, {}),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        colorVariant: "danger",
        sizeVariant: "small",
        style: {
          transition: "opacity 0.3s ease, visibility 0.3s ease"
        },
        onClick: () => handleDeleteModule(selectedModule),
        disabled: isEditingListPrice,
        children: "Delete this module"
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Update module",
        sizeVariant: "medium",
        loading: isSavingChanges,
        colorVariant: "primary",
        fullWidth: true,
        onClick: handleSave,
        disabled: !!(discountEnabled && !updatedModule.discountValue) || !!listPriceErrors.GBP || !!listPriceErrors.USD || isEditingListPrice
      }
    ) })
  ] });
};
