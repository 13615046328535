"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { BreakBetweenDaysRuleForm } from "@v2/feature/attendance/settings/components/attendance-schedule-rule-form/attendance-schedule-rule-break-between-days-form.component";
import { DailyLengthRuleForm } from "@v2/feature/attendance/settings/components/attendance-schedule-rule-form/attendance-schedule-rule-daily-length-form.component";
import {
  RuleTypeOption
} from "@v2/feature/attendance/subfeatures/attendance-schedule-rule/attendance-schedule-rule.interface";
import { snakeToCamelCase } from "@v2/util/string.util";
import { DailyRuleForm } from "./attendance-schedule-rule-form/attendance-schedule-rule-daily-form.component";
import { WeeklyRuleForm } from "./attendance-schedule-rule-form/attendance-schedule-rule-weekly-form.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
export const AttendanceScheduleRuleDrawer = ({
  isOpen,
  setIsOpen,
  rule,
  attendancePayMultipliers,
  createRuleType,
  scheduleId,
  refreshRules,
  polyglot
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    AttendanceScheduleRuleDrawerContent,
    {
      setIsOpen,
      rule,
      attendancePayMultipliers,
      createRuleType,
      scheduleId,
      refreshRules,
      polyglot
    }
  ) });
};
export const AttendanceScheduleRuleDrawerContent = ({
  setIsOpen,
  rule,
  attendancePayMultipliers,
  createRuleType,
  scheduleId,
  refreshRules,
  polyglot
}) => {
  const generateFormTitle = () => {
    if (rule) {
      return `Edit ${polyglot.t(`AttendanceSchedule.scheduleRules.ruleType.${snakeToCamelCase(rule.ruleType)}`).toLowerCase()} rule`;
    }
    if (createRuleType) {
      return `New ${polyglot.t(`AttendanceSchedule.scheduleRules.ruleType.${snakeToCamelCase(createRuleType)}`).toLowerCase()} rule`;
    }
    return "";
  };
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: generateFormTitle() }),
    ((rule == null ? void 0 : rule.ruleType) === RuleTypeOption.Daily || createRuleType === RuleTypeOption.Daily) && /* @__PURE__ */ jsx(
      DailyRuleForm,
      {
        scheduleId,
        rule,
        attendancePayMultipliers,
        refreshRules,
        setIsOpen
      }
    ),
    ((rule == null ? void 0 : rule.ruleType) === RuleTypeOption.Weekly || createRuleType === RuleTypeOption.Weekly) && /* @__PURE__ */ jsx(
      WeeklyRuleForm,
      {
        scheduleId,
        rule,
        attendancePayMultipliers,
        refreshRules,
        setIsOpen
      }
    ),
    ((rule == null ? void 0 : rule.ruleType) === RuleTypeOption.DailyLength || createRuleType === RuleTypeOption.DailyLength) && /* @__PURE__ */ jsx(
      DailyLengthRuleForm,
      {
        scheduleId,
        rule,
        attendancePayMultipliers,
        refreshRules,
        setIsOpen
      }
    ),
    ((rule == null ? void 0 : rule.ruleType) === RuleTypeOption.BreakBetweenDays || createRuleType === RuleTypeOption.BreakBetweenDays) && /* @__PURE__ */ jsx(
      BreakBetweenDaysRuleForm,
      {
        scheduleId,
        rule,
        attendancePayMultipliers,
        refreshRules,
        setIsOpen
      }
    )
  ] });
};
