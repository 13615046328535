"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ColumnsListSection } from "@v2/feature/reports/reports-advanced/components/columns-list.section";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
export const ReportColumns = ({
  selectedColumns,
  setSelectedColumns,
  openAddColumns,
  editColumn,
  reportColumns
}) => {
  const hasColumns = useMemo(() => {
    return Object.keys(selectedColumns).length > 0;
  }, [selectedColumns]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        height: "100%",
        overflowY: "auto"
      },
      children: [
        hasColumns ? /* @__PURE__ */ jsx(
          ColumnsListSection,
          {
            selectedColumns,
            setSelectedColumns,
            editColumn,
            reportColumns
          }
        ) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "No columns selected" }),
        /* @__PURE__ */ jsx(Box, { sx: { pb: spacing.s1 }, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "dashed",
            style: { marginTop: "10px" },
            fullWidth: true,
            onClick: openAddColumns,
            startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
            children: "Add columns"
          }
        ) })
      ]
    }
  );
};
