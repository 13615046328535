"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2, Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ChipComponentWithIcon } from "@v2/components/chip/chip-with-icon.component";
import { DeviceActionChip } from "@v2/components/chip/device-shipping-chip.component";
import { DeviceTransitDrawer } from "@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/device-transit-action-item.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { ConfigurableDeviceStatus, DevicePossessionType } from "@v2/feature/device/device.interface";
import {
  getDeviceLocationAddressByDevicePossession,
  getDeviceOwnerByDevicePossession,
  getDeviceOwnerByDevicePossessionTable,
  isAppleMobileDevice
} from "@v2/feature/device/device.util";
import { DeviceOrderDrawer } from "@v2/feature/device/features/devices-company/components/device-order-drawer.component";
import { DeviceActivityLogPage } from "@v2/feature/device/features/devices-company/pages/device-activity-log.page";
import { DeviceHistoryPage } from "@v2/feature/device/features/devices-company/pages/device-history.page";
import { DeviceMdmDetailsPage } from "@v2/feature/device/features/devices-company/pages/device-mdm-details.page";
import { DeviceMdmSecurityPage } from "@v2/feature/device/features/devices-company/pages/device-mdm-security.page";
import { DeviceNotesPage } from "@v2/feature/device/features/devices-company/pages/device-notes.page";
import { InHouseMdmDeviceInstalledAppsPage } from "@v2/feature/device/features/devices-company/pages/inhouse-mdm-device-installed-apps.page";
import { NoAppliedPolicies } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { UserAddressAPI } from "@v2/feature/user/features/user-forms/user-address/user-address.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { keyBy } from "lodash";
import { generatePath, Redirect, Switch, useHistory, useParams } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Clock } from "@/images/side-bar-icons/Clock.svg";
import { ReactComponent as Device } from "@/images/side-bar-icons/Device.svg";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Order } from "@/images/side-bar-icons/OrderBag.svg";
import { ReactComponent as Shipping } from "@/images/side-bar-icons/Shipping.svg";
import { nestErrorMessage } from "@/lib/errors";
import {
  DEVICE_COMPANY_DEVICE_ENROLL_ROUTE,
  DEVICES_ACTIVITY_LOG_ROUTE,
  DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE,
  DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
  DEVICES_COMPANY_DEVICE_INSTALED_APPS_ROUTE,
  DEVICES_COMPANY_DEVICE_POLICIES_ROUTE,
  DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE,
  DEVICES_COMPANY_DIRECTORY_ROUTE,
  DEVICES_COMPANY_INHOUSE_MDM_DEVICE_INSTALLED_APPS_ROUTE,
  DEVICES_HISTORY_ROUTE,
  DEVICES_NOTES_ROUTE
} from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { DomainSideMenuContent } from "@/v2/components/domain-side-menu-content.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceActionsButton } from "@/v2/feature/device/components/device-details/device-actions-button.component";
import { getModelImage } from "@/v2/feature/device/device.util";
import { DeviceInstalledAppsPage } from "@/v2/feature/device/features/devices-company/pages/device-installed-apps.page";
import { DevicePoliciesPage } from "@/v2/feature/device/features/devices-company/pages/device-policies.page";
import { EnrollExistingDevice } from "@/v2/feature/device/features/order-device/enroll-existing-device.page";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
const getDevicePageConfig = (devicePossessionId, device, isActiveTransit, isUserAssign, currentUser, context) => {
  return [
    {
      title: "",
      stub: "",
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: "Security",
          stub: "security",
          path: generatePath(DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE, {
            devicePossessionId
          }),
          isHidden: !device || isActiveTransit || !isUserAssign || !(device == null ? void 0 : device.mdmable),
          hasChildren: false
        },
        {
          title: "Details",
          stub: "details",
          path: generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, {
            devicePossessionId
          }),
          isHidden: !checkScopes(currentUser, ["devices:all", "devices"], context),
          hasChildren: false
        },
        {
          title: "History",
          stub: "history",
          path: generatePath(DEVICES_HISTORY_ROUTE, { devicePossessionId }),
          isHidden: !checkScopes(currentUser, ["devices:all"], context) || !device,
          hasChildren: false
        },
        {
          title: "Activity log",
          stub: "activity_log",
          path: generatePath(DEVICES_ACTIVITY_LOG_ROUTE, { devicePossessionId }),
          isHidden: !checkScopes(currentUser, ["devices:all", "devices"], context) || !device,
          hasChildren: false
        },
        {
          title: "Apps",
          stub: "apps",
          path: generatePath(DEVICES_COMPANY_DEVICE_INSTALED_APPS_ROUTE, { devicePossessionId }),
          isHidden: (context ? checkScopes(currentUser, ["devices"], context) : true) && !((device == null ? void 0 : device.enrollmentStatus) === "enrolled" || (device == null ? void 0 : device.enrollmentStatus) === "ENROLMENT_FINISHED"),
          hasChildren: false
        },
        {
          title: "Notes",
          stub: "notes",
          path: generatePath(DEVICES_NOTES_ROUTE, {
            devicePossessionId
          }),
          isHidden: !checkScopes(currentUser, ["devices:all", "devices"], context),
          hasChildren: false
        }
      ]
    }
  ];
};
export const DevicesDetailsRouter = () => {
  var _a;
  const [devicePossession, setDevicePossession] = useState(null);
  const [configurableDevice, setConfigurableDevice] = useState(null);
  const [activeDeviceTransit, setActiveDeviceTransit] = useState(null);
  const [appliedPolicies, setAppliedPolicies] = useState(NoAppliedPolicies);
  const [loading, setLoading] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const [assignedUser] = useState(void 0);
  const [appliedZeltPolicies, setAppliedZeltPolicies] = useState(
    NoAppliedPolicies
  );
  const [
    appliedZeltPoliciesForMobile,
    setAppliedZeltPoliciesForMobile
  ] = useState(NoAppliedPolicies);
  const [showMessage] = useMessage();
  const params = useParams();
  const devicePossessionId = Number(params.devicePossessionId);
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });
  const [locationAddress, setLocationAddress] = useState("Unknown");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDeviceTransitId, setSelectedDeviceTransitId] = useState(null);
  const [selectedConfigurableDeviceOrder, setSelectedConfigurableDeviceOrder] = useState(
    null
  );
  const routerHistory = useHistory();
  const fetchDeviceDetails = useCallback(() => __async(void 0, null, function* () {
    var _a2, _b, _c;
    try {
      setLoading(true);
      const configurableDevice2 = yield DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId);
      if (!configurableDevice2) {
        setLoading(false);
        return;
      }
      const manufacturer = (_b = (_a2 = configurableDevice2.devicePossession.device) == null ? void 0 : _a2.manufacturer) != null ? _b : null;
      const isAppleDevice = manufacturer == null ? void 0 : manufacturer.toLowerCase().includes("apple");
      let policyPromise;
      if (isAppleDevice) {
        if (isAppleMobileDevice(configurableDevice2.devicePossession.device)) {
          policyPromise = DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile();
        } else {
          policyPromise = DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature();
        }
      } else {
        policyPromise = DeviceAPI.getAppliedCompanyDevicePolicies();
      }
      const [activeDeviceTransitData, policies] = yield Promise.all([
        DeviceAPI.getActiveTransitForDevicePossession(devicePossessionId),
        policyPromise
      ]);
      setDevicePossession(configurableDevice2.devicePossession);
      setConfigurableDevice(configurableDevice2);
      setActiveDeviceTransit(activeDeviceTransitData);
      if (isAppleDevice) {
        if (isAppleMobileDevice(configurableDevice2.devicePossession.device)) {
          setAppliedZeltPoliciesForMobile(policies);
        } else {
          setAppliedZeltPolicies(policies);
        }
      } else {
        setAppliedPolicies(policies);
      }
      const userAddress = yield UserAddressAPI.findByUserId((_c = configurableDevice2.devicePossession.possessionId) != null ? _c : 0);
      setLocationAddress(
        getDeviceLocationAddressByDevicePossession(
          configurableDevice2.devicePossession,
          configurableDevice2 == null ? void 0 : configurableDevice2.sites.find((site) => site.id === configurableDevice2.devicePossession.possessionId),
          userAddress
        )
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
    }
  }), [devicePossessionId, showMessage]);
  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);
  const refreshDevice = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const configurableDevice2 = yield DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId);
      setDevicePossession(configurableDevice2.devicePossession);
      setConfigurableDevice(configurableDevice2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, "error");
    }
  }), [devicePossessionId, showMessage]);
  const { polyglot } = usePolyglot();
  const handleOpenDrawer = useCallback((deviceTransitId) => {
    setSelectedDeviceTransitId(deviceTransitId);
    setIsDrawerOpen(true);
  }, []);
  const handleOpenDrawerDeviceOrder = useCallback((configurableDeviceData) => {
    setSelectedConfigurableDeviceOrder(configurableDeviceData);
    setIsDrawerOpen(true);
  }, []);
  const customTitle = useMemo(() => {
    var _a2, _b, _c, _d, _e, _f, _g, _h;
    const enroled = ((_a2 = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a2.enrollmentStatus) === "enrolled" || ((_b = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _b.enrollmentStatus) === "ENROLMENT_FINISHED";
    const getUserName = () => {
      var _a3, _b2;
      return getDeviceOwnerByDevicePossession(
        devicePossession != null ? devicePossession : void 0,
        (_b2 = (_a3 = configurableDevice == null ? void 0 : configurableDevice.sites) == null ? void 0 : _a3.find((site) => site.id === (devicePossession == null ? void 0 : devicePossession.possessionId))) != null ? _b2 : null,
        getCachedUserById
      );
    };
    const getDeviceStatus = (cd) => {
      const statusMap = {
        [ConfigurableDeviceStatus.shipping]: /* @__PURE__ */ jsx(
          DeviceActionChip,
          {
            name: "Shipping",
            icon: /* @__PURE__ */ jsx(Shipping, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "black",
            border: "dark",
            textVariant: "paragraph",
            onClick: () => {
              handleOpenDrawer(cd.deviceTransitId);
            }
          }
        ),
        [ConfigurableDeviceStatus.pending]: /* @__PURE__ */ jsx(
          DeviceActionChip,
          {
            name: "Pending",
            icon: /* @__PURE__ */ jsx(Clock, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "black",
            border: "dark",
            textVariant: "paragraph",
            onClick: () => {
              handleOpenDrawer(cd.deviceTransitId);
            }
          }
        ),
        [ConfigurableDeviceStatus.order]: /* @__PURE__ */ jsx(
          DeviceActionChip,
          {
            name: "Order",
            icon: /* @__PURE__ */ jsx(Order, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "DarkGrey",
            border: "dark",
            textVariant: "paragraph",
            onClick: () => {
              handleOpenDrawerDeviceOrder(cd);
            }
          }
        ),
        [ConfigurableDeviceStatus.inventory]: /* @__PURE__ */ jsx(
          ChipComponentWithIcon,
          {
            name: "Inventory",
            icon: /* @__PURE__ */ jsx(Device, __spreadValues({}, iconSize)),
            backgroundColor: "white",
            textColor: "DarkGrey",
            border: "light",
            textVariant: "paragraph"
          }
        )
      };
      const statusMapElement = statusMap[cd.status];
      if (statusMapElement) {
        return statusMapElement;
      } else {
        return /* @__PURE__ */ jsx(
          ChipComponentWithIcon,
          {
            name: enroled ? "Enroled" : "Not enroled",
            backgroundColor: "white",
            textColor: "DarkGrey",
            icon: enroled ? /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })) : /* @__PURE__ */ jsx(Mistake, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
            border: "light",
            textVariant: "paragraph"
          }
        );
      }
    };
    return /* @__PURE__ */ jsx(Box, { children: loading ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", width: "100%", justifyContent: "center" }, children: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "120px",
        height: "70px",
        sx: { background: themeColors.Background, borderRadius: radius.br10 }
      }
    ) }) : /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: spacing.s2,
          boxSizing: "border-box"
        },
        children: [
          (_e = getModelImage((_c = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _c.type, (_d = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _d.modelName, {
            width: "120px",
            height: "120px"
          })) != null ? _e : /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "120px",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                gap: spacing.s1,
                boxSizing: "border-box"
              },
              children: [
                configurableDevice && getDeviceStatus(configurableDevice),
                devicePossession && /* @__PURE__ */ jsx(Fragment, { children: devicePossession.possessionType === DevicePossessionType.User ? /* @__PURE__ */ jsx(
                  Box,
                  {
                    sx: {
                      width: "90%",
                      boxSizing: "border-box"
                    },
                    children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1, justifyContent: "center" }, children: [
                      /* @__PURE__ */ jsx(
                        UserAvatar,
                        {
                          userId: devicePossession.possessionId,
                          size: "s20"
                        },
                        devicePossession.possessionId
                      ),
                      /* @__PURE__ */ jsx(
                        Typography,
                        {
                          variant: "paragraph",
                          sx: {
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            width: "inherit"
                          },
                          children: getUserName()
                        }
                      )
                    ] })
                  }
                ) : getDeviceOwnerByDevicePossessionTable(
                  devicePossession,
                  (_f = configurableDevice == null ? void 0 : configurableDevice.sites) == null ? void 0 : _f.find((item) => item.id === devicePossession.possessionId),
                  polyglot,
                  assignedUser == null ? void 0 : assignedUser.displayName
                ) })
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            DeviceActionsButton,
            {
              configurableDevice,
              fetchDeviceDetails,
              activeTransit: activeDeviceTransit,
              refreshDevicesAppData: refreshDevice,
              assignedUser: (configurableDevice == null ? void 0 : configurableDevice.devicePossession.possessionType) === DevicePossessionType.User ? getCachedUserById(configurableDevice == null ? void 0 : configurableDevice.devicePossession.possessionId) : void 0
            }
          ),
          isDrawerOpen && selectedDeviceTransitId && /* @__PURE__ */ jsx(
            DeviceTransitDrawer,
            {
              deviceTransitId: selectedDeviceTransitId,
              refresh: () => __async(void 0, null, function* () {
                routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
              }),
              afterClose: () => setIsDrawerOpen(false),
              isOpen: isDrawerOpen,
              setIsOpen: setIsDrawerOpen,
              sites: (_g = configurableDevice == null ? void 0 : configurableDevice.sites) != null ? _g : []
            }
          ),
          isDrawerOpen && selectedConfigurableDeviceOrder && /* @__PURE__ */ jsx(
            DeviceOrderDrawer,
            {
              deviceOrder: selectedConfigurableDeviceOrder.order,
              refresh: () => __async(void 0, null, function* () {
                yield refreshDevice();
                setIsDrawerOpen(false);
              }),
              sitesById: keyBy((_h = selectedConfigurableDeviceOrder.sites) != null ? _h : [], "id"),
              reach: "company",
              setIsOpen: setIsDrawerOpen,
              isOpen: isDrawerOpen,
              onClose: () => {
                setIsDrawerOpen(false);
              }
            }
          )
        ]
      }
    ) });
  }, [
    activeDeviceTransit,
    assignedUser == null ? void 0 : assignedUser.displayName,
    configurableDevice,
    devicePossession,
    fetchDeviceDetails,
    getCachedUserById,
    handleOpenDrawer,
    handleOpenDrawerDeviceOrder,
    isDrawerOpen,
    loading,
    polyglot,
    refreshDevice,
    routerHistory,
    selectedConfigurableDeviceOrder,
    selectedDeviceTransitId
  ]);
  return /* @__PURE__ */ jsx(Fragment2, { children: devicePossession ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DomainSideMenuContent,
      {
        pageConfig: getDevicePageConfig(
          devicePossessionId,
          devicePossession == null ? void 0 : devicePossession.device,
          !!activeDeviceTransit,
          (devicePossession == null ? void 0 : devicePossession.possessionType) === DevicePossessionType.User || false,
          state.user,
          scopesContext
        ),
        showBack: true,
        backPath: generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE, {
          devicePossessionId: devicePossession == null ? void 0 : devicePossession.id
        }),
        customTitle,
        type: "Domain"
      }
    ),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices:all"],
          path: DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            DeviceMdmDetailsPage,
            {
              configurableDevice,
              ownerName: getDeviceOwnerByDevicePossession(
                devicePossession,
                configurableDevice == null ? void 0 : configurableDevice.sites.find((site) => site.id === devicePossession.possessionId),
                getCachedUserById
              ),
              locationAddress,
              loading,
              refresh: refreshDevice
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices:all"],
          path: DEVICES_COMPANY_DEVICE_INSTALED_APPS_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(DeviceInstalledAppsPage, { deviceId: devicePossession.deviceId })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: ["devices:all"], path: DEVICES_NOTES_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "31%",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          children: /* @__PURE__ */ jsx(DeviceNotesPage, { devicePossession, loading, refresh: refreshDevice })
        }
      ) }),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices:all"],
          path: DEVICES_ACTIVITY_LOG_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(DeviceActivityLogPage, { devicePossession, loading })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(RouteScopesControl, { exact: true, scopes: ["devices:all"], path: DEVICES_HISTORY_ROUTE, context: scopesContext, children: /* @__PURE__ */ jsx(
        Suspense,
        {
          fallback: /* @__PURE__ */ jsx(
            SkeletonLoader,
            {
              variant: "rectangular",
              width: "31%",
              height: "100%",
              sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
            }
          ),
          children: /* @__PURE__ */ jsx(DeviceHistoryPage, { devicePossession })
        }
      ) }),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices:all"],
          path: DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: configurableDevice && configurableDevice.enrolment !== "asset" && /* @__PURE__ */ jsx(
                DeviceMdmSecurityPage,
                {
                  configurableDevice,
                  fetchDeviceDetails,
                  loading,
                  activeTransit: activeDeviceTransit,
                  appliedPolicies,
                  appliedZeltPolicies,
                  appliedZeltPoliciesForMobile
                }
              )
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices:all"],
          path: DEVICES_COMPANY_INHOUSE_MDM_DEVICE_INSTALLED_APPS_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(InHouseMdmDeviceInstalledAppsPage, { devicePossession, loading })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices:all"],
          path: DEVICES_COMPANY_DEVICE_POLICIES_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "100%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(DevicePoliciesPage, { devicePossession, loading })
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        RouteScopesControl,
        {
          exact: true,
          scopes: ["devices.enroll", "devices:all"],
          path: DEVICE_COMPANY_DEVICE_ENROLL_ROUTE,
          context: scopesContext,
          children: /* @__PURE__ */ jsx(EnrollExistingDevice, { initialDevicePossession: devicePossession, refreshAppData: () => __async(void 0, null, function* () {
          }) })
        }
      ),
      !(devicePossession == null ? void 0 : devicePossession.device) || activeDeviceTransit || (devicePossession == null ? void 0 : devicePossession.possessionType) !== DevicePossessionType.User || !((_a = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a.mdmable) ? /* @__PURE__ */ jsx(
        Redirect,
        {
          exact: true,
          from: DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
          to: DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE
        }
      ) : /* @__PURE__ */ jsx(
        Redirect,
        {
          exact: true,
          from: DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
          to: DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE
        }
      )
    ] })
  ] }) : null });
};
