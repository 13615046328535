"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { AbsenceBalancesTable } from "@v2/feature/absence/components/absence-balances-table.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { RootStyle } from "@v2/styles/root.styles";
import { Typography } from "@/v2/components/typography/typography.component";
export const AbsenceCompanyBalancesPage = ({ absencePolicies }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceCompanyBalancesPage.balances") })
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: false, sx: { overflow: "hidden" }, children: /* @__PURE__ */ jsx(AbsenceBalancesTable, { absencePolicies, view: "company", stickyHeader: true }) })
  ] });
};
