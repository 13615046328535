"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { v4 } from "uuid";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewAPI } from "@/v2/feature/growth/reviews/api-client/review.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const ResultsOverviewCommentModal = ({
  isOpen,
  setIsOpen,
  onClose,
  results,
  refreshResults,
  isPublic,
  selectedComment
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    ResultsOverviewCommentContent,
    {
      results,
      refreshResults,
      onClose,
      isPublic,
      selectedComment
    }
  ) });
};
const useCommentForm = (results, selectedComment, refreshResults, onClose, isPublic) => {
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const formik = useFormik({
    initialValues: {
      comment: selectedComment ? selectedComment.comment : ""
    },
    validationSchema: yup.object({
      comment: yup.string().notRequired()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (results) {
          yield ReviewAPI.upsertComment(results.reviewId, results.cycleId, {
            id: selectedComment ? selectedComment.id : v4(),
            comment: values.comment,
            isPublic: selectedComment ? selectedComment.isPublic : isPublic,
            commentBy: selectedComment ? selectedComment.commentBy : globalState.user.userId
          });
          showMessage("Successfully update the comment", "success");
        }
        yield refreshResults == null ? void 0 : refreshResults();
        onClose();
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
const ResultsOverviewCommentContent = ({
  results,
  refreshResults,
  onClose,
  isPublic,
  selectedComment
}) => {
  const { polyglot } = usePolyglot();
  const formik = useCommentForm(results, selectedComment, refreshResults, onClose, isPublic);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
      "Add ",
      isPublic ? "public" : "private",
      " comment"
    ] }),
    /* @__PURE__ */ jsx(
      RichTextField,
      {
        label: "Comment text",
        value: formik.values.comment,
        onChange: (value) => formik.setFieldValue("comment", value)
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
