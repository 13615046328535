"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from "react";
import { Box } from "@mui/material";
import { ReactComponent as SmallHinge } from "@/images/side-bar-icons/smallHinge.svg";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const textTitleSx = __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), {
  color: themeColors.white
});
const TooltipComponent = ({ title, position, width, minWidth }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        height: "100%",
        width: width || "100%",
        minWidth: minWidth || "200px",
        display: "flex",
        justifyContent: "center",
        flexDirection: position === "bottom" ? "column-reverse" : position === "top" ? "column" : "row-reverse"
      },
      children: [
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              bgcolor: themeColors.DarkGrey,
              borderRadius: "8px"
            },
            children: /* @__PURE__ */ jsx(
              Box,
              {
                sx: __spreadProps(__spreadValues({ paddingX: spacing.p8, paddingY: spacing.p5 }, themeFonts.paragraphSmall), { color: themeColors.white }),
                children: title
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              position: "relative",
              transform: position === "bottom" ? "translate3d(5%, 0%, 0px) rotate(0deg)" : position === "top" ? "translate(-90%, 0%) rotate(180deg)" : "translate(0%, 20%) rotate(0deg) ",
              display: "flex"
            },
            children: /* @__PURE__ */ jsx(SmallHinge, {})
          }
        )
      ]
    }
  );
};
export const TooltipPopper = forwardRef(
  ({ title, position, children, width = "", minWidth = "" }, ref) => {
    const [show, setShow] = useState(false);
    const positionedTooltip = () => {
      switch (position) {
        case "bottom":
          return { top: "100%" };
        case "top":
          return { bottom: "100%" };
        case "right":
          return { top: "0%", left: "100%" };
        default:
          /* @__PURE__ */ jsx(Fragment, {});
      }
    };
    return /* @__PURE__ */ jsx(Box, { ref, onMouseEnter: () => setShow(true), onMouseLeave: () => setShow(false), sx: { cursor: "pointer" }, children: /* @__PURE__ */ jsxs(Box, { sx: { position: "relative", height: "100%", width: "100%" }, children: [
      children,
      /* @__PURE__ */ jsx(
        "div",
        {
          role: "tooltip",
          style: __spreadProps(__spreadValues({
            position: "absolute",
            zIndex: "999999",
            inset: "0px auto auto 0px"
          }, positionedTooltip()), {
            visibility: show ? "visible" : "hidden"
          }),
          children: /* @__PURE__ */ jsx(TooltipComponent, { title, position, width, minWidth })
        }
      )
    ] }) });
  }
);
