"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { REVIEWS_COMPANY_ONGOING_ROUTE, REVIEWS_TEAM_ONGOING_ROUTE } from "@/lib/routes";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { ReachType } from "@/v2/interfaces/general.interface";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const ReviewCycleFinaliseModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  reach
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(ReviewCycleFinaliseContent, { reviewCycle, onClose, refresh, reach }) });
};
const ReviewCycleFinaliseContent = ({
  reviewCycle,
  onClose,
  refresh,
  // isFinished,
  reach
}) => {
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const handleFinalise = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const reachRoute = reach === ReachType.Company ? REVIEWS_COMPANY_ONGOING_ROUTE : REVIEWS_TEAM_ONGOING_ROUTE;
      if (!reviewCycle) {
        showMessage(`Cycle data not fetched`, "error");
        return;
      }
      yield ReviewCycleAPI.finaliseCycle(reviewCycle == null ? void 0 : reviewCycle.id);
      showMessage("Finalisation in process, check back in a while", "success");
      refresh();
      routerHistory.push(reachRoute);
      onClose();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const appropriateText = "Are you sure you are ready to finalise this cycle? Any pending invitations will be removed for outstanding participants. Existing results will be shared with reviewees and their managers. If this cycle requires manager to share feedback, results will not be shared automatically as you finalise the cycle.";
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Finalise this cycle?" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: appropriateText }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Note: Finalisation can take some time. Please wait or check back later." }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "button",
        onClick: handleFinalise,
        sizeVariant: "medium",
        colorVariant: "primary",
        name: "Confirm",
        loading,
        fullWidth: true
      }
    ) })
  ] });
};
