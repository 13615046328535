"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/system";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import TranslationsImportCsvTemplate from "@v2/feature/entity-import/wizard/template-samples/zelt-translations-import-template.csv";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
const TRANSLATIONS_IMPORT_CSV_ENDPOINT = "/apiv2/companies/translations/import/csv";
export const CompanyTranslationsCsvUploadDrawer = ({
  isOpen,
  setIsOpen,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(CompanyTranslationsCsvUploadDrawerContent, { refresh, setIsOpen }) });
};
const CompanyTranslationsCsvUploadDrawerContent = ({
  refresh,
  setIsOpen
}) => {
  const { polyglot } = usePolyglot();
  const [uploadResponse, setUploadResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const handleUpload = (result) => __async(void 0, null, function* () {
    setLoading(true);
    try {
      setUploadResponse(result);
      yield refresh();
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    }
    setLoading(false);
  });
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { mb: spacing.m40 }, children: "Translations CSV Upload" }),
    !uploadResponse && !loading && /* @__PURE__ */ jsxs(Box, { width: "100%", children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey }, children: [
        "Please use",
        " ",
        /* @__PURE__ */ jsx("a", { href: TranslationsImportCsvTemplate, rel: "noreferrer", style: { color: themeColors.DarkGrey }, children: "this template" }),
        " ",
        "for your CSV."
      ] }),
      /* @__PURE__ */ jsx(
        UploadInput,
        {
          onChange: (response) => __async(void 0, null, function* () {
            return yield handleUpload(response);
          }),
          endpoint: TRANSLATIONS_IMPORT_CSV_ENDPOINT
        }
      )
    ] }),
    uploadResponse && !uploadResponse.imported && uploadResponse.invalidEntries > 0 && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
      "There have been found ",
      uploadResponse.invalidEntries,
      " invalid entries. Please adjust your csv and try again"
    ] }),
    (uploadResponse == null ? void 0 : uploadResponse.imported) && uploadResponse.importedCount >= 0 && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
      uploadResponse.importedCount,
      " entr",
      uploadResponse.importedCount === 1 ? "y has" : "ies have",
      " been successfully imported. You can close the drawer now."
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxSx), { mt: spacing.m40, display: "flex", gap: spacing.g10 }), children: [
      (uploadResponse == null ? void 0 : uploadResponse.imported) && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "large",
          colorVariant: "secondary",
          onClick: () => setIsOpen(false),
          type: "button",
          fullWidth: true,
          children: polyglot.t("General.close")
        }
      ),
      uploadResponse && !(uploadResponse == null ? void 0 : uploadResponse.imported) && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "large",
          colorVariant: "secondary",
          onClick: () => setUploadResponse(void 0),
          type: "button",
          fullWidth: true,
          children: "Try again"
        }
      )
    ] })
  ] });
};
