"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { primarySmallBtn } from "@v2/styles/buttons.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { themeColors } from "@/v2/styles/colors.styles";
export const DeviceActivationStepCharger = ({ goToNextStep }) => {
  return /* @__PURE__ */ jsx(Box, { sx: { height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "400px", gap: spacing.gap20 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Use original charger only" }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Please make sure to only charge your device with the original power supply. Any third party charges can decrease the life of the battery." }),
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Button, { sx: primarySmallBtn, onClick: goToNextStep, children: "Continue" }) })
  ] }) });
};
