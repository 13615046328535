"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { StyledFormHelperText } from "@/v2/styles/form-helper-text.styles";
export const CommaSeparatedTextfieldComponent = ({
  fieldName,
  label = "",
  fieldValue,
  setCommaSeparatedValue,
  errorText,
  helperText
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label,
        name: fieldName,
        defaultValue: fieldValue,
        value: fieldValue,
        onChange: (e) => {
          setCommaSeparatedValue(fieldName, e.target.value);
        },
        clearText: () => setCommaSeparatedValue(fieldName, "")
      }
    ),
    errorText && fieldValue ? /* @__PURE__ */ jsx(StyledFormHelperText, { error: true, children: errorText }) : /* @__PURE__ */ jsx(StyledFormHelperText, { children: helperText })
  ] });
};
