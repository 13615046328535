"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import dayjs from "dayjs";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserProfileActionAPI } from "@/v2/feature/user/features/user-profile-action/user-profile-action.api";
export const UserProfileDeactivateModal = ({
  isOpen,
  setIsOpen,
  userId,
  loading,
  deactivationBlocked,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [tempDeactivationDate, setTempDeactivationDate] = useState(null);
  const triggerDeactivate = () => __async(void 0, null, function* () {
    setIsDeactivating(true);
    if (tempDeactivationDate)
      yield UserProfileActionAPI.deactivateUser(userId, { deactivationDate: tempDeactivationDate });
    showMessage(polyglot.t("UserProfileDeactivateModal.successMessages.deactivate"), "success");
    yield refresh();
    setIsOpen(false);
    setIsDeactivating(false);
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserProfileDeactivateModal.deactivate") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserProfileDeactivateModal.subtitle") }),
    loading ? /* @__PURE__ */ jsx(SkeletonLoader, { variant: "rectangular", width: "90%", height: "20vh", sx: { borderRadius: "10px", mx: "auto" } }) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      deactivationBlocked && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserProfileDeactivateModal.confirmDeactivation") }),
      /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          label: polyglot.t("UserProfileDeactivateModal.deactivationDate"),
          name: "deactivationDate",
          value: tempDeactivationDate ? tempDeactivationDate : "",
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              setTempDeactivationDate(value);
            }
          },
          error: void 0,
          helperText: void 0
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), children: polyglot.t("General.cancel") }),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            disabled: !tempDeactivationDate || isDeactivating,
            name: isDeactivating ? polyglot.t("UserProfileDeactivateModal.deactivating") : polyglot.t("UserProfileDeactivateModal.deactivate"),
            onClick: triggerDeactivate,
            loading: isDeactivating,
            fullWidth: true,
            sizeVariant: "medium",
            colorVariant: "primary"
          }
        )
      ] })
    ] })
  ] }) });
};
