"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { getCountryNameForCode } from "@/v2/feature/payments/payments.util";
import { CardStructure } from "@/v2/feature/user/features/user-profile/details/components/card-field-structure.component";
import { useTaxRemoteTable } from "@/v2/feature/user/features/user-profile/details/components/tax-remote-table.component";
import { useTaxUKTable } from "@/v2/feature/user/features/user-profile/details/components/tax-uk-table.component";
import { UserProfileTaxDetailsRemote } from "@/v2/feature/user/features/user-profile/details/components/user-profile-tax-details-remote.component";
import { UserProfileTaxDetailsUK } from "@/v2/feature/user/features/user-profile/details/components/user-profile-tax-details-uk.component";
import { isSameCountryCode, isUKCountryCode } from "@/v2/infrastructure/country/country.util";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const TaxCardStructure = ({
  addAction,
  addButtonTitle,
  cardScope,
  drawerDetails,
  editAction,
  missingInfoIcon,
  onEditRowClick,
  payroll,
  payrollCountry,
  contractCountry,
  showDetailsState: [showDetails, setShowDetails],
  userId
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [countryCode, setCountryCode] = useState(((_a = payroll == null ? void 0 : payroll.effectiveRecord) == null ? void 0 : _a.countryCode) || "GB");
  const ukTable = useTaxUKTable({
    payroll,
    userId,
    onEditRowClick
  });
  const nonUKTable = useTaxRemoteTable({
    payroll,
    userId,
    onEditRowClick,
    countryCode
  });
  const effectiveRecord = payroll == null ? void 0 : payroll.effectiveRecord;
  const [tableRowData, tableColumnData] = isUKCountryCode(countryCode) ? ukTable : nonUKTable;
  const tableSelect = useMemo(() => {
    const countryCodes = new Set(payroll == null ? void 0 : payroll.entries.map((e) => e.countryCode));
    if (countryCodes.size <= 1) return void 0;
    return /* @__PURE__ */ jsx(
      SelectComponent,
      {
        value: countryCode,
        onChange: (e) => setCountryCode(e.target.value),
        name: "payroll-select",
        label: polyglot.t("TaxCardStructure.jurisdiction"),
        options: [...countryCodes].reduce((res, code) => {
          const label = getCountryNameForCode(code);
          if (label) res.push({ label, value: code });
          return res;
        }, []).sort((a, b) => a.label.localeCompare(b.label)),
        sx: { width: "fit-content", minWidth: "250px", ml: "40px", mb: spacing.mb20 }
      }
    );
  }, [polyglot, payroll == null ? void 0 : payroll.entries, countryCode]);
  const payrollMatchesContractCountry = isSameCountryCode(payrollCountry, contractCountry);
  return /* @__PURE__ */ jsx(
    CardStructure,
    {
      cardTitle: polyglot.t("TaxCardStructure.title"),
      cardScope,
      showHistoryButton: true,
      showDetails,
      setShowDetails,
      userId,
      showMissingInfo: !effectiveRecord,
      addButtonTitle,
      addAction,
      cardFieldDetails: /* @__PURE__ */ jsxs(Fragment, { children: [
        !payrollMatchesContractCountry && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb15 }), children: polyglot.t("TaxCardStructure.currentNotApplicable") }),
        payrollMatchesContractCountry && /* @__PURE__ */ jsxs(Fragment, { children: [
          effectiveRecord && effectiveRecord.payrollValues && /* @__PURE__ */ jsx(
            UserProfileTaxDetailsUK,
            {
              payrollId: effectiveRecord.payrollId,
              payrollValues: effectiveRecord.payrollValues
            }
          ),
          effectiveRecord && effectiveRecord.remotePayrollValues && /* @__PURE__ */ jsx(
            UserProfileTaxDetailsRemote,
            {
              payrollId: effectiveRecord == null ? void 0 : effectiveRecord.payrollId,
              remotePayrollValues: effectiveRecord.remotePayrollValues,
              countryCode: effectiveRecord.countryCode
            }
          )
        ] })
      ] }),
      drawerDetails,
      editAction: payrollMatchesContractCountry ? editAction : void 0,
      tableSelect,
      tableRowData: tableRowData != null ? tableRowData : [],
      tableColumn: tableColumnData != null ? tableColumnData : [],
      missingInfoIcon
    }
  );
};
