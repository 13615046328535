"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { PayrunPensionStates } from "@v2/feature/benefits/subfeature/pension/pension.interface";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import {
  PayrunProcessStepStates,
  PensionContributionStates,
  PensionContributionStatuses
} from "@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface";
import { PayrollLocalApi } from "@v2/feature/payroll/payroll-local.api";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { CSVLink } from "react-csv";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PensionProviders } from "@/lib/pensions";
import { PENSION_SETTINGS_ROUTE } from "@/lib/routes";
import { PayrunProcessingItem } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component";
import { PensionConfirmDoneDrawer } from "@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/pension/pension-confirm-done-drawer.component";
const RETRY_COUNTER = 5;
export const PensionContributions = ({
  payRun,
  pensionSchemeId,
  providerName,
  displayName,
  isPensionSchemeSetUp,
  externalProviders,
  pensionContributionsState,
  setPensionContributionsState,
  sx
}) => {
  const [accordionState, setAccordionState] = useState(PayrunProcessStepStates.pending);
  const [isPensionConnected, setIsPensionConnected] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [contributionStatus, setContributionStatus] = useState(void 0);
  const [openConfirmMarkAsSent, setOpenConfirmMarkAsSent] = useState(false);
  const csvRef = useRef();
  const [csvFile, setCSVFile] = useState({
    name: "default.csv",
    data: []
  });
  const [showMessage] = useMessage();
  const fetchPensionSchemeContributions = useCallback(
    (payrunId, count, poll2) => __async(void 0, null, function* () {
      const contributionStatus2 = yield PayrollLocalApi.getPensionContributionStatusForPayrun(payrunId, pensionSchemeId);
      setContributionStatus(contributionStatus2);
      if (contributionStatus2.status === PensionContributionStatuses.Unknown) {
        return;
      }
      if (contributionStatus2.status === PensionContributionStatuses.Sent) {
        contributionStatus2.statusMessage !== "Manually marked as Sent" ? setPensionContributionsState((prev) => __spreadProps(__spreadValues({}, prev), {
          [pensionSchemeId]: PensionContributionStates.submitted
        })) : setPensionContributionsState((prev) => __spreadProps(__spreadValues({}, prev), {
          [pensionSchemeId]: PensionContributionStates.markedAsSent
        }));
        setAccordionState(PayrunProcessStepStates.success);
        return;
      }
      if ([PensionContributionStatuses.Queued, PensionContributionStatuses.Processing].includes(
        contributionStatus2.status
      ) && count < RETRY_COUNTER) {
        poll2(count + 1);
        return;
      }
      if ([PensionContributionStatuses.Queued, PensionContributionStatuses.Processing].includes(
        contributionStatus2.status
      ) && count >= RETRY_COUNTER) {
        setAccordionState(PayrunProcessStepStates.warning);
        return;
      }
      if (contributionStatus2.status === PensionContributionStatuses.Failed) {
        setContributionStatus(contributionStatus2);
        setAccordionState(PayrunProcessStepStates.failure);
        return;
      }
    }),
    [pensionSchemeId, setPensionContributionsState]
  );
  const poll = useCallback(
    (count) => {
      setTimeout(() => fetchPensionSchemeContributions(payRun.id, count, poll), count === 0 ? 0 : 3e3);
    },
    [fetchPensionSchemeContributions, payRun]
  );
  useEffect(() => {
    const handleMissingPensionSchemeId = () => {
      setContributionStatus({
        title: "Payrun pensionSchemeId is missing.",
        status: PensionContributionStatuses.Unknown,
        statusMessage: "One of the reason the pensionSchemeId is missing is that the pension scheme used for this payrun was deleted and can't be found anymore.",
        errorsDetails: []
      });
      setAccordionState(PayrunProcessStepStates.warning);
    };
    (() => __async(void 0, null, function* () {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        if (isPensionSchemeSetUp === null || externalProviders === null) {
          return;
        }
        if (!isPensionSchemeSetUp) {
          setAccordionState(PayrunProcessStepStates.warning);
          return;
        }
        if (!pensionSchemeId) {
          handleMissingPensionSchemeId();
          return;
        }
        const payrunPensionRecord = yield PensionAPI.getPayrunPensionRecordForPensionScheme(payRun.id, pensionSchemeId);
        if ([PayrunPensionStates.submitted, PayrunPensionStates.markedAsSent].includes(payrunPensionRecord.state)) {
          setContributionStatus({
            title: payrunPensionRecord.title,
            status: payrunPensionRecord.status,
            statusMessage: payrunPensionRecord.statusMessage,
            errorsDetails: []
          });
          payrunPensionRecord.state === PayrunPensionStates.submitted ? setPensionContributionsState((prev) => __spreadProps(__spreadValues({}, prev), {
            [pensionSchemeId]: PensionContributionStates.submitted
          })) : setPensionContributionsState((prev) => __spreadProps(__spreadValues({}, prev), {
            [pensionSchemeId]: PensionContributionStates.markedAsSent
          }));
          setAccordionState(PayrunProcessStepStates.success);
          return;
        }
        const isPensionConnected2 = Boolean(
          // If there are 2 providers of the same type (eg. Nest), and only one of them is connected, staffology returns only one record for Nest which says it is connected
          // the non-connected pension will try to submit, but it will fail and ask for a retry. No mark as sent button should be shown, the company should reach to Zelt and the pension should be connected in staffology
          // In most cases the pension will already be connected so this situation should not happen.
          externalProviders.find((provider) => providerName === provider.id && provider.connected)
        );
        setIsPensionConnected(isPensionConnected2);
        if (!isPensionConnected2) {
          setAccordionState(PayrunProcessStepStates.warning);
          return;
        }
        poll(0);
      } catch (error) {
        showMessage(
          `Something went wrong. Could not get pension submission status. ${nestErrorMessage(error)}`,
          "error"
        );
        setAccordionState(PayrunProcessStepStates.failure);
      }
    }))();
  }, [
    payRun.id,
    setPensionContributionsState,
    isPensionSchemeSetUp,
    pensionSchemeId,
    poll,
    showMessage,
    externalProviders,
    providerName
  ]);
  const resubmitPensionContributions = useCallback(() => __async(void 0, null, function* () {
    try {
      yield PensionAPI.resubmitPensionSchemeContribution(
        pensionSchemeId,
        payRun.taxYear,
        payRun.payPeriod,
        payRun.period
      );
    } catch (error) {
      showMessage(`Could not re-submit pension scheme contribution. ${nestErrorMessage(error)}`, "error");
    }
  }), [payRun.payPeriod, payRun.period, payRun.taxYear, pensionSchemeId, showMessage]);
  useEffect(() => {
    if ((csvFile == null ? void 0 : csvFile.data) && csvFile.name !== "default.csv" && csvRef.current && csvRef.current.link) {
      csvRef.current.link.click();
    }
  }, [csvFile]);
  const exportPensionContributions = () => __async(void 0, null, function* () {
    const downloadCSVFile = (name, data) => {
      setCSVFile({ name, data });
    };
    try {
      setIsDownloading(true);
      const { taxYear, payPeriod, period } = payRun;
      const pensionContributions = yield PensionAPI.getPapdisFile(pensionSchemeId, taxYear, payPeriod, period);
      downloadCSVFile(`payrun-${taxYear}-Month-${period}-pension-contributions.csv`, pensionContributions.content);
    } catch (error) {
      showMessage(`Could not download contributions file. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDownloading(false);
    }
  });
  const exportCushonContributionsFile = () => __async(void 0, null, function* () {
    const downloadCSVFile = (name, data) => {
      setCSVFile({ name, data });
    };
    try {
      setIsDownloading(true);
      const { taxYear, payPeriod, period } = payRun;
      const cushonExport = yield PensionAPI.getCushonFile(pensionSchemeId, taxYear, payPeriod, period);
      downloadCSVFile(`payrun-${taxYear}-${payPeriod}-${period}-cushon.csv`, cushonExport.content);
    } catch (error) {
      showMessage(`Could not download Cushon contributions file. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsDownloading(false);
    }
  });
  const handlePensionContributionDownload = () => {
    if (providerName === PensionProviders.Cushon) {
      exportCushonContributionsFile();
    } else {
      exportPensionContributions();
    }
  };
  const isSubmitting = !!contributionStatus && [PensionContributionStatuses.Queued, PensionContributionStatuses.Processing].includes(contributionStatus.status);
  const isMarkedAsSent = pensionContributionsState[pensionSchemeId] === PensionContributionStates.markedAsSent;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      PayrunProcessingItem,
      {
        title: providerName ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap5, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Box, { children: getPensionLogoByProviderName(providerName, 20) }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { whiteSpace: "nowrap", color: themeColors.DarkGrey }), children: displayName != null ? displayName : PensionProvidersValueToLabel[providerName] })
        ] }) : pensionSchemeId ? "Pension" : "Pension | Unknown State",
        description: {
          pending: "Submitting pension contributions...",
          failure: `Pension contribution submission failed. ${(contributionStatus == null ? void 0 : contributionStatus.statusMessage) || ""} (Current status: ${(contributionStatus == null ? void 0 : contributionStatus.status) || ""})`,
          success: isMarkedAsSent ? "Pension contributions have been marked as sent." : "Pension contributions have been submitted.",
          warning: !pensionSchemeId ? "The payrun pension record is missing the id of the pension scheme used when the payrun was opened." : !isPensionSchemeSetUp ? "There is no pension provider set up. You may be reported to the Pension Regulator if you are not enrolling eligible employees into a pension scheme. All UK employers must offer a workplace pension scheme by law." : !isPensionConnected ? "Zelt is not connected to your pension provider and cannot submit pension contributions. You should download and send the contribution data to your provider to ensure pension contributions are kept up-to-date." : isSubmitting ? "Pension contributions are being submitted. For some providers it can take a few minutes." : ""
        }[accordionState],
        buttons: [
          {
            style: "primary",
            label: "Set up Pension",
            show: !isPensionSchemeSetUp && !!pensionSchemeId,
            onClick: () => window.open(PENSION_SETTINGS_ROUTE, "_blank"),
            type: "button"
          },
          {
            style: "primary",
            label: "Retry",
            show: !!isPensionSchemeSetUp && isPensionConnected && accordionState !== PayrunProcessStepStates.success,
            onClick: () => __async(void 0, null, function* () {
              if ((contributionStatus == null ? void 0 : contributionStatus.status) === PensionContributionStatuses.Failed)
                yield resubmitPensionContributions();
              setAccordionState(PayrunProcessStepStates.pending);
              yield poll(0);
            }),
            type: "button"
          },
          {
            style: "secondary",
            label: "Mark as sent",
            show: !!isPensionSchemeSetUp && !isPensionConnected && !isMarkedAsSent || accordionState === "failure",
            onClick: () => setOpenConfirmMarkAsSent(true),
            type: "button"
          },
          {
            style: "secondary",
            label: "Download",
            show: !!isPensionSchemeSetUp,
            loading: isDownloading,
            onClick: () => handlePensionContributionDownload(),
            type: "button"
          }
        ],
        success: accordionState === PayrunProcessStepStates.pending ? void 0 : accordionState === PayrunProcessStepStates.success,
        sx
      },
      `pension-${providerName}`
    ),
    /* @__PURE__ */ jsx(
      CSVLink,
      {
        ref: csvRef,
        filename: csvFile.name,
        data: Array.isArray(csvFile.data) || typeof csvFile.data === "string" ? csvFile.data : []
      }
    ),
    /* @__PURE__ */ jsx(
      PensionConfirmDoneDrawer,
      {
        open: openConfirmMarkAsSent,
        onClose: () => setOpenConfirmMarkAsSent(false),
        markAsSent: () => __async(void 0, null, function* () {
          const updatedPensionStatus = yield PayrollLocalApi.markPensionContributionAsSent(payRun.id, pensionSchemeId);
          setContributionStatus(updatedPensionStatus);
          setPensionContributionsState((prev) => __spreadProps(__spreadValues({}, prev), {
            [pensionSchemeId]: PensionContributionStates.markedAsSent
          }));
          setAccordionState(PayrunProcessStepStates.success);
        })
      }
    )
  ] });
};
