"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { capitalize, Typography } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import moment from "moment";
import { InvoiceStatus } from "@/models/invoice.model";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { DEFAULT_SIZE } from "@/v2/styles/table.styles";
const formatDate = (date, format = "DD/MM/YYYY") => moment(date).format(format);
export class SuperAdminBillingInvoiceTableHelper {
  static getCompanyNameColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { company }
        }
      }) => /* @__PURE__ */ jsx("div", { children: company.name ? company.name : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size
    };
  }
  static getCompanyIdColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { companyId }
        }
      }) => /* @__PURE__ */ jsx("div", { children: companyId ? companyId : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size
    };
  }
  static getPeriodStartDateColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { billingPeriodStartDate }
        }
      }) => /* @__PURE__ */ jsx("div", { children: billingPeriodStartDate ? formatDate(new Date(billingPeriodStartDate)) : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size
    };
  }
  static getPeriodEndDateColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { billingPeriodEndDate }
        }
      }) => /* @__PURE__ */ jsx("div", { children: billingPeriodEndDate ? formatDate(new Date(billingPeriodEndDate)) : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size
    };
  }
  static getInvoiceStatusColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { status, billingFailedPayment }
        }
      }) => status ? /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: status === InvoiceStatus.Paid ? themeColors.black : themeColors.darkRed
          }),
          children: (billingFailedPayment == null ? void 0 : billingFailedPayment.id) ? `${capitalize(status)} - Failed` : capitalize(status)
        }
      ) : /* @__PURE__ */ jsx(EmptyCell, {}),
      size
    };
  }
  static getInvoiceDateColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { createdAt }
        }
      }) => /* @__PURE__ */ jsx("div", { children: createdAt ? formatDate(new Date(createdAt)) : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size
    };
  }
  static getNoOfSubscriptionColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { breakdown }
        }
      }) => /* @__PURE__ */ jsx("div", { children: breakdown.breakdown.length ? breakdown.breakdown.length : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size
    };
  }
  static getTotalAmountColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { amountDuePostTax }
        }
      }) => /* @__PURE__ */ jsx("div", { children: Number(amountDuePostTax) >= 0 ? Number(amountDuePostTax).toFixed(2) : /* @__PURE__ */ jsx(EmptyCell, {}) }),
      size
    };
  }
  static getInvoiceIdColumn(columnDefinition) {
    const { id, header, size = DEFAULT_SIZE } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { id: id2 }
        }
      }) => /* @__PURE__ */ jsx("div", { children: id2 }),
      size
    };
  }
}
