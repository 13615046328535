"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { addMonths } from "date-fns";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DeviceAPI } from "@/v2/feature/device/device.api";
import { DevicePossessionType, ReturnDeviceReason } from "@/v2/feature/device/device.interface";
import { UserAddressAPI } from "@/v2/feature/user/features/user-forms/user-address/user-address.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatAddress } from "@/v2/util/user-data.util";
const TabFilter = [
  { name: "Report damage", value: ReturnDeviceReason.Damage },
  { name: "End contract", value: ReturnDeviceReason.CancelContract }
];
export const OrderActionsDrawer = ({ isOpen, onClose, devicePossession, setIsOpen }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(OrderActionsDrawerContent, { devicePossession, closePage: onClose }) });
};
const OrderActionsDrawerContent = ({
  devicePossession,
  closePage
}) => {
  var _a, _b;
  const [showMessage] = useMessage();
  const [filterValue, setFilterValue] = useState(ReturnDeviceReason.Damage);
  const [notes, setNotes] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const contractEndDate = (devicePossession == null ? void 0 : devicePossession.startDate) && ((_a = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _a.contractLength) ? addMonths(new Date(devicePossession == null ? void 0 : devicePossession.startDate), (_b = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _b.contractLength) : /* @__PURE__ */ new Date();
  const [state, dispatch] = useContext(GlobalContext);
  const initiateDeviceReturn = () => __async(void 0, null, function* () {
    let isReturnInitiated = false;
    try {
      yield DeviceAPI.initiateDeviceReturn(
        devicePossession.id,
        filterValue,
        senderAddress,
        "Zelt HQ",
        // Return to stock
        null,
        notes
      );
      if (devicePossession.possessionType === DevicePossessionType.ZeltStorage && filterValue === ReturnDeviceReason.CancelContract) {
        showMessage("Contract cancellation successfully initiated.", "info");
      } else {
        showMessage("Successfully requested a return", "success");
      }
      isReturnInitiated = true;
      const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices }
      });
      closePage();
    } catch (error) {
      if (isReturnInitiated) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
      } else {
        showMessage(`Could not initiate return device flow. ${nestErrorMessage(error)}`, "error");
      }
    }
  });
  const fetchUserAddress = useCallback(
    (assignedUserId) => __async(void 0, null, function* () {
      try {
        const userAddress = yield UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
          const effectiveAddress = userAddress.effectiveRecord;
          setSenderAddress(formatAddress(effectiveAddress));
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
    }),
    [showMessage]
  );
  useEffect(() => {
    if (devicePossession.possessionType === DevicePossessionType.User) fetchUserAddress(devicePossession.possessionId);
  }, [fetchUserAddress, devicePossession]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Order actions" }),
    /* @__PURE__ */ jsx(
      TabFilterButtons,
      {
        filters: TabFilter,
        setFilterValue,
        filterValue,
        onFilterChange: ({ filterValue: filterValue2 }) => {
          setFilterValue(filterValue2);
        }
      }
    ),
    filterValue === ReturnDeviceReason.Damage && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        "If the device has been damaged please provide details below and we will contact you to arrange a replacement. Note that additional charges may apply.",
        " "
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m20 }, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          multiline: true,
          name: "notes",
          label: "",
          placeholder: "Please provide details",
          value: notes,
          type: "text",
          onChange: (e) => setNotes(e.target.value),
          endAdornment: "none"
        }
      ) })
    ] }),
    filterValue === ReturnDeviceReason.CancelContract && /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
      "Your minimum period ends on ",
      getDateString(contractEndDate),
      ". Note that Cancellation Charge may apply, as defined in the",
      " ",
      /* @__PURE__ */ jsx(
        "a",
        {
          style: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.DarkGrey,
            textTransform: "inherit",
            textDecorationColor: themeColors.DarkGrey
          }),
          target: "_blank",
          rel: "noreferrer",
          href: "https://zelt.app/legal/daas-terms/",
          children: "Devices Terms."
        }
      )
    ] }),
    [ReturnDeviceReason.Damage, ReturnDeviceReason.CancelContract].includes(filterValue) && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => __async(void 0, null, function* () {
          return initiateDeviceReturn();
        }),
        children: filterValue === ReturnDeviceReason.Damage ? "Submit" : "End contract and arrange return"
      }
    ) })
  ] });
};
