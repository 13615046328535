"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Stack } from "@mui/material";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { Typography } from "@v2/components/typography/typography.component";
import { PayrollLogoZelt } from "@v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component";
import { themeColors } from "@v2/styles/colors.styles";
import { radius } from "@v2/styles/radius.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ReactComponent as EyeView } from "@/images/growth/EyeView.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const TemplateBlock = ({ template, clickAction, viewAction, active }) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      role: "button",
      sx: {
        height: "100%",
        width: "100%",
        minHeight: "120px",
        boxSizing: "border-box",
        background: active && active === template.id ? themeColors.Background : themeColors.white,
        border: `1px solid ${themeColors.GreyPress}`,
        borderRadius: radius.br12,
        padding: spacing.p12,
        "&:hover": {
          cursor: "pointer",
          background: themeColors.GreyHover
        }
      },
      onClick: clickAction,
      children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: template.name }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
          /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
            template.questionIds ? template.questionIds.length : 0,
            " questions"
          ] }),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
            template.companyId === null ? /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.g8 }, children: [
              /* @__PURE__ */ jsx(PayrollLogoZelt, { height: "15px", width: "15px", padding: "2.5px" }),
              /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "paragraph",
                  color: "DarkGrey",
                  sx: {
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  },
                  children: "Zelt"
                }
              )
            ] }) : template.createdBy ? /* @__PURE__ */ jsx(UserCell, { userId: template.createdBy }) : /* @__PURE__ */ jsx(EmptyCell, {}),
            viewAction && /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: viewAction, children: /* @__PURE__ */ jsx(EyeView, __spreadValues({}, iconSize)) })
          ] })
        ] })
      ] })
    }
  );
};
