"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { keyBy } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import { ContractEndpoints } from "@/api-client/contract.api";
import useMessage from "@/hooks/notification.hook";
import { useUserIdParam } from "@/hooks/userid-param.hook";
import { nestErrorMessage } from "@/lib/errors";
import { USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { sortString } from "@/v2/components/table/table-sorting.util";
import { Typography } from "@/v2/components/typography/typography.component";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { NewDocumentPreview } from "@/v2/feature/document-new/components/new-document-preview.component";
import { getBelongsToByMemberRule, getImageByFileName } from "@/v2/feature/document-new/document-new.util";
import {
  DocumentFileOperation
} from "@/v2/feature/document-new/interfaces/document-new.interface";
import { DisplayDocumentNewStatus } from "@/v2/feature/document-new/utils/new-document-status.util";
import { stringToPreviewFile } from "@/v2/feature/documents/documents.util";
import { CachedUsersProvider } from "@/v2/feature/user/context/cached-users.context";
import { CloseButton } from "@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component";
import { OnboardingContracts } from "@/v2/feature/user-onboarding/onboarding-by-user/components/onboarding-contracts/onboarding-contracts.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const OnboardingContractsPage = () => {
  var _a, _b, _c, _d;
  const { data: userContracts, isLoading: userContractsLoading } = useApiClient(ContractEndpoints.getUserContracts(), {
    suspense: false
  });
  const { polyglot } = usePolyglot();
  const userId = useUserIdParam();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [contractToSign, setContractToSign] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [error, setError] = useState(null);
  const [docData, setDocData] = useState(null);
  const fileURLRef = useRef(null);
  const documentTypesLookup = useMemo(() => {
    var _a2;
    return keyBy((_a2 = userContracts == null ? void 0 : userContracts.documentTypes) != null ? _a2 : [], "id");
  }, [userContracts]);
  const returnToOnboardingSummary = useCallback(() => {
    routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
  }, [routerHistory, userId]);
  const handleContractSignAction = useCallback(
    (doc) => {
      if (doc && doc.contract) {
        setContractToSign(doc.contract);
        setOpenMissingFieldContractModal(true);
      }
    },
    [setContractToSign, setOpenMissingFieldContractModal]
  );
  const handlePreviewClick = useCallback(
    (document) => __async(void 0, null, function* () {
      try {
        if (!document) return;
        const response = yield DocumentNewAPI.fetchFileDataById(document.id, DocumentFileOperation.preview);
        const docDataResponse = yield stringToPreviewFile(
          response.data.data,
          response.contentType,
          response.extension,
          document.name
        );
        setDocData(docDataResponse);
        if (docDataResponse && docDataResponse.uri) fileURLRef.current = docDataResponse.uri;
        setIsPreviewOpen(true);
        setError(null);
      } catch (e) {
        console.error("::URL Download error", e);
        showMessage(polyglot.t("DocumentTable.errorMessages.preview", { errorMessage: nestErrorMessage(e) }), "error");
      }
    }),
    [polyglot, showMessage]
  );
  const columnData = useMemo(() => {
    return [
      {
        header: () => polyglot.t("DocumentTable.name"),
        accessorFn: (row) => row.name,
        id: "name",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              gap: spacing.s1,
              boxSizing: "border-box"
            },
            children: [
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center" }, children: getImageByFileName(original) }),
              original.name ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.name }) : /* @__PURE__ */ jsx(EmptyCell, {})
            ]
          }
        )
      },
      {
        header: () => polyglot.t("DocumentTable.type"),
        accessorFn: (row) => row,
        id: "typeId",
        enableSorting: false,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => documentTypesLookup && original.typeId && documentTypesLookup[original.typeId] ? /* @__PURE__ */ jsx("div", { children: documentTypesLookup[original.typeId].label }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => polyglot.t("DocumentTable.belongsTo"),
        accessorFn: (row) => row,
        id: "userId",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx("div", { children: original ? getBelongsToByMemberRule(original, "paragraph", void 0) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Status",
        accessorFn: (row) => row,
        id: "status",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original ? /* @__PURE__ */ jsx(DisplayDocumentNewStatus, { document: original }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "",
        id: "action",
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return original.showSign ? /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => handleContractSignAction(original),
              sizeVariant: "small",
              colorVariant: "primary",
              fullWidth: true,
              children: polyglot.t("OnboardingContracts.sign")
            }
          ) : original.showPreview ? /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => __async(void 0, null, function* () {
                return yield handlePreviewClick(original);
              }),
              sizeVariant: "small",
              colorVariant: "secondary",
              fullWidth: true,
              children: polyglot.t("OnboardingContracts.view")
            }
          ) : void 0;
        }
      }
    ];
  }, [polyglot, documentTypesLookup, handleContractSignAction, handlePreviewClick]);
  const closePreview = useCallback(() => {
    setIsPreviewOpen(false);
    if (fileURLRef.current) {
      URL.revokeObjectURL(fileURLRef.current);
      fileURLRef.current = null;
    }
  }, []);
  return /* @__PURE__ */ jsxs(CachedUsersProvider, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
      /* @__PURE__ */ jsxs(Stack, { sx: { flex: 0, mx: "auto", width: "800px", maxWidth: "90vw", mt: spacing.mt40 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("OnboardingContracts.signContracts") }),
        /* @__PURE__ */ jsx(CloseButton, { onClick: () => returnToOnboardingSummary() })
      ] }),
      /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, overflowY: "auto", mt: spacing.mt30 }, children: /* @__PURE__ */ jsxs(Stack, { sx: { mx: "auto", width: "800px", maxWidth: "90vw" }, children: [
        !(userContracts == null ? void 0 : userContracts.contracts) && /* @__PURE__ */ jsx(LoadingSpinner, {}),
        !!((_a = userContracts == null ? void 0 : userContracts.contracts) == null ? void 0 : _a.length) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("OnboardingContracts.signContractsBellow") }),
          /* @__PURE__ */ jsx(
            BasicTable,
            {
              columnData,
              rowData: (_b = userContracts == null ? void 0 : userContracts.contracts) != null ? _b : [],
              loading: userContractsLoading
            }
          )
        ] }),
        ((_c = userContracts == null ? void 0 : userContracts.contracts) == null ? void 0 : _c.length) === 0 && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("OnboardingContracts.noContractsToSign") })
      ] }) })
    ] }),
    /* @__PURE__ */ jsx(
      NewDocumentPreview,
      {
        docData,
        onClose: closePreview,
        isOpen: isPreviewOpen,
        error,
        title: (_d = docData == null ? void 0 : docData.fileName) != null ? _d : "Document"
      }
    ),
    contractToSign && /* @__PURE__ */ jsx(
      OnboardingContracts,
      {
        selectedContract: contractToSign,
        setOpenModal: setOpenMissingFieldContractModal,
        openModal: openMissingFieldContractModal
      }
    )
  ] });
};
