"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { TemplateAPI } from "@/api-client/templates.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { ClearIconButton } from "@/v2/components/theme-components/clear-icon-button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { StyledTextfield } from "@/v2/styles/textfield.styles";
export const RenameTemplateModal = ({ setOpen, open, template, onUpdate, documentTypes }) => {
  const { polyglot } = usePolyglot();
  const [templateName, setTemplateName] = useState(template.name);
  const [showMessage] = useMessage();
  const [isUpdatingTemplate, setIsUpdatingTemplate] = useState(false);
  const [documentType, setDocumentType] = useState(template.type || "");
  const patchTemplate = useCallback(
    (params) => __async(void 0, null, function* () {
      try {
        setIsUpdatingTemplate(true);
        yield TemplateAPI.patchTemplate(params);
        setIsUpdatingTemplate(false);
        if (onUpdate) yield onUpdate();
        setOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("RenameTemplateModal.errorMessages.update", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setIsUpdatingTemplate(true);
      }
    }),
    [showMessage, setOpen, onUpdate, polyglot]
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("RenameTemplateModal.rename") }),
    /* @__PURE__ */ jsx(
      StyledTextfield,
      {
        label: "Name",
        size: "small",
        required: true,
        value: templateName,
        onChange: ({ target: { value } }) => setTemplateName(value),
        fullWidth: true,
        variant: "standard",
        InputLabelProps: { shrink: true },
        InputProps: {
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: templateName && (templateName == null ? void 0 : templateName.length) > 0 && /* @__PURE__ */ jsx(ClearIconButton, { onClick: () => setTemplateName("") }) })
        }
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "updateTemplateType",
        label: polyglot.t("RenameTemplateModal.updateTemplateType"),
        value: documentType,
        options: documentTypes.map((d) => ({ label: d.label, value: d.value })),
        onChange: (e) => setDocumentType(e.target.value),
        required: true
      },
      "updateTemplateType"
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true,
        name: polyglot.t("General.save"),
        loading: isUpdatingTemplate,
        disabled: !templateName,
        onClick: () => patchTemplate({
          id: template.id,
          name: templateName,
          type: documentType || null
        })
      }
    ) })
  ] }) });
};
