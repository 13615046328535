"use strict";
import { jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ListView } from "@/v2/components/list-view/list-view.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const TimesheetsWeeklyListSection = ({
  pagination,
  totalPages,
  onPageChange,
  loading,
  weekList,
  handleWeekClick,
  selectedWeekAndYear,
  totalItems
}) => {
  return /* @__PURE__ */ jsx(Box, { sx: spacing.pt20, children: /* @__PURE__ */ jsx(
    ListView,
    {
      list: weekList,
      pagination: pagination && onPageChange ? {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        total: totalPages != null ? totalPages : 1,
        totalItems,
        onPageChange: (pageIndex, pageSize) => onPageChange(pageIndex, pageSize)
      } : void 0,
      clickAction: (weekNo) => handleWeekClick(weekNo),
      loading,
      hideCicularLoader: true,
      selected: selectedWeekAndYear,
      height: "calc(100vh - 85px)"
    }
  ) });
};
