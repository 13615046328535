"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { UserCell } from "@v2/components/table/user-cell.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { InsuranceAPI } from "@v2/feature/benefits/subfeature/insurance/insurance.api";
import { calculateAnnualValueOfTheBenefitForCurrentTaxYear } from "@v2/feature/benefits/subfeature/insurance/insurance.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { fieldSx, titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const InsuranceEditUserPolicyDrawer = ({
  isOpen,
  setIsOpen,
  userInsurance,
  insurancePolicy,
  refresh,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        InsuranceEditUserPolicyDrawerContent,
        {
          userInsurance,
          insurancePolicy,
          refresh,
          onClose
        }
      )
    }
  ) });
};
const InsuranceEditUserPolicyDrawerContent = ({
  userInsurance,
  insurancePolicy,
  refresh,
  onClose
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      startDate: userInsurance.startDate ? userInsurance.startDate : new LocalDate().toDateString(),
      monthlyEmployeePremium: (_a = userInsurance.monthlyPremium) != null ? _a : 0,
      monthlyEmployeeContribution: (_b = userInsurance.monthlyContribution) != null ? _b : 0
    },
    validationSchema: yup.object({
      startDate: yup.string().test(dateFieldTest).required(polyglot.t("ValidationMessages.requiredField")),
      monthlyEmployeePremium: yup.number().required(polyglot.t("ValidationMessages.requiredField")).moreThan(0, polyglot.t("ValidationMessages.greaterThan", { num: 0 })),
      monthlyEmployeeContribution: yup.number().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => editToUserInsurancePolicy(userInsurance.userId, values, insurancePolicy.id)
  });
  const editToUserInsurancePolicy = (userId, values, insurancePolicyId) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const { startDate, monthlyEmployeePremium, monthlyEmployeeContribution } = values;
      const data = {
        startDate,
        monthlyEmployeePremium: Number(monthlyEmployeePremium),
        monthlyEmployeeContribution: Number(monthlyEmployeeContribution)
      };
      yield InsuranceAPI.updateUserPolicyPremiums(userId, insurancePolicyId, data);
      yield refresh();
      onClose();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("BenefitModule.editPremiums") }),
    /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb30 }, children: /* @__PURE__ */ jsx(UserCell, { userId: userInsurance.userId, nameSx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }) }) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "startDate",
        label: polyglot.t("BenefitModule.policyStartDate"),
        inputFormat: "DD/MM/YYYY",
        value: (_c = formik.values.startDate) != null ? _c : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("startDate", value);
          }
        },
        error: !!formik.errors.startDate && Boolean(formik.touched.startDate),
        helperText: formik.errors.startDate && Boolean(formik.touched.startDate)
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "monthlyEmployeePremium",
        label: polyglot.t("BenefitModule.employeeMonthlyPremium"),
        value: formik.values.monthlyEmployeePremium,
        onChange: formik.handleChange,
        error: formik.touched.monthlyEmployeePremium && Boolean(formik.errors.monthlyEmployeePremium),
        helperText: formik.touched.monthlyEmployeePremium && formik.errors.monthlyEmployeePremium
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "monthlyEmployeeContribution",
        label: polyglot.t("BenefitModule.employeeMonthlyContribution"),
        value: formik.values.monthlyEmployeeContribution,
        onChange: formik.handleChange,
        error: formik.touched.monthlyEmployeeContribution && Boolean(formik.errors.monthlyEmployeeContribution),
        helperText: formik.touched.monthlyEmployeeContribution && formik.errors.monthlyEmployeeContribution
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: fieldSx, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.grey, mb: spacing.mb5 }), children: polyglot.t("BenefitModule.annualValueOfBenefit") }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: formatMoney({
        amount: calculateAnnualValueOfTheBenefitForCurrentTaxYear(
          formik.values.startDate,
          formik.values.monthlyEmployeePremium ? Number(formik.values.monthlyEmployeePremium) : 0,
          formik.values.monthlyEmployeeContribution ? Number(formik.values.monthlyEmployeeContribution) : null,
          userInsurance.dependantsMonthlyPremium,
          userInsurance.dependantsMonthlyContribution
        )
      }) })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.update"),
        loading,
        sizeVariant: "small",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) }) });
};
