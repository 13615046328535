"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { HolidaysProratingRule } from "@v2/feature/absence/absence.interface";
import { getActualPartTimeMethodOptions, isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyPublicHolidaysEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const basicAllowanceProrationDisabled = !absencePolicy.proratingFte && !absencePolicy.proratingStartDate;
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyPublicHolidays(absencePolicy.id, values);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      publicHolidayOnTop: isUnlimited ? false : absencePolicy.publicHolidayOnTop,
      publicHolidayAutobook: absencePolicy.publicHolidayAutobook,
      holidaysProratingRule: basicAllowanceProrationDisabled ? HolidaysProratingRule.AllInPeriod : absencePolicy.holidaysProratingRule
    },
    validationSchema: yup.object({
      publicHolidayOnTop: yup.boolean().notRequired(),
      publicHolidayAutobook: yup.boolean().notRequired(),
      holidaysProratingRule: yup.string().oneOf(Object.values(HolidaysProratingRule)).required("Required field")
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceUtil.publicHolidays") }),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: polyglot.t("AbsenceUtil.autobook"),
              name: "publicHolidayAutobook",
              checked: formik.values.publicHolidayAutobook,
              onChange: formik.handleChange
            }
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", sx: { ml: spacing.m25 }, children: polyglot.t("AbsenceUtil.autobookDesc") })
        ] }),
        !isUnlimited && /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: polyglot.t("AbsenceUtil.addToAllowance"),
              name: "publicHolidayOnTop",
              checked: formik.values.publicHolidayOnTop,
              onChange: formik.handleChange
            }
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", sx: { ml: spacing.m25 }, children: polyglot.t("AbsenceUtil.addToAllowanceDesc") })
        ] }),
        !isUnlimited && formik.values.publicHolidayOnTop && /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { mt: "10px" }, children: polyglot.t("AbsenceUtil.allocation") }),
        !isUnlimited && formik.values.publicHolidayOnTop && /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
          RadioGroup,
          {
            "aria-labelledby": "public-holidays-allocation-group-label",
            name: "public-holidays-allocation-group",
            onChange: (event) => {
              formik.setFieldValue("holidaysProratingRule", event.target.value);
            },
            children: [
              /* @__PURE__ */ jsx(
                FormControlLabel,
                {
                  labelPlacement: "end",
                  value: HolidaysProratingRule.FullValueProrated,
                  checked: formik.values.holidaysProratingRule === HolidaysProratingRule.FullValueProrated,
                  disabled: basicAllowanceProrationDisabled,
                  control: /* @__PURE__ */ jsx(StyledRadio, {}),
                  label: /* @__PURE__ */ jsxs(Box, { children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "headline3", color: basicAllowanceProrationDisabled ? "Grey" : "DarkGrey", children: polyglot.t("AbsenceUtil.proRata") }),
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("AbsenceUtil.proRataDesc") })
                  ] })
                },
                "prorata"
              ),
              /* @__PURE__ */ jsx(
                FormControlLabel,
                {
                  labelPlacement: "end",
                  value: HolidaysProratingRule.AllInPeriod,
                  checked: formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriod || formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated || formik.values.holidaysProratingRule === HolidaysProratingRule.AllInCycle,
                  control: /* @__PURE__ */ jsx(StyledRadio, {}),
                  label: /* @__PURE__ */ jsxs(Box, { children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("AbsenceUtil.actual") }),
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("AbsenceUtil.actualDesc") })
                  ] }),
                  sx: { my: spacing.m20 }
                },
                "actual"
              ),
              (formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriod || formik.values.holidaysProratingRule === HolidaysProratingRule.AllInPeriodProrated || formik.values.holidaysProratingRule === HolidaysProratingRule.AllInCycle) && /* @__PURE__ */ jsx(Box, { sx: { px: spacing.p30 }, children: /* @__PURE__ */ jsx(
                SelectComponent,
                {
                  name: "holidaysProratingRule",
                  label: polyglot.t("AbsenceUtil.partTimeMethod"),
                  options: getActualPartTimeMethodOptions(basicAllowanceProrationDisabled, polyglot),
                  value: formik.values.holidaysProratingRule,
                  onChange: formik.handleChange
                }
              ) })
            ]
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true
          }
        ) })
      ] }) })
    }
  );
};
