"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { LinearProgress } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import { useEventSource } from "@/hooks/event-source-hook";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { ReviewCycleEndpoints } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ParticipantsRollingView } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/rolling/participants-rolling-view.component";
import { ParticipantsInvitePeersSettingModal } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-invite-peers-setting-modal.component";
import { ParticipantsScheduledView } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-participants/components/scheduled/participants-scheduled-view.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { useManagerTeam } from "@/v2/feature/growth/reviews/hooks/review-template-cycle-shared/use-manager-team.hook";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { CycleState, CycleType } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { eventSourceErrorHandler } from "@/v2/infrastructure/push-events/push-events.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const RCUpsertParticipantsPage = ({
  cycleId,
  reach,
  refreshProgress,
  reviewCycleProgress
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { data: reviewCycle, mutate: refreshCycle } = useApiClient(
    ReviewCycleEndpoints.getReviewCycleById(cycleId, reach),
    {
      suspense: false
    }
  );
  const {
    data: reviewParticipants,
    mutate: refreshReviewParticipants,
    isLoading: reviewParticipantsLoading
  } = useApiClient(ReviewCycleEndpoints.getReviewParticipantsByCycleId(cycleId, reach), {
    suspense: false
  });
  const [isInvitePeerOpen, setInvitePeerOpen] = useState(false);
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      yield refreshReviewParticipants == null ? void 0 : refreshReviewParticipants();
      yield refreshCycle == null ? void 0 : refreshCycle();
      yield refreshProgress();
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), "error");
    }
  }), [refreshCycle, refreshProgress, showMessage, polyglot, refreshReviewParticipants]);
  const { usersContainManagersTeam } = useManagerTeam();
  const [state] = useContext(GlobalContext);
  const companyId = state.user.company.companyId;
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const { user } = state;
  const isAdmin = canAccessScopes(user, ["reviews:all"]);
  const isManager = canAccessScopes(user, ["reviews:manager"]);
  const channelName = `review_cycle-${environmentPrefix}-company_id-${companyId}`;
  const reviewSurveyParticipantsUpdatedDataHandler = (data) => {
    if (data.message && data.companyId === companyId) {
      refresh();
      return;
    }
  };
  useEventSource((_a = ReviewCycleEndpoints.getEventSourceUrlForAddRevieweesInCycle(channelName)) == null ? void 0 : _a.url, {
    onMessage: reviewSurveyParticipantsUpdatedDataHandler,
    onError: eventSourceErrorHandler
  });
  useEventSource((_b = ReviewCycleEndpoints.getEventSourceUrlForAddReviewersInCycle(channelName)) == null ? void 0 : _b.url, {
    onMessage: reviewSurveyParticipantsUpdatedDataHandler,
    onError: eventSourceErrorHandler
  });
  const isEditable = useMemo(() => {
    var _a2;
    const isManagerTeamInvolved = usersContainManagersTeam((_a2 = reviewCycle == null ? void 0 : reviewCycle.revieweeId) != null ? _a2 : []);
    return Boolean(
      reviewCycle && (reviewCycle.state === CycleState.Draft || reviewCycle.state === CycleState.Scheduled || reviewCycle.state === CycleState.Ongoing) && Boolean(isAdmin || isManager && reviewCycle.owner === user.userId || isManager && isManagerTeamInvolved)
    );
  }, [reviewCycle, isAdmin, isManager, user, usersContainManagersTeam]);
  if (!reviewCycle) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      title: /* @__PURE__ */ jsx(TitleStatusComponent, { reviewCycle, reachType: reach }),
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      noHorizontalPadding: false,
      loading: reviewParticipantsLoading,
      children: [
        reviewCycleProgress && reviewCycle.state === CycleState.Draft && /* @__PURE__ */ jsx(
          LinearProgress,
          {
            value: reviewCycleProgress || 0,
            variant: "determinate",
            sx: {
              "& .MuiLinearProgress-bar": {
                backgroundColor: themeColors.Green
              },
              backgroundColor: themeColors.Background
            }
          }
        ),
        Boolean(reviewCycle && ((_c = reviewCycle.reviewerSelect) == null ? void 0 : _c.includes(ReviewerTypes.Peer))) && /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                hideEdit: (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Completed || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Ongoing,
                editButtonDisabled: (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Completed || (reviewCycle == null ? void 0 : reviewCycle.state) === CycleState.Ongoing,
                onEdit: () => setInvitePeerOpen(true),
                hidden: !Boolean(reviewCycle && ((_d = reviewCycle.reviewerSelect) == null ? void 0 : _d.includes(ReviewerTypes.Peer))),
                title: "Peer reviews",
                items: [
                  {
                    type: SectionItemType.Pair,
                    label: "Allow participants to invite peers",
                    value: ((_e = reviewCycle == null ? void 0 : reviewCycle.cycleSettings) == null ? void 0 : _e.allowRevieweesToInvitePeers) ? "Enabled" : "Disabled"
                  },
                  {
                    type: SectionItemType.Pair,
                    label: "Hide peer's name",
                    value: ((_f = reviewCycle == null ? void 0 : reviewCycle.visibilitySettings) == null ? void 0 : _f.hidePeerAuthor) ? "Enabled" : "Disabled"
                  }
                ]
              }
            ]
          }
        ),
        reviewCycle && reviewCycle.type === CycleType.Scheduled && /* @__PURE__ */ jsx(
          ParticipantsScheduledView,
          {
            reviewCycle,
            isEditable,
            refresh,
            reviewParticipants: reviewParticipants != null ? reviewParticipants : [],
            reviewParticipantsLoading,
            reach
          }
        ),
        reviewCycle && reviewCycle.type === CycleType.Rolling && /* @__PURE__ */ jsx(
          ParticipantsRollingView,
          {
            reviewCycle,
            isEditable,
            refresh,
            reviewParticipants: reviewParticipants != null ? reviewParticipants : [],
            reviewParticipantsLoading,
            reach
          }
        ),
        /* @__PURE__ */ jsx(
          ParticipantsInvitePeersSettingModal,
          {
            reviewCycle,
            isOpen: isInvitePeerOpen,
            setIsOpen: setInvitePeerOpen,
            onClose: () => setInvitePeerOpen(false),
            refresh
          }
        )
      ]
    }
  );
};
