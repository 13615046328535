"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  DataDomain,
  domainArray,
  ReportEntity,
  ReportTypeAttendanceSelection,
  ReportTypeDevicesSelection,
  ReportTypePeopleSelection,
  ReportTypeTimeSelection
} from "@v2/feature/reports/reports.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { borders } from "@v2/styles/borders.styles";
import { StyledRadio } from "@v2/styles/radio.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ButtonComponent } from "@/v2/components/forms/button.component";
const getTypeByDomain = (domain) => {
  if (domain === DataDomain.TimeAway) return ReportTypeTimeSelection.Requests;
  if (domain === DataDomain.Devices) return ReportTypeDevicesSelection.Devices;
  if (domain === DataDomain.Attendance) return ReportTypeAttendanceSelection.Attendance;
  return ReportTypePeopleSelection.CurrentRecords;
};
export const ReportDomain = ({
  onNext,
  setFormData,
  formData,
  companyConfig
}) => {
  const { polyglot } = usePolyglot();
  const domains = useMemo(() => {
    return domainArray(polyglot).filter(
      (d) => d.name !== DataDomain.Payroll || d.name === DataDomain.Payroll && companyConfig.inPayroll
    );
  }, [polyglot, companyConfig]);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("ReportDomain.selectData") }),
        /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({ width: "100%" }, spacing.mt20), children: /* @__PURE__ */ jsx(
          RadioGroup,
          {
            value: formData.domain,
            onChange: (event) => {
              const domain = event.target.value;
              setFormData((prev) => __spreadValues(__spreadProps(__spreadValues({}, prev), {
                domain,
                type: getTypeByDomain(domain)
              }), [DataDomain.Devices, DataDomain.Attendance, DataDomain.TimeAway].includes(domain) ? {
                entities: [
                  event.target.value === DataDomain.Devices ? ReportEntity.Devices : event.target.value === DataDomain.Attendance ? ReportEntity.Attendance : ReportEntity.Requests
                ]
              } : {}));
            },
            sx: { flexFlow: "column" },
            children: domains.map((d, idx) => /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "start",
                value: d.name,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: d.label }),
                sx: {
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: idx < domainArray(polyglot).length - 1 ? borders.background : "none"
                }
              },
              idx
            ))
          }
        ) }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "large",
            colorVariant: "primary",
            style: { marginTop: spacing.m25 },
            onClick: onNext,
            fullWidth: true,
            children: polyglot.t("General.next")
          }
        )
      ] })
    }
  );
};
