"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const QuickbooksDisconnectedPage = () => {
  return /* @__PURE__ */ jsx(AuthLayout, { title: "QuickBooks Disconnected", children: /* @__PURE__ */ jsx(Stack, { spacing: spacing.m25, sx: { mt: 1 }, children: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
    "Your QuickBooks integration has been disconnected.",
    /* @__PURE__ */ jsx("br", {}),
    "If you\u2019d like to reconnect Zelt and your QuickBooks account, integrate easily via the QuickBooks app in the",
    /* @__PURE__ */ jsx("a", { href: "https://go.zelt.app/apps/quickbooks/about", children: " Zelt App store" }),
    "."
  ] }) }) });
};
