"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { GlobalContext } from "@/GlobalState";
import { SSOAzureAdUpdateForm } from "@/v2/feature/security/security-settings/components/sso-azure-ad-update.component";
import { SSOGWUpdateForm } from "@/v2/feature/security/security-settings/components/sso-gw-update.component";
import { SSOOktaUpdateForm } from "@/v2/feature/security/security-settings/components/sso-okta-update.component";
import { SSOAppsEnum, SSOAppsOptions } from "@/v2/feature/security/security-settings/security.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SSOConfigureDrawer = ({
  isOpen,
  setIsOpen,
  onClose,
  ssoState,
  mfaEnabled,
  onUpdate
}) => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const enabledApp = useMemo(() => {
    return ssoState.find((eachApp) => {
      var _a;
      return (_a = eachApp.state) == null ? void 0 : _a.enabled;
    });
  }, [ssoState]);
  const [updatedSSOApp, setUpdatedSSOApp] = useState(
    enabledApp ? enabledApp.app : void 0
  );
  useEffect(() => {
    setUpdatedSSOApp(enabledApp ? enabledApp.app : void 0);
  }, [enabledApp]);
  const ssoProvider = useMemo(() => {
    return enabledApp && enabledApp.app ? SSOAppsEnum[enabledApp.app] : void 0;
  }, [enabledApp]);
  const enabledActionText = useMemo(() => {
    if (enabledApp && enabledApp.app && enabledApp.app === "sso-okta")
      return polyglot.t("SSOConfigureDrawer.updateApiOkta");
    if (enabledApp && enabledApp.app && (enabledApp.app === "sso-google-workspace" || enabledApp.app === "sso-azure-ad"))
      return polyglot.t("SSOConfigureDrawer.uploadIdP");
    return "";
  }, [polyglot, enabledApp]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setUpdatedSSOApp(enabledApp ? enabledApp.app : void 0);
        onClose();
      },
      children: /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: polyglot.t("SSOConfigureDrawer.ssoFull") }),
        !enabledApp ? /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10 }), children: polyglot.t("SSOConfigureDrawer.selectProvider") }),
          /* @__PURE__ */ jsx(RadioGroup, { "aria-label": "sso-provider", name: "radio-buttons-group", children: SSOAppsOptions.map((eachApp) => /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              value: eachApp.value,
              onChange: () => {
                setUpdatedSSOApp(eachApp.value);
              },
              control: /* @__PURE__ */ jsx(Radio, { checked: updatedSSOApp ? eachApp.value === updatedSSOApp : false, color: "default" }),
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: eachApp.label })
            }
          )) })
        ] }) : /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10 }), children: [
          ssoProvider,
          " ",
          polyglot.t("SSOConfigureDrawer.ssoEnabled"),
          ". ",
          enabledActionText,
          " ",
          polyglot.t("SSOConfigureDrawer.orDisabled"),
          " ",
          ssoProvider,
          " ",
          polyglot.t("SSOConfigureDrawer.sso")
        ] }) }),
        updatedSSOApp && updatedSSOApp === "sso-google-workspace" && /* @__PURE__ */ jsx(
          SSOGWUpdateForm,
          {
            mfaEnabled,
            ssoState,
            onUpdate: () => {
              onClose();
              onUpdate();
            },
            currentUser: user
          }
        ),
        updatedSSOApp && updatedSSOApp === "sso-okta" && /* @__PURE__ */ jsx(
          SSOOktaUpdateForm,
          {
            mfaEnabled,
            ssoState,
            onUpdate: () => {
              onClose();
              onUpdate();
            },
            currentUser: user
          }
        ),
        updatedSSOApp && updatedSSOApp === "sso-azure-ad" && /* @__PURE__ */ jsx(
          SSOAzureAdUpdateForm,
          {
            mfaEnabled,
            ssoState,
            onUpdate: () => {
              onClose();
              onUpdate();
            }
          }
        )
      ] })
    }
  );
};
