"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Trophy } from "@/images/dashboard-icons/Trophy.svg";
import { getDateString } from "@/v2/components/forms/date-label.component";
import { SurveyActionItem } from "@/v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/survey-action-item.component";
import { TodoCard } from "@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { iconSize } from "@/v2/styles/menu.styles";
export const PendingSurveysTodo = ({
  todo,
  setLastChild
}) => {
  const { polyglot } = usePolyglot();
  const [selectedRow, setSelectedRow] = useState(void 0);
  const getTitle = (survey) => {
    return /* @__PURE__ */ jsx(
      Typography,
      {
        variant: "paragraph",
        sx: {
          display: "inline-block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%"
        },
        children: polyglot.t("getDetailByDomain.completeSurvey", {
          cycleName: survey.cycleName,
          date: getDateString(survey.dueDate, false, "d MMM yyyy")
        })
      }
    );
  };
  return /* @__PURE__ */ jsxs(Box, { children: [
    todo.surveys.map((a, idx) => {
      return /* @__PURE__ */ jsx(
        TodoCard,
        {
          icon: /* @__PURE__ */ jsx(Trophy, __spreadValues({}, iconSize)),
          title: getTitle(a),
          actionOnclick: () => setSelectedRow(a),
          showBorder: !(setLastChild && todo.surveys.length === idx + 1),
          userAvatar: /* @__PURE__ */ jsx(UserAvatar, { userId: a.userId, size: "s16" })
        },
        `-${idx}-${a.id}`
      );
    }),
    selectedRow && /* @__PURE__ */ jsx(SurveyActionItem, { selectedRow, afterClose: () => setSelectedRow(void 0) })
  ] });
};
