"use strict";
import { useCallback, useEffect, useRef, useState } from "react";
import { FocusState } from "@/v2/feature/growth/reviews/features/review-answer/review-answer-submission/components/question-comment.component";
export const useQuestionFocus = ({
  question,
  optionKeys,
  isFocused,
  registerMethods,
  handleOptionAction
}) => {
  const [hasKeyboardFocus, setHasKeyboardFocus] = useState(false);
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(null);
  const [focusState, setFocusState] = useState(FocusState.NONE);
  const isInitialRender = useRef(true);
  const containerRef = useRef(null);
  const commentFieldRef = useRef(null);
  const editorDomRef = useRef(null);
  const optionRefs = useRef(/* @__PURE__ */ new Map());
  const richTextFieldRef = useRef(null);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (isFocused) {
      setHasKeyboardFocus(true);
      if (optionKeys.length > 0) {
        setFocusState(FocusState.OPTIONS);
        setHighlightedOptionIndex(0);
      }
    } else {
      setHasKeyboardFocus(false);
      setHighlightedOptionIndex(null);
      setFocusState(FocusState.NONE);
    }
  }, [isFocused, optionKeys.length]);
  const focusCommentField = useCallback(() => {
    if (!hasKeyboardFocus || focusState === FocusState.COMMENT) return;
    setFocusState(FocusState.COMMENT);
    requestAnimationFrame(() => {
      if (commentFieldRef.current) {
        const editorElement = commentFieldRef.current.querySelector(".ProseMirror");
        if (editorElement instanceof HTMLElement) {
          editorElement.focus({ preventScroll: true });
          editorDomRef.current = editorElement;
        }
      }
    });
  }, [hasKeyboardFocus, focusState]);
  const registerCommentFieldRef = useCallback((el) => {
    commentFieldRef.current = el;
    if (el) {
      requestAnimationFrame(() => {
        const editorElement = el.querySelector(".ProseMirror");
        if (editorElement instanceof HTMLElement) {
          editorDomRef.current = editorElement;
        }
      });
    }
  }, []);
  const registerRichTextFieldRef = useCallback((el) => {
    richTextFieldRef.current = el;
    if (el) {
      requestAnimationFrame(() => {
        const editorElement = el.querySelector(".ProseMirror");
        if (editorElement instanceof HTMLElement) {
          editorDomRef.current = editorElement;
        }
      });
    }
  }, []);
  const registerOptionRef = useCallback((key, element) => {
    if (element) {
      optionRefs.current.set(key, element);
    } else {
      optionRefs.current.delete(key);
    }
  }, []);
  const applyFocus = useCallback(() => {
    if (containerRef.current && hasKeyboardFocus) {
      containerRef.current.focus({ preventScroll: true });
    }
  }, [hasKeyboardFocus]);
  const handleKeyNavigation = useCallback(
    (direction) => {
      if (!hasKeyboardFocus) return;
      if (focusState === FocusState.OPTIONS) {
        const currentIndex = highlightedOptionIndex != null ? highlightedOptionIndex : -1;
        if (direction === "enter" && currentIndex >= 0 && currentIndex < optionKeys.length) {
          handleOptionAction(optionKeys[currentIndex]);
          return;
        }
        if (direction === "right" && currentIndex === optionKeys.length - 1 && question.hasComment) {
          setTimeout(() => {
            focusCommentField();
          }, 100);
          return;
        }
        let newIndex = currentIndex;
        if (direction === "left") {
          newIndex = Math.max(0, currentIndex - 1);
        } else {
          newIndex = Math.min(optionKeys.length - 1, currentIndex + 1);
        }
        if (newIndex !== currentIndex) {
          setHighlightedOptionIndex(newIndex);
          if (containerRef.current) {
            containerRef.current.focus({ preventScroll: true });
          }
        }
      }
    },
    [focusState, highlightedOptionIndex, hasKeyboardFocus, optionKeys, question, handleOptionAction, focusCommentField]
  );
  useEffect(() => {
    if (registerMethods) {
      registerMethods({ handleKeyNavigation });
    }
  }, [registerMethods, handleKeyNavigation]);
  const handleContainerKeyDown = useCallback(
    (e) => {
      if (!hasKeyboardFocus) return;
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        if (focusState === FocusState.OPTIONS && highlightedOptionIndex !== null && optionKeys.length > 0) {
          const key = optionKeys[highlightedOptionIndex];
          handleOptionAction(key);
        } else if (focusState === FocusState.COMMENT) {
          focusCommentField();
        } else if (optionKeys.length === 0 && richTextFieldRef.current) {
          richTextFieldRef.current.focus({ preventScroll: true });
        }
      }
      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        e.preventDefault();
        e.stopPropagation();
        const direction = e.key === "ArrowLeft" ? "left" : "right";
        handleKeyNavigation(direction);
      }
      if (e.key === "ArrowDown") {
        e.preventDefault();
        if (focusState === FocusState.OPTIONS && question.hasComment) {
          focusCommentField();
        }
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        if (focusState === FocusState.COMMENT) {
          setFocusState(FocusState.OPTIONS);
          if (containerRef.current) {
            containerRef.current.focus({ preventScroll: true });
          }
          if (highlightedOptionIndex === null && optionKeys.length > 0) {
            setHighlightedOptionIndex(0);
          }
        }
      }
    },
    [
      hasKeyboardFocus,
      focusState,
      highlightedOptionIndex,
      optionKeys,
      question.hasComment,
      handleOptionAction,
      handleKeyNavigation,
      focusCommentField
    ]
  );
  const handleCommentKeyDown = useCallback((e) => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusState(FocusState.OPTIONS);
      if (containerRef.current) {
        containerRef.current.focus({ preventScroll: true });
      }
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (document.activeElement === richTextFieldRef.current) return;
      setTimeout(() => {
        const newEvent = new KeyboardEvent("keydown", {
          key: "ArrowDown",
          bubbles: true,
          cancelable: true
        });
        window.dispatchEvent(newEvent);
      }, 50);
    }
  }, []);
  return {
    hasKeyboardFocus,
    setHasKeyboardFocus,
    highlightedOptionIndex,
    setHighlightedOptionIndex,
    focusState,
    setFocusState,
    commentFieldRef,
    editorDomRef,
    optionRefs,
    focusCommentField,
    registerCommentFieldRef,
    registerOptionRef,
    registerRichTextFieldRef,
    richTextFieldRef,
    handleKeyNavigation,
    handleContainerKeyDown,
    handleCommentKeyDown,
    containerRef,
    applyFocus
  };
};
