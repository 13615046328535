"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Form, FormikProvider } from "formik";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  getDeadlineByReviewerFields
} from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/components/shared/timeline-shared.util";
import { useTimelineForm } from "@/v2/feature/growth/reviews/features/review-cycle/rc-upsert/rc-upsert-timeline/hook/use-timeline-form.hook";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
export const TimelineRollingModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh,
  showFlags
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(TimelineRollingContent, { reviewCycle, onClose, refresh, showFlags }) });
};
const TimelineRollingContent = ({
  reviewCycle,
  onClose,
  refresh,
  showFlags
}) => {
  const { polyglot } = usePolyglot();
  const formik = useTimelineForm(reviewCycle, onClose, refresh, showFlags);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Timeline" }),
    getDeadlineByReviewerFields(showFlags, formik, polyglot)
  ] }) });
};
