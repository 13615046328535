"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const TodoCardVersionSmall = ({ icon, title, actionOnclick, countTitle }) => {
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: {
        paddingX: spacing.p5,
        paddingY: spacing.p3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden"
      },
      onClick: actionOnclick,
      children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Box, { children: icon }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey }, children: title })
        ] }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey }, children: countTitle })
      ] })
    }
  );
};
