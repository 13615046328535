"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { isPast } from "date-fns";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { Typography } from "@/v2/components/typography/typography.component";
import { TaskStatuses } from "@/v2/feature/task/task.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const getTaskStatus = (taskObject, polyglot) => {
  const { status, dueDate } = taskObject;
  const isTaskOverdue = dueDate ? isPast(new Date(dueDate)) : false;
  if (status === TaskStatuses.INCOMPLETE)
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      !isTaskOverdue && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.middleGrey } })),
        " ",
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("getTaskStatus.toDo") })
      ] }),
      isTaskOverdue && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Waiting, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Red } })),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Red }, children: polyglot.t("getTaskStatus.overdue") })
      ] })
    ] });
  else if (status === TaskStatuses.COMPLETE) {
    return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("getTaskStatus.done") })
    ] });
  }
};
