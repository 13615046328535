"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CustomTimeRangeDrawer = ({ isOpen, setIsOpen, onApply }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(CustomTimeRangeDrawerContent, { setIsOpen, onApply }) });
};
const CustomTimeRangeDrawerContent = ({ onApply, setIsOpen }) => {
  const { polyglot } = usePolyglot();
  const handleApply = (startDate, endDate) => {
    onApply(startDate, endDate);
    setIsOpen(false);
  };
  const validationSchema = Yup.object().shape({
    startDate: Yup.mixed().required(polyglot.t("TimesheetsTimeRangeFilter.startDateRequired")),
    endDate: Yup.mixed().required(polyglot.t("TimesheetsTimeRangeFilter.endDateRequired"))
  });
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: ""
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (values.startDate && values.endDate) {
        handleApply(values.startDate, values.endDate);
      }
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g24,
        height: "100%"
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { color: themeColors.DarkGrey }, children: polyglot.t("TimesheetsTimeRangeFilter.customRange") }),
        /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            name: "startDate",
            label: polyglot.t("TimesheetsTimeRangeFilter.start"),
            value: formik.values.startDate,
            onChange: (date) => formik.setFieldValue("startDate", date, true),
            maxDate: formik.values.endDate,
            error: formik.touched.startDate && !!formik.errors.startDate,
            helperText: formik.touched.startDate && formik.errors.startDate
          }
        ),
        /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            name: "endDate",
            label: polyglot.t("TimesheetsTimeRangeFilter.end"),
            value: formik.values.endDate,
            onChange: (date) => formik.setFieldValue("endDate", date, true),
            minDate: formik.values.startDate,
            error: formik.touched.endDate && !!formik.errors.endDate,
            helperText: formik.touched.endDate && formik.errors.endDate
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            type: "submit",
            fullWidth: true,
            disabled: formik.isSubmitting,
            children: polyglot.t("TimesheetsTimeRangeFilter.apply")
          }
        ) })
      ]
    }
  ) }) });
};
