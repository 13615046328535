"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { generatePath, useHistory } from "react-router-dom";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { USER_PERSONAL_TAB } from "@/lib/routes";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { MissingFieldInformationViewDrawer } from "@/v2/feature/dashboard/features/sections/user-profile-widget/components/missing-field-information-view-drawer.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableWhiteIconButtonSx } from "@/v2/styles/icon-button.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DashboardUserProfile = ({
  user,
  widgetData,
  refreshWidgetData,
  readOnly = false
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const { getCachedUserById } = useCachedUsers();
  const u = getCachedUserById(user.userId);
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly, size: "small", children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          "&:hover": { background: themeColors.transparency },
          borderRadius: radius.br10,
          cursor: "pointer"
        },
        onClick: () => {
          var _a2;
          return routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: (_a2 = user == null ? void 0 : user.userId) != null ? _a2 : "" }));
        },
        children: /* @__PURE__ */ jsxs(
          "div",
          {
            style: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: spacing.p10
            },
            children: [
              /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "s100" }),
              /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "headline1",
                  sx: {
                    marginTop: spacing.m15,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    width: "100%"
                  },
                  children: polyglot.t((_a = u == null ? void 0 : u.displayName) != null ? _a : "")
                }
              ),
              /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "paragraph",
                  sx: {
                    marginTop: spacing.m5,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    width: "100%"
                  },
                  children: polyglot.t((_d = (_c = (_b = u == null ? void 0 : u.role) == null ? void 0 : _b.jobPosition) == null ? void 0 : _c.title) != null ? _d : "")
                }
              ),
              /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "paragraph",
                  sx: {
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%"
                  },
                  children: polyglot.t((_g = (_f = (_e = u == null ? void 0 : u.role) == null ? void 0 : _e.site) == null ? void 0 : _f.name) != null ? _g : "")
                }
              )
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: (widgetData == null ? void 0 : widgetData.isMissingInfo) ? /* @__PURE__ */ jsxs(
      Box,
      {
        onClick: () => setIsOpen(true),
        sx: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: spacing.p5,
          "&:hover": { background: themeColors.GreyPress },
          borderRadius: radius.br10,
          cursor: "pointer"
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(MistakeIcon, { style: { fill: themeColors.Red }, width: 14, height: 14 }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Red }, children: polyglot.t("DashboradUserProfile.missingInfo") })
          ] }),
          /* @__PURE__ */ jsx(IconButton, { sx: tableWhiteIconButtonSx, children: /* @__PURE__ */ jsx(Plus, { width: 14, height: 14 }) })
        ]
      }
    ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5, justifyContent: "center" }, children: [
      /* @__PURE__ */ jsx(OkGreen, { style: { fill: themeColors.Green }, width: 14, height: 14 }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey }, children: polyglot.t("DashboradUserProfile.upToDate") })
    ] }) }),
    widgetData && /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
      MissingFieldInformationViewDrawer,
      {
        widgetData,
        user,
        refreshWidgetData
      }
    ) })
  ] }) });
};
