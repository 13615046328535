"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { UploadInput } from "@/component/forms/UploadInput";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const P45FileUpload = ({ userId, disabled }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Typography,
      {
        sx: __spreadProps(__spreadValues({}, themeFonts.headline3), {
          color: themeColors.DarkGrey
        }),
        children: polyglot.t("OnboardingByUser.uploadP45")
      }
    ),
    /* @__PURE__ */ jsx(
      UploadInput,
      {
        label: polyglot.t("OnboardingByUser.uploadP45"),
        cardStyle: {
          padding: 1,
          backgroundColor: "#3366ff1a",
          boxShadow: "none",
          marginBottom: 3,
          width: "100%"
        },
        onChange: (resp) => __async(void 0, null, function* () {
          if (!resp) return;
          try {
            yield DocumentNewAPI.createP45Document({
              fileUuid: resp.uuid,
              name: `p45-${userId}`,
              userId,
              membersRule: UserSelectFiltersOptions.SelectSpecific
            });
          } catch (error) {
          }
        }),
        disabled
      }
    )
  ] });
};
