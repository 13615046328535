"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { PayrollAPI } from "@v2/feature/payroll/payroll.api";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const PayrollOtherSettingsSection = ({ payroll, sx, refreshPayroll }) => {
  const { polyglot } = usePolyglot();
  const [showEditButton, setShowEditButton] = useState(true);
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(
      PayrollSettingSectionHeader,
      {
        showEditButton,
        onEditClick: () => setShowEditButton((prev) => !prev),
        children: polyglot.t("PayrollOtherSettings.title")
      }
    ),
    showEditButton ? /* @__PURE__ */ jsx(PayrollOtherSettingsReadMode, { payroll }) : /* @__PURE__ */ jsx(
      PayrollOtherSettingsEditMode,
      {
        payroll,
        refreshPayroll,
        setShowEditButton
      }
    )
  ] });
};
const PayrollOtherSettingsEditMode = ({
  payroll,
  refreshPayroll,
  setShowEditButton
}) => {
  const { polyglot } = usePolyglot();
  const [updating, setUpdating] = useState(false);
  const [payslipPreview, setPayslipPreview] = useState(payroll.payslipPreview);
  const [showMessage] = useMessage();
  useEscapeKey(() => setShowEditButton(true));
  const updatePayslipPreview = useCallback(
    (value) => __async(void 0, null, function* () {
      setUpdating(true);
      try {
        setPayslipPreview(value);
        yield PayrollAPI.setPayrollPayslipPreview(payroll.id, value);
        yield refreshPayroll();
        setShowEditButton(true);
      } catch (error) {
        setPayslipPreview(payroll.payslipPreview);
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      }
      setUpdating(false);
    }),
    [payroll.id, payroll.payslipPreview, refreshPayroll, setShowEditButton, showMessage]
  );
  useEffect(() => {
    setPayslipPreview(payroll.payslipPreview);
  }, [payroll]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: "10px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("PayrollOtherSettings.payslipPreviewDesc") }),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("PayrollOtherSettings.payslipPreview"),
          checked: payslipPreview,
          onChange: (_, checked) => setPayslipPreview(checked)
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m40, display: "flex", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setShowEditButton(true), sizeVariant: "medium", colorVariant: "secondary", children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          type: "button",
          loading: updating,
          sizeVariant: "medium",
          colorVariant: "primary",
          onClick: () => __async(void 0, null, function* () {
            yield updatePayslipPreview(payslipPreview);
          })
        }
      )
    ] })
  ] });
};
const PayrollOtherSettingsReadMode = ({ payroll }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "inline-grid",
        gridTemplateColumns: "1fr 3fr",
        rowGap: spacing.g10,
        columnGap: spacing.g20
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("PayrollOtherSettings.payslipPreview") }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: payroll.payslipPreview ? polyglot.t("General.on") : polyglot.t("General.off") })
      ]
    }
  );
};
