"use strict";
import * as Sentry from "@sentry/react";
import { isProduction } from "@/v2/infrastructure/firebase/identity-platform.util";
const FE_DSN = "https://b45c39b768524f4b826723f2980cd50d@o553737.ingest.us.sentry.io/5723014";
export const initializeSentry = () => {
  if (process.env.REACT_APP_DISABLE_SENTRY === "true") {
    return;
  }
  Sentry.init({
    dsn: FE_DSN,
    // Performance monitoring
    tracesSampleRate: isProduction ? 0.1 : 0.05,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Session replay
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    // Set which URLs to trace
    tracePropagationTargets: [window.location.origin, /^\//],
    // Environment info
    environment: isProduction ? "production" : "development",
    // Session replay sampling rates
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    // Advanced error handling
    beforeSend(event) {
      var _a, _b;
      if (event.user) {
        delete event.user.ip_address;
        delete event.user.email;
      }
      if ((_b = (_a = event.request) == null ? void 0 : _a.url) == null ? void 0 : _b.includes("/health-check")) {
        return null;
      }
      return event;
    },
    // Enable debug mode in development
    debug: !isProduction,
    maxBreadcrumbs: 50
  });
};
export const setSentryUser = (userId, companyId) => {
  if (process.env.REACT_APP_DISABLE_SENTRY === "true") {
    return;
  }
  Sentry.setUser({
    id: String(userId),
    companyId: String(companyId)
  });
};
initializeSentry();
