"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Minus } from "@/images/side-bar-icons/Minus.svg";
import { ReactComponent as Plus } from "@/images/side-bar-icons/Plus.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { getModelImage } from "@/v2/feature/device/device.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: 14, height: 14 };
export const DevicePreviewComponent = ({ deviceModel, cart, setCart }) => {
  var _a;
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: globalState.user.userId });
  const hasDevicesPriceScope = hasScopes(["devices.price:read", "devices:all"], scopesContext);
  const {
    type,
    modelName,
    modelVersion,
    screenSize,
    storage,
    gpuCores,
    cpuCores,
    price36,
    deliveryDays,
    ram,
    fullPrice
  } = deviceModel;
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "200px",
        paddingX: spacing.p10,
        maxHeight: "450px",
        height: "100%"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Box, { sx: { height: "auto", width: "inherit" }, children: getModelImage(type, modelName, {
            width: "auto",
            height: "120px"
          }) }),
          /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
            /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline3), children: modelName }),
            /* @__PURE__ */ jsxs(Box, { sx: { marginTop: spacing.m10 }, children: [
              modelVersion && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: [
                "Version: ",
                modelVersion
              ] }),
              screenSize && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: [
                "Size: ",
                screenSize,
                '"'
              ] }),
              storage && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: [
                "Storage: ",
                storage,
                "GB"
              ] }),
              ram && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: [
                "RAM: ",
                ram,
                "GB"
              ] }),
              gpuCores && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: [
                "GPU: ",
                gpuCores,
                "-cores"
              ] }),
              cpuCores && /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: [
                "CPU: ",
                cpuCores,
                "-cores"
              ] })
            ] }),
            hasDevicesPriceScope && price36 && /* @__PURE__ */ jsxs(Box, { sx: { marginTop: spacing.m5, display: "flex", width: "100%", justifyContent: "flex-start" }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, width: "55%" }), children: "Monthly Rent" }),
              /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey, width: "45%" }), children: [
                "from \xA3",
                price36
              ] })
            ] }),
            hasDevicesPriceScope && fullPrice && /* @__PURE__ */ jsxs(Box, { sx: { marginTop: spacing.m5, display: "flex", width: "100%", justifyContent: "flex-start" }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, width: "55%" }), children: "Purchase price" }),
              /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey, width: "45%" }), children: [
                "from \xA3",
                fullPrice
              ] })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { marginTop: spacing.m5, display: "flex", width: "100%", justifyContent: "flex-start" }, children: [
              /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, width: "55%" }), children: "Delivery time" }),
              /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey, width: "45%" }), children: [
                deliveryDays,
                " Day",
                deliveryDays > 1 ? "s" : ""
              ] })
            ] })
          ] })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({ display: "flex", justifyContent: "flex-start" }, spacing.mt20), { verticalAlign: "baseline" }), children: cart.length > 0 && ((_a = cart.filter((item) => item.id === deviceModel.id)) == null ? void 0 : _a.length) > 0 ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.m15, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(IconButton, { onClick: () => setCart([]), title: "Edit", sx: tableIconButtonSx, children: /* @__PURE__ */ jsx(Minus, __spreadValues({}, iconSize)) }),
          /* @__PURE__ */ jsx(Typography, { children: "1" }),
          /* @__PURE__ */ jsx(IconButton, { disabled: true, title: "Edit", sx: tableIconButtonSx, children: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)) })
        ] }) : /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: () => setCart([deviceModel]), children: "Add to order" }) })
      ]
    }
  );
};
export const DevicePreviewSkeleton = () => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", width: "100%", maxWidth: "220px", paddingX: spacing.p10 }, children: [
    /* @__PURE__ */ jsx(Box, { sx: { height: "140px" }, children: /* @__PURE__ */ jsx(
      Skeleton,
      {
        animation: "wave",
        variant: "rectangular",
        width: 210,
        height: 120,
        sx: { backgroundColor: themeColors.Background }
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
      /* @__PURE__ */ jsx(Skeleton, { animation: "wave", sx: { backgroundColor: themeColors.Background } }),
      /* @__PURE__ */ jsxs(Box, { sx: { marginTop: spacing.m10 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: /* @__PURE__ */ jsx(Skeleton, { animation: "wave", sx: { backgroundColor: themeColors.Background } }) }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: /* @__PURE__ */ jsx(Skeleton, { animation: "wave", sx: { backgroundColor: themeColors.Background } }) }),
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mb: spacing.m2 }), children: /* @__PURE__ */ jsx(Skeleton, { animation: "wave", sx: { backgroundColor: themeColors.Background } }) })
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", justifyContent: "flex-start" }, spacing.mt20), children: /* @__PURE__ */ jsx(Skeleton, { animation: "wave", sx: { backgroundColor: themeColors.Background } }) })
  ] });
};
