"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CalendarBig } from "@/images/payments-intro/CalendarBig.svg";
import { ReactComponent as PaymentBig } from "@/images/payments-intro/PaymentBig.svg";
import { ReactComponent as SmileBig } from "@/images/payments-intro/SmileBig.svg";
import { ReactComponent as WalletBig } from "@/images/payments-intro/WalletBig.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  IntroCardGroup
} from "@/v2/components/theme-components/icon-content-action-card.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const emptyStates = [
  {
    title: "Bulk payments",
    content: "Bulk payments help you pay the whole team in one go, in just a few clicks. Subject to your bank supporting this feature.",
    iconMedia: /* @__PURE__ */ jsx(PaymentBig, {})
  },
  {
    title: "Salary payments",
    content: "Automate your payroll even further, taking salary payments off your shoulders. Pay via Zelt and salary will reach your account before you say \u201CPay Day\u201D.",
    iconMedia: /* @__PURE__ */ jsx(WalletBig, {})
  },
  {
    title: "Now or Later",
    content: "Spend time when you have it. Run your payroll now and schedule payments to go out on the Pay Day. ",
    iconMedia: /* @__PURE__ */ jsx(CalendarBig, {})
  },
  {
    title: "Simple to use",
    content: "Payments app is easy to use by both admins and team members. Whether you are running payroll, paying taxes or a contractor invoice. ",
    iconMedia: /* @__PURE__ */ jsx(SmileBig, {})
  }
];
export function PaymentEmptyState({ setIsModalOpen }) {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "600px" }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: "Make secure payments using Open Banking directly from Zelt in a few clicks. Zelt supports 10+ most popular banks." }),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-start",
                gap: spacing.g10,
                width: "70%",
                marginTop: spacing.m30
              },
              children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", onClick: () => setIsModalOpen(true), children: "New payment" })
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(IntroCardGroup, { cards: emptyStates }) })
      ]
    }
  );
}
