"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { AttendanceAPI } from "@v2/feature/attendance/attendance.api";
import { ScheduleGeneralForm } from "@v2/feature/attendance/company/components/form/schedule-general-form.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const AttendanceScheduleEditGeneralDrawer = ({
  isOpen,
  setIsOpen,
  attendanceSchedule,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    AttendanceScheduleEditGeneralDrawerContent,
    {
      attendanceSchedule,
      refresh,
      setIsOpen
    }
  ) });
};
const AttendanceScheduleEditGeneralDrawerContent = ({
  attendanceSchedule,
  refresh,
  setIsOpen
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield AttendanceAPI.updateAttendanceScheduleGeneral(attendanceSchedule.id, values);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AttendanceScheduleEditGeneralDrawer.errorMessages.update", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [showMessage, attendanceSchedule.id, refresh, setIsOpen, polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AttendanceScheduleGeneralSection.general") }),
    /* @__PURE__ */ jsx(
      ScheduleGeneralForm,
      {
        attendanceSchedule,
        onSubmit,
        loading,
        buttonName: polyglot.t("General.save")
      }
    )
  ] });
};
