"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { Loader } from "@v2/components/loader.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Apps } from "@/images/side-bar-icons/Apps.svg";
import { ReactComponent as Bill } from "@/images/side-bar-icons/Bill.svg";
import { ReactComponent as Coin } from "@/images/side-bar-icons/Coin.svg";
import { ReactComponent as Device } from "@/images/side-bar-icons/Device.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DASHBOARD_ROUTE } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserDeletionBlockerItem } from "@/v2/feature/user/features/user-profile-action/components/user-deletion-blocker-item.component";
import { UserProfileActionAPI } from "@/v2/feature/user/features/user-profile-action/user-profile-action.api";
import { iconSize } from "@/v2/styles/menu.styles";
import { pluralText } from "@/v2/util/string.util";
export const UserProfileDeleteModal = ({
  isOpen,
  setIsOpen,
  userId,
  loading,
  deletionBlocked,
  userDeviceDetails,
  userDeviceOrders,
  userAppAccounts,
  inPayrun,
  isBillingContact,
  hasPayments
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { refreshCachedUsers } = useCachedUsers();
  const [confirmText, setConfirmText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const closeDeleteModal = () => {
    setConfirmText("");
  };
  const triggerDelete = () => __async(void 0, null, function* () {
    try {
      setIsDeleting(true);
      yield UserProfileActionAPI.deleteUser(userId);
      showMessage(polyglot.t("UserProfileDeleteModal.successMessages.delete"), "success");
      routerHistory.push(DASHBOARD_ROUTE);
    } catch (err) {
      showMessage(`${polyglot.t("UserProfileDeleteModal.errorMessages.delete")}: ${nestErrorMessage(err)}`, "error");
    } finally {
      yield refreshCachedUsers();
      closeDeleteModal();
      setIsDeleting(false);
    }
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: loading ? /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Loader, { loading, children: null }) }) : /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("General.delete") }),
    !deletionBlocked ? /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        polyglot.t("UserProfileDeleteModal.warning"),
        " "
      ] }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "confirmTxt",
          label: polyglot.t("UserProfileDeleteModal.typeHere"),
          autoFocus: true,
          autoComplete: "off",
          value: confirmText,
          onChange: (newVal) => {
            if (!newVal) return;
            setConfirmText(newVal.currentTarget.value);
          }
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          disabled: (confirmText == null ? void 0 : confirmText.toLowerCase()) !== "confirm" || isDeleting,
          name: isDeleting ? polyglot.t("UserProfileDeleteModal.deleting") : polyglot.t("UserProfileDeleteModal.del"),
          onClick: triggerDelete,
          loading: isDeleting,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      ) })
    ] }) : deletionBlocked && /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserProfileDeleteModal.someItemsCannotBeDeleted") }),
      /* @__PURE__ */ jsxs(Stack, { gap: "20px", spacing: 2, children: [
        (userAppAccounts == null ? void 0 : userAppAccounts.length) > 0 && /* @__PURE__ */ jsx(
          UserDeletionBlockerItem,
          {
            icon: /* @__PURE__ */ jsx(Apps, __spreadValues({}, iconSize)),
            text: `${userAppAccounts.length} ${polyglot.t("UserProfileDeleteModal.appAccount")}${pluralText(
              userAppAccounts.length
            )}`
          }
        ),
        (userDeviceDetails == null ? void 0 : userDeviceDetails.length) > 0 && /* @__PURE__ */ jsx(
          UserDeletionBlockerItem,
          {
            icon: /* @__PURE__ */ jsx(Device, __spreadValues({}, iconSize)),
            text: `${userDeviceDetails.length} ${polyglot.t("UserProfileDeleteModal.device")}${pluralText(
              userDeviceDetails.length
            )}`
          }
        ),
        (userDeviceOrders == null ? void 0 : userDeviceOrders.length) > 0 && /* @__PURE__ */ jsx(
          UserDeletionBlockerItem,
          {
            icon: /* @__PURE__ */ jsx(Device, __spreadValues({}, iconSize)),
            text: `${userDeviceOrders.length} ${polyglot.t(
              "UserProfileDeleteModal.deviceOrder"
            )} ${pluralText(userDeviceDetails.length)}`
          }
        ),
        inPayrun && /* @__PURE__ */ jsx(
          UserDeletionBlockerItem,
          {
            icon: /* @__PURE__ */ jsx(Coin, __spreadValues({}, iconSize)),
            text: polyglot.t("UserProfileDeleteModal.payrollRecord")
          }
        ),
        isBillingContact && /* @__PURE__ */ jsx(
          UserDeletionBlockerItem,
          {
            icon: /* @__PURE__ */ jsx(Bill, __spreadValues({}, iconSize)),
            text: polyglot.t("UserProfileDeleteModal.billingContact")
          }
        ),
        hasPayments && /* @__PURE__ */ jsx(
          UserDeletionBlockerItem,
          {
            icon: /* @__PURE__ */ jsx(Bill, __spreadValues({}, iconSize)),
            text: polyglot.t("UserProfileDeleteModal.hasPayments")
          }
        )
      ] })
    ] })
  ] }) });
};
