"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Link } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const TeamtailorApiKeyInstructions = () => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: [
      "a) Log in to",
      " ",
      /* @__PURE__ */ jsx(Link, { target: "_blank", href: "https://app.teamtailor.com/login", children: "Teamtailor" })
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "b) Click your account name in the top right, and from the dropdown menu, click Settings." }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "c) From the left sidebar menu, scroll down and click the API Keys menu option, under the Integrations section." }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "d) Click the New API Key button to generate a new 'Admin' API scope Key with 'Read' and 'Write' permissions" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "white", sx: { mb: spacing.m5 }, children: "e) Copy the newly generated API key or an existing one from the table of API keys, and paste it here." })
  ] });
};
