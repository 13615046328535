"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Checkbox, Dialog, DialogContent, FormControlLabel, Stack, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const CreateUserFormSchema = Yup.object().shape({
  sendInviteEmail: Yup.boolean(),
  username: Yup.string().required().matches(/^[a-z][a-z0-9-._]{0,29}$/i, {
    message: (
      // taken from JumpCloud's own website
      `username must start with a letter, be 30 characters or less, and contain only letters, numbers - . and _`
    )
  })
});
export const JumpCloudNewUserParams = ({ username, onClose, onComplete }) => {
  var _a;
  const formik = useFormik({
    initialValues: {
      sendInviteEmail: false,
      username: (username || "").replace(/[^a-z0-9-._]+/gi, "-").replace(/^[^a-z]+/i, "").slice(0, 30)
      // limit length
    },
    validationSchema: CreateUserFormSchema,
    onSubmit: (values) => {
      onComplete({
        type: "jumpcloud",
        username: values.username,
        sendInviteEmail: values.sendInviteEmail
      });
    }
  });
  return /* @__PURE__ */ jsx(Dialog, { open: true, sx: { px: 8 }, onClose, maxWidth: "sm", children: /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { width: "300px" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { mb: spacing.m10, color: themeColors.DarkGrey }), children: "JumpCloud User" }),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "username",
          type: "text",
          label: "Username",
          size: "small",
          onChange: formik.handleChange,
          value: formik.values.username,
          error: !!formik.errors.username,
          helperText: (_a = formik.touched.username && formik.errors.username) != null ? _a : "",
          clearText: () => formik.setFieldValue("username", "")
        }
      ),
      /* @__PURE__ */ jsx(
        FormControlLabel,
        {
          control: /* @__PURE__ */ jsx(
            Checkbox,
            {
              size: "small",
              name: "sendInviteEmail",
              onChange: formik.handleChange,
              checked: formik.values.sendInviteEmail
            }
          ),
          label: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Send a JumpCloud invitation email" })
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Stack, { direction: "row", justifyContent: "center", gap: spacing.m10, sx: { mt: spacing.m25 }, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          type: "submit",
          sizeVariant: "small",
          colorVariant: "primary",
          onClick: () => formik.handleSubmit(),
          children: "Give access"
        }
      ),
      /* @__PURE__ */ jsx(ButtonComponent, { type: "button", sizeVariant: "small", colorVariant: "secondary", onClick: onClose, children: "Cancel" })
    ] })
  ] }) }) });
};
