"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { spacing } from "@/v2/styles/spacing.styles";
import "@/v2/feature/dashboard/features/sections/user-time-planner/user-time-planner.scss";
export const UserTimePlannerBigLoader = () => {
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "big", isLoadingState: true, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", padding: spacing.p5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Time planner" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Policy summary" })
    ] }) }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          gap: spacing.g20,
          justifyContent: "space-between",
          alignItems: "center"
        },
        children: [0, 1, 2].map((num) => /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              height: "100%",
              width: "100%",
              verticalAlign: "middle",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: spacing.m10
            },
            children: /* @__PURE__ */ jsx("div", { className: "utp-progress-bar", children: /* @__PURE__ */ jsx("progress", { value: "75", max: "100", style: { visibility: "hidden", height: 0, width: 0 }, children: "75%" }) })
          },
          `${num}-utpb`
        ))
      }
    )
  ] }) });
};
