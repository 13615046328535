"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Alert, Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { FormikProvider, useFormik } from "formik";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { LOGIN_ROUTE } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AuthResetPasswordPage = () => {
  var _a, _b;
  const routerHistory = useHistory();
  const qs = new URLSearchParams(useLocation().search);
  const token = (_a = qs.get("t")) != null ? _a : void 0;
  const email = (_b = qs.get("email")) == null ? void 0 : _b.trim().replace(/ /g, "+");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [credentialsError, setCredentialsError] = useState(false);
  const validationSchema = yup.object({
    confirmPassword: yup.string().min(12, "It should be at least 12 characters long").required("Confirm password is required")
  });
  const formik = useFormik({
    initialValues: {
      confirmPassword: ""
    },
    validationSchema,
    onSubmit: (formData) => __async(void 0, null, function* () {
      var _a2, _b2;
      setCredentialsError(false);
      setErrorMessage("");
      setIsLoading(true);
      try {
        yield AuthAPI.resetPassword(formData.confirmPassword, token, email);
        routerHistory.push(`${LOGIN_ROUTE}?reset=true`);
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          setErrorMessage((_a2 = error.response.data.message) != null ? _a2 : error.response.data.error);
          return setCredentialsError(true);
        } else if (error.response && error.response.status === 422) {
          setErrorMessage((_b2 = error.response.data.message) != null ? _b2 : error.response.data.error);
          return setCredentialsError(true);
        }
        throw error;
      } finally {
        setIsLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(
    AuthLayout,
    {
      title: "Set password",
      description: "Create a secure password for your Zelt account",
      submit: formik.handleSubmit,
      children: /* @__PURE__ */ jsxs(FormikProvider, { value: formik, children: [
        (credentialsError || errorMessage) && /* @__PURE__ */ jsx(Alert, { severity: "error", children: errorMessage ? errorMessage : "Invalid credentials" }),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "New password",
            name: "confirmPassword",
            type: "password",
            value: formik.values.confirmPassword,
            onChange: formik.handleChange,
            error: formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword),
            helperText: "Your new password must contain at least 12 characters",
            autoComplete: "new-password",
            autoFocus: true
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            type: "submit",
            name: "Continue",
            fullWidth: true,
            loading: isLoading,
            sizeVariant: "large",
            colorVariant: "primary"
          }
        ) }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.sm, alignItems: "center", mt: spacing.s3 }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "or" }),
          /* @__PURE__ */ jsx(Link, { to: LOGIN_ROUTE, style: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.ZeltYellow }), children: "sign in" })
        ] })
      ] })
    }
  );
};
