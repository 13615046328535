"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, InputAdornment } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SurveyCycleAPI } from "@/v2/feature/growth/surveys/api-client/survey-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCGeneralScheduledReopenModal = ({
  surveyCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(SCGeneralScheduledReopenContent, { surveyCycle, onClose, refresh }) });
};
const SCGeneralScheduledReopenContent = ({ surveyCycle, onClose, refresh }) => {
  var _a, _b, _c, _d, _e;
  const [showMessage] = useMessage();
  const validationSchema = yup.object({
    extendFor: yup.number().integer().min(1, "Extension must be at least 1 day").required("Extension is required")
  });
  const formik = useFormik({
    initialValues: {
      extendFor: 1
    },
    validationSchema,
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (surveyCycle) {
          yield SurveyCycleAPI.reopenCycle(surveyCycle.id, values.extendFor);
          showMessage(`Survey successfully opened survey cycle`, "success");
          yield refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Reopen cycle" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Are you sure you want to reopen this survey cycle? You will be able to edit participants, timeline settings, and reminders. Specify the number of additional days to extend the end date for this cycle below." }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g16 }, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "extendFor",
        label: "Survey is extended for",
        value: formik.values.extendFor,
        onChange: formik.handleChange,
        error: ((_a = formik.touched) == null ? void 0 : _a.extendFor) && !!((_b = formik.errors) == null ? void 0 : _b.extendFor),
        helperText: (_e = ((_c = formik.touched) == null ? void 0 : _c.extendFor) && ((_d = formik.errors) == null ? void 0 : _d.extendFor)) != null ? _e : " ",
        endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "days" }) }),
        type: "number"
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { onClick: onClose, sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, children: "Cancel" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          type: "submit",
          sizeVariant: "medium",
          colorVariant: "primary",
          name: "Reopen",
          loading: formik.isSubmitting,
          fullWidth: true
        }
      )
    ] })
  ] }) });
};
