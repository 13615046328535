"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  getNewEmployeeTaxCodeNoP45,
  getNewEmployeeTaxCodeWithP45
} from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { CloseButton } from "@/v2/feature/user-onboarding/onboarding-by-user/components/close-button.component";
import { LegalGenderSelect } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/legal-gender.component";
import { NINOEntry } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/nino-entry.component";
import { P45Values } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/p45-values.component";
import { StarterDeclarationChoice } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/starter-declaration.component";
import {
  StudentLoansOptions
} from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/student-loans-options.component";
import { getApiErrorMessage } from "@/v2/infrastructure/api-error/api-error.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { todaysDateShortISOString } from "@/v2/util/date-format.util";
export const OnboardingPayrollUK = ({ userId, onClose }) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const [confirmedInfo, setConfirmedInfo] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const [leaveDate, setLeaveDate] = useState("");
  const [p45TaxCode, setP45TaxCode] = useState("");
  const user = getCachedUserById(userId);
  const formik = useFormik({
    initialValues: {
      hasNino: void 0,
      isP45Filled: void 0,
      legalGender: void 0,
      niNumber: void 0,
      taxCode: "0T",
      week1Month1: false,
      openingPreviousGross: void 0,
      openingPreviousTax: void 0,
      starterDeclaration: void 0,
      studentLoans: void 0
    },
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a, _b, _c, _d;
      try {
        setSubmitting(true);
        const update = {
          payrollValues: {
            starterDeclaration: values.starterDeclaration,
            isP45Filled: values.isP45Filled,
            niNumber: values.niNumber,
            legalGender: values.legalGender,
            studentLoan: (_b = (_a = values.studentLoans) == null ? void 0 : _a.studentLoanPlan) != null ? _b : "None",
            postgradLoan: (_d = (_c = values.studentLoans) == null ? void 0 : _c.postgradLoan) != null ? _d : false,
            isDirector: false,
            niAlternativeMethod: null,
            openingNotNewStarter: false,
            niTable: "A",
            week1Month1: values.week1Month1,
            taxCode: values.taxCode,
            openingPreviousGross: values.openingPreviousGross,
            openingPreviousTax: values.openingPreviousTax
          },
          countryCode: "GB",
          effectiveDate: todaysDateShortISOString(),
          changeReason: null
        };
        yield PayrollAPI.updateUserPayrollRecord(userId, update);
        onClose();
      } catch (e) {
        setSubmitting(false);
        showMessage(
          polyglot.t("OnboardingByUser.errors.payrollNotSaved", { errorMessage: getApiErrorMessage(e) }),
          "error"
        );
      }
    })
  });
  const { isP45Filled, legalGender, starterDeclaration, taxCode, studentLoans, niNumber } = formik.values;
  const newEmployee = useMemo(() => {
    var _a;
    if (!isP45Filled) {
      if (!starterDeclaration) {
        return;
      }
      return getNewEmployeeTaxCodeNoP45(starterDeclaration);
    }
    const leave = new Date(leaveDate);
    const start = new Date((_a = user == null ? void 0 : user.startDate) != null ? _a : "");
    if (Number.isNaN(leave.getDate()) || Number.isNaN(start.getDate())) {
      return;
    }
    return getNewEmployeeTaxCodeWithP45(start, leave, p45TaxCode);
  }, [isP45Filled, leaveDate, p45TaxCode, starterDeclaration, user == null ? void 0 : user.startDate]);
  if (newEmployee) {
    Object.keys(newEmployee).forEach((field) => {
      if (formik.values[field] !== newEmployee[field]) {
        formik.setFieldValue(field, newEmployee[field]);
      }
    });
  }
  const canSubmit = [isP45Filled, legalGender, starterDeclaration, studentLoans, niNumber].every((value) => value !== void 0) && (!isP45Filled || !!leaveDate && !!taxCode) && (niNumber === null || !!niNumber) && (studentLoans === null || studentLoans && (studentLoans.postgradLoan || studentLoans.studentLoanPlan !== "None")) && confirmedInfo;
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1 }, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flex: 0, mx: "auto", width: "400px", maxWidth: "90vw", mt: spacing.mt40 }, children: [
      /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.headline1), {
            color: themeColors.DarkGrey
          }),
          children: polyglot.t("OnboardingByUser.payrollOnboarding")
        }
      ),
      /* @__PURE__ */ jsx(CloseButton, { onClick: onClose })
    ] }),
    /* @__PURE__ */ jsx(Stack, { sx: { flex: 1, overflowY: "auto" }, children: /* @__PURE__ */ jsx(Stack, { sx: { mx: "auto", width: "400px", maxWidth: "90vw", gap: spacing.g30 }, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: { my: spacing.g30, gap: spacing.g30 }, children: [
      /* @__PURE__ */ jsx(
        P45Values,
        {
          userId,
          disabled: submitting,
          haveP45: formik.values.isP45Filled,
          setHaveP45: (value) => formik.setFieldValue("isP45Filled", value),
          leaveDate,
          setLeaveDate,
          taxCode: p45TaxCode,
          setTaxCode: (value) => setP45TaxCode(value),
          totalPay: formik.values.openingPreviousGross,
          setTotalPay: (value) => formik.setFieldValue("openingPreviousGross", value),
          taxPaid: formik.values.openingPreviousTax,
          setTaxPaid: (value) => formik.setFieldValue("openingPreviousTax", value)
        }
      ),
      /* @__PURE__ */ jsx(
        LegalGenderSelect,
        {
          gender: formik.values.legalGender,
          setGender: (value) => formik.setFieldValue("legalGender", value),
          disabled: submitting
        }
      ),
      /* @__PURE__ */ jsx(
        NINOEntry,
        {
          niNumber: formik.values.niNumber,
          setNiNumber: (value) => formik.setFieldValue("niNumber", value),
          disabled: submitting
        }
      ),
      // hide starter declaration if the value is determined from P45
      starterDeclaration && !!newEmployee && isP45Filled ? /* @__PURE__ */ jsx(Fragment, {}) : /* @__PURE__ */ jsx(
        StarterDeclarationChoice,
        {
          declaration: formik.values.starterDeclaration,
          setDeclaration: (value) => formik.setFieldValue("starterDeclaration", value),
          disabled: submitting
        }
      ),
      /* @__PURE__ */ jsx(
        StudentLoansOptions,
        {
          setStudentLoans: (studentLoans2) => {
            formik.setFieldValue("studentLoans", studentLoans2);
          },
          value: formik.values.studentLoans,
          disabled: submitting
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "confirmInfo",
          label: polyglot.t("OnboardingByUser.confirmInfo"),
          checked: confirmedInfo,
          onChange: (_, checked) => setConfirmedInfo(checked),
          sx: { mt: spacing.mt20 },
          disabled: submitting
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          fullWidth: true,
          loading: submitting,
          disabled: !canSubmit,
          sizeVariant: "large",
          colorVariant: "primary"
        }
      )
    ] }) }) }) }) })
  ] });
};
