"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, FormControl, CircularProgress } from "@mui/material";
import { BillingAPI, BillingEndpoints } from "@/api-client/billing.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SecondaryHeaderMenu } from "@/v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { BackofficeRootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export function SuperAdminBillingXeroMappingPage({ pageConfig }) {
  var _a, _b;
  const [mappings, setMappings] = useState([]);
  const [changedMappings, setChangedMappings] = useState(/* @__PURE__ */ new Set());
  const [isSaving, setIsSaving] = useState(false);
  const [showMessage] = useMessage();
  const { data: localMappings, isLoading: loadingLocalMapping } = useApiClient(BillingEndpoints.getLocalMapping(), {
    suspense: false
  });
  const {
    data: xeroItemsData,
    isLoading: loadingXeroItems
  } = useApiClient(BillingEndpoints.getXeroItemCodesForMapping(), { suspense: false });
  useEffect(() => {
    if (localMappings) {
      setMappings(localMappings);
      setChangedMappings(/* @__PURE__ */ new Set());
    }
  }, [localMappings]);
  const isLoading = loadingLocalMapping || loadingXeroItems;
  const handleMappingChange = (productId, xeroItemId, xeroItemCode) => {
    setMappings((prevMappings) => {
      const newMappings = [...prevMappings];
      const index = newMappings.findIndex((mapping) => mapping.internalProductCode === productId);
      if (index >= 0) {
        newMappings[index] = __spreadProps(__spreadValues({}, newMappings[index]), {
          xeroItemUuid: xeroItemId,
          xeroItemCode
        });
      }
      return newMappings;
    });
    setChangedMappings((prevChangedMappings) => {
      const newChangedMappings = new Set(prevChangedMappings);
      newChangedMappings.add(productId);
      return newChangedMappings;
    });
  };
  const mappingsChanged = useMemo(() => {
    return changedMappings.size > 0;
  }, [changedMappings]);
  const saveMappings = useCallback(() => __async(this, null, function* () {
    try {
      setIsSaving(true);
      const mappingsToUpdate = mappings.filter((mapping) => changedMappings.has(mapping.internalProductCode));
      if (mappingsToUpdate.length > 0) {
        yield BillingAPI.updateXeroMappings(mappingsToUpdate);
        showMessage("Mappings updated successfully", "success");
        setChangedMappings(/* @__PURE__ */ new Set());
      }
    } catch (error) {
      showMessage(`Failed to update mappings. ${nestErrorMessage(error)}`, "error");
    } finally {
      setIsSaving(false);
    }
  }), [changedMappings, mappings, showMessage]);
  const xeroItemOptions = useMemo(() => {
    return xeroItemsData == null ? void 0 : xeroItemsData.map((item) => ({
      label: item.Code,
      value: item.ItemID
    }));
  }, [xeroItemsData]);
  return /* @__PURE__ */ jsxs(BackofficeRootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Billing" }), showAction: true }),
    ((_a = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _a.tabs) && /* @__PURE__ */ jsx(SecondaryHeaderMenu, { tabs: (_b = pageConfig == null ? void 0 : pageConfig.header) == null ? void 0 : _b.tabs }),
    /* @__PURE__ */ jsx(ContentWrapper, { loading: isLoading, secondLevel: true, children: /* @__PURE__ */ jsxs(Box, { sx: { width: "100%" }, children: [
      isLoading ? /* @__PURE__ */ jsx(CircularProgress, {}) : /* @__PURE__ */ jsxs(Box, { component: "table", sx: { width: "100%", borderCollapse: "collapse" }, children: [
        /* @__PURE__ */ jsx(Box, { component: "thead", children: /* @__PURE__ */ jsxs(Box, { component: "tr", children: [
          /* @__PURE__ */ jsx(Box, { component: "th", sx: { textAlign: "left", borderBottom: "1px solid #ccc", p: spacing.p3 }, children: "Product" }),
          /* @__PURE__ */ jsx(Box, { component: "th", sx: { textAlign: "left", borderBottom: "1px solid #ccc", p: spacing.p3 }, children: "Xero Item Code" })
        ] }) }),
        /* @__PURE__ */ jsx(Box, { component: "tbody", children: mappings.map((mapping) => {
          return /* @__PURE__ */ jsxs(Box, { component: "tr", children: [
            /* @__PURE__ */ jsx(Box, { component: "td", sx: { borderBottom: "1px solid #eee", p: spacing.p3 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: mapping.internalProductCode }) }),
            /* @__PURE__ */ jsx(Box, { component: "td", sx: { borderBottom: "1px solid #eee", p: spacing.p3 }, children: /* @__PURE__ */ jsx(FormControl, { fullWidth: true, variant: "outlined", size: "small", children: /* @__PURE__ */ jsx(
              AutocompleteComponent,
              {
                name: `xero-item-${mapping.internalProductCode}`,
                filterSelectedOptions: true,
                options: xeroItemOptions != null ? xeroItemOptions : [],
                value: (xeroItemOptions == null ? void 0 : xeroItemOptions.find((option) => option.value === mapping.xeroItemUuid)) || null,
                onChange: (_, selectedOption) => {
                  if (selectedOption) {
                    const xeroItemId = selectedOption.value;
                    const xeroItemCode = selectedOption.label;
                    handleMappingChange(mapping.internalProductCode, xeroItemId, xeroItemCode);
                  } else {
                    handleMappingChange(mapping.internalProductCode, "", "");
                  }
                },
                isOptionEqualToValue: (option, value) => option.value === value.value
              }
            ) }) })
          ] }, mapping.internalProductCode);
        }) })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: 2 }, children: /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "primary",
          sizeVariant: "small",
          onClick: saveMappings,
          disabled: !mappingsChanged || isSaving,
          children: isSaving ? "Saving..." : "Save Mappings"
        }
      ) })
    ] }) })
  ] });
}
