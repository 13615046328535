"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import {
  ABSENCE_POLICY_NOTIFICATIONS_REMINDERS_HOURS_OPTIONS,
  getAbsencePolicyNotificationsValidationSchema
} from "@v2/feature/absence/company/policies/pages/absence-policy.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyNotificationsEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  slackChannels,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const SlackChannelsOptions = useMemo(
    () => slackChannels.map((channel) => ({
      value: channel.id,
      label: channel.name
    })),
    [slackChannels]
  );
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyNotifications(absencePolicy.id, values);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, setIsOpen, refresh, absencePolicy, showMessage]
  );
  const formik = useFormik({
    initialValues: {
      notificationsEnabled: absencePolicy.notificationsEnabled,
      remindersSettings: __spreadProps(__spreadValues({}, absencePolicy.remindersSettings), {
        slack: slackChannels.length > 0 ? absencePolicy.remindersSettings.slack : false
        // if no channels found - disable slack
      })
    },
    validationSchema: getAbsencePolicyNotificationsValidationSchema(polyglot),
    onSubmit
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceUtil.notifications") }),
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: polyglot.t("AbsenceUtil.absenceRequests") }),
    /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("EditPolicyNotificationsDrawerContent.sendNotifications") }),
        name: "notificationsEnabled",
        checked: formik.values.notificationsEnabled,
        onChange: formik.handleChange
      }
    ),
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { mt: "10px" }, children: polyglot.t("AbsenceUtil.absenceReminder") }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g20, flexDirection: "column" }, children: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: spacing.g15, flexDirection: "column" }, children: /* @__PURE__ */ jsx(
      CheckboxComponent,
      {
        label: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "headline3",
            sx: { color: slackChannels.length === 0 ? themeColors.Grey : void 0 },
            children: polyglot.t("EditPolicyNotificationsDrawerContent.channel")
          }
        ),
        name: "remindersSettings.slack",
        checked: (_a = formik.values.remindersSettings.slack) != null ? _a : false,
        disabled: slackChannels.length === 0,
        onChange: formik.handleChange
      }
    ) }) }),
    formik.values.remindersSettings.slack && slackChannels.length > 0 && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "remindersSettings.slackChannels",
        label: polyglot.t("EditPolicyNotificationsDrawerContent.reminderSetting"),
        options: SlackChannelsOptions,
        value: formik.values.remindersSettings.slackChannels[0],
        compareValue: formik.values.remindersSettings.slackChannels[0],
        onChange: (e) => {
          const value = e.target.value;
          formik.setFieldValue("remindersSettings.slackChannels", [value]);
        },
        error: !!((_b = formik.errors.remindersSettings) == null ? void 0 : _b.slackChannels) && ((_c = formik.touched.remindersSettings) == null ? void 0 : _c.slackChannels),
        helperText: ((_d = formik.touched.remindersSettings) == null ? void 0 : _d.slackChannels) && ((_e = formik.errors.remindersSettings) == null ? void 0 : _e.slackChannels)
      }
    ),
    formik.values.remindersSettings.slack && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "remindersSettings.remindersScheduledHour",
        label: polyglot.t("EditPolicyNotificationsDrawerContent.reminderSettingSchedule"),
        options: ABSENCE_POLICY_NOTIFICATIONS_REMINDERS_HOURS_OPTIONS,
        value: formik.values.remindersSettings.remindersScheduledHour,
        compareValue: formik.values.remindersSettings.remindersScheduledHour,
        onChange: formik.handleChange,
        error: !!((_f = formik.errors.remindersSettings) == null ? void 0 : _f.remindersScheduledHour) && ((_g = formik.touched.remindersSettings) == null ? void 0 : _g.remindersScheduledHour),
        helperText: ((_h = formik.touched.remindersSettings) == null ? void 0 : _h.remindersScheduledHour) && ((_i = formik.errors.remindersSettings) == null ? void 0 : _i.remindersScheduledHour)
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true
      }
    ) })
  ] }) }) });
};
