"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { APPS_COMPANY_OVERVIEW_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { AppDetailsEndpoints } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { PayrollSettingSectionHeader } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/components/payroll-setting-section-header.component";
import { PayrollAPI, PayrollEndpoints } from "@/v2/feature/payroll/payroll.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { caseInsensitiveSort } from "@/v2/util/array.util";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const PayrollAccountingSettings = ({ payrollId, setAccountEdit, accountEdit, sx }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getScopesContext, hasScopes } = useScopes();
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const scopesContext = getScopesContext({ userId: user.userId });
  const hasAppsScope = hasScopes(["apps"], scopesContext);
  const [selectedTenantId, setSelectedTenantId] = useState(null);
  const {
    data: accountingConfig,
    mutate: refreshAccountingState,
    isValidating: loading
  } = useApiClient(PayrollEndpoints.getAccounting(payrollId), { suspense: false });
  const accountingDefaultApp = "xero";
  const { data: accountingApp } = useApiClient(
    hasAppsScope ? AppDetailsEndpoints.getAppDetails(accountingDefaultApp) : { url: void 0 },
    {
      suspense: false
    }
  );
  useEscapeKey(() => setAccountEdit(false));
  const assignAccountingTenantToPayroll = useCallback(
    (tenantId) => __async(void 0, null, function* () {
      try {
        yield PayrollAPI.assignAccountingAppToPayroll(payrollId, {
          stub: "xero",
          tenantId
        });
        setAccountEdit(false);
        showMessage(polyglot.t("PayrollAccountingSettings.accountingConnected"), "success");
      } catch (e) {
        showMessage(
          polyglot.t("PayrollAccountingSettings.accountingConnectionFailed", { reason: nestErrorMessage(e) }),
          "error"
        );
      }
      refreshAccountingState == null ? void 0 : refreshAccountingState();
    }),
    [payrollId, refreshAccountingState, setAccountEdit, showMessage, polyglot]
  );
  const tenantOptions = useMemo(() => {
    var _a2;
    if (!accountingApp || !(accountingApp == null ? void 0 : accountingApp.tenants) || ((_a2 = accountingApp == null ? void 0 : accountingApp.tenants) == null ? void 0 : _a2.length) === 0) return null;
    return [...accountingApp.tenants].sort((a, b) => caseInsensitiveSort(a, b, (item) => item.tenantName)).map((e) => ({
      label: e.tenantName,
      value: e.tenantId
    }));
  }, [accountingApp]);
  return /* @__PURE__ */ jsxs(Stack, { sx, children: [
    /* @__PURE__ */ jsx(
      PayrollSettingSectionHeader,
      {
        showEditButton: !loading && !accountEdit && (accountingConfig == null ? void 0 : accountingConfig.appInstalled) && !(accountingConfig == null ? void 0 : accountingConfig.invalidatedAt),
        onEditClick: () => {
          var _a2, _b2;
          setSelectedTenantId((_b2 = (_a2 = accountingConfig == null ? void 0 : accountingConfig.organisation) == null ? void 0 : _a2.id) != null ? _b2 : null);
          setAccountEdit(true);
        },
        children: polyglot.t("PayrollAccountingSettings.title")
      }
    ),
    !loading && accountingConfig && accountEdit && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: { font: themeFonts.paragraph }, children: polyglot.t("PayrollAccountingSettings.selectOrganisation") }),
      /* @__PURE__ */ jsxs(Stack, { direction: "column", children: [
        /* @__PURE__ */ jsx(
          RadioGroup,
          {
            name: "external-sync-organisation-group",
            onChange: (event) => {
              var _a2;
              if (!event.target.value) return;
              const matchingTenant = ((_a2 = accountingApp == null ? void 0 : accountingApp.tenants) != null ? _a2 : []).find((t) => t.tenantId === event.target.value);
              if (!matchingTenant) return;
              const { tenantId } = matchingTenant;
              setSelectedTenantId(tenantId);
            },
            children: tenantOptions == null ? void 0 : tenantOptions.map((option) => /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: option.value,
                checked: selectedTenantId ? selectedTenantId === option.value : false,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(Typography, { sx: { font: themeFonts.paragraph }, children: option.label }),
                sx: { mb: spacing.m5 }
              },
              option.value
            ))
          }
        ),
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g10, mt: spacing.m15, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              onClick: () => {
                if (selectedTenantId) assignAccountingTenantToPayroll(selectedTenantId);
              },
              sizeVariant: "medium",
              colorVariant: "primary",
              disabled: !selectedTenantId || selectedTenantId === ((_a = accountingConfig == null ? void 0 : accountingConfig.organisation) == null ? void 0 : _a.id),
              children: polyglot.t("General.save")
            }
          ),
          /* @__PURE__ */ jsx(ButtonComponent, { onClick: () => setAccountEdit(false), sizeVariant: "medium", colorVariant: "secondary", children: polyglot.t("General.cancel") })
        ] })
      ] })
    ] }),
    !loading && accountingConfig && !accountEdit && /* @__PURE__ */ jsx(Stack, { sx: __spreadValues({ gap: spacing.g5 }, sx), children: ((_b = accountingConfig == null ? void 0 : accountingConfig.organisation) == null ? void 0 : _b.name) ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("PayrollAccountingSettings.organisationLabel") }),
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: (_c = accountingConfig == null ? void 0 : accountingConfig.organisation) == null ? void 0 : _c.name })
    ] }) : (accountingConfig == null ? void 0 : accountingConfig.appInstalled) && (accountingConfig == null ? void 0 : accountingConfig.invalidatedAt) ? /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
      polyglot.t("PayrollAccountingSettings.accountingAppInvalidatedFirstPart"),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "text",
          onClick: () => {
            window.open(APPS_COMPANY_OVERVIEW_ROUTE, "_blank");
          },
          style: {
            margin: 0,
            padding: 0,
            color: themeColors.black,
            display: "inline",
            textDecoration: "underline"
          },
          children: "here"
        }
      ),
      polyglot.t("PayrollAccountingSettings.accountingAppInvalidatedSecondPart")
    ] }) : /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("PayrollAccountingSettings.payrollNotLinked") }) })
  ] });
};
