"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { InsuranceAPI } from "@/v2/feature/benefits/subfeature/insurance/insurance.api";
import { fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const validationSchema = yup.object({
  includedUserIds: yup.array().required("Required field").min(1, "Please choose at least user")
});
export const InsuranceUpdateUserPolicyDrawer = ({
  insuranceQuote,
  refresh,
  setIsOpen
}) => {
  var _a;
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: { includedUserIds: (_a = insuranceQuote.includedUserIds) != null ? _a : [] },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        yield InsuranceAPI.updateQuoteMembers(insuranceQuote.id, values.includedUserIds);
        showMessage(`Quote successfully updated`, "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: "Edit employees" }),
    /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      UserSelect,
      {
        label: "Who should be selected?",
        selectedLabel: "Selected employees",
        value: formik.values.includedUserIds,
        onChange: (userIds) => {
          formik.setFieldValue("includedUserIds", userIds);
        },
        error: formik.touched.includedUserIds && Boolean(formik.errors.includedUserIds),
        helperText: formik.touched.includedUserIds && formik.errors.includedUserIds,
        fieldSx: __spreadValues({}, spacing.mb20)
      }
    ) }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(LoaderButton, { name: "Save", sizeVariant: "large", colorVariant: "primary", loading, fullWidth: true }) })
  ] }) }) });
};
