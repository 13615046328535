"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { MissingFieldsDetail } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import {
  AddressForm
} from "@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const MissingAddressFormForPersonalContractSigning = ({
  userId,
  refreshData,
  updateDomainForMissingFields,
  missingFieldsForCategory
}) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("MissingAddressFormForPersonalContractSigning.homeAddress") }),
      /* @__PURE__ */ jsx(IconButton, { onClick: () => setIsModalOpen(true), sx: __spreadProps(__spreadValues({}, secondaryCTABtn), { p: "7px", ml: "auto" }), children: /* @__PURE__ */ jsx(Plus, { width: "16px", height: "16px" }) })
    ] }),
    missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, closeIcon: "back", children: /* @__PURE__ */ jsx(
      AddressForm,
      {
        userId,
        handleRefresh: () => refreshData == null ? void 0 : refreshData(),
        onClose: () => setIsModalOpen(false),
        usedForDataImport: true,
        importHandler: (values) => __async(void 0, null, function* () {
          yield updateDomainForMissingFields == null ? void 0 : updateDomainForMissingFields(values);
          yield refreshData == null ? void 0 : refreshData();
        }),
        showEmployee: true
      }
    ) })
  ] });
};
