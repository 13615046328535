"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { ReportsAPI } from "@v2/feature/reports/reports.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_V2_ROUTE, REPORT_TEAM_REPORTS_V2_ROUTE } from "@/lib/routes";
export const ReportDeleteDrawer = ({ isOpen, setIsOpen, report, refresh, onClose, target }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const deleteReport = useCallback(() => __async(void 0, null, function* () {
    if (!report) return;
    try {
      setIsLoading(true);
      yield ReportsAPI.deleteReport(report.id);
      yield refresh();
      if (target === "all") history.push(REPORT_COMPANY_REPORTS_V2_ROUTE);
      else history.push(REPORT_TEAM_REPORTS_V2_ROUTE);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
    setIsLoading(false);
  }), [showMessage, polyglot, history, report, refresh, target]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Delete report" }),
        /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
          "Are you sure you want to delete the ",
          report == null ? void 0 : report.name,
          " report?"
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
          /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: polyglot.t("General.cancel") }),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "danger",
              fullWidth: true,
              loading: isLoading,
              onClick: deleteReport,
              children: polyglot.t("General.delete")
            }
          )
        ] })
      ] })
    }
  ) });
};
