"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { canAccessScopes } from "@/lib/scopes";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { KeyResultProgressAPI } from "@/v2/feature/growth/goals/features/key-result-progress/key-result-progress.api";
import { KeyResultMutationModal } from "@/v2/feature/growth/goals/features/key-results/components/key-result-mutation-modal.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const KeyResultProgressMutationDrawer = ({
  keyResult,
  progress,
  mode,
  isOpen,
  onClosed,
  onProgressCreated,
  onProgressUpdated
}) => {
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen: (isOpenVal) => {
        if (!isOpenVal) onClosed == null ? void 0 : onClosed();
      },
      onClose: () => {
        onClosed == null ? void 0 : onClosed();
      },
      children: /* @__PURE__ */ jsx(
        KeyResultProgressDrawerContent,
        {
          mode,
          keyResult,
          progress,
          onProgressCreated,
          onProgressUpdated
        }
      )
    }
  );
};
const KeyResultProgressDrawerContent = ({
  keyResult,
  progress,
  mode,
  onProgressCreated,
  onProgressUpdated
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const isAdmin = canAccessScopes(user, ["goals:all"]);
  const isKeyResultOwner = useMemo(() => {
    if (!keyResult) return false;
    return keyResult.ownerIds.includes(user.userId);
  }, [keyResult, user]);
  const canPostProgressUpdates = isKeyResultOwner || isAdmin;
  const [editKeyResultDrawerOpen, setEditKeyResultDrawerOpen] = useState(false);
  const initialProgressValue = mode === "create" ? (_a = keyResult == null ? void 0 : keyResult.lastProgress) != null ? _a : 0 : (_b = progress == null ? void 0 : progress.value) != null ? _b : 0;
  const formik = useFormik({
    initialValues: {
      value: initialProgressValue,
      comment: mode === "edit" ? (_c = progress == null ? void 0 : progress.comment) != null ? _c : "" : ""
    },
    validationSchema: yup.object({
      value: yup.number().min(0, polyglot.t("KeyResultDrawer.errorMessages.minValue")).required(polyglot.t("KeyResultDrawer.errorMessages.valueRequired")),
      comment: yup.string().notRequired()
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      var _a2, _b2, _c2, _d, _e;
      try {
        if (mode === "create") {
          yield KeyResultProgressAPI.createKeyResultProgress((_a2 = keyResult == null ? void 0 : keyResult.goalId) != null ? _a2 : "", (_b2 = keyResult == null ? void 0 : keyResult.id) != null ? _b2 : "", {
            comment: values.comment,
            value: Number(values.value)
          });
          onProgressCreated == null ? void 0 : onProgressCreated();
        } else {
          yield KeyResultProgressAPI.updateKeyResultProgress(
            (_c2 = keyResult == null ? void 0 : keyResult.goalId) != null ? _c2 : "",
            (_d = keyResult == null ? void 0 : keyResult.id) != null ? _d : "",
            (_e = progress == null ? void 0 : progress.id) != null ? _e : "",
            {
              comment: values.comment,
              value: Number(values.value)
            }
          );
          onProgressUpdated == null ? void 0 : onProgressUpdated();
        }
        showMessage(polyglot.t("KeyResultDrawer.keyResultProgressUpdated"), "success");
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  useEffect(() => {
    var _a2;
    if (mode === "edit") return;
    formik.setFieldValue("value", (_a2 = keyResult == null ? void 0 : keyResult.lastProgress) != null ? _a2 : 0);
  }, [keyResult == null ? void 0 : keyResult.lastProgress, mode]);
  if (!keyResult) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(
      Form,
      {
        style: __spreadProps(__spreadValues({}, drawerContentSx), {
          gap: "1.5rem"
        }),
        children: [
          /* @__PURE__ */ jsx(
            TopHeader,
            {
              title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t(
                mode === "create" ? "KeyResultProgressDrawer.title.create" : "KeyResultProgressDrawer.title.edit"
              ) }),
              sx: {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                "& .MuiBox-root": {
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }
            }
          ),
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: "1.5rem" }, children: [
            /* @__PURE__ */ jsx(
              TextfieldComponent,
              {
                name: "value",
                type: "number",
                label: polyglot.t("KeyResultDrawer.formFields.progress"),
                value: formik.values.value,
                onChange: formik.handleChange,
                error: formik.touched.value && !!formik.errors.value,
                helperText: formik.touched.value && formik.errors.value,
                endAdornment: "none",
                fullWidth: true,
                disabled: !canPostProgressUpdates
              }
            ),
            /* @__PURE__ */ jsx(
              RichTextField,
              {
                label: "Comment",
                value: formik.values.comment,
                onChange: (value) => formik.setFieldValue("comment", value),
                error: formik.touched.comment && !!formik.errors.comment,
                helperText: formik.touched.comment && formik.errors.comment,
                disabled: !canPostProgressUpdates
              }
            )
          ] }),
          /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonBoxDrawerSx), { marginTop: "0.5rem" }), children: /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: polyglot.t("General.save"),
              sizeVariant: "medium",
              loading: false,
              colorVariant: "primary",
              fullWidth: true
            }
          ) })
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(
      KeyResultMutationModal,
      {
        mode: "edit",
        goalId: keyResult == null ? void 0 : keyResult.goalId,
        keyResult: keyResult != null ? keyResult : void 0,
        isOpen: editKeyResultDrawerOpen,
        setIsOpen: setEditKeyResultDrawerOpen,
        onClose: () => setEditKeyResultDrawerOpen(false),
        refresh: () => __async(void 0, null, function* () {
          onProgressCreated == null ? void 0 : onProgressCreated();
        })
      }
    )
  ] });
};
