"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ReactComponent as Drag } from "@/images/side-bar-icons/Drag.svg";
import { SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from "@/lib/routes";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReportConfigBackButton } from "@/v2/feature/reports/reports-advanced/components/report-config-back-button.component";
import { SMART_FIELDS } from "@/v2/feature/templates/components/editor";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { textOverflowSx } from "@/v2/styles/root.styles";
export const SideBar = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const onDragStart = (event, content) => {
    var _a;
    (_a = event == null ? void 0 : event.dataTransfer) == null ? void 0 : _a.setData("snippet", ` ${content} `);
  };
  const draggableContent = useMemo(() => {
    return /* @__PURE__ */ jsx(Fragment, { children: SMART_FIELDS.map((snippet) => {
      var _a;
      if (searchInput && !((_a = snippet.label) == null ? void 0 : _a.toLowerCase().includes(searchInput.toLowerCase()))) return null;
      if (snippet == null ? void 0 : snippet.group) {
        return /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "headline3",
            sx: __spreadValues(__spreadValues({}, textOverflowSx), {
              textAlign: "left",
              padding: "1px",
              paddingLeft: 1,
              cursor: "cursor"
            }),
            draggable: false,
            truncateHtml: false,
            children: /* @__PURE__ */ jsx(Fragment, { children: snippet.group })
          },
          snippet.group
        ) }, snippet == null ? void 0 : snippet.group);
      }
      return /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            bgcolor: themeColors.Background,
            borderRadius: radius.br5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "4px",
            marginBottom: "4px"
          },
          onDragStart: (event) => onDragStart(event, snippet.value),
          draggable: true,
          children: [
            /* @__PURE__ */ jsx(Box, { sx: { p: "4px" }, children: /* @__PURE__ */ jsx(Drag, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.Grey })) }),
            /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "paragraph",
                sx: {
                  width: "100%",
                  cursor: "pointer",
                  ":hover": { background: themeColors.GreyHover },
                  p: "4px"
                },
                draggable: true,
                children: snippet.label
              }
            )
          ]
        },
        snippet == null ? void 0 : snippet.value
      );
    }) });
  }, [searchInput]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadProps(__spreadValues({}, drawerContentSx), {
        width: "260px",
        borderRight: borders.background,
        textAlign: "left",
        display: "flex",
        backgroundColor: themeColors.white,
        flexDirection: "column",
        gap: "10px",
        height: null,
        paddingLeft: "20px",
        paddingRight: "10px",
        position: "absolute",
        top: 0,
        left: 60,
        bottom: 0,
        zIndex: 4
      }),
      children: [
        /* @__PURE__ */ jsx(ReportConfigBackButton, { title: "Templates", goBack: () => history.push(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE) }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Placeholders" }),
        /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
          "Drag and drop placeholders into the templates to be dynamically populated when creating a document from it.",
          " "
        ] }),
        /* @__PURE__ */ jsx(
          TableSearch,
          {
            query: searchInput,
            handleChange: (e) => {
              setSearchInput(e.target.value);
            },
            style: { width: "220px" }
          }
        ),
        /* @__PURE__ */ jsx(
          Box,
          {
            style: {
              height: "100%",
              display: "flex",
              justifyContent: "left",
              width: "100%",
              flexDirection: "column",
              overflowY: "scroll",
              paddingRight: "5px"
            },
            children: draggableContent
          }
        )
      ]
    }
  );
};
