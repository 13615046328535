"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment, useMemo } from "react";
import { Box } from "@mui/material";
import { isBefore, isToday } from "date-fns";
import { Typography } from "@/v2/components/typography/typography.component";
import { WidgetLayout } from "@/v2/feature/dashboard/features/components/widget-layout.component";
import { UserCalendarView } from "@/v2/feature/dashboard/features/sections/user-calendar/components/version-big/user-calendar-view.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const UserCalendarVersionbigloader = () => {
  const today = new LocalDate().getDate();
  const weekDaysFromMonToSun = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const calendarData = useMemo(() => {
    const getDaysInMonth = (year, month) => {
      return new Date(year, month + 1, 0).getDate();
    };
    const daysInMonth = getDaysInMonth(today.getFullYear(), today.getMonth());
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1).getDay();
    const result = [];
    let week = [];
    const startDayIndex = firstDay === 0 ? 6 : firstDay - 1;
    for (let day = 0; day < startDayIndex; day++) {
      week.push(/* @__PURE__ */ jsx("td", { className: "cw-table-data" }, `empty-${day}`));
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new LocalDate(new Date(today.getFullYear(), today.getMonth(), day)).getDate();
      const isBeforeToday = isBefore(currentDate, today);
      week.push(
        /* @__PURE__ */ jsx("td", { className: "cw-table-data", children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center"
            },
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "headline3",
                sx: {
                  color: isToday(currentDate) ? themeColors.white : isBeforeToday ? themeColors.Grey : themeColors.DarkGrey,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: isToday(currentDate) ? themeColors.DarkGrey : "transparent",
                  borderRadius: radius.br20,
                  width: "25px",
                  height: "25px"
                },
                children: day
              }
            )
          },
          `${day}-${day}-day`
        ) }, `day-${day}`)
      );
      if ((startDayIndex + day) % 7 === 0) {
        result.push(/* @__PURE__ */ jsx("tr", { children: week }, `week-${result.length}`));
        week = [];
      }
    }
    if (week.length > 0) {
      result.push(/* @__PURE__ */ jsx("tr", { children: week }, `week-${result.length}`));
    }
    return result;
  }, [today]);
  return /* @__PURE__ */ jsx(WidgetLayout, { readOnly: false, size: "big", isLoadingState: true, children: /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
    "div",
    {
      className: "cs-calendar-grid",
      style: {
        pointerEvents: "none"
      },
      children: [
        /* @__PURE__ */ jsx(
          UserCalendarView,
          {
            calendarData,
            weekDaysFromMonToSun,
            headerAction: () => {
            }
          }
        ),
        /* @__PURE__ */ jsxs("div", { style: { height: "100%", overflow: "hidden" }, children: [
          /* @__PURE__ */ jsx("div", { className: "cw-event-list", children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Upcoming" }) }),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                gap: spacing.g25,
                marginTop: spacing.m20
              },
              children: [0, 1, 2, 3, 4].map((num) => /* @__PURE__ */ jsx("div", { className: "ucw-loader-container", children: /* @__PURE__ */ jsx("div", { className: "ucw-progress" }) }, `${num}-ucvb`))
            }
          )
        ] })
      ]
    }
  ) }) });
};
