"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeliveryMethod, DevicePossessionType } from "@v2/feature/device/device.interface";
import { DeliveryMethodsOptions } from "@v2/feature/device/device.util";
import { UserAddressAPI } from "@v2/feature/user/features/user-forms/user-address/user-address.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { formatAddress } from "@v2/util/user-data.util";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { SiteAPI } from "@/api-client/site.api";
import { GlobalContext, GlobalStateActions } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from "@/lib/routes";
export const DeviceInventoryReassignDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(DeviceInventoryAssignDrawerContent, { devicePossession }) });
export const DeviceInventoryAssignDrawerContent = ({
  devicePossession
}) => {
  var _a, _b;
  const [loading, setLoading] = useState(false);
  const [sitesOptions, setSitesOptions] = useState([]);
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();
  const formatDate = () => {
    const date = /* @__PURE__ */ new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const containsSpecialCharacters = (input) => {
    const regex = /[^a-zA-Z0-9]/;
    return regex.test(input);
  };
  const formik = useFormik({
    initialValues: {
      possessionType: DevicePossessionType.User,
      possessionId: void 0,
      deliveryMethod: DeliveryMethod.ShippingToEmployee,
      deliveryAddress: "",
      date: formatDate(),
      deviceAccountName: ""
    },
    validationSchema: yup.object({
      possessionType: yup.string().required(),
      possessionId: yup.number().typeError("A receiver should be selected").required("A receiver should be selected"),
      deliveryMethod: yup.string().required("A delivery method should be selected"),
      deliveryAddress: yup.string().when("deliveryMethod", {
        is: (val) => val === DeliveryMethod.ShippingToEmployee || val === DeliveryMethod.ShippingToSite,
        then: (schema) => schema.required("Delivery Address is required"),
        otherwise: (schema) => schema.nullable().notRequired()
      }),
      deviceAccountName: yup.string().nullable().notRequired().test("no-special-chars", "Device account name contains invalid characters or space", (value) => {
        if (!value) return true;
        return !containsSpecialCharacters(value);
      })
    }),
    enableReinitialize: true,
    onSubmit: (formData) => __async(void 0, null, function* () {
      let isOwnerChangeInitiated = false;
      try {
        if (!formData.possessionId) {
          showMessage("A receiver should be selected", "error");
          return;
        }
        if (!formData.deliveryMethod) {
          showMessage("A delivery method should be selected", "error");
          return;
        }
        if (formData.deliveryMethod === DeliveryMethod.ShippingToEmployee && !formData.deliveryAddress) {
          showMessage("Delivery date should be provided", "error");
          return;
        }
        if (formData.deliveryMethod === DeliveryMethod.Handover && !formData.date) {
          showMessage("Handover date should be provided", "error");
          return;
        }
        let deliveryDetails;
        if (formData.deliveryMethod === DeliveryMethod.AssignDepToUser) {
          deliveryDetails = {
            deliveryMethod: formData.deliveryMethod,
            deliveryAddress: void 0,
            date: null,
            deviceAccountName: formData.deviceAccountName
          };
        } else {
          deliveryDetails = {
            deliveryMethod: formData.deliveryMethod,
            deliveryAddress: formData.deliveryMethod !== DeliveryMethod.Handover ? formData.deliveryAddress : void 0,
            date: formData.deliveryMethod === DeliveryMethod.Handover || devicePossession.possessionType === DevicePossessionType.ZeltStorage ? formData.date : null
          };
        }
        const newPossessionDetails = {
          possessionType: formData.possessionType,
          possessionId: formData.possessionId
        };
        setLoading(true);
        if (formData.deliveryMethod === DeliveryMethod.AssignDepToUser) {
          yield DeviceAPI.assignDepDeviceToUser(devicePossession.id, newPossessionDetails, deliveryDetails);
        } else {
          yield DeviceAPI.reassignInventoryDevicePossession(devicePossession.id, newPossessionDetails, deliveryDetails);
        }
        showMessage("Device owner updated.", "success");
        isOwnerChangeInitiated = true;
        const alertsForDevices = yield DeviceAPI.getAlerts(state.user.userId);
        routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
        dispatch({
          type: GlobalStateActions.UPDATE_ALERTS,
          payload: { devices: alertsForDevices }
        });
      } catch (error) {
        if (isOwnerChangeInitiated) {
          showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, "error");
        } else {
          showMessage(`Could not reassign device: ${nestErrorMessage(error)}`, "error");
        }
      } finally {
        setLoading(false);
      }
    })
  });
  const setUserAddress = useCallback(
    (assignedUserId) => __async(void 0, null, function* () {
      try {
        const userAddress = yield UserAddressAPI.findByUserId(assignedUserId);
        if (userAddress && (userAddress == null ? void 0 : userAddress.effectiveRecord)) {
          const effectiveAddress = userAddress.effectiveRecord;
          return formatAddress(effectiveAddress);
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, "error");
      }
      return "";
    }),
    [showMessage]
  );
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      try {
        const sites = yield SiteAPI.listSites();
        setSitesOptions(
          sites.filter(
            (site) => !(devicePossession.possessionType === DevicePossessionType.CompanySite && site.id === devicePossession.possessionId)
          ).map((site) => {
            var _a2;
            return { value: site.id, label: site.name, address: (_a2 = site.address) != null ? _a2 : "" };
          })
        );
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, "error");
      }
    }))();
  }, [showMessage, devicePossession]);
  const additionalOption = {
    label: "Assign dep device to user",
    value: DeliveryMethod.AssignDepToUser
  };
  const options = DeliveryMethodsOptions.filter(
    (o) => devicePossession.possessionType === DevicePossessionType.ZeltStorage ? o.value !== DeliveryMethod.Handover : o.value !== DeliveryMethod.ShippingToSite
  );
  options.push(additionalOption);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Assign device" }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "deliveryMethod",
        label: "Assign method",
        options,
        value: formik.values.deliveryMethod,
        compareValue: formik.values.deliveryMethod,
        onChange: (e) => {
          formik.handleChange(e);
          formik.setFieldValue(
            "possessionType",
            e.target.value === DeliveryMethod.ShippingToSite ? DevicePossessionType.CompanySite : DevicePossessionType.User
          );
          formik.setFieldValue("possessionId", void 0);
          formik.setFieldValue("deliveryAddress", "");
          formik.setFieldValue("deliveryDate", null);
          formik.setFieldValue("deviceAccountName", null);
        },
        error: !!formik.errors.deliveryMethod && formik.touched.deliveryMethod,
        helperText: formik.touched.deliveryMethod && formik.errors.deliveryMethod
      }
    ),
    [DeliveryMethod.ShippingToEmployee, DeliveryMethod.Handover, DeliveryMethod.AssignDepToUser].includes(
      formik.values.deliveryMethod
    ) ? /* @__PURE__ */ jsx(
      SingleUserSelect,
      {
        name: "userId",
        options: "company",
        value: formik.values.possessionId,
        onChange: (_, x) => __async(void 0, null, function* () {
          var _a2;
          const userId = (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null;
          yield formik.setFieldValue("possessionId", userId);
          if (userId) {
            const address = yield setUserAddress(userId);
            yield formik.setFieldValue("deliveryAddress", address);
          }
        }),
        label: "Employee",
        error: Boolean(formik.errors.possessionId),
        helperText: formik.errors.possessionId
      }
    ) : /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "possessionId",
        label: "Site",
        options: sitesOptions,
        value: formik.values.possessionId,
        compareValue: formik.values.possessionId,
        error: !!formik.errors.possessionId && formik.touched.possessionId,
        helperText: formik.touched.possessionId && formik.errors.possessionId,
        onChange: (e) => {
          var _a2, _b2;
          formik.handleChange(e);
          const siteAddress = (_b2 = (_a2 = sitesOptions.find((s) => s.value === e.target.value)) == null ? void 0 : _a2.address) != null ? _b2 : "";
          formik.setFieldValue("deliveryAddress", siteAddress);
        }
      }
    ),
    [DeliveryMethod.ShippingToEmployee, DeliveryMethod.ShippingToSite].includes(formik.values.deliveryMethod) && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "deliveryAddress",
        label: "Delivery address",
        value: (_a = formik.values.deliveryAddress) != null ? _a : "",
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.deliveryAddress && !!formik.errors.deliveryAddress,
        helperText: formik.touched.deliveryAddress && formik.errors.deliveryAddress,
        clearText: () => formik.setFieldValue("deliveryAddress", "")
      }
    ),
    formik.values.deliveryMethod === DeliveryMethod.AssignDepToUser && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "deviceAccountName",
        label: "Device account name",
        value: (_b = formik.values.deviceAccountName) != null ? _b : "",
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.deviceAccountName && !!formik.errors.deviceAccountName,
        clearText: () => formik.setFieldValue("deviceAccountName", ""),
        helperText: `To set up your device with DEP, enter a name for the account. If not provided, the user\u2019s first name will be used as the account name. The temporary password will be set to \u2018poweredbyZelt\u2019`
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Assign",
        loading,
        disabled: !formik.isValid,
        colorVariant: "primary",
        sizeVariant: "medium",
        fullWidth: true
      }
    ) })
  ] }) });
};
