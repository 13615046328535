"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { DEBOUNCE_500_MS } from "@v2/feature/documents/documents.util";
import { DEFAULT_CURRENCY } from "@v2/feature/payments/payments.interface";
import { CustomProfileFormType } from "@v2/feature/user/features/user-profile/details/user-profile.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import { keyBy } from "lodash";
import * as Yup from "yup";
import { CompensationAPI, CompensationEndpoints } from "@/api-client/compensation.api";
import { UserContractEndpoints } from "@/api-client/contracts.api";
import useMessage from "@/hooks/notification.hook";
import { displayRateLabel, getSalaryBasisOptions, PaySchedules, PayTypes, SalaryBasis } from "@/lib/employment";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { useAdminOnboardingFieldsContext } from "@/v2/feature/onboarding/context/admin-onboarding-fields.context";
import { payScheduleUnit } from "@/v2/feature/payroll/features/payroll-uk/payroll-uk.util";
import { UserCustomField } from "@/v2/feature/user/components/user-custom-field.component";
import {
  PayScheduleEnum,
  PayTypeEnum,
  SalaryBasisEnum
} from "@/v2/feature/user/features/user-forms/user-compensation/user-compensation.dto";
import { originalValueOrUndefined } from "@/v2/feature/user/features/user-profile/details/components/user-profile-compensation-form.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { popularCurrencyShortOptions } from "@/v2/infrastructure/currency/currency.interface";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { spacing } from "@/v2/styles/spacing.styles";
import { formatAsCompensationCurrency } from "@/v2/util/string-format.util";
const NewUserCompensationSchema = (defaultFieldsMap, polyglot) => {
  return Yup.object().shape({
    effectiveDate: defaultFieldsMap["compensation.effectiveDate"] && defaultFieldsMap["compensation.effectiveDate"].isRequired ? Yup.string().test(dateFieldTest).required(polyglot.t("validation.requiredField")) : Yup.string().test(dateFieldTest).nullable().notRequired(),
    paySchedule: defaultFieldsMap["compensation.paySchedule"] && defaultFieldsMap["compensation.paySchedule"].isRequired ? Yup.string().trim().oneOf(PaySchedules(polyglot).map((eachSchedule) => eachSchedule.value)).required(polyglot.t("validation.requiredField")) : Yup.string().nullable().notRequired(),
    salaryBasis: defaultFieldsMap["compensation.salaryBasis"] && defaultFieldsMap["compensation.salaryBasis"].isRequired ? Yup.string().trim().oneOf(SalaryBasis(polyglot).map((eachBasis) => eachBasis.value)).required(polyglot.t("validation.requiredField")) : Yup.string().nullable().notRequired(),
    rate: defaultFieldsMap["compensation.rate"] && defaultFieldsMap["compensation.rate"].isRequired ? Yup.number().typeError(polyglot.t("validation.selectValid")).min(0, polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")) : Yup.number().nullable().notRequired(),
    units: defaultFieldsMap["compensation.units"] && defaultFieldsMap["compensation.units"].isRequired ? Yup.number().nullable().notRequired().when(["paySchedule", "salaryBasis", "payType"], {
      is: (paySchedule, salaryBasis, payType) => paySchedule && [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(salaryBasis) && payType === PayTypeEnum.Salaried,
      then: (schema) => schema.typeError(polyglot.t("validation.selectValid")).min(1, polyglot.t("validation.greaterThanZero")).required(polyglot.t("validation.requiredField"))
    }) : Yup.number().nullable().notRequired(),
    currency: defaultFieldsMap["compensation.currency"] && defaultFieldsMap["compensation.currency"].isRequired ? Yup.string().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField")) : Yup.string().nullable().notRequired(),
    minimumContractedHoursInMinutes: Yup.number().nullable().notRequired()
  });
};
export const NewEmployeeCompensationSection = ({
  compensation,
  onNext,
  sx,
  userId,
  startDate
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p;
  const { polyglot } = usePolyglot();
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const { data: userFtePercentage } = useApiClient(CompensationEndpoints.getFTEPercentageForUser(userId), {
    suspense: false
  });
  const { data: currentContract } = useApiClient(UserContractEndpoints.findCurrentByUserId(userId), {
    suspense: false
  });
  const { getCachedDefaultFieldsByFormName, getCachedCustomFieldsByFormName } = useAdminOnboardingFieldsContext();
  const defaultFields = getCachedDefaultFieldsByFormName(CustomProfileFormType.Salary);
  const customFields = getCachedCustomFieldsByFormName(CustomProfileFormType.Salary);
  const defaultFieldsMap = useMemo(() => {
    return keyBy(defaultFields, "fieldStub");
  }, [defaultFields]);
  const customFieldIds = useMemo(() => new Set((customFields != null ? customFields : []).map((field) => field.fieldId)), [customFields]);
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2, _j2, _k2, _l2, _m2, _n2, _o2, _p2, _q, _r;
    const values = {};
    if (defaultFieldsMap["compensation.paySchedule"])
      values.paySchedule = (_a2 = compensation == null ? void 0 : compensation.paySchedule) != null ? _a2 : PayScheduleEnum.Monthly;
    if (defaultFieldsMap["compensation.salaryBasis"])
      values.salaryBasis = (_b2 = compensation == null ? void 0 : compensation.salaryBasis) != null ? _b2 : SalaryBasisEnum.Annual;
    if (defaultFieldsMap["compensation.currency"])
      values.currency = (_e2 = (_d2 = compensation == null ? void 0 : compensation.currency) != null ? _d2 : (_c2 = currentContract == null ? void 0 : currentContract.entity) == null ? void 0 : _c2.currency) != null ? _e2 : DEFAULT_CURRENCY;
    if (defaultFieldsMap["compensation.rate"])
      values.rate = (compensation == null ? void 0 : compensation.prorateSalaryByFte) ? (_f2 = compensation.nonProratedRate) != null ? _f2 : 0 : (_g2 = compensation == null ? void 0 : compensation.rate) != null ? _g2 : 0;
    if (defaultFieldsMap["compensation.units"]) values.units = (_h2 = compensation == null ? void 0 : compensation.units) != null ? _h2 : void 0;
    if (defaultFieldsMap["compensation.effectiveDate"])
      values.effectiveDate = (_i2 = compensation == null ? void 0 : compensation.effectiveDate) != null ? _i2 : new LocalDate(startDate).toDateString();
    values.payType = (_j2 = compensation == null ? void 0 : compensation.payType) != null ? _j2 : PayTypeEnum.Salaried;
    values.prorateSalaryByFte = (_k2 = compensation == null ? void 0 : compensation.prorateSalaryByFte) != null ? _k2 : false;
    values.proratedRate = compensation ? (compensation == null ? void 0 : compensation.prorateSalaryByFte) ? (_l2 = compensation == null ? void 0 : compensation.rate) != null ? _l2 : 0 : 0 : 0;
    values.nonProratedRate = (_m2 = compensation == null ? void 0 : compensation.nonProratedRate) != null ? _m2 : void 0;
    values.annualSalary = (_o2 = (_n2 = compensation == null ? void 0 : compensation.compensationBreakdown) == null ? void 0 : _n2.fixedRates) == null ? void 0 : _o2.annual;
    values.salaryPerPaySchedule = (_p2 = compensation == null ? void 0 : compensation.compensationBreakdown) == null ? void 0 : _p2.payScheduleRate;
    values.customUpdates = (_r = (_q = compensation == null ? void 0 : compensation.customUpdates) == null ? void 0 : _q.filter((update) => customFieldIds.has(update.fieldId))) != null ? _r : [];
    values.minimumContractedHoursInMinutes = (compensation == null ? void 0 : compensation.minimumContractedHoursInMinutes) ? compensation.minimumContractedHoursInMinutes / 60 : null;
    return values;
  }, [defaultFieldsMap, compensation, customFieldIds, startDate, (_a = currentContract == null ? void 0 : currentContract.entity) == null ? void 0 : _a.currency]);
  const availableUserFtePercentage = useMemo(() => {
    if (userFtePercentage && !isNaN(userFtePercentage)) return userFtePercentage;
    return 0;
  }, [userFtePercentage]);
  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: NewUserCompensationSchema(defaultFieldsMap, polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2;
      setSaving(true);
      try {
        const updatedValues = __spreadProps(__spreadValues({}, values), {
          units: [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes((_a2 = formik.values) == null ? void 0 : _a2.salaryBasis) ? values.units : 0,
          rate: formik.values.prorateSalaryByFte ? (_b2 = formik.values.proratedRate) != null ? _b2 : null : formik.values.rate,
          nonProratedRate: (_c2 = formik.values.rate) != null ? _c2 : void 0,
          minimumContractedHoursInMinutes: values.payType === PayTypeEnum.Hourly && values.minimumContractedHoursInMinutes ? values.minimumContractedHoursInMinutes * 60 : null
        });
        delete updatedValues.proratedRate;
        if (compensation) {
          yield CompensationAPI.updateById(userId, __spreadProps(__spreadValues({}, updatedValues), {
            id: compensation.id
          }));
        } else {
          yield CompensationAPI.create(userId, __spreadValues({}, updatedValues));
        }
        onNext();
      } catch (error) {
        showMessage(
          polyglot.t("NewEmployeeCompensationSection.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  const onChangeRate = useCallback(
    (objToCheck) => {
      if (objToCheck) {
        const { rate, prorateSalaryByFte } = objToCheck;
        const rateValue = rate != null ? rate : 0;
        const proratedRate = prorateSalaryByFte && availableUserFtePercentage ? availableUserFtePercentage * rateValue : rateValue;
        formik.setFieldValue("rate", rate, true);
        formik.setFieldValue("proratedRate", originalValueOrUndefined(proratedRate), true);
      }
    },
    [availableUserFtePercentage, formik]
  );
  const proratedSuffix = useMemo(() => {
    return formik.values.prorateSalaryByFte && availableUserFtePercentage ? "- Prorated".concat(" (" + availableUserFtePercentage * 100 + "%)") : "";
  }, [formik.values.prorateSalaryByFte, availableUserFtePercentage]);
  const hasSubmitted = formik.submitCount > 0;
  const modalUnitLabel = (objToCheck) => {
    return (objToCheck == null ? void 0 : objToCheck.salaryBasis) === "Daily" ? polyglot.t("NewEmployeeCompensationSection.days") : polyglot.t("NewEmployeeCompensationSection.hours");
  };
  const modalSalaryPerLabel = (objToCheck) => {
    return (objToCheck == null ? void 0 : objToCheck.paySchedule) === "Monthly" ? polyglot.t("NewEmployeeCompensationSection.month") : polyglot.t("NewEmployeeCompensationSection.week");
  };
  const getCalculatedSalaryRates = useCallback(
    (compensation2) => __async(void 0, null, function* () {
      var _a2;
      try {
        const updatedComp = yield CompensationAPI.calculateSalaryFixedRates(userId, __spreadProps(__spreadValues({}, compensation2), {
          currency: (_a2 = formik.values.currency) != null ? _a2 : DEFAULT_CURRENCY
        }));
        return updatedComp;
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
        return null;
      }
    }),
    [showMessage, polyglot, userId, formik.values.currency]
  );
  const debouncedCall = useCallback(() => __async(void 0, null, function* () {
    var _a2;
    if (!getCalculatedSalaryRates || !formik.values.paySchedule || !formik.values.salaryBasis) return;
    getCalculatedSalaryRates({
      rate: (_a2 = formik.values.rate) != null ? _a2 : 0,
      paySchedule: formik.values.paySchedule,
      salaryBasis: formik.values.salaryBasis,
      units: formik.values.units
    }).then((compBreakdown) => {
      var _a3, _b2;
      formik.setFieldValue("annualSalary", (_a3 = compBreakdown == null ? void 0 : compBreakdown.fixedRates.annual) != null ? _a3 : "");
      formik.setFieldValue("salaryPerPaySchedule", (_b2 = compBreakdown == null ? void 0 : compBreakdown.payScheduleRate) != null ? _b2 : "");
    }).catch((error) => {
      showMessage(nestErrorMessage(error, polyglot), "error");
    });
  }), [
    showMessage,
    polyglot,
    getCalculatedSalaryRates,
    formik.values.rate,
    formik.values.paySchedule,
    formik.values.salaryBasis,
    formik.values.units
  ]);
  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedCall();
    }, DEBOUNCE_500_MS);
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedCall]);
  const salaryBasisOptions = useMemo(() => {
    return getSalaryBasisOptions(
      polyglot,
      formik.values.paySchedule,
      formik.values.payType,
      initialValues == null ? void 0 : initialValues.salaryBasis
    );
  }, [polyglot, initialValues == null ? void 0 : initialValues.salaryBasis, formik.values.paySchedule, formik.values.payType]);
  useEffect(() => {
    if (salaryBasisOptions.length > 0 && !salaryBasisOptions.find((option) => option.value === formik.values.salaryBasis)) {
      formik.setFieldValue("salaryBasis", salaryBasisOptions[0].value);
    }
  }, [formik.values.paySchedule, salaryBasisOptions]);
  const handlePayTypeChange = (event) => {
    const payType = event.target.value;
    if (payType === PayTypeEnum.Hourly) {
      formik.setFieldValue("salaryBasis", SalaryBasisEnum.Hourly);
      formik.setFieldValue("units", 0);
      formik.setFieldValue("prorateSalaryByFte", false);
    } else {
      formik.setFieldValue("minimumContractedHoursInMinutes", null);
    }
    formik.handleChange(event);
  };
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(Stack, { sx: __spreadValues({ gap: spacing.g30 }, sx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("CompensationForm.compensation") }),
    defaultFieldsMap["compensation.effectiveDate"] && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_b = formik.values.effectiveDate) != null ? _b : null,
        onChange: (values) => formik.setFieldValue("effectiveDate", values),
        name: "effectiveDate",
        label: polyglot.t("CompensationCard.effectiveDate"),
        error: !!formik.errors.effectiveDate && formik.touched.effectiveDate,
        helperText: formik.errors.effectiveDate && formik.touched.effectiveDate
      }
    ),
    defaultFieldsMap["compensation.salaryBasis"] && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "payType",
        label: polyglot.t("CompensationForm.payType"),
        options: PayTypes(polyglot),
        value: formik.values.payType,
        compareValue: formik.values.payType,
        error: !!formik.errors.payType && formik.touched.payType,
        onChange: handlePayTypeChange,
        helperText: formik.errors.payType && formik.touched.payType,
        required: (_c = defaultFieldsMap["compensation.salaryBasis"]) == null ? void 0 : _c.isRequired
      }
    ),
    defaultFieldsMap["compensation.paySchedule"] && /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "paySchedule",
        label: polyglot.t("CompensationForm.paySchedule"),
        options: PaySchedules(polyglot),
        value: formik.values.paySchedule,
        onChange: formik.handleChange,
        disabled: saving,
        helperText: hasSubmitted && formik.errors.paySchedule,
        error: hasSubmitted && !!formik.errors.paySchedule,
        required: (_d = defaultFieldsMap["compensation.paySchedule"]) == null ? void 0 : _d.isRequired
      }
    ),
    formik.values.payType === PayTypeEnum.Salaried && defaultFieldsMap["compensation.salaryBasis"] && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "salaryBasis",
          label: polyglot.t("CompensationForm.salaryBasis"),
          options: salaryBasisOptions,
          value: formik.values.salaryBasis,
          compareValue: formik.values.salaryBasis,
          onChange: handlePayTypeChange,
          disabled: saving,
          helperText: hasSubmitted && formik.errors.salaryBasis,
          error: hasSubmitted && !!formik.errors.salaryBasis,
          required: (_e = defaultFieldsMap["compensation.salaryBasis"]) == null ? void 0 : _e.isRequired
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          label: polyglot.t("CompensationForm.prorateSalaryByFte"),
          name: "prorateSalaryByFte",
          checked: formik.values.prorateSalaryByFte,
          onChange: (_, value) => {
            formik.setFieldValue("prorateSalaryByFte", value);
            onChangeRate(__spreadProps(__spreadValues({}, formik.values), {
              prorateSalaryByFte: value
            }));
          }
        }
      ),
      formik.values.prorateSalaryByFte && (currentContract == null ? void 0 : currentContract.attendanceSchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { mt: spacing.m10 }, children: polyglot.t("CompensationForm.prorateSalaryByFteDescription", {
        percentage: currentContract.attendanceSchedule.ftePercent,
        workHours: convertMinutesToClockHours(currentContract.attendanceSchedule.totalTime, polyglot),
        fteHours: convertMinutesToClockHours(currentContract.attendanceSchedule.fteEquivalent, polyglot)
      }) }) : formik.values.prorateSalaryByFte && !(currentContract == null ? void 0 : currentContract.attendanceSchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "RedDark", sx: { mt: spacing.m10 }, children: polyglot.t("CompensationForm.noScheduleMessage") }) : null
    ] }),
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
      defaultFieldsMap["compensation.currency"] && /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "currency",
          label: polyglot.t("CompensationForm.currency"),
          options: popularCurrencyShortOptions,
          value: formik.values.currency,
          onChange: formik.handleChange,
          disabled: saving,
          helperText: hasSubmitted && formik.errors.currency,
          error: hasSubmitted && !!formik.errors.currency,
          sx: { flexBasis: "50%" },
          required: (_f = defaultFieldsMap["compensation.currency"]) == null ? void 0 : _f.isRequired
        }
      ),
      defaultFieldsMap["compensation.rate"] && /* @__PURE__ */ jsx(
        MoneyTextfieldComponent,
        {
          name: "rate",
          label: displayRateLabel(formik.values.salaryBasis, polyglot),
          value: (_g = formik.values.rate) != null ? _g : 0,
          onChange: (newValue) => {
            formik.setFieldValue("rate", newValue);
            onChangeRate(__spreadProps(__spreadValues({}, formik.values), {
              rate: newValue ? Number(newValue) : 0
            }));
          },
          autoFocus: true,
          clearToZero: true,
          onClearChange: (newValue) => {
            formik.setFieldValue("rate", newValue);
            onChangeRate(__spreadProps(__spreadValues({}, formik.values), {
              rate: newValue ? Number(newValue) : 0
            }));
          },
          required: (_h = defaultFieldsMap["compensation.rate"]) == null ? void 0 : _h.isRequired
        }
      )
    ] }),
    formik.values.payType === PayTypeEnum.Salaried && /* @__PURE__ */ jsxs(Fragment, { children: [
      ((_i = formik.values) == null ? void 0 : _i.salaryBasis) && [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes((_j = formik.values) == null ? void 0 : _j.salaryBasis) && defaultFieldsMap["compensation.units"] && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "units",
          label: polyglot.t("NewEmployeeCompensationSection.units", {
            unitLabel: modalUnitLabel(formik.values),
            salary: modalSalaryPerLabel(formik.values)
          }),
          value: formik.values.units,
          type: "number",
          onChange: formik.handleChange,
          error: formik.touched.units && !!formik.errors.units,
          helperText: (_k = formik.touched.units && formik.errors.units) != null ? _k : " ",
          clearText: () => formik.setFieldValue("units", ""),
          required: (_l = defaultFieldsMap["compensation.units"]) == null ? void 0 : _l.isRequired
        }
      ),
      (Boolean(formik.values.salaryPerPaySchedule) !== null || Boolean(formik.values.salaryPerPaySchedule) !== void 0) && (Boolean(formik.values.paySchedule) !== null || Boolean(formik.values.paySchedule) !== void 0) && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("NewEmployeeCompensationSection.salaryPer", {
          paySchedule: payScheduleUnit((_m = formik.values.paySchedule) != null ? _m : PayScheduleEnum.Monthly),
          proRated: proratedSuffix
        }) }),
        !Number.isNaN(formik.values.salaryPerPaySchedule) ? /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatAsCompensationCurrency(formik.values.currency, (_n = formik.values.salaryPerPaySchedule) != null ? _n : 0) }) : /* @__PURE__ */ jsx(Box, {})
      ] }),
      (Boolean(formik.values.annualSalary) !== null || Boolean(formik.values.annualSalary) !== void 0) && formik.values.salaryBasis && ["Monthly", "Annual"].includes(formik.values.salaryBasis) && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("NewEmployeeCompensationSection.annualSalaryGross", { salary: proratedSuffix }) }),
        !Number.isNaN(formik.values.annualSalary) ? /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatAsCompensationCurrency(formik.values.currency, (_o = formik.values.annualSalary) != null ? _o : 0) }) : /* @__PURE__ */ jsx(Box, {})
      ] })
    ] }),
    formik.values.payType === PayTypeEnum.Hourly && defaultFieldsMap["compensation.salaryBasis"] && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "minimumContractedHoursInMinutes",
        label: polyglot.t("CompensationCard.minimumContractedHours"),
        value: formik.values.minimumContractedHoursInMinutes,
        onChange: formik.handleChange,
        error: formik.touched.minimumContractedHoursInMinutes && !!formik.errors.minimumContractedHoursInMinutes,
        helperText: formik.touched.minimumContractedHoursInMinutes && formik.errors.minimumContractedHoursInMinutes,
        type: "number"
      }
    ),
    /* @__PURE__ */ jsx(
      UserCustomField,
      {
        values: (_p = formik.values.customUpdates) != null ? _p : [],
        formFields: customFields != null ? customFields : [],
        onChange: (values) => formik.setFieldValue("customUpdates", values),
        disabled: saving,
        fieldSx: {}
      }
    ),
    /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.continue"),
        loading: saving,
        colorVariant: "primary",
        sizeVariant: "large",
        fullWidth: true,
        disabled: !formik.isValid
      }
    )
  ] }) }) });
};
