"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { InsurancePolicyDocuments } from "@v2/feature/benefits/subfeature/insurance/components/insurance-policy-documents.component";
import { InsuranceProviderDetails } from "@v2/feature/benefits/subfeature/insurance/components/insurance-provider-details.component";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { BENEFITS_COMPANY_ROUTE } from "@/lib/routes";
import { InsuranceEmptyState } from "@/v2/feature/benefits/subfeature/insurance/components/insurance-empty-state.component";
import { InsurancePendingDetails } from "@/v2/feature/benefits/subfeature/insurance/components/insurance-pending-details.component";
export const InsuranceCompanyOverviewPage = ({
  insurancePolicy,
  loading,
  insuranceQuote,
  refresh
}) => {
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: "Insurance", showBack: true, backPath: BENEFITS_COMPANY_ROUTE }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      insurancePolicy && /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb40 }, children: /* @__PURE__ */ jsx(InsuranceProviderDetails, { insurancePolicy }) }),
      (insurancePolicy == null ? void 0 : insurancePolicy.uploadedDocuments) && insurancePolicy.uploadedDocuments.length > 0 && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt40 }, children: /* @__PURE__ */ jsx(InsurancePolicyDocuments, { insurancePolicy }) }),
      !insurancePolicy && (insuranceQuote == null ? void 0 : insuranceQuote.status) === "Pending" && /* @__PURE__ */ jsx(InsurancePendingDetails, { insuranceQuote, refresh }),
      !insurancePolicy && !insuranceQuote && /* @__PURE__ */ jsx(InsuranceEmptyState, {})
    ] })
  ] });
};
