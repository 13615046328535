"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DevicePossessionDrawer } from "@v2/feature/super-admin/features/helper-dashboard/components/device-possession-drawer.component";
import {
  SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE,
  SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE
} from "@v2/feature/super-admin/features/helper-dashboard/helper.router";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { iconSize } from "@v2/styles/table.styles";
import { generatePath, Route, Switch, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { nestErrorMessage } from "@/lib/errors";
import { getDateString } from "@/v2/components/forms/date-label.component";
export const HelperDevicesRouter = ({ company }) => {
  const [loading, setLoading] = useState(true);
  const [showMessage] = useMessage();
  const params = useParams();
  const companyId = Number(params.companyId);
  const [devicePossessions, setDevicePossessions] = useState([]);
  const [selectedDevicePossession, setSelectedDevicePossession] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [noOfActivePossessionsOfDevices, setNoOfActivePossessionsOfDevices] = useState(
    {}
  );
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const devicePossessions2 = yield DeviceAPI.getAllDevicePossessionsByCompanyId(companyId);
      setDevicePossessions(devicePossessions2);
      const mapWithDevicesOccurrencesInActivePossessions = {};
      for (const devicePossession of devicePossessions2) {
        if (devicePossession.startDate && !devicePossession.endDate && !mapWithDevicesOccurrencesInActivePossessions[devicePossession.deviceId]) {
          mapWithDevicesOccurrencesInActivePossessions[devicePossession.deviceId] = 1;
        } else if (devicePossession.startDate && !devicePossession.endDate) {
          mapWithDevicesOccurrencesInActivePossessions[devicePossession.deviceId] += 1;
        }
      }
      setNoOfActivePossessionsOfDevices(mapWithDevicesOccurrencesInActivePossessions);
    } catch (error) {
      showMessage(`Error loading device possessions: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage, companyId]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const columnData = useMemo(() => {
    return [
      {
        header: () => "Id",
        accessorFn: (row) => row,
        id: "id",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: possession.id });
        },
        maxSize: 35,
        minSize: 35
      },
      {
        header: () => "Device Id",
        accessorFn: (row) => row,
        id: "deviceId",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: possession.deviceId });
        },
        maxSize: 45,
        minSize: 45
      },
      {
        header: () => "Model Name",
        accessorFn: (row) => row,
        id: "device.modelName",
        enableSorting: false,
        cell: (info) => {
          var _a;
          const possession = info.getValue();
          return ((_a = possession.device) == null ? void 0 : _a.modelName) ? /* @__PURE__ */ jsx(Box, { children: possession.device.modelName }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 150,
        minSize: 150
      },
      {
        header: () => "Possession Type",
        accessorFn: (row) => row,
        id: "possessionType",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: possession.possessionType });
        },
        maxSize: 80,
        minSize: 80
      },
      {
        header: () => "Possession Id",
        accessorFn: (row) => row,
        id: "possessionId",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: possession.possessionId });
        },
        maxSize: 70,
        minSize: 70
      },
      {
        header: () => "Delivery Address",
        accessorFn: (row) => row,
        id: "deliveryAddress",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return possession.deliveryAddress ? /* @__PURE__ */ jsx(Tooltip, { title: possession.deliveryAddress, placement: "top", children: /* @__PURE__ */ jsx(Box, { children: possession.deliveryAddress }) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 200,
        minSize: 200
      },
      {
        header: () => "Start Date",
        accessorFn: (row) => row,
        id: "startDate",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return possession.startDate ? /* @__PURE__ */ jsx(Box, { children: getDateString(possession.startDate) }) : /* @__PURE__ */ jsx(EmptyCell, {});
        },
        maxSize: 85,
        minSize: 85
      },
      {
        header: () => "End Date",
        accessorFn: (row) => row,
        id: "endDate",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return /* @__PURE__ */ jsxs(Box, { sx: { width: 1, display: "flex", justifyContent: "space-between" }, children: [
            possession.endDate ? getDateString(possession.endDate) : /* @__PURE__ */ jsx(EmptyCell, {}),
            noOfActivePossessionsOfDevices[possession.deviceId] && noOfActivePossessionsOfDevices[possession.deviceId] > 1 && /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize))
          ] });
        },
        maxSize: 85,
        minSize: 85
      },
      {
        header: () => "Created At",
        accessorFn: (row) => row,
        id: "createdAt",
        enableSorting: false,
        cell: (info) => {
          const possession = info.getValue();
          return /* @__PURE__ */ jsx(Box, { children: getDateString(possession.createdAt) });
        },
        maxSize: 85,
        minSize: 85
      }
    ];
  }, [noOfActivePossessionsOfDevices]);
  const handleRowClick = useCallback((row) => {
    setSelectedDevicePossession(row.original);
    setIsDrawerOpen(true);
  }, []);
  return /* @__PURE__ */ jsx(Switch, { children: /* @__PURE__ */ jsx(Route, { path: SUPER_ADMIN_HELPER_COMPANY_DEVICES_ROUTE, children: /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: [
          "Helper page - Devices | ",
          (company == null ? void 0 : company.name) ? company.name : "",
          " [ ",
          companyId,
          " ]"
        ] }),
        showBack: true,
        backPath: generatePath(SUPER_ADMIN_HELPER_COMPANY_DETAILS_ROUTE, { companyId })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: devicePossessions,
          columnData,
          rowClick: handleRowClick
        }
      ) }),
      selectedDevicePossession && /* @__PURE__ */ jsx(
        DevicePossessionDrawer,
        {
          isOpen: isDrawerOpen,
          setIsOpen: setIsDrawerOpen,
          devicePossession: selectedDevicePossession,
          companyId,
          refresh,
          onUpdate: () => {
            setSelectedDevicePossession(null);
            setIsDrawerOpen(false);
          }
        }
      )
    ] })
  ] }) }) });
};
