"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CommaSeparatedTextfieldComponent } from "@/v2/components/forms/comma-separated-textfield.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import {
  definitionListSx,
  definitionSx,
  definitionTermSx,
  definitionValueSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { detailTermSx, detailValueSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { actionIconSize } from "@/v2/styles/table.styles";
import { isValidCommaSeparatedEmail } from "@/v2/util/string.util";
export const AppCCForNewAccounts = ({ appDetails }) => {
  const fieldLabel = "Activation email CC";
  const [edit, setEdit] = useState(false);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [appEmailCC, setAppEmailCC] = useState("");
  const invalidEmailText = useMemo(() => {
    if (appEmailCC && !isValidCommaSeparatedEmail(appEmailCC)) {
      return `The email(s) provided is invalid`;
    }
    return "";
  }, [appEmailCC]);
  const refreshAppEmail = useCallback(() => __async(void 0, null, function* () {
    var _a;
    const updatedList = yield AppIntegrationAPI.getCCEmailList((_a = appDetails == null ? void 0 : appDetails.stub) != null ? _a : "");
    setAppEmailCC(updatedList ? updatedList.join(", ") : "");
  }), [appDetails == null ? void 0 : appDetails.stub]);
  useEffect(() => {
    refreshAppEmail();
  }, [refreshAppEmail]);
  const saveCCEmailListForApp = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (appDetails) yield AppIntegrationAPI.saveCCEmailList(appDetails == null ? void 0 : appDetails.stub, appEmailCC);
      showMessage("Successfully saved App email CC list!", "success");
      setEdit(false);
    } catch (error) {
      showMessage(`Failed to save App email CC list: ${JSON.stringify(error)}`, "error");
    } finally {
      setLoading(false);
      refreshAppEmail();
    }
  });
  return /* @__PURE__ */ jsxs(Box, { sx: { width: "550px", mt: spacing.m60 }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: "App configuration" }),
      /* @__PURE__ */ jsx(IconButton, { sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { display: `${edit ? "none" : ""}` }), onClick: () => setEdit(true), children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, actionIconSize)) })
    ] }),
    !edit ? /* @__PURE__ */ jsx(Box, { component: "dl", sx: { definitionListSx }, children: /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: "30px", display: "flex", alignItems: "center" }), children: [
      /* @__PURE__ */ jsx(Typography, { component: "dt", sx: definitionTermSx, children: fieldLabel }),
      /* @__PURE__ */ jsx(Typography, { component: "dd", sx: definitionValueSx, children: (appEmailCC == null ? void 0 : appEmailCC.length) > 0 ? appEmailCC : "None" })
    ] }) }) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", mt: "30px", alignItems: "center" }, component: "section", children: [
        /* @__PURE__ */ jsx(Box, { sx: { minWidth: "190px", maxWidth: "190px" }, children: /* @__PURE__ */ jsxs(Typography, { sx: detailTermSx, children: [
          " ",
          fieldLabel
        ] }) }),
        /* @__PURE__ */ jsx(Box, { sx: detailValueSx, children: /* @__PURE__ */ jsx(
          CommaSeparatedTextfieldComponent,
          {
            fieldName: "selectedEmailRecipients",
            fieldValue: appEmailCC != null ? appEmailCC : "",
            setCommaSeparatedValue: (fieldName, value) => {
              setAppEmailCC(value);
            },
            errorText: invalidEmailText != null ? invalidEmailText : "",
            helperText: "Separate multiple emails by a comma"
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", my: spacing.m50, gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            disabled: false,
            onClick: () => setEdit(false),
            children: "Cancel"
          }
        ),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            disabled: !!invalidEmailText && (invalidEmailText == null ? void 0 : invalidEmailText.length) > 0 || loading,
            onClick: () => saveCCEmailListForApp(),
            children: "Save"
          }
        )
      ] })
    ] })
  ] });
};
