"use strict";
import { EXCEL_CLOCK_HOURS_COLUMNS_SET } from "@v2/feature/reports/components/result-table.component";
import {
  ReportColumnType
} from "@v2/feature/reports/reports.interface";
import { deepCopy } from "@v2/infrastructure/object/object.util";
import { LocalDate } from "@v2/util/local-date";
import { differenceInDays } from "date-fns";
import saveAs from "file-saver";
import JSZip from "jszip";
import { kebabCase } from "lodash";
import Papa from "papaparse";
function getCSVKeyMapping(data, resultColumnNameMapping) {
  const keys = new Set(data.flatMap((entry) => Object.keys(entry)));
  return Object.fromEntries(
    [...keys].map((key) => {
      if (resultColumnNameMapping == null ? void 0 : resultColumnNameMapping[key]) {
        return [key, resultColumnNameMapping == null ? void 0 : resultColumnNameMapping[key]];
      }
      const m = key.match(/^(.+?): (.+)$/);
      if (m) {
        return [key, `${kebabCase(m[1])}.${kebabCase(m[2])}`];
      }
      return [key, key];
    })
  );
}
export const getNormalisedKeysOldReports = (data, resultColumnNameMapping) => {
  var _a;
  const allKeys = [];
  for (const record of data)
    for (const d of Object.keys(record)) {
      const finalKey = (_a = resultColumnNameMapping == null ? void 0 : resultColumnNameMapping[d]) != null ? _a : d;
      if (allKeys.includes(finalKey)) continue;
      else {
        allKeys.push((resultColumnNameMapping == null ? void 0 : resultColumnNameMapping[finalKey]) ? resultColumnNameMapping == null ? void 0 : resultColumnNameMapping[finalKey] : finalKey);
      }
    }
  return allKeys;
};
export const getNormalisedValue = (value, key) => {
  if (key.toLowerCase().includes("lastexternalsync") || key.toLowerCase().includes("createdat") || key.toLowerCase().includes("updatedat") || key.toLowerCase().includes("lasttimeonline"))
    return value ? new LocalDate(value).toDateTimeString() : value;
  if (EXCEL_CLOCK_HOURS_COLUMNS_SET.has(key)) {
    const [hourValue, minutesValue] = value && typeof value === "string" && value.includes(":") ? value.split(":") : [];
    const h = hourValue ? Number.parseInt(hourValue) : 0;
    const m = minutesValue ? Number.parseInt(minutesValue) : 0;
    return `${h}h ${m < 10 ? "0" : ""}${m}m`;
  }
  if (value instanceof Date) {
    return new LocalDate(value).toFullString();
  }
  return value;
};
export const capitalize = (s) => {
  let name = s && s[0].toUpperCase() + s.slice(1);
  if (name.includes(":")) {
    const colonIndex = name.indexOf(":");
    name = name.slice(0, colonIndex + 2) + name[colonIndex + 2].toUpperCase() + name.slice(colonIndex + 3);
  }
  return name;
};
export const getDateType = (start, end, polyglot) => {
  const dateDifference = differenceInDays(new Date(end), new Date(start));
  if (dateDifference === 30) return polyglot.t("getDateType.thirty");
  if (dateDifference === 7) return polyglot.t("getDateType.seven");
  return polyglot.t("getDateType.custom");
};
export const exportReportCSVOldReports = (reportName, filteredData, fileName) => {
  if (filteredData) {
    const allKeys = Object.keys(filteredData).filter((key) => filteredData[key].length > 0);
    const allFiles = allKeys.map(
      (data) => Papa.unparse({ data: filteredData[data], fields: getNormalisedKeysOldReports(filteredData[data]) })
    );
    const zip = new JSZip();
    allFiles.map((file, idx) => zip.file(fileName != null ? fileName : `report-${allKeys[idx]}.csv`, file));
    zip.generateAsync({ type: "blob" }).then(function(content) {
      saveAs(content, `${reportName}.zip`);
    });
  }
};
export const exportReportCSV = (reportName, reportResponse) => {
  const keys = Object.keys(reportResponse.header.order);
  keys.sort((a, b) => reportResponse.header.order[a] - reportResponse.header.order[b]);
  const fields = keys.map((key) => {
    var _a;
    return (_a = reportResponse.header.labels[key]) != null ? _a : key;
  });
  const data = reportResponse.data.map((row) => {
    const objResponse = [];
    for (const key of keys) {
      objResponse.push(row[key].value);
    }
    return objResponse;
  });
  const csv = Papa.unparse({ fields, data });
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${reportName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const handleMappingForCSVExport = (data, customMappings) => {
  const allMappedRecords = [];
  const mapping = getCSVKeyMapping(data, customMappings);
  data.forEach((d) => {
    var _a;
    const mappedRecord = {};
    for (const key of Object.keys(d)) {
      const finalKey = (_a = mapping[key]) != null ? _a : key;
      mappedRecord[finalKey] = d[key];
    }
    allMappedRecords.push(mappedRecord);
  });
  return {
    data: allMappedRecords,
    fields: Object.values(mapping)
  };
};
export const exportCurrentReportCSV = (reportName, filteredData, resultColumnNameMapping) => {
  if (filteredData) {
    const { data, fields } = handleMappingForCSVExport(filteredData, resultColumnNameMapping != null ? resultColumnNameMapping : {});
    const csvData = Papa.unparse({
      data,
      fields
    });
    const filename = `zelt-report-${new LocalDate().toDateString()}-${kebabCase(reportName)}.csv`;
    saveAs(new Blob([csvData], { type: "text/csv" }), filename);
  }
};
export const extractAllColumnsFromColumnsConfig = (columnsConfig) => {
  return Object.keys(columnsConfig).flatMap((entity) => {
    var _a;
    return (_a = columnsConfig[entity]) != null ? _a : [];
  });
};
function cleanUpColumnsSelection(selectedColumns) {
  var _a;
  const selectedColumnsCopy = deepCopy(selectedColumns);
  for (const table in selectedColumnsCopy) {
    const columns = (_a = selectedColumnsCopy[table]) != null ? _a : [];
    for (const col of columns) {
      if (col.type === ReportColumnType.PLAIN) delete col.type;
      delete col.label;
      delete col.order;
    }
  }
  return selectedColumnsCopy;
}
export function reverseColumnsSelectionCleanUpUsingDefaultValues(selectedColumns, columnsOptions) {
  var _a, _b, _c;
  for (const table in selectedColumns) {
    for (const col of selectedColumns[table]) {
      if (!col.type) col.type = ReportColumnType.PLAIN;
      if (!col.label) {
        const tableColumnsList = (_a = columnsOptions == null ? void 0 : columnsOptions.find((c) => c.stub === table)) == null ? void 0 : _a.columns;
        col.label = tableColumnsList ? (_c = (_b = tableColumnsList[col.col]) == null ? void 0 : _b.label) != null ? _c : col.col : col.col;
      }
    }
  }
}
export function getReportShareURL(selectedColumns, selectedFilters, scope, effectiveDate, effectiveDateEnabled) {
  try {
    const columnsJSONString = JSON.stringify(cleanUpColumnsSelection(selectedColumns));
    const filtersJSONString = JSON.stringify(selectedFilters);
    const effectiveDateJSONString = JSON.stringify(effectiveDate != null ? effectiveDate : new LocalDate().toDateString());
    const effectiveDateEnabledJSONString = JSON.stringify(effectiveDateEnabled);
    const columnsJSONEncoded = encodeURIComponent(columnsJSONString);
    const filtersJSONEncoded = encodeURIComponent(filtersJSONString);
    const effectiveDateEncoded = encodeURIComponent(effectiveDateJSONString);
    const effectiveDateEnabledEncoded = encodeURIComponent(effectiveDateEnabledJSONString);
    const isProduction = process.env.REACT_APP_ENV === "production";
    const envDomain = `${isProduction ? "go.zelt.app" : "go.testzelt.com"}`;
    const url = `https://${envDomain}/report/${scope}/reports-advanced/create?columns=${columnsJSONEncoded}&filters=${filtersJSONEncoded}&effectiveDate=${effectiveDateEncoded}&effectiveDateEnabled=${effectiveDateEnabledEncoded}`;
    return { success: true, url, errorMessage: void 0 };
  } catch (error) {
    console.error(error);
    return { success: false, url: null, errorMessage: error.message };
  }
}
