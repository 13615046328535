"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { PayrunPensionStates } from "@v2/feature/benefits/subfeature/pension/pension.interface";
import {
  AccountingJournalStates,
  DownloadPayslipsStates,
  HMRCSubmissionStates,
  PayrunPaymentsStates
} from "@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface";
import { PayrollLocalApi } from "@v2/feature/payroll/payroll-local.api";
import { PayrunStates } from "@v2/feature/payroll/payroll.interface";
import { primarySmallBtn } from "@v2/styles/buttons.styles";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@v2/styles/spacing.styles";
import axios from "axios";
import { useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { titleSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
const PENDING = 0;
const DONE = 1;
const ERROR = -1;
export const HelperPayrunDetailsPage = ({ company }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const [payrun, setPayrun] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const companyId = Number(params.companyId);
  const payrunId = Number(params.payrunId);
  const [showMessage] = useMessage();
  const [currentUserId, setCurrentUserId] = useState(0);
  const [states, setStates] = useState({
    setInProgress: PENDING,
    enrichEntries: PENDING,
    enrichPayslips: PENDING,
    enrichFPS: PENDING,
    enrichPension: PENDING,
    enrichAccounting: PENDING,
    enrichPayments: PENDING,
    enrichStates: PENDING
  });
  const refreshPayrun = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const payrun2 = yield PayrollLocalApi.getLocalPayRunByIdAsSuperAdmin(companyId, payrunId);
      setPayrun(payrun2);
    } catch (error) {
      showMessage(`Error: ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage, companyId, payrunId]);
  useEffect(() => {
    refreshPayrun();
  }, [refreshPayrun]);
  const setInProgress = useCallback(
    (companyId2) => __async(void 0, null, function* () {
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-in-progress/${companyId2}/${payrunId}`);
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { setInProgress: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { setInProgress: ERROR }));
      }
    }),
    [payrunId, refreshPayrun, showMessage]
  );
  const enrichPayrunEntries = useCallback(
    (companyId2) => __async(void 0, null, function* () {
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-entries/${companyId2}/${payrunId}`);
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichEntries: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichEntries: ERROR }));
      }
    }),
    [payrunId, refreshPayrun, showMessage]
  );
  const enrichPayrunPayslips = useCallback(
    (companyId2, currentUserId2) => __async(void 0, null, function* () {
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-payslips/${companyId2}/${payrunId}`, { currentUserId: currentUserId2 });
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichPayslips: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichPayslips: ERROR }));
      }
    }),
    [payrunId, refreshPayrun, showMessage]
  );
  const enrichPayrunFPS = useCallback(
    (companyId2) => __async(void 0, null, function* () {
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-fps/${companyId2}/${payrunId}`);
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichFPS: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichFPS: ERROR }));
      }
    }),
    [payrunId, refreshPayrun, showMessage]
  );
  const enrichPayrunPension = useCallback(
    (companyId2, pensionSchemeId) => __async(void 0, null, function* () {
      if (!pensionSchemeId) return showMessage("Pension scheme is not selected", "error");
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-pension/${companyId2}/${payrunId}/${pensionSchemeId}`);
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichPension: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichPension: ERROR }));
      }
    }),
    [showMessage, payrunId, refreshPayrun]
  );
  const enrichPayrunAccounting = useCallback(
    (companyId2) => __async(void 0, null, function* () {
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-accounting/${companyId2}/${payrunId}`);
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichAccounting: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichAccounting: ERROR }));
      }
    }),
    [showMessage, payrunId, refreshPayrun]
  );
  const enrichPayrunPayments = useCallback(
    (companyId2) => __async(void 0, null, function* () {
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-payments/${companyId2}/${payrunId}`);
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichPayments: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichPayments: ERROR }));
      }
    }),
    [showMessage, payrunId, refreshPayrun]
  );
  const enrichPayrunStates = useCallback(
    (companyId2) => __async(void 0, null, function* () {
      try {
        yield axios.post(`/apiv2/payroll/payruns/enrich/payrun-state/${companyId2}/${payrunId}`);
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichStates: DONE }));
        yield refreshPayrun();
      } catch (error) {
        showMessage(`Error: ${nestErrorMessage(error)}`, "error");
        setStates((prev) => __spreadProps(__spreadValues({}, prev), { enrichStates: ERROR }));
      }
    }),
    [showMessage, payrunId, refreshPayrun]
  );
  const getButtonColorBasedOnState = (field) => {
    switch (states[field]) {
      case ERROR: {
        return "error";
      }
      case DONE: {
        return "success";
      }
      default: {
        return "primary";
      }
    }
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: [
          "Helper page - Payrun details | ",
          (company == null ? void 0 : company.name) ? company.name : "",
          " [ ",
          companyId,
          " ]"
        ] }),
        showBack: true
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      payrun && /* @__PURE__ */ jsxs(Typography, { sx: titleSx, children: [
        payrun.payPeriod,
        " ",
        payrun.period,
        " ",
        payrun.taxYear
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: "Payrun states" }),
      payrun && /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            gap: spacing.g30,
            mt: spacing.m10,
            overflowX: "auto",
            minHeight: "100px",
            pb: spacing.m5
          },
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { children: "id" }),
              /* @__PURE__ */ jsx(Typography, { children: "taxYear" }),
              /* @__PURE__ */ jsx(Typography, { children: "payPeriod" }),
              /* @__PURE__ */ jsx(Typography, { children: "period" })
            ] }),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsx(Typography, { children: payrun.id }),
              /* @__PURE__ */ jsx(Typography, { children: payrun.taxYear }),
              /* @__PURE__ */ jsx(Typography, { children: payrun.payPeriod }),
              /* @__PURE__ */ jsx(Typography, { children: payrun.period })
            ] }),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderLeft: "1px solid black",
                  pl: 1
                },
                children: [
                  /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Payslips" }),
                  /* @__PURE__ */ jsx(
                    Typography,
                    {
                      sx: themeFonts.headline3,
                      color: ((_a = payrun.payrunPayslips) == null ? void 0 : _a.state) === DownloadPayslipsStates.downloaded ? "#23ad51" : "#ada61a",
                      children: (_c = (_b = payrun.payrunPayslips) == null ? void 0 : _b.state) != null ? _c : "N/A"
                    }
                  )
                ]
              }
            ),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  borderLeft: "1px solid black",
                  pl: 1,
                  justifyContent: "space-between"
                },
                children: [
                  /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "FPS Submission" }),
                  /* @__PURE__ */ jsx(
                    Typography,
                    {
                      sx: themeFonts.headline3,
                      color: ((_d = payrun.payrunFPS) == null ? void 0 : _d.state) && [HMRCSubmissionStates.submitted, HMRCSubmissionStates.markedAsSent].includes((_e = payrun.payrunFPS) == null ? void 0 : _e.state) ? "#23ad51" : "#ada61a",
                      children: (_g = (_f = payrun.payrunFPS) == null ? void 0 : _f.state) != null ? _g : "N/A"
                    }
                  )
                ]
              }
            ),
            payrun.payrunPensions && payrun.payrunPensions.map((payrunPension, index) => {
              var _a2, _b2, _c2;
              return /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "1px solid black",
                    pl: 1,
                    justifyContent: "space-between"
                  },
                  children: [
                    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
                      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { whiteSpace: "nowrap" }), children: "Pension Submission" }),
                      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: (_b2 = (_a2 = payrunPension.pensionScheme) == null ? void 0 : _a2.providerName) != null ? _b2 : "" })
                    ] }),
                    /* @__PURE__ */ jsx(
                      Typography,
                      {
                        sx: themeFonts.headline3,
                        color: payrunPension.state && [PayrunPensionStates.submitted, PayrunPensionStates.markedAsSent].includes(payrunPension.state) ? "#23ad51" : "#ada61a",
                        children: (_c2 = payrunPension == null ? void 0 : payrunPension.state) != null ? _c2 : "N/A"
                      }
                    )
                  ]
                },
                index
              );
            }),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  borderLeft: "1px solid black",
                  pl: 1,
                  justifyContent: "space-between"
                },
                children: [
                  /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Accounting Submission" }),
                  /* @__PURE__ */ jsx(
                    Typography,
                    {
                      sx: themeFonts.headline3,
                      color: ((_h = payrun.payrunAccounting) == null ? void 0 : _h.state) && [AccountingJournalStates.submitted, AccountingJournalStates.markedAsSent].includes(
                        (_i = payrun.payrunAccounting) == null ? void 0 : _i.state
                      ) ? "#23ad51" : "#ada61a",
                      children: (_k = (_j = payrun.payrunAccounting) == null ? void 0 : _j.state) != null ? _k : "N/A"
                    }
                  )
                ]
              }
            ),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  borderLeft: "1px solid black",
                  pl: 1,
                  justifyContent: "space-between"
                },
                children: [
                  /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Payments" }),
                  /* @__PURE__ */ jsx(
                    Typography,
                    {
                      sx: themeFonts.headline3,
                      color: ((_l = payrun.payrunPayments) == null ? void 0 : _l.state) === PayrunPaymentsStates.created ? "#23ad51" : "#ada61a",
                      children: (_n = (_m = payrun.payrunPayments) == null ? void 0 : _m.state) != null ? _n : "N/A"
                    }
                  )
                ]
              }
            ),
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  borderLeft: "1px solid black",
                  pl: 1,
                  justifyContent: "space-between"
                },
                children: [
                  /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Payrun State" }),
                  /* @__PURE__ */ jsx(
                    Typography,
                    {
                      sx: themeFonts.headline3,
                      color: payrun.state === PayrunStates.completed ? "#23ad51" : "#000",
                      children: payrun.state
                    }
                  )
                ]
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { mt: spacing.m30, mb: spacing.m10 }), children: "Payrun actions" }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 1 }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: 2 }, children: [
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsxs(Typography, { sx: { mr: 4 }, children: [
              "Company ",
              /* @__PURE__ */ jsx("b", { children: companyId })
            ] }),
            /* @__PURE__ */ jsxs(Typography, { sx: { mr: 4 }, children: [
              "Payrun ",
              /* @__PURE__ */ jsx("b", { children: payrunId })
            ] })
          ] }),
          /* @__PURE__ */ jsx(
            TextField,
            {
              id: "outlined-name",
              label: "Current User Id",
              name: "currentUserId",
              value: currentUserId,
              onChange: (e) => setCurrentUserId(Number(e.target.value)),
              size: "small"
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", my: 3, gap: 1, overflowX: "auto", pb: spacing.m5 }, children: [
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: primarySmallBtn,
              onClick: () => setInProgress(companyId),
              size: "small",
              color: getButtonColorBasedOnState("setInProgress"),
              children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Set In Progress" })
            }
          ),
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: primarySmallBtn,
              onClick: () => enrichPayrunEntries(companyId),
              size: "small",
              color: getButtonColorBasedOnState("enrichEntries"),
              children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Enrich Entries" })
            }
          ),
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: primarySmallBtn,
              onClick: () => enrichPayrunPayslips(companyId, currentUserId),
              size: "small",
              color: getButtonColorBasedOnState("enrichPayslips"),
              disabled: currentUserId === 0,
              children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Enrich Payslips" })
            }
          ),
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: primarySmallBtn,
              onClick: () => enrichPayrunFPS(companyId),
              size: "small",
              color: getButtonColorBasedOnState("enrichFPS"),
              children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Enrich FPS" })
            }
          ),
          (payrun == null ? void 0 : payrun.payrunPensions) && payrun.payrunPensions.map((payrunPension, index) => {
            var _a2, _b2;
            return /* @__PURE__ */ jsx(
              Button,
              {
                sx: primarySmallBtn,
                onClick: () => enrichPayrunPension(companyId, payrunPension.pensionSchemeId),
                size: "small",
                color: getButtonColorBasedOnState("enrichPension"),
                children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column" }, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Enrich Pension" }),
                  /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
                    "(",
                    (_b2 = (_a2 = payrunPension.pensionScheme) == null ? void 0 : _a2.providerName) != null ? _b2 : "",
                    ")"
                  ] })
                ] })
              },
              index
            );
          }),
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: primarySmallBtn,
              onClick: () => enrichPayrunAccounting(companyId),
              size: "small",
              color: getButtonColorBasedOnState("enrichAccounting"),
              children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Enrich Accounting" })
            }
          ),
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: primarySmallBtn,
              onClick: () => enrichPayrunPayments(companyId),
              size: "small",
              color: getButtonColorBasedOnState("enrichPayments"),
              children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Enrich Payments" })
            }
          ),
          /* @__PURE__ */ jsx(
            Button,
            {
              sx: primarySmallBtn,
              onClick: () => enrichPayrunStates(companyId),
              size: "small",
              color: getButtonColorBasedOnState("enrichStates"),
              children: /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Enrich State" })
            }
          )
        ] })
      ] })
    ] })
  ] });
};
