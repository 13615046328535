"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import {
  BenefitsSettingsPageSection,
  DescriptionLine
} from "@v2/feature/benefits/subfeature/benefits-settings/components/benefits-settings-page-section.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
export const PensionSettingsMembersPage = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(BenefitsSettingsPageSection, { title: polyglot.t("General.members"), children: /* @__PURE__ */ jsx(
    DescriptionLine,
    {
      field: polyglot.t("General.members"),
      value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("PensionModule.memberAllocation") }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("PensionModule.rememberToAssess") })
      ] })
    }
  ) });
};
