"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { Alert, Stack, Typography } from "@mui/material";
import { ReactComponent as ErrorIcon } from "@/images/side-bar-icons/Error.svg";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoYellow.svg";
import { ReactComponent as OkIcon } from "@/images/side-bar-icons/OkGreen.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const AlertIcon = ({ severity }) => {
  if (severity === "success") return /* @__PURE__ */ jsx(OkIcon, { style: { width: 16, height: 16 } });
  if (severity === "warning") return /* @__PURE__ */ jsx(InfoIcon, { style: { width: 16, height: 16 } });
  return /* @__PURE__ */ jsx(ErrorIcon, { style: { width: 16, height: 16 } });
};
const getFontColor = (severity) => {
  switch (severity) {
    case "success":
      return themeColors.DarkGreen;
    case "warning":
      return themeColors.YellowDark;
    default:
      return themeColors.RedDark;
  }
};
const AlertMessage = (_a) => {
  var _b = _a, { severity, message, actionTitle, onAction } = _b, props = __objRest(_b, ["severity", "message", "actionTitle", "onAction"]);
  return /* @__PURE__ */ jsx(Stack, { width: "100%", children: /* @__PURE__ */ jsx(
    Alert,
    __spreadProps(__spreadValues({
      severity,
      sx: {
        borderRadius: spacing.p10,
        padding: 0,
        paddingLeft: spacing.p8,
        color: getFontColor(severity),
        "& .MuiAlert-icon": {
          marginRight: spacing.m8,
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        },
        "& .MuiAlert-action": {
          marginRight: spacing.m8
        }
      },
      icon: /* @__PURE__ */ jsx("div", { style: { display: "flex", justifyContent: "center", alignItems: "center" }, children: /* @__PURE__ */ jsx(AlertIcon, { severity }) }),
      action: actionTitle && onAction ? /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "textUnderline",
          onClick: onAction,
          style: { textTransform: "none", color: getFontColor(severity), fontWeight: 400 },
          fullWidth: true,
          children: actionTitle
        }
      ) : null
    }, props), {
      children: /* @__PURE__ */ jsx(Typography, { variant: "body2", sx: { fontWeight: 400 }, children: message })
    })
  ) });
};
export { AlertMessage };
