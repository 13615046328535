"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import { Typography } from "@/v2/components/typography/typography.component";
import { spacing } from "@/v2/styles/spacing.styles";
export const TitledHeader = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: label }),
    typeof value === "object" && value,
    typeof value !== "object" && typeof value !== "undefined" && /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { whiteSpace: "nowrap" }, children: `${value}` })
  ] });
};
export const TitledHeaderStack = ({ items, sx }) => {
  return /* @__PURE__ */ jsx(Stack, { sx: __spreadValues({ flexFlow: "row", gap: spacing.g60 }, sx), children: items.map((props) => /* @__PURE__ */ jsx(TitledHeader, __spreadValues({}, props), props.label)) });
};
