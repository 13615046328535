"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getActionDate } from "@v2/feature/offboarding/offboarding.util";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { LocalDate } from "@v2/util/local-date";
import { isPast } from "date-fns";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { isToday } from "@/lib/date-time.lib";
import { nestErrorMessage } from "@/lib/errors";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import {
  SingleUserSelect
} from "@/v2/components/forms/user-select/single-user-select.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppIntegrationAPI, AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import {
  APP_ACTION_DRAWER_MODES,
  APP_ACTION_WHEN_OPTIONS,
  AppActionWhenTabFilter,
  AppActionWhenValueEnum,
  APPS_NOT_REQUIRING_REFRESH_POST_ACTION,
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION,
  appStubToName,
  REFRESH_DELAY_APP_USER_LIST
} from "@/v2/feature/app-integration/app-integration.interface";
import { JumpCloudNewUserParams } from "@/v2/feature/app-integration/apps/jumpcloud/components/jumpcloud-new-user-params-modal.component";
import {
  APPS_NEEDING_EMAIL_FOR_DELETION,
  APPS_WITHOUT_DELETE_OPTION,
  emailOptionsForUserCreation,
  getActionNameForApp,
  getEmailAddressForMissingId,
  hasActiveEmailForAccount
} from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import {
  buttonBoxSx,
  fieldSx,
  titleSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserContractTypeEnum } from "@/v2/feature/user/features/user-profile/user-profile.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { StyledFormCheckbox } from "@/v2/styles/checkbox.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppActionsDrawerPage = ({
  appStub,
  selectedActiveUserLogin,
  setSelectedActiveUserLogin,
  usersWithoutAccess,
  usersWithAccess,
  preselectedUser = void 0,
  countriesForEmployment,
  refreshApp,
  refreshDelayedActions,
  markTeamUserPending,
  directoryMode = "team",
  closePage,
  mode = APP_ACTION_DRAWER_MODES.create
}) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { polyglot } = usePolyglot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userId, setUserId] = useState(void 0);
  const today = new LocalDate().toDateString();
  const tomorrow = new Date((/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() + 1));
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const [noPersonalEmailSoUserCreationDisabled, setNoPersonalEmailSoUserCreationDisabled] = useState(false);
  const [employmentUserStartDateInThePast, setEmploymentUserStartDateInThePast] = useState(false);
  const usersWithoutAccessOptions = useMemo(
    () => nonTerminatedCachedUsers.filter((user) => usersWithoutAccess.some((u) => u.userId === user.userId)).map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    }),
    [nonTerminatedCachedUsers, usersWithoutAccess]
  );
  const usersWithAccessOptions = useMemo(
    () => usersWithAccess && usersWithAccess.length > 0 ? nonTerminatedCachedUsers.filter((user) => usersWithAccess.some((u) => u.userId === user.userId && u.userId !== userId)).map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    }) : [],
    [nonTerminatedCachedUsers, userId, usersWithAccess]
  );
  const externalUserAccessOptions = useMemo(
    () => usersWithAccess && usersWithAccess.length > 0 ? usersWithAccess.filter((eachUser) => eachUser.id !== (preselectedUser == null ? void 0 : preselectedUser.id)).map((u) => {
      return { label: u.displayName, value: u.id };
    }) : [],
    [preselectedUser == null ? void 0 : preselectedUser.id, usersWithAccess]
  );
  const nonPayrolledUserOptions = useMemo(
    () => nonTerminatedCachedUsers && nonTerminatedCachedUsers.length > 0 ? nonTerminatedCachedUsers.filter(
      (eachUser) => {
        var _a2;
        return ((_a2 = eachUser.userContract) == null ? void 0 : _a2.type) && eachUser.userContract.type === UserContractTypeEnum.NonPayrolled && usersWithoutAccess.some((u) => u.userId === eachUser.userId && u.userId !== userId);
      }
    ).map((u) => {
      return __spreadProps(__spreadValues({ label: u.displayName, value: u.userId }, u), { disabled: true });
    }) : [],
    [nonTerminatedCachedUsers, userId, usersWithoutAccess]
  );
  const countriesForEmploymentOptions = useMemo(() => {
    return countriesForEmployment == null ? void 0 : countriesForEmployment.map((eachCountry) => {
      return { label: eachCountry.name, value: eachCountry.code };
    });
  }, [countriesForEmployment]);
  const [showMessage] = useMessage();
  const [refreshAt, setRefreshAt] = useState(null);
  const [currentlySelectedUserEmails, setCurrentlySelectedUserEmails] = useState(
    []
  );
  const [createUserWithParams, setCreateUserWithParams] = useState(null);
  const { data: companyDomainForEmail } = useApiClient(AppIntegrationEndpoints.getCompanyDomainForApp(appStub), {
    suspense: false
  });
  const { data: personalEmailForUser, isValidating } = useApiClient(
    (preselectedUser == null ? void 0 : preselectedUser.userId) ? AppIntegrationEndpoints.getPersonalEmailForAppsByUserId(+(preselectedUser == null ? void 0 : preselectedUser.userId), appStub) : { url: void 0 },
    {
      suspense: false
    }
  );
  const [plannedNewWorkEmail, setPlannedNewWorkEmail] = useState("<new_work_email>");
  const appsNeedingCreateParams = ["jumpcloud"];
  useEffect(() => {
    var _a2, _b2;
    const usernameForNewAccount = preselectedUser && preselectedUser.userId ? (_b2 = (_a2 = getCachedUserById(Number(preselectedUser == null ? void 0 : preselectedUser.userId))) == null ? void 0 : _a2.emailAddress) == null ? void 0 : _b2.split("@")[0] : "<no_user_id>";
    setPlannedNewWorkEmail(`${usernameForNewAccount}@${companyDomainForEmail}`);
  }, [preselectedUser, getCachedUserById, companyDomainForEmail]);
  useEffect(() => {
    if (!refreshAt) return;
    const delay = Math.max(refreshAt - Date.now(), 0);
    setTimeout(() => {
      setRefreshAt(null);
      refreshApp();
    }, delay);
  }, [refreshApp, refreshAt]);
  function refreshAppData(delay = REFRESH_DELAY_APP_USER_LIST) {
    setRefreshAt(Date.now() + delay);
  }
  const emailSelectorRequired = useMemo(
    () => mode === APP_ACTION_DRAWER_MODES.create && APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub),
    [mode, appStub]
  );
  const selectedEmailOptions = useMemo(() => {
    return preselectedUser && !isValidating ? emailOptionsForUserCreation(appStub, preselectedUser, personalEmailForUser != null ? personalEmailForUser : "", nonTerminatedCachedUsers) : void 0;
  }, [appStub, isValidating, nonTerminatedCachedUsers, personalEmailForUser, preselectedUser]);
  const initialValues = {
    userId: userId || void 0,
    whenAction: "now",
    countryForEmployment: void 0,
    workspaceTransferThenDelete: false,
    transferDataSourceUser: userId || void 0,
    transferDataTargetUserOptionObj: void 0,
    transferDataTargetUser: void 0,
    actionDate: new LocalDate().toDateString(),
    emailSelectorRequired,
    selectedEmailForUserCreation: emailSelectorRequired && selectedEmailOptions ? (_a = selectedEmailOptions[0]) == null ? void 0 : _a.value : void 0
  };
  const validationSchema = yup.object({
    userId: yup.mixed().nullable().required("User is required"),
    actionDate: yup.string().test(dateFieldTest).nullable().notRequired(),
    countryForEmployment: mode === APP_ACTION_DRAWER_MODES.createEmployment ? yup.string().nullable().required("Country is required to create employment") : yup.string().nullable().notRequired(),
    workspaceTransferThenDelete: yup.boolean().nullable().notRequired(),
    selectedEmailForUserCreation: yup.string().notRequired().when("emailSelectorRequired", {
      is: true,
      then: yup.string().nullable().required("Selected email is required")
    }),
    transferDataTargetUser: yup.string().notRequired().when("workspaceTransferThenDelete", {
      is: true,
      then: yup.string().nullable().required()
    })
  });
  const doAssignUser = useCallback(
    (userToBeAssigned) => __async(void 0, null, function* () {
      try {
        if (selectedActiveUserLogin && userToBeAssigned) {
          yield AppIntegrationAPI.assignUser(appStub, userToBeAssigned, selectedActiveUserLogin);
          if (setSelectedActiveUserLogin) setSelectedActiveUserLogin(void 0);
          showMessage("Account assigned successfully", "success");
          if (!APPS_NOT_REQUIRING_REFRESH_POST_ACTION.includes(appStub)) refreshApp();
        }
      } catch (error) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [selectedActiveUserLogin, appStub, setSelectedActiveUserLogin, showMessage, refreshApp]
  );
  const unassignUser = useCallback(
    (_0) => __async(void 0, [_0], function* ({ emailObj }) {
      var _a2;
      try {
        yield AppIntegrationAPI.unassignUser(
          appStub,
          (_a2 = preselectedUser && preselectedUser.userId) != null ? _a2 : 0,
          emailObj.email
        );
        showMessage("Account unassigned successfully", "success");
        refreshApp();
        if (preselectedUser && preselectedUser.userId && markTeamUserPending)
          markTeamUserPending(Number(preselectedUser.userId));
      } catch (error) {
        showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, "error");
      }
    }),
    [appStub, preselectedUser, showMessage, refreshApp, markTeamUserPending]
  );
  function doSuspendUser(user, values) {
    return __async(this, null, function* () {
      try {
        yield AppIntegrationAPI.suspendAppUser(
          appStub,
          String(user == null ? void 0 : user.id),
          (user == null ? void 0 : user.userId) ? Number(user == null ? void 0 : user.userId) : 0,
          getActionDate(values.actionDate)
        );
        if (refreshDelayedActions) refreshDelayedActions();
        showMessage("Suspension initiated", "success");
        if (user.userId && markTeamUserPending) markTeamUserPending(Number(user.userId));
      } catch (error) {
        if ((error == null ? void 0 : error.response.data.error) === `Higher tier of ${appStub} app needed.`) {
          showMessage(
            "In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.",
            "error"
          );
        } else {
          showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, "error");
        }
      }
    });
  }
  function doDeleteUser(user, values) {
    return __async(this, null, function* () {
      var _a2, _b2, _c2, _d2, _e2, _f2;
      try {
        if (user.isAdmin) {
          showMessage(
            "You can't delete an Admin user! To be able to delete this user you should first downgrade this user to a non-admin role.",
            "error"
          );
          return;
        }
        let appUserId = void 0;
        if (appStub === "github") {
          const githubEmail = hasActiveEmailForAccount(user);
          if (githubEmail) yield unassignUser({ emailObj: githubEmail });
          appUserId = githubEmail == null ? void 0 : githubEmail.email;
        }
        if (!appUserId) appUserId = (_a2 = user.login) != null ? _a2 : user.id;
        if (!appUserId) appUserId = getEmailAddressForMissingId(user);
        if (appUserId && formik.values.userId)
          yield AppIntegrationAPI.deleteAppUser(
            appStub,
            String(appUserId) === String(formik.values.userId) && APPS_NEEDING_EMAIL_FOR_DELETION.includes(appStub) && preselectedUser && preselectedUser.primaryEmail ? preselectedUser.primaryEmail : String(appUserId),
            String(appUserId) === String(formik.values.userId) ? 0 : Number(formik.values.userId),
            getActionDate(values.actionDate)
          );
        if (refreshDelayedActions) refreshDelayedActions();
        showMessage(
          `User deletion ${!values.actionDate || isToday(new LocalDate(values.actionDate).getDate()) ? "initiated" : "scheduled"}`,
          "success"
        );
        if (formik.values.userId && markTeamUserPending) markTeamUserPending(Number(formik.values.userId));
      } catch (error) {
        if (((_c2 = (_b2 = error == null ? void 0 : error.response) == null ? void 0 : _b2.data) == null ? void 0 : _c2.error) === "Higher tier needed.") {
          showMessage(
            "In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.",
            "error"
          );
        } else if ((_f2 = (_e2 = (_d2 = error == null ? void 0 : error.response) == null ? void 0 : _d2.data) == null ? void 0 : _e2.error) == null ? void 0 : _f2.includes("Super Admin")) {
          showMessage("This user is a Super Admin. Can not delete a Super Admin.", "error");
        } else {
          showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, "error");
        }
      }
    });
  }
  function doTransferDataAndDeleteUser(user, values) {
    return __async(this, null, function* () {
      var _a2;
      try {
        yield AppIntegrationAPI.dataTransferThenDeleteForAppUser(
          appStub,
          (_a2 = values.userId) != null ? _a2 : 0,
          String(values.transferDataSourceUser),
          String(values.transferDataTargetUser),
          new LocalDate(values.actionDate).toDateString()
        );
        showMessage("User data transfer and deletion initiated", "success");
        if (user.userId && markTeamUserPending) markTeamUserPending(Number(user.userId));
      } catch (error) {
        if ((error == null ? void 0 : error.response.data.error) === `Higher tier of ${appStub} app needed.`) {
          showMessage(
            "In order to manage user accounts in Slack you need to be at least on a Plus Subscription tier.",
            "error"
          );
        } else {
          showMessage(`Oops, something happened. Please try again: ${nestErrorMessage(error)}`, "error");
        }
      }
    });
  }
  const getParamsForAppUserCreation = (values) => {
    if (emailSelectorRequired) return { selectedEmail: values.selectedEmailForUserCreation };
    return void 0;
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setIsSubmitting(true);
        if (!values.userId || !values.actionDate) throw new Error("userId and action date are required");
        if (mode === APP_ACTION_DRAWER_MODES.create)
          yield createUser(new LocalDate(values.actionDate).toDateString(), getParamsForAppUserCreation(values));
        if (mode === APP_ACTION_DRAWER_MODES.createEmployment && values.countryForEmployment)
          yield createEmployment(+values.userId, values.countryForEmployment);
        if (mode === APP_ACTION_DRAWER_MODES.assign) yield doAssignUser(+values.userId);
        if (mode === APP_ACTION_DRAWER_MODES.suspend && preselectedUser && values)
          yield doSuspendUser(preselectedUser, values);
        if (mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete && preselectedUser && formik.values.workspaceTransferThenDelete && formik.values.transferDataTargetUser) {
          yield doTransferDataAndDeleteUser(preselectedUser, values);
        }
        if (mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete && preselectedUser && !formik.values.workspaceTransferThenDelete) {
          yield doDeleteUser(preselectedUser, values);
        }
        if (mode === APP_ACTION_DRAWER_MODES.delete && preselectedUser) {
          yield doDeleteUser(preselectedUser, values);
        }
        closePage();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  const helperTextForUserCreation = useMemo(() => {
    if (emailSelectorRequired) {
      return personalEmailForUser ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb30, fontStyle: "italic" }), children: `Account details and login instructions will be sent to ${APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub) ? formik.values.selectedEmailForUserCreation : " the selected email"}  once the account is created` }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mb: spacing.mb30, fontStyle: "italic" }), children: `No personal email exists for this user; ${appStubToName[appStub]} account cannot be provisioned without personal email.` });
    }
  }, [appStub, emailSelectorRequired, formik.values.selectedEmailForUserCreation, personalEmailForUser]);
  useEffect(() => {
    var _a2;
    if (APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub)) {
      const personalEmailIndex = selectedEmailOptions == null ? void 0 : selectedEmailOptions.findIndex(
        (email) => {
          var _a3, _b2;
          return (_b2 = (_a3 = email == null ? void 0 : email.label) == null ? void 0 : _a3.toLowerCase()) == null ? void 0 : _b2.includes("personal");
        }
      );
      const hasPersonalEmail = personalEmailIndex && personalEmailIndex >= 0;
      if (personalEmailForUser) formik.setFieldValue("selectedEmailForUserCreation", personalEmailForUser);
      else if (hasPersonalEmail && selectedEmailOptions && (selectedEmailOptions == null ? void 0 : selectedEmailOptions.length) > 0)
        formik.setFieldValue("selectedEmailForUserCreation", (_a2 = selectedEmailOptions[personalEmailIndex]) == null ? void 0 : _a2.value);
      setNoPersonalEmailSoUserCreationDisabled(personalEmailForUser ? false : true);
    }
  }, [appStub, selectedEmailOptions, personalEmailForUser]);
  const emailSelectorForUserCreation = useMemo(() => {
    if (emailSelectorRequired) {
      return /* @__PURE__ */ jsxs(Fragment, { children: [
        !APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(appStub) && /* @__PURE__ */ jsx(
          Box,
          {
            sx: __spreadProps(__spreadValues({}, fieldSx), {
              display: "flex-start",
              flexDirection: "column",
              gap: spacing.g10,
              alignItems: "left"
            }),
            children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                name: "emailSelector",
                label: "Invite email",
                options: selectedEmailOptions != null ? selectedEmailOptions : [],
                value: formik.values.selectedEmailForUserCreation,
                error: !!formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation,
                onChange: (e) => {
                  formik.setFieldValue("selectedEmailForUserCreation", e.target.value, true);
                },
                helperText: formik.errors.selectedEmailForUserCreation && formik.touched.selectedEmailForUserCreation
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Creating email address",
            name: "emailAddressForNewAccount",
            disabled: true,
            value: plannedNewWorkEmail
          }
        ) })
      ] });
    } else return /* @__PURE__ */ jsx(Fragment, {});
  }, [appStub, selectedEmailOptions, mode, formik.values.selectedEmailForUserCreation, plannedNewWorkEmail]);
  useEffect(() => {
    if (preselectedUser && preselectedUser.userId) {
      setUserId(isNaN(+preselectedUser.userId) ? preselectedUser.userId : +preselectedUser.userId);
    } else if (preselectedUser && preselectedUser.id) {
      setUserId(isNaN(+preselectedUser.id) ? preselectedUser.id : +preselectedUser.id);
    }
  }, [preselectedUser]);
  useEffect(() => {
    setTimeout(() => formik.setFieldValue("userId", userId, true));
  }, [userId]);
  function createUser(date, createUserParams) {
    return __async(this, null, function* () {
      var _a2, _b2;
      if (appStub === "monday") {
        showMessage("This feature requires SCIM and SSO to be enabled.", "error");
        return;
      }
      if (appsNeedingCreateParams.includes(appStub) && !createUserParams) {
        const jumpCloudUserCreation = new Promise((resolve, _reject) => {
          const closeModalAndCreateUser = (params) => __async(this, null, function* () {
            setCreateUserWithParams(null);
            yield createUser(date, params);
            resolve("Jumpcloud user created");
          });
          setCreateUserWithParams(() => closeModalAndCreateUser);
        });
        yield jumpCloudUserCreation;
        return;
      }
      try {
        yield AppIntegrationAPI.createAppUser(
          appStub,
          formik.values.userId,
          createUserParams,
          date ? getActionDate(date) : void 0
        );
        if (formik.values.userId && !isNaN(+formik.values.userId) && markTeamUserPending)
          markTeamUserPending(+formik.values.userId);
        if (refreshDelayedActions) refreshDelayedActions();
        refreshAppData(500);
        showMessage("Creation initiated", "success");
      } catch (error) {
        if ((_b2 = (_a2 = error.response) == null ? void 0 : _a2.data) == null ? void 0 : _b2.message) {
          showMessage(nestErrorMessage(error), "error");
        } else {
          showMessage(`Oops, something happened. Please try again: ${JSON.stringify(error)}`, "error");
        }
      }
    });
  }
  function createEmployment(userId2, country) {
    return __async(this, null, function* () {
      var _a2, _b2;
      try {
        yield AppIntegrationAPI.createEmployment(appStub, userId2, country);
        if (userId2 && !isNaN(userId2) && markTeamUserPending) markTeamUserPending(userId2);
        showMessage("Employment successfully created", "success");
        refreshApp();
      } catch (error) {
        if ((_b2 = (_a2 = error.response) == null ? void 0 : _a2.data) == null ? void 0 : _b2.message) {
          showMessage(nestErrorMessage(error), "error");
        } else {
          showMessage(`Oops, something happened. Please try again: ${JSON.stringify(error)}`, "error");
        }
      }
    });
  }
  const getDrawerTitle = (currentMode) => {
    var _a2;
    if (currentMode === APP_ACTION_DRAWER_MODES.create)
      return polyglot.t(userId ? "AppActionsDrawerPage.giveAccessToUser" : "AppActionsDrawerPage.addUser");
    if (currentMode === APP_ACTION_DRAWER_MODES.assign) return polyglot.t("AppActionsDrawerPage.assignToTeamMember");
    if (currentMode === APP_ACTION_DRAWER_MODES.createEmployment)
      return polyglot.t("AppActionsDrawerPage.createEmployment");
    if (currentMode === APP_ACTION_DRAWER_MODES.delete && APPS_WITHOUT_DELETE_OPTION.includes(appStub))
      return polyglot.t("AppActionsDrawerPage.removeFromOrganisation", {
        userName: preselectedUser == null ? void 0 : preselectedUser.displayName
      });
    if (currentMode === APP_ACTION_DRAWER_MODES.delete && !APPS_WITHOUT_DELETE_OPTION.includes(appStub))
      return polyglot.t("AppActionsDrawerPage.deleteAccount", {
        userName: preselectedUser == null ? void 0 : preselectedUser.displayName
      });
    if (currentMode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete)
      return polyglot.t("AppActionsDrawerPage.deleteAccount", {
        userName: preselectedUser == null ? void 0 : preselectedUser.displayName
      });
    if (currentMode === APP_ACTION_DRAWER_MODES.suspend)
      return (_a2 = getActionNameForApp(
        "Suspend",
        appStub,
        polyglot.t("AppActionsDrawerPage.suspendUser", {
          userName: preselectedUser == null ? void 0 : preselectedUser.displayName
        })
      )) != null ? _a2 : polyglot.t("AppActionsDrawerPage.suspendUser", {
        userName: preselectedUser == null ? void 0 : preselectedUser.displayName
      });
    else return "";
  };
  const getDrawerConfirmButtonText = (currentMode) => {
    var _a2;
    if (currentMode === APP_ACTION_DRAWER_MODES.create) return "Add";
    if (currentMode === APP_ACTION_DRAWER_MODES.assign) return "Assign";
    if (currentMode === APP_ACTION_DRAWER_MODES.suspend) return (_a2 = getActionNameForApp("Suspend", appStub)) != null ? _a2 : "Suspend";
    if (currentMode === APP_ACTION_DRAWER_MODES.delete) return "Remove";
    if (currentMode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete) return "Delete";
    if (currentMode === APP_ACTION_DRAWER_MODES.createEmployment) return "Create";
    else return "";
  };
  const getUserSelectionOption = (currentMode, directoryMode2) => {
    if ([APP_ACTION_DRAWER_MODES.suspend, APP_ACTION_DRAWER_MODES.assign].includes(currentMode) && directoryMode2 === "team")
      return usersWithoutAccessOptions;
    if ([APP_ACTION_DRAWER_MODES.suspend, APP_ACTION_DRAWER_MODES.delete].includes(currentMode) && directoryMode2 === "external")
      return externalUserAccessOptions;
    if ([APP_ACTION_DRAWER_MODES.assign].includes(currentMode) && directoryMode2 === "external")
      return usersWithoutAccessOptions;
    if ([APP_ACTION_DRAWER_MODES.createEmployment].includes(currentMode) && directoryMode2 === "employments") {
      return nonPayrolledUserOptions;
    }
    return usersWithoutAccessOptions;
  };
  const titleSxTheme = useMemo(() => {
    if (emailSelectorRequired) {
      return __spreadValues({}, themeFonts.headline1);
    }
    return titleSx;
  }, [emailSelectorRequired]);
  const setWhenActionValue = useCallback(
    (value) => {
      formik.setFieldValue("whenAction", value);
    },
    [formik]
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
      /* @__PURE__ */ jsx(Typography, { sx: titleSxTheme, children: getDrawerTitle(mode) }),
      helperTextForUserCreation,
      ![
        APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete,
        APP_ACTION_DRAWER_MODES.suspend,
        APP_ACTION_DRAWER_MODES.delete
      ].includes(mode) && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        SingleUserSelect,
        {
          name: "userId",
          options: getUserSelectionOption(mode, directoryMode),
          disabled: mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete,
          onChange: (_, x) => {
            var _a2;
            const data = x;
            const currentlySelectedUser = (data == null ? void 0 : data.value) ? getCachedUserById(data == null ? void 0 : data.value) : void 0;
            formik.setFieldValue("userId", (_a2 = data == null ? void 0 : data.value) != null ? _a2 : null);
            setCurrentlySelectedUserEmails(
              currentlySelectedUser && currentlySelectedUser.emailAddress ? [{ primary: currentlySelectedUser.emailAddress, email: currentlySelectedUser.emailAddress }] : []
            );
            if (mode === APP_ACTION_DRAWER_MODES.createEmployment && (currentlySelectedUser == null ? void 0 : currentlySelectedUser.startDate)) {
              setEmploymentUserStartDateInThePast(isPast(new Date(currentlySelectedUser.startDate)));
            }
          },
          value: formik.values.userId ? Number(formik.values.userId) : void 0,
          label: mode === APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete ? "Being deleted" : mode === APP_ACTION_DRAWER_MODES.createEmployment ? "Non-payrolled" : "Payrolled",
          error: Boolean(formik.errors.userId),
          helperText: formik.errors.userId
        }
      ) }),
      mode === APP_ACTION_DRAWER_MODES.createEmployment && employmentUserStartDateInThePast && /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Red }), children: "Cannot create employment if user's start date is in the past" }),
      directoryMode === "employments" && APP_ACTION_DRAWER_MODES.createEmployment === mode && /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "countryForEmployment",
          label: "Country for Employment creation",
          options: countriesForEmploymentOptions,
          value: formik.values.countryForEmployment,
          error: !!formik.errors.countryForEmployment && formik.touched.countryForEmployment,
          onChange: (e) => {
            formik.setFieldValue("countryForEmployment", e.target.value);
          },
          helperText: formik.errors.countryForEmployment && formik.touched.countryForEmployment
        }
      ),
      emailSelectorForUserCreation,
      [APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete].includes(mode) && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "whenAction",
            label: "When",
            options: APP_ACTION_WHEN_OPTIONS,
            value: formik.values.whenAction,
            error: !!formik.errors.whenAction && formik.touched.whenAction,
            onChange: (e) => {
              const newVal = e.target.value;
              if (newVal === "now") formik.setFieldValue("actionDate", today);
              else formik.setFieldValue("actionDate", new LocalDate(tomorrow).toDateString());
              formik.handleChange(e);
            },
            helperText: formik.errors.whenAction && formik.touched.whenAction
          }
        ) }),
        formik.values.whenAction === "later" && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          DatePickerComponent,
          {
            inputFormat: "DD/MM/YYYY",
            value: (_b = formik.values.actionDate) != null ? _b : null,
            onChange: (value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue("actionDate", value);
              }
            },
            name: "actionDate",
            minDate: tomorrow,
            label: "Deletion date",
            error: !!formik.errors.actionDate && formik.touched.actionDate,
            helperText: formik.errors.actionDate && formik.touched.actionDate
          }
        ) })
      ] }),
      [APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete].includes(mode) && /* @__PURE__ */ jsxs(Box, { sx: fieldSx, children: [
        ((usersWithAccessOptions == null ? void 0 : usersWithAccessOptions.length) > 0 || (externalUserAccessOptions == null ? void 0 : externalUserAccessOptions.length) > 0) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "flex-start", width: "100%", mb: spacing.m15 }, children: [
          /* @__PURE__ */ jsx(
            StyledFormCheckbox,
            {
              name: "workspaceTransferThenDelete",
              checked: formik.values.workspaceTransferThenDelete,
              onChange: () => {
                formik.setFieldValue(
                  "workspaceTransferThenDelete",
                  !formik.values.workspaceTransferThenDelete,
                  true
                );
              }
            }
          ),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { ml: spacing.m10 }), children: "Select if you would like to transfer this user's files to another Google Workspace account" })
        ] }),
        formik.values.workspaceTransferThenDelete && usersWithAccessOptions && usersWithAccessOptions.length > 0 && directoryMode === "team" && /* @__PURE__ */ jsx(
          SingleUserSelect,
          {
            name: "transferDataTargetUser",
            options: usersWithAccessOptions,
            value: (_c = formik.values.transferDataTargetUserOptionObj) == null ? void 0 : _c.value,
            onChange: (_, x) => {
              var _a2;
              const data = x;
              const foundAppTargetUser = usersWithAccess && usersWithAccess.length > 0 && data && data.value ? usersWithAccess.find((u) => u.userId === data.value) : null;
              formik.setFieldValue("transferDataTargetUserOptionObj", data, true);
              formik.setFieldValue("transferDataTargetUser", (_a2 = foundAppTargetUser == null ? void 0 : foundAppTargetUser.id) != null ? _a2 : null, true);
              formik.setFieldTouched("transferDataTargetUser", true, true);
              if (preselectedUser) formik.setFieldValue("transferDataSourceUser", preselectedUser.id, true);
              setTimeout(() => formik.setFieldTouched("transferDataTargetUser", true));
            },
            label: "Select Google Workspace account",
            error: Boolean(formik.errors.userId),
            helperText: formik.errors.userId
          }
        ),
        formik.values.workspaceTransferThenDelete && (externalUserAccessOptions == null ? void 0 : externalUserAccessOptions.length) > 0 && directoryMode === "external" && /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "transferDataTargetUser",
            label: "Select Google Workspace account",
            options: externalUserAccessOptions,
            value: (_d = formik.values.transferDataTargetUserOptionObj) == null ? void 0 : _d.value,
            error: !!formik.errors.transferDataTargetUserOptionObj && formik.touched.transferDataTargetUserOptionObj,
            onChange: (e) => {
              var _a2;
              const data = e == null ? void 0 : e.target;
              const foundAppTargetUser = usersWithAccess && usersWithAccess.length > 0 && data && data.value ? usersWithAccess.find((u) => u.id === data.value) : null;
              formik.setFieldValue("transferDataTargetUserOptionObj", data.value);
              formik.setFieldValue("transferDataTargetUser", (_a2 = foundAppTargetUser == null ? void 0 : foundAppTargetUser.id) != null ? _a2 : null);
              formik.setFieldTouched("transferDataTargetUser", true, true);
              if (preselectedUser) {
                formik.setFieldValue("transferDataSourceUser", preselectedUser.id);
                formik.setFieldValue("userId", preselectedUser.id);
              }
              setTimeout(() => formik.setFieldTouched("transferDataTargetUser", true));
            },
            helperText: formik.errors.transferDataTargetUser && formik.touched.transferDataTargetUser
          }
        )
      ] }),
      ![
        APP_ACTION_DRAWER_MODES.createEmployment,
        APP_ACTION_DRAWER_MODES.assign,
        APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete
      ].includes(mode) && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TabFilterButtons,
        {
          filters: AppActionWhenTabFilter,
          setFilterValue: setWhenActionValue,
          filterValue: (_e = formik.values.whenAction) != null ? _e : AppActionWhenValueEnum.now,
          onFilterChange: ({ filterValue }) => {
            if (filterValue === AppActionWhenValueEnum.now) formik.setFieldValue("actionDate", today);
            else formik.setFieldValue("actionDate", new LocalDate(tomorrow).toDateString());
          }
        }
      ) }),
      ![APP_ACTION_DRAWER_MODES.assign, APP_ACTION_DRAWER_MODES.googleWorkspaceTransferThenDelete].includes(
        mode
      ) && formik.values.whenAction === "later" && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_f = formik.values.actionDate) != null ? _f : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              formik.setFieldValue("actionDate", value);
            }
          },
          name: "actionDate",
          minDate: tomorrow,
          label: "Action date",
          error: !!formik.errors.actionDate && formik.touched.actionDate,
          helperText: formik.errors.actionDate && formik.touched.actionDate
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
        LoaderButton,
        {
          id: `btnConfirmAction_${userId}`,
          fullWidth: true,
          type: "submit",
          loading: isSubmitting,
          sizeVariant: "medium",
          colorVariant: "primary",
          disabled: isSubmitting || !formik.isValid || mode === APP_ACTION_DRAWER_MODES.create && noPersonalEmailSoUserCreationDisabled || mode === APP_ACTION_DRAWER_MODES.createEmployment && employmentUserStartDateInThePast,
          children: getDrawerConfirmButtonText(mode)
        }
      ) })
    ] }) }),
    createUserWithParams && appStub === "jumpcloud" && /* @__PURE__ */ jsx(
      JumpCloudNewUserParams,
      {
        onComplete: createUserWithParams,
        onClose: () => setCreateUserWithParams(null),
        username: (_g = currentlySelectedUserEmails.find((e) => e.primary)) == null ? void 0 : _g.email.split("@")[0]
      }
    )
  ] });
};
