"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { SettingsReadOnlyLine } from "@v2/feature/absence/subfeatures/settings/policy-details/components/settings-read-only-line.component";
import { processMdmServerName } from "@v2/feature/device/device.util";
import { CustomDepActionsDrawer } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/custom-dep-actions-drawer.component";
import { useDepAccountDetails } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook";
import { DepWizardHelper } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-wizard-helper";
import { DepTokenStatus } from "@v2/feature/device/features/devices-settings/features/zelt-mdm/dep.dto";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Rejected } from "@/images/side-bar-icons/Rejected.svg";
import { SETTINGS_DEVICES_DEP_ROUTE, SETTINGS_DEVICES_STORE_ROUTE } from "@/lib/routes";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceSettingsAPI } from "@/v2/feature/device/features/devices-settings/devices-settings.api";
import { EnrolmentSettingsDrawer } from "@/v2/feature/device/features/devices-settings/features/general/components/enrolment-settings-drawer.component";
import { definitionSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const SettingsCard = ({
  title,
  subCopy,
  onEdit,
  disabled,
  detail
}) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: title }),
      /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: onEdit, disabled, children: /* @__PURE__ */ jsx(Edit, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.DarkGrey })) })
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.DarkGrey, mt: spacing.m10 }, children: subCopy }),
    detail
  ] });
};
export const DevicesGeneralPage = () => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const routerHistory = useHistory();
  const [isEnrollmentOpen, setIsEnrollmentOpen] = useState(false);
  const [isCustomDepOpen, setIsCustomDepOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [generalSettings, setGeneralSettings] = useState(void 0);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const mdmServerName = processMdmServerName(globalState.user.company.companyId + globalState.user.company.name);
  const details = useDepAccountDetails(mdmServerName);
  const getGeneralSettings = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const settings = yield DeviceSettingsAPI.getCompanyDeviceSettings();
      setGeneralSettings(settings);
    } catch (error) {
      showMessage("Could not load devices settings", "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    getGeneralSettings();
  }, [getGeneralSettings]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", sx: { color: themeColors.DarkGrey }, children: "Devices" })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: isLoading, children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            flexDirection: "column",
            maxWidth: { xs: "100%", sm: "100%", md: "85%", lg: "70%", xl: "70%" },
            width: "100%",
            gap: spacing.g50
          },
          children: [
            /* @__PURE__ */ jsx(
              SettingsCard,
              {
                title: "Auto enrolment",
                subCopy: "Automated device enrolment streamlines the onboarding of company owned devices. Connect your Apple Business Manager to automatically enrol your devices in Zelt.",
                onEdit: () => {
                  var _a2;
                  return ((_a2 = details.depAccountDetails) == null ? void 0 : _a2.access_token_expiry) ? setIsCustomDepOpen(true) : routerHistory.push(SETTINGS_DEVICES_DEP_ROUTE);
                },
                disabled: false,
                detail: details.depAccountDetails ? /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, definitionSx), { mt: spacing.m15, display: "flex", flexDirection: "column" }), children: [
                  /* @__PURE__ */ jsx(SettingsReadOnlyLine, { field: "Organisation", value: (_b = (_a = details.depAccountDetails) == null ? void 0 : _a.org_name) != null ? _b : "N/A" }),
                  /* @__PURE__ */ jsx(
                    SettingsReadOnlyLine,
                    {
                      field: "MDM Server name",
                      value: (_d = (_c = details.depAccountDetails) == null ? void 0 : _c.server_name) != null ? _d : "N/A"
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    SettingsReadOnlyLine,
                    {
                      field: "Renew by",
                      value: ((_e = details.depAccountDetails) == null ? void 0 : _e.access_token_expiry) ? DepWizardHelper.formatDate((_f = details.depAccountDetails) == null ? void 0 : _f.access_token_expiry) : "N/A"
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    SettingsReadOnlyLine,
                    {
                      field: "Connection status",
                      value: ((_g = details.depAccountDetails) == null ? void 0 : _g.status) === DepTokenStatus.Active ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
                        /* @__PURE__ */ jsx(OkGreen, __spreadProps(__spreadValues({}, iconSize), { style: { fill: themeColors.Green } })),
                        " ",
                        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Active" })
                      ] }) : ((_h = details.depAccountDetails) == null ? void 0 : _h.status) === DepTokenStatus.Inactive ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
                        /* @__PURE__ */ jsx(Rejected, __spreadProps(__spreadValues({}, iconSize), { fill: themeColors.Red })),
                        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Inactive" })
                      ] }) : "Unknown"
                    }
                  )
                ] }) : void 0
              }
            ),
            /* @__PURE__ */ jsx(
              SettingsCard,
              {
                title: "Store settings",
                subCopy: "Configure the range of Zelt devices and accessories that your employees can see and order",
                disabled: false,
                onEdit: () => routerHistory.push(SETTINGS_DEVICES_STORE_ROUTE)
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(DrawerModal, { isOpen: isEnrollmentOpen, setIsOpen: setIsEnrollmentOpen, children: /* @__PURE__ */ jsx(
        EnrolmentSettingsDrawer,
        {
          generalSettings,
          onClose: () => __async(void 0, null, function* () {
            setIsEnrollmentOpen(false);
            yield getGeneralSettings();
          })
        }
      ) }),
      /* @__PURE__ */ jsx(DrawerModal, { isOpen: isCustomDepOpen, setIsOpen: setIsCustomDepOpen, children: /* @__PURE__ */ jsx(
        CustomDepActionsDrawer,
        {
          mdmServerName,
          depAccountDetailsPar: details.depAccountDetails,
          onClose: () => __async(void 0, null, function* () {
            details.refreshDepAccountDetails();
            setIsCustomDepOpen(false);
          }),
          onRefresh: () => __async(void 0, null, function* () {
            details.refreshDepAccountDetails();
          })
        }
      ) })
    ] })
  ] });
};
