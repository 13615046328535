"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { LocalDate } from "@v2/util/local-date";
import moment from "moment";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const formatAmount = (discount) => {
  return discount.discountType === "fixed" ? `-${Number(discount.amountValue).toFixed(2)}` : `-${Number(discount.amountValue).toFixed(0)}%`;
};
const formatDate = (date, format = "DD/MM/YYYY") => moment(date).format(format);
export class SuperAdminBillingDiscountTableHelper {
  static getDiscountNameColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { description }
        }
      }) => /* @__PURE__ */ jsx("div", { children: description ? description : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountIdColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { id: id2 }
        }
      }) => /* @__PURE__ */ jsx("div", { children: id2 ? id2 : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountTypeColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { discountType }
        }
      }) => /* @__PURE__ */ jsx("div", { children: discountType ? discountType : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountAmountColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.amountValue ? formatAmount(original) : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountStartDateColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { startDate }
        }
      }) => /* @__PURE__ */ jsx("div", { children: startDate ? formatDate(new LocalDate(startDate).getDate()) : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountEndDateColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { endDate }
        }
      }) => /* @__PURE__ */ jsx("div", { children: endDate ? formatDate(new LocalDate(endDate).getDate()) : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountCreatedAtColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { createdAt }
        }
      }) => /* @__PURE__ */ jsx("div", { children: createdAt ? formatDate(createdAt) : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountUpdatedAtColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { updatedAt }
        }
      }) => /* @__PURE__ */ jsx("div", { children: updatedAt ? formatDate(updatedAt) : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
  static getDiscountUpdatedByColumn(columnDefinition) {
    const { id, header, size } = columnDefinition;
    return {
      header: () => header,
      accessorFn: (row) => row,
      id,
      cell: ({
        row: {
          original: { user }
        }
      }) => /* @__PURE__ */ jsx("div", { children: user ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(UserAvatar, { userId: user.userId, size: "s20", sx: { alignSelf: "self-start" } }),
        /* @__PURE__ */ jsx(
          Typography,
          {
            sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
              display: "flex",
              alignItems: "center",
              gap: spacing.m5
            }),
            children: `${user.firstName} ${user.lastName}`
          }
        )
      ] }) : /* @__PURE__ */ jsx(
        Typography,
        {
          sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
            color: themeColors.Grey
          }),
          children: "\u2014"
        }
      ) }),
      size
    };
  }
}
