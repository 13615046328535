"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ParticipantsInvitePeersSettingModal = ({
  reviewCycle,
  isOpen,
  setIsOpen,
  onClose,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(ParticipantsInvitePeersSettingContent, { reviewCycle, onClose, refresh }) });
};
const usePeerInviteForm = (reviewCycle, onClose, refresh) => {
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      cycleSettings: (reviewCycle == null ? void 0 : reviewCycle.cycleSettings) || { allowRevieweesToInvitePeers: false },
      visibilitySettings: (reviewCycle == null ? void 0 : reviewCycle.visibilitySettings) || {
        hidePeerAuthor: false
      }
    },
    validationSchema: yup.object({
      cycleSettings: yup.object({
        allowRevieweesToInvitePeers: yup.boolean()
      }),
      visibilitySettings: yup.object({
        hidePeerAuthor: yup.boolean()
      })
    }),
    onSubmit: (_0, _1) => __async(void 0, [_0, _1], function* (values, { setSubmitting }) {
      try {
        if (reviewCycle) {
          yield ReviewCycleAPI.updateReviewCycle(__spreadProps(__spreadValues({}, reviewCycle), {
            cycleSettings: values.cycleSettings,
            visibilitySettings: values.visibilitySettings
          }));
          showMessage("Successfully update the cycle", "success");
          yield refresh();
          onClose();
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      } finally {
        setSubmitting(false);
      }
    })
  });
  return formik;
};
const ParticipantsInvitePeersSettingContent = ({
  reviewCycle,
  onClose,
  refresh
}) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const formik = usePeerInviteForm(reviewCycle, onClose, refresh);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Peer review" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "flex-start" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Allow participants to invite peers" }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Enabling this allows participants to choose their peers. However, you can also select peers on their behalf." })
      ] }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: (_b = (_a = formik.values) == null ? void 0 : _a.cycleSettings) == null ? void 0 : _b.allowRevieweesToInvitePeers,
          name: "allow-reviewees-to-invite-peers",
          onChange: (_e, enabled) => {
            formik.setFieldValue("cycleSettings.allowRevieweesToInvitePeers", enabled);
            if (enabled) formik.setFieldValue("visibilitySettings.hidePeerAuthor", !enabled);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "flex-start" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Hide peer's name" }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Peer feedback will be displayed without author's name." })
      ] }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          disabled: (_c = formik.values) == null ? void 0 : _c.cycleSettings.allowRevieweesToInvitePeers,
          checked: formik.values.visibilitySettings.hidePeerAuthor,
          name: "hide-peer-author",
          onChange: (_e, enabled) => {
            formik.setFieldValue("visibilitySettings.hidePeerAuthor", enabled);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        type: "submit",
        sizeVariant: "medium",
        colorVariant: "primary",
        name: polyglot.t("General.save"),
        loading: formik.isSubmitting,
        fullWidth: true
      }
    ) })
  ] }) });
};
