"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SingleUserSelect } from "@v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { UserRoleAPI } from "@/api-client/user-role.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { USER_OFFBOARDING_ROUTE } from "@/lib/routes";
export const OffboardingManagerChangeDrawer = ({ isOpen, setIsOpen, userId, refresh }) => {
  const history = useHistory();
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(false);
  const saveNewManager = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!values.newManagerId) return;
      try {
        setIsLoading(true);
        yield UserRoleAPI.setNewManagerOnOnboardingCurrentUser(userId, Number(values.newManagerId));
        yield refresh();
        setIsLoading(false);
        history.push(generatePath(USER_OFFBOARDING_ROUTE, { userId }));
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
    }),
    [refresh, history, showMessage, polyglot, userId]
  );
  const formik = useFormik({
    initialValues: {
      newManagerId: null
    },
    validationSchema: yup.object({
      newManagerId: yup.number().integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: saveNewManager
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      afterClose: () => {
        setIsLoading(false);
        formik.setFieldValue("newManagerId", null);
      },
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("OffboardingPage.selectNewManager") }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("OffboardingPage.selectNewManagerDescription") }),
        /* @__PURE__ */ jsx(
          SingleUserSelect,
          {
            name: "newManagerId",
            options: "company",
            onChange: (_, x) => formik.setFieldValue("newManagerId", (x == null ? void 0 : x.value) ? Number(x.value) : null),
            value: formik.values.newManagerId,
            label: polyglot.t("OffboardingPage.selectNewManager"),
            error: formik.touched.newManagerId && Boolean(formik.errors.newManagerId),
            helperText: formik.touched.newManagerId && formik.errors.newManagerId
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.save"),
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true,
            loading: isLoading
          }
        ) })
      ] }) })
    }
  );
};
