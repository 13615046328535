"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { CustomProfileFormEndpoints } from "@/v2/feature/custom-fields/custom-profile-fields.api";
import { CustomSectionForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-custom-section-form.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MissingCustomSection = ({ userId, refreshPayroll, missingFields }) => {
  var _a;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardId, setCardId] = useState(null);
  const { data: forms, mutate: refreshForms } = useApiClient(CustomProfileFormEndpoints.listForms(), {
    suspense: false
  });
  const { data: formValues, mutate: refreshValues } = useApiClient(CustomProfileFormEndpoints.getFormValues(userId), {
    suspense: false
  });
  const form = useMemo(() => forms == null ? void 0 : forms.find((f) => f.formId === cardId), [forms, cardId]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: missingFields.map((mf) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: mf.text }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          onClick: () => {
            setIsModalOpen(true);
            setCardId(mf.cardId);
          },
          colorVariant: "secondary",
          sizeVariant: "small",
          children: /* @__PURE__ */ jsx(Plus, { width: "14px", height: "14px" })
        }
      )
    ] })) }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, closeIcon: "back", children: /* @__PURE__ */ jsx(
      CustomSectionForm,
      {
        userId,
        form,
        fields: (_a = formValues == null ? void 0 : formValues.filter((f) => f.formId === (form == null ? void 0 : form.formId))) != null ? _a : [],
        onSubmit: () => {
          setIsModalOpen(false);
          refreshForms == null ? void 0 : refreshForms();
          refreshValues == null ? void 0 : refreshValues();
          refreshPayroll == null ? void 0 : refreshPayroll();
        },
        onClose: () => setIsModalOpen(false)
      }
    ) })
  ] });
};
