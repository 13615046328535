"use strict";
import { jsx } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { GlobalContext } from "@/GlobalState";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { GrowthQuestionLibraryContent } from "@/v2/feature/growth/shared/components/growth-question-library-content.component";
import { SurveyQuestionBankEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-question-bank.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { replaceParamsInQuestionText, stripHtml } from "@/v2/util/string.util";
export const SurveyCycleCreationQuestionSelectModal = ({
  isQuestionsModalOpen,
  setIsQuestionsModalOpen,
  action,
  excludeQuestions
}) => {
  var _a, _b;
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const company_name = (_b = (_a = user == null ? void 0 : user.company) == null ? void 0 : _a.name) != null ? _b : void 0;
  const { data: questions, isLoading: questionsLoading } = useApiClient(
    SurveyQuestionBankEndpoints.getSurveyQuestionFromBank(searchInput, filterString),
    {
      suspense: false
    }
  );
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const factorFilterTypes = useMemo(() => {
    if (!allGrowthFactors) return void 0;
    return {
      factor: allGrowthFactors == null ? void 0 : allGrowthFactors.map((factor) => {
        return { label: factor.name, value: factor.name };
      })
    };
  }, [allGrowthFactors]);
  const filteredQuestions = useMemo(() => {
    if (!excludeQuestions) return questions;
    const excludeQuestionIds = excludeQuestions.map((q) => q.id);
    return questions == null ? void 0 : questions.filter((q) => !excludeQuestionIds.includes(q.id));
  }, [excludeQuestions, questions]);
  const commonQuestionArray = useMemo(() => {
    if (!filteredQuestions) return [];
    return filteredQuestions.map((q) => {
      var _a2;
      return {
        id: q.id,
        questionText: q.questionText && q.questionText.length > 0 ? replaceParamsInQuestionText(stripHtml(q.questionText), { company_name }) : stripHtml(q.questionText),
        factor: q.factor,
        type: q.type,
        scaleConfig: (_a2 = q.scaleConfig) != null ? _a2 : null
      };
    });
  }, [filteredQuestions, company_name]);
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen: isQuestionsModalOpen,
      setIsOpen: setIsQuestionsModalOpen,
      onClose: () => {
        setIsQuestionsModalOpen(false);
      },
      loading: questionsLoading,
      widthPercentage: 95,
      sx: {
        px: 0
      },
      children: /* @__PURE__ */ jsx(
        GrowthQuestionLibraryContent,
        {
          questions: commonQuestionArray,
          setIsQuestionsModalOpen,
          action,
          setSearchInput,
          searchInput,
          allGrowthFactors,
          setFilterString,
          filterString,
          factorFilterTypes
        }
      )
    }
  );
};
