"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useContext, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { GlobalContext } from "@/GlobalState";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { MultiUserAvatar } from "@/v2/components/theme-components/multi-user-avatar.component";
import { CustomUserModal } from "@/v2/components/user-select-type/components/custom-user-modal.component";
import { SpecificUserModal } from "@/v2/components/user-select-type/components/specific-user-modal.component";
import {
  CustomRuleOptions,
  getCustomRuleOptionsList,
  UserSelectFiltersOptions
} from "@/v2/components/user-select-type/user-select.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { UserContractTypeEnum } from "@/v2/feature/user/features/user-profile/user-profile.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { ReachType } from "@/v2/interfaces/general.interface";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledFormHelperText } from "@/v2/styles/form-helper-text.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const getUserTabFilter = (excludeEveryone, excludeSpecific, excludeCustomRule, excludeNone, selectedSpecificButtonLabel, polyglot) => [
  ...excludeEveryone ? [] : [{ name: polyglot.t("UserSelect.everyone"), value: UserSelectFiltersOptions.Everyone }],
  ...excludeNone ? [] : [{ name: polyglot.t("UserSelect.none"), value: UserSelectFiltersOptions.None }],
  ...excludeSpecific ? [] : [{ name: selectedSpecificButtonLabel, value: UserSelectFiltersOptions.SelectSpecific }],
  ...excludeCustomRule ? [] : [{ name: polyglot.t("UserSelect.customRule"), value: UserSelectFiltersOptions.CustomRule }]
];
export const getRuleTypeString = (customRule, sitesForCompany, departmentsForCompany, entitiesForCompany, workerTypes, polyglot) => {
  var _a, _b;
  const ruleTypeArray = customRule ? customRule.split("=") : [];
  if (ruleTypeArray.length === 0 && !sitesForCompany && !departmentsForCompany && !workerTypes) return;
  const ruleType = (_a = ruleTypeArray[0]) != null ? _a : [];
  const ids = ((_b = ruleTypeArray[1]) == null ? void 0 : _b.split(",").map(Number)) || [];
  switch (ruleType) {
    case CustomRuleOptions.Site:
      return sitesForCompany ? `Site is ${ids.map((id) => {
        var _a2, _b2;
        return (_b2 = (_a2 = sitesForCompany.find((r) => r.id === id)) == null ? void 0 : _a2.name) != null ? _b2 : "";
      }).filter(Boolean).join(", ")}` : "Site";
    case CustomRuleOptions.Department:
      return departmentsForCompany ? `Department is ${ids.map((id) => {
        var _a2, _b2;
        return (_b2 = (_a2 = departmentsForCompany.find((r) => r.id === id)) == null ? void 0 : _a2.name) != null ? _b2 : "";
      }).filter(Boolean).join(", ")}` : "Department";
    case CustomRuleOptions.Entity:
      return entitiesForCompany ? `Entity is ${ids.map((id) => {
        var _a2, _b2;
        return (_b2 = (_a2 = entitiesForCompany.find((r) => r.id === id)) == null ? void 0 : _a2.legalName) != null ? _b2 : "";
      }).filter(Boolean).join(", ")}` : "Entity";
    case CustomRuleOptions.WorkerType:
      return workerTypes ? `Worker type is ${workerTypes.filter((r) => ruleTypeArray[1].split(",").includes(r)).map((r) => polyglot.t(`UserContractTypes.${r.valueOf().toLowerCase()}`)).join(", ")}` : "Worker type";
    default:
      return "";
  }
};
export const UserSelect = React.memo(
  ({
    label,
    selectedLabel,
    labelTooltip,
    requiredLabel = false,
    value,
    onChange,
    directionSx = { display: "flex", flexDirection: "column", gap: spacing.s1 },
    labelSx = __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.DarkGrey }),
    fieldSx = {},
    error = void 0,
    helperText = void 0,
    initialFilterValue = UserSelectFiltersOptions.None,
    excludeEveryone = false,
    excludeSpecific = false,
    excludeNone = true,
    selectedSpecificButtonLabel,
    ruleString = void 0,
    excludeCustomRule = false,
    customRuleOptions,
    disabled = false,
    userOptions,
    allowEmpty = false,
    hideUserList = false,
    allValidUserIds,
    allInvalidUserIds,
    reach = ReachType.Company
  }) => {
    var _a, _b;
    const { polyglot } = usePolyglot();
    const { nonTerminatedCachedUsers } = useCachedUsers();
    const [globalState] = useContext(GlobalContext);
    const allUsers = useMemo(() => {
      const reports = new Set(globalState.user.reports);
      let filteredUsers = userOptions != null ? userOptions : reach === ReachType.Team ? nonTerminatedCachedUsers.filter((u) => reports.has(u.userId)).map((u) => ({
        value: u.userId,
        label: u.displayName
      })) : nonTerminatedCachedUsers.map((u) => ({
        value: u.userId,
        label: u.displayName
      }));
      if (allValidUserIds) {
        const validUsersSet = new Set(allValidUserIds);
        filteredUsers = filteredUsers.filter((u) => validUsersSet.has(u.value));
      }
      if (allInvalidUserIds) {
        const invalidUsersSet = new Set(allInvalidUserIds);
        filteredUsers = filteredUsers.filter((u) => !invalidUsersSet.has(u.value));
      }
      return filteredUsers;
    }, [globalState.user.reports, allValidUserIds, allInvalidUserIds, userOptions, nonTerminatedCachedUsers, reach]);
    const [filterValue, setFilterValue] = useState(initialFilterValue);
    const [lastValidFilterValue, setLastValidFilterValue] = useState(initialFilterValue);
    const [isSpecificOpen, setSpecificIsOpen] = useState(false);
    const [isCustomOpen, setCustomIsOpen] = useState(false);
    const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
    const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), { suspense: false });
    const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
    const [customRuleTypeString, setCustomRuleTypeString] = useState(
      ruleString ? getRuleTypeString(
        ruleString,
        sites,
        departments,
        (_a = generalSettings == null ? void 0 : generalSettings.entities) != null ? _a : [],
        [UserContractTypeEnum.Payrolled, UserContractTypeEnum.NonPayrolled],
        polyglot
      ) : void 0
    );
    const selectableCustomRuleOptions = useMemo(() => {
      const optionsList = getCustomRuleOptionsList(polyglot);
      if (!customRuleOptions || customRuleOptions.length === 0) return optionsList;
      return optionsList.filter((option) => {
        return customRuleOptions.includes(option.value);
      });
    }, [customRuleOptions, polyglot]);
    return /* @__PURE__ */ jsxs(Box, { sx: directionSx, children: [
      label && /* @__PURE__ */ jsx(Typography, { sx: labelSx, children: /* @__PURE__ */ jsxs(Fragment, { children: [
        requiredLabel ? `${label} * ` : label,
        labelTooltip
      ] }) }),
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            disabled,
            filters: getUserTabFilter(
              excludeEveryone,
              excludeSpecific,
              excludeCustomRule,
              excludeNone,
              selectedSpecificButtonLabel != null ? selectedSpecificButtonLabel : polyglot.t("UserSelect.specific"),
              polyglot
            ),
            setFilterValue,
            filterValue,
            onFilterChange: ({ filterValue: filterValue2 }) => {
              if (filterValue2 === UserSelectFiltersOptions.Everyone) {
                onChange(
                  allUsers.map((u) => u.value),
                  filterValue2
                );
                setLastValidFilterValue(UserSelectFiltersOptions.Everyone);
              }
              if (filterValue2 === UserSelectFiltersOptions.SelectSpecific) {
                if (lastValidFilterValue === UserSelectFiltersOptions.SelectSpecific)
                  onChange(value != null ? value : [], filterValue2);
                else onChange([], filterValue2);
                setSpecificIsOpen(true);
              }
              if (filterValue2 === UserSelectFiltersOptions.CustomRule) {
                onChange([], filterValue2);
                setCustomIsOpen(true);
              }
              if (allowEmpty && filterValue2 === UserSelectFiltersOptions.None) {
                onChange([], filterValue2);
              }
            }
          }
        ),
        error && helperText && /* @__PURE__ */ jsx(StyledFormHelperText, { error, sx: { mt: spacing.m10 }, children: helperText })
      ] }),
      (value && value.length > 0 || filterValue) && !hideUserList && /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({ display: "flex", flexDirection: "column", gap: spacing.g10 }, fieldSx), children: [
        selectedLabel && /* @__PURE__ */ jsx(Typography, { sx: labelSx, children: selectedLabel }),
        filterValue === UserSelectFiltersOptions.CustomRule ? /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: customRuleTypeString != null ? customRuleTypeString : "custom rule" }) : value ? /* @__PURE__ */ jsx(MultiUserAvatar, { userIds: value }) : /* @__PURE__ */ jsx(Fragment, {})
      ] }),
      /* @__PURE__ */ jsx(
        SpecificUserModal,
        {
          isSpecificOpen,
          setSpecificIsOpen,
          label,
          value,
          onClose: () => setFilterValue(lastValidFilterValue),
          userOptions: allUsers,
          allowEmpty,
          onSelect: (userIds) => {
            onChange(userIds, filterValue);
            setSpecificIsOpen(false);
            setLastValidFilterValue(filterValue);
          }
        }
      ),
      /* @__PURE__ */ jsx(
        CustomUserModal,
        {
          isCustomOpen,
          setCustomIsOpen,
          label,
          onChange: (userIds, customRule) => onChange(userIds, filterValue, customRule),
          customRuleOptions: selectableCustomRuleOptions,
          onClose: () => setFilterValue(lastValidFilterValue),
          setCustomRuleTypeString,
          ruleString,
          sites: sites != null ? sites : [],
          departments: departments != null ? departments : [],
          entities: (_b = generalSettings == null ? void 0 : generalSettings.entities) != null ? _b : [],
          workerTypes: Object.values(UserContractTypeEnum)
        }
      )
    ] });
  }
);
