"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, FormControlLabel, RadioGroup } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { AuthAPI } from "@/v2/feature/auth/auth.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { borders } from "@/v2/styles/borders.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const state = {
  CONFIRM: 0,
  CHOOSE: 1
};
export const UserProfileResetPwDrawer = ({ isOpen, setIsOpen, userId }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const [loading, setLoading] = useState(false);
  const [resetState, setResetState] = useState(state.CONFIRM);
  const [selectedEmailIdx, setSelectedEmailIdx] = useState(-1);
  const [emails, setEmails] = useState([]);
  const user = userId ? getCachedUserById(userId) : void 0;
  const handleResetPassword = () => __async(void 0, null, function* () {
    var _a;
    if (!user) return;
    try {
      setLoading(true);
      const payload = {
        email: user.emailAddress,
        emailIndex: selectedEmailIdx
      };
      const emails2 = (_a = yield AuthAPI.forgotPassword(payload)) == null ? void 0 : _a.emails;
      if ((emails2 == null ? void 0 : emails2.length) > 1) {
        setEmails(emails2);
        setResetState(state.CHOOSE);
      } else {
        showMessage(polyglot.t("UserProfileResetPwDrawer.successMessages.reset"), "success");
        close();
      }
    } catch (error) {
      showMessage(
        polyglot.t("UserProfileResetPwDrawer.errorMessages.reset", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  const onClose = useCallback(() => {
    setSelectedEmailIdx(-1);
    setTimeout(() => setResetState(state.CONFIRM), 500);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen, onClose]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UserProfileResetPwDrawer.title") }),
    resetState === state.CONFIRM && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserProfileResetPwDrawer.confirm") }),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            fullWidth: true,
            disabled: loading,
            onClick: close,
            children: polyglot.t("General.cancel")
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            fullWidth: true,
            type: "button",
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.yes"),
            loading,
            onClick: handleResetPassword
          }
        )
      ] })
    ] }),
    resetState === state.CHOOSE && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("UserProfileResetPwDrawer.select") }),
      /* @__PURE__ */ jsx(
        RadioGroup,
        {
          "aria-labelledby": "demo-radio-buttons-group-label",
          name: "radio-buttons-group",
          onChange: (event) => {
            setSelectedEmailIdx(parseInt(event.target.value));
          },
          // the order in which emails are displayed is important - the indexes are directly
          // tied to the order that the backend uses.
          children: emails.map((email, idx) => /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "start",
              value: idx,
              checked: selectedEmailIdx === idx,
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              label: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: email }),
              sx: {
                justifyContent: "space-between",
                borderBottom: borders.middle,
                py: spacing.p10
              }
            },
            email
          ))
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "secondary",
            fullWidth: true,
            disabled: loading,
            onClick: close,
            children: polyglot.t("General.cancel")
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            fullWidth: true,
            type: "button",
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.send"),
            loading,
            onClick: handleResetPassword
          }
        )
      ] })
    ] })
  ] }) });
};
