"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
export const TimeInputFields = ({
  value,
  onChange,
  hoursLabel,
  minutesLabel,
  containerSx,
  hoursWidth = "50%",
  minutesWidth = "50%",
  polyglot,
  disabled,
  maxMinutes
}) => {
  const handleHoursChange = useCallback(
    (e) => {
      const hoursValue = e.target.value.replace(/\D/g, "");
      const minutes = value ? value % 60 : 0;
      let totalMinutes = Number(hoursValue) * 60 + minutes;
      if (maxMinutes !== void 0 && totalMinutes > maxMinutes) {
        totalMinutes = maxMinutes;
      }
      onChange(totalMinutes);
    },
    [value, onChange, maxMinutes]
  );
  const handleMinutesChange = useCallback(
    (e) => {
      const minutesValue = e.target.value.replace(/\D/g, "");
      const hours = value ? Math.floor(value / 60) : 0;
      let totalMinutes = hours * 60 + Math.min(Number(minutesValue), 59);
      if (maxMinutes !== void 0 && totalMinutes > maxMinutes) {
        totalMinutes = maxMinutes;
      }
      onChange(totalMinutes);
    },
    [value, onChange, maxMinutes]
  );
  return /* @__PURE__ */ jsx(Box, { sx: __spreadValues({ display: "flex", gap: "10px", alignItems: "center" }, containerSx), children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "10px", width: "100%" }, children: [
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "hours",
        label: hoursLabel || polyglot.t("TimeUnitTypes.hours"),
        value: value !== null ? Math.floor(value / 60) : "-",
        onChange: handleHoursChange,
        sx: { width: hoursWidth },
        disabled
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "minutes",
        label: minutesLabel || polyglot.t("TimeUnitTypes.minutes"),
        value: value !== null ? value % 60 : "-",
        onChange: handleMinutesChange,
        sx: { width: minutesWidth },
        disabled
      }
    )
  ] }) });
};
