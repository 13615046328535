"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
export const UploadProfilePicture = ({
  value,
  onChange,
  disabled = false,
  url,
  userId,
  showBadge = false,
  badge,
  avatarSize = "s40",
  boxSize = 40
}) => {
  const [resetKey, setResetKey] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarVisibility, setAvatarVisibility] = useState(true);
  useEffect(() => {
    setResetKey(resetKey + 1);
    if (resetKey !== 1) setIsLoading(false);
  }, [value]);
  const handleChangeStatus = (file, status) => {
    switch (status) {
      case "preparing": {
        setIsLoading(true);
        break;
      }
      case "done": {
        if (file.xhr) {
          onChange(file.xhr.response);
        }
        setIsLoading(false);
        break;
      }
      case "removed": {
        onChange();
        break;
      }
      default: {
        break;
      }
    }
  };
  const setVisibility = (visibility) => {
    if (!disabled) {
      setAvatarVisibility(visibility);
    }
  };
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        position: "relative",
        width: boxSize
      },
      onMouseEnter: () => setVisibility(false),
      onMouseLeave: () => setVisibility(true),
      children: [
        isLoading ? /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            },
            children: /* @__PURE__ */ jsx(
              Skeleton,
              {
                animation: "wave",
                variant: "circular",
                width: boxSize,
                height: boxSize,
                sx: { backgroundColor: themeColors.Background }
              }
            )
          }
        ) : /* @__PURE__ */ jsx(Box, { children: userId ? /* @__PURE__ */ jsx(UserAvatar, { userId, size: avatarSize, showBadge, badge }, userId) : /* @__PURE__ */ jsx(Avatar, { src: value, sx: { width: boxSize, height: boxSize } }) }),
        !disabled && /* @__PURE__ */ jsx(
          Dropzone,
          {
            styles: {
              dropzone: {
                position: "absolute",
                borderRadius: "50%",
                width: boxSize,
                height: boxSize,
                top: 0,
                margin: 0,
                minHeight: "0px",
                overflow: "hidden",
                border: "none"
              },
              inputLabel: __spreadValues({
                fontSize: "1rem",
                padding: "0.5rem",
                textAlign: "center"
              }, value ? { fontSize: "1.25rem" } : {})
            },
            getUploadParams: () => ({
              url
            }),
            onChangeStatus: handleChangeStatus,
            maxFiles: 1,
            maxSizeBytes: 10485760,
            canCancel: false,
            accept: "image/*",
            inputContent: disabled || isLoading || avatarVisibility ? "" : ""
          },
          resetKey
        )
      ]
    }
  );
};
