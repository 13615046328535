"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
export const ReportSettingsSection = ({
  effectiveDate,
  setEffectiveDate,
  showEffectiveRecords,
  setShowEffectiveRecords
}) => {
  const { polyglot } = usePolyglot();
  const [value, setValue] = useState(effectiveDate);
  const [valueShowEffective, setValueShowEffective] = useState(showEffectiveRecords);
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s3, height: "100%" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ReportsPage.effectiveRecords") }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "This section filters user profile records, such as role, compensation, and contract history, to show only current, effective records by removing past and future entries." })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Show effective records only" }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: valueShowEffective,
          name: "anonymous-survey",
          onChange: (_e, enabled) => {
            setValueShowEffective(enabled);
          }
        }
      )
    ] }) }),
    valueShowEffective && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "effectiveDate",
        label: true,
        hiddenLabel: true,
        required: true,
        onChange: (date) => {
          setValue(date);
        },
        value
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => {
          setEffectiveDate(value);
          setShowEffectiveRecords(valueShowEffective);
        },
        fullWidth: true,
        children: "Apply"
      }
    ) })
  ] });
};
