"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@/v2/components/typography/typography.component";
export const LabelValueViewItem = ({
  label,
  value,
  sx,
  labelFont = "paragraph"
}) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }, sx),
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: labelFont, children: label }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: value })
      ]
    }
  );
};
