"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx } from "react/jsx-runtime";
import { CustomBenefitCategory } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import Asset from "@/images/benefits/assets.png";
import Educational from "@/images/benefits/educational.webp";
import Entertainment from "@/images/benefits/entertainment.webp";
import Health from "@/images/benefits/health.webp";
import Loans from "@/images/benefits/loans.png";
import Medical from "@/images/benefits/medical.png";
import Mileage from "@/images/benefits/mileage.webp";
import Pension from "@/images/benefits/pension.webp";
import Telephone from "@/images/benefits/telephone.webp";
import Travel from "@/images/benefits/travel.webp";
import Vehicles from "@/images/benefits/vehicles.webp";
import Vouchers from "@/images/benefits/vouchers.webp";
import { getLogoByProviderName } from "@/v2/feature/benefits/subfeature/insurance/insurance.util";
import { getPensionLogoByProviderName } from "@/v2/feature/benefits/subfeature/pension/pension.util";
export const getDefaultBenefitImageByCategory = (benefitCategory, width = "100%", height = "auto") => {
  const size = { width, height };
  switch (benefitCategory) {
    case CustomBenefitCategory.Asset:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Asset", src: Asset }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Educational:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Educational", src: Educational }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Entertainment:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Entertainment", src: Entertainment }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Health:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Health", src: Health }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Loans:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Loans", src: Loans }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Medical:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Medical", src: Medical }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Mileage:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Mileage", src: Mileage }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Pension:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Pension", src: Pension }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Telephone:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Telephone", src: Telephone }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Travel:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Travel", src: Travel }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Vehicles:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Vehicles", src: Vehicles }, size), { loading: "lazy" }));
    case CustomBenefitCategory.Vouchers:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Vouchers", src: Vouchers }, size), { loading: "lazy" }));
    default:
      return /* @__PURE__ */ jsx("img", __spreadProps(__spreadValues({ alt: "Benefit", src: Pension }, size), { loading: "lazy" }));
  }
};
export const getImageByBenefitType = (item) => {
  const { benefitType, name, url, category } = item;
  if (benefitType === "insurance") {
    return getLogoByProviderName(name, 40);
  } else if (benefitType === "custom-benefit") {
    return url ? /* @__PURE__ */ jsx("img", { src: url, width: 40, height: 40, alt: "benefit-logo" }) : getDefaultBenefitImageByCategory(category, "50px", "auto");
  } else if (benefitType === "pension") {
    return getPensionLogoByProviderName(name, 40);
  }
  return /* @__PURE__ */ jsx(Fragment, {});
};
