"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { isHTMLValid } from "@/v2/feature/growth/shared/utils/growth-common.util";
import { borders } from "@/v2/styles/borders.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export var FocusState = /* @__PURE__ */ ((FocusState2) => {
  FocusState2["COMMENT"] = "COMMENT";
  FocusState2["OPTIONS"] = "OPTIONS";
  FocusState2["NONE"] = "NONE";
  return FocusState2;
})(FocusState || {});
export const QuestionComment = ({
  question,
  currentComment,
  handleCommentChange,
  showError,
  registerCommentFieldRef,
  setFocusState,
  onKeyDown,
  isFocused = false
}) => {
  const richTextFieldRef = useRef(null);
  const previousFocusState = useRef(false);
  const setRefs = (el) => {
    richTextFieldRef.current = el;
    registerCommentFieldRef(el);
  };
  useEffect(() => {
    if (isFocused && !previousFocusState.current && richTextFieldRef.current) {
      setTimeout(() => {
        if (richTextFieldRef.current) {
          richTextFieldRef.current.focus();
          setFocusState("COMMENT" /* COMMENT */);
        }
      }, 50);
    }
    previousFocusState.current = !!isFocused;
  }, [isFocused, setFocusState]);
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "flex",
        flexDirection: "column",
        gap: spacing.g8,
        pt: spacing.p16,
        borderTop: `${borders.background}`,
        borderRadius: "4px"
      },
      "data-comment-container": "true",
      onClick: () => {
        if (richTextFieldRef.current) {
          richTextFieldRef.current.focus();
          setFocusState("COMMENT" /* COMMENT */);
        }
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Add comment" }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: question.isCommentRequired ? "Required" : "Optional" })
        ] }),
        /* @__PURE__ */ jsx(
          RichTextField,
          {
            value: currentComment,
            onChange: (value) => handleCommentChange(value),
            error: showError && question.hasComment && question.isCommentRequired,
            helperText: showError && question.hasComment && question.isCommentRequired && !isHTMLValid(currentComment) ? "This field is required" : "",
            inputRef: setRefs,
            onFocus: () => setFocusState("COMMENT" /* COMMENT */),
            onKeyDown
          },
          question.id
        )
      ]
    }
  );
};
