"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Box } from "@mui/system";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GrowthScaleAPI } from "@/v2/feature/growth/growth-scale/growth-scale.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GrowthScaleArchiveModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  selectedScale,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(GrowthScaleArchiveModalContent, { selectedScale, refresh, onClose }) });
};
const GrowthScaleArchiveModalContent = ({
  selectedScale,
  refresh,
  onClose
}) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const handleArchive = useCallback(() => __async(void 0, null, function* () {
    try {
      if (!selectedScale) {
        return;
      }
      yield GrowthScaleAPI.archiveScale(selectedScale.id);
      showMessage(
        polyglot.t("SuccessMessage.successfully", {
          action: "archived",
          record: "scale"
        }),
        "success"
      );
      yield refresh == null ? void 0 : refresh();
      onClose();
    } catch (error) {
      showMessage(
        polyglot.t("ErrorMessages.somethingWentWrong", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    }
  }), [onClose, showMessage, polyglot, refresh, selectedScale]);
  if (!selectedScale)
    return /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, drawerContentSx), { px: spacing.s2 }), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Scale" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("GrowthScaleArchiveModalContent.scaleNotFound") })
    ] });
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("GrowthScaleArchiveModalContent.confirmation") }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("GrowthScaleArchiveModalContent.areYouSureYouWantToArchive") }) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, onClick: handleArchive, children: polyglot.t("GrowthScaleArchiveModalContent.archiveAction") })
    ] })
  ] });
};
