"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { InfoModal } from "@v2/components/info-modal.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { mergeDevicePolicies } from "@v2/feature/device/device.dto";
import { DevicePolicyType, SupportedDeviceType } from "@v2/feature/device/device.interface";
import { AndroidSettingsCard } from "@v2/feature/device/features/devices-settings/features/mdm-android/android-setings-card.component";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { SETTINGS_DEVICES_GENERAL_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { NoAppliedPolicies } from "@/v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledFormHelperText } from "@/v2/styles/form-helper-text.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import {
  buttonBoxSx,
  cardListSx,
  cardSubtitleSx,
  cardTitleIconSx,
  fieldLabelSx,
  settingsfieldSx
} from "@/v2/styles/settings.styles";
export const AndroidMDMSettings = () => {
  var _a, _b, _c;
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [editPoliciesCard, setEditPoliciesCard] = useState(false);
  const [unchangedAppliedPolicies, setUnchangedAppliedPolicies] = useState(NoAppliedPolicies);
  const [androidAppliedPolicies, setAndroidAppliedPolicies] = useState(NoAppliedPolicies);
  const [passCodePolicies, setPassCodePolicies] = useState([]);
  const [osUpdatesPolicies, setOsUpdatesPolicies] = useState([]);
  const [allPolicies, setAllPolicies] = useState([]);
  const [appliedPolicies, setAppliedPolicies] = useState(NoAppliedPolicies);
  const routerHistory = useHistory();
  const handlePolicyChange = (policyType) => (event) => {
    setAndroidAppliedPolicies((prev) => __spreadProps(__spreadValues({}, prev), {
      [policyType]: [Number(event.target.value)]
    }));
  };
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const [policies, appliedPolicies2] = yield Promise.all([
        DeviceAPI.getAvailableCompanyDevicePolicies(),
        DeviceAPI.getAppliedCompanyDevicePolicies()
      ]);
      setUnchangedAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setAndroidAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setAppliedPolicies(Boolean(appliedPolicies2) ? appliedPolicies2 : NoAppliedPolicies);
      setAllPolicies(policies);
      setPassCodePolicies([
        {
          id: 0,
          name: "None",
          type: DevicePolicyType.passcode,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.android
        },
        ...policies.filter(
          (policy) => policy.type === DevicePolicyType.passcode && policy.supportedDeviceType.toString() === SupportedDeviceType.android.toString()
        )
      ]);
      setOsUpdatesPolicies([
        {
          id: 0,
          name: "None",
          type: DevicePolicyType.updates,
          isInhouseMdm: false,
          hide: false,
          supportedDeviceType: SupportedDeviceType.android
        },
        ...policies && policies.length > 0 ? policies.filter(
          (policy) => policy.type === DevicePolicyType.updates && policy.supportedDeviceType.toString() === SupportedDeviceType.android.toString()
        ) : []
      ]);
    } catch (error) {
      showMessage("Something went wrong. Could not load the company policies.", "error");
    } finally {
      setLoading(false);
    }
  }), [showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  const passcodePoliciesOptions = passCodePolicies.map((policy) => ({
    value: policy.id,
    label: policy.name.includes("Basic") ? `${policy.name} (Recommended)` : policy.name,
    description: policy.description
  })).sort((a, b) => {
    var _a2, _b2;
    const sortValue = {
      None: 4,
      "Basic (Recommended)": 1,
      Moderate: 2,
      Strict: 3
    };
    return ((_a2 = sortValue[a.label]) != null ? _a2 : 0) - ((_b2 = sortValue[b.label]) != null ? _b2 : 0);
  });
  const selectedPasscodePolicy = (_a = passcodePoliciesOptions.find(
    (policy) => androidAppliedPolicies[DevicePolicyType.passcode].includes(policy.value)
  )) != null ? _a : passcodePoliciesOptions.find((value) => value.value === 0);
  const osUpdatesPoliciesOptions = osUpdatesPolicies.map((policy) => ({
    value: policy.id,
    label: policy.name,
    description: policy.description
  })).sort((a, b) => {
    var _a2, _b2;
    const sortValue = {
      None: 4,
      "Notify only": 1,
      "Auto install updates and remind to restart if required": 2,
      "Auto install and force restart": 3
    };
    return ((_a2 = sortValue[a.label]) != null ? _a2 : 0) - ((_b2 = sortValue[b.label]) != null ? _b2 : 0);
  });
  const selectedOsUpdatesPolicy = (_b = osUpdatesPoliciesOptions.find(
    (policy) => androidAppliedPolicies[DevicePolicyType.updates].includes(policy.value)
  )) != null ? _b : osUpdatesPoliciesOptions.find((value) => value.value === 0);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Devices" })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, children: [
      editPoliciesCard ? /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              width: "100%",
              maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" },
              paddingBottom: spacing.p60
            },
            children: [
              /* @__PURE__ */ jsx(Box, { sx: cardTitleIconSx, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Security" }) }),
              /* @__PURE__ */ jsx(Typography, { sx: cardSubtitleSx, children: "Configure security settings for Android devices connected to Hexnode MDM." }),
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap30 }, children: [
                /* @__PURE__ */ jsxs(Box, { sx: settingsfieldSx, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: fieldLabelSx, children: "Passcode" }),
                  /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, cardListSx), { position: "relative" }), children: [
                    /* @__PURE__ */ jsx(
                      SelectComponent,
                      {
                        name: "passcode",
                        label: "",
                        options: passcodePoliciesOptions,
                        value: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.value,
                        compareValue: selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.value,
                        onChange: handlePolicyChange(DevicePolicyType.passcode),
                        disabled: !editPoliciesCard
                      }
                    ),
                    (selectedPasscodePolicy == null ? void 0 : selectedPasscodePolicy.description) && /* @__PURE__ */ jsx(StyledFormHelperText, { children: selectedPasscodePolicy.description })
                  ] })
                ] }),
                /* @__PURE__ */ jsxs(Box, { sx: settingsfieldSx, children: [
                  /* @__PURE__ */ jsx(Typography, { sx: fieldLabelSx, children: "OS updates" }),
                  /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, cardListSx), { position: "relative" }), children: [
                    /* @__PURE__ */ jsx(
                      SelectComponent,
                      {
                        name: "osUpdates",
                        label: "",
                        options: osUpdatesPoliciesOptions,
                        value: (_c = selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.value) != null ? _c : 0,
                        compareValue: selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.value,
                        onChange: handlePolicyChange(DevicePolicyType.updates),
                        disabled: !editPoliciesCard
                      }
                    ),
                    (selectedOsUpdatesPolicy == null ? void 0 : selectedOsUpdatesPolicy.description) && /* @__PURE__ */ jsx(StyledFormHelperText, { children: selectedOsUpdatesPolicy.description })
                  ] })
                ] })
              ] })
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              width: "100%",
              maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" },
              paddingBottom: spacing.p60
            },
            children: /* @__PURE__ */ jsx(Box, { children: editPoliciesCard && /* @__PURE__ */ jsxs(
              Box,
              {
                sx: __spreadProps(__spreadValues({}, buttonBoxSx), {
                  display: "flex",
                  maxWidth: "400px",
                  width: "auto",
                  mt: spacing.mt40,
                  gap: spacing.gap10
                }),
                children: [
                  /* @__PURE__ */ jsx(
                    ButtonComponent,
                    {
                      fullWidth: true,
                      sizeVariant: "medium",
                      colorVariant: "secondary",
                      onClick: () => {
                        setEditPoliciesCard(false);
                        setAndroidAppliedPolicies(unchangedAppliedPolicies);
                      },
                      children: "Cancel"
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    LoaderButton,
                    {
                      name: "Save",
                      loading,
                      fullWidth: true,
                      colorVariant: "primary",
                      sizeVariant: "medium",
                      onClick: () => __async(void 0, null, function* () {
                        try {
                          const relevantPolicyIds = new Set(
                            allPolicies.filter(
                              (policy) => policy.supportedDeviceType.toString() === SupportedDeviceType.android.toString()
                            ).map((policy) => policy.id)
                          );
                          for (const key of Object.values(DevicePolicyType)) {
                            appliedPolicies[key] = appliedPolicies[key].filter(
                              (value) => !relevantPolicyIds.has(value)
                            );
                          }
                          let updatedPolicies = mergeDevicePolicies(androidAppliedPolicies, appliedPolicies);
                          yield DeviceAPI.updateCompanyDeviceAppliedPolicies(updatedPolicies);
                          setEditPoliciesCard(false);
                          showMessage("Policies successfully saved.", "success");
                          setIsInfoModalOpen(true);
                          yield refresh();
                          routerHistory.push(SETTINGS_DEVICES_GENERAL_ROUTE);
                        } catch (e) {
                          showMessage("The new policies could not be saved. Please try again.", "error");
                        }
                      })
                    }
                  )
                ]
              }
            ) })
          }
        )
      ] }) : /* @__PURE__ */ jsx(
        AndroidSettingsCard,
        {
          setEditPoliciesCard,
          selectedPasscodePolicy,
          selectedOsUpdatesPolicy
        }
      ),
      /* @__PURE__ */ jsx(InfoModal, { isOpen: isInfoModalOpen, title: "Changes saved", onClose: () => setIsInfoModalOpen(false), children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Your changes have been applied to all devices, however it may take sometime to show here. Check back in a few hours." }) })
    ] })
  ] });
};
