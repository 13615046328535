"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AttendanceShiftAPI } from "@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.api";
import { UserAvatar } from "@v2/feature/user/components/user-avatar.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { isoDateAndTimeFormat, isValidTimeString } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { spacing } from "@v2/styles/spacing.styles";
import { LocalDate } from "@v2/util/local-date";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Hazard } from "@/images/side-bar-icons/Hazard.svg";
import { nestErrorMessage } from "@/lib/errors";
import { TimePickerComponent } from "@/v2/components/forms/time-picker.component";
import { NotFoundBlock } from "@/v2/components/not-found-block.component";
import { AttendanceEndpoints } from "@/v2/feature/attendance/attendance.api";
import { endAttendanceShift } from "@/v2/feature/dashboard/features/sections/user-shift/user-shift.util";
import { ViewerItem } from "@/v2/feature/payments/components/payment-details-drawer.component";
import { hazardSize } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { hazardMissingField } from "@/v2/styles/icon-button.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { dateAPItoDisplay, extractTimeOnlyFromDateTimeString } from "@/v2/util/date-format.util";
export const UnfinishedAttendanceShiftDrawer = ({
  isOpen,
  setIsOpen,
  shift,
  refresh,
  onClose,
  afterClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, afterClose, children: /* @__PURE__ */ jsx(UnfinishedAttendanceShiftDrawerContent, { refresh, setIsOpen, shift }) });
};
const UnfinishedAttendanceShiftDrawerContent = ({
  shift,
  setIsOpen,
  refresh
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const hasUnfinishedEntries = shift ? shift.shiftEntries.some((entry) => !entry.endHour) : false;
  const isUnfinishedShift = shift ? !shift.isEnded : false;
  const [showMessage] = useMessage();
  const { logDate, userId } = shift != null ? shift : {};
  const { data: userSchedule } = useApiClient(
    logDate && userId ? AttendanceEndpoints.getUserAttendanceScheduleByLogDate(userId, logDate) : userId ? AttendanceEndpoints.getUserAttendanceSchedule(userId) : null,
    { suspense: false }
  );
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      if (!(shift == null ? void 0 : shift.shiftEntries) || shift.shiftEntries.length === 0) {
        showMessage("No unfinished entries have been found.", "error");
        return;
      }
      try {
        setLoading(true);
        if (hasUnfinishedEntries) {
          yield AttendanceShiftAPI.endUnfinishedAttendanceShiftById(
            shift.userId,
            shift.shiftEntries.at(-1).entryId,
            shift.logDate,
            values.endHour,
            values.endHourTimestamp,
            // for now if admin ends the shift, don't save location
            null,
            null
          );
          showMessage("Successfully updated attendance shift", "success");
          yield refresh();
        } else {
          if (logDate) yield endAttendanceShift(logDate, showMessage, refresh, polyglot, true, shift.userId);
        }
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.badRequest", {
            errorMessage: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [shift, showMessage, hasUnfinishedEntries, setIsOpen, refresh, logDate, polyglot]
  );
  const attendanceTypesAllowedById = useMemo(
    () => {
      var _a2, _b2;
      return (_b2 = (_a2 = userSchedule == null ? void 0 : userSchedule.attendanceTypesAllowed) == null ? void 0 : _a2.reduce((response, type) => {
        response[type.id] = type;
        return response;
      }, {})) != null ? _b2 : {};
    },
    [userSchedule]
  );
  const minEndTime = useMemo(() => {
    var _a2;
    if (!shift) return /* @__PURE__ */ new Date();
    if (!shift.shiftEntries || !((_a2 = shift.shiftEntries[0]) == null ? void 0 : _a2.startHourTimestamp)) {
      const minTime = new LocalDate(shift.logDate);
      minTime.getDate().setHours(0, 0, 0, 0);
      return minTime.getDate();
    }
    const lastEntryStart = new Date(shift.shiftEntries.at(-1).startHourTimestamp);
    lastEntryStart.setMinutes(lastEntryStart.getMinutes() + 5, 0, 0);
    return lastEntryStart;
  }, [shift]);
  const viewModeSchema = Yup.object().shape({});
  const editModeSchema = Yup.object().shape({
    endHour: Yup.string().matches(isoDateAndTimeFormat, polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.valueInvalid")).required(polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.endHourRequired")),
    endHourTimestamp: Yup.date().min(
      minEndTime,
      `Min time: ${minEndTime.toLocaleDateString(void 0, {
        hour: "2-digit",
        minute: "2-digit"
      }).split(", ")[1]}`
    ).typeError(polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.valueInvalid")).required(polyglot.t("UnfinishedAttendanceShiftDrawer.errorMessages.endHourTimeStampRequired"))
  });
  const formik = useFormik({
    initialValues: {
      endHour: "",
      endHourTimestamp: null
    },
    validationSchema: isEditing ? editModeSchema : viewModeSchema,
    onSubmit
  });
  const timeTextForEntry = (entry) => {
    const missingTimePlaceholder = "--:--";
    const startTime = entry.startHour ? extractTimeOnlyFromDateTimeString(entry.startHour) : missingTimePlaceholder;
    const endTime = entry.endHour ? extractTimeOnlyFromDateTimeString(entry.endHour) : missingTimePlaceholder;
    return `${startTime} to ${endTime}`;
  };
  if (!shift) return /* @__PURE__ */ jsx(NotFoundBlock, { title: "Something went wrong", subtitle: "Shift not found" });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { autoComplete: "off", style: drawerContentSx, children: [
    !isEditing ? /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("UnfinishedAttendanceShiftDrawer.unfinishedShift") }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: 0, mb: spacing.mb20 }, children: [
        /* @__PURE__ */ jsx(
          ViewerItem,
          {
            title: polyglot.t("UnfinishedAttendanceShiftDrawer.employee"),
            value: /* @__PURE__ */ jsx(UserAvatar, { userId: shift.userId, size: "s20", nameColor: "DarkGrey", showName: true, nameVariant: "headline3" })
          }
        ),
        (isUnfinishedShift || hasUnfinishedEntries) && /* @__PURE__ */ jsx(
          ViewerItem,
          {
            title: polyglot.t("AttendanceReportColumnsOptions.status"),
            value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
              /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, hazardMissingField), { width: "14px", height: "14px" }), children: /* @__PURE__ */ jsx(Hazard, __spreadValues({}, hazardSize)) }),
              polyglot.t("UnfinishedAttendanceShiftDrawer.unfinished")
            ] })
          }
        ),
        userSchedule && (userSchedule == null ? void 0 : userSchedule.name) && /* @__PURE__ */ jsx(ViewerItem, { title: polyglot.t("UnfinishedAttendanceShiftDrawer.schedule"), value: userSchedule.name }),
        logDate && /* @__PURE__ */ jsx(
          ViewerItem,
          {
            title: polyglot.t("UnfinishedAttendanceShiftDrawer.logDate"),
            value: (_a = dateAPItoDisplay(logDate)) != null ? _a : ""
          }
        ),
        /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { mt: spacing.mt20 }, children: polyglot.t("UnfinishedAttendanceShiftDrawer.entries") }),
        shift == null ? void 0 : shift.shiftEntries.map((entry) => {
          var _a2, _b2;
          return /* @__PURE__ */ jsx(
            ViewerItem,
            {
              title: (_b2 = (_a2 = attendanceTypesAllowedById[entry.attendanceTypeId]) == null ? void 0 : _a2.name) != null ? _b2 : "N/A",
              value: /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { color: themeColors.DarkGrey }, children: timeTextForEntry(entry) })
            }
          );
        })
      ] })
    ] }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, mt: 0, mb: spacing.mb20 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("EditTrackTimeDrawerContent.edit") }),
      /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("UnfinishedAttendanceShiftDrawer.employee"),
          value: /* @__PURE__ */ jsx(UserAvatar, { userId: shift.userId, size: "s20", nameColor: "DarkGrey", showName: true, nameVariant: "headline3" })
        }
      ),
      userSchedule && (userSchedule == null ? void 0 : userSchedule.name) && /* @__PURE__ */ jsx(ViewerItem, { title: polyglot.t("UnfinishedAttendanceShiftDrawer.schedule"), value: userSchedule.name }),
      logDate && /* @__PURE__ */ jsx(
        ViewerItem,
        {
          title: polyglot.t("UnfinishedAttendanceShiftDrawer.logDate"),
          value: (_b = dateAPItoDisplay(logDate)) != null ? _b : ""
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Date" }),
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: new LocalDate(shift.logDate).toLocaleDateString(void 0, {
            day: "2-digit",
            month: "short",
            year: "numeric"
          }) })
        ] }),
        shift.shiftEntries.at(-1) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.sm }, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Started at" }),
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: new LocalDate(shift.shiftEntries.at(-1).startHour).toTimeOnlyString({ hour: true, minute: true }) })
        ] }),
        /* @__PURE__ */ jsx(Box, { sx: { width: "50%", maxWidth: "120px" }, children: /* @__PURE__ */ jsx(
          TimePickerComponent,
          {
            textFieldKey: "endHour",
            label: polyglot.t("UnfinishedAttendanceShiftDrawer.endHour"),
            value: formik.values.endHour,
            onChange: (event) => {
              const time = event.target.value;
              if (isValidTimeString(time)) {
                const date = new LocalDate(`${shift.logDate}T${time}:00`);
                formik.setFieldValue("endHour", date.toFullString());
                formik.setFieldValue("endHourTimestamp", date.getDate());
                formik.validateField("endHour");
                formik.validateField("endHourTimestamp");
              }
            },
            error: !!formik.errors.endHour && formik.touched.endHour || !!formik.errors.endHourTimestamp && formik.touched.endHourTimestamp,
            helperText: formik.touched.endHour && formik.errors.endHour || formik.touched.endHourTimestamp && formik.errors.endHourTimestamp,
            fullWidth: true
          }
        ) })
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: shift.canApprove && /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: isEditing ? polyglot.t("General.submit") : hasUnfinishedEntries ? polyglot.t("General.edit") : polyglot.t("UnfinishedAttendanceShiftDrawer.completeShift"),
        fullWidth: true,
        loading,
        sizeVariant: "medium",
        colorVariant: "primary",
        onClick: () => {
          if (isEditing || !isEditing && !hasUnfinishedEntries && isUnfinishedShift) {
            formik.handleSubmit();
          } else if (!isEditing && hasUnfinishedEntries) {
            setIsEditing(true);
          }
        }
      }
    ) })
  ] }) });
};
