"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { ReactComponent as Plus } from "@/images/fields/Plus.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { MissingFieldsDetail } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { AddressForm } from "@/v2/feature/user/features/user-profile/details/components/user-profile-address-form.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const MissingAddress = ({ userId, refreshPayroll, missingFieldsForCategory }) => {
  const { polyglot } = usePolyglot();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "column" }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: polyglot.t("MissingAddressFormForPersonalContractSigning.homeAddress") }),
        /* @__PURE__ */ jsx(IconButton, { sizeVariant: "small", colorVariant: "secondary", onClick: () => setIsModalOpen(true), children: /* @__PURE__ */ jsx(Plus, { width: "14px", height: "14px" }) })
      ] }),
      missingFieldsForCategory && MissingFieldsDetail(missingFieldsForCategory)
    ] }),
    /* @__PURE__ */ jsx(DrawerModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, closeIcon: "back", children: /* @__PURE__ */ jsx(
      AddressForm,
      {
        userId,
        handleRefresh: () => refreshPayroll == null ? void 0 : refreshPayroll(),
        onClose: () => setIsModalOpen(false),
        showEmployee: true
      }
    ) })
  ] });
};
