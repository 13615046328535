"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { PayrollExternalApi } from "@/v2/feature/payroll/payroll-external.api";
import { PayrollAPI } from "@/v2/feature/payroll/payroll.api";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollReportType = () => {
  var _a;
  const { polyglot } = usePolyglot();
  const PayrollReportTypes = [
    { name: "P30", desc: polyglot.t("PayrollReportType.p30") },
    { name: "P32", desc: polyglot.t("PayrollReportType.p32") },
    { name: "Payslips", desc: polyglot.t("PayrollReportType.payslips") }
  ];
  const [payrolls, setPayrolls] = useState();
  const [selectedPayrollId, setSelectedPayrollId] = useState();
  const [payrollPeriods, setPayrollPeriods] = useState();
  const [paySchedules, setPaySchedules] = useState();
  const [selectedType, setSelectedType] = useState("");
  const [selectedP32TaxYear, setSelectedP32TaxYear] = useState("");
  const [selectedP30Period, setSelectedP30Period] = useState("");
  const [selectedPayslipPayrun, setSelectedPayslipPayrun] = useState("");
  const [showMessage] = useMessage();
  const taxYears = useMemo(() => [...new Set((payrollPeriods != null ? payrollPeriods : []).map(({ taxYear }) => taxYear))].sort(), [
    payrollPeriods
  ]);
  const p30Periods = useMemo(
    () => (payrollPeriods != null ? payrollPeriods : []).map((p) => `${p.periodEnding}|${p.taxYear}`).sort().reverse(),
    // show in date order, most-recent first
    [payrollPeriods]
  );
  const payslipPayruns = useMemo(
    () => (paySchedules != null ? paySchedules : []).map(({ payRuns }) => payRuns.map(({ metadata }) => metadata)).flat().sort((a, b) => b.endDate.localeCompare(a.endDate)),
    [paySchedules]
  );
  useEffect(() => {
    PayrollAPI.getCompanyPayrolls().then(
      (payrolls2) => [...payrolls2.items].sort((a, b) => {
        if (!a.entity) return -1;
        if (!b.entity) return 1;
        return a.entity.legalName.localeCompare(b.entity.legalName, void 0, { sensitivity: "base" });
      })
    ).then(setPayrolls);
  }, []);
  useEffect(() => {
    setSelectedType("");
    setPayrollPeriods(void 0);
    setPaySchedules(void 0);
    if (typeof selectedPayrollId === "number") {
      PayrollExternalApi.getHmrcLiabilities(selectedPayrollId).then(setPayrollPeriods);
      PayrollExternalApi.getPaySchedulesForAllTaxYears(selectedPayrollId).then(setPaySchedules);
    }
  }, [selectedPayrollId]);
  useEffect(() => {
    setSelectedP30Period("");
    setSelectedP32TaxYear("");
    setSelectedPayslipPayrun("");
  }, [selectedType]);
  const formatPeriodEndDate = useCallback(
    (date) => new Date(date).toLocaleDateString(void 0, {
      year: "numeric",
      month: "long",
      day: "2-digit"
    }),
    []
  );
  const showDownloadButton = selectedType === "P32" && !!selectedP32TaxYear || selectedType === "P30" && !!selectedP30Period || selectedType === "Payslips" && !!selectedPayslipPayrun;
  const performDownload = useCallback(() => __async(void 0, null, function* () {
    try {
      if (selectedType === "P30" && !!selectedP30Period && selectedPayrollId) {
        const [periodEnding, taxYear] = selectedP30Period.split("|");
        window.location.href = yield PayrollExternalApi.downloadP30Url(selectedPayrollId, taxYear, periodEnding);
      } else if (selectedType === "P32" && !!selectedP32TaxYear && selectedPayrollId) {
        window.location.href = yield PayrollExternalApi.downloadP32Url(selectedPayrollId, selectedP32TaxYear);
      } else if (selectedType === "Payslips" && !!selectedPayslipPayrun && selectedPayrollId) {
        const { taxYear, payPeriod, periodNumber, ordinal } = JSON.parse(selectedPayslipPayrun);
        window.location.href = yield PayrollExternalApi.downloadPayslipsUrl(
          selectedPayrollId,
          taxYear,
          payPeriod,
          periodNumber,
          ordinal
        );
      }
    } catch (error) {
      showMessage(`${polyglot.t("PayrollReportType.errorMessages.download")}. ${nestErrorMessage(error)}`, "error");
    }
  }), [
    polyglot,
    selectedP30Period,
    selectedP32TaxYear,
    selectedPayrollId,
    selectedPayslipPayrun,
    selectedType,
    showMessage
  ]);
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("PayrollReportType.select") }),
        /* @__PURE__ */ jsxs(FormControl, { sx: __spreadValues({ width: "100%" }, spacing.mt20), children: [
          /* @__PURE__ */ jsx(
            SelectComponent,
            {
              name: "payroll-id",
              label: "Payroll",
              options: (_a = payrolls == null ? void 0 : payrolls.map((payroll) => {
                var _a2, _b;
                return {
                  label: (_b = (_a2 = payroll.entity) == null ? void 0 : _a2.legalName) != null ? _b : `PayrollID ${payroll.id}`,
                  value: payroll.id
                };
              })) != null ? _a : [],
              onChange: (e) => setSelectedPayrollId(e.target.value),
              value: selectedPayrollId,
              sx: { my: spacing.my20 }
            }
          ),
          /* @__PURE__ */ jsx(
            RadioGroup,
            {
              value: selectedType,
              onChange: (e) => {
                setSelectedType(e.target.value);
              },
              sx: { flexFlow: "column" },
              children: PayrollReportTypes.map((d, idx, arr) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
                /* @__PURE__ */ jsx(
                  FormControlLabel,
                  {
                    labelPlacement: "start",
                    value: d.name,
                    control: /* @__PURE__ */ jsx(StyledRadio, {}),
                    label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
                      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: d.name }),
                      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.Grey }), children: d.desc })
                    ] }),
                    disabled: !(paySchedules == null ? void 0 : paySchedules.length),
                    sx: {
                      width: "100%",
                      justifyContent: "space-between",
                      display: "flex",
                      margin: 0,
                      paddingY: spacing.p15,
                      borderBottom: idx < arr.length - 1 ? borders.background : "none"
                    }
                  }
                ),
                d.name === "P30" && selectedType === d.name && /* @__PURE__ */ jsx(
                  SelectComponent,
                  {
                    name: "p30period",
                    label: polyglot.t("PayrollReportType.p30period"),
                    options: p30Periods.map((period) => ({
                      label: formatPeriodEndDate(period.slice(0, 10)),
                      value: period
                    })),
                    onChange: (e) => setSelectedP30Period(e.target.value),
                    value: selectedP30Period,
                    sx: { my: spacing.my20 }
                  }
                ),
                d.name === "P32" && selectedType === d.name && /* @__PURE__ */ jsx(
                  SelectComponent,
                  {
                    name: "taxYear",
                    label: polyglot.t("PayrollReportType.taxYear"),
                    options: taxYears.map((taxYear) => ({
                      label: taxYear.slice(-4),
                      value: taxYear
                    })),
                    onChange: (e) => setSelectedP32TaxYear(e.target.value),
                    value: selectedP32TaxYear,
                    sx: { my: spacing.my20 }
                  }
                ),
                d.name === "Payslips" && selectedType === d.name && /* @__PURE__ */ jsx(
                  SelectComponent,
                  {
                    name: "payslipsPeriod",
                    label: polyglot.t("PayrollReportType.payslipsPeriod"),
                    options: payslipPayruns.map((payslipPayrun) => ({
                      label: formatPeriodEndDate(payslipPayrun.endDate),
                      value: JSON.stringify(payslipPayrun)
                    })),
                    onChange: (e) => setSelectedPayslipPayrun(e.target.value),
                    value: selectedPayslipPayrun,
                    sx: { my: spacing.my20 }
                  }
                )
              ] }, d.name))
            }
          )
        ] }),
        showDownloadButton && /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            type: "button",
            name: polyglot.t("General.download"),
            style: { marginTop: spacing.m25 },
            sizeVariant: "large",
            colorVariant: "primary",
            onClick: performDownload,
            fullWidth: true,
            children: polyglot.t("General.download")
          }
        )
      ] })
    }
  );
};
