"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Box, Grid } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { PaymentsEndpoints } from "@v2/feature/payments/payments.api";
import { getPaymentStatus } from "@v2/feature/payments/payments.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { themeFonts } from "@v2/styles/fonts.styles";
import { formatCurrency } from "@v2/util/currency-format.util";
export const PaymentsGroupDrawer = ({ isOpen, setIsOpen, transactionId }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsx(PaymentsGroupDrawerContent, { transactionId })
    }
  ) });
};
const PaymentsGroupDrawerContent = ({ transactionId }) => {
  const { polyglot } = usePolyglot();
  const { data: payments } = useApiClient(PaymentsEndpoints.getPaymentsByTransactionId(transactionId));
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Payments" }),
    payments && /* @__PURE__ */ jsxs(Grid, { container: true, spacing: "8px", children: [
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 3, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Type" }) }),
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 4, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Reference" }) }),
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 3, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Status" }) }),
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 2, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Amount" }) }),
      payments.map((payment) => /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 3, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: payment.type }) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 4, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { textOverflow: "ellipsis" }, children: payment.reference }) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 3, children: getPaymentStatus(polyglot, payment, themeFonts.paragraph) }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 2, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: formatCurrency(payment.amount, {}, payment.currency) }) })
      ] })),
      /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 10 }),
        /* @__PURE__ */ jsx(Grid, { item: true, xs: 2, children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: formatCurrency(
          payments.reduce((total, p) => total + p.amount, 0),
          {},
          payments[0].currency
        ) }) })
      ] })
    ] })
  ] });
};
