"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { isPast } from "date-fns";
import dayjs from "dayjs";
import { CompanyAPI } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as EditIcon } from "@/images/new-theme-icon/Edit.svg";
import { Divider } from "@/v2/components/divider.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
import {
  currencyOptions,
  DiscountTypeValues,
  SeatsMethod,
  seatsMethodOptions,
  subscriptionTypeOptions,
  vatSettingOptions
} from "@/v2/feature/super-admin/features/super-admin-billing-v2/company-subscription.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { iconSize } from "@/v2/styles/table.styles";
import { formatCurrency } from "@/v2/util/currency-format.util";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
import { toTitleCase } from "@/v2/util/string.util";
export const discountExpired = (discountEndDate) => discountEndDate ? isPast(new Date(discountEndDate)) : false;
export const SuperAdminBillingCompanySubscriptionEditForm = ({
  companySubscription,
  availablePlans,
  onSave,
  onEditModule
}) => {
  var _a, _b, _c, _d;
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [updatedSubscription, setUpdatedSubscription] = useState(companySubscription);
  const [showMessage] = useMessage();
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsSavingChanges(true);
      if (onSave) {
        const { companyId, id } = updatedSubscription;
        yield CompanyAPI.updateCompanySubscription(companyId, id, updatedSubscription);
        yield onSave();
      }
      setIsSavingChanges(false);
      showMessage("Customer subscription updated successfully", "success");
    } catch (e) {
      showMessage("Failed to update customer plan", "error");
      console.error(":::: error ::::", e);
      setIsSavingChanges(false);
    }
  }), [onSave, showMessage, updatedSubscription]);
  const handleModuleAddition = useCallback(
    (planId) => {
      const matchingPlan = availablePlans.find((plan) => plan.id === planId);
      if (matchingPlan) {
        const planToAdd = {
          planDefinitionId: planId,
          planName: matchingPlan.name,
          cost: matchingPlan.cost,
          pricePerSeat: matchingPlan.cost
        };
        setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), {
          subscribedPlans: [...prev.subscribedPlans, planToAdd]
        }));
      }
    },
    [availablePlans]
  );
  const newModuleOptions = useMemo(
    () => availablePlans.filter(
      (eachOption) => !updatedSubscription.subscribedPlans.some(
        (m) => m.planDefinitionId === eachOption.id || m.planName === eachOption.name
      )
    ).map((eachPlan) => {
      return {
        handler: () => __async(void 0, null, function* () {
          handleModuleAddition(eachPlan.id);
        }),
        label: eachPlan.name,
        disabled: false
      };
    }),
    [availablePlans, handleModuleAddition, updatedSubscription.subscribedPlans]
  );
  const noMoreModuleOption = [
    {
      handler: () => {
      },
      label: "No more modules available",
      disabled: true
    }
  ];
  const discountEndDateDetail = (p) => p.discountEndDate ? `Valid till ${dateAPItoDisplay(p.discountEndDate)}` : "";
  const numberOfSeatsLabel = updatedSubscription.seatsMethod === SeatsMethod.FIXED ? "Number of seats" : "Minimum seats";
  const discountColumnDetail = useCallback(
    (p) => {
      if (p.discountType && p.discountValue && discountExpired(p.discountEndDate)) {
        return `${p.discountEndDate ? "Discount expired " + dateAPItoDisplay(p.discountEndDate) : ""}`;
      }
      if (p.discountType && p.discountValue && !discountExpired(p.discountEndDate)) {
        if (p.discountType === DiscountTypeValues.FIXED) {
          return `${formatCurrency(p.discountValue, void 0, updatedSubscription.currency)} ${discountEndDateDetail(
            p
          )}`;
        } else if (p.discountType === DiscountTypeValues.PERCENTAGE) {
          return `${p.discountValue}% ${discountEndDateDetail(p)}`;
        }
      }
      return /* @__PURE__ */ jsx(EmptyCell, {});
    },
    [updatedSubscription.currency]
  );
  const effectivePriceForModule = useCallback(
    (original) => {
      if (original.pricePerSeat && original.discountValue && !discountExpired(original.discountEndDate))
        return formatCurrency(
          original.pricePerSeat[updatedSubscription.currency],
          void 0,
          updatedSubscription.currency
        );
      else if ((!original.discountValue || original.discountValue && discountExpired(original.discountEndDate)) && original.cost)
        return formatCurrency(original.cost[updatedSubscription.currency], void 0, updatedSubscription.currency);
      return /* @__PURE__ */ jsx(EmptyCell, {});
    },
    [updatedSubscription.currency]
  );
  const moduleColumns = useMemo(() => {
    return [
      {
        header: () => "Name",
        accessorFn: (row) => row,
        id: "name",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.planName }) })
      },
      {
        header: () => "List price",
        accessorFn: (row) => row,
        id: "cost",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.cost && updatedSubscription.currency ? formatCurrency(original.cost[updatedSubscription.currency], void 0, updatedSubscription.currency) : /* @__PURE__ */ jsx(EmptyCell, {}) }) })
      },
      {
        header: () => "Discount",
        accessorFn: (row) => row,
        id: "discount",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: discountColumnDetail(original) }) })
      },
      {
        header: () => "Effective price",
        accessorFn: (row) => row,
        id: "cost",
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: effectivePriceForModule(original) }) })
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        size: 75,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                alignItems: "center",
                gap: spacing.gap5
              },
              children: /* @__PURE__ */ jsx(
                IconButton,
                {
                  sx: tableIconButtonSx,
                  onClick: () => {
                    onEditModule(original, updatedSubscription);
                  },
                  children: /* @__PURE__ */ jsx(EditIcon, __spreadValues({}, iconSize))
                }
              )
            }
          );
        }
      }
    ];
  }, [discountColumnDetail, effectivePriceForModule, onEditModule, updatedSubscription]);
  const sortedModules = useMemo(() => {
    return [...updatedSubscription.subscribedPlans].sort((a, b) => a.planDefinitionId - b.planDefinitionId);
  }, [updatedSubscription]);
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Plan" }),
    /* @__PURE__ */ jsx(DrawerViewerItem, { title: "Company", value: (_b = (_a = companySubscription == null ? void 0 : companySubscription.company) == null ? void 0 : _a.name) != null ? _b : "" }, "companyName"),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Xero customer name",
        name: "xeroCustomerName",
        value: updatedSubscription.xeroCustomerName,
        onChange: (e) => {
          const updatedName = e.target.value;
          setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { xeroCustomerName: updatedName }));
        },
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "currency",
        label: "Billing currency",
        options: currencyOptions.map((currency) => ({
          label: currency,
          value: currency
        })),
        value: updatedSubscription.currency,
        compareValue: updatedSubscription.currency,
        onChange: (e) => {
          const newCurrency = e.target.value;
          setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { currency: newCurrency }));
        }
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "vatSetting",
        label: "VAT setting",
        options: vatSettingOptions.map((vatSetting) => ({
          label: vatSetting,
          value: vatSetting
        })),
        value: updatedSubscription.vatSetting,
        compareValue: updatedSubscription.vatSetting,
        onChange: (e) => {
          const newSetting = e.target.value;
          setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { vatSetting: newSetting }));
        }
      }
    ),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: subscriptionTypeOptions.map((typeOption) => ({
          label: toTitleCase(typeOption),
          value: typeOption
        })),
        value: updatedSubscription.type,
        compareValue: updatedSubscription.type,
        onChange: (e) => {
          const newType = e.target.value;
          setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { type: newType }));
        }
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Box, { width: "85%", children: /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          inputFormat: "DD/MM/YYYY",
          value: (_c = updatedSubscription.endDate) != null ? _c : null,
          onChange: (value) => {
            if (dayjs(value).isValid()) {
              setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { endDate: new Date(value) }));
            }
          },
          name: "endDate",
          label: "End date (Optional)"
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", flexWrap: "wrap" }, children: updatedSubscription.endDate && /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "small",
          colorVariant: "secondary",
          onClick: () => {
            setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { endDate: null }));
          },
          children: "Clear"
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "seatsMethod",
        label: "Seats method",
        options: seatsMethodOptions.map((methodOption) => ({
          label: toTitleCase(methodOption),
          value: methodOption
        })),
        value: updatedSubscription.seatsMethod,
        compareValue: updatedSubscription.seatsMethod,
        onChange: (e) => {
          const newMethod = e.target.value;
          setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { seatsMethod: newMethod }));
        }
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: numberOfSeatsLabel,
        name: "numberOfSeats",
        value: updatedSubscription.numberOfSeats,
        onChange: (e) => {
          const newSeats = +e.target.value;
          setUpdatedSubscription((prev) => __spreadProps(__spreadValues({}, prev), { numberOfSeats: newSeats }));
        },
        fullWidth: true,
        size: "small",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Modules" }),
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: (newModuleOptions == null ? void 0 : newModuleOptions.length) > 0 ? newModuleOptions : noMoreModuleOption,
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Add new",
              fullWidth: true
            },
            anchorOrigin: { horizontal: "left", vertical: "bottom" },
            transformOrigin: { horizontal: "left", vertical: "top" }
          }
        )
      ] }),
      ((_d = updatedSubscription.subscribedPlans) == null ? void 0 : _d.length) > 0 ? /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: sortedModules,
          columnData: moduleColumns,
          loading: false,
          hidePagination: true
        }
      ) : /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "grey", children: "No modules added yet" })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        sizeVariant: "medium",
        loading: isSavingChanges,
        colorVariant: "primary",
        fullWidth: true,
        onClick: handleSave
      }
    ) })
  ] });
};
