"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box, FormControl, IconButton } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { EquityAPI } from "@/api-client/equity.api";
import { UserEquitySchema } from "@/component/dashboard/userDetails/validations/userFormValidations";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { equityTypeOptions } from "@/lib/employment";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { ProfileField } from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { popularCurrencyShortOptions } from "@/v2/infrastructure/currency/currency.interface";
import { iconCTAButtonSx } from "@/v2/styles/icon-button.styles";
export const EquityForm = ({
  initialValues,
  userId,
  refreshUserEquityData,
  rowModalMode,
  handleSubmit,
  setFormCurrentlyEditing,
  setIsModalOpen,
  setLoading,
  loading,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  }
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [rowForDeletion, setRowForDeletion] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [state] = useContext(GlobalContext);
  const [showMessage] = useMessage();
  const defaultBlankModalValues = {
    id: 0,
    grantDate: void 0,
    type: "",
    amount: 0,
    vestingStart: void 0,
    vestingPeriod: 0,
    vestingCliff: 0,
    unitPrice: 0,
    currency: "",
    reason: ""
  };
  const formik = useFormik({
    initialValues: initialValues != null ? initialValues : defaultBlankModalValues,
    enableReinitialize: true,
    validationSchema: UserEquitySchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (!usedForDataImport) {
          const modalUserEquityBody = __spreadProps(__spreadValues({}, values), {
            reason: values.reason,
            type: values.type,
            amount: Number(values == null ? void 0 : values.amount),
            unitPrice: Number(values == null ? void 0 : values.unitPrice),
            vestingStart: values.vestingStart ? values.vestingStart : void 0,
            vestingPeriod: values.vestingPeriod ? +values.vestingPeriod : 0,
            vestingCliff: values.vestingCliff ? +values.vestingCliff : 0,
            currency: (values == null ? void 0 : values.currency) && (values == null ? void 0 : values.currency.length) > 0 ? values.currency : "...",
            userId,
            createdBy: state.user.userId,
            updatedBy: state.user.userId,
            companyId: state.user.company.companyId
          });
          if (rowModalMode === "add") {
            yield EquityAPI.create(modalUserEquityBody);
            showMessage(polyglot.t("EquityForm.successMessages.add"), "success");
          } else if (rowModalMode === "edit" && !!values.id) {
            yield EquityAPI.updateById(__spreadProps(__spreadValues({}, modalUserEquityBody), { id: values.id }));
            showMessage(polyglot.t("EquityForm.successMessages.update"), "success");
          } else {
            showMessage(polyglot.t("EquityForm.errorMessages.add"), "error");
          }
          yield refreshUserEquityData();
          formik.resetForm();
          setIsModalOpen(false);
        } else {
          importHandler == null ? void 0 : importHandler(values);
        }
      } catch (error) {
        showMessage(
          `${polyglot.t("EquityForm.errorMessages.update")}: ${nestErrorMessage(error)}; Please try again.`,
          "error"
        );
      } finally {
        setLoading(false);
        setFormCurrentlyEditing(false);
        handleSubmit(userId);
      }
    })
  });
  const deleteEquityRow = (rowId) => {
    setIsRemovalModalOpen(true);
    setRowForDeletion(rowId);
  };
  const handleDeleteRow = () => __async(void 0, null, function* () {
    try {
      if (rowForDeletion) yield EquityAPI.deleteById(userId, rowForDeletion);
      showMessage(polyglot.t("EquityForm.successMessages.delete"), "success");
    } catch (error) {
      showMessage(polyglot.t("General.delete"), "error");
    } finally {
      yield refreshUserEquityData();
      handleSubmit(userId);
      setFormCurrentlyEditing(false);
      setRowForDeletion(0);
      formik.resetForm();
      setIsModalOpen(false);
      setIsRemovalModalOpen(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    rowModalMode === "add" ? /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("EquityForm.new") }) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("EquityForm.edit") }),
      ((_a = formik.values) == null ? void 0 : _a.id) && /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: iconCTAButtonSx,
          onClick: (event) => {
            setAnchorEl(event.currentTarget);
            if (!formik.values.id) {
              showMessage("Missing row.", "error");
            } else {
              deleteEquityRow(formik.values.id);
              setIsRemovalModalOpen(true);
            }
          },
          children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
        }
      )
    ] }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.type", children: /* @__PURE__ */ jsx(FormControl, { fullWidth: true, size: "small", children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: polyglot.t("EquityForm.type"),
        options: equityTypeOptions(polyglot),
        value: formik.values.type,
        compareValue: formik.values.type,
        error: !!formik.errors.type && formik.touched.type,
        onChange: formik.handleChange,
        helperText: formik.errors.type && formik.touched.type
      }
    ) }) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.grantDate", children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_b = formik.values.grantDate) != null ? _b : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("grantDate", value);
          }
        },
        name: "grantDate",
        label: polyglot.t("EquityForm.grantDate"),
        error: !!formik.errors.grantDate && formik.touched.grantDate,
        helperText: formik.errors.grantDate && formik.touched.grantDate
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.amount", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "amount",
        label: polyglot.t("EquityForm.amount"),
        value: formik.values.amount,
        type: "number",
        onChange: formik.handleChange,
        error: formik.touched.amount && !!formik.errors.amount,
        helperText: (_c = formik.touched.amount && formik.errors.amount) != null ? _c : " ",
        clearText: () => formik.setFieldValue("amount", "")
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.vestingStart", children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_d = formik.values.vestingStart) != null ? _d : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("vestingStart", value);
          }
        },
        name: "vestingStart",
        label: polyglot.t("EquityForm.vestingStart"),
        error: !!formik.errors.vestingStart && formik.touched.vestingStart,
        helperText: formik.errors.vestingStart && formik.touched.vestingStart
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.vestingPeriod", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "vestingPeriod",
        label: polyglot.t("EquityForm.vestingPeriod"),
        value: formik.values.vestingPeriod,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.vestingPeriod && !!formik.errors.vestingPeriod,
        helperText: (_e = formik.touched.vestingPeriod && formik.errors.vestingPeriod) != null ? _e : " ",
        clearText: () => formik.setFieldValue("vestingPeriod", "")
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.vestingCliff", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "vestingCliff",
        label: polyglot.t("EquityForm.vestingCliff"),
        value: formik.values.vestingCliff,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.vestingCliff && !!formik.errors.vestingCliff,
        helperText: (_f = formik.touched.vestingCliff && formik.errors.vestingCliff) != null ? _f : " ",
        clearText: () => formik.setFieldValue("vestingCliff", "")
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.unitPrice", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "unitPrice",
        label: polyglot.t("EquityForm.exercisePrice"),
        value: formik.values.unitPrice,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.unitPrice && !!formik.errors.unitPrice,
        helperText: (_g = formik.touched.unitPrice && formik.errors.unitPrice) != null ? _g : " ",
        clearText: () => formik.setFieldValue("unitPrice", "")
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.currency", children: /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "currency",
        label: polyglot.t("EquityForm.currency"),
        options: popularCurrencyShortOptions,
        value: formik.values.currency,
        compareValue: formik.values.currency,
        error: !!formik.errors.currency && formik.touched.currency,
        onChange: formik.handleChange,
        helperText: formik.errors.currency && formik.touched.currency
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "equity.reason", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "reason",
        label: polyglot.t("EquityForm.reason"),
        value: formik.values.reason,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.reason && !!formik.errors.reason,
        helperText: (_h = formik.touched.reason && formik.errors.reason) != null ? _h : " ",
        clearText: () => formik.setFieldValue("reason", "")
      }
    ) }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("EquityForm.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("EquityForm.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: isRemovalModalOpen,
        onClose: () => {
          setIsRemovalModalOpen(false);
          setRowForDeletion(0);
        },
        anchorEl,
        takeAction: handleDeleteRow,
        message: polyglot.t("EquityForm.confirmDelete"),
        callToAction: polyglot.t("EquityForm.callToAction")
      }
    )
  ] }) });
};
