"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { differenceInYears, isPast, isToday } from "date-fns";
export const isDataEmpty = (data, customUpdates) => {
  const emptyArray = Object.keys(data).filter((key) => {
    if (key === "customUpdates") {
      return customUpdates.length === 0 || customUpdates.some((update) => update.value.length === 0);
    } else return data[key] === void 0 || data[key] === null || data[key] === "";
  });
  return emptyArray.length === Object.keys(data).length;
};
export const buildColumnsForCustomFields = (data, columnDefaults) => {
  if (!data) return [];
  const fieldNames = new Set(data.flatMap((entry) => entry.customUpdates).map((u) => u.field.fieldName));
  return [...fieldNames].sort().map((fieldName) => __spreadProps(__spreadValues({}, columnDefaults), {
    header: () => fieldName,
    accessorFn: (row) => row,
    id: fieldName,
    enableSorting: false,
    cell: ({
      row: {
        original: { customUpdates }
      }
    }) => {
      var _a, _b;
      return (_b = (_a = customUpdates.find((u) => u.field.fieldName === fieldName)) == null ? void 0 : _a.value) != null ? _b : null;
    }
  }));
};
export function calculateAge(birthdate) {
  const today = /* @__PURE__ */ new Date();
  const age = differenceInYears(today, birthdate);
  return age;
}
export function isDateEffectivelyPast(date) {
  if (isToday(date)) {
    return false;
  }
  return isPast(date);
}
export const getTranslatedFieldValue = (value, polyglot) => {
  try {
    const parsedValue = JSON.parse(value);
    return Array.isArray(parsedValue) ? parsedValue.map((item) => polyglot.t(item)).join(", ") : polyglot.t(value != null ? value : "");
  } catch (e) {
    return polyglot.t(value != null ? value : "");
  }
};
