"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { SCRollingTimelineModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/components/rolling/sc-rolling-timeline-modal.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const SCRollingTimelineView = ({ surveyCycle, isEditable, refreshCycle }) => {
  var _a, _b;
  const [isOpen, setIsOpen] = useState(false);
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      SettingsSubsectionContent,
      {
        sections: [
          {
            title: "Timeline",
            onEdit: () => setIsOpen(true),
            hideEdit: !isEditable,
            items: [
              {
                type: SectionItemType.TextLine,
                value: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { pb: spacing.s2 }, children: "Timeline defines the deadline for each newly invited participant. After the deadline, their individual invite will expire." })
              },
              {
                type: SectionItemType.Pair,
                label: polyglot.t("CycleSettingTimelineSection.surveyOpenFor"),
                value: `${(_b = (_a = surveyCycle == null ? void 0 : surveyCycle.timelineSettings) == null ? void 0 : _a.surveyOpenFor) != null ? _b : "7"} days`
              }
            ]
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      SCRollingTimelineModal,
      {
        surveyCycle,
        isOpen,
        setIsOpen,
        onClose: () => setIsOpen(false),
        refresh: () => __async(void 0, null, function* () {
          yield refreshCycle == null ? void 0 : refreshCycle();
        })
      }
    )
  ] });
};
