"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ReactComponent as Clock } from "@/images/reviews/Clock.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getReviewerTypesChips } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ResultsOverviewReviewScoreTable = ({
  results,
  resultsLoading,
  reviewScore,
  showBorder
}) => {
  const [showAll, setShowAll] = useState(false);
  const rowData = useMemo(() => {
    const initialData = results ? [{ reviewerId: results.revieweeId, average: results.reviewAvgScore, type: null, entryState: null }] : [];
    return [...initialData, ...reviewScore || []];
  }, [results, reviewScore]);
  const displayedRowData = useMemo(() => {
    return showAll ? rowData : rowData.slice(0, 4);
  }, [rowData, showAll]);
  const tableColumns = useMemo(
    () => [
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "reviewerId",
        enableSorting: false,
        cell: ({
          row: {
            original: { reviewerId }
          }
        }) => {
          var _a;
          return ((_a = results == null ? void 0 : results.visibilitySettings) == null ? void 0 : _a.hidePeerAuthor) && !reviewerId ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Author hidden" }) : /* @__PURE__ */ jsx(UserCell, { userId: reviewerId });
        }
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: ({
          row: {
            original: { type }
          }
        }) => type ? /* @__PURE__ */ jsx("div", { children: getReviewerTypesChips(false)[type] }) : /* @__PURE__ */ jsx(Fragment, {})
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "type",
        enableSorting: false,
        cell: ({
          row: {
            original: { type, average }
          }
        }) => type && !average ? /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
          ChipComponent,
          {
            name: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
              /* @__PURE__ */ jsx(Clock, __spreadValues({}, iconSize)),
              /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: "Pending" })
            ] }),
            backgroundColor: "white",
            border: "middleGrey2"
          }
        ) }) : /* @__PURE__ */ jsx(Fragment, {})
      },
      {
        header: () => "",
        accessorFn: (row) => row,
        id: "average",
        enableSorting: false,
        cell: ({
          row: {
            original: { average, type }
          }
        }) => average ? /* @__PURE__ */ jsxs(Typography, { variant: type ? "paragraph" : "headline2", children: [
          average,
          "%"
        ] }) : /* @__PURE__ */ jsx(EmptyCell, {})
      }
    ],
    [results]
  );
  const customRowStyle = (row) => {
    return {
      backgroundColor: !row.original.type ? themeColors.TableHover : "inherit",
      borderTop: showBorder && row.index !== 0 ? `1px solid ${themeColors.GreyMiddle}` : "none"
    };
  };
  return /* @__PURE__ */ jsxs("div", { className: "hide-for-capture", children: [
    /* @__PURE__ */ jsx(
      BasicTable,
      {
        rowData: displayedRowData != null ? displayedRowData : [],
        columnData: tableColumns,
        loading: resultsLoading,
        paginationSx: { px: spacing.px16, boxSizing: "border-box" },
        customRowStyle,
        hidePagination: true,
        hideHeader: true,
        containerStyle: { borderRadius: radius.br15 },
        style: { borderRadius: radius.br15, border: showBorder ? `1px solid ${themeColors.GreyMiddle}` : "none" }
      }
    ),
    rowData.length > 4 && /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          alignItems: "center",
          gap: spacing.sm,
          textAlign: "center",
          marginTop: spacing.s1,
          px: spacing.s1
        },
        children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "text",
            sizeVariant: "link",
            onClick: () => setShowAll((prev) => !prev),
            style: themeFonts.headline3,
            children: showAll ? "Show less" : `+${rowData.length - 4} more`
          }
        )
      }
    )
  ] });
};
