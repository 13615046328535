"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { styled, Tab, Tabs } from "@mui/material";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const StyledTabs = styled(Tabs)(() => __spreadProps(__spreadValues({
  height: 40,
  minHeight: 40,
  margin: "0px 0px !important"
}, spacing.mx40), {
  backgroundColor: themeColors.white,
  "& .MuiTabs-flexContainer": {
    height: 40
  },
  "& .MuiTabs-indicator": {
    display: "inherit",
    backgroundColor: themeColors.DarkGrey,
    color: themeColors.DarkGrey,
    width: "inherit",
    height: "3px"
  },
  "& .MuiTabs-scroller": { overflow: "visible !important", height: 40 },
  borderBottom: borders.middle
}));
export const StyledTab = styled(Tab)(() => __spreadProps(__spreadValues({
  textTransform: "inherit"
}, themeFonts.paragraph), {
  color: themeColors.DarkGrey,
  height: 40,
  padding: "8px 1px",
  marginRight: "18px",
  minWidth: "fit-content",
  minHeight: 40,
  borderRadius: 6,
  display: "flex",
  "& > div": {
    marginBottom: "20px"
  },
  "&.Mui-selected": __spreadProps(__spreadValues({}, themeFonts.headline3), {
    color: themeColors.DarkGrey,
    "&:hover": __spreadProps(__spreadValues({}, themeFonts.headline3), {
      color: themeColors.DarkGrey
    })
  }),
  "&:hover": __spreadProps(__spreadValues({}, themeFonts.paragraph), {
    color: themeColors.Grey
  })
}));
export const StyledTabsComponent = ({
  tabs,
  currentTab,
  setCurrentTab
}) => {
  return /* @__PURE__ */ jsx(
    StyledTabs,
    {
      value: currentTab,
      scrollButtons: "auto",
      variant: "scrollable",
      allowScrollButtonsMobile: true,
      onChange: (_, value) => {
        setCurrentTab(value);
      },
      children: tabs.map((tab) => /* @__PURE__ */ jsx(StyledTab, { disableRipple: true, label: tab.label, value: tab.value, onClick: tab.onClick }, tab.value))
    }
  );
};
