"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextFieldAndUnit } from "@v2/components/forms/textfield-and-unit.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { ToilUnit } from "@v2/feature/absence/absence.dto";
import { isUnlimitedPolicy } from "@v2/feature/absence/absence.util";
import { AttendanceEndpoints } from "@v2/feature/attendance/attendance.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyTOILEditDrawer = ({ isOpen, setIsOpen, absencePolicy, refresh }) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isUnlimited = isUnlimitedPolicy(absencePolicy);
  const { data: attendanceTypes } = useApiClient(isUnlimited ? null : AttendanceEndpoints.getCompanyAttendanceTypes(), {
    suspense: false
  });
  const TOILOptions = useMemo(
    () => [
      { label: polyglot.t("General.none"), value: "" },
      ...(attendanceTypes != null ? attendanceTypes : []).map((type) => ({
        label: type.name,
        value: type.id
      }))
    ],
    [attendanceTypes, polyglot]
  );
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      if (isUnlimited) return;
      const policyData = {
        toilTypeId: values.toilTypeId ? Number(values.toilTypeId) : null,
        toilUnit: values.toilTypeId ? values.toilUnit : null,
        toilUnitsNo: values.toilTypeId && values.toilUnitsNo ? Number(values.toilUnitsNo) : null
      };
      if (!policyData.toilUnitsNo || policyData.toilUnitsNo <= 0) policyData.toilUnitsNo = null;
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyTOIL(absencePolicy.id, policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    }),
    [polyglot, absencePolicy.id, refresh, setIsOpen, showMessage, isUnlimited]
  );
  const formik = useFormik({
    initialValues: {
      toilTypeId: (_a = absencePolicy.toilTypeId) != null ? _a : null,
      toilUnit: (_b = absencePolicy.toilUnit) != null ? _b : ToilUnit.Month,
      toilUnitsNo: (_c = absencePolicy.toilUnitsNo) != null ? _c : 12
    },
    validationSchema: yup.object({
      toilTypeId: yup.number().nullable().integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).notRequired(),
      toilUnit: yup.string().nullable().oneOf([...Object.values(ToilUnit), null], polyglot.t("ValidationMessages.validValue")).when("toilTypeId", {
        is: (val) => Boolean(val),
        then: (schema) => schema.required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.notRequired()
      }),
      toilUnitsNo: yup.number().integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue")).nullable().when("toilTypeId", {
        is: (val) => Boolean(val),
        then: (schema) => schema.min(1, polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
        otherwise: (schema) => schema.notRequired()
      })
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceModule.toil") }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AbsenceModule.toilDesc") }),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "toilTypeId",
            label: polyglot.t("AbsenceModule.toilJobType"),
            options: TOILOptions,
            value: (_d = formik.values.toilTypeId) != null ? _d : "",
            onChange: (e) => {
              formik.handleChange(e);
              formik.setFieldValue("toilUnit", ToilUnit.Month);
              formik.setFieldValue("toilUnitsNo", 12);
            },
            compareValue: formik.values.toilTypeId,
            error: !!formik.errors.toilTypeId && formik.touched.toilTypeId,
            helperText: formik.touched.toilTypeId && formik.errors.toilTypeId
          }
        ),
        formik.values.toilTypeId && /* @__PURE__ */ jsx(
          TextFieldAndUnit,
          {
            label: polyglot.t("AbsencePolicyAllowanceSection.expiry"),
            valueName: "toilUnitsNo",
            value: formik.values.toilUnitsNo,
            onValueChange: (value) => {
              formik.setFieldValue("toilUnitsNo", Number(value));
            },
            unitName: "toilUnit",
            unit: formik.values.toilUnit,
            onUnitChange: (value) => {
              formik.setFieldValue("toilUnit", value);
            },
            unitOptions: [
              { label: polyglot.t("getUnitTypeOptions.days"), value: ToilUnit.Day },
              { label: polyglot.t("getUnitTypeOptions.months"), value: ToilUnit.Month }
            ],
            error: !!formik.errors.toilUnit && formik.touched.toilUnit || !!formik.errors.toilUnitsNo && formik.touched.toilUnitsNo,
            helperText: formik.touched.toilUnit && formik.errors.toilUnit || formik.touched.toilUnitsNo && formik.errors.toilUnitsNo
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true
          }
        ) })
      ] }) })
    }
  );
};
