"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, FormControl, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import { ReportEntity, ReportTypeTimeSelection } from "@v2/feature/reports/reports.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { capitalize } from "lodash";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { borders } from "@/v2/styles/borders.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const TimeReportType = ({
  onNext,
  setFormData,
  formData
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(
    Box,
    {
      sx: { display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" },
      children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px", width: "100%" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: polyglot.t("TimeReportType.select") }),
        /* @__PURE__ */ jsx(FormControl, { sx: __spreadValues({ width: "100%" }, spacing.mt20), children: /* @__PURE__ */ jsx(
          RadioGroup,
          {
            value: formData.type,
            onChange: (e) => {
              setFormData((prev) => __spreadProps(__spreadValues({}, prev), {
                type: e.target.value,
                entities: [ReportEntity.Requests]
              }));
            },
            sx: { flexFlow: "column" },
            children: [
              { value: ReportTypeTimeSelection.Requests, label: polyglot.t("TimeReportType.requests") },
              { value: ReportTypeTimeSelection.Balances, label: polyglot.t("TimeReportType.balances") }
            ].map((item, idx, arr) => /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "start",
                value: item["value"],
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g5 }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: capitalize(item["label"]) }) }),
                sx: {
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                  margin: 0,
                  paddingY: spacing.p15,
                  borderBottom: idx < arr.length - 1 ? borders.background : "none"
                }
              }
            ))
          }
        ) }),
        /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            onClick: onNext,
            style: { marginTop: spacing.m25 },
            fullWidth: true,
            children: polyglot.t("General.continue")
          }
        )
      ] })
    }
  );
};
