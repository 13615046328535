"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ReactComponent as Insurance } from "@/images/insurance/Insurance.svg";
import { ReactComponent as Planet } from "@/images/insurance/Planet.svg";
import { ReactComponent as WalletBig } from "@/images/pension/WalletBig.svg";
import { SETTINGS_BENEFITS_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  IntroCardGroup
} from "@/v2/components/theme-components/icon-content-action-card.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const emptyStates = [
  {
    title: "Integrated with payroll",
    content: "Report user-benefits to HMRC when you run payroll on Zelt. Add and change benefit contributions, add dependents and offboard leavers seamlessly. ",
    iconMedia: /* @__PURE__ */ jsx(WalletBig, {})
  },
  {
    title: "Connect existing insurance",
    content: "If you already have a plan - migrate it over to Zelt and let your team see all user-benefits they have in one place. ",
    iconMedia: /* @__PURE__ */ jsx(Insurance, {})
  },
  {
    title: "Local and international",
    content: "Powered by Hooray Insurance, get access to the best rates from insurers even for your global team. ",
    iconMedia: /* @__PURE__ */ jsx(Planet, {})
  }
];
export const InsuranceEmptyState = () => {
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: spacing.g60
      },
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "600px" }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: "Health insurance" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10 }), children: "Offer your employees a well-rounded medical benefit. Fully integrated in Payroll. See and manage employee membership in Zelt. Report the benefits to HMRC via P11D or set up Payrolled benefits" }),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-start",
                gap: spacing.g10,
                width: "70%",
                marginTop: spacing.m30
              },
              children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "medium",
                  colorVariant: "primary",
                  onClick: () => routerHistory.push(SETTINGS_BENEFITS_ROUTE),
                  children: "Set up insurance"
                }
              )
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(IntroCardGroup, { cards: emptyStates }) })
      ]
    }
  );
};
