"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { UserFamilyMemberType } from "@v2/feature/user/features/user-forms/user-family/user-family.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { AddUserFamilyMemberDrawer } from "@v2/feature/user/features/user-profile/details/components/user-family/add-user-family-member-drawer.component";
import { UserAPI } from "@v2/feature/user/user.api";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { capitalize } from "lodash";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ArrowDown } from "@/images/components/ArrowDown.svg";
import { ReactComponent as Edit } from "@/images/side-bar-icons/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { translateUserFamilyMemberType } from "@/v2/infrastructure/i18n/translate.util";
export const UserFamilyMembersSection = ({
  userId,
  userFamilyMembers,
  refreshMembers
}) => {
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mode, setMode] = useState(null);
  const [newMemberType, setNewMemberType] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const onDelete = useCallback(
    (memberId) => __async(void 0, null, function* () {
      try {
        yield UserAPI.deleteUserFamilyMemberById(userId, memberId);
        yield refreshMembers();
      } catch (error) {
        showMessage(
          `${polyglot.t("AddUserFamilyMemberDrawer.errorMessages.badRequest")}. ${nestErrorMessage(error)}`,
          "error"
        );
      }
    }),
    [showMessage, userId, refreshMembers, polyglot]
  );
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    userFamilyMembers.map((member) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: member.type }),
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g5, alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: (e) => {
                e.stopPropagation();
                setNewMemberType(member.type);
                setSelectedMember(member);
                setIsDrawerOpen(true);
              },
              children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              sx: tableIconButtonSx,
              onClick: (e) => {
                setAnchorEl(e.currentTarget);
                setMode("delete");
                e.stopPropagation();
              },
              children: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize))
            }
          )
        ] })
      ] }),
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("AddUserFamilyMemberDrawer.fullName", {
          type: translateUserFamilyMemberType(member.type, polyglot)
        }) }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: member.name })
      ] }),
      member.dob && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("AddUserFamilyMemberDrawer.dob", {
          type: translateUserFamilyMemberType(member.type, polyglot)
        }) }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: member.dob })
      ] }),
      member.gender && /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("AddUserFamilyMemberDrawer.memberTypeGender", {
          type: translateUserFamilyMemberType(member.type, polyglot)
        }) }),
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: capitalize(member.gender) })
      ] }),
      mode && /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: Boolean(anchorEl),
          onClose: () => {
            setTimeout(() => {
              setAnchorEl(null);
              setMode(null);
            }, 400);
          },
          anchorEl,
          takeAction: () => __async(void 0, null, function* () {
            if (mode === "delete") yield onDelete(member.id);
            setMode(null);
          }),
          message: polyglot.t("AddUserFamilyMemberDrawer.confirmDelete"),
          callToAction: polyglot.t("General.yes")
        }
      )
    ] }, member.id)),
    /* @__PURE__ */ jsx(
      StyledMenuComponent,
      {
        options: [
          {
            handler: () => {
              setNewMemberType(UserFamilyMemberType.Spouse);
              setSelectedMember(null);
              setIsDrawerOpen(true);
            },
            label: polyglot.t("UserFamilyMemberType.Spouse")
          },
          {
            handler: () => {
              setNewMemberType(UserFamilyMemberType.Partner);
              setSelectedMember(null);
              setIsDrawerOpen(true);
            },
            label: polyglot.t("UserFamilyMemberType.Partner")
          },
          {
            handler: () => {
              setNewMemberType(UserFamilyMemberType.Child);
              setSelectedMember(null);
              setIsDrawerOpen(true);
            },
            label: polyglot.t("UserFamilyMemberType.Child")
          }
        ],
        actionButtonDetails: {
          type: "button",
          colorVariant: "secondary",
          sizeVariant: "small",
          title: polyglot.t("AddUserFamilyMemberDrawer.add"),
          icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
          iconPosition: "end"
        }
      }
    ),
    newMemberType && /* @__PURE__ */ jsx(
      AddUserFamilyMemberDrawer,
      {
        isOpen: isDrawerOpen,
        setIsOpen: setIsDrawerOpen,
        member: selectedMember,
        onClose: () => {
          setNewMemberType(null);
          setSelectedMember(null);
          setIsDrawerOpen(false);
        },
        type: newMemberType,
        userId,
        refresh: refreshMembers
      }
    )
  ] });
};
