"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import axios from "axios";
export class ReportsAPI {
  static generatePeopleCreatedChangeReport(reportData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/people/change-reports", reportData)).data;
    });
  }
  static generatePeopleCreatedCurrentReport(reportData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/people/current-records", reportData)).data;
    });
  }
  static generateTimeCreatedReport(reportData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/time", reportData)).data;
    });
  }
  static generateAttendanceCreatedReport(reportData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/attendance", reportData)).data;
    });
  }
  static generateDevicesCreatedReports(reportData) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/devices", reportData)).data;
    });
  }
  static exportReportById(id) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/reports/filtered?id=${id}`)).data;
    });
  }
  static exportCurrentReportById(id) {
    return __async(this, null, function* () {
      return (yield axios.get(`/apiv2/reports/current-filtered?id=${id}`)).data;
    });
  }
  static saveReport(create) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/save", create)).data;
    });
  }
  static saveAdvancedReport(create) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/save-advanced", create)).data;
    });
  }
  static patchReport(id, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/reports/${id}/update`, update);
    });
  }
  static updateAdvancedReport(id, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/reports/${id}/update-advanced`, update);
    });
  }
  static patchReportDates(id, update) {
    return __async(this, null, function* () {
      yield axios.patch(`/apiv2/reports/${id}/update-dates`, update);
    });
  }
  static deleteReport(id) {
    return __async(this, null, function* () {
      return (yield axios.delete(`/apiv2/reports/${id}`)).data;
    });
  }
  static generateTestReport(selectedColumns, selectedFilters) {
    return __async(this, null, function* () {
      return (yield axios.post("/apiv2/reports/test", { selectedColumns, selectedFilters })).data;
    });
  }
  static generateReport(selectedColumns, selectedFilters, effectiveDate, showEffectiveRecords, target) {
    return __async(this, null, function* () {
      return (yield axios.post(`/apiv2/reports/generate/advanced?target=${target}`, {
        selectedColumns,
        selectedFilters,
        effectiveDate,
        effectiveDateEnabled: showEffectiveRecords
      })).data;
    });
  }
}
export class ReportsEndpoints {
  static getDevicesReportsFiltersOptions() {
    return {
      url: "/apiv2/reports/devices/filters-options"
    };
  }
  static getPeopleReportsFiltersOptions() {
    return {
      url: "/apiv2/reports/people/filters-options"
    };
  }
  static getPeopleReportsAdditionalColumnsOptions() {
    return {
      url: "/apiv2/reports/people/columns-additional-options"
    };
  }
  static getTestReportsFiltersOptions() {
    return { url: "/apiv2/reports/setup" };
  }
  static getGrowthReportsFiltersOptions() {
    return { url: "/apiv2/reports/growth/filters" };
  }
  static getAllReports() {
    return { url: "/apiv2/reports/all" };
  }
  static getAllNewReports(target) {
    return { url: `/apiv2/reports/all/new?target=${target}` };
  }
  static getReportById(id) {
    return { url: `/apiv2/reports/${id}` };
  }
}
