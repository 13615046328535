"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { nestErrorMessage } from "@/lib/errors";
import { USER_ONBOARDING_SUMMARY_ROUTE } from "@/lib/routes";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { InlineLink } from "@/v2/components/inline-link.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AuthLayout } from "@/v2/feature/auth/components/auth-layout.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const MIN_PWD_LENGTH = 12;
const validationSchema = yup.object({
  password: yup.string().min(MIN_PWD_LENGTH, `Password must be at least ${MIN_PWD_LENGTH} characters`).required("Password is required"),
  confirm: yup.string().test("mismatch-passwords", "Confirmed password does not match", function(confirm) {
    const { password } = this.parent;
    return !password || !confirm || password === confirm;
  }).required("Password confirmation is required")
});
export const UserOnboardingCreatePassword = () => {
  const routerHistory = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  const userId = searchParams.get("id") || "-1";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const createPasswordUrl = useCallback(() => {
    const searchParams2 = new URLSearchParams(window.location.search);
    const apiParams = new URLSearchParams();
    apiParams.set("id", searchParams2.get("id") || "");
    apiParams.set("token", searchParams2.get("token") || "");
    apiParams.set("expires", searchParams2.get("expires") || "");
    apiParams.set("signature", searchParams2.get("signature") || "");
    apiParams.set("email", searchParams2.get("email") || "");
    return `/apiv2/auth/register?${apiParams.toString()}`;
  }, []);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm: ""
    },
    validationSchema,
    onSubmit: (formData) => __async(void 0, null, function* () {
      var _a, _b;
      setIsSubmitting(true);
      setErrorMessage("");
      try {
        yield axios.post(createPasswordUrl(), {
          password: formData.password
        });
        routerHistory.push(generatePath(USER_ONBOARDING_SUMMARY_ROUTE, { userId }));
      } catch (err) {
        if (err.response.status === 422 && ((_b = (_a = err.response) == null ? void 0 : _a.data) == null ? void 0 : _b.error) === "LEAKED_PASSWORD") {
          setErrorMessage("Password does not meet the security requirements, please try a different password");
        } else {
          setErrorMessage(`${nestErrorMessage(err)}`);
        }
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsxs(
    AuthLayout,
    {
      title: "Please set your password",
      description: email ? `Your work email is ${email}` : "",
      showLoginButton: true,
      submit: formik.handleSubmit,
      children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Email",
            name: "email",
            value: email,
            type: "text",
            autoComplete: "email",
            sx: { display: "none" }
          }
        ),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Password",
            name: "password",
            value: formik.values.password,
            onChange: formik.handleChange,
            error: hasSubmitted && !!formik.errors.password,
            helperText: hasSubmitted && formik.errors.password || `Must be at least ${MIN_PWD_LENGTH} characters`,
            type: "password",
            autoComplete: "new-password",
            autoFocus: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: "Confirm password",
            name: "confirm",
            value: formik.values.confirm,
            onChange: formik.handleChange,
            error: !!errorMessage || hasSubmitted && !!formik.errors.confirm,
            helperText: errorMessage || hasSubmitted && formik.errors.confirm,
            type: "password",
            autoComplete: "new-password",
            sx: { mt: spacing.mt30 }
          }
        ),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            type: "submit",
            name: "Continue",
            loading: isSubmitting,
            sizeVariant: "medium",
            colorVariant: "primary",
            fullWidth: true,
            style: { marginTop: "40px" }
          }
        ),
        /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, mt: spacing.m30 }), children: [
          "By continuing, you are agreeing to our ",
          /* @__PURE__ */ jsx(InlineLink, { href: "https://www.zelt.app/privacy", title: "Privacy Policy", openInNewWindow: true }),
          " and ",
          /* @__PURE__ */ jsx(InlineLink, { href: "https://www.zelt.app/terms", title: "Terms and Conditions", openInNewWindow: true })
        ] })
      ]
    }
  );
};
