"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  CustomBenefitCategory,
  CustomBenefitType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { UploadInput } from "@/component/forms/UploadInput";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { ReactComponent as DocumentIcon } from "@/images/side-bar-icons/Subtract.svg";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
const getValidationSchema = (polyglot) => yup.object({
  name: yup.string().nullable().required(polyglot.t("ValidationMessages.requiredField")),
  description: yup.string().nullable().notRequired(),
  category: yup.string().oneOf(Object.values(CustomBenefitCategory)).required(polyglot.t("ValidationMessages.requiredField")),
  type: yup.string().oneOf(Object.values(CustomBenefitType), polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
  benefitLink: yup.string().nullable().notRequired(),
  logoUuid: yup.string().nullable().notRequired()
});
export const EditCustomBenefitGeneralDrawer = ({ isOpen, setIsOpen, customBenefit, refresh }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(EditCustomBenefitGeneralDrawerContent, { setIsOpen, refresh, customBenefit })
  }
) });
export const EditCustomBenefitGeneralDrawerContent = ({ setIsOpen, refresh, customBenefit }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      type: customBenefit.type,
      category: customBenefit.category,
      name: (_a = customBenefit.name) != null ? _a : "",
      description: (_b = customBenefit.description) != null ? _b : "",
      benefitLink: (_c = customBenefit.benefitLink) != null ? _c : "",
      logoUuid: (_d = customBenefit.logoUuid) != null ? _d : ""
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      const valuesToSubmit = {
        type: values.type,
        category: values.category,
        name: values.name,
        description: values.description ? values.description : null,
        benefitLink: values.benefitLink ? values.benefitLink : null,
        logoUuid: values.logoUuid ? values.logoUuid : null
      };
      try {
        setLoading(true);
        yield CustomBenefitAPI.updateCustomBenefitGeneral(customBenefit.id, valuesToSubmit);
        showMessage(polyglot.t("BenefitModule.benefitUpdated"), "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("BenefitModule.editBenefit") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("General.name"),
        value: formik.values.name,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("name", ""),
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_e = formik.touched.name && formik.errors.name) != null ? _e : " "
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        multiline: true,
        name: "description",
        label: polyglot.t("General.description"),
        value: formik.values.description,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.description && !!formik.errors.description,
        helperText: (_f = formik.touched.description && formik.errors.description) != null ? _f : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        multiline: true,
        name: "benefitLink",
        label: polyglot.t("BenefitModule.addBenefitLink"),
        value: formik.values.benefitLink,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.benefitLink && !!formik.errors.benefitLink,
        helperText: (_g = formik.touched.benefitLink && formik.errors.benefitLink) != null ? _g : " ",
        endAdornment: "none"
      }
    ),
    /* @__PURE__ */ jsxs(Box, { children: [
      formik.values.logoUuid ? /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("BenefitModule.benefitLogo") }) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("BenefitModule.uploadBenefitLogo") }),
      formik.values.logoUuid ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItem: "center", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItem: "center", gap: spacing.g10 }, children: [
          (customBenefit == null ? void 0 : customBenefit.logoUuid) === formik.values.logoUuid ? /* @__PURE__ */ jsx("img", { src: customBenefit == null ? void 0 : customBenefit.url, width: 16, height: 16, alt: "benefit-logo" }) : /* @__PURE__ */ jsx(DocumentIcon, { width: 16, height: 16 }),
          /* @__PURE__ */ jsxs(Typography, { variant: "headline3", children: [
            (_h = customBenefit == null ? void 0 : customBenefit.fileName) != null ? _h : polyglot.t("BenefitModule.logo"),
            " "
          ] })
        ] }),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            onClick: () => formik.setFieldValue("logoUuid", null),
            title: polyglot.t("General.remove"),
            children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize))
          }
        )
      ] }) : /* @__PURE__ */ jsx(
        UploadInput,
        {
          onChange: (resp) => {
            formik.setFieldValue("logoUuid", resp == null ? void 0 : resp.uuid);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
