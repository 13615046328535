"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { Autocomplete, Box, Paper, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { ReactComponent as ChoseIcon } from "@/images/side-bar-icons/Chose.svg";
import { nestErrorMessage } from "@/lib/errors";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { ClearIcon } from "@/v2/components/theme-components/clear-icon.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import {
  getGenderOptions,
  PERSONAL_INFORMATION_GENDERS
} from "@/v2/feature/user/features/user-profile/details/user-profile-details.interface";
import { UserAPI } from "@/v2/feature/user/user.api";
import { getCountriesForNationality } from "@/v2/infrastructure/country/country.util";
import { dateFieldTest } from "@/v2/infrastructure/date/date-format.util";
import { StyledAuto, StyledAutoTextfield } from "@/v2/styles/autocomplete.styles";
import { StyledMenuItem } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const iconSize = { width: "14px", height: "14px" };
const countryOptions = pipe(
  getCountriesForNationality(),
  RA.map(
    (r) => {
      var _a;
      return pipe(
        (_a = r.options) != null ? _a : [],
        RA.map((x) => __spreadProps(__spreadValues({}, x), { group: r.label }))
      );
    }
  ),
  RA.flatten
);
const getPersonalInformationSchemaValues = (polyglot) => {
  return {
    dob: Yup.string().test(dateFieldTest).nullable().notRequired(),
    gender: Yup.string().nullable(),
    nationality: Yup.string().nullable().notRequired(),
    passportNumber: Yup.string().nullable().notRequired(),
    personalEmail: Yup.string().nullable().email(polyglot.t("PersonalInformationCard.errorMessages.emailInvalid")).required(polyglot.t("PersonalInformationCard.errorMessages.emailRquired")),
    phone: Yup.string().nullable().notRequired()
  };
};
export const PersonalInformationSchema = (polyglot) => Yup.object().shape(getPersonalInformationSchemaValues(polyglot));
export const PersonalInformationSchemaForImport = (polyglot) => Yup.object().shape(__spreadProps(__spreadValues({}, getPersonalInformationSchemaValues(polyglot)), {
  personalEmail: Yup.string().nullable().email().notRequired(),
  gender: Yup.string().oneOf([...PERSONAL_INFORMATION_GENDERS]).nullable().notRequired()
}));
export const PersonalInformationForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  usedForDataImport = false,
  importHandler = () => {
  },
  showEmployee = false,
  customSchema
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const { getDefaultField } = useProfileFields();
  const validationSchema = useMemo(() => {
    return Yup.object().shape(__spreadValues(__spreadValues({}, getPersonalInformationSchemaValues(polyglot)), customSchema));
  }, [polyglot, customSchema]);
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        if (!usedForDataImport) {
          const _result = yield UserAPI.patchUserPersonalInfo(userId, __spreadValues({}, values));
          onSubmit(__spreadValues({}, values));
          showMessage(polyglot.t("PersonalInformationForm.successMessages.update"), "success");
        } else {
          importHandler == null ? void 0 : importHandler(__spreadValues({}, values));
        }
      } catch (error) {
        showMessage(
          polyglot.t("PersonalInformationForm.errorMessages.save", { msg: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("PersonalInformationForm.edit") }),
    showEmployee && /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("PersonalInformationForm.employee") }),
      /* @__PURE__ */ jsx(UserCell, { userId, nameVariant: "headline3" })
    ] }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "dob", children: /* @__PURE__ */ jsx(
      TypeableDateComponent,
      {
        name: "dob",
        label: polyglot.t("PersonalInformationForm.dob"),
        value: formik.values.dob,
        onChange: (value) => formik.setFieldValue("dob", value),
        error: formik.touched.dob && !!formik.errors.dob,
        helperText: formik.touched.dob && formik.errors.dob,
        disabled: loading,
        required: (_a = getDefaultField("dob")) == null ? void 0 : _a.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "gender", children: /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "gender",
        label: polyglot.t("PersonalInformationForm.gender"),
        options: getGenderOptions(polyglot),
        value: getGenderOptions(polyglot).find(
          ({ value }) => value === formik.values.gender
        ),
        compareValue: (_b = formik.values.gender) != null ? _b : "",
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("gender", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        error: formik.touched.gender && Boolean(formik.errors.gender),
        helperText: formik.touched.gender && formik.errors.gender,
        required: (_c = getDefaultField("gender")) == null ? void 0 : _c.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "nationality", children: /* @__PURE__ */ jsx(
      Autocomplete,
      {
        fullWidth: true,
        options: countryOptions,
        getOptionLabel: (option) => option.label,
        value: countryOptions.find(({ value }) => value === formik.values.nationality),
        renderOption: (props, option) => {
          return option.value === formik.values.nationality ? /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: option.label }),
                /* @__PURE__ */ jsx(ChoseIcon, __spreadValues({}, iconSize))
              ]
            }
          ) })) : /* @__PURE__ */ jsx(StyledMenuItem, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: option.label }) }));
        },
        groupBy: (option) => option.group,
        onChange: (_, x) => {
          var _a2;
          return formik.setFieldValue("nationality", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
        },
        renderInput: (params) => {
          var _a2;
          return /* @__PURE__ */ jsx(
            StyledAutoTextfield,
            __spreadProps(__spreadValues({}, params), {
              variant: "standard",
              size: "small",
              InputLabelProps: { shrink: true },
              label: polyglot.t("PersonalInformationForm.nationality"),
              name: "nationality",
              error: !!formik.errors.nationality && formik.touched.nationality,
              helperText: formik.errors.nationality && formik.touched.nationality,
              required: (_a2 = getDefaultField("nationality")) == null ? void 0 : _a2.isRequired
            })
          );
        },
        popupIcon: /* @__PURE__ */ jsx(ArrowDownACIcon, {}),
        clearIcon: /* @__PURE__ */ jsx(ClearIcon, {}),
        PaperComponent: ({ children }) => /* @__PURE__ */ jsx(Paper, { sx: StyledAuto, children })
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "passportNumber", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "passportNumber",
        label: polyglot.t("PersonalInformationForm.passport"),
        value: formik.values.passportNumber,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.passportNumber && !!formik.errors.passportNumber,
        helperText: (_d = formik.touched.passportNumber && formik.errors.passportNumber) != null ? _d : " ",
        clearText: () => formik.setFieldValue("passportNumber", ""),
        required: (_e = getDefaultField("passportNumber")) == null ? void 0 : _e.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "personalEmail", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "personalEmail",
        label: polyglot.t("PersonalInformationForm.personalEmail"),
        value: formik.values.personalEmail,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.personalEmail && !!formik.errors.personalEmail,
        helperText: (_f = formik.touched.personalEmail && formik.errors.personalEmail) != null ? _f : " ",
        clearText: () => formik.setFieldValue("personalEmail", ""),
        required: (_g = getDefaultField("personalEmail")) == null ? void 0 : _g.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "phone", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "phone",
        label: polyglot.t("PersonalInformationForm.phone"),
        value: formik.values.phone,
        type: "string",
        onChange: formik.handleChange,
        error: formik.touched.phone && !!formik.errors.phone,
        helperText: (_h = formik.touched.phone && formik.errors.phone) != null ? _h : " ",
        clearText: () => formik.setFieldValue("phone", ""),
        required: (_i = getDefaultField("phone")) == null ? void 0 : _i.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values)
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
