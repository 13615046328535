"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  CustomFieldComponents,
  ProfileField
} from "@/v2/feature/user/features/user-profile/details/components/show-custom-field.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserAPI } from "@/v2/feature/user/user.api";
export const BasicInformationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  middleName: Yup.string().optional().nullable(),
  displayName: Yup.string().notRequired(),
  emailAddress: Yup.string().email("Enter a valid email").required("Email is required")
});
export const BasicInformationForm = ({
  initialValues,
  userId,
  onSubmit,
  onClose,
  handleRefresh,
  usedForDataImport = false,
  importHandler = () => {
  }
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { hasScopes } = useScopes();
  const hasBasicAll = hasScopes(["user.basicInfo:all"], { userId });
  const [loading, setLoading] = useState(false);
  const { getDefaultField } = useProfileFields();
  const onFormikSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      var _a2, _b2, _c2, _d2, _e2, _f2;
      setLoading(true);
      try {
        if (!usedForDataImport) {
          const _result = yield UserAPI.patchUserBasicInfo(userId, values);
          onSubmit == null ? void 0 : onSubmit(__spreadValues({}, values));
          handleRefresh();
          showMessage(polyglot.t("BasicInformationForm.successMessages.update"), "success");
        } else {
          importHandler == null ? void 0 : importHandler(__spreadValues({}, values));
        }
      } catch (error) {
        const err = error;
        if (((_c2 = (_b2 = (_a2 = err == null ? void 0 : err.response) == null ? void 0 : _a2.data) == null ? void 0 : _b2.error) == null ? void 0 : _c2.includes("Key (email_address)")) && ((_f2 = (_e2 = (_d2 = err == null ? void 0 : err.response) == null ? void 0 : _d2.data) == null ? void 0 : _e2.error) == null ? void 0 : _f2.includes("already exists"))) {
          showMessage(polyglot.t("BasicInformationForm.errorMessages.update"), "error");
        } else {
          showMessage(polyglot.t("BasicInformationForm.errorMessages.save", { msg: nestErrorMessage(error) }), "error");
        }
      } finally {
        setLoading(false);
      }
    }),
    [importHandler, onSubmit, polyglot, showMessage, usedForDataImport, userId, handleRefresh]
  );
  const formik = useFormik({
    initialValues: __spreadValues({
      firstName: "",
      lastName: "",
      middleName: "",
      displayName: "",
      customUpdates: [],
      emailAddress: ""
    }, initialValues),
    enableReinitialize: true,
    validationSchema: BasicInformationSchema,
    onSubmit: onFormikSubmit
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Edit basic" }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "emailAddress", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emailAddress",
        label: polyglot.t("BasicInformationForm.emailAddress"),
        value: formik.values.emailAddress,
        type: "text",
        disabled: !hasBasicAll,
        onChange: formik.handleChange,
        error: formik.touched.emailAddress && !!formik.errors.emailAddress,
        helperText: (_a = formik.touched.emailAddress && formik.errors.emailAddress) != null ? _a : " ",
        clearText: () => formik.setFieldValue("emailAddress", ""),
        required: (_b = getDefaultField("emailAddress")) == null ? void 0 : _b.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "firstName", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "firstName",
        label: polyglot.t("BasicInformationForm.firstName"),
        value: formik.values.firstName,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.firstName && !!formik.errors.firstName,
        helperText: (_c = formik.touched.firstName && formik.errors.firstName) != null ? _c : " ",
        clearText: () => formik.setFieldValue("firstName", ""),
        required: (_d = getDefaultField("firstName")) == null ? void 0 : _d.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "lastName", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "lastName",
        label: polyglot.t("BasicInformationForm.lastName"),
        value: formik.values.lastName,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.lastName && !!formik.errors.lastName,
        helperText: (_e = formik.touched.lastName && formik.errors.lastName) != null ? _e : " ",
        clearText: () => formik.setFieldValue("lastName", ""),
        required: (_f = getDefaultField("lastName")) == null ? void 0 : _f.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "middleName", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "middleName",
        label: polyglot.t("BasicInformationForm.middleName"),
        value: formik.values.middleName,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.middleName && !!formik.errors.middleName,
        helperText: (_g = formik.touched.middleName && formik.errors.middleName) != null ? _g : " ",
        clearText: () => formik.setFieldValue("middleName", ""),
        required: (_h = getDefaultField("middleName")) == null ? void 0 : _h.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(ProfileField, { fieldStub: "displayName", children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "displayName",
        label: polyglot.t("BasicInformationForm.displayName"),
        value: formik.values.displayName,
        type: "text",
        onChange: formik.handleChange,
        error: formik.touched.displayName && !!formik.errors.displayName,
        helperText: (_i = formik.touched.displayName && formik.errors.displayName) != null ? _i : " ",
        clearText: () => formik.setFieldValue("displayName", ""),
        required: (_j = getDefaultField("displayName")) == null ? void 0 : _j.isRequired
      }
    ) }),
    /* @__PURE__ */ jsx(
      CustomFieldComponents,
      {
        values: formik.values.customUpdates,
        onChange: (values) => formik.setFieldValue("customUpdates", values)
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] })
  ] }) });
};
