"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { GrowthChip } from "@v2/feature/growth/shared/components/growth-chip.component";
export const QuestionCell = ({ text, chips, textVariant = "paragraph", textColor = "DarkGrey" }) => {
  return /* @__PURE__ */ jsxs(Box, { children: [
    typeof text === "string" ? /* @__PURE__ */ jsx(Typography, { variant: textVariant, color: textColor, children: text }) : text,
    chips && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", gap: "16px", mt: "8px" }, children: chips.map((chip, index) => /* @__PURE__ */ jsx(GrowthChip, { options: chip }, index)) })
  ] });
};
