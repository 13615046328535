"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { ReactComponent as DeleteIcon } from "@/images/side-bar-icons/Delete.svg";
import { ReactComponent as Loading } from "@/images/side-bar-icons/Spin.svg";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AccountingCodeItem } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/accounting-settings/components/accounting-code-item.component";
import {
  CALCULATION_METHOD_OPTIONS,
  PAYCODE_TYPE_OPTIONS
} from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/paycodes-settings/payroll-settings-paycodes-table.component";
import { MAX_PAYLINE_DESCRIPTION_LENGTH } from "@/v2/feature/payroll/payroll-external.interface";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PayrollEditPayCodesPage = ({
  payCodesNames,
  payCode,
  nominalCodes,
  reimportNominalCodes,
  accountingConfig,
  close,
  deleteCompanyPaycode,
  updateCompanyPaycode
}) => {
  var _a;
  const [updatingPayCode, setUpdatingPayCode] = useState();
  const isSystemOrControlCode = payCode.isSystemCode || payCode.isControlCode;
  const [calculationMethod, setCalculationMethod] = useState(
    payCode.calculationType === "MultipleOfHourlyRate" ? "multipleHourly" : payCode.calculationType === "MultipleOfDailyRate" ? "multipleDaily" : payCode.calculationType === "PercentageOfGross" ? "percentage" : "fixedAmount"
  );
  const validationSchema = useMemo(() => {
    const otherPayCodeNames = payCodesNames.filter((name) => name !== payCode.code);
    return yup.object({
      code: yup.string().required("Pay code identifier is required").notOneOf(otherPayCodeNames, "Pay code already exists"),
      title: yup.string().required("Pay code name is required"),
      isDeduction: yup.boolean().required("Choose a pay code type"),
      multiple: calculationMethod === "multipleHourly" || calculationMethod === "multipleDaily" ? yup.number().min(0.1, "Must be a positive number").required() : yup.number(),
      percentage: calculationMethod === "percentage" ? yup.number().min(0.1, "Must be a positive number").required() : yup.number()
    });
  }, [calculationMethod, payCode.code, payCodesNames]);
  const deletePayCode = useCallback(() => __async(void 0, null, function* () {
    setUpdatingPayCode("deleting");
    const payCodeDeleted = yield deleteCompanyPaycode(payCode);
    setUpdatingPayCode(void 0);
    if (payCodeDeleted) close();
  }), [close, deleteCompanyPaycode, payCode]);
  const formik = useFormik({
    initialValues: __spreadProps(__spreadValues({}, payCode), {
      multiple: payCode.calculationType === "MultipleOfHourlyRate" ? payCode.hourlyRateMultiplier || 1 : payCode.calculationType === "MultipleOfDailyRate" ? payCode.dailyRateMultiplier || 1 : 1,
      percentage: payCode.calculationType === "PercentageOfGross" ? payCode.defaultValue || 0 : 0,
      multiplierType: "None",
      accountingField: (_a = accountingConfig.accountingCodes.fields) == null ? void 0 : _a.find((f) => f.payCode === payCode.code)
    }),
    validationSchema,
    onSubmit: (_b) => __async(void 0, null, function* () {
      var _c = _b, { accountingField } = _c, updatedPayCode = __objRest(_c, ["accountingField"]);
      const updatedPayCodePayload = __spreadProps(__spreadValues({}, updatedPayCode), {
        defaultValue: calculationMethod === "fixedAmount" ? formik.values.defaultValue : calculationMethod === "multipleHourly" || calculationMethod === "multipleDaily" ? formik.values.multiple : calculationMethod === "percentage" ? formik.values.percentage : 0,
        calculationType: calculationMethod === "fixedAmount" ? "FixedAmount" : calculationMethod === "percentage" ? "PercentageOfGross" : calculationMethod === "multipleHourly" ? "MultipleOfHourlyRate" : "MultipleOfDailyRate",
        multiplierType: "None",
        hourlyRateMultiplier: calculationMethod === "multipleHourly" ? formik.values.multiple || 1 : 0,
        dailyRateMultiplier: calculationMethod === "multipleDaily" ? formik.values.multiple || 1 : 0
      });
      setUpdatingPayCode("saving");
      const savedPaycode = yield updateCompanyPaycode(updatedPayCodePayload, accountingField);
      setUpdatingPayCode(void 0);
      if (savedPaycode) close();
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Edit code" }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          sx: __spreadProps(__spreadValues({}, tableIconButtonSx), { ml: "auto" }),
          title: `Delete pay code: ${payCode.code}`,
          onClick: () => deletePayCode(),
          disabled: isSystemOrControlCode || !!updatingPayCode,
          children: updatingPayCode === "deleting" ? /* @__PURE__ */ jsx(Loading, {}) : /* @__PURE__ */ jsx(DeleteIcon, {})
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Stack, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Pay code" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: payCode.code })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "title",
        label: "Name",
        type: "text",
        onChange: formik.handleChange,
        value: formik.values.title,
        error: formik.touched.title && !!formik.errors.title,
        helperText: formik.touched.title && formik.errors.title,
        disabled: isSystemOrControlCode || !!updatingPayCode,
        maxLength: MAX_PAYLINE_DESCRIPTION_LENGTH
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: PAYCODE_TYPE_OPTIONS,
        onChange: (e) => formik.setFieldValue("isDeduction", e.target.value === "deduction"),
        value: formik.values.isDeduction ? "deduction" : "addition",
        disabled: isSystemOrControlCode || !!updatingPayCode
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "calculationMethod",
        label: "Calculation Method",
        options: CALCULATION_METHOD_OPTIONS,
        onChange: (e) => setCalculationMethod(e.target.value),
        value: calculationMethod,
        disabled: isSystemOrControlCode || !!updatingPayCode
      }
    ),
    calculationMethod === "multipleHourly" || calculationMethod === "multipleDaily" ? /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "multiple",
        label: calculationMethod === "multipleHourly" ? "Hour multiple" : "Day multiple",
        type: "number",
        onChange: formik.handleChange,
        value: formik.values.multiple,
        error: formik.touched.multiple && !!formik.errors.multiple,
        helperText: formik.touched.multiple && formik.errors.multiple,
        disabled: isSystemOrControlCode || !!updatingPayCode
      }
    ) : null,
    calculationMethod === "percentage" ? /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "percentage",
        label: "Percentage",
        type: "number",
        onChange: formik.handleChange,
        value: formik.values.percentage,
        error: formik.touched.percentage && !!formik.errors.percentage,
        helperText: formik.touched.percentage && formik.errors.percentage,
        disabled: isSystemOrControlCode || !!updatingPayCode
      }
    ) : null,
    /* @__PURE__ */ jsxs(Stack, { sx: { gap: spacing.g15 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Subject to" }),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isNiable",
          label: "National Insurance",
          checked: formik.values.isNiable,
          onChange: formik.handleChange,
          disabled: isSystemOrControlCode || !!updatingPayCode
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isTaxable",
          label: "Tax",
          checked: formik.values.isTaxable,
          onChange: formik.handleChange,
          disabled: isSystemOrControlCode || !!updatingPayCode
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isPensionable",
          label: "Pension",
          checked: formik.values.isPensionable,
          onChange: formik.handleChange,
          disabled: isSystemOrControlCode || !!updatingPayCode
        }
      ),
      /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "isAttachable",
          label: "Attachment Orders",
          checked: formik.values.isAttachable,
          onChange: formik.handleChange,
          disabled: isSystemOrControlCode || !!updatingPayCode
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      AccountingCodeItem,
      {
        payCode: payCode.code,
        nominalCodes,
        reimportNominalCodes,
        accountingConfig,
        accountingField: formik.values.accountingField,
        setAccountingField: (value) => {
          formik.setFieldValue("accountingField", value);
        },
        disabled: !!updatingPayCode,
        autoFocus: isSystemOrControlCode
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Save",
        loading: updatingPayCode === "saving",
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        style: {
          visibility: updatingPayCode === "deleting" ? "hidden" : "visible"
        }
      }
    ) })
  ] }) });
};
