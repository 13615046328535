"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { PensionAPI } from "@v2/feature/benefits/subfeature/pension/pension.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const EditPensionGeneralDrawer = ({ isOpen, setIsOpen, provider, refresh }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(EditPensionGeneralDrawerContent, { setIsOpen, refresh, provider })
  }
) });
export const EditPensionGeneralDrawerContent = ({ setIsOpen, refresh, provider }) => {
  var _a;
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      displayName: (_a = provider.displayName) != null ? _a : ""
    },
    validationSchema: Yup.object({
      displayName: Yup.string().required(polyglot.t("ValidationMessages.requiredField"))
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      return updateProvider(values);
    })
  });
  const updateProvider = (data) => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const updatePensionBody = {
        displayName: data.displayName
      };
      yield PensionAPI.updatePensionGeneralData(provider.id, updatePensionBody);
      showMessage(polyglot.t("PensionModule.pensionConnected"), "success");
      setIsOpen(false);
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("PensionModule.updatePension") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "displayName",
        label: polyglot.t("General.displayName"),
        value: formik.values.displayName,
        onChange: formik.handleChange,
        error: formik.touched.displayName && !!formik.errors.displayName,
        helperText: formik.touched.displayName && formik.errors.displayName
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.update"),
        loading,
        sizeVariant: "medium",
        colorVariant: "primary",
        fullWidth: true
      }
    ) })
  ] }) });
};
