"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box, FormControl, Grid } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@/v2/styles/spacing.styles";
export const AbsencePolicyAppMappingDrawer = ({
  isOpen,
  existingMapping,
  onClose,
  onUpdate,
  setIsOpen,
  absencePolicies,
  externalMappingOptions,
  appStub,
  alreadyMappedPolicies
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: {
      policyId: (existingMapping == null ? void 0 : existingMapping.policyId) || 0,
      // 0 indicates no policy selected
      type: (existingMapping == null ? void 0 : existingMapping.type) || "",
      reason: (existingMapping == null ? void 0 : existingMapping.reason) || ""
    },
    enableReinitialize: true,
    // To reset form when initialValues change
    validationSchema: Yup.object().shape({
      policyId: Yup.number().required(polyglot.t("AbsencePolicyMappingDrawer.validation.policyRequired")).min(1, polyglot.t("AbsencePolicyMappingDrawer.validation.policyRequired")),
      type: Yup.string().when("policyId", {
        is: (policyId) => policyId > 0,
        then: Yup.string().required(polyglot.t("AbsencePolicyMappingDrawer.validation.typeRequired")),
        otherwise: Yup.string()
      }),
      reason: Yup.string().when("policyId", {
        is: (policyId) => policyId > 0,
        then: Yup.string().required(polyglot.t("AbsencePolicyMappingDrawer.validation.reasonRequired")),
        otherwise: Yup.string()
      })
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        if (existingMapping) {
          yield AppIntegrationAPI.updateExistingExternalMapping(appStub, "absence", values);
        } else {
          yield AppIntegrationAPI.createNewExternalMapping(appStub, "absence", values);
        }
        showMessage(polyglot.t("AbsencePolicyMappingDrawer.successMessage"), "success");
        onClose();
        onUpdate();
      } catch (error) {
        console.error("Failed to update absence policy mapping:", error);
        showMessage(polyglot.t("AbsencePolicyMappingDrawer.errorMessage"), "error");
      }
    })
  });
  const handlePolicyChange = (event) => {
    const policyId = Number(event.target.value);
    if (policyId === 0) {
      formik.resetForm();
      return;
    }
    const policy = absencePolicies.find((p) => p.id === policyId) || null;
    if (policy) {
      if (existingMapping && existingMapping.policyId === policyId) {
        formik.setValues({
          policyId,
          type: existingMapping.type,
          reason: existingMapping.reason
        });
      } else {
        formik.setValues({
          policyId,
          type: "",
          reason: ""
        });
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      if (existingMapping) {
        const policy = absencePolicies.find((p) => p.id === existingMapping.policyId) || null;
        if (policy) {
          formik.setValues({
            policyId: policy.id,
            // Already a number
            type: existingMapping.type,
            reason: existingMapping.reason
          });
        }
      } else {
        formik.resetForm();
      }
    }
  }, [isOpen, existingMapping]);
  const policyOptions = [
    { label: polyglot.t("AbsencePolicyMappingDrawer.selectPolicyPlaceholder"), value: 0 },
    // 0 for placeholder
    ...absencePolicies.filter((eachPolicy) => !existingMapping ? !alreadyMappedPolicies.has(eachPolicy.id) : true).map((policy) => ({
      label: policy.name,
      value: policy.id
      // Ensure all values are numbers
    }))
  ];
  const absenceTypeOptions = (_a = externalMappingOptions == null ? void 0 : externalMappingOptions.absenceTypes) == null ? void 0 : _a.map((type) => ({
    label: type,
    value: type
  }));
  const absenceReasonOptions = (_b = externalMappingOptions == null ? void 0 : externalMappingOptions.absenceReasons) == null ? void 0 : _b.map((reason) => ({
    label: reason,
    value: reason
  }));
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, sx: { overflowY: "hidden", px: spacing.s2 }, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: __spreadValues({}, drawerContentSx), children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AppMappingPage.title") }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          overflowY: "auto",
          maxHeight: "60vh",
          mt: spacing.mt10
        },
        children: [
          /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.mb20 }, children: /* @__PURE__ */ jsx(FormControl, { fullWidth: true, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              label: polyglot.t("AbsencePolicyMappingDrawer.selectPolicy"),
              name: "policyId",
              value: formik.values.policyId,
              onChange: handlePolicyChange,
              error: formik.touched.policyId && Boolean(formik.errors.policyId),
              helperText: formik.touched.policyId && formik.errors.policyId,
              options: policyOptions,
              disabled: !!existingMapping
            }
          ) }) }),
          formik.values.policyId !== void 0 && formik.values.policyId !== null && formik.values.policyId > 0 && /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Grid, { container: true, spacing: 2, alignItems: "center", children: [
            /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, children: /* @__PURE__ */ jsx(FormControl, { fullWidth: true, children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                label: polyglot.t("AbsencePolicyMappingDrawer.type"),
                name: "type",
                value: formik.values.type,
                onChange: formik.handleChange,
                error: formik.touched.type && Boolean(formik.errors.type),
                helperText: formik.touched.type && formik.errors.type,
                options: absenceTypeOptions
              }
            ) }) }),
            /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, sm: 6, children: /* @__PURE__ */ jsx(FormControl, { fullWidth: true, children: /* @__PURE__ */ jsx(
              SelectComponent,
              {
                label: polyglot.t("AbsencePolicyMappingDrawer.reason"),
                name: "reason",
                value: formik.values.reason,
                onChange: formik.handleChange,
                error: formik.touched.reason && Boolean(formik.errors.reason),
                helperText: formik.touched.reason && formik.errors.reason,
                options: absenceReasonOptions
              }
            ) }) })
          ] }) })
        ]
      }
    ),
    formik.errors.policyId && typeof formik.errors.policyId === "number" && /* @__PURE__ */ jsx(Typography, { color: "red", variant: "paragraph", children: formik.errors.policyId }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        colorVariant: "primary",
        sizeVariant: "medium",
        type: "submit",
        disabled: !formik.isValid || formik.isSubmitting,
        children: existingMapping ? polyglot.t("General.update") : polyglot.t("General.create")
      }
    ) })
  ] }) }) });
};
