"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { keyBy } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { PlaceholderBox } from "@/v2/feature/growth/reviews/features/components/placeholder-box.component";
import { SurveyTemplateAPI, SurveyTemplateEndpoints } from "@/v2/feature/growth/surveys/api-client/survey-template.api";
import { SurveyQuestionBuilder } from "@/v2/feature/growth/surveys/features/components/question-builder.component";
import { SurveySectionBuilder } from "@/v2/feature/growth/surveys/features/components/section-builder.component";
import { CycleCreationQuestionPreviewer } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/components/cycle-creation-question-previewer.component";
import { SurveyCycleCreationQuestionSelectModal } from "@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/components/cycle-creation-question-select-modal.component";
import { SurveyTemplateMoveToSectionModal } from "@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/components/survey-template-move-to-section-modal.component";
import { SurveyTemplateQuestionModal } from "@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/components/survey-template-question-modal.component";
import { SurveyTemplateSectionModal } from "@/v2/feature/growth/surveys/features/survey-template/survey-template-builder/components/survey-template-section-modal.component";
import { useSurveyTemplateQuestionOptions } from "@/v2/feature/growth/surveys/hooks/survey-template-cycle-shared/use-template-question-options.hook";
import { useSurveyTemplateSectionOptions } from "@/v2/feature/growth/surveys/hooks/survey-template-cycle-shared/use-template-section-options.hook";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SurveyTemplateBuilderPage = ({
  templateObject,
  refreshTemplate
}) => {
  var _a, _b, _c;
  const params = useParams();
  const templateId = params.templateId;
  const { data: bankCount } = useApiClient(SurveyTemplateEndpoints.getBankCount(), { suspense: false });
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const [question, setQuestion] = useState(null);
  const [section, setSection] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState(false);
  const [isQuestionBankOpen, setIsQuestionBankOpen] = useState(false);
  const [isMoveToSectionOpen, setIsMoveToSectionOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(void 0);
  const [isPreviewerOpen, setIsPreviewerOpen] = useState(false);
  const [template, setTemplate] = useState(void 0);
  useEffect(() => {
    if (templateObject) {
      setTemplate(templateObject.template);
    }
  }, [templateObject]);
  const questions = useMemo(() => (templateObject == null ? void 0 : templateObject.questions) || [], [templateObject == null ? void 0 : templateObject.questions]);
  const sections = useMemo(() => (templateObject == null ? void 0 : templateObject.sections) || [], [templateObject == null ? void 0 : templateObject.sections]);
  const renderQuestion = (questionId) => {
    if (!questions || !template) return null;
    const question2 = questions.find((q) => q.id === questionId);
    if (!question2) return null;
    return /* @__PURE__ */ jsx(
      QuestionComponent,
      {
        question: question2,
        template,
        sectionId: null,
        setQuestion,
        setIsQuestionModalOpen,
        refreshTemplate,
        setIsMoveToSectionOpen,
        setSelectedQuestionId,
        hasSections: Boolean(sections && sections.length > 0),
        growthFactors
      },
      question2.id
    );
  };
  const renderSection = (sectionId) => {
    var _a2;
    const section2 = sections == null ? void 0 : sections.find((s) => s.id === sectionId);
    if (!section2 || !template) return null;
    const orderSection = template == null ? void 0 : template.order.find((o) => o.id === sectionId && o.type === "section");
    if (!orderSection) return null;
    const orderedQuestions = ((_a2 = orderSection.questions) != null ? _a2 : []).map((questionId) => questions == null ? void 0 : questions.find((q) => q.id === questionId)).filter((question2) => Boolean(question2));
    const handleDragDrop = (results) => __async(void 0, null, function* () {
      const { source, destination, type } = results;
      if (!template) return;
      if (type !== "group") return;
      const sectionIndex = template == null ? void 0 : template.order.findIndex((o) => o.type === "section" && o.id === section2.id);
      if (sectionIndex === -1) return;
      const reOrdered = __spreadValues({}, template.order[sectionIndex]);
      if (!reOrdered || !reOrdered.questions) return;
      const sourceIndex = source.index;
      const destinationIndex = destination == null ? void 0 : destination.index;
      if (sourceIndex === -1 || destinationIndex === void 0 || destinationIndex === -1) return;
      const [removed] = reOrdered.questions.splice(sourceIndex, 1);
      reOrdered.questions.splice(destinationIndex, 0, removed);
      const updatedOrder = [...template.order];
      updatedOrder[sectionIndex] = reOrdered;
      yield SurveyTemplateAPI.updateOrderSurveyTemplate({ templateId, order: updatedOrder });
      yield refreshTemplate == null ? void 0 : refreshTemplate();
    });
    return /* @__PURE__ */ jsx(
      TemplateBuilderSection,
      {
        section: section2,
        setSection,
        setIsQuestionModalOpen,
        setIsSectionModalOpen,
        questions: orderedQuestions,
        handleDragDrop,
        template,
        setQuestion,
        refreshTemplate,
        setIsMoveToSectionOpen,
        setSelectedQuestionId,
        sections,
        growthFactors
      },
      section2.id
    );
  };
  const addQuestionsToTemplateHandle = (selectedQuestionIds) => __async(void 0, null, function* () {
    try {
      yield SurveyTemplateAPI.addQuestionsToTemplate([...selectedQuestionIds], templateId);
      refreshTemplate == null ? void 0 : refreshTemplate();
    } catch (error) {
    }
  });
  const moveQuestionInsideSection = (sectionId, questionId) => __async(void 0, null, function* () {
    try {
      yield SurveyTemplateAPI.moveQuestionInsideSection({ templateId, sectionId, questionId });
      refreshTemplate == null ? void 0 : refreshTemplate();
    } catch (error) {
    }
  });
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        width: "100%",
        height: "100%",
        boxSizing: "border-box"
      },
      children: [
        /* @__PURE__ */ jsxs(
          Box,
          {
            className: "sub-title",
            sx: {
              px: spacing.p16,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: spacing.m4
            },
            children: [
              /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "Questions" }),
              /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "small", onClick: () => setIsPreviewerOpen(true), children: "Preview" })
            ]
          }
        ),
        (template == null ? void 0 : template.companyId) && /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m24, width: "100%", boxSizing: "border-box", px: spacing.p16 }, children: /* @__PURE__ */ jsx(
          PlaceholderBox,
          {
            title: "Select questions from library",
            countOne: `${(_a = bankCount == null ? void 0 : bankCount.totalCategories) != null ? _a : 0} factors`,
            countTwo: `${(_b = bankCount == null ? void 0 : bankCount.totalQuestions) != null ? _b : 0} questions`,
            action: () => setIsQuestionBankOpen(true)
          }
        ) }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: __spreadProps(__spreadValues({}, template && template.order ? {} : { margin: spacing.m16, border: `1px dashed ${themeColors.Background}` }), {
              mt: spacing.m24,
              height: "100%"
            }),
            children: [
              /* @__PURE__ */ jsx(
                Box,
                {
                  sx: {
                    display: "flex",
                    flexDirection: "column"
                  },
                  children: template && ((_c = template.order) == null ? void 0 : _c.map((item) => {
                    if (item.type === "question") {
                      return renderQuestion(item.id);
                    } else if (item.type === "section") {
                      return renderSection(item.id);
                    }
                    return /* @__PURE__ */ jsx(Fragment, {});
                  }))
                }
              ),
              (template == null ? void 0 : template.companyId) && /* @__PURE__ */ jsxs(
                Box,
                {
                  sx: {
                    display: "flex",
                    gap: spacing.g5,
                    mt: template && template.order ? spacing.m24 : "0px",
                    padding: spacing.p16
                  },
                  children: [
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsSectionModalOpen(true),
                        children: "New section"
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      ButtonComponent,
                      {
                        colorVariant: "secondary",
                        sizeVariant: "medium",
                        startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
                        onClick: () => setIsQuestionModalOpen(true),
                        children: "New question"
                      }
                    )
                  ]
                }
              ),
              /* @__PURE__ */ jsx(
                SurveyTemplateQuestionModal,
                {
                  setIsOpen: setIsQuestionModalOpen,
                  isOpen: isQuestionModalOpen,
                  question,
                  onClose: () => {
                    setIsQuestionModalOpen(false);
                    setQuestion(null);
                    setSection(null);
                  },
                  refresh: () => __async(void 0, null, function* () {
                    yield refreshTemplate == null ? void 0 : refreshTemplate();
                  }),
                  section,
                  templateId
                }
              ),
              /* @__PURE__ */ jsx(
                SurveyTemplateSectionModal,
                {
                  setIsOpen: setIsSectionModalOpen,
                  isOpen: isSectionModalOpen,
                  section,
                  onClose: () => {
                    setIsSectionModalOpen(false);
                    setSection(null);
                    setQuestion(null);
                  },
                  refresh: () => __async(void 0, null, function* () {
                    yield refreshTemplate == null ? void 0 : refreshTemplate();
                  }),
                  templateId
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SurveyCycleCreationQuestionSelectModal,
          {
            setIsQuestionsModalOpen: setIsQuestionBankOpen,
            isQuestionsModalOpen: isQuestionBankOpen,
            action: addQuestionsToTemplateHandle,
            excludeQuestions: questions || []
          }
        ),
        /* @__PURE__ */ jsx(
          SurveyTemplateMoveToSectionModal,
          {
            isOpen: isMoveToSectionOpen,
            setIsOpen: setIsMoveToSectionOpen,
            onClose: () => {
              setIsMoveToSectionOpen(false);
            },
            action: moveQuestionInsideSection,
            sections,
            questionId: selectedQuestionId
          }
        ),
        /* @__PURE__ */ jsx(
          CycleCreationQuestionPreviewer,
          {
            isPreviewerOpen,
            setIsPreviewerOpen,
            allQuestions: questions || [],
            allSections: sections || [],
            order: (template == null ? void 0 : template.order) || []
          }
        )
      ]
    }
  );
};
const TemplateBuilderSection = ({
  section,
  setSection,
  setIsQuestionModalOpen,
  setIsSectionModalOpen,
  setIsMoveToSectionOpen,
  questions,
  handleDragDrop,
  template,
  setQuestion,
  refreshTemplate,
  setSelectedQuestionId,
  sections,
  growthFactors
}) => {
  const sectionOptions = useSurveyTemplateSectionOptions(
    template,
    section,
    setSection,
    refreshTemplate,
    setIsSectionModalOpen
  );
  const newQuestionAction = () => {
    setSection(section);
    setIsQuestionModalOpen(true);
  };
  return /* @__PURE__ */ jsx(
    SurveySectionBuilder,
    {
      isEditable: Boolean(template.companyId),
      section,
      sectionOptions,
      dragQuestionComponent: /* @__PURE__ */ jsx(DragDropContext, { onDragEnd: handleDragDrop, children: /* @__PURE__ */ jsx(Droppable, { droppableId: "ROOT", type: "group", isDropDisabled: false, children: (provided) => /* @__PURE__ */ jsxs("div", __spreadProps(__spreadValues({}, provided.droppableProps), { ref: provided.innerRef, children: [
        questions && questions.map((question, idx) => /* @__PURE__ */ jsx(
          Draggable,
          {
            isDragDisabled: (section == null ? void 0 : section.companyId) === null || false,
            draggableId: question.id,
            index: idx,
            children: (provided2) => /* @__PURE__ */ jsx("div", __spreadProps(__spreadValues(__spreadValues({}, provided2.dragHandleProps), provided2.draggableProps), { ref: provided2.innerRef, children: /* @__PURE__ */ jsx(
              QuestionComponent,
              {
                question,
                template,
                sectionId: section.id,
                setQuestion,
                setIsQuestionModalOpen,
                refreshTemplate,
                setIsMoveToSectionOpen,
                setSelectedQuestionId,
                hasSections: Boolean(sections && sections.length > 0),
                growthFactors
              }
            ) }))
          },
          question.id
        )),
        provided.placeholder
      ] })) }) }),
      newQuestionAction
    }
  );
};
const QuestionComponent = ({
  question,
  template,
  sectionId,
  setQuestion,
  setIsQuestionModalOpen,
  refreshTemplate,
  setIsMoveToSectionOpen,
  setSelectedQuestionId,
  hasSections,
  growthFactors
}) => {
  const questionOptions = useSurveyTemplateQuestionOptions(
    question,
    setQuestion,
    setIsQuestionModalOpen,
    setIsMoveToSectionOpen,
    template,
    sectionId,
    refreshTemplate,
    setSelectedQuestionId,
    hasSections
  );
  return /* @__PURE__ */ jsx(
    SurveyQuestionBuilder,
    {
      questionOptions,
      question,
      growthFactors,
      template,
      showAction: Boolean(template.companyId)
    }
  );
};
