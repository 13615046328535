"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Link } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { underlinedLinkLight } from "@/v2/styles/buttons.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const LastpassInfoCard = () => /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", sx: { mt: "10px" }, children: [
  /* @__PURE__ */ jsx(Link, { sx: underlinedLinkLight, href: "https://www.lastpass.com/pricing", children: "Enterprise plan" }),
  " required to use LastPass API."
] });
export const LastpassProvHashInstructions = () => /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", color: "Grey", sx: { mb: spacing.m5 }, children: [
  "You can create a provisioning hash in your ",
  /* @__PURE__ */ jsx(Link, { sx: underlinedLinkLight, target: "_blank", href: "https://admin.lastpass.com/advanced/enterpriseApi", children: "Lastpass API settings" })
] });
export const LastpassAccountIdInstructions = () => /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", color: "Grey", sx: { mb: spacing.m5 }, children: [
  "Your account ID can be found in your Lastpass company ",
  /* @__PURE__ */ jsx(Link, { sx: underlinedLinkLight, target: "_blank", href: "https://admin.lastpass.com/dashboard", children: "Dashboard" })
] });
