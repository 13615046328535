"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useMemo, useState } from "react";
import { Box, Chip, FormLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { AutocompleteComponent } from "@v2/components/forms/autocomplete.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { CustomBenefitAPI } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.api";
import {
  CustomBenefitPaymentMethodType
} from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { isLoanBenefit, isRecurringBenefit } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { getExpenseTypeOptions } from "@v2/feature/payments/expenses.util";
import { PaymentTypeSettingsEndpoints } from "@v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { CompanyEndpoints } from "@/api-client/company.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
import { PaymentCategoryEnum } from "@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-settings.interface";
const getValidationSchema = (polyglot) => yup.object({
  paymentMethod: yup.string().oneOf(Object.values(CustomBenefitPaymentMethodType), polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
  paymentTypeIds: yup.array().of(
    yup.number().integer(polyglot.t("ValidationMessages.validValue")).typeError(polyglot.t("ValidationMessages.validValue"))
  ).typeError(polyglot.t("ValidationMessages.validValue")).required("ValidationMessages.requiredField").when("paymentMethod", {
    is: (val) => val === CustomBenefitPaymentMethodType.Expenses,
    then: (schema) => schema.min(1, polyglot.t("ValidationMessages.requiredField"))
  }),
  payCode: yup.string().nullable().notRequired(),
  // .when('paymentMethod', {
  //   is: (val: CustomBenefitPaymentMethodType) => val === CustomBenefitPaymentMethodType.Payroll,
  //   then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  payCodeEmployer: yup.string().nullable().notRequired()
  // .when('paymentMethod', {
  //   is: (val: CustomBenefitPaymentMethodType) => val === CustomBenefitPaymentMethodType.Payroll,
  //   then: (schema) => schema.required(polyglot.t('ValidationMessages.requiredField')),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
});
export const EditCustomBenefitPaymentDrawer = ({ isOpen, setIsOpen, customBenefit, refresh }) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(EditCustomBenefitPaymentDrawerContent, { setIsOpen, refresh, customBenefit })
  }
) });
export const EditCustomBenefitPaymentDrawerContent = ({ setIsOpen, refresh, customBenefit }) => {
  var _a, _b, _c, _d, _e;
  const { data } = useApiClient(PaymentTypeSettingsEndpoints.getExpenseTypesForCompanyId());
  const { data: companyConfig } = useApiClient(CompanyEndpoints.getCompanyConfig());
  const hasCompanyPayroll = (companyConfig == null ? void 0 : companyConfig.inPayroll) || (companyConfig == null ? void 0 : companyConfig.inGlobalPayroll);
  const expenseTypes = useMemo(() => {
    var _a2, _b2;
    return (_b2 = (_a2 = data == null ? void 0 : data.settings) == null ? void 0 : _a2.filter((type) => type.type === PaymentCategoryEnum.EXPENSE)) != null ? _b2 : [];
  }, [data]);
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const isLoan = isLoanBenefit(customBenefit.type);
  const isRecurring = isRecurringBenefit(customBenefit.type);
  const expenseTypeOptions = useMemo(() => getExpenseTypeOptions(expenseTypes != null ? expenseTypes : []), [expenseTypes]);
  const formik = useFormik({
    initialValues: {
      paymentMethod: (_a = customBenefit.paymentMethod) != null ? _a : CustomBenefitPaymentMethodType.Tracking,
      payCode: customBenefit.payCode,
      payCodeEmployer: customBenefit.payCodeEmployer,
      paymentTypeIds: (_c = (_b = customBenefit.paymentTypes) == null ? void 0 : _b.map(({ id }) => id)) != null ? _c : []
    },
    validationSchema: getValidationSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const update = {
          paymentMethod: values.paymentMethod,
          paymentTypeIds: values.paymentMethod === CustomBenefitPaymentMethodType.Expenses && values.paymentTypeIds ? values.paymentTypeIds : [],
          payCode: values.payCode,
          payCodeEmployer: values.payCodeEmployer
        };
        yield CustomBenefitAPI.updateCustomBenefitPayment(customBenefit.id, update);
        showMessage(polyglot.t("BenefitModule.benefitUpdated"), "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("BenefitModule.editBenefit") }),
    /* @__PURE__ */ jsxs(FormControl, { sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsx(FormLabel, { id: "payment-method-group-label", children: /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("BenefitModule.paymentMethod") }) }),
      /* @__PURE__ */ jsxs(
        RadioGroup,
        {
          "aria-labelledby": "payment-method-group-label",
          name: "paymentMethod",
          onChange: (event) => {
            const value = event.target.value;
            formik.setFieldValue("paymentMethod", event.target.value);
            if (value !== CustomBenefitPaymentMethodType.Expenses) formik.setFieldValue("paymentTypeIds", []);
          },
          sx: { gap: "10px" },
          children: [
            !isLoan && /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: CustomBenefitPaymentMethodType.Tracking,
                checked: formik.values.paymentMethod === CustomBenefitPaymentMethodType.Tracking,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsxs(Box, { children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Tracking only" }),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Manage benefits and members without payments" })
                ] })
              },
              "tracking"
            ),
            !isLoan && !isRecurring && /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: CustomBenefitPaymentMethodType.Expenses,
                checked: formik.values.paymentMethod === CustomBenefitPaymentMethodType.Expenses,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                label: /* @__PURE__ */ jsxs(Box, { children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Expenses" }),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Allow to claim expenses against benefit allowance" })
                ] })
              },
              "expenses"
            ),
            /* @__PURE__ */ jsx(
              FormControlLabel,
              {
                labelPlacement: "end",
                value: CustomBenefitPaymentMethodType.Payroll,
                checked: formik.values.paymentMethod === CustomBenefitPaymentMethodType.Payroll,
                control: /* @__PURE__ */ jsx(StyledRadio, {}),
                disabled: !hasCompanyPayroll,
                label: /* @__PURE__ */ jsxs(Box, { children: [
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: hasCompanyPayroll ? "Payroll" : "Payroll (Complete set up first)" }),
                  /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", children: "Pay or deduct contributions via payroll" })
                ] })
              },
              "payroll"
            )
          ]
        }
      )
    ] }),
    formik.values.paymentMethod === CustomBenefitPaymentMethodType.Expenses && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Expense settings" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { my: "10px" }, children: "Select expense policy that should be used for this allowance." }),
      /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          multiple: true,
          label: polyglot.t("BenefitModule.expenseType"),
          name: "paymentTypeIds",
          filterSelectedOptions: true,
          disableCloseOnSelect: true,
          value: expenseTypeOptions.filter(
            (o) => formik.values.paymentTypeIds.includes(o.value)
          ),
          options: expenseTypeOptions,
          onChange: (_, e) => {
            const value = e;
            formik.setFieldValue(
              "paymentTypeIds",
              value.map((o) => o.value)
            );
          },
          isOptionEqualToValue: (x, y) => x.value === y.value,
          renderTags: (tagValue, getTagProps) => tagValue.map((option, index) => {
            return /* @__PURE__ */ jsx(Chip, __spreadValues({ label: option.label }, getTagProps({ index })));
          }),
          error: !!formik.errors.paymentTypeIds && formik.touched.paymentTypeIds,
          helperText: formik.touched.paymentTypeIds && formik.errors.paymentTypeIds
        }
      )
    ] }),
    formik.values.paymentMethod === CustomBenefitPaymentMethodType.Payroll && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "payCode",
        label: polyglot.t("PayItemModule.payCodeEmployee"),
        value: formik.values.payCode,
        onChange: formik.handleChange,
        error: formik.touched.payCode && !!formik.errors.payCode,
        helperText: (_d = formik.touched.payCode && formik.errors.payCode) != null ? _d : " "
      }
    ),
    formik.values.paymentMethod === CustomBenefitPaymentMethodType.Payroll && isRecurring && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "payCodeEmployer",
        label: polyglot.t("PayItemModule.payCodeEmployer"),
        value: formik.values.payCodeEmployer,
        onChange: formik.handleChange,
        error: formik.touched.payCodeEmployer && !!formik.errors.payCodeEmployer,
        helperText: (_e = formik.touched.payCodeEmployer && formik.errors.payCodeEmployer) != null ? _e : " "
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        fullWidth: true
      }
    ) })
  ] }) });
};
