"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { IDCheckAPI } from "../id-check.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import {
  buttonBoxSx,
  drawerContentSx,
  fieldSx
} from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { spacing } from "@/v2/styles/spacing.styles";
export const AccountInformationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Employee Id is required")
});
export const EditIndividualCheckForm = ({ initialValues, onClose }) => {
  var _a;
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      price: Yup.number().required("Price is required")
    }),
    onSubmit: (values) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield IDCheckAPI.updateIndividualCheck(values);
        onClose();
        showMessage("Successfully updated Individual check", "success");
      } catch (error) {
        showMessage(`Failed to update individual check: ${nestErrorMessage(error)}; Please try again.`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsx(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
      "Price for ",
      formik.values.id
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "price",
        label: "Price",
        value: formik.values.price,
        onChange: formik.handleChange,
        type: "text",
        error: formik.touched.price && !!formik.errors.price,
        helperText: (_a = formik.touched.price && formik.errors.price) != null ? _a : " "
      }
    ) }) }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Update",
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) }) });
};
