"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { StyledRadio } from "@v2/styles/radio.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { Typography } from "@/v2/components/typography/typography.component";
export const PolicyProratingEditDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  refresh
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const onSubmit = useCallback(
    (values) => __async(void 0, null, function* () {
      const policyData = {
        proratingStartDate: true,
        // This is true in both cases (prorate by start date or prorate by start date & fte)
        proratingFte: values.proratingFte
      };
      try {
        setLoading(true);
        yield AbsenceAPI.updateAbsencePolicyProrating(absencePolicy.id, policyData);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(
          polyglot.t("AbsenceUtil.errorMessages.badRequest", { nestErrorMessage: nestErrorMessage(error) }),
          "error"
        );
      } finally {
        setLoading(false);
      }
    }),
    [setIsOpen, refresh, absencePolicy, showMessage, polyglot]
  );
  const formik = useFormik({
    initialValues: {
      proratingStartDate: absencePolicy.proratingStartDate,
      proratingFte: absencePolicy.proratingFte
    },
    validationSchema: yup.object({
      proratingStartDate: yup.boolean().required("Required field"),
      proratingFte: yup.boolean().required("Required field")
    }),
    onSubmit
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => __async(void 0, null, function* () {
        setTimeout(() => formik.resetForm(), 500);
      }),
      children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("AbsenceUtil.prorating") }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
          RadioGroup,
          {
            name: "prorating-allocation-group",
            onChange: (event) => {
              formik.setFieldValue("proratingFte", event.target.value === "true");
            },
            children: [
              /* @__PURE__ */ jsx(
                FormControlLabel,
                {
                  labelPlacement: "end",
                  value: "false",
                  checked: !formik.values.proratingFte,
                  control: /* @__PURE__ */ jsx(StyledRadio, {}),
                  label: /* @__PURE__ */ jsxs(Box, { children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AbsenceUtil.prorateByStartDate") }),
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("AbsenceUtil.prorateByStartDateDesc") })
                  ] }),
                  sx: { mb: "20px" }
                },
                "startDateOnly"
              ),
              /* @__PURE__ */ jsx(
                FormControlLabel,
                {
                  labelPlacement: "end",
                  value: "true",
                  checked: formik.values.proratingFte,
                  control: /* @__PURE__ */ jsx(StyledRadio, {}),
                  label: /* @__PURE__ */ jsxs(Box, { children: [
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("AbsenceUtil.prorateByStartDateAndFTE") }),
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: polyglot.t("AbsenceUtil.prorateByStartDateAndFTEDesc") })
                  ] })
                },
                "startDateAndFte"
              )
            ]
          }
        ) }) }),
        /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            sizeVariant: "medium",
            colorVariant: "primary",
            name: polyglot.t("General.save"),
            loading,
            fullWidth: true
          }
        ) })
      ] }) })
    }
  );
};
