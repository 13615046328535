"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { spacing } from "@/v2/styles/spacing.styles";
export const EmptyStateJobLevel = ({ addNewTrack }) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10, maxWidth: "18rem" }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("EmptyStateJobLevel.firstTrack") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("EmptyStateJobLevel.emptyMessage") }),
    /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "small",
        colorVariant: "primary",
        type: "button",
        onClick: addNewTrack,
        style: { marginTop: spacing.m10 },
        children: polyglot.t("EmptyStateJobLevel.newTrack")
      }
    )
  ] });
};
