"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "@/images/side-bar-icons/ArrowUp.svg";
import { ReactComponent as CheckedCheckbox } from "@/images/side-bar-icons/CheckedCheckbox.svg";
import { ReactComponent as EmptyCheckbox } from "@/images/side-bar-icons/EmptyCheckbox.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenu } from "@/v2/components/table/table-filter.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CategoryFilters = ({
  filterTypes,
  setFilterString,
  filterString,
  dynamicFilterNames = false,
  disabled,
  buttonStyle
}) => {
  var _a;
  const [filter, setFilter] = useState(null);
  const [filterObject, setFilterObject] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : void 0;
  const filterStringToObject = useCallback(() => {
    const defaultFilterString = {};
    if (filterString.length > 0) {
      const andString = filterString.split("&");
      if (andString.length > 0) {
        andString.forEach((item) => {
          const [key, values] = item.split("=");
          if (key && values) defaultFilterString[key] = values.split(",");
        });
      }
    }
    setFilterObject(defaultFilterString);
  }, [filterString]);
  useEffect(() => {
    filterStringToObject();
  }, [filterStringToObject]);
  const handleFilterSelect = (value) => () => {
    var _a2, _b;
    let updatedValues;
    if (filter) {
      const deselectValue = filterObject[filter] && ((_a2 = filterObject[filter]) == null ? void 0 : _a2.length) && filterObject[filter].indexOf(value);
      if (deselectValue >= 0) {
        updatedValues = filterObject[filter].filter((item) => item !== value);
        if (filterObject[filter].length <= 1) delete filterObject[filter];
      } else {
        updatedValues = ((_b = filterObject[filter]) == null ? void 0 : _b.length) ? [...filterObject[filter], value] : [value];
      }
      const newFilterOptions = __spreadProps(__spreadValues({}, filterObject), { [filter]: updatedValues });
      setFilterObject(newFilterOptions);
      let newQueryString = "";
      Object.keys(newFilterOptions).forEach((key, index) => {
        const optionsSelected = newFilterOptions[key].toString();
        if (optionsSelected.length > 0) {
          if (index === 0) newQueryString += `${key}=${optionsSelected}`;
          else newQueryString += `&${key}=${optionsSelected}`;
        }
      });
      setFilterString(newQueryString);
    }
  };
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5, flexWrap: "wrap" }, children: Object.keys(filterTypes).map((ft) => {
      var _a2, _b;
      return /* @__PURE__ */ jsxs(
        ButtonComponent,
        {
          sizeVariant: "filter",
          style: __spreadValues({ textTransform: "capitalize" }, buttonStyle),
          colorVariant: filterString.includes(ft) ? "active" : "secondary",
          onClick: (event) => {
            setFilter(ft);
            setAnchorEl(event.currentTarget);
          },
          disabled,
          children: [
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
              dynamicFilterNames ? ft.split("-").pop() : ft,
              filterString.includes(ft) && ((_a2 = filterObject[ft]) == null ? void 0 : _a2.length) > 0 && /* @__PURE__ */ jsx(
                Typography,
                {
                  sx: __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), {
                    backgroundColor: themeColors.white,
                    color: themeColors.DarkGrey,
                    borderRadius: radius.br10,
                    height: "16px",
                    width: "16px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: spacing.m2
                  }),
                  children: (_b = filterObject[ft]) == null ? void 0 : _b.length
                }
              )
            ] }),
            filter === ft && Boolean(anchorEl) ? /* @__PURE__ */ jsx(ArrowUp, {}) : /* @__PURE__ */ jsx(ArrowDown, {})
          ]
        },
        `${ft}-types`
      );
    }) }),
    /* @__PURE__ */ jsx(
      StyledMenu,
      {
        id,
        MenuListProps: {
          "aria-labelledby": "btnMenuOptions"
        },
        open,
        anchorEl,
        onClose: () => {
          setAnchorEl(null);
          setFilter(null);
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: { horizontal: "left", vertical: "top" },
        children: filter && ((_a = filterTypes[filter]) == null ? void 0 : _a.map((subFilter) => {
          var _a2;
          return /* @__PURE__ */ jsxs(
            MenuItem,
            {
              onClick: handleFilterSelect(subFilter.value.toString()),
              disableRipple: true,
              sx: { display: "flex", alignItems: "center", gap: spacing.g10 },
              disabled,
              children: [
                filterObject[filter] && ((_a2 = filterObject[filter]) == null ? void 0 : _a2.indexOf(subFilter.value.toString())) !== -1 ? /* @__PURE__ */ jsx(CheckedCheckbox, __spreadValues({}, iconSize)) : /* @__PURE__ */ jsx(EmptyCheckbox, __spreadValues({}, iconSize)),
                /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: subFilter.label })
              ]
            },
            subFilter.value
          );
        }))
      }
    )
  ] });
};
