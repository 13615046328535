"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Close } from "@/images/app-icons/Close.svg";
import { ReactComponent as PersonalIcon } from "@/images/onboarding-step-icons/PersonalBig.svg";
import { ReactComponent as EmploymentIcon } from "@/images/onboarding-step-icons/ProfiBig.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ONBOARDING_NEW_USER_ROUTE, SETTINGS_ONBOARDING_FLOWS_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import {
  ActionSelectionGroupComponent,
  ActionSelectionItem,
  ActionSelectionItemList
} from "@/v2/components/action-selection-group.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { EditableTitle } from "@/v2/components/forms/editable-title.component";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { AppsOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/apps/apps.component";
import { BenefitsOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/benefits.component";
import { ContractOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/contracts/contract.component";
import { DevicesOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/devices/devices.component";
import { DocumentsOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/documents/documents.component";
import { IDVerifyOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.component";
import { PayrollOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/payroll/payroll.component";
import { TasksOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/tasks/tasks.component";
import { TrainingOnboardingItem } from "@/v2/feature/onboarding/onboarding-items/training.component";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { useEscapeKey } from "@/v2/util/keyboard-hook.util";
export const iconSize = { width: 14, height: 14 };
export const OnboardingTemplateEdit = () => {
  var _a;
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const params = useParams();
  const mode = (_a = useLocation().state) == null ? void 0 : _a.mode;
  const [template, setTemplate] = useState();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [drawerView, setDrawerView] = useState();
  const [renameHintDismissed, setRenameHintDismissed] = useState(false);
  const loadTemplate = useCallback(() => __async(void 0, null, function* () {
    const id = Number(params.templateId);
    if (!Number.isInteger(id)) {
      throw new Error(`Invalid template id: ${params.templateId}`);
    }
    const templates = yield OnboardingAPI.getOnboardingTemplates();
    const template2 = templates.find((t) => t.templateId === id);
    if (!template2) {
      routerHistory.push(SETTINGS_ONBOARDING_FLOWS_ROUTE);
      return;
    }
    setTemplate(template2);
  }), [params.templateId, routerHistory]);
  useEffect(() => {
    loadTemplate();
  }, [loadTemplate]);
  const closeTemplateEditing = useCallback(() => routerHistory.goBack(), [routerHistory]);
  useEscapeKey(closeTemplateEditing);
  const checkOnboardingItemScope = useCallback(
    (scopes) => {
      return checkScopes(currentUser, scopes);
    },
    [currentUser]
  );
  if (!template) {
    return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsx(LoadingSpinner, {}) });
  }
  const [
    contractsItem_step1,
    contractsItem_step2,
    contractsItem_step3,
    contractsItem_step4,
    contractsItem_step5,
    tasksItem,
    documentsItem,
    idverify,
    devices,
    apps,
    payroll,
    benefits,
    training
  ] = [
    /* @__PURE__ */ jsx(
      ContractOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.contracts, __spreadValues({}, template2))),
        checkOnboardingItemScope,
        step: 1
      }
    ),
    /* @__PURE__ */ jsx(
      ContractOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.contracts_step2, __spreadValues({}, template2))),
        checkOnboardingItemScope,
        step: 2
      }
    ),
    /* @__PURE__ */ jsx(
      ContractOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.contracts_step3, __spreadValues({}, template2))),
        checkOnboardingItemScope,
        step: 3
      }
    ),
    /* @__PURE__ */ jsx(
      ContractOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.contracts_step4, __spreadValues({}, template2))),
        checkOnboardingItemScope,
        step: 4
      }
    ),
    /* @__PURE__ */ jsx(
      ContractOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.contracts_step5, __spreadValues({}, template2))),
        checkOnboardingItemScope,
        step: 5
      }
    ),
    /* @__PURE__ */ jsx(
      TasksOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.tasks, __spreadValues({}, template2))),
        checkOnboardingItemScope
      }
    ),
    /* @__PURE__ */ jsx(
      DocumentsOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.documents, __spreadValues({}, template2))),
        checkOnboardingItemScope
      }
    ),
    /* @__PURE__ */ jsx(
      IDVerifyOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.idverify, __spreadValues({}, template2)))
      }
    ),
    /* @__PURE__ */ jsx(
      DevicesOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.device, __spreadValues({}, template2)))
      }
    ),
    /* @__PURE__ */ jsx(
      AppsOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.apps, __spreadValues({}, template2))),
        checkOnboardingItemScope
      }
    ),
    /* @__PURE__ */ jsx(
      PayrollOnboardingItem,
      {
        template,
        setDrawerOpen,
        setDrawerView,
        onDelete: () => setTemplate((template2) => template2 && (template2 == null ? true : delete template2.template.payroll, __spreadValues({}, template2)))
      }
    ),
    /* @__PURE__ */ jsx(BenefitsOnboardingItem, { template }),
    /* @__PURE__ */ jsx(TrainingOnboardingItem, { template })
  ];
  const unassignedSteps = [];
  if (!template.template.contracts) {
    unassignedSteps.push(contractsItem_step1);
  }
  if (template.template.contracts && !template.template.contracts_step2) {
    unassignedSteps.push(contractsItem_step2);
  }
  if (template.template.contracts_step2 && !template.template.contracts_step3) {
    unassignedSteps.push(contractsItem_step3);
  }
  if (template.template.contracts_step3 && !template.template.contracts_step4) {
    unassignedSteps.push(contractsItem_step4);
  }
  if (template.template.contracts_step4 && !template.template.contracts_step5) {
    unassignedSteps.push(contractsItem_step5);
  }
  if (!template.template.tasks) {
    unassignedSteps.push(tasksItem);
  }
  if (!template.template.documents) {
    unassignedSteps.push(documentsItem);
  }
  if (!template.template.device) {
    unassignedSteps.push(devices);
  }
  if (!template.template.apps) {
    unassignedSteps.push(apps);
  }
  if (!template.template.payroll) {
    unassignedSteps.push(payroll);
  }
  if (!template.template.idverify) {
    unassignedSteps.push(idverify);
  }
  unassignedSteps.push(benefits, training);
  const assignedSteps = [];
  if (template.template.contracts) {
    assignedSteps.push(contractsItem_step1);
  }
  if (template.template.contracts_step2) {
    assignedSteps.push(contractsItem_step2);
  }
  if (template.template.contracts_step3) {
    assignedSteps.push(contractsItem_step3);
  }
  if (template.template.contracts_step4) {
    assignedSteps.push(contractsItem_step4);
  }
  if (template.template.contracts_step5) {
    assignedSteps.push(contractsItem_step5);
  }
  if (template.template.tasks) {
    assignedSteps.push(tasksItem);
  }
  if (template.template.documents) {
    assignedSteps.push(documentsItem);
  }
  if (template.template.device) {
    assignedSteps.push(devices);
  }
  if (template.template.apps) {
    assignedSteps.push(apps);
  }
  if (template.template.payroll) {
    assignedSteps.push(payroll);
  }
  if (template.template.idverify) {
    assignedSteps.push(idverify);
  }
  return /* @__PURE__ */ jsx(RootStyle, { children: /* @__PURE__ */ jsxs(
    Stack,
    {
      sx: {
        position: "absolute",
        background: themeColors.white,
        zIndex: 10,
        inset: 0,
        overflow: "auto"
      },
      children: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: __spreadProps(__spreadValues({}, iconButtonSx), { flex: 0, position: "absolute", top: spacing.g20, right: spacing.g20 }),
            onClick: closeTemplateEditing,
            children: /* @__PURE__ */ jsx(Close, __spreadProps(__spreadValues({}, iconSize), { stroke: themeColors.DarkGrey }))
          }
        ),
        /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", pt: spacing.p30, px: spacing.p30, overflow: "hidden" }, children: [
          /* @__PURE__ */ jsx(
            ActionSelectionGroupComponent,
            {
              title: polyglot.t("OnboardingTemplateEdit.addSteps"),
              actions: unassignedSteps,
              emptyActionsMessage: polyglot.t("OnboardingTemplateEdit.addStepsMex")
            }
          ),
          /* @__PURE__ */ jsxs(Stack, { sx: { p: spacing.p30, borderRadius: radius.br15, maxWidth: "380px", flex: 1, mr: "auto" }, children: [
            /* @__PURE__ */ jsx(
              EditableTitle,
              {
                variant: "headline1",
                sx: { marginTop: "-10px" },
                value: template.name,
                onChange: (value) => {
                  setTemplate(__spreadProps(__spreadValues({}, template), { name: value }));
                },
                maxLength: 50,
                editButtonTooltip: mode === "first-template" && !renameHintDismissed ? {
                  open: true,
                  title: /* @__PURE__ */ jsxs(Stack, { sx: { alignItems: "flex-start", maxWidth: "200px" }, children: [
                    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.white, m: spacing.m10 }), children: polyglot.t("OnboardingTemplateEdit.rename") }),
                    /* @__PURE__ */ jsx(
                      Button,
                      {
                        onClick: () => setRenameHintDismissed(true),
                        sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey, textTransform: "none" }),
                        children: polyglot.t("OnboardingTemplateEdit.okay")
                      }
                    )
                  ] })
                } : void 0
              }
            ),
            /* @__PURE__ */ jsx(
              ActionSelectionItemList,
              {
                actions: [
                  /* @__PURE__ */ jsx(
                    ActionSelectionItem,
                    {
                      step: {
                        Icon: PersonalIcon,
                        title: polyglot.t("OnboardingTemplateEdit.personalInfo"),
                        description: polyglot.t("OnboardingTemplateEdit.personalInfoDesc"),
                        required: true
                      },
                      sx: { py: spacing.py20 }
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    ActionSelectionItem,
                    {
                      step: {
                        Icon: EmploymentIcon,
                        title: polyglot.t("OnboardingTemplateEdit.employmentInfo"),
                        description: polyglot.t("OnboardingTemplateEdit.employmentInfoDesc"),
                        required: true
                      },
                      sx: { py: spacing.py20 }
                    }
                  ),
                  ...assignedSteps
                ]
              }
            ),
            /* @__PURE__ */ jsxs(
              ButtonComponent,
              {
                fullWidth: true,
                type: "button",
                onClick: () => __async(void 0, null, function* () {
                  try {
                    yield OnboardingAPI.updateOnboardingTemplate(template);
                    if (mode === "first-template") {
                      routerHistory.push(ONBOARDING_NEW_USER_ROUTE, { templateId: template.templateId });
                    } else {
                      closeTemplateEditing();
                    }
                  } catch (error) {
                    showMessage(
                      polyglot.t("OnboardingTemplateEdit.errorMessages.save", { errorMessage: nestErrorMessage(error) }),
                      "error"
                    );
                  }
                }),
                colorVariant: "primary",
                sizeVariant: "large",
                style: { flexShrink: 0, marginTop: "20px" },
                children: [
                  " ",
                  mode === "first-template" ? polyglot.t("General.continue") : polyglot.t("General.save")
                ]
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ jsx(DrawerModal, { isOpen: isDrawerOpen, setIsOpen: setDrawerOpen, children: drawerView != null ? drawerView : /* @__PURE__ */ jsx(Fragment, {}) })
      ]
    }
  ) });
};
