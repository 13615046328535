"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { RichTextField } from "@/v2/components/forms/rich-text/rich-text-field.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getSentimentColor } from "@/v2/feature/growth/growth-scale/growth-scale.interface";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ScaleQuestionReadonly = ({
  questionText,
  scaleConfig,
  hasComment,
  isCommentRequired
}) => {
  if (!questionText) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g24 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", dangerouslySetInnerHTML: { __html: questionText != null ? questionText : "" } }),
    /* @__PURE__ */ jsx(RatingScale, { scaleConfig }),
    hasComment && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column",
          gap: spacing.g8,
          pt: spacing.p16,
          borderTop: `${borders.background}`
        },
        children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Add comment" }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: isCommentRequired ? "Required" : "Optional" })
          ] }),
          /* @__PURE__ */ jsx(RichTextField, { value: null, disabled: true })
        ]
      }
    )
  ] });
};
const RatingScale = ({ scaleConfig }) => {
  const [scaleKey, selectedScaleKey] = useState(null);
  return /* @__PURE__ */ jsxs("div", { style: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
    scaleConfig && Object.entries(scaleConfig.value).length > 5 && (scaleConfig == null ? void 0 : scaleConfig.points) && /* @__PURE__ */ jsx(Box, { sx: { visibility: scaleKey ? "visible" : "hidden", minHeight: "20px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: scaleKey ? scaleConfig.points[scaleKey] : "" }) }),
    /* @__PURE__ */ jsx(Box, { style: { display: "flex", flexGrow: 1, gap: spacing.g4 }, children: (scaleConfig == null ? void 0 : scaleConfig.value) && Object.entries(scaleConfig.value).map(([key, value]) => {
      var _a;
      return /* @__PURE__ */ jsxs(
        Box,
        {
          sx: { display: "flex", flexDirection: "column", gap: spacing.s2, width: "100%", boxSizing: "border-box" },
          children: [
            /* @__PURE__ */ jsx(
              Box,
              {
                style: {
                  flex: 1,
                  minHeight: "44px",
                  maxHeight: "44px",
                  height: "44px",
                  display: "flex",
                  width: "100%",
                  cursor: "pointer",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                  backgroundColor: scaleKey === key ? (_a = getSentimentColor()[scaleConfig.sentiments[key]]) != null ? _a : themeColors.DarkGrey : themeColors.Background,
                  borderRadius: radius.br8,
                  userSelect: "none"
                },
                onClick: () => {
                  selectedScaleKey(key);
                },
                children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: value })
              },
              key
            ),
            Object.entries(scaleConfig.value).length < 6 && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", textAlign: "center", children: scaleConfig.points[key] })
          ]
        },
        key
      );
    }) })
  ] });
};
