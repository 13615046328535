"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import { OauthClientAPI } from "@/api-client/oauth-client.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const DeveloperHubDeleteClientConfirmDrawer = ({
  oauthApp,
  isOpen,
  setIsOpen,
  onDelete
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showMessage] = useMessage();
  const deleteOauthClient = useCallback(() => __async(void 0, null, function* () {
    try {
      setIsDeleting(true);
      yield OauthClientAPI.deleteSpecificClient(oauthApp.clientId);
      showMessage(`${polyglot.t("DeveloperHub.successMessages.delete")}`, "success");
      setIsOpen(false);
      onDelete();
      routerHistory.push(SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE);
    } catch (error) {
      showMessage(
        `${polyglot.t("DeveloperHubClientGenerateDrawer.errorMessages.delete")}: ${nestErrorMessage(error)}`,
        "error"
      );
      setIsDeleting(false);
    }
  }), [oauthApp.clientId, showMessage, polyglot, setIsOpen, onDelete, routerHistory]);
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("DeveloperHub.clientTable.deleteConfirm") }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          sizeVariant: "medium",
          colorVariant: "secondary",
          onClick: () => setIsOpen(false),
          disabled: isDeleting,
          fullWidth: true,
          children: polyglot.t("General.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.delete"),
          loading: isDeleting,
          onClick: deleteOauthClient,
          colorVariant: "danger",
          sizeVariant: "medium",
          fullWidth: true
        }
      )
    ] })
  ] }) });
};
