"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CompanyDepartmentAPI } from "@/api-client/company-department.api";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
const departmentFormSchema = (polyglot) => {
  Yup.object().shape({
    name: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, polyglot.t("validation.avoidSpecialCharacter")).required(polyglot.t("validation.requiredField"))
  });
};
export const DepartmentFormDrawer = ({
  isOpen,
  setIsOpen,
  selectedDepartment,
  refreshDepartments,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(
    DepartmentFormDrawerContent,
    {
      selectedDepartment,
      refreshDepartments,
      closeFormDrawer: onClose
    }
  ) });
};
const DepartmentFormDrawerContent = ({
  selectedDepartment,
  refreshDepartments,
  closeFormDrawer
}) => {
  var _a, _b;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const formik = useFormik({
    initialValues: { name: (_a = selectedDepartment == null ? void 0 : selectedDepartment.name) != null ? _a : "" },
    validationSchema: departmentFormSchema(polyglot),
    onSubmit: (values) => __async(void 0, null, function* () {
      const action = selectedDepartment ? "update" : "add";
      try {
        setIsSubmitting(true);
        if (selectedDepartment && selectedDepartment.id) {
          yield CompanyDepartmentAPI.updateCompanyDepartment(selectedDepartment.id, {
            name: values.name
          });
        } else {
          yield CompanyDepartmentAPI.addCompanyDepartment({ name: values.name });
        }
        showMessage(polyglot.t(`DepartmentFormDrawer.successMessages.${action}`), "success");
        refreshDepartments();
        closeFormDrawer();
      } catch (error) {
        showMessage(
          polyglot.t(`DepartmentFormDrawer.errorMessages.${action}`, {
            nestError: nestErrorMessage(error)
          }),
          "error"
        );
      } finally {
        setIsSubmitting(false);
      }
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: selectedDepartment ? polyglot.t("DepartmentFormDrawer.edit") : polyglot.t("DepartmentFormDrawer.new") }) }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("DepartmentFormDrawer.departmentNameLabel"),
        value: formik.values.name,
        type: "text",
        onChange: (e) => {
          const updatedValue = e.currentTarget.value;
          formik.setFieldValue("name", updatedValue);
        },
        error: formik.touched.name && !!formik.errors.name,
        helperText: (_b = formik.touched.name && formik.errors.name) != null ? _b : " ",
        clearText: () => formik.setFieldValue("name", "")
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        fullWidth: true,
        type: "submit",
        loading: isSubmitting,
        colorVariant: "primary",
        sizeVariant: "medium",
        disabled: isSubmitting || Object.keys(formik.errors).length > 0,
        name: polyglot.t("General.save")
      }
    ) })
  ] }) });
};
