"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { IconButton, Stack, Typography, Box } from "@mui/material";
import {
  RuleTypeOption
} from "@v2/feature/attendance/subfeatures/attendance-schedule-rule/attendance-schedule-rule.interface";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/table.styles";
import { useDebouncedCallback } from "use-debounce";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Delete } from "@/images/fields/Trash.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as ArrowUp } from "@/images/side-bar-icons/ArrowUpSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SwitchComponent } from "@/v2/components/forms/switch.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { AttendanceEndpoints } from "@/v2/feature/attendance/attendance.api";
import { AttendanceScheduleRuleDrawer } from "@/v2/feature/attendance/settings/components/attendance-schedule-rule-drawer.component";
import { AttendanceScheduleRuleAPI } from "@/v2/feature/attendance/subfeatures/attendance-schedule-rule/attendance-schedule-rule.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
const AttendanceScheduleRuleActionsCell = ({
  rule,
  setSelectedRule,
  handleDelete,
  moveRule,
  index,
  rulesOrderLength
}) => {
  return /* @__PURE__ */ jsxs(Stack, { className: "rule-actions-cell", flexDirection: "row", justifyContent: "flex-end", gap: "5px", whiteSpace: "nowrap", children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        className: "visible-on-hover",
        sx: __spreadValues({}, tableIconButtonSx),
        onClick: () => moveRule(index, "up"),
        disabled: index <= 1,
        children: /* @__PURE__ */ jsx(Box, { sx: { alignItems: "center", paddingTop: "4px", paddingRight: "4px" }, children: /* @__PURE__ */ jsx(ArrowUp, __spreadValues({}, iconSize)) })
      }
    ),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        className: "visible-on-hover",
        sx: __spreadValues({}, tableIconButtonSx),
        onClick: () => moveRule(index, "down"),
        disabled: index >= rulesOrderLength - 1,
        children: /* @__PURE__ */ jsx(Box, { sx: { alignItems: "center", paddingTop: "4px", paddingRight: "4px" }, children: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)) })
      }
    ),
    /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => setSelectedRule(rule), children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)) }),
    /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: () => handleDelete(rule.id), children: /* @__PURE__ */ jsx(Delete, __spreadValues({}, iconSize)) })
  ] });
};
const CustomTableRow = styled("tr")(({ hideHover = false }) => ({
  // borderBottom: borders.background,
  backgroundColor: themeColors.white,
  ".show-on-hover": { display: "none" },
  ":hover": {
    backgroundColor: hideHover ? "none" : themeColors.TableHover,
    ".show-on-hover": { display: "block" }
  },
  "& .rule-actions-cell .visible-on-hover": {
    visibility: "hidden"
  },
  "&:hover .rule-actions-cell .visible-on-hover, &:hover + .MuiTableRow-root .rule-actions-cell .visible-on-hover": {
    visibility: "visible"
  }
}));
export const AttendanceSettingsRuleSection = ({
  schedule
  // refreshSchedule,
}) => {
  const { polyglot } = usePolyglot();
  const [selectedRule, setSelectedRule] = useState(null);
  const [showMessage] = useMessage();
  const ruleTypeLabelMap = {
    [RuleTypeOption.Weekly]: polyglot.t("AttendanceSchedule.scheduleRules.ruleType.weekly"),
    [RuleTypeOption.Daily]: polyglot.t("AttendanceSchedule.scheduleRules.ruleType.daily"),
    [RuleTypeOption.DailyLength]: polyglot.t("AttendanceSchedule.scheduleRules.ruleType.dailyLength"),
    [RuleTypeOption.BreakBetweenDays]: polyglot.t("AttendanceSchedule.scheduleRules.ruleType.breakBetweenDays")
  };
  const ruleOptions = [
    {
      label: ruleTypeLabelMap[RuleTypeOption.Weekly],
      handler: () => {
        setCreateRuleType(RuleTypeOption.Weekly);
      }
    },
    {
      label: ruleTypeLabelMap[RuleTypeOption.Daily],
      handler: () => {
        setCreateRuleType(RuleTypeOption.Daily);
      }
    },
    {
      label: ruleTypeLabelMap[RuleTypeOption.DailyLength],
      handler: () => {
        setCreateRuleType(RuleTypeOption.DailyLength);
      }
    },
    {
      label: ruleTypeLabelMap[RuleTypeOption.BreakBetweenDays],
      handler: () => {
        setCreateRuleType(RuleTypeOption.BreakBetweenDays);
      }
    }
  ];
  const baseHourRule = useMemo(
    () => ({
      priority: 0,
      name: "Hours not covered by rules",
      payMultiplier: {
        id: 0,
        name: "Job multiplier",
        value: 0
      },
      stopOnMatch: false,
      ruleType: "daily",
      payMultiplierId: 0,
      units: 0,
      useMinimumContractedHoursForUnits: false,
      id: -1,
      companyId: 0,
      attendanceScheduleId: 0
    }),
    []
  );
  const { data: rules, mutate: refreshRules } = useApiClient(
    AttendanceScheduleRuleAPI.getAllRulesForScheduleEndpoint(schedule.id),
    {
      suspense: false
    }
  );
  const {
    data: attendancePayMultipliers
    // mutate: refreshPayMultipliers,
    // isLoading: isLoadingMultipliers,
  } = useApiClient(AttendanceEndpoints.getCompanyAttendancePayMultipliers(), {
    suspense: false
  });
  const [createRuleType, setCreateRuleType] = useState(null);
  const refreshScheduleRules = useCallback(() => __async(void 0, null, function* () {
    if (refreshRules) yield refreshRules();
  }), [refreshRules]);
  const handleDelete = useCallback(
    (ruleId) => __async(void 0, null, function* () {
      try {
        yield AttendanceScheduleRuleAPI.deleteRule(schedule.id, ruleId);
        refreshScheduleRules();
        showMessage(polyglot.t("AttendanceSchedule.scheduleRules.successfulMessages.ruleDeleted"), "success");
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [refreshScheduleRules, showMessage, schedule.id, polyglot]
  );
  const columns = [
    {
      header: polyglot.t("AttendanceSchedule.scheduleRules.priority"),
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => {
        return original.id === -1 ? /* @__PURE__ */ jsx(EmptyCell, {}) : /* @__PURE__ */ jsx(Typography, { variant: "inherit", children: original.priority });
      },
      id: "priority"
    },
    {
      header: polyglot.t("AttendanceSchedule.scheduleRules.rule"),
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => {
        return /* @__PURE__ */ jsx(Typography, { variant: "inherit", children: original.name });
      },
      id: "name"
    },
    {
      header: polyglot.t("AttendanceSchedule.scheduleRules.type"),
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => {
        return original.id === -1 ? /* @__PURE__ */ jsx(EmptyCell, {}) : /* @__PURE__ */ jsx(Typography, { variant: "inherit", children: ruleTypeLabelMap[original.ruleType] });
      },
      id: "ruleType"
    },
    {
      header: polyglot.t("AttendanceSchedule.scheduleRules.multiplier"),
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => {
        return /* @__PURE__ */ jsx(Typography, { variant: "inherit", children: original.payMultiplier.name });
      },
      id: "payMultiplier"
    },
    {
      header: polyglot.t("AttendanceSchedule.scheduleRules.stopOnMatch"),
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => {
        return original.id === -1 ? /* @__PURE__ */ jsx(EmptyCell, {}) : /* @__PURE__ */ jsx(Stack, { children: /* @__PURE__ */ jsx(
          SwitchComponent,
          {
            checked: original.stopOnMatch,
            onClick: (e) => __async(void 0, null, function* () {
              e.stopPropagation();
              try {
                yield AttendanceScheduleRuleAPI.toggleStopOnMatch(schedule.id, original.id, !original.stopOnMatch);
                refreshScheduleRules();
              } catch (error) {
                console.error(error);
              }
            })
          }
        ) });
      },
      id: "stopOnMatch"
    },
    {
      header: "",
      accessorFn: (row) => row,
      id: "actions",
      cell: ({ row: { original }, row: { index } }) => {
        if (original.id === -1) return;
        return /* @__PURE__ */ jsx(
          AttendanceScheduleRuleActionsCell,
          {
            rule: original,
            setSelectedRule,
            handleDelete,
            moveRule,
            index,
            rulesOrderLength: rulesOrder.length
          }
        );
      }
    }
  ];
  const [rulesOrder, setRulesOrder] = useState([baseHourRule, ...rules != null ? rules : []]);
  useEffect(() => {
    setRulesOrder([baseHourRule, ...rules != null ? rules : []]);
  }, [rules, baseHourRule]);
  const debouncedUpdateRuleOrder = useDebouncedCallback((ruleIds) => __async(void 0, null, function* () {
    try {
      yield AttendanceScheduleRuleAPI.updateRuleOrder(schedule.id, ruleIds);
      refreshScheduleRules();
    } catch (error) {
      console.error(error);
    }
  }), 1500);
  const moveRule = (index, direction) => {
    setRulesOrder((prevRules) => {
      const newRules = [...prevRules];
      const startIndex = 1;
      const targetIndex = direction === "up" ? index - 1 : index + 1;
      if (index < startIndex || targetIndex < startIndex || targetIndex >= newRules.length) return newRules;
      [newRules[index], newRules[targetIndex]] = [newRules[targetIndex], newRules[index]];
      debouncedUpdateRuleOrder.callback(newRules.slice(startIndex).map((rule) => rule.id));
      return newRules;
    });
  };
  return /* @__PURE__ */ jsxs(ContentWrapper, { children: [
    /* @__PURE__ */ jsx(
      SettingsSectionContent,
      {
        title: polyglot.t("AttendanceSchedule.rules"),
        headerWidth: "100%",
        contentWidth: "100%",
        buttons: [
          /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: ruleOptions,
              actionButtonDetails: {
                type: "button",
                colorVariant: "primary",
                sizeVariant: "small",
                title: polyglot.t("AttendanceSchedule.newRule"),
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end"
              }
            }
          )
        ],
        children: /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: rulesOrder.map((rule, index) => __spreadProps(__spreadValues({}, rule), { priority: index })),
            columnData: columns,
            loading: false,
            initialSort: [],
            customRowStyle: (row) => row.index === 0 ? {
              borderBottom: `1px solid ${themeColors.middleGrey}`,
              cursor: "default"
            } : {
              // cursor: 'default',
            },
            hidePagination: true,
            customTableRow: CustomTableRow,
            rowClick: (row) => {
              if (row.original.id !== -1) setSelectedRule(row.original);
            }
          }
        )
      }
    ),
    selectedRule && /* @__PURE__ */ jsx(
      AttendanceScheduleRuleDrawer,
      {
        isOpen: !!selectedRule,
        setIsOpen: (isOpen) => setSelectedRule(isOpen ? selectedRule : null),
        rule: selectedRule,
        attendancePayMultipliers: attendancePayMultipliers != null ? attendancePayMultipliers : [],
        scheduleId: schedule.id,
        refreshRules: refreshScheduleRules,
        polyglot
      }
    ),
    /* @__PURE__ */ jsx(
      AttendanceScheduleRuleDrawer,
      {
        isOpen: !!createRuleType,
        setIsOpen: (isOpen) => setCreateRuleType(isOpen ? createRuleType : null),
        attendancePayMultipliers: attendancePayMultipliers != null ? attendancePayMultipliers : [],
        createRuleType: createRuleType != null ? createRuleType : void 0,
        scheduleId: schedule.id,
        refreshRules: refreshScheduleRules,
        polyglot
      }
    )
  ] });
};
