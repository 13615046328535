"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteScopesControl } from "@/component/widgets/Scopes";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import {
  ONBOARDING_INTRO_ROUTE,
  ONBOARDING_NEW_USER_ROUTE,
  ONBOARDING_ROUTE,
  ONBOARDING_USER_STATUS_APPS_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE,
  ONBOARDING_USER_STATUS_DEVICE_ROUTE,
  ONBOARDING_USER_STATUS_ROUTE
} from "@/lib/routes";
import { AdminOnboardingFieldsProvider } from "@/v2/feature/onboarding/context/admin-onboarding-fields.context";
import { OnboardingAppsPage } from "@/v2/feature/onboarding/onboarding-apps/onboarding-apps.page";
import { OnboardingDeviceInventoryPage } from "@/v2/feature/onboarding/onboarding-device/onboarding-device-inventory.page";
import { OnboardingDeviceOrderPage } from "@/v2/feature/onboarding/onboarding-device-order.page";
import { OnboardingIntro } from "@/v2/feature/onboarding/onboarding-intro.page";
import { OnboardingUserStatusPage } from "@/v2/feature/onboarding/onboarding-user-status.page";
import { OnboardingUserTable } from "@/v2/feature/onboarding/onboarding-user-table.page";
import { OnboardingUserSetup } from "@/v2/feature/onboarding/user-setup/onboarding-user-setup.page";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
export const OnboardingRouter = () => {
  const [templates, setTemplates] = useState();
  const [showMessage] = useMessage();
  const refresh = useCallback(() => __async(void 0, null, function* () {
    try {
      const [templates2] = yield Promise.all([OnboardingAPI.getOnboardingTemplates()]);
      setTemplates(templates2);
    } catch (error) {
      showMessage(`Onboarding failed to load. ${nestErrorMessage(error)}`, "error");
    }
  }), [showMessage]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  if (!templates) {
    return /* @__PURE__ */ jsx(OnboardingIntro, { loading: true });
  }
  return /* @__PURE__ */ jsx(AdminOnboardingFieldsProvider, { children: /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { path: ONBOARDING_NEW_USER_ROUTE, component: OnboardingUserSetup }),
    /* @__PURE__ */ jsx(Route, { path: ONBOARDING_USER_STATUS_APPS_ROUTE, component: OnboardingAppsPage }),
    /* @__PURE__ */ jsx(Route, { path: ONBOARDING_USER_STATUS_DEVICE_ORDER_ROUTE, component: OnboardingDeviceOrderPage }),
    /* @__PURE__ */ jsx(Route, { path: ONBOARDING_USER_STATUS_DEVICE_ROUTE, component: OnboardingDeviceInventoryPage }),
    /* @__PURE__ */ jsx(Route, { path: ONBOARDING_USER_STATUS_ROUTE, component: OnboardingUserStatusPage }),
    /* @__PURE__ */ jsxs(RouteScopesControl, { scopes: ["user.onboard:all"], path: ONBOARDING_INTRO_ROUTE, children: [
      templates.length > 0 && /* @__PURE__ */ jsx(Redirect, { to: ONBOARDING_ROUTE }),
      templates.length === 0 && /* @__PURE__ */ jsx(OnboardingIntro, { refresh })
    ] }),
    /* @__PURE__ */ jsxs(RouteScopesControl, { scopes: ["user.onboard:all"], path: ONBOARDING_ROUTE, children: [
      templates.length > 0 && /* @__PURE__ */ jsx(OnboardingUserTable, {}),
      templates.length === 0 && /* @__PURE__ */ jsx(Redirect, { to: ONBOARDING_INTRO_ROUTE })
    ] })
  ] }) });
};
