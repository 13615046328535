"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ReactComponent as CommentIcon } from "@/images/reviews/comment.svg";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getAnalyticsByType } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { QuestionType, QuestionTypeMap } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SummaryDisplayBar = ({
  question,
  growthFactors,
  onClick,
  showBorder,
  sx = {},
  hidePerQuestionScore = false
}) => {
  var _a, _b;
  if (!question) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      onClick,
      sx,
      showBorder,
      cells: [
        {
          content: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.g8 }, children: [
            question && question.questionText && /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: question.questionText }),
            /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
              question.factor && /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "middle",
                  name: /* @__PURE__ */ jsxs(
                    Box,
                    {
                      sx: {
                        display: "flex",
                        alignItems: "center",
                        gap: spacing.g4
                      },
                      children: [
                        /* @__PURE__ */ jsx(
                          "div",
                          {
                            style: {
                              backgroundColor: (_b = (_a = growthFactors[question.factor]) == null ? void 0 : _a.color) != null ? _b : themeColors.Grey,
                              height: "8px",
                              width: "8px",
                              borderRadius: radius.br25
                            }
                          }
                        ),
                        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: question.factor })
                      ]
                    }
                  )
                }
              ),
              question.type === QuestionType.ScaleQuestion && question.scaleConfig ? /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "middle",
                  name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: question.scaleConfig.type })
                }
              ) : /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "middle",
                  name: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: QuestionTypeMap[question.type] })
                }
              ),
              question.type && question.type !== QuestionType.OpenEnded && question.hasComment && /* @__PURE__ */ jsx(
                ChipComponent,
                {
                  backgroundColor: "white",
                  border: "middle",
                  name: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.sm }, children: [
                    /* @__PURE__ */ jsx(CommentIcon, __spreadValues({}, iconSize)),
                    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: question.commentsCount })
                  ] })
                }
              )
            ] })
          ] }),
          gridXs: hidePerQuestionScore ? 11 : 8
        },
        ...hidePerQuestionScore ? [] : [
          {
            content: getAnalyticsByType(question),
            gridXs: 3,
            sx: { display: "flex", justifyContent: "flex-start" }
          }
        ],
        {
          content: /* @__PURE__ */ jsx(Fragment, {}),
          gridXs: 1
        }
      ]
    }
  );
};
