"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { useHistory } from "react-router-dom";
import { TemplateAPI, TemplateEndpoints } from "@/api-client/templates.api";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from "@/lib/routes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
export const SelectTemplateForContractModal = ({
  setOpen,
  open,
  setSelectedTemplate,
  onClose
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen: open, setIsOpen: setOpen, onClose, children: /* @__PURE__ */ jsx(SelectTemplateForContractContent, { setSelectedTemplate }) });
};
const SelectTemplateForContractContent = ({
  setSelectedTemplate
}) => {
  const { data: templateOptions } = useApiClient(TemplateEndpoints.getTemplateOptions(), { suspense: false });
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  const scopesContext = { userId: user.userId };
  const [loadingTemplate, setLoadingTemplate] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const useSmartTemplateForDocument = () => __async(void 0, null, function* () {
    const matchingTemplate = (templateOptions || []).find((t) => t.value === selectedTemplateId);
    if (!matchingTemplate || !selectedTemplateId) {
      return;
    }
    setLoadingTemplate(true);
    const contractTemplate = yield TemplateAPI.getTemplateById({ templateId: selectedTemplateId });
    setLoadingTemplate(false);
    setSelectedTemplate(contractTemplate);
  });
  return /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("ContractModal.template") }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ContractModal.documentFromSmartTemplate") }),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "selectedTemplateId",
        label: polyglot.t("ContractModal.templateSelection"),
        options: templateOptions != null ? templateOptions : [],
        fullWidth: true,
        value: (templateOptions != null ? templateOptions : []).find(({ value }) => value === selectedTemplateId) || null,
        compareValue: selectedTemplateId,
        onChange: (_, x) => {
          setSelectedTemplateId(x ? x.value : void 0);
        },
        editList: {
          handler: () => routerHistory.push(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE),
          isHidden: !hasScopes(["templates", "documents:all"], scopesContext)
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsxs(
      ButtonComponent,
      {
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary",
        disabled: !selectedTemplateId,
        onClick: useSmartTemplateForDocument,
        loading: loadingTemplate,
        children: [
          polyglot.t("ContractModal.useTemplateForDocument"),
          " "
        ]
      }
    ) })
  ] });
};
