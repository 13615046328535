"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { getIconForAppStatus } from "../../../app-integration-detailed-personal.router";
import useMessage from "@/hooks/notification.hook";
import cover from "@/images/app-empty.svg";
import { ReactComponent as Reload } from "@/images/side-bar-icons/Reload.svg";
import { EmptyStateComponent } from "@/v2/components/empty-state.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { AppIntegrationEndpoints } from "@/v2/feature/app-integration/app-integration.api";
import {
  externalUserHasDelayedAppAction,
  getTooltipTitleForExternalScheduledStatus
} from "@/v2/feature/app-integration/app-integration.util";
import { AppDetailsEndpoints } from "@/v2/feature/app-integration/features/app-details/app-details.api";
import { AppDetailConfigurationModal } from "@/v2/feature/app-integration/features/app-details/components/app-detail-configuration-modal.component";
import { AppDetailsActionButton } from "@/v2/feature/app-integration/features/app-details/components/app-details-action-button.component";
import { AppDetailsTable } from "@/v2/feature/app-integration/features/app-details/components/app-details-table.component";
import { AppNameHeader } from "@/v2/feature/app-integration/features/app-details/components/app-name-header.component";
import { AppActionsDrawer } from "@/v2/feature/app-integration/features/app-details/sections/app-actions-drawer/app-actions-drawer.section";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { OnboardNewEmployee } from "@/v2/feature/onboarding/components/onboard-new-employee.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { secondarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const CandidateManagementTabFilter = [
  { name: "All", value: "all" },
  { name: "Hired", value: "Hired" },
  { name: "Onboarded in Zelt", value: "Onboarded in Zelt" }
];
export const AppCandidateManagementPage = ({
  candidateList,
  importedCandidateList,
  appStub,
  app,
  loading,
  error,
  onAppChange,
  hasAppsAllOrAppOwner,
  refreshCandidates
}) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("all");
  const [filteredCandidateList, setFilteredCandidateList] = useState([]);
  const [isAppActionsDrawerOpen, setIsAppActionsDrawerOpen] = useState(false);
  const [selectedActiveUserLogin, setSelectedActiveUserLogin] = useState(void 0);
  const [preselectedUser, setPreselectedUser] = useState(void 0);
  const [actionsDrawerMode, setActionsDrawerMode] = useState(void 0);
  const [onboardUserDrawerOpen, setOnboardUserDrawerOpen] = useState(false);
  const { data: candidateOnboardingTemplate } = useApiClient(AppDetailsEndpoints.getOnboardingConfiguration(appStub), {
    suspense: false
  });
  const { data: delayedActions, mutate: refreshDelayedActions } = useApiClient(
    AppIntegrationEndpoints.getDelayedAppActionsByStub(appStub),
    {
      suspense: false
    }
  );
  const hiredAndImportedCandidates = useMemo(() => {
    const listToAdd = candidateList != null ? candidateList : [];
    return listToAdd.concat(importedCandidateList);
  }, [candidateList, importedCandidateList]);
  useEffect(() => {
    setFilteredCandidateList(
      searchInput.length === 0 ? hiredAndImportedCandidates : hiredAndImportedCandidates.filter(
        (eachUser) => {
          var _a, _b, _c;
          return ((_a = eachUser == null ? void 0 : eachUser.name) == null ? void 0 : _a.fullName) && ((_c = (_b = eachUser == null ? void 0 : eachUser.name) == null ? void 0 : _b.fullName) == null ? void 0 : _c.toLowerCase().includes(searchInput.toLowerCase()));
        }
      )
    );
  }, [filterString, searchInput, hiredAndImportedCandidates]);
  useEffect(() => {
    setFilteredCandidateList(
      filterString === "all" ? hiredAndImportedCandidates : hiredAndImportedCandidates.filter((eachUser) => eachUser.userStatus === filterString)
    );
  }, [filterString, hiredAndImportedCandidates]);
  const candidateColumn = useMemo(
    () => [
      {
        header: () => "Candidate Name",
        id: "fullName",
        accessorFn: (row) => {
          var _a;
          return (_a = row.name) == null ? void 0 : _a.fullName;
        },
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a;
          return /* @__PURE__ */ jsx(
            Typography,
            {
              sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                display: "flex",
                alignItems: "center",
                gap: spacing.m5,
                color: themeColors.DarkGrey
              }),
              children: (_a = original.name && original.name.fullName) != null ? _a : "N/A"
            }
          );
        }
      },
      {
        header: () => "Status",
        id: "userStatus",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          StyledTooltip,
          {
            title: getTooltipTitleForExternalScheduledStatus(
              delayedActions,
              original.primaryEmail,
              original.id ? String(original.id) : ""
            ),
            children: /* @__PURE__ */ jsx(Box, { sx: { py: 0.2, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsxs(
              Typography,
              {
                sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), {
                  display: "flex",
                  alignItems: "center",
                  gap: spacing.m5,
                  color: themeColors.DarkGrey
                }),
                children: [
                  original.userStatus && !externalUserHasDelayedAppAction(
                    delayedActions,
                    original.primaryEmail,
                    original.id ? String(original.id) : "0"
                  ) ? getIconForAppStatus(polyglot, original.userStatus) : "",
                  original.userStatus && delayedActions && externalUserHasDelayedAppAction(
                    delayedActions,
                    original.primaryEmail,
                    original.id ? String(original.id) : "0"
                  ) ? getIconForAppStatus(polyglot, "Scheduled") : ""
                ]
              }
            ) })
          }
        )
      },
      {
        header: () => "",
        id: "action",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(
          AppDetailsActionButton,
          {
            setIsAppActionsDrawerOpen,
            setSelectedActiveUserLogin,
            setGroupManagementDrawerOpen: () => {
            },
            setGroupManagementDrawerMode: () => {
            },
            refresh: onAppChange,
            appStub,
            user: original,
            delayedActions: delayedActions != null ? delayedActions : { delayed: [] },
            refreshDelayedActions,
            table: "candidates",
            hasAppsAllOrAppOwner,
            setPreselectedUser,
            setActionsDrawerMode,
            error,
            setOnboardUserDrawerOpen
          }
        )
      }
    ],
    [delayedActions, polyglot, onAppChange, appStub, refreshDelayedActions, hasAppsAllOrAppOwner, error]
  );
  const mapToUserForOnboarding = (user) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const nameParts = (_c = (_b = (_a = user == null ? void 0 : user.name) == null ? void 0 : _a.fullName) == null ? void 0 : _b.split(" ")) == null ? void 0 : _c.filter(Boolean);
    if (!nameParts || (nameParts == null ? void 0 : nameParts.length) === 0) {
      showMessage(`Unable to import user without name details. Please update candidate and try again`, "error");
    }
    return {
      userId: 0,
      firstName: nameParts ? nameParts[0] : "",
      lastName: nameParts ? nameParts[1] : "",
      emailAddress: "",
      personalEmail: (_d = user.primaryEmail) != null ? _d : "",
      jobTitle: (_e = user == null ? void 0 : user.jobTitle) != null ? _e : "",
      startDate: (_f = user == null ? void 0 : user.startDate) != null ? _f : void 0,
      phone: (_g = user == null ? void 0 : user.phone) != null ? _g : "",
      templateId: candidateOnboardingTemplate && (candidateOnboardingTemplate == null ? void 0 : candidateOnboardingTemplate.selected) ? candidateOnboardingTemplate.selected : void 0
    };
  };
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(AppNameHeader, { title: polyglot.t("AppCandidateManagementPage.title"), app }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "left"
          },
          children: !error && /* @__PURE__ */ jsxs(Fragment, { children: [
            /* @__PURE__ */ jsx(
              TabFilterButtons,
              {
                filters: CandidateManagementTabFilter,
                setFilterValue: setFilterString,
                filterValue: filterString,
                hasSearch: true,
                onFilterChange: ({ searchInput: searchInput2 }) => {
                  setSearchInput(searchInput2);
                }
              }
            ),
            hasAppsAllOrAppOwner && /* @__PURE__ */ jsx(
              Button,
              {
                sx: secondarySmallBtn,
                startIcon: /* @__PURE__ */ jsx(Reload, __spreadValues({}, iconSize)),
                onClick: () => refreshCandidates == null ? void 0 : refreshCandidates(),
                disabled: loading,
                children: "Refresh"
              }
            )
          ] })
        }
      ),
      /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
        /* @__PURE__ */ jsx(
          AppDetailsTable,
          {
            column: candidateColumn,
            row: [...filteredCandidateList],
            loading,
            hidePagination: true
          }
        ),
        error && !(app == null ? void 0 : app.authorised) && /* @__PURE__ */ jsx(
          EmptyStateComponent,
          {
            header: "Authorize this app",
            subheader: "Zelt allows you to manage your whole toolstack from one place.",
            detail: "To start using your app connect your account in Settings first.",
            cover
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        AppDetailConfigurationModal,
        {
          open: showModal,
          handleClose: () => setShowModal(false),
          app,
          appStub,
          refresh: () => onAppChange(appStub)
        }
      ),
      /* @__PURE__ */ jsx(
        OnboardNewEmployee,
        {
          isOpen: onboardUserDrawerOpen,
          setIsOpen: setOnboardUserDrawerOpen,
          initialValues: preselectedUser ? mapToUserForOnboarding(preselectedUser) : {
            userId: 0
          },
          onDraftUserCreated: () => setOnboardUserDrawerOpen(false),
          mode: "import",
          appStub,
          externalId: preselectedUser ? String(preselectedUser.id) : void 0,
          onUserCreated: () => {
            refreshCandidates == null ? void 0 : refreshCandidates();
          }
        }
      ),
      /* @__PURE__ */ jsx(
        AppActionsDrawer,
        {
          appStub,
          usersWithoutAccess: candidateList ? [...candidateList] : [],
          usersWithAccess: candidateList ? [...candidateList] : [],
          isOpen: isAppActionsDrawerOpen,
          onClose: () => setIsAppActionsDrawerOpen(false),
          refreshApp: () => onAppChange(appStub),
          refreshDelayedActions,
          setIsAppActionsDrawerOpen,
          preselectedUser,
          mode: actionsDrawerMode,
          selectedActiveUserLogin,
          setSelectedActiveUserLogin,
          directoryMode: "external"
        }
      )
    ] })
  ] });
};
