"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/system";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { DocumentNewAPI } from "@/v2/feature/document-new/api-client/document-new.api";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
export const NewDocumentDeleteModal = ({
  isOpen,
  setIsOpen,
  onClose,
  selectionModel,
  refresh
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(NewDocumentDeleteContent, { selectionModel, refresh, onClose }) });
};
const NewDocumentDeleteContent = ({
  refresh,
  selectionModel,
  onClose
}) => {
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const handleBulkDelete = useCallback(
    (idsToDelete) => __async(void 0, null, function* () {
      setLoading(true);
      try {
        yield DocumentNewAPI.bulkDelete(idsToDelete);
        showMessage("Successfully delete all selected", "success");
        refresh();
      } catch (error) {
        showMessage(nestErrorMessage(error, polyglot), "error");
      } finally {
        setLoading(false);
      }
    }),
    [showMessage, polyglot, refresh]
  );
  return /* @__PURE__ */ jsxs(Box, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Delete documents" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Are you sure you want to delete all the selected documents?" }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: onClose, fullWidth: true, children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          name: polyglot.t("General.delete"),
          loading,
          fullWidth: true,
          onClick: () => handleBulkDelete(selectionModel)
        }
      )
    ] })
  ] });
};
