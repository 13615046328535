"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import { EditableTitle } from "@v2/components/forms/editable-title.component";
import { ColumnsDrawer } from "@v2/components/table/columns-drawer.component";
import { FiltersDrawer } from "@v2/components/table/filters-drawer.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ResultTableOldReports } from "@v2/feature/reports/components/result-table.component";
import { ReportsAPI, ReportsEndpoints } from "@v2/feature/reports/reports.api";
import { ReportEntity } from "@v2/feature/reports/reports.interface";
import {
  getAllColumnsOptionsPeopleCurrentReports,
  getAllUsersCurrentRecordsEntitiesNames,
  getOnlyAllowedColumnsStringsPeopleCurrentReports,
  getPeopleReportFilters
} from "@v2/feature/reports/util/people-report.util";
import { exportCurrentReportCSV, extractAllColumnsFromColumnsConfig } from "@v2/feature/reports/util/report.util";
import { useApiClient } from "@v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { primarySmallBtn, secondarySmallBtn } from "@v2/styles/buttons.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { RootStyle } from "@v2/styles/root.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as Export } from "@/images/side-bar-icons/Export.svg";
import { nestErrorMessage } from "@/lib/errors";
import { REPORT_COMPANY_REPORTS_OVERVIEW } from "@/lib/routes";
export const PeopleCurrentUpdateReport = ({
  report,
  loading,
  customFields,
  additionalColumns
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const { data: peopleFiltersOptions } = useApiClient(ReportsEndpoints.getPeopleReportsFiltersOptions(), {
    suspense: false
  });
  const { hasScopes } = useScopes();
  const [filteredData, setFilteredData] = useState(void 0);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState(
    void 0
  );
  const [filterTypes, setFilterTypes] = useState({});
  const [filterString, setFilterString] = useState((_b = (_a = report == null ? void 0 : report.config) == null ? void 0 : _a.filters) != null ? _b : "");
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [reportLoading, setReportsLoading] = useState(true);
  const [reportName, setReportName] = useState((_c = report == null ? void 0 : report.name) != null ? _c : "");
  const [searchInput, setSearchInput] = useState("");
  const [selectedColumnsStrings, setSelectedColumnsStrings] = useState([]);
  const [allTheOtherDataIsLoaded, setAllTheOtherDataIsLoaded] = useState(false);
  const allCurrentReportEntities = useMemo(() => getAllUsersCurrentRecordsEntitiesNames(hasScopes), [hasScopes]);
  const ColumnsOptions = useMemo(() => {
    var _a2, _b2;
    const entities = (_b2 = (_a2 = report.config) == null ? void 0 : _a2.entities) != null ? _b2 : allCurrentReportEntities;
    return getAllColumnsOptionsPeopleCurrentReports(entities, customFields, hasScopes, additionalColumns, polyglot);
  }, [(_d = report.config) == null ? void 0 : _d.entities, allCurrentReportEntities, customFields, hasScopes, additionalColumns, polyglot]);
  useEffect(() => {
    var _a2;
    const options = ((_a2 = report.config) == null ? void 0 : _a2.columns) ? extractAllColumnsFromColumnsConfig(report.config.columns) : getOnlyAllowedColumnsStringsPeopleCurrentReports(
      allCurrentReportEntities,
      customFields,
      hasScopes,
      additionalColumns,
      polyglot
    );
    if (!Array.isArray(options)) {
      const arrayOptions = Object.keys(options).reduce((res, key) => {
        var _a3;
        res.push((_a3 = options[key]) != null ? _a3 : []);
        return res;
      }, []);
      setSelectedColumnsStrings(arrayOptions);
      return;
    }
    setSelectedColumnsStrings(options);
    setAllTheOtherDataIsLoaded(true);
  }, [polyglot, additionalColumns, (_e = report.config) == null ? void 0 : _e.columns, customFields, hasScopes, allCurrentReportEntities]);
  useEffect(() => {
    if (peopleFiltersOptions) {
      const filters = getPeopleReportFilters(peopleFiltersOptions, polyglot);
      setFilterTypes(filters);
    }
  }, [peopleFiltersOptions, polyglot]);
  const getReportData = useCallback(
    (formData, selectedColumns, filterString2) => __async(void 0, null, function* () {
      setReportsLoading(true);
      try {
        let filteredUsers = yield ReportsAPI.generatePeopleCreatedCurrentReport(__spreadProps(__spreadValues({}, formData.config), {
          filters: filterString2,
          columns: { [ReportEntity.User]: selectedColumns }
        }));
        setFilteredData(filteredUsers != null ? filteredUsers : []);
      } catch (error) {
        showMessage(
          `${polyglot.t("PeopleCurrentUpdateReport.errorMessages.generate")}. ${nestErrorMessage(error)}`,
          "error"
        );
      }
      setReportsLoading(false);
    }),
    [polyglot, showMessage]
  );
  useEffect(() => {
    if (report && allTheOtherDataIsLoaded) getReportData(report, selectedColumnsStrings, filterString);
  }, [getReportData, report, selectedColumnsStrings, filterString, allTheOtherDataIsLoaded]);
  useEffect(() => {
    setFilteredAndSearchedData(
      filteredData == null ? void 0 : filteredData.filter(
        (data) => !searchInput || data.employeeName && String(data.employeeName).toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  }, [searchInput, filteredData]);
  const handleSave = useCallback(() => __async(void 0, null, function* () {
    try {
      if (report) {
        const paramObj = {
          filters: filterString,
          columns: { [ReportEntity.User]: selectedColumnsStrings },
          fileName: reportName != null ? reportName : report.name
        };
        yield ReportsAPI.patchReport(report.id, paramObj);
      }
      showMessage(polyglot.t("PeopleCurrentUpdateReport.successMessages.update"), "success");
      routerHistory.push(REPORT_COMPANY_REPORTS_OVERVIEW);
    } catch (error) {
      showMessage(nestErrorMessage(error), "error");
    }
  }), [polyglot, report, reportName, showMessage, routerHistory, filterString, selectedColumnsStrings]);
  const exportCsv = useCallback(() => {
    try {
      exportCurrentReportCSV(reportName, filteredData);
    } catch (e) {
      showMessage(polyglot.t("PeopleCurrentUpdateReport.errorMessages.download"), "error");
    }
  }, [polyglot, reportName, filteredData, showMessage]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        showBack: true,
        title: reportName ? /* @__PURE__ */ jsx(
          EditableTitle,
          {
            variant: "headline1",
            value: reportName,
            onChange: (value) => {
              setReportName(value);
            },
            maxLength: 50,
            editButtonTooltip: void 0
          }
        ) : /* @__PURE__ */ jsx(Fragment, {}),
        showAction: true,
        actions: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
          /* @__PURE__ */ jsx(Button, { fullWidth: true, sx: secondarySmallBtn, startIcon: /* @__PURE__ */ jsx(Export, __spreadValues({}, iconSize)), onClick: exportCsv, children: polyglot.t("PeopleCurrentUpdateReport.downloadCsv") }),
          /* @__PURE__ */ jsx(Button, { sx: primarySmallBtn, onClick: handleSave, children: polyglot.t("General.save") })
        ] })
      }
    ),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading, sx: { pt: 0 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m20, display: "flex", alignItems: "center", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(
          ColumnsDrawer,
          {
            columnsOptions: ColumnsOptions,
            selectedColumns: selectedColumnsStrings,
            setSelectedColumns: setSelectedColumnsStrings
          }
        ),
        /* @__PURE__ */ jsx(
          FiltersDrawer,
          {
            filtersOptions: [{ filters: filterTypes }],
            selectedFilters: filterString,
            setSelectedFilters: setFilterString,
            encodedFilterNames: true
          }
        ),
        /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => {
          var _a2, _b2;
          return setSearchInput((_b2 = (_a2 = e.target.value) == null ? void 0 : _a2.trim()) != null ? _b2 : "");
        } })
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m20 }, children: /* @__PURE__ */ jsx(ResultTableOldReports, { filteredData: filteredAndSearchedData != null ? filteredAndSearchedData : [], loading: reportLoading }) })
    ] })
  ] });
};
