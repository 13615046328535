"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/system";
import { Typography } from "@v2/components/typography/typography.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { DrawerViewerItem } from "@/v2/feature/absence/components/drawer-viewer-item.component";
import { UserAvatar } from "@/v2/feature/user/components/user-avatar.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { spacing } from "@/v2/styles/spacing.styles";
export const AppAccessDetailDrawer = ({
  isOpen,
  setIsOpen,
  appUserDetail,
  appName,
  appStatusValue,
  scheduledActionDate,
  appActionButtons
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    AppAccessDetailDrawerContent,
    {
      appUserDetail,
      appName,
      appStatusValue,
      scheduledActionDate,
      appActionButtons
    }
  ) });
};
export const AppAccessDetailDrawerContent = ({
  appUserDetail,
  appName,
  appStatusValue,
  scheduledActionDate,
  appActionButtons
}) => {
  var _a;
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();
  const cachedUser = appUserDetail && appUserDetail.userId ? getCachedUserById(+appUserDetail.userId) : void 0;
  let emailForDisplay = (_a = appUserDetail == null ? void 0 : appUserDetail.emails) == null ? void 0 : _a.find(
    (eachEmail) => eachEmail.matched || eachEmail.assigned || eachEmail.primary
  );
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: appName }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({ display: "flex", flexDirection: "column" }, spacing.gap20), { marginTop: spacing.m30 }), children: [
      appUserDetail && /* @__PURE__ */ jsx(
        DrawerViewerItem,
        {
          title: polyglot.t("AppAccessDetailsDrawer.employee"),
          value: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
            appUserDetail.userId && /* @__PURE__ */ jsx(UserAvatar, { userId: +appUserDetail.userId, size: "s20" }),
            /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t(
              cachedUser ? cachedUser.displayName || `${cachedUser.firstName} ${cachedUser.lastName} ` : `${appUserDetail.displayName}`
            ) })
          ] })
        }
      ),
      emailForDisplay && /* @__PURE__ */ jsx(
        DrawerViewerItem,
        {
          title: polyglot.t("AppAccessDetailsDrawer.login"),
          value: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: emailForDisplay == null ? void 0 : emailForDisplay.email }) })
        }
      ),
      appStatusValue && /* @__PURE__ */ jsx(
        DrawerViewerItem,
        {
          title: polyglot.t("AppAccessDetailsDrawer.status"),
          value: /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: scheduledActionDate != null ? scheduledActionDate : appStatusValue }) })
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          mt: spacing.mt20,
          transition: "all 0.3s ease",
          transitionProperty: "margin-top"
        },
        children: appActionButtons
      }
    )
  ] });
};
