"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { HolidayCalendarAPI } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
const validationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("validation.requiredField"))
});
export const HolidayCalendarGeneralDrawer = ({ isOpen, setIsOpen, holidayCalendar, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(
        HolidayCalendarGeneralDrawerContent,
        {
          holidayCalendar,
          refresh,
          setIsOpen
        }
      )
    }
  ) });
};
const HolidayCalendarGeneralDrawerContent = ({ holidayCalendar, refresh, setIsOpen }) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const updateCalendar = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const data = { name: values.name };
        yield HolidayCalendarAPI.updateHolidayCalendarGeneral(holidayCalendar.id, data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setLoading(false);
    }),
    [showMessage, polyglot, refresh, holidayCalendar, setIsOpen]
  );
  const formik = useFormik({
    initialValues: {
      name: holidayCalendar.name
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: updateCalendar
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("HolidayCalendarModule.newCalendar") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("General.name"),
        name: "name",
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && Boolean(formik.errors.name),
        helperText: formik.touched.name && formik.errors.name,
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
