"use strict";
export var NotificationTypes = /* @__PURE__ */ ((NotificationTypes2) => {
  NotificationTypes2["newJoiner"] = "newJoiner";
  NotificationTypes2["workAnniversary"] = "workAnniversary";
  NotificationTypes2["lastDay"] = "lastDay";
  NotificationTypes2["birthday"] = "birthday";
  return NotificationTypes2;
})(NotificationTypes || {});
export var DomainTypes = /* @__PURE__ */ ((DomainTypes2) => {
  DomainTypes2["bankAccount"] = "bankAccount";
  DomainTypes2["probationPeriod"] = "probationPeriod";
  DomainTypes2["attendance"] = "attendance";
  DomainTypes2["documentExpiry"] = "documentExpiry";
  DomainTypes2["documentChanges"] = "documentChanges";
  DomainTypes2["userAddress"] = "userAddress";
  DomainTypes2["securityDigest"] = "securityDigest";
  DomainTypes2["userSecurityDigest"] = "userSecurityDigest";
  return DomainTypes2;
})(DomainTypes || {});
export const getDomainTypesOptions = (polyglot) => {
  return [
    {
      value: "bankAccount" /* bankAccount */,
      label: polyglot.t("DomainTypesOptions.bankAccount")
    },
    {
      value: "attendance" /* attendance */,
      label: polyglot.t("DomainTypesOptions.attendanceRoundup")
    },
    {
      value: "probationPeriod" /* probationPeriod */,
      label: polyglot.t("DomainTypesOptions.probationPeriod")
    },
    {
      value: "documentExpiry" /* documentExpiry */,
      label: polyglot.t("DomainTypesOptions.documentExpiry")
    },
    {
      value: "documentChanges" /* documentChanges */,
      label: polyglot.t("DomainTypesOptions.documentChanges")
    },
    {
      value: "userAddress" /* userAddress */,
      label: polyglot.t("DomainTypesOptions.userAddress")
    },
    {
      value: "securityDigest" /* securityDigest */,
      label: polyglot.t("DomainTypesOptions.securityDigest")
    },
    {
      value: "userSecurityDigest" /* userSecurityDigest */,
      label: polyglot.t("DomainTypesOptions.userSecurityDigest")
    }
  ];
};
export var NotificationSendTo = /* @__PURE__ */ ((NotificationSendTo2) => {
  NotificationSendTo2["ADMIN"] = "admin";
  NotificationSendTo2["MANAGER"] = "manager";
  NotificationSendTo2["SPECIFIC"] = "specific";
  NotificationSendTo2["EVERYONE"] = "everyone";
  return NotificationSendTo2;
})(NotificationSendTo || {});
export var NotificationSendToHumanValue = /* @__PURE__ */ ((NotificationSendToHumanValue2) => {
  NotificationSendToHumanValue2["admin"] = "Admin";
  NotificationSendToHumanValue2["manager"] = "Manager";
  NotificationSendToHumanValue2["specific"] = "Specific users";
  NotificationSendToHumanValue2["everyone"] = "Everyone";
  NotificationSendToHumanValue2["allEmployees"] = "All employees";
  return NotificationSendToHumanValue2;
})(NotificationSendToHumanValue || {});
export var NotificationChannel = /* @__PURE__ */ ((NotificationChannel2) => {
  NotificationChannel2["EMAIL"] = "Email";
  NotificationChannel2["SLACK_CHANNEL"] = "Slack";
  NotificationChannel2["SLACK_BOT"] = "Slack bot";
  NotificationChannel2["NONE"] = "None";
  return NotificationChannel2;
})(NotificationChannel || {});
export var NotificationWhen = /* @__PURE__ */ ((NotificationWhen2) => {
  NotificationWhen2["IMMEDIATE"] = "Immediate";
  NotificationWhen2["BEFORE"] = "Before";
  NotificationWhen2["ONCE"] = "Once";
  NotificationWhen2["DAILY"] = "Daily";
  NotificationWhen2["WEEKLY"] = "Weekly";
  return NotificationWhen2;
})(NotificationWhen || {});
export var NotificationCategory = /* @__PURE__ */ ((NotificationCategory2) => {
  NotificationCategory2["PEOPLE"] = "People";
  NotificationCategory2["DOCUMENTS"] = "Documents";
  NotificationCategory2["EMPLOYEE_INFORMATION"] = "People";
  NotificationCategory2["PROFILE"] = "Profile";
  NotificationCategory2["ATTENDANCE"] = "Attendance";
  NotificationCategory2["DEVICES"] = "Devices";
  return NotificationCategory2;
})(NotificationCategory || {});
export var NotificationHumanName = /* @__PURE__ */ ((NotificationHumanName2) => {
  NotificationHumanName2["lastDay"] = "Last day";
  NotificationHumanName2["birthday"] = "Birthday";
  NotificationHumanName2["newJoiner"] = "New joiner";
  NotificationHumanName2["workAnniversary"] = "Work anniversary";
  NotificationHumanName2["bankAccount"] = "Bank details change";
  NotificationHumanName2["probationPeriod"] = "Probation period ending";
  NotificationHumanName2["attendance"] = "Attendance roundup";
  NotificationHumanName2["documentExpiry"] = "Document expiry";
  NotificationHumanName2["documentChanges"] = "Document changes";
  NotificationHumanName2["userAddress"] = "Address change";
  NotificationHumanName2["contractEnd"] = "Contract end";
  NotificationHumanName2["idCheck"] = "ID Check updates";
  NotificationHumanName2["securityDigest"] = "Security digest";
  NotificationHumanName2["userSecurityDigest"] = "User security digest";
  return NotificationHumanName2;
})(NotificationHumanName || {});
export var NotificationSystemName = /* @__PURE__ */ ((NotificationSystemName2) => {
  NotificationSystemName2["lastDay"] = "lastDay";
  NotificationSystemName2["birthday"] = "birthday";
  NotificationSystemName2["newJoiner"] = "newJoiner";
  NotificationSystemName2["workAnniversary"] = "workAnniversary";
  NotificationSystemName2["bankAccount"] = "bankAccount";
  NotificationSystemName2["probationPeriod"] = "probationPeriod";
  NotificationSystemName2["documentExpiry"] = "documentExpiry";
  NotificationSystemName2["documentChanges"] = "documentChanges";
  NotificationSystemName2["userAddress"] = "userAddress";
  NotificationSystemName2["profileNotification"] = "profileNotification";
  NotificationSystemName2["contractEnd"] = "contractEnd";
  NotificationSystemName2["attendance"] = "attendance";
  NotificationSystemName2["onboarding"] = "onboarding";
  NotificationSystemName2["idCheck"] = "idCheck";
  NotificationSystemName2["securityDigest"] = "securityDigest";
  NotificationSystemName2["userSecurityDigest"] = "userSecurityDigest";
  return NotificationSystemName2;
})(NotificationSystemName || {});
export var WhenFilterEnum = /* @__PURE__ */ ((WhenFilterEnum2) => {
  WhenFilterEnum2["IMMEDIATE"] = "immediate";
  WhenFilterEnum2["BEFORE"] = "before";
  WhenFilterEnum2["AFTER"] = "after";
  return WhenFilterEnum2;
})(WhenFilterEnum || {});
export const WhenFilterOptions = (polyglot) => {
  return [
    { name: polyglot.t("WhenFilterOptions.onDay"), value: "immediate" /* IMMEDIATE */ },
    { name: polyglot.t("WhenFilterOptions.before"), value: "before" /* BEFORE */ },
    { name: polyglot.t("WhenFilterOptions.after"), value: "after" /* AFTER */ }
  ];
};
export const DOMAINS_WITH_BEFORE = [
  "documentExpiry" /* documentExpiry */,
  "probationPeriod" /* probationPeriod */,
  "profileNotification" /* profileNotification */,
  "contractEnd" /* contractEnd */
];
export const DOMAINS_WITH_AFTER = [
  "documentExpiry" /* documentExpiry */,
  "probationPeriod" /* probationPeriod */,
  "profileNotification" /* profileNotification */,
  "contractEnd" /* contractEnd */
];
export const USER_NOTIFICATIONS_LIST = [
  "birthday" /* birthday */,
  "lastDay" /* lastDay */,
  "newJoiner" /* newJoiner */,
  "workAnniversary" /* workAnniversary */
];
export const NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK = [
  ...USER_NOTIFICATIONS_LIST,
  "profileNotification" /* profileNotification */
];
export var CustomNotificationType = /* @__PURE__ */ ((CustomNotificationType2) => {
  CustomNotificationType2["PROFILE_NOTIFICATION"] = "profileNotification";
  return CustomNotificationType2;
})(CustomNotificationType || {});
export const EMPLOYEE_INFO_NOTIFICATIONS = [
  "bankAccount" /* bankAccount */,
  "probationPeriod" /* probationPeriod */,
  "userAddress" /* userAddress */
];
export const NOTIFICATIONS_TO_HIDE_SPECIFIC = [
  "profileNotification" /* profileNotification */,
  "onboarding" /* onboarding */
];
export var DefaultNotifications = /* @__PURE__ */ ((DefaultNotifications2) => {
  DefaultNotifications2["onboardingCompletion"] = "Onboarding completion";
  DefaultNotifications2["onboardingReminder"] = "Onboarding reminder";
  return DefaultNotifications2;
})(DefaultNotifications || {});
export const DOCUMENT_NOTIFICATIONS = ["documentChanges" /* documentChanges */, "documentExpiry" /* documentExpiry */];
export const ATTENDANCE_NOTIFICATIONS = ["attendance" /* attendance */];
export const DEVICE_NOTIFICATIONS = ["securityDigest" /* securityDigest */, "userSecurityDigest" /* userSecurityDigest */];
export var DayOfWeek = /* @__PURE__ */ ((DayOfWeek2) => {
  DayOfWeek2[DayOfWeek2["Sunday"] = 0] = "Sunday";
  DayOfWeek2[DayOfWeek2["Monday"] = 1] = "Monday";
  DayOfWeek2[DayOfWeek2["Tuesday"] = 2] = "Tuesday";
  DayOfWeek2[DayOfWeek2["Wednesday"] = 3] = "Wednesday";
  DayOfWeek2[DayOfWeek2["Thursday"] = 4] = "Thursday";
  DayOfWeek2[DayOfWeek2["Friday"] = 5] = "Friday";
  DayOfWeek2[DayOfWeek2["Saturday"] = 6] = "Saturday";
  return DayOfWeek2;
})(DayOfWeek || {});
export const NumberToDay = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
};
export const DAYS_OF_THE_WEEK_OPTIONS = [
  { label: NumberToDay[0], value: 0 /* Sunday */ },
  { label: NumberToDay[1], value: 1 /* Monday */ },
  { label: NumberToDay[2], value: 2 /* Tuesday */ },
  { label: NumberToDay[3], value: 3 /* Wednesday */ },
  { label: NumberToDay[4], value: 4 /* Thursday */ },
  { label: NumberToDay[5], value: 5 /* Friday */ },
  { label: NumberToDay[6], value: 6 /* Saturday */ }
];
export const REMINDER_SCHEDULE_OPTIONS = [
  { label: "Once" /* ONCE */, value: "Once" /* ONCE */ },
  { label: "Daily" /* DAILY */, value: "Daily" /* DAILY */ },
  { label: "Weekly" /* WEEKLY */, value: "Weekly" /* WEEKLY */ }
];
export const EMPLOYEE_TRIGGER_EVENTS = /* @__PURE__ */ new Set(["dob", "startDate", "leaveDate"]);
