"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SwitchComponent } from "@v2/components/forms/switch.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { HolidayCalendarAPI } from "@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.api";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { iconSize } from "@v2/styles/menu.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
const validationSchema = (polyglot) => yup.object({
  name: yup.string().required(polyglot.t("validation.requiredField")),
  date: yup.string().test(dateFieldTest).required(polyglot.t("validation.requiredField")),
  isRecurring: yup.boolean().typeError(polyglot.t("validation.selectValid")).required(polyglot.t("validation.requiredField"))
});
export const HolidayDrawer = ({ isOpen, setIsOpen, calendarId, holiday, refresh }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4 }
        }
      ),
      children: /* @__PURE__ */ jsx(HolidayDrawerContent, { calendarId, holiday, refresh, setIsOpen })
    }
  ) });
};
const HolidayDrawerContent = ({ calendarId, holiday, refresh, setIsOpen }) => {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const upsertHoliday = useCallback(
    (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        const data = { name: values.name, date: values.date, isRecurring: values.isRecurring };
        if (holiday) yield HolidayCalendarAPI.updateCalendarHoliday(calendarId, holiday.id, data);
        else yield HolidayCalendarAPI.addHolidayToCalendar(calendarId, data);
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
      }
      setLoading(false);
    }),
    [calendarId, showMessage, polyglot, refresh, holiday, setIsOpen]
  );
  const formik = useFormik({
    initialValues: {
      name: (_a = holiday == null ? void 0 : holiday.name) != null ? _a : "",
      date: (_b = holiday == null ? void 0 : holiday.date) != null ? _b : "",
      isRecurring: (_c = holiday == null ? void 0 : holiday.isRecurring) != null ? _c : true
    },
    validationSchema: validationSchema(polyglot),
    onSubmit: upsertHoliday
  });
  const deleteHoliday = useCallback(() => __async(void 0, null, function* () {
    if (!holiday) return;
    try {
      yield HolidayCalendarAPI.deleteCalendarHoliday(calendarId, holiday.id);
      yield refresh();
      setIsOpen(false);
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    }
  }), [showMessage, polyglot, refresh, calendarId, holiday, setIsOpen]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: holiday ? polyglot.t("HolidayCalendarModule.holiday") : polyglot.t("HolidayCalendarModule.newHoliday") }),
      holiday && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(IconButton, { sx: tableIconButtonSx, onClick: deleteHoliday, children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, iconSize)) }) })
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: polyglot.t("HolidayCalendarModule.holidayName"),
        name: "name",
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && Boolean(formik.errors.name),
        helperText: formik.touched.name && formik.errors.name,
        fullWidth: true
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("HolidayCalendarModule.recurringAnnually") }),
      /* @__PURE__ */ jsx(
        SwitchComponent,
        {
          checked: formik.values.isRecurring,
          name: "is-recurring",
          onChange: (_e, enabled) => {
            formik.setFieldValue("isRecurring", enabled);
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: formik.values.isRecurring ? polyglot.t("HolidayCalendarModule.isRecurringDesc") : polyglot.t("HolidayCalendarModule.isNotRecurringDesc") }),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        name: "date",
        label: polyglot.t("General.date"),
        onChange: (value) => {
          formik.setFieldValue("date", value);
        },
        value: formik.values.date,
        error: formik.touched.date && !!formik.errors.date,
        helperText: formik.touched.date && formik.errors.date
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: polyglot.t("General.save"),
        loading,
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "primary"
      }
    ) })
  ] }) });
};
