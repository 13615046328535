"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { groupBy, keyBy } from "lodash";
import { useParams } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { iconSize } from "@/v2/components/forms/editable-title.component";
import { NotFoundBlock } from "@/v2/components/not-found-block.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SettingsSectionContent } from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import { GrowthFactorEndpoints } from "@/v2/feature/growth/growth-factor/growth-factor.api";
import { ReviewCycleAPI } from "@/v2/feature/growth/reviews/api-client/review-cycle.api";
import { ReviewResultEndpoints } from "@/v2/feature/growth/reviews/api-client/review-result.api";
import {
  generateExcel,
  generatePdfReport
} from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-result.util";
import { PublishReviewModal } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/publish-review-modal.component";
import { ResultOverviewDetailed } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/result-overview-detailed.component";
import { ResultOverviewSummary } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/result-overview-summary.component";
import { ResultsOverviewReviewComment } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-review-comment.component";
import { ResultsOverviewReviewScoreTable } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/results-overview-review-score-table.component";
import { UnpublishReviewModal } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/unpublish-review-modal.component";
import { TitleStatusComponent } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import {
  ReminderType
} from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { ResultView } from "@/v2/feature/growth/reviews/interfaces/review-result.interface";
import { GrowthForbiddenAccess } from "@/v2/feature/growth/shared/components/growth-forbidden-access.component";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { ReachType } from "@/v2/interfaces/general.interface";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewCycleDetailResultsOverviewPage = ({
  reviewCycle,
  cycleLoading,
  reachType
}) => {
  var _a, _b;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const [state] = useContext(GlobalContext);
  const params = useParams();
  const { cycleId, revieweeId } = params;
  const { data: results, isLoading: resultsLoading, mutate: refreshResults, error: resultsError } = useApiClient(
    ReviewResultEndpoints.getReviewOverviewResultByReviewee(Number(revieweeId), cycleId),
    {
      suspense: false
    }
  );
  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false
  });
  const growthFactors = useMemo(() => {
    return keyBy(allGrowthFactors, "name");
  }, [allGrowthFactors]);
  const [isSharableOpen, setIsSharableOpen] = useState(false);
  const [unpublishOpen, setUnpublishOpen] = useState(false);
  const [resultView, setResultView] = useState(ResultView.summary);
  const { hasScopes } = useScopes();
  const isAdminOrManager = hasScopes(["reviews:manager"], { userId: Number(revieweeId) });
  const reportName = useMemo(() => `${reviewCycle.displayName}-result`, [reviewCycle.displayName]);
  const TabFilter = useMemo(
    () => [
      { name: polyglot.t("ReviewCycleDetailResultsOverviewPage.summary"), value: ResultView.summary },
      { name: polyglot.t("ReviewCycleDetailResultsOverviewPage.detailed"), value: ResultView.detailed },
      { name: polyglot.t("ReviewCycleDetailResultsOverviewPage.comments"), value: ResultView.comments }
    ],
    [polyglot]
  );
  const questionLookup = useMemo(() => {
    var _a2;
    return keyBy((_a2 = results == null ? void 0 : results.questionResponseSummary) != null ? _a2 : [], "questionId");
  }, [results]);
  const sectionGroupedQuestionLookup = useMemo(() => {
    var _a2;
    return groupBy(
      ((_a2 = results == null ? void 0 : results.questionResponseSummary) != null ? _a2 : []).filter((item) => item.sectionId),
      "sectionId"
    );
  }, [results]);
  const sectionLookup = useMemo(() => {
    var _a2;
    return keyBy((_a2 = results == null ? void 0 : results.sections) != null ? _a2 : [], "id");
  }, [results == null ? void 0 : results.sections]);
  const isPublishable = useMemo(
    () => Boolean(
      reviewCycle && isAdminOrManager && results && (reviewCycle.state === CycleState.Completed || reviewCycle.state === CycleState.Ongoing) && reachType !== ReachType.Me && results.questionResponseSummary.length > 0 && !(results == null ? void 0 : results.isResultShared)
    ),
    [reviewCycle, reachType, isAdminOrManager, results]
  );
  const reviewCompleted = useMemo(
    () => Boolean(results && results.reviewerScoreSummary.every((reviewer) => reviewer.average)),
    [results]
  );
  if (!results && resultsError && resultsError.status === 403) {
    return /* @__PURE__ */ jsx("div", { style: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(GrowthForbiddenAccess, {}) });
  }
  if (!results && !resultsLoading)
    return /* @__PURE__ */ jsx("div", { style: { width: "100%", margin: spacing.s6 }, children: /* @__PURE__ */ jsx(
      NotFoundBlock,
      {
        title: polyglot.t("General.oops"),
        subtitle: polyglot.t("ReviewCycleDetailResultsOverviewPage.notFound")
      }
    ) });
  return /* @__PURE__ */ jsxs(
    SettingsSectionContent,
    {
      id: reportName,
      title: /* @__PURE__ */ jsx(
        TitleStatusComponent,
        {
          reviewCycle,
          reachType,
          published: Boolean(results == null ? void 0 : results.isResultShared)
        }
      ),
      noHorizontalPadding: false,
      topHeaderPaddingSx: { px: spacing.px16 },
      contentWidth: "100%",
      headerWidth: "100%",
      loading: cycleLoading,
      buttons: [
        /* @__PURE__ */ jsx(
          StyledMenuComponent,
          {
            options: [
              ...!(results == null ? void 0 : results.isResultShared) && isAdminOrManager && !reviewCompleted && reviewCycle.state !== CycleState.Completed ? [
                {
                  handler: () => __async(void 0, null, function* () {
                    try {
                      if (results) {
                        const remindBody = {
                          cycleId: results == null ? void 0 : results.cycleId,
                          revieweeIds: [results.revieweeId]
                        };
                        yield ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.All, remindBody);
                        showMessage(
                          `${polyglot.t(
                            "ReviewCycleDetailResultsOverviewPage.successMessages.successfullySentReminder"
                          )}`,
                          "success"
                        );
                      }
                    } catch (error) {
                      showMessage(
                        `${polyglot.t(
                          "ReviewCycleDetailResultsOverviewPage.errorMessages.somethingWentWrong"
                        )}. ${nestErrorMessage(error)}`,
                        "error"
                      );
                    }
                  }),
                  label: polyglot.t("ReviewCycleDetailResultsOverviewPage.remindAll"),
                  disabled: false
                },
                {
                  handler: () => __async(void 0, null, function* () {
                    try {
                      if (results) {
                        const remindBody = {
                          cycleId: results == null ? void 0 : results.cycleId,
                          revieweeIds: [results.revieweeId]
                        };
                        yield ReviewCycleAPI.sendReminderByRevieweeIds(ReminderType.Overdue, remindBody);
                        showMessage(
                          `${polyglot.t(
                            "ReviewCycleDetailResultsOverviewPage.successMessages.successfullySentReminder"
                          )}`,
                          "success"
                        );
                      }
                    } catch (error) {
                      showMessage(
                        `${polyglot.t(
                          "ReviewCycleDetailResultsOverviewPage.errorMessages.somethingWentWrong"
                        )}. ${nestErrorMessage(error)}`,
                        "error"
                      );
                    }
                  }),
                  label: polyglot.t("ReviewCycleDetailResultsOverviewPage.remindOverdue"),
                  disabled: false
                }
              ] : [],
              {
                handler: () => generatePdfReport(results, reviewCycle, reportName, revieweeId, getCachedUserById),
                label: polyglot.t("ReviewCycleDetailResultsOverviewPage.exportPdf")
              },
              {
                handler: () => generateExcel(results, reviewCycle, reportName, revieweeId, getCachedUserById),
                label: polyglot.t("ReviewCycleDetailResultsOverviewPage.exportExcel")
              },
              ...isAdminOrManager && (results == null ? void 0 : results.isResultShared) ? [
                {
                  handler: () => setUnpublishOpen(true),
                  label: polyglot.t("ReviewCycleDetailResultsOverviewPage.unpublish")
                }
              ] : []
            ],
            actionButtonDetails: {
              type: "button",
              colorVariant: "secondary",
              sizeVariant: "small",
              title: "Actions",
              icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
              iconPosition: "end"
            }
          }
        ),
        isPublishable && /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "primary", sizeVariant: "small", onClick: () => setIsSharableOpen(true), children: polyglot.t("ReviewCycleDetailResultsOverviewPage.publish") })
      ],
      children: [
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      ResultsOverviewReviewScoreTable,
                      {
                        results,
                        resultsLoading: Boolean(resultsLoading),
                        reviewScore: results ? results.reviewerScoreSummary : [],
                        showBorder: false
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          SettingsSubsectionContent,
          {
            sections: [
              {
                contentWidth: "100%",
                headerWidth: "100%",
                items: [
                  {
                    type: SectionItemType.Component,
                    value: /* @__PURE__ */ jsx(
                      TabFilterButtons,
                      {
                        filters: TabFilter,
                        setFilterValue: (filterValue) => {
                          setResultView(filterValue);
                        },
                        filterValue: resultView != null ? resultView : ""
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    hidden: resultView !== ResultView.summary,
                    value: Object.keys(questionLookup).length === 0 ? /* @__PURE__ */ jsx(EmptyState, {}) : /* @__PURE__ */ jsx(
                      ResultOverviewSummary,
                      {
                        order: (_a = results == null ? void 0 : results.order) != null ? _a : [],
                        questionLookup,
                        sectionLookup,
                        sectionGroupedQuestionLookup,
                        growthFactors,
                        hidePerQuestionScore: false
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    hidden: resultView !== ResultView.detailed,
                    value: Object.keys(questionLookup).length === 0 ? /* @__PURE__ */ jsx(EmptyState, {}) : /* @__PURE__ */ jsx(
                      ResultOverviewDetailed,
                      {
                        order: (_b = results == null ? void 0 : results.order) != null ? _b : [],
                        questionLookup,
                        sectionLookup,
                        sectionGroupedQuestionLookup,
                        growthFactors,
                        hidePerQuestionScore: false,
                        reviewCycle
                      }
                    )
                  },
                  {
                    type: SectionItemType.Component,
                    hidden: resultView !== ResultView.comments,
                    value: /* @__PURE__ */ jsx(
                      ResultsOverviewReviewComment,
                      {
                        results,
                        refreshResults,
                        loggedInUser: state.user.userId,
                        reachType
                      }
                    )
                  }
                ]
              }
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          PublishReviewModal,
          {
            isOpen: isSharableOpen,
            setIsOpen: setIsSharableOpen,
            onClose: () => {
              setIsSharableOpen(false);
            },
            results,
            refreshResults
          }
        ),
        /* @__PURE__ */ jsx(
          UnpublishReviewModal,
          {
            isOpen: unpublishOpen,
            setIsOpen: setUnpublishOpen,
            onClose: () => {
              setUnpublishOpen(false);
            },
            results,
            refreshResults
          }
        )
      ]
    }
  );
};
const EmptyState = () => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Box, { sx: { paddingTop: spacing.s3 }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: polyglot.t("ReviewCycleDetailResultsOverviewPage.noResultsYet") }) });
};
