"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box } from "@mui/material";
import { ChipComponent } from "@/v2/components/chip/chip.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { SummaryDisplayBar } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle-detail/review-cycle-detail-results/review-cycle-detail-results-overview/component/summary-display-bar.component";
import { getReviewerTypesChips } from "@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util";
import { ReviewerTypes } from "@/v2/feature/growth/reviews/interfaces/review-cycle.interface";
import { QuestionType } from "@/v2/feature/growth/reviews/interfaces/review-question.interface";
import { GridDisplayBarV2 } from "@/v2/feature/growth/shared/components/grid-display-bar.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const DetailedDisplayBlock = ({
  question,
  growthFactors,
  hidePerQuestionScore,
  reviewCycle
}) => {
  if (!question) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", boxSizing: "border-box" }, children: [
    /* @__PURE__ */ jsx(
      SummaryDisplayBar,
      {
        question,
        growthFactors,
        hidePerQuestionScore,
        showBorder: true,
        sx: {
          background: themeColors.TableHover,
          borderBottomLeftRadius: "unset",
          borderBottomRightRadius: "unset",
          borderBottom: 0
        }
      }
    ),
    /* @__PURE__ */ jsx(Box, { children: (question == null ? void 0 : question.answers) && (question == null ? void 0 : question.answers.map((answer) => /* @__PURE__ */ jsx(ReviewAnswerComponent, { answer, question, reviewCycle }))) })
  ] });
};
const ReviewAnswerComponent = ({
  answer,
  question,
  reviewCycle
}) => {
  var _a, _b;
  const scaleLabel = useMemo(() => {
    var _a2, _b2, _c, _d, _e;
    return answer.answer && question ? `${(_b2 = (_a2 = question.scaleConfig) == null ? void 0 : _a2.points) == null ? void 0 : _b2[answer.answer]} (${((_d = (_c = question.scaleConfig) == null ? void 0 : _c.value) == null ? void 0 : _d[answer.answer]) || 0} out of ${Math.max(...Object.values(((_e = question.scaleConfig) == null ? void 0 : _e.value) || []).map(Number))})` : "NA";
  }, [answer.answer, question]);
  const hideVisibility = useMemo(() => {
    if (!question) return false;
    return answer.answerType !== ReviewerTypes.Self && (answer.answerType === ReviewerTypes.Manager && question.visibilitySettings.hideManagerResult || answer.answerType === ReviewerTypes.Upward && question.visibilitySettings.hideUpwardResult || answer.answerType === ReviewerTypes.Peer && question.visibilitySettings.hidePeerResult);
  }, [answer.answerType, question]);
  if (!question) return /* @__PURE__ */ jsx(Fragment, {});
  return /* @__PURE__ */ jsx(
    GridDisplayBarV2,
    {
      showBorder: true,
      cells: [
        {
          content: /* @__PURE__ */ jsxs(
            Box,
            {
              sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.g8, boxSizing: "border-box" },
              children: [
                answer.comment ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", dangerouslySetInnerHTML: { __html: (_a = answer.comment) != null ? _a : "" } }) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "No comment provided" }),
                /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: [
                  /* @__PURE__ */ jsx(
                    ChipComponent,
                    {
                      name: answer.reviewerId ? /* @__PURE__ */ jsx(
                        UserCell,
                        {
                          userId: answer.reviewerId,
                          nameVariant: "paragraph",
                          nameSx: { color: themeColors.DarkGrey }
                        }
                      ) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "Author hidden" }),
                      backgroundColor: "white",
                      border: "background",
                      textColor: "DarkGrey"
                    }
                  ),
                  /* @__PURE__ */ jsx(Box, { children: getReviewerTypesChips(hideVisibility, Boolean(reviewCycle.visibilitySettings.hidePeerAuthor))[answer.answerType] })
                ] })
              ]
            }
          ),
          gridXs: 8
        },
        {
          content: answer.answer ? /* @__PURE__ */ jsx(Fragment, { children: question.type === QuestionType.ScaleQuestion ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: scaleLabel }) : question.type === QuestionType.MultipleAnswer ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: answer.answer.split(",").map((ans) => {
            var _a2;
            return (_a2 = question.answerOptions) == null ? void 0 : _a2[ans];
          }).filter(Boolean).join(", ") }) : question.type === QuestionType.SingleAnswer ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "DarkGrey", children: ((_b = question.answerOptions) == null ? void 0 : _b[answer.answer]) || "NA" }) : /* @__PURE__ */ jsx(Fragment, {}) }) : /* @__PURE__ */ jsx(Fragment, {}),
          gridXs: 4,
          sx: { display: "flex", justifyContent: "flex-start", boxSizing: "border-box" }
        }
      ],
      sx: { borderRadius: "unset", boxSizing: "border-box" }
    }
  );
};
