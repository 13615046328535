"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
const CONFIRMATION = "Add carry over as adjustment to all members";
export const HelperAbsenceCarryOverCalculationDrawer = ({
  isOpen,
  setIsOpen,
  absencePolicy,
  companyId,
  carryOverYear,
  refresh
}) => {
  var _a, _b, _c;
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState("");
  const calculateCarryOver = useCallback(
    (confirmationText) => __async(void 0, null, function* () {
      if (!absencePolicy) return;
      if (confirmationText !== CONFIRMATION) return;
      try {
        setLoading(true);
        yield AbsenceAPI.calculateCompanyPolicyCarryOverByYearAsSuperAdmin(companyId, carryOverYear, absencePolicy.id);
        showMessage("Success", "success");
        yield refresh();
        setIsOpen(false);
      } catch (error) {
        console.error(error);
        showMessage(`Could not calculate carry over. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    }),
    [companyId, carryOverYear, showMessage, refresh, absencePolicy, setIsOpen]
  );
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, afterClose: () => setConfirmation(""), children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Confirm Carry Over Calculation" }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Policy" }),
      /* @__PURE__ */ jsxs(Typography, { variant: "headline3", children: [
        (_b = (_a = absencePolicy == null ? void 0 : absencePolicy.fullName) != null ? _a : absencePolicy == null ? void 0 : absencePolicy.name) != null ? _b : "Absence policy",
        " [Policy ID:",
        " ",
        (_c = absencePolicy == null ? void 0 : absencePolicy.id) != null ? _c : "N/A",
        "]"
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: "Year" }),
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: carryOverYear })
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "You are going to calculate carry over and add adjustments where necessary to all the members of this policy" }),
    /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
      'To continue please type "',
      CONFIRMATION,
      '" in the box bellow'
    ] }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "confirmation",
        label: "Confirmation",
        value: confirmation,
        onChange: (e) => {
          var _a2;
          setConfirmation((_a2 = e.target.value) != null ? _a2 : "");
        },
        error: Boolean(confirmation && confirmation !== CONFIRMATION),
        helperText: confirmation && confirmation !== CONFIRMATION ? "Please confirm action" : ""
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, children: "Cancel" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          loading,
          colorVariant: "danger",
          name: "Calculate Carry Over",
          onClick: () => __async(void 0, null, function* () {
            yield calculateCarryOver(confirmation);
          }),
          fullWidth: true,
          disabled: confirmation !== CONFIRMATION
        }
      )
    ] })
  ] }) });
};
