"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import dayjs from "dayjs";
import locale from "dayjs/locale/en-gb";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import utc from "dayjs/plugin/utc";
import { ReactComponent as BackBlue } from "@/images/calendar/BackCalendar.svg";
import { ReactComponent as NextBlue } from "@/images/calendar/NextCalendar.svg";
import { ReactComponent as ArrowDownACIcon } from "@/images/fields/ArrowDown.svg";
import { ReactComponent as CalendarPicker } from "@/images/fields/Calendar.svg";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { radius } from "@/v2/styles/radius.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { StyledTextfield } from "@/v2/styles/textfield.styles";
dayjs.extend(utc);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.locale("en-gb");
const SHORT_DATE_FORMAT = "YYYY-MM-DD";
const getDayName = (day) => {
  switch (day) {
    case "Su":
      return "Sun";
    case "Mo":
      return "Mon";
    case "Tu":
      return "Tue";
    case "We":
      return "Wed";
    case "Th":
      return "Thu";
    case "Fr":
      return "Fri";
    case "Sa":
      return "Sat";
    default:
      return "";
  }
};
const getDateTabFilter = (polyglot) => {
  return [
    { name: polyglot.t("getDateTabFilter.today"), value: "today" },
    { name: polyglot.t("getDateTabFilter.tomorrow"), value: "tomorrow" },
    { name: polyglot.t("getDateTabFilter.oneWeek"), value: "one-week" }
  ];
};
export const DatePickerComponent = forwardRef(
  ({
    name,
    label,
    requiredLabel,
    value,
    onChange,
    error,
    placeholder,
    helperText,
    inputFormat = "DD/MM/YYYY",
    hiddenLabel,
    views,
    minDate = /* @__PURE__ */ new Date("01/01/2000"),
    maxDate,
    disableFuture = false,
    disablePast = false,
    disableMaskedInput = false,
    shouldDisableDate = void 0,
    required = false,
    disabled,
    shortcuts = false,
    readOnly = false,
    sx,
    returnNullOnChange = false,
    shortcutLabel,
    shortcutLabelSx = __spreadProps(__spreadValues({}, themeFonts.paragraphSmall), { color: themeColors.DarkGrey })
  }, ref) => {
    const { polyglot } = usePolyglot();
    const [filterValue, setFilterValue] = useState("");
    const dateValue = useMemo(
      () => value === null ? null : dayjs(value),
      [value]
    );
    useEffect(() => {
      if (dayjs(dateValue).isToday()) return setFilterValue("today");
      if (dayjs(dateValue).isTomorrow()) return setFilterValue("tomorrow");
      if (dayjs(dateValue).isSame(dayjs().add(7, "day"), "date")) {
        setFilterValue("one-week");
      } else setFilterValue("");
    }, [dateValue]);
    return /* @__PURE__ */ jsxs(Box, { sx, children: [
      shortcuts && /* @__PURE__ */ jsxs(Box, { sx: __spreadProps(__spreadValues({}, spacing.mb20), { display: "flex", flexDirection: "column", gap: spacing.g10 }), children: [
        shortcutLabel && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, shortcutLabelSx), children: shortcutLabel }),
        /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            disabled,
            filters: getDateTabFilter(polyglot),
            setFilterValue,
            filterValue,
            onFilterChange: ({ filterValue: filterValue2 }) => {
              if (filterValue2 === "today") {
                const dateValue2 = dayjs().format(SHORT_DATE_FORMAT);
                onChange(dateValue2);
              }
              if (filterValue2 === "tomorrow") {
                const dateValue2 = dayjs().add(1, "day").format(SHORT_DATE_FORMAT);
                onChange(dateValue2);
              }
              if (filterValue2 === "one-week") {
                const dateValue2 = dayjs().add(7, "day").format(SHORT_DATE_FORMAT);
                onChange(dateValue2);
              }
            }
          }
        )
      ] }),
      /* @__PURE__ */ jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: locale, children: /* @__PURE__ */ jsx(
        DatePicker,
        {
          ref,
          disableMaskedInput,
          views,
          inputFormat,
          value: dateValue,
          showDaysOutsideCurrentMonth: true,
          desktopModeMediaQuery: "@media (pointer: fine)",
          minDate,
          disableFuture,
          disablePast,
          shouldDisableDate,
          maxDate,
          disabled,
          PaperProps: {
            sx: {
              width: "100%",
              borderRadius: radius.br15,
              boxShadow: "0px 2px 20px 0px #0D0D0E1A",
              minWidth: "340px",
              padding: "0px",
              margin: "0px",
              "&.Mui-selected": {
                backgroundColor: themeColors.DarkGrey,
                color: themeColors.Background
              },
              "& .MuiTypography-caption": __spreadProps(__spreadValues({
                width: "32px",
                height: "18px"
              }, themeFonts.headline3), {
                color: themeColors.Grey
              }),
              "& .PrivatePickersSlideTransition-root": {
                justifyContent: "center",
                "& > div": {
                  justifyContent: "space-around",
                  padding: "0px 15px"
                }
              },
              '& .PrivatePickersSlideTransition-root [role="row"]': {
                justifyContent: "space-around",
                "& > div": {
                  justifyContent: "space-around"
                }
              },
              "& .MuiCalendarPicker-viewTransitionContainer": {
                "& > div": {
                  justifyContent: "space-around"
                }
              },
              "& .PrivatePickersFadeTransitionGroup-root": {
                "& > div > div > div": {
                  justifyContent: "space-around",
                  padding: "0px 15px"
                }
              },
              "& .PrivatePickersYear-root": __spreadProps(__spreadValues({}, themeFonts.headline3), {
                color: themeColors.DarkGrey,
                flexBasis: "0%",
                flexWrap: "100%",
                //important
                "& .Mui-selected": {
                  backgroundColor: `${themeColors.DarkGrey} !important`,
                  //important,
                  color: themeColors.Background
                }
              }),
              "& .MuiMonthPicker-root": __spreadProps(__spreadValues({}, themeFonts.headline3), {
                color: themeColors.DarkGrey,
                "& .Mui-selected": {
                  backgroundColor: `${themeColors.DarkGrey} !important`,
                  //important
                  color: themeColors.Background
                }
              }),
              "& button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin:hover": {
                backgroundColor: themeColors.white,
                color: themeColors.Grey
              },
              "& button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today": {
                border: "none !important",
                backgroundColor: themeColors.Background,
                color: themeColors.DarkGrey
              },
              "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button": {
                backgroundColor: themeColors.white,
                color: themeColors.DarkGrey,
                fill: themeColors.DarkGrey,
                "&:hover": {
                  backgroundColor: themeColors.white,
                  color: themeColors.Grey,
                  fill: themeColors.Grey
                },
                "&:after": {
                  backgroundColor: themeColors.white,
                  color: themeColors.Grey,
                  fill: themeColors.Grey
                }
              },
              "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeStart.MuiIconButton-sizeSmall.MuiPickersArrowSwitcher-button": {
                backgroundColor: themeColors.white,
                color: themeColors.DarkGrey,
                fill: themeColors.DarkGrey,
                "&:hover": {
                  backgroundColor: themeColors.white,
                  color: themeColors.Grey,
                  fill: themeColors.Grey
                },
                "&:after": {
                  backgroundColor: themeColors.white,
                  color: themeColors.Grey,
                  fill: themeColors.Grey
                }
              },
              "& div.PrivatePickersFadeTransitionGroup-root": __spreadProps(__spreadValues({}, themeFonts.headline3), {
                color: themeColors.DarkGrey
              })
            }
          },
          dayOfWeekFormatter: (day) => {
            return getDayName(day);
          },
          onChange: (value2) => {
            if (shortcuts) {
              onChange(dayjs(value2).utc(true).format(SHORT_DATE_FORMAT));
              if (dayjs(value2).isToday()) {
                setFilterValue("today");
              } else if (dayjs(value2).isTomorrow()) {
                setFilterValue("tomorrow");
              } else if (dayjs(value2).isSame(dayjs().add(7, "day"), "date")) {
                setFilterValue("one-week");
              } else setFilterValue("");
            } else if (!value2 && returnNullOnChange) onChange("");
            else if (dayjs(value2).isValid()) onChange(dayjs(value2).utc(true).format(SHORT_DATE_FORMAT));
          },
          renderDay: (day, _value, DayComponentProps) => {
            return /* @__PURE__ */ jsx(
              PickersDay,
              __spreadProps(__spreadValues({
                disableRipple: true
              }, DayComponentProps), {
                sx: {
                  "&.Mui-selected": {
                    backgroundColor: themeColors.DarkGrey,
                    color: themeColors.Background
                  },
                  "&.MuiPickersDay-root": __spreadProps(__spreadValues({
                    marginX: spacing.m4,
                    width: "32px",
                    height: "32px",
                    display: "flex"
                  }, themeFonts.headline3), {
                    color: themeColors.DarkGrey,
                    "&.Mui-disabled": {
                      color: themeColors.Grey
                    },
                    "&.Mui-selected": {
                      backgroundColor: themeColors.DarkGrey,
                      color: themeColors.Background
                    }
                  })
                }
              })
            );
          },
          components: {
            OpenPickerIcon: CalendarPicker,
            SwitchViewIcon: ArrowDownACIcon,
            LeftArrowIcon: BackBlue,
            RightArrowIcon: NextBlue
          },
          renderInput: (params) => /* @__PURE__ */ jsx(
            StyledTextfield,
            __spreadProps(__spreadValues({}, params), {
              fullWidth: true,
              variant: "standard",
              size: "small",
              value: dateValue,
              onChange: (e) => {
                if (e.target.value === "") onChange("");
              },
              InputLabelProps: { shrink: true },
              placeholder: placeholder || (typeof label === "string" ? label : void 0),
              name,
              required,
              hiddenLabel,
              label: hiddenLabel ? "" : /* @__PURE__ */ jsx(Fragment, { children: requiredLabel ? `${label} * ` : label }),
              error,
              helperText,
              inputProps: __spreadProps(__spreadValues({}, params.inputProps), {
                readOnly
              }),
              fieldPadding: "0px 9px"
            })
          )
        }
      ) })
    ] });
  }
);
