"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { Suspense, useState } from "react";
import { Box, InputAdornment } from "@mui/material";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@v2/components/theme-components/notification-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { EarningsType } from "@v2/feature/benefits/subfeature/pension/pension.interface";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { PensionAPI } from "@/v2/feature/benefits/subfeature/pension/pension.api";
export const ManageWorkerGroupDrawer = ({
  isOpen,
  setIsOpen,
  pensionSchemeId,
  refresh,
  workerGroup
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
    Suspense,
    {
      fallback: /* @__PURE__ */ jsx(
        SkeletonLoader,
        {
          variant: "rectangular",
          width: "90%",
          height: "90vh",
          sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
        }
      ),
      children: /* @__PURE__ */ jsx(
        ManageWorkerGroupDrawerContent,
        {
          setIsOpen,
          pensionSchemeId,
          refresh,
          workerGroup
        }
      )
    }
  ) });
};
const ManageWorkerGroupDrawerContent = ({
  setIsOpen,
  pensionSchemeId,
  refresh,
  workerGroup
}) => {
  var _a, _b, _c, _d, _e;
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSave, setAnchorElSave] = React.useState(null);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const DefaultUpperLimit = 50270;
  const DefaultLowerLimit = 6240;
  const UnbandedUpperLimit = 1e6;
  const earningsLimitOptions = [
    { value: EarningsType.qualifying, label: EarningsType.qualifying },
    { value: EarningsType.custom, label: EarningsType.custom },
    { value: EarningsType.unbanded, label: EarningsType.unbanded }
  ];
  const formik = useFormik({
    initialValues: {
      name: (_a = workerGroup == null ? void 0 : workerGroup.name) != null ? _a : void 0,
      employerContribution: String((_b = workerGroup == null ? void 0 : workerGroup.employerContribution) != null ? _b : 3),
      employeeContribution: String((_c = workerGroup == null ? void 0 : workerGroup.employeeContribution) != null ? _c : 5),
      customThreshold: workerGroup && (workerGroup.upperLimit !== DefaultUpperLimit || workerGroup.lowerLimit !== DefaultLowerLimit) ? workerGroup.upperLimit === UnbandedUpperLimit ? EarningsType.unbanded : EarningsType.custom : EarningsType.qualifying,
      lowerLimit: (_d = workerGroup == null ? void 0 : workerGroup.lowerLimit) != null ? _d : DefaultLowerLimit,
      upperLimit: (_e = workerGroup == null ? void 0 : workerGroup.upperLimit) != null ? _e : DefaultUpperLimit
    },
    validationSchema: Yup.object({
      name: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      employerContribution: Yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      employeeContribution: Yup.number().typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      customThreshold: Yup.string().required(polyglot.t("ValidationMessages.requiredField")),
      lowerLimit: Yup.number().min(0).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField")),
      upperLimit: Yup.number().min(0).typeError(polyglot.t("ValidationMessages.validValue")).required(polyglot.t("ValidationMessages.requiredField"))
    }),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      if (!values.employeeContribution || !values.employerContribution) {
        showMessage(polyglot.t("PensionModule.contributionsMinimum8Percent"), "error");
        return;
      }
      const employerContribution = Number.parseFloat(values.employerContribution);
      const employeeContribution = Number.parseFloat(values.employeeContribution);
      if (employeeContribution < 0 || employerContribution < 0 || employeeContribution + employerContribution < 8) {
        showMessage(polyglot.t("PensionModule.contributionsMinimum8Percent"), "error");
        return;
      }
      yield workerGroup ? updateWorkerGroup(values) : addWorkerGroup(values);
    })
  });
  const addWorkerGroup = (data) => __async(void 0, null, function* () {
    try {
      if (!data.name) return;
      const employerContribution = Number.parseFloat(data.employerContribution);
      const employeeContribution = Number.parseFloat(data.employeeContribution);
      setLoading(true);
      yield PensionAPI.addWorkerGroup(pensionSchemeId, {
        customThreshold: data.customThreshold !== EarningsType.qualifying,
        name: data.name,
        upperLimit: data.upperLimit,
        lowerLimit: data.lowerLimit,
        employeeContribution,
        employerContribution
      });
      showMessage(polyglot.t("PensionModule.workerGroupAdded"), "success");
      setIsOpen(false);
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoading(false);
    }
  });
  const updateWorkerGroup = (data) => __async(void 0, null, function* () {
    if (!(workerGroup == null ? void 0 : workerGroup.id) || !data.name) return;
    const employerContribution = Number.parseFloat(data.employerContribution);
    const employeeContribution = Number.parseFloat(data.employeeContribution);
    try {
      setLoading(true);
      yield PensionAPI.updateWorkerGroup(pensionSchemeId, workerGroup.id, {
        name: data.name,
        customThreshold: data.customThreshold !== EarningsType.qualifying,
        upperLimit: data.upperLimit,
        lowerLimit: data.lowerLimit,
        employeeContribution,
        employerContribution
      });
      showMessage(polyglot.t("PensionModule.workerGroupUpdated"), "success");
      setIsOpen(false);
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setLoading(false);
    }
  });
  const deleteWorkerGroup = () => __async(void 0, null, function* () {
    if (!(workerGroup == null ? void 0 : workerGroup.id)) return;
    try {
      setIsDeleteLoading(true);
      yield PensionAPI.deleteWorkerGroup(pensionSchemeId, workerGroup.id);
      showMessage(polyglot.t("PensionModule.workerGroupDeleted"), "success");
      setIsOpen(false);
      yield refresh();
    } catch (error) {
      showMessage(polyglot.t("ErrorMessages.somethingWentWrong", { errorMessage: nestErrorMessage(error) }), "error");
    } finally {
      setIsDeleteLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: workerGroup ? polyglot.t("PensionModule.updateWorkerGroup") : polyglot.t("PensionModule.addWorkerGroup") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "name",
        label: polyglot.t("PensionModule.groupName"),
        value: formik.values.name,
        onChange: formik.handleChange,
        error: formik.touched.name && !!formik.errors.name,
        helperText: formik.touched.name && formik.errors.name
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap20 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "employerContribution",
          label: polyglot.t("BenefitModule.employerContribution"),
          value: formik.values.employerContribution,
          onChange: formik.handleChange,
          error: formik.touched.employerContribution && !!formik.errors.employerContribution,
          helperText: formik.touched.employerContribution && formik.errors.employerContribution,
          InputProps: {
            startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: "%" })
          }
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "employeeContribution",
          label: polyglot.t("BenefitModule.employeeContribution"),
          value: formik.values.employeeContribution,
          onChange: formik.handleChange,
          error: formik.touched.employeeContribution && !!formik.errors.employeeContribution,
          helperText: formik.touched.employeeContribution && formik.errors.employeeContribution,
          InputProps: {
            startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: "%" })
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "customThreshold",
        label: polyglot.t("PensionModule.earningLimits"),
        options: earningsLimitOptions,
        value: formik.values.customThreshold,
        compareValue: formik.values.customThreshold,
        onChange: (e) => {
          formik.handleChange(e);
          if (e.target.value === EarningsType.qualifying) {
            formik.setFieldValue("upperLimit", DefaultUpperLimit);
            formik.setFieldValue("lowerLimit", DefaultLowerLimit);
          }
          if (e.target.value === EarningsType.unbanded) {
            formik.setFieldValue("upperLimit", UnbandedUpperLimit);
          }
        },
        error: !!formik.errors.customThreshold && formik.touched.customThreshold,
        helperText: formik.touched.customThreshold && formik.errors.customThreshold
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap20 }, children: [
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "lowerLimit",
          label: polyglot.t("PensionModule.lowerLimit"),
          value: formik.values.lowerLimit,
          onChange: (e) => {
            const value = e.target.value === "" ? "" : !Number.isNaN(e.target.value) ? Number(e.target.value) : void 0;
            if (value !== "" && !value) return;
            formik.setFieldValue("lowerLimit", value);
            if (value !== DefaultUpperLimit) {
              formik.setFieldValue("customThreshold", EarningsType.custom);
            }
          },
          error: formik.touched.lowerLimit && !!formik.errors.lowerLimit,
          helperText: formik.touched.lowerLimit && formik.errors.lowerLimit,
          InputProps: {
            startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: "\xA3" })
          }
        }
      ),
      formik.values.customThreshold !== EarningsType.unbanded && /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "upperLimit",
          label: polyglot.t("PensionModule.upperLimit"),
          value: formik.values.upperLimit,
          onChange: (e) => {
            const value = e.target.value === "" ? "" : !Number.isNaN(e.target.value) ? Number(e.target.value) : void 0;
            if (value !== "" && !value) return;
            formik.setFieldValue("upperLimit", value);
            if (value !== DefaultLowerLimit) {
              formik.setFieldValue("customThreshold", EarningsType.custom);
            }
          },
          error: formik.touched.upperLimit && !!formik.errors.upperLimit,
          helperText: formik.touched.upperLimit && formik.errors.upperLimit,
          InputProps: {
            startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: "\xA3" })
          }
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      workerGroup && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.delete"),
            disabled: loading,
            loading: isDeleteLoading,
            sizeVariant: "medium",
            colorVariant: "secondary",
            color: "error",
            type: "button",
            onClick: (event) => {
              setAnchorEl(event.currentTarget);
            },
            fullWidth: true
          }
        ),
        /* @__PURE__ */ jsx(
          NotificationModal,
          {
            isOpen: Boolean(anchorEl),
            onClose: () => setAnchorEl(null),
            anchorEl,
            takeAction: () => __async(void 0, null, function* () {
              yield deleteWorkerGroup();
              setAnchorEl(null);
            }),
            message: polyglot.t("PensionModule.deleteWorkerGroup"),
            callToAction: polyglot.t("General.yes")
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: workerGroup ? polyglot.t("General.save") : polyglot.t("General.create"),
          type: "button",
          loading,
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true,
          onClick: (event) => {
            setAnchorElSave(event.currentTarget);
          },
          disabled: isDeleteLoading
        }
      ),
      /* @__PURE__ */ jsx(
        NotificationModal,
        {
          isOpen: Boolean(anchorElSave),
          onClose: () => setAnchorElSave(null),
          anchorEl: anchorElSave,
          takeAction: formik.handleSubmit,
          message: workerGroup ? polyglot.t("PensionModule.updateAllEnrolledEmployees") : polyglot.t("PensionModule.createNewWorkerGroup"),
          callToAction: polyglot.t("General.yes")
        }
      )
    ] })
  ] }) });
};
