"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { DatePickerComponent } from "@v2/components/forms/date-picker.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceOwnership, DeviceType } from "@v2/feature/device/device.interface";
import {
  DeviceTypesValueLabelOptions,
  getDeviceOwnerAsSuperadminByDevicePossession
} from "@v2/feature/device/device.util";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { tableIconButtonSx } from "@v2/styles/icon-button.styles";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { actionIconSize } from "@v2/styles/table.styles";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as FullScreen } from "@/images/side-bar-icons/FullScreen.svg";
import { nestErrorMessage } from "@/lib/errors";
import { SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE } from "@/lib/routes";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SuperAdminOverviewDeviceDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  setDevicePossession,
  userNames,
  matchings,
  sites,
  refresh
}) => /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(
  Suspense,
  {
    fallback: /* @__PURE__ */ jsx(
      SkeletonLoader,
      {
        variant: "rectangular",
        width: "90%",
        height: "90vh",
        sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
      }
    ),
    children: /* @__PURE__ */ jsx(
      SuperAdminDeviceOverviewDrawerContent,
      {
        devicePossession,
        refresh,
        setDevicePossession,
        userNames,
        sites,
        matchings
      }
    )
  }
) });
export const SuperAdminDeviceOverviewDrawerContent = ({
  devicePossession,
  setDevicePossession,
  userNames,
  matchings,
  sites,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _A, _B, _C, _D, _E, _F, _G, _H;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [externalId, setExternalId] = useState(matchings[devicePossession.deviceId]);
  const [showMessage] = useMessage();
  const initialValues = {
    serialNumber: (_b = (_a = devicePossession.device) == null ? void 0 : _a.serialNumber) != null ? _b : null,
    internalNotes: (_d = (_c = devicePossession.device) == null ? void 0 : _c.internalNotes) != null ? _d : null,
    price: (_f = (_e = devicePossession.device) == null ? void 0 : _e.price) != null ? _f : null,
    type: (_h = (_g = devicePossession.device) == null ? void 0 : _g.type) != null ? _h : DeviceType.Laptop,
    contractLength: (_j = (_i = devicePossession.device) == null ? void 0 : _i.contractLength) != null ? _j : null,
    contractStartDate: ((_k = devicePossession.device) == null ? void 0 : _k.contractStartDate) ? (_l = devicePossession.device) == null ? void 0 : _l.contractStartDate : null
  };
  const validationSchema = yup.object({
    serialNumber: yup.string().nullable().notRequired(),
    internalNotes: yup.string().nullable().notRequired(),
    price: yup.number().nullable().notRequired(),
    type: yup.string().required("Device type is required"),
    contractLength: yup.number().integer().nullable().notRequired(),
    contractStartDate: yup.string().test(dateFieldTest).nullable().notRequired()
  });
  const patchDeviceEntity = (formData) => __async(void 0, null, function* () {
    try {
      const { serialNumber, internalNotes, customerNotes, price, type, contractLength, contractStartDate } = formData;
      setLoading(true);
      yield DeviceAPI.updateDeviceByIdAsSuperadmin(devicePossession.deviceId, {
        serialNumber,
        internalNotes,
        customerNotes,
        price: Number(price),
        type,
        contractLength: Number(contractLength),
        contractStartDate
      });
      showMessage("Device successfully updated.", "success");
      yield refresh();
    } catch (error) {
      showMessage(`Device could not be updated. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      return patchDeviceEntity(values);
    })
  });
  useEffect(() => {
    formik.validateForm();
  }, []);
  const refreshDeviceCache = () => __async(void 0, null, function* () {
    setRefreshLoading(true);
    try {
      const refreshedDevicePossession = yield DeviceAPI.getRefreshedDevicePossessionDetails(devicePossession.id);
      setDevicePossession(refreshedDevicePossession);
      showMessage("Device Possession refreshed.", "success");
    } catch (error) {
      showMessage("The device could have not been refreshed", "error");
    } finally {
      setRefreshLoading(false);
    }
  });
  const syncDeviceById = () => __async(void 0, null, function* () {
    var _a2, _b2, _c2;
    try {
      const initialExternalId = externalId;
      setSyncLoading(true);
      const response = yield DeviceAPI.syncWithExternalProviderById(devicePossession.id);
      setDevicePossession(response.devicePossession);
      setExternalId((_a2 = response.externalId) != null ? _a2 : null);
      if (response.externalId) showMessage("Device successfully synced.", "success");
      else if (!initialExternalId && !response.externalId)
        showMessage("The local device could not be matched with an external device.", "warning");
      else if (initialExternalId && !response.externalId) {
        showMessage(
          "External matching has been removed as the local device could not be matched with an external device.",
          "info"
        );
      }
    } catch (error) {
      if (((_c2 = (_b2 = error.response) == null ? void 0 : _b2.data) == null ? void 0 : _c2.statusCode) === 404 && error.response.data.message === "External matching not found") {
        showMessage("External matching not found", "error");
        return;
      }
      showMessage("Could not sync device. Something went wrong.", "error");
    } finally {
      setSyncLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Device details" }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          title: "more-details",
          onClick: () => {
            history.push(
              generatePath(SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE, { deviceId: devicePossession.deviceId })
            );
          },
          sx: tableIconButtonSx,
          children: /* @__PURE__ */ jsx(FullScreen, __spreadValues({}, actionIconSize))
        }
      )
    ] }),
    ((_m = devicePossession.device) == null ? void 0 : _m.modelName) && /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: devicePossession.device.modelName }),
    !((_n = devicePossession.device) == null ? void 0 : _n.inHouseMdm) && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "External ID",
        name: "externalId",
        value: externalId != null ? externalId : "N/A",
        size: "small",
        endAdornment: "none",
        disabled: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Ownership",
        name: "ownership",
        value: (_p = (_o = devicePossession.device) == null ? void 0 : _o.ownership.toString()) != null ? _p : "N/A",
        size: "small",
        endAdornment: "none",
        disabled: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Owned by",
        name: "ownedBy",
        value: getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites),
        size: "small",
        endAdornment: "none",
        disabled: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Serial Number",
        name: "serialNumber",
        value: formik.values.serialNumber,
        onChange: formik.handleChange,
        error: formik.touched.serialNumber && Boolean(formik.errors.serialNumber),
        helperText: formik.touched.serialNumber && formik.errors.serialNumber,
        clearText: () => formik.setFieldValue("serialNumber", ""),
        size: "small"
      }
    ),
    /* @__PURE__ */ jsx(
      SelectComponent,
      {
        name: "type",
        label: "Type",
        options: DeviceTypesValueLabelOptions,
        value: (_q = formik.values.type) != null ? _q : void 0,
        compareValue: (_r = formik.values.type) != null ? _r : void 0,
        onChange: formik.handleChange,
        error: formik.touched.type && !!formik.errors.type,
        helperText: formik.touched.type && formik.errors.type
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Internal notes",
        name: "internalNotes",
        value: formik.values.internalNotes,
        onChange: formik.handleChange,
        error: formik.touched.internalNotes && Boolean(formik.errors.internalNotes),
        helperText: formik.touched.internalNotes && formik.errors.internalNotes,
        clearText: () => formik.setFieldValue("internalNotes", ""),
        size: "small"
      }
    ),
    ((_s = devicePossession.device) == null ? void 0 : _s.ownership) === DeviceOwnership.Rental && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Price",
        name: "price",
        value: formik.values.price,
        onChange: formik.handleChange,
        error: formik.touched.price && Boolean(formik.errors.price),
        helperText: formik.touched.price && formik.errors.price,
        clearText: () => formik.setFieldValue("price", ""),
        size: "small"
      }
    ),
    ((_t = devicePossession.device) == null ? void 0 : _t.ownership) === DeviceOwnership.Rental && /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        label: "Contract length",
        name: "contractLength",
        value: formik.values.contractLength,
        onChange: formik.handleChange,
        error: formik.touched.contractLength && Boolean(formik.errors.contractLength),
        helperText: formik.touched.contractLength && formik.errors.contractLength,
        clearText: () => formik.setFieldValue("contractLength", ""),
        size: "small"
      }
    ),
    ((_u = devicePossession.device) == null ? void 0 : _u.ownership) === DeviceOwnership.Rental && /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_v = formik.values.contractStartDate) != null ? _v : null,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("contractStartDate", value);
          }
        },
        name: "contractStartDate",
        label: "Contract start date",
        error: !!formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate),
        helperText: formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate)
      }
    ),
    devicePossession.device && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Device Summary" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: (_x = (_w = devicePossession.device) == null ? void 0 : _w.modelName) != null ? _x : "-" }),
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        (_z = (_y = devicePossession.device) == null ? void 0 : _y.screenSize) != null ? _z : "??",
        " inch"
      ] }),
      /* @__PURE__ */ jsxs(Typography, { variant: "paragraph", children: [
        (_B = (_A = devicePossession.device) == null ? void 0 : _A.ram) != null ? _B : "??",
        "GB Memory"
      ] })
    ] }),
    ((_C = devicePossession.device) == null ? void 0 : _C.policies) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s1 }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: "Applied Policies" }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: devicePossession.device.policies.map((policy) => policy.name).join(", ") })
    ] }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadValues({
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%"
        }, buttonBoxDrawerSx),
        children: [
          ((_D = devicePossession.device) == null ? void 0 : _D.serialNumber) && !((_E = devicePossession.device) == null ? void 0 : _E.inHouseMdm) && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Sync with Hexnode",
              loading: syncLoading,
              onClick: syncDeviceById,
              disabled: !Boolean((_F = devicePossession.device) == null ? void 0 : _F.serialNumber),
              sizeVariant: "medium",
              colorVariant: "secondary",
              fullWidth: true
            }
          ),
          ((_G = devicePossession.device) == null ? void 0 : _G.serialNumber) && !((_H = devicePossession.device) == null ? void 0 : _H.inHouseMdm) && /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Update MDM data",
              loading: refreshLoading,
              onClick: refreshDeviceCache,
              sizeVariant: "medium",
              colorVariant: "secondary",
              fullWidth: true
            }
          ),
          /* @__PURE__ */ jsx(
            LoaderButton,
            {
              name: "Save",
              loading,
              onClick: () => formik.handleSubmit(),
              sizeVariant: "medium",
              colorVariant: "primary",
              fullWidth: true
            }
          )
        ]
      }
    )
  ] }) });
};
