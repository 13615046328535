"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { themeColors } from "@/v2/styles/colors.styles";
export const CircleProgress = (props = {}) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { position: "relative", display: "flex" }, children: [
    /* @__PURE__ */ jsx(
      CircularProgress,
      {
        variant: "determinate",
        value: 100,
        size: props.size,
        sx: {
          color: props.backgroundColor && themeColors[props.backgroundColor]
        }
      }
    ),
    /* @__PURE__ */ jsx(
      CircularProgress,
      {
        variant: "determinate",
        value: props.value,
        size: props.size,
        sx: __spreadValues({
          color: props.color && themeColors[props.color],
          left: 0,
          position: "absolute"
        }, props.sx)
      }
    )
  ] });
};
