"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { TextfieldComponent } from "@v2/components/forms/textfield.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx, fieldSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { YapilyInstitutions } from "@/v2/feature/payments/payments.interface";
const PayerDetailsDrawerContent = ({
  onSubmit,
  setIsOpen,
  institution
}) => {
  var _a, _b, _c, _d;
  const isGoldmanSachs = institution === YapilyInstitutions.GoldmanSachs;
  const validationSchema = isGoldmanSachs ? Yup.object().shape({
    accountName: Yup.string().nullable().matches(/^[0-9a-zA-Z ]*$/, "Please avoid any special character").required("Account Name is required"),
    accountNumber: Yup.string().nullable().matches(/^[0-9a-zA-Z]*$/, "Please avoid using space or any special character").required("Account Number is required"),
    swiftBic: Yup.string().required("SWIFT BIC is required")
  }) : Yup.object().shape({
    accountName: Yup.string().nullable().matches(/^[0-9a-zA-Z ]*$/, "Please avoid any special character").required("Account Name is required"),
    accountNumber: Yup.string().nullable().matches(/^[0-9a-zA-Z]*$/, "Please avoid using space or any special character").required("Account Number is required"),
    sortCode: Yup.string().nullable().matches(/^[0-9-]*$/, "Please use only numeric characters").required("Sort Code is required")
  });
  const formik = useFormik({
    initialValues: {
      accountName: null,
      accountNumber: null,
      sortCode: null,
      swiftBic: null,
      bankName: null
    },
    validationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      var _a2, _b2;
      yield onSubmit({
        accountName: values.accountName,
        accountNumber: values.accountNumber,
        sortCode: isGoldmanSachs ? null : (_b2 = (_a2 = values.sortCode) == null ? void 0 : _a2.replaceAll("-", "")) != null ? _b2 : null,
        swiftBic: isGoldmanSachs ? values.swiftBic : null,
        bankName: null
      });
      setIsOpen(false);
    })
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: isGoldmanSachs ? "Goldman Sachs Account Details" : "Payer Bank Account Details" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Please provide the bank account details that will be used to make the payment" }),
    /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Account Name",
          name: "accountName",
          value: (_a = formik.values.accountName) != null ? _a : "",
          onChange: formik.handleChange,
          error: formik.touched.accountName && Boolean(formik.errors.accountName),
          helperText: formik.touched.accountName && formik.errors.accountName,
          autoFocus: true,
          clearText: () => formik.setFieldValue("accountName", null)
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Account Number",
          name: "accountNumber",
          value: (_b = formik.values.accountNumber) != null ? _b : "",
          onChange: formik.handleChange,
          error: formik.touched.accountNumber && Boolean(formik.errors.accountNumber),
          helperText: formik.touched.accountNumber && formik.errors.accountNumber,
          clearText: () => formik.setFieldValue("accountNumber", null)
        }
      ) }),
      isGoldmanSachs ? /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "SWIFT BIC",
          name: "swiftBic",
          value: (_c = formik.values.swiftBic) != null ? _c : "",
          onChange: formik.handleChange,
          error: formik.touched.swiftBic && Boolean(formik.errors.swiftBic),
          helperText: formik.touched.swiftBic && formik.errors.swiftBic,
          clearText: () => formik.setFieldValue("swiftBic", void 0)
        }
      ) }) : /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: "Sort Code",
          name: "sortCode",
          value: (_d = formik.values.sortCode) != null ? _d : "",
          onChange: formik.handleChange,
          error: formik.touched.sortCode && Boolean(formik.errors.sortCode),
          helperText: formik.touched.sortCode && formik.errors.sortCode,
          clearText: () => formik.setFieldValue("sortCode", null)
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", onClick: () => setIsOpen(false), fullWidth: true, children: "Cancel" }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Continue",
          loading: formik.isSubmitting,
          onClick: () => formik.handleSubmit(),
          type: "button",
          sizeVariant: "medium",
          colorVariant: "primary",
          fullWidth: true
        }
      )
    ] })
  ] }) });
};
export const PayerDetailsDrawer = ({
  isOpen,
  setIsOpen,
  onSubmit,
  institution
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(PayerDetailsDrawerContent, { onSubmit, setIsOpen, institution }) });
};
