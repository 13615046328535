"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Box } from "@mui/material";
import { HiddenColumnSelector } from "@v2/components/table/hidden-column-selector.component";
import { PaymentDetailsDrawer } from "@v2/feature/payments/components/payment-details-drawer.component";
import { PayrollLocalEndpoints } from "@v2/feature/payroll/payroll-local.api";
import { useCachedUsers } from "@v2/feature/user/context/cached-users.context";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as ArrowDown } from "@/images/side-bar-icons/ArrowDownSelect.svg";
import { ReactComponent as Chose } from "@/images/side-bar-icons/Chose.svg";
import { ReactComponent as HMRC } from "@/images/side-bar-icons/hmrc.svg";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as ArrowRight } from "@/images/side-bar-icons/Next.svg";
import { nestErrorMessage } from "@/lib/errors";
import { PAYMENTS_TEAM_SELECT_BANK_ROUTE, USER_PERSONAL_TAB } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { DateLabelComponent } from "@/v2/components/forms/date-label.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { CurrencyCell } from "@/v2/components/table/currency-cell.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { BasicServerTable } from "@/v2/components/table/server-side-table.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { getRequestFromPayment, usePaymentContext } from "@/v2/feature/payments/features/make-payment/payment.context";
import { PaymentForDialogSection } from "@/v2/feature/payments/features/make-payment/sections/payment-form-dialog.section";
import { PaymentEmptyState } from "@/v2/feature/payments/pages/components/payment-empty-state.component";
import { PaymentsAPI, PaymentsEndpoints } from "@/v2/feature/payments/payments.api";
import { PaymentStatus } from "@/v2/feature/payments/payments.dto";
import { EntryTypeForPaymentTable } from "@/v2/feature/payments/payments.interface";
import { PAYMENTS_SELECT_BANK_ROUTE } from "@/v2/feature/payments/payments.router";
import { allPaymentColumns, getPaymentStatus } from "@/v2/feature/payments/payments.util";
import { getContractorInvoiceStatusComponent } from "@/v2/feature/payments/utils/get-contractor-invoice-status.util";
import { SelectDeselectRows } from "@/v2/feature/task/components/task-table/select-deselect-rows.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { usePolyglot } from "@/v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { radius } from "@/v2/styles/radius.styles";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { selectColumnSize } from "@/v2/styles/table.styles";
import { formatMediumDate } from "@/v2/util/date-format.util";
const PaymentTabFilter = (polyglot) => {
  return [
    { name: polyglot.t("PaymentStatusFilter.outstanding"), value: PaymentStatus.Outstanding },
    { name: polyglot.t("PaymentStatusFilter.all"), value: PaymentStatus.All },
    { name: polyglot.t("PaymentStatusFilter.paid"), value: PaymentStatus.Paid }
  ];
};
const GetTooltipText = ({ alertsObj }) => {
  const routerHistory = useHistory();
  let tooltipTitle = [];
  if (alertsObj.bankAccountMissing) {
    tooltipTitle.push("Bank account");
  }
  if (alertsObj.userAddressMissing) {
    tooltipTitle.push("Address");
  }
  if (alertsObj.referenceMissing) {
    tooltipTitle.push("Reference");
  }
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", color: "Grey", sx: { padding: 0, margin: 0 }, children: "Missing info:" }),
    tooltipTitle.map((s, i) => /* @__PURE__ */ jsxs(Typography, { variant: "paragraphSmall", color: "Grey", sx: { padding: 0, margin: 0 }, children: [
      "- ",
      s
    ] }, i)),
    (alertsObj.bankAccountMissing || alertsObj.userAddressMissing) && /* @__PURE__ */ jsxs(
      ButtonComponent,
      {
        sizeVariant: "small",
        colorVariant: "tooltip",
        onClick: () => {
          var _a;
          return routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: (_a = alertsObj == null ? void 0 : alertsObj.userId) != null ? _a : "" }));
        },
        children: [
          "Complete info ",
          /* @__PURE__ */ jsx(ArrowRight, { width: 12, height: 12, style: { fill: themeColors.Grey } })
        ]
      }
    )
  ] });
};
export function PaymentsTransactionsTable({
  isModalOpen,
  setIsModalOpen,
  setLoading,
  setShowEmpty,
  showEmpty,
  setSelectionModel,
  selectionModel,
  mode,
  // should break this up into 3 different tables for expense / payment / invoice, after demo
  reach = "company"
}) {
  var _a, _b, _c;
  const { polyglot } = usePolyglot();
  const [, setState] = usePaymentContext();
  const routerHistory = useHistory();
  const { getCachedUserById } = useCachedUsers();
  const { getScopesContext, hasScopes } = useScopes();
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const currentUserIsAdmin = hasScopes(["payments:all"], getScopesContext(user));
  const [paginationState, setPaginationState] = useState({ pageIndex: 1, pageSize: 100 });
  const [sortingState, setSortingState] = useState([]);
  const [tableState, setTableState] = useState({
    filterValue: PaymentStatus.Outstanding,
    searchInput: ""
  });
  const { filterValue, searchInput } = tableState;
  const { data: payments, mutate: refreshPayments } = useApiClient(
    reach === "company" ? PaymentsEndpoints.findByCompanyId(paginationState, sortingState[0], filterValue, searchInput) : reach === "team" ? PaymentsEndpoints.teamFindByCompanyId(paginationState, sortingState[0], filterValue, searchInput) : { url: "" }
  );
  const { data: payrunPaymentToPayrollMapping } = useApiClient(
    currentUserIsAdmin ? PayrollLocalEndpoints.getPayrunPaymentToPayrollIdMapping() : { url: "" }
  );
  const [showMessage] = useMessage();
  const location = useLocation();
  const [paymentAlerts, setPaymentAlerts] = useState();
  const [selectedPayment, setSelectedPayment] = useState(void 0);
  const [isOpen, setIsOpen] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const updateTableState = useCallback((newState) => {
    setTableState((prevState) => {
      if ("filterValue" in newState || "searchInput" in newState) {
        setPaginationState((p) => __spreadProps(__spreadValues({}, p), { pageIndex: 1 }));
      }
      return __spreadValues(__spreadValues({}, prevState), newState);
    });
  }, []);
  useEffect(() => {
    var _a2, _b2, _c2, _d;
    setHiddenColumns((_d = (_c2 = (_b2 = (_a2 = state.user.features) == null ? void 0 : _a2.payment) == null ? void 0 : _b2.table) == null ? void 0 : _c2.hiddenColumns) != null ? _d : []);
  }, [(_c = (_b = (_a = state.user.features) == null ? void 0 : _a.payment) == null ? void 0 : _b.table) == null ? void 0 : _c.hiddenColumns]);
  const refresh = useCallback(() => __async(this, null, function* () {
    setLoading(true);
    setShowEmpty(true);
    refreshPayments();
    const paymentsAlerts = yield PaymentsAPI.getAlerts(dispatch);
    setPaymentAlerts(paymentsAlerts);
    setLoading(false);
    setShowEmpty(false);
  }), [setLoading, setShowEmpty, refreshPayments, dispatch]);
  useEffect(() => {
    if (refreshPayments && location.pathname) refreshPayments();
  }, [refreshPayments, location.pathname]);
  const filteredPayments = useMemo(() => {
    var _a2, _b2;
    let paymentsWithPayrollData;
    if (!payments || !payments.items) return [];
    let filteredPayments2 = payments.items.map((p) => {
      var _a3;
      return __spreadProps(__spreadValues({}, p), { username: p.userId ? (_a3 = getCachedUserById(p == null ? void 0 : p.userId)) == null ? void 0 : _a3.displayName : "" });
    });
    if (((_a2 = sortingState[0]) == null ? void 0 : _a2.desc) === false) {
      filteredPayments2 = filteredPayments2.reverse();
    }
    paymentsWithPayrollData = (_b2 = filteredPayments2 == null ? void 0 : filteredPayments2.map((p) => {
      var _a3;
      if (payrunPaymentToPayrollMapping && payrunPaymentToPayrollMapping[p.id]) {
        return __spreadProps(__spreadValues({}, p), {
          payroll: (_a3 = payrunPaymentToPayrollMapping[p.id]) == null ? void 0 : _a3.payroll
        });
      }
      return p;
    })) != null ? _b2 : [];
    return paymentsWithPayrollData;
  }, [getCachedUserById, payments, payrunPaymentToPayrollMapping, sortingState]);
  const selectablePaymentsOnPage = useMemo(() => {
    return filteredPayments.filter((payment) => payment.status !== PaymentStatus.Paid);
  }, [filteredPayments]);
  const refreshTableData = useCallback(() => __async(this, null, function* () {
    setLoading(true);
    setShowEmpty(true);
    yield refreshPayments();
    setLoading(false);
    setShowEmpty(false);
  }), [setLoading, setShowEmpty, refreshPayments]);
  const gotoSelectBankRoute = useCallback(() => {
    routerHistory.push(reach === "company" ? PAYMENTS_SELECT_BANK_ROUTE : PAYMENTS_TEAM_SELECT_BANK_ROUTE);
  }, [routerHistory, reach]);
  const columns = useMemo(() => {
    const nonInvoiceActionsColumn = (original) => {
      const alertsObj = paymentAlerts == null ? void 0 : paymentAlerts.entries.find((p) => p.id === original.id);
      return ![PaymentStatus.Paid, PaymentStatus.Processing].includes(original.status) ? /* @__PURE__ */ jsx(
        Box,
        {
          sx: {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: spacing.g5,
            visibility: selectionModel.length < 1 ? "visible" : "hidden"
          },
          children: !(alertsObj == null ? void 0 : alertsObj.bankAccountMissing) && !(alertsObj == null ? void 0 : alertsObj.userAddressMissing) && !(alertsObj == null ? void 0 : alertsObj.referenceMissing) && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => {
                setState(() => {
                  return {
                    payments: [original],
                    requests: [getRequestFromPayment(original)]
                  };
                });
                return gotoSelectBankRoute();
              },
              children: polyglot.t("PaymentListActions.pay")
            }
          )
        }
      ) : /* @__PURE__ */ jsx(Fragment, {});
    };
    const typeColumn = {
      header: () => polyglot.t("PaymentTableHeaders.type"),
      id: "category",
      enableSorting: true,
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: original.category })
    };
    const paymentDateColumn = {
      header: () => polyglot.t("PaymentTableHeaders.paymentDate"),
      id: "createdAt",
      enableSorting: true,
      accessorFn: (row) => row,
      cell: ({ row: { original } }) => {
        var _a2, _b2;
        return ((_a2 = original.transaction) == null ? void 0 : _a2.updatedAt) ? /* @__PURE__ */ jsx(DateLabelComponent, { date: (_b2 = original.transaction) == null ? void 0 : _b2.updatedAt, duration: true }) : /* @__PURE__ */ jsx(EmptyCell, {});
      }
    };
    return [
      {
        id: "select",
        enableSorting: false,
        minSize: selectColumnSize,
        size: selectColumnSize,
        header: () => {
          const selectablePaymentIds = new Set(selectablePaymentsOnPage.filter((p) => p.reference).map(({ id }) => id));
          const allSelected = selectionModel.length > 0 && selectionModel.length === selectablePaymentIds.size && selectionModel.every((id) => selectablePaymentIds.has(id));
          return /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: "allSelected",
              checked: allSelected,
              value: "allSelected",
              onChange: (_, checked) => {
                setSelectionModel(checked ? [...selectablePaymentIds] : []);
              }
            }
          );
        },
        cell: ({ row: { original } }) => {
          var _a2, _b2, _c2, _d;
          return original.status !== PaymentStatus.Paid ? /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: void 0,
              name: (_b2 = (_a2 = original.userId) == null ? void 0 : _a2.toString()) != null ? _b2 : "",
              checked: selectionModel.includes(original.id),
              value: (_d = (_c2 = original.userId) == null ? void 0 : _c2.toString()) != null ? _d : "",
              onChange: () => {
                let finalArray;
                if (selectionModel == null ? void 0 : selectionModel.includes(original.id)) {
                  finalArray = selectionModel.filter((sm) => sm !== original.id);
                } else finalArray = [...selectionModel, original.id];
                setSelectionModel(finalArray);
              },
              disabled: !original.reference
            }
          ) : null;
        }
      },
      {
        header: () => polyglot.t("PaymentTableHeaders.beneficiary"),
        id: "name",
        minSize: 180,
        maxSize: 180,
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const alertsObj = paymentAlerts == null ? void 0 : paymentAlerts.entries.find((p) => p.id === original.id);
          return /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m10, cursor: "pointer" }, children: original.userId ? /* @__PURE__ */ jsx(
            UserCell,
            {
              userId: original.userId,
              endAdornment: alertsObj && (alertsObj.bankAccountMissing || alertsObj.userAddressMissing || alertsObj.referenceMissing) && /* @__PURE__ */ jsx(StyledTooltip, { title: /* @__PURE__ */ jsx(GetTooltipText, { alertsObj }), children: /* @__PURE__ */ jsx(Mistake, __spreadValues({}, iconSize)) })
            }
          ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.m5 }, children: [
            /* @__PURE__ */ jsx(HMRC, { width: 20, height: 20 }),
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "HMRC" })
          ] }) });
        }
      },
      ...mode === "expense" ? [] : [typeColumn],
      {
        header: () => polyglot.t("PaymentTableHeaders.reference"),
        id: "reference",
        maxSize: 200,
        enableSorting: true,
        accessorFn: (row) => row.reference,
        cell: ({ row: { original } }) => original.reference ? /* @__PURE__ */ jsx("div", { children: original.reference }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => mode === "expense" ? polyglot.t("PaymentTableHeaders.date") : polyglot.t("PaymentTableHeaders.dueDate"),
        id: "dueDate",
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const alertsObj = paymentAlerts == null ? void 0 : paymentAlerts.entries.find((p) => p.id === original.id);
          return mode === "expense" && original.dueDate ? formatMediumDate(original.dueDate) : /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: original.dueDate ? /* @__PURE__ */ jsx(
            DateLabelComponent,
            {
              date: original.dueDate,
              duration: true,
              titleSx: __spreadValues({
                color: alertsObj && alertsObj.paymentOverdue && ![PaymentStatus.MarkPaid, PaymentStatus.Paid].includes(original.status) ? themeColors.red : themeColors.DarkGrey
              }, themeFonts.paragraph)
            }
          ) : /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      ...mode === "expense" ? [] : [paymentDateColumn],
      {
        header: () => polyglot.t("PaymentTableHeaders.status"),
        id: "status",
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          let statusComponent = /* @__PURE__ */ jsx(EmptyCell, {});
          if (original.rawExpense && (original == null ? void 0 : original.typeForPaymentTable) === EntryTypeForPaymentTable.EXPENSE) {
            statusComponent = /* @__PURE__ */ jsx("div", { children: getContractorInvoiceStatusComponent(original.rawExpense.status, __spreadValues({}, themeFonts.paragraph)) });
          } else if ((original == null ? void 0 : original.typeForPaymentTable) !== EntryTypeForPaymentTable.INVOICE) {
            statusComponent = /* @__PURE__ */ jsx(
              StyledTooltip,
              {
                title: original.status === PaymentStatus.Failed ? original.message || "" : original.status === PaymentStatus.Unknown ? "Exited the flow or authorisation failed. Check with your bank before retrying or mark as paid." : "",
                children: getPaymentStatus(polyglot, original, __spreadValues({}, themeFonts.paragraph))
              }
            );
          } else if (original.rawContractorInvoice && (original == null ? void 0 : original.typeForPaymentTable) === EntryTypeForPaymentTable.INVOICE) {
            statusComponent = /* @__PURE__ */ jsx("div", { children: getContractorInvoiceStatusComponent(original.rawContractorInvoice.status, __spreadValues({}, themeFonts.paragraph)) });
          }
          return statusComponent;
        }
      },
      {
        header: () => polyglot.t("PaymentTableHeaders.amount"),
        id: "amount",
        enableSorting: true,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(CurrencyCell, { value: original.amount, currency: original.currency })
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          return nonInvoiceActionsColumn(original);
        }
      }
    ];
  }, [
    mode,
    paymentAlerts == null ? void 0 : paymentAlerts.entries,
    selectionModel,
    setState,
    gotoSelectBankRoute,
    selectablePaymentsOnPage,
    setSelectionModel,
    polyglot
  ]);
  const handMarkPaid = () => __async(this, null, function* () {
    try {
      yield PaymentsAPI.markAsPaid(selectionModel);
      showMessage("Marked as paid successfully", "success");
      setSelectionModel([]);
      yield refresh();
    } catch (e) {
      console.error(e);
      showMessage(nestErrorMessage(e), "error");
    }
  });
  const handlePay = () => {
    const toBePaid = selectionModel.map((id) => payments == null ? void 0 : payments.items.find((payment) => payment.id === id)).filter(Boolean);
    if (!toBePaid.length) {
      setState(({ requests }) => {
        return {
          payments: [],
          requests
        };
      });
      return gotoSelectBankRoute();
    }
    if (toBePaid && toBePaid.length > 0) {
      setState(() => {
        return {
          payments: toBePaid,
          requests: toBePaid.map((payment) => getRequestFromPayment(payment))
        };
      });
      return gotoSelectBankRoute();
    }
  };
  const getPaymentsAllActionsOptions = () => {
    return [
      {
        icon: /* @__PURE__ */ jsx(Chose, __spreadValues({}, iconSize)),
        handler: () => handMarkPaid(),
        label: "Mark as paid",
        disabled: false
      }
      // Bulk delete is no longer required
      // {
      //   icon: <Trash {...iconSize} />,
      //   handler: () => handleDelete(),
      //   label: 'Delete',
      //   disabled: false,
      // },
    ];
  };
  useEffect(() => {
    (() => __async(this, null, function* () {
      try {
        const paymentsAlerts = yield PaymentsAPI.getAlerts(dispatch);
        setPaymentAlerts(paymentsAlerts);
      } catch (error) {
        showMessage("Could not load payments alerts.", "error");
      }
    }))();
  }, [showMessage, dispatch]);
  const handleRowClick = (row) => {
    setIsOpen(true);
    setSelectedPayment(row.original);
  };
  const validateSelectedPayments = () => {
    const paymentsIdWithMissingData = paymentAlerts && paymentAlerts.entries.filter((payment) => payment.userAddressMissing || payment.bankAccountMissing || payment.referenceMissing).map((item) => item.id);
    return !(selectionModel.filter((s) => paymentsIdWithMissingData == null ? void 0 : paymentsIdWithMissingData.includes(s)).length > 0);
  };
  return /* @__PURE__ */ jsxs(Box, { sx: { height: "100%" }, children: [
    showEmpty ? /* @__PURE__ */ jsx(PaymentEmptyState, { setIsModalOpen }) : /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center" }, children: [
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "start", gap: spacing.g5 }, children: [
          /* @__PURE__ */ jsx(
            TabFilterButtons,
            {
              filters: PaymentTabFilter(polyglot),
              setFilterValue: (filterValue2) => {
                updateTableState({ filterValue: filterValue2 });
                if (filterValue2 !== PaymentStatus.Outstanding) setSelectionModel([]);
              },
              filterValue,
              hasSearch: true,
              onFilterChange: ({ filterValue: filterValue2, searchInput: searchInput2 }) => {
                updateTableState({ filterValue: filterValue2, searchInput: searchInput2 });
              }
            }
          ),
          /* @__PURE__ */ jsx(
            HiddenColumnSelector,
            {
              options: allPaymentColumns,
              hiddenColumns,
              setHiddenColumns,
              columnAction: { domain: "payment", subDomain: "table", feature: "hiddenColumns" }
            }
          )
        ] }) }),
        selectionModel.length > 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "end", gap: spacing.g5, marginLeft: spacing.ml10 }, children: [
          /* @__PURE__ */ jsx(
            SelectDeselectRows,
            {
              selectionModel,
              setSelectionModel,
              rows: selectablePaymentsOnPage,
              hideSelectAll: true
            }
          ),
          /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: getPaymentsAllActionsOptions(),
              actionButtonDetails: {
                type: "button",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "Actions",
                icon: /* @__PURE__ */ jsx(ArrowDown, __spreadValues({}, iconSize)),
                iconPosition: "end"
              }
            }
          ),
          validateSelectedPayments() && /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "small",
              colorVariant: "primary",
              onClick: () => handlePay(),
              disabled: selectionModel.length < 1,
              children: "Pay"
            }
          )
        ] })
      ] }) }),
      /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: /* @__PURE__ */ jsx(
        BasicServerTable,
        {
          rowData: filteredPayments,
          columnData: columns,
          rowClick: handleRowClick,
          pagination: paginationState,
          setPagination: setPaginationState,
          totalPages: payments == null ? void 0 : payments.totalPages,
          totalItems: payments == null ? void 0 : payments.totalItems,
          sorting: sortingState,
          setSorting: setSortingState,
          hiddenColumns: [...hiddenColumns, ...filterValue !== PaymentStatus.Outstanding ? ["select"] : []]
        }
      ) })
    ] }),
    isModalOpen && /* @__PURE__ */ jsx(
      DrawerModal,
      {
        isOpen: isModalOpen,
        setIsOpen: setIsModalOpen,
        onClose: () => {
          setIsModalOpen(false);
          setSelectedPayment(void 0);
        },
        children: /* @__PURE__ */ jsx(
          PaymentForDialogSection,
          {
            selectedPayment,
            onChange: () => __async(this, null, function* () {
              yield refresh();
              setSelectedPayment(void 0);
            }),
            setIsModalOpen
          }
        )
      }
    ),
    selectedPayment && /* @__PURE__ */ jsx(
      PaymentDetailsDrawer,
      {
        isOpen,
        setIsOpen,
        payment: selectedPayment,
        refresh: () => __async(this, null, function* () {
          yield refresh();
          yield refreshTableData();
        }),
        paymentAlerts,
        onClose: () => {
          setIsOpen(false);
          setSelectedPayment(void 0);
        }
      }
    )
  ] });
}
export const PaymentsTransactionsTableSkeleton = () => /* @__PURE__ */ jsxs(
  Box,
  {
    sx: {
      marginTop: spacing.m30,
      width: "100%",
      textAlign: "center"
    },
    children: [
      /* @__PURE__ */ jsxs(
        "div",
        {
          style: {
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            gap: spacing.g5
          },
          children: [
            /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                width: "100px",
                height: "20px",
                sx: { background: themeColors.Background, borderRadius: radius.br10 }
              }
            ),
            /* @__PURE__ */ jsx(
              SkeletonLoader,
              {
                variant: "rectangular",
                width: "100px",
                height: "20px",
                sx: { background: themeColors.Background, borderRadius: radius.br10 }
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(BasicTable, { rowData: [], columnData: [], loading: true }) })
    ]
  }
);
export function PaymentsListPage() {
  const { polyglot } = usePolyglot();
  const routerLocation = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: polyglot.t("getSidebarConfig.payments") }),
        showBack: routerLocation.pathname.includes("/settings"),
        showAction: !showEmpty && selectionModel.length < 1
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, sx: __spreadProps(__spreadValues({}, spacing.pt20), { paddingBottom: 0 }), border: !showEmpty, children: /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(PaymentsTransactionsTableSkeleton, {}) }),
        children: /* @__PURE__ */ jsx(
          PaymentsTransactionsTable,
          {
            isModalOpen,
            setIsModalOpen,
            setLoading,
            setShowEmpty,
            showEmpty,
            setSelectionModel,
            selectionModel,
            mode: "payment",
            reach: "company"
          }
        )
      }
    ) })
  ] });
}
