"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Divider } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { CompanyPayrollType } from "@v2/feature/payroll/payroll.interface";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { iconSize } from "@v2/styles/table.styles";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { Typography } from "@/v2/components/typography/typography.component";
export const PayrunReopenConfirmationDrawer = ({
  payrollType,
  isOpen,
  setIsOpen,
  onConfirm
}) => {
  const [loading, setIsLoading] = useState(false);
  const reopenPayRun = () => __async(void 0, null, function* () {
    setIsLoading(true);
    yield onConfirm();
    setIsLoading(false);
  });
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Reopening payrun" }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Before you reopen this payrun please review the warnings and ensure you have taken the necessary actions before running this period in Zelt again." }),
    payrollType === CompanyPayrollType.UKPayroll && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10 }, children: [
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(MistakeIcon, __spreadValues({}, iconSize)) }),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { mb: spacing.mb5 }, children: "HMRC submission" }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "HMRC can confuse multiple payrun submissions so please keep an eye on your Government Gateway account for any error alerts." })
        ] })
      ] }),
      /* @__PURE__ */ jsx(Divider, { sx: { my: spacing.m20 } })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10 }, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(MistakeIcon, __spreadValues({}, iconSize)) }),
      /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { mb: spacing.mb5 }, children: "Accounting journal" }),
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "You will need to login to your accounting software and delete the accounting journal for the current pay period." })
      ] })
    ] }),
    payrollType === CompanyPayrollType.UKPayroll && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Divider, { sx: { my: spacing.m20, width: 1 } }),
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap10 }, children: [
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(MistakeIcon, __spreadValues({}, iconSize)) }),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline3", sx: { mb: spacing.mb5 }, children: "Pension" }),
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Please log into your pension provider platform and delete the latest contribution submission before re-running the payrun in Zelt." })
        ] })
      ] })
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(
      LoaderButton,
      {
        name: "Reopen this payrun",
        sizeVariant: "medium",
        colorVariant: "primary",
        loading,
        onClick: reopenPayRun,
        fullWidth: true
      }
    ) })
  ] }) });
};
