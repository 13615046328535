"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { differenceInDays, format, formatDistanceToNowStrict, isValid, parseISO } from "date-fns";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { removeTZ } from "@/v2/util/date-format.util";
export const getDateString = (date, duration = false, dateformat = "d MMM yyyy") => {
  try {
    const useDaysUnit = differenceInDays(/* @__PURE__ */ new Date(), new Date(date)) < 30;
    if (date && isValid(new Date(date))) {
      return duration ? formatDistanceToNowStrict(new Date(date), {
        unit: useDaysUnit ? "day" : "month",
        addSuffix: true
      }) : format(parseISO(removeTZ(new Date(date).toISOString())), dateformat);
    }
    return "";
  } catch (error) {
    console.error(error);
    return "Invalid date";
  }
};
export const DateLabelComponent = ({
  date,
  dateformat = "d MMM yyyy",
  duration = false,
  titleSx = __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey })
}) => {
  return /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({ whiteSpace: "nowrap" }, titleSx), children: getDateString(date, duration, dateformat) });
};
