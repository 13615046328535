"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { generatePath, useHistory } from "react-router-dom";
import { GlobalContext } from "@/GlobalState";
import { DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, DEVICES_ME_DEVICE_SECURITY_ROUTE } from "@/lib/routes";
import { checkScopes } from "@/lib/scopes";
import { IconContentActionCard } from "@/v2/components/theme-components/icon-content-action-card.component";
import { getModelImage } from "@/v2/feature/device/device.util";
import { secondaryCTABtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const MyDevicesActive = ({
  devicePossessions,
  userId
}) => {
  const [globalState] = useContext(GlobalContext);
  const currentUserId = globalState.user.userId;
  const hasDevicesAll = useMemo(
    () => checkScopes(globalState.user, ["devices:all"], { userId: globalState.user.userId }),
    [globalState.user]
  );
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: "Active" }),
    /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.mt20), children: devicePossessions.length > 0 ? /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: devicePossessions.map((d, idx) => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
      return /* @__PURE__ */ jsx(
        IconContentActionCard,
        {
          iconMedia: d && getModelImage((_a = d == null ? void 0 : d.device) == null ? void 0 : _a.type, (_b = d == null ? void 0 : d.device) == null ? void 0 : _b.modelName, {
            width: "150px",
            height: "auto"
          }),
          title: (_e = (_c = d.device) == null ? void 0 : _c.deviceName) != null ? _e : (_d = d.device) == null ? void 0 : _d.modelName,
          content: /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m5, display: "flex", flexDirection: "column", gap: spacing.g5 }, children: [
            ((_f = d.device) == null ? void 0 : _f.lastCheckIn) && /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
              "Last active about ",
              formatDistanceToNowStrict(new Date(d.device.lastCheckIn)),
              " ago"
            ] }),
            ((_g = d.device) == null ? void 0 : _g.type) && !d.device.mdmable && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: (_h = d.device) == null ? void 0 : _h.type }),
            ((_i = d.device) == null ? void 0 : _i.serialNumber) && /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: (_j = d.device) == null ? void 0 : _j.serialNumber })
          ] }),
          action: userId === currentUserId || hasDevicesAll ? /* @__PURE__ */ jsx(
            Button,
            {
              sx: secondaryCTABtn,
              onClick: () => {
                if (userId === currentUserId)
                  routerHistory.push(
                    generatePath(DEVICES_ME_DEVICE_SECURITY_ROUTE, { devicePossessionId: d.id })
                  );
                else if (hasDevicesAll)
                  routerHistory.push(
                    generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, { devicePossessionId: d.id })
                  );
              },
              children: "Details"
            }
          ) : null,
          showDivider: idx < devicePossessions.length - 1
        }
      );
    }) }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: "There are no active possessions at the moment" }) })
  ] });
};
