"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Plus } from "@/images/new-theme-icon/Plus.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { StyledMenuComponent } from "@/v2/components/theme-components/styled-menu.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const SurveySectionBuilder = ({
  isEditable,
  section,
  sectionOptions,
  dragQuestionComponent,
  newQuestionAction
}) => {
  var _a;
  return /* @__PURE__ */ jsxs(Box, { sx: { borderBottom: `1px solid ${themeColors.lightGrey}` }, children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          backgroundColor: themeColors.Background,
          justifyContent: "space-between",
          alignItems: "center",
          p: spacing.p16,
          marginTop: spacing.m24,
          borderBottom: `1px solid ${themeColors.lightGrey}`
        },
        children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                flexDirection: "column",
                gap: spacing.s1
              },
              children: [
                /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: section.name }),
                section.description && /* @__PURE__ */ jsx(
                  Typography,
                  {
                    variant: "paragraph",
                    dangerouslySetInnerHTML: { __html: (_a = section.description) != null ? _a : "" }
                  }
                )
              ]
            }
          ),
          isEditable && /* @__PURE__ */ jsx(
            StyledMenuComponent,
            {
              options: sectionOptions,
              actionButtonDetails: {
                type: "iconButton",
                colorVariant: "secondary",
                sizeVariant: "small",
                title: "actions",
                icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
              }
            }
          )
        ]
      }
    ),
    dragQuestionComponent,
    isEditable && /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          bgcolor: themeColors.white,
          p: spacing.p16
        },
        children: /* @__PURE__ */ jsx(
          ButtonComponent,
          {
            colorVariant: "secondary",
            sizeVariant: "medium",
            startIcon: /* @__PURE__ */ jsx(Plus, __spreadValues({}, iconSize)),
            onClick: newQuestionAction,
            children: "New question"
          }
        )
      }
    )
  ] });
};
