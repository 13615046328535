"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export function formatSortCodeUI(sortCode) {
  if (typeof sortCode !== "string") return "";
  const cleanSortCode = sortCode.replace("-", "");
  if (cleanSortCode.length !== 6 || isNaN(parseInt(sortCode))) return "";
  return cleanSortCode.slice(0, 2) + "-" + cleanSortCode.slice(2, 4) + "-" + cleanSortCode.slice(4, 6);
}
export function floatUIToAPI(floatStr) {
  if (!floatStr) {
    return 0;
  } else {
    const f = parseFloat(floatStr);
    return isNaN(f) ? 0 : f;
  }
}
export const isZero = (n) => !n || Math.abs(Number(n)) < 5e-3;
export function formatCurrency(value, opts, outputCurrency) {
  const amount = isZero(value) ? 0 : value;
  const formatter = new Intl.NumberFormat("en-GB", __spreadProps(__spreadValues({
    style: "currency",
    currency: outputCurrency != null ? outputCurrency : "GBP"
  }, (opts == null ? void 0 : opts.wholeNumber) && {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }), {
    currencyDisplay: (opts == null ? void 0 : opts.noCurrencySymbol) ? "code" : void 0
  }));
  let result = typeof amount === "number" || typeof amount === "string" ? formatter.format(Number(amount)) : null;
  if (result && (opts == null ? void 0 : opts.noCurrencySymbol)) {
    result = result.replace(/[A-Z]+/g, "").trim();
  }
  return result;
}
export function formatTaxYear(taxYearStr) {
  const match = taxYearStr && taxYearStr.match(/^Year([0-9]{4}$)/);
  if (match) {
    const year = parseInt(match[1]);
    return `Year ${year}/${year - 1999}`;
  } else {
    return null;
  }
}
const AMOUNT_FORMAT = new Intl.NumberFormat("en-GB", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});
export function formatAmount(value) {
  const amount = isZero(value) ? 0 : value;
  return typeof amount === "number" || typeof amount === "string" ? AMOUNT_FORMAT.format(amount) : null;
}
export function fixedAmount(value, invertValue = false) {
  const amount = isZero(value) ? 0 : value;
  if (amount === 0) return AMOUNT_FORMAT.format(amount);
  if (typeof amount === "number" || typeof amount === "string") {
    const formattedAmount = AMOUNT_FORMAT.format(amount);
    if (invertValue && formattedAmount.includes("-")) return formattedAmount.replaceAll("-", "");
    if (invertValue && !formattedAmount.includes("-")) return `-${formattedAmount}`;
    return formattedAmount;
  } else return null;
}
export function removeTrailingZeroes(value) {
  return value.replace(/\.0+$/, "").replace(/(\.\d*?[1-9])0+$/, "$1");
}
