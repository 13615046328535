"use strict";
import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { isValid } from "date-fns";
import dayjs from "dayjs";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TypeableDateComponent } from "@/v2/components/forms/typeable-date.component";
import { CustomProfileFieldType } from "@/v2/feature/custom-fields/custom-profile-fields.dto";
import { sortSelectOptions } from "@/v2/feature/custom-fields/custom-profile-fields.util";
import { fieldSx as defaultFieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
export const UserShowCustomFieldComponent = ({
  field,
  onChange,
  autoFocus,
  fieldSx = defaultFieldSx,
  disabled
}) => {
  const { polyglot } = usePolyglot();
  const [localValue, setLocalValue] = useState(field.value);
  const [fieldError, setFieldError] = useState();
  useEffect(() => {
    setLocalValue(field.value);
  }, [field.value]);
  const handleUpdate = useCallback(
    (value) => {
      setLocalValue(value);
      onChange(field.field.fieldId, value);
    },
    [field.field.fieldId, onChange]
  );
  const getFieldByType = useCallback(
    (customField) => {
      var _a, _b, _c, _d;
      switch (customField.field.fieldType) {
        case CustomProfileFieldType.Input:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              label: customField.field.fieldName,
              required: customField.field.isRequired,
              value: localValue,
              onChange: (e) => handleUpdate(e.target.value),
              autoFocus,
              disabled
            }
          ) });
        case CustomProfileFieldType.DatePicker:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
            DatePickerComponent,
            {
              inputFormat: "DD/MM/YYYY",
              required: customField.field.isRequired,
              value: localValue != null ? localValue : null,
              onChange: (value) => {
                if (dayjs(value).isValid()) {
                  handleUpdate(value);
                }
              },
              name: customField.field.fieldName,
              label: customField.field.fieldName,
              disabled
            }
          ) }) });
        case CustomProfileFieldType.Select:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            SelectComponent,
            {
              required: customField.field.isRequired,
              label: customField.field.fieldName,
              name: customField.field.fieldName,
              options: sortSelectOptions((_b = (_a = customField.field.options) == null ? void 0 : _a.allOptions) != null ? _b : []),
              value: localValue,
              onChange: (e) => handleUpdate(e.target.value),
              disabled
            }
          ) });
        case CustomProfileFieldType.Multiselect:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            MultipleSelectCheckbox,
            {
              required: customField.field.isRequired,
              label: customField.field.fieldName,
              id: customField.field.fieldName,
              options: sortSelectOptions((_d = (_c = customField.field.options) == null ? void 0 : _c.allOptions) != null ? _d : []),
              value: (() => {
                var _a2, _b2;
                try {
                  const parsedValue = JSON.parse(localValue);
                  const allOptions = (_b2 = (_a2 = customField.field.options) == null ? void 0 : _a2.allOptions) != null ? _b2 : [];
                  const selectedOptions = parsedValue.map((val) => allOptions.find((opt) => opt.value === val) || null).filter((opt) => opt !== null);
                  return selectedOptions;
                } catch (e) {
                  return [];
                }
              })(),
              onChange: (_, selectedOptions) => {
                const newValue = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                setLocalValue(JSON.stringify(newValue));
                handleUpdate(JSON.stringify(newValue));
              },
              disabled,
              isOptionEqualToValue: (option, selectedValue) => (option == null ? void 0 : option.value) === (selectedValue == null ? void 0 : selectedValue.value),
              getOptionLabel: (option) => (option == null ? void 0 : option.label) || ""
            }
          ) });
        case CustomProfileFieldType.Number:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TextfieldComponent,
            {
              required: customField.field.isRequired,
              type: "number",
              label: customField.field.fieldName,
              value: localValue,
              onChange: (e) => handleUpdate(e.target.value),
              autoFocus,
              disabled
            }
          ) });
        case CustomProfileFieldType.DateInput:
          return /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
            TypeableDateComponent,
            {
              name: customField.field.fieldName,
              label: customField.field.fieldName,
              required: customField.field.isRequired,
              value: localValue,
              onChange: (value) => {
                if (value && !isValid(new Date(value))) {
                  setFieldError(polyglot.t("ShowCustomFieldComponent.errorMessages.invalidDate"));
                } else {
                  handleUpdate(value);
                  setFieldError(void 0);
                }
              },
              autoFocus,
              error: !!fieldError,
              helperText: fieldError,
              disabled
            }
          ) });
        default:
          return null;
      }
    },
    [autoFocus, disabled, fieldError, fieldSx, localValue, polyglot, handleUpdate]
  );
  return getFieldByType(field);
};
