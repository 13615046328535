"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, FormControl } from "@mui/material";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ShareViaEmailDrawer = ({
  selectedDocument,
  isOpen,
  setSelectedDocument,
  setIsOpen
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const [showMessage] = useMessage();
  const [noteString, setNoteString] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [recipientUser, setRecipientUser] = useState(
    ((_a = selectedDocument == null ? void 0 : selectedDocument.belongsTo) == null ? void 0 : _a.length) === 1 && ((_c = (_b = selectedDocument == null ? void 0 : selectedDocument.belongsTo[0]) == null ? void 0 : _b.User) == null ? void 0 : _c.userId) ? (_e = (_d = selectedDocument == null ? void 0 : selectedDocument.belongsTo[0]) == null ? void 0 : _d.User) == null ? void 0 : _e.userId : null
  );
  const belongsToUserOptions = pipe(
    nonTerminatedCachedUsers,
    RA.map((u) => __spreadValues({ value: u.userId, label: u.displayName }, u))
  );
  const documentFileUuid = ((_f = selectedDocument == null ? void 0 : selectedDocument.attachments) == null ? void 0 : _f.length) === 1 && ((_g = selectedDocument == null ? void 0 : selectedDocument.attachments[0]) == null ? void 0 : _g.fileUuid) ? (_h = selectedDocument == null ? void 0 : selectedDocument.attachments[0]) == null ? void 0 : _h.fileUuid : (_i = selectedDocument == null ? void 0 : selectedDocument.fileUuid) != null ? _i : null;
  const handleButtonClick = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      if (selectedDocument && documentFileUuid && recipientUser) {
        yield DocumentAPI.shareDocumentViaEmail(documentFileUuid, recipientUser, noteString);
        setIsOpen(false);
        showMessage("Document successfully shared", "success");
      } else {
        if (!documentFileUuid) showMessage("Document missing attachment - cannot share via email", "error");
        if (!recipientUser) showMessage("Recipient missing - cannot share via email", "error");
      }
    } catch (error2) {
      showMessage(`Could not send notification. ${nestErrorMessage(error2)}`, "error");
    } finally {
      setLoading(false);
    }
  });
  return /* @__PURE__ */ jsx(
    DrawerModal,
    {
      isOpen,
      setIsOpen,
      onClose: () => {
        setIsOpen(false);
        setSelectedDocument(null);
      },
      children: /* @__PURE__ */ jsxs("div", { children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: [
          (selectedDocument == null ? void 0 : selectedDocument.name) && /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Share via email" }),
          /* @__PURE__ */ jsx(ViewItem, { label: "Document being shared", value: (selectedDocument == null ? void 0 : selectedDocument.name) ? selectedDocument == null ? void 0 : selectedDocument.name : "-" }),
          /* @__PURE__ */ jsx(
            SingleUserSelect,
            {
              name: "singleownership",
              options: belongsToUserOptions,
              onChange: (_, value) => {
                if (value) {
                  setRecipientUser(value.userId);
                } else {
                  setRecipientUser(null);
                }
              },
              value: recipientUser,
              label: "Share document to",
              error: false,
              helperText: false
            }
          )
        ] }),
        /* @__PURE__ */ jsx(FormControl, { sx: { mt: spacing.m30 }, error, fullWidth: true, children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            multiline: true,
            name: "note",
            label: "Note",
            value: noteString,
            type: "text",
            onChange: (e) => {
              setNoteString(e.target.value);
              setError(false);
            },
            endAdornment: "none"
          }
        ) }),
        /* @__PURE__ */ jsx(Box, { sx: spacing.mt40, children: /* @__PURE__ */ jsx(
          LoaderButton,
          {
            loading,
            colorVariant: "secondary",
            sizeVariant: "large",
            fullWidth: true,
            onClick: handleButtonClick,
            name: "Share document",
            disabled: !documentFileUuid || !recipientUser
          }
        ) })
      ] })
    }
  );
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: label }),
    /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: value })
  ] });
};
