"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { FormControl, FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { themeColors } from "@v2/styles/colors.styles";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { MoneyTextfieldComponent } from "@/v2/components/forms/money-textfield.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { P45FileUpload } from "@/v2/feature/user-onboarding/onboarding-by-user/pages/onboarding-payroll/components/p45-file-upload.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { StyledRadio } from "@/v2/styles/radio.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const P45Values = ({
  userId,
  disabled,
  haveP45,
  setHaveP45,
  leaveDate,
  setLeaveDate,
  taxCode,
  setTaxCode,
  totalPay,
  setTotalPay,
  taxPaid,
  setTaxPaid
}) => {
  const { polyglot } = usePolyglot();
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Stack, { children: [
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline3), { color: themeColors.DarkGrey }), children: polyglot.t("OnboardingByUser.doYouHaveP45") }),
    /* @__PURE__ */ jsx(FormControl, { sx: { width: "100%" }, children: /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        value: haveP45 === void 0 ? "" : haveP45 ? "yes" : "no",
        onChange: (event) => {
          setHaveP45(event.target.value === "yes");
        },
        sx: { flexFlow: "row" },
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "yes",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              disabled,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: polyglot.t("OnboardingByUser.yesIDo") }),
              sx: { width: 200 }
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              labelPlacement: "end",
              value: "no",
              control: /* @__PURE__ */ jsx(StyledRadio, {}),
              disabled,
              label: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: polyglot.t("OnboardingByUser.noIDont") })
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ jsxs(Stack, { sx: { overflow: "hidden" }, children: [
      /* @__PURE__ */ jsx(Stack, { sx: { mt: haveP45 ? spacing.g20 : "-100%", transition: "margin-top 0.4s linear" }, children: /* @__PURE__ */ jsx(P45FileUpload, { userId, disabled }) }),
      /* @__PURE__ */ jsx(
        DatePickerComponent,
        {
          name: "leaveDate",
          label: polyglot.t("OnboardingByUser.leavingDate"),
          value: leaveDate,
          onChange: (value) => {
            setLeaveDate(value);
          },
          disabled,
          disableFuture: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          label: polyglot.t("OnboardingByUser.taxCodeAtLeaving"),
          onChange: (e) => {
            setTaxCode(e.target.value.toUpperCase().replace(/[^A-Z\d]+/g, ""));
          },
          value: taxCode,
          disabled,
          clearText: () => setTaxCode(""),
          sx: { mt: spacing.mt20 }
        }
      ),
      /* @__PURE__ */ jsx(
        MoneyTextfieldComponent,
        {
          name: "totalPay",
          label: polyglot.t("OnboardingByUser.totalPay"),
          onChange: (value) => setTotalPay(value != null ? value : 0),
          value: totalPay,
          disabled,
          sx: { mt: spacing.mt20 }
        }
      ),
      /* @__PURE__ */ jsx(
        MoneyTextfieldComponent,
        {
          name: "taxPaid",
          label: polyglot.t("OnboardingByUser.taxPaid"),
          onChange: (value) => setTaxPaid(value != null ? value : 0),
          value: taxPaid,
          disabled,
          sx: { mt: spacing.mt20 }
        }
      )
    ] })
  ] }) });
};
