"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  filterDocuments,
  getDocumentParentCategory,
  getDocumentTypeListBasedOnAudience,
  getPinnedDocuments
} from "@v2/feature/documents/documents.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { useHistory, useLocation } from "react-router-dom";
import { ContractAPI } from "@/api-client/contract.api";
import { TemplateAPI } from "@/api-client/templates.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE } from "@/lib/routes";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { SkeletonLoader } from "@/v2/feature/dashboard/components/skeleton-loader.component";
import { DocumentTable } from "@/v2/feature/documents/components/document-table.component";
import { NewDocumentButton } from "@/v2/feature/documents/components/new-document-action-button.component";
import { PinnedDocument } from "@/v2/feature/documents/components/pinned-document.component";
import { DocumentAPI } from "@/v2/feature/documents/document.api";
import { DocPreviewer } from "@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component";
import { ContractModal } from "@/v2/feature/templates/components/contract-modal.component";
import { MissingPersonalEmployeeFieldsModal } from "@/v2/feature/templates/components/missing-personal-employee-fields-modal.component";
import { MissingTemplateFieldModal } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { SelectTemplateForContractModal } from "@/v2/feature/templates/components/select-template-for-contract-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { themeColors } from "@/v2/styles/colors.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const PersonalDocuments = ({
  userId,
  userName,
  setDocumentFormMode,
  documentState,
  documentDispatch,
  setOpen,
  loading,
  documents,
  setFilterValue
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const [searchInput, setSearchInput] = useState("");
  const [paginationReset, setPaginationReset] = useState(false);
  const [filterString, setFilterString] = useState("");
  const routerLocation = useLocation();
  const currentUserIsDocumentAdmin = hasScopes(
    ["documents:all", "documents:manager"],
    getScopesContext(globalState.user)
  );
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const allUsers = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState(false);
  const [missingFieldsForContractSigning, setMissingFieldsForContractSigning] = useState();
  const [selectedContract, setSelectedContract] = useState();
  const [showMessage] = useMessage();
  const [selectedDocBuffer, setSelectedDocBuffer] = useState();
  const [selectedDocContentType, setSelectedDocContentType] = useState("");
  const [selectedDocName, setSelectedDocName] = useState("");
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(void 0);
  const [missingFields, setMissingFields] = useState();
  const [contractRecipientUserId, setContractRecipientUserId] = useState();
  const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState();
  const filteredDocuments = useMemo(() => filterDocuments(searchInput)(documents)(filterString), [
    documents,
    searchInput,
    filterString
  ]);
  const pinnedDocuments = useMemo(() => getPinnedDocuments(documents), [documents]);
  const handleUploadDocument = () => {
    documentDispatch({ kind: "add_document", owner: { label: userName, value: userId } });
    setDocumentFormMode("add");
    setFilterValue(
      getDocumentParentCategory(
        "me",
        "add",
        void 0,
        currentUserIsDocumentAdmin,
        documentState.types
      )
    );
    setOpen(true);
  };
  const handleRequestDocument = () => {
    documentDispatch({ kind: "request_document", owner: { label: userName, value: userId }, reach: "me" });
    setDocumentFormMode("request");
    setFilterValue(
      getDocumentParentCategory(
        "me",
        "request",
        void 0,
        currentUserIsDocumentAdmin,
        documentState.types
      )
    );
    setOpen(true);
  };
  const refreshMissingFieldsForEmployeeContract = (contractId) => __async(void 0, null, function* () {
    const missingFields2 = yield ContractAPI.getEmployeeFieldsForContractById(contractId);
    setMissingFieldsForContractSigning(missingFields2);
  });
  const handleMissingFieldContractPopulation = (missingFields2, contract) => {
    setMissingFieldsForContractSigning(missingFields2);
    setSelectedContract(contract);
    setOpenMissingFieldContractModal(true);
  };
  const handleBulkUploadDocument = () => {
    routerHistory.push(DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE);
  };
  const handleTemplateClick = () => {
    setOpenTemplateModal(true);
  };
  const handlePreviewClick = useCallback(
    (zeltDocument) => __async(void 0, null, function* () {
      var _a, _b;
      try {
        const fileUuidFromAttachment = zeltDocument.attachments && zeltDocument.attachments[0] && ((_a = zeltDocument.attachments[0]) == null ? void 0 : _a.fileUuid);
        const finalUuid = (_b = zeltDocument.fileUuid) != null ? _b : fileUuidFromAttachment;
        if (!finalUuid) return;
        yield DocumentAPI.previewViaUuid(finalUuid).then((_0) => __async(void 0, [_0], function* ({ contentType, file }) {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        }));
      } catch (e) {
        console.error("::URL Download error", e);
        showMessage(
          polyglot.t("PersonalDocuments.errorMessages.preview", { errorMessage: nestErrorMessage(e) }),
          "error"
        );
      }
    }),
    [setOpenPreviewModal, setSelectedDocBuffer, setSelectedDocName, showMessage, polyglot]
  );
  const refreshMissingFieldsForTemplate = useCallback(
    (templateId, contractRecipientUserId2, companySignatoryUserId) => __async(void 0, null, function* () {
      try {
        const templateResult = yield TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId: contractRecipientUserId2,
          companySignatoryUserId
        });
        setMissingFields(templateResult.fields);
      } catch (error) {
        showMessage(
          polyglot.t("TemplatesPage.errorMessages.verify", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    }),
    [showMessage, polyglot]
  );
  const onMissingField = (params) => {
    const { contractRecipientUserId: contractRecipientUserId2, templateVerificationResult } = params;
    setContractRecipientUserId(contractRecipientUserId2);
    setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
    setMissingFields(templateVerificationResult.fields);
    setOpenMissingFieldContractModal(true);
  };
  return /* @__PURE__ */ jsxs(
    ContentWrapper,
    {
      loading,
      sx: __spreadProps(__spreadValues({}, pinnedDocuments.length > 0 ? spacing.pt40 : spacing.pt20), { overflow: "hidden" }),
      children: [
        pinnedDocuments.length > 0 && /* @__PURE__ */ jsx(Box, { sx: __spreadValues({}, spacing.pb40), children: /* @__PURE__ */ jsx(
          Box,
          {
            sx: {
              display: "flex",
              alignItems: "center",
              columnGap: spacing.g60,
              rowGap: spacing.g20,
              flexWrap: "wrap"
            },
            children: /* @__PURE__ */ jsx(
              PinnedDocument,
              {
                pinnedDocuments,
                onEditClick: (value) => {
                  documentDispatch({ kind: "edit_document", value, userList: allUsers });
                  setDocumentFormMode("edit");
                  setFilterValue(
                    getDocumentParentCategory(
                      "me",
                      "edit",
                      value,
                      currentUserIsDocumentAdmin,
                      documentState.types
                    )
                  );
                  setOpen(true);
                },
                documentTypes: [...documentState.types],
                personalDocuments: true,
                onPreviewClick: handlePreviewClick
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsxs(
          Box,
          {
            sx: {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            },
            children: [
              /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
                /* @__PURE__ */ jsx(
                  CategoryFilters,
                  {
                    filterTypes: {
                      type: getDocumentTypeListBasedOnAudience(documentState.types, currentUserIsDocumentAdmin).map((d) => {
                        return { value: d.value, label: d.label };
                      })
                    },
                    setFilterString,
                    filterString
                  }
                ),
                /* @__PURE__ */ jsx(
                  TableSearch,
                  {
                    query: searchInput,
                    handleChange: (e) => {
                      var _a, _b;
                      setSearchInput((_b = (_a = e.target.value) == null ? void 0 : _a.trim()) != null ? _b : "");
                      setPaginationReset(true);
                    },
                    handleBlur: () => setPaginationReset(false)
                  }
                )
              ] }),
              !routerLocation.pathname.includes("/me") && /* @__PURE__ */ jsx(
                NewDocumentButton,
                {
                  onUploadClick: handleUploadDocument,
                  onRequestClick: handleRequestDocument,
                  onBulkUploadClick: currentUserIsDocumentAdmin ? handleBulkUploadDocument : void 0,
                  onTemplateClick: currentUserIsDocumentAdmin ? handleTemplateClick : void 0,
                  size: "small",
                  isAdmin: currentUserIsDocumentAdmin
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsxs(Box, { sx: __spreadValues({}, spacing.mt20), children: [
          /* @__PURE__ */ jsx(
            Suspense,
            {
              fallback: /* @__PURE__ */ jsx(
                SkeletonLoader,
                {
                  variant: "rectangular",
                  width: "31%",
                  height: "50%",
                  sx: { borderRadius: "10px", backgroundColor: themeColors.Background }
                }
              ),
              children: /* @__PURE__ */ jsx(
                DocumentTable,
                {
                  documents: filteredDocuments,
                  documentTypes: [...documentState.types],
                  personalDocuments: true,
                  onEditClick: (value) => {
                    documentDispatch({ kind: "edit_document", value, userList: allUsers });
                    setDocumentFormMode("edit");
                    setFilterValue(
                      getDocumentParentCategory(
                        "me",
                        "edit",
                        value,
                        currentUserIsDocumentAdmin,
                        documentState.types
                      )
                    );
                    setOpen(true);
                  },
                  paginationReset,
                  onMissingContractFields: handleMissingFieldContractPopulation,
                  setSelectedDocBuffer,
                  setSelectedDocContentType,
                  setSelectedDocName,
                  setOpenPreviewModal,
                  stickyHeader: true
                }
              )
            }
          ),
          selectedContract && missingFieldsForContractSigning && openMissingFieldContractModal && /* @__PURE__ */ jsx(
            MissingPersonalEmployeeFieldsModal,
            {
              open: openMissingFieldContractModal,
              setOpen: setOpenMissingFieldContractModal,
              templateId: selectedContract.templateId,
              contractId: selectedContract.id,
              missingFields: missingFieldsForContractSigning,
              contractRecipientId: selectedContract.recipient,
              refreshMissingFields: refreshMissingFieldsForEmployeeContract
            }
          ),
          selectedDocBuffer && openPreviewModal && /* @__PURE__ */ jsx(
            DocPreviewer,
            {
              fileBuffer: selectedDocBuffer,
              contentType: selectedDocContentType,
              visible: true,
              onClose: () => setOpenPreviewModal(false),
              title: selectedDocName
            }
          ),
          selectedTemplate && openContractModal && /* @__PURE__ */ jsx(
            ContractModal,
            {
              open: openContractModal,
              setOpen: setOpenContractModal,
              contractTemplate: selectedTemplate,
              onMissingField,
              existingRecipient: userId
            }
          ),
          selectedTemplate && missingFields && contractRecipientUserId && openMissingFieldContractModal && /* @__PURE__ */ jsx(
            MissingTemplateFieldModal,
            {
              open: openMissingFieldContractModal,
              setOpen: setOpenMissingFieldContractModal,
              templateId: selectedTemplate.id,
              missingFields,
              contractRecipientId: contractRecipientUserId,
              companySignatoryUserId: contractCompanySignatoryUserId,
              refreshMissingFields: refreshMissingFieldsForTemplate
            }
          ),
          setSelectedTemplate && openTemplateModal && /* @__PURE__ */ jsx(
            SelectTemplateForContractModal,
            {
              open: openTemplateModal,
              setOpen: setOpenTemplateModal,
              setSelectedTemplate: (template) => {
                setSelectedTemplate(template != null ? template : void 0);
                setOpenContractModal(true);
              }
            }
          )
        ] })
      ]
    }
  );
};
