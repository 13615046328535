"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { Typography } from "@v2/components/typography/typography.component";
import { drawerContentSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import useScopes from "@/hooks/scopes.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ONBOARDING_NEW_USER_ROUTE, SETTINGS_ONBOARDING_ROUTE } from "@/lib/routes";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import {
  APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION
} from "@/v2/feature/app-integration/app-integration.interface";
import { getAppOwnerId } from "@/v2/feature/app-integration/features/app-details/app-details.util";
import {
  APPS_SUPPORTING_CANDIDATE_MANAGEMENT,
  APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT
} from "@/v2/feature/app-integration/features/app-details/pages/app-employment-management.page";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { OnboardingAPI } from "@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api";
const getNewEmployeeSchema = (polyglot) => Yup.object().shape({
  firstName: Yup.string().trim().required(polyglot.t("OnboardNewEmployee.errorMessages.nameRequired")),
  lastName: Yup.string().trim().required(polyglot.t("OnboardNewEmployee.errorMessages.lastNameRequired")),
  emailAddress: Yup.string().trim().email(polyglot.t("OnboardNewEmployee.errorMessages.emailInvalid")).required(polyglot.t("OnboardNewEmployee.errorMessages.emailRequired")),
  personalEmail: Yup.string().trim().email(polyglot.t("OnboardNewEmployee.errorMessages.emailInvalid")).required(polyglot.t("OnboardNewEmployee.errorMessages.emailRequired")),
  startDate: Yup.string().test(dateFieldTest).required(polyglot.t("OnboardNewEmployee.errorMessages.startDateRequired")),
  templateId: Yup.number().integer().optional()
});
export const OnboardNewEmployee = ({
  isOpen,
  setIsOpen,
  onClose,
  initialValues,
  onDraftUserCreated,
  onUserCreated,
  mode = "onboard",
  appStub,
  externalId
}) => {
  var _c;
  const { polyglot } = usePolyglot();
  const [templates, setTemplates] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showMessage] = useMessage();
  const draftOnly = useRef(false);
  const routerHistory = useHistory();
  const { refreshCachedUsers, getCachedUserById } = useCachedUsers();
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { hasScopes } = useScopes();
  useEffect(() => {
    OnboardingAPI.getOnboardingTemplates().then(setTemplates);
  }, []);
  const appSource = useMemo(() => {
    if (appStub && APPS_SUPPORTING_EMPLOYMENT_MANAGEMENT.includes(appStub)) return appStub;
    if (appStub && APPS_SUPPORTING_CANDIDATE_MANAGEMENT.includes(appStub)) return appStub;
    return void 0;
  }, [appStub]);
  const formik = useFormik({
    initialValues: __spreadValues({
      userId: 0,
      firstName: "",
      lastName: "",
      emailAddress: "",
      personalEmail: "",
      startDate: void 0,
      templateId: void 0
    }, initialValues),
    enableReinitialize: initialValues ? true : false,
    validateOnMount: true,
    validationSchema: getNewEmployeeSchema(polyglot),
    onSubmit: (_a) => __async(void 0, null, function* () {
      var _b = _a, { userId } = _b, values = __objRest(_b, ["userId"]);
      setSaving(true);
      try {
        let newUserId;
        if (userId) {
          newUserId = yield OnboardingAPI.updateUserOnboarding(
            __spreadProps(__spreadValues({}, values), {
              userId,
              displayName: `${values.firstName} ${values.lastName}`
            }),
            refreshCachedUsers
          );
        } else {
          newUserId = yield OnboardingAPI.createUserOnboarding(
            __spreadProps(__spreadValues({}, values), {
              appSource,
              externalId
              // type: EmploymentTypeEnum.Employee,
              // selectedEmail: hasEmailSelectorForUserCreation(formik.values.templateId)
              //   ? formik.values.emailAddress
              //   : undefined,
            }),
            refreshCachedUsers
          );
          if (mode === "import" && appStub && newUserId && externalId)
            yield AppIntegrationAPI.createIdMatchingForNewCandidate(appStub, newUserId, externalId);
        }
        if (draftOnly.current) {
          onDraftUserCreated();
          return;
        }
        routerHistory.push(ONBOARDING_NEW_USER_ROUTE, { newUserId });
        onUserCreated == null ? void 0 : onUserCreated();
      } catch (error) {
        showMessage(
          polyglot.t("OnboardNewEmployee.errorMessages.create", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
        setSaving(false);
      }
    })
  });
  useEffect(() => {
    if (templates.length === 1 && formik.values.templateId !== templates[0].templateId) {
      formik.setFieldValue("templateId", templates[0].templateId, true);
    }
  }, [formik, templates]);
  const saveDraft = useCallback(() => {
    draftOnly.current = true;
    formik.submitForm();
  }, [formik]);
  const onboardUser = useCallback(() => {
    draftOnly.current = false;
    formik.submitForm();
  }, [formik]);
  const templateOptions = useMemo(() => {
    return [
      ...templates.sort((a, b) => a.name.localeCompare(b.name, void 0, { sensitivity: "base" })).map((t) => ({ label: t.name, value: t.templateId }))
    ];
  }, [templates]);
  const hasEmailSelectorForUserCreation = (templateId) => {
    var _a, _b, _c2;
    const templateDetail = templates.find((t) => t.templateId === templateId);
    return mode === "onboard" && templateId && templateDetail && ((_c2 = (_b = (_a = templateDetail == null ? void 0 : templateDetail.template) == null ? void 0 : _a.apps) == null ? void 0 : _b.stubs) == null ? void 0 : _c2.some((stub) => APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(stub))) ? templateDetail : void 0;
  };
  const validateEmailDomainForAppOnboarding = (..._0) => __async(void 0, [..._0], function* (templateId = formik.values.templateId) {
    var _a, _b, _c2, _d;
    const selectedTemplateDetail = hasEmailSelectorForUserCreation(templateId);
    const appEmailValidation = [];
    if (!selectedTemplateDetail || !((_b = (_a = selectedTemplateDetail == null ? void 0 : selectedTemplateDetail.template) == null ? void 0 : _a.apps) == null ? void 0 : _b.stubs)) return true;
    let workEmailDomain;
    let appOwnerEmailDomain;
    for (const eachStub of (_d = (_c2 = selectedTemplateDetail == null ? void 0 : selectedTemplateDetail.template) == null ? void 0 : _c2.apps) == null ? void 0 : _d.stubs) {
      if (APPS_WITH_EMAIL_SELECTOR_FOR_USER_CREATION.has(eachStub)) {
        const stub = eachStub;
        const appOwnerId = yield getAppOwnerId(stub);
        const appOwner = getCachedUserById(appOwnerId);
        if (!appOwner) {
          appEmailValidation.push(false);
          return;
        }
        const appOwnerEmail = appOwner.emailAddress;
        appOwnerEmailDomain = appOwnerEmail.split("@")[1];
        workEmailDomain = formik.values.emailAddress.split("@")[1];
        appEmailValidation.push(appOwnerEmailDomain === workEmailDomain);
      }
    }
    const workEmailDomainValidated = appEmailValidation.every((each) => each === true);
    if (!workEmailDomainValidated) {
      const errorMessage = polyglot.t("OnboardNewEmployee.errorMessages.domainsDoNotMatch", {
        workEmailDomain,
        appOwnerEmailDomain
      });
      formik.setFieldError("emailAddress", errorMessage);
    } else {
      formik.setFieldError("emailAddress", void 0);
    }
  });
  const hasSubmitted = formik.submitCount > 0;
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: mode === "onboard" ? polyglot.t("OnboardNewEmployee.add") : polyglot.t("OnboardNewEmployee.onboardCandidate") }),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "firstName",
        label: polyglot.t("OnboardNewEmployee.firstName"),
        value: formik.values.firstName,
        onChange: formik.handleChange,
        disabled: saving,
        clearText: () => formik.setFieldValue("firstName", ""),
        helperText: hasSubmitted && formik.errors.firstName,
        error: hasSubmitted && !!formik.errors.firstName,
        autoFocus: true,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "lastName",
        label: polyglot.t("OnboardNewEmployee.lastName"),
        value: formik.values.lastName,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("lastName", ""),
        helperText: hasSubmitted && formik.errors.lastName,
        error: hasSubmitted && !!formik.errors.lastName,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "emailAddress",
        label: polyglot.t("OnboardNewEmployee.emailAddress"),
        type: "email",
        value: formik.values.emailAddress,
        disabled: saving,
        onChange: formik.handleChange,
        onBlur: () => validateEmailDomainForAppOnboarding(),
        clearText: () => formik.setFieldValue("emailAddress", ""),
        helperText: (hasSubmitted || hasEmailSelectorForUserCreation(formik.values.templateId)) && formik.errors.emailAddress,
        error: (hasSubmitted || hasEmailSelectorForUserCreation(formik.values.templateId)) && !!formik.errors.emailAddress,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextfieldComponent,
      {
        name: "personalEmail",
        label: polyglot.t("OnboardNewEmployee.personalEmail"),
        type: "email",
        value: formik.values.personalEmail,
        disabled: saving,
        onChange: formik.handleChange,
        clearText: () => formik.setFieldValue("personalEmail", ""),
        helperText: hasSubmitted && formik.errors.personalEmail,
        error: hasSubmitted && !!formik.errors.personalEmail,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: (_c = formik.values.startDate) != null ? _c : null,
        onChange: (value) => {
          formik.setFieldValue("startDate", value);
        },
        name: "startDate",
        label: polyglot.t("OnboardNewEmployee.startDate"),
        disabled: saving,
        helperText: hasSubmitted && formik.errors.startDate,
        error: hasSubmitted && !!formik.errors.startDate,
        required: true
      }
    ),
    /* @__PURE__ */ jsx(
      AutocompleteComponent,
      {
        name: "templateId",
        label: polyglot.t("OnboardNewEmployee.templateId"),
        options: templateOptions,
        value: templateOptions.find(({ value }) => value === formik.values.templateId) || null,
        compareValue: formik.values.templateId,
        onChange: (_, x) => {
          var _a;
          formik.setFieldValue("templateId", (_a = x == null ? void 0 : x.value) != null ? _a : null);
        },
        error: hasSubmitted && !!formik.errors.templateId,
        helperText: hasSubmitted && formik.errors.templateId,
        editList: {
          handler: () => routerHistory.push(SETTINGS_ONBOARDING_ROUTE),
          isHidden: !hasScopes(["user.onboard:all"], { userId: user.userId })
        }
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      !formik.values.templateId && /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("OnboardNewEmployee.save"),
          type: "button",
          disabled: saving,
          onClick: saveDraft,
          loading: saving && draftOnly.current,
          colorVariant: "secondary",
          sizeVariant: "medium",
          fullWidth: true
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("OnboardNewEmployee.onboard"),
          type: "button",
          disabled: saving || !formik.values.templateId,
          onClick: onboardUser,
          loading: saving && !draftOnly.current,
          colorVariant: "primary",
          sizeVariant: "medium",
          fullWidth: true
        }
      )
    ] })
  ] }) }) });
};
