"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { UserSelectFiltersOptions } from "@/v2/components/user-select-type/user-select.interface";
import { PermissionGroupMemberRemoverList } from "@/v2/feature/permission-group/components/permission-group-member-remover-list.component";
import { convertUserSummariesToIds } from "@/v2/feature/permission-group/components/permission-group-targets-edit-drawer.component";
import { PermissionGroupAPI } from "@/v2/feature/permission-group/permission-group.api";
import {
  PERMISSION_GROUP_EDIT_DRAWER_MODES
} from "@/v2/feature/permission-group/permission-group.interface";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { drawerContentSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { translatePermissionCategory, translatePermissionCategoryDesc } from "@/v2/infrastructure/i18n/translate.util";
import { borders } from "@/v2/styles/borders.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const scopesWithEntityLevelPermissions = {
  "payroll.selectedEntity:all": "payroll"
};
const PermissionGroupEditFormSchema = (polyglot) => Yup.object().shape({
  name: Yup.string().required(polyglot.t("PermissionGroupEditDrawerPage.errorMessages.nameRequired")),
  description: Yup.string().required(polyglot.t("PermissionGroupEditDrawerPage.errorMessages.groupDescRequired"))
});
export const PermissionGroupEditDrawerPage = ({
  refreshPermissionGroup,
  selectedPermission: permissionGroup,
  permissionGroupMembers,
  selectedPermissionCategory,
  closePage,
  mode = PERMISSION_GROUP_EDIT_DRAWER_MODES.member,
  setLoading,
  permissionGroupName,
  additionalScopesToEdit,
  entitiesForPermission
}) => {
  var _a, _b, _c, _d;
  const { polyglot } = usePolyglot();
  const isAdminPermissionGroup = permissionGroupName === "Admin";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMessage] = useMessage();
  const [updatedScopeState, setUpdatedScopeState] = useState(void 0);
  const [originalScopeState, setOriginalScopeState] = useState({});
  const { cachedUsers: companyUsers } = useCachedUsers();
  const [userListForMembershipDeletion, setUserListForMembershipDeletion] = useState([]);
  const [selectedInclusions, setSelectedInclusions] = useState(
    (_a = permissionGroup == null ? void 0 : permissionGroup.inclusions) != null ? _a : []
  );
  const [selectedExclusions, setSelectedExclusions] = useState(
    (_b = permissionGroup == null ? void 0 : permissionGroup.exclusions) != null ? _b : []
  );
  const [entityLevelPermissionsByType, setEntityLevelPermissionsByType] = useState(() => {
    var _a2;
    const permissions = (_a2 = permissionGroup == null ? void 0 : permissionGroup.entityLevelPermissions) != null ? _a2 : [];
    return permissions.reduce((acc, permission) => {
      const { entityType, entityId } = permission;
      if (!acc[entityType]) {
        acc[entityType] = [];
      }
      acc[entityType].push(entityId);
      return acc;
    }, {});
  });
  const currentMembers = useMemo(() => {
    var _a2;
    return (_a2 = permissionGroupMembers == null ? void 0 : permissionGroupMembers.map((m) => m.userId)) != null ? _a2 : [];
  }, [permissionGroupMembers]);
  const appsError = useMemo(() => {
    if (updatedScopeState && (selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.name) === "Apps") {
      if (!updatedScopeState["apps"] && updatedScopeState["apps.connect"]) return true;
    }
    return false;
  }, [updatedScopeState, selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.name]);
  const MODES_FOR_TITLE = [PERMISSION_GROUP_EDIT_DRAWER_MODES.general, PERMISSION_GROUP_EDIT_DRAWER_MODES.add];
  const VIEW_ONLY_MODE = mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.view;
  const usersNotInMemberList = useMemo(
    () => companyUsers.filter((u) => !currentMembers.includes(u.userId)).map((u) => {
      var _a2;
      return {
        label: (_a2 = u.displayName) != null ? _a2 : "",
        value: u.userId
      };
    }),
    [companyUsers, currentMembers]
  );
  const initialValues = useMemo(
    () => {
      var _a2, _b2;
      return {
        name: [PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) ? "" : (_a2 = permissionGroup == null ? void 0 : permissionGroup.name) != null ? _a2 : "",
        description: [PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) ? "" : (_b2 = permissionGroup == null ? void 0 : permissionGroup.description) != null ? _b2 : "",
        members: [],
        userListForMembershipDeletion: []
      };
    },
    [permissionGroup, mode]
  );
  const updatePermissionGroupMembers = useCallback(
    (members, name, description) => __async(void 0, null, function* () {
      if (!permissionGroup) return;
      try {
        if (isAdminPermissionGroup) yield PermissionGroupAPI.updatePermissionGroupMembers(permissionGroup.id, members);
        else
          yield PermissionGroupAPI.updatePermissionGroupDetails(permissionGroup.id, {
            members,
            name,
            description,
            inclusions: convertUserSummariesToIds(selectedInclusions),
            exclusions: convertUserSummariesToIds(selectedExclusions),
            customExclRule: permissionGroup.customExclRule,
            targetsExclRule: permissionGroup.targetsExclRule
          });
        showMessage(polyglot.t("PermissionGroupEditDrawerPage.successMessages.membersUpdate"), "success");
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [polyglot, permissionGroup, isAdminPermissionGroup, showMessage, selectedInclusions, selectedExclusions]
  );
  const updatePermissionGroupEntityLevelPermissions = useCallback(
    (entityLevelPermissions) => __async(void 0, null, function* () {
      if (!permissionGroup) return;
      try {
        yield PermissionGroupAPI.updatePermissionGroupDetails(permissionGroup.id, {
          members: currentMembers,
          name: permissionGroup.name,
          description: permissionGroup.description,
          inclusions: convertUserSummariesToIds(selectedInclusions),
          exclusions: convertUserSummariesToIds(selectedExclusions),
          customExclRule: permissionGroup.customExclRule,
          targetsExclRule: permissionGroup.targetsExclRule,
          entityLevelPermissions
        });
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [permissionGroup, currentMembers, selectedExclusions, selectedInclusions, showMessage]
  );
  const createPermissionGroup = useCallback(
    (name, description, members) => __async(void 0, null, function* () {
      if (!name) return;
      try {
        const newPermissionGroupPayload = {
          name,
          description,
          members,
          scopes: [],
          editMembers: true,
          inclusions: convertUserSummariesToIds(selectedInclusions),
          exclusions: convertUserSummariesToIds(selectedExclusions)
        };
        yield PermissionGroupAPI.createPermissionGroup(newPermissionGroupPayload);
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [showMessage, selectedInclusions, selectedExclusions]
  );
  const enablePermissionScope = useCallback(
    (scopeList) => __async(void 0, null, function* () {
      if (!permissionGroup) return;
      try {
        if (scopeList.length > 0) {
          const enableScopePromises = scopeList.map((eachScope) => {
            return PermissionGroupAPI.enablePermissionScope(permissionGroup.id, eachScope);
          });
          yield Promise.all(enableScopePromises);
          showMessage(polyglot.t("PermissionGroupEditDrawerPage.successMessages.scopeEnabled"), "success");
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [showMessage, permissionGroup, polyglot]
  );
  const disablePermissionScope = useCallback(
    (scopeList) => __async(void 0, null, function* () {
      if (!permissionGroup) return;
      try {
        if (scopeList.length > 0) {
          const disableScopePromises = scopeList.map((eachScope) => {
            return PermissionGroupAPI.disablePermissionScope(permissionGroup.id, eachScope);
          });
          yield Promise.all(disableScopePromises);
          showMessage(polyglot.t("PermissionGroupEditDrawerPage.successMessages.scopeDisabled"), "success");
        }
      } catch (error) {
        showMessage(nestErrorMessage(error), "error");
      }
    }),
    [showMessage, permissionGroup, polyglot]
  );
  const generateEntitySelector = useCallback(
    (scope) => {
      var _a2, _b2;
      const scopeWithEntity = scopesWithEntityLevelPermissions[scope];
      const entityType = scopeWithEntity;
      if (scopeWithEntity && entitiesForPermission && entityType in entitiesForPermission) {
        const entities = entitiesForPermission[entityType];
        const options = (_a2 = entities == null ? void 0 : entities.map(({ id, label }) => ({ value: id, label }))) != null ? _a2 : [];
        const selectedEntities = (_b2 = entityLevelPermissionsByType[entityType]) != null ? _b2 : [];
        const selectedOptions = options.filter(({ value }) => selectedEntities.includes(value));
        const entitySelectorOnChange = (_, v) => {
          const selectedEntities2 = v.map(({ value }) => value);
          setEntityLevelPermissionsByType((prevState) => __spreadProps(__spreadValues({}, prevState), {
            [entityType]: selectedEntities2
          }));
        };
        return /* @__PURE__ */ jsx(
          MultipleSelectCheckbox,
          {
            id: `${entityType}-entity-selector`,
            options,
            value: selectedOptions,
            onChange: entitySelectorOnChange,
            isOptionEqualToValue: (x, y) => x.value === y.value,
            getOptionLabel: (option) => option.label,
            label: `Select ${entityType} entities`,
            placeholder: "Choose entity",
            disabled: false,
            required: false
          }
        );
      }
      return /* @__PURE__ */ jsx(Fragment, {});
    },
    [entityLevelPermissionsByType, entitiesForPermission]
  );
  const isEntitySelectVisible = (scope) => !!(updatedScopeState && updatedScopeState[scope]);
  const formik = useFormik({
    initialValues,
    validationSchema: PermissionGroupEditFormSchema(polyglot),
    onSubmit: (_values) => __async(void 0, null, function* () {
      if (!updatedScopeState) return;
      try {
        setIsSubmitting(true);
        setLoading(true);
        if (mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.member || mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.general || mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.target) {
          if (permissionGroup && permissionGroup.id) {
            const updatedMemberList = [
              ...currentMembers.filter(
                (m) => !convertUserSummariesToIds(userListForMembershipDeletion).includes(m)
              ),
              ...formik.values.members
            ];
            yield updatePermissionGroupMembers(updatedMemberList, formik.values.name, formik.values.description);
            yield refreshPermissionGroup();
          }
        }
        if (mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.scope) {
          const scopesToDisable = [];
          const scopesToEnable = [];
          Object.keys(updatedScopeState).map((eachKey) => __async(void 0, null, function* () {
            if (updatedScopeState[eachKey] !== originalScopeState[eachKey]) {
              if (!updatedScopeState[eachKey]) scopesToDisable.push(eachKey);
              else if (updatedScopeState[eachKey]) scopesToEnable.push(eachKey);
            }
          }));
          yield Promise.all([disablePermissionScope(scopesToDisable), enablePermissionScope(scopesToEnable)]);
          const updatedEntityLevelPermissions = Object.entries(
            entityLevelPermissionsByType
          ).flatMap(([entityType, entityIds]) => entityIds.map((entityId) => ({ entityType, entityId })));
          yield updatePermissionGroupEntityLevelPermissions(updatedEntityLevelPermissions);
          yield refreshPermissionGroup();
        }
        if (mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.add) {
          yield createPermissionGroup(formik.values.name, formik.values.description, formik.values.members);
        }
        closePage();
      } catch (error) {
        showMessage(
          `${polyglot.t("PermissionGroupEditDrawerPage.errorMessages.update")} ${PERMISSION_GROUP_EDIT_DRAWER_MODES.member ? polyglot.t("PermissionGroupEditDrawerPage.errorMessages.members") : polyglot.t("PermissionGroupEditDrawerPage.errorMessages.group")}: ${nestErrorMessage(error)}`,
          "error"
        );
      } finally {
        setIsSubmitting(false);
        setLoading(false);
      }
    })
  });
  const getDrawerTitle = (currentMode) => {
    if (currentMode === PERMISSION_GROUP_EDIT_DRAWER_MODES.member)
      return polyglot.t("PermissionGroupEditDrawerPage.editGroup");
    if (currentMode === PERMISSION_GROUP_EDIT_DRAWER_MODES.add)
      return polyglot.t("PermissionGroupEditDrawerPage.addGroup");
    else return "";
  };
  useEffect(() => {
    formik.setFieldValue("userListForMembershipDeletion", userListForMembershipDeletion);
  }, [userListForMembershipDeletion]);
  const scopesToEdit = useMemo(() => {
    var _a2;
    const permissions = ((_a2 = selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.subcategories[0]) == null ? void 0 : _a2.permissions) || [];
    return permissions.sort((a, b) => {
      const isEntityLevelA = scopesWithEntityLevelPermissions[a.scope] !== void 0;
      const isEntityLevelB = scopesWithEntityLevelPermissions[b.scope] !== void 0;
      if (isEntityLevelA && !isEntityLevelB) return 1;
      if (!isEntityLevelA && isEntityLevelB) return -1;
      return 0;
    });
  }, [selectedPermissionCategory]);
  useEffect(() => {
    var _a2, _b2, _c2, _d2;
    const scopeMap = {};
    for (const eachPermission of (_b2 = (_a2 = selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.subcategories[0]) == null ? void 0 : _a2.permissions) != null ? _b2 : []) {
      scopeMap[eachPermission.scope] = !!((_c2 = eachPermission == null ? void 0 : eachPermission.permission_group_scope) == null ? void 0 : _c2.enabled);
    }
    for (const eachPermission of additionalScopesToEdit != null ? additionalScopesToEdit : []) {
      scopeMap[eachPermission.scope] = !!((_d2 = eachPermission == null ? void 0 : eachPermission.permission_group_scope) == null ? void 0 : _d2.enabled);
    }
    setUpdatedScopeState(scopeMap);
    setOriginalScopeState(Object.assign({}, scopeMap));
  }, [scopesToEdit, additionalScopesToEdit, selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.subcategories]);
  const isAdditionalScopesListVisible = useMemo(() => {
    if (isAdminPermissionGroup || !additionalScopesToEdit || additionalScopesToEdit.length === 0) return false;
    if ((selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.name) === "Administration")
      return Boolean(updatedScopeState && updatedScopeState["user.onboard:all"]);
    if ((selectedPermissionCategory == null ? void 0 : selectedPermissionCategory.name) === "Payroll")
      return Boolean(updatedScopeState && updatedScopeState["payroll:all"]);
    return true;
  }, [isAdminPermissionGroup, additionalScopesToEdit, selectedPermissionCategory, updatedScopeState]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Stack, { children: [
      MODES_FOR_TITLE.includes(mode) ? /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: getDrawerTitle(mode) }),
        PERMISSION_GROUP_EDIT_DRAWER_MODES.member === mode && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: isAdminPermissionGroup ? polyglot.t("PermissionGroupEditDrawerPage.defaultGroupDesc") : polyglot.t("PermissionGroupEditDrawerPage.customGroupDesc") })
      ] }) : /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: translatePermissionCategory(selectedPermissionCategory ? selectedPermissionCategory.name : "", polyglot) }),
      mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.general && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: polyglot.t("PermissionGroups.editGeneral") }),
      mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.target && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: polyglot.t("PermissionGroups.editTargets") }),
      mode === PERMISSION_GROUP_EDIT_DRAWER_MODES.member && /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: polyglot.t("PermissionGroups.editMembers") })
    ] }),
    !isAdminPermissionGroup && [PERMISSION_GROUP_EDIT_DRAWER_MODES.general, PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: polyglot.t("PermissionGroupEditDrawerPage.nameLabel"),
          value: formik.values.name,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.name && !!formik.errors.name,
          helperText: (_c = formik.touched.name && formik.errors.name) != null ? _c : " ",
          clearText: () => formik.setFieldValue("name", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "description",
          label: polyglot.t("PermissionGroupEditDrawerPage.descLabel"),
          value: formik.values.description,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.description && !!formik.errors.description,
          helperText: (_d = formik.touched.description && formik.errors.description) != null ? _d : " ",
          clearText: () => formik.setFieldValue("description", "")
        }
      ) })
    ] }),
    [PERMISSION_GROUP_EDIT_DRAWER_MODES.member, PERMISSION_GROUP_EDIT_DRAWER_MODES.add].includes(mode) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        UserSelect,
        {
          label: polyglot.t("PermissionGroupEditDrawerPage.whoSelect"),
          selectedLabel: "Selected members",
          value: formik.values.members,
          onChange: (userIds) => {
            formik.setFieldValue("members", userIds);
          },
          userOptions: usersNotInMemberList,
          fieldSx: __spreadValues({}, spacing.mb20)
        }
      ) }),
      /* @__PURE__ */ jsx(
        PermissionGroupMemberRemoverList,
        {
          setUserListForMembershipDeletion,
          userListForMembershipDeletion: convertUserSummariesToIds(userListForMembershipDeletion),
          existingMemberList: currentMembers
        }
      )
    ] }),
    [PERMISSION_GROUP_EDIT_DRAWER_MODES.target].includes(mode) && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        UserSelect,
        {
          label: polyglot.t("PermissionGroupListingTable.targets.include"),
          selectedLabel: polyglot.t("PermissionGroupListingTable.targets.includedTargets"),
          value: convertUserSummariesToIds(selectedInclusions),
          onChange: (userIds, filterValue, customRule) => {
            setSelectedInclusions([...userIds]);
            if (filterValue === UserSelectFiltersOptions.Everyone) {
              setSelectedInclusions([]);
              formik.setFieldValue("inclusions", []);
            } else {
              setSelectedInclusions([...userIds]);
              formik.setFieldValue("inclusions", [...userIds]);
            }
            formik.setFieldValue("targetsRule", filterValue);
            formik.setFieldValue("customRule", customRule);
          },
          initialFilterValue: !selectedInclusions || selectedInclusions.length === 0 ? UserSelectFiltersOptions.Everyone : UserSelectFiltersOptions.SelectSpecific,
          fieldSx: __spreadValues({}, spacing.mb20),
          excludeCustomRule: true,
          allowEmpty: true
        }
      ) }),
      /* @__PURE__ */ jsx("br", {}),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        UserSelect,
        {
          label: polyglot.t("PermissionGroupListingTable.targets.exclude"),
          selectedLabel: polyglot.t("PermissionGroupListingTable.targets.excludedTargets"),
          value: convertUserSummariesToIds(selectedExclusions),
          onChange: (userIds, filterValue, customRule) => {
            if (filterValue === UserSelectFiltersOptions.None) {
              setSelectedExclusions([]);
              formik.setFieldValue("exclusions", []);
            } else {
              setSelectedExclusions([...userIds]);
              formik.setFieldValue("exclusions", [...userIds]);
            }
            formik.setFieldValue("targetsExclRule", filterValue);
            formik.setFieldValue("customExclRule", customRule);
          },
          initialFilterValue: !selectedExclusions || selectedExclusions.length === 0 ? UserSelectFiltersOptions.None : UserSelectFiltersOptions.SelectSpecific,
          fieldSx: __spreadValues({}, spacing.mb20),
          excludeEveryone: true,
          excludeCustomRule: true,
          excludeNone: false,
          allowEmpty: true
        }
      ) })
    ] }),
    [PERMISSION_GROUP_EDIT_DRAWER_MODES.scope, PERMISSION_GROUP_EDIT_DRAWER_MODES.view].includes(mode) && /* @__PURE__ */ jsx(
      Box,
      {
        sx: {
          display: "flex",
          flexDirection: "column"
        },
        children: scopesToEdit == null ? void 0 : scopesToEdit.map((eachPermission, idx) => /* @__PURE__ */ jsxs(Box, { sx: { borderBottom: idx === scopesToEdit.length - 1 ? "none" : borders.light }, children: [
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: spacing.g20,
                width: "100%",
                py: spacing.m15
              },
              children: [
                /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "90%" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: translatePermissionCategoryDesc(eachPermission.name, polyglot) }) }),
                /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
                  CheckboxComponent,
                  {
                    label: void 0,
                    name: eachPermission.name,
                    checked: updatedScopeState ? updatedScopeState[eachPermission.scope] : false,
                    disabled: VIEW_ONLY_MODE,
                    onChange: () => {
                      setUpdatedScopeState((prevState) => __spreadProps(__spreadValues({}, prevState), {
                        [eachPermission.scope]: prevState ? !prevState[eachPermission.scope] : true
                      }));
                    }
                  }
                ) })
              ]
            }
          ),
          eachPermission.scope in scopesWithEntityLevelPermissions && isEntitySelectVisible(eachPermission.scope) && generateEntitySelector(eachPermission.scope)
        ] }))
      }
    ),
    additionalScopesToEdit && isAdditionalScopesListVisible && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between" }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: polyglot.t("PermissionGroupEditDrawerPage.additionalScopeReco") }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: void 0,
            checked: additionalScopesToEdit.filter(
              (permission) => updatedScopeState && updatedScopeState[permission.scope]
            ).length === additionalScopesToEdit.length,
            disabled: VIEW_ONLY_MODE,
            onChange: () => {
              const areAllChecked = additionalScopesToEdit.filter(
                (permission) => updatedScopeState && updatedScopeState[permission.scope]
              ).length === additionalScopesToEdit.length;
              for (const permission of additionalScopesToEdit) {
                setUpdatedScopeState((prevState) => __spreadProps(__spreadValues({}, prevState), {
                  [permission.scope]: !areAllChecked
                }));
              }
            }
          }
        ) })
      ] }),
      additionalScopesToEdit.map((eachPermission, idx) => /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: spacing.g20,
            width: "100%",
            py: spacing.m15,
            borderBottom: idx === additionalScopesToEdit.length - 1 ? "none" : borders.light
          },
          children: [
            /* @__PURE__ */ jsx(Box, { sx: { maxWidth: "90%" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: eachPermission.name }) }),
            /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(
              CheckboxComponent,
              {
                label: void 0,
                name: eachPermission.name,
                checked: updatedScopeState ? updatedScopeState[eachPermission.scope] : false,
                disabled: VIEW_ONLY_MODE,
                onChange: () => {
                  setUpdatedScopeState((prevState) => __spreadProps(__spreadValues({}, prevState), {
                    [eachPermission.scope]: prevState ? !prevState[eachPermission.scope] : true
                  }));
                }
              }
            ) })
          ]
        }
      ))
    ] }),
    appsError && /* @__PURE__ */ jsx(
      FormHelperText,
      {
        sx: __spreadProps(__spreadValues({ margin: 0, padding: 0 }, themeFonts.paragraph), { color: themeColors.RedDark }),
        id: "component-error-text",
        children: polyglot.t("PermissionGroupEditDrawerPage.appScopeDesc")
      }
    ),
    !VIEW_ONLY_MODE && /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(LoaderButton, { fullWidth: true, type: "submit", loading: isSubmitting, sizeVariant: "medium", colorVariant: "primary", children: polyglot.t("General.save") }) })
  ] }) });
};
