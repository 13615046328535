"use strict";
export var AnonymityType = /* @__PURE__ */ ((AnonymityType2) => {
  AnonymityType2["Full"] = "full";
  AnonymityType2["Semi"] = "semi";
  return AnonymityType2;
})(AnonymityType || {});
export var AnonymityThreshold = /* @__PURE__ */ ((AnonymityThreshold2) => {
  AnonymityThreshold2[AnonymityThreshold2["Three"] = 3] = "Three";
  AnonymityThreshold2[AnonymityThreshold2["Five"] = 5] = "Five";
  AnonymityThreshold2[AnonymityThreshold2["Ten"] = 10] = "Ten";
  return AnonymityThreshold2;
})(AnonymityThreshold || {});
export var SurveyFrequencyEnum = /* @__PURE__ */ ((SurveyFrequencyEnum2) => {
  SurveyFrequencyEnum2["once"] = "Once";
  SurveyFrequencyEnum2["recurring"] = "Recurring";
  return SurveyFrequencyEnum2;
})(SurveyFrequencyEnum || {});
export var SurveyFrequencyValues = /* @__PURE__ */ ((SurveyFrequencyValues2) => {
  SurveyFrequencyValues2["once"] = "once";
  SurveyFrequencyValues2["recurring"] = "recurring";
  return SurveyFrequencyValues2;
})(SurveyFrequencyValues || {});
export var SurveyStartOptionsEnum = /* @__PURE__ */ ((SurveyStartOptionsEnum2) => {
  SurveyStartOptionsEnum2["now"] = "Start now";
  SurveyStartOptionsEnum2["later"] = "Schedule for later";
  return SurveyStartOptionsEnum2;
})(SurveyStartOptionsEnum || {});
export var MessageTypeValue = /* @__PURE__ */ ((MessageTypeValue2) => {
  MessageTypeValue2["Default"] = "default";
  MessageTypeValue2["Custom"] = "custom";
  return MessageTypeValue2;
})(MessageTypeValue || {});
export const InviteMessageOptions = [
  {
    value: "default" /* Default */,
    label: "Use default message"
  },
  {
    value: "custom" /* Custom */,
    label: "Custom message"
  }
];
export var ReminderFrequencyValue = /* @__PURE__ */ ((ReminderFrequencyValue2) => {
  ReminderFrequencyValue2["Daily"] = "daily";
  ReminderFrequencyValue2["EveryMonday"] = "everyMonday";
  ReminderFrequencyValue2["None"] = "none";
  return ReminderFrequencyValue2;
})(ReminderFrequencyValue || {});
export var ReminderFrequencyLabel = /* @__PURE__ */ ((ReminderFrequencyLabel2) => {
  ReminderFrequencyLabel2["Daily"] = "Daily";
  ReminderFrequencyLabel2["EveryMonday"] = "Weekly on Monday";
  ReminderFrequencyLabel2["None"] = "None";
  return ReminderFrequencyLabel2;
})(ReminderFrequencyLabel || {});
export const ReminderFrequencyOptions = [
  {
    value: "daily" /* Daily */,
    label: "Daily" /* Daily */
  },
  {
    value: "everyMonday" /* EveryMonday */,
    label: "Weekly on Monday" /* EveryMonday */
  },
  {
    value: "none" /* None */,
    label: "None" /* None */
  }
];
export const ReminderFrequencyDict = {
  ["daily" /* Daily */]: "Daily" /* Daily */,
  ["everyMonday" /* EveryMonday */]: "Weekly on Monday" /* EveryMonday */,
  ["none" /* None */]: "None" /* None */
};
export var SurveyCycleAnonymityType = /* @__PURE__ */ ((SurveyCycleAnonymityType2) => {
  SurveyCycleAnonymityType2["full"] = "Full";
  SurveyCycleAnonymityType2["semi"] = "Semi";
  return SurveyCycleAnonymityType2;
})(SurveyCycleAnonymityType || {});
export const SURVEYS_ALL_SCOPE = "surveys:all";
export const SURVEYS_MANAGER_SCOPE = "surveys:manager";
export var SurveyImpactLabel = /* @__PURE__ */ ((SurveyImpactLabel2) => {
  SurveyImpactLabel2["High"] = "High";
  SurveyImpactLabel2["Medium"] = "Medium";
  SurveyImpactLabel2["Low"] = "Low";
  SurveyImpactLabel2["None"] = "None";
  return SurveyImpactLabel2;
})(SurveyImpactLabel || {});
export var MeasureBy = /* @__PURE__ */ ((MeasureBy2) => {
  MeasureBy2["NPS"] = "NPS";
  MeasureBy2["Positive"] = "Positive";
  MeasureBy2["Average"] = "Average";
  return MeasureBy2;
})(MeasureBy || {});
export var ShowBy = /* @__PURE__ */ ((ShowBy2) => {
  ShowBy2["Questions"] = "questions";
  ShowBy2["Factor"] = "factor";
  return ShowBy2;
})(ShowBy || {});
export var ComparedBy = /* @__PURE__ */ ((ComparedBy2) => {
  ComparedBy2["Site"] = "site";
  ComparedBy2["Department"] = "department";
  ComparedBy2["Gender"] = "gender";
  ComparedBy2["Tenure"] = "tenure";
  ComparedBy2["None"] = "none";
  return ComparedBy2;
})(ComparedBy || {});
export const DEFAULT_THRESHOLD_VALUE = 3;
