"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { formatMoney } from "@v2/feature/payments/utils/money.util";
import { titleSx } from "@v2/feature/user/features/user-profile/details/components/styles.layout";
import { buttonBoxSx } from "@v2/styles/settings.styles";
import { spacing } from "@v2/styles/spacing.styles";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
export const RefinancingPlanPreviewDrawer = ({ isOpen, setIsOpen, preview }) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap20 }, children: [
    /* @__PURE__ */ jsx(Typography, { sx: titleSx, children: "Preview" }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: "Terms" }),
      /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
        "Period: ",
        /* @__PURE__ */ jsx("b", { children: preview.terms.period })
      ] }),
      /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
        "No of periods: ",
        /* @__PURE__ */ jsx("b", { children: preview.terms.number_of_periods })
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: "Tax subtotals" }),
      preview.tax_subtotals.map((tax) => /* @__PURE__ */ jsxs(Box, { children: [
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          "Taxable amount = ",
          /* @__PURE__ */ jsx("b", { children: formatMoney({ amount: tax.taxable_amount }) })
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          "Tax rate =",
          " ",
          /* @__PURE__ */ jsxs("b", { children: [
            tax.tax_rate,
            " (",
            tax.tax_rate * 100,
            "%)"
          ] })
        ] }),
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          "VAT = ",
          /* @__PURE__ */ jsx("b", { children: formatMoney({ amount: tax.tax_amount }) })
        ] })
      ] }))
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.gap10, mb: spacing.m30 }, children: [
      /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline3, children: "Instalments" }),
      preview.instalments.map((instalment, index) => /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10 }, children: [
        /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
          index,
          ")"
        ] }),
        /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
            "Repayment amount: ",
            /* @__PURE__ */ jsx("b", { children: formatMoney({ amount: instalment.repayment_amount }) })
          ] }),
          /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
            "Invoice issue date: ",
            /* @__PURE__ */ jsx("b", { children: instalment.invoice_issue_date })
          ] }),
          /* @__PURE__ */ jsxs(Box, { children: [
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.paragraph, children: "Tax subtotals:" }),
            instalment.tax_subtotals.map((tax) => /* @__PURE__ */ jsxs(Box, { sx: { ml: spacing.m20, pb: spacing.m5 }, children: [
              /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
                "Taxable amount = ",
                /* @__PURE__ */ jsx("b", { children: formatMoney({ amount: tax.taxable_amount }) })
              ] }),
              /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
                "Tax rate =",
                " ",
                /* @__PURE__ */ jsxs("b", { children: [
                  tax.tax_rate,
                  " (",
                  tax.tax_rate * 100,
                  "%)"
                ] })
              ] }),
              /* @__PURE__ */ jsxs(Typography, { sx: themeFonts.paragraph, children: [
                "VAT = ",
                /* @__PURE__ */ jsx("b", { children: formatMoney({ amount: tax.tax_amount }) })
              ] })
            ] }))
          ] })
        ] })
      ] }))
    ] }),
    /* @__PURE__ */ jsx(Box, { sx: buttonBoxSx, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        sizeVariant: "medium",
        colorVariant: "secondary",
        name: "OK",
        onClick: () => setIsOpen(false),
        fullWidth: true,
        children: "OK"
      }
    ) })
  ] }) });
};
