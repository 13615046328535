"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { generatePath, useHistory } from "react-router-dom";
import * as yup from "yup";
import { SurveyEntryAPI } from "../../../../api-client/survey-entry.api";
import { GlobalContext } from "@/GlobalState";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { getSurveyDueDate } from "@/v2/feature/growth/surveys/features/survey-entry/survey-entry.util";
import { AnonymityType, DEFAULT_THRESHOLD_VALUE } from "@/v2/feature/growth/surveys/interfaces/survey-cycle.interface";
import { EntryState } from "@/v2/feature/growth/surveys/interfaces/survey-entry.interface";
import { drawerContentSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { themeColors } from "@/v2/styles/colors.styles";
import { buttonBoxDrawerSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { dateAPItoDisplay } from "@/v2/util/date-format.util";
export const SurveyEntriesPersonalStartModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  selectedEntry
}) => {
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, onClose, loading: false, afterClose, children: /* @__PURE__ */ jsx(SurveyEntriesPersonalStartContent, { selectedEntry, onClose }) });
};
const useStartModalForm = (selectedEntry, setLoading, shouldSelectPeers) => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const formik = useFormik({
    initialValues: {
      peerIds: []
    },
    validationSchema: yup.object({
      peerIds: shouldSelectPeers ? yup.array().of(yup.number()).min(1, "At least one peer is required").required("Peers are required") : yup.array().of(yup.number())
    }),
    onSubmit: (_values) => __async(void 0, null, function* () {
      try {
        if (!selectedEntry || !selectedEntry.cycle) {
          return;
        }
        routerHistory.push(
          generatePath(SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE, {
            entryId: selectedEntry.id,
            cycleId: selectedEntry.cycle.id
          })
        );
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
      } finally {
        setLoading(false);
      }
    })
  });
  return formik;
};
const SurveyEntriesPersonalStartContent = ({
  selectedEntry,
  onClose
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const routerHistory = useHistory();
  const [loading, setLoading] = useState(false);
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const [showMessage] = useMessage();
  const getAnonimityText = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2;
    if (!Boolean((_b2 = (_a2 = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _a2.visibilitySettings) == null ? void 0 : _b2.anonymiseAnswers))
      return "No, your answers are not anonymous.";
    if (Boolean((_d2 = (_c2 = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _c2.visibilitySettings) == null ? void 0 : _d2.anonymiseAnswers) && ((_f2 = (_e2 = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _e2.visibilitySettings) == null ? void 0 : _f2.anonymityType) && ((_h2 = (_g2 = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _g2.visibilitySettings) == null ? void 0 : _h2.anonymityType) === AnonymityType.Semi)
      return "Yes. Your answers will not be attributed to you, however some demographic data is collected to help segment and analyse answers. Your department, site, gender and tenure are saved alongside answers you provide. Individual responses remain anonymous and will only be reviewed in aggregate form to protect your privacy.";
    return "Yes, this survey does not collect any personally identifiable information.";
  }, [(_a = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _a.visibilitySettings]);
  const handleContinue = useCallback(() => __async(void 0, null, function* () {
    if (!selectedEntry || !selectedEntry.cycle) {
      return;
    }
    routerHistory.push(
      generatePath(SURVEYS_ME_ONGOING_CYCLE_DETAILS_ROUTE, {
        entryId: selectedEntry.id,
        cycleId: selectedEntry.cycle.id
      })
    );
  }), [selectedEntry, routerHistory]);
  const handleRemind = useCallback(() => __async(void 0, null, function* () {
    if (!selectedEntry || !selectedEntry.cycle) {
      return;
    }
    try {
      setLoading(true);
      yield SurveyEntryAPI.remindParticipantByEntryId(selectedEntry.id);
      onClose();
      showMessage(`Reminder sent successfully`, "success");
    } catch (error) {
      showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [onClose, selectedEntry, showMessage]);
  const formik = useStartModalForm(selectedEntry, setLoading, false);
  const isAnonymousSurvey = (_c = (_b = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _b.visibilitySettings) == null ? void 0 : _c.anonymiseAnswers;
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g8 }, children: [
      /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
        "Let's ",
        selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? "start" : "continue",
        " your survey"
      ] }),
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: isAnonymousSurvey ? "Please note that you will not be able to save your progress, anonymous surveys only allow for one submission. " : "You can save and exit at any time, come back to this survey later" })
    ] }),
    ((_d = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _d.displayName) && /* @__PURE__ */ jsx(ViewItem, { label: "Cycle name", value: selectedEntry.cycle.displayName }),
    ((_f = (_e = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _e.timelineSettings) == null ? void 0 : _f.startDate) && /* @__PURE__ */ jsx(ViewItem, { label: "Due date", value: (_g = dateAPItoDisplay(getSurveyDueDate(selectedEntry).toDateString())) != null ? _g : "" }),
    (selectedEntry == null ? void 0 : selectedEntry.participantId) && /* @__PURE__ */ jsx(ViewItem, { label: "Anonymous", value: getAnonimityText }),
    isAnonymousSurvey && /* @__PURE__ */ jsx(
      ViewItem,
      {
        label: "How will my answers be displayed?",
        value: `At minimum, ${((_i = (_h = selectedEntry == null ? void 0 : selectedEntry.cycle) == null ? void 0 : _h.visibilitySettings) == null ? void 0 : _i.anonymityThreshold) || DEFAULT_THRESHOLD_VALUE} answers required in any question or filtered segment to show results`
      }
    ),
    Boolean(currentUser.userId === (selectedEntry == null ? void 0 : selectedEntry.participantId)) ? /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "secondary", fullWidth: true, onClick: onClose, children: "Cancel" }),
      selectedEntry && selectedEntry.entryState === EntryState.NotStarted ? /* @__PURE__ */ jsx(
        LoaderButton,
        {
          sizeVariant: "medium",
          colorVariant: "primary",
          name: "Start survey",
          fullWidth: true,
          type: "submit",
          loading
        }
      ) : /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, onClick: () => handleContinue(), children: "Continue" })
    ] }) : /* @__PURE__ */ jsx(Box, { sx: buttonBoxDrawerSx, children: /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "medium", colorVariant: "primary", fullWidth: true, onClick: () => handleRemind(), children: "Remind" }) })
  ] }) });
};
const ViewItem = ({ label, value }) => {
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g4 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", sx: { color: themeColors.Grey }, children: label }),
    /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: value })
  ] });
};
