"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { themeColors } from "@v2/styles/colors.styles";
import { ReactComponent as Ok } from "@/images/side-bar-icons/Ok.svg";
import { themeFonts } from "@v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export default function SuccessCloseTab() {
  return /* @__PURE__ */ jsxs(Box, { sx: { m: "auto", display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { mx: "auto", display: "flex", alignItems: "center", gap: 2 }, children: [
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Ok, { width: "40", height: "40px", fill: themeColors.ZeltYellow }) }),
      /* @__PURE__ */ jsx(Box, { sx: { display: "flex" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Payment successfully registered." }) })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.mt20, color: themeColors.Grey }), alignSelf: "center", children: "This tab can be safely closed now." })
  ] });
}
