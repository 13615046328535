"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Stack, capitalize } from "@mui/material";
import {
  RelativeAssignmentOptions,
  RelativeAssignmentValues,
  RequestedForRelativeAssignmentOptions
} from "@v2/feature/task/subfeature/checklist/checklist-item.dto";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { actionIconSize } from "@v2/styles/table.styles";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { DepartmentEndpoints } from "@/api-client/company-department.api";
import { CompanyEndpoints } from "@/api-client/company.api";
import { SiteEndpoints } from "@/api-client/site.api";
import { ReactComponent as QuestionCircle } from "@/images/app-icons/QuestionCircle.svg";
import { ReactComponent as TrashIcon } from "@/images/fields/Trash.svg";
import {
  AttachmentComponent,
  attachmentComponentValueToString,
  stringToAttachmentComponentValue
} from "@/v2/components/forms/attachment.component";
import { AutocompleteComponent } from "@/v2/components/forms/autocomplete.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { SingleUserSelect } from "@/v2/components/forms/user-select/single-user-select.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { NotificationModal } from "@/v2/components/theme-components/notification-modal.component";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UserSelect } from "@/v2/components/user-select-type/user-select.component";
import { getCustomRuleOptionsList } from "@/v2/components/user-select-type/user-select.interface";
import { getUnitTypeOptions } from "@/v2/feature/templates/components/missing-template-field-modal.component";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { buttonBoxSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconButtonSx } from "@/v2/styles/icon-button.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT = (polyglot) => polyglot.t("ChecklistItemFormModal.taskForSomebodyHelper");
const mapOptions = (polyglot, items, ids, labelKey, valueKey) => {
  const filteredItems = items.filter((item) => ids.includes(item[valueKey])).map((item) => ({ label: polyglot.t(item[labelKey]), value: item[valueKey] }));
  return filteredItems;
};
const createEntityArrayFromCustomRule = (polyglot, customRule, generalSettings, sites, departments) => {
  var _a;
  if (!customRule) return [];
  const [key, idString] = customRule.split("=");
  const ids = idString.split(",").map((id) => parseInt(id)).filter((id) => !isNaN(id));
  switch (key) {
    case "department":
      return mapOptions(polyglot, departments != null ? departments : [], ids, "name", "id");
    case "site":
      return mapOptions(polyglot, sites != null ? sites : [], ids, "name", "id");
    case "entity":
      return mapOptions(polyglot, (_a = generalSettings == null ? void 0 : generalSettings.entities) != null ? _a : [], ids, "legalName", "id");
    default:
      console.error(`Unknown key '${key}' in customRule`);
      return [];
  }
};
export const ChecklistItemFormModal = ({
  onClose,
  formData,
  action,
  deleteAction
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
  const { polyglot } = usePolyglot();
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const OFFSET_DURATION_HELPER_TEXT = polyglot.t("ChecklistItemFormModal.offsetDurationHelper");
  const { data: sites } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: departments } = useApiClient(DepartmentEndpoints.getCompanyDepartments(), {
    suspense: false
  });
  const { data: generalSettings } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [assignedToSelectedValue, setAssignedToSelectedValue] = useState(
    (_b = (_a = formData == null ? void 0 : formData.assignedToCustomRule) == null ? void 0 : _a.split("=")[0]) != null ? _b : ""
  );
  const [assignedToCustomRule, setAssignedToCustomRule] = useState((_c = formData == null ? void 0 : formData.assignedToCustomRule) != null ? _c : "");
  const [assignedToOption, setAssignedToOption] = useState([]);
  useEffect(() => {
    if (assignedToCustomRule) {
      const optionArray = createEntityArrayFromCustomRule(
        polyglot,
        assignedToCustomRule,
        generalSettings,
        sites,
        departments
      );
      setAssignedToOption(optionArray);
    }
  }, [polyglot, assignedToCustomRule, generalSettings, sites, departments]);
  const users = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    });
  }, [nonTerminatedCachedUsers]);
  const assignedToOptions = useMemo(() => {
    var _a2;
    if (assignedToSelectedValue === "department") {
      return departments == null ? void 0 : departments.map((d) => ({ label: polyglot.t(d.name), value: d.id }));
    }
    if (assignedToSelectedValue === "site") {
      return sites == null ? void 0 : sites.map((s) => ({ label: polyglot.t(s.name), value: s.id }));
    }
    if (assignedToSelectedValue === "entity") {
      return (_a2 = generalSettings == null ? void 0 : generalSettings.entities) == null ? void 0 : _a2.map((e) => ({ label: polyglot.t(e.legalName), value: e.id }));
    }
    return [];
  }, [polyglot, departments, generalSettings == null ? void 0 : generalSettings.entities, assignedToSelectedValue, sites]);
  const handleSubmit = (values) => __async(void 0, null, function* () {
    const {
      assignedUserId,
      requestedForId,
      dueDate,
      assignedToPlaceholder,
      requestedForPlaceholder,
      userAssignWithPlaceholder,
      dueDateOffsetDuration,
      dueDateOffsetUnit,
      taskForSomebodyElse,
      attachmentValues,
      assignedUserIds,
      assignedToCustomRule: assignedToCustomRule2
    } = values;
    const newValues = __spreadProps(__spreadValues(__spreadValues({}, formData), values), {
      dueDate: dueDate != null ? dueDate : null,
      assignedToPlaceholder,
      requestedForPlaceholder: taskForSomebodyElse ? requestedForPlaceholder : "",
      userAssignWithPlaceholder,
      dueDateOffsetDuration,
      dueDateOffsetUnit,
      attachmentValues: attachmentValues != null ? attachmentValues : null,
      assignedUserId: assignedUserId ? Number(assignedUserId) : null,
      assignedUserIds: assignedUserIds != null ? assignedUserIds : null,
      requestedForId: requestedForId ? Number(requestedForId) : void 0,
      assignedToCustomRule: assignedToCustomRule2 != null ? assignedToCustomRule2 : null
    });
    setLoading(true);
    yield action(newValues);
    formik.resetForm();
    onClose();
    setLoading(false);
  });
  const userOptions = useMemo(() => {
    if (formData == null ? void 0 : formData.assignedUserId) return [formData == null ? void 0 : formData.assignedUserId];
    return [];
  }, [formData == null ? void 0 : formData.assignedUserId]);
  const formik = useFormik({
    initialValues: {
      name: (_d = formData == null ? void 0 : formData.name) != null ? _d : "",
      description: (_e = formData == null ? void 0 : formData.description) != null ? _e : "",
      assignedUserId: formData == null ? void 0 : formData.assignedUserId,
      assignedUserIds: userOptions != null ? userOptions : [],
      assignedToPlaceholder: (formData == null ? void 0 : formData.assignedUserId) ? RelativeAssignmentValues.someoneElse : (_f = formData == null ? void 0 : formData.assignedToPlaceholder) != null ? _f : RelativeAssignmentValues.checklistAssignee,
      requestedForId: formData == null ? void 0 : formData.requestedForId,
      requestedForPlaceholder: (formData == null ? void 0 : formData.requestedForId) ? RelativeAssignmentValues.someoneElse : (_g = formData == null ? void 0 : formData.requestedForPlaceholder) != null ? _g : RelativeAssignmentValues.checklistAssignee,
      dueDateOffsetDuration: (_h = formData == null ? void 0 : formData.dueDateOffsetDuration) != null ? _h : 0,
      dueDateOffsetUnit: (_i = formData == null ? void 0 : formData.dueDateOffsetUnit) != null ? _i : "Days",
      userAssignWithPlaceholder: (formData == null ? void 0 : formData.assignedUserId) ? false : true,
      taskForSomebodyElse: (formData == null ? void 0 : formData.requestedForPlaceholder) ? true : false,
      attachmentValues: (_j = formData == null ? void 0 : formData.attachmentValues) != null ? _j : void 0,
      assignedToCustomRule: formData == null ? void 0 : formData.assignedToCustomRule
    },
    validationSchema: Yup.object({
      name: Yup.string().required(polyglot.t("ChecklistItemFormModal.errorMessages.nameRequired")),
      description: Yup.string().nullable().notRequired(),
      assignedToPlaceholder: Yup.string().required(
        polyglot.t("ChecklistItemFormModal.errorMessages.placeholderRequired")
      ),
      dueDateOffsetDuration: Yup.number().required(polyglot.t("ChecklistItemFormModal.errorMessages.dueDateRequired")),
      dueDateOffsetUnit: Yup.string().required(
        polyglot.t("ChecklistItemFormModal.errorMessages.dueDateOffsetRequired")
      ),
      attachmentValues: Yup.string().nullable().notRequired()
    }),
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(__spreadProps(__spreadValues({}, values), { assignedToCustomRule }))
  });
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g10 }, children: [
      /* @__PURE__ */ jsxs(
        Box,
        {
          sx: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            mb: spacing.g10
          },
          children: [
            /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: (formData == null ? void 0 : formData.id) ? polyglot.t("ChecklistItemFormModal.edit") : polyglot.t("ChecklistItemFormModal.new") }),
            (formData == null ? void 0 : formData.id) && /* @__PURE__ */ jsx(
              IconButton,
              {
                sx: iconButtonSx,
                onClick: (event) => {
                  setAnchorEl(event.currentTarget);
                },
                children: /* @__PURE__ */ jsx(TrashIcon, __spreadValues({}, actionIconSize))
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "name",
          label: polyglot.t("ChecklistItemFormModal.name"),
          value: formik.values.name,
          type: "text",
          onChange: formik.handleChange,
          error: formik.touched.name && !!formik.errors.name,
          helperText: (_k = formik.touched.name && formik.errors.name) != null ? _k : " ",
          clearText: () => formik.setFieldValue("name", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        TextfieldComponent,
        {
          name: "description",
          label: polyglot.t("ChecklistItemFormModal.description"),
          value: formik.values.description,
          type: "text",
          multiline: true,
          onChange: formik.handleChange,
          error: formik.touched.description && !!formik.errors.description,
          helperText: (_l = formik.touched.description && formik.errors.description) != null ? _l : " ",
          clearText: () => formik.setFieldValue("description", "")
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        AutocompleteComponent,
        {
          name: "assignedToPlaceholder",
          label: polyglot.t("ChecklistItemFormModal.assignedToPlaceholder"),
          options: RelativeAssignmentOptions,
          value: RelativeAssignmentOptions.find((option) => option.value === formik.values.assignedToPlaceholder),
          compareValue: formik.values.assignedToPlaceholder,
          onChange: (_, option) => {
            if (option) {
              formik.setFieldValue("assignedToPlaceholder", option.value, true);
              if ((option == null ? void 0 : option.value) === RelativeAssignmentValues.customRule || (option == null ? void 0 : option.value) === RelativeAssignmentValues.everyone || (option == null ? void 0 : option.value) === RelativeAssignmentValues.checklistAssignee || (option == null ? void 0 : option.value) === RelativeAssignmentValues.manager) {
                formik.setFieldValue("assignedUserIds", [], true);
              }
            }
          },
          error: !!formik.errors.assignedToPlaceholder && formik.touched.assignedToPlaceholder,
          helperText: formik.errors.assignedToPlaceholder && formik.touched.assignedToPlaceholder,
          getOptionLabel: (option) => option.label
        }
      ) }),
      formik.values.assignedToPlaceholder === RelativeAssignmentValues.customRule && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SelectComponent,
          {
            label: polyglot.t("CustomUserModal.condition"),
            name: "condition",
            options: getCustomRuleOptionsList(polyglot),
            value: assignedToSelectedValue,
            onChange: (e) => {
              setAssignedToSelectedValue(e.target.value);
              setAssignedToOption([]);
            }
          }
        ) }),
        assignedToSelectedValue && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          AutocompleteComponent,
          {
            multiple: true,
            label: capitalize(assignedToSelectedValue),
            disableCloseOnSelect: true,
            name: assignedToSelectedValue,
            filterSelectedOptions: true,
            value: assignedToOption,
            compareValue: assignedToOption,
            options: assignedToOptions != null ? assignedToOptions : [],
            onChange: (_, e) => {
              const value = e;
              setAssignedToOption(value);
              setAssignedToCustomRule(`${assignedToSelectedValue}=${value.map((v) => v.value).join(",")}`);
            },
            isOptionEqualToValue: (x, y) => x.value === y.value,
            renderTags: (options) => {
              return options.map((o) => o.label).join(", ");
            }
          }
        ) })
      ] }),
      formik.values.assignedToPlaceholder === RelativeAssignmentValues.someoneElse && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        UserSelect,
        {
          label: polyglot.t("TaskFormModal.whoShouldComplete"),
          selectedLabel: polyglot.t("TaskFormModal.selectedEmployees"),
          value: (_m = formik.values.assignedUserIds) != null ? _m : [],
          onChange: (userIds) => {
            formik.setFieldValue("assignedUserIds", userIds);
          },
          userOptions: users,
          fieldSx: { mb: spacing.m10 },
          excludeEveryone: true,
          excludeCustomRule: true
        }
      ) }),
      /* @__PURE__ */ jsx(Box, { sx: { mb: spacing.g5, display: "flex", alignItems: "center", gap: spacing.g10 }, children: /* @__PURE__ */ jsx(
        CheckboxComponent,
        {
          name: "taskForSomebodyElse",
          label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("ChecklistItemFormModal.taskCompletedForSomeoneElse") }),
            /* @__PURE__ */ jsx(StyledTooltip, { title: TASK_FOR_SOMEBODY_ELSE_HELPER_TEXT(polyglot), children: /* @__PURE__ */ jsx(QuestionCircle, { style: { height: 12, width: 12 }, fill: themeColors.GreyMiddle }) })
          ] }),
          checked: formik.values.taskForSomebodyElse,
          onChange: (_, checked) => {
            formik.setFieldValue("taskForSomebodyElse", checked);
            if (!checked) formik.setFieldValue("requestedForPlaceholder", "");
          }
        }
      ) }),
      formik.values.taskForSomebodyElse && /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          AutocompleteComponent,
          {
            name: "requestedForPlaceholder",
            label: polyglot.t("ChecklistItemFormModal.whoIsThisTaskFor"),
            options: RequestedForRelativeAssignmentOptions,
            value: RequestedForRelativeAssignmentOptions.find(
              (option) => option.value === formik.values.requestedForPlaceholder
            ),
            compareValue: formik.values.requestedForPlaceholder,
            onChange: (_, option) => {
              if (option) formik.setFieldValue("requestedForPlaceholder", option.value, true);
            },
            error: !!formik.errors.requestedForPlaceholder && formik.touched.requestedForPlaceholder,
            helperText: formik.errors.requestedForPlaceholder && formik.touched.requestedForPlaceholder,
            getOptionLabel: (option) => option.label
          }
        ) }),
        formik.values.requestedForPlaceholder === RelativeAssignmentValues.someoneElse && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
          SingleUserSelect,
          {
            name: "userId",
            options: "company",
            onChange: (_, x) => {
              var _a2;
              return formik.setFieldValue("requestedForId", (_a2 = x == null ? void 0 : x.value) != null ? _a2 : null);
            },
            value: formik.values.requestedForId,
            label: polyglot.t("ChecklistItemFormModal.requestedForSomeoneElse"),
            error: formik.touched.requestedForId && Boolean(formik.errors.requestedForId),
            helperText: formik.touched.requestedForId && formik.errors.requestedForId
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { flexFlow: "row", gap: spacing.g20 }, children: [
        /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            sx: { width: "50%" },
            name: "dueDateOffsetDuration",
            label: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
              /* @__PURE__ */ jsx(Typography, { variant: "paragraphSmall", children: polyglot.t("ChecklistItemFormModal.dueDateOffsetDuration") }),
              /* @__PURE__ */ jsx(StyledTooltip, { title: OFFSET_DURATION_HELPER_TEXT, children: /* @__PURE__ */ jsx(QuestionCircle, { style: { height: 10, width: 10 }, fill: themeColors.GreyMiddle }) })
            ] }),
            value: formik.values.dueDateOffsetDuration,
            type: "number",
            onChange: formik.handleChange,
            error: formik.touched.dueDateOffsetDuration && !!formik.errors.dueDateOffsetDuration,
            helperText: (_n = formik.touched.dueDateOffsetDuration && formik.errors.dueDateOffsetDuration) != null ? _n : "",
            clearText: () => formik.setFieldValue("probation_period_length", 0)
          }
        ),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            sx: { width: "50%" },
            name: "dueDateOffsetUnit",
            label: polyglot.t("ChecklistItemFormModal.dueDateOffsetUnit"),
            options: getUnitTypeOptions(polyglot),
            value: formik.values.dueDateOffsetUnit,
            compareValue: formik.values.dueDateOffsetUnit,
            error: !!formik.errors.dueDateOffsetUnit && formik.touched.dueDateOffsetUnit,
            onChange: formik.handleChange,
            helperText: formik.errors.dueDateOffsetUnit && formik.touched.dueDateOffsetUnit
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { sx: spacing.mt20, children: /* @__PURE__ */ jsx(
        AttachmentComponent,
        {
          variant: "paragraph",
          disabled: false,
          label: "Add a file",
          name: "attachmentValues",
          showSelectExisting: true,
          value: stringToAttachmentComponentValue((_o = formik.values.attachmentValues) != null ? _o : ""),
          onChange: (value) => {
            formik == null ? void 0 : formik.setFieldValue("attachmentValues", attachmentComponentValueToString(value));
          },
          error: formik.touched.attachmentValues && Boolean(formik.errors.attachmentValues),
          helperText: formik.touched.attachmentValues && formik.errors.attachmentValues
        }
      ) }),
      /* @__PURE__ */ jsxs(Box, { sx: buttonBoxSx, children: [
        (formData == null ? void 0 : formData.id) && /* @__PURE__ */ jsx(ButtonComponent, { fullWidth: true, colorVariant: "secondary", sizeVariant: "medium", onClick: () => onClose(), children: polyglot.t("General.cancel") }),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: polyglot.t("General.save"),
            sizeVariant: "medium",
            colorVariant: "primary",
            loading,
            fullWidth: true,
            disabled: !formik.isValid || !formik.dirty || loading
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx(
      NotificationModal,
      {
        isOpen: Boolean(anchorEl),
        onClose: () => setAnchorEl(null),
        anchorEl,
        takeAction: () => {
          if (formData == null ? void 0 : formData.id) deleteAction(formData == null ? void 0 : formData.id);
          setAnchorEl(null);
          onClose();
        },
        message: polyglot.t("ChecklistItemFormModal.confirmDelete"),
        callToAction: polyglot.t("General.yes")
      }
    )
  ] }) });
};
