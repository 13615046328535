"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { EmptyStateBox } from "@v2/components/empty-state-box.component";
import { useDebouncedCallback } from "use-debounce";
import useMessage from "@/hooks/notification.hook";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { EmptyCell } from "@/v2/components/table/empty-cell.component";
import { TableSearch } from "@/v2/components/table/table-search.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { ReviewEntryEndpoints } from "@/v2/feature/growth/reviews/api-client/review-entry.api";
import { ReviewEntriesPersonalStartModal } from "@/v2/feature/growth/reviews/features/review-personal/review-entry/components/review-entries-personal-start-modal.component";
import {
  getEntryStatus,
  getReviewDueDate,
  getReviewType
} from "@/v2/feature/growth/reviews/features/review-personal/review-entry/review-entry.util";
import { CycleState } from "@/v2/feature/growth/shared/interfaces/growth-common.interface";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { RootStyle } from "@/v2/styles/root.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const ReviewPersonalEntries = ({ userId }) => {
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState("");
  const { data: userEntries, isValidating: validationgUserEntries, isLoading: loadingUserEntries } = useApiClient(
    ReviewEntryEndpoints.getUserEntries(userId, searchInput),
    {
      suspense: false
    }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(void 0);
  const tableColumns = useMemo(
    () => [
      {
        header: () => "Cycle name",
        id: "cycle.name",
        accessorFn: (row) => {
          var _a, _b;
          return ((_a = row.cycle) == null ? void 0 : _a.displayName) || ((_b = row.cycle) == null ? void 0 : _b.internalName) || "";
        },
        enableSorting: false,
        cell: ({ row: { original } }) => {
          var _a, _b;
          return /* @__PURE__ */ jsx("div", { children: ((_a = original.cycle) == null ? void 0 : _a.displayName) || ((_b = original.cycle) == null ? void 0 : _b.internalName) || /* @__PURE__ */ jsx(EmptyCell, {}) });
        }
      },
      {
        header: () => "Reviewee",
        id: "revieweeId",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({
          row: {
            original: { revieweeId }
          }
        }) => revieweeId ? /* @__PURE__ */ jsx(UserCell, { userId: revieweeId }, revieweeId) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Review type",
        id: "type",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => original ? /* @__PURE__ */ jsx("div", { children: getReviewType(original) }) : /* @__PURE__ */ jsx(EmptyCell, {})
      },
      {
        header: () => "Due date",
        id: "duedate",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: getReviewDueDate(original).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short", year: "numeric" }) })
      },
      {
        header: () => "Status",
        id: "state",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx("div", { children: getEntryStatus()[original.entryState] })
      }
    ],
    []
  );
  const handleRowClick = useCallback((row) => {
    var _a;
    if (((_a = row.original.cycle) == null ? void 0 : _a.state) !== CycleState.Completed) {
      setIsOpen(true);
      setSelectedEntry(row.original);
    }
  }, []);
  const debouncedSearch = useDebouncedCallback((event) => __async(void 0, null, function* () {
    try {
      setSearchInput(event.target.value);
    } catch (error) {
      showMessage("Failed to handle search", "error");
    }
  }), 300);
  const handleSearch = useCallback((event) => debouncedSearch.callback(event), [
    debouncedSearch
  ]);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(TopHeader, { title: /* @__PURE__ */ jsx(Typography, { variant: "headline1", children: "Invites" }) }),
    /* @__PURE__ */ jsxs(ContentWrapper, { loading: false, children: [
      !loadingUserEntries && userEntries && userEntries.length === 0 && !searchInput ? /* @__PURE__ */ jsx(
        EmptyStateBox,
        {
          title: "Your invites",
          subtitle: "Here you will find invitations to performance reviews for yourself or your colleagues. At the moment you have no invites pending."
        }
      ) : /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
        /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: handleSearch }),
        /* @__PURE__ */ jsx(
          BasicTable,
          {
            rowData: userEntries ? [...userEntries] : [],
            columnData: tableColumns,
            loading: validationgUserEntries,
            hidePagination: true,
            rowClick: handleRowClick
          }
        )
      ] }),
      /* @__PURE__ */ jsx(
        ReviewEntriesPersonalStartModal,
        {
          isOpen,
          setIsOpen,
          onClose: () => {
            setIsOpen(false);
          },
          afterClose: () => setSelectedEntry(void 0),
          selectedEntry
        }
      )
    ] })
  ] });
};
