"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { DrawerModal } from "@v2/components/theme-components/drawer-modal.component";
import { dateFieldTest } from "@v2/infrastructure/date/date-format.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { buttonBoxDrawerSx } from "@v2/styles/settings.styles";
import { LocalDate } from "@v2/util/local-date";
import { isToday } from "date-fns";
import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DatePickerComponent } from "@/v2/components/forms/date-picker.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import {
  SingleUserSelect
} from "@/v2/components/forms/user-select/single-user-select.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import { LoaderButton } from "@/v2/components/theme-components/loading-button.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { AppIntegrationAPI } from "@/v2/feature/app-integration/app-integration.api";
import { appStubToName } from "@/v2/feature/app-integration/app-integration.interface";
import {
  APPS_NEEDING_EMAIL_FOR_DELETION,
  getEmailAddressForMissingId,
  hasActiveEmailForAccount
} from "@/v2/feature/app-integration/features/app-details/app-details.util";
import { getActionDate, userStatusIs } from "@/v2/feature/offboarding/offboarding.util";
import { useCachedUsers } from "@/v2/feature/user/context/cached-users.context";
import { HOUR_OF_THE_DAY_OPTIONS } from "@/v2/feature/user/features/user-notifications/user-notifications.util";
import { drawerContentSx, fieldSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { UserProfileActionAPI } from "@/v2/feature/user/features/user-profile-action/user-profile-action.api";
import { spacing } from "@/v2/styles/spacing.styles";
const SHORT_DATE_FORMAT = "YYYY-MM-DD";
export const DeactivationSchema = Yup.object().shape({
  deactivationDate: Yup.string().test(dateFieldTest).required("deactivation date is required"),
  transferDataTargetUser: Yup.string().notRequired().when("workspaceTransferThenDelete", {
    is: true,
    then: Yup.string().nullable().required()
  })
});
export const DeactivateAppsDrawer = ({
  isOpen,
  setIsOpen,
  userId,
  userApp,
  userLeaveDate,
  onClose,
  refresh,
  existingDelayedAction,
  existingDeactivationDate
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const DateTabFilter = [
    { name: polyglot.t("DeactivateAppsDrawer.today"), value: "today" },
    { name: polyglot.t("DeactivateAppsDrawer.lastDay"), value: "last-day" }
  ];
  const appStub = userApp.stub;
  const [users, setUsers] = useState([]);
  const [dateFilterValue, setDateFilterValue] = useState("today");
  const [typeFilterValue, setTypeFilterValue] = useState("suspend");
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [loading, setLoading] = useState(false);
  const [showMessage] = useMessage();
  const TypeTabFilter = [
    ...userApp.allowsSuspend && !userStatusIs(userApp.userStatus, ["Suspended", "No access"]) ? [{ name: polyglot.t("DeactivateAppsDrawer.suspend"), value: "suspend" }] : [],
    ...userApp.allowsDelete && !userStatusIs(userApp.userStatus, ["Scheduled", "Deleted", "No access"]) ? [{ name: polyglot.t("DeactivateAppsDrawer.delete"), value: "delete" }] : []
  ];
  const activeTeamMembers = (teamList) => {
    return nonTerminatedCachedUsers.filter(
      (u) => teamList.some(
        (eachUser) => {
          var _a2;
          return (((_a2 = eachUser == null ? void 0 : eachUser.emails) == null ? void 0 : _a2.some((eachEmail) => ["Active", "Invited"].includes(eachEmail.status))) || eachUser.userStatus === "Active") && eachUser.userId !== userId && u.userId === eachUser.userId;
        }
      )
    ).map((u) => {
      return __spreadValues({ label: u.displayName, value: u.userId }, u);
    });
  };
  useEffect(() => {
    const today = /* @__PURE__ */ new Date();
    if (existingDelayedAction && existingDelayedAction.data.deactivationDate) {
      const deactivationDate = new Date(existingDelayedAction.data.deactivationDate);
      if (deactivationDate > today) {
        setDateFilterValue("last-day");
      }
    } else if (existingDeactivationDate) {
      const deactivationDate = new Date(existingDeactivationDate);
      if (deactivationDate > today) {
        setDateFilterValue("last-day");
      }
    }
  }, [existingDeactivationDate, existingDelayedAction]);
  const getTeamUsers = useCallback(() => __async(void 0, null, function* () {
    try {
      if (appStub !== "zelt") {
        const { users: users2 } = yield AppIntegrationAPI.listAppUsers(appStub);
        setUsers(users2);
      }
    } catch (error) {
      showMessage(
        polyglot.t("DeactivateAppsDrawer.errorMessages.list", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  }), [appStub, showMessage, polyglot]);
  useEffect(() => {
    getTeamUsers();
  }, [getTeamUsers]);
  const unassignUser = (_0) => __async(void 0, [_0], function* ({ emailObj }) {
    try {
      yield AppIntegrationAPI.unassignUser(appStub, userId != null ? userId : 0, emailObj.email);
      showMessage("Account unassigned successfully", "success");
    } catch (error) {
      showMessage(
        polyglot.t("DeactivateAppsDrawer.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
        "error"
      );
    }
  });
  const getAppUserId = (appUserId, user) => {
    return String(appUserId) === String(userId) && APPS_NEEDING_EMAIL_FOR_DELETION.includes(appStub) && user && user.primaryEmail ? user.primaryEmail : String(appUserId);
  };
  function doDeleteUser(user, values) {
    return __async(this, null, function* () {
      var _a2, _b2, _c2, _d2, _e2;
      try {
        if (user.isAdmin) {
          showMessage(polyglot.t("DeactivateAppsDrawer.errorMessages.deleteAdminNotAllowed"), "error");
          return;
        }
        let appUserId = void 0;
        if (appStub === "github") {
          const githubEmail = hasActiveEmailForAccount(user);
          if (githubEmail) yield unassignUser({ emailObj: githubEmail });
          appUserId = githubEmail == null ? void 0 : githubEmail.email;
        }
        if (!appUserId) appUserId = user.login || user.id || getEmailAddressForMissingId(user);
        if (appUserId && userId)
          yield AppIntegrationAPI.deleteAppUser(
            appStub,
            getAppUserId(appUserId, user),
            Number(userId),
            getActionDate(values.deactivationDate)
          );
        showMessage(
          polyglot.t("DeactivateAppsDrawer.successMessages.deletion", {
            type: !values.deactivationDate || isToday(new Date(values.deactivationDate)) ? polyglot.t("DeactivateAppsDrawer.initiated") : polyglot.t("DeactivateAppsDrawer.scheduled")
          }),
          "success"
        );
      } catch (error) {
        if (((_b2 = (_a2 = error == null ? void 0 : error.response) == null ? void 0 : _a2.data) == null ? void 0 : _b2.error) === "Higher tier needed.") {
          showMessage(polyglot.t("DeactivateAppsDrawer.errorMessages.manageSlack"), "error");
        } else if ((_e2 = (_d2 = (_c2 = error == null ? void 0 : error.response) == null ? void 0 : _c2.data) == null ? void 0 : _d2.error) == null ? void 0 : _e2.includes("Super Admin")) {
          showMessage(polyglot.t("DeactivateAppsDrawer.errorMessages.userSuperAdmin"), "error");
        } else {
          showMessage(
            polyglot.t("DeactivateAppsDrawer.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
            "error"
          );
        }
      }
    });
  }
  function doSuspendUser(user, values, zeltUserId) {
    return __async(this, null, function* () {
      try {
        const actionDate = getActionDate(new Date(values.deactivationDate));
        yield AppIntegrationAPI.suspendAppUser(appStub, String(user == null ? void 0 : user.id), zeltUserId != null ? zeltUserId : 0, actionDate, values.cronHour);
        const appName = appStub === "zelt" ? "Zelt" : appStubToName[appStub];
        showMessage(
          actionDate ? polyglot.t("DeactivateAppsDrawer.successMessages.suspensionScheduled", { actionDate, appName }) : polyglot.t("DeactivateAppsDrawer.successMessages.suspensionInitiated", { appName }),
          "success"
        );
      } catch (error) {
        showMessage(
          polyglot.t("DeactivateAppsDrawer.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    });
  }
  function doTransferDataAndDeleteUser(user, values) {
    return __async(this, null, function* () {
      try {
        yield AppIntegrationAPI.dataTransferThenDeleteForAppUser(
          appStub,
          userId != null ? userId : 0,
          String(user.id),
          String(values.transferDataTargetUser),
          values.deactivationDate
        );
        showMessage(polyglot.t("DeactivateAppsDrawer.successMessages.dataTransferIntiated"), "success");
      } catch (error) {
        showMessage(
          polyglot.t("DeactivateAppsDrawer.errorMessages.badRequest", { errorMessage: nestErrorMessage(error) }),
          "error"
        );
      }
    });
  }
  const handleGoogleWorkspace = (user, values) => __async(void 0, null, function* () {
    if (typeFilterValue === "delete") {
      if (values.transferDataTargetUser) yield doTransferDataAndDeleteUser(user, values);
      else yield doDeleteUser(user, values);
    } else {
      yield doSuspendUser(user, values, userId);
    }
  });
  const handleZeltDeactivation = (values) => __async(void 0, null, function* () {
    try {
      const today = /* @__PURE__ */ new Date();
      const deactivationDate = new Date(values.deactivationDate);
      const scheduledForLater = deactivationDate > today;
      yield UserProfileActionAPI.deactivateUser(userId, { deactivationDate: values.deactivationDate });
      showMessage(
        scheduledForLater ? polyglot.t("DeactivateAppsDrawer.successMessages.deactivationScheduled", {
          deactivationDate: values.deactivationDate
        }) : polyglot.t("DeactivateAppsDrawer.successMessages.deactivate"),
        "success"
      );
    } catch (error) {
      showMessage(polyglot.t("DeactivateAppsDrawer.errorMessages.deactivate"), "error");
    }
  });
  const formik = useFormik({
    initialValues: {
      cronHour: existingDelayedAction && ((_a = existingDelayedAction == null ? void 0 : existingDelayedAction.data) == null ? void 0 : _a.cronHour) ? (_b = existingDelayedAction == null ? void 0 : existingDelayedAction.data) == null ? void 0 : _b.cronHour : 0,
      deactivationDate: existingDeactivationDate ? new LocalDate(existingDeactivationDate).toDateString() : existingDelayedAction && ((_c = existingDelayedAction == null ? void 0 : existingDelayedAction.data) == null ? void 0 : _c.deactivationDate) ? new LocalDate((_d = existingDelayedAction == null ? void 0 : existingDelayedAction.data) == null ? void 0 : _d.deactivationDate).toDateString() : new LocalDate().toDateString(),
      transferDataTargetUser: void 0,
      transferDataTargetUserOptionObj: void 0
    },
    enableReinitialize: false,
    validationSchema: DeactivationSchema,
    onSubmit: (values) => __async(void 0, null, function* () {
      try {
        setLoading(true);
        if (userApp) {
          if (appStub === "zelt") yield handleZeltDeactivation(values);
          else if (appStub === "google-workspace") yield handleGoogleWorkspace(userApp, values);
          else {
            if (typeFilterValue === "delete") yield doDeleteUser(userApp, values);
            else yield doSuspendUser(userApp, values, userId);
          }
          yield refresh();
        }
      } catch (error) {
        showMessage(polyglot.t("DeactivateAppsDrawer.errorMessages.tryAgain"), "error");
      } finally {
        setLoading(false);
      }
    })
  });
  const handleCronHourChange = (event) => {
    var _a2;
    formik.setFieldValue("cronHour", (_a2 = event.target) == null ? void 0 : _a2.value);
  };
  return /* @__PURE__ */ jsx(DrawerModal, { isOpen, setIsOpen, children: /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { onSubmit: formik.handleSubmit, style: drawerContentSx, children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "headline1", children: [
      polyglot.t("DeactivateAppsDrawer.deactivationDate"),
      " -",
      " ",
      appStub === "zelt" ? "Zelt" : appStubToName[appStub]
    ] }),
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: appStub === "zelt" ? polyglot.t("UserProfileDeactivateModal.subtitle") : polyglot.t("DeactivateAppsDrawer.dateDesc", {
      hourOfDeactivation: `${(_e = formik.values.cronHour) == null ? void 0 : _e.toString().padStart(2, "0")}:00`
    }) }),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10 }, children: /* @__PURE__ */ jsx(
      TabFilterButtons,
      {
        filters: DateTabFilter,
        setFilterValue: setDateFilterValue,
        filterValue: dateFilterValue,
        onFilterChange: ({ filterValue }) => {
          if (filterValue === "today") {
            const dateValue = dayjs().format(SHORT_DATE_FORMAT);
            formik.setFieldValue("deactivationDate", dateValue);
          }
          if (filterValue === "last-day" && userLeaveDate) {
            const dateValue = dayjs(userLeaveDate).format(SHORT_DATE_FORMAT);
            formik.setFieldValue("deactivationDate", dateValue);
          }
        }
      }
    ) }),
    /* @__PURE__ */ jsx(FormControl, { size: "small", fullWidth: true, children: /* @__PURE__ */ jsx(
      DatePickerComponent,
      {
        inputFormat: "DD/MM/YYYY",
        value: formik.values.deactivationDate,
        onChange: (value) => {
          if (dayjs(value).isValid()) {
            formik.setFieldValue("deactivationDate", value);
          }
        },
        name: "deactivationDate",
        label: polyglot.t("DeactivateAppsDrawer.deactivationDate"),
        error: Boolean(!!formik.errors.deactivationDate && formik.touched.deactivationDate),
        helperText: formik.touched.deactivationDate && formik.errors.deactivationDate
      }
    ) }),
    appStub !== "zelt" && dateFilterValue !== "today" && /* @__PURE__ */ jsxs(FormControl, { size: "small", fullWidth: true, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: polyglot.t("DeactivateAppsDrawer.hourToDeactivate") }),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "cronHour",
          label: "",
          options: HOUR_OF_THE_DAY_OPTIONS,
          value: formik.values.cronHour,
          compareValue: formik.values.cronHour,
          error: void 0,
          onChange: handleCronHourChange,
          helperText: void 0,
          fullWidth: true
        }
      )
    ] }),
    appStub !== "zelt" && /* @__PURE__ */ jsxs(Box, { children: [
      /* @__PURE__ */ jsx(
        TabFilterButtons,
        {
          filters: TypeTabFilter,
          setFilterValue: setTypeFilterValue,
          filterValue: typeFilterValue,
          onFilterChange: () => {
            formik.setFieldValue("transferDataTargetUserOptionObj", void 0, true);
            formik.setFieldValue("transferDataTargetUser", void 0, true);
          }
        }
      ),
      appStub === "google-workspace" && typeFilterValue === "delete" && /* @__PURE__ */ jsx(Box, { sx: fieldSx, children: /* @__PURE__ */ jsx(
        SingleUserSelect,
        {
          name: "transferDataTargetUser",
          options: activeTeamMembers(users),
          onChange: (_, x) => {
            var _a2;
            const data = x;
            const foundAppTargetUser = users && users.length > 0 && data && data.value ? users.find((u) => u.userId === data.value) : null;
            formik.setFieldValue("transferDataTargetUserOptionObj", data, true);
            formik.setFieldValue("transferDataTargetUser", (_a2 = foundAppTargetUser == null ? void 0 : foundAppTargetUser.id) != null ? _a2 : null, true);
            formik.setFieldTouched("transferDataTargetUser", true, true);
          },
          value: (_f = formik.values.transferDataTargetUserOptionObj) == null ? void 0 : _f.value,
          label: polyglot.t("DeactivateAppsDrawer.migrateTo"),
          error: formik.touched.transferDataTargetUser && Boolean(formik.errors.transferDataTargetUser),
          helperText: formik.touched.transferDataTargetUser && formik.errors.transferDataTargetUser
        }
      ) })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: buttonBoxDrawerSx, children: [
      /* @__PURE__ */ jsx(ButtonComponent, { colorVariant: "secondary", sizeVariant: "medium", fullWidth: true, onClick: () => onClose(), children: polyglot.t("General.cancel") }),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: polyglot.t("General.save"),
          loading,
          fullWidth: true,
          colorVariant: "primary",
          sizeVariant: "medium"
        }
      )
    ] })
  ] }) }) });
};
