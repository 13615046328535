"use strict";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { ContentWrapper } from "@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { getDefaultBenefitImageByCategory } from "@v2/feature/benefits/benefits.util";
import { NewBenefitDrawer } from "@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component";
import { getYearlyAllowanceNextRenewal } from "@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.util";
import { InsuranceType } from "@v2/feature/benefits/subfeature/insurance/insurance.interface";
import { getLogoByProviderName } from "@v2/feature/benefits/subfeature/insurance/insurance.util";
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel
} from "@v2/feature/benefits/subfeature/pension/pension.util";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { translateCustomBenefitCategory } from "@v2/infrastructure/i18n/translate.util";
import { spacing } from "@v2/styles/spacing.styles";
import { sortBy } from "lodash";
import { generatePath, useHistory } from "react-router-dom";
import useScopes from "@/hooks/scopes.hook";
import { ReactComponent as InsuranceBig } from "@/images/benefits/Insurance.svg";
import {
  DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE,
  INSURANCE_COMPANY_OVERVIEW_ROUTE,
  PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE
} from "@/lib/routes";
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard
} from "@/v2/components/settings/settings-item-card.component";
import {
  SectionItemType,
  SettingsSubsectionContent
} from "@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component";
import {
  CustomBenefitAllowanceType,
  CustomBenefitCategory
} from "@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface";
import { pluralText } from "@/v2/util/string.util";
export const BenefitsCompanyOverviewSection = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshAllBenefits,
  loading
}) => {
  var _a, _b;
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();
  const [isOpen, setIsOpen] = useState(false);
  const hasInsuranceAllScope = hasScopes(["insurance:all"]);
  return /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsx(
    SettingsSubsectionContent,
    {
      loadingAll: loading,
      sections: [
        {
          contentWidth: "100%",
          items: [
            {
              type: SectionItemType.Component,
              value: /* @__PURE__ */ jsxs(Box, { sx: flexContainerStyle, children: [
                sortBy(pensionSchemes, ["displayName"]).map((pensionScheme, index) => {
                  var _a2;
                  return /* @__PURE__ */ jsx(
                    SettingsItemCard,
                    {
                      title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                        /* @__PURE__ */ jsx(Box, { children: getPensionLogoByProviderName(pensionScheme.providerName, 40) }),
                        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: pensionScheme.displayName }),
                        " "
                      ] }),
                      boxSx: responsiveCardStyle,
                      boxAction: () => {
                        routerHistory.push(
                          generatePath(PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE, {
                            pensionSchemeId: pensionScheme.id
                          })
                        );
                      },
                      contentItemsSets: [
                        {
                          name: (_a2 = PensionProvidersValueToLabel[pensionScheme.providerName]) != null ? _a2 : pensionScheme.providerName,
                          type: ContentItemType.chip,
                          textColor: "DarkGrey",
                          backgroundColor: "white",
                          border: "middle"
                        },
                        {
                          name: polyglot.t("PensionModule.noOfWorkerGroups", {
                            smart_count: pensionScheme.workerGroups.length
                          }),
                          type: ContentItemType.chip,
                          textColor: "DarkGrey",
                          backgroundColor: "white",
                          border: "middle"
                        }
                      ]
                    },
                    `pension-${index}`
                  );
                }),
                hasInsuranceAllScope && (insuranceQuote == null ? void 0 : insuranceQuote.policy) ? /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                      /* @__PURE__ */ jsx(Box, { children: getLogoByProviderName(insuranceQuote.policy.providerName, 40) }),
                      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: insuranceQuote.policy.providerName }),
                      " "
                    ] }),
                    boxSx: responsiveCardStyle,
                    boxAction: () => {
                      routerHistory.push(INSURANCE_COMPANY_OVERVIEW_ROUTE);
                    },
                    contentItemsSets: [
                      {
                        name: insuranceQuote.policy.type === InsuranceType.Health ? translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot) : insuranceQuote.policy.type,
                        type: ContentItemType.chip,
                        textColor: "DarkGrey",
                        backgroundColor: "white",
                        border: "middle"
                      },
                      {
                        name: polyglot.t("BenefitModule.noOfMembers", {
                          smart_count: (_a = insuranceQuote.noOfPolicyMembers) != null ? _a : 0
                        }),
                        type: ContentItemType.chip,
                        textColor: "DarkGrey",
                        backgroundColor: "white",
                        border: "middle"
                      }
                    ]
                  },
                  "insurance"
                ) : insuranceQuote && hasInsuranceAllScope ? /* @__PURE__ */ jsx(
                  SettingsItemCard,
                  {
                    title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                      /* @__PURE__ */ jsx(InsuranceBig, { style: { width: "40px", height: "40px", borderRadius: "50%" } }),
                      /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("BenefitModule.quoteRequested") }),
                      " "
                    ] }),
                    description: polyglot.t("BenefitModule.quotePending"),
                    boxSx: responsiveCardStyle,
                    boxAction: () => {
                      routerHistory.push(INSURANCE_COMPANY_OVERVIEW_ROUTE);
                    }
                  },
                  "insurance"
                ) : /* @__PURE__ */ jsx(Fragment, {}),
                (_b = sortBy(customBenefits, ["name"])) == null ? void 0 : _b.map((benefit, index) => {
                  const contentItems = [
                    {
                      name: benefit.type,
                      type: ContentItemType.chip,
                      textColor: "DarkGrey",
                      backgroundColor: "white",
                      border: "middle"
                    },
                    {
                      name: `${benefit.includedUserIds.length} member${pluralText(benefit.includedUserIds.length)}`,
                      type: ContentItemType.chip,
                      textColor: "DarkGrey",
                      backgroundColor: "white",
                      border: "middle"
                    }
                  ];
                  if (benefit.allowanceType === CustomBenefitAllowanceType.Yearly) {
                    contentItems.push({
                      name: getYearlyAllowanceNextRenewal(benefit, "dayMonth"),
                      type: ContentItemType.chip,
                      textColor: "DarkGrey",
                      backgroundColor: "white",
                      border: "middle"
                    });
                  }
                  return /* @__PURE__ */ jsx(
                    SettingsItemCard,
                    {
                      title: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.s1 }, children: [
                        (benefit == null ? void 0 : benefit.url) ? /* @__PURE__ */ jsx("img", { src: benefit.url, width: 40, height: 40, alt: "benefit-logo" }) : getDefaultBenefitImageByCategory(benefit.category, "50px", "auto"),
                        /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: benefit.name }),
                        " "
                      ] }),
                      boxSx: responsiveCardStyle,
                      boxAction: () => {
                        routerHistory.push(
                          generatePath(DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE, { benefitId: benefit.id })
                        );
                      },
                      contentItemsSets: contentItems
                    },
                    `benefit-${index}`
                  );
                })
              ] })
            },
            {
              type: SectionItemType.Component,
              value: pensionSchemes.length === 0 && !insuranceQuote && (customBenefits != null ? customBenefits : []).length === 0 && /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.s2 }, children: [
                /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "No benefit has been set up yet." }),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    sizeVariant: "small",
                    colorVariant: "primary",
                    onClick: () => {
                      setIsOpen(true);
                    },
                    children: polyglot.t("BenefitModule.newBenefit")
                  }
                ),
                /* @__PURE__ */ jsx(NewBenefitDrawer, { isOpen, setIsOpen, refresh: refreshAllBenefits })
              ] })
            }
          ]
        }
      ]
    }
  ) });
};
