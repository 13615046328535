"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { IconButton } from "@/v2/components/forms/icon-button.component";
import { UserCell } from "@/v2/components/table/user-cell.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { UpsertSurveyTemplateModal } from "@/v2/feature/growth/surveys/features/survey-template/survey-template-list/components/upsert-survey-template.component-modal";
import { themeColors } from "@/v2/styles/colors.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
import { LocalDate } from "@/v2/util/local-date";
export const SurveyTemplateGeneralPage = ({
  templateObject,
  refreshTemplate
}) => {
  const [isOpen, setIsOpen] = useState(false);
  let template;
  if (templateObject) {
    ({ template } = templateObject);
  }
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(
      Box,
      {
        className: "sub-title",
        sx: { display: "flex", justifyContent: "space-between", alignItems: "center", px: spacing.p16, mt: spacing.m4 },
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "headline2", children: "General" }),
          (template == null ? void 0 : template.companyId) && /* @__PURE__ */ jsx(
            IconButton,
            {
              colorVariant: "secondary",
              sizeVariant: "small",
              onClick: () => {
                setIsOpen(true);
              },
              children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", mt: spacing.m24, px: spacing.p16 }, children: [
      (template == null ? void 0 : template.name) && /* @__PURE__ */ jsx(ViewItem, { title: "Name", value: template == null ? void 0 : template.name }),
      (template == null ? void 0 : template.createdAt) && /* @__PURE__ */ jsx(
        ViewItem,
        {
          title: "Created on",
          value: new LocalDate(template == null ? void 0 : template.createdAt).getDate().toLocaleDateString(void 0, { day: "numeric", month: "short" })
        }
      ),
      (template == null ? void 0 : template.createdBy) && /* @__PURE__ */ jsx(ViewItem, { title: "Created by", value: /* @__PURE__ */ jsx(UserCell, { userId: template == null ? void 0 : template.createdBy }) })
    ] }),
    /* @__PURE__ */ jsx(
      UpsertSurveyTemplateModal,
      {
        setIsOpen,
        isOpen,
        onClose: () => {
          setIsOpen(false);
          refreshTemplate == null ? void 0 : refreshTemplate();
        },
        template
      }
    )
  ] });
};
const ViewItem = ({ title, value }) => {
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: {
        display: "inline-grid",
        gridTemplateColumns: "1.5fr 2.5fr",
        paddingY: spacing.p4
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { color: themeColors.Grey }, children: title }),
        typeof value === "string" ? /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: value }) : /* @__PURE__ */ jsx(Box, { children: value })
      ]
    }
  );
};
