"use strict";
export function displayUserName(u) {
  return u.displayName ? u.displayName : `${u.firstName} ${u.lastName}`;
}
export const PERSONAL_INFORMATION_GENDERS = ["male", "female", "non-binary"];
export const getGenderOptions = (polyglot) => {
  return [
    { value: "male", label: polyglot.t("getGenderOptions.male") },
    { value: "female", label: polyglot.t("getGenderOptions.female") },
    { value: "non-binary", label: polyglot.t("getGenderOptions.nonBinary") }
  ];
};
export const ABOUT_INFORMATION_DIETARY = [
  "dairy-free",
  "diabetes",
  "gluten-free",
  "keto",
  "kosher",
  "lactose-intolerant",
  "nut-allergies",
  "pescatarian",
  "vegan",
  "vegetarian"
];
export const getDietaryOptions = (polyglot) => {
  return [
    { value: "dairy-free", label: polyglot.t("getDietaroyOptions.dairyFree") },
    { value: "diabetes", label: polyglot.t("getDietaroyOptions.diabetes") },
    { value: "gluten-free", label: polyglot.t("getDietaroyOptions.glutenFree") },
    { value: "keto", label: polyglot.t("getDietaroyOptions.keto") },
    { value: "kosher", label: polyglot.t("getDietaroyOptions.kosher") },
    { value: "lactose-intolerant", label: polyglot.t("getDietaroyOptions.lactoseIntolerant") },
    { value: "nut-allergies", label: polyglot.t("getDietaroyOptions.nutAllergies") },
    { value: "pescatarian", label: polyglot.t("getDietaroyOptions.pescatarian") },
    { value: "vegan", label: polyglot.t("getDietaroyOptions.vegan") },
    { value: "vegetarian", label: polyglot.t("getDietaroyOptions.vegetarian") }
  ];
};
export var DietaryOptionsStatuses = /* @__PURE__ */ ((DietaryOptionsStatuses2) => {
  DietaryOptionsStatuses2["DAIRYFREE"] = "dairy-free";
  DietaryOptionsStatuses2["DIABETES"] = "diabetes";
  DietaryOptionsStatuses2["GLUTENFREE"] = "gluten-free";
  DietaryOptionsStatuses2["KETO"] = "keto";
  DietaryOptionsStatuses2["KOSHER"] = "kosher";
  DietaryOptionsStatuses2["LACTOSEINTOLERANT"] = "lactose-intolerant";
  DietaryOptionsStatuses2["NUTALLERGIES"] = "nut-allergies";
  DietaryOptionsStatuses2["PESCATARIAN"] = "pescatarian";
  DietaryOptionsStatuses2["VEAGN"] = "vegan";
  DietaryOptionsStatuses2["VEGETARIAN"] = "vegetarian";
  return DietaryOptionsStatuses2;
})(DietaryOptionsStatuses || {});
export const getDefaultEmployeeOption = (polyglot) => {
  return getNewEmployeeOption(polyglot);
};
const getNewEmployeeOption = (polyglot) => {
  return { value: "new employee", label: polyglot.t("getNewEmployeeOption.label") };
};
const getResignationOption = (polyglot) => {
  return { value: "resignation", label: polyglot.t("getResignationOption.label") };
};
const getPerformanceOption = (polyglot) => {
  return { value: "performance", label: polyglot.t("getPerformanceOption.label") };
};
const getLocationOption = (polyglot) => {
  return { value: "location", label: polyglot.t("getLocationOption.label") };
};
const getSalaryOption = (polyglot) => {
  return { value: "salary", label: polyglot.t("getSalaryOption.label") };
};
const getCultureOption = (polyglot) => {
  return { value: "culture", label: polyglot.t("getCultureOption.label") };
};
const getCareerProgressionOption = (polyglot) => {
  return { value: "career progression", label: polyglot.t("getCareerProgressionOption.label") };
};
const getPersonalOption = (polyglot) => {
  return { value: "personal", label: polyglot.t("getPersonalOption.label") };
};
const getMisconductOption = (polyglot) => {
  return { value: "misconduct", label: polyglot.t("getMisconductOption.label") };
};
const getContractEndedOption = (polyglot) => {
  return { value: "contract-ended", label: polyglot.t("getContractEndedOption.label") };
};
const getRedundancyOption = (polyglot) => {
  return { value: "redundancy", label: polyglot.t("getRedundancyOption.label") };
};
const getNoticeGivenOption = (polyglot) => {
  return { value: "notice given", label: polyglot.t("getNoticeGivenOption.label") };
};
const getRetirementOption = (polyglot) => {
  return { value: "retirement", label: polyglot.t("getRetirementOption.label") };
};
const getDidNotMeetExpectationOption = (polyglot) => {
  return {
    value: "did not meet expectations",
    label: polyglot.t("getDidNotMeetExpectationOption.label")
  };
};
const getSabbaticalOption = (polyglot) => {
  return {
    value: "sabbatical",
    label: polyglot.t("getSabbaticalOption.label")
  };
};
const getParentalLeaveOption = (polyglot) => {
  return {
    value: "parental leave",
    label: polyglot.t("getParentalLeaveOption.label")
  };
};
const getGardenLeaveOption = (polyglot) => {
  return {
    value: "garden leave",
    label: polyglot.t("getGardenLeaveOption.label")
  };
};
const getImportedOption = (polyglot) => {
  return {
    value: "Imported",
    label: polyglot.t("getImportedOption.label")
  };
};
export const getRehiredOption = (polyglot) => {
  return {
    value: "rehired",
    label: polyglot.t("getRehiredOption.label")
  };
};
export const getOtherOption = (polyglot) => {
  return {
    value: "other",
    label: polyglot.t("getOtherOption.label")
  };
};
export const getAllEmployedOptions = (polyglot) => {
  return [getNewEmployeeOption(polyglot), getRehiredOption(polyglot), getImportedOption(polyglot)];
};
export const getAllLeaveOptions = (polyglot) => {
  return [getSabbaticalOption(polyglot), getParentalLeaveOption(polyglot), getGardenLeaveOption(polyglot)];
};
export const getAllDefaultTerminationChangeReasonOptions = (polyglot) => {
  return [
    getResignationOption(polyglot),
    getRedundancyOption(polyglot),
    getNoticeGivenOption(polyglot),
    getDidNotMeetExpectationOption(polyglot),
    getRetirementOption(polyglot),
    getOtherOption(polyglot),
    getPerformanceOption(polyglot),
    getLocationOption(polyglot),
    getSalaryOption(polyglot),
    getCultureOption(polyglot),
    getCareerProgressionOption(polyglot),
    getPersonalOption(polyglot),
    getMisconductOption(polyglot),
    getContractEndedOption(polyglot)
  ];
};
export const getAllowancesUnits = (polyglot) => {
  return [
    { value: "day", label: polyglot.t("AllowanceType.days") },
    { value: "hour", label: polyglot.t("AllowanceType.hours") }
  ];
};
export const getAllChangeTypeOptions = (polyglot) => {
  return [
    ...getAllEmployedOptions(polyglot),
    ...getAllLeaveOptions(polyglot),
    ...getAllDefaultTerminationChangeReasonOptions(polyglot)
  ];
};
export const getRoleLastChangeTypeOptions = (polyglot) => {
  return [
    getNewEmployeeOption(polyglot),
    { value: "promotion", label: polyglot.t("getRoleLastChangeTypeOptions.promotion") },
    { value: "resignation", label: polyglot.t("getRoleLastChangeTypeOptions.resignation") },
    { value: "lateral move", label: polyglot.t("getRoleLastChangeTypeOptions.lateralMove") },
    { value: "rotation", label: polyglot.t("getRoleLastChangeTypeOptions.rotation") },
    { value: "demotion", label: polyglot.t("getRoleLastChangeTypeOptions.demotion") },
    { value: "transfer", label: polyglot.t("getRoleLastChangeTypeOptions.transfer") },
    { value: "manager change", label: polyglot.t("getRoleLastChangeTypeOptions.managerChange") },
    { value: "reorganisation", label: polyglot.t("getRoleLastChangeTypeOptions.reorganisation") },
    { value: "correction", label: polyglot.t("getRoleLastChangeTypeOptions.correction") },
    getRehiredOption(polyglot),
    { value: "other", label: polyglot.t("getRoleLastChangeTypeOptions.other") }
  ];
};
export const RIGHT_TO_WORK_DOCUMENT_TYPES = ["proofOfAddress", "passportOrId", "workPermitVisa"];
export const getRightToWorkDocumentTypeOptions = (polyglot) => {
  return [
    { value: "proofOfAddress", label: polyglot.t("getRightToWorkDocumentTypeOptions.proofOfAddress") },
    { value: "passportOrId", label: polyglot.t("getRightToWorkDocumentTypeOptions.passportOrId") },
    { value: "workPermitVisa", label: polyglot.t("getRightToWorkDocumentTypeOptions.workPermitVisa") }
  ];
};
export var MaritalStatus = /* @__PURE__ */ ((MaritalStatus2) => {
  MaritalStatus2["Married"] = "married";
  MaritalStatus2["Single"] = "single";
  MaritalStatus2["Partnership"] = "civil-partnership";
  MaritalStatus2["PreferNotToSay"] = "prefer-not-to-say";
  MaritalStatus2["Divorced"] = "divorced";
  MaritalStatus2["Widowed"] = "widowed";
  return MaritalStatus2;
})(MaritalStatus || {});
export const FAMILY_INFORMATION_MARITAL = Object.values(MaritalStatus);
export const getMaritalOptions = (polyglot) => {
  return [
    { value: "single" /* Single */, label: polyglot.t("getMaritalOptions.single") },
    { value: "married" /* Married */, label: polyglot.t("getMaritalOptions.married") },
    { value: "civil-partnership" /* Partnership */, label: polyglot.t("getMaritalOptions.civilPartnership") },
    { value: "divorced" /* Divorced */, label: polyglot.t("getMaritalOptions.divorced") },
    { value: "widowed" /* Widowed */, label: polyglot.t("getMaritalOptions.widowed") },
    { value: "prefer-not-to-say" /* PreferNotToSay */, label: polyglot.t("getMaritalOptions.preferNotToSay") }
  ];
};
export const PARTIALLY_COMPLETED_HELPER_TEXT = "You can preview preliminary results of some checks directly in Zinc. Once all checks are completed a report will be saved in Documents in Zelt";
export var IdCheckStatusEnum = /* @__PURE__ */ ((IdCheckStatusEnum2) => {
  IdCheckStatusEnum2["PENDING"] = "pending";
  IdCheckStatusEnum2["PARTIALLY_COMPLETED"] = "partially-completed";
  IdCheckStatusEnum2["COMPLETED"] = "completed";
  IdCheckStatusEnum2["UNKNOWN"] = "unknown";
  IdCheckStatusEnum2["CANCELLED"] = "cancelled";
  IdCheckStatusEnum2["ACTION_REQUIRED"] = "action-required";
  IdCheckStatusEnum2["REVIEWED"] = "reviewed";
  return IdCheckStatusEnum2;
})(IdCheckStatusEnum || {});
export const ID_CHECK_COMPLETED_STATES = /* @__PURE__ */ new Set([
  "completed" /* COMPLETED */,
  "reviewed" /* REVIEWED */,
  "action-required" /* ACTION_REQUIRED */
]);
export const BASE64_PDF_PREFIX = "data:application/octet-stream;base64,";
export const MANUAL_RTW_CHECK = "manual-rtw-check";
