"use strict";
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { SETTINGS_REQUEST_FORMS_ROUTE } from "@/lib/routes";
import { LoadingSpinner } from "@/v2/components/loader.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { NewRequestFormPage } from "@/v2/feature/requests/features/request-forms/new-request-form/new-request-form.page";
import { RequestFormsEndpoints } from "@/v2/feature/requests/request-forms.api";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { spacing } from "@/v2/styles/spacing.styles";
export const EditRequestFormPage = () => {
  var _a;
  const { formId: formIdParam } = useParams();
  const [template, setTemplate] = useState();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { data: templates, error } = useApiClient(RequestFormsEndpoints.getFormTemplates(), { suspense: false });
  useEffect(() => {
    if (!templates) return;
    const formId = Number(formIdParam);
    const template2 = templates.find((t) => t.id === formId);
    if (!template2) {
      showMessage("Request template not found", "error");
      routerHistory.replace(SETTINGS_REQUEST_FORMS_ROUTE);
      return;
    }
    setTemplate(template2);
  }, [formIdParam, routerHistory, showMessage, templates]);
  if (template) {
    return /* @__PURE__ */ jsx(NewRequestFormPage, { templateToEdit: template });
  }
  return /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, justifyContent: "center", mt: spacing.mt40 }, children: [
    !template && error && /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: (_a = error == null ? void 0 : error.data) == null ? void 0 : _a.message }),
    !template && !error && /* @__PURE__ */ jsx(LoadingSpinner, {})
  ] });
};
