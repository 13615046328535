"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { EnrollmentStatus, InHouseMdmAPI } from "@v2/feature/device/features/enrollment-device/in-house-mdm.api";
import { pusherClient } from "@v2/infrastructure/pusher/pusher.hook";
import { saveAs } from "file-saver";
import { generatePath, useHistory } from "react-router-dom";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as Mistake } from "@/images/side-bar-icons/Mistake.svg";
import { nestErrorMessage } from "@/lib/errors";
import { DEVICES_COMPANY_DEVICE_DETAILS_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { DevicePossessionType, EnrolmentType } from "@/v2/feature/device/device.interface";
import { PlanNames, UpgradeToProModal } from "@/v2/feature/user/components/upgrade-to-pro-modal.component";
import { buttonSettingSx } from "@/v2/feature/user/features/user-profile/details/components/styles.layout";
import { doesErrorRequireCompanyToUpgrade } from "@/v2/infrastructure/restrictions/restriction.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { settingCardSx } from "@/v2/styles/settings.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const GetKeyValue = ({
  key1,
  value1,
  key2,
  value2
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10, display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: [
        key1,
        ": "
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline3), children: value1 })
    ] }),
    /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.m10, display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
      /* @__PURE__ */ jsxs(Typography, { sx: __spreadValues({}, themeFonts.paragraph), children: [
        key2,
        ": "
      ] }),
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline3), children: value2 })
    ] })
  ] });
};
export const EnrollDevice = ({ devicePossession, refreshAppData }) => {
  const [showingState, setShowingState] = useState("enrolling");
  const compDeviceInfo = useRef(null);
  const compEnrollmentStatus = useRef(null);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const routerHistory = useHistory();
  const isProduction = process.env.REACT_APP_ENV === "production";
  const environmentPrefix = `${isProduction ? "prod" : "test"}`;
  const [showMessage] = useMessage();
  useEffect(() => {
    (() => __async(void 0, null, function* () {
      var _a;
      try {
        if (devicePossession.possessionType !== DevicePossessionType.User) {
          setShowingState("inventorydevice");
          return;
        }
        if (((_a = devicePossession.device) == null ? void 0 : _a.enrolmentType) === EnrolmentType.ABM_DEP) {
          setShowingState("depdevice");
          return;
        }
      } catch (error) {
        showMessage(`Could not retrieve enrollment data. ${nestErrorMessage(error)}`, "error");
      }
    }))();
    const enrollmentStatusHandler = (data) => {
      if (data.message) {
        compEnrollmentStatus.current = EnrollmentStatus[data.message];
        if (compDeviceInfo.current && compEnrollmentStatus.current === EnrollmentStatus.ENROLMENT_FINISHED) {
          setShowingState("enrolled");
          return;
        }
        if (compEnrollmentStatus.current !== EnrollmentStatus.ENROLMENT_FINISHED) {
          setShowingState("failed");
          return;
        }
        return;
      }
    };
    const deviceInfoStatusHandler = (data) => {
      if (data.message) {
        compDeviceInfo.current = JSON.parse(data.message);
        if (compDeviceInfo.current && compEnrollmentStatus.current && compEnrollmentStatus.current === EnrollmentStatus.ENROLMENT_FINISHED) {
          setShowingState("enrolled");
          return;
        }
        if (compEnrollmentStatus.current && compEnrollmentStatus.current !== EnrollmentStatus.ENROLMENT_FINISHED && !compDeviceInfo.current) {
          setShowingState("failed");
          return;
        }
        return;
      }
    };
    const standardChannel = pusherClient.subscribe(
      `mdm-${environmentPrefix}-device-id-${devicePossession.deviceId}`
    );
    standardChannel.bind(`device-info`, deviceInfoStatusHandler);
    standardChannel.bind(`enrollment-status`, enrollmentStatusHandler);
  }, [devicePossession, showMessage, environmentPrefix]);
  const downloadEnrollmentProfile = useCallback(() => __async(void 0, null, function* () {
    try {
      const profile = yield InHouseMdmAPI.getEnrolmentProfile(devicePossession.deviceId);
      const blob = new Blob([Buffer.from(profile, "base64")], {
        type: "application/x-apple-aspen-config"
      });
      saveAs(blob, "enrollment.mobileconfig");
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(`Could not download mdm config. ${nestErrorMessage(error)}`, "error");
      }
    }
  }), [devicePossession.deviceId, showMessage]);
  const getCurrentState = () => {
    switch (showingState) {
      case "enrolling":
        return /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g5 }, children: [
            /* @__PURE__ */ jsx(Mistake, { width: 20, height: 20, fill: themeColors.GreyMiddle }),
            /* @__PURE__ */ jsx(Typography, { sx: themeFonts.headline1, children: "Device not enroled" })
          ] }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Download Zelt MDM and secure your sensitive data, recover lost devices and configure settings. See your device status live on Zelt and manage it remotely when needed." }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10, color: themeColors.DarkGrey }), children: "Click download and install the configuration profile (enrollment.mobileconfig) to start the enrolment." }),
          /* @__PURE__ */ jsxs(
            Box,
            {
              sx: __spreadProps(__spreadValues({
                display: "flex",
                alignItems: "left"
              }, spacing.mt20), {
                gap: spacing.g5,
                width: { xs: "100%", sm: "50%", md: "50%", lg: "50%" }
              }),
              children: [
                /* @__PURE__ */ jsx(ButtonComponent, { sizeVariant: "small", colorVariant: "primary", onClick: downloadEnrollmentProfile, fullWidth: true, children: "Download" }),
                /* @__PURE__ */ jsx(
                  ButtonComponent,
                  {
                    sizeVariant: "small",
                    colorVariant: "secondary",
                    fullWidth: true,
                    onClick: () => window.open("https://zelt.app/blog/protect-your-device-with-zelt-mdm", "_blank"),
                    children: "Learn more"
                  }
                )
              ]
            }
          )
        ] });
      case "enrolled":
        return /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues(__spreadValues({}, themeFonts.headline1), spacing.mt40), children: "All done!" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mt: spacing.m5 }), children: "Your device has been successfully enrolled. You can sit back and enjoy your device securely. To see and manage this device go to Devices app." }),
          compDeviceInfo.current ? /* @__PURE__ */ jsx(
            GetKeyValue,
            {
              key1: "Device model",
              value1: compDeviceInfo.current.modelName,
              key2: "Serial number",
              value2: compDeviceInfo.current.serialNumber
            }
          ) : /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m10, display: "flex", alignItems: "center" }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Couldn't fetch the enrollment status." }) }),
          /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonSettingSx), { maxWidth: "35%", width: "auto", mt: spacing.m30 }), children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "primary",
              onClick: () => __async(void 0, null, function* () {
                yield refreshAppData();
                routerHistory.push(
                  generatePath(DEVICES_COMPANY_DEVICE_DETAILS_ROUTE, { devicePossessionId: devicePossession.id })
                );
              }),
              children: "Complete"
            }
          ) })
        ] });
      case "failed":
        return /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Try again" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey, mt: spacing.m5 }), children: "Looks like the profile installation was not successful. Please try again." }),
          /* @__PURE__ */ jsx(Box, { sx: __spreadProps(__spreadValues({}, buttonSettingSx), { maxWidth: "35%", width: "auto", mt: spacing.m30 }), children: /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "primary",
              fullWidth: true,
              onClick: downloadEnrollmentProfile,
              children: "Download"
            }
          ) })
        ] });
      case "inventorydevice":
        return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline2), children: "Use Zelt MDM " }),
          " ",
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: "Before enrolling this device into MDM, please ensure it is assigned to a user." })
        ] });
      case "depdevice":
        return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: 2 }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline2), children: "Use Zelt MDM " }),
          " ",
          /* @__PURE__ */ jsxs(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.DarkGrey }), children: [
            "Your device is eligible to automated-enrolment, wipe it or follow the instruction:",
            /* @__PURE__ */ jsx("p", { children: "Open the Terminal app on the required computer and perform the following steps:" }),
            /* @__PURE__ */ jsxs("ol", { children: [
              /* @__PURE__ */ jsx("li", { children: "Enter the command:" }),
              /* @__PURE__ */ jsx("pre", { children: /* @__PURE__ */ jsx("code", { children: "sudo profiles renew -type enrollment" }) }),
              /* @__PURE__ */ jsx("li", { children: "Press Return." }),
              /* @__PURE__ */ jsx("li", { children: "Enter the password for the local account and press Return." })
            ] }),
            /* @__PURE__ */ jsx("p", { children: "For macOS 14 or later:" }),
            /* @__PURE__ */ jsxs("ol", { children: [
              /* @__PURE__ */ jsx("li", { children: "Click 'Enroll' on the Remote Management screen." }),
              /* @__PURE__ */ jsx("li", { children: "Enter the password for the local account and click 'Enroll'." }),
              /* @__PURE__ */ jsx("li", { children: "A status of 'Enrollment Complete' will appear on the Remote Management screen." }),
              /* @__PURE__ */ jsx("li", { children: "Click 'Quit'." })
            ] }),
            /* @__PURE__ */ jsx("p", { children: "For macOS 13 or earlier:" }),
            /* @__PURE__ */ jsxs("ol", { children: [
              /* @__PURE__ */ jsx("li", { children: "Click 'Details' on the Device Enrolment notification banner in the upper right-hand corner of your screen." }),
              /* @__PURE__ */ jsx("li", { children: "If the banner is not immediately visible, click on the date in the upper right-hand corner of your Mac to display recent notifications, including the Device Enrollment banner." }),
              /* @__PURE__ */ jsx("li", { children: "Follow the prompts to install the required profiles." })
            ] }),
            /* @__PURE__ */ jsx("p", { children: "Enable 'Find My' or sign in to iCloud on the computer after enrolment to share its Activation Lock bypass code with Zelt." }),
            /* @__PURE__ */ jsx("p", { children: "Remember: If you enabled FileVault before enrolment and want to store FileVault keys in Zelt, you must escrow a new FileVault recovery key." })
          ] })
        ] });
      default:
        return /* @__PURE__ */ jsx(Fragment, { children: "Something went wrong" });
    }
  };
  return /* @__PURE__ */ jsxs(Box, { sx: settingCardSx, children: [
    getCurrentState(),
    /* @__PURE__ */ jsx(
      UpgradeToProModal,
      {
        isOpen: upgradeModalOpen,
        setIsDrawerOpen: (isOpen) => setUpgradeModalOpen(isOpen),
        planName: PlanNames.TECH_PRO,
        messageSuffix: "proGeneric"
      }
    )
  ] });
};
