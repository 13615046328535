"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FILE_UPLOAD_ENDPOINT } from "@/api-client/routes";
import useMessage from "@/hooks/notification.hook";
import { CustomPreview } from "@/v2/components/custom-preview.component";
import { Typography } from "@/v2/components/typography/typography.component";
import { borders } from "@/v2/styles/borders.styles";
import { neutralColors, themeColors } from "@/v2/styles/colors.styles";
import { radius } from "@/v2/styles/radius.styles";
import { Box } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import Dropzone from "react-dropzone-uploader";
import Resizer from "react-image-file-resizer";
import "react-dropzone-uploader/dist/styles.css";
import "./UploadInput.css";
export function UploadInput({
  maxFiles = 1,
  maxSizeBytes = 10485760,
  // 10MB
  value,
  onChange,
  endpoint = FILE_UPLOAD_ENDPOINT,
  disabled = false,
  skipUpload,
  displayText,
  displayTextColor = "DarkGrey",
  displayTextVariant = "paragraph",
  fileOwner,
  sx
}) {
  const { polyglot } = usePolyglot();
  const [resetKey, setResetKey] = useState(1);
  const [drag, setDrag] = useState(false);
  const [showMessage] = useMessage();
  const resizeImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      // max width
      800,
      // max height (maintains aspect ratio)
      "JPEG",
      // format
      100,
      // quality
      0,
      // rotation
      (uri) => __async(this, null, function* () {
        if (typeof uri === "string") {
          const response = yield fetch(uri);
          const blob = yield response.blob();
          const resizedFile = new File([blob], file.name, { type: "image/jpeg" });
          resolve(resizedFile);
        } else {
          resolve(uri);
        }
      }),
      "base64",
      500
      // max size in KB
    );
  });
  const preprocessFile = (fileWithMeta) => __async(this, null, function* () {
    const file = fileWithMeta.file;
    if (file.type.startsWith("image/")) {
      const resizedFile = yield resizeImage(file);
      fileWithMeta.meta = __spreadProps(__spreadValues({}, fileWithMeta.meta), { name: resizedFile.name });
      fileWithMeta.file = resizedFile;
    }
    return fileWithMeta;
  });
  const handleChangeStatus = (file, status, _allFiles) => {
    var _a, _b;
    if (status === "done") {
      onChange(((_a = file.xhr) == null ? void 0 : _a.response) ? JSON.parse((_b = file.xhr) == null ? void 0 : _b.response) : "", file.file, status);
      setDrag(false);
    } else if (status === "removed") {
      onChange(void 0, void 0, status);
      setDrag(false);
    } else if (status === "error_file_size") {
      onChange(void 0, void 0, status);
      setResetKey(resetKey + 1);
      setDrag(false);
      showMessage("File cannot be bigger than 10MB; please try uploading a smaller file.", "error");
    } else {
      onChange(void 0, file == null ? void 0 : file.file, status);
    }
  };
  useEffect(() => {
    if (!value) {
      setResetKey(resetKey + 1);
    }
  }, [value]);
  const getUploadParams = (fileWithMeta) => __async(this, null, function* () {
    const preprocessedFileWithMeta = yield preprocessFile(fileWithMeta);
    const url = endpoint;
    const params = { url, headers: {}, file: preprocessedFileWithMeta.file };
    if (fileOwner) params.headers = { fileOwner };
    return params;
  });
  return /* @__PURE__ */ jsx(
    Dropzone,
    {
      getUploadParams: skipUpload ? void 0 : getUploadParams,
      onChangeStatus: handleChangeStatus,
      PreviewComponent: (props) => /* @__PURE__ */ jsx(CustomPreview, __spreadValues({}, props)),
      canCancel: false,
      maxFiles,
      maxSizeBytes,
      styles: {
        dropzone: {
          border: "none",
          boxShadow: "none",
          overflow: "hidden",
          minHeight: "45px"
        },
        input: { border: "none", boxShadow: "none", backgroundColor: neutralColors.n0 },
        inputLabel: { width: "inherit", position: "inherit", backgroundColor: neutralColors.n0 }
      },
      inputContent: /* @__PURE__ */ jsxs(
        Box,
        {
          sx: __spreadValues({
            alignItems: "center",
            minHeight: "45px",
            cursor: disabled ? "not-allowed" : "pointer",
            width: "100%",
            mt: "5px"
          }, sx),
          children: [
            /* @__PURE__ */ jsxs(
              Box,
              {
                sx: {
                  pointerEvents: disabled ? "inherit" : "none",
                  opacity: disabled ? "0.4" : "1",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: "20px"
                },
                children: [
                  /* @__PURE__ */ jsx(
                    Box,
                    {
                      sx: {
                        width: "100%",
                        height: "36px",
                        border: drag ? borders.dashHover : borders.dash,
                        borderRadius: radius.br10,
                        color: themeColors.DarkGrey,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        pointerEvents: disabled ? "inherit" : "none",
                        opacity: disabled ? "0.4" : "1"
                      },
                      onDragEnter: () => {
                        setDrag(true);
                      },
                      onDragLeave: () => {
                        if (!value) {
                          setDrag(false);
                        }
                      },
                      children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("UploadInput.drop") })
                    },
                    1
                  ),
                  !drag && /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("UploadInput.or") }) }, 2),
                  !drag && /* @__PURE__ */ jsx(
                    Box,
                    {
                      sx: {
                        width: "100%",
                        height: "40px",
                        borderRadius: radius.br10,
                        backgroundColor: themeColors.Background,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&:hover": {
                          backgroundColor: themeColors.TableHover
                        }
                      },
                      children: /* @__PURE__ */ jsx(Typography, { variant: "headline3", children: polyglot.t("UploadInput.click") })
                    },
                    3
                  )
                ]
              },
              "drag"
            ),
            displayText && /* @__PURE__ */ jsx(Typography, { variant: displayTextVariant, color: displayTextColor, sx: { marginTop: "10px" }, children: displayText })
          ]
        },
        "input-content"
      )
    },
    resetKey
  );
}
