"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { useProfileFields } from "@/hooks/profile-fields.hook";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as InfoIcon } from "@/images/side-bar-icons/InfoCircleBlack.svg";
import { BasicTable } from "@/v2/components/table/basic-table.component";
import { sortBoolean, sortNumeric, sortString } from "@/v2/components/table/table-sorting.util";
import { StyledTooltip } from "@/v2/components/theme-components/styled-tooltip.component";
import { tableIconButtonSx } from "@/v2/styles/icon-button.styles";
import { iconSize } from "@/v2/styles/menu.styles";
import { spacing } from "@/v2/styles/spacing.styles";
export const GlobalPayrollPayCodesTable = ({ payCodes, onEditPayCodeClick }) => {
  const sortedPaycodes = useMemo(() => payCodes == null ? void 0 : payCodes.sort((a, b) => a.order - b.order), [payCodes]);
  const { getCustomFieldsForForm } = useProfileFields();
  const salaryFieldNamesByPaycode = useMemo(() => {
    const salaryCustomFields = getCustomFieldsForForm("salary");
    return new Map(
      salaryCustomFields.filter((f) => f.paycode).map((f, _, arr) => [
        f.paycode,
        arr.filter(({ paycode }) => paycode === f.paycode).map((field) => field.fieldName)
      ])
    );
  }, [getCustomFieldsForForm]);
  const columns = useMemo(
    () => [
      {
        id: "code",
        header: () => "Code",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.code),
        cell: (c) => /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: c.row.original.code }) })
      },
      {
        id: "name",
        header: () => "Name",
        accessorFn: (row) => row.name,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name, { sensitivity: "base" }),
        cell: (c) => /* @__PURE__ */ jsx(Box, { sx: { width: "250px" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { width: "100%", wordBreak: "break-word", whiteSpace: "normal" }, children: c.row.original.name }) })
      },
      {
        id: "required",
        header: () => "Required",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => item.required, true),
        cell: (c) => /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: c.row.original.required ? "Required" : "Optional" }) })
      },
      {
        id: "credit",
        header: () => "Credit",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.credit, { sensitivity: "base" }),
        cell: (c) => /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { width: "100%" }, children: c.row.original.credit }) })
      },
      {
        id: "debit",
        header: () => "Debit",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.debit, { sensitivity: "base" }),
        cell: (c) => /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { width: "100%" }, children: c.row.original.debit }) })
      },
      {
        id: "salary-field",
        header: () => "Salary field",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => salaryFieldNamesByPaycode.has(item.code)),
        cell: (c) => {
          var _a;
          return /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { width: "100%" }, children: ((_a = salaryFieldNamesByPaycode.get(c.row.original.code)) != null ? _a : []).join(", ") }) });
        }
      },
      {
        id: "default-value",
        header: () => "Default value",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortBoolean(a, b, (item) => !!item.formula),
        cell: (c) => /* @__PURE__ */ jsx(Box, { sx: { width: "100%", display: "flex", gap: spacing.s1, alignItems: "center" }, children: c.row.original.formula ? /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Yes" }),
          /* @__PURE__ */ jsx(
            StyledTooltip,
            {
              title: /* @__PURE__ */ jsx("div", { style: { whiteSpace: "pre" }, children: c.row.original.formula }),
              placement: "top",
              slotProps: { tooltip: { sx: { maxWidth: "fit-content" } } },
              children: /* @__PURE__ */ jsx(InfoIcon, __spreadProps(__spreadValues({}, iconSize), { tabIndex: 0 }))
            }
          )
        ] }) : /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "No" }) })
      },
      {
        id: "payrun-order",
        header: () => "Payrun display order",
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.order),
        cell: (c) => /* @__PURE__ */ jsx(Box, { sx: { width: "100%" }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", sx: { width: "100%" }, children: c.row.original.order }) })
      },
      {
        id: "action",
        header: () => "",
        accessorFn: (row) => row,
        enableSorting: false,
        cell: (c) => /* @__PURE__ */ jsx(Stack, { sx: { flexFlow: "row", alignItems: "center", gap: spacing.s1, justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
          IconButton,
          {
            sx: tableIconButtonSx,
            title: `Edit ${c.row.original.code}`,
            onClick: () => onEditPayCodeClick(c.row.original),
            children: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize))
          }
        ) })
      }
    ],
    [onEditPayCodeClick, salaryFieldNamesByPaycode]
  );
  return /* @__PURE__ */ jsx(BasicTable, { loading: !payCodes, rowData: sortedPaycodes != null ? sortedPaycodes : [], columnData: columns, hidePagination: true });
};
