"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { spacing } from "@v2/styles/spacing.styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as MistakeIcon } from "@/images/side-bar-icons/Mistake.svg";
import { ReactComponent as OkGreen } from "@/images/side-bar-icons/ok-green.svg";
import { ReactComponent as Waiting } from "@/images/side-bar-icons/Waiting.svg";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { TransactionStatus } from "@/v2/feature/payments/payments.dto";
import { PAYMENTS_ROUTE } from "@/v2/feature/payments/payments.router";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
function PaymentIconLiner({ icon, title, subtitle }) {
  return /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px" }, children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.g10, alignItems: "center" }, children: [
      icon,
      /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: title })
    ] }),
    /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10 }), children: subtitle })
  ] });
}
export const PaymentResultStep = () => {
  const routerHistory = useHistory();
  const transactionStatus = localStorage.getItem("transactionStatus");
  return /* @__PURE__ */ jsx(Box, { display: "flex", justifyContent: "center", children: /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "400px" }, children: [
    transactionStatus === TransactionStatus.COMPLETED && /* @__PURE__ */ jsx(
      PaymentIconLiner,
      {
        title: "Payment completed",
        subtitle: "You can now safely close this page",
        icon: /* @__PURE__ */ jsx(OkGreen, { width: 30, height: 30, style: { fill: themeColors.Green } })
      }
    ),
    transactionStatus === TransactionStatus.PENDING && /* @__PURE__ */ jsx(
      PaymentIconLiner,
      {
        title: "Payment in progress",
        subtitle: "You can now safely close this page",
        icon: /* @__PURE__ */ jsx(Waiting, { width: 30, height: 30, style: { fill: themeColors.Grey } })
      }
    ),
    transactionStatus === TransactionStatus.FAILED && /* @__PURE__ */ jsx(
      PaymentIconLiner,
      {
        title: "Payment failed",
        subtitle: "Payment could not be processed",
        icon: /* @__PURE__ */ jsx(MistakeIcon, { width: 30, height: 30 })
      }
    ),
    /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.m30 }, children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        fullWidth: true,
        sizeVariant: "medium",
        colorVariant: "secondary",
        onClick: () => routerHistory.push(PAYMENTS_ROUTE),
        children: "Close"
      }
    ) })
  ] }) });
};
