"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { CheckboxComponent } from "@v2/components/forms/checkbox.component";
import { SelectComponent } from "@v2/components/forms/select.component";
import { BasicTable } from "@v2/components/table/basic-table.component";
import { EmptyCell } from "@v2/components/table/empty-cell.component";
import { TableSearch } from "@v2/components/table/table-search.component";
import { sortNumeric, sortString } from "@v2/components/table/table-sorting.util";
import { LoaderButton } from "@v2/components/theme-components/loading-button.component";
import { Typography } from "@v2/components/typography/typography.component";
import { AbsenceAPI } from "@v2/feature/absence/absence.api";
import { convertMinutesToClockHours } from "@v2/feature/absence/absence.util";
import { HelperAbsenceCarryOverCalculationDrawer } from "@v2/feature/super-admin/features/helper-dashboard/components/absence/helper-absence-carry-over-calculation-drawer.component";
import { spacing } from "@v2/styles/spacing.styles";
import { keyBy } from "lodash";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
export const HelperAbsenceUsersCarryOverSection = ({ companyId, absencePolicies, users }) => {
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);
  const [carryOverYear, setCarryOverYear] = useState((/* @__PURE__ */ new Date()).getFullYear());
  const [searchInput, setSearchInput] = useState("");
  const [showCarryOver, setShowCarryOver] = useState(true);
  const [showOneOff, setShowOneOff] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isConfirmationDrawerOpen, setIsConfirmationDrawerOpen] = useState(false);
  const [usersAdjustments, setUsersAdjustments] = useState(null);
  const [showMessage] = useMessage();
  const usersMap = useMemo(() => keyBy(users, "userId"), [users]);
  const policiesMap = useMemo(() => keyBy(absencePolicies, "id"), [absencePolicies]);
  const getUsersCarryOverByYear = useCallback(() => __async(void 0, null, function* () {
    if (!selectedPolicyId || !carryOverYear) {
      showMessage("Please select a year and a policy first", "error");
      return;
    }
    try {
      setLoading(true);
      const usersAdjustments2 = yield AbsenceAPI.getCompanyUserAllowanceAdjustmentsByYearAsSuperAdmin(
        companyId,
        carryOverYear,
        selectedPolicyId
      );
      setUsersAdjustments(usersAdjustments2);
    } catch (error) {
      console.error(error);
      showMessage(`Could not get users carry over. ${nestErrorMessage(error)}`, "error");
    } finally {
      setLoading(false);
    }
  }), [selectedPolicyId, carryOverYear, companyId, showMessage]);
  const columns = useMemo(
    () => [
      {
        header: () => "User ID",
        id: "userId",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.userId),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.userId });
        }
      },
      {
        header: () => "User name",
        id: "firstName",
        enableSorting: true,
        sortingFn: (a, b) => sortString(
          a,
          b,
          (item) => usersMap[item.userId] ? `${usersMap[item.userId].firstName} ${usersMap[item.userId].lastName}` : ""
        ),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          const user = usersMap[original.userId];
          return user ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: `${user.firstName} ${user.lastName}` }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Policy",
        id: "policyId",
        accessorFn: (row) => row,
        enableSorting: false,
        // sortingFn: (a, b) =>
        //   sortString(a, b, (item) => (policiesMap[item.policyId] ? policiesMap[item.policyId].name : '')),
        cell: ({ row: { original } }) => {
          const policy = policiesMap[original.policyId];
          return policy ? /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: policy.name }) : /* @__PURE__ */ jsx(EmptyCell, {});
        }
      },
      {
        header: () => "Effective year",
        id: "effectiveYear",
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.effectiveYear });
        }
      },
      {
        header: () => "Type",
        id: "type",
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.type),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => {
          return /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.type });
        }
      },
      {
        header: () => "Adjustment (minutes)",
        id: "adjustment",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.adjustment) != null ? _a : 0;
        }),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: original.adjustment })
      },
      {
        header: () => "Adjustment (hours)",
        id: "adjustment_hours",
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => {
          var _a;
          return (_a = item.adjustment) != null ? _a : 0;
        }),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) => /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: convertMinutesToClockHours(original.adjustment, null) })
      }
    ],
    [usersMap, policiesMap]
  );
  const filteredUserAllowance = useMemo(() => {
    if (!usersAdjustments) return null;
    let filtered = !searchInput ? usersAdjustments : usersAdjustments.filter((userAdjustment) => {
      if (searchInput === String(userAdjustment.userId)) return true;
      const user = users.find((user2) => user2.userId === userAdjustment.userId);
      return user && `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchInput.toLowerCase());
    });
    if (showOneOff && showCarryOver) return filtered;
    return filtered.filter((r) => r.type === "one-off" && showOneOff || r.type === "carry-over" && showCarryOver);
  }, [usersAdjustments, searchInput, users, showCarryOver, showOneOff]);
  const yearsOptions = useMemo(() => {
    const policy = selectedPolicyId && absencePolicies.find((p) => p.id === selectedPolicyId);
    if (!policy || policy.cycleStartDay === 1 && policy.cycleStartMonth === 1)
      return [
        { label: "2021", value: 2021 },
        { label: "2022", value: 2022 },
        { label: "2023", value: 2023 },
        { label: "2024", value: 2024 },
        { label: "2025", value: 2025 },
        { label: "2026", value: 2026 }
      ];
    return [
      { label: "2021/22", value: 2021 },
      { label: "2022/23", value: 2022 },
      { label: "2023/24", value: 2023 },
      { label: "2024/25", value: 2024 },
      { label: "2025/26", value: 2025 },
      { label: "2026/27", value: 2026 }
    ];
  }, [absencePolicies, selectedPolicyId]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: { mt: "20px", mb: "10px" }, children: "Users allowance adjustments by year & policy" }),
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: spacing.gap20, alignItems: "end" }, children: [
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "policy",
          label: "Policy",
          options: (absencePolicies != null ? absencePolicies : []).map((policy) => ({
            label: `${policy.name} (${policy.id})`,
            value: policy.id
          })),
          value: selectedPolicyId,
          onChange: (e) => setSelectedPolicyId(Number(e.target.value)),
          sx: { width: "200px" }
        }
      ),
      /* @__PURE__ */ jsx(
        SelectComponent,
        {
          name: "effectiveYear",
          label: "Effective year",
          options: yearsOptions,
          value: carryOverYear,
          onChange: (e) => setCarryOverYear(Number(e.target.value)),
          sx: { width: "100px" }
        }
      ),
      /* @__PURE__ */ jsx(
        LoaderButton,
        {
          name: "Get Adjustments",
          onClick: getUsersCarryOverByYear,
          loading,
          disabled: !selectedPolicyId,
          sizeVariant: "medium",
          colorVariant: "primary"
        }
      )
    ] }),
    filteredUserAllowance ? /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
      /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", justifyContent: "space-between", gap: "20px" }, children: [
        /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", gap: "10px", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx(
            TableSearch,
            {
              query: searchInput,
              handleChange: (e) => {
                setSearchInput(e.target.value);
              },
              placeholder: "Search",
              style: { minWidth: "100px" }
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              name: "carry-over",
              label: "carry-over",
              checked: showCarryOver,
              onChange: () => setShowCarryOver((prev) => !prev)
            }
          ),
          /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              name: "one-off",
              label: "one-off",
              checked: showOneOff,
              onChange: () => setShowOneOff((prev) => !prev)
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          LoaderButton,
          {
            name: `Calculate Carry Over (${companyId})`,
            onClick: () => {
              setIsConfirmationDrawerOpen(true);
            },
            loading,
            disabled: !!selectedPolicyId && selectedPolicyId < 1,
            sizeVariant: "small",
            colorVariant: "secondary"
          }
        ),
        selectedPolicyId && /* @__PURE__ */ jsx(
          HelperAbsenceCarryOverCalculationDrawer,
          {
            isOpen: isConfirmationDrawerOpen,
            setIsOpen: setIsConfirmationDrawerOpen,
            absencePolicy: absencePolicies.find((p) => p.id === selectedPolicyId),
            companyId,
            carryOverYear,
            refresh: getUsersCarryOverByYear
          }
        )
      ] }),
      /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
        BasicTable,
        {
          rowData: filteredUserAllowance,
          columnData: columns,
          hidePagination: true,
          loading,
          noDataMessage: "Sorry, we couldn't find anything..."
        }
      ) }) })
    ] }) : /* @__PURE__ */ jsx(Box, { sx: { mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(Typography, { variant: "paragraph", children: "Please select a year and a policy, then click the button to get the carry over data." }) })
  ] });
};
