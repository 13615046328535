"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import { Typography } from "@v2/components/typography/typography.component";
import { ExpenseModal } from "@v2/feature/payments/pages/components/expense-modal.component";
import { GlobalContext } from "@/GlobalState";
import useScopes from "@/hooks/scopes.hook";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { PublicImageViewer } from "@/v2/components/upload/public-image-viewer.component";
export const PaymentExpensePreview = ({ expense, titleSx }) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;
  const { getScopesContext, hasScopes } = useScopes();
  const currentUserIsAdmin = hasScopes(["invoices:all"], getScopesContext(user));
  const [openExpenseModal, setOpenExpenseModal] = useState(false);
  return /* @__PURE__ */ jsxs(Box, { children: [
    /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "headline2", sx: titleSx, children: "Expense" }),
      /* @__PURE__ */ jsx(
        ButtonComponent,
        {
          colorVariant: "textUnderline",
          sizeVariant: "small",
          onClick: () => setOpenExpenseModal(true),
          style: { paddingRight: 0 },
          children: "View expense"
        }
      )
    ] }),
    expense.attachment && /* @__PURE__ */ jsx(PublicImageViewer, { fileName: expense.attachment, uploadName: "Uploaded receipt", hasView: true, hasDownload: true }),
    /* @__PURE__ */ jsx(
      ExpenseModal,
      {
        isOpen: openExpenseModal,
        setIsOpen: setOpenExpenseModal,
        selectedExpense: expense,
        onClose: () => setOpenExpenseModal(false),
        onActionPerformed: () => __async(void 0, null, function* () {
        }),
        currentUserIsAdmin
      }
    )
  ] });
};
