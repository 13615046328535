"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { Form, FormikProvider, useFormik } from "formik";
import useMessage from "@/hooks/notification.hook";
import { nestErrorMessage } from "@/lib/errors";
import { ApproverSelectComponent } from "@/v2/components/approver-select/approver-select.component";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import { CheckboxComponent } from "@/v2/components/forms/checkbox.component";
import { MultipleSelectCheckbox } from "@/v2/components/forms/multiple-select-checkbox.component";
import { SelectComponent } from "@/v2/components/forms/select.component";
import { TextfieldComponent } from "@/v2/components/forms/textfield.component";
import { TabFilterButtons } from "@/v2/components/tab-filter-buttons.component";
import "@/v2/feature/dashboard/features/sections/user-calendar/user-calendar-widget.scss";
import {
  CustomNotificationType,
  DOMAINS_WITH_AFTER,
  DOMAINS_WITH_BEFORE,
  NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK,
  NotificationSystemName,
  WhenFilterEnum,
  WhenFilterOptions
} from "@/v2/feature/notification/notification-settings/notification.interface";
import { addDefaultUserDateFields } from "@/v2/feature/notification/notification.util";
import { ProfileNotificationAPI } from "@/v2/feature/notification/profile-notification.api";
import { HOUR_OF_THE_DAY_OPTIONS } from "@/v2/feature/user/features/user-notifications/user-notifications.util";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const NOTIFICATIONS_WITH_BEFORE_OPTION_ENABLED = [
  NotificationSystemName.documentExpiry,
  NotificationSystemName.probationPeriod
];
export const NotificationsSettingAddFormDrawer = ({
  notificationToEdit,
  setIsOpen,
  allSlackChannels,
  slackConnected,
  refreshNotificationData,
  allDateFields
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState(
    notificationToEdit.systemName && DOMAINS_WITH_BEFORE.includes(notificationToEdit.systemName) && (notificationToEdit == null ? void 0 : notificationToEdit.daysBefore) && notificationToEdit.daysBefore > 0 ? WhenFilterEnum.BEFORE : WhenFilterEnum.IMMEDIATE
  );
  const mapValuesToPayload = (values) => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2;
    const now = /* @__PURE__ */ new Date();
    const {
      emailEnabled,
      slackEnabled,
      slackChannels,
      sendToEditValue,
      cronHour,
      employeeTriggerEvent,
      name,
      notificationText,
      sendSlackBot
    } = values;
    const slackChannelValues = slackChannels == null ? void 0 : slackChannels.map((d) => d.value);
    const [fieldId, formId] = (_a2 = employeeTriggerEvent != null ? employeeTriggerEvent : "") == null ? void 0 : _a2.split("/");
    const record = {
      companyId: 0,
      createdAt: now,
      updatedAt: now,
      id: 0,
      domain: CustomNotificationType.PROFILE_NOTIFICATION,
      sendAdmin: (_b2 = sendToEditValue == null ? void 0 : sendToEditValue.presets) == null ? void 0 : _b2.includes("admin"),
      sendManager: (_c2 = sendToEditValue == null ? void 0 : sendToEditValue.presets) == null ? void 0 : _c2.includes("manager:1"),
      sendSpecificUsers: ((_d2 = sendToEditValue == null ? void 0 : sendToEditValue.userIds) == null ? void 0 : _d2.length) > 0 ? true : false,
      email: emailEnabled,
      specificUserEmails: sendToEditValue == null ? void 0 : sendToEditValue.userIds,
      daysBefore: (_e2 = filterValue && filterValue === WhenFilterEnum.BEFORE ? values.daysBefore : 0) != null ? _e2 : 0,
      daysAfter: (_f2 = filterValue && filterValue === WhenFilterEnum.AFTER ? values.daysAfter : 0) != null ? _f2 : 0,
      slackChannels: slackChannelValues != null ? slackChannelValues : [],
      slack: (_g2 = slackEnabled || sendSlackBot) != null ? _g2 : false,
      cronHour: cronHour != null ? cronHour : 6,
      fieldId,
      formId,
      name,
      notificationText,
      sendSlackBot: sendSlackBot != null ? sendSlackBot : false
    };
    return record;
  };
  const handleSubmit = (values) => __async(void 0, null, function* () {
    try {
      if (values) {
        const record = mapValuesToPayload(values);
        yield ProfileNotificationAPI.createNewProfileNotification(record);
      }
      refreshNotificationData();
      setIsOpen(false);
      formik.resetForm();
      showMessage(polyglot.t("NotificationsSettingAddFormDrawer.successMessages.create"), "success");
    } catch (e) {
      showMessage(polyglot.t("NotificationsSettingsEdit.errorMessages.cannotbeSaved"), "error");
    }
  });
  const formik = useFormik({
    initialValues: __spreadValues({}, notificationToEdit),
    // validationSchema: yup.object({
    //   TODO
    // }),
    enableReinitialize: true,
    onSubmit: (values) => __async(void 0, null, function* () {
      handleSubmit(values);
    })
  });
  useEffect(() => {
    if (!notificationToEdit) {
      setFilterValue(WhenFilterEnum.IMMEDIATE);
      return;
    }
    const { systemName, daysAfter, daysBefore } = notificationToEdit;
    if (systemName && DOMAINS_WITH_AFTER.includes(systemName) && daysAfter && daysAfter > 0) {
      setFilterValue(WhenFilterEnum.AFTER);
    } else if (systemName && DOMAINS_WITH_BEFORE.includes(systemName) && daysBefore && daysBefore > 0) {
      setFilterValue(WhenFilterEnum.BEFORE);
    } else {
      setFilterValue(WhenFilterEnum.IMMEDIATE);
    }
  }, [notificationToEdit]);
  const testCustomNotification = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      const record = mapValuesToPayload(formik.values);
      yield ProfileNotificationAPI.testProfileNotification(record);
      showMessage(polyglot.t("NotificationsSettingAddFormDrawer.successMessages.test"), "success");
    } catch (error) {
      showMessage(
        polyglot.t("NotificationsSettingAddFormDrawer.errorMessages.test", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  const saveAndEnable = () => __async(void 0, null, function* () {
    try {
      setLoading(true);
      yield handleSubmit(formik.values);
    } catch (error) {
      showMessage(
        polyglot.t("NotificationsSettingAddFormDrawer.errorMessages.save", {
          errorMessage: nestErrorMessage(error)
        }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  });
  const handleCronHourChange = (event) => {
    var _a2;
    formik.setFieldValue("cronHour", (_a2 = event.target) == null ? void 0 : _a2.value);
  };
  const handleEmployeeTriggerEvent = (event) => {
    var _a2;
    formik.setFieldValue("employeeTriggerEvent", (_a2 = event.target) == null ? void 0 : _a2.value);
  };
  const allDateFieldOptions = useMemo(() => {
    const result = [];
    if (allDateFields && allDateFields.dataFieldsCustom) {
      for (const eachCustom of allDateFields.dataFieldsCustom) {
        result.push({
          value: `${eachCustom.fieldId}/${eachCustom.formId}`,
          label: eachCustom.fieldName
        });
      }
    }
    return addDefaultUserDateFields(result);
  }, [allDateFields]);
  const sendToAdmin = (_c = (_b = (_a = formik.values) == null ? void 0 : _a.sendToEditValue) == null ? void 0 : _b.presets) == null ? void 0 : _c.includes("admin");
  const sendToManager = (_f = (_e = (_d = formik.values) == null ? void 0 : _d.sendToEditValue) == null ? void 0 : _e.presets) == null ? void 0 : _f.includes("manager:1");
  const suffixLabelForSlackBot = useMemo(() => {
    const botRecipients = [];
    if (sendToAdmin) botRecipients.push("Admin");
    if (sendToManager) botRecipients.push("Manager");
    return botRecipients.length > 0 ? ` to ${botRecipients.join(", ")}` : "";
  }, [sendToAdmin, sendToManager]);
  return /* @__PURE__ */ jsx(FormikProvider, { value: formik, children: /* @__PURE__ */ jsxs(Form, { children: [
    /* @__PURE__ */ jsx(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g20 }, children: /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: polyglot.t("NotificationSettingsPage.newNotification") }) }),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, spacing.mt30), {
          display: "flex",
          flexDirection: "row",
          gap: spacing.g20,
          justifyContent: "space-between"
        }),
        children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("NotificationsSettingAddFormDrawer.notificationName"),
            name: "name",
            value: formik.values.name,
            onChange: formik.handleChange,
            error: formik.touched.name && !!formik.errors.name,
            helperText: formik.touched.name && formik.errors.name,
            type: "text",
            clearText: () => formik.setFieldValue("name", "")
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      Box,
      {
        sx: __spreadProps(__spreadValues({}, spacing.mt15), {
          display: "flex",
          flexDirection: "row",
          gap: spacing.g20,
          justifyContent: "space-between"
        }),
        children: /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            label: polyglot.t("NotificationsSettingAddFormDrawer.notificationText"),
            name: "notificationText",
            value: formik.values.notificationText,
            onChange: formik.handleChange,
            error: formik.touched.notificationText && !!formik.errors.notificationText,
            helperText: formik.touched.notificationText && formik.errors.notificationText,
            type: "text",
            clearText: () => formik.setFieldValue("notificationText", "")
          }
        )
      }
    ),
    /* @__PURE__ */ jsxs(Stack, { display: "flex", direction: "column", sx: __spreadValues({}, spacing.mt20), children: [
      /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingAddFormDrawer.sendTo") }),
      /* @__PURE__ */ jsx(
        ApproverSelectComponent,
        {
          hideEveryone: formik.values.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) && ((_g = formik.values.sendToEditValue.userIds) == null ? void 0 : _g.length) === 0 ? false : true,
          hidePresets: formik.values.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) ? ["admin", "manager:1"] : ["everyone"],
          hideSpecific: formik.values.systemName && NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) && !formik.values.sendToEditValue.presets.includes("everyone") || formik.values.systemName && !NOTIFICATIONS_SYSTEM_NAMES_WITH_SLACK.includes(formik.values.systemName) ? false : true,
          hideAutoApprove: true,
          value: formik.values.sendToEditValue,
          userModalTitle: polyglot.t("NotificationsSettingAddFormDrawer.userModalTitle"),
          onChange: (value) => {
            formik.setFieldValue("sendToEditValue", value);
          },
          sx: { mb: spacing.mb20 }
        }
      ),
      notificationToEdit && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt15 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingsEdit.employeeTriggerEvent") }),
        allDateFieldOptions.length > 0 ? /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "employeeTriggerEvent",
            label: "",
            options: allDateFieldOptions,
            value: formik.values.employeeTriggerEvent,
            compareValue: formik.values.employeeTriggerEvent,
            error: void 0,
            onChange: handleEmployeeTriggerEvent,
            helperText: void 0,
            fullWidth: true
          }
        ) : /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.black }), children: polyglot.t("NotificationsSettingAddFormDrawer.noCustomDate") })
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt15 }, gap: spacing.g10, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingAddFormDrawer.sendNotification") }),
        /* @__PURE__ */ jsx(
          TabFilterButtons,
          {
            disabled: (notificationToEdit == null ? void 0 : notificationToEdit.systemName) && !NOTIFICATIONS_WITH_BEFORE_OPTION_ENABLED.includes(notificationToEdit == null ? void 0 : notificationToEdit.systemName),
            filters: WhenFilterOptions(polyglot),
            setFilterValue,
            filterValue,
            onFilterChange: ({ filterValue: filterValue2 }) => {
              setFilterValue(filterValue2);
              formik.setFieldValue("when", filterValue2);
            }
          }
        ),
        filterValue === WhenFilterEnum.BEFORE && /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "daysBefore",
            label: polyglot.t("NotificationsSettingAddFormDrawer.daysBefore"),
            value: formik.values.daysBefore,
            type: "number",
            onChange: formik.handleChange,
            error: formik.touched.daysBefore && !!formik.errors.daysBefore,
            helperText: (_h = formik.touched.daysBefore && formik.errors.daysBefore) != null ? _h : " ",
            clearText: () => formik.setFieldValue("daysBefore", "")
          }
        ),
        filterValue === WhenFilterEnum.AFTER && /* @__PURE__ */ jsx(
          TextfieldComponent,
          {
            name: "daysAfter",
            label: polyglot.t("NotificationsSettingAddFormDrawer.daysAfter"),
            value: formik.values.daysAfter,
            type: "number",
            onChange: formik.handleChange,
            error: formik.touched.daysAfter && !!formik.errors.daysAfter,
            helperText: (_i = formik.touched.daysAfter && formik.errors.daysAfter) != null ? _i : " ",
            clearText: () => formik.setFieldValue("daysAfter", "")
          }
        )
      ] }),
      /* @__PURE__ */ jsxs(Stack, { sx: { mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingAddFormDrawer.channel") }),
        notificationToEdit && /* @__PURE__ */ jsx(Box, { sx: { display: "inline-flex", alignItems: "center", mt: spacing.mt10 }, children: /* @__PURE__ */ jsx(
          CheckboxComponent,
          {
            label: polyglot.t("NotificationsSettingAddFormDrawer.email"),
            name: "emailEnabledSetting",
            checked: formik.values.emailEnabled,
            value: formik.values.emailEnabled,
            onChange: (e, checked) => {
              formik.setFieldValue("emailEnabled", checked);
            }
          }
        ) }),
        notificationToEdit && slackConnected && /* @__PURE__ */ jsxs(Fragment, { children: [
          (sendToAdmin || sendToManager) && /* @__PURE__ */ jsx(Box, { sx: { display: "inline-flex", alignItems: "center", mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: polyglot.t("NotificationsSettingAddFormDrawer.slackBotSettings", {
                suffix: suffixLabelForSlackBot
              }),
              name: "slackBotSetting",
              checked: formik.values.sendSlackBot,
              value: formik.values.sendSlackBot,
              onChange: (e, checked) => {
                formik.setFieldValue("sendSlackBot", checked);
              }
            }
          ) }),
          /* @__PURE__ */ jsx(Box, { sx: { display: "inline-flex", alignItems: "center", mt: spacing.mt20 }, children: /* @__PURE__ */ jsx(
            CheckboxComponent,
            {
              label: polyglot.t("NotificationsSettingsEdit.slackChannels"),
              name: "slackEnabledSetting",
              checked: formik.values.slackEnabled,
              value: formik.values.slackEnabled,
              onChange: (e, checked) => {
                formik.setFieldValue("slackEnabled", checked);
                const updatedValues = checked && formik.values.slackChannels ? [...formik.values.slackChannels] : [];
                formik.setFieldValue("slackChannels", updatedValues);
              }
            }
          ) })
        ] })
      ] }),
      notificationToEdit && slackConnected && formik.values.slackEnabled && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingsEdit.slackChannels") }),
        /* @__PURE__ */ jsx(
          MultipleSelectCheckbox,
          {
            label: "",
            id: "slackChannel",
            limitTags: -1,
            options: allSlackChannels,
            value: (_j = formik.values.slackChannels) != null ? _j : [],
            onChange: (e, values) => {
              formik.setFieldValue("slackChannels", values);
            },
            isOptionEqualToValue: (x, y) => x.value === y.value,
            getOptionLabel: ({ label }) => label
          }
        )
      ] }),
      notificationToEdit && formik.values && /* @__PURE__ */ jsxs(Box, { sx: { mt: spacing.mt20 }, children: [
        /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { color: themeColors.Grey }), children: polyglot.t("NotificationsSettingsEdit.hourToSendReminders") }),
        /* @__PURE__ */ jsx(
          SelectComponent,
          {
            name: "cronHour",
            label: "",
            options: HOUR_OF_THE_DAY_OPTIONS,
            value: formik.values.cronHour,
            compareValue: formik.values.cronHour,
            error: void 0,
            onChange: handleCronHourChange,
            helperText: void 0,
            fullWidth: true
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsxs(
      Box,
      {
        sx: __spreadValues({
          display: "flex",
          justifyContent: "space-between",
          gap: spacing.g10,
          width: "100%"
        }, spacing.mt30),
        children: [
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              colorVariant: "secondary",
              sizeVariant: "medium",
              disabled: !formik.values.slackEnabled && !formik.values.emailEnabled,
              fullWidth: true,
              onClick: testCustomNotification,
              children: polyglot.t("General.sendATest")
            }
          ),
          /* @__PURE__ */ jsx(
            ButtonComponent,
            {
              sizeVariant: "medium",
              colorVariant: "primary",
              disabled: loading || !formik.values.employeeTriggerEvent,
              fullWidth: true,
              onClick: saveAndEnable,
              children: polyglot.t("General.saveAndEnable")
            }
          )
        ]
      }
    )
  ] }) });
};
