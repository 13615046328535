"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { TableSearch } from "@v2/components/table/table-search.component";
import { SkeletonLoader } from "@v2/feature/dashboard/components/skeleton-loader.component";
import {
  DeviceConditionValueLabelOptions,
  DeviceOSValueLabelOptions,
  DeviceShopFilter,
  DeviceTypeFilterOptions
} from "@v2/feature/device/device.util";
import { filterStringToObject } from "@v2/feature/user/user.util";
import { spacing } from "@v2/styles/spacing.styles";
import { CategoryFilters } from "@/v2/components/table/category-filters.component";
import { DrawerModal } from "@/v2/components/theme-components/drawer-modal.component";
import { ContentWrapper } from "@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component";
import { TopHeader } from "@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component";
import { DeviceEndpoints } from "@/v2/feature/device/device.api";
import {
  DevicePreviewComponent,
  DevicePreviewSkeleton
} from "@/v2/feature/device/features/devices-company/components/device-preview.component";
import { OrderDevicesForm } from "@/v2/feature/device/features/devices-company/components/order-devices-form.component";
import { useApiClient } from "@/v2/infrastructure/api-client/api-client.hook";
import { primarySmallBtn } from "@/v2/styles/buttons.styles";
import { themeColors } from "@/v2/styles/colors.styles";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { RootStyle } from "@/v2/styles/root.styles";
const GetDeviceList = ({
  cart,
  setCart
}) => {
  const { data: availableDeviceModels } = useApiClient(DeviceEndpoints.getDeviceModelsByCompanyId());
  const [searchInput, setSearchInput] = useState("");
  const [filterString, setFilterString] = useState("");
  const [filterTypes, setFilterTypes] = useState({});
  const getRams = useCallback(() => {
    let filteredDeviceModels = availableDeviceModels != null ? availableDeviceModels : [];
    const nonNullRamValues = filteredDeviceModels.map((row) => row.ram).filter((value) => value !== null);
    const uniqueRamValues = Array.from(new Set(nonNullRamValues));
    uniqueRamValues.sort((a, b) => a - b);
    const ramValueLabelOptions = uniqueRamValues.map((value) => ({
      label: `${value}GB`,
      value
    }));
    return ramValueLabelOptions;
  }, [availableDeviceModels]);
  const getStorage = useCallback(() => {
    let filteredDeviceModels = availableDeviceModels != null ? availableDeviceModels : [];
    const nonNullValues = filteredDeviceModels.map((row) => row.storage).filter((value) => value !== null);
    const uniqueValues = Array.from(new Set(nonNullValues));
    uniqueValues.sort((a, b) => a - b);
    const storageValueLabelOptions = uniqueValues.map((value) => ({
      label: `${value}GB`,
      value
    }));
    return storageValueLabelOptions;
  }, [availableDeviceModels]);
  const getManufacturer = useCallback(() => {
    let filteredDeviceModels = availableDeviceModels != null ? availableDeviceModels : [];
    const nonNullValues = filteredDeviceModels.map((row) => row.manufacturer).filter((value) => value !== null);
    const uniqueValues = Array.from(new Set(nonNullValues));
    uniqueValues.sort((a, b) => a.localeCompare(b));
    const manufacturerValueLabelOptions = uniqueValues.map((value) => ({
      label: `${value}`,
      value: value.toLowerCase()
    }));
    return manufacturerValueLabelOptions;
  }, [availableDeviceModels]);
  const getFilterOptions = useCallback(() => __async(void 0, null, function* () {
    const FILTERS = [
      DeviceShopFilter.Manufacturer,
      DeviceShopFilter.Type,
      DeviceShopFilter.RAM,
      DeviceShopFilter.Storage,
      DeviceShopFilter.OS,
      DeviceShopFilter.Condition
    ];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case DeviceShopFilter.Manufacturer:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: getManufacturer() });
          break;
        case DeviceShopFilter.Type:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: DeviceTypeFilterOptions });
          break;
        case DeviceShopFilter.RAM:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: getRams() });
          break;
        case DeviceShopFilter.Storage:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: getStorage() });
          break;
        case DeviceShopFilter.OS:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: DeviceOSValueLabelOptions });
          break;
        case DeviceShopFilter.Condition:
          filters = __spreadProps(__spreadValues({}, filters), { [filter]: DeviceConditionValueLabelOptions });
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }), [getRams, getStorage, getManufacturer]);
  const tableData = useMemo(() => {
    let filteredDeviceModels = availableDeviceModels != null ? availableDeviceModels : [];
    if (availableDeviceModels && searchInput) {
      filteredDeviceModels = filteredDeviceModels.filter((deviceModel) => {
        const modelName = deviceModel.modelName;
        const id = deviceModel.id;
        return (modelName == null ? void 0 : modelName.toLowerCase().includes(searchInput.toLowerCase())) || String(id).includes(searchInput.toLowerCase());
      });
    }
    if (availableDeviceModels && filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case DeviceShopFilter.Manufacturer: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a;
                  return model.manufacturer && ((_a = filterOptions[key]) == null ? void 0 : _a.includes(model.manufacturer.toLowerCase()));
                }
              );
              break;
            }
            case DeviceShopFilter.Type: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a;
                  return model.type && ((_a = filterOptions[key]) == null ? void 0 : _a.includes(model.type));
                }
              );
              break;
            }
            case DeviceShopFilter.RAM: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a;
                  return model.ram && ((_a = filterOptions[key]) == null ? void 0 : _a.includes(String(model.ram)));
                }
              );
              break;
            }
            case DeviceShopFilter.Storage: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a;
                  return model.storage && ((_a = filterOptions[key]) == null ? void 0 : _a.includes(String(model.storage)));
                }
              );
              break;
            }
            case DeviceShopFilter.OS: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => {
                  var _a;
                  return model.os && ((_a = filterOptions[key]) == null ? void 0 : _a.includes(model.os.toLowerCase()));
                }
              );
              break;
            }
            case DeviceShopFilter.Condition: {
              break;
            }
            default:
              break;
          }
        }
      }
    }
    return filteredDeviceModels;
  }, [availableDeviceModels, filterString, searchInput]);
  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);
  return /* @__PURE__ */ jsxs(Box, { children: [
    availableDeviceModels && availableDeviceModels.length > 0 && /* @__PURE__ */ jsxs(
      Box,
      {
        sx: {
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          gap: spacing.g5
        },
        children: [
          filterTypes && /* @__PURE__ */ jsx(CategoryFilters, { filterTypes, setFilterString, filterString }),
          /* @__PURE__ */ jsx(TableSearch, { query: searchInput, handleChange: (e) => setSearchInput(e.target.value) })
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Grid,
      {
        container: true,
        columnGap: { xl: "40px", lg: "40px", md: "30px", sm: "20px", xs: "30px" },
        rowGap: { xl: "40px", lg: "40px", md: "30px", sm: "20px", xs: "30px" },
        sx: { marginTop: spacing.m30 },
        children: tableData && tableData.map((device) => /* @__PURE__ */ jsx(Grid, { item: true, xl: 2, lg: 3, md: 4, sm: 6, xs: 8, children: /* @__PURE__ */ jsx(DevicePreviewComponent, { deviceModel: device, cart, setCart }) }, device.id))
      }
    )
  ] });
};
export const DeviceStorePage = ({ loading, refresh, reach }) => {
  const [cart, setCart] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  return /* @__PURE__ */ jsxs(RootStyle, { children: [
    /* @__PURE__ */ jsx(
      TopHeader,
      {
        title: /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.headline1), { color: themeColors.DarkGrey }), children: "Devices" }),
        actions: /* @__PURE__ */ jsxs(Button, { sx: primarySmallBtn, onClick: () => setIsOpen(true), children: [
          "Cart : ",
          cart.length
        ] }),
        showAction: cart.length > 0
      }
    ),
    /* @__PURE__ */ jsx(ContentWrapper, { loading, children: /* @__PURE__ */ jsx(
      DeviceStoreView,
      {
        cartState: [cart, setCart],
        orderFormOpen: isOpen,
        reach,
        onClose: () => __async(void 0, null, function* () {
          yield refresh();
          setIsOpen(false);
          setCart([]);
        })
      }
    ) })
  ] });
};
export const DeviceStoreView = ({
  cartState,
  orderFormOpen,
  reach,
  onClose,
  possessionId,
  sx
}) => {
  const [cart, setCart] = cartState;
  return /* @__PURE__ */ jsxs(Box, { sx, children: [
    /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(Grid, { container: true, spacing: { lg: "40px", sm: "20px" }, children: [1, 2, 3, 4, 5, 6, 7].map((n) => /* @__PURE__ */ jsx(Grid, { item: true, xl: 2, lg: 3, md: 4, sm: 6, xs: 8, children: /* @__PURE__ */ jsx(DevicePreviewSkeleton, {}) }, n)) }),
        children: /* @__PURE__ */ jsx(GetDeviceList, { cart, setCart })
      }
    ),
    cart[0] && /* @__PURE__ */ jsx(DrawerModal, { isOpen: orderFormOpen, setIsOpen: () => onClose(false), children: /* @__PURE__ */ jsx(
      Suspense,
      {
        fallback: /* @__PURE__ */ jsx(
          SkeletonLoader,
          {
            variant: "rectangular",
            width: "90%",
            height: "90vh",
            sx: { borderRadius: "10px", mx: "auto", mt: 4, backgroundColor: themeColors.Background }
          }
        ),
        children: /* @__PURE__ */ jsx(OrderDevicesForm, { deviceModel: cart[0], close: onClose, reach, possessionId })
      }
    ) })
  ] });
};
