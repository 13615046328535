"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ReactComponent as PensionBig } from "@/images/pension/PensionBig.svg";
import { ReactComponent as ProfiBig } from "@/images/pension/ProfiBig.svg";
import { ReactComponent as WalletBig } from "@/images/pension/WalletBig.svg";
import { SETTINGS_BENEFITS_ROUTE } from "@/lib/routes";
import { ButtonComponent } from "@/v2/components/forms/button.component";
import {
  IntroCardGroup
} from "@/v2/components/theme-components/icon-content-action-card.component";
import { themeFonts } from "@/v2/styles/fonts.styles";
import { spacing } from "@/v2/styles/spacing.styles";
const emptyStates = [
  {
    title: "Integrated with payroll",
    content: "Add new joiners, sync contributions and remove leavers automatically without logging in.",
    iconMedia: /* @__PURE__ */ jsx(WalletBig, {})
  },
  {
    title: "Engage employees",
    content: "Allow your team to select themselves which plan and contribution levels work best for them. ",
    iconMedia: /* @__PURE__ */ jsx(ProfiBig, {})
  },
  {
    title: "Save on NI",
    content: "Use salary schemes for pension, healthcare and other user-benefits to save taxes. Let employees choose their options and reconcile automatically with payroll.",
    iconMedia: /* @__PURE__ */ jsx(PensionBig, {})
  }
];
export const PensionEmptyState = () => {
  const routerHistory = useHistory();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      sx: __spreadValues({
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: spacing.g60
      }, spacing.px40),
      children: [
        /* @__PURE__ */ jsxs(Box, { sx: { maxWidth: "600px" }, children: [
          /* @__PURE__ */ jsx(Typography, { sx: __spreadValues({}, themeFonts.headline1), children: "Workplace pension" }),
          /* @__PURE__ */ jsx(Typography, { sx: __spreadProps(__spreadValues({}, themeFonts.paragraph), { mt: spacing.m10 }), children: "Arrange a retirement pot for your employees in Zelt. Fully integrated in Payroll. Managed from the central hub and accessible by your team. Workplace pension is a requirement for UK employers." }),
          /* @__PURE__ */ jsx(
            Box,
            {
              sx: {
                display: "flex",
                justifyContent: "flex-start",
                gap: spacing.g10,
                width: "70%",
                marginTop: spacing.m30
              },
              children: /* @__PURE__ */ jsx(
                ButtonComponent,
                {
                  sizeVariant: "medium",
                  colorVariant: "primary",
                  onClick: () => routerHistory.push(SETTINGS_BENEFITS_ROUTE),
                  children: "Set up pension"
                }
              )
            }
          )
        ] }),
        /* @__PURE__ */ jsx(Box, { children: /* @__PURE__ */ jsx(IntroCardGroup, { cards: emptyStates }) })
      ]
    }
  );
};
