"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box } from "@mui/material";
import { ChipComponent } from "@v2/components/chip/chip.component";
import { ButtonComponent } from "@v2/components/forms/button.component";
import { StyledMenuComponent } from "@v2/components/theme-components/styled-menu.component";
import { Typography } from "@v2/components/typography/typography.component";
import { DeviceAPI } from "@v2/feature/device/device.api";
import { DeviceNotesOverviewModal } from "@v2/feature/device/features/device-cards/components/device-notes-overview-modal.component";
import { GridDisplayBarV2 } from "@v2/feature/growth/shared/components/grid-display-bar.component";
import { usePolyglot } from "@v2/infrastructure/i18n/i8n.util";
import { iconSize } from "@v2/styles/menu.styles";
import { spacing } from "@v2/styles/spacing.styles";
import useMessage from "@/hooks/notification.hook";
import { ReactComponent as ActionsSmall } from "@/images/fields/ActionDots.svg";
import { ReactComponent as Edit } from "@/images/new-theme-icon/Edit.svg";
import { ReactComponent as Trash } from "@/images/side-bar-icons/Trash.svg";
import { nestErrorMessage } from "@/lib/errors";
export const DeviceNotesComponent = ({
  devicePossession,
  refresh
}) => {
  var _a, _b, _c, _d, _e, _f;
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage] = useMessage();
  return /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", flexDirection: "column", gap: spacing.g16 }, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "paragraph", color: "Grey", children: "You can add,edit or delete notes here" }),
    !((_a = devicePossession.device) == null ? void 0 : _a.customerNotes) && /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, className: "hide-for-capture", children: /* @__PURE__ */ jsx(
      ButtonComponent,
      {
        colorVariant: "secondary",
        sizeVariant: "small",
        onClick: () => {
          setIsOpen(true);
        },
        children: "Add note"
      }
    ) }),
    ((_b = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _b.customerNotes) && /* @__PURE__ */ jsx(
      GridDisplayBarV2,
      {
        cells: [
          {
            content: /* @__PURE__ */ jsxs(Box, { sx: { display: "flex", width: "85%", flexDirection: "column", gap: spacing.g8 }, children: [
              /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "paragraph",
                  dangerouslySetInnerHTML: { __html: (_d = (_c = devicePossession == null ? void 0 : devicePossession.device) == null ? void 0 : _c.customerNotes) != null ? _d : "" },
                  sx: { padding: 0, margin: 0, overflowWrap: "break-word" }
                }
              ),
              /* @__PURE__ */ jsx(Box, { sx: { display: "flex", alignItems: "center", gap: spacing.g8 }, children: /* @__PURE__ */ jsx(ChipComponent, { name: "Note", backgroundColor: "white", border: "background", textVariant: "paragraph" }) })
            ] }),
            gridXs: 8
          },
          {
            content: /* @__PURE__ */ jsx(
              StyledMenuComponent,
              {
                className: "hide-for-capture",
                options: [
                  {
                    icon: /* @__PURE__ */ jsx(Edit, __spreadValues({}, iconSize)),
                    handler: () => {
                      setIsOpen(true);
                    },
                    label: polyglot.t("General.edit")
                  },
                  {
                    icon: /* @__PURE__ */ jsx(Trash, __spreadValues({}, iconSize)),
                    handler: () => __async(void 0, null, function* () {
                      try {
                        yield DeviceAPI.updateDeviceCustomerNotes(devicePossession.deviceId, {
                          customerNotes: null
                        });
                        showMessage("Notes deleted successfully", "success");
                        yield refresh();
                      } catch (error) {
                        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, "error");
                      }
                    }),
                    label: polyglot.t("General.delete")
                  }
                ],
                actionButtonDetails: {
                  type: "iconButton",
                  colorVariant: "secondary",
                  sizeVariant: "small",
                  title: "actions",
                  icon: /* @__PURE__ */ jsx(ActionsSmall, __spreadValues({}, iconSize))
                }
              }
            ),
            gridXs: 4,
            sx: { display: "flex", justifyContent: "flex-end" }
          }
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      DeviceNotesOverviewModal,
      {
        isOpen,
        setIsOpen,
        onClose: () => __async(void 0, null, function* () {
          yield refresh == null ? void 0 : refresh();
          setIsOpen(false);
        }),
        selectedNote: (_f = (_e = devicePossession.device) == null ? void 0 : _e.customerNotes) != null ? _f : void 0,
        devicePossession
      }
    )
  ] });
};
